import Chart from 'react-apexcharts';
import { useState } from 'react'
import DashCards from '../../DashBoardsCards/DashCards';
import { Row, Col } from 'reactstrap'
import { rejectedPayer, rejectedPayerRX, rejectedPayerRepetition, rejectedPayerRepetitionRX, rejectedPayerRepetitionSAM, rejectedPayerSAM } from './RejectedPayer';
import ModalLoader from '../../Loader/ModalLoader';

const RejectedClaimsCharts = ({ callBack }) => {
    const [rejected, setRejected] = useState('All')
    const [yaxis, setYaxis] = useState('')
    const [count, setCount] = useState()
    const optionssalesoverview = {
        grid: {
            show: true,
            borderColor: 'rgba(0,0,0,.3)',
            strokeDashArray: 2,
            padding: {
                left: 0,
                right: 0,
                bottom: 0,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '15%',
                endingShape: 'flat',
            },
        },
        colors: ['#52C234','#26c6da', '#1e88e5'],
        fill: {
            type: 'solid',
            opacity: 1,
        },
        chart: {
            fontFamily: "'Poppins', sans-serif",
            toolbar: {
                show: false,
            },
            sparkline: {
                enabled: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        markers: {
            size: 0,
        },
        legend: {
            show: false,
        },
        xaxis: {
            type: 'category',
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            labels: {
                show: true,
                style: {
                    colors: '#99abb4',
                    fontSize: '12px',
                },
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            show: true,
            labels: {
                show: true,
                style: {
                    colors: '#99abb4',
                    fontSize: '12px',
                },
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        stroke: {
            show: true,
            width: 1,
            lineCap: 'butt',
            colors: ['transparent'],
        },
        tooltip: {
            theme: 'dark',
        },
    };
    const seriessalesoverview = [

        {
            name: '2021',
            data: sessionStorage.getItem('name') === 'Aurora' ?
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] :
                sessionStorage.getItem('name') === 'RX' ? [0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0]
                    : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },

        {
            name: '2022',
            data: sessionStorage.getItem('name') === 'Aurora' ?
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1] :
                sessionStorage.getItem('name') === 'RX' ? [0, 0, 2, 13, 20, 6, 0, 0, 0, 0, 0, 0]
                    : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
            name: '2023',
            data: sessionStorage.getItem('name') === 'Aurora' ?
                [1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] :
                sessionStorage.getItem('name') === 'RX' ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
            name: '2024',
            data: sessionStorage.getItem('name') === 'Aurora' ?
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] :
                sessionStorage.getItem('name') === 'RX' ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    : [0, 0, 0, 3, 0, 0, 11, 33, 0, 0, 0, 0],
        },
    ];
    const series = [
        {
            name: 'Claims',
            data: [
                count
            ],
        }
    ]
    var options = {
        chart: {
            type: 'bar',
            height: 450,
            stacked: true,
        },
        plotOptions: {
            bar: {
                horizontal: false,
            },
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },

        xaxis: {
            categories: [
                yaxis
            ],
            labels: {
                formatter: function (val) {
                    return val
                }
            },

        },
        yaxis: {
            title: {
                text: undefined
            },
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val
                }
            }
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
        }
    };
    const [isShow, setisShow] = useState(false)

    const handleData = (e) => {
        setisShow(true)
        let tempCount = 0;
        if (e.target.value === 'All') {
            setYaxis(e.target.value)
            setRejected(e.target.value)
            callBack('close', 0)

            // callBack(0, e.target.value, tempCount)

        } else {
            setRejected('')
            setYaxis(e.target.value)
            console.log('setYaxis(e.target.value) is :',e.target.value);
            const cnt = sessionStorage.getItem('name') === 'Aurora' ?
                rejectedPayerRepetition :
                sessionStorage.getItem('name') === 'RX' ? rejectedPayerRepetitionRX
                    :
                    sessionStorage.getItem('name') === 'PersonalClinic' ?
                        rejectedPayerRepetitionSAM : ''
            console.log('dataaaa ', cnt)
            for (let i = 0; i < cnt.length; i++) {
                if (e.target.value === cnt[i]) {
                    console.log('count', i)
                    // alert('hi')
                    tempCount += 1
                }
            }
            console.log(tempCount)
            callBack(e.target.value, tempCount)
            setCount(tempCount)
        }
        setTimeout(() => {
            setisShow(false)
        }, 1200);
    }
    return (
        <>
            <Row className='mb-2'>
                <ModalLoader isShow={isShow} />
                <Col md='4'>
                    <small className='text-start fs-4 text-dark fw-bolder'>Filter by payers</small>
                    {sessionStorage.getItem('name') === 'Aurora' ? <select className='form-control' value={yaxis} onChange={(e) => handleData(e)}>
                        {

                            rejectedPayer.map((val, i) => {
                                return <option key={i} value={val}>{val}</option>
                            })
                        }
                    </select>
                        : sessionStorage.getItem('name') === 'PersonalClinic' ? <select className='form-control' value={yaxis} onChange={(e) => handleData(e)}>
                            {

                                rejectedPayerSAM.map((val, i) => {
                                    return <option key={i} value={val}>{val}</option>
                                })
                            }
                        </select>
                            : sessionStorage.getItem('name') === 'RX' ?
                                <select className='form-control' value={yaxis} onChange={(e) => handleData(e)}>
                                    {

                                        rejectedPayerRX.map((val, i) => {
                                            return <option key={i} value={val}>{val}</option>
                                        })
                                    }
                                </select> :
                                ''}
                </Col>
            </Row>
            {
                rejected === 'All' ? <DashCards
                    title="Rejected Claims(Patients Count)"
                    actions={
                        <div className="hstack gap-2">
                             {/* <div className="d-flex align-items-center fs-6 " style={{color:'#52C234'}}>
                                <i className="bi bi-circle-fill fs-7 me-2 "></i> 2021
                            </div>
                            <div className="d-flex align-items-center fs-6 text-success">
                                <i className="bi bi-circle-fill fs-7 me-2 "></i> 2022
                            </div> */}
                            <div className="d-flex align-items-center fs-6 text-primary">
                                <i className="bi bi-circle-fill fs-7 me-2 "></i> 2023
                            </div>
                            <div className="d-flex align-items-center fs-6 ">
                                <i className="bi bi-circle-fill fs-7 me-2 "></i> 2024
                            </div>
                        </div>
                    }
                >

                    <Chart
                        options={optionssalesoverview}
                        series={seriessalesoverview}
                        type="bar"
                        height="308px"
                    />
                </DashCards> :
                    <div className="mt-4 position-relative" style={{ height: '350px', width: '1150px' }}>
                        <Chart options={options} series={series} type="bar" height="350px" width='1150px' />
                    </div>
            }

        </>

    );
};

export default RejectedClaimsCharts;
