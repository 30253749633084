import React, { useState } from 'react'
import { Row } from 'reactstrap'
import ClaimBlocks from './ClaimBlocks'
import ClaimChart from './ClaimChart'
import {
    grandTotal, over90, within90, twentythree, twentyfour ,
    grandTotalSAM, twentytwentySAM, twentytwoSAM, twentyoneSAM, over90SAM, within90SAM, twentythreeSAM , twentyfourSAM
} from './ClaimSumaryData'
import { grandTotalRX, over90RX, twentyoneRX, twentythreeRX, twentytwentyRX, twentytwoRX, within90RX } from './RX/RXClaimData'
const ClaimDashBoard = () => {
    const initialpercent = sessionStorage.getItem('name') === 'Aurora' ? (over90[0] / grandTotal[0]) * 100
        : sessionStorage.getItem('name') === 'RX' ? (over90RX[0] / grandTotalRX[0]) * 100
            : sessionStorage.getItem('name') === 'PersonalClinic' ? (over90SAM[0] / grandTotalSAM[0]) * 100 : ''
    const [analysis, setAnalysis] = useState({
        grandTotal: sessionStorage.getItem('name') === 'Aurora' ? grandTotal[0]
            : sessionStorage.getItem('name') === 'RX' ? grandTotalRX[0]
                : sessionStorage.getItem('name') === 'PersonalClinic' ? grandTotalSAM[0] : '',        
        // twenty20: sessionStorage.getItem('name') === 'Aurora' ? twentytwenty[0]
        //     : sessionStorage.getItem('name') === 'RX' ? twentytwentyRX[0]
        //         : sessionStorage.getItem('name') === 'PersonalClinic' ? twentytwentySAM[0] : ' ',
        // twenty21: sessionStorage.getItem('name') === 'Aurora' ? twentyone[0]
        //     : sessionStorage.getItem('name') === 'RX' ? twentyoneRX[0]
        //         : sessionStorage.getItem('name') === 'PersonalClinic' ? twentyoneSAM[0] : '',

        // twenty22: sessionStorage.getItem('name') === 'Aurora' ? twentytwo[0]
        twenty22: sessionStorage.getItem('name') === 'RX' ? twentytwoRX[0]
                : sessionStorage.getItem('name') === 'PersonalClinic' ? twentytwoSAM[0] : '',
        twenty23: sessionStorage.getItem('name') === 'Aurora' ? twentythree[0]
            : sessionStorage.getItem('name') === 'RX' ? twentythreeRX[0]
                : sessionStorage.getItem('name') === 'PersonalClinic' ? twentythreeSAM[0] : '',
        twenty24: sessionStorage.getItem('name') === 'Aurora' ? twentyfour[0]
            : sessionStorage.getItem('name') === 'PersonalClinic' ? twentyfourSAM[0] : '',
        over90: sessionStorage.getItem('name') === 'Aurora' ? over90[0]
            : sessionStorage.getItem('name') === 'RX' ? over90RX[0]
                : sessionStorage.getItem('name') === 'PersonalClinic' ? over90SAM[0] : '',
        within90: sessionStorage.getItem('name') === 'Aurora' ? within90[0]
            : sessionStorage.getItem('name') === 'RX' ? within90RX[0]
                : sessionStorage.getItem('name') === 'PersonalClinic' ? within90SAM[0] : '',
        percentageOvera90: initialpercent
    })
    const updateDate = (a, b, c, d, e, f, g, h) => {
        // console.log(`a : ${a} , b : ${b} , c : ${c} , d : ${d} , e : ${e} , f : ${f} , g : $ ${g} .`)

        const percent = (e / a) * 100
        setAnalysis({
            grandTotal: a,
            // twenty20: b,
            // twenty21: b,
            twenty22: b,
            twenty23: c,
            twenty24: d,
            over90: e,
            within90: f,
            percentageOvera90: percent
        })
    }
    return (
        <>
            <Row>
                <ClaimBlocks data={analysis} />
            </Row>
            <Row className='mt-2'>
                <ClaimChart updateData={updateDate} />
            </Row>

        </>
    )
}

export default ClaimDashBoard