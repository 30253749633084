import React, { useState } from 'react'
import { Row, Col } from 'reactstrap'
import ERAPie from './ERAPie'
import ModalShowInsuranceName from './ModalShowInsuranceName'
import bounceModule from './ERAModule.module.css'
const ERABlocks = () => {
    const [session,] = useState(sessionStorage.getItem('name'))
    const ERA_eligible_and_Enrolled = [
        'AARP UHC CLAIMS UNIT',
        'AETNA',
        'BCBS PPO',
        'BLUE CROSS MEDICARE ADVANTAGE',
        'CHAMPVA',
        'CIGNA',
        'CIGNA HEALTH SPRING',
        'HEALTH ALLIANCE',
        'HUMANA',
        'MEDICARE PART B',
        'UNITED HEALTH CARE'
    ]
    const ERA_not_applicable = [
        'ALL SAVERS',
        'AMERICAN REPUBLIC CORP INSURANCE CO',
        'AMERICAN ZURICH INSURANCE COMPANY',
        'BRIGHT HEALTH CARE',
        'DEVOTED HEALTH INC',
        'FOX VALLEY MEDICINE LTD',
        'PEKIN INSURANCE',
        'SECURA INSURANCE COMPANIES',
        'SEDGWICK CMS',
        'SILAC INSURANCE COMPANY'

    ]
    const ERA_Eligible_and_Not_enrolled = [

    ]

    const ERA_eligible_and_EnrolledSAM = [
        'AARP',
        'AETNA',
        'AETNA BETTER HEALTH',
        'AMITA HEALTH CARE NETWORK HCN',
        'BLUE CROSS BLUE SHIELD PPO',
        'BLUE CROSS COMMUNITY MMAI',
        'BLUE CROSS MEDICARE ADVANTAGE',
        'CIGNA',
        'HUMANA',
        'ILL DEPT OF PUBLIC AID',
        'MEDICARE PART B',
        'MERIDIAN HEALTH PLAN',
        'MERITAIN HEALTH',
        'Provider  Partners Health Plan',
        'RAILROAD MEDICARE',
        'UMR',
        'UNITED HEALTH CARE',
        'WELLCARE HMO//CLAIMS',

    ]
    const ERA_not_applicableSAM = [
        'ALL SAVERS',
        'Ambetter',
        'AMERICAN ALLIANCE INS',
        'benefit managment group',
        'BRISTOL WEST INC CO',
        'BROADSPIRE',
        'CCMSI',
        'CNA INS',
        'CORVEL',
        'COUNTY CARE',
        'Devoted Health',
        'EDWARD HEALTH PARTNERS',
        'ERIE INSURANCE EXCHANGE',
        'ESIS CENTRAL WC CLAIMS',
        'FARMERS INSURANCE',
        'First Chicago Insurance Company',
        'FRINGE BENEFIT GROUP INC',
        'GALLAGHER BASSETT',
        'GCU',
        'GHI',
        'GUIDEONE',
        'HANOVER INS GROUP',
        'IHP EDWARD',
        'IMAGINE HEALTH',
        'IPMG',
        'Liberty Mutual',
        'LOCAL 0025 SEIU',
        'Longevity Health Plan',
        'MacNeal Health Providers CHS( HMO )',
        'MHPBLUE ADVANTAGE COMMERCIAL',
        'MIDWEST OPERATING ENGINEERS',
        'MOLINA HEALTHCARE OF ILL MMCP',
        'NATIONAL CONTINENTAL INSURANCE',
        'Nationwide',
        'PLUMBERS 130',
        'PREMIER ACCESS INC',
        'Primary Care Associates',
        'PROGRESSIVE INS CO',
        'Risk management',
        'SENTRY',
        'Travelers Insurance',
        'TRICARE CLAIMS',
        'United HealthCare Shared Services',
        'VETERANS ADMINISTRATION',


    ]
    const ERA_Eligible_and_Not_enrolledSAM = [
        'Advocate',
        'ALLIED BENEFIT SYSTEM',
        'ALLSTATE',
        'Ascension Complete Illinois',
        'Automated Benefit Services, Inc.',
        'BRIGHT HEALTH PLAN',
        'CIGNA HEALTHSPRING',
        'COLONIAL PENN LIFE INSURANCE CO.',
        'MUTUAL OF OMAHA CO',
        'State Farm',

    ]
    ///rx
    const ERA_eligible_and_EnrolledRX = [
        'AETNA',
        'ALL SAVERS',
        'BCBS MEDICARE ADVANTAGE',
        'BCBS OF ILLINOIS',
        'CHAMPVA',
        'CIGNA',
        'GOLDEN RULE INSURANCE CO',
        'HUMANA CLAIMS',
        'MEDICARE',
        'RAILROAD MEDICARE',
        'UMR',
        'UNITED HEALTHCARE',
    ]
    const ERA_not_applicableRX = [
        'ABS',
        'ADMINISTRATIVE CONCEPTS INC',
        'AETNA SUPPLEMENTAL INS',
        'ASCENSION SMART HEALTH',
        'BENEFIT ADMINISTRATIVE SYSTEMS,LLC',
        'BERKSHIRE, HATHOWAY',
        'BLUE CROSS COMMUNITY OPTIONS',
        'CLASSIC BLUE HMO - MHP',
        'COLONIAL PENN LIFE INS COMPANY',
        'CORVEL',
        'CRUM FORESTER INS',
        'ESIS CENTRAL WC CLAIMS',
        'FOREIGN SERVICE BENEFIT PLAN',
        'GALLAGHER BASSETT',
        'Grenz Administrators',
        'HMO ILLINOIS LPP',
        'ICW GROUP',
        'INSURANCE TPA',
        'International Benefits Admin',
        'IPMG EMPLOYEE BENEFITS SERVICES',
        'KPIC',
        'LIBERTY MUTUAL',
        'Lucent Health',
        'MANHATTAN LIFE ASSURANCE',
        'NALC',
        'OSCAR',
        'PATIENT ADVOCATES',
        'PREFERRED ONE/MINNEAPOLIS,MN',
        'SBMA',
        'SEDGWICK',
        'SHEET METAL WORKER HEALTH',
        'SILAC INSURANCE COMPANY',
        'SMART HEALTH',
        'Societys Insurance',
        'TRANSACTRX',
        'Unified Life Insurance Co',
        'UNITED WORLD LIFE INS CO',
        'UNITEDHEALTH SHARED SERVICES',
        'WEBTPA',
        'WELLFLEET',
    ]
    const ERA_Eligible_and_Not_enrolledRX = [
        'AARP',
        'ALLIED BENEFIT SYSTEMS',
        'BRIGHT HEALTH',
        'CIGNA HEALTHSPRING',
        'GREAT WEST HEALTHCARE',
        'MERITAIN HEALTH',
        'MUTUAL OF OMAHA',
        'TRICARE',
    ]
    const [isShow, setisShow] = useState(false)
    const [data, setData] = useState([])
    const [eraStatusName, setEraStatusName] = useState('')
    const handleEligible = () => {
        setData([])
        setisShow(true)
        setData(session === 'Aurora' ? [...ERA_eligible_and_Enrolled] : session === 'RX' ? [...ERA_eligible_and_EnrolledRX] : [...ERA_eligible_and_EnrolledSAM])
        setEraStatusName('ERA Eligible and Enrolled')
    }
    const handleNotEligible = () => {
        setData([])
        setisShow(true)
        setData(session === 'Aurora' ? [...ERA_Eligible_and_Not_enrolled] : session === 'RX' ? [...ERA_Eligible_and_Not_enrolledRX] : [...ERA_Eligible_and_Not_enrolledSAM])
        setEraStatusName('ERA Eligible and Not Enrolled')
    }
    const handleNotApplicable = () => {
        setData([])
        setisShow(true)
        setData(session === 'Aurora' ? [...ERA_not_applicable] : session === 'RX' ? [...ERA_not_applicableRX] : [...ERA_not_applicableSAM])
        setEraStatusName('ERA Not Applicable')
    }
    const callBackCloseModal = () => {
        setisShow(false)
    }
    return (
        <Row className='text-white'>
            <Row className='m-3 position-absolute top-50 bottom-50'>
                <ModalShowInsuranceName isShow={isShow} data={data} eraStatusName={eraStatusName} callBackCloseModal={callBackCloseModal} />
            </Row>
            <h2 className='text-dark text-center'>ERA Status </h2>

            <Col>
                <h3 className='text-center'>ERA Status</h3>

                <ERAPie />
            </Col>
            <Col className='mt-5'>
                <Col
                    onClick={() => handleEligible()}
                    style={{ backgroundColor: '#6e48aa', cursor: 'pointer' }}
                    className={`m-1 p-2 border rounded ${bounceModule.bounce}`} >
                    <h3> ERA eligible  and Enrolled:</h3>
                    <span className='text-center fs-3'>{session === 'Aurora' ? ERA_eligible_and_Enrolled.length :
                        session === 'PersonalClinic' ? ERA_eligible_and_EnrolledSAM.length :
                            session === 'RX' ? ERA_eligible_and_EnrolledRX.length :
                                ""}</span>
                </Col>
                <Col
                    onClick={() => handleNotEligible()}
                    style={{ backgroundColor: '#6e48aa', cursor: 'pointer' }}
                    className={`m-1 p-2 border rounded ${bounceModule.bounce}`} >
                    <h3> ERA Eligible  and Not enrolled</h3>
                    <span className='text-center fs-3'>{session === 'Aurora' ?
                        ERA_Eligible_and_Not_enrolled.length :
                        session === 'PersonalClinic' ? ERA_Eligible_and_Not_enrolledSAM.length
                            :
                            session === 'RX' ? ERA_Eligible_and_Not_enrolledRX.length
                                : ""}</span>
                </Col>
                <Col
                    onClick={() => handleNotApplicable()}
                    style={{ backgroundColor: '#6e48aa', cursor: 'pointer' }}
                    className={`m-1 p-2 border rounded ${bounceModule.bounce}`} >
                    <h3> ERA not applicable:</h3>
                    <span className='text-center fs-3'>{session === 'Aurora' ?
                        ERA_not_applicable.length :
                        session === 'PersonalClinic' ? ERA_not_applicableSAM.length
                            :
                            session === 'RX' ? ERA_not_applicableRX.length
                                : ""}</span>
                </Col></Col>


            {/* <Col style={{ backgroundColor: '#6e48aa' }} className='m-1 p-2 border rounded'>
<h3>Enroll Not Mentioned</h3>
<span className='text-center fs-3'>52</span>
    </Col> */}
        </Row>
    )
}

export default ERABlocks