// import React from 'react';
import {allF1Charge , allF1Payment , allF1Collectable , allF1NonCollectable , allF1ClaimCount ,
    allF2Charge , allF2Payment , allF2Collectable , allF2NonCollectable , allF2ClaimCount ,
    allF3Charge , allF3Payment , allF3Collectable , allF3NonCollectable , allF3ClaimCount ,
    pro1F1Charge , pro1F1Payment , pro1F1Collectable , pro1F1NonCollectable , pro1F1ClaimCount ,
    pro1F2Charge , pro1F2Payment , pro1F2Collectable , pro1F2NonCollectable , pro1F2ClaimCount ,
    pro1F3Charge , pro1F3Payment , pro1F3Collectable , pro1F3NonCollectable , pro1F3ClaimCount ,
    pro2F1Charge , pro2F1Payment , pro2F1Collectable , pro2F1NonCollectable , pro2F1ClaimCount ,
    pro2F2Charge , pro2F2Payment , pro2F2Collectable , pro2F2NonCollectable , pro2F2ClaimCount ,
    pro2F3Charge , pro2F3Payment , pro2F3Collectable , pro2F3NonCollectable , pro2F3ClaimCount ,
    pro3F1Charge , pro3F1Payment , pro3F1Collectable , pro3F1NonCollectable , pro3F1ClaimCount ,
    pro3F2Charge , pro3F2Payment , pro3F2Collectable , pro3F2NonCollectable , pro3F2ClaimCount , 
    pro3F3Charge , pro3F3Payment , pro3F3Collectable , pro3F3NonCollectable , pro3F3ClaimCount ,
    pro4F1Charge , pro4F1Payment , pro4F1Collectable , pro4F1NonCollectable , pro4F1ClaimCount ,
    pro4F2Charge , pro4F2Payment , pro4F2Collectable , pro4F2NonCollectable , pro4F2ClaimCount ,
    pro4F3Charge , pro4F3Payment , pro4F3Collectable , pro4F3NonCollectable , pro4F3ClaimCount ,
    pro5F1Charge , pro5F1Payment , pro5F1Collectable , pro5F1NonCollectable , pro5F1ClaimCount ,
    pro5F2Charge , pro5F2Payment , pro5F2Collectable , pro5F2NonCollectable , pro5F2ClaimCount ,
    pro5F3Charge , pro5F3Payment , pro5F3Collectable , pro5F3NonCollectable , pro5F3ClaimCount ,
    pro6F1Charge , pro6F1Payment , pro6F1Collectable , pro6F1NonCollectable , pro6F1ClaimCount ,
    pro6F2Charge , pro6F2Payment , pro6F2Collectable , pro6F2NonCollectable , pro6F2ClaimCount ,
    pro6F3Charge , pro6F3Payment , pro6F3Collectable , pro6F3NonCollectable , pro6F3ClaimCount ,
    pro7F1Charge , pro7F1Payment , pro7F1Collectable , pro7F1NonCollectable , pro7F1ClaimCount ,
    pro7F2Charge , pro7F2Payment , pro7F2Collectable , pro7F2NonCollectable , pro7F2ClaimCount , 
    pro7F3Charge , pro7F3Payment , pro7F3Collectable , pro7F3NonCollectable , pro7F3ClaimCount } from './FacilityData';



// const ProvidersAndFacility = () => {

    // all
    export let allProviderChargesRX = [];
    export let allProviderPaymentRX = [];
    export let allProviderCollectableRX = [];
    export let allProviderNonCollectableRX = [];
    export let allProviderClaimRX = [];
    export let allProviderPervisitRX = [];

    // pro 1
    export let provider1Charges = [];
    export let provider1Payment = [];
    export let provider1Collectable = [];
    export let provider1NonCollectable = [];
    export let provider1Claim = [];
    export let provider1PerVisist = [];

    // pro 2
    export let provider2Charges = []; 
    export let provider2Payment = [];
    export let provider2Collectable = [];
    export let provider2NonCollectable = [];
    export let provider2Claim = [];
    export let provider2PerVisist = [];

    // pro 3
    export let provider3Charges = [];
    export let provider3Payment = [];
    export let provider3Collectable = [];
    export let provider3NonCollectable = [];
    export let provider3Claim = [];
    export let provider3PerVisist = [];

    // pro 4
    export let provider4Charges = []; 
    export let provider4Payment = [];
    export let provider4Collectable = [];
    export let provider4NonCollectable = [];
    export let provider4Claim = [];
    export let provider4PerVisist = [];

    // pro 5
    export let provider5Charges = [];
    export let provider5Payment = [];
    export let provider5Collectable = [];
    export let provider5NonCollectable = [];
    export let provider5Claim = [];
    export let provider5PerVisist = [];

    // pro 6
    export let provider6Charges = [];
    export let provider6Payment = [];
    export let provider6Collectable = [];
    export let provider6NonCollectable = [];
    export let provider6Claim = [];
    export let provider6PerVisist = [];

    // pro 7
    export let provider7Charges = [];
    export let provider7Payment = [];
    export let provider7Collectable = [];
    export let provider7NonCollectable = [];
    export let provider7Claim = [];
    export let provider7PerVisist = [];


    let facilityFiles = [[allF1Charge,allF2Charge,allF3Charge],
    [allF1Payment,allF2Payment,allF3Payment],
    [allF1Collectable,allF2Collectable,allF3Collectable],
    [allF1NonCollectable,allF2NonCollectable,allF3NonCollectable],
    [allF1ClaimCount,allF2ClaimCount,allF3ClaimCount],
    [pro1F1Charge,pro1F2Charge,pro1F3Charge],
    [pro1F1Payment,pro1F2Payment,pro1F3Payment],
    [pro1F1Collectable,pro1F2Collectable,pro1F3Collectable],
    [pro1F1NonCollectable,pro1F2NonCollectable,pro1F3NonCollectable],
    [pro1F1ClaimCount,pro1F2ClaimCount,pro1F3ClaimCount],
    [pro2F1Charge,pro2F2Charge,pro2F3Charge],
    [pro2F1Payment,pro2F2Payment,pro2F3Payment],
    [pro2F1Collectable,pro2F2Collectable,pro2F3Collectable],
    [pro2F1NonCollectable,pro2F2NonCollectable,pro2F3NonCollectable],
    [pro2F1ClaimCount,pro2F2ClaimCount,pro2F3ClaimCount],
    [pro3F1Charge,pro3F2Charge,pro3F3Charge],
    [pro3F1Payment,pro3F2Payment,pro3F3Payment],
    [pro3F1Collectable,pro3F2Collectable,pro3F3Collectable],
    [pro3F1NonCollectable,pro3F2NonCollectable,pro3F3NonCollectable],
    [pro3F1ClaimCount,pro3F2ClaimCount,pro3F3ClaimCount],
    [pro4F1Charge,pro4F2Charge,pro4F3Charge],
    [pro4F1Payment,pro4F2Payment,pro4F3Payment],
    [pro4F1Collectable,pro4F2Collectable,pro4F3Collectable],
    [pro4F1NonCollectable,pro4F2NonCollectable,pro4F3NonCollectable],
    [pro4F1ClaimCount,pro4F2ClaimCount,pro4F3ClaimCount],
    [pro5F1Charge,pro5F2Charge,pro5F3Charge],
    [pro5F1Payment,pro5F2Payment,pro5F3Payment],
    [pro5F1Collectable,pro5F2Collectable,pro5F3Collectable],
    [pro5F1NonCollectable,pro5F2NonCollectable,pro5F3NonCollectable],
    [pro5F1ClaimCount,pro5F2ClaimCount,pro5F3ClaimCount],
    [pro6F1Charge,pro6F2Charge,pro6F3Charge],
    [pro6F1Payment,pro6F2Payment,pro6F3Payment],
    [pro6F1Collectable,pro6F2Collectable,pro6F3Collectable],
    [pro6F1NonCollectable,pro6F2NonCollectable,pro6F3NonCollectable],
    [pro6F1ClaimCount,pro6F2ClaimCount,pro6F3ClaimCount],
    [pro7F1Charge,pro7F2Charge,pro7F3Charge],
    [pro7F1Payment,pro7F2Payment,pro7F3Payment],
    [pro7F1Collectable,pro7F2Collectable,pro7F3Collectable],
    [pro7F1NonCollectable,pro7F2NonCollectable,pro7F3NonCollectable],
    [pro7F1ClaimCount,pro7F2ClaimCount,pro7F3ClaimCount]]

    let savingFiles = [allProviderChargesRX , allProviderPaymentRX , allProviderCollectableRX , allProviderNonCollectableRX , allProviderClaimRX ,
        provider1Charges , provider1Payment , provider1Collectable , provider1NonCollectable , provider1Claim ,
        provider2Charges , provider2Payment , provider2Collectable , provider2NonCollectable , provider2Claim ,
        provider3Charges , provider3Payment , provider3Collectable , provider3NonCollectable , provider3Claim ,
        provider4Charges , provider4Payment , provider4Collectable , provider4NonCollectable , provider4Claim ,
        provider5Charges , provider5Payment , provider5Collectable , provider5NonCollectable , provider5Claim ,
        provider6Charges , provider6Payment , provider6Collectable , provider6NonCollectable , provider6Claim ,
        provider7Charges , provider7Payment , provider7Collectable , provider7NonCollectable , provider7Claim ]


        // console.log("provider7Claim : ",provider7Claim)

    for(let i=0 ; i<savingFiles.length ; i++ ){

        // console.log("i<savingFiles.length :",i<savingFiles.length)
        // console.log("test")
        for(let k=0 ; k<35 ; k++){
            // console.log("allF1Charge.length :",allF1Charge.length)
            savingFiles[i].push(Object.values(facilityFiles[i][0])[k] + Object.values(facilityFiles[i][1])[k] + Object.values(facilityFiles[i][2])[k]);
        }
    }



    let Pervisit = [[allProviderPaymentRX , allProviderClaimRX],
    [provider1Payment , provider1Claim],
    [provider2Payment , provider2Claim],
    [provider3Payment , provider3Claim],
    [provider4Payment , provider4Claim],
    [provider5Payment , provider5Claim],
    [provider6Payment , provider6Claim],
    [provider7Payment , provider7Claim]]

    let savingFile = [allProviderPervisitRX , provider1PerVisist , provider2PerVisist , provider3PerVisist , provider4PerVisist , provider5PerVisist , provider6PerVisist , provider7PerVisist]
    // console.log('allProviderPervisitRX :',allProviderPervisitRX)
    // console.log("provider7PerVisist :",provider7PerVisist)
    for(let i=0 ; i<savingFile.length ; i++){
        console.log("test")
        for(let j=0 ; j<35 ; j++){
            // console.log("Pervisit[i][0][j] :",Pervisit[i][0][j] )
            savingFile[i].push(Pervisit[i][0][j] / Pervisit[i][1][j])

        }
    }

//   return (
//     <div>
//         {console.log('allProviderPervisitRX :',allProviderPervisitRX)}
//     </div>
//   )
// }

// export default ProvidersAndFacility;























