import React, { useState } from 'react'
// import DashCards from '../../DashBoardsCards/DashCards';
import Chart from 'react-apexcharts';
import { Row, Col, Button } from 'reactstrap';
import { cptCodes, cptCodesPersonal, cptCodesRX } from './CptCodes';
// import { transamericapayments, transamericaunits, travellerpayments, travellerunits, tricarepayments, tricareunits, trustmarkpayments, trustmarkunits, unifiedpayments, unifiedunits, unitednationalpayments, unitednationalunits, unitedsharedpayments, unitedsharedunits, unitedworldpayments, unitedworldunits, unknownpayments, unknownunits, walmartpayments, walmartunits, webtpapayments, webtpaunits, wellfleetpayments, wellfleetunits, wellnetpayments, wellnetunits, unitedHeartLandUnit, unitedHeartPayments } from './CPTData11';
// import { rslpayments, rslunits, sanapayments, sanaunits, sedgwickpaymenst, sedgwickunits, sentrypayments, sentryunits, smarthealthapayments, smarthealthunits, societypayments, societyunits, starmackpayments, starmackunits, statefarmpayments, statefarmunits, studentresourcepayments, studentresourceunits, transactrixpayments, transactrixunits } from './CPTData10';
// import { panamericanpayments, panamericanunits, patientadvpayments, patientadvunits, planstinpayments, planstinunits, pmapayments, pmaunits, prairiepayments, prairieunits, preferedpayments, preferedunits, premierpayments, premierunits, railroadpayments, railroadunits, risingmedicalpayments, risingmedicalunits } from './CPTData9';
// import { medisharepayments, medishareunits, meridianunits, meridinapayments, meritainpayments, meritainunits, midwestpayments, midwestunits, nalcpayments, nalcunits, nationalgeneralpayments, nationalgeneralunits, northamericapayments, northamericaunits, oscarpayments, oscarunits, paipayments, paiunits } from './CPTData8';
// import { illinoiscasualtyunits, illinoispublicunits, internationalbenefitunits, ipmgunits, keybenefitunits, kpicunits, laborersunits, libertyunits, lucentunits, assachusetunits, illinoiscasualtypayments, illinoispublicpayments, internationalbenefitpayments, ipmgpayments, keybenefitpayments, kpicpayments, laborerspayments, lucentpayments, assachusetpayments, libertypayments } from './CPTData7';
// import { groupadminiunits, guideoneunits, hanoverunits, hardfortunits, healthlinkunits, performanceunits, hmaunits, icwunits, idpaunits, groupadminipayments, guideonepayments, hanoverpayments, hardfortpayments, healthlinkpayments, performancepayments, hmapayments, icupayments, idpapayments } from './CPTData6';
// import { corvelpayments, corvelunits, crumforesterpayments, crumforesterunits, emcpayments, emcunits, eriepayments, erieunits, esispayments, esisunits, fhnpayments, fhnunits, foreignservicepayments, foreignserviceunits, freedomlifepayments, freedomlifeunits, GALLAGHERpayments, GALLAGHERunits, goldenrulepayments, goldenruleunits } from './CPTData5';
// import { brighthealthpayments, brighthealthunits, broadspirepayments, broadspireunits, californiapayments, californiaunits, champvapayments, champvaunits, cignawestpayments, cignawestunits } from './CPTData4';
// import { benefitadministrativeunits, berkshireunits, bindunits, bluecrossunits, boonunits, boonchapmanunits, benefitadministrativepayments, berkshirepayments, bindpayments, bluecrosspayments, boonpayments, boonchapmanpayments } from './CPTData3';
// import { allsaversunits, alliedbenefitunits, alliednationalunits, allstateinsuranceunits, amrisureinsuranceunits, amtrustfinancialunits, ascensionunits, ascensionsmartunits, allsaverspayments, alliedbenefitpayments, alliednationalpayments, allstateinsurancepayments, amrisureinsurancepayments, amtrustfinancialpayments, ascensionpayments, ascensionsmartpayments } from './CPTData2';
import {
    AETNAUnits,AETNAPayments,medicareUnits, bcbsillinoisUnits, bcbsmedicareunits,
    aetnaunit, aarpunit, cignahealthunits, cignamedicareadvunits,
    hmoilinoisunits, humanainsuranceunits, selfpayunits, unitedhealthunits, umrunits, absunits, administrativeunits, afsliccareunits, medicarePayments, bcbspayments, bcbsmedicarepayments, aetnapayments, aarppayments, cignahealthpayments, cignamedicareadvpayments, hmoillionoispayments, humanainsurancepayments, selfpaypayments, unitedhealthpayments, umrpayments, abspayments, administrativepayments, afsliccarepayments, totalPayments, totalUnits
    ,humanaHmoUnits,humanaSelectUnits,medicarePartBUnits,midwestOperatingEngWelFundUnits,patientUnits,selfPayUnits,silacInsuranceCompanyUnits,ufcwLocal1546HealthAndWelfareFundUnits,unitedHealthCareUnits,unitedHEALTHCareUnits,usaSeniorCareUnits,humanaHmoPayment,humanaSelectPayment,medicarePartBPayment,midwestOperatingEngWelFundPayment,patientPayment,selfPayPayment,silacInsuranceCompanyPayment,ufcwLocal1546HealthAndWelfareFundPayment,
    unitedHealthCarePayment,unitedHEALTHCarePayment,usaSeniorCarePayment,unitedHealthCareNewUnit,unitedHealthCareNewPayment,unitHealthCareUnit,unitHealthCarePayment,UnitHealthCareUnits,UnitHealthCarePayments,USAseniorCareUnits,USAseniorCarePayment,unitedHeartlandUnit,unitedHeartlandPayment
} from './CPTData';
import ModalLoader from '../../Loader/ModalLoader';
import * as XLSX from "xlsx";
import { arrayValuesOfCPT } from './CPTdataArray';
import { payers, payersCPT, payersCPTPersonal } from './../ClaimSummary/payers';
import { subHeader } from './../../cptdata';
import {
    personaltotal, personaltotalunit, personalunit1,
    personalunit2,
    personalunit3,
    personalunit4,
    personalunit5,
    personalunit6,
    personalunit7,
    personalunit8,
    personalunit9,
    personalunit10,
    personalunit11,
    personalunit12,
    personalunit13,
    personalunit14,
    personalunit15,
    personalunit16,
    personalunit17,
    personalunit18,
    personalunit19,
    personalunit20,
    personalunit21,
    personalunit22,
    personalunit23,
    personalunit24,
    personalunit25,
    personalunit26,
    personalunit27,
    personalunit28,
    personalunit29,
    personalunit30,
    personalunit31,
    personalunit32,
    personalunit33,
    personalunit34,
    personalunit35,
    personalunit36,
    personalunit37,
    personalunit38,
    personalunit39,
    personalunit40,
    personalunit41,
    personalunit42,
    personalunit43,
    personalunit44,
    personalunit45,
    personalunit46,
    personalunit47,
    personalunit48,
    personalunit49,
    personalunit50,
    personalunit51,
    personalunit52,
    personalunit53,
    personalunit54,
    personalunit55,
    personalunit56,
    personalunit57,
    personalunit58,
    personalunit59,
    personalunit60,
    personalunit61,
    personalunit62,
    personalunit63,
    personalunit64,
    personalunit65,
    personalunit66,
    personalunit67,
    personalunit68,
    personalunit69,
    personalunit70,
    personalunit71,
    personalunit711,
    personalunit72,
    personalunit73,
    personalunit74,
    personalunit75,
    personalunit76,
    personalunit77,
    personalunit78,
    personalunit79,
    personalunit80,
    personalunit81,
    personalunit82,
    personalunit83,
    personalunit84,
    personalunit85,
    personalunit86,
    personalunit87,
    personalunit88,
    personalunit89,
    personalunit90,
    personalunit91,
    personalpayment1,
    personalpayment2,
    personalpayment3,
    personalpayment4,
    personalpayment5,
    personalpayment6,
    personalpayment7,
    personalpayment8,
    personalpayment9,
    personalpayment10,
    personalpayment11,
    personalpayment12,
    personalpayment13,
    personalpayment14,
    personalpayment15,
    personalpayment16,
    personalpayment17,
    personalpayment18,
    personalpayment19,
    personalpayment20,
    personalpayment21,
    personalpayment22,
    personalpayment23,
    personalpayment24,
    personalpayment25,
    personalpayment26,
    personalpayment27,
    personalpayment28,
    personalpayment29,
    personalpayment30,
    personalpayment31,
    personalpayment32,
    personalpayment33,
    personalpayment34,
    personalpayment35,
    personalpayment36,
    personalpayment37,
    personalpayment38,
    personalpayment39,
    personalpayment40,
    personalpayment41,
    personalpayment42,
    personalpayment43,
    personalpayment44,
    personalpayment45,
    personalpayment46,
    personalpayment47,
    personalpayment48,
    personalpayment49,
    personalpayment50,
    personalpayment51,
    personalpayment52,
    personalpayment53,
    personalpayment54,
    personalpayment55,
    personalpayment56,
    personalpayment57,
    personalpayment58,
    personalpayment59,
    personalpayment60,
    personalpayment61,
    personalpayment62,
    personalpayment63,
    personalpayment64,
    personalpayment65,
    personalpayment66,
    personalpayment67,
    personalpayment68,
    personalpayment69,
    personalpayment70,
    personalpayment71,
    personalpayment711,
    personalpayment72,
    personalpayment73,
    personalpayment74,
    personalpayment75,
    personalpayment76,
    personalpayment77,
    personalpayment78,
    personalpayment79,
    personalpayment80,
    personalpayment81,
    personalpayment82,
    personalpayment83,
    personalpayment84,
    personalpayment85,
    personalpayment86,
    personalpayment87,
    personalpayment88,
    personalpayment89,
    personalpayment90,
    personalpayment91
    
} from './PersonalClinic/Cptdata1';
import {
    personaltotalunitRX,
    personalunit1RX,
    personalunit2RX,
    personalunit3RX,
    personalunit4RX,
    personalunit5RX,
    personalunit6RX,
    personalunit7RX,
    personalunit8RX,
    personalunit9RX,
    personalunit10RX,
    personalunit11RX,
    personalunit12RX,
    personalunit13RX,
    personalunit14RX,
    personalunit15RX,
    personalunit16RX,
    personalunit17RX,
    personalunit18RX,
    personalunit19RX,
    personalunit20RX,
    personalunit21RX,
    personalunit22RX,
    personalunit23RX,
    personalunit24RX,
    personalunit25RX,
    personalunit26RX,
    personalunit27RX,
    personalunit28RX,
    personalunit29RX,
    personalunit30RX,
    personalunit31RX,
    personalunit32RX,
    personalunit33RX,
    personalunit34RX,
    personalunit35RX,
    personalunit36RX,
    personalunit37RX,
    personalunit38RX,
    personalunit39RX,
    personalunit40RX,
    personalunit41RX,
    personalunit42RX,
    personalunit43RX,
    personalunit44RX,
    personalunit45RX,
    personalunit46RX,
    personalunit47RX,
    personalunit48RX,
    personalunit49RX,
    personalunit50RX,
    personalunit51RX,
    personalunit52RX,
    personalunit53RX,
    personalunit54RX,
    personalunit55RX,
    personalunit56RX,
    personalunit57RX,
    personalunit58RX,
    personalunit59RX,
    personalunit60RX,
    personalunit61RX,
    personalunit62RX,
    personalunit63RX,
    personalunit64RX,
    personalunit65RX,
    personalunit66RX,
    personalunit67RX,
    personalunit68RX,
    personalunit69RX,
    personalunit70RX,
    personalunit71RX,
    personalunit711RX,
    personalunit72RX,
    personalunit73RX,
    personalunit74RX,
    personalunit75RX,
    personalunit76RX,
    personalunit77RX,
    personalunit78RX,
    personalunit79RX,
    personalunit80RX,
    personaltotalRX,
    personalpayment1RX,
    personalpayment2RX,
    personalpayment3RX,
    personalpayment4RX,
    personalpayment5RX,
    personalpayment6RX,
    personalpayment7RX,
    personalpayment8RX,
    personalpayment9RX,
    personalpayment10RX,
    personalpayment11RX,
    personalpayment12RX,
    personalpayment13RX,
    personalpayment14RX,
    personalpayment15RX,
    personalpayment16RX,
    personalpayment17RX,
    personalpayment18RX,
    personalpayment19RX,
    personalpayment20RX,
    personalpayment21RX,
    personalpayment22RX,
    personalpayment23RX,
    personalpayment24RX,
    personalpayment25RX,
    personalpayment26RX,
    personalpayment27RX,
    personalpayment28RX,
    personalpayment29RX,
    personalpayment30RX,
    personalpayment31RX,
    personalpayment32RX,
    personalpayment33RX,
    personalpayment34RX,
    personalpayment35RX,
    personalpayment36RX,
    personalpayment37RX,
    personalpayment38RX,
    personalpayment39RX,
    personalpayment40RX,
    personalpayment41RX,
    personalpayment42RX,
    personalpayment43RX,
    personalpayment44RX,
    personalpayment45RX,
    personalpayment46RX,
    personalpayment47RX,
    personalpayment48RX,
    personalpayment49RX,
    personalpayment50RX,
    personalpayment51RX,
    personalpayment52RX,
    personalpayment53RX,
    personalpayment54RX,
    personalpayment55RX,
    personalpayment56RX,
    personalpayment57RX,
    personalpayment58RX,
    personalpayment59RX,
    personalpayment60RX,
    personalpayment61RX,
    personalpayment62RX,
    personalpayment63RX,
    personalpayment64RX,
    personalpayment65RX,
    personalpayment66RX,
    personalpayment67RX,
    personalpayment68RX,
    personalpayment69RX,
    personalpayment70RX,
    personalpayment71RX,
    personalpayment711RX,
    personalpayment72RX,
    personalpayment73RX,
    personalpayment74RX,
    personalpayment75RX,
    personalpayment76RX,
    personalpayment77RX,
    personalpayment78RX,
    personalpayment79RX,
    personalpayment80RX
} from './RxRevenue/RxCPTData'
import {
    personalunit81RX,
    personalunit82RX,
    personalunit83RX,
    personalunit84RX,
    personalunit85RX,
    personalunit86RX,
    personalunit87RX,
    personalunit88RX,
    personalunit89RX,
    personalunit90RX,
    personalunit91RX,
    personalunit92RX,
    personalunit93RX,
    personalunit94RX,
    personalunit95RX,
    personalunit96RX,
    personalunit97RX,
    personalunit98RX,
    personalunit99RX,
    personalunit100RX,
    personalunit101RX,
    personalunit102RX,
    personalunit103RX,
    personalunit104RX,
    personalunit105RX,
    personalunit106RX,
    personalunit107RX,
    personalunit108RX,
    personalpayment81RX,
    personalpayment82RX,
    personalpayment83RX,
    personalpayment84RX,
    personalpayment85RX,
    personalpayment86RX,
    personalpayment87RX,
    personalpayment88RX,
    personalpayment89RX,
    personalpayment90RX,
    personalpayment91RX,
    personalpayment92RX,
    personalpayment93RX,
    personalpayment94RX,
    personalpayment95RX,
    personalpayment96RX,
    personalpayment97RX,
    personalpayment98RX,
    personalpayment99RX,
    personalpayment100RX,
    personalpayment101RX,
    personalpayment102RX,
    personalpayment103RX,
    personalpayment104RX,
    personalpayment105RX,
    personalpayment106RX,
    personalpayment107RX,
    personalpayment108RX
} from './RxRevenue/RxCPTData2'
// import { personalpayment81, personalpayment82, personalpayment83, personalpayment84, personalpayment85, personalpayment86, personalpayment87, personalpayment88, personalpayment89, personalpayment90, personalpayment91, personalunit81, personalunit82, personalunit83, personalunit84, personalunit85, personalunit86, personalunit87, personalunit88, personalunit89, personalunit90, personalunit91 } from './PersonalClinic/Cptdata2';

const CPTLevelCharts = ({ callbackupdate }) => {
    const [cpt, setCPT] = useState('')
    const excelHeader = sessionStorage.getItem('name') === 'Aurora' ? payersCPT : payersCPTPersonal
    const [session,] = useState(sessionStorage.getItem('name'))
    const [storecpt, setStoreCpt] = useState([])
    const [storecptIndex, setStoreCptIndex] = useState([])
    const [indexCpt, setIndexCpt] = useState(0)
    const series = [{
        name: 'Units',
        data: session === 'Aurora' ? [
            totalUnits[indexCpt],
            AETNAUnits[indexCpt],
            medicareUnits[indexCpt],
            bcbsillinoisUnits[indexCpt],
            bcbsmedicareunits[indexCpt],
            aetnaunit[indexCpt],
            aarpunit[indexCpt],
            cignahealthunits[indexCpt],
            cignamedicareadvunits[indexCpt],
            hmoilinoisunits[indexCpt],
            humanainsuranceunits[indexCpt],
            selfpayunits[indexCpt],
            silacInsuranceCompanyUnits[indexCpt],
            unitedhealthunits[indexCpt],
            umrunits[indexCpt],
            absunits[indexCpt],
            administrativeunits[indexCpt],
            afsliccareunits[indexCpt],
            humanaHmoUnits[indexCpt],
            humanaSelectUnits[indexCpt],
            medicarePartBUnits[indexCpt],
            midwestOperatingEngWelFundUnits[indexCpt],
            patientUnits[indexCpt],
            selfPayUnits[indexCpt],
            // ufcwLocal1546HealthAndWelfareFundUnits[indexCpt],
            // unitedHealthCareUnits[indexCpt],
            // unitedHEALTHCareUnits[indexCpt],
            // usaSeniorCareUnits[indexCpt],
            // unitedHealthCareNewUnit[indexCpt],
            // unitHealthCareUnit[indexCpt],
            // UnitHealthCareUnits[indexCpt],
            // USAseniorCareUnits[indexCpt],
            // unitedHeartlandUnit[indexCpt],
            // allsaversunits[indexCpt],
            // alliedbenefitunits[indexCpt],
            // alliednationalunits[indexCpt],
            // allstateinsuranceunits[indexCpt],
            // amrisureinsuranceunits[indexCpt],
            // amtrustfinancialunits[indexCpt],
            // ascensionunits[indexCpt],
            // ascensionsmartunits[indexCpt],
            // benefitadministrativeunits[indexCpt],
            // berkshireunits[indexCpt],
            // bindunits[indexCpt],
            // bluecrossunits[indexCpt],
            // boonunits[indexCpt]
            // boonchapmanunits[indexCpt],
            // brighthealthunits[indexCpt],
            // broadspireunits[indexCpt],
            // californiaunits[indexCpt],
            // champvaunits[indexCpt],
            // cignawestunits[indexCpt],

        ] : session === 'PersonalClinic' ? [
            personaltotalunit[indexCpt],
            personalunit1[indexCpt],
            personalunit2[indexCpt],
            personalunit3[indexCpt],
            personalunit4[indexCpt],
            personalunit5[indexCpt],
            personalunit6[indexCpt],
            personalunit7[indexCpt],
            personalunit8[indexCpt],
            personalunit9[indexCpt],
            personalunit10[indexCpt],
            personalunit11[indexCpt],
            personalunit12[indexCpt],
            personalunit13[indexCpt],
            personalunit14[indexCpt],
            personalunit15[indexCpt],
            personalunit16[indexCpt],
            personalunit17[indexCpt],
            personalunit18[indexCpt],
            personalunit19[indexCpt],
            personalunit20[indexCpt],
            personalunit21[indexCpt],
            personalunit22[indexCpt],
            personalunit23[indexCpt],
            personalunit24[indexCpt],
            personalunit25[indexCpt],
            personalunit26[indexCpt],
            personalunit27[indexCpt],
            personalunit28[indexCpt],
            personalunit29[indexCpt],
            personalunit30[indexCpt],
            personalunit31[indexCpt],
            personalunit32[indexCpt],
            personalunit33[indexCpt],
            personalunit34[indexCpt],
            personalunit35[indexCpt],
            personalunit36[indexCpt],
            personalunit37[indexCpt],
            personalunit38[indexCpt],
            personalunit39[indexCpt],
            personalunit40[indexCpt],
            personalunit41[indexCpt],
            personalunit42[indexCpt],
            personalunit43[indexCpt],
            personalunit44[indexCpt],
            personalunit45[indexCpt],
            personalunit46[indexCpt],
            personalunit47[indexCpt],
            personalunit48[indexCpt],
            personalunit49[indexCpt],
            personalunit50[indexCpt],
            personalunit51[indexCpt],
            personalunit52[indexCpt],
            personalunit53[indexCpt],
            personalunit54[indexCpt],
            personalunit55[indexCpt],
            personalunit56[indexCpt],
            personalunit57[indexCpt],
            personalunit58[indexCpt],
            personalunit59[indexCpt],
            personalunit60[indexCpt],
            personalunit61[indexCpt],
            personalunit62[indexCpt],
            personalunit63[indexCpt],
            personalunit64[indexCpt],
            personalunit65[indexCpt],
            personalunit66[indexCpt],
            personalunit67[indexCpt],
            personalunit68[indexCpt],
            personalunit69[indexCpt],
            personalunit70[indexCpt],
            personalunit71[indexCpt],
            personalunit711[indexCpt],
            personalunit72[indexCpt],
            personalunit73[indexCpt],
            personalunit74[indexCpt],
            // personalunit75[indexCpt],
            // personalunit76[indexCpt],
            // personalunit77[indexCpt],
            // personalunit78[indexCpt],
            // personalunit79[indexCpt],
            // personalunit80[indexCpt],
            // personalunit81[indexCpt],
            // personalunit82[indexCpt],
            // personalunit83[indexCpt],
            // personalunit84[indexCpt],
            // personalunit85[indexCpt],
            // personalunit86[indexCpt]
            // personalunit87[indexCpt],
            // personalunit88[indexCpt],
            // personalunit87[indexCpt],
            // personalunit88[indexCpt],
            // personalunit89[indexCpt],
            // personalunit90[indexCpt],
            // personalunit91[indexCpt]
        ] : [
            personaltotalunitRX[indexCpt],
            personalunit1RX[indexCpt],
            personalunit2RX[indexCpt],
            personalunit3RX[indexCpt],
            personalunit4RX[indexCpt],
            personalunit5RX[indexCpt],
            personalunit6RX[indexCpt],
            personalunit7RX[indexCpt],
            personalunit8RX[indexCpt],
            personalunit9RX[indexCpt],
            personalunit10RX[indexCpt],
            personalunit11RX[indexCpt],
            personalunit12RX[indexCpt],
            personalunit13RX[indexCpt],
            personalunit14RX[indexCpt],
            personalunit15RX[indexCpt],
            personalunit16RX[indexCpt],
            personalunit17RX[indexCpt],
            personalunit18RX[indexCpt],
            personalunit19RX[indexCpt],
            personalunit20RX[indexCpt],
            personalunit21RX[indexCpt],
            personalunit22RX[indexCpt],
            personalunit23RX[indexCpt],
            personalunit24RX[indexCpt],
            personalunit25RX[indexCpt],
            personalunit26RX[indexCpt],
            personalunit27RX[indexCpt],
            personalunit28RX[indexCpt],
            personalunit29RX[indexCpt],
            personalunit30RX[indexCpt],
            personalunit31RX[indexCpt],
            personalunit32RX[indexCpt],
            personalunit33RX[indexCpt],
            personalunit34RX[indexCpt],
            personalunit35RX[indexCpt],
            personalunit36RX[indexCpt],
            personalunit37RX[indexCpt],
            personalunit38RX[indexCpt],
            personalunit39RX[indexCpt],
            personalunit40RX[indexCpt],
            personalunit41RX[indexCpt],
            personalunit42RX[indexCpt],
            personalunit43RX[indexCpt],
            personalunit44RX[indexCpt],
            personalunit45RX[indexCpt],
            personalunit46RX[indexCpt],
            personalunit47RX[indexCpt],
            personalunit48RX[indexCpt],
            personalunit49RX[indexCpt],
            personalunit50RX[indexCpt],
            personalunit51RX[indexCpt],
            personalunit52RX[indexCpt],
            personalunit53RX[indexCpt],
            personalunit54RX[indexCpt],
            personalunit55RX[indexCpt],
            personalunit56RX[indexCpt],
            personalunit57RX[indexCpt],
            personalunit58RX[indexCpt],
            personalunit59RX[indexCpt],
            personalunit60RX[indexCpt],
            personalunit61RX[indexCpt],
            personalunit62RX[indexCpt],
            personalunit63RX[indexCpt],
            personalunit64RX[indexCpt],
            personalunit65RX[indexCpt],
            personalunit66RX[indexCpt],
            personalunit67RX[indexCpt],
            personalunit68RX[indexCpt],
            personalunit69RX[indexCpt],
            personalunit70RX[indexCpt],
            personalunit71RX[indexCpt],
            personalunit711RX[indexCpt],
            personalunit72RX[indexCpt],
            personalunit73RX[indexCpt],
            personalunit74RX[indexCpt],
            personalunit75RX[indexCpt],
            personalunit76RX[indexCpt],
            personalunit77RX[indexCpt],
            personalunit78RX[indexCpt],
            personalunit79RX[indexCpt],
            personalunit80RX[indexCpt],
            personalunit81RX[indexCpt],
            personalunit82RX[indexCpt],
            personalunit83RX[indexCpt],
            personalunit84RX[indexCpt],
            personalunit85RX[indexCpt],
            personalunit86RX[indexCpt],
            personalunit87RX[indexCpt],
            personalunit88RX[indexCpt],
            personalunit89RX[indexCpt],
            personalunit90RX[indexCpt],
            personalunit91RX[indexCpt],
            personalunit92RX[indexCpt],
            personalunit93RX[indexCpt],
            personalunit94RX[indexCpt],
            personalunit95RX[indexCpt],
            personalunit96RX[indexCpt],
            personalunit97RX[indexCpt],
            personalunit98RX[indexCpt],
            personalunit99RX[indexCpt],
            personalunit100RX[indexCpt],
            personalunit101RX[indexCpt],
            personalunit102RX[indexCpt],
            personalunit103RX[indexCpt],
            personalunit104RX[indexCpt],
            personalunit105RX[indexCpt],
            personalunit106RX[indexCpt],
            personalunit107RX[indexCpt],
            personalunit108RX[indexCpt],
        ]
    },
    {
        name: 'Payments',
        data: session === 'Aurora' ? [
            totalPayments[indexCpt],
            AETNAPayments[indexCpt],
            medicarePayments[indexCpt],
            bcbspayments[indexCpt],
            bcbsmedicarepayments[indexCpt],
            aetnapayments[indexCpt],
            aarppayments[indexCpt],
            cignahealthpayments[indexCpt],
            cignamedicareadvpayments[indexCpt],
            hmoillionoispayments[indexCpt],
            humanainsurancepayments[indexCpt],
            selfpaypayments[indexCpt],
            silacInsuranceCompanyPayment[indexCpt],
            unitedhealthpayments[indexCpt],
            umrpayments[indexCpt],
            abspayments[indexCpt],
            administrativepayments[indexCpt],
            afsliccarepayments[indexCpt],
            humanaHmoPayment[indexCpt],
            humanaSelectPayment[indexCpt],
            medicarePartBPayment[indexCpt],
            midwestOperatingEngWelFundPayment[indexCpt],
            patientPayment[indexCpt],
            selfPayPayment[indexCpt],
            // ufcwLocal1546HealthAndWelfareFundPayment[indexCpt],
            // unitedHealthCarePayment[indexCpt],
            // unitedHEALTHCarePayment[indexCpt],
            // usaSeniorCarePayment[indexCpt],
            // unitedHealthCareNewPayment[indexCpt],
            // unitHealthCarePayment[indexCpt],
            // UnitHealthCarePayments[indexCpt], 
            // USAseniorCarePayment[indexCpt],
            // unitedHeartlandPayment[indexCpt]     
            // allsaverspayments[indexCpt],
            // alliedbenefitpayments[indexCpt],
            // alliednationalpayments[indexCpt],
            // allstateinsurancepayments[indexCpt],
            // amrisureinsurancepayments[indexCpt],
            // amtrustfinancialpayments[indexCpt],
            // ascensionpayments[indexCpt],
            // ascensionsmartpayments[indexCpt],
            // benefitadministrativepayments[indexCpt],
            // berkshirepayments[indexCpt],
            // bindpayments[indexCpt],
            // bluecrosspayments[indexCpt],
            // boonpayments[indexCpt]
            // boonchapmanpayments[indexCpt],
            // brighthealthpayments[indexCpt],
            // broadspirepayments[indexCpt],
            // californiapayments[indexCpt],
            // champvapayments[indexCpt],
            // cignawestpayments[indexCpt],

        ] : session === 'PersonalClinic' ?
            [
                personaltotal[indexCpt],
                personalpayment1[indexCpt],
                personalpayment2[indexCpt],
                personalpayment3[indexCpt],
                personalpayment4[indexCpt],
                personalpayment5[indexCpt],
                personalpayment6[indexCpt],
                personalpayment7[indexCpt],
                personalpayment8[indexCpt],
                personalpayment9[indexCpt],
                personalpayment10[indexCpt],
                personalpayment11[indexCpt],
                personalpayment12[indexCpt],
                personalpayment13[indexCpt],
                personalpayment14[indexCpt],
                personalpayment15[indexCpt],
                personalpayment16[indexCpt],
                personalpayment17[indexCpt],
                personalpayment18[indexCpt],
                personalpayment19[indexCpt],
                personalpayment20[indexCpt],
                personalpayment21[indexCpt],
                personalpayment22[indexCpt],
                personalpayment23[indexCpt],
                personalpayment24[indexCpt],
                personalpayment25[indexCpt],
                personalpayment26[indexCpt],
                personalpayment27[indexCpt],
                personalpayment28[indexCpt],
                personalpayment29[indexCpt],
                personalpayment30[indexCpt],
                personalpayment31[indexCpt],
                personalpayment32[indexCpt],
                personalpayment33[indexCpt],
                personalpayment34[indexCpt],
                personalpayment35[indexCpt],
                personalpayment36[indexCpt],
                personalpayment37[indexCpt],
                personalpayment38[indexCpt],
                personalpayment39[indexCpt],
                personalpayment40[indexCpt],
                personalpayment41[indexCpt],
                personalpayment42[indexCpt],
                personalpayment43[indexCpt],
                personalpayment44[indexCpt],
                personalpayment45[indexCpt],
                personalpayment46[indexCpt],
                personalpayment47[indexCpt],
                personalpayment48[indexCpt],
                personalpayment49[indexCpt],
                personalpayment50[indexCpt],
                personalpayment51[indexCpt],
                personalpayment52[indexCpt],
                personalpayment53[indexCpt],
                personalpayment54[indexCpt],
                personalpayment55[indexCpt],
                personalpayment56[indexCpt],
                personalpayment57[indexCpt],
                personalpayment58[indexCpt],
                personalpayment59[indexCpt],
                personalpayment60[indexCpt],
                personalpayment61[indexCpt],
                personalpayment62[indexCpt],
                personalpayment63[indexCpt],
                personalpayment64[indexCpt],
                personalpayment65[indexCpt],
                personalpayment66[indexCpt],
                personalpayment67[indexCpt],
                personalpayment68[indexCpt],
                personalpayment69[indexCpt],
                personalpayment70[indexCpt],
                personalpayment71[indexCpt],
                personalpayment711[indexCpt],
                personalpayment72[indexCpt],
                personalpayment73[indexCpt],
                personalpayment74[indexCpt],
                // personalpayment75[indexCpt],
                // personalpayment76[indexCpt],
                // personalpayment77[indexCpt],
                // personalpayment78[indexCpt],
                // personalpayment79[indexCpt],
                // personalpayment80[indexCpt],
                // personalpayment81[indexCpt],
                // personalpayment82[indexCpt],
                // personalpayment83[indexCpt],
                // personalpayment84[indexCpt],
                // personalpayment85[indexCpt],
                // personalpayment86[indexCpt]
                // personalpayment87[indexCpt],
                // personalpayment88[indexCpt],
                // personalpayment87[indexCpt],
                // personalpayment88[indexCpt],
                // personalpayment89[indexCpt],
                // personalpayment90[indexCpt],
                // personalpayment91[indexCpt]
            ] : [
                
                    personaltotalRX[indexCpt],
                    personalpayment1RX[indexCpt],
                    personalpayment2RX[indexCpt],
                    personalpayment3RX[indexCpt],
                    personalpayment4RX[indexCpt],
                    personalpayment5RX[indexCpt],
                    personalpayment6RX[indexCpt],
                    personalpayment7RX[indexCpt],
                    personalpayment8RX[indexCpt],
                    personalpayment9RX[indexCpt],
                    personalpayment10RX[indexCpt],
                    personalpayment11RX[indexCpt],
                    personalpayment12RX[indexCpt],
                    personalpayment13RX[indexCpt],
                    personalpayment14RX[indexCpt],
                    personalpayment15RX[indexCpt],
                    personalpayment16RX[indexCpt],
                    personalpayment17RX[indexCpt],
                    personalpayment18RX[indexCpt],
                    personalpayment19RX[indexCpt],
                    personalpayment20RX[indexCpt],
                    personalpayment21RX[indexCpt],
                    personalpayment22RX[indexCpt],
                    personalpayment23RX[indexCpt],
                    personalpayment24RX[indexCpt],
                    personalpayment25RX[indexCpt],
                    personalpayment26RX[indexCpt],
                    personalpayment27RX[indexCpt],
                    personalpayment28RX[indexCpt],
                    personalpayment29RX[indexCpt],
                    personalpayment30RX[indexCpt],
                    personalpayment31RX[indexCpt],
                    personalpayment32RX[indexCpt],
                    personalpayment33RX[indexCpt],
                    personalpayment34RX[indexCpt],
                    personalpayment35RX[indexCpt],
                    personalpayment36RX[indexCpt],
                    personalpayment37RX[indexCpt],
                    personalpayment38RX[indexCpt],
                    personalpayment39RX[indexCpt],
                    personalpayment40RX[indexCpt],
                    personalpayment41RX[indexCpt],
                    personalpayment42RX[indexCpt],
                    personalpayment43RX[indexCpt],
                    personalpayment44RX[indexCpt],
                    personalpayment45RX[indexCpt],
                    personalpayment46RX[indexCpt],
                    personalpayment47RX[indexCpt],
                    personalpayment48RX[indexCpt],
                    personalpayment49RX[indexCpt],
                    personalpayment50RX[indexCpt],
                    personalpayment51RX[indexCpt],
                    personalpayment52RX[indexCpt],
                    personalpayment53RX[indexCpt],
                    personalpayment54RX[indexCpt],
                    personalpayment55RX[indexCpt],
                    personalpayment56RX[indexCpt],
                    personalpayment57RX[indexCpt],
                    personalpayment58RX[indexCpt],
                    personalpayment59RX[indexCpt],
                    personalpayment60RX[indexCpt],
                    personalpayment61RX[indexCpt],
                    personalpayment62RX[indexCpt],
                    personalpayment63RX[indexCpt],
                    personalpayment64RX[indexCpt],
                    personalpayment65RX[indexCpt],
                    personalpayment66RX[indexCpt],
                    personalpayment67RX[indexCpt],
                    personalpayment68RX[indexCpt],
                    personalpayment69RX[indexCpt],
                    personalpayment70RX[indexCpt],
                    personalpayment71RX[indexCpt],
                    personalpayment711RX[indexCpt],
                    personalpayment72RX[indexCpt],
                    personalpayment73RX[indexCpt],
                    personalpayment74RX[indexCpt],
                    personalpayment75RX[indexCpt],
                    personalpayment76RX[indexCpt],
                    personalpayment77RX[indexCpt],
                    personalpayment78RX[indexCpt],
                    personalpayment79RX[indexCpt],
                    personalpayment80RX[indexCpt],
                    personalpayment81RX[indexCpt],
                    personalpayment82RX[indexCpt],
                    personalpayment83RX[indexCpt],
                    personalpayment84RX[indexCpt],
                    personalpayment85RX[indexCpt],
                    personalpayment86RX[indexCpt],
                    personalpayment87RX[indexCpt],
                    personalpayment88RX[indexCpt],
                    personalpayment89RX[indexCpt],
                    personalpayment90RX[indexCpt],
                    personalpayment91RX[indexCpt],
                    personalpayment92RX[indexCpt],
                    personalpayment93RX[indexCpt],
                    personalpayment94RX[indexCpt],
                    personalpayment95RX[indexCpt],
                    personalpayment96RX[indexCpt],
                    personalpayment97RX[indexCpt],
                    personalpayment98RX[indexCpt],
                    personalpayment99RX[indexCpt],
                    personalpayment100RX[indexCpt],
                    personalpayment101RX[indexCpt],
                    personalpayment102RX[indexCpt],
                    personalpayment103RX[indexCpt],
                    personalpayment104RX[indexCpt],
                    personalpayment105RX[indexCpt],
                    personalpayment106RX[indexCpt],
                    personalpayment107RX[indexCpt],
                    personalpayment108RX[indexCpt],
                ]
            
    },

    ]
    var options = {
        chart: {
            type: 'bar',
            height: 450,
            stacked: true,
        },
        plotOptions: {
            bar: {
                horizontal: true,
            },
        },
        stroke: {
            width: 1,
            colors: ['black']
        },

        xaxis: {
            categories: session === 'Aurora' ? [
                'TOTAL',
                'AETNA',
                'Aetna Medicare Advantage',
                'AMERICAN REPUBLIC CORP INSURANCE CO',
                'ASCENSION SMAR THEALTH',
                'BCBS',
                'BCBS PPO',
                'BLUE CROSS MEDICARE ADVANTAGE',
                'CHAMPVA',
                'CIGNA ',
                'CIGNA GREAT WEST',
                'DEVOTED HEALTH INC',
                'FOX VALLEY MEDICINE LTD',
                'FOX VALLEY MEDICINE LTD BCBS HMO',
                'HUMANA HMO',
                'HUMANA SELECT',
                'MEDICARE PART B',
                'Meritain Health ',
                'MIDWEST OPERATING ENG WEL FUND',
                'Patient',
                'Self Pay',
                'UNITED HEALTH CARE',
                'UNITED HEALTHCARE',
                'UNITED HEARTLAND',
                

            ] : session === 'PersonalClinic' ?
                [
                    'TOTAL',
                    'AARP/UHC CLAIMS',
                    'Advocate- Christ',
                    'ADVOCATE CHRIST PHYSICIAN PARTNERS',
                    'ADVOCATE HEALTH CARE',
                    'ADVOCATE PHYSICIAN PARTNERS',
                    'AETNA',
                    'AETNA BETTER HEALTH INC DUAL PLAN( IL )',
                    'Aetna Better Health of IL - Medicaid',
                    'AETNA SELECT OPEN ACCESS',
                    'Aetna Senior Supplement Ins',
                    'ALL SAVERS ( UHC )',
                    'ALLIED BENEFIT SYSTEM',
                    'AMITA HEALTH CARE NETWORK HCN',
                    'Automated Benefit Services, Inc.',
                    'BLUE CROSS BLUE SHIELD PPO',
                    'BLUE CROSS COMMUNITY MMAI/FAMILY HEALTH',
                    'BLUE CROSS MEDICARE ADVANTAGE',
                    'BLUE CROSS MEDICARE ADVANTAGE PPO',
                    'CIGNA',
                    'CIGNA HEALTH & LIFE INURANCE',
                    'CIGNA HEALTHSPRING HMO',
                    'CIGNA HMO',
                    'CIGNA MEDICARE',
                    'Cigna PPO',
                    'CLEAR SPRING HEALTH',
                    'CORESOURCE',
                    'COUNTRY LIFE INSURANCE',
                    'COUNTY CARE',
                    'COUNTY CARE HEALTH PLAN',
                    'Devoted Health',
                    'FEDERAL LIFE INSURANACE CO',
                    'GREAT WEST CASUALTY CO',
                    'HEALTHLINK',
                    'HUMANA  ADVENTIST',
                    'HUMANA CLAIMS',
                    'IHP EDWARD/MED ADV C/O ARCADIA',
                    'IHPHMO IL',
                    'ILLINOIS DEPT OF PUBLIC AID',
                    'IPMG',
                    'MacNeal Health Providers CHS( HMO )',
                    'MEDICARE   PART B',
                    'MERIDIAN HEALTH PLAN',
                    'MERITAIN HEALTH',
                    'MHPBLUE ADVANTAGE',
                    'MHPBLUE ADVANTAGE COMMERCIAL',
                    'MOLINA HEALTHCARE OF ILL MMCP',
                    'MUTUAL OF OMAHA CO',
                    'NATIONAL ELEVATOR INDUSTR',
                    'OSCAR',
                    'Patient',
                    'Presence Health Partners',
                    'Priority health',
                    'Provider  Partners Health Plan',
                    'RAILROAD MEDICARE PALMETTO/TRAVELERS',
                    'Seamless Health',
                    'Sedgewick',
                    'Self Pay',
                    'STATE FARM',
                    'STATE FARM CLAIMS',
                    'TRICARE CLAIMS',
                    'UMR',
                    'UNITED HEALTH CARE',
                    'UNITED HEALTHCARE',
                    'United Healthcare -HMO',
                    'United Healthcare Medicare',
                    'UNITED HEALTHCARE MEDICARE COMPLETE',
                    'United HealthCare Shared Services',
                    'UNITED HEALTHCARE/ MEDICARE SOLUTION',
                    'UnitedHealthcare Choice Plus',
                    'US DEPARTMENT OF LABOR',
                    'VHA OFFICE OF COMMUNITY CARE',
                    'WAUSAU WAUSAU WI BX 8013',
                    'WELLCARE BY ALLWELL',
                    'WELLCARE HMO/ /CLAIMS',
                    'ZING HEALTH',
                    
                ] : [
                    'MEDICARE',
                    'BCBS Illinois',
                    'BCBS MEDICARE ADVANTAGE',
                    'AETNA',
                    'AARP',
                    'CIGNA HEALTHCARE',
                    'CIGNA MEDICARE ADVANTAGE',
                    'HMO ILLINOIS LPP',
                    'HUMANA INSURANCE',
                    'SELF PAY',
                    'UNITED HEALTH CARE',
                    'UMR',
                    'ABS',
                    'ADMINISTRATIVE CONCEPTS INC',
                    'AFSLIC CARE CHOICE',
                    'ALL SAVERS',
                    'ALLIED BENEFIT SYSTEMS',
                    'ALLIED NATIONAL',
                    'ALLSTATE  INSURANCE',
                    'AMERISURE INSURANCE COMPANY',
                    'AMTRUST FINANCIAL',
                    'Ascension Complete',
                    'ASCENSION SMART HEALTH',
                    'BENEFIT ADMINISTRAATIVE SYSTEMS,LLC',
                    'BERKSHIRE, HATHOWAY',
                    'BIND BENEFITS, INC',
                    'BLUE CROSS COMMUNITY MMAI',
                    'BOON GROUP',
                    'BOON-CHAPMAN BENEFIT ADMIN INC',
                    'BRIGHT HEALTH',
                    'BROADSPIRE',
                    'CALIFORNIA INS COMPANY',
                    'CHAMPVA',
                    'CIGNA GREAT WEST',
                    'CLASSIC BLUE HMO - MHP',
                    'CONCIERGE ADMINISTRATIVE SERVICES',
                    'CORESOURCE',
                    'CORVEL',
                    'CRUM FORESTER INS',
                    'EMC INSURANCE',
                    'Erie Insurance Group',
                    'ESIS CENTRAL WC CLAIMS',
                    'FHN',
                    'FOREIGN SERVICE BENEFIT PLAN',
                    'FREEDOM LIFE INS CO OF AMERICA',
                    'GALLAGHER BASSETT',
                    'GOLDEN RULE INSURANCE CO',
                    'GROUP ADMINISTRATORS LTD',
                    'GUIDEONE',
                    'HANOVER INSURANCE CO',
                    'HARTFORD/LEXINGTON',
                    'HEALTHLINK',
                    'High Performance Health Plan',
                    'HMA, LLC',
                    'ICW GROUP',
                    'IDPA SECONDARY',
                    'ILLINOIS CASUALTY COMPANY',
                    'ILLINOIS DEPT OF PUBLIC AID',
                    'International Benefits Admin',
                    'IPMG EMPLOYEE BENEFITS SERVICES',
                    'KEY BENEFIT ADMINISTRATORS',
                    'KPIC',
                    'LABORERS HWF/WESTCHESTER,IL',
                    'LIBERTY MUTUAL',
                    'LUCENT HEALTH',
                    'Massachusetts Mutual Life Insurance Co',
                    'MEDI SHARE',
                    'MERIDIAN HEALTH',
                    'MERITAIN HEALTH',
                    'MIDWEST OPERATING ENGINEERS',
                    'NALC/ASHBURN,VA',
                    'NATIONAL GENERAL',
                    'North America Administrators, Lp',
                    'OSCAR',
                    'PAI',
                    'PAN AMERICAN LIFE INS CO',
                    'PATIENT ADVOCATES',
                    'PLANSTIN ADMININSTRATION',
                    'PMA COMPANIES',
                    'PRAIRIE STATES ENTERPRISES,INC',
                    'PREFERRED ONE/MINNEAPOLIS,MN',
                    'PREMIER ACCESS, INC',
                    'RAILROAD MEDICARE',
                    'RISING MEDICAL SOLUTIONS',
                    'RSL SPECIALTY PRODUCTS ADMINISTRATION',
                    'SANA BENEFITS',
                    'SEDGWICK',
                    'SENTRY CLAIMS CENTER',
                    'SMART HEALTH',
                    'Societys Insurance',
                    'STARMARK/CLINTON,IA',
                    'STATE FARM',
                    'STUDENT RESOURCES',
                    'TRANSACTRX',
                    'TRANSAMERICA LIFE INSURANCE',
                    'TRAVELERS INSURANCE',
                    'TRICARE',
                    'TRUSTMARK HEALTH BENEFITS',
                    'Unified Life Insurance Co',
                    'UNITED HEARTLAND',
                    'UNITED NATIONAL INSURANCE GROUP',
                    'UNITED SHARED SERVICES',
                    'UNITED WORLD LIFE INS CO',
                    'Unknown',
                    'Walmart Claim Services',
                    'WEBTPA',
                    'WELLFLEET',
                    'WELLNET',
                    'ZURICH INSURANCE'
                ]
            ,
            labels: {
                formatter: function (val) {
                    return val
                }
            },
        },
        yaxis: {
            title: {
                text: undefined
            },
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val
                }
            }
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
        }
    };
    const [isShow, setisShow] = useState(false)
    const handleData = (e) => {
        setisShow(true)
        setCPT(e.target.value)
        // console.log('setCPT :',e.target.value);
        // console.log('  auroracptcode index ',cptCodes.indexOf(e.target.value) - 1);
        const val = session === 'Aurora' ?
            cptCodes.indexOf(e.target.value) - 1 :
            session === 'PersonalClinic' ? cptCodesPersonal.indexOf(e.target.value) - 1 : cptCodesRX.indexOf(e.target.value) - 1
        setIndexCpt(val)
        callbackupdate(session === 'Aurora' ? [totalUnits[val], totalPayments[val]] : session === 'RX' ? [personaltotalunitRX[val],personaltotalRX[val] ]:
            [personaltotalunit[val], personaltotal[val]])
        setTimeout(() => {
            setisShow(false)
        }, 1200);
    }
    const handleStoreCPT = (e) => {
        setStoreCpt(prev => [...prev, e.target.value])
        setStoreCptIndex(prev => [...prev, sessionStorage.getItem('name') === 'Aurora' ?
            cptCodes.indexOf(e.target.value) - 1 :sessionStorage.getItem('name') ? cptCodesRX.indexOf(e.target.value) - 1 : cptCodesPersonal.indexOf(e.target.value) - 1])
    }
    const handleClear = () => {
        storecpt.pop()
        storecptIndex.pop()
        setStoreCpt([...storecpt])
        setStoreCptIndex([...storecptIndex])
    }
    const handleDownload = () => {
        // console.log('hello', storecptIndex)
        setisShow(true)
        let res = []
        for (let i = 0; i < storecptIndex.length; i++) {
            let temp = Object.assign({}, arrayValuesOfCPT(storecptIndex[i], storecpt[i]))
            res.push(temp)
        }
        let header = []
        header = [excelHeader.map(val => {
            return val
        })]
        // console.log(key)
        console.log('header :' , header)
        console.log(res)
        var merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 0 } },
        { s: { r: 0, c: 1 }, e: { r: 0, c: 2 } },
        { s: { r: 0, c: 3 }, e: { r: 0, c: 4 } },
        { s: { r: 0, c: 5 }, e: { r: 0, c: 6 } },
        { s: { r: 0, c: 7 }, e: { r: 0, c: 8 } },
        { s: { r: 0, c: 9 }, e: { r: 0, c: 10 } },
        { s: { r: 0, c: 11 }, e: { r: 0, c: 12 } },
        { s: { r: 0, c: 13 }, e: { r: 0, c: 14 } },
        { s: { r: 0, c: 15 }, e: { r: 0, c: 16 } },
        { s: { r: 0, c: 17 }, e: { r: 0, c: 18 } },
        { s: { r: 0, c: 19 }, e: { r: 0, c: 20 } },
        { s: { r: 0, c: 21 }, e: { r: 0, c: 22 } },
        { s: { r: 0, c: 23 }, e: { r: 0, c: 24 } },
        { s: { r: 0, c: 25 }, e: { r: 0, c: 26 } },
        { s: { r: 0, c: 27 }, e: { r: 0, c: 28 } },
        { s: { r: 0, c: 29 }, e: { r: 0, c: 30 } },
        { s: { r: 0, c: 31 }, e: { r: 0, c: 32 } },
        { s: { r: 0, c: 33 }, e: { r: 0, c: 34 } },
        { s: { r: 0, c: 35 }, e: { r: 0, c: 36 } },
        { s: { r: 0, c: 37 }, e: { r: 0, c: 38 } },
        { s: { r: 0, c: 39 }, e: { r: 0, c: 40 } },
        { s: { r: 0, c: 41 }, e: { r: 0, c: 42 } },
        { s: { r: 0, c: 43 }, e: { r: 0, c: 44 } },
        { s: { r: 0, c: 45 }, e: { r: 0, c: 46 } },
        { s: { r: 0, c: 47 }, e: { r: 0, c: 48 } },
        { s: { r: 0, c: 49 }, e: { r: 0, c: 50 } },
        { s: { r: 0, c: 51 }, e: { r: 0, c: 52 } },
        { s: { r: 0, c: 53 }, e: { r: 0, c: 54 } },
        { s: { r: 0, c: 55 }, e: { r: 0, c: 56 } },
        { s: { r: 0, c: 57 }, e: { r: 0, c: 58 } },
        { s: { r: 0, c: 59 }, e: { r: 0, c: 60 } },
        { s: { r: 0, c: 61 }, e: { r: 0, c: 62 } },
        { s: { r: 0, c: 63 }, e: { r: 0, c: 64 } },
        { s: { r: 0, c: 65 }, e: { r: 0, c: 66 } },
        { s: { r: 0, c: 67 }, e: { r: 0, c: 68 } },
        { s: { r: 0, c: 69 }, e: { r: 0, c: 70 } },
        { s: { r: 0, c: 71 }, e: { r: 0, c: 72 } },
        { s: { r: 0, c: 73 }, e: { r: 0, c: 74 } },
        { s: { r: 0, c: 75 }, e: { r: 0, c: 76 } },
        { s: { r: 0, c: 77 }, e: { r: 0, c: 78 } },
        { s: { r: 0, c: 79 }, e: { r: 0, c: 80 } },
        { s: { r: 0, c: 81 }, e: { r: 0, c: 82 } },
        { s: { r: 0, c: 83 }, e: { r: 0, c: 84 } },
        { s: { r: 0, c: 85 }, e: { r: 0, c: 86 } },
        { s: { r: 0, c: 87 }, e: { r: 0, c: 88 } },
        { s: { r: 0, c: 89 }, e: { r: 0, c: 90 } },
        { s: { r: 0, c: 91 }, e: { r: 0, c: 92 } },
        { s: { r: 0, c: 93 }, e: { r: 0, c: 94 } },
        { s: { r: 0, c: 95 }, e: { r: 0, c: 96 } },
        { s: { r: 0, c: 97 }, e: { r: 0, c: 98 } },
        { s: { r: 0, c: 99 }, e: { r: 0, c: 100 } },
        { s: { r: 0, c: 101 }, e: { r: 0, c: 102 } },
        { s: { r: 0, c: 103 }, e: { r: 0, c: 104 } },
        { s: { r: 0, c: 105 }, e: { r: 0, c: 106 } },
        { s: { r: 0, c: 107 }, e: { r: 0, c: 108 } },
        { s: { r: 0, c: 109 }, e: { r: 0, c: 110 } },
        { s: { r: 0, c: 111 }, e: { r: 0, c: 112 } },
        { s: { r: 0, c: 113 }, e: { r: 0, c: 114 } },
        { s: { r: 0, c: 115 }, e: { r: 0, c: 116 } },
        { s: { r: 0, c: 117 }, e: { r: 0, c: 118 } },
        { s: { r: 0, c: 119 }, e: { r: 0, c: 120 } },
        { s: { r: 0, c: 121 }, e: { r: 0, c: 122 } },
        { s: { r: 0, c: 123 }, e: { r: 0, c: 124 } },
        { s: { r: 0, c: 125 }, e: { r: 0, c: 126 } },
        { s: { r: 0, c: 127 }, e: { r: 0, c: 128 } },
        { s: { r: 0, c: 129 }, e: { r: 0, c: 130 } },
        { s: { r: 0, c: 131 }, e: { r: 0, c: 132 } },
        { s: { r: 0, c: 133 }, e: { r: 0, c: 134 } },
        { s: { r: 0, c: 135 }, e: { r: 0, c: 136 } },
        { s: { r: 0, c: 137 }, e: { r: 0, c: 138 } },
        { s: { r: 0, c: 139 }, e: { r: 0, c: 140 } },
        { s: { r: 0, c: 141 }, e: { r: 0, c: 142 } },
        { s: { r: 0, c: 143 }, e: { r: 0, c: 144 } },
        { s: { r: 0, c: 145 }, e: { r: 0, c: 146 } },
        { s: { r: 0, c: 147 }, e: { r: 0, c: 148 } },
        { s: { r: 0, c: 149 }, e: { r: 0, c: 150 } },
        { s: { r: 0, c: 151 }, e: { r: 0, c: 152 } },
        { s: { r: 0, c: 153 }, e: { r: 0, c: 154 } },
        { s: { r: 0, c: 155 }, e: { r: 0, c: 156 } },
        { s: { r: 0, c: 157 }, e: { r: 0, c: 158 } },
        { s: { r: 0, c: 159 }, e: { r: 0, c: 160 } },
        { s: { r: 0, c: 161 }, e: { r: 0, c: 162 } },
        { s: { r: 0, c: 163 }, e: { r: 0, c: 164 } },
        { s: { r: 0, c: 165 }, e: { r: 0, c: 166 } },
        { s: { r: 0, c: 167 }, e: { r: 0, c: 168 } },
        { s: { r: 0, c: 169 }, e: { r: 0, c: 170 } },
        { s: { r: 0, c: 171 }, e: { r: 0, c: 172 } },
        { s: { r: 0, c: 173 }, e: { r: 0, c: 174 } },
        { s: { r: 0, c: 175 }, e: { r: 0, c: 176 } },
        { s: { r: 0, c: 177 }, e: { r: 0, c: 178 } },
        { s: { r: 0, c: 179 }, e: { r: 0, c: 180 } },
        { s: { r: 0, c: 181 }, e: { r: 0, c: 182 } },
        { s: { r: 0, c: 183 }, e: { r: 0, c: 184 } },
        { s: { r: 0, c: 185 }, e: { r: 0, c: 186 } },
        { s: { r: 0, c: 187 }, e: { r: 0, c: 188 } },
        { s: { r: 0, c: 189 }, e: { r: 0, c: 190 } },
        ]
        const worksheet = XLSX.utils.json_to_sheet(res, { origin: 'A3', skipHeader: true });
        const ws = XLSX.utils.sheet_add_aoa(worksheet, header, { origin: 'A1' });
        XLSX.utils.sheet_add_aoa(worksheet, subHeader, { origin: 'B2' });
        if (!ws['!merges']) ws['!merges'] = [];
        ws['!merges'] = merge;
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, storecpt.toString() + ".xlsx");
        setTimeout(() => {
            setisShow(false)
        }, 1000);


    }
    return (
        <Row className='overflow-auto mt-2'>
            <ModalLoader isShow={isShow} />
            {/* <h3 className='text-center'> CPT Level Payments</h3> */}
            <Row>
                <Col md='4' className='border border-grey shadow-lg'>
                    <small className='text-start fs-4 text-dark fw-bolder'>Filter by CPT Code</small>
                    <select className='form-control mb-2' value={cpt} onChange={(e) => handleData(e)}>
                        {
                            session === 'Aurora' ?

                                cptCodes.map((val, i) => {
                                    return <option key={i} value={val}>{val}</option>
                                })
                                : session === 'PersonalClinic' ?

                                    cptCodesPersonal.map((val, i) => {
                                        return <option key={i} value={val}>{val}</option>
                                    })
                                    : cptCodesRX.map((val, i) => {
                                        return <option key={i} value={val}>{val}</option>
                                    })
                        }
                    </select>
                </Col>
                <Col md='4' className='border border-grey shadow-lg'>
                    <small className='text-start fs-4 text-dark fw-bolder'>Download in Excel</small>
                    <select className='form-control mb-2' onChange={(e) => handleStoreCPT(e)}>
                        {
                            session === 'Aurora' ?
                                cptCodes.map((val, i) => {
                                    return <option key={i} value={val}>{val}</option>
                                })
                                : session === 'PersonalClinic' ?
                                    cptCodesPersonal.map((val, i) => {
                                        return <option key={i} value={val}>{val}</option>
                                    })
                                    : cptCodesRX.map((val, i) => {
                                        return <option key={i} value={val}>{val}</option>
                                    })
                        }
                    </select>

                </Col>
                <Col className='border border-grey shadow-lg'>
                    <Row className='mt-2 p-2 d-flex justify-content-start '>
                        <Row>
                            <Button className='btn text-primary w-25 border-primary mb-2' color='white' onClick={handleClear}>Clear</Button><br />
                            <Button className='mx-2 btn text-primary w-25 border-primary mb-2' color='white' onClick={handleDownload}>Download</Button><br />

                        </Row>                        {
                            storecpt.map((val, i) => {
                                return <small
                                    className='p-2 mx-1 text-center text-white bg-info mb-2'
                                    style={{ borderRadius: '10px', width: '100px' }}
                                    key={i}>
                                    {val}
                                </small>

                            })
                        }
                    </Row>
                </Col>
            </Row>
            <div className="mt-4 position-relative " style={{ height: '1950px', width: '1100px' }}>
                <Chart options={options} series={series} type="bar" height="1950px" width='1100px' />
            </div>
        </Row>
    )
}

export default CPTLevelCharts