export const RXFromTo = [
    [
        [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        [
            0,
            94,
            0,
            29,
            45,
            13,
            0,
            10,
            0,
            0,
            10,
            0,
            8,
            0,
            0,
            0,
            59,
            0,
            5,
            0,
            0,
            0,
            0,
            156,
            0,
            5,
            0,
            0,
            73,
            12,
            0, 
            0,
            0,   
            0   
           
           

        ],
        [
            0,
            0,
            5,
            0,
            45,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            30,
            0,
            0,
            190,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,   
            15, 
            0,   
            0,   
            0,   
            0 
           
           
        ]
        , [
            48,
            94,
            0,
            278,
            0,
            13,
            0,
            0,
            0,
            0,
            5,
            0,
            8,
            0,
            0,
            0,
            59,
            0,
            5,
            62,
            0,
            0,
            0,
            156,
            0,
            5,
            0,
            0,
            73, 
            3,
            0,   
            0,   
            0,   
            0 
           
           

        ]
        , [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ]
        , [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0

        ]
    ],
    [
        [
            60844,
            60454,
            57022,
            71860,
            48311,
            78836,
            66470,
            73384,
            50335,
            110500,
            102934,
            72909,
            78624,
            58643,
            87847,
            56683,
            71883,
            50486,
            76203,
            46209,
            88753,
            83956,
            112581,
            62738,
            104484,
            100625,
            99505,
            41709,
            91423, 
            82040, 
            76101, 
            84069, 
            57237, 
            127807, 
            117097 
           

        ]
        , [
            34428,
            36743,
            38612,
            42402,
            32937,
            43982,
            39047,
            47948,
            33367,
            47301,
            65513,
            47427,
            47616,
            40650,
            53187,
            46031,
            35544,
            31229,
            46193,
            41641,
            42677,
            54285,
            54909,
            46756,
            56728,
            52390,
            56166,
            40427,
            39368,
            44696,
            47523,
            54114,
            35256,
            56643,
            70265
           

        ]
        , [
            187,
            243,
            97,
            110,
            154,
            248,
            71,
            0,
            119,
            150,
            105,
            197,
            7706,
            183,
            130,
            236,
            224,
            243,
            215,
            11150,
            13081,
            7377,
            3516,
            1,
            99,
            201,
            536,
            280,
            180,
            542,
            257,
            323,
            127,
            918,
            448
           

        ]
        , [
            23056,
            22062,
            21835,
            25568,
            18277,
            24783,
            20210,
            25044,
            19334,
            28734,
            42420,
            26295,
            26665,
            23458,
            30978,
            24762,
            20618,
            20634,
            30408,
            23844,
            24034,
            29052,
            27502,
            21851,
            39890,
            41399,
            43295,
            30605,
            28213,
            31601,
            32629,
            34970,
            24573,
            37286,
            48827
           


        ]
        , [
            429,
            333,
            331,
            420,
            271,
            471,
            395,
            416,
            257,
            470,
            450,
            332,
            393,
            282,
            415,
            273,
            339,
            237,
            380,
            412,
            397,
            452,
            435,
            378,
            389,
            402,
            417,
            328,
            462,
            436,
            438,
            496,
            289,
            475,
            493


        ]
        , [
            80,
            110,
            117,
            101,
            122,
            93,
            99,
            115,
            130,
            101,
            146,
            143,
            121,
            144,
            128,
            169,
            105,
            132,
            122,
            101,
            107,
            120,
            126,
            124,
            146,
            130,
            135,
            123,
            85, 
            103, 
            109, 
            109, 
            122, 
            119, 
            143 
           


        ]
    ],
    [
        [
            85512,
            53805,
            92540,
            76005,
            60308,
            85978,
            78062,
            93570,
            72683,
            91543,
            97055,
            100391,
            85440,
            72068,
            83030,
            83792,
            81234,
            39101,
            88434,
            102211,
            94889,
            116357,
            119216,
            96739,
            132293,
            101312,
            134537,
            113573,
            94419, 
            104234, 
            68693, 
            99032, 
            123550, 
            120684, 
            152530 
           

        ]
        , [
            40225,
            49666,
            52606,
            50554,
            38869,
            40815,
            49533,
            55390,
            41237,
            49059,
            57934,
            58261,
            44538,
            56013,
            54053,
            50074,
            52617,
            31555,
            48113,
            71733,
            49587,
            68877,
            72132,
            69382,
            68760,
            59956,
            72999,
            65436,
            54948,
            61904,
            46660,
            61779,
            53376,
            61884,
            88662
           

        ]
        , [
            1,
            122,
            465,
            35,
            142,
            77,
            172,
            224,
            114,
            111,
            202,
            285,
            4328,
            369,
            108,
            160,
            129,
            538,
            322,
            9942,
            7708,
            7404,
            2926,
            173,
            145,
            150,
            90,
            217,
            196,
            553,
            1437,
            488,
            503,
            425,
            243
           

        ]
        , [
            26091,
            27779,
            28541,
            31928,
            23909,
            24243,
            27271,
            30686,
            22632,
            31197,
            36368,
            37348,
            26685,
            30202,
            30946,
            25492,
            36599,
            17541,
            28142,
            37725,
            25649,
            34773,
            37210,
            32361,
            43952,
            47708,
            55234,
            48988,
            39521,
            42386,
            32590,
            41792,
            34980,
            44797,
            65772
           


        ]
        , [
            613,
            339,
            555,
            490,
            382,
            535,
            442,
            471,
            355,
            474,
            449,
            512,
            431,
            340,
            404,
            382,
            391,
            202,
            372,
            386,
            397,
            412,
            420,
            432,
            439,
            417,
            467,
            399,
            521,
            511,
            430,
            553,
            463,
            499,
            598


        ]
        , [
            66,
            147,
            95,
            103,
            102,
            76,
            112,
            118,
            116,
            104,
            129,
            114,
            103,
            165,
            134,
            131,
            135,
            156,
            129,
            186,
            125,
            167,
            172,
            161,
            157,
            144,
            156,
            164,
            105,
            121,
            109, 
            112, 
            115, 
            124, 
            148 
           


        ]
    ],
    [
        [
            57431,
            58749,
            75650,
            70368,
            67129,
            61804,
            77570,
            67965,
            89393,
            96606,
            92757,
            69604,
            69419,
            71437,
            80137,
            76466,
            95649,
            36705,
            65313,
            87251,
            102209,
            119214,
            99136,
            77999,
            99129,
            95069,
            90313,
            94486,
            93056, 
            73211, 
            87779, 
            75544, 
            108306, 
            130471, 
            126637 
           

        ]
        , [
            28449,
            37876,
            48274,
            42544,
            40275,
            39861,
            37392,
            40680,
            48712,
            59958,
            62220,
            47212,
            36931,
            34603,
            60954,
            45016,
            49343,
            34658,
            45006,
            54546,
            58006,
            62823,
            74341,
            60735,
            52344,
            51358,
            55978,
            50538,
            56256,
            47863,
            49327,
            52526,
            49515,
            66234,
            75163
           


        ]
        , [
            1,
            99,
            392,
            108,
            58,
            28,
            295,
            2,
            175,
            213,
            240,
            112,
            2818,
            65,
            260,
            60,
            20,
            120,
            302,
            5375,
            10922,
            10816,
            3155,
            307,
            10,
            0,
            50,
            288,
            258,
            138,
            296,
            153,
            1086,
            92,
            159
           

        ]
        , [
            19491,
            22287,
            26735,
            25320,
            23886,
            24732,
            21032,
            22226,
            28809,
            34095,
            38410,
            27616,
            22025,
            21902,
            36475,
            24249,
            30582,
            18265,
            24060,
            29835,
            30955,
            32533,
            34254,
            26309,
            34704,
            37123,
            41055,
            33159,
            35822,
            32433,
            32035,
            31641,
            32059,
            44082,
            56468
           

        ]
        , [
            443,
            355,
            479,
            412,
            422,
            402,
            422,
            370,
            436,
            485,
            452,
            347,
            376,
            352,
            409,
            365,
            453,
            212,
            376,
            423,
            453,
            407,
            492,
            438,
            387,
            368,
            362,
            358,
            488,
            416,
            448,
            458,
            469,
            532,
            494


        ]
        , [
            64,
            107,
            101,
            103,
            95,
            99,
            89,
            110,
            112,
            124,
            138,
            136,
            98,
            98,
            149,
            123,
            109,
            163,
            120,
            129,
            128,
            154,
            151,
            139,
            135,
            140,
            155,
            141,
            115, 
            115, 
            110, 
            115, 
            106, 
            125, 
            152 
           


        ]
    ],
    [
        [
            55920,
            36041,
            61296,
            66931,
            52800,
            70747,
            71201,
            100611,
            71507,
            74741,
            66130,
            62372,
            71124,
            63660,
            66990,
            63747,
            66588,
            45424,
            72870,
            96452,
            71110,
            77246,
            74211,
            79252,
            91960,
            9048,
            2492,
            2908,
            72763, 
            83380,
            69269, 
            64650, 
            63780, 
            91113, 
            65818 
           

        ]
        , [
            27817,
            31210,
            31070,
            36513,
            32545,
            39311,
            36348,
            53150,
            43075,
            47442,
            43559,
            39091,
            35502,
            39709,
            42544,
            44164,
            45763,
            25165,
            49633,
            58677,
            46563,
            55417,
            42154,
            49780,
            51923,
            28787,
            8174,
            7125,
            18266,
            45172,
            41613,
            43458,
            34777,
            53232,
            42154
           


        ]
        , [
            11,
            11,
            228,
            48,
            282,
            372,
            170,
            2,
            0,
            18,
            95,
            3,
            5819,
            25,
            111,
            60,
            210,
            113,
            129,
            8878,
            11050,
            9038,
            1801,
            10,
            53,
            50,
            0,
            0,
            176,
            234,
            260,
            205,
            79,
            152,
            92
           


        ]
        , [
            18128,
            17626,
            19094,
            27445,
            17558,
            21639,
            26728,
            29274,
            22911,
            30167,
            28232,
            15952,
            21108,
            23568,
            25095,
            24334,
            26622,
            15068,
            31022,
            33897,
            25434,
            28098,
            20098,
            23765,
            32359,
            21124,
            5238,
            2372,
            13552,
            30217,
            28070,
            28461,
            19259,
            33753,
            30701
           

        ]
        , [
            464,
            227,
            401,
            397,
            349,
            467,
            400,
            487,
            354,
            391,
            361,
            356,
            393,
            339,
            360,
            321,
            347,
            228,
            360,
            369,
            397,
            368,
            342,
            375,
            278,
            92,
            0,
            0,
            414,
            440,
            371,
            387,
            346,
            380,
            285


        ]
        , [
            60,
            137,
            77,
            92,
            93,
            84,
            91,
            109,
            122,
            121,
            121,
            110,
            90,
            117,
            118,
            138,
            132,
            110,
            138,
            159,
            117,
            151,
            123,
            133,
            187,
            313,
            0,
            0,
            44, 
            103, 
            112, 
            112, 
            101, 
            140, 
            148 
           

        ]
    ],
    [
        [
            80649,
            64002,
            85298,
            68478,
            72986,
            89485,
            58156,
            95173,
            95166,
            112712,
            91179,
            106763,
            85091,
            58520,
            95491,
            89148,
            83285,
            53888,
            73171,
            114804,
            94259,
            103878,
            90891,
            91386,
            87905,
            103549,
            118384,
            99581,
            92793, 
            103106, 
            77346, 
            91916, 
            115223, 
            138573, 
            118306 
           

        ]
        , [
            46163,
            47311,
            55648,
            42230,
            34002,
            47227,
            55349,
            38756,
            48913,
            61473,
            64782,
            57830,
            49869,
            44669,
            51836,
            61798,
            52967,
            24614,
            45502,
            64641,
            66449,
            57777,
            59150,
            53409,
            57067,
            53573,
            60602,
            56444,
            59151,
            49538,
            53842,
            58521,
            50061,
            76633,
            78786
           


        ]
        , [
            372,
            659,
            206,
            33,
            120,
            149,
            7,
            49,
            130,
            310,
            314,
            4287,
            4175,
            68,
            178,
            74,
            371,
            68,
            874,
            7516,
            5354,
            5678,
            2252,
            183,
            19,
            319,
            110,
            230,
            433,
            586,
            135,
            404,
            261,
            207,
            202
           

        ]
        , [
            28763,
            27482,
            33139,
            26841,
            24363,
            28948,
            23510,
            27596,
            29057,
            35244,
            41197,
            36338,
            30621,
            25421,
            33604,
            31307,
            36218,
            17473,
            29406,
            37092,
            35319,
            31751,
            27980,
            28851,
            40366,
            41386,
            48464,
            39327,
            42235,
            37032,
            44534,
            40322,
            38360,
            50387,
            56030
           


        ]
        , [
            677,
            401,
            593,
            486,
            517,
            612,
            386,
            523,
            482,
            643,
            528,
            580,
            494,
            328,
            542,
            492,
            501,
            306,
            509,
            502,
            487,
            462,
            478,
            456,
            439,
            448,
            469,
            432,
            445,
            626,
            475,
            543,
            534,
            625,
            460


        ]
        , [
            68,
            118,
            94,
            87,
            66,
            77,
            143,
            74,
            101,
            96,
            123,
            100,
            101,
            136,
            96,
            126,
            106,
            80,
            89,
            129,
            136,
            125,
            124,
            117,
            130,
            120,
            129,
            131,
            133, 
            79, 
            113, 
            108, 
            94, 
            123, 
            171 
           

        ]
    ],
    [
        [
            68938,
            63175,
            90849,
            83421,
            78226,
            67988,
            73949,
            102931,
            87649,
            89763,
            64223,
            81304,
            66404,
            43778,
            90277,
            79900,
            59948,
            47030,
            71783,
            89423,
            76448,
            85793,
            80904,
            76047,
            33253,
            44946,
            56298,
            39827,
            35345, 
            59709, 
            33424, 
            34831, 
            49683, 
            50837, 
            52584 
           

        ]
        , [
            33682,
            41090,
            68396,
            51722,
            48007,
            53433,
            48190,
            50673,
            55947,
            59214,
            56288,
            41359,
            48925,
            41165,
            52652,
            53748,
            48652,
            32496,
            50473,
            64594,
            50804,
            46760,
            49613,
            54769,
            44642,
            23222,
            37804,
            26688,
            28129,
            25303,
            35853,
            28550,
            25335,
            28672,
            36488
           


        ]
        , [
            27,
            1,
            87,
            45,
            51,
            359,
            16,
            50,
            0,
            77,
            205,
            7,
            1822,
            75,
            161,
            0,
            90,
            173,
            741,
            6073,
            8998,
            6034,
            988,
            0,
            0,
            0,
            0,
            1282,
            4,
            59,
            72,
            61,
            145,
            260,
            86
           

        ]
        , [
            17141,
            22708,
            29321,
            26167,
            21534,
            24865,
            22483,
            22657,
            28092,
            28242,
            29027,
            20695,
            22744,
            18701,
            22165,
            25443,
            23254,
            15516,
            23614,
            29680,
            20863,
            18979,
            22541,
            21571,
            22921,
            11376,
            20261,
            12970,
            13393,
            12794,
            16616,
            14111,
            14322,
            19423,
            23685
           

        ]
        , [
            348,
            289,
            358,
            324,
            334,
            326,
            301,
            310,
            303,
            392,
            290,
            354,
            322,
            156,
            307,
            285,
            217,
            152,
            227,
            269,
            278,
            283,
            249,
            212,
            207,
            112,
            172,
            102,
            127,
            179,
            118,
            157,
            135,
            210,
            178


        ]
        , [
            97,
            142,
            191,
            160,
            144,
            164,
            160,
            163,
            185,
            151,
            194,
            117,
            152,
            264,
            172,
            189,
            224,
            214,
            222,
            240,
            183,
            165,
            199,
            258,
            216,
            207,
            220,
            262,
            221,
            141,
            304, 
            182, 
            188, 
            137, 
            205 
           

        ]
    ]
]