import React, { useState, useEffect } from 'react'
import { Row, Col, Button } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import RxRevenue from '../Images/RxRevenue.png'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import logo from '../Images/RxRevenue.png'
import Aos from 'aos'
import "aos/dist/aos.css";
import wave from '../Images/wave6.png'
const LoginPage = () => {
    const navigate = useNavigate()
    const [data, setData] = useState({
        name: '',
        password: ''
    })
    useEffect(() => {
        Aos.init({
            duration: 2000
        })
    }, [])
    const handleSubmit = (e) => {
        // alert(data.name)
        // console.log(data)
        if (data.name === '' || data.password === '') {
            return toast.warn('Fill the username and password')

        }
        if ((data.name === 'AuroraPainClinic' && data.password === 'APC@123') ||
            (data.name === 'PersonalPhysicians' && data.password === 'PPC@123') ||
            (data.name === 'RxRevenue' && data.password === 'RxRev@2023')
        ) {
            toast.success('Successfully logged in')
            sessionStorage.setItem('name', data.name === 'PersonalPhysicians' ? 'PersonalClinic'
                : data.name === 'RxRevenue' ? 'RX' : 'Aurora');
            setTimeout(() => {
                return navigate(`/dashboard?name=${data.name === 'RxRevenue' ? 'RX' : data.name}`, {
                    replace: true
                })
            }, 1000);
        } else {
            toast.error('Invalid Username / Password')
        }
    }
    const [showPassword, setShowPassword] = useState(false)
    return (
        <div
            style={{
                width: '100%', position: 'relative', right: '0px', top: '0px',
                height: '600px', background: `url(${wave})`,
                backgroundSize: 'cover', backgroundRepeat: 'no-repeat'
            }}>
            <Row style={{ height: '480px', width: '100%' }}
                className={`
            rounded d-flex  justify-content-center flex-row  w-100 
            `}
            >
                <Col md='6'
                    className={` mt-5 d-block justify-content-end flex-row`}

                >
                    <h1 data-Aos="slide-down" className="my-5 display-3 fw-bold ls-tight px-3" >
                        Welcome To <br />
                        <span style={{ color: '#377FC3' }}>Performance Dashboard</span>
                    </h1>
                    <p data-Aos="slide-right" className='px-3 ' style={{ color: 'black' }}>
                        With our mission to bring you successful practice.We guide you on your workflow and focus you
                        on your END-TO-END REVENUE CYCLE MANAGEMENT.<br />
                        Our billing team looks forward to working with you !
                    </p>
                    {/* <p data-Aos="slide-right" className='px-3' style={{ color: 'black' }}>
                        
                    </p> */}

                </Col>
                <Col style={{ borderRadius: '0px 25px 0px 25px' }}
                    data-Aos="zoom-in-up" md='3' className={` mt-5 m-2 bg-white shadow-lg `}>
                    <div className='position-relative top-50 start-50 translate-middle'>
                        <img
                            className='position-relative start-50 translate-middle-x'
                            style={{ height: '95px', width: '200px' }}
                            src={logo}
                            alt="Login Logo"
                        />
                        <h2 className='text-start' style={{ color: '#377FC3' }}>Login</h2>
                        <div>
                            <label className='text-dark fs-5'>Name</label>
                            <input type='text' className='form-control' value={data.name} onChange={(e) => setData({
                                ...data, name: e.target.value
                            })} placeholder='Enter the name' />
                        </div>
                        <div>
                            <label className='text-dark fs-5'>Password</label>
                            <input type={showPassword ? 'text' : 'password'} className='form-control' value={data.password} onChange={(e) => setData({
                                ...data, password: e.target.value
                            })} placeholder='Enter the password' />
                        </div>
                        <div className='d-flex mt-3'>
                            <input
                                type='checkbox'
                                onChange={() => setShowPassword(prev => !prev)}
                                className='form-check'
                            /><span className='mx-2'>Show password</span>
                        </div>
                        <div>
                            <Button
                                className='mt-4 btn text-primary border border-primary'
                                color='white'
                                onClick={(e) => handleSubmit(e)}
                            >
                                Login
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
            <ToastContainer autoClose={2000} position='top-right' />
        </div>
    )
}

export default LoginPage