import React, { useState } from 'react'
import DashCards from '../../DashBoardsCards/DashCards';
import Chart from 'react-apexcharts';
import { Row, Col, Button } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

import {
    // jan2021Series, feb2021Series, mar2021Series, apr2021Series, may2021Series
    // , june2021Series, jul2021Series, aug2021Series, sept2021Series, oct2021Series, nov2021Series, dec2021Series,
    // jan2020Series, feb2020Series, mar2020Series, apr2020Series, may2020Series
    // , june2020Series, jul2020Series, aug2020Series, sept2020Series, oct2020Series, nov2020Series, dec2020Series,
    oct2020SeriesPer, nov2020SeriesPer, dec2020SeriesPer, jan2021SeriesPer, feb2021SeriesPer, mar2021SeriesPer, apr2021SeriesPer, may2021SeriesPer
    , june2021SeriesPer, jul2021SeriesPer, aug2021SeriesPer, sept2021SeriesPer, oct2021SeriesPer, nov2021SeriesPer, dec2021SeriesPer,
    jan2022SeriesPer, feb2022SeriesPer, mar2022SeriesPer, apr2022SeriesPer, may2022SeriesPer, june2022SeriesPer, jul2022SeriesPer, aug2022SeriesPer,
    sept2022SeriesPer,
    oct2022SeriesPer,
    nov2022SeriesPer,
    dec2022SeriesPer,
    jan2023SeriesPer,
    feb2023SeriesPer,
    mar2023SeriesPer,
    apr2023SeriesPer,
    may2023SeriesPer,
    jun2023SeriesPer,
    jul2023SeriesPer,
    aug2023SeriesPer,
    sep2023SeriesPer,
    oct2023SeriesPer,
    nov2023SeriesPer,
    dec2023SeriesPer,
    jan2024SeriesPer,
    feb2024SeriesPer,
    mar2024SeriesPer,
    apr2024SeriesPer,
    may2024SeriesPer,
    jun2024SeriesPer,
    jul2024SeriesPer,
    aug2024SeriesPer

} from './series';
import { jan2022Series, feb2022Series, mar2022Series, apr2022Series, may2022Series, jun2022Series, jul2022Series, aug2022Series, sept2022Series, oct2022Series,
    nov2022Series,
    dec2022Series, } from './Series2022';
import { jan2023Series, feb2023Series, mar2023Series, apr2023Series, may2023Series, jun2023Series, jul2023Series, aug2023Series, sep2023Series, oct2023Series, nov2023Series , dec2023Series} from './Series2023'
import { jan2024Series , feb2024Series , mar2024Series , apr2024Series , may2024Series, jun2024Series, jul2024Series, aug2024Series } from './Series2024';
import {  firstChargesSAM, secondChargesSAM, thirdChargesSAM, fifthChargesSAM, sixthChargesSAM, ninethCharges, tenthCharges, eleventhCharges, sevethChargesSAM, eightChargesSAM, twelvethCharges, thirteenCharges, tenthChargesSAM, fourteenCharges, eleventhChargesSAM, fifteenCharges, twelvethChargesSAM, sixteenCharges,thirteenthChargesSAM,seventeenCharges,fourteenthChargesSAM , eighteenCharges , fifteenthChargesSAM, nineteenCharges, sixteenthChargesSAM } from '../Quarters/QuartersDropDown';
// firstCharges, secondCharges, thirdCharges, fourthCharges, fifthCharges, sixthCharges, seventhCharges, eighthCharges, 
// import { option, fromDate, toDates } from '../DropDown/GridDropDown';
import { providersArray, providersArraySAM } from './ProvidersArray';
import ModalLoader from '../../Loader/ModalLoader';
import { fourthChargesSAM, nineChargesSAM } from './../Quarters/QuartersDropDown';
import { apr2021SeriesRX, apr2022SeriesRX, apr2023SeriesRX, aug2021SeriesRX, aug2022SeriesRX, dec2021SeriesRX, dec2022SeriesRX, feb2021SeriesRX, feb2022SeriesRX, feb2023SeriesRX, jan2021SeriesRX, jan2022SeriesRX, jan2023SeriesRX, jul2021SeriesRX, jul2022SeriesRX, jun2022SeriesRX, june2021SeriesRX, mar2021SeriesRX, mar2022SeriesRX, mar2023SeriesRX, may2021SeriesRX, may2022SeriesRX, nov2021SeriesRX, nov2022SeriesRX, oct2021SeriesRX, oct2022SeriesRX, sept2021SeriesRX, sept2022SeriesRX, may2023SeriesRX, jun2023SeriesRX, jul2023SeriesRX, aug2023SeriesRX, sep2023SeriesRX, oct2023SeriesRX, nov2023SeriesRX } from './RX/MonthData';
import { eighthChargesRX, fifthChargesRX, firstChargesRX, fourthChargesRX, ninethChargesRX, secondChargesRX, seventhChargesRX, sixthChargesRX, tenthChargesRX, thirdChargesRX, eleventhChargesRX, twelvethChargesRX ,
    firQuarOf21InAll , secQuarOf21InAll , thiQuarOf21InAll , fouQuarOf21InAll , firQuarOf22InAll , secQuarOf22InAll , thiQuarOf22InAll , fouQuarOf22InAll , firQuarOf23InAll , secQuarOf23InAll , thiQuarOf23InAll , fouQuarOf23InAll ,
    firQuarOf21InPro1 , secQuarOf21InPro1 , thiQuarOf21InPro1 , fouQuarOf21InPro1 , firQuarOf22InPro1 , secQuarOf22InPro1 , thiQuarOf22InPro1 , fouQuarOf22InPro1 , firQuarOf23InPro1 , secQuarOf23InPro1 , thiQuarOf23InPro1 , fouQuarOf23InPro1 ,
    firQuarOf21InPro2 , secQuarOf21InPro2 , thiQuarOf21InPro2 , fouQuarOf21InPro2 , firQuarOf22InPro2 , secQuarOf22InPro2 , thiQuarOf22InPro2 , fouQuarOf22InPro2 , firQuarOf23InPro2 , secQuarOf23InPro2 , thiQuarOf23InPro2 , fouQuarOf23InPro2 ,
    firQuarOf21InPro3 , secQuarOf21InPro3 , thiQuarOf21InPro3 , fouQuarOf21InPro3 , firQuarOf22InPro3 , secQuarOf22InPro3 , thiQuarOf22InPro3 , fouQuarOf22InPro3 , firQuarOf23InPro3 , secQuarOf23InPro3 , thiQuarOf23InPro3 , fouQuarOf23InPro3 ,
    firQuarOf21InPro4 , secQuarOf21InPro4 , thiQuarOf21InPro4 , fouQuarOf21InPro4 , firQuarOf22InPro4 , secQuarOf22InPro4 , thiQuarOf22InPro4 , fouQuarOf22InPro4 , firQuarOf23InPro4 , secQuarOf23InPro4 , thiQuarOf23InPro4 , fouQuarOf23InPro4 ,
    firQuarOf21InPro5 , secQuarOf21InPro5 , thiQuarOf21InPro5 , fouQuarOf21InPro5 , firQuarOf22InPro5 , secQuarOf22InPro5 , thiQuarOf22InPro5 , fouQuarOf22InPro5 , firQuarOf23InPro5 , secQuarOf23InPro5 , thiQuarOf23InPro5 , fouQuarOf23InPro5 ,
    firQuarOf21InPro6 , secQuarOf21InPro6 , thiQuarOf21InPro6 , fouQuarOf21InPro6 , firQuarOf22InPro6 , secQuarOf22InPro6 , thiQuarOf22InPro6 , fouQuarOf22InPro6 , firQuarOf23InPro6 , secQuarOf23InPro6 , thiQuarOf23InPro6 , fouQuarOf23InPro6 ,
    firQuarOf21InPro7 , secQuarOf21InPro7 , thiQuarOf21InPro7 , fouQuarOf21InPro7 , firQuarOf22InPro7 , secQuarOf22InPro7 , thiQuarOf22InPro7 , fouQuarOf22InPro7 , firQuarOf23InPro7 , secQuarOf23InPro7 , thiQuarOf23InPro7 , fouQuarOf23InPro7 ,} from './RX/QuarterData';

import { allProviderChargesRX, allProviderClaimRX, allProviderCollectableRX, allProviderNonCollectableRX, allProviderPaymentRX, allProviderPervisitRX, provider1Charges, provider1Claim, provider1Collectable, provider1NonCollectable, provider1Payment, provider1PerVisist, provider2Charges, provider2Claim, provider2Collectable, provider2NonCollectable, provider2Payment, provider2PerVisist, provider3Charges, provider3Claim, provider3Collectable, provider3NonCollectable, provider3Payment, provider3PerVisist, provider4Charges, provider4Claim, provider4Collectable, provider4NonCollectable, provider4Payment, provider4PerVisist, provider5Charges, provider5Claim, provider5Collectable, provider5NonCollectable, provider5Payment, provider5PerVisist, provider6Charges, provider6Claim, provider6Collectable, provider6NonCollectable, provider6Payment, provider6PerVisist, provider7Charges, provider7Claim, provider7Collectable, provider7NonCollectable, provider7Payment, provider7PerVisist } from './RX/ProvidersAndFacility';

import { RXFromTo } from './RX/FromToData';
import {allF1Charge,allF1Payment,allF1Collectable,allF1NonCollectable,allF1ClaimCount,allF1PerVisit,
    allF2Charge,allF2Payment,allF2Collectable,allF2NonCollectable,allF2ClaimCount,allF2PerVisit,
    allF3Charge,allF3Payment,allF3Collectable,allF3NonCollectable,allF3ClaimCount,allF3PerVisit,
    pro1F1Charge,pro1F1Payment,pro1F1Collectable,pro1F1NonCollectable,pro1F1ClaimCount,pro1F1PerVisit,
    pro1F2Charge,pro1F2Payment,pro1F2Collectable,pro1F2NonCollectable,pro1F2ClaimCount,pro1F2PerVisit,
    pro1F3Charge,pro1F3Payment,pro1F3Collectable,pro1F3NonCollectable,pro1F3ClaimCount,pro1F3PerVisit,
    pro2F1Charge,pro2F1Payment,pro2F1Collectable,pro2F1NonCollectable,pro2F1ClaimCount,pro2F1PerVisit,
    pro2F2Charge,pro2F2Payment,pro2F2Collectable,pro2F2NonCollectable,pro2F2ClaimCount,pro2F2PerVisit,
    pro2F3Charge,pro2F3Payment,pro2F3Collectable,pro2F3NonCollectable,pro2F3ClaimCount,pro2F3PerVisit,
    pro3F1Charge,pro3F1Payment,pro3F1Collectable,pro3F1NonCollectable,pro3F1ClaimCount,pro3F1PerVisit,
    pro3F2Charge,pro3F2Payment,pro3F2Collectable,pro3F2NonCollectable,pro3F2ClaimCount,pro3F2PerVisit,
    pro3F3Charge,pro3F3Payment,pro3F3Collectable,pro3F3NonCollectable,pro3F3ClaimCount,pro3F3PerVisit,
    pro4F1Charge,pro4F1Payment,pro4F1Collectable,pro4F1NonCollectable,pro4F1ClaimCount,pro4F1PerVisit,
    pro4F2Charge,pro4F2Payment,pro4F2Collectable,pro4F2NonCollectable,pro4F2ClaimCount,pro4F2PerVisit,
    pro4F3Charge,pro4F3Payment,pro4F3Collectable,pro4F3NonCollectable,pro4F3ClaimCount,pro4F3PerVisit,
    pro5F1Charge,pro5F1Payment,pro5F1Collectable,pro5F1NonCollectable,pro5F1ClaimCount,pro5F1PerVisit,
    pro5F2Charge,pro5F2Payment,pro5F2Collectable,pro5F2NonCollectable,pro5F2ClaimCount,pro5F2PerVisit,
    pro5F3Charge,pro5F3Payment,pro5F3Collectable,pro5F3NonCollectable,pro5F3ClaimCount,pro5F3PerVisit,
    pro6F1Charge,pro6F1Payment,pro6F1Collectable,pro6F1NonCollectable,pro6F1ClaimCount,pro6F1PerVisit,
    pro6F2Charge,pro6F2Payment,pro6F2Collectable,pro6F2NonCollectable,pro6F2ClaimCount,pro6F2PerVisit,
    pro6F3Charge,pro6F3Payment,pro6F3Collectable,pro6F3NonCollectable,pro6F3ClaimCount,pro6F3PerVisit,
    pro7F1Charge,pro7F1Payment,pro7F1Collectable,pro7F1NonCollectable,pro7F1ClaimCount,pro7F1PerVisit,
    pro7F2Charge,pro7F2Payment,pro7F2Collectable,pro7F2NonCollectable,pro7F2ClaimCount,pro7F2PerVisit,
    pro7F3Charge,pro7F3Payment,pro7F3Collectable,pro7F3NonCollectable,pro7F3ClaimCount,pro7F3PerVisit,} from './RX/FacilityData';

import {allproWithJan21Charges , allproWithJan21Pay , allproWithJan21Count , allproWithJan21NonCount , allproWithJan21Claim , allproWithJan21PerVisit , allproWithFeb21Charges , allproWithFeb21Pay , allproWithFeb21Count , allproWithFeb21NonCount , allproWithFeb21Claim , allproWithFeb21PerVisit , allproWithMar21Charges , allproWithMar21Pay , allproWithMar21Count , allproWithMar21NonCount , allproWithMar21Claim , allproWithMar21PerVisit , allproWithApr21Charges , allproWithApr21Pay , allproWithApr21Count , allproWithApr21NonCount , allproWithApr21Claim , allproWithApr21PerVisit , allproWithMay21Charges , allproWithMay21Pay , allproWithMay21Count , allproWithMay21NonCount , allproWithMay21Claim , allproWithMay21PerVisit , allproWithJun21Charges , allproWithJun21Pay , allproWithJun21Count , allproWithJun21NonCount , allproWithJun21Claim , allproWithJun21PerVisit , allproWithJul21Charges , allproWithJul21Pay , allproWithJul21Count , allproWithJul21NonCount , allproWithJul21Claim , allproWithJul21PerVisit , allproWithAug21Charges , allproWithAug21Pay , allproWithAug21Count , allproWithAug21NonCount , allproWithAug21Claim , allproWithAug21PerVisit , allproWithSep21Charges , allproWithSep21Pay , allproWithSep21Count , allproWithSep21NonCount , allproWithSep21Claim , allproWithSep21PerVisit , allproWithOct21Charges , allproWithOct21Pay , allproWithOct21Count , allproWithOct21NonCount , allproWithOct21Claim , allproWithOct21PerVisit , allproWithNov21Charges , allproWithNov21Pay , allproWithNov21Count , allproWithNov21NonCount , allproWithNov21Claim , allproWithNov21PerVisit , allproWithDec21Charges , allproWithDec21Pay , allproWithDec21Count , allproWithDec21NonCount , allproWithDec21Claim , allproWithDec21PerVisit,
    allproWithJan22Charges , allproWithJan22Pay , allproWithJan22Count , allproWithJan22NonCount , allproWithJan22Claim , allproWithJan22PerVisit , allproWithFeb22Charges , allproWithFeb22Pay , allproWithFeb22Count , allproWithFeb22NonCount , allproWithFeb22Claim , allproWithFeb22PerVisit , allproWithMar22Charges , allproWithMar22Pay , allproWithMar22Count , allproWithMar22NonCount , allproWithMar22Claim , allproWithMar22PerVisit , allproWithApr22Charges , allproWithApr22Pay , allproWithApr22Count , allproWithApr22NonCount , allproWithApr22Claim , allproWithApr22PerVisit , allproWithMay22Charges , allproWithMay22Pay , allproWithMay22Count , allproWithMay22NonCount , allproWithMay22Claim , allproWithMay22PerVisit , allproWithJun22Charges , allproWithJun22Pay , allproWithJun22Count , allproWithJun22NonCount , allproWithJun22Claim , allproWithJun22PerVisit , allproWithJul22Charges , allproWithJul22Pay , allproWithJul22Count , allproWithJul22NonCount , allproWithJul22Claim , allproWithJul22PerVisit , allproWithAug22Charges , allproWithAug22Pay , allproWithAug22Count , allproWithAug22NonCount , allproWithAug22Claim , allproWithAug22PerVisit , allproWithSep22Charges , allproWithSep22Pay , allproWithSep22Count , allproWithSep22NonCount , allproWithSep22Claim , allproWithSep22PerVisit , allproWithOct22Charges , allproWithOct22Pay , allproWithOct22Count , allproWithOct22NonCount , allproWithOct22Claim , allproWithOct22PerVisit , allproWithNov22Charges , allproWithNov22Pay , allproWithNov22Count , allproWithNov22NonCount , allproWithNov22Claim , allproWithNov22PerVisit , allproWithDec22Charges , allproWithDec22Pay , allproWithDec22Count , allproWithDec22NonCount , allproWithDec22Claim , allproWithDec22PerVisit,
    allproWithJan23Charges , allproWithJan23Pay , allproWithJan23Count , allproWithJan23NonCount , allproWithJan23Claim , allproWithJan23PerVisit , allproWithFeb23Charges , allproWithFeb23Pay , allproWithFeb23Count , allproWithFeb23NonCount , allproWithFeb23Claim , allproWithFeb23PerVisit , allproWithMar23Charges , allproWithMar23Pay , allproWithMar23Count , allproWithMar23NonCount , allproWithMar23Claim , allproWithMar23PerVisit , allproWithApr23Charges , allproWithApr23Pay , allproWithApr23Count , allproWithApr23NonCount , allproWithApr23Claim , allproWithApr23PerVisit , allproWithMay23Charges , allproWithMay23Pay , allproWithMay23Count , allproWithMay23NonCount , allproWithMay23Claim , allproWithMay23PerVisit , allproWithJun23Charges , allproWithJun23Pay , allproWithJun23Count , allproWithJun23NonCount , allproWithJun23Claim , allproWithJun23PerVisit , allproWithJul23Charges , allproWithJul23Pay , allproWithJul23Count , allproWithJul23NonCount , allproWithJul23Claim , allproWithJul23PerVisit , allproWithAug23Charges , allproWithAug23Pay , allproWithAug23Count , allproWithAug23NonCount , allproWithAug23Claim , allproWithAug23PerVisit , allproWithSep23Charges , allproWithSep23Pay , allproWithSep23Count , allproWithSep23NonCount , allproWithSep23Claim , allproWithSep23PerVisit , allproWithOct23Charges , allproWithOct23Pay , allproWithOct23Count , allproWithOct23NonCount , allproWithOct23Claim , allproWithOct23PerVisit , allproWithNov23Charges , allproWithNov23Pay , allproWithNov23Count , allproWithNov23NonCount , allproWithNov23Claim , allproWithNov23PerVisit } from './RX/allProvidersWithMonthData';
import {pro1WithJan21Charges , pro1WithJan21Pay , pro1WithJan21Count , pro1WithJan21NonCount , pro1WithJan21Claim , pro1WithJan21PerVisit , pro1WithFeb21Charges , pro1WithFeb21Pay , pro1WithFeb21Count , pro1WithFeb21NonCount , pro1WithFeb21Claim , pro1WithFeb21PerVisit , pro1WithMar21Charges , pro1WithMar21Pay , pro1WithMar21Count , pro1WithMar21NonCount , pro1WithMar21Claim , pro1WithMar21PerVisit , pro1WithApr21Charges , pro1WithApr21Pay , pro1WithApr21Count , pro1WithApr21NonCount , pro1WithApr21Claim , pro1WithApr21PerVisit , pro1WithMay21Charges , pro1WithMay21Pay , pro1WithMay21Count , pro1WithMay21NonCount , pro1WithMay21Claim , pro1WithMay21PerVisit , pro1WithJun21Charges , pro1WithJun21Pay , pro1WithJun21Count , pro1WithJun21NonCount , pro1WithJun21Claim , pro1WithJun21PerVisit , pro1WithJul21Charges , pro1WithJul21Pay , pro1WithJul21Count , pro1WithJul21NonCount , pro1WithJul21Claim , pro1WithJul21PerVisit , pro1WithAug21Charges , pro1WithAug21Pay , pro1WithAug21Count , pro1WithAug21NonCount , pro1WithAug21Claim , pro1WithAug21PerVisit , pro1WithSep21Charges , pro1WithSep21Pay , pro1WithSep21Count , pro1WithSep21NonCount , pro1WithSep21Claim , pro1WithSep21PerVisit , pro1WithOct21Charges , pro1WithOct21Pay , pro1WithOct21Count , pro1WithOct21NonCount , pro1WithOct21Claim , pro1WithOct21PerVisit , pro1WithNov21Charges , pro1WithNov21Pay , pro1WithNov21Count , pro1WithNov21NonCount , pro1WithNov21Claim , pro1WithNov21PerVisit , pro1WithDec21Charges , pro1WithDec21Pay , pro1WithDec21Count , pro1WithDec21NonCount , pro1WithDec21Claim , pro1WithDec21PerVisit,
    pro1WithJan22Charges , pro1WithJan22Pay , pro1WithJan22Count , pro1WithJan22NonCount , pro1WithJan22Claim , pro1WithJan22PerVisit , pro1WithFeb22Charges , pro1WithFeb22Pay , pro1WithFeb22Count , pro1WithFeb22NonCount , pro1WithFeb22Claim , pro1WithFeb22PerVisit , pro1WithMar22Charges , pro1WithMar22Pay , pro1WithMar22Count , pro1WithMar22NonCount , pro1WithMar22Claim , pro1WithMar22PerVisit , pro1WithApr22Charges , pro1WithApr22Pay , pro1WithApr22Count , pro1WithApr22NonCount , pro1WithApr22Claim , pro1WithApr22PerVisit , pro1WithMay22Charges , pro1WithMay22Pay , pro1WithMay22Count , pro1WithMay22NonCount , pro1WithMay22Claim , pro1WithMay22PerVisit , pro1WithJun22Charges , pro1WithJun22Pay , pro1WithJun22Count , pro1WithJun22NonCount , pro1WithJun22Claim , pro1WithJun22PerVisit , pro1WithJul22Charges , pro1WithJul22Pay , pro1WithJul22Count , pro1WithJul22NonCount , pro1WithJul22Claim , pro1WithJul22PerVisit , pro1WithAug22Charges , pro1WithAug22Pay , pro1WithAug22Count , pro1WithAug22NonCount , pro1WithAug22Claim , pro1WithAug22PerVisit , pro1WithSep22Charges , pro1WithSep22Pay , pro1WithSep22Count , pro1WithSep22NonCount , pro1WithSep22Claim , pro1WithSep22PerVisit , pro1WithOct22Charges , pro1WithOct22Pay , pro1WithOct22Count , pro1WithOct22NonCount , pro1WithOct22Claim , pro1WithOct22PerVisit , pro1WithNov22Charges , pro1WithNov22Pay , pro1WithNov22Count , pro1WithNov22NonCount , pro1WithNov22Claim , pro1WithNov22PerVisit , pro1WithDec22Charges , pro1WithDec22Pay , pro1WithDec22Count , pro1WithDec22NonCount , pro1WithDec22Claim , pro1WithDec22PerVisit,
    pro1WithJan23Charges , pro1WithJan23Pay , pro1WithJan23Count , pro1WithJan23NonCount , pro1WithJan23Claim , pro1WithJan23PerVisit , pro1WithFeb23Charges , pro1WithFeb23Pay , pro1WithFeb23Count , pro1WithFeb23NonCount , pro1WithFeb23Claim , pro1WithFeb23PerVisit , pro1WithMar23Charges , pro1WithMar23Pay , pro1WithMar23Count , pro1WithMar23NonCount , pro1WithMar23Claim , pro1WithMar23PerVisit , pro1WithApr23Charges , pro1WithApr23Pay , pro1WithApr23Count , pro1WithApr23NonCount , pro1WithApr23Claim , pro1WithApr23PerVisit , pro1WithMay23Charges , pro1WithMay23Pay , pro1WithMay23Count , pro1WithMay23NonCount , pro1WithMay23Claim , pro1WithMay23PerVisit , pro1WithJun23Charges , pro1WithJun23Pay , pro1WithJun23Count , pro1WithJun23NonCount , pro1WithJun23Claim , pro1WithJun23PerVisit , pro1WithJul23Charges , pro1WithJul23Pay , pro1WithJul23Count , pro1WithJul23NonCount , pro1WithJul23Claim , pro1WithJul23PerVisit , pro1WithAug23Charges , pro1WithAug23Pay , pro1WithAug23Count , pro1WithAug23NonCount , pro1WithAug23Claim , pro1WithAug23PerVisit , pro1WithSep23Charges , pro1WithSep23Pay , pro1WithSep23Count , pro1WithSep23NonCount , pro1WithSep23Claim , pro1WithSep23PerVisit , pro1WithOct23Charges , pro1WithOct23Pay , pro1WithOct23Count , pro1WithOct23NonCount , pro1WithOct23Claim , pro1WithOct23PerVisit , pro1WithNov23Charges , pro1WithNov23Pay , pro1WithNov23Count , pro1WithNov23NonCount , pro1WithNov23Claim , pro1WithNov23PerVisit } from './RX/provider1WithMonthData'
import {pro2WithJan21Charges , pro2WithJan21Pay , pro2WithJan21Count , pro2WithJan21NonCount , pro2WithJan21Claim , pro2WithJan21PerVisit , pro2WithFeb21Charges , pro2WithFeb21Pay , pro2WithFeb21Count , pro2WithFeb21NonCount , pro2WithFeb21Claim , pro2WithFeb21PerVisit , pro2WithMar21Charges , pro2WithMar21Pay , pro2WithMar21Count , pro2WithMar21NonCount , pro2WithMar21Claim , pro2WithMar21PerVisit , pro2WithApr21Charges , pro2WithApr21Pay , pro2WithApr21Count , pro2WithApr21NonCount , pro2WithApr21Claim , pro2WithApr21PerVisit , pro2WithMay21Charges , pro2WithMay21Pay , pro2WithMay21Count , pro2WithMay21NonCount , pro2WithMay21Claim , pro2WithMay21PerVisit , pro2WithJun21Charges , pro2WithJun21Pay , pro2WithJun21Count , pro2WithJun21NonCount , pro2WithJun21Claim , pro2WithJun21PerVisit , pro2WithJul21Charges , pro2WithJul21Pay , pro2WithJul21Count , pro2WithJul21NonCount , pro2WithJul21Claim , pro2WithJul21PerVisit , pro2WithAug21Charges , pro2WithAug21Pay , pro2WithAug21Count , pro2WithAug21NonCount , pro2WithAug21Claim , pro2WithAug21PerVisit , pro2WithSep21Charges , pro2WithSep21Pay , pro2WithSep21Count , pro2WithSep21NonCount , pro2WithSep21Claim , pro2WithSep21PerVisit , pro2WithOct21Charges , pro2WithOct21Pay , pro2WithOct21Count , pro2WithOct21NonCount , pro2WithOct21Claim , pro2WithOct21PerVisit , pro2WithNov21Charges , pro2WithNov21Pay , pro2WithNov21Count , pro2WithNov21NonCount , pro2WithNov21Claim , pro2WithNov21PerVisit , pro2WithDec21Charges , pro2WithDec21Pay , pro2WithDec21Count , pro2WithDec21NonCount , pro2WithDec21Claim , pro2WithDec21PerVisit,
    pro2WithJan22Charges , pro2WithJan22Pay , pro2WithJan22Count , pro2WithJan22NonCount , pro2WithJan22Claim , pro2WithJan22PerVisit , pro2WithFeb22Charges , pro2WithFeb22Pay , pro2WithFeb22Count , pro2WithFeb22NonCount , pro2WithFeb22Claim , pro2WithFeb22PerVisit , pro2WithMar22Charges , pro2WithMar22Pay , pro2WithMar22Count , pro2WithMar22NonCount , pro2WithMar22Claim , pro2WithMar22PerVisit , pro2WithApr22Charges , pro2WithApr22Pay , pro2WithApr22Count , pro2WithApr22NonCount , pro2WithApr22Claim , pro2WithApr22PerVisit , pro2WithMay22Charges , pro2WithMay22Pay , pro2WithMay22Count , pro2WithMay22NonCount , pro2WithMay22Claim , pro2WithMay22PerVisit , pro2WithJun22Charges , pro2WithJun22Pay , pro2WithJun22Count , pro2WithJun22NonCount , pro2WithJun22Claim , pro2WithJun22PerVisit , pro2WithJul22Charges , pro2WithJul22Pay , pro2WithJul22Count , pro2WithJul22NonCount , pro2WithJul22Claim , pro2WithJul22PerVisit , pro2WithAug22Charges , pro2WithAug22Pay , pro2WithAug22Count , pro2WithAug22NonCount , pro2WithAug22Claim , pro2WithAug22PerVisit , pro2WithSep22Charges , pro2WithSep22Pay , pro2WithSep22Count , pro2WithSep22NonCount , pro2WithSep22Claim , pro2WithSep22PerVisit , pro2WithOct22Charges , pro2WithOct22Pay , pro2WithOct22Count , pro2WithOct22NonCount , pro2WithOct22Claim , pro2WithOct22PerVisit , pro2WithNov22Charges , pro2WithNov22Pay , pro2WithNov22Count , pro2WithNov22NonCount , pro2WithNov22Claim , pro2WithNov22PerVisit , pro2WithDec22Charges , pro2WithDec22Pay , pro2WithDec22Count , pro2WithDec22NonCount , pro2WithDec22Claim , pro2WithDec22PerVisit,
    pro2WithJan23Charges , pro2WithJan23Pay , pro2WithJan23Count , pro2WithJan23NonCount , pro2WithJan23Claim , pro2WithJan23PerVisit , pro2WithFeb23Charges , pro2WithFeb23Pay , pro2WithFeb23Count , pro2WithFeb23NonCount , pro2WithFeb23Claim , pro2WithFeb23PerVisit , pro2WithMar23Charges , pro2WithMar23Pay , pro2WithMar23Count , pro2WithMar23NonCount , pro2WithMar23Claim , pro2WithMar23PerVisit , pro2WithApr23Charges , pro2WithApr23Pay , pro2WithApr23Count , pro2WithApr23NonCount , pro2WithApr23Claim , pro2WithApr23PerVisit , pro2WithMay23Charges , pro2WithMay23Pay , pro2WithMay23Count , pro2WithMay23NonCount , pro2WithMay23Claim , pro2WithMay23PerVisit , pro2WithJun23Charges , pro2WithJun23Pay , pro2WithJun23Count , pro2WithJun23NonCount , pro2WithJun23Claim , pro2WithJun23PerVisit , pro2WithJul23Charges , pro2WithJul23Pay , pro2WithJul23Count , pro2WithJul23NonCount , pro2WithJul23Claim , pro2WithJul23PerVisit , pro2WithAug23Charges , pro2WithAug23Pay , pro2WithAug23Count , pro2WithAug23NonCount , pro2WithAug23Claim , pro2WithAug23PerVisit , pro2WithSep23Charges , pro2WithSep23Pay , pro2WithSep23Count , pro2WithSep23NonCount , pro2WithSep23Claim , pro2WithSep23PerVisit , pro2WithOct23Charges , pro2WithOct23Pay , pro2WithOct23Count , pro2WithOct23NonCount , pro2WithOct23Claim , pro2WithOct23PerVisit , pro2WithNov23Charges , pro2WithNov23Pay , pro2WithNov23Count , pro2WithNov23NonCount , pro2WithNov23Claim , pro2WithNov23PerVisit } from './RX/provider2WithMonthData'
import {pro3WithJan21Charges , pro3WithJan21Pay , pro3WithJan21Count , pro3WithJan21NonCount , pro3WithJan21Claim , pro3WithJan21PerVisit , pro3WithFeb21Charges , pro3WithFeb21Pay , pro3WithFeb21Count , pro3WithFeb21NonCount , pro3WithFeb21Claim , pro3WithFeb21PerVisit , pro3WithMar21Charges , pro3WithMar21Pay , pro3WithMar21Count , pro3WithMar21NonCount , pro3WithMar21Claim , pro3WithMar21PerVisit , pro3WithApr21Charges , pro3WithApr21Pay , pro3WithApr21Count , pro3WithApr21NonCount , pro3WithApr21Claim , pro3WithApr21PerVisit , pro3WithMay21Charges , pro3WithMay21Pay , pro3WithMay21Count , pro3WithMay21NonCount , pro3WithMay21Claim , pro3WithMay21PerVisit , pro3WithJun21Charges , pro3WithJun21Pay , pro3WithJun21Count , pro3WithJun21NonCount , pro3WithJun21Claim , pro3WithJun21PerVisit , pro3WithJul21Charges , pro3WithJul21Pay , pro3WithJul21Count , pro3WithJul21NonCount , pro3WithJul21Claim , pro3WithJul21PerVisit , pro3WithAug21Charges , pro3WithAug21Pay , pro3WithAug21Count , pro3WithAug21NonCount , pro3WithAug21Claim , pro3WithAug21PerVisit , pro3WithSep21Charges , pro3WithSep21Pay , pro3WithSep21Count , pro3WithSep21NonCount , pro3WithSep21Claim , pro3WithSep21PerVisit , pro3WithOct21Charges , pro3WithOct21Pay , pro3WithOct21Count , pro3WithOct21NonCount , pro3WithOct21Claim , pro3WithOct21PerVisit , pro3WithNov21Charges , pro3WithNov21Pay , pro3WithNov21Count , pro3WithNov21NonCount , pro3WithNov21Claim , pro3WithNov21PerVisit , pro3WithDec21Charges , pro3WithDec21Pay , pro3WithDec21Count , pro3WithDec21NonCount , pro3WithDec21Claim , pro3WithDec21PerVisit,
    pro3WithJan22Charges , pro3WithJan22Pay , pro3WithJan22Count , pro3WithJan22NonCount , pro3WithJan22Claim , pro3WithJan22PerVisit , pro3WithFeb22Charges , pro3WithFeb22Pay , pro3WithFeb22Count , pro3WithFeb22NonCount , pro3WithFeb22Claim , pro3WithFeb22PerVisit , pro3WithMar22Charges , pro3WithMar22Pay , pro3WithMar22Count , pro3WithMar22NonCount , pro3WithMar22Claim , pro3WithMar22PerVisit , pro3WithApr22Charges , pro3WithApr22Pay , pro3WithApr22Count , pro3WithApr22NonCount , pro3WithApr22Claim , pro3WithApr22PerVisit , pro3WithMay22Charges , pro3WithMay22Pay , pro3WithMay22Count , pro3WithMay22NonCount , pro3WithMay22Claim , pro3WithMay22PerVisit , pro3WithJun22Charges , pro3WithJun22Pay , pro3WithJun22Count , pro3WithJun22NonCount , pro3WithJun22Claim , pro3WithJun22PerVisit , pro3WithJul22Charges , pro3WithJul22Pay , pro3WithJul22Count , pro3WithJul22NonCount , pro3WithJul22Claim , pro3WithJul22PerVisit , pro3WithAug22Charges , pro3WithAug22Pay , pro3WithAug22Count , pro3WithAug22NonCount , pro3WithAug22Claim , pro3WithAug22PerVisit , pro3WithSep22Charges , pro3WithSep22Pay , pro3WithSep22Count , pro3WithSep22NonCount , pro3WithSep22Claim , pro3WithSep22PerVisit , pro3WithOct22Charges , pro3WithOct22Pay , pro3WithOct22Count , pro3WithOct22NonCount , pro3WithOct22Claim , pro3WithOct22PerVisit , pro3WithNov22Charges , pro3WithNov22Pay , pro3WithNov22Count , pro3WithNov22NonCount , pro3WithNov22Claim , pro3WithNov22PerVisit , pro3WithDec22Charges , pro3WithDec22Pay , pro3WithDec22Count , pro3WithDec22NonCount , pro3WithDec22Claim , pro3WithDec22PerVisit,
    pro3WithJan23Charges , pro3WithJan23Pay , pro3WithJan23Count , pro3WithJan23NonCount , pro3WithJan23Claim , pro3WithJan23PerVisit , pro3WithFeb23Charges , pro3WithFeb23Pay , pro3WithFeb23Count , pro3WithFeb23NonCount , pro3WithFeb23Claim , pro3WithFeb23PerVisit , pro3WithMar23Charges , pro3WithMar23Pay , pro3WithMar23Count , pro3WithMar23NonCount , pro3WithMar23Claim , pro3WithMar23PerVisit , pro3WithApr23Charges , pro3WithApr23Pay , pro3WithApr23Count , pro3WithApr23NonCount , pro3WithApr23Claim , pro3WithApr23PerVisit , pro3WithMay23Charges , pro3WithMay23Pay , pro3WithMay23Count , pro3WithMay23NonCount , pro3WithMay23Claim , pro3WithMay23PerVisit , pro3WithJun23Charges , pro3WithJun23Pay , pro3WithJun23Count , pro3WithJun23NonCount , pro3WithJun23Claim , pro3WithJun23PerVisit , pro3WithJul23Charges , pro3WithJul23Pay , pro3WithJul23Count , pro3WithJul23NonCount , pro3WithJul23Claim , pro3WithJul23PerVisit , pro3WithAug23Charges , pro3WithAug23Pay , pro3WithAug23Count , pro3WithAug23NonCount , pro3WithAug23Claim , pro3WithAug23PerVisit , pro3WithSep23Charges , pro3WithSep23Pay , pro3WithSep23Count , pro3WithSep23NonCount , pro3WithSep23Claim , pro3WithSep23PerVisit , pro3WithOct23Charges , pro3WithOct23Pay , pro3WithOct23Count , pro3WithOct23NonCount , pro3WithOct23Claim , pro3WithOct23PerVisit , pro3WithNov23Charges , pro3WithNov23Pay , pro3WithNov23Count , pro3WithNov23NonCount , pro3WithNov23Claim , pro3WithNov23PerVisit } from './RX/provider3WithMonthData'
import {pro4WithJan21Charges , pro4WithJan21Pay , pro4WithJan21Count , pro4WithJan21NonCount , pro4WithJan21Claim , pro4WithJan21PerVisit , pro4WithFeb21Charges , pro4WithFeb21Pay , pro4WithFeb21Count , pro4WithFeb21NonCount , pro4WithFeb21Claim , pro4WithFeb21PerVisit , pro4WithMar21Charges , pro4WithMar21Pay , pro4WithMar21Count , pro4WithMar21NonCount , pro4WithMar21Claim , pro4WithMar21PerVisit , pro4WithApr21Charges , pro4WithApr21Pay , pro4WithApr21Count , pro4WithApr21NonCount , pro4WithApr21Claim , pro4WithApr21PerVisit , pro4WithMay21Charges , pro4WithMay21Pay , pro4WithMay21Count , pro4WithMay21NonCount , pro4WithMay21Claim , pro4WithMay21PerVisit , pro4WithJun21Charges , pro4WithJun21Pay , pro4WithJun21Count , pro4WithJun21NonCount , pro4WithJun21Claim , pro4WithJun21PerVisit , pro4WithJul21Charges , pro4WithJul21Pay , pro4WithJul21Count , pro4WithJul21NonCount , pro4WithJul21Claim , pro4WithJul21PerVisit , pro4WithAug21Charges , pro4WithAug21Pay , pro4WithAug21Count , pro4WithAug21NonCount , pro4WithAug21Claim , pro4WithAug21PerVisit , pro4WithSep21Charges , pro4WithSep21Pay , pro4WithSep21Count , pro4WithSep21NonCount , pro4WithSep21Claim , pro4WithSep21PerVisit , pro4WithOct21Charges , pro4WithOct21Pay , pro4WithOct21Count , pro4WithOct21NonCount , pro4WithOct21Claim , pro4WithOct21PerVisit , pro4WithNov21Charges , pro4WithNov21Pay , pro4WithNov21Count , pro4WithNov21NonCount , pro4WithNov21Claim , pro4WithNov21PerVisit , pro4WithDec21Charges , pro4WithDec21Pay , pro4WithDec21Count , pro4WithDec21NonCount , pro4WithDec21Claim , pro4WithDec21PerVisit,
    pro4WithJan22Charges , pro4WithJan22Pay , pro4WithJan22Count , pro4WithJan22NonCount , pro4WithJan22Claim , pro4WithJan22PerVisit , pro4WithFeb22Charges , pro4WithFeb22Pay , pro4WithFeb22Count , pro4WithFeb22NonCount , pro4WithFeb22Claim , pro4WithFeb22PerVisit , pro4WithMar22Charges , pro4WithMar22Pay , pro4WithMar22Count , pro4WithMar22NonCount , pro4WithMar22Claim , pro4WithMar22PerVisit , pro4WithApr22Charges , pro4WithApr22Pay , pro4WithApr22Count , pro4WithApr22NonCount , pro4WithApr22Claim , pro4WithApr22PerVisit , pro4WithMay22Charges , pro4WithMay22Pay , pro4WithMay22Count , pro4WithMay22NonCount , pro4WithMay22Claim , pro4WithMay22PerVisit , pro4WithJun22Charges , pro4WithJun22Pay , pro4WithJun22Count , pro4WithJun22NonCount , pro4WithJun22Claim , pro4WithJun22PerVisit , pro4WithJul22Charges , pro4WithJul22Pay , pro4WithJul22Count , pro4WithJul22NonCount , pro4WithJul22Claim , pro4WithJul22PerVisit , pro4WithAug22Charges , pro4WithAug22Pay , pro4WithAug22Count , pro4WithAug22NonCount , pro4WithAug22Claim , pro4WithAug22PerVisit , pro4WithSep22Charges , pro4WithSep22Pay , pro4WithSep22Count , pro4WithSep22NonCount , pro4WithSep22Claim , pro4WithSep22PerVisit , pro4WithOct22Charges , pro4WithOct22Pay , pro4WithOct22Count , pro4WithOct22NonCount , pro4WithOct22Claim , pro4WithOct22PerVisit , pro4WithNov22Charges , pro4WithNov22Pay , pro4WithNov22Count , pro4WithNov22NonCount , pro4WithNov22Claim , pro4WithNov22PerVisit , pro4WithDec22Charges , pro4WithDec22Pay , pro4WithDec22Count , pro4WithDec22NonCount , pro4WithDec22Claim , pro4WithDec22PerVisit,
    pro4WithJan23Charges , pro4WithJan23Pay , pro4WithJan23Count , pro4WithJan23NonCount , pro4WithJan23Claim , pro4WithJan23PerVisit , pro4WithFeb23Charges , pro4WithFeb23Pay , pro4WithFeb23Count , pro4WithFeb23NonCount , pro4WithFeb23Claim , pro4WithFeb23PerVisit , pro4WithMar23Charges , pro4WithMar23Pay , pro4WithMar23Count , pro4WithMar23NonCount , pro4WithMar23Claim , pro4WithMar23PerVisit , pro4WithApr23Charges , pro4WithApr23Pay , pro4WithApr23Count , pro4WithApr23NonCount , pro4WithApr23Claim , pro4WithApr23PerVisit , pro4WithMay23Charges , pro4WithMay23Pay , pro4WithMay23Count , pro4WithMay23NonCount , pro4WithMay23Claim , pro4WithMay23PerVisit , pro4WithJun23Charges , pro4WithJun23Pay , pro4WithJun23Count , pro4WithJun23NonCount , pro4WithJun23Claim , pro4WithJun23PerVisit , pro4WithJul23Charges , pro4WithJul23Pay , pro4WithJul23Count , pro4WithJul23NonCount , pro4WithJul23Claim , pro4WithJul23PerVisit , pro4WithAug23Charges , pro4WithAug23Pay , pro4WithAug23Count , pro4WithAug23NonCount , pro4WithAug23Claim , pro4WithAug23PerVisit , pro4WithSep23Charges , pro4WithSep23Pay , pro4WithSep23Count , pro4WithSep23NonCount , pro4WithSep23Claim , pro4WithSep23PerVisit , pro4WithOct23Charges , pro4WithOct23Pay , pro4WithOct23Count , pro4WithOct23NonCount , pro4WithOct23Claim , pro4WithOct23PerVisit , pro4WithNov23Charges , pro4WithNov23Pay , pro4WithNov23Count , pro4WithNov23NonCount , pro4WithNov23Claim , pro4WithNov23PerVisit } from './RX/provider4WithMonthData'
import {pro5WithJan21Charges , pro5WithJan21Pay , pro5WithJan21Count , pro5WithJan21NonCount , pro5WithJan21Claim , pro5WithJan21PerVisit , pro5WithFeb21Charges , pro5WithFeb21Pay , pro5WithFeb21Count , pro5WithFeb21NonCount , pro5WithFeb21Claim , pro5WithFeb21PerVisit , pro5WithMar21Charges , pro5WithMar21Pay , pro5WithMar21Count , pro5WithMar21NonCount , pro5WithMar21Claim , pro5WithMar21PerVisit , pro5WithApr21Charges , pro5WithApr21Pay , pro5WithApr21Count , pro5WithApr21NonCount , pro5WithApr21Claim , pro5WithApr21PerVisit , pro5WithMay21Charges , pro5WithMay21Pay , pro5WithMay21Count , pro5WithMay21NonCount , pro5WithMay21Claim , pro5WithMay21PerVisit , pro5WithJun21Charges , pro5WithJun21Pay , pro5WithJun21Count , pro5WithJun21NonCount , pro5WithJun21Claim , pro5WithJun21PerVisit , pro5WithJul21Charges , pro5WithJul21Pay , pro5WithJul21Count , pro5WithJul21NonCount , pro5WithJul21Claim , pro5WithJul21PerVisit , pro5WithAug21Charges , pro5WithAug21Pay , pro5WithAug21Count , pro5WithAug21NonCount , pro5WithAug21Claim , pro5WithAug21PerVisit , pro5WithSep21Charges , pro5WithSep21Pay , pro5WithSep21Count , pro5WithSep21NonCount , pro5WithSep21Claim , pro5WithSep21PerVisit , pro5WithOct21Charges , pro5WithOct21Pay , pro5WithOct21Count , pro5WithOct21NonCount , pro5WithOct21Claim , pro5WithOct21PerVisit , pro5WithNov21Charges , pro5WithNov21Pay , pro5WithNov21Count , pro5WithNov21NonCount , pro5WithNov21Claim , pro5WithNov21PerVisit , pro5WithDec21Charges , pro5WithDec21Pay , pro5WithDec21Count , pro5WithDec21NonCount , pro5WithDec21Claim , pro5WithDec21PerVisit,
    pro5WithJan22Charges , pro5WithJan22Pay , pro5WithJan22Count , pro5WithJan22NonCount , pro5WithJan22Claim , pro5WithJan22PerVisit , pro5WithFeb22Charges , pro5WithFeb22Pay , pro5WithFeb22Count , pro5WithFeb22NonCount , pro5WithFeb22Claim , pro5WithFeb22PerVisit , pro5WithMar22Charges , pro5WithMar22Pay , pro5WithMar22Count , pro5WithMar22NonCount , pro5WithMar22Claim , pro5WithMar22PerVisit , pro5WithApr22Charges , pro5WithApr22Pay , pro5WithApr22Count , pro5WithApr22NonCount , pro5WithApr22Claim , pro5WithApr22PerVisit , pro5WithMay22Charges , pro5WithMay22Pay , pro5WithMay22Count , pro5WithMay22NonCount , pro5WithMay22Claim , pro5WithMay22PerVisit , pro5WithJun22Charges , pro5WithJun22Pay , pro5WithJun22Count , pro5WithJun22NonCount , pro5WithJun22Claim , pro5WithJun22PerVisit , pro5WithJul22Charges , pro5WithJul22Pay , pro5WithJul22Count , pro5WithJul22NonCount , pro5WithJul22Claim , pro5WithJul22PerVisit , pro5WithAug22Charges , pro5WithAug22Pay , pro5WithAug22Count , pro5WithAug22NonCount , pro5WithAug22Claim , pro5WithAug22PerVisit , pro5WithSep22Charges , pro5WithSep22Pay , pro5WithSep22Count , pro5WithSep22NonCount , pro5WithSep22Claim , pro5WithSep22PerVisit , pro5WithOct22Charges , pro5WithOct22Pay , pro5WithOct22Count , pro5WithOct22NonCount , pro5WithOct22Claim , pro5WithOct22PerVisit , pro5WithNov22Charges , pro5WithNov22Pay , pro5WithNov22Count , pro5WithNov22NonCount , pro5WithNov22Claim , pro5WithNov22PerVisit , pro5WithDec22Charges , pro5WithDec22Pay , pro5WithDec22Count , pro5WithDec22NonCount , pro5WithDec22Claim , pro5WithDec22PerVisit,
    pro5WithJan23Charges , pro5WithJan23Pay , pro5WithJan23Count , pro5WithJan23NonCount , pro5WithJan23Claim , pro5WithJan23PerVisit , pro5WithFeb23Charges , pro5WithFeb23Pay , pro5WithFeb23Count , pro5WithFeb23NonCount , pro5WithFeb23Claim , pro5WithFeb23PerVisit , pro5WithMar23Charges , pro5WithMar23Pay , pro5WithMar23Count , pro5WithMar23NonCount , pro5WithMar23Claim , pro5WithMar23PerVisit , pro5WithApr23Charges , pro5WithApr23Pay , pro5WithApr23Count , pro5WithApr23NonCount , pro5WithApr23Claim , pro5WithApr23PerVisit , pro5WithMay23Charges , pro5WithMay23Pay , pro5WithMay23Count , pro5WithMay23NonCount , pro5WithMay23Claim , pro5WithMay23PerVisit , pro5WithJun23Charges , pro5WithJun23Pay , pro5WithJun23Count , pro5WithJun23NonCount , pro5WithJun23Claim , pro5WithJun23PerVisit , pro5WithJul23Charges , pro5WithJul23Pay , pro5WithJul23Count , pro5WithJul23NonCount , pro5WithJul23Claim , pro5WithJul23PerVisit , pro5WithAug23Charges , pro5WithAug23Pay , pro5WithAug23Count , pro5WithAug23NonCount , pro5WithAug23Claim , pro5WithAug23PerVisit , pro5WithSep23Charges , pro5WithSep23Pay , pro5WithSep23Count , pro5WithSep23NonCount , pro5WithSep23Claim , pro5WithSep23PerVisit , pro5WithOct23Charges , pro5WithOct23Pay , pro5WithOct23Count , pro5WithOct23NonCount , pro5WithOct23Claim , pro5WithOct23PerVisit , pro5WithNov23Charges , pro5WithNov23Pay , pro5WithNov23Count , pro5WithNov23NonCount , pro5WithNov23Claim , pro5WithNov23PerVisit } from './RX/provider5WithMonthData'
import {pro6WithJan21Charges , pro6WithJan21Pay , pro6WithJan21Count , pro6WithJan21NonCount , pro6WithJan21Claim , pro6WithJan21PerVisit , pro6WithFeb21Charges , pro6WithFeb21Pay , pro6WithFeb21Count , pro6WithFeb21NonCount , pro6WithFeb21Claim , pro6WithFeb21PerVisit , pro6WithMar21Charges , pro6WithMar21Pay , pro6WithMar21Count , pro6WithMar21NonCount , pro6WithMar21Claim , pro6WithMar21PerVisit , pro6WithApr21Charges , pro6WithApr21Pay , pro6WithApr21Count , pro6WithApr21NonCount , pro6WithApr21Claim , pro6WithApr21PerVisit , pro6WithMay21Charges , pro6WithMay21Pay , pro6WithMay21Count , pro6WithMay21NonCount , pro6WithMay21Claim , pro6WithMay21PerVisit , pro6WithJun21Charges , pro6WithJun21Pay , pro6WithJun21Count , pro6WithJun21NonCount , pro6WithJun21Claim , pro6WithJun21PerVisit , pro6WithJul21Charges , pro6WithJul21Pay , pro6WithJul21Count , pro6WithJul21NonCount , pro6WithJul21Claim , pro6WithJul21PerVisit , pro6WithAug21Charges , pro6WithAug21Pay , pro6WithAug21Count , pro6WithAug21NonCount , pro6WithAug21Claim , pro6WithAug21PerVisit , pro6WithSep21Charges , pro6WithSep21Pay , pro6WithSep21Count , pro6WithSep21NonCount , pro6WithSep21Claim , pro6WithSep21PerVisit , pro6WithOct21Charges , pro6WithOct21Pay , pro6WithOct21Count , pro6WithOct21NonCount , pro6WithOct21Claim , pro6WithOct21PerVisit , pro6WithNov21Charges , pro6WithNov21Pay , pro6WithNov21Count , pro6WithNov21NonCount , pro6WithNov21Claim , pro6WithNov21PerVisit , pro6WithDec21Charges , pro6WithDec21Pay , pro6WithDec21Count , pro6WithDec21NonCount , pro6WithDec21Claim , pro6WithDec21PerVisit,
    pro6WithJan22Charges , pro6WithJan22Pay , pro6WithJan22Count , pro6WithJan22NonCount , pro6WithJan22Claim , pro6WithJan22PerVisit , pro6WithFeb22Charges , pro6WithFeb22Pay , pro6WithFeb22Count , pro6WithFeb22NonCount , pro6WithFeb22Claim , pro6WithFeb22PerVisit , pro6WithMar22Charges , pro6WithMar22Pay , pro6WithMar22Count , pro6WithMar22NonCount , pro6WithMar22Claim , pro6WithMar22PerVisit , pro6WithApr22Charges , pro6WithApr22Pay , pro6WithApr22Count , pro6WithApr22NonCount , pro6WithApr22Claim , pro6WithApr22PerVisit , pro6WithMay22Charges , pro6WithMay22Pay , pro6WithMay22Count , pro6WithMay22NonCount , pro6WithMay22Claim , pro6WithMay22PerVisit , pro6WithJun22Charges , pro6WithJun22Pay , pro6WithJun22Count , pro6WithJun22NonCount , pro6WithJun22Claim , pro6WithJun22PerVisit , pro6WithJul22Charges , pro6WithJul22Pay , pro6WithJul22Count , pro6WithJul22NonCount , pro6WithJul22Claim , pro6WithJul22PerVisit , pro6WithAug22Charges , pro6WithAug22Pay , pro6WithAug22Count , pro6WithAug22NonCount , pro6WithAug22Claim , pro6WithAug22PerVisit , pro6WithSep22Charges , pro6WithSep22Pay , pro6WithSep22Count , pro6WithSep22NonCount , pro6WithSep22Claim , pro6WithSep22PerVisit , pro6WithOct22Charges , pro6WithOct22Pay , pro6WithOct22Count , pro6WithOct22NonCount , pro6WithOct22Claim , pro6WithOct22PerVisit , pro6WithNov22Charges , pro6WithNov22Pay , pro6WithNov22Count , pro6WithNov22NonCount , pro6WithNov22Claim , pro6WithNov22PerVisit , pro6WithDec22Charges , pro6WithDec22Pay , pro6WithDec22Count , pro6WithDec22NonCount , pro6WithDec22Claim , pro6WithDec22PerVisit,
    pro6WithJan23Charges , pro6WithJan23Pay , pro6WithJan23Count , pro6WithJan23NonCount , pro6WithJan23Claim , pro6WithJan23PerVisit , pro6WithFeb23Charges , pro6WithFeb23Pay , pro6WithFeb23Count , pro6WithFeb23NonCount , pro6WithFeb23Claim , pro6WithFeb23PerVisit , pro6WithMar23Charges , pro6WithMar23Pay , pro6WithMar23Count , pro6WithMar23NonCount , pro6WithMar23Claim , pro6WithMar23PerVisit , pro6WithApr23Charges , pro6WithApr23Pay , pro6WithApr23Count , pro6WithApr23NonCount , pro6WithApr23Claim , pro6WithApr23PerVisit , pro6WithMay23Charges , pro6WithMay23Pay , pro6WithMay23Count , pro6WithMay23NonCount , pro6WithMay23Claim , pro6WithMay23PerVisit , pro6WithJun23Charges , pro6WithJun23Pay , pro6WithJun23Count , pro6WithJun23NonCount , pro6WithJun23Claim , pro6WithJun23PerVisit , pro6WithJul23Charges , pro6WithJul23Pay , pro6WithJul23Count , pro6WithJul23NonCount , pro6WithJul23Claim , pro6WithJul23PerVisit , pro6WithAug23Charges , pro6WithAug23Pay , pro6WithAug23Count , pro6WithAug23NonCount , pro6WithAug23Claim , pro6WithAug23PerVisit , pro6WithSep23Charges , pro6WithSep23Pay , pro6WithSep23Count , pro6WithSep23NonCount , pro6WithSep23Claim , pro6WithSep23PerVisit , pro6WithOct23Charges , pro6WithOct23Pay , pro6WithOct23Count , pro6WithOct23NonCount , pro6WithOct23Claim , pro6WithOct23PerVisit , pro6WithNov23Charges , pro6WithNov23Pay , pro6WithNov23Count , pro6WithNov23NonCount , pro6WithNov23Claim , pro6WithNov23PerVisit } from './RX/provider6WithMonthData'
import {pro7WithJan21Charges , pro7WithJan21Pay , pro7WithJan21Count , pro7WithJan21NonCount , pro7WithJan21Claim , pro7WithJan21PerVisit , pro7WithFeb21Charges , pro7WithFeb21Pay , pro7WithFeb21Count , pro7WithFeb21NonCount , pro7WithFeb21Claim , pro7WithFeb21PerVisit , pro7WithMar21Charges , pro7WithMar21Pay , pro7WithMar21Count , pro7WithMar21NonCount , pro7WithMar21Claim , pro7WithMar21PerVisit , pro7WithApr21Charges , pro7WithApr21Pay , pro7WithApr21Count , pro7WithApr21NonCount , pro7WithApr21Claim , pro7WithApr21PerVisit , pro7WithMay21Charges , pro7WithMay21Pay , pro7WithMay21Count , pro7WithMay21NonCount , pro7WithMay21Claim , pro7WithMay21PerVisit , pro7WithJun21Charges , pro7WithJun21Pay , pro7WithJun21Count , pro7WithJun21NonCount , pro7WithJun21Claim , pro7WithJun21PerVisit , pro7WithJul21Charges , pro7WithJul21Pay , pro7WithJul21Count , pro7WithJul21NonCount , pro7WithJul21Claim , pro7WithJul21PerVisit , pro7WithAug21Charges , pro7WithAug21Pay , pro7WithAug21Count , pro7WithAug21NonCount , pro7WithAug21Claim , pro7WithAug21PerVisit , pro7WithSep21Charges , pro7WithSep21Pay , pro7WithSep21Count , pro7WithSep21NonCount , pro7WithSep21Claim , pro7WithSep21PerVisit , pro7WithOct21Charges , pro7WithOct21Pay , pro7WithOct21Count , pro7WithOct21NonCount , pro7WithOct21Claim , pro7WithOct21PerVisit , pro7WithNov21Charges , pro7WithNov21Pay , pro7WithNov21Count , pro7WithNov21NonCount , pro7WithNov21Claim , pro7WithNov21PerVisit , pro7WithDec21Charges , pro7WithDec21Pay , pro7WithDec21Count , pro7WithDec21NonCount , pro7WithDec21Claim , pro7WithDec21PerVisit,
    pro7WithJan22Charges , pro7WithJan22Pay , pro7WithJan22Count , pro7WithJan22NonCount , pro7WithJan22Claim , pro7WithJan22PerVisit , pro7WithFeb22Charges , pro7WithFeb22Pay , pro7WithFeb22Count , pro7WithFeb22NonCount , pro7WithFeb22Claim , pro7WithFeb22PerVisit , pro7WithMar22Charges , pro7WithMar22Pay , pro7WithMar22Count , pro7WithMar22NonCount , pro7WithMar22Claim , pro7WithMar22PerVisit , pro7WithApr22Charges , pro7WithApr22Pay , pro7WithApr22Count , pro7WithApr22NonCount , pro7WithApr22Claim , pro7WithApr22PerVisit , pro7WithMay22Charges , pro7WithMay22Pay , pro7WithMay22Count , pro7WithMay22NonCount , pro7WithMay22Claim , pro7WithMay22PerVisit , pro7WithJun22Charges , pro7WithJun22Pay , pro7WithJun22Count , pro7WithJun22NonCount , pro7WithJun22Claim , pro7WithJun22PerVisit , pro7WithJul22Charges , pro7WithJul22Pay , pro7WithJul22Count , pro7WithJul22NonCount , pro7WithJul22Claim , pro7WithJul22PerVisit , pro7WithAug22Charges , pro7WithAug22Pay , pro7WithAug22Count , pro7WithAug22NonCount , pro7WithAug22Claim , pro7WithAug22PerVisit , pro7WithSep22Charges , pro7WithSep22Pay , pro7WithSep22Count , pro7WithSep22NonCount , pro7WithSep22Claim , pro7WithSep22PerVisit , pro7WithOct22Charges , pro7WithOct22Pay , pro7WithOct22Count , pro7WithOct22NonCount , pro7WithOct22Claim , pro7WithOct22PerVisit , pro7WithNov22Charges , pro7WithNov22Pay , pro7WithNov22Count , pro7WithNov22NonCount , pro7WithNov22Claim , pro7WithNov22PerVisit , pro7WithDec22Charges , pro7WithDec22Pay , pro7WithDec22Count , pro7WithDec22NonCount , pro7WithDec22Claim , pro7WithDec22PerVisit,
    pro7WithJan23Charges , pro7WithJan23Pay , pro7WithJan23Count , pro7WithJan23NonCount , pro7WithJan23Claim , pro7WithJan23PerVisit , pro7WithFeb23Charges , pro7WithFeb23Pay , pro7WithFeb23Count , pro7WithFeb23NonCount , pro7WithFeb23Claim , pro7WithFeb23PerVisit , pro7WithMar23Charges , pro7WithMar23Pay , pro7WithMar23Count , pro7WithMar23NonCount , pro7WithMar23Claim , pro7WithMar23PerVisit , pro7WithApr23Charges , pro7WithApr23Pay , pro7WithApr23Count , pro7WithApr23NonCount , pro7WithApr23Claim , pro7WithApr23PerVisit , pro7WithMay23Charges , pro7WithMay23Pay , pro7WithMay23Count , pro7WithMay23NonCount , pro7WithMay23Claim , pro7WithMay23PerVisit , pro7WithJun23Charges , pro7WithJun23Pay , pro7WithJun23Count , pro7WithJun23NonCount , pro7WithJun23Claim , pro7WithJun23PerVisit , pro7WithJul23Charges , pro7WithJul23Pay , pro7WithJul23Count , pro7WithJul23NonCount , pro7WithJul23Claim , pro7WithJul23PerVisit , pro7WithAug23Charges , pro7WithAug23Pay , pro7WithAug23Count , pro7WithAug23NonCount , pro7WithAug23Claim , pro7WithAug23PerVisit , pro7WithSep23Charges , pro7WithSep23Pay , pro7WithSep23Count , pro7WithSep23NonCount , pro7WithSep23Claim , pro7WithSep23PerVisit , pro7WithOct23Charges , pro7WithOct23Pay , pro7WithOct23Count , pro7WithOct23NonCount , pro7WithOct23Claim , pro7WithOct23PerVisit , pro7WithNov23Charges , pro7WithNov23Pay , pro7WithNov23Count , pro7WithNov23NonCount , pro7WithNov23Claim , pro7WithNov23PerVisit } from './RX/provider7WithMonthData'
import ProvidersAndFacility from './RX/ProvidersAndFacility';

// import {providersInMonth} from './RX/ProvidersInMonthData'; 
// import {providersInMonth} from './RX/providerWithMonth';
let NewProvider = () => {
    let from = [
        // 'Jan-2020', 'Feb-2020', 'Mar-2020',
        // 'Apr-2020', 'May-2020', 'Jun-2020', 'July-2020', 'Aug-2020', 'Sep-2020', 'Oct-2020', 'Nov-2020', 'Dec-2020',
        // 'Jan-2021', 'Feb-2021', 'Mar-2021',
        // 'Apr-2021', 'May-2021', 'Jun-2021', 'July-2021', 'Aug-2021', 'Sep-2021', 'Oct-2021', 'Nov-2021', 'Dec-2021',
        'Jan-2022', 'Feb-2022', 'Mar-2022', 'Apr-2022', 'May-2022', 'Jun-2022','Jul-2022','Aug-2022',
        'Sept-2022', 'Oct-2022', 'Nov-2022', 'Dec-2022', 'Jan-2023', 'Feb-2023',
        'Mar-2023', 'Apr-2023','May-2023','Jun-2023','Jul-2023','Aug-2023','Sep-2023','Oct-2023','Nov-2023','Dec-2023','Jan-2024','Feb-2024','Mar-2024','Apr-2024','May-2024',
        'Jun-2024','Jul-2024','Aug-2024'
    ]
    let to = [
        // 'Oct-2020', 'Nov-2020', 'Dec-2020',
        // 'Jan-2021', 'Feb-2021', 'Mar-2021',
        // 'Apr-2021', 'May-2021', 'Jun-2021', 'July-2021', 'Aug-2021', 'Sep-2021', 'Oct-2021', 'Nov-2021', 'Dec-2021',
        'Jan-2022', 'Feb-2022', 'Mar-2022', 'Apr-2022', 'May-2022', 'Jun-2022', 'Jul-2022', 'Aug-2022', 'Sept-2022', 'Oct-2022', 'Nov-2022',
        'Dec-2022', 'Jan-2023', 'Feb-2023', 'Mar-2023', 'Apr-2023','May-2023','Jun-2023','Jul-2023','Aug-2023','Sep-2023','Oct-2023','Nov-2023','Dec-2023',
        'Jan-2024','Feb-2024','Mar-2024','Apr-2024','May-2024','Jun-2024','Jul-2024','Aug-2024'
    ]
    let fromRX = [
        'Jan-2021', 'Feb-2021', 'Mar-2021',
        'Apr-2021', 'May-2021', 'Jun-2021', 'July-2021', 'Aug-2021', 'Sep-2021', 'Oct-2021', 'Nov-2021', 'Dec-2021',
        'Jan-2022', 'Feb-2022', 'Mar-2022', 'Apr-2022', 'May-2022', 'Jun-2022', 'Jul-2022', 'Aug-2022', 'Sept-2022',
        'Oct-2022', 'Nov-2022', 'Dec-2022', 'Jan-2023', 'Feb-2023', 'Mar-2023', 'Apr-2023', 'May-2023', 'Jun-2023',
        'Jul-2023', 'Aug-2023', 'Sep-2023', 'Oct-2023', 'Nov-2023']
    let toRX = [
        'Jan-2021', 'Feb-2021', 'Mar-2021',
        'Apr-2021', 'May-2021', 'Jun-2021', 'July-2021', 'Aug-2021', 'Sep-2021', 'Oct-2021', 'Nov-2021', 'Dec-2021',
        'Jan-2022', 'Feb-2022', 'Mar-2022', 'Apr-2022', 'May-2022', 'Jun-2022', 'Jul-2022', 'Aug-2022', 'Sept-2022', 'Oct-2022', 'Nov-2022',
        'Dec-2022', 'Jan-2023', 'Feb-2023', 'Mar-2023', 'Apr-2023', 'May-2023', 'Jun-2023', 'Jul-2023', 'Aug-2023', 'Sep-2023', 'Oct-2023', 'Nov-2023'
    ]

    let fromDate = sessionStorage.getItem('name') === 'Aurora' ? from :
        sessionStorage.getItem('name') === 'PersonalClinic' ?
            to : fromRX
    let toDates = sessionStorage.getItem('name') === 'Aurora' ? from : sessionStorage.getItem('name') === 'PersonalClinic' ?
        to : toRX
    let monthsForInitialLoad = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ]
    let [session,] = useState(sessionStorage.getItem('name'))
    // console.log('name is  ', session, fromDate, toDates, monthsForInitialLoad[new Date().getUTCMonth()] + '-' + new Date().getFullYear().toString().substring(2))
    let [isShow, setisShow] = useState(false)
    let [lastReceivedMonthRecord,] = useState('Aug-24')/// Aurora latest month record 
    let [lastReceivedMonthRecordFromPersonal,] = useState('Aug-24')/// Personal latest month record
    let [lastReceivedMonthRecordfromRx,] = useState('Nov-23')/// Rx latest month record
    let [fromToDateChart, setfromToDateChart] = useState(false)
    let [showModal, setShowModal] = useState(false)
    let [showborder, setshowborder] = useState(false)
    let [showOverAll, setshowOverAll] = useState(false)
    let [Providers, setProvider] = useState('All Providers')
    let [Facility, setFacility] = useState("All Facilities")

    let [Month, setMonth] = useState(sessionStorage.getItem('name') === 'PersonalClinic' ? lastReceivedMonthRecordFromPersonal !== monthsForInitialLoad[new Date().getUTCMonth()] + '-' + new Date().getFullYear() ? lastReceivedMonthRecordFromPersonal : monthsForInitialLoad[new Date().getUTCMonth()] + '-' + new Date().getFullYear().toString().substring(2)
    : sessionStorage.getItem('name') === 'RX' ? lastReceivedMonthRecordfromRx !== monthsForInitialLoad[new Date().getUTCMonth()] + '-' + new Date().getFullYear() ? lastReceivedMonthRecordfromRx : monthsForInitialLoad[new Date().getUTCMonth()] + '-' + new Date().getFullYear().toString().substring(2) 
    : lastReceivedMonthRecord !== monthsForInitialLoad[new Date().getUTCMonth()] + '-' + new Date().getFullYear() ? lastReceivedMonthRecord : monthsForInitialLoad[new Date().getUTCMonth()] + '-' + new Date().getFullYear().toString().substring(2) )

    // let [Month, setMonth] = useState(lastReceivedMonthRecord !== monthsForInitialLoad[new Date().getUTCMonth()] + '-' + new Date().getFullYear() ? lastReceivedMonthRecord : monthsForInitialLoad[new Date().getUTCMonth()] + '-' + new Date().getFullYear().toString().substring(2))

    let [quarters, setQuarters] = useState('')
    let [changeBar, setHorizontal] = useState(true)
    let [provider, setProviderFromdate] = useState(0)
    let [data, setData] = useState([])
    let [month, setMonthFromdate] = useState([])
    let [fromdate, setFromDate] = useState('')
    let [todate, setToDate] = useState('')
    let [fromdateTemp, setFromDateTemp] = useState('')
    let [todateTemp, setToDateTemp] = useState('')
    let [providerTemp, setproviderTemp] = useState('')
    console.log("Find",lastReceivedMonthRecord !== monthsForInitialLoad[new Date().getUTCMonth()] + '-' + new Date().getFullYear() ? lastReceivedMonthRecord : monthsForInitialLoad[new Date().getUTCMonth()] + '-' + new Date().getFullYear().toString())
    let allCharges =
        [
            3769217
        ]
    let allChargesAver =[
        117788
    ]
    let allChargesSAM =
        [
            481027.46,
            361328.7,
            339807.05,
            343423.9,
            350187.12,
            340015.59,
            448290.63,
            369072.98,
            383310.2,
            383559.4,
            513055.18,
            460196.14,
            419307.83,
            424729.61,
            507144.53,
            396490.89,
            474574.8,
            484657.35,
            433521.88,
            489582.85,
            534181.68,
            532560.22,
            503639.82,
            517108.16,
            408448.25,
            393186.5,
            640308.33,
            433608.5,
            446029.93,
            499110.98,
            431042.78,
            504639.18,
            
        ]
    let allPayments = [
        932886

    ]
    let allPaymentsAver = [
        29153
    ] 
    let allPaymentsSAM = [
        132281.25,
        127658.26,
        140846.73,
        114010.36,
        128554.98,
        119664.64,
        116741.31,
        151057.68,
        123677.05,
        131443.24,
        160411.36,
        173369.27,
        127059.66,
        134731.89,
        146741.15,
        130246.14,
        160108.61,
        142970.45,
        138886.25,
        143517.76,
        159041.02,
        167155.18,
        168352.96,
        178280.39,
        145511.88,
        122979.68,
        136401.86,
        171985.37,
        146113.96,
        144902.29,
        157512.41,
        174682.05,
        
    ]
    let allCollect = [
        204409

    ]
    let allCollectAver = [
        6388
    ]
    let allCollectSAM = [
        12976.26,
        15927.64,
        27177.06,
        16010.36,
        20250.95,
        22415.47,
        18907.77,
        13921.79,
        39252.34,
        17936.93,
        13962.15,
        13739.24,
        10738.54,
        10373.08,
        22275.55,
        15223.74,
        17184.43,
        9233.63,
        14114.94,
        16346.81,
        16799.57,
        19598.7,
        15381.24,
        25988,
        15316.32,
        17398.36,
        20331.5,
        12055.78,
        14356.71,
        38012.28,
        19254.69,
        18129.42,
        
    ]
    let allnonCollect = [
        2846599

    ]
    let allnonCollectAver = [
        88956
    ]
    let allnonCollectSAM = [
        220749.63,
        277241.96,
        231527.47,
        215654.6,
        238270.43,
        238439.69,
        215326.95,
        277140.36,
        217085.76,
        227762.59,
        271115.52,
        311539.68,
        246540.05,
        275458.61,
        303015.07,
        252830.64,
        326893.41,
        294057.17,
        281989.05,
        281353.32,
        309174.02,
        326653.6,
        338502.86,
        324309.25,
        287804.41,
        274631.32,
        290482.91,
        363295.53,
        302770.81,
        282554.81,
        300238.39,
        352260.46,
        
    ]
    let allClaims = [
        3291
    ]
    let allClaimsAver = [
        103
    ]
    let allClaimsSAM = [
        1378,
        1298,
        1024,
        1043,
        1089,
        1052,
        1323,
        1091,
        1186,
        1029,
        1524,
        1519,
        1183,
        2513,
        1336,
        1151,
        1215,
        1280,
        1158,
        1468,
        1412,
        1443,
        1285,
        1410,
        1261,
        1138,
        1746,
        1200,
        1223,
        1449,
        1234,
        1405,
        
    ]
    let allVisit = [
        9394
    ]
    let allVisitAver = [
        294
    ]
    let allVisitSAM = [
        95.9951015965167,
        98.349969183359,
        137.545634765625,
        109.310028763183,
        118.048650137741,
        113.749657794677,
        88.2398412698413,
        138.458001833181,
        104.280817875211,
        127.738814382896,
        105.256797900262,
        114.133818301514,
        107.404615384615,
        53.6139633903701,
        109.836190119761,
        113.159113814075,
        131.776633744856,
        111.6956640625,
        119.936312607945,
        98.29,
        112.635283286119,
        115.838655578656,
        131.013976653696,
        126.439992907801,
        115.394036478985,
        108.066502636204,
        78.1224856815579,
        143.321141666667,
        119.4717579722,
        100.001580400276,
        127.643768233387,
        124.328861209964,
        
    ]
    ///PROVIDER 1 PERSONAL CLINIC
    let pro1Charges = [
        0,
        70,
        2052,
        0,
        2528,
        1866,
        1311,
        1575,
        415,
        5114,
        1133,
        555,
        420,
        914.77,
        1313,
        60,
        620,
        1813,
        0,
        269,
        1400,
        3304,
        2814,
        2082,
        1225,
        24396,
        1225,
        1599,
        3199,
        5834,
        1835,
        4510,
        
    ]
    let pro1Payments = [
        0,
        0,
        305,
        0,
        475,
        315,
        0,
        115,
        35,
        170,
        190,
        95,
        70,
        145,
        0,
        0,
        45,
        145,
        0,
        0,
        153.87,
        410,
        65,
        15,
        98,
        480,
        0,
        50,
        15,
        65,
        65,
        175,
        
    ]
    let pro1Collect = [
        0,
        210,
        1642,
        0,
        2053,
        1296,
        608,
        0,
        0,
        998,
        1332,
        105,
        70,
        0,
        550,
        5145,
        1261,
        739,
        719,
        1676,
        0,
        1389,
        210,
        843.77,
        190,
        195,
        0,
        35,
        134,
        0,
        4902,
        841,
        
    ]
    let pro1NonCollect = [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        14.29,
        0,
        
    ]
    let pro1Claims = [
        0,
        2,
        8,
        0,
        7,
        6,
        4,
        14,
        4,
        7,
        2,
        9,
        2,
        2,
        8,
        2,
        2,
        8,
        0,
        9,
        9,
        15,
        9,
        18,
        11,
        83,
        10,
        14,
        15,
        18,
        8,
        8,
        
    ]
    let pro1Visit = [
        0,
        0,
        38.125,
        0,
        67.8571428571429,
        52.5,
        0,
        8.21428571428571,
        8.75,
        24.2857142857143,
        95,
        10.5555555555556,
        35,
        72.5,
        0,
        0,
        22.5,
        18.125,
        0,
        0,
        17.0966666666667,
        27.3333333333333,
        7.22222222222222,
        0.833333333333333,
        8.90909090909091,
        5.78313253012048,
        0,
        3.57142857142857,
        1,
        3.61111111111111,
        8.125,
        21.875,
        
    ]
    ///PROVIDER 2 PERSONAL CLINIC

    let pro2Charges = [
        239640.24,
        145875.37,
        115499.95,
        96620.91,
        118862.01,
        118594.7,
        221632.93,
        144934.07,
        125124.15,
        138402.08,
        229697.46,
        158689.37,
        109061.96,
        171356.91,
        157914.53,
        136543.24,
        192194.74,
        181819.17,
        137178.64,
        133587.29,
        199977.13,
        175655.81,
        114457.01,
        154163.25,
        111439.6,
        121562.01,
        230544.15,
        135735.2,
        126100.58,
        164262.53,
        134043.42,
        140416.48,
        
    ]
    let pro2Payments = [
        45825.51,
        54400.43,
        65955.13,
        35527.73,
        42978.85,
        38409.23,
        55366.57,
        57841.12,
        40816.56,
        44603.81,
        57823.35,
        63533.04,
        41653.26,
        43640.19,
        53453.43,
        45658,
        61371.26,
        56157.32,
        53951.09,
        46988.98,
        46006.67,
        64737.39,
        49035.5,
        52828.66,
        45549.45,
        38503.57,
        46531.06,
        65978.75,
        49887.86,
        54136.79,
        53496.91,
        58956.39,
        
    ]
    let pro2Collect = [
        4354.95,
        7465.48,
        13074.35,
        6117.93,
        10263.56,
        9243.95,
        10357.5,
        10870.57,
        13491.9,
        8136.22,
        4019.72,
        7043.47,
        3099.34,
        3841.82,
        8525.19,
        6653.66,
        7064.52,
        3397.42,
        7509.54,
        4779.23,
        7435.37,
        8733.23,
        4338.12,
        9173.74,
        7317.43,
        6022.08,
        5482.27,
        3877.22,
        10326.81,
        27357.03,
        6075.78,
        4204.37,
        
    ]
    let pro2NonCollect = [
        89438.36,
        116736.61,
        94249.74,
        63043.81,
        84986.71,
        78980.25,
        109259.44,
        110700.32,
        74318.44,
        76331.07,
        103644.65,
        128367.69,
        84112.18,
        89741.84,
        110151.77,
        77239.15,
        113227.21,
        96467.55,
        99595.44,
        85242.14,
        82890.26,
        119496.74,
        90438.2,
        82774.03,
        78420.63,
        84446.55,
        88870.67,
        124751.96,
        81274.07,
        90690.72,
        86230.57,
        103627.94,
        
    ]
    let pro2Claims = [
        714,
        592,
        385,
        335,
        406,
        371,
        675,
        443,
        494,
        461,
        778,
        656,
        383,
        680,
        504,
        479,
        514,
        569,
        445,
        551,
        581,
        537,
        371,
        515,
        468,
        413,
        820,
        492,
        408,
        586,
        477,
        509,
        
    ]
    let pro2Visit = [
        64.1813865546219,
        91.8926182432432,
        171.312025974026,
        106.052925373134,
        105.859236453202,
        103.528921832884,
        82.0245481481481,
        130.566862302483,
        82.6246153846154,
        96.7544685466377,
        74.3230719794344,
        96.8491463414634,
        108.755248041775,
        64.17675,
        106.058392857143,
        95.3194154488518,
        119.399338521401,
        98.694762741652,
        121.238404494382,
        85.2794555353902,
        79.1853184165232,
        120.553798882682,
        132.17115902965,
        102.579922330097,
        97.3278846153846,
        93.2289830508475,
        56.7451951219512,
        134.103150406504,
        122.274166666667,
        92.3836006825939,
        112.15285115304,
        115.827878192534,
        
    ]

    //PROVIDER 3 PERSONAL CLINIC
    //3
    let pro3Charges = [
        104415,
        83841.01,
        91654.01,
        116275.2,
        89558.96,
        98343.37,
        99543.49,
        93434.8,
        111757.36,
        81466.38,
        132710.72,
        129251.58,
        114683,
        124785.07,
        150652,
        120066.11,
        144756.03,
        149093.55,
        128109.45,
        160201.86,
        138504.99,
        136507.13,
        161449.15,
        150551.31,
        135810.46,
        100282.83,
        182684.11,
        136693.74,
        132491.42,
        130377.45,
        101557.54,
        174253.63,
        
    ]
    let pro3Payments = [
        32057.61,
        31151.23,
        31169.33,
        31851.57,
        35667.87,
        36485.18,
        27063.4,
        42242.25,
        35373.81,
        29874.49,
        43835.88,
        48475.33,
        34535.4,
        38741.09,
        45434.05,
        39199.13,
        43616.09,
        39139.53,
        38254.34,
        41936.99,
        46657.2,
        40489.63,
        51395.53,
        52111.25,
        40501.77,
        34453.31,
        34733.3,
        47529.73,
        41941.87,
        34964.83,
        41298.06,
        45813.3,
        
    ]
    let pro3Collect = [
        4289.61,
        3410.67,
        4861.54,
        3250.86,
        1609.74,
        6297.62,
        3825.82,
        1219.44,
        14738.19,
        4604.03,
        3489.46,
        3858,
        3300.32,
        2355.69,
        5702.75,
        1415.99,
        4215.84,
        2954.88,
        1598.36,
        3371.42,
        2383.13,
        3329.32,
        1618.38,
        3440.76,
        2754.59,
        3017.92,
        3513.87,
        2710.45,
        1251.69,
        4168.66,
        2875.29,
        9702.91,
        
    ]
    let pro3NonCollect = [
        57538.84,
        66240.02,
        62684.13,
        58959.78,
        68711.19,
        69205.17,
        44910.93,
        79560.49,
        59323.21,
        54958.65,
        73467.73,
        82711.61,
        59367.26,
        79933.65,
        94836.99,
        78365.35,
        99783.35,
        97024.41,
        84784.36,
        94889.63,
        100340.96,
        81256.47,
        113450.26,
        104642.07,
        93983.15,
        77781.28,
        91174.02,
        120128.02,
        99902.02,
        69055.07,
        86162.16,
        100882.89,
        
    ]
    let pro3Claims = [
        354,
        306,
        300,
        358,
        283,
        341,
        330,
        298,
        347,
        241,
        399,
        417,
        355,
        1149,
        393,
        330,
        361,
        346,
        336,
        401,
        313,
        346,
        390,
        350,
        354,
        254,
        383,
        321,
        359,
        338,
        260,
        435,
        
    ]
    let pro3Visit = [
        90.558220338983,
        101.801405228758,
        103.897766666667,
        88.9708659217877,
        126.034876325088,
        106.994662756598,
        82.010303030303,
        141.752516778523,
        101.94181556196,
        123.960539419087,
        109.864360902256,
        116.247793764988,
        97.2828169014085,
        33.7172236727589,
        115.608269720102,
        118.785242424242,
        120.820193905817,
        113.120028901734,
        113.852202380952,
        104.58102244389,
        149.064536741214,
        117.022052023121,
        131.78341025641,
        148.889285714286,
        114.411779661017,
        135.642952755905,
        90.6874673629243,
        148.06769470405,
        116.829721448468,
        103.44624260355,
        158.838692307692,
        105.317931034483,
        
    ]
    
    //PROVIDER 4 PERSONAL CLINIC
    //4
    let pro4Charges = [
        137862.22,
        131972.32,
        131361.09,
        131018.79,
        141023.15,
        121211.52,
        125803.21,
        129129.11,
        146013.69,
        158576.94,
        149514,
        171700.19,
        195142.87,
        127672.86,
        197265,
        139821.54,
        137004.03,
        151931.63,
        168233.79,
        160449.71,
        161200.61,
        175414.41,
        192400.61,
        182890.31,
        150921.81,
        110749.66,
        192533.78,
        138205.87,
        162060.78,
        174053.4,
        172816.19,
        169461.22,
        
    ]
    let pro4Payments = [
        54438.4,
        41956.73,
        43432.27,
        46661.06,
        49868.26,
        44341.63,
        34354.5,
        50891.46,
        47443.46,
        56920.03,
        58572.54,
        61250.9,
        50777.38,
        52215.28,
        47853.67,
        45389.01,
        54679.7,
        47528.6,
        46680.82,
        52989.17,
        54673.18,
        50973.84,
        59741.33,
        61655.44,
        54807.19,
        42780.18,
        46121.77,
        48947.03,
        46356.86,
        48510.67,
        54092.62,
        62749.59,
        
    ]
    let pro4Collect = [
        4251.7,
        5116.64,
        7469.46,
        6462.57,
        5882.92,
        5602.9,
        4116.45,
        1831.78,
        11072.25,
        4198.68,
        5120.97,
        2732.77,
        4268.88,
        4175.57,
        7497.61,
        1667.8,
        4642.55,
        2142.33,
        4288.04,
        5812.16,
        5443.07,
        4700.06,
        7937.44,
        10464.74,
        4310.3,
        6703.23,
        3939.75,
        4533.5,
        2507.12,
        5498.59,
        4518.73,
        3990.64,
        
    ]
    let pro4NonCollect = [
        74003.69,
        94476.04,
        74805.49,
        93966.01,
        84592.53,
        90254.27,
        61098.42,
        86847.4,
        83444.11,
        96332.78,
        94003.14,
        100460.38,
        103060.61,
        105748.45,
        98026.31,
        97226.14,
        113346.11,
        100565.21,
        97609.25,
        98831.39,
        106264.25,
        103354.68,
        117190.88,
        117183.2,
        108746.69,
        97957.48,
        95044.81,
        99909.65,
        106948.01,
        107966.69,
        108648.49,
        133065.63,
        
    ]
    let pro4Claims = [
        310,
        398,
        331,
        351,
        394,
        334,
        314,
        336,
        341,
        320,
        345,
        437,
        443,
        682,
        431,
        340,
        338,
        357,
        377,
        422,
        429,
        412,
        425,
        442,
        396,
        289,
        446,
        317,
        380,
        430,
        433,
        402,
        
    ]
    let pro4Visit = [
        175.607741935484,
        105.41891959799,
        131.215317220544,
        132.937492877493,
        126.569187817259,
        132.759371257485,
        109.40923566879,
        151.462678571429,
        139.130381231672,
        177.87509375,
        169.77547826087,
        140.162242562929,
        114.621625282167,
        76.5619941348974,
        111.02939675174,
        133.497088235294,
        161.77426035503,
        133.133333333333,
        123.821803713528,
        125.566753554502,
        127.44331002331,
        123.722912621359,
        140.567835294118,
        139.491945701357,
        138.401994949495,
        148.02830449827,
        103.412040358744,
        154.407034700315,
        121.991736842105,
        112.815511627907,
        124.925219399538,
        156.093507462687,
        
    ]

    //PROVIDER 5 PERSONAL CLINIC
    //5 PERSONAL CLINIC
    let pro5Charges = [
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
       
    ]
    let pro5Payments = [
        -40.27, 
        149.87, 
        0, 
        0, 
        25, 
        113.6, 
        -43.16, 
        -32.15, 
        8.22, 
        -125.09, 
        -10.41, 
        15, 
        15, 
        -9.67, 
        0, 
        0, 
        161, 
        0, 
        0, 
        0, 
        8.62, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
       
    ]
    let pro5Collect = [
        80, 
        -205.15, 
        130.27, 
        45, 
        -25, 
        -25, 
        0, 
        0, 
        -50, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        25.2, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
       
    ]
    let pro5NonCollect = [
        78.39, 
        55.28, 
        0, 
        0, 
        0, 
        0, 
        58.16, 
        32.15, 
        0, 
        140.09, 
        0, 
        0, 
        0, 
        34.67, 
        0, 
        0, 
        69, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
       
    ]
    let pro5Claims = [
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
       
    ]
    let pro5Visit = [
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
       
    ]

    //6 PROVIDER 6 PERSONAL CLINIC
    let pro6Charges = [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0, 
        0,   
        35074.99,
        33098.95,
        41678.87,
        32519.05,
        27421.29,
        9051.38,
        36196,
        33321.29,
        21374.69,
        22178.15,
        24583.6,
        20790.63,
        15997.85,
        
        
    ]
    let pro6Payments = [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0, 
        0, 
        1602.62,
        11541.48,
        10544.32,
        8115.6,
        11670.04,
        4555.47,
        6762.62,
        9015.73,
        9479.86,
        7912.37,
        7225,
        8559.82,
        6987.77,
        
    ]
    let pro6Collect = [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,   
        0,   
        708,
        1538,
        1447.09,
        1277.3,
        2064.99,
        744,
        1460.13,
        7395.61,
        899.61,
        137.09,
        988,
        882.89,
        -609.5,
        
    ]
    let pro6NonCollect = [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0, 
        0,   
        2390.16,
        19678.55,
        22545.71,
        17423.52,
        19709.95,
        6653.94,
        14446.01,
        15393.41,
        18505.9,
        14646.71,
        14842.33,
        19182.88,
        14684,
        
    ]
    let pro6Claims = [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        85,
        80,
        133,
        90,
        85,
        32,
        99,
        87,
        56,
        61,
        77,
        56,
        51,
        
    ]
    let pro6Visit = [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0, 
        0,   
        18.8543529411765,
        144.2685,
        79.2806015037594,
        90.1733333333333,
        137.294588235294,
        142.3584375,
        68.3092929292929,
        103.62908045977,
        169.283214285714,
        129.710983606557,
        93.8311688311688,
        152.853928571429,
        137.015098039216,
        
    ]


    ///7
    let pro7Charges = [
        68938,
        63175.00,
        90849.00,
        83421.00,
        78226.00,
        67988.00,
        73949.00,
        102931.00,
        87649.00,
        89763.00,
        64223.00,
        81303.50,
        66404.00,
        43778.00,
        90277.49,
        79900.00,
        59948.10,
        47029.65
    ]

    let totalFacilityWithMonth = [{
        name: 'Charges',
        data: Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [allproWithMar21Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [allproWithApr21Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [allproWithMay21Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [allproWithJun21Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [allproWithJul21Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [allproWithAug21Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [allproWithSep21Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [allproWithOct21Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [allproWithNov21Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [allproWithDec21Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [allproWithJan22Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [allproWithFeb22Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [allproWithMar22Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [allproWithApr22Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [allproWithMay22Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [allproWithJun22Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [allproWithJul22Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [allproWithAug22Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [allproWithSep22Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [allproWithOct22Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [allproWithNov22Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [allproWithDec22Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [allproWithJan23Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [allproWithFeb23Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [allproWithMar23Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [allproWithApr23Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [allproWithMay23Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [allproWithJun23Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [allproWithJul23Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [allproWithAug23Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [allproWithSep23Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [allproWithOct23Charges[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [allproWithNov23Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro1WithJan21Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro1WithFeb21Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro1WithMar21Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro1WithApr21Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro1WithMay21Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro1WithJun21Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro1WithJul21Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro1WithAug21Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro1WithSep21Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro1WithOct21Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro1WithNov21Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro1WithDec21Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro1WithJan22Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro1WithFeb22Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro1WithMar22Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro1WithApr22Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro1WithMay22Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro1WithJun22Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro1WithJul22Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro1WithAug22Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro1WithSep22Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro1WithOct22Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro1WithNov22Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro1WithDec22Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro1WithJan23Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro1WithFeb23Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro1WithMar23Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro1WithApr23Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro1WithMay23Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro1WithJun23Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro1WithJul23Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro1WithAug23Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro1WithSep23Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro1WithOct23Charges[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro1WithNov23Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro2WithJan21Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro2WithFeb21Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro2WithMar21Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro2WithApr21Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro2WithMay21Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro2WithJun21Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro2WithJul21Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro2WithAug21Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro2WithSep21Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro2WithOct21Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro2WithNov21Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro2WithDec21Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro2WithJan22Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro2WithFeb22Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro2WithMar22Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro2WithApr22Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro2WithMay22Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro2WithJun22Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro2WithJul22Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro2WithAug22Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro2WithSep22Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro2WithOct22Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro2WithNov22Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro2WithDec22Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro2WithJan23Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro2WithFeb23Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro2WithMar23Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro2WithApr23Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro2WithMay23Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro2WithJun23Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro2WithJul23Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro2WithAug23Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro2WithSep23Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro2WithOct23Charges[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro2WithNov23Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro3WithJan21Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro3WithFeb21Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro3WithMar21Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro3WithApr21Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro3WithMay21Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro3WithJun21Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro3WithJul21Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro3WithAug21Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro3WithSep21Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro3WithOct21Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro3WithNov21Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro3WithDec21Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro3WithJan22Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro3WithFeb22Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro3WithMar22Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro3WithApr22Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro3WithMay22Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro3WithJun22Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro3WithJul22Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro3WithAug22Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro3WithSep22Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro3WithOct22Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro3WithNov22Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro3WithDec22Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro3WithJan23Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro3WithFeb23Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro3WithMar23Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro3WithApr23Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro3WithMay23Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro3WithJun23Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro3WithJul23Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro3WithAug23Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro3WithSep23Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro3WithOct23Charges[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro3WithNov23Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro4WithJan21Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro4WithFeb21Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro4WithMar21Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro4WithApr21Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro4WithMay21Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro4WithJun21Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro4WithJul21Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro4WithAug21Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro4WithSep21Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro4WithOct21Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro4WithNov21Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro4WithDec21Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro4WithJan22Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro4WithFeb22Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro4WithMar22Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro4WithApr22Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro4WithMay22Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro4WithJun22Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro4WithJul22Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro4WithAug22Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro4WithSep22Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro4WithOct22Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro4WithNov22Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro4WithDec22Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro4WithJan23Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro4WithFeb23Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro4WithMar23Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro4WithApr23Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro4WithMay23Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro4WithJun23Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro4WithJul23Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro4WithAug23Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro4WithSep23Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro4WithOct23Charges[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro4WithNov23Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro5WithJan21Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro5WithFeb21Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro5WithMar21Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro5WithApr21Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro5WithMay21Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro5WithJun21Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro5WithJul21Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro5WithAug21Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro5WithSep21Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro5WithOct21Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro5WithNov21Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro5WithDec21Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro5WithJan22Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro5WithFeb22Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro5WithMar22Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro5WithApr22Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro5WithMay22Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro5WithJun22Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro5WithJul22Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro5WithAug22Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro5WithSep22Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro5WithOct22Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro5WithNov22Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro5WithDec22Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro5WithJan23Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro5WithFeb23Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro5WithMar23Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro5WithApr23Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro5WithMay23Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro5WithJun23Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro5WithJul23Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro5WithAug23Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro5WithSep23Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro5WithOct23Charges[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro5WithNov23Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro6WithJan21Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro6WithFeb21Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro6WithMar21Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro6WithApr21Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro6WithMay21Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro6WithJun21Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro6WithJul21Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro6WithAug21Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro6WithSep21Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro6WithOct21Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro6WithNov21Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro6WithDec21Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro6WithJan22Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro6WithFeb22Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro6WithMar22Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro6WithApr22Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro6WithMay22Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro6WithJun22Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro6WithJul22Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro6WithAug22Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro6WithSep22Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro6WithOct22Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro6WithNov22Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro6WithDec22Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro6WithJan23Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro6WithFeb23Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro6WithMar23Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro6WithApr23Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro6WithMay23Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro6WithJun23Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro6WithJul23Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro6WithAug23Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro6WithSep23Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro6WithOct23Charges[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro6WithNov23Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro7WithJan21Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro7WithFeb21Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro7WithMar21Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro7WithApr21Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro7WithMay21Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro7WithJun21Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro7WithJul21Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro7WithAug21Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro7WithSep21Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro7WithOct21Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro7WithNov21Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro7WithDec21Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro7WithJan22Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro7WithFeb22Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro7WithMar22Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro7WithApr22Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro7WithMay22Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro7WithJun22Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro7WithJul22Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro7WithAug22Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro7WithSep22Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro7WithOct22Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro7WithNov22Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro7WithDec22Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro7WithJan23Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro7WithFeb23Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro7WithMar23Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro7WithApr23Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro7WithMay23Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro7WithJun23Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro7WithJul23Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro7WithAug23Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro7WithSep23Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro7WithOct23Charges[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro7WithNov23Charges[3]]
            : ''
        },{
            name: 'Payments',
            data:Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [allproWithMar21Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [allproWithApr21Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [allproWithMay21Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [allproWithJun21Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [allproWithJul21Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [allproWithAug21Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [allproWithSep21Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [allproWithOct21Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [allproWithNov21Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [allproWithDec21Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [allproWithJan22Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [allproWithFeb22Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [allproWithMar22Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [allproWithApr22Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [allproWithMay22Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [allproWithJun22Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [allproWithJul22Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [allproWithAug22Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [allproWithSep22Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [allproWithOct22Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [allproWithNov22Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [allproWithDec22Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [allproWithJan23Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [allproWithFeb23Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [allproWithMar23Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [allproWithApr23Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [allproWithMay23Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [allproWithJun23Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [allproWithJul23Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [allproWithAug23Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [allproWithSep23Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [allproWithOct23Pay[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [allproWithNov23Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro1WithJan21Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro1WithFeb21Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro1WithMar21Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro1WithApr21Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro1WithMay21Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro1WithJun21Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro1WithJul21Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro1WithAug21Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro1WithSep21Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro1WithOct21Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro1WithNov21Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro1WithDec21Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro1WithJan22Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro1WithFeb22Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro1WithMar22Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro1WithApr22Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro1WithMay22Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro1WithJun22Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro1WithJul22Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro1WithAug22Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro1WithSep22Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro1WithOct22Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro1WithNov22Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro1WithDec22Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro1WithJan23Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro1WithFeb23Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro1WithMar23Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro1WithApr23Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro1WithMay23Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro1WithJun23Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro1WithJul23Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro1WithAug23Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro1WithSep23Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro1WithOct23Pay[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro1WithNov23Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro2WithJan21Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro2WithFeb21Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro2WithMar21Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro2WithApr21Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro2WithMay21Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro2WithJun21Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro2WithJul21Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro2WithAug21Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro2WithSep21Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro2WithOct21Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro2WithNov21Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro2WithDec21Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro2WithJan22Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro2WithFeb22Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro2WithMar22Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro2WithApr22Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro2WithMay22Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro2WithJun22Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro2WithJul22Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro2WithAug22Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro2WithSep22Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro2WithOct22Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro2WithNov22Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro2WithDec22Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro2WithJan23Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro2WithFeb23Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro2WithMar23Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro2WithApr23Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro2WithMay23Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro2WithJun23Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro2WithJul23Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro2WithAug23Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro2WithSep23Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro2WithOct23Pay[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro2WithNov23Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro3WithJan21Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro3WithFeb21Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro3WithMar21Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro3WithApr21Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro3WithMay21Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro3WithJun21Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro3WithJul21Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro3WithAug21Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro3WithSep21Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro3WithOct21Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro3WithNov21Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro3WithDec21Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro3WithJan22Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro3WithFeb22Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro3WithMar22Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro3WithApr22Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro3WithMay22Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro3WithJun22Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro3WithJul22Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro3WithAug22Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro3WithSep22Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro3WithOct22Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro3WithNov22Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro3WithDec22Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro3WithJan23Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro3WithFeb23Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro3WithMar23Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro3WithApr23Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro3WithMay23Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro3WithJun23Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro3WithJul23Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro3WithAug23Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro3WithSep23Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro3WithOct23Pay[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro3WithNov23Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro4WithJan21Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro4WithFeb21Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro4WithMar21Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro4WithApr21Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro4WithMay21Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro4WithJun21Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro4WithJul21Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro4WithAug21Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro4WithSep21Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro4WithOct21Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro4WithNov21Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro4WithDec21Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro4WithJan22Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro4WithFeb22Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro4WithMar22Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro4WithApr22Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro4WithMay22Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro4WithJun22Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro4WithJul22Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro4WithAug22Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro4WithSep22Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro4WithOct22Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro4WithNov22Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro4WithDec22Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro4WithJan23Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro4WithFeb23Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro4WithMar23Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro4WithApr23Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro4WithMay23Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro4WithJun23Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro4WithJul23Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro4WithAug23Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro4WithSep23Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro4WithOct23Pay[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro4WithNov23Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro5WithJan21Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro5WithFeb21Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro5WithMar21Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro5WithApr21Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro5WithMay21Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro5WithJun21Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro5WithJul21Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro5WithAug21Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro5WithSep21Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro5WithOct21Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro5WithNov21Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro5WithDec21Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro5WithJan22Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro5WithFeb22Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro5WithMar22Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro5WithApr22Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro5WithMay22Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro5WithJun22Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro5WithJul22Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro5WithAug22Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro5WithSep22Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro5WithOct22Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro5WithNov22Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro5WithDec22Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro5WithJan23Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro5WithFeb23Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro5WithMar23Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro5WithApr23Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro5WithMay23Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro5WithJun23Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro5WithJul23Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro5WithAug23Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro5WithSep23Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro5WithOct23Pay[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro5WithNov23Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro6WithJan21Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro6WithFeb21Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro6WithMar21Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro6WithApr21Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro6WithMay21Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro6WithJun21Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro6WithJul21Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro6WithAug21Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro6WithSep21Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro6WithOct21Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro6WithNov21Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro6WithDec21Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro6WithJan22Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro6WithFeb22Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro6WithMar22Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro6WithApr22Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro6WithMay22Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro6WithJun22Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro6WithJul22Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro6WithAug22Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro6WithSep22Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro6WithOct22Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro6WithNov22Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro6WithDec22Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro6WithJan23Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro6WithFeb23Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro6WithMar23Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro6WithApr23Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro6WithMay23Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro6WithJun23Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro6WithJul23Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro6WithAug23Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro6WithSep23Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro6WithOct23Pay[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro6WithNov23Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro7WithJan21Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro7WithFeb21Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro7WithMar21Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro7WithApr21Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro7WithMay21Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro7WithJun21Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro7WithJul21Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro7WithAug21Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro7WithSep21Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro7WithOct21Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro7WithNov21Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro7WithDec21Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro7WithJan22Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro7WithFeb22Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro7WithMar22Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro7WithApr22Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro7WithMay22Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro7WithJun22Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro7WithJul22Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro7WithAug22Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro7WithSep22Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro7WithOct22Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro7WithNov22Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro7WithDec22Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro7WithJan23Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro7WithFeb23Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro7WithMar23Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro7WithApr23Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro7WithMay23Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro7WithJun23Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro7WithJul23Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro7WithAug23Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro7WithSep23Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro7WithOct23Pay[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro7WithNov23Pay[3]]
            
            // :Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21Pay[0]]
            // : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21Pay[1]]
            // : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21Pay[2]]
            // : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21Pay[0]]
            // : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21Pay[1]]
            // : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21Pay[2]] 
            // : Providers === 'All Providers' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21Pay[3]]
            // : Providers === 'All Providers' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21Pay[3]]
            // : Providers === 'All Providers' && Month === 'Mar-21' && session === 'RX' ? [...allproWithMar21Pay]
            // : Providers === 'All Providers' && Month === 'Apr-21' && session === 'RX' ? [...allproWithApr21Pay]
            // : Providers === 'All Providers' && Month === 'May-21' && session === 'RX' ? [...allproWithMay21Pay]
            // : Providers === 'All Providers' && Month === 'Jun-21' && session === 'RX' ? [...allproWithJun21Pay]
            // : Providers === 'All Providers' && Month === 'Jul-21' && session === 'RX' ? [...allproWithJul21Pay]
            // : Providers === 'All Providers' && Month === 'Aug-21' && session === 'RX' ? [...allproWithAug21Pay]
            // : Providers === 'All Providers' && Month === 'Sep-21' && session === 'RX' ? [...allproWithSep21Pay]
            // : Providers === 'All Providers' && Month === 'Oct-21' && session === 'RX' ? [...allproWithOct21Pay]
            // : Providers === 'All Providers' && Month === 'Nov-21' && session === 'RX' ? [...allproWithNov21Pay]
            // : Providers === 'All Providers' && Month === 'Dec-21' && session === 'RX' ? [...allproWithDec21Pay]
            // : Providers === 'All Providers' && Month === 'Jan-22' && session === 'RX' ? [...allproWithJan22Pay]
            // : Providers === 'All Providers' && Month === 'Feb-22' && session === 'RX' ? [...allproWithFeb22Pay]
            // : Providers === 'All Providers' && Month === 'Mar-22' && session === 'RX' ? [...allproWithMar22Pay]
            // : Providers === 'All Providers' && Month === 'Apr-22' && session === 'RX' ? [...allproWithApr22Pay]
            // : Providers === 'All Providers' && Month === 'May-22' && session === 'RX' ? [...allproWithMay22Pay]
            // : Providers === 'All Providers' && Month === 'Jun-22' && session === 'RX' ? [...allproWithJun22Pay]
            // : Providers === 'All Providers' && Month === 'Jul-22' && session === 'RX' ? [...allproWithJul22Pay]
            // : Providers === 'All Providers' && Month === 'Aug-22' && session === 'RX' ? [...allproWithAug22Pay]
            // : Providers === 'All Providers' && Month === 'Sep-22' && session === 'RX' ? [...allproWithSep22Pay]
            // : Providers === 'All Providers' && Month === 'Oct-22' && session === 'RX' ? [...allproWithOct22Pay]
            // : Providers === 'All Providers' && Month === 'Nov-22' && session === 'RX' ? [...allproWithNov22Pay]
            // : Providers === 'All Providers' && Month === 'Dec-22' && session === 'RX' ? [...allproWithDec22Pay]
            // : Providers === 'All Providers' && Month === 'Jan-23' && session === 'RX' ? [...allproWithJan23Pay]
            // : Providers === 'All Providers' && Month === 'Feb-23' && session === 'RX' ? [...allproWithFeb23Pay]
            // : Providers === 'All Providers' && Month === 'Mar-23' && session === 'RX' ? [...allproWithMar23Pay]
            // : Providers === 'All Providers' && Month === 'Apr-23' && session === 'RX' ? [...allproWithApr23Pay]
            // : Providers === 'All Providers' && Month === 'May-23' && session === 'RX' ? [...allproWithMay23Pay]
            // : Providers === 'All Providers' && Month === 'Jun-23' && session === 'RX' ? [...allproWithJun23Pay]
            // : Providers === 'All Providers' && Month === 'Jul-23' && session === 'RX' ? [...allproWithJul23Pay]
            // : Providers === 'All Providers' && Month === 'Aug-23' && session === 'RX' ? [...allproWithAug23Pay]
            // : Providers === 'All Providers' && Month === 'Sep-23' && session === 'RX' ? [...allproWithSep23Pay]
            // : Providers === 'All Providers' && Month === 'Oct-23' && session === 'RX' ? [...allproWithOct23Pay]
            // : Providers === 'All Providers' && Month === 'Nov-23' && session === 'RX' ? [...allproWithNov23Pay]
            // :  Providers === 'Provider1' && Month === 'Jan-21' && session === 'RX' ? [...pro1WithJan21Pay]
            // : Providers === 'Provider1' && Month === 'Feb-21' && session === 'RX' ? [...pro1WithFeb21Pay]
            // : Providers === 'Provider1' && Month === 'Mar-21' && session === 'RX' ? [...pro1WithMar21Pay]
            // : Providers === 'Provider1' && Month === 'Apr-21' && session === 'RX' ? [...pro1WithApr21Pay]
            // : Providers === 'Provider1' && Month === 'May-21' && session === 'RX' ? [...pro1WithMay21Pay]
            // : Providers === 'Provider1' && Month === 'Jun-21' && session === 'RX' ? [...pro1WithJun21Pay]
            // : Providers === 'Provider1' && Month === 'Jul-21' && session === 'RX' ? [...pro1WithJul21Pay]
            // : Providers === 'Provider1' && Month === 'Aug-21' && session === 'RX' ? [...pro1WithAug21Pay]
            // : Providers === 'Provider1' && Month === 'Sep-21' && session === 'RX' ? [...pro1WithSep21Pay]
            // : Providers === 'Provider1' && Month === 'Oct-21' && session === 'RX' ? [...pro1WithOct21Pay]
            // : Providers === 'Provider1' && Month === 'Nov-21' && session === 'RX' ? [...pro1WithNov21Pay]
            // : Providers === 'Provider1' && Month === 'Dec-21' && session === 'RX' ? [...pro1WithDec21Pay]
            // : Providers === 'Provider1' && Month === 'Jan-22' && session === 'RX' ? [...pro1WithJan22Pay]
            // : Providers === 'Provider1' && Month === 'Feb-22' && session === 'RX' ? [...pro1WithFeb22Pay]
            // : Providers === 'Provider1' && Month === 'Mar-22' && session === 'RX' ? [...pro1WithMar22Pay]
            // : Providers === 'Provider1' && Month === 'Apr-22' && session === 'RX' ? [...pro1WithApr22Pay]
            // : Providers === 'Provider1' && Month === 'May-22' && session === 'RX' ? [...pro1WithMay22Pay]
            // : Providers === 'Provider1' && Month === 'Jun-22' && session === 'RX' ? [...pro1WithJun22Pay]
            // : Providers === 'Provider1' && Month === 'Jul-22' && session === 'RX' ? [...pro1WithJul22Pay]
            // : Providers === 'Provider1' && Month === 'Aug-22' && session === 'RX' ? [...pro1WithAug22Pay]
            // : Providers === 'Provider1' && Month === 'Sep-22' && session === 'RX' ? [...pro1WithSep22Pay]
            // : Providers === 'Provider1' && Month === 'Oct-22' && session === 'RX' ? [...pro1WithOct22Pay]
            // : Providers === 'Provider1' && Month === 'Nov-22' && session === 'RX' ? [...pro1WithNov22Pay]
            // : Providers === 'Provider1' && Month === 'Dec-22' && session === 'RX' ? [...pro1WithDec22Pay]
            // : Providers === 'Provider1' && Month === 'Jan-23' && session === 'RX' ? [...pro1WithJan23Pay]
            // : Providers === 'Provider1' && Month === 'Feb-23' && session === 'RX' ? [...pro1WithFeb23Pay]
            // : Providers === 'Provider1' && Month === 'Mar-23' && session === 'RX' ? [...pro1WithMar23Pay]
            // : Providers === 'Provider1' && Month === 'Apr-23' && session === 'RX' ? [...pro1WithApr23Pay]
            // : Providers === 'Provider1' && Month === 'May-23' && session === 'RX' ? [...pro1WithMay23Pay]
            // : Providers === 'Provider1' && Month === 'Jun-23' && session === 'RX' ? [...pro1WithJun23Pay]
            // : Providers === 'Provider1' && Month === 'Jul-23' && session === 'RX' ? [...pro1WithJul23Pay]
            // : Providers === 'Provider1' && Month === 'Aug-23' && session === 'RX' ? [...pro1WithAug23Pay]
            // : Providers === 'Provider1' && Month === 'Sep-23' && session === 'RX' ? [...pro1WithSep23Pay]
            // : Providers === 'Provider1' && Month === 'Oct-23' && session === 'RX' ? [...pro1WithOct23Pay]
            // : Providers === 'Provider1' && Month === 'Nov-23' && session === 'RX' ? [...pro1WithNov23Pay]
            // : Providers === 'Provider2' && Month === 'Jan-21' && session === 'RX' ? [...pro2WithJan21Pay]
            // : Providers === 'Provider2' && Month === 'Feb-21' && session === 'RX' ? [...pro2WithFeb21Pay]
            // : Providers === 'Provider2' && Month === 'Mar-21' && session === 'RX' ? [...pro2WithMar21Pay]
            // : Providers === 'Provider2' && Month === 'Apr-21' && session === 'RX' ? [...pro2WithApr21Pay]
            // : Providers === 'Provider2' && Month === 'May-21' && session === 'RX' ? [...pro2WithMay21Pay]
            // : Providers === 'Provider2' && Month === 'Jun-21' && session === 'RX' ? [...pro2WithJun21Pay]
            // : Providers === 'Provider2' && Month === 'Jul-21' && session === 'RX' ? [...pro2WithJul21Pay]
            // : Providers === 'Provider2' && Month === 'Aug-21' && session === 'RX' ? [...pro2WithAug21Pay]
            // : Providers === 'Provider2' && Month === 'Sep-21' && session === 'RX' ? [...pro2WithSep21Pay]
            // : Providers === 'Provider2' && Month === 'Oct-21' && session === 'RX' ? [...pro2WithOct21Pay]
            // : Providers === 'Provider2' && Month === 'Nov-21' && session === 'RX' ? [...pro2WithNov21Pay]
            // : Providers === 'Provider2' && Month === 'Dec-21' && session === 'RX' ? [...pro2WithDec21Pay]
            // : Providers === 'Provider2' && Month === 'Jan-22' && session === 'RX' ? [...pro2WithJan22Pay]
            // : Providers === 'Provider2' && Month === 'Feb-22' && session === 'RX' ? [...pro2WithFeb22Pay]
            // : Providers === 'Provider2' && Month === 'Mar-22' && session === 'RX' ? [...pro2WithMar22Pay]
            // : Providers === 'Provider2' && Month === 'Apr-22' && session === 'RX' ? [...pro2WithApr22Pay]
            // : Providers === 'Provider2' && Month === 'May-22' && session === 'RX' ? [...pro2WithMay22Pay]
            // : Providers === 'Provider2' && Month === 'Jun-22' && session === 'RX' ? [...pro2WithJun22Pay]
            // : Providers === 'Provider2' && Month === 'Jul-22' && session === 'RX' ? [...pro2WithJul22Pay]
            // : Providers === 'Provider2' && Month === 'Aug-22' && session === 'RX' ? [...pro2WithAug22Pay]
            // : Providers === 'Provider2' && Month === 'Sep-22' && session === 'RX' ? [...pro2WithSep22Pay]
            // : Providers === 'Provider2' && Month === 'Oct-22' && session === 'RX' ? [...pro2WithOct22Pay]
            // : Providers === 'Provider2' && Month === 'Nov-22' && session === 'RX' ? [...pro2WithNov22Pay]
            // : Providers === 'Provider2' && Month === 'Dec-22' && session === 'RX' ? [...pro2WithDec22Pay]
            // : Providers === 'Provider2' && Month === 'Jan-23' && session === 'RX' ? [...pro2WithJan23Pay]
            // : Providers === 'Provider2' && Month === 'Feb-23' && session === 'RX' ? [...pro2WithFeb23Pay]
            // : Providers === 'Provider2' && Month === 'Mar-23' && session === 'RX' ? [...pro2WithMar23Pay]
            // : Providers === 'Provider2' && Month === 'Apr-23' && session === 'RX' ? [...pro2WithApr23Pay]
            // : Providers === 'Provider2' && Month === 'May-23' && session === 'RX' ? [...pro2WithMay23Pay]
            // : Providers === 'Provider2' && Month === 'Jun-23' && session === 'RX' ? [...pro2WithJun23Pay]
            // : Providers === 'Provider2' && Month === 'Jul-23' && session === 'RX' ? [...pro2WithJul23Pay]
            // : Providers === 'Provider2' && Month === 'Aug-23' && session === 'RX' ? [...pro2WithAug23Pay]
            // : Providers === 'Provider2' && Month === 'Sep-23' && session === 'RX' ? [...pro2WithSep23Pay]
            // : Providers === 'Provider2' && Month === 'Oct-23' && session === 'RX' ? [...pro2WithOct23Pay]
            // : Providers === 'Provider2' && Month === 'Nov-23' && session === 'RX' ? [...pro2WithNov23Pay]
            // : Providers === 'Provider3' && Month === 'Jan-21' && session === 'RX' ? [...pro3WithJan21Pay]
            // : Providers === 'Provider3' && Month === 'Feb-21' && session === 'RX' ? [...pro3WithFeb21Pay]
            // : Providers === 'Provider3' && Month === 'Mar-21' && session === 'RX' ? [...pro3WithMar21Pay]
            // : Providers === 'Provider3' && Month === 'Apr-21' && session === 'RX' ? [...pro3WithApr21Pay]
            // : Providers === 'Provider3' && Month === 'May-21' && session === 'RX' ? [...pro3WithMay21Pay]
            // : Providers === 'Provider3' && Month === 'Jun-21' && session === 'RX' ? [...pro3WithJun21Pay]
            // : Providers === 'Provider3' && Month === 'Jul-21' && session === 'RX' ? [...pro3WithJul21Pay]
            // : Providers === 'Provider3' && Month === 'Aug-21' && session === 'RX' ? [...pro3WithAug21Pay]
            // : Providers === 'Provider3' && Month === 'Sep-21' && session === 'RX' ? [...pro3WithSep21Pay]
            // : Providers === 'Provider3' && Month === 'Oct-21' && session === 'RX' ? [...pro3WithOct21Pay]
            // : Providers === 'Provider3' && Month === 'Nov-21' && session === 'RX' ? [...pro3WithNov21Pay]
            // : Providers === 'Provider3' && Month === 'Dec-21' && session === 'RX' ? [...pro3WithDec21Pay]
            // : Providers === 'Provider3' && Month === 'Jan-22' && session === 'RX' ? [...pro3WithJan22Pay]
            // : Providers === 'Provider3' && Month === 'Feb-22' && session === 'RX' ? [...pro3WithFeb22Pay]
            // : Providers === 'Provider3' && Month === 'Mar-22' && session === 'RX' ? [...pro3WithMar22Pay]
            // : Providers === 'Provider3' && Month === 'Apr-22' && session === 'RX' ? [...pro3WithApr22Pay]
            // : Providers === 'Provider3' && Month === 'May-22' && session === 'RX' ? [...pro3WithMay22Pay]
            // : Providers === 'Provider3' && Month === 'Jun-22' && session === 'RX' ? [...pro3WithJun22Pay]
            // : Providers === 'Provider3' && Month === 'Jul-22' && session === 'RX' ? [...pro3WithJul22Pay]
            // : Providers === 'Provider3' && Month === 'Aug-22' && session === 'RX' ? [...pro3WithAug22Pay]
            // : Providers === 'Provider3' && Month === 'Sep-22' && session === 'RX' ? [...pro3WithSep22Pay]
            // : Providers === 'Provider3' && Month === 'Oct-22' && session === 'RX' ? [...pro3WithOct22Pay]
            // : Providers === 'Provider3' && Month === 'Nov-22' && session === 'RX' ? [...pro3WithNov22Pay]
            // : Providers === 'Provider3' && Month === 'Dec-22' && session === 'RX' ? [...pro3WithDec22Pay]
            // : Providers === 'Provider3' && Month === 'Jan-23' && session === 'RX' ? [...pro3WithJan23Pay]
            // : Providers === 'Provider3' && Month === 'Feb-23' && session === 'RX' ? [...pro3WithFeb23Pay]
            // : Providers === 'Provider3' && Month === 'Mar-23' && session === 'RX' ? [...pro3WithMar23Pay]
            // : Providers === 'Provider3' && Month === 'Apr-23' && session === 'RX' ? [...pro3WithApr23Pay]
            // : Providers === 'Provider3' && Month === 'May-23' && session === 'RX' ? [...pro3WithMay23Pay]
            // : Providers === 'Provider3' && Month === 'Jun-23' && session === 'RX' ? [...pro3WithJun23Pay]
            // : Providers === 'Provider3' && Month === 'Jul-23' && session === 'RX' ? [...pro3WithJul23Pay]
            // : Providers === 'Provider3' && Month === 'Aug-23' && session === 'RX' ? [...pro3WithAug23Pay]
            // : Providers === 'Provider3' && Month === 'Sep-23' && session === 'RX' ? [...pro3WithSep23Pay]
            // : Providers === 'Provider3' && Month === 'Oct-23' && session === 'RX' ? [...pro3WithOct23Pay]
            // : Providers === 'Provider3' && Month === 'Nov-23' && session === 'RX' ? [...pro3WithNov23Pay]
            // : Providers === 'Provider4' && Month === 'Jan-21' && session === 'RX' ? [...pro4WithJan21Pay]
            // : Providers === 'Provider4' && Month === 'Feb-21' && session === 'RX' ? [...pro4WithFeb21Pay]
            // : Providers === 'Provider4' && Month === 'Mar-21' && session === 'RX' ? [...pro4WithMar21Pay]
            // : Providers === 'Provider4' && Month === 'Apr-21' && session === 'RX' ? [...pro4WithApr21Pay]
            // : Providers === 'Provider4' && Month === 'May-21' && session === 'RX' ? [...pro4WithMay21Pay]
            // : Providers === 'Provider4' && Month === 'Jun-21' && session === 'RX' ? [...pro4WithJun21Pay]
            // : Providers === 'Provider4' && Month === 'Jul-21' && session === 'RX' ? [...pro4WithJul21Pay]
            // : Providers === 'Provider4' && Month === 'Aug-21' && session === 'RX' ? [...pro4WithAug21Pay]
            // : Providers === 'Provider4' && Month === 'Sep-21' && session === 'RX' ? [...pro4WithSep21Pay]
            // : Providers === 'Provider4' && Month === 'Oct-21' && session === 'RX' ? [...pro4WithOct21Pay]
            // : Providers === 'Provider4' && Month === 'Nov-21' && session === 'RX' ? [...pro4WithNov21Pay]
            // : Providers === 'Provider4' && Month === 'Dec-21' && session === 'RX' ? [...pro4WithDec21Pay]
            // : Providers === 'Provider4' && Month === 'Jan-22' && session === 'RX' ? [...pro4WithJan22Pay]
            // : Providers === 'Provider4' && Month === 'Feb-22' && session === 'RX' ? [...pro4WithFeb22Pay]
            // : Providers === 'Provider4' && Month === 'Mar-22' && session === 'RX' ? [...pro4WithMar22Pay]
            // : Providers === 'Provider4' && Month === 'Apr-22' && session === 'RX' ? [...pro4WithApr22Pay]
            // : Providers === 'Provider4' && Month === 'May-22' && session === 'RX' ? [...pro4WithMay22Pay]
            // : Providers === 'Provider4' && Month === 'Jun-22' && session === 'RX' ? [...pro4WithJun22Pay]
            // : Providers === 'Provider4' && Month === 'Jul-22' && session === 'RX' ? [...pro4WithJul22Pay]
            // : Providers === 'Provider4' && Month === 'Aug-22' && session === 'RX' ? [...pro4WithAug22Pay]
            // : Providers === 'Provider4' && Month === 'Sep-22' && session === 'RX' ? [...pro4WithSep22Pay]
            // : Providers === 'Provider4' && Month === 'Oct-22' && session === 'RX' ? [...pro4WithOct22Pay]
            // : Providers === 'Provider4' && Month === 'Nov-22' && session === 'RX' ? [...pro4WithNov22Pay]
            // : Providers === 'Provider4' && Month === 'Dec-22' && session === 'RX' ? [...pro4WithDec22Pay]
            // : Providers === 'Provider4' && Month === 'Jan-23' && session === 'RX' ? [...pro4WithJan23Pay]
            // : Providers === 'Provider4' && Month === 'Feb-23' && session === 'RX' ? [...pro4WithFeb23Pay]
            // : Providers === 'Provider4' && Month === 'Mar-23' && session === 'RX' ? [...pro4WithMar23Pay]
            // : Providers === 'Provider4' && Month === 'Apr-23' && session === 'RX' ? [...pro4WithApr23Pay]
            // : Providers === 'Provider4' && Month === 'May-23' && session === 'RX' ? [...pro4WithMay23Pay]
            // : Providers === 'Provider4' && Month === 'Jun-23' && session === 'RX' ? [...pro4WithJun23Pay]
            // : Providers === 'Provider4' && Month === 'Jul-23' && session === 'RX' ? [...pro4WithJul23Pay]
            // : Providers === 'Provider4' && Month === 'Aug-23' && session === 'RX' ? [...pro4WithAug23Pay]
            // : Providers === 'Provider4' && Month === 'Sep-23' && session === 'RX' ? [...pro4WithSep23Pay]
            // : Providers === 'Provider4' && Month === 'Oct-23' && session === 'RX' ? [...pro4WithOct23Pay]
            // : Providers === 'Provider4' && Month === 'Nov-23' && session === 'RX' ? [...pro4WithNov23Pay]
            // : Providers === 'Provider5' && Month === 'Jan-21' && session === 'RX' ? [...pro5WithJan21Pay]
            // : Providers === 'Provider5' && Month === 'Feb-21' && session === 'RX' ? [...pro5WithFeb21Pay]
            // : Providers === 'Provider5' && Month === 'Mar-21' && session === 'RX' ? [...pro5WithMar21Pay]
            // : Providers === 'Provider5' && Month === 'Apr-21' && session === 'RX' ? [...pro5WithApr21Pay]
            // : Providers === 'Provider5' && Month === 'May-21' && session === 'RX' ? [...pro5WithMay21Pay]
            // : Providers === 'Provider5' && Month === 'Jun-21' && session === 'RX' ? [...pro5WithJun21Pay]
            // : Providers === 'Provider5' && Month === 'Jul-21' && session === 'RX' ? [...pro5WithJul21Pay]
            // : Providers === 'Provider5' && Month === 'Aug-21' && session === 'RX' ? [...pro5WithAug21Pay]
            // : Providers === 'Provider5' && Month === 'Sep-21' && session === 'RX' ? [...pro5WithSep21Pay]
            // : Providers === 'Provider5' && Month === 'Oct-21' && session === 'RX' ? [...pro5WithOct21Pay]
            // : Providers === 'Provider5' && Month === 'Nov-21' && session === 'RX' ? [...pro5WithNov21Pay]
            // : Providers === 'Provider5' && Month === 'Dec-21' && session === 'RX' ? [...pro5WithDec21Pay]
            // : Providers === 'Provider5' && Month === 'Jan-22' && session === 'RX' ? [...pro5WithJan22Pay]
            // : Providers === 'Provider5' && Month === 'Feb-22' && session === 'RX' ? [...pro5WithFeb22Pay]
            // : Providers === 'Provider5' && Month === 'Mar-22' && session === 'RX' ? [...pro5WithMar22Pay]
            // : Providers === 'Provider5' && Month === 'Apr-22' && session === 'RX' ? [...pro5WithApr22Pay]
            // : Providers === 'Provider5' && Month === 'May-22' && session === 'RX' ? [...pro5WithMay22Pay]
            // : Providers === 'Provider5' && Month === 'Jun-22' && session === 'RX' ? [...pro5WithJun22Pay]
            // : Providers === 'Provider5' && Month === 'Jul-22' && session === 'RX' ? [...pro5WithJul22Pay]
            // : Providers === 'Provider5' && Month === 'Aug-22' && session === 'RX' ? [...pro5WithAug22Pay]
            // : Providers === 'Provider5' && Month === 'Sep-22' && session === 'RX' ? [...pro5WithSep22Pay]
            // : Providers === 'Provider5' && Month === 'Oct-22' && session === 'RX' ? [...pro5WithOct22Pay]
            // : Providers === 'Provider5' && Month === 'Nov-22' && session === 'RX' ? [...pro5WithNov22Pay]
            // : Providers === 'Provider5' && Month === 'Dec-22' && session === 'RX' ? [...pro5WithDec22Pay]
            // : Providers === 'Provider5' && Month === 'Jan-23' && session === 'RX' ? [...pro5WithJan23Pay]
            // : Providers === 'Provider5' && Month === 'Feb-23' && session === 'RX' ? [...pro5WithFeb23Pay]
            // : Providers === 'Provider5' && Month === 'Mar-23' && session === 'RX' ? [...pro5WithMar23Pay]
            // : Providers === 'Provider5' && Month === 'Apr-23' && session === 'RX' ? [...pro5WithApr23Pay]
            // : Providers === 'Provider5' && Month === 'May-23' && session === 'RX' ? [...pro5WithMay23Pay]
            // : Providers === 'Provider5' && Month === 'Jun-23' && session === 'RX' ? [...pro5WithJun23Pay]
            // : Providers === 'Provider5' && Month === 'Jul-23' && session === 'RX' ? [...pro5WithJul23Pay]
            // : Providers === 'Provider5' && Month === 'Aug-23' && session === 'RX' ? [...pro5WithAug23Pay]
            // : Providers === 'Provider5' && Month === 'Sep-23' && session === 'RX' ? [...pro5WithSep23Pay]
            // : Providers === 'Provider5' && Month === 'Oct-23' && session === 'RX' ? [...pro5WithOct23Pay]
            // : Providers === 'Provider5' && Month === 'Nov-23' && session === 'RX' ? [...pro5WithNov23Pay]
            // : Providers === 'Provider6' && Month === 'Jan-21' && session === 'RX' ? [...pro6WithJan21Pay]
            // : Providers === 'Provider6' && Month === 'Feb-21' && session === 'RX' ? [...pro6WithFeb21Pay]
            // : Providers === 'Provider6' && Month === 'Mar-21' && session === 'RX' ? [...pro6WithMar21Pay]
            // : Providers === 'Provider6' && Month === 'Apr-21' && session === 'RX' ? [...pro6WithApr21Pay]
            // : Providers === 'Provider6' && Month === 'May-21' && session === 'RX' ? [...pro6WithMay21Pay]
            // : Providers === 'Provider6' && Month === 'Jun-21' && session === 'RX' ? [...pro6WithJun21Pay]
            // : Providers === 'Provider6' && Month === 'Jul-21' && session === 'RX' ? [...pro6WithJul21Pay]
            // : Providers === 'Provider6' && Month === 'Aug-21' && session === 'RX' ? [...pro6WithAug21Pay]
            // : Providers === 'Provider6' && Month === 'Sep-21' && session === 'RX' ? [...pro6WithSep21Pay]
            // : Providers === 'Provider6' && Month === 'Oct-21' && session === 'RX' ? [...pro6WithOct21Pay]
            // : Providers === 'Provider6' && Month === 'Nov-21' && session === 'RX' ? [...pro6WithNov21Pay]
            // : Providers === 'Provider6' && Month === 'Dec-21' && session === 'RX' ? [...pro6WithDec21Pay]
            // : Providers === 'Provider6' && Month === 'Jan-22' && session === 'RX' ? [...pro6WithJan22Pay]
            // : Providers === 'Provider6' && Month === 'Feb-22' && session === 'RX' ? [...pro6WithFeb22Pay]
            // : Providers === 'Provider6' && Month === 'Mar-22' && session === 'RX' ? [...pro6WithMar22Pay]
            // : Providers === 'Provider6' && Month === 'Apr-22' && session === 'RX' ? [...pro6WithApr22Pay]
            // : Providers === 'Provider6' && Month === 'May-22' && session === 'RX' ? [...pro6WithMay22Pay]
            // : Providers === 'Provider6' && Month === 'Jun-22' && session === 'RX' ? [...pro6WithJun22Pay]
            // : Providers === 'Provider6' && Month === 'Jul-22' && session === 'RX' ? [...pro6WithJul22Pay]
            // : Providers === 'Provider6' && Month === 'Aug-22' && session === 'RX' ? [...pro6WithAug22Pay]
            // : Providers === 'Provider6' && Month === 'Sep-22' && session === 'RX' ? [...pro6WithSep22Pay]
            // : Providers === 'Provider6' && Month === 'Oct-22' && session === 'RX' ? [...pro6WithOct22Pay]
            // : Providers === 'Provider6' && Month === 'Nov-22' && session === 'RX' ? [...pro6WithNov22Pay]
            // : Providers === 'Provider6' && Month === 'Dec-22' && session === 'RX' ? [...pro6WithDec22Pay]
            // : Providers === 'Provider6' && Month === 'Jan-23' && session === 'RX' ? [...pro6WithJan23Pay]
            // : Providers === 'Provider6' && Month === 'Feb-23' && session === 'RX' ? [...pro6WithFeb23Pay]
            // : Providers === 'Provider6' && Month === 'Mar-23' && session === 'RX' ? [...pro6WithMar23Pay]
            // : Providers === 'Provider6' && Month === 'Apr-23' && session === 'RX' ? [...pro6WithApr23Pay]
            // : Providers === 'Provider6' && Month === 'May-23' && session === 'RX' ? [...pro6WithMay23Pay]
            // : Providers === 'Provider6' && Month === 'Jun-23' && session === 'RX' ? [...pro6WithJun23Pay]
            // : Providers === 'Provider6' && Month === 'Jul-23' && session === 'RX' ? [...pro6WithJul23Pay]
            // : Providers === 'Provider6' && Month === 'Aug-23' && session === 'RX' ? [...pro6WithAug23Pay]
            // : Providers === 'Provider6' && Month === 'Sep-23' && session === 'RX' ? [...pro6WithSep23Pay]
            // : Providers === 'Provider6' && Month === 'Oct-23' && session === 'RX' ? [...pro6WithOct23Pay]
            // : Providers === 'Provider6' && Month === 'Nov-23' && session === 'RX' ? [...pro6WithNov23Pay]
            // : Providers === 'Provider7' && Month === 'Jan-21' && session === 'RX' ? [...pro7WithJan21Pay]
            // : Providers === 'Provider7' && Month === 'Feb-21' && session === 'RX' ? [...pro7WithFeb21Pay]
            // : Providers === 'Provider7' && Month === 'Mar-21' && session === 'RX' ? [...pro7WithMar21Pay]
            // : Providers === 'Provider7' && Month === 'Apr-21' && session === 'RX' ? [...pro7WithApr21Pay]
            // : Providers === 'Provider7' && Month === 'May-21' && session === 'RX' ? [...pro7WithMay21Pay]
            // : Providers === 'Provider7' && Month === 'Jun-21' && session === 'RX' ? [...pro7WithJun21Pay]
            // : Providers === 'Provider7' && Month === 'Jul-21' && session === 'RX' ? [...pro7WithJul21Pay]
            // : Providers === 'Provider7' && Month === 'Aug-21' && session === 'RX' ? [...pro7WithAug21Pay]
            // : Providers === 'Provider7' && Month === 'Sep-21' && session === 'RX' ? [...pro7WithSep21Pay]
            // : Providers === 'Provider7' && Month === 'Oct-21' && session === 'RX' ? [...pro7WithOct21Pay]
            // : Providers === 'Provider7' && Month === 'Nov-21' && session === 'RX' ? [...pro7WithNov21Pay]
            // : Providers === 'Provider7' && Month === 'Dec-21' && session === 'RX' ? [...pro7WithDec21Pay]
            // : Providers === 'Provider7' && Month === 'Jan-22' && session === 'RX' ? [...pro7WithJan22Pay]
            // : Providers === 'Provider7' && Month === 'Feb-22' && session === 'RX' ? [...pro7WithFeb22Pay]
            // : Providers === 'Provider7' && Month === 'Mar-22' && session === 'RX' ? [...pro7WithMar22Pay]
            // : Providers === 'Provider7' && Month === 'Apr-22' && session === 'RX' ? [...pro7WithApr22Pay]
            // : Providers === 'Provider7' && Month === 'May-22' && session === 'RX' ? [...pro7WithMay22Pay]
            // : Providers === 'Provider7' && Month === 'Jun-22' && session === 'RX' ? [...pro7WithJun22Pay]
            // : Providers === 'Provider7' && Month === 'Jul-22' && session === 'RX' ? [...pro7WithJul22Pay]
            // : Providers === 'Provider7' && Month === 'Aug-22' && session === 'RX' ? [...pro7WithAug22Pay]
            // : Providers === 'Provider7' && Month === 'Sep-22' && session === 'RX' ? [...pro7WithSep22Pay]
            // : Providers === 'Provider7' && Month === 'Oct-22' && session === 'RX' ? [...pro7WithOct22Pay]
            // : Providers === 'Provider7' && Month === 'Nov-22' && session === 'RX' ? [...pro7WithNov22Pay]
            // : Providers === 'Provider7' && Month === 'Dec-22' && session === 'RX' ? [...pro7WithDec22Pay]
            // : Providers === 'Provider7' && Month === 'Jan-23' && session === 'RX' ? [...pro7WithJan23Pay]
            // : Providers === 'Provider7' && Month === 'Feb-23' && session === 'RX' ? [...pro7WithFeb23Pay]
            // : Providers === 'Provider7' && Month === 'Mar-23' && session === 'RX' ? [...pro7WithMar23Pay]
            // : Providers === 'Provider7' && Month === 'Apr-23' && session === 'RX' ? [...pro7WithApr23Pay]
            // : Providers === 'Provider7' && Month === 'May-23' && session === 'RX' ? [...pro7WithMay23Pay]
            // : Providers === 'Provider7' && Month === 'Jun-23' && session === 'RX' ? [...pro7WithJun23Pay]
            // : Providers === 'Provider7' && Month === 'Jul-23' && session === 'RX' ? [...pro7WithJul23Pay]
            // : Providers === 'Provider7' && Month === 'Aug-23' && session === 'RX' ? [...pro7WithAug23Pay]
            // : Providers === 'Provider7' && Month === 'Sep-23' && session === 'RX' ? [...pro7WithSep23Pay]
            // : Providers === 'Provider7' && Month === 'Oct-23' && session === 'RX' ? [...pro7WithOct23Pay]
            // : Providers === 'Provider7' && Month === 'Nov-23' && session === 'RX' ? [...pro7WithNov23Pay]
                : ''
            },{
                name: 'Collectable',
                data:Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [allproWithMar21Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [allproWithApr21Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [allproWithMay21Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [allproWithJun21Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [allproWithJul21Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [allproWithAug21Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [allproWithSep21Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [allproWithOct21Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [allproWithNov21Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [allproWithDec21Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [allproWithJan22Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [allproWithFeb22Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [allproWithMar22Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [allproWithApr22Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [allproWithMay22Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [allproWithJun22Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [allproWithJul22Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [allproWithAug22Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [allproWithSep22Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [allproWithOct22Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [allproWithNov22Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [allproWithDec22Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [allproWithJan23Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [allproWithFeb23Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [allproWithMar23Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [allproWithApr23Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [allproWithMay23Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [allproWithJun23Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [allproWithJul23Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [allproWithAug23Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [allproWithSep23Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [allproWithOct23Count[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [allproWithNov23Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro1WithJan21Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro1WithFeb21Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro1WithMar21Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro1WithApr21Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro1WithMay21Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro1WithJun21Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro1WithJul21Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro1WithAug21Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro1WithSep21Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro1WithOct21Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro1WithNov21Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro1WithDec21Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro1WithJan22Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro1WithFeb22Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro1WithMar22Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro1WithApr22Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro1WithMay22Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro1WithJun22Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro1WithJul22Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro1WithAug22Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro1WithSep22Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro1WithOct22Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro1WithNov22Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro1WithDec22Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro1WithJan23Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro1WithFeb23Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro1WithMar23Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro1WithApr23Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro1WithMay23Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro1WithJun23Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro1WithJul23Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro1WithAug23Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro1WithSep23Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro1WithOct23Count[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro1WithNov23Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro2WithJan21Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro2WithFeb21Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro2WithMar21Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro2WithApr21Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro2WithMay21Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro2WithJun21Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro2WithJul21Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro2WithAug21Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro2WithSep21Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro2WithOct21Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro2WithNov21Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro2WithDec21Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro2WithJan22Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro2WithFeb22Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro2WithMar22Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro2WithApr22Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro2WithMay22Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro2WithJun22Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro2WithJul22Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro2WithAug22Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro2WithSep22Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro2WithOct22Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro2WithNov22Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro2WithDec22Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro2WithJan23Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro2WithFeb23Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro2WithMar23Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro2WithApr23Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro2WithMay23Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro2WithJun23Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro2WithJul23Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro2WithAug23Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro2WithSep23Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro2WithOct23Count[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro2WithNov23Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro3WithJan21Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro3WithFeb21Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro3WithMar21Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro3WithApr21Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro3WithMay21Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro3WithJun21Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro3WithJul21Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro3WithAug21Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro3WithSep21Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro3WithOct21Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro3WithNov21Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro3WithDec21Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro3WithJan22Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro3WithFeb22Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro3WithMar22Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro3WithApr22Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro3WithMay22Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro3WithJun22Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro3WithJul22Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro3WithAug22Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro3WithSep22Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro3WithOct22Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro3WithNov22Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro3WithDec22Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro3WithJan23Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro3WithFeb23Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro3WithMar23Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro3WithApr23Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro3WithMay23Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro3WithJun23Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro3WithJul23Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro3WithAug23Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro3WithSep23Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro3WithOct23Count[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro3WithNov23Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro4WithJan21Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro4WithFeb21Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro4WithMar21Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro4WithApr21Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro4WithMay21Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro4WithJun21Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro4WithJul21Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro4WithAug21Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro4WithSep21Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro4WithOct21Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro4WithNov21Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro4WithDec21Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro4WithJan22Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro4WithFeb22Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro4WithMar22Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro4WithApr22Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro4WithMay22Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro4WithJun22Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro4WithJul22Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro4WithAug22Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro4WithSep22Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro4WithOct22Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro4WithNov22Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro4WithDec22Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro4WithJan23Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro4WithFeb23Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro4WithMar23Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro4WithApr23Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro4WithMay23Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro4WithJun23Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro4WithJul23Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro4WithAug23Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro4WithSep23Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro4WithOct23Count[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro4WithNov23Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro5WithJan21Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro5WithFeb21Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro5WithMar21Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro5WithApr21Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro5WithMay21Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro5WithJun21Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro5WithJul21Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro5WithAug21Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro5WithSep21Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro5WithOct21Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro5WithNov21Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro5WithDec21Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro5WithJan22Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro5WithFeb22Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro5WithMar22Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro5WithApr22Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro5WithMay22Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro5WithJun22Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro5WithJul22Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro5WithAug22Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro5WithSep22Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro5WithOct22Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro5WithNov22Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro5WithDec22Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro5WithJan23Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro5WithFeb23Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro5WithMar23Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro5WithApr23Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro5WithMay23Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro5WithJun23Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro5WithJul23Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro5WithAug23Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro5WithSep23Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro5WithOct23Count[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro5WithNov23Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro6WithJan21Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro6WithFeb21Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro6WithMar21Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro6WithApr21Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro6WithMay21Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro6WithJun21Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro6WithJul21Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro6WithAug21Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro6WithSep21Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro6WithOct21Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro6WithNov21Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro6WithDec21Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro6WithJan22Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro6WithFeb22Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro6WithMar22Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro6WithApr22Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro6WithMay22Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro6WithJun22Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro6WithJul22Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro6WithAug22Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro6WithSep22Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro6WithOct22Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro6WithNov22Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro6WithDec22Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro6WithJan23Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro6WithFeb23Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro6WithMar23Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro6WithApr23Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro6WithMay23Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro6WithJun23Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro6WithJul23Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro6WithAug23Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro6WithSep23Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro6WithOct23Count[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro6WithNov23Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro7WithJan21Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro7WithFeb21Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro7WithMar21Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro7WithApr21Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro7WithMay21Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro7WithJun21Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro7WithJul21Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro7WithAug21Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro7WithSep21Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro7WithOct21Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro7WithNov21Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro7WithDec21Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro7WithJan22Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro7WithFeb22Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro7WithMar22Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro7WithApr22Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro7WithMay22Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro7WithJun22Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro7WithJul22Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro7WithAug22Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro7WithSep22Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro7WithOct22Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro7WithNov22Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro7WithDec22Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro7WithJan23Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro7WithFeb23Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro7WithMar23Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro7WithApr23Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro7WithMay23Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro7WithJun23Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro7WithJul23Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro7WithAug23Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro7WithSep23Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro7WithOct23Count[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro7WithNov23Count[3]]
             : ''

            },{
                name: 'Non-Collectable',
                data: Providers === 'All Providers' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [allproWithMar21NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [allproWithApr21NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [allproWithMay21NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [allproWithJun21NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [allproWithJul21NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [allproWithAug21NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [allproWithSep21NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [allproWithOct21NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [allproWithNov21NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [allproWithDec21NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [allproWithJan22NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [allproWithFeb22NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [allproWithMar22NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [allproWithApr22NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [allproWithMay22NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [allproWithJun22NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [allproWithJul22NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [allproWithAug22NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [allproWithSep22NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [allproWithOct22NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [allproWithNov22NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [allproWithDec22NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [allproWithJan23NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [allproWithFeb23NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [allproWithMar23NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [allproWithApr23NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [allproWithMay23NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [allproWithJun23NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [allproWithJul23NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [allproWithAug23NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [allproWithSep23NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [allproWithOct23NonCount[3]]
            : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [allproWithNov23NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro1WithJan21NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro1WithFeb21NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro1WithMar21NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro1WithApr21NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro1WithMay21NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro1WithJun21NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro1WithJul21NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro1WithAug21NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro1WithSep21NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro1WithOct21NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro1WithNov21NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro1WithDec21NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro1WithJan22NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro1WithFeb22NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro1WithMar22NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro1WithApr22NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro1WithMay22NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro1WithJun22NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro1WithJul22NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro1WithAug22NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro1WithSep22NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro1WithOct22NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro1WithNov22NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro1WithDec22NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro1WithJan23NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro1WithFeb23NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro1WithMar23NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro1WithApr23NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro1WithMay23NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro1WithJun23NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro1WithJul23NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro1WithAug23NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro1WithSep23NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro1WithOct23NonCount[3]]
            : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro1WithNov23NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro2WithJan21NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro2WithFeb21NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro2WithMar21NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro2WithApr21NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro2WithMay21NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro2WithJun21NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro2WithJul21NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro2WithAug21NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro2WithSep21NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro2WithOct21NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro2WithNov21NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro2WithDec21NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro2WithJan22NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro2WithFeb22NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro2WithMar22NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro2WithApr22NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro2WithMay22NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro2WithJun22NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro2WithJul22NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro2WithAug22NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro2WithSep22NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro2WithOct22NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro2WithNov22NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro2WithDec22NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro2WithJan23NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro2WithFeb23NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro2WithMar23NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro2WithApr23NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro2WithMay23NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro2WithJun23NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro2WithJul23NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro2WithAug23NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro2WithSep23NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro2WithOct23NonCount[3]]
            : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro2WithNov23NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro3WithJan21NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro3WithFeb21NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro3WithMar21NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro3WithApr21NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro3WithMay21NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro3WithJun21NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro3WithJul21NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro3WithAug21NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro3WithSep21NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro3WithOct21NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro3WithNov21NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro3WithDec21NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro3WithJan22NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro3WithFeb22NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro3WithMar22NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro3WithApr22NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro3WithMay22NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro3WithJun22NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro3WithJul22NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro3WithAug22NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro3WithSep22NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro3WithOct22NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro3WithNov22NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro3WithDec22NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro3WithJan23NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro3WithFeb23NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro3WithMar23NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro3WithApr23NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro3WithMay23NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro3WithJun23NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro3WithJul23NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro3WithAug23NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro3WithSep23NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro3WithOct23NonCount[3]]
            : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro3WithNov23NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro4WithJan21NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro4WithFeb21NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro4WithMar21NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro4WithApr21NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro4WithMay21NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro4WithJun21NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro4WithJul21NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro4WithAug21NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro4WithSep21NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro4WithOct21NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro4WithNov21NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro4WithDec21NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro4WithJan22NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro4WithFeb22NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro4WithMar22NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro4WithApr22NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro4WithMay22NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro4WithJun22NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro4WithJul22NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro4WithAug22NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro4WithSep22NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro4WithOct22NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro4WithNov22NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro4WithDec22NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro4WithJan23NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro4WithFeb23NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro4WithMar23NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro4WithApr23NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro4WithMay23NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro4WithJun23NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro4WithJul23NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro4WithAug23NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro4WithSep23NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro4WithOct23NonCount[3]]
            : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro4WithNov23NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro5WithJan21NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro5WithFeb21NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro5WithMar21NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro5WithApr21NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro5WithMay21NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro5WithJun21NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro5WithJul21NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro5WithAug21NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro5WithSep21NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro5WithOct21NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro5WithNov21NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro5WithDec21NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro5WithJan22NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro5WithFeb22NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro5WithMar22NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro5WithApr22NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro5WithMay22NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro5WithJun22NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro5WithJul22NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro5WithAug22NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro5WithSep22NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro5WithOct22NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro5WithNov22NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro5WithDec22NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro5WithJan23NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro5WithFeb23NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro5WithMar23NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro5WithApr23NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro5WithMay23NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro5WithJun23NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro5WithJul23NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro5WithAug23NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro5WithSep23NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro5WithOct23NonCount[3]]
            : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro5WithNov23NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro6WithJan21NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro6WithFeb21NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro6WithMar21NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro6WithApr21NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro6WithMay21NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro6WithJun21NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro6WithJul21NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro6WithAug21NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro6WithSep21NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro6WithOct21NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro6WithNov21NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro6WithDec21NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro6WithJan22NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro6WithFeb22NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro6WithMar22NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro6WithApr22NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro6WithMay22NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro6WithJun22NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro6WithJul22NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro6WithAug22NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro6WithSep22NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro6WithOct22NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro6WithNov22NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro6WithDec22NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro6WithJan23NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro6WithFeb23NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro6WithMar23NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro6WithApr23NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro6WithMay23NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro6WithJun23NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro6WithJul23NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro6WithAug23NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro6WithSep23NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro6WithOct23NonCount[3]]
            : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro6WithNov23NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro7WithJan21NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro7WithFeb21NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro7WithMar21NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro7WithApr21NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro7WithMay21NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro7WithJun21NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro7WithJul21NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro7WithAug21NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro7WithSep21NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro7WithOct21NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro7WithNov21NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro7WithDec21NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro7WithJan22NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro7WithFeb22NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro7WithMar22NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro7WithApr22NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro7WithMay22NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro7WithJun22NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro7WithJul22NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro7WithAug22NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro7WithSep22NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro7WithOct22NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro7WithNov22NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro7WithDec22NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro7WithJan23NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro7WithFeb23NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro7WithMar23NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro7WithApr23NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro7WithMay23NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro7WithJun23NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro7WithJul23NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro7WithAug23NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro7WithSep23NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro7WithOct23NonCount[3]]
            : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro7WithNov23NonCount[3]]
                : ''
            },{
                name: 'Claims',
                data:Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [allproWithMar21Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [allproWithApr21Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [allproWithMay21Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [allproWithJun21Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [allproWithJul21Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [allproWithAug21Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [allproWithSep21Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [allproWithOct21Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [allproWithNov21Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [allproWithDec21Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [allproWithJan22Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [allproWithFeb22Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [allproWithMar22Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [allproWithApr22Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [allproWithMay22Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [allproWithJun22Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [allproWithJul22Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [allproWithAug22Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [allproWithSep22Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [allproWithOct22Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [allproWithNov22Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [allproWithDec22Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [allproWithJan23Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [allproWithFeb23Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [allproWithMar23Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [allproWithApr23Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [allproWithMay23Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [allproWithJun23Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [allproWithJul23Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [allproWithAug23Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [allproWithSep23Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [allproWithOct23Claim[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [allproWithNov23Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro1WithJan21Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro1WithFeb21Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro1WithMar21Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro1WithApr21Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro1WithMay21Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro1WithJun21Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro1WithJul21Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro1WithAug21Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro1WithSep21Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro1WithOct21Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro1WithNov21Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro1WithDec21Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro1WithJan22Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro1WithFeb22Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro1WithMar22Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro1WithApr22Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro1WithMay22Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro1WithJun22Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro1WithJul22Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro1WithAug22Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro1WithSep22Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro1WithOct22Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro1WithNov22Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro1WithDec22Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro1WithJan23Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro1WithFeb23Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro1WithMar23Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro1WithApr23Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro1WithMay23Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro1WithJun23Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro1WithJul23Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro1WithAug23Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro1WithSep23Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro1WithOct23Claim[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro1WithNov23Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro2WithJan21Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro2WithFeb21Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro2WithMar21Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro2WithApr21Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro2WithMay21Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro2WithJun21Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro2WithJul21Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro2WithAug21Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro2WithSep21Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro2WithOct21Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro2WithNov21Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro2WithDec21Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro2WithJan22Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro2WithFeb22Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro2WithMar22Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro2WithApr22Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro2WithMay22Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro2WithJun22Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro2WithJul22Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro2WithAug22Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro2WithSep22Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro2WithOct22Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro2WithNov22Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro2WithDec22Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro2WithJan23Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro2WithFeb23Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro2WithMar23Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro2WithApr23Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro2WithMay23Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro2WithJun23Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro2WithJul23Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro2WithAug23Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro2WithSep23Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro2WithOct23Claim[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro2WithNov23Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro3WithJan21Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro3WithFeb21Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro3WithMar21Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro3WithApr21Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro3WithMay21Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro3WithJun21Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro3WithJul21Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro3WithAug21Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro3WithSep21Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro3WithOct21Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro3WithNov21Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro3WithDec21Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro3WithJan22Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro3WithFeb22Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro3WithMar22Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro3WithApr22Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro3WithMay22Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro3WithJun22Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro3WithJul22Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro3WithAug22Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro3WithSep22Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro3WithOct22Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro3WithNov22Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro3WithDec22Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro3WithJan23Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro3WithFeb23Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro3WithMar23Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro3WithApr23Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro3WithMay23Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro3WithJun23Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro3WithJul23Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro3WithAug23Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro3WithSep23Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro3WithOct23Claim[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro3WithNov23Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro4WithJan21Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro4WithFeb21Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro4WithMar21Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro4WithApr21Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro4WithMay21Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro4WithJun21Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro4WithJul21Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro4WithAug21Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro4WithSep21Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro4WithOct21Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro4WithNov21Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro4WithDec21Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro4WithJan22Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro4WithFeb22Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro4WithMar22Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro4WithApr22Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro4WithMay22Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro4WithJun22Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro4WithJul22Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro4WithAug22Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro4WithSep22Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro4WithOct22Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro4WithNov22Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro4WithDec22Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro4WithJan23Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro4WithFeb23Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro4WithMar23Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro4WithApr23Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro4WithMay23Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro4WithJun23Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro4WithJul23Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro4WithAug23Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro4WithSep23Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro4WithOct23Claim[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro4WithNov23Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro5WithJan21Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro5WithFeb21Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro5WithMar21Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro5WithApr21Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro5WithMay21Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro5WithJun21Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro5WithJul21Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro5WithAug21Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro5WithSep21Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro5WithOct21Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro5WithNov21Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro5WithDec21Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro5WithJan22Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro5WithFeb22Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro5WithMar22Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro5WithApr22Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro5WithMay22Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro5WithJun22Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro5WithJul22Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro5WithAug22Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro5WithSep22Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro5WithOct22Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro5WithNov22Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro5WithDec22Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro5WithJan23Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro5WithFeb23Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro5WithMar23Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro5WithApr23Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro5WithMay23Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro5WithJun23Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro5WithJul23Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro5WithAug23Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro5WithSep23Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro5WithOct23Claim[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro5WithNov23Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro6WithJan21Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro6WithFeb21Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro6WithMar21Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro6WithApr21Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro6WithMay21Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro6WithJun21Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro6WithJul21Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro6WithAug21Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro6WithSep21Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro6WithOct21Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro6WithNov21Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro6WithDec21Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro6WithJan22Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro6WithFeb22Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro6WithMar22Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro6WithApr22Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro6WithMay22Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro6WithJun22Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro6WithJul22Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro6WithAug22Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro6WithSep22Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro6WithOct22Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro6WithNov22Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro6WithDec22Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro6WithJan23Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro6WithFeb23Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro6WithMar23Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro6WithApr23Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro6WithMay23Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro6WithJun23Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro6WithJul23Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro6WithAug23Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro6WithSep23Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro6WithOct23Claim[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro6WithNov23Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro7WithJan21Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro7WithFeb21Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro7WithMar21Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro7WithApr21Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro7WithMay21Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro7WithJun21Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro7WithJul21Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro7WithAug21Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro7WithSep21Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro7WithOct21Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro7WithNov21Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro7WithDec21Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro7WithJan22Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro7WithFeb22Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro7WithMar22Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro7WithApr22Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro7WithMay22Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro7WithJun22Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro7WithJul22Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro7WithAug22Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro7WithSep22Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro7WithOct22Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro7WithNov22Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro7WithDec22Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro7WithJan23Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro7WithFeb23Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro7WithMar23Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro7WithApr23Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro7WithMay23Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro7WithJun23Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro7WithJul23Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro7WithAug23Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro7WithSep23Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro7WithOct23Claim[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro7WithNov23Claim[3]]
                : ''
            },{
                name: 'Per-Visit',
                data:Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [allproWithMar21PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [allproWithApr21PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [allproWithMay21PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [allproWithJun21PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [allproWithJul21PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [allproWithAug21PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [allproWithSep21PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [allproWithOct21PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [allproWithNov21PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [allproWithDec21PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [allproWithJan22PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [allproWithFeb22PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [allproWithMar22PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [allproWithApr22PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [allproWithMay22PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [allproWithJun22PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [allproWithJul22PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [allproWithAug22PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [allproWithSep22PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [allproWithOct22PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [allproWithNov22PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [allproWithDec22PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [allproWithJan23PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [allproWithFeb23PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [allproWithMar23PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [allproWithApr23PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [allproWithMay23PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [allproWithJun23PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [allproWithJul23PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [allproWithAug23PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [allproWithSep23PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [allproWithOct23PerVisit[3]]
                : Providers === 'All Providers' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [allproWithNov23PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro1WithJan21PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro1WithFeb21PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro1WithMar21PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro1WithApr21PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro1WithMay21PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro1WithJun21PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro1WithJul21PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro1WithAug21PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro1WithSep21PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro1WithOct21PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro1WithNov21PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro1WithDec21PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro1WithJan22PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro1WithFeb22PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro1WithMar22PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro1WithApr22PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro1WithMay22PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro1WithJun22PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro1WithJul22PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro1WithAug22PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro1WithSep22PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro1WithOct22PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro1WithNov22PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro1WithDec22PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro1WithJan23PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro1WithFeb23PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro1WithMar23PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro1WithApr23PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro1WithMay23PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro1WithJun23PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro1WithJul23PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro1WithAug23PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro1WithSep23PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro1WithOct23PerVisit[3]]
                : Providers === 'Provider1' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro1WithNov23PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro2WithJan21PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro2WithFeb21PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro2WithMar21PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro2WithApr21PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro2WithMay21PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro2WithJun21PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro2WithJul21PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro2WithAug21PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro2WithSep21PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro2WithOct21PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro2WithNov21PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro2WithDec21PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro2WithJan22PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro2WithFeb22PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro2WithMar22PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro2WithApr22PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro2WithMay22PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro2WithJun22PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro2WithJul22PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro2WithAug22PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro2WithSep22PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro2WithOct22PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro2WithNov22PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro2WithDec22PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro2WithJan23PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro2WithFeb23PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro2WithMar23PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro2WithApr23PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro2WithMay23PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro2WithJun23PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro2WithJul23PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro2WithAug23PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro2WithSep23PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro2WithOct23PerVisit[3]]
                : Providers === 'Provider2' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro2WithNov23PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro3WithJan21PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro3WithFeb21PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro3WithMar21PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro3WithApr21PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro3WithMay21PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro3WithJun21PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro3WithJul21PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro3WithAug21PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro3WithSep21PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro3WithOct21PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro3WithNov21PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro3WithDec21PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro3WithJan22PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro3WithFeb22PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro3WithMar22PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro3WithApr22PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro3WithMay22PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro3WithJun22PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro3WithJul22PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro3WithAug22PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro3WithSep22PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro3WithOct22PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro3WithNov22PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro3WithDec22PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro3WithJan23PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro3WithFeb23PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro3WithMar23PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro3WithApr23PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro3WithMay23PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro3WithJun23PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro3WithJul23PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro3WithAug23PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro3WithSep23PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro3WithOct23PerVisit[3]]
                : Providers === 'Provider3' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro3WithNov23PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro4WithJan21PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro4WithFeb21PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro4WithMar21PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro4WithApr21PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro4WithMay21PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro4WithJun21PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro4WithJul21PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro4WithAug21PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro4WithSep21PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro4WithOct21PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro4WithNov21PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro4WithDec21PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro4WithJan22PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro4WithFeb22PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro4WithMar22PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro4WithApr22PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro4WithMay22PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro4WithJun22PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro4WithJul22PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro4WithAug22PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro4WithSep22PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro4WithOct22PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro4WithNov22PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro4WithDec22PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro4WithJan23PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro4WithFeb23PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro4WithMar23PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro4WithApr23PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro4WithMay23PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro4WithJun23PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro4WithJul23PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro4WithAug23PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro4WithSep23PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro4WithOct23PerVisit[3]]
                : Providers === 'Provider4' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro4WithNov23PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro5WithJan21PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro5WithFeb21PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro5WithMar21PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro5WithApr21PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro5WithMay21PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro5WithJun21PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro5WithJul21PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro5WithAug21PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro5WithSep21PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro5WithOct21PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro5WithNov21PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro5WithDec21PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro5WithJan22PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro5WithFeb22PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro5WithMar22PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro5WithApr22PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro5WithMay22PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro5WithJun22PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro5WithJul22PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro5WithAug22PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro5WithSep22PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro5WithOct22PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro5WithNov22PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro5WithDec22PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro5WithJan23PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro5WithFeb23PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro5WithMar23PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro5WithApr23PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro5WithMay23PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro5WithJun23PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro5WithJul23PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro5WithAug23PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro5WithSep23PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro5WithOct23PerVisit[3]]
                : Providers === 'Provider5' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro5WithNov23PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro6WithJan21PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro6WithFeb21PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro6WithMar21PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro6WithApr21PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro6WithMay21PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro6WithJun21PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro6WithJul21PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro6WithAug21PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro6WithSep21PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro6WithOct21PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro6WithNov21PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro6WithDec21PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro6WithJan22PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro6WithFeb22PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro6WithMar22PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro6WithApr22PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro6WithMay22PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro6WithJun22PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro6WithJul22PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro6WithAug22PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro6WithSep22PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro6WithOct22PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro6WithNov22PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro6WithDec22PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro6WithJan23PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro6WithFeb23PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro6WithMar23PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro6WithApr23PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro6WithMay23PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro6WithJun23PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro6WithJul23PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro6WithAug23PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro6WithSep23PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro6WithOct23PerVisit[3]]
                : Providers === 'Provider6' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro6WithNov23PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jan-21' && session === 'RX' ? [pro7WithJan21PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Feb-21' && session === 'RX' ? [pro7WithFeb21PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Mar-21' && session === 'RX' ? [pro7WithMar21PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Apr-21' && session === 'RX' ? [pro7WithApr21PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'May-21' && session === 'RX' ? [pro7WithMay21PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jun-21' && session === 'RX' ? [pro7WithJun21PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jul-21' && session === 'RX' ? [pro7WithJul21PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Aug-21' && session === 'RX' ? [pro7WithAug21PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Sep-21' && session === 'RX' ? [pro7WithSep21PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Oct-21' && session === 'RX' ? [pro7WithOct21PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Nov-21' && session === 'RX' ? [pro7WithNov21PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Dec-21' && session === 'RX' ? [pro7WithDec21PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jan-22' && session === 'RX' ? [pro7WithJan22PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Feb-22' && session === 'RX' ? [pro7WithFeb22PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Mar-22' && session === 'RX' ? [pro7WithMar22PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Apr-22' && session === 'RX' ? [pro7WithApr22PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'May-22' && session === 'RX' ? [pro7WithMay22PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jun-22' && session === 'RX' ? [pro7WithJun22PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jul-22' && session === 'RX' ? [pro7WithJul22PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Aug-22' && session === 'RX' ? [pro7WithAug22PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Sep-22' && session === 'RX' ? [pro7WithSep22PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Oct-22' && session === 'RX' ? [pro7WithOct22PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Nov-22' && session === 'RX' ? [pro7WithNov22PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Dec-22' && session === 'RX' ? [pro7WithDec22PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jan-23' && session === 'RX' ? [pro7WithJan23PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Feb-23' && session === 'RX' ? [pro7WithFeb23PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Mar-23' && session === 'RX' ? [pro7WithMar23PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Apr-23' && session === 'RX' ? [pro7WithApr23PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'May-23' && session === 'RX' ? [pro7WithMay23PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jun-23' && session === 'RX' ? [pro7WithJun23PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Jul-23' && session === 'RX' ? [pro7WithJul23PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Aug-23' && session === 'RX' ? [pro7WithAug23PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Sep-23' && session === 'RX' ? [pro7WithSep23PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Oct-23' && session === 'RX' ? [pro7WithOct23PerVisit[3]]
                : Providers === 'Provider7' && Facility === 'All Facilities' && Month === 'Nov-23' && session === 'RX' ? [pro7WithNov23PerVisit[3]]
                : ''
            }
]
// 26-12-2023 end 

    let providersInMonth = [{
        name: 'Charges',
        data: Providers === 'All Providers' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21Charges[3]]
            : Providers === 'All Providers' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21Charges[3]]
            : Providers === 'All Providers' && Month === 'Mar-21' && session === 'RX' ? [allproWithMar21Charges[3]]
            : Providers === 'All Providers' && Month === 'Apr-21' && session === 'RX' ? [allproWithApr21Charges[3]]
            : Providers === 'All Providers' && Month === 'May-21' && session === 'RX' ? [allproWithMay21Charges[3]]
            : Providers === 'All Providers' && Month === 'Jun-21' && session === 'RX' ? [allproWithJun21Charges[3]]
            : Providers === 'All Providers' && Month === 'Jul-21' && session === 'RX' ? [allproWithJul21Charges[3]]
            : Providers === 'All Providers' && Month === 'Aug-21' && session === 'RX' ? [allproWithAug21Charges[3]]
            : Providers === 'All Providers' && Month === 'Sep-21' && session === 'RX' ? [allproWithSep21Charges[3]]
            : Providers === 'All Providers' && Month === 'Oct-21' && session === 'RX' ? [allproWithOct21Charges[3]]
            : Providers === 'All Providers' && Month === 'Nov-21' && session === 'RX' ? [allproWithNov21Charges[3]]
            : Providers === 'All Providers' && Month === 'Dec-21' && session === 'RX' ? [allproWithDec21Charges[3]]
            : Providers === 'All Providers' && Month === 'Jan-22' && session === 'RX' ? [allproWithJan22Charges[3]]
            : Providers === 'All Providers' && Month === 'Feb-22' && session === 'RX' ? [allproWithFeb22Charges[3]]
            : Providers === 'All Providers' && Month === 'Mar-22' && session === 'RX' ? [allproWithMar22Charges[3]]
            : Providers === 'All Providers' && Month === 'Apr-22' && session === 'RX' ? [allproWithApr22Charges[3]]
            : Providers === 'All Providers' && Month === 'May-22' && session === 'RX' ? [allproWithMay22Charges[3]]
            : Providers === 'All Providers' && Month === 'Jun-22' && session === 'RX' ? [allproWithJun22Charges[3]]
            : Providers === 'All Providers' && Month === 'Jul-22' && session === 'RX' ? [allproWithJul22Charges[3]]
            : Providers === 'All Providers' && Month === 'Aug-22' && session === 'RX' ? [allproWithAug22Charges[3]]
            : Providers === 'All Providers' && Month === 'Sep-22' && session === 'RX' ? [allproWithSep22Charges[3]]
            : Providers === 'All Providers' && Month === 'Oct-22' && session === 'RX' ? [allproWithOct22Charges[3]]
            : Providers === 'All Providers' && Month === 'Nov-22' && session === 'RX' ? [allproWithNov22Charges[3]]
            : Providers === 'All Providers' && Month === 'Dec-22' && session === 'RX' ? [allproWithDec22Charges[3]]
            : Providers === 'All Providers' && Month === 'Jan-23' && session === 'RX' ? [allproWithJan23Charges[3]]
            : Providers === 'All Providers' && Month === 'Feb-23' && session === 'RX' ? [allproWithFeb23Charges[3]]
            : Providers === 'All Providers' && Month === 'Mar-23' && session === 'RX' ? [allproWithMar23Charges[3]]
            : Providers === 'All Providers' && Month === 'Apr-23' && session === 'RX' ? [allproWithApr23Charges[3]]
            : Providers === 'All Providers' && Month === 'May-23' && session === 'RX' ? [allproWithMay23Charges[3]]
            : Providers === 'All Providers' && Month === 'Jun-23' && session === 'RX' ? [allproWithJun23Charges[3]]
            : Providers === 'All Providers' && Month === 'Jul-23' && session === 'RX' ? [allproWithJul23Charges[3]]
            : Providers === 'All Providers' && Month === 'Aug-23' && session === 'RX' ? [allproWithAug23Charges[3]]
            : Providers === 'All Providers' && Month === 'Sep-23' && session === 'RX' ? [allproWithSep23Charges[3]]
            : Providers === 'All Providers' && Month === 'Oct-23' && session === 'RX' ? [allproWithOct23Charges[3]]
            : Providers === 'All Providers' && Month === 'Nov-23' && session === 'RX' ? [allproWithNov23Charges[3]]
            : Providers === 'Provider1' && Month === 'Jan-21' && session === 'RX' ? [pro1WithJan21Charges[3]]
            : Providers === 'Provider1' && Month === 'Feb-21' && session === 'RX' ? [pro1WithFeb21Charges[3]]
            : Providers === 'Provider1' && Month === 'Mar-21' && session === 'RX' ? [pro1WithMar21Charges[3]]
            : Providers === 'Provider1' && Month === 'Apr-21' && session === 'RX' ? [pro1WithApr21Charges[3]]
            : Providers === 'Provider1' && Month === 'May-21' && session === 'RX' ? [pro1WithMay21Charges[3]]
            : Providers === 'Provider1' && Month === 'Jun-21' && session === 'RX' ? [pro1WithJun21Charges[3]]
            : Providers === 'Provider1' && Month === 'Jul-21' && session === 'RX' ? [pro1WithJul21Charges[3]]
            : Providers === 'Provider1' && Month === 'Aug-21' && session === 'RX' ? [pro1WithAug21Charges[3]]
            : Providers === 'Provider1' && Month === 'Sep-21' && session === 'RX' ? [pro1WithSep21Charges[3]]
            : Providers === 'Provider1' && Month === 'Oct-21' && session === 'RX' ? [pro1WithOct21Charges[3]]
            : Providers === 'Provider1' && Month === 'Nov-21' && session === 'RX' ? [pro1WithNov21Charges[3]]
            : Providers === 'Provider1' && Month === 'Dec-21' && session === 'RX' ? [pro1WithDec21Charges[3]]
            : Providers === 'Provider1' && Month === 'Jan-22' && session === 'RX' ? [pro1WithJan22Charges[3]]
            : Providers === 'Provider1' && Month === 'Feb-22' && session === 'RX' ? [pro1WithFeb22Charges[3]]
            : Providers === 'Provider1' && Month === 'Mar-22' && session === 'RX' ? [pro1WithMar22Charges[3]]
            : Providers === 'Provider1' && Month === 'Apr-22' && session === 'RX' ? [pro1WithApr22Charges[3]]
            : Providers === 'Provider1' && Month === 'May-22' && session === 'RX' ? [pro1WithMay22Charges[3]]
            : Providers === 'Provider1' && Month === 'Jun-22' && session === 'RX' ? [pro1WithJun22Charges[3]]
            : Providers === 'Provider1' && Month === 'Jul-22' && session === 'RX' ? [pro1WithJul22Charges[3]]
            : Providers === 'Provider1' && Month === 'Aug-22' && session === 'RX' ? [pro1WithAug22Charges[3]]
            : Providers === 'Provider1' && Month === 'Sep-22' && session === 'RX' ? [pro1WithSep22Charges[3]]
            : Providers === 'Provider1' && Month === 'Oct-22' && session === 'RX' ? [pro1WithOct22Charges[3]]
            : Providers === 'Provider1' && Month === 'Nov-22' && session === 'RX' ? [pro1WithNov22Charges[3]]
            : Providers === 'Provider1' && Month === 'Dec-22' && session === 'RX' ? [pro1WithDec22Charges[3]]
            : Providers === 'Provider1' && Month === 'Jan-23' && session === 'RX' ? [pro1WithJan23Charges[3]]
            : Providers === 'Provider1' && Month === 'Feb-23' && session === 'RX' ? [pro1WithFeb23Charges[3]]
            : Providers === 'Provider1' && Month === 'Mar-23' && session === 'RX' ? [pro1WithMar23Charges[3]]
            : Providers === 'Provider1' && Month === 'Apr-23' && session === 'RX' ? [pro1WithApr23Charges[3]]
            : Providers === 'Provider1' && Month === 'May-23' && session === 'RX' ? [pro1WithMay23Charges[3]]
            : Providers === 'Provider1' && Month === 'Jun-23' && session === 'RX' ? [pro1WithJun23Charges[3]]
            : Providers === 'Provider1' && Month === 'Jul-23' && session === 'RX' ? [pro1WithJul23Charges[3]]
            : Providers === 'Provider1' && Month === 'Aug-23' && session === 'RX' ? [pro1WithAug23Charges[3]]
            : Providers === 'Provider1' && Month === 'Sep-23' && session === 'RX' ? [pro1WithSep23Charges[3]]
            : Providers === 'Provider1' && Month === 'Oct-23' && session === 'RX' ? [pro1WithOct23Charges[3]]
            : Providers === 'Provider1' && Month === 'Nov-23' && session === 'RX' ? [pro1WithNov23Charges[3]]
            : Providers === 'Provider2' && Month === 'Jan-21' && session === 'RX' ? [pro2WithJan21Charges[3]]
            : Providers === 'Provider2' && Month === 'Feb-21' && session === 'RX' ? [pro2WithFeb21Charges[3]]
            : Providers === 'Provider2' && Month === 'Mar-21' && session === 'RX' ? [pro2WithMar21Charges[3]]
            : Providers === 'Provider2' && Month === 'Apr-21' && session === 'RX' ? [pro2WithApr21Charges[3]]
            : Providers === 'Provider2' && Month === 'May-21' && session === 'RX' ? [pro2WithMay21Charges[3]]
            : Providers === 'Provider2' && Month === 'Jun-21' && session === 'RX' ? [pro2WithJun21Charges[3]]
            : Providers === 'Provider2' && Month === 'Jul-21' && session === 'RX' ? [pro2WithJul21Charges[3]]
            : Providers === 'Provider2' && Month === 'Aug-21' && session === 'RX' ? [pro2WithAug21Charges[3]]
            : Providers === 'Provider2' && Month === 'Sep-21' && session === 'RX' ? [pro2WithSep21Charges[3]]
            : Providers === 'Provider2' && Month === 'Oct-21' && session === 'RX' ? [pro2WithOct21Charges[3]]
            : Providers === 'Provider2' && Month === 'Nov-21' && session === 'RX' ? [pro2WithNov21Charges[3]]
            : Providers === 'Provider2' && Month === 'Dec-21' && session === 'RX' ? [pro2WithDec21Charges[3]]
            : Providers === 'Provider2' && Month === 'Jan-22' && session === 'RX' ? [pro2WithJan22Charges[3]]
            : Providers === 'Provider2' && Month === 'Feb-22' && session === 'RX' ? [pro2WithFeb22Charges[3]]
            : Providers === 'Provider2' && Month === 'Mar-22' && session === 'RX' ? [pro2WithMar22Charges[3]]
            : Providers === 'Provider2' && Month === 'Apr-22' && session === 'RX' ? [pro2WithApr22Charges[3]]
            : Providers === 'Provider2' && Month === 'May-22' && session === 'RX' ? [pro2WithMay22Charges[3]]
            : Providers === 'Provider2' && Month === 'Jun-22' && session === 'RX' ? [pro2WithJun22Charges[3]]
            : Providers === 'Provider2' && Month === 'Jul-22' && session === 'RX' ? [pro2WithJul22Charges[3]]
            : Providers === 'Provider2' && Month === 'Aug-22' && session === 'RX' ? [pro2WithAug22Charges[3]]
            : Providers === 'Provider2' && Month === 'Sep-22' && session === 'RX' ? [pro2WithSep22Charges[3]]
            : Providers === 'Provider2' && Month === 'Oct-22' && session === 'RX' ? [pro2WithOct22Charges[3]]
            : Providers === 'Provider2' && Month === 'Nov-22' && session === 'RX' ? [pro2WithNov22Charges[3]]
            : Providers === 'Provider2' && Month === 'Dec-22' && session === 'RX' ? [pro2WithDec22Charges[3]]
            : Providers === 'Provider2' && Month === 'Jan-23' && session === 'RX' ? [pro2WithJan23Charges[3]]
            : Providers === 'Provider2' && Month === 'Feb-23' && session === 'RX' ? [pro2WithFeb23Charges[3]]
            : Providers === 'Provider2' && Month === 'Mar-23' && session === 'RX' ? [pro2WithMar23Charges[3]]
            : Providers === 'Provider2' && Month === 'Apr-23' && session === 'RX' ? [pro2WithApr23Charges[3]]
            : Providers === 'Provider2' && Month === 'May-23' && session === 'RX' ? [pro2WithMay23Charges[3]]
            : Providers === 'Provider2' && Month === 'Jun-23' && session === 'RX' ? [pro2WithJun23Charges[3]]
            : Providers === 'Provider2' && Month === 'Jul-23' && session === 'RX' ? [pro2WithJul23Charges[3]]
            : Providers === 'Provider2' && Month === 'Aug-23' && session === 'RX' ? [pro2WithAug23Charges[3]]
            : Providers === 'Provider2' && Month === 'Sep-23' && session === 'RX' ? [pro2WithSep23Charges[3]]
            : Providers === 'Provider2' && Month === 'Oct-23' && session === 'RX' ? [pro2WithOct23Charges[3]]
            : Providers === 'Provider2' && Month === 'Nov-23' && session === 'RX' ? [pro2WithNov23Charges[3]]
            : Providers === 'Provider3' && Month === 'Jan-21' && session === 'RX' ? [pro3WithJan21Charges[3]]
            : Providers === 'Provider3' && Month === 'Feb-21' && session === 'RX' ? [pro3WithFeb21Charges[3]]
            : Providers === 'Provider3' && Month === 'Mar-21' && session === 'RX' ? [pro3WithMar21Charges[3]]
            : Providers === 'Provider3' && Month === 'Apr-21' && session === 'RX' ? [pro3WithApr21Charges[3]]
            : Providers === 'Provider3' && Month === 'May-21' && session === 'RX' ? [pro3WithMay21Charges[3]]
            : Providers === 'Provider3' && Month === 'Jun-21' && session === 'RX' ? [pro3WithJun21Charges[3]]
            : Providers === 'Provider3' && Month === 'Jul-21' && session === 'RX' ? [pro3WithJul21Charges[3]]
            : Providers === 'Provider3' && Month === 'Aug-21' && session === 'RX' ? [pro3WithAug21Charges[3]]
            : Providers === 'Provider3' && Month === 'Sep-21' && session === 'RX' ? [pro3WithSep21Charges[3]]
            : Providers === 'Provider3' && Month === 'Oct-21' && session === 'RX' ? [pro3WithOct21Charges[3]]
            : Providers === 'Provider3' && Month === 'Nov-21' && session === 'RX' ? [pro3WithNov21Charges[3]]
            : Providers === 'Provider3' && Month === 'Dec-21' && session === 'RX' ? [pro3WithDec21Charges[3]]
            : Providers === 'Provider3' && Month === 'Jan-22' && session === 'RX' ? [pro3WithJan22Charges[3]]
            : Providers === 'Provider3' && Month === 'Feb-22' && session === 'RX' ? [pro3WithFeb22Charges[3]]
            : Providers === 'Provider3' && Month === 'Mar-22' && session === 'RX' ? [pro3WithMar22Charges[3]]
            : Providers === 'Provider3' && Month === 'Apr-22' && session === 'RX' ? [pro3WithApr22Charges[3]]
            : Providers === 'Provider3' && Month === 'May-22' && session === 'RX' ? [pro3WithMay22Charges[3]]
            : Providers === 'Provider3' && Month === 'Jun-22' && session === 'RX' ? [pro3WithJun22Charges[3]]
            : Providers === 'Provider3' && Month === 'Jul-22' && session === 'RX' ? [pro3WithJul22Charges[3]]
            : Providers === 'Provider3' && Month === 'Aug-22' && session === 'RX' ? [pro3WithAug22Charges[3]]
            : Providers === 'Provider3' && Month === 'Sep-22' && session === 'RX' ? [pro3WithSep22Charges[3]]
            : Providers === 'Provider3' && Month === 'Oct-22' && session === 'RX' ? [pro3WithOct22Charges[3]]
            : Providers === 'Provider3' && Month === 'Nov-22' && session === 'RX' ? [pro3WithNov22Charges[3]]
            : Providers === 'Provider3' && Month === 'Dec-22' && session === 'RX' ? [pro3WithDec22Charges[3]]
            : Providers === 'Provider3' && Month === 'Jan-23' && session === 'RX' ? [pro3WithJan23Charges[3]]
            : Providers === 'Provider3' && Month === 'Feb-23' && session === 'RX' ? [pro3WithFeb23Charges[3]]
            : Providers === 'Provider3' && Month === 'Mar-23' && session === 'RX' ? [pro3WithMar23Charges[3]]
            : Providers === 'Provider3' && Month === 'Apr-23' && session === 'RX' ? [pro3WithApr23Charges[3]]
            : Providers === 'Provider3' && Month === 'May-23' && session === 'RX' ? [pro3WithMay23Charges[3]]
            : Providers === 'Provider3' && Month === 'Jun-23' && session === 'RX' ? [pro3WithJun23Charges[3]]
            : Providers === 'Provider3' && Month === 'Jul-23' && session === 'RX' ? [pro3WithJul23Charges[3]]
            : Providers === 'Provider3' && Month === 'Aug-23' && session === 'RX' ? [pro3WithAug23Charges[3]]
            : Providers === 'Provider3' && Month === 'Sep-23' && session === 'RX' ? [pro3WithSep23Charges[3]]
            : Providers === 'Provider3' && Month === 'Oct-23' && session === 'RX' ? [pro3WithOct23Charges[3]]
            : Providers === 'Provider3' && Month === 'Nov-23' && session === 'RX' ? [pro3WithNov23Charges[3]]
            : Providers === 'Provider4' && Month === 'Jan-21' && session === 'RX' ? [pro4WithJan21Charges[3]]
            : Providers === 'Provider4' && Month === 'Feb-21' && session === 'RX' ? [pro4WithFeb21Charges[3]]
            : Providers === 'Provider4' && Month === 'Mar-21' && session === 'RX' ? [pro4WithMar21Charges[3]]
            : Providers === 'Provider4' && Month === 'Apr-21' && session === 'RX' ? [pro4WithApr21Charges[3]]
            : Providers === 'Provider4' && Month === 'May-21' && session === 'RX' ? [pro4WithMay21Charges[3]]
            : Providers === 'Provider4' && Month === 'Jun-21' && session === 'RX' ? [pro4WithJun21Charges[3]]
            : Providers === 'Provider4' && Month === 'Jul-21' && session === 'RX' ? [pro4WithJul21Charges[3]]
            : Providers === 'Provider4' && Month === 'Aug-21' && session === 'RX' ? [pro4WithAug21Charges[3]]
            : Providers === 'Provider4' && Month === 'Sep-21' && session === 'RX' ? [pro4WithSep21Charges[3]]
            : Providers === 'Provider4' && Month === 'Oct-21' && session === 'RX' ? [pro4WithOct21Charges[3]]
            : Providers === 'Provider4' && Month === 'Nov-21' && session === 'RX' ? [pro4WithNov21Charges[3]]
            : Providers === 'Provider4' && Month === 'Dec-21' && session === 'RX' ? [pro4WithDec21Charges[3]]
            : Providers === 'Provider4' && Month === 'Jan-22' && session === 'RX' ? [pro4WithJan22Charges[3]]
            : Providers === 'Provider4' && Month === 'Feb-22' && session === 'RX' ? [pro4WithFeb22Charges[3]]
            : Providers === 'Provider4' && Month === 'Mar-22' && session === 'RX' ? [pro4WithMar22Charges[3]]
            : Providers === 'Provider4' && Month === 'Apr-22' && session === 'RX' ? [pro4WithApr22Charges[3]]
            : Providers === 'Provider4' && Month === 'May-22' && session === 'RX' ? [pro4WithMay22Charges[3]]
            : Providers === 'Provider4' && Month === 'Jun-22' && session === 'RX' ? [pro4WithJun22Charges[3]]
            : Providers === 'Provider4' && Month === 'Jul-22' && session === 'RX' ? [pro4WithJul22Charges[3]]
            : Providers === 'Provider4' && Month === 'Aug-22' && session === 'RX' ? [pro4WithAug22Charges[3]]
            : Providers === 'Provider4' && Month === 'Sep-22' && session === 'RX' ? [pro4WithSep22Charges[3]]
            : Providers === 'Provider4' && Month === 'Oct-22' && session === 'RX' ? [pro4WithOct22Charges[3]]
            : Providers === 'Provider4' && Month === 'Nov-22' && session === 'RX' ? [pro4WithNov22Charges[3]]
            : Providers === 'Provider4' && Month === 'Dec-22' && session === 'RX' ? [pro4WithDec22Charges[3]]
            : Providers === 'Provider4' && Month === 'Jan-23' && session === 'RX' ? [pro4WithJan23Charges[3]]
            : Providers === 'Provider4' && Month === 'Feb-23' && session === 'RX' ? [pro4WithFeb23Charges[3]]
            : Providers === 'Provider4' && Month === 'Mar-23' && session === 'RX' ? [pro4WithMar23Charges[3]]
            : Providers === 'Provider4' && Month === 'Apr-23' && session === 'RX' ? [pro4WithApr23Charges[3]]
            : Providers === 'Provider4' && Month === 'May-23' && session === 'RX' ? [pro4WithMay23Charges[3]]
            : Providers === 'Provider4' && Month === 'Jun-23' && session === 'RX' ? [pro4WithJun23Charges[3]]
            : Providers === 'Provider4' && Month === 'Jul-23' && session === 'RX' ? [pro4WithJul23Charges[3]]
            : Providers === 'Provider4' && Month === 'Aug-23' && session === 'RX' ? [pro4WithAug23Charges[3]]
            : Providers === 'Provider4' && Month === 'Sep-23' && session === 'RX' ? [pro4WithSep23Charges[3]]
            : Providers === 'Provider4' && Month === 'Oct-23' && session === 'RX' ? [pro4WithOct23Charges[3]]
            : Providers === 'Provider4' && Month === 'Nov-23' && session === 'RX' ? [pro4WithNov23Charges[3]]
            : Providers === 'Provider5' && Month === 'Jan-21' && session === 'RX' ? [pro5WithJan21Charges[3]]
            : Providers === 'Provider5' && Month === 'Feb-21' && session === 'RX' ? [pro5WithFeb21Charges[3]]
            : Providers === 'Provider5' && Month === 'Mar-21' && session === 'RX' ? [pro5WithMar21Charges[3]]
            : Providers === 'Provider5' && Month === 'Apr-21' && session === 'RX' ? [pro5WithApr21Charges[3]]
            : Providers === 'Provider5' && Month === 'May-21' && session === 'RX' ? [pro5WithMay21Charges[3]]
            : Providers === 'Provider5' && Month === 'Jun-21' && session === 'RX' ? [pro5WithJun21Charges[3]]
            : Providers === 'Provider5' && Month === 'Jul-21' && session === 'RX' ? [pro5WithJul21Charges[3]]
            : Providers === 'Provider5' && Month === 'Aug-21' && session === 'RX' ? [pro5WithAug21Charges[3]]
            : Providers === 'Provider5' && Month === 'Sep-21' && session === 'RX' ? [pro5WithSep21Charges[3]]
            : Providers === 'Provider5' && Month === 'Oct-21' && session === 'RX' ? [pro5WithOct21Charges[3]]
            : Providers === 'Provider5' && Month === 'Nov-21' && session === 'RX' ? [pro5WithNov21Charges[3]]
            : Providers === 'Provider5' && Month === 'Dec-21' && session === 'RX' ? [pro5WithDec21Charges[3]]
            : Providers === 'Provider5' && Month === 'Jan-22' && session === 'RX' ? [pro5WithJan22Charges[3]]
            : Providers === 'Provider5' && Month === 'Feb-22' && session === 'RX' ? [pro5WithFeb22Charges[3]]
            : Providers === 'Provider5' && Month === 'Mar-22' && session === 'RX' ? [pro5WithMar22Charges[3]]
            : Providers === 'Provider5' && Month === 'Apr-22' && session === 'RX' ? [pro5WithApr22Charges[3]]
            : Providers === 'Provider5' && Month === 'May-22' && session === 'RX' ? [pro5WithMay22Charges[3]]
            : Providers === 'Provider5' && Month === 'Jun-22' && session === 'RX' ? [pro5WithJun22Charges[3]]
            : Providers === 'Provider5' && Month === 'Jul-22' && session === 'RX' ? [pro5WithJul22Charges[3]]
            : Providers === 'Provider5' && Month === 'Aug-22' && session === 'RX' ? [pro5WithAug22Charges[3]]
            : Providers === 'Provider5' && Month === 'Sep-22' && session === 'RX' ? [pro5WithSep22Charges[3]]
            : Providers === 'Provider5' && Month === 'Oct-22' && session === 'RX' ? [pro5WithOct22Charges[3]]
            : Providers === 'Provider5' && Month === 'Nov-22' && session === 'RX' ? [pro5WithNov22Charges[3]]
            : Providers === 'Provider5' && Month === 'Dec-22' && session === 'RX' ? [pro5WithDec22Charges[3]]
            : Providers === 'Provider5' && Month === 'Jan-23' && session === 'RX' ? [pro5WithJan23Charges[3]]
            : Providers === 'Provider5' && Month === 'Feb-23' && session === 'RX' ? [pro5WithFeb23Charges[3]]
            : Providers === 'Provider5' && Month === 'Mar-23' && session === 'RX' ? [pro5WithMar23Charges[3]]
            : Providers === 'Provider5' && Month === 'Apr-23' && session === 'RX' ? [pro5WithApr23Charges[3]]
            : Providers === 'Provider5' && Month === 'May-23' && session === 'RX' ? [pro5WithMay23Charges[3]]
            : Providers === 'Provider5' && Month === 'Jun-23' && session === 'RX' ? [pro5WithJun23Charges[3]]
            : Providers === 'Provider5' && Month === 'Jul-23' && session === 'RX' ? [pro5WithJul23Charges[3]]
            : Providers === 'Provider5' && Month === 'Aug-23' && session === 'RX' ? [pro5WithAug23Charges[3]]
            : Providers === 'Provider5' && Month === 'Sep-23' && session === 'RX' ? [pro5WithSep23Charges[3]]
            : Providers === 'Provider5' && Month === 'Oct-23' && session === 'RX' ? [pro5WithOct23Charges[3]]
            : Providers === 'Provider5' && Month === 'Nov-23' && session === 'RX' ? [pro5WithNov23Charges[3]]
            : Providers === 'Provider6' && Month === 'Jan-21' && session === 'RX' ? [pro6WithJan21Charges[3]]
            : Providers === 'Provider6' && Month === 'Feb-21' && session === 'RX' ? [pro6WithFeb21Charges[3]]
            : Providers === 'Provider6' && Month === 'Mar-21' && session === 'RX' ? [pro6WithMar21Charges[3]]
            : Providers === 'Provider6' && Month === 'Apr-21' && session === 'RX' ? [pro6WithApr21Charges[3]]
            : Providers === 'Provider6' && Month === 'May-21' && session === 'RX' ? [pro6WithMay21Charges[3]]
            : Providers === 'Provider6' && Month === 'Jun-21' && session === 'RX' ? [pro6WithJun21Charges[3]]
            : Providers === 'Provider6' && Month === 'Jul-21' && session === 'RX' ? [pro6WithJul21Charges[3]]
            : Providers === 'Provider6' && Month === 'Aug-21' && session === 'RX' ? [pro6WithAug21Charges[3]]
            : Providers === 'Provider6' && Month === 'Sep-21' && session === 'RX' ? [pro6WithSep21Charges[3]]
            : Providers === 'Provider6' && Month === 'Oct-21' && session === 'RX' ? [pro6WithOct21Charges[3]]
            : Providers === 'Provider6' && Month === 'Nov-21' && session === 'RX' ? [pro6WithNov21Charges[3]]
            : Providers === 'Provider6' && Month === 'Dec-21' && session === 'RX' ? [pro6WithDec21Charges[3]]
            : Providers === 'Provider6' && Month === 'Jan-22' && session === 'RX' ? [pro6WithJan22Charges[3]]
            : Providers === 'Provider6' && Month === 'Feb-22' && session === 'RX' ? [pro6WithFeb22Charges[3]]
            : Providers === 'Provider6' && Month === 'Mar-22' && session === 'RX' ? [pro6WithMar22Charges[3]]
            : Providers === 'Provider6' && Month === 'Apr-22' && session === 'RX' ? [pro6WithApr22Charges[3]]
            : Providers === 'Provider6' && Month === 'May-22' && session === 'RX' ? [pro6WithMay22Charges[3]]
            : Providers === 'Provider6' && Month === 'Jun-22' && session === 'RX' ? [pro6WithJun22Charges[3]]
            : Providers === 'Provider6' && Month === 'Jul-22' && session === 'RX' ? [pro6WithJul22Charges[3]]
            : Providers === 'Provider6' && Month === 'Aug-22' && session === 'RX' ? [pro6WithAug22Charges[3]]
            : Providers === 'Provider6' && Month === 'Sep-22' && session === 'RX' ? [pro6WithSep22Charges[3]]
            : Providers === 'Provider6' && Month === 'Oct-22' && session === 'RX' ? [pro6WithOct22Charges[3]]
            : Providers === 'Provider6' && Month === 'Nov-22' && session === 'RX' ? [pro6WithNov22Charges[3]]
            : Providers === 'Provider6' && Month === 'Dec-22' && session === 'RX' ? [pro6WithDec22Charges[3]]
            : Providers === 'Provider6' && Month === 'Jan-23' && session === 'RX' ? [pro6WithJan23Charges[3]]
            : Providers === 'Provider6' && Month === 'Feb-23' && session === 'RX' ? [pro6WithFeb23Charges[3]]
            : Providers === 'Provider6' && Month === 'Mar-23' && session === 'RX' ? [pro6WithMar23Charges[3]]
            : Providers === 'Provider6' && Month === 'Apr-23' && session === 'RX' ? [pro6WithApr23Charges[3]]
            : Providers === 'Provider6' && Month === 'May-23' && session === 'RX' ? [pro6WithMay23Charges[3]]
            : Providers === 'Provider6' && Month === 'Jun-23' && session === 'RX' ? [pro6WithJun23Charges[3]]
            : Providers === 'Provider6' && Month === 'Jul-23' && session === 'RX' ? [pro6WithJul23Charges[3]]
            : Providers === 'Provider6' && Month === 'Aug-23' && session === 'RX' ? [pro6WithAug23Charges[3]]
            : Providers === 'Provider6' && Month === 'Sep-23' && session === 'RX' ? [pro6WithSep23Charges[3]]
            : Providers === 'Provider6' && Month === 'Oct-23' && session === 'RX' ? [pro6WithOct23Charges[3]]
            : Providers === 'Provider6' && Month === 'Nov-23' && session === 'RX' ? [pro6WithNov23Charges[3]]
            : Providers === 'Provider7' && Month === 'Jan-21' && session === 'RX' ? [pro7WithJan21Charges[3]]
            : Providers === 'Provider7' && Month === 'Feb-21' && session === 'RX' ? [pro7WithFeb21Charges[3]]
            : Providers === 'Provider7' && Month === 'Mar-21' && session === 'RX' ? [pro7WithMar21Charges[3]]
            : Providers === 'Provider7' && Month === 'Apr-21' && session === 'RX' ? [pro7WithApr21Charges[3]]
            : Providers === 'Provider7' && Month === 'May-21' && session === 'RX' ? [pro7WithMay21Charges[3]]
            : Providers === 'Provider7' && Month === 'Jun-21' && session === 'RX' ? [pro7WithJun21Charges[3]]
            : Providers === 'Provider7' && Month === 'Jul-21' && session === 'RX' ? [pro7WithJul21Charges[3]]
            : Providers === 'Provider7' && Month === 'Aug-21' && session === 'RX' ? [pro7WithAug21Charges[3]]
            : Providers === 'Provider7' && Month === 'Sep-21' && session === 'RX' ? [pro7WithSep21Charges[3]]
            : Providers === 'Provider7' && Month === 'Oct-21' && session === 'RX' ? [pro7WithOct21Charges[3]]
            : Providers === 'Provider7' && Month === 'Nov-21' && session === 'RX' ? [pro7WithNov21Charges[3]]
            : Providers === 'Provider7' && Month === 'Dec-21' && session === 'RX' ? [pro7WithDec21Charges[3]]
            : Providers === 'Provider7' && Month === 'Jan-22' && session === 'RX' ? [pro7WithJan22Charges[3]]
            : Providers === 'Provider7' && Month === 'Feb-22' && session === 'RX' ? [pro7WithFeb22Charges[3]]
            : Providers === 'Provider7' && Month === 'Mar-22' && session === 'RX' ? [pro7WithMar22Charges[3]]
            : Providers === 'Provider7' && Month === 'Apr-22' && session === 'RX' ? [pro7WithApr22Charges[3]]
            : Providers === 'Provider7' && Month === 'May-22' && session === 'RX' ? [pro7WithMay22Charges[3]]
            : Providers === 'Provider7' && Month === 'Jun-22' && session === 'RX' ? [pro7WithJun22Charges[3]]
            : Providers === 'Provider7' && Month === 'Jul-22' && session === 'RX' ? [pro7WithJul22Charges[3]]
            : Providers === 'Provider7' && Month === 'Aug-22' && session === 'RX' ? [pro7WithAug22Charges[3]]
            : Providers === 'Provider7' && Month === 'Sep-22' && session === 'RX' ? [pro7WithSep22Charges[3]]
            : Providers === 'Provider7' && Month === 'Oct-22' && session === 'RX' ? [pro7WithOct22Charges[3]]
            : Providers === 'Provider7' && Month === 'Nov-22' && session === 'RX' ? [pro7WithNov22Charges[3]]
            : Providers === 'Provider7' && Month === 'Dec-22' && session === 'RX' ? [pro7WithDec22Charges[3]]
            : Providers === 'Provider7' && Month === 'Jan-23' && session === 'RX' ? [pro7WithJan23Charges[3]]
            : Providers === 'Provider7' && Month === 'Feb-23' && session === 'RX' ? [pro7WithFeb23Charges[3]]
            : Providers === 'Provider7' && Month === 'Mar-23' && session === 'RX' ? [pro7WithMar23Charges[3]]
            : Providers === 'Provider7' && Month === 'Apr-23' && session === 'RX' ? [pro7WithApr23Charges[3]]
            : Providers === 'Provider7' && Month === 'May-23' && session === 'RX' ? [pro7WithMay23Charges[3]]
            : Providers === 'Provider7' && Month === 'Jun-23' && session === 'RX' ? [pro7WithJun23Charges[3]]
            : Providers === 'Provider7' && Month === 'Jul-23' && session === 'RX' ? [pro7WithJul23Charges[3]]
            : Providers === 'Provider7' && Month === 'Aug-23' && session === 'RX' ? [pro7WithAug23Charges[3]]
            : Providers === 'Provider7' && Month === 'Sep-23' && session === 'RX' ? [pro7WithSep23Charges[3]]
            : Providers === 'Provider7' && Month === 'Oct-23' && session === 'RX' ? [pro7WithOct23Charges[3]]
            : Providers === 'Provider7' && Month === 'Nov-23' && session === 'RX' ? [pro7WithNov23Charges[3]]
            : ''
        },{
            name: 'Payments',
            data:Providers === 'All Providers' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21Pay[3]]
            : Providers === 'All Providers' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21Pay[3]]
            : Providers === 'All Providers' && Month === 'Mar-21' && session === 'RX' ? [allproWithMar21Pay[3]]
            : Providers === 'All Providers' && Month === 'Apr-21' && session === 'RX' ? [allproWithApr21Pay[3]]
            : Providers === 'All Providers' && Month === 'May-21' && session === 'RX' ? [allproWithMay21Pay[3]]
            : Providers === 'All Providers' && Month === 'Jun-21' && session === 'RX' ? [allproWithJun21Pay[3]]
            : Providers === 'All Providers' && Month === 'Jul-21' && session === 'RX' ? [allproWithJul21Pay[3]]
            : Providers === 'All Providers' && Month === 'Aug-21' && session === 'RX' ? [allproWithAug21Pay[3]]
            : Providers === 'All Providers' && Month === 'Sep-21' && session === 'RX' ? [allproWithSep21Pay[3]]
            : Providers === 'All Providers' && Month === 'Oct-21' && session === 'RX' ? [allproWithOct21Pay[3]]
            : Providers === 'All Providers' && Month === 'Nov-21' && session === 'RX' ? [allproWithNov21Pay[3]]
            : Providers === 'All Providers' && Month === 'Dec-21' && session === 'RX' ? [allproWithDec21Pay[3]]
            : Providers === 'All Providers' && Month === 'Jan-22' && session === 'RX' ? [allproWithJan22Pay[3]]
            : Providers === 'All Providers' && Month === 'Feb-22' && session === 'RX' ? [allproWithFeb22Pay[3]]
            : Providers === 'All Providers' && Month === 'Mar-22' && session === 'RX' ? [allproWithMar22Pay[3]]
            : Providers === 'All Providers' && Month === 'Apr-22' && session === 'RX' ? [allproWithApr22Pay[3]]
            : Providers === 'All Providers' && Month === 'May-22' && session === 'RX' ? [allproWithMay22Pay[3]]
            : Providers === 'All Providers' && Month === 'Jun-22' && session === 'RX' ? [allproWithJun22Pay[3]]
            : Providers === 'All Providers' && Month === 'Jul-22' && session === 'RX' ? [allproWithJul22Pay[3]]
            : Providers === 'All Providers' && Month === 'Aug-22' && session === 'RX' ? [allproWithAug22Pay[3]]
            : Providers === 'All Providers' && Month === 'Sep-22' && session === 'RX' ? [allproWithSep22Pay[3]]
            : Providers === 'All Providers' && Month === 'Oct-22' && session === 'RX' ? [allproWithOct22Pay[3]]
            : Providers === 'All Providers' && Month === 'Nov-22' && session === 'RX' ? [allproWithNov22Pay[3]]
            : Providers === 'All Providers' && Month === 'Dec-22' && session === 'RX' ? [allproWithDec22Pay[3]]
            : Providers === 'All Providers' && Month === 'Jan-23' && session === 'RX' ? [allproWithJan23Pay[3]]
            : Providers === 'All Providers' && Month === 'Feb-23' && session === 'RX' ? [allproWithFeb23Pay[3]]
            : Providers === 'All Providers' && Month === 'Mar-23' && session === 'RX' ? [allproWithMar23Pay[3]]
            : Providers === 'All Providers' && Month === 'Apr-23' && session === 'RX' ? [allproWithApr23Pay[3]]
            : Providers === 'All Providers' && Month === 'May-23' && session === 'RX' ? [allproWithMay23Pay[3]]
            : Providers === 'All Providers' && Month === 'Jun-23' && session === 'RX' ? [allproWithJun23Pay[3]]
            : Providers === 'All Providers' && Month === 'Jul-23' && session === 'RX' ? [allproWithJul23Pay[3]]
            : Providers === 'All Providers' && Month === 'Aug-23' && session === 'RX' ? [allproWithAug23Pay[3]]
            : Providers === 'All Providers' && Month === 'Sep-23' && session === 'RX' ? [allproWithSep23Pay[3]]
            : Providers === 'All Providers' && Month === 'Oct-23' && session === 'RX' ? [allproWithOct23Pay[3]]
            : Providers === 'All Providers' && Month === 'Nov-23' && session === 'RX' ? [allproWithNov23Pay[3]]
            : Providers === 'Provider1' && Month === 'Jan-21' && session === 'RX' ? [pro1WithJan21Pay[3]]
            : Providers === 'Provider1' && Month === 'Feb-21' && session === 'RX' ? [pro1WithFeb21Pay[3]]
            : Providers === 'Provider1' && Month === 'Mar-21' && session === 'RX' ? [pro1WithMar21Pay[3]]
            : Providers === 'Provider1' && Month === 'Apr-21' && session === 'RX' ? [pro1WithApr21Pay[3]]
            : Providers === 'Provider1' && Month === 'May-21' && session === 'RX' ? [pro1WithMay21Pay[3]]
            : Providers === 'Provider1' && Month === 'Jun-21' && session === 'RX' ? [pro1WithJun21Pay[3]]
            : Providers === 'Provider1' && Month === 'Jul-21' && session === 'RX' ? [pro1WithJul21Pay[3]]
            : Providers === 'Provider1' && Month === 'Aug-21' && session === 'RX' ? [pro1WithAug21Pay[3]]
            : Providers === 'Provider1' && Month === 'Sep-21' && session === 'RX' ? [pro1WithSep21Pay[3]]
            : Providers === 'Provider1' && Month === 'Oct-21' && session === 'RX' ? [pro1WithOct21Pay[3]]
            : Providers === 'Provider1' && Month === 'Nov-21' && session === 'RX' ? [pro1WithNov21Pay[3]]
            : Providers === 'Provider1' && Month === 'Dec-21' && session === 'RX' ? [pro1WithDec21Pay[3]]
            : Providers === 'Provider1' && Month === 'Jan-22' && session === 'RX' ? [pro1WithJan22Pay[3]]
            : Providers === 'Provider1' && Month === 'Feb-22' && session === 'RX' ? [pro1WithFeb22Pay[3]]
            : Providers === 'Provider1' && Month === 'Mar-22' && session === 'RX' ? [pro1WithMar22Pay[3]]
            : Providers === 'Provider1' && Month === 'Apr-22' && session === 'RX' ? [pro1WithApr22Pay[3]]
            : Providers === 'Provider1' && Month === 'May-22' && session === 'RX' ? [pro1WithMay22Pay[3]]
            : Providers === 'Provider1' && Month === 'Jun-22' && session === 'RX' ? [pro1WithJun22Pay[3]]
            : Providers === 'Provider1' && Month === 'Jul-22' && session === 'RX' ? [pro1WithJul22Pay[3]]
            : Providers === 'Provider1' && Month === 'Aug-22' && session === 'RX' ? [pro1WithAug22Pay[3]]
            : Providers === 'Provider1' && Month === 'Sep-22' && session === 'RX' ? [pro1WithSep22Pay[3]]
            : Providers === 'Provider1' && Month === 'Oct-22' && session === 'RX' ? [pro1WithOct22Pay[3]]
            : Providers === 'Provider1' && Month === 'Nov-22' && session === 'RX' ? [pro1WithNov22Pay[3]]
            : Providers === 'Provider1' && Month === 'Dec-22' && session === 'RX' ? [pro1WithDec22Pay[3]]
            : Providers === 'Provider1' && Month === 'Jan-23' && session === 'RX' ? [pro1WithJan23Pay[3]]
            : Providers === 'Provider1' && Month === 'Feb-23' && session === 'RX' ? [pro1WithFeb23Pay[3]]
            : Providers === 'Provider1' && Month === 'Mar-23' && session === 'RX' ? [pro1WithMar23Pay[3]]
            : Providers === 'Provider1' && Month === 'Apr-23' && session === 'RX' ? [pro1WithApr23Pay[3]]
            : Providers === 'Provider1' && Month === 'May-23' && session === 'RX' ? [pro1WithMay23Pay[3]]
            : Providers === 'Provider1' && Month === 'Jun-23' && session === 'RX' ? [pro1WithJun23Pay[3]]
            : Providers === 'Provider1' && Month === 'Jul-23' && session === 'RX' ? [pro1WithJul23Pay[3]]
            : Providers === 'Provider1' && Month === 'Aug-23' && session === 'RX' ? [pro1WithAug23Pay[3]]
            : Providers === 'Provider1' && Month === 'Sep-23' && session === 'RX' ? [pro1WithSep23Pay[3]]
            : Providers === 'Provider1' && Month === 'Oct-23' && session === 'RX' ? [pro1WithOct23Pay[3]]
            : Providers === 'Provider1' && Month === 'Nov-23' && session === 'RX' ? [pro1WithNov23Pay[3]]
            : Providers === 'Provider2' && Month === 'Jan-21' && session === 'RX' ? [pro2WithJan21Pay[3]]
            : Providers === 'Provider2' && Month === 'Feb-21' && session === 'RX' ? [pro2WithFeb21Pay[3]]
            : Providers === 'Provider2' && Month === 'Mar-21' && session === 'RX' ? [pro2WithMar21Pay[3]]
            : Providers === 'Provider2' && Month === 'Apr-21' && session === 'RX' ? [pro2WithApr21Pay[3]]
            : Providers === 'Provider2' && Month === 'May-21' && session === 'RX' ? [pro2WithMay21Pay[3]]
            : Providers === 'Provider2' && Month === 'Jun-21' && session === 'RX' ? [pro2WithJun21Pay[3]]
            : Providers === 'Provider2' && Month === 'Jul-21' && session === 'RX' ? [pro2WithJul21Pay[3]]
            : Providers === 'Provider2' && Month === 'Aug-21' && session === 'RX' ? [pro2WithAug21Pay[3]]
            : Providers === 'Provider2' && Month === 'Sep-21' && session === 'RX' ? [pro2WithSep21Pay[3]]
            : Providers === 'Provider2' && Month === 'Oct-21' && session === 'RX' ? [pro2WithOct21Pay[3]]
            : Providers === 'Provider2' && Month === 'Nov-21' && session === 'RX' ? [pro2WithNov21Pay[3]]
            : Providers === 'Provider2' && Month === 'Dec-21' && session === 'RX' ? [pro2WithDec21Pay[3]]
            : Providers === 'Provider2' && Month === 'Jan-22' && session === 'RX' ? [pro2WithJan22Pay[3]]
            : Providers === 'Provider2' && Month === 'Feb-22' && session === 'RX' ? [pro2WithFeb22Pay[3]]
            : Providers === 'Provider2' && Month === 'Mar-22' && session === 'RX' ? [pro2WithMar22Pay[3]]
            : Providers === 'Provider2' && Month === 'Apr-22' && session === 'RX' ? [pro2WithApr22Pay[3]]
            : Providers === 'Provider2' && Month === 'May-22' && session === 'RX' ? [pro2WithMay22Pay[3]]
            : Providers === 'Provider2' && Month === 'Jun-22' && session === 'RX' ? [pro2WithJun22Pay[3]]
            : Providers === 'Provider2' && Month === 'Jul-22' && session === 'RX' ? [pro2WithJul22Pay[3]]
            : Providers === 'Provider2' && Month === 'Aug-22' && session === 'RX' ? [pro2WithAug22Pay[3]]
            : Providers === 'Provider2' && Month === 'Sep-22' && session === 'RX' ? [pro2WithSep22Pay[3]]
            : Providers === 'Provider2' && Month === 'Oct-22' && session === 'RX' ? [pro2WithOct22Pay[3]]
            : Providers === 'Provider2' && Month === 'Nov-22' && session === 'RX' ? [pro2WithNov22Pay[3]]
            : Providers === 'Provider2' && Month === 'Dec-22' && session === 'RX' ? [pro2WithDec22Pay[3]]
            : Providers === 'Provider2' && Month === 'Jan-23' && session === 'RX' ? [pro2WithJan23Pay[3]]
            : Providers === 'Provider2' && Month === 'Feb-23' && session === 'RX' ? [pro2WithFeb23Pay[3]]
            : Providers === 'Provider2' && Month === 'Mar-23' && session === 'RX' ? [pro2WithMar23Pay[3]]
            : Providers === 'Provider2' && Month === 'Apr-23' && session === 'RX' ? [pro2WithApr23Pay[3]]
            : Providers === 'Provider2' && Month === 'May-23' && session === 'RX' ? [pro2WithMay23Pay[3]]
            : Providers === 'Provider2' && Month === 'Jun-23' && session === 'RX' ? [pro2WithJun23Pay[3]]
            : Providers === 'Provider2' && Month === 'Jul-23' && session === 'RX' ? [pro2WithJul23Pay[3]]
            : Providers === 'Provider2' && Month === 'Aug-23' && session === 'RX' ? [pro2WithAug23Pay[3]]
            : Providers === 'Provider2' && Month === 'Sep-23' && session === 'RX' ? [pro2WithSep23Pay[3]]
            : Providers === 'Provider2' && Month === 'Oct-23' && session === 'RX' ? [pro2WithOct23Pay[3]]
            : Providers === 'Provider2' && Month === 'Nov-23' && session === 'RX' ? [pro2WithNov23Pay[3]]
            : Providers === 'Provider3' && Month === 'Jan-21' && session === 'RX' ? [pro3WithJan21Pay[3]]
            : Providers === 'Provider3' && Month === 'Feb-21' && session === 'RX' ? [pro3WithFeb21Pay[3]]
            : Providers === 'Provider3' && Month === 'Mar-21' && session === 'RX' ? [pro3WithMar21Pay[3]]
            : Providers === 'Provider3' && Month === 'Apr-21' && session === 'RX' ? [pro3WithApr21Pay[3]]
            : Providers === 'Provider3' && Month === 'May-21' && session === 'RX' ? [pro3WithMay21Pay[3]]
            : Providers === 'Provider3' && Month === 'Jun-21' && session === 'RX' ? [pro3WithJun21Pay[3]]
            : Providers === 'Provider3' && Month === 'Jul-21' && session === 'RX' ? [pro3WithJul21Pay[3]]
            : Providers === 'Provider3' && Month === 'Aug-21' && session === 'RX' ? [pro3WithAug21Pay[3]]
            : Providers === 'Provider3' && Month === 'Sep-21' && session === 'RX' ? [pro3WithSep21Pay[3]]
            : Providers === 'Provider3' && Month === 'Oct-21' && session === 'RX' ? [pro3WithOct21Pay[3]]
            : Providers === 'Provider3' && Month === 'Nov-21' && session === 'RX' ? [pro3WithNov21Pay[3]]
            : Providers === 'Provider3' && Month === 'Dec-21' && session === 'RX' ? [pro3WithDec21Pay[3]]
            : Providers === 'Provider3' && Month === 'Jan-22' && session === 'RX' ? [pro3WithJan22Pay[3]]
            : Providers === 'Provider3' && Month === 'Feb-22' && session === 'RX' ? [pro3WithFeb22Pay[3]]
            : Providers === 'Provider3' && Month === 'Mar-22' && session === 'RX' ? [pro3WithMar22Pay[3]]
            : Providers === 'Provider3' && Month === 'Apr-22' && session === 'RX' ? [pro3WithApr22Pay[3]]
            : Providers === 'Provider3' && Month === 'May-22' && session === 'RX' ? [pro3WithMay22Pay[3]]
            : Providers === 'Provider3' && Month === 'Jun-22' && session === 'RX' ? [pro3WithJun22Pay[3]]
            : Providers === 'Provider3' && Month === 'Jul-22' && session === 'RX' ? [pro3WithJul22Pay[3]]
            : Providers === 'Provider3' && Month === 'Aug-22' && session === 'RX' ? [pro3WithAug22Pay[3]]
            : Providers === 'Provider3' && Month === 'Sep-22' && session === 'RX' ? [pro3WithSep22Pay[3]]
            : Providers === 'Provider3' && Month === 'Oct-22' && session === 'RX' ? [pro3WithOct22Pay[3]]
            : Providers === 'Provider3' && Month === 'Nov-22' && session === 'RX' ? [pro3WithNov22Pay[3]]
            : Providers === 'Provider3' && Month === 'Dec-22' && session === 'RX' ? [pro3WithDec22Pay[3]]
            : Providers === 'Provider3' && Month === 'Jan-23' && session === 'RX' ? [pro3WithJan23Pay[3]]
            : Providers === 'Provider3' && Month === 'Feb-23' && session === 'RX' ? [pro3WithFeb23Pay[3]]
            : Providers === 'Provider3' && Month === 'Mar-23' && session === 'RX' ? [pro3WithMar23Pay[3]]
            : Providers === 'Provider3' && Month === 'Apr-23' && session === 'RX' ? [pro3WithApr23Pay[3]]
            : Providers === 'Provider3' && Month === 'May-23' && session === 'RX' ? [pro3WithMay23Pay[3]]
            : Providers === 'Provider3' && Month === 'Jun-23' && session === 'RX' ? [pro3WithJun23Pay[3]]
            : Providers === 'Provider3' && Month === 'Jul-23' && session === 'RX' ? [pro3WithJul23Pay[3]]
            : Providers === 'Provider3' && Month === 'Aug-23' && session === 'RX' ? [pro3WithAug23Pay[3]]
            : Providers === 'Provider3' && Month === 'Sep-23' && session === 'RX' ? [pro3WithSep23Pay[3]]
            : Providers === 'Provider3' && Month === 'Oct-23' && session === 'RX' ? [pro3WithOct23Pay[3]]
            : Providers === 'Provider3' && Month === 'Nov-23' && session === 'RX' ? [pro3WithNov23Pay[3]]
            : Providers === 'Provider4' && Month === 'Jan-21' && session === 'RX' ? [pro4WithJan21Pay[3]]
            : Providers === 'Provider4' && Month === 'Feb-21' && session === 'RX' ? [pro4WithFeb21Pay[3]]
            : Providers === 'Provider4' && Month === 'Mar-21' && session === 'RX' ? [pro4WithMar21Pay[3]]
            : Providers === 'Provider4' && Month === 'Apr-21' && session === 'RX' ? [pro4WithApr21Pay[3]]
            : Providers === 'Provider4' && Month === 'May-21' && session === 'RX' ? [pro4WithMay21Pay[3]]
            : Providers === 'Provider4' && Month === 'Jun-21' && session === 'RX' ? [pro4WithJun21Pay[3]]
            : Providers === 'Provider4' && Month === 'Jul-21' && session === 'RX' ? [pro4WithJul21Pay[3]]
            : Providers === 'Provider4' && Month === 'Aug-21' && session === 'RX' ? [pro4WithAug21Pay[3]]
            : Providers === 'Provider4' && Month === 'Sep-21' && session === 'RX' ? [pro4WithSep21Pay[3]]
            : Providers === 'Provider4' && Month === 'Oct-21' && session === 'RX' ? [pro4WithOct21Pay[3]]
            : Providers === 'Provider4' && Month === 'Nov-21' && session === 'RX' ? [pro4WithNov21Pay[3]]
            : Providers === 'Provider4' && Month === 'Dec-21' && session === 'RX' ? [pro4WithDec21Pay[3]]
            : Providers === 'Provider4' && Month === 'Jan-22' && session === 'RX' ? [pro4WithJan22Pay[3]]
            : Providers === 'Provider4' && Month === 'Feb-22' && session === 'RX' ? [pro4WithFeb22Pay[3]]
            : Providers === 'Provider4' && Month === 'Mar-22' && session === 'RX' ? [pro4WithMar22Pay[3]]
            : Providers === 'Provider4' && Month === 'Apr-22' && session === 'RX' ? [pro4WithApr22Pay[3]]
            : Providers === 'Provider4' && Month === 'May-22' && session === 'RX' ? [pro4WithMay22Pay[3]]
            : Providers === 'Provider4' && Month === 'Jun-22' && session === 'RX' ? [pro4WithJun22Pay[3]]
            : Providers === 'Provider4' && Month === 'Jul-22' && session === 'RX' ? [pro4WithJul22Pay[3]]
            : Providers === 'Provider4' && Month === 'Aug-22' && session === 'RX' ? [pro4WithAug22Pay[3]]
            : Providers === 'Provider4' && Month === 'Sep-22' && session === 'RX' ? [pro4WithSep22Pay[3]]
            : Providers === 'Provider4' && Month === 'Oct-22' && session === 'RX' ? [pro4WithOct22Pay[3]]
            : Providers === 'Provider4' && Month === 'Nov-22' && session === 'RX' ? [pro4WithNov22Pay[3]]
            : Providers === 'Provider4' && Month === 'Dec-22' && session === 'RX' ? [pro4WithDec22Pay[3]]
            : Providers === 'Provider4' && Month === 'Jan-23' && session === 'RX' ? [pro4WithJan23Pay[3]]
            : Providers === 'Provider4' && Month === 'Feb-23' && session === 'RX' ? [pro4WithFeb23Pay[3]]
            : Providers === 'Provider4' && Month === 'Mar-23' && session === 'RX' ? [pro4WithMar23Pay[3]]
            : Providers === 'Provider4' && Month === 'Apr-23' && session === 'RX' ? [pro4WithApr23Pay[3]]
            : Providers === 'Provider4' && Month === 'May-23' && session === 'RX' ? [pro4WithMay23Pay[3]]
            : Providers === 'Provider4' && Month === 'Jun-23' && session === 'RX' ? [pro4WithJun23Pay[3]]
            : Providers === 'Provider4' && Month === 'Jul-23' && session === 'RX' ? [pro4WithJul23Pay[3]]
            : Providers === 'Provider4' && Month === 'Aug-23' && session === 'RX' ? [pro4WithAug23Pay[3]]
            : Providers === 'Provider4' && Month === 'Sep-23' && session === 'RX' ? [pro4WithSep23Pay[3]]
            : Providers === 'Provider4' && Month === 'Oct-23' && session === 'RX' ? [pro4WithOct23Pay[3]]
            : Providers === 'Provider4' && Month === 'Nov-23' && session === 'RX' ? [pro4WithNov23Pay[3]]
            : Providers === 'Provider5' && Month === 'Jan-21' && session === 'RX' ? [pro5WithJan21Pay[3]]
            : Providers === 'Provider5' && Month === 'Feb-21' && session === 'RX' ? [pro5WithFeb21Pay[3]]
            : Providers === 'Provider5' && Month === 'Mar-21' && session === 'RX' ? [pro5WithMar21Pay[3]]
            : Providers === 'Provider5' && Month === 'Apr-21' && session === 'RX' ? [pro5WithApr21Pay[3]]
            : Providers === 'Provider5' && Month === 'May-21' && session === 'RX' ? [pro5WithMay21Pay[3]]
            : Providers === 'Provider5' && Month === 'Jun-21' && session === 'RX' ? [pro5WithJun21Pay[3]]
            : Providers === 'Provider5' && Month === 'Jul-21' && session === 'RX' ? [pro5WithJul21Pay[3]]
            : Providers === 'Provider5' && Month === 'Aug-21' && session === 'RX' ? [pro5WithAug21Pay[3]]
            : Providers === 'Provider5' && Month === 'Sep-21' && session === 'RX' ? [pro5WithSep21Pay[3]]
            : Providers === 'Provider5' && Month === 'Oct-21' && session === 'RX' ? [pro5WithOct21Pay[3]]
            : Providers === 'Provider5' && Month === 'Nov-21' && session === 'RX' ? [pro5WithNov21Pay[3]]
            : Providers === 'Provider5' && Month === 'Dec-21' && session === 'RX' ? [pro5WithDec21Pay[3]]
            : Providers === 'Provider5' && Month === 'Jan-22' && session === 'RX' ? [pro5WithJan22Pay[3]]
            : Providers === 'Provider5' && Month === 'Feb-22' && session === 'RX' ? [pro5WithFeb22Pay[3]]
            : Providers === 'Provider5' && Month === 'Mar-22' && session === 'RX' ? [pro5WithMar22Pay[3]]
            : Providers === 'Provider5' && Month === 'Apr-22' && session === 'RX' ? [pro5WithApr22Pay[3]]
            : Providers === 'Provider5' && Month === 'May-22' && session === 'RX' ? [pro5WithMay22Pay[3]]
            : Providers === 'Provider5' && Month === 'Jun-22' && session === 'RX' ? [pro5WithJun22Pay[3]]
            : Providers === 'Provider5' && Month === 'Jul-22' && session === 'RX' ? [pro5WithJul22Pay[3]]
            : Providers === 'Provider5' && Month === 'Aug-22' && session === 'RX' ? [pro5WithAug22Pay[3]]
            : Providers === 'Provider5' && Month === 'Sep-22' && session === 'RX' ? [pro5WithSep22Pay[3]]
            : Providers === 'Provider5' && Month === 'Oct-22' && session === 'RX' ? [pro5WithOct22Pay[3]]
            : Providers === 'Provider5' && Month === 'Nov-22' && session === 'RX' ? [pro5WithNov22Pay[3]]
            : Providers === 'Provider5' && Month === 'Dec-22' && session === 'RX' ? [pro5WithDec22Pay[3]]
            : Providers === 'Provider5' && Month === 'Jan-23' && session === 'RX' ? [pro5WithJan23Pay[3]]
            : Providers === 'Provider5' && Month === 'Feb-23' && session === 'RX' ? [pro5WithFeb23Pay[3]]
            : Providers === 'Provider5' && Month === 'Mar-23' && session === 'RX' ? [pro5WithMar23Pay[3]]
            : Providers === 'Provider5' && Month === 'Apr-23' && session === 'RX' ? [pro5WithApr23Pay[3]]
            : Providers === 'Provider5' && Month === 'May-23' && session === 'RX' ? [pro5WithMay23Pay[3]]
            : Providers === 'Provider5' && Month === 'Jun-23' && session === 'RX' ? [pro5WithJun23Pay[3]]
            : Providers === 'Provider5' && Month === 'Jul-23' && session === 'RX' ? [pro5WithJul23Pay[3]]
            : Providers === 'Provider5' && Month === 'Aug-23' && session === 'RX' ? [pro5WithAug23Pay[3]]
            : Providers === 'Provider5' && Month === 'Sep-23' && session === 'RX' ? [pro5WithSep23Pay[3]]
            : Providers === 'Provider5' && Month === 'Oct-23' && session === 'RX' ? [pro5WithOct23Pay[3]]
            : Providers === 'Provider5' && Month === 'Nov-23' && session === 'RX' ? [pro5WithNov23Pay[3]]
            : Providers === 'Provider6' && Month === 'Jan-21' && session === 'RX' ? [pro6WithJan21Pay[3]]
            : Providers === 'Provider6' && Month === 'Feb-21' && session === 'RX' ? [pro6WithFeb21Pay[3]]
            : Providers === 'Provider6' && Month === 'Mar-21' && session === 'RX' ? [pro6WithMar21Pay[3]]
            : Providers === 'Provider6' && Month === 'Apr-21' && session === 'RX' ? [pro6WithApr21Pay[3]]
            : Providers === 'Provider6' && Month === 'May-21' && session === 'RX' ? [pro6WithMay21Pay[3]]
            : Providers === 'Provider6' && Month === 'Jun-21' && session === 'RX' ? [pro6WithJun21Pay[3]]
            : Providers === 'Provider6' && Month === 'Jul-21' && session === 'RX' ? [pro6WithJul21Pay[3]]
            : Providers === 'Provider6' && Month === 'Aug-21' && session === 'RX' ? [pro6WithAug21Pay[3]]
            : Providers === 'Provider6' && Month === 'Sep-21' && session === 'RX' ? [pro6WithSep21Pay[3]]
            : Providers === 'Provider6' && Month === 'Oct-21' && session === 'RX' ? [pro6WithOct21Pay[3]]
            : Providers === 'Provider6' && Month === 'Nov-21' && session === 'RX' ? [pro6WithNov21Pay[3]]
            : Providers === 'Provider6' && Month === 'Dec-21' && session === 'RX' ? [pro6WithDec21Pay[3]]
            : Providers === 'Provider6' && Month === 'Jan-22' && session === 'RX' ? [pro6WithJan22Pay[3]]
            : Providers === 'Provider6' && Month === 'Feb-22' && session === 'RX' ? [pro6WithFeb22Pay[3]]
            : Providers === 'Provider6' && Month === 'Mar-22' && session === 'RX' ? [pro6WithMar22Pay[3]]
            : Providers === 'Provider6' && Month === 'Apr-22' && session === 'RX' ? [pro6WithApr22Pay[3]]
            : Providers === 'Provider6' && Month === 'May-22' && session === 'RX' ? [pro6WithMay22Pay[3]]
            : Providers === 'Provider6' && Month === 'Jun-22' && session === 'RX' ? [pro6WithJun22Pay[3]]
            : Providers === 'Provider6' && Month === 'Jul-22' && session === 'RX' ? [pro6WithJul22Pay[3]]
            : Providers === 'Provider6' && Month === 'Aug-22' && session === 'RX' ? [pro6WithAug22Pay[3]]
            : Providers === 'Provider6' && Month === 'Sep-22' && session === 'RX' ? [pro6WithSep22Pay[3]]
            : Providers === 'Provider6' && Month === 'Oct-22' && session === 'RX' ? [pro6WithOct22Pay[3]]
            : Providers === 'Provider6' && Month === 'Nov-22' && session === 'RX' ? [pro6WithNov22Pay[3]]
            : Providers === 'Provider6' && Month === 'Dec-22' && session === 'RX' ? [pro6WithDec22Pay[3]]
            : Providers === 'Provider6' && Month === 'Jan-23' && session === 'RX' ? [pro6WithJan23Pay[3]]
            : Providers === 'Provider6' && Month === 'Feb-23' && session === 'RX' ? [pro6WithFeb23Pay[3]]
            : Providers === 'Provider6' && Month === 'Mar-23' && session === 'RX' ? [pro6WithMar23Pay[3]]
            : Providers === 'Provider6' && Month === 'Apr-23' && session === 'RX' ? [pro6WithApr23Pay[3]]
            : Providers === 'Provider6' && Month === 'May-23' && session === 'RX' ? [pro6WithMay23Pay[3]]
            : Providers === 'Provider6' && Month === 'Jun-23' && session === 'RX' ? [pro6WithJun23Pay[3]]
            : Providers === 'Provider6' && Month === 'Jul-23' && session === 'RX' ? [pro6WithJul23Pay[3]]
            : Providers === 'Provider6' && Month === 'Aug-23' && session === 'RX' ? [pro6WithAug23Pay[3]]
            : Providers === 'Provider6' && Month === 'Sep-23' && session === 'RX' ? [pro6WithSep23Pay[3]]
            : Providers === 'Provider6' && Month === 'Oct-23' && session === 'RX' ? [pro6WithOct23Pay[3]]
            : Providers === 'Provider6' && Month === 'Nov-23' && session === 'RX' ? [pro6WithNov23Pay[3]]
            : Providers === 'Provider7' && Month === 'Jan-21' && session === 'RX' ? [pro7WithJan21Pay[3]]
            : Providers === 'Provider7' && Month === 'Feb-21' && session === 'RX' ? [pro7WithFeb21Pay[3]]
            : Providers === 'Provider7' && Month === 'Mar-21' && session === 'RX' ? [pro7WithMar21Pay[3]]
            : Providers === 'Provider7' && Month === 'Apr-21' && session === 'RX' ? [pro7WithApr21Pay[3]]
            : Providers === 'Provider7' && Month === 'May-21' && session === 'RX' ? [pro7WithMay21Pay[3]]
            : Providers === 'Provider7' && Month === 'Jun-21' && session === 'RX' ? [pro7WithJun21Pay[3]]
            : Providers === 'Provider7' && Month === 'Jul-21' && session === 'RX' ? [pro7WithJul21Pay[3]]
            : Providers === 'Provider7' && Month === 'Aug-21' && session === 'RX' ? [pro7WithAug21Pay[3]]
            : Providers === 'Provider7' && Month === 'Sep-21' && session === 'RX' ? [pro7WithSep21Pay[3]]
            : Providers === 'Provider7' && Month === 'Oct-21' && session === 'RX' ? [pro7WithOct21Pay[3]]
            : Providers === 'Provider7' && Month === 'Nov-21' && session === 'RX' ? [pro7WithNov21Pay[3]]
            : Providers === 'Provider7' && Month === 'Dec-21' && session === 'RX' ? [pro7WithDec21Pay[3]]
            : Providers === 'Provider7' && Month === 'Jan-22' && session === 'RX' ? [pro7WithJan22Pay[3]]
            : Providers === 'Provider7' && Month === 'Feb-22' && session === 'RX' ? [pro7WithFeb22Pay[3]]
            : Providers === 'Provider7' && Month === 'Mar-22' && session === 'RX' ? [pro7WithMar22Pay[3]]
            : Providers === 'Provider7' && Month === 'Apr-22' && session === 'RX' ? [pro7WithApr22Pay[3]]
            : Providers === 'Provider7' && Month === 'May-22' && session === 'RX' ? [pro7WithMay22Pay[3]]
            : Providers === 'Provider7' && Month === 'Jun-22' && session === 'RX' ? [pro7WithJun22Pay[3]]
            : Providers === 'Provider7' && Month === 'Jul-22' && session === 'RX' ? [pro7WithJul22Pay[3]]
            : Providers === 'Provider7' && Month === 'Aug-22' && session === 'RX' ? [pro7WithAug22Pay[3]]
            : Providers === 'Provider7' && Month === 'Sep-22' && session === 'RX' ? [pro7WithSep22Pay[3]]
            : Providers === 'Provider7' && Month === 'Oct-22' && session === 'RX' ? [pro7WithOct22Pay[3]]
            : Providers === 'Provider7' && Month === 'Nov-22' && session === 'RX' ? [pro7WithNov22Pay[3]]
            : Providers === 'Provider7' && Month === 'Dec-22' && session === 'RX' ? [pro7WithDec22Pay[3]]
            : Providers === 'Provider7' && Month === 'Jan-23' && session === 'RX' ? [pro7WithJan23Pay[3]]
            : Providers === 'Provider7' && Month === 'Feb-23' && session === 'RX' ? [pro7WithFeb23Pay[3]]
            : Providers === 'Provider7' && Month === 'Mar-23' && session === 'RX' ? [pro7WithMar23Pay[3]]
            : Providers === 'Provider7' && Month === 'Apr-23' && session === 'RX' ? [pro7WithApr23Pay[3]]
            : Providers === 'Provider7' && Month === 'May-23' && session === 'RX' ? [pro7WithMay23Pay[3]]
            : Providers === 'Provider7' && Month === 'Jun-23' && session === 'RX' ? [pro7WithJun23Pay[3]]
            : Providers === 'Provider7' && Month === 'Jul-23' && session === 'RX' ? [pro7WithJul23Pay[3]]
            : Providers === 'Provider7' && Month === 'Aug-23' && session === 'RX' ? [pro7WithAug23Pay[3]]
            : Providers === 'Provider7' && Month === 'Sep-23' && session === 'RX' ? [pro7WithSep23Pay[3]]
            : Providers === 'Provider7' && Month === 'Oct-23' && session === 'RX' ? [pro7WithOct23Pay[3]]
            : Providers === 'Provider7' && Month === 'Nov-23' && session === 'RX' ? [pro7WithNov23Pay[3]]
            : ''
            },{
                name: 'Collectable',
                data:Providers === 'All Providers' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21Count[3]]
            : Providers === 'All Providers' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21Count[3]]
            : Providers === 'All Providers' && Month === 'Mar-21' && session === 'RX' ? [allproWithMar21Count[3]]
            : Providers === 'All Providers' && Month === 'Apr-21' && session === 'RX' ? [allproWithApr21Count[3]]
            : Providers === 'All Providers' && Month === 'May-21' && session === 'RX' ? [allproWithMay21Count[3]]
            : Providers === 'All Providers' && Month === 'Jun-21' && session === 'RX' ? [allproWithJun21Count[3]]
            : Providers === 'All Providers' && Month === 'Jul-21' && session === 'RX' ? [allproWithJul21Count[3]]
            : Providers === 'All Providers' && Month === 'Aug-21' && session === 'RX' ? [allproWithAug21Count[3]]
            : Providers === 'All Providers' && Month === 'Sep-21' && session === 'RX' ? [allproWithSep21Count[3]]
            : Providers === 'All Providers' && Month === 'Oct-21' && session === 'RX' ? [allproWithOct21Count[3]]
            : Providers === 'All Providers' && Month === 'Nov-21' && session === 'RX' ? [allproWithNov21Count[3]]
            : Providers === 'All Providers' && Month === 'Dec-21' && session === 'RX' ? [allproWithDec21Count[3]]
            : Providers === 'All Providers' && Month === 'Jan-22' && session === 'RX' ? [allproWithJan22Count[3]]
            : Providers === 'All Providers' && Month === 'Feb-22' && session === 'RX' ? [allproWithFeb22Count[3]]
            : Providers === 'All Providers' && Month === 'Mar-22' && session === 'RX' ? [allproWithMar22Count[3]]
            : Providers === 'All Providers' && Month === 'Apr-22' && session === 'RX' ? [allproWithApr22Count[3]]
            : Providers === 'All Providers' && Month === 'May-22' && session === 'RX' ? [allproWithMay22Count[3]]
            : Providers === 'All Providers' && Month === 'Jun-22' && session === 'RX' ? [allproWithJun22Count[3]]
            : Providers === 'All Providers' && Month === 'Jul-22' && session === 'RX' ? [allproWithJul22Count[3]]
            : Providers === 'All Providers' && Month === 'Aug-22' && session === 'RX' ? [allproWithAug22Count[3]]
            : Providers === 'All Providers' && Month === 'Sep-22' && session === 'RX' ? [allproWithSep22Count[3]]
            : Providers === 'All Providers' && Month === 'Oct-22' && session === 'RX' ? [allproWithOct22Count[3]]
            : Providers === 'All Providers' && Month === 'Nov-22' && session === 'RX' ? [allproWithNov22Count[3]]
            : Providers === 'All Providers' && Month === 'Dec-22' && session === 'RX' ? [allproWithDec22Count[3]]
            : Providers === 'All Providers' && Month === 'Jan-23' && session === 'RX' ? [allproWithJan23Count[3]]
            : Providers === 'All Providers' && Month === 'Feb-23' && session === 'RX' ? [allproWithFeb23Count[3]]
            : Providers === 'All Providers' && Month === 'Mar-23' && session === 'RX' ? [allproWithMar23Count[3]]
            : Providers === 'All Providers' && Month === 'Apr-23' && session === 'RX' ? [allproWithApr23Count[3]]
            : Providers === 'All Providers' && Month === 'May-23' && session === 'RX' ? [allproWithMay23Count[3]]
            : Providers === 'All Providers' && Month === 'Jun-23' && session === 'RX' ? [allproWithJun23Count[3]]
            : Providers === 'All Providers' && Month === 'Jul-23' && session === 'RX' ? [allproWithJul23Count[3]]
            : Providers === 'All Providers' && Month === 'Aug-23' && session === 'RX' ? [allproWithAug23Count[3]]
            : Providers === 'All Providers' && Month === 'Sep-23' && session === 'RX' ? [allproWithSep23Count[3]]
            : Providers === 'All Providers' && Month === 'Oct-23' && session === 'RX' ? [allproWithOct23Count[3]]
            : Providers === 'All Providers' && Month === 'Nov-23' && session === 'RX' ? [allproWithNov23Count[3]]
            : Providers === 'Provider1' && Month === 'Jan-21' && session === 'RX' ? [pro1WithJan21Count[3]]
            : Providers === 'Provider1' && Month === 'Feb-21' && session === 'RX' ? [pro1WithFeb21Count[3]]
            : Providers === 'Provider1' && Month === 'Mar-21' && session === 'RX' ? [pro1WithMar21Count[3]]
            : Providers === 'Provider1' && Month === 'Apr-21' && session === 'RX' ? [pro1WithApr21Count[3]]
            : Providers === 'Provider1' && Month === 'May-21' && session === 'RX' ? [pro1WithMay21Count[3]]
            : Providers === 'Provider1' && Month === 'Jun-21' && session === 'RX' ? [pro1WithJun21Count[3]]
            : Providers === 'Provider1' && Month === 'Jul-21' && session === 'RX' ? [pro1WithJul21Count[3]]
            : Providers === 'Provider1' && Month === 'Aug-21' && session === 'RX' ? [pro1WithAug21Count[3]]
            : Providers === 'Provider1' && Month === 'Sep-21' && session === 'RX' ? [pro1WithSep21Count[3]]
            : Providers === 'Provider1' && Month === 'Oct-21' && session === 'RX' ? [pro1WithOct21Count[3]]
            : Providers === 'Provider1' && Month === 'Nov-21' && session === 'RX' ? [pro1WithNov21Count[3]]
            : Providers === 'Provider1' && Month === 'Dec-21' && session === 'RX' ? [pro1WithDec21Count[3]]
            : Providers === 'Provider1' && Month === 'Jan-22' && session === 'RX' ? [pro1WithJan22Count[3]]
            : Providers === 'Provider1' && Month === 'Feb-22' && session === 'RX' ? [pro1WithFeb22Count[3]]
            : Providers === 'Provider1' && Month === 'Mar-22' && session === 'RX' ? [pro1WithMar22Count[3]]
            : Providers === 'Provider1' && Month === 'Apr-22' && session === 'RX' ? [pro1WithApr22Count[3]]
            : Providers === 'Provider1' && Month === 'May-22' && session === 'RX' ? [pro1WithMay22Count[3]]
            : Providers === 'Provider1' && Month === 'Jun-22' && session === 'RX' ? [pro1WithJun22Count[3]]
            : Providers === 'Provider1' && Month === 'Jul-22' && session === 'RX' ? [pro1WithJul22Count[3]]
            : Providers === 'Provider1' && Month === 'Aug-22' && session === 'RX' ? [pro1WithAug22Count[3]]
            : Providers === 'Provider1' && Month === 'Sep-22' && session === 'RX' ? [pro1WithSep22Count[3]]
            : Providers === 'Provider1' && Month === 'Oct-22' && session === 'RX' ? [pro1WithOct22Count[3]]
            : Providers === 'Provider1' && Month === 'Nov-22' && session === 'RX' ? [pro1WithNov22Count[3]]
            : Providers === 'Provider1' && Month === 'Dec-22' && session === 'RX' ? [pro1WithDec22Count[3]]
            : Providers === 'Provider1' && Month === 'Jan-23' && session === 'RX' ? [pro1WithJan23Count[3]]
            : Providers === 'Provider1' && Month === 'Feb-23' && session === 'RX' ? [pro1WithFeb23Count[3]]
            : Providers === 'Provider1' && Month === 'Mar-23' && session === 'RX' ? [pro1WithMar23Count[3]]
            : Providers === 'Provider1' && Month === 'Apr-23' && session === 'RX' ? [pro1WithApr23Count[3]]
            : Providers === 'Provider1' && Month === 'May-23' && session === 'RX' ? [pro1WithMay23Count[3]]
            : Providers === 'Provider1' && Month === 'Jun-23' && session === 'RX' ? [pro1WithJun23Count[3]]
            : Providers === 'Provider1' && Month === 'Jul-23' && session === 'RX' ? [pro1WithJul23Count[3]]
            : Providers === 'Provider1' && Month === 'Aug-23' && session === 'RX' ? [pro1WithAug23Count[3]]
            : Providers === 'Provider1' && Month === 'Sep-23' && session === 'RX' ? [pro1WithSep23Count[3]]
            : Providers === 'Provider1' && Month === 'Oct-23' && session === 'RX' ? [pro1WithOct23Count[3]]
            : Providers === 'Provider1' && Month === 'Nov-23' && session === 'RX' ? [pro1WithNov23Count[3]]
            : Providers === 'Provider2' && Month === 'Jan-21' && session === 'RX' ? [pro2WithJan21Count[3]]
            : Providers === 'Provider2' && Month === 'Feb-21' && session === 'RX' ? [pro2WithFeb21Count[3]]
            : Providers === 'Provider2' && Month === 'Mar-21' && session === 'RX' ? [pro2WithMar21Count[3]]
            : Providers === 'Provider2' && Month === 'Apr-21' && session === 'RX' ? [pro2WithApr21Count[3]]
            : Providers === 'Provider2' && Month === 'May-21' && session === 'RX' ? [pro2WithMay21Count[3]]
            : Providers === 'Provider2' && Month === 'Jun-21' && session === 'RX' ? [pro2WithJun21Count[3]]
            : Providers === 'Provider2' && Month === 'Jul-21' && session === 'RX' ? [pro2WithJul21Count[3]]
            : Providers === 'Provider2' && Month === 'Aug-21' && session === 'RX' ? [pro2WithAug21Count[3]]
            : Providers === 'Provider2' && Month === 'Sep-21' && session === 'RX' ? [pro2WithSep21Count[3]]
            : Providers === 'Provider2' && Month === 'Oct-21' && session === 'RX' ? [pro2WithOct21Count[3]]
            : Providers === 'Provider2' && Month === 'Nov-21' && session === 'RX' ? [pro2WithNov21Count[3]]
            : Providers === 'Provider2' && Month === 'Dec-21' && session === 'RX' ? [pro2WithDec21Count[3]]
            : Providers === 'Provider2' && Month === 'Jan-22' && session === 'RX' ? [pro2WithJan22Count[3]]
            : Providers === 'Provider2' && Month === 'Feb-22' && session === 'RX' ? [pro2WithFeb22Count[3]]
            : Providers === 'Provider2' && Month === 'Mar-22' && session === 'RX' ? [pro2WithMar22Count[3]]
            : Providers === 'Provider2' && Month === 'Apr-22' && session === 'RX' ? [pro2WithApr22Count[3]]
            : Providers === 'Provider2' && Month === 'May-22' && session === 'RX' ? [pro2WithMay22Count[3]]
            : Providers === 'Provider2' && Month === 'Jun-22' && session === 'RX' ? [pro2WithJun22Count[3]]
            : Providers === 'Provider2' && Month === 'Jul-22' && session === 'RX' ? [pro2WithJul22Count[3]]
            : Providers === 'Provider2' && Month === 'Aug-22' && session === 'RX' ? [pro2WithAug22Count[3]]
            : Providers === 'Provider2' && Month === 'Sep-22' && session === 'RX' ? [pro2WithSep22Count[3]]
            : Providers === 'Provider2' && Month === 'Oct-22' && session === 'RX' ? [pro2WithOct22Count[3]]
            : Providers === 'Provider2' && Month === 'Nov-22' && session === 'RX' ? [pro2WithNov22Count[3]]
            : Providers === 'Provider2' && Month === 'Dec-22' && session === 'RX' ? [pro2WithDec22Count[3]]
            : Providers === 'Provider2' && Month === 'Jan-23' && session === 'RX' ? [pro2WithJan23Count[3]]
            : Providers === 'Provider2' && Month === 'Feb-23' && session === 'RX' ? [pro2WithFeb23Count[3]]
            : Providers === 'Provider2' && Month === 'Mar-23' && session === 'RX' ? [pro2WithMar23Count[3]]
            : Providers === 'Provider2' && Month === 'Apr-23' && session === 'RX' ? [pro2WithApr23Count[3]]
            : Providers === 'Provider2' && Month === 'May-23' && session === 'RX' ? [pro2WithMay23Count[3]]
            : Providers === 'Provider2' && Month === 'Jun-23' && session === 'RX' ? [pro2WithJun23Count[3]]
            : Providers === 'Provider2' && Month === 'Jul-23' && session === 'RX' ? [pro2WithJul23Count[3]]
            : Providers === 'Provider2' && Month === 'Aug-23' && session === 'RX' ? [pro2WithAug23Count[3]]
            : Providers === 'Provider2' && Month === 'Sep-23' && session === 'RX' ? [pro2WithSep23Count[3]]
            : Providers === 'Provider2' && Month === 'Oct-23' && session === 'RX' ? [pro2WithOct23Count[3]]
            : Providers === 'Provider2' && Month === 'Nov-23' && session === 'RX' ? [pro2WithNov23Count[3]]
            : Providers === 'Provider3' && Month === 'Jan-21' && session === 'RX' ? [pro3WithJan21Count[3]]
            : Providers === 'Provider3' && Month === 'Feb-21' && session === 'RX' ? [pro3WithFeb21Count[3]]
            : Providers === 'Provider3' && Month === 'Mar-21' && session === 'RX' ? [pro3WithMar21Count[3]]
            : Providers === 'Provider3' && Month === 'Apr-21' && session === 'RX' ? [pro3WithApr21Count[3]]
            : Providers === 'Provider3' && Month === 'May-21' && session === 'RX' ? [pro3WithMay21Count[3]]
            : Providers === 'Provider3' && Month === 'Jun-21' && session === 'RX' ? [pro3WithJun21Count[3]]
            : Providers === 'Provider3' && Month === 'Jul-21' && session === 'RX' ? [pro3WithJul21Count[3]]
            : Providers === 'Provider3' && Month === 'Aug-21' && session === 'RX' ? [pro3WithAug21Count[3]]
            : Providers === 'Provider3' && Month === 'Sep-21' && session === 'RX' ? [pro3WithSep21Count[3]]
            : Providers === 'Provider3' && Month === 'Oct-21' && session === 'RX' ? [pro3WithOct21Count[3]]
            : Providers === 'Provider3' && Month === 'Nov-21' && session === 'RX' ? [pro3WithNov21Count[3]]
            : Providers === 'Provider3' && Month === 'Dec-21' && session === 'RX' ? [pro3WithDec21Count[3]]
            : Providers === 'Provider3' && Month === 'Jan-22' && session === 'RX' ? [pro3WithJan22Count[3]]
            : Providers === 'Provider3' && Month === 'Feb-22' && session === 'RX' ? [pro3WithFeb22Count[3]]
            : Providers === 'Provider3' && Month === 'Mar-22' && session === 'RX' ? [pro3WithMar22Count[3]]
            : Providers === 'Provider3' && Month === 'Apr-22' && session === 'RX' ? [pro3WithApr22Count[3]]
            : Providers === 'Provider3' && Month === 'May-22' && session === 'RX' ? [pro3WithMay22Count[3]]
            : Providers === 'Provider3' && Month === 'Jun-22' && session === 'RX' ? [pro3WithJun22Count[3]]
            : Providers === 'Provider3' && Month === 'Jul-22' && session === 'RX' ? [pro3WithJul22Count[3]]
            : Providers === 'Provider3' && Month === 'Aug-22' && session === 'RX' ? [pro3WithAug22Count[3]]
            : Providers === 'Provider3' && Month === 'Sep-22' && session === 'RX' ? [pro3WithSep22Count[3]]
            : Providers === 'Provider3' && Month === 'Oct-22' && session === 'RX' ? [pro3WithOct22Count[3]]
            : Providers === 'Provider3' && Month === 'Nov-22' && session === 'RX' ? [pro3WithNov22Count[3]]
            : Providers === 'Provider3' && Month === 'Dec-22' && session === 'RX' ? [pro3WithDec22Count[3]]
            : Providers === 'Provider3' && Month === 'Jan-23' && session === 'RX' ? [pro3WithJan23Count[3]]
            : Providers === 'Provider3' && Month === 'Feb-23' && session === 'RX' ? [pro3WithFeb23Count[3]]
            : Providers === 'Provider3' && Month === 'Mar-23' && session === 'RX' ? [pro3WithMar23Count[3]]
            : Providers === 'Provider3' && Month === 'Apr-23' && session === 'RX' ? [pro3WithApr23Count[3]]
            : Providers === 'Provider3' && Month === 'May-23' && session === 'RX' ? [pro3WithMay23Count[3]]
            : Providers === 'Provider3' && Month === 'Jun-23' && session === 'RX' ? [pro3WithJun23Count[3]]
            : Providers === 'Provider3' && Month === 'Jul-23' && session === 'RX' ? [pro3WithJul23Count[3]]
            : Providers === 'Provider3' && Month === 'Aug-23' && session === 'RX' ? [pro3WithAug23Count[3]]
            : Providers === 'Provider3' && Month === 'Sep-23' && session === 'RX' ? [pro3WithSep23Count[3]]
            : Providers === 'Provider3' && Month === 'Oct-23' && session === 'RX' ? [pro3WithOct23Count[3]]
            : Providers === 'Provider3' && Month === 'Nov-23' && session === 'RX' ? [pro3WithNov23Count[3]]
            : Providers === 'Provider4' && Month === 'Jan-21' && session === 'RX' ? [pro4WithJan21Count[3]]
            : Providers === 'Provider4' && Month === 'Feb-21' && session === 'RX' ? [pro4WithFeb21Count[3]]
            : Providers === 'Provider4' && Month === 'Mar-21' && session === 'RX' ? [pro4WithMar21Count[3]]
            : Providers === 'Provider4' && Month === 'Apr-21' && session === 'RX' ? [pro4WithApr21Count[3]]
            : Providers === 'Provider4' && Month === 'May-21' && session === 'RX' ? [pro4WithMay21Count[3]]
            : Providers === 'Provider4' && Month === 'Jun-21' && session === 'RX' ? [pro4WithJun21Count[3]]
            : Providers === 'Provider4' && Month === 'Jul-21' && session === 'RX' ? [pro4WithJul21Count[3]]
            : Providers === 'Provider4' && Month === 'Aug-21' && session === 'RX' ? [pro4WithAug21Count[3]]
            : Providers === 'Provider4' && Month === 'Sep-21' && session === 'RX' ? [pro4WithSep21Count[3]]
            : Providers === 'Provider4' && Month === 'Oct-21' && session === 'RX' ? [pro4WithOct21Count[3]]
            : Providers === 'Provider4' && Month === 'Nov-21' && session === 'RX' ? [pro4WithNov21Count[3]]
            : Providers === 'Provider4' && Month === 'Dec-21' && session === 'RX' ? [pro4WithDec21Count[3]]
            : Providers === 'Provider4' && Month === 'Jan-22' && session === 'RX' ? [pro4WithJan22Count[3]]
            : Providers === 'Provider4' && Month === 'Feb-22' && session === 'RX' ? [pro4WithFeb22Count[3]]
            : Providers === 'Provider4' && Month === 'Mar-22' && session === 'RX' ? [pro4WithMar22Count[3]]
            : Providers === 'Provider4' && Month === 'Apr-22' && session === 'RX' ? [pro4WithApr22Count[3]]
            : Providers === 'Provider4' && Month === 'May-22' && session === 'RX' ? [pro4WithMay22Count[3]]
            : Providers === 'Provider4' && Month === 'Jun-22' && session === 'RX' ? [pro4WithJun22Count[3]]
            : Providers === 'Provider4' && Month === 'Jul-22' && session === 'RX' ? [pro4WithJul22Count[3]]
            : Providers === 'Provider4' && Month === 'Aug-22' && session === 'RX' ? [pro4WithAug22Count[3]]
            : Providers === 'Provider4' && Month === 'Sep-22' && session === 'RX' ? [pro4WithSep22Count[3]]
            : Providers === 'Provider4' && Month === 'Oct-22' && session === 'RX' ? [pro4WithOct22Count[3]]
            : Providers === 'Provider4' && Month === 'Nov-22' && session === 'RX' ? [pro4WithNov22Count[3]]
            : Providers === 'Provider4' && Month === 'Dec-22' && session === 'RX' ? [pro4WithDec22Count[3]]
            : Providers === 'Provider4' && Month === 'Jan-23' && session === 'RX' ? [pro4WithJan23Count[3]]
            : Providers === 'Provider4' && Month === 'Feb-23' && session === 'RX' ? [pro4WithFeb23Count[3]]
            : Providers === 'Provider4' && Month === 'Mar-23' && session === 'RX' ? [pro4WithMar23Count[3]]
            : Providers === 'Provider4' && Month === 'Apr-23' && session === 'RX' ? [pro4WithApr23Count[3]]
            : Providers === 'Provider4' && Month === 'May-23' && session === 'RX' ? [pro4WithMay23Count[3]]
            : Providers === 'Provider4' && Month === 'Jun-23' && session === 'RX' ? [pro4WithJun23Count[3]]
            : Providers === 'Provider4' && Month === 'Jul-23' && session === 'RX' ? [pro4WithJul23Count[3]]
            : Providers === 'Provider4' && Month === 'Aug-23' && session === 'RX' ? [pro4WithAug23Count[3]]
            : Providers === 'Provider4' && Month === 'Sep-23' && session === 'RX' ? [pro4WithSep23Count[3]]
            : Providers === 'Provider4' && Month === 'Oct-23' && session === 'RX' ? [pro4WithOct23Count[3]]
            : Providers === 'Provider4' && Month === 'Nov-23' && session === 'RX' ? [pro4WithNov23Count[3]]
            : Providers === 'Provider5' && Month === 'Jan-21' && session === 'RX' ? [pro5WithJan21Count[3]]
            : Providers === 'Provider5' && Month === 'Feb-21' && session === 'RX' ? [pro5WithFeb21Count[3]]
            : Providers === 'Provider5' && Month === 'Mar-21' && session === 'RX' ? [pro5WithMar21Count[3]]
            : Providers === 'Provider5' && Month === 'Apr-21' && session === 'RX' ? [pro5WithApr21Count[3]]
            : Providers === 'Provider5' && Month === 'May-21' && session === 'RX' ? [pro5WithMay21Count[3]]
            : Providers === 'Provider5' && Month === 'Jun-21' && session === 'RX' ? [pro5WithJun21Count[3]]
            : Providers === 'Provider5' && Month === 'Jul-21' && session === 'RX' ? [pro5WithJul21Count[3]]
            : Providers === 'Provider5' && Month === 'Aug-21' && session === 'RX' ? [pro5WithAug21Count[3]]
            : Providers === 'Provider5' && Month === 'Sep-21' && session === 'RX' ? [pro5WithSep21Count[3]]
            : Providers === 'Provider5' && Month === 'Oct-21' && session === 'RX' ? [pro5WithOct21Count[3]]
            : Providers === 'Provider5' && Month === 'Nov-21' && session === 'RX' ? [pro5WithNov21Count[3]]
            : Providers === 'Provider5' && Month === 'Dec-21' && session === 'RX' ? [pro5WithDec21Count[3]]
            : Providers === 'Provider5' && Month === 'Jan-22' && session === 'RX' ? [pro5WithJan22Count[3]]
            : Providers === 'Provider5' && Month === 'Feb-22' && session === 'RX' ? [pro5WithFeb22Count[3]]
            : Providers === 'Provider5' && Month === 'Mar-22' && session === 'RX' ? [pro5WithMar22Count[3]]
            : Providers === 'Provider5' && Month === 'Apr-22' && session === 'RX' ? [pro5WithApr22Count[3]]
            : Providers === 'Provider5' && Month === 'May-22' && session === 'RX' ? [pro5WithMay22Count[3]]
            : Providers === 'Provider5' && Month === 'Jun-22' && session === 'RX' ? [pro5WithJun22Count[3]]
            : Providers === 'Provider5' && Month === 'Jul-22' && session === 'RX' ? [pro5WithJul22Count[3]]
            : Providers === 'Provider5' && Month === 'Aug-22' && session === 'RX' ? [pro5WithAug22Count[3]]
            : Providers === 'Provider5' && Month === 'Sep-22' && session === 'RX' ? [pro5WithSep22Count[3]]
            : Providers === 'Provider5' && Month === 'Oct-22' && session === 'RX' ? [pro5WithOct22Count[3]]
            : Providers === 'Provider5' && Month === 'Nov-22' && session === 'RX' ? [pro5WithNov22Count[3]]
            : Providers === 'Provider5' && Month === 'Dec-22' && session === 'RX' ? [pro5WithDec22Count[3]]
            : Providers === 'Provider5' && Month === 'Jan-23' && session === 'RX' ? [pro5WithJan23Count[3]]
            : Providers === 'Provider5' && Month === 'Feb-23' && session === 'RX' ? [pro5WithFeb23Count[3]]
            : Providers === 'Provider5' && Month === 'Mar-23' && session === 'RX' ? [pro5WithMar23Count[3]]
            : Providers === 'Provider5' && Month === 'Apr-23' && session === 'RX' ? [pro5WithApr23Count[3]]
            : Providers === 'Provider5' && Month === 'May-23' && session === 'RX' ? [pro5WithMay23Count[3]]
            : Providers === 'Provider5' && Month === 'Jun-23' && session === 'RX' ? [pro5WithJun23Count[3]]
            : Providers === 'Provider5' && Month === 'Jul-23' && session === 'RX' ? [pro5WithJul23Count[3]]
            : Providers === 'Provider5' && Month === 'Aug-23' && session === 'RX' ? [pro5WithAug23Count[3]]
            : Providers === 'Provider5' && Month === 'Sep-23' && session === 'RX' ? [pro5WithSep23Count[3]]
            : Providers === 'Provider5' && Month === 'Oct-23' && session === 'RX' ? [pro5WithOct23Count[3]]
            : Providers === 'Provider5' && Month === 'Nov-23' && session === 'RX' ? [pro5WithNov23Count[3]]
            : Providers === 'Provider6' && Month === 'Jan-21' && session === 'RX' ? [pro6WithJan21Count[3]]
            : Providers === 'Provider6' && Month === 'Feb-21' && session === 'RX' ? [pro6WithFeb21Count[3]]
            : Providers === 'Provider6' && Month === 'Mar-21' && session === 'RX' ? [pro6WithMar21Count[3]]
            : Providers === 'Provider6' && Month === 'Apr-21' && session === 'RX' ? [pro6WithApr21Count[3]]
            : Providers === 'Provider6' && Month === 'May-21' && session === 'RX' ? [pro6WithMay21Count[3]]
            : Providers === 'Provider6' && Month === 'Jun-21' && session === 'RX' ? [pro6WithJun21Count[3]]
            : Providers === 'Provider6' && Month === 'Jul-21' && session === 'RX' ? [pro6WithJul21Count[3]]
            : Providers === 'Provider6' && Month === 'Aug-21' && session === 'RX' ? [pro6WithAug21Count[3]]
            : Providers === 'Provider6' && Month === 'Sep-21' && session === 'RX' ? [pro6WithSep21Count[3]]
            : Providers === 'Provider6' && Month === 'Oct-21' && session === 'RX' ? [pro6WithOct21Count[3]]
            : Providers === 'Provider6' && Month === 'Nov-21' && session === 'RX' ? [pro6WithNov21Count[3]]
            : Providers === 'Provider6' && Month === 'Dec-21' && session === 'RX' ? [pro6WithDec21Count[3]]
            : Providers === 'Provider6' && Month === 'Jan-22' && session === 'RX' ? [pro6WithJan22Count[3]]
            : Providers === 'Provider6' && Month === 'Feb-22' && session === 'RX' ? [pro6WithFeb22Count[3]]
            : Providers === 'Provider6' && Month === 'Mar-22' && session === 'RX' ? [pro6WithMar22Count[3]]
            : Providers === 'Provider6' && Month === 'Apr-22' && session === 'RX' ? [pro6WithApr22Count[3]]
            : Providers === 'Provider6' && Month === 'May-22' && session === 'RX' ? [pro6WithMay22Count[3]]
            : Providers === 'Provider6' && Month === 'Jun-22' && session === 'RX' ? [pro6WithJun22Count[3]]
            : Providers === 'Provider6' && Month === 'Jul-22' && session === 'RX' ? [pro6WithJul22Count[3]]
            : Providers === 'Provider6' && Month === 'Aug-22' && session === 'RX' ? [pro6WithAug22Count[3]]
            : Providers === 'Provider6' && Month === 'Sep-22' && session === 'RX' ? [pro6WithSep22Count[3]]
            : Providers === 'Provider6' && Month === 'Oct-22' && session === 'RX' ? [pro6WithOct22Count[3]]
            : Providers === 'Provider6' && Month === 'Nov-22' && session === 'RX' ? [pro6WithNov22Count[3]]
            : Providers === 'Provider6' && Month === 'Dec-22' && session === 'RX' ? [pro6WithDec22Count[3]]
            : Providers === 'Provider6' && Month === 'Jan-23' && session === 'RX' ? [pro6WithJan23Count[3]]
            : Providers === 'Provider6' && Month === 'Feb-23' && session === 'RX' ? [pro6WithFeb23Count[3]]
            : Providers === 'Provider6' && Month === 'Mar-23' && session === 'RX' ? [pro6WithMar23Count[3]]
            : Providers === 'Provider6' && Month === 'Apr-23' && session === 'RX' ? [pro6WithApr23Count[3]]
            : Providers === 'Provider6' && Month === 'May-23' && session === 'RX' ? [pro6WithMay23Count[3]]
            : Providers === 'Provider6' && Month === 'Jun-23' && session === 'RX' ? [pro6WithJun23Count[3]]
            : Providers === 'Provider6' && Month === 'Jul-23' && session === 'RX' ? [pro6WithJul23Count[3]]
            : Providers === 'Provider6' && Month === 'Aug-23' && session === 'RX' ? [pro6WithAug23Count[3]]
            : Providers === 'Provider6' && Month === 'Sep-23' && session === 'RX' ? [pro6WithSep23Count[3]]
            : Providers === 'Provider6' && Month === 'Oct-23' && session === 'RX' ? [pro6WithOct23Count[3]]
            : Providers === 'Provider6' && Month === 'Nov-23' && session === 'RX' ? [pro6WithNov23Count[3]]
            : Providers === 'Provider7' && Month === 'Jan-21' && session === 'RX' ? [pro7WithJan21Count[3]]
            : Providers === 'Provider7' && Month === 'Feb-21' && session === 'RX' ? [pro7WithFeb21Count[3]]
            : Providers === 'Provider7' && Month === 'Mar-21' && session === 'RX' ? [pro7WithMar21Count[3]]
            : Providers === 'Provider7' && Month === 'Apr-21' && session === 'RX' ? [pro7WithApr21Count[3]]
            : Providers === 'Provider7' && Month === 'May-21' && session === 'RX' ? [pro7WithMay21Count[3]]
            : Providers === 'Provider7' && Month === 'Jun-21' && session === 'RX' ? [pro7WithJun21Count[3]]
            : Providers === 'Provider7' && Month === 'Jul-21' && session === 'RX' ? [pro7WithJul21Count[3]]
            : Providers === 'Provider7' && Month === 'Aug-21' && session === 'RX' ? [pro7WithAug21Count[3]]
            : Providers === 'Provider7' && Month === 'Sep-21' && session === 'RX' ? [pro7WithSep21Count[3]]
            : Providers === 'Provider7' && Month === 'Oct-21' && session === 'RX' ? [pro7WithOct21Count[3]]
            : Providers === 'Provider7' && Month === 'Nov-21' && session === 'RX' ? [pro7WithNov21Count[3]]
            : Providers === 'Provider7' && Month === 'Dec-21' && session === 'RX' ? [pro7WithDec21Count[3]]
            : Providers === 'Provider7' && Month === 'Jan-22' && session === 'RX' ? [pro7WithJan22Count[3]]
            : Providers === 'Provider7' && Month === 'Feb-22' && session === 'RX' ? [pro7WithFeb22Count[3]]
            : Providers === 'Provider7' && Month === 'Mar-22' && session === 'RX' ? [pro7WithMar22Count[3]]
            : Providers === 'Provider7' && Month === 'Apr-22' && session === 'RX' ? [pro7WithApr22Count[3]]
            : Providers === 'Provider7' && Month === 'May-22' && session === 'RX' ? [pro7WithMay22Count[3]]
            : Providers === 'Provider7' && Month === 'Jun-22' && session === 'RX' ? [pro7WithJun22Count[3]]
            : Providers === 'Provider7' && Month === 'Jul-22' && session === 'RX' ? [pro7WithJul22Count[3]]
            : Providers === 'Provider7' && Month === 'Aug-22' && session === 'RX' ? [pro7WithAug22Count[3]]
            : Providers === 'Provider7' && Month === 'Sep-22' && session === 'RX' ? [pro7WithSep22Count[3]]
            : Providers === 'Provider7' && Month === 'Oct-22' && session === 'RX' ? [pro7WithOct22Count[3]]
            : Providers === 'Provider7' && Month === 'Nov-22' && session === 'RX' ? [pro7WithNov22Count[3]]
            : Providers === 'Provider7' && Month === 'Dec-22' && session === 'RX' ? [pro7WithDec22Count[3]]
            : Providers === 'Provider7' && Month === 'Jan-23' && session === 'RX' ? [pro7WithJan23Count[3]]
            : Providers === 'Provider7' && Month === 'Feb-23' && session === 'RX' ? [pro7WithFeb23Count[3]]
            : Providers === 'Provider7' && Month === 'Mar-23' && session === 'RX' ? [pro7WithMar23Count[3]]
            : Providers === 'Provider7' && Month === 'Apr-23' && session === 'RX' ? [pro7WithApr23Count[3]]
            : Providers === 'Provider7' && Month === 'May-23' && session === 'RX' ? [pro7WithMay23Count[3]]
            : Providers === 'Provider7' && Month === 'Jun-23' && session === 'RX' ? [pro7WithJun23Count[3]]
            : Providers === 'Provider7' && Month === 'Jul-23' && session === 'RX' ? [pro7WithJul23Count[3]]
            : Providers === 'Provider7' && Month === 'Aug-23' && session === 'RX' ? [pro7WithAug23Count[3]]
            : Providers === 'Provider7' && Month === 'Sep-23' && session === 'RX' ? [pro7WithSep23Count[3]]
            : Providers === 'Provider7' && Month === 'Oct-23' && session === 'RX' ? [pro7WithOct23Count[3]]
            : Providers === 'Provider7' && Month === 'Nov-23' && session === 'RX' ? [pro7WithNov23Count[3]]
             : ''

            },{
                name: 'Non-Collectable',
                data: Providers === 'All Providers' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21NonCount[3]]
            : Providers === 'All Providers' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21NonCount[3]]
            : Providers === 'All Providers' && Month === 'Mar-21' && session === 'RX' ? [allproWithMar21NonCount[3]]
            : Providers === 'All Providers' && Month === 'Apr-21' && session === 'RX' ? [allproWithApr21NonCount[3]]
            : Providers === 'All Providers' && Month === 'May-21' && session === 'RX' ? [allproWithMay21NonCount[3]]
            : Providers === 'All Providers' && Month === 'Jun-21' && session === 'RX' ? [allproWithJun21NonCount[3]]
            : Providers === 'All Providers' && Month === 'Jul-21' && session === 'RX' ? [allproWithJul21NonCount[3]]
            : Providers === 'All Providers' && Month === 'Aug-21' && session === 'RX' ? [allproWithAug21NonCount[3]]
            : Providers === 'All Providers' && Month === 'Sep-21' && session === 'RX' ? [allproWithSep21NonCount[3]]
            : Providers === 'All Providers' && Month === 'Oct-21' && session === 'RX' ? [allproWithOct21NonCount[3]]
            : Providers === 'All Providers' && Month === 'Nov-21' && session === 'RX' ? [allproWithNov21NonCount[3]]
            : Providers === 'All Providers' && Month === 'Dec-21' && session === 'RX' ? [allproWithDec21NonCount[3]]
            : Providers === 'All Providers' && Month === 'Jan-22' && session === 'RX' ? [allproWithJan22NonCount[3]]
            : Providers === 'All Providers' && Month === 'Feb-22' && session === 'RX' ? [allproWithFeb22NonCount[3]]
            : Providers === 'All Providers' && Month === 'Mar-22' && session === 'RX' ? [allproWithMar22NonCount[3]]
            : Providers === 'All Providers' && Month === 'Apr-22' && session === 'RX' ? [allproWithApr22NonCount[3]]
            : Providers === 'All Providers' && Month === 'May-22' && session === 'RX' ? [allproWithMay22NonCount[3]]
            : Providers === 'All Providers' && Month === 'Jun-22' && session === 'RX' ? [allproWithJun22NonCount[3]]
            : Providers === 'All Providers' && Month === 'Jul-22' && session === 'RX' ? [allproWithJul22NonCount[3]]
            : Providers === 'All Providers' && Month === 'Aug-22' && session === 'RX' ? [allproWithAug22NonCount[3]]
            : Providers === 'All Providers' && Month === 'Sep-22' && session === 'RX' ? [allproWithSep22NonCount[3]]
            : Providers === 'All Providers' && Month === 'Oct-22' && session === 'RX' ? [allproWithOct22NonCount[3]]
            : Providers === 'All Providers' && Month === 'Nov-22' && session === 'RX' ? [allproWithNov22NonCount[3]]
            : Providers === 'All Providers' && Month === 'Dec-22' && session === 'RX' ? [allproWithDec22NonCount[3]]
            : Providers === 'All Providers' && Month === 'Jan-23' && session === 'RX' ? [allproWithJan23NonCount[3]]
            : Providers === 'All Providers' && Month === 'Feb-23' && session === 'RX' ? [allproWithFeb23NonCount[3]]
            : Providers === 'All Providers' && Month === 'Mar-23' && session === 'RX' ? [allproWithMar23NonCount[3]]
            : Providers === 'All Providers' && Month === 'Apr-23' && session === 'RX' ? [allproWithApr23NonCount[3]]
            : Providers === 'All Providers' && Month === 'May-23' && session === 'RX' ? [allproWithMay23NonCount[3]]
            : Providers === 'All Providers' && Month === 'Jun-23' && session === 'RX' ? [allproWithJun23NonCount[3]]
            : Providers === 'All Providers' && Month === 'Jul-23' && session === 'RX' ? [allproWithJul23NonCount[3]]
            : Providers === 'All Providers' && Month === 'Aug-23' && session === 'RX' ? [allproWithAug23NonCount[3]]
            : Providers === 'All Providers' && Month === 'Sep-23' && session === 'RX' ? [allproWithSep23NonCount[3]]
            : Providers === 'All Providers' && Month === 'Oct-23' && session === 'RX' ? [allproWithOct23NonCount[3]]
            : Providers === 'All Providers' && Month === 'Nov-23' && session === 'RX' ? [allproWithNov23NonCount[3]]
            : Providers === 'Provider1' && Month === 'Jan-21' && session === 'RX' ? [pro1WithJan21NonCount[3]]
            : Providers === 'Provider1' && Month === 'Feb-21' && session === 'RX' ? [pro1WithFeb21NonCount[3]]
            : Providers === 'Provider1' && Month === 'Mar-21' && session === 'RX' ? [pro1WithMar21NonCount[3]]
            : Providers === 'Provider1' && Month === 'Apr-21' && session === 'RX' ? [pro1WithApr21NonCount[3]]
            : Providers === 'Provider1' && Month === 'May-21' && session === 'RX' ? [pro1WithMay21NonCount[3]]
            : Providers === 'Provider1' && Month === 'Jun-21' && session === 'RX' ? [pro1WithJun21NonCount[3]]
            : Providers === 'Provider1' && Month === 'Jul-21' && session === 'RX' ? [pro1WithJul21NonCount[3]]
            : Providers === 'Provider1' && Month === 'Aug-21' && session === 'RX' ? [pro1WithAug21NonCount[3]]
            : Providers === 'Provider1' && Month === 'Sep-21' && session === 'RX' ? [pro1WithSep21NonCount[3]]
            : Providers === 'Provider1' && Month === 'Oct-21' && session === 'RX' ? [pro1WithOct21NonCount[3]]
            : Providers === 'Provider1' && Month === 'Nov-21' && session === 'RX' ? [pro1WithNov21NonCount[3]]
            : Providers === 'Provider1' && Month === 'Dec-21' && session === 'RX' ? [pro1WithDec21NonCount[3]]
            : Providers === 'Provider1' && Month === 'Jan-22' && session === 'RX' ? [pro1WithJan22NonCount[3]]
            : Providers === 'Provider1' && Month === 'Feb-22' && session === 'RX' ? [pro1WithFeb22NonCount[3]]
            : Providers === 'Provider1' && Month === 'Mar-22' && session === 'RX' ? [pro1WithMar22NonCount[3]]
            : Providers === 'Provider1' && Month === 'Apr-22' && session === 'RX' ? [pro1WithApr22NonCount[3]]
            : Providers === 'Provider1' && Month === 'May-22' && session === 'RX' ? [pro1WithMay22NonCount[3]]
            : Providers === 'Provider1' && Month === 'Jun-22' && session === 'RX' ? [pro1WithJun22NonCount[3]]
            : Providers === 'Provider1' && Month === 'Jul-22' && session === 'RX' ? [pro1WithJul22NonCount[3]]
            : Providers === 'Provider1' && Month === 'Aug-22' && session === 'RX' ? [pro1WithAug22NonCount[3]]
            : Providers === 'Provider1' && Month === 'Sep-22' && session === 'RX' ? [pro1WithSep22NonCount[3]]
            : Providers === 'Provider1' && Month === 'Oct-22' && session === 'RX' ? [pro1WithOct22NonCount[3]]
            : Providers === 'Provider1' && Month === 'Nov-22' && session === 'RX' ? [pro1WithNov22NonCount[3]]
            : Providers === 'Provider1' && Month === 'Dec-22' && session === 'RX' ? [pro1WithDec22NonCount[3]]
            : Providers === 'Provider1' && Month === 'Jan-23' && session === 'RX' ? [pro1WithJan23NonCount[3]]
            : Providers === 'Provider1' && Month === 'Feb-23' && session === 'RX' ? [pro1WithFeb23NonCount[3]]
            : Providers === 'Provider1' && Month === 'Mar-23' && session === 'RX' ? [pro1WithMar23NonCount[3]]
            : Providers === 'Provider1' && Month === 'Apr-23' && session === 'RX' ? [pro1WithApr23NonCount[3]]
            : Providers === 'Provider1' && Month === 'May-23' && session === 'RX' ? [pro1WithMay23NonCount[3]]
            : Providers === 'Provider1' && Month === 'Jun-23' && session === 'RX' ? [pro1WithJun23NonCount[3]]
            : Providers === 'Provider1' && Month === 'Jul-23' && session === 'RX' ? [pro1WithJul23NonCount[3]]
            : Providers === 'Provider1' && Month === 'Aug-23' && session === 'RX' ? [pro1WithAug23NonCount[3]]
            : Providers === 'Provider1' && Month === 'Sep-23' && session === 'RX' ? [pro1WithSep23NonCount[3]]
            : Providers === 'Provider1' && Month === 'Oct-23' && session === 'RX' ? [pro1WithOct23NonCount[3]]
            : Providers === 'Provider1' && Month === 'Nov-23' && session === 'RX' ? [pro1WithNov23NonCount[3]]
            : Providers === 'Provider2' && Month === 'Jan-21' && session === 'RX' ? [pro2WithJan21NonCount[3]]
            : Providers === 'Provider2' && Month === 'Feb-21' && session === 'RX' ? [pro2WithFeb21NonCount[3]]
            : Providers === 'Provider2' && Month === 'Mar-21' && session === 'RX' ? [pro2WithMar21NonCount[3]]
            : Providers === 'Provider2' && Month === 'Apr-21' && session === 'RX' ? [pro2WithApr21NonCount[3]]
            : Providers === 'Provider2' && Month === 'May-21' && session === 'RX' ? [pro2WithMay21NonCount[3]]
            : Providers === 'Provider2' && Month === 'Jun-21' && session === 'RX' ? [pro2WithJun21NonCount[3]]
            : Providers === 'Provider2' && Month === 'Jul-21' && session === 'RX' ? [pro2WithJul21NonCount[3]]
            : Providers === 'Provider2' && Month === 'Aug-21' && session === 'RX' ? [pro2WithAug21NonCount[3]]
            : Providers === 'Provider2' && Month === 'Sep-21' && session === 'RX' ? [pro2WithSep21NonCount[3]]
            : Providers === 'Provider2' && Month === 'Oct-21' && session === 'RX' ? [pro2WithOct21NonCount[3]]
            : Providers === 'Provider2' && Month === 'Nov-21' && session === 'RX' ? [pro2WithNov21NonCount[3]]
            : Providers === 'Provider2' && Month === 'Dec-21' && session === 'RX' ? [pro2WithDec21NonCount[3]]
            : Providers === 'Provider2' && Month === 'Jan-22' && session === 'RX' ? [pro2WithJan22NonCount[3]]
            : Providers === 'Provider2' && Month === 'Feb-22' && session === 'RX' ? [pro2WithFeb22NonCount[3]]
            : Providers === 'Provider2' && Month === 'Mar-22' && session === 'RX' ? [pro2WithMar22NonCount[3]]
            : Providers === 'Provider2' && Month === 'Apr-22' && session === 'RX' ? [pro2WithApr22NonCount[3]]
            : Providers === 'Provider2' && Month === 'May-22' && session === 'RX' ? [pro2WithMay22NonCount[3]]
            : Providers === 'Provider2' && Month === 'Jun-22' && session === 'RX' ? [pro2WithJun22NonCount[3]]
            : Providers === 'Provider2' && Month === 'Jul-22' && session === 'RX' ? [pro2WithJul22NonCount[3]]
            : Providers === 'Provider2' && Month === 'Aug-22' && session === 'RX' ? [pro2WithAug22NonCount[3]]
            : Providers === 'Provider2' && Month === 'Sep-22' && session === 'RX' ? [pro2WithSep22NonCount[3]]
            : Providers === 'Provider2' && Month === 'Oct-22' && session === 'RX' ? [pro2WithOct22NonCount[3]]
            : Providers === 'Provider2' && Month === 'Nov-22' && session === 'RX' ? [pro2WithNov22NonCount[3]]
            : Providers === 'Provider2' && Month === 'Dec-22' && session === 'RX' ? [pro2WithDec22NonCount[3]]
            : Providers === 'Provider2' && Month === 'Jan-23' && session === 'RX' ? [pro2WithJan23NonCount[3]]
            : Providers === 'Provider2' && Month === 'Feb-23' && session === 'RX' ? [pro2WithFeb23NonCount[3]]
            : Providers === 'Provider2' && Month === 'Mar-23' && session === 'RX' ? [pro2WithMar23NonCount[3]]
            : Providers === 'Provider2' && Month === 'Apr-23' && session === 'RX' ? [pro2WithApr23NonCount[3]]
            : Providers === 'Provider2' && Month === 'May-23' && session === 'RX' ? [pro2WithMay23NonCount[3]]
            : Providers === 'Provider2' && Month === 'Jun-23' && session === 'RX' ? [pro2WithJun23NonCount[3]]
            : Providers === 'Provider2' && Month === 'Jul-23' && session === 'RX' ? [pro2WithJul23NonCount[3]]
            : Providers === 'Provider2' && Month === 'Aug-23' && session === 'RX' ? [pro2WithAug23NonCount[3]]
            : Providers === 'Provider2' && Month === 'Sep-23' && session === 'RX' ? [pro2WithSep23NonCount[3]]
            : Providers === 'Provider2' && Month === 'Oct-23' && session === 'RX' ? [pro2WithOct23NonCount[3]]
            : Providers === 'Provider2' && Month === 'Nov-23' && session === 'RX' ? [pro2WithNov23NonCount[3]]
            : Providers === 'Provider3' && Month === 'Jan-21' && session === 'RX' ? [pro3WithJan21NonCount[3]]
            : Providers === 'Provider3' && Month === 'Feb-21' && session === 'RX' ? [pro3WithFeb21NonCount[3]]
            : Providers === 'Provider3' && Month === 'Mar-21' && session === 'RX' ? [pro3WithMar21NonCount[3]]
            : Providers === 'Provider3' && Month === 'Apr-21' && session === 'RX' ? [pro3WithApr21NonCount[3]]
            : Providers === 'Provider3' && Month === 'May-21' && session === 'RX' ? [pro3WithMay21NonCount[3]]
            : Providers === 'Provider3' && Month === 'Jun-21' && session === 'RX' ? [pro3WithJun21NonCount[3]]
            : Providers === 'Provider3' && Month === 'Jul-21' && session === 'RX' ? [pro3WithJul21NonCount[3]]
            : Providers === 'Provider3' && Month === 'Aug-21' && session === 'RX' ? [pro3WithAug21NonCount[3]]
            : Providers === 'Provider3' && Month === 'Sep-21' && session === 'RX' ? [pro3WithSep21NonCount[3]]
            : Providers === 'Provider3' && Month === 'Oct-21' && session === 'RX' ? [pro3WithOct21NonCount[3]]
            : Providers === 'Provider3' && Month === 'Nov-21' && session === 'RX' ? [pro3WithNov21NonCount[3]]
            : Providers === 'Provider3' && Month === 'Dec-21' && session === 'RX' ? [pro3WithDec21NonCount[3]]
            : Providers === 'Provider3' && Month === 'Jan-22' && session === 'RX' ? [pro3WithJan22NonCount[3]]
            : Providers === 'Provider3' && Month === 'Feb-22' && session === 'RX' ? [pro3WithFeb22NonCount[3]]
            : Providers === 'Provider3' && Month === 'Mar-22' && session === 'RX' ? [pro3WithMar22NonCount[3]]
            : Providers === 'Provider3' && Month === 'Apr-22' && session === 'RX' ? [pro3WithApr22NonCount[3]]
            : Providers === 'Provider3' && Month === 'May-22' && session === 'RX' ? [pro3WithMay22NonCount[3]]
            : Providers === 'Provider3' && Month === 'Jun-22' && session === 'RX' ? [pro3WithJun22NonCount[3]]
            : Providers === 'Provider3' && Month === 'Jul-22' && session === 'RX' ? [pro3WithJul22NonCount[3]]
            : Providers === 'Provider3' && Month === 'Aug-22' && session === 'RX' ? [pro3WithAug22NonCount[3]]
            : Providers === 'Provider3' && Month === 'Sep-22' && session === 'RX' ? [pro3WithSep22NonCount[3]]
            : Providers === 'Provider3' && Month === 'Oct-22' && session === 'RX' ? [pro3WithOct22NonCount[3]]
            : Providers === 'Provider3' && Month === 'Nov-22' && session === 'RX' ? [pro3WithNov22NonCount[3]]
            : Providers === 'Provider3' && Month === 'Dec-22' && session === 'RX' ? [pro3WithDec22NonCount[3]]
            : Providers === 'Provider3' && Month === 'Jan-23' && session === 'RX' ? [pro3WithJan23NonCount[3]]
            : Providers === 'Provider3' && Month === 'Feb-23' && session === 'RX' ? [pro3WithFeb23NonCount[3]]
            : Providers === 'Provider3' && Month === 'Mar-23' && session === 'RX' ? [pro3WithMar23NonCount[3]]
            : Providers === 'Provider3' && Month === 'Apr-23' && session === 'RX' ? [pro3WithApr23NonCount[3]]
            : Providers === 'Provider3' && Month === 'May-23' && session === 'RX' ? [pro3WithMay23NonCount[3]]
            : Providers === 'Provider3' && Month === 'Jun-23' && session === 'RX' ? [pro3WithJun23NonCount[3]]
            : Providers === 'Provider3' && Month === 'Jul-23' && session === 'RX' ? [pro3WithJul23NonCount[3]]
            : Providers === 'Provider3' && Month === 'Aug-23' && session === 'RX' ? [pro3WithAug23NonCount[3]]
            : Providers === 'Provider3' && Month === 'Sep-23' && session === 'RX' ? [pro3WithSep23NonCount[3]]
            : Providers === 'Provider3' && Month === 'Oct-23' && session === 'RX' ? [pro3WithOct23NonCount[3]]
            : Providers === 'Provider3' && Month === 'Nov-23' && session === 'RX' ? [pro3WithNov23NonCount[3]]
            : Providers === 'Provider4' && Month === 'Jan-21' && session === 'RX' ? [pro4WithJan21NonCount[3]]
            : Providers === 'Provider4' && Month === 'Feb-21' && session === 'RX' ? [pro4WithFeb21NonCount[3]]
            : Providers === 'Provider4' && Month === 'Mar-21' && session === 'RX' ? [pro4WithMar21NonCount[3]]
            : Providers === 'Provider4' && Month === 'Apr-21' && session === 'RX' ? [pro4WithApr21NonCount[3]]
            : Providers === 'Provider4' && Month === 'May-21' && session === 'RX' ? [pro4WithMay21NonCount[3]]
            : Providers === 'Provider4' && Month === 'Jun-21' && session === 'RX' ? [pro4WithJun21NonCount[3]]
            : Providers === 'Provider4' && Month === 'Jul-21' && session === 'RX' ? [pro4WithJul21NonCount[3]]
            : Providers === 'Provider4' && Month === 'Aug-21' && session === 'RX' ? [pro4WithAug21NonCount[3]]
            : Providers === 'Provider4' && Month === 'Sep-21' && session === 'RX' ? [pro4WithSep21NonCount[3]]
            : Providers === 'Provider4' && Month === 'Oct-21' && session === 'RX' ? [pro4WithOct21NonCount[3]]
            : Providers === 'Provider4' && Month === 'Nov-21' && session === 'RX' ? [pro4WithNov21NonCount[3]]
            : Providers === 'Provider4' && Month === 'Dec-21' && session === 'RX' ? [pro4WithDec21NonCount[3]]
            : Providers === 'Provider4' && Month === 'Jan-22' && session === 'RX' ? [pro4WithJan22NonCount[3]]
            : Providers === 'Provider4' && Month === 'Feb-22' && session === 'RX' ? [pro4WithFeb22NonCount[3]]
            : Providers === 'Provider4' && Month === 'Mar-22' && session === 'RX' ? [pro4WithMar22NonCount[3]]
            : Providers === 'Provider4' && Month === 'Apr-22' && session === 'RX' ? [pro4WithApr22NonCount[3]]
            : Providers === 'Provider4' && Month === 'May-22' && session === 'RX' ? [pro4WithMay22NonCount[3]]
            : Providers === 'Provider4' && Month === 'Jun-22' && session === 'RX' ? [pro4WithJun22NonCount[3]]
            : Providers === 'Provider4' && Month === 'Jul-22' && session === 'RX' ? [pro4WithJul22NonCount[3]]
            : Providers === 'Provider4' && Month === 'Aug-22' && session === 'RX' ? [pro4WithAug22NonCount[3]]
            : Providers === 'Provider4' && Month === 'Sep-22' && session === 'RX' ? [pro4WithSep22NonCount[3]]
            : Providers === 'Provider4' && Month === 'Oct-22' && session === 'RX' ? [pro4WithOct22NonCount[3]]
            : Providers === 'Provider4' && Month === 'Nov-22' && session === 'RX' ? [pro4WithNov22NonCount[3]]
            : Providers === 'Provider4' && Month === 'Dec-22' && session === 'RX' ? [pro4WithDec22NonCount[3]]
            : Providers === 'Provider4' && Month === 'Jan-23' && session === 'RX' ? [pro4WithJan23NonCount[3]]
            : Providers === 'Provider4' && Month === 'Feb-23' && session === 'RX' ? [pro4WithFeb23NonCount[3]]
            : Providers === 'Provider4' && Month === 'Mar-23' && session === 'RX' ? [pro4WithMar23NonCount[3]]
            : Providers === 'Provider4' && Month === 'Apr-23' && session === 'RX' ? [pro4WithApr23NonCount[3]]
            : Providers === 'Provider4' && Month === 'May-23' && session === 'RX' ? [pro4WithMay23NonCount[3]]
            : Providers === 'Provider4' && Month === 'Jun-23' && session === 'RX' ? [pro4WithJun23NonCount[3]]
            : Providers === 'Provider4' && Month === 'Jul-23' && session === 'RX' ? [pro4WithJul23NonCount[3]]
            : Providers === 'Provider4' && Month === 'Aug-23' && session === 'RX' ? [pro4WithAug23NonCount[3]]
            : Providers === 'Provider4' && Month === 'Sep-23' && session === 'RX' ? [pro4WithSep23NonCount[3]]
            : Providers === 'Provider4' && Month === 'Oct-23' && session === 'RX' ? [pro4WithOct23NonCount[3]]
            : Providers === 'Provider4' && Month === 'Nov-23' && session === 'RX' ? [pro4WithNov23NonCount[3]]
            : Providers === 'Provider5' && Month === 'Jan-21' && session === 'RX' ? [pro5WithJan21NonCount[3]]
            : Providers === 'Provider5' && Month === 'Feb-21' && session === 'RX' ? [pro5WithFeb21NonCount[3]]
            : Providers === 'Provider5' && Month === 'Mar-21' && session === 'RX' ? [pro5WithMar21NonCount[3]]
            : Providers === 'Provider5' && Month === 'Apr-21' && session === 'RX' ? [pro5WithApr21NonCount[3]]
            : Providers === 'Provider5' && Month === 'May-21' && session === 'RX' ? [pro5WithMay21NonCount[3]]
            : Providers === 'Provider5' && Month === 'Jun-21' && session === 'RX' ? [pro5WithJun21NonCount[3]]
            : Providers === 'Provider5' && Month === 'Jul-21' && session === 'RX' ? [pro5WithJul21NonCount[3]]
            : Providers === 'Provider5' && Month === 'Aug-21' && session === 'RX' ? [pro5WithAug21NonCount[3]]
            : Providers === 'Provider5' && Month === 'Sep-21' && session === 'RX' ? [pro5WithSep21NonCount[3]]
            : Providers === 'Provider5' && Month === 'Oct-21' && session === 'RX' ? [pro5WithOct21NonCount[3]]
            : Providers === 'Provider5' && Month === 'Nov-21' && session === 'RX' ? [pro5WithNov21NonCount[3]]
            : Providers === 'Provider5' && Month === 'Dec-21' && session === 'RX' ? [pro5WithDec21NonCount[3]]
            : Providers === 'Provider5' && Month === 'Jan-22' && session === 'RX' ? [pro5WithJan22NonCount[3]]
            : Providers === 'Provider5' && Month === 'Feb-22' && session === 'RX' ? [pro5WithFeb22NonCount[3]]
            : Providers === 'Provider5' && Month === 'Mar-22' && session === 'RX' ? [pro5WithMar22NonCount[3]]
            : Providers === 'Provider5' && Month === 'Apr-22' && session === 'RX' ? [pro5WithApr22NonCount[3]]
            : Providers === 'Provider5' && Month === 'May-22' && session === 'RX' ? [pro5WithMay22NonCount[3]]
            : Providers === 'Provider5' && Month === 'Jun-22' && session === 'RX' ? [pro5WithJun22NonCount[3]]
            : Providers === 'Provider5' && Month === 'Jul-22' && session === 'RX' ? [pro5WithJul22NonCount[3]]
            : Providers === 'Provider5' && Month === 'Aug-22' && session === 'RX' ? [pro5WithAug22NonCount[3]]
            : Providers === 'Provider5' && Month === 'Sep-22' && session === 'RX' ? [pro5WithSep22NonCount[3]]
            : Providers === 'Provider5' && Month === 'Oct-22' && session === 'RX' ? [pro5WithOct22NonCount[3]]
            : Providers === 'Provider5' && Month === 'Nov-22' && session === 'RX' ? [pro5WithNov22NonCount[3]]
            : Providers === 'Provider5' && Month === 'Dec-22' && session === 'RX' ? [pro5WithDec22NonCount[3]]
            : Providers === 'Provider5' && Month === 'Jan-23' && session === 'RX' ? [pro5WithJan23NonCount[3]]
            : Providers === 'Provider5' && Month === 'Feb-23' && session === 'RX' ? [pro5WithFeb23NonCount[3]]
            : Providers === 'Provider5' && Month === 'Mar-23' && session === 'RX' ? [pro5WithMar23NonCount[3]]
            : Providers === 'Provider5' && Month === 'Apr-23' && session === 'RX' ? [pro5WithApr23NonCount[3]]
            : Providers === 'Provider5' && Month === 'May-23' && session === 'RX' ? [pro5WithMay23NonCount[3]]
            : Providers === 'Provider5' && Month === 'Jun-23' && session === 'RX' ? [pro5WithJun23NonCount[3]]
            : Providers === 'Provider5' && Month === 'Jul-23' && session === 'RX' ? [pro5WithJul23NonCount[3]]
            : Providers === 'Provider5' && Month === 'Aug-23' && session === 'RX' ? [pro5WithAug23NonCount[3]]
            : Providers === 'Provider5' && Month === 'Sep-23' && session === 'RX' ? [pro5WithSep23NonCount[3]]
            : Providers === 'Provider5' && Month === 'Oct-23' && session === 'RX' ? [pro5WithOct23NonCount[3]]
            : Providers === 'Provider5' && Month === 'Nov-23' && session === 'RX' ? [pro5WithNov23NonCount[3]]
            : Providers === 'Provider6' && Month === 'Jan-21' && session === 'RX' ? [pro6WithJan21NonCount[3]]
            : Providers === 'Provider6' && Month === 'Feb-21' && session === 'RX' ? [pro6WithFeb21NonCount[3]]
            : Providers === 'Provider6' && Month === 'Mar-21' && session === 'RX' ? [pro6WithMar21NonCount[3]]
            : Providers === 'Provider6' && Month === 'Apr-21' && session === 'RX' ? [pro6WithApr21NonCount[3]]
            : Providers === 'Provider6' && Month === 'May-21' && session === 'RX' ? [pro6WithMay21NonCount[3]]
            : Providers === 'Provider6' && Month === 'Jun-21' && session === 'RX' ? [pro6WithJun21NonCount[3]]
            : Providers === 'Provider6' && Month === 'Jul-21' && session === 'RX' ? [pro6WithJul21NonCount[3]]
            : Providers === 'Provider6' && Month === 'Aug-21' && session === 'RX' ? [pro6WithAug21NonCount[3]]
            : Providers === 'Provider6' && Month === 'Sep-21' && session === 'RX' ? [pro6WithSep21NonCount[3]]
            : Providers === 'Provider6' && Month === 'Oct-21' && session === 'RX' ? [pro6WithOct21NonCount[3]]
            : Providers === 'Provider6' && Month === 'Nov-21' && session === 'RX' ? [pro6WithNov21NonCount[3]]
            : Providers === 'Provider6' && Month === 'Dec-21' && session === 'RX' ? [pro6WithDec21NonCount[3]]
            : Providers === 'Provider6' && Month === 'Jan-22' && session === 'RX' ? [pro6WithJan22NonCount[3]]
            : Providers === 'Provider6' && Month === 'Feb-22' && session === 'RX' ? [pro6WithFeb22NonCount[3]]
            : Providers === 'Provider6' && Month === 'Mar-22' && session === 'RX' ? [pro6WithMar22NonCount[3]]
            : Providers === 'Provider6' && Month === 'Apr-22' && session === 'RX' ? [pro6WithApr22NonCount[3]]
            : Providers === 'Provider6' && Month === 'May-22' && session === 'RX' ? [pro6WithMay22NonCount[3]]
            : Providers === 'Provider6' && Month === 'Jun-22' && session === 'RX' ? [pro6WithJun22NonCount[3]]
            : Providers === 'Provider6' && Month === 'Jul-22' && session === 'RX' ? [pro6WithJul22NonCount[3]]
            : Providers === 'Provider6' && Month === 'Aug-22' && session === 'RX' ? [pro6WithAug22NonCount[3]]
            : Providers === 'Provider6' && Month === 'Sep-22' && session === 'RX' ? [pro6WithSep22NonCount[3]]
            : Providers === 'Provider6' && Month === 'Oct-22' && session === 'RX' ? [pro6WithOct22NonCount[3]]
            : Providers === 'Provider6' && Month === 'Nov-22' && session === 'RX' ? [pro6WithNov22NonCount[3]]
            : Providers === 'Provider6' && Month === 'Dec-22' && session === 'RX' ? [pro6WithDec22NonCount[3]]
            : Providers === 'Provider6' && Month === 'Jan-23' && session === 'RX' ? [pro6WithJan23NonCount[3]]
            : Providers === 'Provider6' && Month === 'Feb-23' && session === 'RX' ? [pro6WithFeb23NonCount[3]]
            : Providers === 'Provider6' && Month === 'Mar-23' && session === 'RX' ? [pro6WithMar23NonCount[3]]
            : Providers === 'Provider6' && Month === 'Apr-23' && session === 'RX' ? [pro6WithApr23NonCount[3]]
            : Providers === 'Provider6' && Month === 'May-23' && session === 'RX' ? [pro6WithMay23NonCount[3]]
            : Providers === 'Provider6' && Month === 'Jun-23' && session === 'RX' ? [pro6WithJun23NonCount[3]]
            : Providers === 'Provider6' && Month === 'Jul-23' && session === 'RX' ? [pro6WithJul23NonCount[3]]
            : Providers === 'Provider6' && Month === 'Aug-23' && session === 'RX' ? [pro6WithAug23NonCount[3]]
            : Providers === 'Provider6' && Month === 'Sep-23' && session === 'RX' ? [pro6WithSep23NonCount[3]]
            : Providers === 'Provider6' && Month === 'Oct-23' && session === 'RX' ? [pro6WithOct23NonCount[3]]
            : Providers === 'Provider6' && Month === 'Nov-23' && session === 'RX' ? [pro6WithNov23NonCount[3]]
            : Providers === 'Provider7' && Month === 'Jan-21' && session === 'RX' ? [pro7WithJan21NonCount[3]]
            : Providers === 'Provider7' && Month === 'Feb-21' && session === 'RX' ? [pro7WithFeb21NonCount[3]]
            : Providers === 'Provider7' && Month === 'Mar-21' && session === 'RX' ? [pro7WithMar21NonCount[3]]
            : Providers === 'Provider7' && Month === 'Apr-21' && session === 'RX' ? [pro7WithApr21NonCount[3]]
            : Providers === 'Provider7' && Month === 'May-21' && session === 'RX' ? [pro7WithMay21NonCount[3]]
            : Providers === 'Provider7' && Month === 'Jun-21' && session === 'RX' ? [pro7WithJun21NonCount[3]]
            : Providers === 'Provider7' && Month === 'Jul-21' && session === 'RX' ? [pro7WithJul21NonCount[3]]
            : Providers === 'Provider7' && Month === 'Aug-21' && session === 'RX' ? [pro7WithAug21NonCount[3]]
            : Providers === 'Provider7' && Month === 'Sep-21' && session === 'RX' ? [pro7WithSep21NonCount[3]]
            : Providers === 'Provider7' && Month === 'Oct-21' && session === 'RX' ? [pro7WithOct21NonCount[3]]
            : Providers === 'Provider7' && Month === 'Nov-21' && session === 'RX' ? [pro7WithNov21NonCount[3]]
            : Providers === 'Provider7' && Month === 'Dec-21' && session === 'RX' ? [pro7WithDec21NonCount[3]]
            : Providers === 'Provider7' && Month === 'Jan-22' && session === 'RX' ? [pro7WithJan22NonCount[3]]
            : Providers === 'Provider7' && Month === 'Feb-22' && session === 'RX' ? [pro7WithFeb22NonCount[3]]
            : Providers === 'Provider7' && Month === 'Mar-22' && session === 'RX' ? [pro7WithMar22NonCount[3]]
            : Providers === 'Provider7' && Month === 'Apr-22' && session === 'RX' ? [pro7WithApr22NonCount[3]]
            : Providers === 'Provider7' && Month === 'May-22' && session === 'RX' ? [pro7WithMay22NonCount[3]]
            : Providers === 'Provider7' && Month === 'Jun-22' && session === 'RX' ? [pro7WithJun22NonCount[3]]
            : Providers === 'Provider7' && Month === 'Jul-22' && session === 'RX' ? [pro7WithJul22NonCount[3]]
            : Providers === 'Provider7' && Month === 'Aug-22' && session === 'RX' ? [pro7WithAug22NonCount[3]]
            : Providers === 'Provider7' && Month === 'Sep-22' && session === 'RX' ? [pro7WithSep22NonCount[3]]
            : Providers === 'Provider7' && Month === 'Oct-22' && session === 'RX' ? [pro7WithOct22NonCount[3]]
            : Providers === 'Provider7' && Month === 'Nov-22' && session === 'RX' ? [pro7WithNov22NonCount[3]]
            : Providers === 'Provider7' && Month === 'Dec-22' && session === 'RX' ? [pro7WithDec22NonCount[3]]
            : Providers === 'Provider7' && Month === 'Jan-23' && session === 'RX' ? [pro7WithJan23NonCount[3]]
            : Providers === 'Provider7' && Month === 'Feb-23' && session === 'RX' ? [pro7WithFeb23NonCount[3]]
            : Providers === 'Provider7' && Month === 'Mar-23' && session === 'RX' ? [pro7WithMar23NonCount[3]]
            : Providers === 'Provider7' && Month === 'Apr-23' && session === 'RX' ? [pro7WithApr23NonCount[3]]
            : Providers === 'Provider7' && Month === 'May-23' && session === 'RX' ? [pro7WithMay23NonCount[3]]
            : Providers === 'Provider7' && Month === 'Jun-23' && session === 'RX' ? [pro7WithJun23NonCount[3]]
            : Providers === 'Provider7' && Month === 'Jul-23' && session === 'RX' ? [pro7WithJul23NonCount[3]]
            : Providers === 'Provider7' && Month === 'Aug-23' && session === 'RX' ? [pro7WithAug23NonCount[3]]
            : Providers === 'Provider7' && Month === 'Sep-23' && session === 'RX' ? [pro7WithSep23NonCount[3]]
            : Providers === 'Provider7' && Month === 'Oct-23' && session === 'RX' ? [pro7WithOct23NonCount[3]]
            : Providers === 'Provider7' && Month === 'Nov-23' && session === 'RX' ? [pro7WithNov23NonCount[3]]
                : ''
            },{
                name: 'Claims',
                data:Providers === 'All Providers' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21Claim[3]]
                : Providers === 'All Providers' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21Claim[3]]
                : Providers === 'All Providers' && Month === 'Mar-21' && session === 'RX' ? [allproWithMar21Claim[3]]
                : Providers === 'All Providers' && Month === 'Apr-21' && session === 'RX' ? [allproWithApr21Claim[3]]
                : Providers === 'All Providers' && Month === 'May-21' && session === 'RX' ? [allproWithMay21Claim[3]]
                : Providers === 'All Providers' && Month === 'Jun-21' && session === 'RX' ? [allproWithJun21Claim[3]]
                : Providers === 'All Providers' && Month === 'Jul-21' && session === 'RX' ? [allproWithJul21Claim[3]]
                : Providers === 'All Providers' && Month === 'Aug-21' && session === 'RX' ? [allproWithAug21Claim[3]]
                : Providers === 'All Providers' && Month === 'Sep-21' && session === 'RX' ? [allproWithSep21Claim[3]]
                : Providers === 'All Providers' && Month === 'Oct-21' && session === 'RX' ? [allproWithOct21Claim[3]]
                : Providers === 'All Providers' && Month === 'Nov-21' && session === 'RX' ? [allproWithNov21Claim[3]]
                : Providers === 'All Providers' && Month === 'Dec-21' && session === 'RX' ? [allproWithDec21Claim[3]]
                : Providers === 'All Providers' && Month === 'Jan-22' && session === 'RX' ? [allproWithJan22Claim[3]]
                : Providers === 'All Providers' && Month === 'Feb-22' && session === 'RX' ? [allproWithFeb22Claim[3]]
                : Providers === 'All Providers' && Month === 'Mar-22' && session === 'RX' ? [allproWithMar22Claim[3]]
                : Providers === 'All Providers' && Month === 'Apr-22' && session === 'RX' ? [allproWithApr22Claim[3]]
                : Providers === 'All Providers' && Month === 'May-22' && session === 'RX' ? [allproWithMay22Claim[3]]
                : Providers === 'All Providers' && Month === 'Jun-22' && session === 'RX' ? [allproWithJun22Claim[3]]
                : Providers === 'All Providers' && Month === 'Jul-22' && session === 'RX' ? [allproWithJul22Claim[3]]
                : Providers === 'All Providers' && Month === 'Aug-22' && session === 'RX' ? [allproWithAug22Claim[3]]
                : Providers === 'All Providers' && Month === 'Sep-22' && session === 'RX' ? [allproWithSep22Claim[3]]
                : Providers === 'All Providers' && Month === 'Oct-22' && session === 'RX' ? [allproWithOct22Claim[3]]
                : Providers === 'All Providers' && Month === 'Nov-22' && session === 'RX' ? [allproWithNov22Claim[3]]
                : Providers === 'All Providers' && Month === 'Dec-22' && session === 'RX' ? [allproWithDec22Claim[3]]
                : Providers === 'All Providers' && Month === 'Jan-23' && session === 'RX' ? [allproWithJan23Claim[3]]
                : Providers === 'All Providers' && Month === 'Feb-23' && session === 'RX' ? [allproWithFeb23Claim[3]]
                : Providers === 'All Providers' && Month === 'Mar-23' && session === 'RX' ? [allproWithMar23Claim[3]]
                : Providers === 'All Providers' && Month === 'Apr-23' && session === 'RX' ? [allproWithApr23Claim[3]]
                : Providers === 'All Providers' && Month === 'May-23' && session === 'RX' ? [allproWithMay23Claim[3]]
                : Providers === 'All Providers' && Month === 'Jun-23' && session === 'RX' ? [allproWithJun23Claim[3]]
                : Providers === 'All Providers' && Month === 'Jul-23' && session === 'RX' ? [allproWithJul23Claim[3]]
                : Providers === 'All Providers' && Month === 'Aug-23' && session === 'RX' ? [allproWithAug23Claim[3]]
                : Providers === 'All Providers' && Month === 'Sep-23' && session === 'RX' ? [allproWithSep23Claim[3]]
                : Providers === 'All Providers' && Month === 'Oct-23' && session === 'RX' ? [allproWithOct23Claim[3]]
                : Providers === 'All Providers' && Month === 'Nov-23' && session === 'RX' ? [allproWithNov23Claim[3]]
                : Providers === 'Provider1' && Month === 'Jan-21' && session === 'RX' ? [pro1WithJan21Claim[3]]
                : Providers === 'Provider1' && Month === 'Feb-21' && session === 'RX' ? [pro1WithFeb21Claim[3]]
                : Providers === 'Provider1' && Month === 'Mar-21' && session === 'RX' ? [pro1WithMar21Claim[3]]
                : Providers === 'Provider1' && Month === 'Apr-21' && session === 'RX' ? [pro1WithApr21Claim[3]]
                : Providers === 'Provider1' && Month === 'May-21' && session === 'RX' ? [pro1WithMay21Claim[3]]
                : Providers === 'Provider1' && Month === 'Jun-21' && session === 'RX' ? [pro1WithJun21Claim[3]]
                : Providers === 'Provider1' && Month === 'Jul-21' && session === 'RX' ? [pro1WithJul21Claim[3]]
                : Providers === 'Provider1' && Month === 'Aug-21' && session === 'RX' ? [pro1WithAug21Claim[3]]
                : Providers === 'Provider1' && Month === 'Sep-21' && session === 'RX' ? [pro1WithSep21Claim[3]]
                : Providers === 'Provider1' && Month === 'Oct-21' && session === 'RX' ? [pro1WithOct21Claim[3]]
                : Providers === 'Provider1' && Month === 'Nov-21' && session === 'RX' ? [pro1WithNov21Claim[3]]
                : Providers === 'Provider1' && Month === 'Dec-21' && session === 'RX' ? [pro1WithDec21Claim[3]]
                : Providers === 'Provider1' && Month === 'Jan-22' && session === 'RX' ? [pro1WithJan22Claim[3]]
                : Providers === 'Provider1' && Month === 'Feb-22' && session === 'RX' ? [pro1WithFeb22Claim[3]]
                : Providers === 'Provider1' && Month === 'Mar-22' && session === 'RX' ? [pro1WithMar22Claim[3]]
                : Providers === 'Provider1' && Month === 'Apr-22' && session === 'RX' ? [pro1WithApr22Claim[3]]
                : Providers === 'Provider1' && Month === 'May-22' && session === 'RX' ? [pro1WithMay22Claim[3]]
                : Providers === 'Provider1' && Month === 'Jun-22' && session === 'RX' ? [pro1WithJun22Claim[3]]
                : Providers === 'Provider1' && Month === 'Jul-22' && session === 'RX' ? [pro1WithJul22Claim[3]]
                : Providers === 'Provider1' && Month === 'Aug-22' && session === 'RX' ? [pro1WithAug22Claim[3]]
                : Providers === 'Provider1' && Month === 'Sep-22' && session === 'RX' ? [pro1WithSep22Claim[3]]
                : Providers === 'Provider1' && Month === 'Oct-22' && session === 'RX' ? [pro1WithOct22Claim[3]]
                : Providers === 'Provider1' && Month === 'Nov-22' && session === 'RX' ? [pro1WithNov22Claim[3]]
                : Providers === 'Provider1' && Month === 'Dec-22' && session === 'RX' ? [pro1WithDec22Claim[3]]
                : Providers === 'Provider1' && Month === 'Jan-23' && session === 'RX' ? [pro1WithJan23Claim[3]]
                : Providers === 'Provider1' && Month === 'Feb-23' && session === 'RX' ? [pro1WithFeb23Claim[3]]
                : Providers === 'Provider1' && Month === 'Mar-23' && session === 'RX' ? [pro1WithMar23Claim[3]]
                : Providers === 'Provider1' && Month === 'Apr-23' && session === 'RX' ? [pro1WithApr23Claim[3]]
                : Providers === 'Provider1' && Month === 'May-23' && session === 'RX' ? [pro1WithMay23Claim[3]]
                : Providers === 'Provider1' && Month === 'Jun-23' && session === 'RX' ? [pro1WithJun23Claim[3]]
                : Providers === 'Provider1' && Month === 'Jul-23' && session === 'RX' ? [pro1WithJul23Claim[3]]
                : Providers === 'Provider1' && Month === 'Aug-23' && session === 'RX' ? [pro1WithAug23Claim[3]]
                : Providers === 'Provider1' && Month === 'Sep-23' && session === 'RX' ? [pro1WithSep23Claim[3]]
                : Providers === 'Provider1' && Month === 'Oct-23' && session === 'RX' ? [pro1WithOct23Claim[3]]
                : Providers === 'Provider1' && Month === 'Nov-23' && session === 'RX' ? [pro1WithNov23Claim[3]]
                : Providers === 'Provider2' && Month === 'Jan-21' && session === 'RX' ? [pro2WithJan21Claim[3]]
                : Providers === 'Provider2' && Month === 'Feb-21' && session === 'RX' ? [pro2WithFeb21Claim[3]]
                : Providers === 'Provider2' && Month === 'Mar-21' && session === 'RX' ? [pro2WithMar21Claim[3]]
                : Providers === 'Provider2' && Month === 'Apr-21' && session === 'RX' ? [pro2WithApr21Claim[3]]
                : Providers === 'Provider2' && Month === 'May-21' && session === 'RX' ? [pro2WithMay21Claim[3]]
                : Providers === 'Provider2' && Month === 'Jun-21' && session === 'RX' ? [pro2WithJun21Claim[3]]
                : Providers === 'Provider2' && Month === 'Jul-21' && session === 'RX' ? [pro2WithJul21Claim[3]]
                : Providers === 'Provider2' && Month === 'Aug-21' && session === 'RX' ? [pro2WithAug21Claim[3]]
                : Providers === 'Provider2' && Month === 'Sep-21' && session === 'RX' ? [pro2WithSep21Claim[3]]
                : Providers === 'Provider2' && Month === 'Oct-21' && session === 'RX' ? [pro2WithOct21Claim[3]]
                : Providers === 'Provider2' && Month === 'Nov-21' && session === 'RX' ? [pro2WithNov21Claim[3]]
                : Providers === 'Provider2' && Month === 'Dec-21' && session === 'RX' ? [pro2WithDec21Claim[3]]
                : Providers === 'Provider2' && Month === 'Jan-22' && session === 'RX' ? [pro2WithJan22Claim[3]]
                : Providers === 'Provider2' && Month === 'Feb-22' && session === 'RX' ? [pro2WithFeb22Claim[3]]
                : Providers === 'Provider2' && Month === 'Mar-22' && session === 'RX' ? [pro2WithMar22Claim[3]]
                : Providers === 'Provider2' && Month === 'Apr-22' && session === 'RX' ? [pro2WithApr22Claim[3]]
                : Providers === 'Provider2' && Month === 'May-22' && session === 'RX' ? [pro2WithMay22Claim[3]]
                : Providers === 'Provider2' && Month === 'Jun-22' && session === 'RX' ? [pro2WithJun22Claim[3]]
                : Providers === 'Provider2' && Month === 'Jul-22' && session === 'RX' ? [pro2WithJul22Claim[3]]
                : Providers === 'Provider2' && Month === 'Aug-22' && session === 'RX' ? [pro2WithAug22Claim[3]]
                : Providers === 'Provider2' && Month === 'Sep-22' && session === 'RX' ? [pro2WithSep22Claim[3]]
                : Providers === 'Provider2' && Month === 'Oct-22' && session === 'RX' ? [pro2WithOct22Claim[3]]
                : Providers === 'Provider2' && Month === 'Nov-22' && session === 'RX' ? [pro2WithNov22Claim[3]]
                : Providers === 'Provider2' && Month === 'Dec-22' && session === 'RX' ? [pro2WithDec22Claim[3]]
                : Providers === 'Provider2' && Month === 'Jan-23' && session === 'RX' ? [pro2WithJan23Claim[3]]
                : Providers === 'Provider2' && Month === 'Feb-23' && session === 'RX' ? [pro2WithFeb23Claim[3]]
                : Providers === 'Provider2' && Month === 'Mar-23' && session === 'RX' ? [pro2WithMar23Claim[3]]
                : Providers === 'Provider2' && Month === 'Apr-23' && session === 'RX' ? [pro2WithApr23Claim[3]]
                : Providers === 'Provider2' && Month === 'May-23' && session === 'RX' ? [pro2WithMay23Claim[3]]
                : Providers === 'Provider2' && Month === 'Jun-23' && session === 'RX' ? [pro2WithJun23Claim[3]]
                : Providers === 'Provider2' && Month === 'Jul-23' && session === 'RX' ? [pro2WithJul23Claim[3]]
                : Providers === 'Provider2' && Month === 'Aug-23' && session === 'RX' ? [pro2WithAug23Claim[3]]
                : Providers === 'Provider2' && Month === 'Sep-23' && session === 'RX' ? [pro2WithSep23Claim[3]]
                : Providers === 'Provider2' && Month === 'Oct-23' && session === 'RX' ? [pro2WithOct23Claim[3]]
                : Providers === 'Provider2' && Month === 'Nov-23' && session === 'RX' ? [pro2WithNov23Claim[3]]
                : Providers === 'Provider3' && Month === 'Jan-21' && session === 'RX' ? [pro3WithJan21Claim[3]]
                : Providers === 'Provider3' && Month === 'Feb-21' && session === 'RX' ? [pro3WithFeb21Claim[3]]
                : Providers === 'Provider3' && Month === 'Mar-21' && session === 'RX' ? [pro3WithMar21Claim[3]]
                : Providers === 'Provider3' && Month === 'Apr-21' && session === 'RX' ? [pro3WithApr21Claim[3]]
                : Providers === 'Provider3' && Month === 'May-21' && session === 'RX' ? [pro3WithMay21Claim[3]]
                : Providers === 'Provider3' && Month === 'Jun-21' && session === 'RX' ? [pro3WithJun21Claim[3]]
                : Providers === 'Provider3' && Month === 'Jul-21' && session === 'RX' ? [pro3WithJul21Claim[3]]
                : Providers === 'Provider3' && Month === 'Aug-21' && session === 'RX' ? [pro3WithAug21Claim[3]]
                : Providers === 'Provider3' && Month === 'Sep-21' && session === 'RX' ? [pro3WithSep21Claim[3]]
                : Providers === 'Provider3' && Month === 'Oct-21' && session === 'RX' ? [pro3WithOct21Claim[3]]
                : Providers === 'Provider3' && Month === 'Nov-21' && session === 'RX' ? [pro3WithNov21Claim[3]]
                : Providers === 'Provider3' && Month === 'Dec-21' && session === 'RX' ? [pro3WithDec21Claim[3]]
                : Providers === 'Provider3' && Month === 'Jan-22' && session === 'RX' ? [pro3WithJan22Claim[3]]
                : Providers === 'Provider3' && Month === 'Feb-22' && session === 'RX' ? [pro3WithFeb22Claim[3]]
                : Providers === 'Provider3' && Month === 'Mar-22' && session === 'RX' ? [pro3WithMar22Claim[3]]
                : Providers === 'Provider3' && Month === 'Apr-22' && session === 'RX' ? [pro3WithApr22Claim[3]]
                : Providers === 'Provider3' && Month === 'May-22' && session === 'RX' ? [pro3WithMay22Claim[3]]
                : Providers === 'Provider3' && Month === 'Jun-22' && session === 'RX' ? [pro3WithJun22Claim[3]]
                : Providers === 'Provider3' && Month === 'Jul-22' && session === 'RX' ? [pro3WithJul22Claim[3]]
                : Providers === 'Provider3' && Month === 'Aug-22' && session === 'RX' ? [pro3WithAug22Claim[3]]
                : Providers === 'Provider3' && Month === 'Sep-22' && session === 'RX' ? [pro3WithSep22Claim[3]]
                : Providers === 'Provider3' && Month === 'Oct-22' && session === 'RX' ? [pro3WithOct22Claim[3]]
                : Providers === 'Provider3' && Month === 'Nov-22' && session === 'RX' ? [pro3WithNov22Claim[3]]
                : Providers === 'Provider3' && Month === 'Dec-22' && session === 'RX' ? [pro3WithDec22Claim[3]]
                : Providers === 'Provider3' && Month === 'Jan-23' && session === 'RX' ? [pro3WithJan23Claim[3]]
                : Providers === 'Provider3' && Month === 'Feb-23' && session === 'RX' ? [pro3WithFeb23Claim[3]]
                : Providers === 'Provider3' && Month === 'Mar-23' && session === 'RX' ? [pro3WithMar23Claim[3]]
                : Providers === 'Provider3' && Month === 'Apr-23' && session === 'RX' ? [pro3WithApr23Claim[3]]
                : Providers === 'Provider3' && Month === 'May-23' && session === 'RX' ? [pro3WithMay23Claim[3]]
                : Providers === 'Provider3' && Month === 'Jun-23' && session === 'RX' ? [pro3WithJun23Claim[3]]
                : Providers === 'Provider3' && Month === 'Jul-23' && session === 'RX' ? [pro3WithJul23Claim[3]]
                : Providers === 'Provider3' && Month === 'Aug-23' && session === 'RX' ? [pro3WithAug23Claim[3]]
                : Providers === 'Provider3' && Month === 'Sep-23' && session === 'RX' ? [pro3WithSep23Claim[3]]
                : Providers === 'Provider3' && Month === 'Oct-23' && session === 'RX' ? [pro3WithOct23Claim[3]]
                : Providers === 'Provider3' && Month === 'Nov-23' && session === 'RX' ? [pro3WithNov23Claim[3]]
                : Providers === 'Provider4' && Month === 'Jan-21' && session === 'RX' ? [pro4WithJan21Claim[3]]
                : Providers === 'Provider4' && Month === 'Feb-21' && session === 'RX' ? [pro4WithFeb21Claim[3]]
                : Providers === 'Provider4' && Month === 'Mar-21' && session === 'RX' ? [pro4WithMar21Claim[3]]
                : Providers === 'Provider4' && Month === 'Apr-21' && session === 'RX' ? [pro4WithApr21Claim[3]]
                : Providers === 'Provider4' && Month === 'May-21' && session === 'RX' ? [pro4WithMay21Claim[3]]
                : Providers === 'Provider4' && Month === 'Jun-21' && session === 'RX' ? [pro4WithJun21Claim[3]]
                : Providers === 'Provider4' && Month === 'Jul-21' && session === 'RX' ? [pro4WithJul21Claim[3]]
                : Providers === 'Provider4' && Month === 'Aug-21' && session === 'RX' ? [pro4WithAug21Claim[3]]
                : Providers === 'Provider4' && Month === 'Sep-21' && session === 'RX' ? [pro4WithSep21Claim[3]]
                : Providers === 'Provider4' && Month === 'Oct-21' && session === 'RX' ? [pro4WithOct21Claim[3]]
                : Providers === 'Provider4' && Month === 'Nov-21' && session === 'RX' ? [pro4WithNov21Claim[3]]
                : Providers === 'Provider4' && Month === 'Dec-21' && session === 'RX' ? [pro4WithDec21Claim[3]]
                : Providers === 'Provider4' && Month === 'Jan-22' && session === 'RX' ? [pro4WithJan22Claim[3]]
                : Providers === 'Provider4' && Month === 'Feb-22' && session === 'RX' ? [pro4WithFeb22Claim[3]]
                : Providers === 'Provider4' && Month === 'Mar-22' && session === 'RX' ? [pro4WithMar22Claim[3]]
                : Providers === 'Provider4' && Month === 'Apr-22' && session === 'RX' ? [pro4WithApr22Claim[3]]
                : Providers === 'Provider4' && Month === 'May-22' && session === 'RX' ? [pro4WithMay22Claim[3]]
                : Providers === 'Provider4' && Month === 'Jun-22' && session === 'RX' ? [pro4WithJun22Claim[3]]
                : Providers === 'Provider4' && Month === 'Jul-22' && session === 'RX' ? [pro4WithJul22Claim[3]]
                : Providers === 'Provider4' && Month === 'Aug-22' && session === 'RX' ? [pro4WithAug22Claim[3]]
                : Providers === 'Provider4' && Month === 'Sep-22' && session === 'RX' ? [pro4WithSep22Claim[3]]
                : Providers === 'Provider4' && Month === 'Oct-22' && session === 'RX' ? [pro4WithOct22Claim[3]]
                : Providers === 'Provider4' && Month === 'Nov-22' && session === 'RX' ? [pro4WithNov22Claim[3]]
                : Providers === 'Provider4' && Month === 'Dec-22' && session === 'RX' ? [pro4WithDec22Claim[3]]
                : Providers === 'Provider4' && Month === 'Jan-23' && session === 'RX' ? [pro4WithJan23Claim[3]]
                : Providers === 'Provider4' && Month === 'Feb-23' && session === 'RX' ? [pro4WithFeb23Claim[3]]
                : Providers === 'Provider4' && Month === 'Mar-23' && session === 'RX' ? [pro4WithMar23Claim[3]]
                : Providers === 'Provider4' && Month === 'Apr-23' && session === 'RX' ? [pro4WithApr23Claim[3]]
                : Providers === 'Provider4' && Month === 'May-23' && session === 'RX' ? [pro4WithMay23Claim[3]]
                : Providers === 'Provider4' && Month === 'Jun-23' && session === 'RX' ? [pro4WithJun23Claim[3]]
                : Providers === 'Provider4' && Month === 'Jul-23' && session === 'RX' ? [pro4WithJul23Claim[3]]
                : Providers === 'Provider4' && Month === 'Aug-23' && session === 'RX' ? [pro4WithAug23Claim[3]]
                : Providers === 'Provider4' && Month === 'Sep-23' && session === 'RX' ? [pro4WithSep23Claim[3]]
                : Providers === 'Provider4' && Month === 'Oct-23' && session === 'RX' ? [pro4WithOct23Claim[3]]
                : Providers === 'Provider4' && Month === 'Nov-23' && session === 'RX' ? [pro4WithNov23Claim[3]]
                : Providers === 'Provider5' && Month === 'Jan-21' && session === 'RX' ? [pro5WithJan21Claim[3]]
                : Providers === 'Provider5' && Month === 'Feb-21' && session === 'RX' ? [pro5WithFeb21Claim[3]]
                : Providers === 'Provider5' && Month === 'Mar-21' && session === 'RX' ? [pro5WithMar21Claim[3]]
                : Providers === 'Provider5' && Month === 'Apr-21' && session === 'RX' ? [pro5WithApr21Claim[3]]
                : Providers === 'Provider5' && Month === 'May-21' && session === 'RX' ? [pro5WithMay21Claim[3]]
                : Providers === 'Provider5' && Month === 'Jun-21' && session === 'RX' ? [pro5WithJun21Claim[3]]
                : Providers === 'Provider5' && Month === 'Jul-21' && session === 'RX' ? [pro5WithJul21Claim[3]]
                : Providers === 'Provider5' && Month === 'Aug-21' && session === 'RX' ? [pro5WithAug21Claim[3]]
                : Providers === 'Provider5' && Month === 'Sep-21' && session === 'RX' ? [pro5WithSep21Claim[3]]
                : Providers === 'Provider5' && Month === 'Oct-21' && session === 'RX' ? [pro5WithOct21Claim[3]]
                : Providers === 'Provider5' && Month === 'Nov-21' && session === 'RX' ? [pro5WithNov21Claim[3]]
                : Providers === 'Provider5' && Month === 'Dec-21' && session === 'RX' ? [pro5WithDec21Claim[3]]
                : Providers === 'Provider5' && Month === 'Jan-22' && session === 'RX' ? [pro5WithJan22Claim[3]]
                : Providers === 'Provider5' && Month === 'Feb-22' && session === 'RX' ? [pro5WithFeb22Claim[3]]
                : Providers === 'Provider5' && Month === 'Mar-22' && session === 'RX' ? [pro5WithMar22Claim[3]]
                : Providers === 'Provider5' && Month === 'Apr-22' && session === 'RX' ? [pro5WithApr22Claim[3]]
                : Providers === 'Provider5' && Month === 'May-22' && session === 'RX' ? [pro5WithMay22Claim[3]]
                : Providers === 'Provider5' && Month === 'Jun-22' && session === 'RX' ? [pro5WithJun22Claim[3]]
                : Providers === 'Provider5' && Month === 'Jul-22' && session === 'RX' ? [pro5WithJul22Claim[3]]
                : Providers === 'Provider5' && Month === 'Aug-22' && session === 'RX' ? [pro5WithAug22Claim[3]]
                : Providers === 'Provider5' && Month === 'Sep-22' && session === 'RX' ? [pro5WithSep22Claim[3]]
                : Providers === 'Provider5' && Month === 'Oct-22' && session === 'RX' ? [pro5WithOct22Claim[3]]
                : Providers === 'Provider5' && Month === 'Nov-22' && session === 'RX' ? [pro5WithNov22Claim[3]]
                : Providers === 'Provider5' && Month === 'Dec-22' && session === 'RX' ? [pro5WithDec22Claim[3]]
                : Providers === 'Provider5' && Month === 'Jan-23' && session === 'RX' ? [pro5WithJan23Claim[3]]
                : Providers === 'Provider5' && Month === 'Feb-23' && session === 'RX' ? [pro5WithFeb23Claim[3]]
                : Providers === 'Provider5' && Month === 'Mar-23' && session === 'RX' ? [pro5WithMar23Claim[3]]
                : Providers === 'Provider5' && Month === 'Apr-23' && session === 'RX' ? [pro5WithApr23Claim[3]]
                : Providers === 'Provider5' && Month === 'May-23' && session === 'RX' ? [pro5WithMay23Claim[3]]
                : Providers === 'Provider5' && Month === 'Jun-23' && session === 'RX' ? [pro5WithJun23Claim[3]]
                : Providers === 'Provider5' && Month === 'Jul-23' && session === 'RX' ? [pro5WithJul23Claim[3]]
                : Providers === 'Provider5' && Month === 'Aug-23' && session === 'RX' ? [pro5WithAug23Claim[3]]
                : Providers === 'Provider5' && Month === 'Sep-23' && session === 'RX' ? [pro5WithSep23Claim[3]]
                : Providers === 'Provider5' && Month === 'Oct-23' && session === 'RX' ? [pro5WithOct23Claim[3]]
                : Providers === 'Provider5' && Month === 'Nov-23' && session === 'RX' ? [pro5WithNov23Claim[3]]
                : Providers === 'Provider6' && Month === 'Jan-21' && session === 'RX' ? [pro6WithJan21Claim[3]]
                : Providers === 'Provider6' && Month === 'Feb-21' && session === 'RX' ? [pro6WithFeb21Claim[3]]
                : Providers === 'Provider6' && Month === 'Mar-21' && session === 'RX' ? [pro6WithMar21Claim[3]]
                : Providers === 'Provider6' && Month === 'Apr-21' && session === 'RX' ? [pro6WithApr21Claim[3]]
                : Providers === 'Provider6' && Month === 'May-21' && session === 'RX' ? [pro6WithMay21Claim[3]]
                : Providers === 'Provider6' && Month === 'Jun-21' && session === 'RX' ? [pro6WithJun21Claim[3]]
                : Providers === 'Provider6' && Month === 'Jul-21' && session === 'RX' ? [pro6WithJul21Claim[3]]
                : Providers === 'Provider6' && Month === 'Aug-21' && session === 'RX' ? [pro6WithAug21Claim[3]]
                : Providers === 'Provider6' && Month === 'Sep-21' && session === 'RX' ? [pro6WithSep21Claim[3]]
                : Providers === 'Provider6' && Month === 'Oct-21' && session === 'RX' ? [pro6WithOct21Claim[3]]
                : Providers === 'Provider6' && Month === 'Nov-21' && session === 'RX' ? [pro6WithNov21Claim[3]]
                : Providers === 'Provider6' && Month === 'Dec-21' && session === 'RX' ? [pro6WithDec21Claim[3]]
                : Providers === 'Provider6' && Month === 'Jan-22' && session === 'RX' ? [pro6WithJan22Claim[3]]
                : Providers === 'Provider6' && Month === 'Feb-22' && session === 'RX' ? [pro6WithFeb22Claim[3]]
                : Providers === 'Provider6' && Month === 'Mar-22' && session === 'RX' ? [pro6WithMar22Claim[3]]
                : Providers === 'Provider6' && Month === 'Apr-22' && session === 'RX' ? [pro6WithApr22Claim[3]]
                : Providers === 'Provider6' && Month === 'May-22' && session === 'RX' ? [pro6WithMay22Claim[3]]
                : Providers === 'Provider6' && Month === 'Jun-22' && session === 'RX' ? [pro6WithJun22Claim[3]]
                : Providers === 'Provider6' && Month === 'Jul-22' && session === 'RX' ? [pro6WithJul22Claim[3]]
                : Providers === 'Provider6' && Month === 'Aug-22' && session === 'RX' ? [pro6WithAug22Claim[3]]
                : Providers === 'Provider6' && Month === 'Sep-22' && session === 'RX' ? [pro6WithSep22Claim[3]]
                : Providers === 'Provider6' && Month === 'Oct-22' && session === 'RX' ? [pro6WithOct22Claim[3]]
                : Providers === 'Provider6' && Month === 'Nov-22' && session === 'RX' ? [pro6WithNov22Claim[3]]
                : Providers === 'Provider6' && Month === 'Dec-22' && session === 'RX' ? [pro6WithDec22Claim[3]]
                : Providers === 'Provider6' && Month === 'Jan-23' && session === 'RX' ? [pro6WithJan23Claim[3]]
                : Providers === 'Provider6' && Month === 'Feb-23' && session === 'RX' ? [pro6WithFeb23Claim[3]]
                : Providers === 'Provider6' && Month === 'Mar-23' && session === 'RX' ? [pro6WithMar23Claim[3]]
                : Providers === 'Provider6' && Month === 'Apr-23' && session === 'RX' ? [pro6WithApr23Claim[3]]
                : Providers === 'Provider6' && Month === 'May-23' && session === 'RX' ? [pro6WithMay23Claim[3]]
                : Providers === 'Provider6' && Month === 'Jun-23' && session === 'RX' ? [pro6WithJun23Claim[3]]
                : Providers === 'Provider6' && Month === 'Jul-23' && session === 'RX' ? [pro6WithJul23Claim[3]]
                : Providers === 'Provider6' && Month === 'Aug-23' && session === 'RX' ? [pro6WithAug23Claim[3]]
                : Providers === 'Provider6' && Month === 'Sep-23' && session === 'RX' ? [pro6WithSep23Claim[3]]
                : Providers === 'Provider6' && Month === 'Oct-23' && session === 'RX' ? [pro6WithOct23Claim[3]]
                : Providers === 'Provider6' && Month === 'Nov-23' && session === 'RX' ? [pro6WithNov23Claim[3]]
                : Providers === 'Provider7' && Month === 'Jan-21' && session === 'RX' ? [pro7WithJan21Claim[3]]
                : Providers === 'Provider7' && Month === 'Feb-21' && session === 'RX' ? [pro7WithFeb21Claim[3]]
                : Providers === 'Provider7' && Month === 'Mar-21' && session === 'RX' ? [pro7WithMar21Claim[3]]
                : Providers === 'Provider7' && Month === 'Apr-21' && session === 'RX' ? [pro7WithApr21Claim[3]]
                : Providers === 'Provider7' && Month === 'May-21' && session === 'RX' ? [pro7WithMay21Claim[3]]
                : Providers === 'Provider7' && Month === 'Jun-21' && session === 'RX' ? [pro7WithJun21Claim[3]]
                : Providers === 'Provider7' && Month === 'Jul-21' && session === 'RX' ? [pro7WithJul21Claim[3]]
                : Providers === 'Provider7' && Month === 'Aug-21' && session === 'RX' ? [pro7WithAug21Claim[3]]
                : Providers === 'Provider7' && Month === 'Sep-21' && session === 'RX' ? [pro7WithSep21Claim[3]]
                : Providers === 'Provider7' && Month === 'Oct-21' && session === 'RX' ? [pro7WithOct21Claim[3]]
                : Providers === 'Provider7' && Month === 'Nov-21' && session === 'RX' ? [pro7WithNov21Claim[3]]
                : Providers === 'Provider7' && Month === 'Dec-21' && session === 'RX' ? [pro7WithDec21Claim[3]]
                : Providers === 'Provider7' && Month === 'Jan-22' && session === 'RX' ? [pro7WithJan22Claim[3]]
                : Providers === 'Provider7' && Month === 'Feb-22' && session === 'RX' ? [pro7WithFeb22Claim[3]]
                : Providers === 'Provider7' && Month === 'Mar-22' && session === 'RX' ? [pro7WithMar22Claim[3]]
                : Providers === 'Provider7' && Month === 'Apr-22' && session === 'RX' ? [pro7WithApr22Claim[3]]
                : Providers === 'Provider7' && Month === 'May-22' && session === 'RX' ? [pro7WithMay22Claim[3]]
                : Providers === 'Provider7' && Month === 'Jun-22' && session === 'RX' ? [pro7WithJun22Claim[3]]
                : Providers === 'Provider7' && Month === 'Jul-22' && session === 'RX' ? [pro7WithJul22Claim[3]]
                : Providers === 'Provider7' && Month === 'Aug-22' && session === 'RX' ? [pro7WithAug22Claim[3]]
                : Providers === 'Provider7' && Month === 'Sep-22' && session === 'RX' ? [pro7WithSep22Claim[3]]
                : Providers === 'Provider7' && Month === 'Oct-22' && session === 'RX' ? [pro7WithOct22Claim[3]]
                : Providers === 'Provider7' && Month === 'Nov-22' && session === 'RX' ? [pro7WithNov22Claim[3]]
                : Providers === 'Provider7' && Month === 'Dec-22' && session === 'RX' ? [pro7WithDec22Claim[3]]
                : Providers === 'Provider7' && Month === 'Jan-23' && session === 'RX' ? [pro7WithJan23Claim[3]]
                : Providers === 'Provider7' && Month === 'Feb-23' && session === 'RX' ? [pro7WithFeb23Claim[3]]
                : Providers === 'Provider7' && Month === 'Mar-23' && session === 'RX' ? [pro7WithMar23Claim[3]]
                : Providers === 'Provider7' && Month === 'Apr-23' && session === 'RX' ? [pro7WithApr23Claim[3]]
                : Providers === 'Provider7' && Month === 'May-23' && session === 'RX' ? [pro7WithMay23Claim[3]]
                : Providers === 'Provider7' && Month === 'Jun-23' && session === 'RX' ? [pro7WithJun23Claim[3]]
                : Providers === 'Provider7' && Month === 'Jul-23' && session === 'RX' ? [pro7WithJul23Claim[3]]
                : Providers === 'Provider7' && Month === 'Aug-23' && session === 'RX' ? [pro7WithAug23Claim[3]]
                : Providers === 'Provider7' && Month === 'Sep-23' && session === 'RX' ? [pro7WithSep23Claim[3]]
                : Providers === 'Provider7' && Month === 'Oct-23' && session === 'RX' ? [pro7WithOct23Claim[3]]
                : Providers === 'Provider7' && Month === 'Nov-23' && session === 'RX' ? [pro7WithNov23Claim[3]]
                : ''
            },{
                name: 'Per-Visit',
                data:Providers === 'All Providers' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21PerVisit[3]]
                : Providers === 'All Providers' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21PerVisit[3]]
                : Providers === 'All Providers' && Month === 'Mar-21' && session === 'RX' ? [allproWithMar21PerVisit[3]]
                : Providers === 'All Providers' && Month === 'Apr-21' && session === 'RX' ? [allproWithApr21PerVisit[3]]
                : Providers === 'All Providers' && Month === 'May-21' && session === 'RX' ? [allproWithMay21PerVisit[3]]
                : Providers === 'All Providers' && Month === 'Jun-21' && session === 'RX' ? [allproWithJun21PerVisit[3]]
                : Providers === 'All Providers' && Month === 'Jul-21' && session === 'RX' ? [allproWithJul21PerVisit[3]]
                : Providers === 'All Providers' && Month === 'Aug-21' && session === 'RX' ? [allproWithAug21PerVisit[3]]
                : Providers === 'All Providers' && Month === 'Sep-21' && session === 'RX' ? [allproWithSep21PerVisit[3]]
                : Providers === 'All Providers' && Month === 'Oct-21' && session === 'RX' ? [allproWithOct21PerVisit[3]]
                : Providers === 'All Providers' && Month === 'Nov-21' && session === 'RX' ? [allproWithNov21PerVisit[3]]
                : Providers === 'All Providers' && Month === 'Dec-21' && session === 'RX' ? [allproWithDec21PerVisit[3]]
                : Providers === 'All Providers' && Month === 'Jan-22' && session === 'RX' ? [allproWithJan22PerVisit[3]]
                : Providers === 'All Providers' && Month === 'Feb-22' && session === 'RX' ? [allproWithFeb22PerVisit[3]]
                : Providers === 'All Providers' && Month === 'Mar-22' && session === 'RX' ? [allproWithMar22PerVisit[3]]
                : Providers === 'All Providers' && Month === 'Apr-22' && session === 'RX' ? [allproWithApr22PerVisit[3]]
                : Providers === 'All Providers' && Month === 'May-22' && session === 'RX' ? [allproWithMay22PerVisit[3]]
                : Providers === 'All Providers' && Month === 'Jun-22' && session === 'RX' ? [allproWithJun22PerVisit[3]]
                : Providers === 'All Providers' && Month === 'Jul-22' && session === 'RX' ? [allproWithJul22PerVisit[3]]
                : Providers === 'All Providers' && Month === 'Aug-22' && session === 'RX' ? [allproWithAug22PerVisit[3]]
                : Providers === 'All Providers' && Month === 'Sep-22' && session === 'RX' ? [allproWithSep22PerVisit[3]]
                : Providers === 'All Providers' && Month === 'Oct-22' && session === 'RX' ? [allproWithOct22PerVisit[3]]
                : Providers === 'All Providers' && Month === 'Nov-22' && session === 'RX' ? [allproWithNov22PerVisit[3]]
                : Providers === 'All Providers' && Month === 'Dec-22' && session === 'RX' ? [allproWithDec22PerVisit[3]]
                : Providers === 'All Providers' && Month === 'Jan-23' && session === 'RX' ? [allproWithJan23PerVisit[3]]
                : Providers === 'All Providers' && Month === 'Feb-23' && session === 'RX' ? [allproWithFeb23PerVisit[3]]
                : Providers === 'All Providers' && Month === 'Mar-23' && session === 'RX' ? [allproWithMar23PerVisit[3]]
                : Providers === 'All Providers' && Month === 'Apr-23' && session === 'RX' ? [allproWithApr23PerVisit[3]]
                : Providers === 'All Providers' && Month === 'May-23' && session === 'RX' ? [allproWithMay23PerVisit[3]]
                : Providers === 'All Providers' && Month === 'Jun-23' && session === 'RX' ? [allproWithJun23PerVisit[3]]
                : Providers === 'All Providers' && Month === 'Jul-23' && session === 'RX' ? [allproWithJul23PerVisit[3]]
                : Providers === 'All Providers' && Month === 'Aug-23' && session === 'RX' ? [allproWithAug23PerVisit[3]]
                : Providers === 'All Providers' && Month === 'Sep-23' && session === 'RX' ? [allproWithSep23PerVisit[3]]
                : Providers === 'All Providers' && Month === 'Oct-23' && session === 'RX' ? [allproWithOct23PerVisit[3]]
                : Providers === 'All Providers' && Month === 'Nov-23' && session === 'RX' ? [allproWithNov23PerVisit[3]]
                : Providers === 'Provider1' && Month === 'Jan-21' && session === 'RX' ? [pro1WithJan21PerVisit[3]]
                : Providers === 'Provider1' && Month === 'Feb-21' && session === 'RX' ? [pro1WithFeb21PerVisit[3]]
                : Providers === 'Provider1' && Month === 'Mar-21' && session === 'RX' ? [pro1WithMar21PerVisit[3]]
                : Providers === 'Provider1' && Month === 'Apr-21' && session === 'RX' ? [pro1WithApr21PerVisit[3]]
                : Providers === 'Provider1' && Month === 'May-21' && session === 'RX' ? [pro1WithMay21PerVisit[3]]
                : Providers === 'Provider1' && Month === 'Jun-21' && session === 'RX' ? [pro1WithJun21PerVisit[3]]
                : Providers === 'Provider1' && Month === 'Jul-21' && session === 'RX' ? [pro1WithJul21PerVisit[3]]
                : Providers === 'Provider1' && Month === 'Aug-21' && session === 'RX' ? [pro1WithAug21PerVisit[3]]
                : Providers === 'Provider1' && Month === 'Sep-21' && session === 'RX' ? [pro1WithSep21PerVisit[3]]
                : Providers === 'Provider1' && Month === 'Oct-21' && session === 'RX' ? [pro1WithOct21PerVisit[3]]
                : Providers === 'Provider1' && Month === 'Nov-21' && session === 'RX' ? [pro1WithNov21PerVisit[3]]
                : Providers === 'Provider1' && Month === 'Dec-21' && session === 'RX' ? [pro1WithDec21PerVisit[3]]
                : Providers === 'Provider1' && Month === 'Jan-22' && session === 'RX' ? [pro1WithJan22PerVisit[3]]
                : Providers === 'Provider1' && Month === 'Feb-22' && session === 'RX' ? [pro1WithFeb22PerVisit[3]]
                : Providers === 'Provider1' && Month === 'Mar-22' && session === 'RX' ? [pro1WithMar22PerVisit[3]]
                : Providers === 'Provider1' && Month === 'Apr-22' && session === 'RX' ? [pro1WithApr22PerVisit[3]]
                : Providers === 'Provider1' && Month === 'May-22' && session === 'RX' ? [pro1WithMay22PerVisit[3]]
                : Providers === 'Provider1' && Month === 'Jun-22' && session === 'RX' ? [pro1WithJun22PerVisit[3]]
                : Providers === 'Provider1' && Month === 'Jul-22' && session === 'RX' ? [pro1WithJul22PerVisit[3]]
                : Providers === 'Provider1' && Month === 'Aug-22' && session === 'RX' ? [pro1WithAug22PerVisit[3]]
                : Providers === 'Provider1' && Month === 'Sep-22' && session === 'RX' ? [pro1WithSep22PerVisit[3]]
                : Providers === 'Provider1' && Month === 'Oct-22' && session === 'RX' ? [pro1WithOct22PerVisit[3]]
                : Providers === 'Provider1' && Month === 'Nov-22' && session === 'RX' ? [pro1WithNov22PerVisit[3]]
                : Providers === 'Provider1' && Month === 'Dec-22' && session === 'RX' ? [pro1WithDec22PerVisit[3]]
                : Providers === 'Provider1' && Month === 'Jan-23' && session === 'RX' ? [pro1WithJan23PerVisit[3]]
                : Providers === 'Provider1' && Month === 'Feb-23' && session === 'RX' ? [pro1WithFeb23PerVisit[3]]
                : Providers === 'Provider1' && Month === 'Mar-23' && session === 'RX' ? [pro1WithMar23PerVisit[3]]
                : Providers === 'Provider1' && Month === 'Apr-23' && session === 'RX' ? [pro1WithApr23PerVisit[3]]
                : Providers === 'Provider1' && Month === 'May-23' && session === 'RX' ? [pro1WithMay23PerVisit[3]]
                : Providers === 'Provider1' && Month === 'Jun-23' && session === 'RX' ? [pro1WithJun23PerVisit[3]]
                : Providers === 'Provider1' && Month === 'Jul-23' && session === 'RX' ? [pro1WithJul23PerVisit[3]]
                : Providers === 'Provider1' && Month === 'Aug-23' && session === 'RX' ? [pro1WithAug23PerVisit[3]]
                : Providers === 'Provider1' && Month === 'Sep-23' && session === 'RX' ? [pro1WithSep23PerVisit[3]]
                : Providers === 'Provider1' && Month === 'Oct-23' && session === 'RX' ? [pro1WithOct23PerVisit[3]]
                : Providers === 'Provider1' && Month === 'Nov-23' && session === 'RX' ? [pro1WithNov23PerVisit[3]]
                : Providers === 'Provider2' && Month === 'Jan-21' && session === 'RX' ? [pro2WithJan21PerVisit[3]]
                : Providers === 'Provider2' && Month === 'Feb-21' && session === 'RX' ? [pro2WithFeb21PerVisit[3]]
                : Providers === 'Provider2' && Month === 'Mar-21' && session === 'RX' ? [pro2WithMar21PerVisit[3]]
                : Providers === 'Provider2' && Month === 'Apr-21' && session === 'RX' ? [pro2WithApr21PerVisit[3]]
                : Providers === 'Provider2' && Month === 'May-21' && session === 'RX' ? [pro2WithMay21PerVisit[3]]
                : Providers === 'Provider2' && Month === 'Jun-21' && session === 'RX' ? [pro2WithJun21PerVisit[3]]
                : Providers === 'Provider2' && Month === 'Jul-21' && session === 'RX' ? [pro2WithJul21PerVisit[3]]
                : Providers === 'Provider2' && Month === 'Aug-21' && session === 'RX' ? [pro2WithAug21PerVisit[3]]
                : Providers === 'Provider2' && Month === 'Sep-21' && session === 'RX' ? [pro2WithSep21PerVisit[3]]
                : Providers === 'Provider2' && Month === 'Oct-21' && session === 'RX' ? [pro2WithOct21PerVisit[3]]
                : Providers === 'Provider2' && Month === 'Nov-21' && session === 'RX' ? [pro2WithNov21PerVisit[3]]
                : Providers === 'Provider2' && Month === 'Dec-21' && session === 'RX' ? [pro2WithDec21PerVisit[3]]
                : Providers === 'Provider2' && Month === 'Jan-22' && session === 'RX' ? [pro2WithJan22PerVisit[3]]
                : Providers === 'Provider2' && Month === 'Feb-22' && session === 'RX' ? [pro2WithFeb22PerVisit[3]]
                : Providers === 'Provider2' && Month === 'Mar-22' && session === 'RX' ? [pro2WithMar22PerVisit[3]]
                : Providers === 'Provider2' && Month === 'Apr-22' && session === 'RX' ? [pro2WithApr22PerVisit[3]]
                : Providers === 'Provider2' && Month === 'May-22' && session === 'RX' ? [pro2WithMay22PerVisit[3]]
                : Providers === 'Provider2' && Month === 'Jun-22' && session === 'RX' ? [pro2WithJun22PerVisit[3]]
                : Providers === 'Provider2' && Month === 'Jul-22' && session === 'RX' ? [pro2WithJul22PerVisit[3]]
                : Providers === 'Provider2' && Month === 'Aug-22' && session === 'RX' ? [pro2WithAug22PerVisit[3]]
                : Providers === 'Provider2' && Month === 'Sep-22' && session === 'RX' ? [pro2WithSep22PerVisit[3]]
                : Providers === 'Provider2' && Month === 'Oct-22' && session === 'RX' ? [pro2WithOct22PerVisit[3]]
                : Providers === 'Provider2' && Month === 'Nov-22' && session === 'RX' ? [pro2WithNov22PerVisit[3]]
                : Providers === 'Provider2' && Month === 'Dec-22' && session === 'RX' ? [pro2WithDec22PerVisit[3]]
                : Providers === 'Provider2' && Month === 'Jan-23' && session === 'RX' ? [pro2WithJan23PerVisit[3]]
                : Providers === 'Provider2' && Month === 'Feb-23' && session === 'RX' ? [pro2WithFeb23PerVisit[3]]
                : Providers === 'Provider2' && Month === 'Mar-23' && session === 'RX' ? [pro2WithMar23PerVisit[3]]
                : Providers === 'Provider2' && Month === 'Apr-23' && session === 'RX' ? [pro2WithApr23PerVisit[3]]
                : Providers === 'Provider2' && Month === 'May-23' && session === 'RX' ? [pro2WithMay23PerVisit[3]]
                : Providers === 'Provider2' && Month === 'Jun-23' && session === 'RX' ? [pro2WithJun23PerVisit[3]]
                : Providers === 'Provider2' && Month === 'Jul-23' && session === 'RX' ? [pro2WithJul23PerVisit[3]]
                : Providers === 'Provider2' && Month === 'Aug-23' && session === 'RX' ? [pro2WithAug23PerVisit[3]]
                : Providers === 'Provider2' && Month === 'Sep-23' && session === 'RX' ? [pro2WithSep23PerVisit[3]]
                : Providers === 'Provider2' && Month === 'Oct-23' && session === 'RX' ? [pro2WithOct23PerVisit[3]]
                : Providers === 'Provider2' && Month === 'Nov-23' && session === 'RX' ? [pro2WithNov23PerVisit[3]]
                : Providers === 'Provider3' && Month === 'Jan-21' && session === 'RX' ? [pro3WithJan21PerVisit[3]]
                : Providers === 'Provider3' && Month === 'Feb-21' && session === 'RX' ? [pro3WithFeb21PerVisit[3]]
                : Providers === 'Provider3' && Month === 'Mar-21' && session === 'RX' ? [pro3WithMar21PerVisit[3]]
                : Providers === 'Provider3' && Month === 'Apr-21' && session === 'RX' ? [pro3WithApr21PerVisit[3]]
                : Providers === 'Provider3' && Month === 'May-21' && session === 'RX' ? [pro3WithMay21PerVisit[3]]
                : Providers === 'Provider3' && Month === 'Jun-21' && session === 'RX' ? [pro3WithJun21PerVisit[3]]
                : Providers === 'Provider3' && Month === 'Jul-21' && session === 'RX' ? [pro3WithJul21PerVisit[3]]
                : Providers === 'Provider3' && Month === 'Aug-21' && session === 'RX' ? [pro3WithAug21PerVisit[3]]
                : Providers === 'Provider3' && Month === 'Sep-21' && session === 'RX' ? [pro3WithSep21PerVisit[3]]
                : Providers === 'Provider3' && Month === 'Oct-21' && session === 'RX' ? [pro3WithOct21PerVisit[3]]
                : Providers === 'Provider3' && Month === 'Nov-21' && session === 'RX' ? [pro3WithNov21PerVisit[3]]
                : Providers === 'Provider3' && Month === 'Dec-21' && session === 'RX' ? [pro3WithDec21PerVisit[3]]
                : Providers === 'Provider3' && Month === 'Jan-22' && session === 'RX' ? [pro3WithJan22PerVisit[3]]
                : Providers === 'Provider3' && Month === 'Feb-22' && session === 'RX' ? [pro3WithFeb22PerVisit[3]]
                : Providers === 'Provider3' && Month === 'Mar-22' && session === 'RX' ? [pro3WithMar22PerVisit[3]]
                : Providers === 'Provider3' && Month === 'Apr-22' && session === 'RX' ? [pro3WithApr22PerVisit[3]]
                : Providers === 'Provider3' && Month === 'May-22' && session === 'RX' ? [pro3WithMay22PerVisit[3]]
                : Providers === 'Provider3' && Month === 'Jun-22' && session === 'RX' ? [pro3WithJun22PerVisit[3]]
                : Providers === 'Provider3' && Month === 'Jul-22' && session === 'RX' ? [pro3WithJul22PerVisit[3]]
                : Providers === 'Provider3' && Month === 'Aug-22' && session === 'RX' ? [pro3WithAug22PerVisit[3]]
                : Providers === 'Provider3' && Month === 'Sep-22' && session === 'RX' ? [pro3WithSep22PerVisit[3]]
                : Providers === 'Provider3' && Month === 'Oct-22' && session === 'RX' ? [pro3WithOct22PerVisit[3]]
                : Providers === 'Provider3' && Month === 'Nov-22' && session === 'RX' ? [pro3WithNov22PerVisit[3]]
                : Providers === 'Provider3' && Month === 'Dec-22' && session === 'RX' ? [pro3WithDec22PerVisit[3]]
                : Providers === 'Provider3' && Month === 'Jan-23' && session === 'RX' ? [pro3WithJan23PerVisit[3]]
                : Providers === 'Provider3' && Month === 'Feb-23' && session === 'RX' ? [pro3WithFeb23PerVisit[3]]
                : Providers === 'Provider3' && Month === 'Mar-23' && session === 'RX' ? [pro3WithMar23PerVisit[3]]
                : Providers === 'Provider3' && Month === 'Apr-23' && session === 'RX' ? [pro3WithApr23PerVisit[3]]
                : Providers === 'Provider3' && Month === 'May-23' && session === 'RX' ? [pro3WithMay23PerVisit[3]]
                : Providers === 'Provider3' && Month === 'Jun-23' && session === 'RX' ? [pro3WithJun23PerVisit[3]]
                : Providers === 'Provider3' && Month === 'Jul-23' && session === 'RX' ? [pro3WithJul23PerVisit[3]]
                : Providers === 'Provider3' && Month === 'Aug-23' && session === 'RX' ? [pro3WithAug23PerVisit[3]]
                : Providers === 'Provider3' && Month === 'Sep-23' && session === 'RX' ? [pro3WithSep23PerVisit[3]]
                : Providers === 'Provider3' && Month === 'Oct-23' && session === 'RX' ? [pro3WithOct23PerVisit[3]]
                : Providers === 'Provider3' && Month === 'Nov-23' && session === 'RX' ? [pro3WithNov23PerVisit[3]]
                : Providers === 'Provider4' && Month === 'Jan-21' && session === 'RX' ? [pro4WithJan21PerVisit[3]]
                : Providers === 'Provider4' && Month === 'Feb-21' && session === 'RX' ? [pro4WithFeb21PerVisit[3]]
                : Providers === 'Provider4' && Month === 'Mar-21' && session === 'RX' ? [pro4WithMar21PerVisit[3]]
                : Providers === 'Provider4' && Month === 'Apr-21' && session === 'RX' ? [pro4WithApr21PerVisit[3]]
                : Providers === 'Provider4' && Month === 'May-21' && session === 'RX' ? [pro4WithMay21PerVisit[3]]
                : Providers === 'Provider4' && Month === 'Jun-21' && session === 'RX' ? [pro4WithJun21PerVisit[3]]
                : Providers === 'Provider4' && Month === 'Jul-21' && session === 'RX' ? [pro4WithJul21PerVisit[3]]
                : Providers === 'Provider4' && Month === 'Aug-21' && session === 'RX' ? [pro4WithAug21PerVisit[3]]
                : Providers === 'Provider4' && Month === 'Sep-21' && session === 'RX' ? [pro4WithSep21PerVisit[3]]
                : Providers === 'Provider4' && Month === 'Oct-21' && session === 'RX' ? [pro4WithOct21PerVisit[3]]
                : Providers === 'Provider4' && Month === 'Nov-21' && session === 'RX' ? [pro4WithNov21PerVisit[3]]
                : Providers === 'Provider4' && Month === 'Dec-21' && session === 'RX' ? [pro4WithDec21PerVisit[3]]
                : Providers === 'Provider4' && Month === 'Jan-22' && session === 'RX' ? [pro4WithJan22PerVisit[3]]
                : Providers === 'Provider4' && Month === 'Feb-22' && session === 'RX' ? [pro4WithFeb22PerVisit[3]]
                : Providers === 'Provider4' && Month === 'Mar-22' && session === 'RX' ? [pro4WithMar22PerVisit[3]]
                : Providers === 'Provider4' && Month === 'Apr-22' && session === 'RX' ? [pro4WithApr22PerVisit[3]]
                : Providers === 'Provider4' && Month === 'May-22' && session === 'RX' ? [pro4WithMay22PerVisit[3]]
                : Providers === 'Provider4' && Month === 'Jun-22' && session === 'RX' ? [pro4WithJun22PerVisit[3]]
                : Providers === 'Provider4' && Month === 'Jul-22' && session === 'RX' ? [pro4WithJul22PerVisit[3]]
                : Providers === 'Provider4' && Month === 'Aug-22' && session === 'RX' ? [pro4WithAug22PerVisit[3]]
                : Providers === 'Provider4' && Month === 'Sep-22' && session === 'RX' ? [pro4WithSep22PerVisit[3]]
                : Providers === 'Provider4' && Month === 'Oct-22' && session === 'RX' ? [pro4WithOct22PerVisit[3]]
                : Providers === 'Provider4' && Month === 'Nov-22' && session === 'RX' ? [pro4WithNov22PerVisit[3]]
                : Providers === 'Provider4' && Month === 'Dec-22' && session === 'RX' ? [pro4WithDec22PerVisit[3]]
                : Providers === 'Provider4' && Month === 'Jan-23' && session === 'RX' ? [pro4WithJan23PerVisit[3]]
                : Providers === 'Provider4' && Month === 'Feb-23' && session === 'RX' ? [pro4WithFeb23PerVisit[3]]
                : Providers === 'Provider4' && Month === 'Mar-23' && session === 'RX' ? [pro4WithMar23PerVisit[3]]
                : Providers === 'Provider4' && Month === 'Apr-23' && session === 'RX' ? [pro4WithApr23PerVisit[3]]
                : Providers === 'Provider4' && Month === 'May-23' && session === 'RX' ? [pro4WithMay23PerVisit[3]]
                : Providers === 'Provider4' && Month === 'Jun-23' && session === 'RX' ? [pro4WithJun23PerVisit[3]]
                : Providers === 'Provider4' && Month === 'Jul-23' && session === 'RX' ? [pro4WithJul23PerVisit[3]]
                : Providers === 'Provider4' && Month === 'Aug-23' && session === 'RX' ? [pro4WithAug23PerVisit[3]]
                : Providers === 'Provider4' && Month === 'Sep-23' && session === 'RX' ? [pro4WithSep23PerVisit[3]]
                : Providers === 'Provider4' && Month === 'Oct-23' && session === 'RX' ? [pro4WithOct23PerVisit[3]]
                : Providers === 'Provider4' && Month === 'Nov-23' && session === 'RX' ? [pro4WithNov23PerVisit[3]]
                : Providers === 'Provider5' && Month === 'Jan-21' && session === 'RX' ? [pro5WithJan21PerVisit[3]]
                : Providers === 'Provider5' && Month === 'Feb-21' && session === 'RX' ? [pro5WithFeb21PerVisit[3]]
                : Providers === 'Provider5' && Month === 'Mar-21' && session === 'RX' ? [pro5WithMar21PerVisit[3]]
                : Providers === 'Provider5' && Month === 'Apr-21' && session === 'RX' ? [pro5WithApr21PerVisit[3]]
                : Providers === 'Provider5' && Month === 'May-21' && session === 'RX' ? [pro5WithMay21PerVisit[3]]
                : Providers === 'Provider5' && Month === 'Jun-21' && session === 'RX' ? [pro5WithJun21PerVisit[3]]
                : Providers === 'Provider5' && Month === 'Jul-21' && session === 'RX' ? [pro5WithJul21PerVisit[3]]
                : Providers === 'Provider5' && Month === 'Aug-21' && session === 'RX' ? [pro5WithAug21PerVisit[3]]
                : Providers === 'Provider5' && Month === 'Sep-21' && session === 'RX' ? [pro5WithSep21PerVisit[3]]
                : Providers === 'Provider5' && Month === 'Oct-21' && session === 'RX' ? [pro5WithOct21PerVisit[3]]
                : Providers === 'Provider5' && Month === 'Nov-21' && session === 'RX' ? [pro5WithNov21PerVisit[3]]
                : Providers === 'Provider5' && Month === 'Dec-21' && session === 'RX' ? [pro5WithDec21PerVisit[3]]
                : Providers === 'Provider5' && Month === 'Jan-22' && session === 'RX' ? [pro5WithJan22PerVisit[3]]
                : Providers === 'Provider5' && Month === 'Feb-22' && session === 'RX' ? [pro5WithFeb22PerVisit[3]]
                : Providers === 'Provider5' && Month === 'Mar-22' && session === 'RX' ? [pro5WithMar22PerVisit[3]]
                : Providers === 'Provider5' && Month === 'Apr-22' && session === 'RX' ? [pro5WithApr22PerVisit[3]]
                : Providers === 'Provider5' && Month === 'May-22' && session === 'RX' ? [pro5WithMay22PerVisit[3]]
                : Providers === 'Provider5' && Month === 'Jun-22' && session === 'RX' ? [pro5WithJun22PerVisit[3]]
                : Providers === 'Provider5' && Month === 'Jul-22' && session === 'RX' ? [pro5WithJul22PerVisit[3]]
                : Providers === 'Provider5' && Month === 'Aug-22' && session === 'RX' ? [pro5WithAug22PerVisit[3]]
                : Providers === 'Provider5' && Month === 'Sep-22' && session === 'RX' ? [pro5WithSep22PerVisit[3]]
                : Providers === 'Provider5' && Month === 'Oct-22' && session === 'RX' ? [pro5WithOct22PerVisit[3]]
                : Providers === 'Provider5' && Month === 'Nov-22' && session === 'RX' ? [pro5WithNov22PerVisit[3]]
                : Providers === 'Provider5' && Month === 'Dec-22' && session === 'RX' ? [pro5WithDec22PerVisit[3]]
                : Providers === 'Provider5' && Month === 'Jan-23' && session === 'RX' ? [pro5WithJan23PerVisit[3]]
                : Providers === 'Provider5' && Month === 'Feb-23' && session === 'RX' ? [pro5WithFeb23PerVisit[3]]
                : Providers === 'Provider5' && Month === 'Mar-23' && session === 'RX' ? [pro5WithMar23PerVisit[3]]
                : Providers === 'Provider5' && Month === 'Apr-23' && session === 'RX' ? [pro5WithApr23PerVisit[3]]
                : Providers === 'Provider5' && Month === 'May-23' && session === 'RX' ? [pro5WithMay23PerVisit[3]]
                : Providers === 'Provider5' && Month === 'Jun-23' && session === 'RX' ? [pro5WithJun23PerVisit[3]]
                : Providers === 'Provider5' && Month === 'Jul-23' && session === 'RX' ? [pro5WithJul23PerVisit[3]]
                : Providers === 'Provider5' && Month === 'Aug-23' && session === 'RX' ? [pro5WithAug23PerVisit[3]]
                : Providers === 'Provider5' && Month === 'Sep-23' && session === 'RX' ? [pro5WithSep23PerVisit[3]]
                : Providers === 'Provider5' && Month === 'Oct-23' && session === 'RX' ? [pro5WithOct23PerVisit[3]]
                : Providers === 'Provider5' && Month === 'Nov-23' && session === 'RX' ? [pro5WithNov23PerVisit[3]]
                : Providers === 'Provider6' && Month === 'Jan-21' && session === 'RX' ? [pro6WithJan21PerVisit[3]]
                : Providers === 'Provider6' && Month === 'Feb-21' && session === 'RX' ? [pro6WithFeb21PerVisit[3]]
                : Providers === 'Provider6' && Month === 'Mar-21' && session === 'RX' ? [pro6WithMar21PerVisit[3]]
                : Providers === 'Provider6' && Month === 'Apr-21' && session === 'RX' ? [pro6WithApr21PerVisit[3]]
                : Providers === 'Provider6' && Month === 'May-21' && session === 'RX' ? [pro6WithMay21PerVisit[3]]
                : Providers === 'Provider6' && Month === 'Jun-21' && session === 'RX' ? [pro6WithJun21PerVisit[3]]
                : Providers === 'Provider6' && Month === 'Jul-21' && session === 'RX' ? [pro6WithJul21PerVisit[3]]
                : Providers === 'Provider6' && Month === 'Aug-21' && session === 'RX' ? [pro6WithAug21PerVisit[3]]
                : Providers === 'Provider6' && Month === 'Sep-21' && session === 'RX' ? [pro6WithSep21PerVisit[3]]
                : Providers === 'Provider6' && Month === 'Oct-21' && session === 'RX' ? [pro6WithOct21PerVisit[3]]
                : Providers === 'Provider6' && Month === 'Nov-21' && session === 'RX' ? [pro6WithNov21PerVisit[3]]
                : Providers === 'Provider6' && Month === 'Dec-21' && session === 'RX' ? [pro6WithDec21PerVisit[3]]
                : Providers === 'Provider6' && Month === 'Jan-22' && session === 'RX' ? [pro6WithJan22PerVisit[3]]
                : Providers === 'Provider6' && Month === 'Feb-22' && session === 'RX' ? [pro6WithFeb22PerVisit[3]]
                : Providers === 'Provider6' && Month === 'Mar-22' && session === 'RX' ? [pro6WithMar22PerVisit[3]]
                : Providers === 'Provider6' && Month === 'Apr-22' && session === 'RX' ? [pro6WithApr22PerVisit[3]]
                : Providers === 'Provider6' && Month === 'May-22' && session === 'RX' ? [pro6WithMay22PerVisit[3]]
                : Providers === 'Provider6' && Month === 'Jun-22' && session === 'RX' ? [pro6WithJun22PerVisit[3]]
                : Providers === 'Provider6' && Month === 'Jul-22' && session === 'RX' ? [pro6WithJul22PerVisit[3]]
                : Providers === 'Provider6' && Month === 'Aug-22' && session === 'RX' ? [pro6WithAug22PerVisit[3]]
                : Providers === 'Provider6' && Month === 'Sep-22' && session === 'RX' ? [pro6WithSep22PerVisit[3]]
                : Providers === 'Provider6' && Month === 'Oct-22' && session === 'RX' ? [pro6WithOct22PerVisit[3]]
                : Providers === 'Provider6' && Month === 'Nov-22' && session === 'RX' ? [pro6WithNov22PerVisit[3]]
                : Providers === 'Provider6' && Month === 'Dec-22' && session === 'RX' ? [pro6WithDec22PerVisit[3]]
                : Providers === 'Provider6' && Month === 'Jan-23' && session === 'RX' ? [pro6WithJan23PerVisit[3]]
                : Providers === 'Provider6' && Month === 'Feb-23' && session === 'RX' ? [pro6WithFeb23PerVisit[3]]
                : Providers === 'Provider6' && Month === 'Mar-23' && session === 'RX' ? [pro6WithMar23PerVisit[3]]
                : Providers === 'Provider6' && Month === 'Apr-23' && session === 'RX' ? [pro6WithApr23PerVisit[3]]
                : Providers === 'Provider6' && Month === 'May-23' && session === 'RX' ? [pro6WithMay23PerVisit[3]]
                : Providers === 'Provider6' && Month === 'Jun-23' && session === 'RX' ? [pro6WithJun23PerVisit[3]]
                : Providers === 'Provider6' && Month === 'Jul-23' && session === 'RX' ? [pro6WithJul23PerVisit[3]]
                : Providers === 'Provider6' && Month === 'Aug-23' && session === 'RX' ? [pro6WithAug23PerVisit[3]]
                : Providers === 'Provider6' && Month === 'Sep-23' && session === 'RX' ? [pro6WithSep23PerVisit[3]]
                : Providers === 'Provider6' && Month === 'Oct-23' && session === 'RX' ? [pro6WithOct23PerVisit[3]]
                : Providers === 'Provider6' && Month === 'Nov-23' && session === 'RX' ? [pro6WithNov23PerVisit[3]]
                : Providers === 'Provider7' && Month === 'Jan-21' && session === 'RX' ? [pro7WithJan21PerVisit[3]]
                : Providers === 'Provider7' && Month === 'Feb-21' && session === 'RX' ? [pro7WithFeb21PerVisit[3]]
                : Providers === 'Provider7' && Month === 'Mar-21' && session === 'RX' ? [pro7WithMar21PerVisit[3]]
                : Providers === 'Provider7' && Month === 'Apr-21' && session === 'RX' ? [pro7WithApr21PerVisit[3]]
                : Providers === 'Provider7' && Month === 'May-21' && session === 'RX' ? [pro7WithMay21PerVisit[3]]
                : Providers === 'Provider7' && Month === 'Jun-21' && session === 'RX' ? [pro7WithJun21PerVisit[3]]
                : Providers === 'Provider7' && Month === 'Jul-21' && session === 'RX' ? [pro7WithJul21PerVisit[3]]
                : Providers === 'Provider7' && Month === 'Aug-21' && session === 'RX' ? [pro7WithAug21PerVisit[3]]
                : Providers === 'Provider7' && Month === 'Sep-21' && session === 'RX' ? [pro7WithSep21PerVisit[3]]
                : Providers === 'Provider7' && Month === 'Oct-21' && session === 'RX' ? [pro7WithOct21PerVisit[3]]
                : Providers === 'Provider7' && Month === 'Nov-21' && session === 'RX' ? [pro7WithNov21PerVisit[3]]
                : Providers === 'Provider7' && Month === 'Dec-21' && session === 'RX' ? [pro7WithDec21PerVisit[3]]
                : Providers === 'Provider7' && Month === 'Jan-22' && session === 'RX' ? [pro7WithJan22PerVisit[3]]
                : Providers === 'Provider7' && Month === 'Feb-22' && session === 'RX' ? [pro7WithFeb22PerVisit[3]]
                : Providers === 'Provider7' && Month === 'Mar-22' && session === 'RX' ? [pro7WithMar22PerVisit[3]]
                : Providers === 'Provider7' && Month === 'Apr-22' && session === 'RX' ? [pro7WithApr22PerVisit[3]]
                : Providers === 'Provider7' && Month === 'May-22' && session === 'RX' ? [pro7WithMay22PerVisit[3]]
                : Providers === 'Provider7' && Month === 'Jun-22' && session === 'RX' ? [pro7WithJun22PerVisit[3]]
                : Providers === 'Provider7' && Month === 'Jul-22' && session === 'RX' ? [pro7WithJul22PerVisit[3]]
                : Providers === 'Provider7' && Month === 'Aug-22' && session === 'RX' ? [pro7WithAug22PerVisit[3]]
                : Providers === 'Provider7' && Month === 'Sep-22' && session === 'RX' ? [pro7WithSep22PerVisit[3]]
                : Providers === 'Provider7' && Month === 'Oct-22' && session === 'RX' ? [pro7WithOct22PerVisit[3]]
                : Providers === 'Provider7' && Month === 'Nov-22' && session === 'RX' ? [pro7WithNov22PerVisit[3]]
                : Providers === 'Provider7' && Month === 'Dec-22' && session === 'RX' ? [pro7WithDec22PerVisit[3]]
                : Providers === 'Provider7' && Month === 'Jan-23' && session === 'RX' ? [pro7WithJan23PerVisit[3]]
                : Providers === 'Provider7' && Month === 'Feb-23' && session === 'RX' ? [pro7WithFeb23PerVisit[3]]
                : Providers === 'Provider7' && Month === 'Mar-23' && session === 'RX' ? [pro7WithMar23PerVisit[3]]
                : Providers === 'Provider7' && Month === 'Apr-23' && session === 'RX' ? [pro7WithApr23PerVisit[3]]
                : Providers === 'Provider7' && Month === 'May-23' && session === 'RX' ? [pro7WithMay23PerVisit[3]]
                : Providers === 'Provider7' && Month === 'Jun-23' && session === 'RX' ? [pro7WithJun23PerVisit[3]]
                : Providers === 'Provider7' && Month === 'Jul-23' && session === 'RX' ? [pro7WithJul23PerVisit[3]]
                : Providers === 'Provider7' && Month === 'Aug-23' && session === 'RX' ? [pro7WithAug23PerVisit[3]]
                : Providers === 'Provider7' && Month === 'Sep-23' && session === 'RX' ? [pro7WithSep23PerVisit[3]]
                : Providers === 'Provider7' && Month === 'Oct-23' && session === 'RX' ? [pro7WithOct23PerVisit[3]]
                : Providers === 'Provider7' && Month === 'Nov-23' && session === 'RX' ? [pro7WithNov23PerVisit[3]]
                : ''
            }
]

    let providersMonthFacility = [{
        name: 'Charges',
        data: Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21Charges[2]] 
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21Charges[2]] 
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [allproWithMar21Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [allproWithMar21Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [allproWithMar21Charges[2]] 
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [allproWithApr21Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [allproWithApr21Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [allproWithApr21Charges[2]] 
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [allproWithMay21Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [allproWithMay21Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [allproWithMay21Charges[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [allproWithJun21Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [allproWithJun21Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [allproWithJun21Charges[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [allproWithJul21Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [allproWithJul21Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [allproWithJul21Charges[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [allproWithAug21Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [allproWithAug21Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [allproWithAug21Charges[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [allproWithSep21Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [allproWithSep21Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [allproWithSep21Charges[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [allproWithOct21Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [allproWithOct21Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [allproWithOct21Charges[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [allproWithNov21Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [allproWithNov21Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [allproWithNov21Charges[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [allproWithDec21Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [allproWithDec21Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [allproWithDec21Charges[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [allproWithJan22Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [allproWithJan22Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [allproWithJan22Charges[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [allproWithFeb22Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [allproWithFeb22Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [allproWithFeb22Charges[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [allproWithMar22Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [allproWithMar22Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [allproWithMar22Charges[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [allproWithApr22Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [allproWithApr22Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [allproWithApr22Charges[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [allproWithMay22Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [allproWithMay22Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [allproWithMay22Charges[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [allproWithJun22Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [allproWithJun22Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [allproWithJun22Charges[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [allproWithJul22Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [allproWithJul22Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [allproWithJul22Charges[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [allproWithAug22Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [allproWithAug22Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [allproWithAug22Charges[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [allproWithSep22Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [allproWithSep22Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [allproWithSep22Charges[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [allproWithOct22Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [allproWithOct22Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [allproWithOct22Charges[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [allproWithNov22Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [allproWithNov22Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [allproWithNov22Charges[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [allproWithDec22Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [allproWithDec22Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [allproWithDec22Charges[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [allproWithJan23Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [allproWithJan23Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [allproWithJan23Charges[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [allproWithFeb23Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [allproWithFeb23Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [allproWithFeb23Charges[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [allproWithMar23Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [allproWithMar23Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [allproWithMar23Charges[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [allproWithApr23Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [allproWithApr23Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [allproWithApr23Charges[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [allproWithMay23Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [allproWithMay23Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [allproWithMay23Charges[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [allproWithJun23Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [allproWithJun23Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [allproWithJun23Charges[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [allproWithJul23Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [allproWithJul23Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [allproWithJul23Charges[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [allproWithAug23Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [allproWithAug23Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [allproWithAug23Charges[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [allproWithSep23Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [allproWithSep23Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [allproWithSep23Charges[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [allproWithOct23Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [allproWithOct23Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [allproWithOct23Charges[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [allproWithNov23Charges[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [allproWithNov23Charges[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [allproWithNov23Charges[2]]

            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro1WithJan21Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro1WithJan21Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro1WithJan21Charges[2]] 
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro1WithFeb21Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro1WithFeb21Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro1WithFeb21Charges[2]] 
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro1WithMar21Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro1WithMar21Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro1WithMar21Charges[2]] 
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro1WithApr21Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro1WithApr21Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro1WithApr21Charges[2]] 
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro1WithMay21Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro1WithMay21Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro1WithMay21Charges[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro1WithJun21Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro1WithJun21Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro1WithJun21Charges[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro1WithJul21Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro1WithJul21Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro1WithJul21Charges[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro1WithAug21Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro1WithAug21Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro1WithAug21Charges[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro1WithSep21Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro1WithSep21Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro1WithSep21Charges[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro1WithOct21Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro1WithOct21Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro1WithOct21Charges[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro1WithNov21Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro1WithNov21Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro1WithNov21Charges[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro1WithDec21Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro1WithDec21Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro1WithDec21Charges[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro1WithJan22Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro1WithJan22Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro1WithJan22Charges[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro1WithFeb22Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro1WithFeb22Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro1WithFeb22Charges[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro1WithMar22Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro1WithMar22Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro1WithMar22Charges[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro1WithApr22Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro1WithApr22Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro1WithApr22Charges[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro1WithMay22Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro1WithMay22Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro1WithMay22Charges[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro1WithJun22Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro1WithJun22Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro1WithJun22Charges[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro1WithJul22Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro1WithJul22Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro1WithJul22Charges[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro1WithAug22Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro1WithAug22Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro1WithAug22Charges[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro1WithSep22Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro1WithSep22Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro1WithSep22Charges[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro1WithOct22Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro1WithOct22Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro1WithOct22Charges[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro1WithNov22Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro1WithNov22Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro1WithNov22Charges[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro1WithDec22Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro1WithDec22Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro1WithDec22Charges[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro1WithJan23Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro1WithJan23Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro1WithJan23Charges[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro1WithFeb23Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro1WithFeb23Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro1WithFeb23Charges[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro1WithMar23Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro1WithMar23Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro1WithMar23Charges[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro1WithApr23Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro1WithApr23Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro1WithApr23Charges[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro1WithMay23Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro1WithMay23Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro1WithMay23Charges[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro1WithJun23Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro1WithJun23Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro1WithJun23Charges[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro1WithJul23Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro1WithJul23Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro1WithJul23Charges[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro1WithAug23Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro1WithAug23Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro1WithAug23Charges[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro1WithSep23Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro1WithSep23Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro1WithSep23Charges[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro1WithOct23Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro1WithOct23Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro1WithOct23Charges[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro1WithNov23Charges[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro1WithNov23Charges[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro1WithNov23Charges[2]]

            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro2WithJan21Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro2WithJan21Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro2WithJan21Charges[2]] 
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro2WithFeb21Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro2WithFeb21Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro2WithFeb21Charges[2]] 
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro2WithMar21Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro2WithMar21Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro2WithMar21Charges[2]] 
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro2WithApr21Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro2WithApr21Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro2WithApr21Charges[2]] 
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro2WithMay21Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro2WithMay21Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro2WithMay21Charges[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro2WithJun21Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro2WithJun21Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro2WithJun21Charges[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro2WithJul21Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro2WithJul21Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro2WithJul21Charges[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro2WithAug21Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro2WithAug21Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro2WithAug21Charges[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro2WithSep21Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro2WithSep21Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro2WithSep21Charges[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro2WithOct21Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro2WithOct21Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro2WithOct21Charges[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro2WithNov21Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro2WithNov21Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro2WithNov21Charges[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro2WithDec21Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro2WithDec21Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro2WithDec21Charges[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro2WithJan22Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro2WithJan22Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro2WithJan22Charges[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro2WithFeb22Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro2WithFeb22Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro2WithFeb22Charges[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro2WithMar22Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro2WithMar22Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro2WithMar22Charges[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro2WithApr22Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro2WithApr22Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro2WithApr22Charges[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro2WithMay22Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro2WithMay22Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro2WithMay22Charges[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro2WithJun22Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro2WithJun22Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro2WithJun22Charges[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro2WithJul22Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro2WithJul22Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro2WithJul22Charges[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro2WithAug22Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro2WithAug22Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro2WithAug22Charges[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro2WithSep22Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro2WithSep22Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro2WithSep22Charges[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro2WithOct22Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro2WithOct22Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro2WithOct22Charges[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro2WithNov22Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro2WithNov22Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro2WithNov22Charges[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro2WithDec22Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro2WithDec22Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro2WithDec22Charges[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro2WithJan23Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro2WithJan23Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro2WithJan23Charges[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro2WithFeb23Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro2WithFeb23Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro2WithFeb23Charges[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro2WithMar23Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro2WithMar23Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro2WithMar23Charges[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro2WithApr23Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro2WithApr23Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro2WithApr23Charges[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro2WithMay23Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro2WithMay23Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro2WithMay23Charges[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro2WithJun23Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro2WithJun23Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro2WithJun23Charges[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro2WithJul23Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro2WithJul23Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro2WithJul23Charges[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro2WithAug23Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro2WithAug23Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro2WithAug23Charges[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro2WithSep23Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro2WithSep23Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro2WithSep23Charges[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro2WithOct23Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro2WithOct23Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro2WithOct23Charges[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro2WithNov23Charges[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro2WithNov23Charges[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro2WithNov23Charges[2]]

            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro3WithJan21Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro3WithJan21Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro3WithJan21Charges[2]] 
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro3WithFeb21Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro3WithFeb21Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro3WithFeb21Charges[2]] 
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro3WithMar21Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro3WithMar21Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro3WithMar21Charges[2]] 
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro3WithApr21Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro3WithApr21Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro3WithApr21Charges[2]] 
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro3WithMay21Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro3WithMay21Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro3WithMay21Charges[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro3WithJun21Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro3WithJun21Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro3WithJun21Charges[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro3WithJul21Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro3WithJul21Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro3WithJul21Charges[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro3WithAug21Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro3WithAug21Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro3WithAug21Charges[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro3WithSep21Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro3WithSep21Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro3WithSep21Charges[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro3WithOct21Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro3WithOct21Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro3WithOct21Charges[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro3WithNov21Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro3WithNov21Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro3WithNov21Charges[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro3WithDec21Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro3WithDec21Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro3WithDec21Charges[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro3WithJan22Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro3WithJan22Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro3WithJan22Charges[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro3WithFeb22Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro3WithFeb22Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro3WithFeb22Charges[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro3WithMar22Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro3WithMar22Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro3WithMar22Charges[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro3WithApr22Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro3WithApr22Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro3WithApr22Charges[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro3WithMay22Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro3WithMay22Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro3WithMay22Charges[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro3WithJun22Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro3WithJun22Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro3WithJun22Charges[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro3WithJul22Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro3WithJul22Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro3WithJul22Charges[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro3WithAug22Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro3WithAug22Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro3WithAug22Charges[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro3WithSep22Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro3WithSep22Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro3WithSep22Charges[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro3WithOct22Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro3WithOct22Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro3WithOct22Charges[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro3WithNov22Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro3WithNov22Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro3WithNov22Charges[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro3WithDec22Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro3WithDec22Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro3WithDec22Charges[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro3WithJan23Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro3WithJan23Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro3WithJan23Charges[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro3WithFeb23Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro3WithFeb23Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro3WithFeb23Charges[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro3WithMar23Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro3WithMar23Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro3WithMar23Charges[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro3WithApr23Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro3WithApr23Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro3WithApr23Charges[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro3WithMay23Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro3WithMay23Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro3WithMay23Charges[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro3WithJun23Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro3WithJun23Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro3WithJun23Charges[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro3WithJul23Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro3WithJul23Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro3WithJul23Charges[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro3WithAug23Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro3WithAug23Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro3WithAug23Charges[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro3WithSep23Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro3WithSep23Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro3WithSep23Charges[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro3WithOct23Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro3WithOct23Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro3WithOct23Charges[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro3WithNov23Charges[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro3WithNov23Charges[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro3WithNov23Charges[2]]

            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro4WithJan21Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro4WithJan21Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro4WithJan21Charges[2]] 
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro4WithFeb21Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro4WithFeb21Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro4WithFeb21Charges[2]] 
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro4WithMar21Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro4WithMar21Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro4WithMar21Charges[2]] 
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro4WithApr21Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro4WithApr21Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro4WithApr21Charges[2]] 
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro4WithMay21Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro4WithMay21Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro4WithMay21Charges[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro4WithJun21Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro4WithJun21Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro4WithJun21Charges[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro4WithJul21Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro4WithJul21Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro4WithJul21Charges[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro4WithAug21Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro4WithAug21Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro4WithAug21Charges[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro4WithSep21Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro4WithSep21Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro4WithSep21Charges[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro4WithOct21Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro4WithOct21Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro4WithOct21Charges[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro4WithNov21Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro4WithNov21Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro4WithNov21Charges[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro4WithDec21Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro4WithDec21Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro4WithDec21Charges[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro4WithJan22Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro4WithJan22Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro4WithJan22Charges[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro4WithFeb22Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro4WithFeb22Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro4WithFeb22Charges[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro4WithMar22Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro4WithMar22Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro4WithMar22Charges[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro4WithApr22Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro4WithApr22Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro4WithApr22Charges[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro4WithMay22Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro4WithMay22Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro4WithMay22Charges[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro4WithJun22Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro4WithJun22Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro4WithJun22Charges[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro4WithJul22Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro4WithJul22Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro4WithJul22Charges[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro4WithAug22Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro4WithAug22Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro4WithAug22Charges[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro4WithSep22Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro4WithSep22Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro4WithSep22Charges[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro4WithOct22Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro4WithOct22Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro4WithOct22Charges[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro4WithNov22Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro4WithNov22Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro4WithNov22Charges[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro4WithDec22Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro4WithDec22Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro4WithDec22Charges[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro4WithJan23Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro4WithJan23Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro4WithJan23Charges[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro4WithFeb23Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro4WithFeb23Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro4WithFeb23Charges[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro4WithMar23Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro4WithMar23Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro4WithMar23Charges[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro4WithApr23Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro4WithApr23Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro4WithApr23Charges[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro4WithMay23Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro4WithMay23Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro4WithMay23Charges[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro4WithJun23Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro4WithJun23Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro4WithJun23Charges[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro4WithJul23Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro4WithJul23Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro4WithJul23Charges[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro4WithAug23Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro4WithAug23Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro4WithAug23Charges[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro4WithSep23Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro4WithSep23Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro4WithSep23Charges[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro4WithOct23Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro4WithOct23Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro4WithOct23Charges[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro4WithNov23Charges[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro4WithNov23Charges[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro4WithNov23Charges[2]]

            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro5WithJan21Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro5WithJan21Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro5WithJan21Charges[2]] 
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro5WithFeb21Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro5WithFeb21Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro5WithFeb21Charges[2]] 
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro5WithMar21Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro5WithMar21Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro5WithMar21Charges[2]] 
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro5WithApr21Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro5WithApr21Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro5WithApr21Charges[2]] 
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro5WithMay21Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro5WithMay21Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro5WithMay21Charges[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro5WithJun21Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro5WithJun21Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro5WithJun21Charges[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro5WithJul21Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro5WithJul21Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro5WithJul21Charges[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro5WithAug21Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro5WithAug21Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro5WithAug21Charges[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro5WithSep21Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro5WithSep21Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro5WithSep21Charges[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro5WithOct21Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro5WithOct21Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro5WithOct21Charges[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro5WithNov21Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro5WithNov21Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro5WithNov21Charges[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro5WithDec21Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro5WithDec21Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro5WithDec21Charges[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro5WithJan22Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro5WithJan22Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro5WithJan22Charges[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro5WithFeb22Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro5WithFeb22Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro5WithFeb22Charges[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro5WithMar22Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro5WithMar22Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro5WithMar22Charges[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro5WithApr22Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro5WithApr22Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro5WithApr22Charges[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro5WithMay22Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro5WithMay22Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro5WithMay22Charges[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro5WithJun22Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro5WithJun22Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro5WithJun22Charges[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro5WithJul22Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro5WithJul22Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro5WithJul22Charges[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro5WithAug22Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro5WithAug22Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro5WithAug22Charges[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro5WithSep22Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro5WithSep22Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro5WithSep22Charges[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro5WithOct22Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro5WithOct22Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro5WithOct22Charges[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro5WithNov22Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro5WithNov22Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro5WithNov22Charges[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro5WithDec22Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro5WithDec22Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro5WithDec22Charges[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro5WithJan23Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro5WithJan23Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro5WithJan23Charges[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro5WithFeb23Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro5WithFeb23Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro5WithFeb23Charges[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro5WithMar23Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro5WithMar23Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro5WithMar23Charges[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro5WithApr23Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro5WithApr23Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro5WithApr23Charges[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro5WithMay23Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro5WithMay23Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro5WithMay23Charges[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro5WithJun23Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro5WithJun23Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro5WithJun23Charges[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro5WithJul23Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro5WithJul23Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro5WithJul23Charges[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro5WithAug23Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro5WithAug23Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro5WithAug23Charges[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro5WithSep23Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro5WithSep23Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro5WithSep23Charges[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro5WithOct23Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro5WithOct23Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro5WithOct23Charges[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro5WithNov23Charges[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro5WithNov23Charges[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro5WithNov23Charges[2]]

            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro6WithJan21Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro6WithJan21Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro6WithJan21Charges[2]] 
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro6WithFeb21Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro6WithFeb21Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro6WithFeb21Charges[2]] 
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro6WithMar21Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro6WithMar21Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro6WithMar21Charges[2]] 
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro6WithApr21Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro6WithApr21Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro6WithApr21Charges[2]] 
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro6WithMay21Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro6WithMay21Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro6WithMay21Charges[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro6WithJun21Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro6WithJun21Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro6WithJun21Charges[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro6WithJul21Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro6WithJul21Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro6WithJul21Charges[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro6WithAug21Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro6WithAug21Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro6WithAug21Charges[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro6WithSep21Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro6WithSep21Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro6WithSep21Charges[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro6WithOct21Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro6WithOct21Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro6WithOct21Charges[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro6WithNov21Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro6WithNov21Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro6WithNov21Charges[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro6WithDec21Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro6WithDec21Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro6WithDec21Charges[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro6WithJan22Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro6WithJan22Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro6WithJan22Charges[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro6WithFeb22Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro6WithFeb22Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro6WithFeb22Charges[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro6WithMar22Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro6WithMar22Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro6WithMar22Charges[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro6WithApr22Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro6WithApr22Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro6WithApr22Charges[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro6WithMay22Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro6WithMay22Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro6WithMay22Charges[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro6WithJun22Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro6WithJun22Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro6WithJun22Charges[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro6WithJul22Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro6WithJul22Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro6WithJul22Charges[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro6WithAug22Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro6WithAug22Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro6WithAug22Charges[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro6WithSep22Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro6WithSep22Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro6WithSep22Charges[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro6WithOct22Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro6WithOct22Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro6WithOct22Charges[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro6WithNov22Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro6WithNov22Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro6WithNov22Charges[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro6WithDec22Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro6WithDec22Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro6WithDec22Charges[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro6WithJan23Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro6WithJan23Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro6WithJan23Charges[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro6WithFeb23Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro6WithFeb23Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro6WithFeb23Charges[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro6WithMar23Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro6WithMar23Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro6WithMar23Charges[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro6WithApr23Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro6WithApr23Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro6WithApr23Charges[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro6WithMay23Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro6WithMay23Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro6WithMay23Charges[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro6WithJun23Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro6WithJun23Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro6WithJun23Charges[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro6WithJul23Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro6WithJul23Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro6WithJul23Charges[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro6WithAug23Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro6WithAug23Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro6WithAug23Charges[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro6WithSep23Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro6WithSep23Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro6WithSep23Charges[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro6WithOct23Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro6WithOct23Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro6WithOct23Charges[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro6WithNov23Charges[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro6WithNov23Charges[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro6WithNov23Charges[2]]

            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro7WithJan21Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro7WithJan21Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro7WithJan21Charges[2]] 
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro7WithFeb21Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro7WithFeb21Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro7WithFeb21Charges[2]] 
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro7WithMar21Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro7WithMar21Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro7WithMar21Charges[2]] 
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro7WithApr21Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro7WithApr21Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro7WithApr21Charges[2]] 
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro7WithMay21Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro7WithMay21Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro7WithMay21Charges[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro7WithJun21Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro7WithJun21Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro7WithJun21Charges[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro7WithJul21Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro7WithJul21Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro7WithJul21Charges[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro7WithAug21Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro7WithAug21Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro7WithAug21Charges[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro7WithSep21Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro7WithSep21Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro7WithSep21Charges[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro7WithOct21Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro7WithOct21Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro7WithOct21Charges[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro7WithNov21Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro7WithNov21Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro7WithNov21Charges[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro7WithDec21Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro7WithDec21Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro7WithDec21Charges[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro7WithJan22Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro7WithJan22Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro7WithJan22Charges[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro7WithFeb22Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro7WithFeb22Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro7WithFeb22Charges[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro7WithMar22Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro7WithMar22Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro7WithMar22Charges[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro7WithApr22Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro7WithApr22Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro7WithApr22Charges[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro7WithMay22Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro7WithMay22Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro7WithMay22Charges[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro7WithJun22Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro7WithJun22Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro7WithJun22Charges[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro7WithJul22Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro7WithJul22Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro7WithJul22Charges[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro7WithAug22Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro7WithAug22Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro7WithAug22Charges[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro7WithSep22Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro7WithSep22Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro7WithSep22Charges[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro7WithOct22Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro7WithOct22Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro7WithOct22Charges[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro7WithNov22Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro7WithNov22Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro7WithNov22Charges[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro7WithDec22Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro7WithDec22Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro7WithDec22Charges[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro7WithJan23Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro7WithJan23Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro7WithJan23Charges[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro7WithFeb23Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro7WithFeb23Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro7WithFeb23Charges[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro7WithMar23Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro7WithMar23Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro7WithMar23Charges[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro7WithApr23Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro7WithApr23Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro7WithApr23Charges[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro7WithMay23Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro7WithMay23Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro7WithMay23Charges[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro7WithJun23Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro7WithJun23Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro7WithJun23Charges[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro7WithJul23Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro7WithJul23Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro7WithJul23Charges[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro7WithAug23Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro7WithAug23Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro7WithAug23Charges[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro7WithSep23Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro7WithSep23Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro7WithSep23Charges[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro7WithOct23Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro7WithOct23Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro7WithOct23Charges[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro7WithNov23Charges[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro7WithNov23Charges[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro7WithNov23Charges[2]]
            : ''
        },{
            name: 'Payments',
            data:Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21Pay[2]] 
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21Pay[2]] 
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [allproWithMar21Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [allproWithMar21Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [allproWithMar21Pay[2]] 
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [allproWithApr21Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [allproWithApr21Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [allproWithApr21Pay[2]] 
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [allproWithMay21Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [allproWithMay21Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [allproWithMay21Pay[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [allproWithJun21Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [allproWithJun21Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [allproWithJun21Pay[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [allproWithJul21Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [allproWithJul21Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [allproWithJul21Pay[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [allproWithAug21Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [allproWithAug21Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [allproWithAug21Pay[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [allproWithSep21Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [allproWithSep21Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [allproWithSep21Pay[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [allproWithOct21Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [allproWithOct21Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [allproWithOct21Pay[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [allproWithNov21Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [allproWithNov21Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [allproWithNov21Pay[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [allproWithDec21Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [allproWithDec21Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [allproWithDec21Pay[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [allproWithJan22Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [allproWithJan22Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [allproWithJan22Pay[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [allproWithFeb22Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [allproWithFeb22Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [allproWithFeb22Pay[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [allproWithMar22Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [allproWithMar22Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [allproWithMar22Pay[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [allproWithApr22Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [allproWithApr22Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [allproWithApr22Pay[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [allproWithMay22Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [allproWithMay22Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [allproWithMay22Pay[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [allproWithJun22Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [allproWithJun22Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [allproWithJun22Pay[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [allproWithJul22Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [allproWithJul22Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [allproWithJul22Pay[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [allproWithAug22Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [allproWithAug22Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [allproWithAug22Pay[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [allproWithSep22Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [allproWithSep22Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [allproWithSep22Pay[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [allproWithOct22Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [allproWithOct22Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [allproWithOct22Pay[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [allproWithNov22Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [allproWithNov22Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [allproWithNov22Pay[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [allproWithDec22Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [allproWithDec22Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [allproWithDec22Pay[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [allproWithJan23Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [allproWithJan23Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [allproWithJan23Pay[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [allproWithFeb23Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [allproWithFeb23Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [allproWithFeb23Pay[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [allproWithMar23Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [allproWithMar23Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [allproWithMar23Pay[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [allproWithApr23Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [allproWithApr23Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [allproWithApr23Pay[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [allproWithMay23Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [allproWithMay23Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [allproWithMay23Pay[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [allproWithJun23Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [allproWithJun23Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [allproWithJun23Pay[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [allproWithJul23Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [allproWithJul23Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [allproWithJul23Pay[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [allproWithAug23Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [allproWithAug23Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [allproWithAug23Pay[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [allproWithSep23Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [allproWithSep23Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [allproWithSep23Pay[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [allproWithOct23Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [allproWithOct23Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [allproWithOct23Pay[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [allproWithNov23Pay[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [allproWithNov23Pay[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [allproWithNov23Pay[2]]

            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro1WithJan21Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro1WithJan21Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro1WithJan21Pay[2]] 
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro1WithFeb21Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro1WithFeb21Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro1WithFeb21Pay[2]] 
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro1WithMar21Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro1WithMar21Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro1WithMar21Pay[2]] 
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro1WithApr21Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro1WithApr21Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro1WithApr21Pay[2]] 
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro1WithMay21Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro1WithMay21Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro1WithMay21Pay[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro1WithJun21Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro1WithJun21Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro1WithJun21Pay[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro1WithJul21Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro1WithJul21Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro1WithJul21Pay[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro1WithAug21Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro1WithAug21Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro1WithAug21Pay[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro1WithSep21Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro1WithSep21Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro1WithSep21Pay[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro1WithOct21Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro1WithOct21Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro1WithOct21Pay[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro1WithNov21Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro1WithNov21Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro1WithNov21Pay[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro1WithDec21Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro1WithDec21Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro1WithDec21Pay[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro1WithJan22Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro1WithJan22Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro1WithJan22Pay[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro1WithFeb22Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro1WithFeb22Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro1WithFeb22Pay[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro1WithMar22Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro1WithMar22Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro1WithMar22Pay[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro1WithApr22Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro1WithApr22Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro1WithApr22Pay[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro1WithMay22Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro1WithMay22Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro1WithMay22Pay[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro1WithJun22Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro1WithJun22Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro1WithJun22Pay[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro1WithJul22Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro1WithJul22Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro1WithJul22Pay[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro1WithAug22Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro1WithAug22Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro1WithAug22Pay[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro1WithSep22Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro1WithSep22Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro1WithSep22Pay[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro1WithOct22Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro1WithOct22Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro1WithOct22Pay[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro1WithNov22Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro1WithNov22Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro1WithNov22Pay[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro1WithDec22Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro1WithDec22Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro1WithDec22Pay[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro1WithJan23Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro1WithJan23Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro1WithJan23Pay[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro1WithFeb23Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro1WithFeb23Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro1WithFeb23Pay[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro1WithMar23Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro1WithMar23Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro1WithMar23Pay[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro1WithApr23Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro1WithApr23Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro1WithApr23Pay[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro1WithMay23Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro1WithMay23Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro1WithMay23Pay[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro1WithJun23Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro1WithJun23Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro1WithJun23Pay[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro1WithJul23Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro1WithJul23Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro1WithJul23Pay[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro1WithAug23Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro1WithAug23Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro1WithAug23Pay[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro1WithSep23Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro1WithSep23Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro1WithSep23Pay[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro1WithOct23Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro1WithOct23Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro1WithOct23Pay[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro1WithNov23Pay[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro1WithNov23Pay[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro1WithNov23Pay[2]]

            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro2WithJan21Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro2WithJan21Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro2WithJan21Pay[2]] 
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro2WithFeb21Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro2WithFeb21Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro2WithFeb21Pay[2]] 
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro2WithMar21Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro2WithMar21Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro2WithMar21Pay[2]] 
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro2WithApr21Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro2WithApr21Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro2WithApr21Pay[2]] 
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro2WithMay21Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro2WithMay21Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro2WithMay21Pay[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro2WithJun21Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro2WithJun21Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro2WithJun21Pay[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro2WithJul21Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro2WithJul21Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro2WithJul21Pay[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro2WithAug21Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro2WithAug21Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro2WithAug21Pay[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro2WithSep21Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro2WithSep21Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro2WithSep21Pay[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro2WithOct21Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro2WithOct21Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro2WithOct21Pay[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro2WithNov21Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro2WithNov21Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro2WithNov21Pay[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro2WithDec21Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro2WithDec21Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro2WithDec21Pay[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro2WithJan22Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro2WithJan22Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro2WithJan22Pay[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro2WithFeb22Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro2WithFeb22Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro2WithFeb22Pay[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro2WithMar22Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro2WithMar22Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro2WithMar22Pay[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro2WithApr22Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro2WithApr22Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro2WithApr22Pay[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro2WithMay22Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro2WithMay22Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro2WithMay22Pay[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro2WithJun22Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro2WithJun22Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro2WithJun22Pay[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro2WithJul22Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro2WithJul22Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro2WithJul22Pay[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro2WithAug22Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro2WithAug22Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro2WithAug22Pay[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro2WithSep22Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro2WithSep22Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro2WithSep22Pay[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro2WithOct22Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro2WithOct22Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro2WithOct22Pay[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro2WithNov22Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro2WithNov22Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro2WithNov22Pay[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro2WithDec22Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro2WithDec22Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro2WithDec22Pay[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro2WithJan23Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro2WithJan23Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro2WithJan23Pay[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro2WithFeb23Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro2WithFeb23Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro2WithFeb23Pay[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro2WithMar23Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro2WithMar23Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro2WithMar23Pay[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro2WithApr23Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro2WithApr23Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro2WithApr23Pay[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro2WithMay23Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro2WithMay23Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro2WithMay23Pay[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro2WithJun23Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro2WithJun23Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro2WithJun23Pay[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro2WithJul23Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro2WithJul23Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro2WithJul23Pay[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro2WithAug23Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro2WithAug23Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro2WithAug23Pay[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro2WithSep23Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro2WithSep23Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro2WithSep23Pay[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro2WithOct23Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro2WithOct23Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro2WithOct23Pay[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro2WithNov23Pay[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro2WithNov23Pay[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro2WithNov23Pay[2]]

            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro3WithJan21Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro3WithJan21Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro3WithJan21Pay[2]] 
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro3WithFeb21Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro3WithFeb21Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro3WithFeb21Pay[2]] 
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro3WithMar21Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro3WithMar21Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro3WithMar21Pay[2]] 
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro3WithApr21Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro3WithApr21Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro3WithApr21Pay[2]] 
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro3WithMay21Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro3WithMay21Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro3WithMay21Pay[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro3WithJun21Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro3WithJun21Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro3WithJun21Pay[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro3WithJul21Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro3WithJul21Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro3WithJul21Pay[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro3WithAug21Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro3WithAug21Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro3WithAug21Pay[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro3WithSep21Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro3WithSep21Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro3WithSep21Pay[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro3WithOct21Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro3WithOct21Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro3WithOct21Pay[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro3WithNov21Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro3WithNov21Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro3WithNov21Pay[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro3WithDec21Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro3WithDec21Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro3WithDec21Pay[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro3WithJan22Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro3WithJan22Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro3WithJan22Pay[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro3WithFeb22Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro3WithFeb22Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro3WithFeb22Pay[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro3WithMar22Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro3WithMar22Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro3WithMar22Pay[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro3WithApr22Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro3WithApr22Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro3WithApr22Pay[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro3WithMay22Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro3WithMay22Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro3WithMay22Pay[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro3WithJun22Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro3WithJun22Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro3WithJun22Pay[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro3WithJul22Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro3WithJul22Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro3WithJul22Pay[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro3WithAug22Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro3WithAug22Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro3WithAug22Pay[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro3WithSep22Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro3WithSep22Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro3WithSep22Pay[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro3WithOct22Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro3WithOct22Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro3WithOct22Pay[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro3WithNov22Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro3WithNov22Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro3WithNov22Pay[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro3WithDec22Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro3WithDec22Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro3WithDec22Pay[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro3WithJan23Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro3WithJan23Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro3WithJan23Pay[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro3WithFeb23Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro3WithFeb23Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro3WithFeb23Pay[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro3WithMar23Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro3WithMar23Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro3WithMar23Pay[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro3WithApr23Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro3WithApr23Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro3WithApr23Pay[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro3WithMay23Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro3WithMay23Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro3WithMay23Pay[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro3WithJun23Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro3WithJun23Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro3WithJun23Pay[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro3WithJul23Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro3WithJul23Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro3WithJul23Pay[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro3WithAug23Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro3WithAug23Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro3WithAug23Pay[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro3WithSep23Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro3WithSep23Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro3WithSep23Pay[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro3WithOct23Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro3WithOct23Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro3WithOct23Pay[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro3WithNov23Pay[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro3WithNov23Pay[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro3WithNov23Pay[2]]

            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro4WithJan21Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro4WithJan21Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro4WithJan21Pay[2]] 
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro4WithFeb21Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro4WithFeb21Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro4WithFeb21Pay[2]] 
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro4WithMar21Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro4WithMar21Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro4WithMar21Pay[2]] 
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro4WithApr21Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro4WithApr21Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro4WithApr21Pay[2]] 
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro4WithMay21Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro4WithMay21Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro4WithMay21Pay[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro4WithJun21Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro4WithJun21Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro4WithJun21Pay[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro4WithJul21Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro4WithJul21Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro4WithJul21Pay[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro4WithAug21Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro4WithAug21Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro4WithAug21Pay[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro4WithSep21Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro4WithSep21Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro4WithSep21Pay[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro4WithOct21Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro4WithOct21Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro4WithOct21Pay[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro4WithNov21Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro4WithNov21Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro4WithNov21Pay[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro4WithDec21Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro4WithDec21Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro4WithDec21Pay[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro4WithJan22Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro4WithJan22Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro4WithJan22Pay[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro4WithFeb22Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro4WithFeb22Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro4WithFeb22Pay[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro4WithMar22Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro4WithMar22Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro4WithMar22Pay[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro4WithApr22Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro4WithApr22Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro4WithApr22Pay[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro4WithMay22Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro4WithMay22Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro4WithMay22Pay[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro4WithJun22Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro4WithJun22Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro4WithJun22Pay[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro4WithJul22Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro4WithJul22Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro4WithJul22Pay[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro4WithAug22Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro4WithAug22Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro4WithAug22Pay[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro4WithSep22Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro4WithSep22Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro4WithSep22Pay[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro4WithOct22Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro4WithOct22Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro4WithOct22Pay[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro4WithNov22Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro4WithNov22Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro4WithNov22Pay[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro4WithDec22Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro4WithDec22Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro4WithDec22Pay[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro4WithJan23Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro4WithJan23Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro4WithJan23Pay[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro4WithFeb23Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro4WithFeb23Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro4WithFeb23Pay[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro4WithMar23Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro4WithMar23Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro4WithMar23Pay[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro4WithApr23Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro4WithApr23Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro4WithApr23Pay[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro4WithMay23Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro4WithMay23Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro4WithMay23Pay[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro4WithJun23Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro4WithJun23Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro4WithJun23Pay[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro4WithJul23Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro4WithJul23Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro4WithJul23Pay[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro4WithAug23Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro4WithAug23Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro4WithAug23Pay[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro4WithSep23Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro4WithSep23Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro4WithSep23Pay[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro4WithOct23Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro4WithOct23Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro4WithOct23Pay[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro4WithNov23Pay[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro4WithNov23Pay[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro4WithNov23Pay[2]]

            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro5WithJan21Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro5WithJan21Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro5WithJan21Pay[2]] 
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro5WithFeb21Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro5WithFeb21Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro5WithFeb21Pay[2]] 
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro5WithMar21Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro5WithMar21Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro5WithMar21Pay[2]] 
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro5WithApr21Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro5WithApr21Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro5WithApr21Pay[2]] 
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro5WithMay21Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro5WithMay21Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro5WithMay21Pay[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro5WithJun21Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro5WithJun21Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro5WithJun21Pay[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro5WithJul21Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro5WithJul21Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro5WithJul21Pay[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro5WithAug21Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro5WithAug21Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro5WithAug21Pay[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro5WithSep21Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro5WithSep21Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro5WithSep21Pay[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro5WithOct21Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro5WithOct21Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro5WithOct21Pay[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro5WithNov21Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro5WithNov21Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro5WithNov21Pay[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro5WithDec21Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro5WithDec21Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro5WithDec21Pay[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro5WithJan22Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro5WithJan22Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro5WithJan22Pay[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro5WithFeb22Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro5WithFeb22Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro5WithFeb22Pay[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro5WithMar22Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro5WithMar22Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro5WithMar22Pay[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro5WithApr22Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro5WithApr22Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro5WithApr22Pay[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro5WithMay22Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro5WithMay22Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro5WithMay22Pay[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro5WithJun22Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro5WithJun22Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro5WithJun22Pay[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro5WithJul22Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro5WithJul22Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro5WithJul22Pay[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro5WithAug22Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro5WithAug22Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro5WithAug22Pay[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro5WithSep22Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro5WithSep22Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro5WithSep22Pay[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro5WithOct22Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro5WithOct22Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro5WithOct22Pay[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro5WithNov22Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro5WithNov22Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro5WithNov22Pay[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro5WithDec22Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro5WithDec22Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro5WithDec22Pay[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro5WithJan23Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro5WithJan23Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro5WithJan23Pay[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro5WithFeb23Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro5WithFeb23Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro5WithFeb23Pay[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro5WithMar23Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro5WithMar23Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro5WithMar23Pay[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro5WithApr23Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro5WithApr23Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro5WithApr23Pay[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro5WithMay23Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro5WithMay23Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro5WithMay23Pay[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro5WithJun23Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro5WithJun23Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro5WithJun23Pay[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro5WithJul23Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro5WithJul23Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro5WithJul23Pay[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro5WithAug23Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro5WithAug23Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro5WithAug23Pay[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro5WithSep23Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro5WithSep23Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro5WithSep23Pay[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro5WithOct23Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro5WithOct23Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro5WithOct23Pay[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro5WithNov23Pay[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro5WithNov23Pay[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro5WithNov23Pay[2]]

            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro6WithJan21Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro6WithJan21Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro6WithJan21Pay[2]] 
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro6WithFeb21Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro6WithFeb21Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro6WithFeb21Pay[2]] 
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro6WithMar21Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro6WithMar21Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro6WithMar21Pay[2]] 
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro6WithApr21Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro6WithApr21Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro6WithApr21Pay[2]] 
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro6WithMay21Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro6WithMay21Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro6WithMay21Pay[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro6WithJun21Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro6WithJun21Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro6WithJun21Pay[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro6WithJul21Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro6WithJul21Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro6WithJul21Pay[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro6WithAug21Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro6WithAug21Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro6WithAug21Pay[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro6WithSep21Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro6WithSep21Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro6WithSep21Pay[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro6WithOct21Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro6WithOct21Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro6WithOct21Pay[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro6WithNov21Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro6WithNov21Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro6WithNov21Pay[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro6WithDec21Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro6WithDec21Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro6WithDec21Pay[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro6WithJan22Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro6WithJan22Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro6WithJan22Pay[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro6WithFeb22Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro6WithFeb22Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro6WithFeb22Pay[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro6WithMar22Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro6WithMar22Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro6WithMar22Pay[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro6WithApr22Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro6WithApr22Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro6WithApr22Pay[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro6WithMay22Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro6WithMay22Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro6WithMay22Pay[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro6WithJun22Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro6WithJun22Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro6WithJun22Pay[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro6WithJul22Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro6WithJul22Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro6WithJul22Pay[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro6WithAug22Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro6WithAug22Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro6WithAug22Pay[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro6WithSep22Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro6WithSep22Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro6WithSep22Pay[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro6WithOct22Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro6WithOct22Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro6WithOct22Pay[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro6WithNov22Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro6WithNov22Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro6WithNov22Pay[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro6WithDec22Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro6WithDec22Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro6WithDec22Pay[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro6WithJan23Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro6WithJan23Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro6WithJan23Pay[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro6WithFeb23Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro6WithFeb23Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro6WithFeb23Pay[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro6WithMar23Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro6WithMar23Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro6WithMar23Pay[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro6WithApr23Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro6WithApr23Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro6WithApr23Pay[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro6WithMay23Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro6WithMay23Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro6WithMay23Pay[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro6WithJun23Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro6WithJun23Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro6WithJun23Pay[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro6WithJul23Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro6WithJul23Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro6WithJul23Pay[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro6WithAug23Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro6WithAug23Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro6WithAug23Pay[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro6WithSep23Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro6WithSep23Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro6WithSep23Pay[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro6WithOct23Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro6WithOct23Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro6WithOct23Pay[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro6WithNov23Pay[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro6WithNov23Pay[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro6WithNov23Pay[2]]

            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro7WithJan21Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro7WithJan21Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro7WithJan21Pay[2]] 
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro7WithFeb21Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro7WithFeb21Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro7WithFeb21Pay[2]] 
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro7WithMar21Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro7WithMar21Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro7WithMar21Pay[2]] 
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro7WithApr21Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro7WithApr21Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro7WithApr21Pay[2]] 
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro7WithMay21Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro7WithMay21Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro7WithMay21Pay[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro7WithJun21Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro7WithJun21Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro7WithJun21Pay[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro7WithJul21Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro7WithJul21Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro7WithJul21Pay[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro7WithAug21Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro7WithAug21Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro7WithAug21Pay[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro7WithSep21Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro7WithSep21Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro7WithSep21Pay[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro7WithOct21Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro7WithOct21Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro7WithOct21Pay[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro7WithNov21Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro7WithNov21Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro7WithNov21Pay[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro7WithDec21Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro7WithDec21Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro7WithDec21Pay[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro7WithJan22Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro7WithJan22Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro7WithJan22Pay[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro7WithFeb22Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro7WithFeb22Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro7WithFeb22Pay[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro7WithMar22Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro7WithMar22Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro7WithMar22Pay[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro7WithApr22Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro7WithApr22Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro7WithApr22Pay[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro7WithMay22Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro7WithMay22Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro7WithMay22Pay[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro7WithJun22Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro7WithJun22Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro7WithJun22Pay[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro7WithJul22Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro7WithJul22Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro7WithJul22Pay[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro7WithAug22Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro7WithAug22Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro7WithAug22Pay[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro7WithSep22Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro7WithSep22Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro7WithSep22Pay[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro7WithOct22Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro7WithOct22Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro7WithOct22Pay[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro7WithNov22Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro7WithNov22Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro7WithNov22Pay[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro7WithDec22Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro7WithDec22Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro7WithDec22Pay[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro7WithJan23Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro7WithJan23Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro7WithJan23Pay[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro7WithFeb23Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro7WithFeb23Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro7WithFeb23Pay[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro7WithMar23Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro7WithMar23Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro7WithMar23Pay[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro7WithApr23Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro7WithApr23Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro7WithApr23Pay[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro7WithMay23Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro7WithMay23Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro7WithMay23Pay[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro7WithJun23Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro7WithJun23Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro7WithJun23Pay[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro7WithJul23Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro7WithJul23Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro7WithJul23Pay[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro7WithAug23Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro7WithAug23Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro7WithAug23Pay[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro7WithSep23Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro7WithSep23Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro7WithSep23Pay[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro7WithOct23Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro7WithOct23Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro7WithOct23Pay[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro7WithNov23Pay[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro7WithNov23Pay[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro7WithNov23Pay[2]]
            : ''
            },{
                name: 'Collectable',
                data: Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21Count[2]] 
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21Count[2]] 
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [allproWithMar21Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [allproWithMar21Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [allproWithMar21Count[2]] 
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [allproWithApr21Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [allproWithApr21Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [allproWithApr21Count[2]] 
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [allproWithMay21Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [allproWithMay21Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [allproWithMay21Count[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [allproWithJun21Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [allproWithJun21Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [allproWithJun21Count[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [allproWithJul21Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [allproWithJul21Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [allproWithJul21Count[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [allproWithAug21Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [allproWithAug21Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [allproWithAug21Count[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [allproWithSep21Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [allproWithSep21Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [allproWithSep21Count[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [allproWithOct21Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [allproWithOct21Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [allproWithOct21Count[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [allproWithNov21Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [allproWithNov21Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [allproWithNov21Count[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [allproWithDec21Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [allproWithDec21Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [allproWithDec21Count[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [allproWithJan22Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [allproWithJan22Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [allproWithJan22Count[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [allproWithFeb22Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [allproWithFeb22Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [allproWithFeb22Count[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [allproWithMar22Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [allproWithMar22Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [allproWithMar22Count[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [allproWithApr22Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [allproWithApr22Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [allproWithApr22Count[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [allproWithMay22Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [allproWithMay22Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [allproWithMay22Count[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [allproWithJun22Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [allproWithJun22Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [allproWithJun22Count[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [allproWithJul22Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [allproWithJul22Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [allproWithJul22Count[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [allproWithAug22Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [allproWithAug22Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [allproWithAug22Count[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [allproWithSep22Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [allproWithSep22Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [allproWithSep22Count[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [allproWithOct22Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [allproWithOct22Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [allproWithOct22Count[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [allproWithNov22Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [allproWithNov22Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [allproWithNov22Count[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [allproWithDec22Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [allproWithDec22Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [allproWithDec22Count[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [allproWithJan23Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [allproWithJan23Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [allproWithJan23Count[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [allproWithFeb23Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [allproWithFeb23Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [allproWithFeb23Count[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [allproWithMar23Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [allproWithMar23Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [allproWithMar23Count[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [allproWithApr23Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [allproWithApr23Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [allproWithApr23Count[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [allproWithMay23Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [allproWithMay23Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [allproWithMay23Count[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [allproWithJun23Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [allproWithJun23Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [allproWithJun23Count[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [allproWithJul23Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [allproWithJul23Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [allproWithJul23Count[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [allproWithAug23Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [allproWithAug23Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [allproWithAug23Count[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [allproWithSep23Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [allproWithSep23Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [allproWithSep23Count[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [allproWithOct23Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [allproWithOct23Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [allproWithOct23Count[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [allproWithNov23Count[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [allproWithNov23Count[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [allproWithNov23Count[2]]

            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro1WithJan21Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro1WithJan21Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro1WithJan21Count[2]] 
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro1WithFeb21Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro1WithFeb21Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro1WithFeb21Count[2]] 
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro1WithMar21Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro1WithMar21Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro1WithMar21Count[2]] 
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro1WithApr21Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro1WithApr21Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro1WithApr21Count[2]] 
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro1WithMay21Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro1WithMay21Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro1WithMay21Count[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro1WithJun21Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro1WithJun21Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro1WithJun21Count[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro1WithJul21Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro1WithJul21Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro1WithJul21Count[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro1WithAug21Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro1WithAug21Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro1WithAug21Count[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro1WithSep21Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro1WithSep21Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro1WithSep21Count[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro1WithOct21Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro1WithOct21Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro1WithOct21Count[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro1WithNov21Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro1WithNov21Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro1WithNov21Count[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro1WithDec21Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro1WithDec21Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro1WithDec21Count[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro1WithJan22Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro1WithJan22Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro1WithJan22Count[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro1WithFeb22Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro1WithFeb22Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro1WithFeb22Count[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro1WithMar22Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro1WithMar22Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro1WithMar22Count[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro1WithApr22Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro1WithApr22Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro1WithApr22Count[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro1WithMay22Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro1WithMay22Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro1WithMay22Count[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro1WithJun22Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro1WithJun22Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro1WithJun22Count[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro1WithJul22Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro1WithJul22Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro1WithJul22Count[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro1WithAug22Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro1WithAug22Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro1WithAug22Count[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro1WithSep22Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro1WithSep22Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro1WithSep22Count[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro1WithOct22Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro1WithOct22Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro1WithOct22Count[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro1WithNov22Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro1WithNov22Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro1WithNov22Count[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro1WithDec22Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro1WithDec22Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro1WithDec22Count[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro1WithJan23Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro1WithJan23Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro1WithJan23Count[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro1WithFeb23Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro1WithFeb23Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro1WithFeb23Count[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro1WithMar23Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro1WithMar23Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro1WithMar23Count[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro1WithApr23Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro1WithApr23Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro1WithApr23Count[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro1WithMay23Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro1WithMay23Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro1WithMay23Count[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro1WithJun23Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro1WithJun23Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro1WithJun23Count[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro1WithJul23Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro1WithJul23Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro1WithJul23Count[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro1WithAug23Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro1WithAug23Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro1WithAug23Count[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro1WithSep23Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro1WithSep23Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro1WithSep23Count[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro1WithOct23Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro1WithOct23Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro1WithOct23Count[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro1WithNov23Count[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro1WithNov23Count[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro1WithNov23Count[2]]

            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro2WithJan21Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro2WithJan21Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro2WithJan21Count[2]] 
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro2WithFeb21Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro2WithFeb21Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro2WithFeb21Count[2]] 
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro2WithMar21Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro2WithMar21Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro2WithMar21Count[2]] 
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro2WithApr21Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro2WithApr21Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro2WithApr21Count[2]] 
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro2WithMay21Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro2WithMay21Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro2WithMay21Count[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro2WithJun21Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro2WithJun21Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro2WithJun21Count[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro2WithJul21Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro2WithJul21Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro2WithJul21Count[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro2WithAug21Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro2WithAug21Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro2WithAug21Count[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro2WithSep21Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro2WithSep21Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro2WithSep21Count[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro2WithOct21Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro2WithOct21Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro2WithOct21Count[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro2WithNov21Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro2WithNov21Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro2WithNov21Count[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro2WithDec21Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro2WithDec21Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro2WithDec21Count[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro2WithJan22Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro2WithJan22Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro2WithJan22Count[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro2WithFeb22Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro2WithFeb22Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro2WithFeb22Count[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro2WithMar22Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro2WithMar22Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro2WithMar22Count[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro2WithApr22Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro2WithApr22Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro2WithApr22Count[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro2WithMay22Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro2WithMay22Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro2WithMay22Count[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro2WithJun22Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro2WithJun22Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro2WithJun22Count[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro2WithJul22Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro2WithJul22Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro2WithJul22Count[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro2WithAug22Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro2WithAug22Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro2WithAug22Count[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro2WithSep22Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro2WithSep22Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro2WithSep22Count[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro2WithOct22Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro2WithOct22Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro2WithOct22Count[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro2WithNov22Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro2WithNov22Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro2WithNov22Count[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro2WithDec22Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro2WithDec22Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro2WithDec22Count[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro2WithJan23Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro2WithJan23Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro2WithJan23Count[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro2WithFeb23Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro2WithFeb23Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro2WithFeb23Count[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro2WithMar23Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro2WithMar23Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro2WithMar23Count[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro2WithApr23Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro2WithApr23Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro2WithApr23Count[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro2WithMay23Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro2WithMay23Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro2WithMay23Count[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro2WithJun23Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro2WithJun23Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro2WithJun23Count[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro2WithJul23Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro2WithJul23Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro2WithJul23Count[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro2WithAug23Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro2WithAug23Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro2WithAug23Count[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro2WithSep23Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro2WithSep23Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro2WithSep23Count[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro2WithOct23Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro2WithOct23Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro2WithOct23Count[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro2WithNov23Count[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro2WithNov23Count[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro2WithNov23Count[2]]

            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro3WithJan21Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro3WithJan21Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro3WithJan21Count[2]] 
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro3WithFeb21Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro3WithFeb21Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro3WithFeb21Count[2]] 
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro3WithMar21Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro3WithMar21Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro3WithMar21Count[2]] 
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro3WithApr21Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro3WithApr21Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro3WithApr21Count[2]] 
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro3WithMay21Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro3WithMay21Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro3WithMay21Count[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro3WithJun21Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro3WithJun21Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro3WithJun21Count[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro3WithJul21Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro3WithJul21Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro3WithJul21Count[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro3WithAug21Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro3WithAug21Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro3WithAug21Count[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro3WithSep21Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro3WithSep21Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro3WithSep21Count[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro3WithOct21Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro3WithOct21Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro3WithOct21Count[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro3WithNov21Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro3WithNov21Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro3WithNov21Count[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro3WithDec21Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro3WithDec21Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro3WithDec21Count[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro3WithJan22Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro3WithJan22Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro3WithJan22Count[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro3WithFeb22Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro3WithFeb22Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro3WithFeb22Count[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro3WithMar22Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro3WithMar22Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro3WithMar22Count[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro3WithApr22Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro3WithApr22Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro3WithApr22Count[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro3WithMay22Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro3WithMay22Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro3WithMay22Count[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro3WithJun22Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro3WithJun22Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro3WithJun22Count[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro3WithJul22Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro3WithJul22Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro3WithJul22Count[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro3WithAug22Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro3WithAug22Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro3WithAug22Count[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro3WithSep22Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro3WithSep22Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro3WithSep22Count[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro3WithOct22Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro3WithOct22Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro3WithOct22Count[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro3WithNov22Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro3WithNov22Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro3WithNov22Count[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro3WithDec22Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro3WithDec22Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro3WithDec22Count[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro3WithJan23Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro3WithJan23Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro3WithJan23Count[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro3WithFeb23Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro3WithFeb23Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro3WithFeb23Count[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro3WithMar23Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro3WithMar23Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro3WithMar23Count[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro3WithApr23Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro3WithApr23Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro3WithApr23Count[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro3WithMay23Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro3WithMay23Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro3WithMay23Count[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro3WithJun23Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro3WithJun23Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro3WithJun23Count[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro3WithJul23Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro3WithJul23Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro3WithJul23Count[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro3WithAug23Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro3WithAug23Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro3WithAug23Count[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro3WithSep23Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro3WithSep23Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro3WithSep23Count[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro3WithOct23Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro3WithOct23Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro3WithOct23Count[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro3WithNov23Count[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro3WithNov23Count[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro3WithNov23Count[2]]

            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro4WithJan21Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro4WithJan21Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro4WithJan21Count[2]] 
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro4WithFeb21Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro4WithFeb21Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro4WithFeb21Count[2]] 
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro4WithMar21Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro4WithMar21Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro4WithMar21Count[2]] 
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro4WithApr21Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro4WithApr21Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro4WithApr21Count[2]] 
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro4WithMay21Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro4WithMay21Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro4WithMay21Count[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro4WithJun21Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro4WithJun21Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro4WithJun21Count[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro4WithJul21Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro4WithJul21Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro4WithJul21Count[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro4WithAug21Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro4WithAug21Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro4WithAug21Count[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro4WithSep21Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro4WithSep21Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro4WithSep21Count[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro4WithOct21Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro4WithOct21Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro4WithOct21Count[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro4WithNov21Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro4WithNov21Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro4WithNov21Count[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro4WithDec21Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro4WithDec21Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro4WithDec21Count[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro4WithJan22Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro4WithJan22Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro4WithJan22Count[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro4WithFeb22Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro4WithFeb22Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro4WithFeb22Count[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro4WithMar22Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro4WithMar22Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro4WithMar22Count[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro4WithApr22Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro4WithApr22Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro4WithApr22Count[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro4WithMay22Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro4WithMay22Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro4WithMay22Count[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro4WithJun22Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro4WithJun22Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro4WithJun22Count[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro4WithJul22Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro4WithJul22Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro4WithJul22Count[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro4WithAug22Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro4WithAug22Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro4WithAug22Count[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro4WithSep22Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro4WithSep22Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro4WithSep22Count[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro4WithOct22Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro4WithOct22Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro4WithOct22Count[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro4WithNov22Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro4WithNov22Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro4WithNov22Count[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro4WithDec22Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro4WithDec22Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro4WithDec22Count[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro4WithJan23Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro4WithJan23Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro4WithJan23Count[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro4WithFeb23Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro4WithFeb23Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro4WithFeb23Count[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro4WithMar23Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro4WithMar23Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro4WithMar23Count[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro4WithApr23Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro4WithApr23Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro4WithApr23Count[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro4WithMay23Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro4WithMay23Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro4WithMay23Count[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro4WithJun23Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro4WithJun23Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro4WithJun23Count[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro4WithJul23Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro4WithJul23Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro4WithJul23Count[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro4WithAug23Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro4WithAug23Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro4WithAug23Count[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro4WithSep23Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro4WithSep23Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro4WithSep23Count[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro4WithOct23Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro4WithOct23Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro4WithOct23Count[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro4WithNov23Count[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro4WithNov23Count[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro4WithNov23Count[2]]

            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro5WithJan21Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro5WithJan21Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro5WithJan21Count[2]] 
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro5WithFeb21Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro5WithFeb21Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro5WithFeb21Count[2]] 
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro5WithMar21Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro5WithMar21Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro5WithMar21Count[2]] 
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro5WithApr21Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro5WithApr21Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro5WithApr21Count[2]] 
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro5WithMay21Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro5WithMay21Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro5WithMay21Count[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro5WithJun21Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro5WithJun21Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro5WithJun21Count[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro5WithJul21Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro5WithJul21Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro5WithJul21Count[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro5WithAug21Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro5WithAug21Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro5WithAug21Count[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro5WithSep21Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro5WithSep21Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro5WithSep21Count[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro5WithOct21Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro5WithOct21Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro5WithOct21Count[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro5WithNov21Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro5WithNov21Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro5WithNov21Count[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro5WithDec21Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro5WithDec21Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro5WithDec21Count[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro5WithJan22Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro5WithJan22Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro5WithJan22Count[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro5WithFeb22Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro5WithFeb22Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro5WithFeb22Count[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro5WithMar22Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro5WithMar22Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro5WithMar22Count[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro5WithApr22Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro5WithApr22Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro5WithApr22Count[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro5WithMay22Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro5WithMay22Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro5WithMay22Count[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro5WithJun22Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro5WithJun22Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro5WithJun22Count[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro5WithJul22Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro5WithJul22Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro5WithJul22Count[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro5WithAug22Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro5WithAug22Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro5WithAug22Count[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro5WithSep22Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro5WithSep22Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro5WithSep22Count[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro5WithOct22Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro5WithOct22Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro5WithOct22Count[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro5WithNov22Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro5WithNov22Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro5WithNov22Count[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro5WithDec22Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro5WithDec22Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro5WithDec22Count[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro5WithJan23Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro5WithJan23Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro5WithJan23Count[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro5WithFeb23Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro5WithFeb23Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro5WithFeb23Count[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro5WithMar23Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro5WithMar23Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro5WithMar23Count[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro5WithApr23Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro5WithApr23Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro5WithApr23Count[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro5WithMay23Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro5WithMay23Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro5WithMay23Count[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro5WithJun23Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro5WithJun23Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro5WithJun23Count[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro5WithJul23Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro5WithJul23Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro5WithJul23Count[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro5WithAug23Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro5WithAug23Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro5WithAug23Count[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro5WithSep23Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro5WithSep23Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro5WithSep23Count[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro5WithOct23Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro5WithOct23Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro5WithOct23Count[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro5WithNov23Count[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro5WithNov23Count[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro5WithNov23Count[2]]

            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro6WithJan21Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro6WithJan21Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro6WithJan21Count[2]] 
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro6WithFeb21Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro6WithFeb21Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro6WithFeb21Count[2]] 
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro6WithMar21Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro6WithMar21Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro6WithMar21Count[2]] 
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro6WithApr21Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro6WithApr21Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro6WithApr21Count[2]] 
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro6WithMay21Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro6WithMay21Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro6WithMay21Count[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro6WithJun21Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro6WithJun21Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro6WithJun21Count[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro6WithJul21Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro6WithJul21Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro6WithJul21Count[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro6WithAug21Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro6WithAug21Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro6WithAug21Count[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro6WithSep21Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro6WithSep21Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro6WithSep21Count[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro6WithOct21Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro6WithOct21Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro6WithOct21Count[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro6WithNov21Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro6WithNov21Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro6WithNov21Count[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro6WithDec21Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro6WithDec21Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro6WithDec21Count[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro6WithJan22Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro6WithJan22Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro6WithJan22Count[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro6WithFeb22Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro6WithFeb22Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro6WithFeb22Count[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro6WithMar22Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro6WithMar22Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro6WithMar22Count[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro6WithApr22Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro6WithApr22Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro6WithApr22Count[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro6WithMay22Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro6WithMay22Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro6WithMay22Count[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro6WithJun22Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro6WithJun22Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro6WithJun22Count[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro6WithJul22Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro6WithJul22Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro6WithJul22Count[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro6WithAug22Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro6WithAug22Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro6WithAug22Count[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro6WithSep22Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro6WithSep22Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro6WithSep22Count[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro6WithOct22Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro6WithOct22Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro6WithOct22Count[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro6WithNov22Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro6WithNov22Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro6WithNov22Count[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro6WithDec22Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro6WithDec22Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro6WithDec22Count[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro6WithJan23Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro6WithJan23Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro6WithJan23Count[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro6WithFeb23Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro6WithFeb23Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro6WithFeb23Count[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro6WithMar23Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro6WithMar23Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro6WithMar23Count[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro6WithApr23Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro6WithApr23Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro6WithApr23Count[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro6WithMay23Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro6WithMay23Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro6WithMay23Count[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro6WithJun23Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro6WithJun23Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro6WithJun23Count[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro6WithJul23Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro6WithJul23Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro6WithJul23Count[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro6WithAug23Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro6WithAug23Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro6WithAug23Count[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro6WithSep23Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro6WithSep23Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro6WithSep23Count[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro6WithOct23Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro6WithOct23Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro6WithOct23Count[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro6WithNov23Count[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro6WithNov23Count[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro6WithNov23Count[2]]

            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro7WithJan21Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro7WithJan21Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro7WithJan21Count[2]] 
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro7WithFeb21Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro7WithFeb21Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro7WithFeb21Count[2]] 
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro7WithMar21Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro7WithMar21Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro7WithMar21Count[2]] 
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro7WithApr21Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro7WithApr21Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro7WithApr21Count[2]] 
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro7WithMay21Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro7WithMay21Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro7WithMay21Count[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro7WithJun21Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro7WithJun21Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro7WithJun21Count[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro7WithJul21Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro7WithJul21Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro7WithJul21Count[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro7WithAug21Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro7WithAug21Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro7WithAug21Count[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro7WithSep21Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro7WithSep21Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro7WithSep21Count[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro7WithOct21Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro7WithOct21Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro7WithOct21Count[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro7WithNov21Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro7WithNov21Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro7WithNov21Count[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro7WithDec21Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro7WithDec21Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro7WithDec21Count[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro7WithJan22Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro7WithJan22Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro7WithJan22Count[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro7WithFeb22Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro7WithFeb22Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro7WithFeb22Count[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro7WithMar22Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro7WithMar22Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro7WithMar22Count[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro7WithApr22Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro7WithApr22Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro7WithApr22Count[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro7WithMay22Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro7WithMay22Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro7WithMay22Count[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro7WithJun22Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro7WithJun22Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro7WithJun22Count[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro7WithJul22Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro7WithJul22Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro7WithJul22Count[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro7WithAug22Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro7WithAug22Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro7WithAug22Count[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro7WithSep22Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro7WithSep22Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro7WithSep22Count[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro7WithOct22Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro7WithOct22Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro7WithOct22Count[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro7WithNov22Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro7WithNov22Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro7WithNov22Count[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro7WithDec22Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro7WithDec22Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro7WithDec22Count[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro7WithJan23Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro7WithJan23Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro7WithJan23Count[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro7WithFeb23Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro7WithFeb23Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro7WithFeb23Count[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro7WithMar23Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro7WithMar23Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro7WithMar23Count[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro7WithApr23Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro7WithApr23Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro7WithApr23Count[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro7WithMay23Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro7WithMay23Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro7WithMay23Count[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro7WithJun23Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro7WithJun23Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro7WithJun23Count[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro7WithJul23Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro7WithJul23Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro7WithJul23Count[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro7WithAug23Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro7WithAug23Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro7WithAug23Count[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro7WithSep23Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro7WithSep23Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro7WithSep23Count[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro7WithOct23Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro7WithOct23Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro7WithOct23Count[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro7WithNov23Count[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro7WithNov23Count[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro7WithNov23Count[2]]
            : ''

            },{
                name: 'Non-Collectable',
                data: Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21NonCount[2]] 
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21NonCount[2]] 
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [allproWithMar21NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [allproWithMar21NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [allproWithMar21NonCount[2]] 
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [allproWithApr21NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [allproWithApr21NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [allproWithApr21NonCount[2]] 
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [allproWithMay21NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [allproWithMay21NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [allproWithMay21NonCount[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [allproWithJun21NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [allproWithJun21NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [allproWithJun21NonCount[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [allproWithJul21NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [allproWithJul21NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [allproWithJul21NonCount[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [allproWithAug21NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [allproWithAug21NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [allproWithAug21NonCount[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [allproWithSep21NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [allproWithSep21NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [allproWithSep21NonCount[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [allproWithOct21NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [allproWithOct21NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [allproWithOct21NonCount[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [allproWithNov21NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [allproWithNov21NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [allproWithNov21NonCount[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [allproWithDec21NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [allproWithDec21NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [allproWithDec21NonCount[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [allproWithJan22NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [allproWithJan22NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [allproWithJan22NonCount[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [allproWithFeb22NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [allproWithFeb22NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [allproWithFeb22NonCount[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [allproWithMar22NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [allproWithMar22NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [allproWithMar22NonCount[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [allproWithApr22NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [allproWithApr22NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [allproWithApr22NonCount[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [allproWithMay22NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [allproWithMay22NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [allproWithMay22NonCount[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [allproWithJun22NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [allproWithJun22NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [allproWithJun22NonCount[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [allproWithJul22NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [allproWithJul22NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [allproWithJul22NonCount[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [allproWithAug22NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [allproWithAug22NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [allproWithAug22NonCount[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [allproWithSep22NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [allproWithSep22NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [allproWithSep22NonCount[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [allproWithOct22NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [allproWithOct22NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [allproWithOct22NonCount[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [allproWithNov22NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [allproWithNov22NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [allproWithNov22NonCount[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [allproWithDec22NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [allproWithDec22NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [allproWithDec22NonCount[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [allproWithJan23NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [allproWithJan23NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [allproWithJan23NonCount[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [allproWithFeb23NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [allproWithFeb23NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [allproWithFeb23NonCount[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [allproWithMar23NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [allproWithMar23NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [allproWithMar23NonCount[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [allproWithApr23NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [allproWithApr23NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [allproWithApr23NonCount[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [allproWithMay23NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [allproWithMay23NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [allproWithMay23NonCount[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [allproWithJun23NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [allproWithJun23NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [allproWithJun23NonCount[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [allproWithJul23NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [allproWithJul23NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [allproWithJul23NonCount[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [allproWithAug23NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [allproWithAug23NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [allproWithAug23NonCount[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [allproWithSep23NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [allproWithSep23NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [allproWithSep23NonCount[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [allproWithOct23NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [allproWithOct23NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [allproWithOct23NonCount[2]]
            : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [allproWithNov23NonCount[0]]
            : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [allproWithNov23NonCount[1]]
            : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [allproWithNov23NonCount[2]]

            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro1WithJan21NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro1WithJan21NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro1WithJan21NonCount[2]] 
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro1WithFeb21NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro1WithFeb21NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro1WithFeb21NonCount[2]] 
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro1WithMar21NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro1WithMar21NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro1WithMar21NonCount[2]] 
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro1WithApr21NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro1WithApr21NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro1WithApr21NonCount[2]] 
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro1WithMay21NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro1WithMay21NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro1WithMay21NonCount[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro1WithJun21NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro1WithJun21NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro1WithJun21NonCount[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro1WithJul21NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro1WithJul21NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro1WithJul21NonCount[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro1WithAug21NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro1WithAug21NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro1WithAug21NonCount[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro1WithSep21NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro1WithSep21NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro1WithSep21NonCount[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro1WithOct21NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro1WithOct21NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro1WithOct21NonCount[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro1WithNov21NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro1WithNov21NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro1WithNov21NonCount[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro1WithDec21NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro1WithDec21NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro1WithDec21NonCount[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro1WithJan22NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro1WithJan22NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro1WithJan22NonCount[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro1WithFeb22NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro1WithFeb22NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro1WithFeb22NonCount[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro1WithMar22NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro1WithMar22NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro1WithMar22NonCount[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro1WithApr22NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro1WithApr22NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro1WithApr22NonCount[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro1WithMay22NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro1WithMay22NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro1WithMay22NonCount[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro1WithJun22NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro1WithJun22NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro1WithJun22NonCount[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro1WithJul22NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro1WithJul22NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro1WithJul22NonCount[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro1WithAug22NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro1WithAug22NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro1WithAug22NonCount[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro1WithSep22NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro1WithSep22NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro1WithSep22NonCount[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro1WithOct22NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro1WithOct22NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro1WithOct22NonCount[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro1WithNov22NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro1WithNov22NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro1WithNov22NonCount[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro1WithDec22NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro1WithDec22NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro1WithDec22NonCount[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro1WithJan23NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro1WithJan23NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro1WithJan23NonCount[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro1WithFeb23NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro1WithFeb23NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro1WithFeb23NonCount[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro1WithMar23NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro1WithMar23NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro1WithMar23NonCount[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro1WithApr23NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro1WithApr23NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro1WithApr23NonCount[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro1WithMay23NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro1WithMay23NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro1WithMay23NonCount[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro1WithJun23NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro1WithJun23NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro1WithJun23NonCount[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro1WithJul23NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro1WithJul23NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro1WithJul23NonCount[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro1WithAug23NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro1WithAug23NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro1WithAug23NonCount[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro1WithSep23NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro1WithSep23NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro1WithSep23NonCount[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro1WithOct23NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro1WithOct23NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro1WithOct23NonCount[2]]
            : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro1WithNov23NonCount[0]]
            : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro1WithNov23NonCount[1]]
            : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro1WithNov23NonCount[2]]

            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro2WithJan21NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro2WithJan21NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro2WithJan21NonCount[2]] 
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro2WithFeb21NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro2WithFeb21NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro2WithFeb21NonCount[2]] 
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro2WithMar21NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro2WithMar21NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro2WithMar21NonCount[2]] 
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro2WithApr21NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro2WithApr21NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro2WithApr21NonCount[2]] 
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro2WithMay21NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro2WithMay21NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro2WithMay21NonCount[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro2WithJun21NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro2WithJun21NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro2WithJun21NonCount[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro2WithJul21NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro2WithJul21NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro2WithJul21NonCount[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro2WithAug21NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro2WithAug21NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro2WithAug21NonCount[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro2WithSep21NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro2WithSep21NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro2WithSep21NonCount[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro2WithOct21NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro2WithOct21NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro2WithOct21NonCount[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro2WithNov21NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro2WithNov21NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro2WithNov21NonCount[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro2WithDec21NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro2WithDec21NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro2WithDec21NonCount[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro2WithJan22NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro2WithJan22NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro2WithJan22NonCount[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro2WithFeb22NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro2WithFeb22NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro2WithFeb22NonCount[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro2WithMar22NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro2WithMar22NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro2WithMar22NonCount[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro2WithApr22NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro2WithApr22NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro2WithApr22NonCount[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro2WithMay22NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro2WithMay22NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro2WithMay22NonCount[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro2WithJun22NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro2WithJun22NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro2WithJun22NonCount[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro2WithJul22NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro2WithJul22NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro2WithJul22NonCount[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro2WithAug22NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro2WithAug22NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro2WithAug22NonCount[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro2WithSep22NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro2WithSep22NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro2WithSep22NonCount[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro2WithOct22NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro2WithOct22NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro2WithOct22NonCount[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro2WithNov22NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro2WithNov22NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro2WithNov22NonCount[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro2WithDec22NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro2WithDec22NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro2WithDec22NonCount[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro2WithJan23NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro2WithJan23NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro2WithJan23NonCount[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro2WithFeb23NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro2WithFeb23NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro2WithFeb23NonCount[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro2WithMar23NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro2WithMar23NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro2WithMar23NonCount[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro2WithApr23NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro2WithApr23NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro2WithApr23NonCount[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro2WithMay23NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro2WithMay23NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro2WithMay23NonCount[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro2WithJun23NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro2WithJun23NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro2WithJun23NonCount[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro2WithJul23NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro2WithJul23NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro2WithJul23NonCount[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro2WithAug23NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro2WithAug23NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro2WithAug23NonCount[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro2WithSep23NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro2WithSep23NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro2WithSep23NonCount[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro2WithOct23NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro2WithOct23NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro2WithOct23NonCount[2]]
            : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro2WithNov23NonCount[0]]
            : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro2WithNov23NonCount[1]]
            : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro2WithNov23NonCount[2]]

            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro3WithJan21NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro3WithJan21NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro3WithJan21NonCount[2]] 
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro3WithFeb21NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro3WithFeb21NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro3WithFeb21NonCount[2]] 
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro3WithMar21NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro3WithMar21NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro3WithMar21NonCount[2]] 
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro3WithApr21NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro3WithApr21NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro3WithApr21NonCount[2]] 
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro3WithMay21NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro3WithMay21NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro3WithMay21NonCount[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro3WithJun21NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro3WithJun21NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro3WithJun21NonCount[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro3WithJul21NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro3WithJul21NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro3WithJul21NonCount[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro3WithAug21NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro3WithAug21NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro3WithAug21NonCount[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro3WithSep21NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro3WithSep21NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro3WithSep21NonCount[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro3WithOct21NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro3WithOct21NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro3WithOct21NonCount[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro3WithNov21NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro3WithNov21NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro3WithNov21NonCount[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro3WithDec21NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro3WithDec21NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro3WithDec21NonCount[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro3WithJan22NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro3WithJan22NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro3WithJan22NonCount[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro3WithFeb22NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro3WithFeb22NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro3WithFeb22NonCount[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro3WithMar22NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro3WithMar22NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro3WithMar22NonCount[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro3WithApr22NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro3WithApr22NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro3WithApr22NonCount[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro3WithMay22NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro3WithMay22NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro3WithMay22NonCount[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro3WithJun22NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro3WithJun22NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro3WithJun22NonCount[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro3WithJul22NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro3WithJul22NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro3WithJul22NonCount[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro3WithAug22NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro3WithAug22NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro3WithAug22NonCount[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro3WithSep22NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro3WithSep22NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro3WithSep22NonCount[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro3WithOct22NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro3WithOct22NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro3WithOct22NonCount[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro3WithNov22NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro3WithNov22NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro3WithNov22NonCount[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro3WithDec22NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro3WithDec22NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro3WithDec22NonCount[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro3WithJan23NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro3WithJan23NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro3WithJan23NonCount[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro3WithFeb23NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro3WithFeb23NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro3WithFeb23NonCount[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro3WithMar23NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro3WithMar23NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro3WithMar23NonCount[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro3WithApr23NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro3WithApr23NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro3WithApr23NonCount[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro3WithMay23NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro3WithMay23NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro3WithMay23NonCount[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro3WithJun23NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro3WithJun23NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro3WithJun23NonCount[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro3WithJul23NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro3WithJul23NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro3WithJul23NonCount[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro3WithAug23NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro3WithAug23NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro3WithAug23NonCount[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro3WithSep23NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro3WithSep23NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro3WithSep23NonCount[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro3WithOct23NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro3WithOct23NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro3WithOct23NonCount[2]]
            : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro3WithNov23NonCount[0]]
            : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro3WithNov23NonCount[1]]
            : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro3WithNov23NonCount[2]]

            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro4WithJan21NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro4WithJan21NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro4WithJan21NonCount[2]] 
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro4WithFeb21NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro4WithFeb21NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro4WithFeb21NonCount[2]] 
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro4WithMar21NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro4WithMar21NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro4WithMar21NonCount[2]] 
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro4WithApr21NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro4WithApr21NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro4WithApr21NonCount[2]] 
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro4WithMay21NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro4WithMay21NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro4WithMay21NonCount[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro4WithJun21NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro4WithJun21NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro4WithJun21NonCount[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro4WithJul21NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro4WithJul21NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro4WithJul21NonCount[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro4WithAug21NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro4WithAug21NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro4WithAug21NonCount[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro4WithSep21NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro4WithSep21NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro4WithSep21NonCount[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro4WithOct21NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro4WithOct21NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro4WithOct21NonCount[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro4WithNov21NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro4WithNov21NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro4WithNov21NonCount[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro4WithDec21NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro4WithDec21NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro4WithDec21NonCount[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro4WithJan22NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro4WithJan22NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro4WithJan22NonCount[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro4WithFeb22NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro4WithFeb22NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro4WithFeb22NonCount[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro4WithMar22NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro4WithMar22NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro4WithMar22NonCount[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro4WithApr22NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro4WithApr22NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro4WithApr22NonCount[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro4WithMay22NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro4WithMay22NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro4WithMay22NonCount[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro4WithJun22NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro4WithJun22NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro4WithJun22NonCount[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro4WithJul22NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro4WithJul22NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro4WithJul22NonCount[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro4WithAug22NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro4WithAug22NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro4WithAug22NonCount[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro4WithSep22NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro4WithSep22NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro4WithSep22NonCount[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro4WithOct22NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro4WithOct22NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro4WithOct22NonCount[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro4WithNov22NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro4WithNov22NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro4WithNov22NonCount[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro4WithDec22NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro4WithDec22NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro4WithDec22NonCount[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro4WithJan23NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro4WithJan23NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro4WithJan23NonCount[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro4WithFeb23NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro4WithFeb23NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro4WithFeb23NonCount[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro4WithMar23NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro4WithMar23NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro4WithMar23NonCount[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro4WithApr23NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro4WithApr23NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro4WithApr23NonCount[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro4WithMay23NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro4WithMay23NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro4WithMay23NonCount[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro4WithJun23NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro4WithJun23NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro4WithJun23NonCount[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro4WithJul23NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro4WithJul23NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro4WithJul23NonCount[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro4WithAug23NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro4WithAug23NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro4WithAug23NonCount[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro4WithSep23NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro4WithSep23NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro4WithSep23NonCount[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro4WithOct23NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro4WithOct23NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro4WithOct23NonCount[2]]
            : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro4WithNov23NonCount[0]]
            : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro4WithNov23NonCount[1]]
            : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro4WithNov23NonCount[2]]

            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro5WithJan21NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro5WithJan21NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro5WithJan21NonCount[2]] 
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro5WithFeb21NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro5WithFeb21NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro5WithFeb21NonCount[2]] 
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro5WithMar21NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro5WithMar21NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro5WithMar21NonCount[2]] 
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro5WithApr21NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro5WithApr21NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro5WithApr21NonCount[2]] 
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro5WithMay21NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro5WithMay21NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro5WithMay21NonCount[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro5WithJun21NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro5WithJun21NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro5WithJun21NonCount[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro5WithJul21NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro5WithJul21NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro5WithJul21NonCount[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro5WithAug21NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro5WithAug21NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro5WithAug21NonCount[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro5WithSep21NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro5WithSep21NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro5WithSep21NonCount[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro5WithOct21NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro5WithOct21NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro5WithOct21NonCount[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro5WithNov21NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro5WithNov21NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro5WithNov21NonCount[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro5WithDec21NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro5WithDec21NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro5WithDec21NonCount[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro5WithJan22NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro5WithJan22NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro5WithJan22NonCount[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro5WithFeb22NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro5WithFeb22NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro5WithFeb22NonCount[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro5WithMar22NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro5WithMar22NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro5WithMar22NonCount[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro5WithApr22NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro5WithApr22NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro5WithApr22NonCount[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro5WithMay22NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro5WithMay22NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro5WithMay22NonCount[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro5WithJun22NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro5WithJun22NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro5WithJun22NonCount[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro5WithJul22NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro5WithJul22NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro5WithJul22NonCount[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro5WithAug22NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro5WithAug22NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro5WithAug22NonCount[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro5WithSep22NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro5WithSep22NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro5WithSep22NonCount[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro5WithOct22NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro5WithOct22NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro5WithOct22NonCount[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro5WithNov22NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro5WithNov22NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro5WithNov22NonCount[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro5WithDec22NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro5WithDec22NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro5WithDec22NonCount[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro5WithJan23NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro5WithJan23NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro5WithJan23NonCount[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro5WithFeb23NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro5WithFeb23NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro5WithFeb23NonCount[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro5WithMar23NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro5WithMar23NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro5WithMar23NonCount[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro5WithApr23NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro5WithApr23NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro5WithApr23NonCount[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro5WithMay23NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro5WithMay23NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro5WithMay23NonCount[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro5WithJun23NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro5WithJun23NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro5WithJun23NonCount[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro5WithJul23NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro5WithJul23NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro5WithJul23NonCount[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro5WithAug23NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro5WithAug23NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro5WithAug23NonCount[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro5WithSep23NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro5WithSep23NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro5WithSep23NonCount[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro5WithOct23NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro5WithOct23NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro5WithOct23NonCount[2]]
            : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro5WithNov23NonCount[0]]
            : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro5WithNov23NonCount[1]]
            : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro5WithNov23NonCount[2]]

            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro6WithJan21NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro6WithJan21NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro6WithJan21NonCount[2]] 
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro6WithFeb21NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro6WithFeb21NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro6WithFeb21NonCount[2]] 
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro6WithMar21NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro6WithMar21NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro6WithMar21NonCount[2]] 
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro6WithApr21NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro6WithApr21NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro6WithApr21NonCount[2]] 
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro6WithMay21NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro6WithMay21NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro6WithMay21NonCount[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro6WithJun21NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro6WithJun21NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro6WithJun21NonCount[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro6WithJul21NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro6WithJul21NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro6WithJul21NonCount[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro6WithAug21NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro6WithAug21NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro6WithAug21NonCount[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro6WithSep21NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro6WithSep21NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro6WithSep21NonCount[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro6WithOct21NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro6WithOct21NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro6WithOct21NonCount[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro6WithNov21NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro6WithNov21NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro6WithNov21NonCount[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro6WithDec21NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro6WithDec21NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro6WithDec21NonCount[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro6WithJan22NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro6WithJan22NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro6WithJan22NonCount[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro6WithFeb22NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro6WithFeb22NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro6WithFeb22NonCount[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro6WithMar22NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro6WithMar22NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro6WithMar22NonCount[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro6WithApr22NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro6WithApr22NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro6WithApr22NonCount[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro6WithMay22NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro6WithMay22NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro6WithMay22NonCount[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro6WithJun22NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro6WithJun22NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro6WithJun22NonCount[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro6WithJul22NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro6WithJul22NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro6WithJul22NonCount[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro6WithAug22NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro6WithAug22NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro6WithAug22NonCount[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro6WithSep22NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro6WithSep22NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro6WithSep22NonCount[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro6WithOct22NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro6WithOct22NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro6WithOct22NonCount[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro6WithNov22NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro6WithNov22NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro6WithNov22NonCount[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro6WithDec22NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro6WithDec22NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro6WithDec22NonCount[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro6WithJan23NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro6WithJan23NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro6WithJan23NonCount[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro6WithFeb23NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro6WithFeb23NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro6WithFeb23NonCount[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro6WithMar23NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro6WithMar23NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro6WithMar23NonCount[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro6WithApr23NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro6WithApr23NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro6WithApr23NonCount[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro6WithMay23NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro6WithMay23NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro6WithMay23NonCount[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro6WithJun23NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro6WithJun23NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro6WithJun23NonCount[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro6WithJul23NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro6WithJul23NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro6WithJul23NonCount[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro6WithAug23NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro6WithAug23NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro6WithAug23NonCount[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro6WithSep23NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro6WithSep23NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro6WithSep23NonCount[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro6WithOct23NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro6WithOct23NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro6WithOct23NonCount[2]]
            : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro6WithNov23NonCount[0]]
            : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro6WithNov23NonCount[1]]
            : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro6WithNov23NonCount[2]]

            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro7WithJan21NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro7WithJan21NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro7WithJan21NonCount[2]] 
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro7WithFeb21NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro7WithFeb21NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro7WithFeb21NonCount[2]] 
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro7WithMar21NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro7WithMar21NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro7WithMar21NonCount[2]] 
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro7WithApr21NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro7WithApr21NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro7WithApr21NonCount[2]] 
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro7WithMay21NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro7WithMay21NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro7WithMay21NonCount[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro7WithJun21NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro7WithJun21NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro7WithJun21NonCount[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro7WithJul21NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro7WithJul21NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro7WithJul21NonCount[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro7WithAug21NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro7WithAug21NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro7WithAug21NonCount[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro7WithSep21NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro7WithSep21NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro7WithSep21NonCount[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro7WithOct21NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro7WithOct21NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro7WithOct21NonCount[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro7WithNov21NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro7WithNov21NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro7WithNov21NonCount[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro7WithDec21NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro7WithDec21NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro7WithDec21NonCount[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro7WithJan22NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro7WithJan22NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro7WithJan22NonCount[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro7WithFeb22NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro7WithFeb22NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro7WithFeb22NonCount[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro7WithMar22NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro7WithMar22NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro7WithMar22NonCount[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro7WithApr22NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro7WithApr22NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro7WithApr22NonCount[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro7WithMay22NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro7WithMay22NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro7WithMay22NonCount[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro7WithJun22NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro7WithJun22NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro7WithJun22NonCount[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro7WithJul22NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro7WithJul22NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro7WithJul22NonCount[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro7WithAug22NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro7WithAug22NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro7WithAug22NonCount[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro7WithSep22NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro7WithSep22NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro7WithSep22NonCount[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro7WithOct22NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro7WithOct22NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro7WithOct22NonCount[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro7WithNov22NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro7WithNov22NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro7WithNov22NonCount[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro7WithDec22NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro7WithDec22NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro7WithDec22NonCount[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro7WithJan23NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro7WithJan23NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro7WithJan23NonCount[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro7WithFeb23NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro7WithFeb23NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro7WithFeb23NonCount[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro7WithMar23NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro7WithMar23NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro7WithMar23NonCount[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro7WithApr23NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro7WithApr23NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro7WithApr23NonCount[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro7WithMay23NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro7WithMay23NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro7WithMay23NonCount[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro7WithJun23NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro7WithJun23NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro7WithJun23NonCount[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro7WithJul23NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro7WithJul23NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro7WithJul23NonCount[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro7WithAug23NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro7WithAug23NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro7WithAug23NonCount[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro7WithSep23NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro7WithSep23NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro7WithSep23NonCount[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro7WithOct23NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro7WithOct23NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro7WithOct23NonCount[2]]
            : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro7WithNov23NonCount[0]]
            : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro7WithNov23NonCount[1]]
            : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro7WithNov23NonCount[2]]
            : ''
            },{
                name: 'Claims',
                data: Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21Claim[2]] 
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21Claim[2]] 
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [allproWithMar21Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [allproWithMar21Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [allproWithMar21Claim[2]] 
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [allproWithApr21Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [allproWithApr21Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [allproWithApr21Claim[2]] 
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [allproWithMay21Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [allproWithMay21Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [allproWithMay21Claim[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [allproWithJun21Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [allproWithJun21Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [allproWithJun21Claim[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [allproWithJul21Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [allproWithJul21Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [allproWithJul21Claim[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [allproWithAug21Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [allproWithAug21Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [allproWithAug21Claim[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [allproWithSep21Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [allproWithSep21Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [allproWithSep21Claim[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [allproWithOct21Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [allproWithOct21Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [allproWithOct21Claim[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [allproWithNov21Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [allproWithNov21Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [allproWithNov21Claim[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [allproWithDec21Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [allproWithDec21Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [allproWithDec21Claim[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [allproWithJan22Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [allproWithJan22Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [allproWithJan22Claim[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [allproWithFeb22Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [allproWithFeb22Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [allproWithFeb22Claim[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [allproWithMar22Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [allproWithMar22Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [allproWithMar22Claim[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [allproWithApr22Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [allproWithApr22Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [allproWithApr22Claim[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [allproWithMay22Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [allproWithMay22Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [allproWithMay22Claim[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [allproWithJun22Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [allproWithJun22Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [allproWithJun22Claim[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [allproWithJul22Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [allproWithJul22Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [allproWithJul22Claim[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [allproWithAug22Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [allproWithAug22Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [allproWithAug22Claim[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [allproWithSep22Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [allproWithSep22Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [allproWithSep22Claim[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [allproWithOct22Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [allproWithOct22Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [allproWithOct22Claim[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [allproWithNov22Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [allproWithNov22Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [allproWithNov22Claim[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [allproWithDec22Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [allproWithDec22Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [allproWithDec22Claim[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [allproWithJan23Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [allproWithJan23Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [allproWithJan23Claim[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [allproWithFeb23Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [allproWithFeb23Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [allproWithFeb23Claim[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [allproWithMar23Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [allproWithMar23Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [allproWithMar23Claim[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [allproWithApr23Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [allproWithApr23Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [allproWithApr23Claim[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [allproWithMay23Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [allproWithMay23Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [allproWithMay23Claim[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [allproWithJun23Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [allproWithJun23Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [allproWithJun23Claim[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [allproWithJul23Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [allproWithJul23Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [allproWithJul23Claim[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [allproWithAug23Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [allproWithAug23Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [allproWithAug23Claim[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [allproWithSep23Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [allproWithSep23Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [allproWithSep23Claim[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [allproWithOct23Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [allproWithOct23Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [allproWithOct23Claim[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [allproWithNov23Claim[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [allproWithNov23Claim[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [allproWithNov23Claim[2]]
    
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro1WithJan21Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro1WithJan21Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro1WithJan21Claim[2]] 
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro1WithFeb21Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro1WithFeb21Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro1WithFeb21Claim[2]] 
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro1WithMar21Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro1WithMar21Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro1WithMar21Claim[2]] 
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro1WithApr21Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro1WithApr21Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro1WithApr21Claim[2]] 
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro1WithMay21Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro1WithMay21Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro1WithMay21Claim[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro1WithJun21Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro1WithJun21Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro1WithJun21Claim[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro1WithJul21Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro1WithJul21Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro1WithJul21Claim[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro1WithAug21Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro1WithAug21Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro1WithAug21Claim[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro1WithSep21Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro1WithSep21Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro1WithSep21Claim[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro1WithOct21Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro1WithOct21Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro1WithOct21Claim[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro1WithNov21Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro1WithNov21Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro1WithNov21Claim[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro1WithDec21Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro1WithDec21Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro1WithDec21Claim[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro1WithJan22Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro1WithJan22Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro1WithJan22Claim[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro1WithFeb22Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro1WithFeb22Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro1WithFeb22Claim[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro1WithMar22Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro1WithMar22Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro1WithMar22Claim[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro1WithApr22Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro1WithApr22Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro1WithApr22Claim[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro1WithMay22Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro1WithMay22Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro1WithMay22Claim[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro1WithJun22Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro1WithJun22Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro1WithJun22Claim[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro1WithJul22Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro1WithJul22Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro1WithJul22Claim[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro1WithAug22Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro1WithAug22Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro1WithAug22Claim[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro1WithSep22Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro1WithSep22Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro1WithSep22Claim[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro1WithOct22Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro1WithOct22Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro1WithOct22Claim[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro1WithNov22Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro1WithNov22Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro1WithNov22Claim[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro1WithDec22Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro1WithDec22Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro1WithDec22Claim[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro1WithJan23Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro1WithJan23Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro1WithJan23Claim[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro1WithFeb23Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro1WithFeb23Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro1WithFeb23Claim[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro1WithMar23Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro1WithMar23Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro1WithMar23Claim[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro1WithApr23Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro1WithApr23Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro1WithApr23Claim[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro1WithMay23Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro1WithMay23Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro1WithMay23Claim[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro1WithJun23Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro1WithJun23Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro1WithJun23Claim[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro1WithJul23Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro1WithJul23Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro1WithJul23Claim[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro1WithAug23Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro1WithAug23Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro1WithAug23Claim[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro1WithSep23Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro1WithSep23Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro1WithSep23Claim[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro1WithOct23Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro1WithOct23Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro1WithOct23Claim[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro1WithNov23Claim[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro1WithNov23Claim[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro1WithNov23Claim[2]]
    
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro2WithJan21Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro2WithJan21Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro2WithJan21Claim[2]] 
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro2WithFeb21Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro2WithFeb21Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro2WithFeb21Claim[2]] 
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro2WithMar21Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro2WithMar21Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro2WithMar21Claim[2]] 
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro2WithApr21Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro2WithApr21Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro2WithApr21Claim[2]] 
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro2WithMay21Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro2WithMay21Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro2WithMay21Claim[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro2WithJun21Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro2WithJun21Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro2WithJun21Claim[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro2WithJul21Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro2WithJul21Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro2WithJul21Claim[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro2WithAug21Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro2WithAug21Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro2WithAug21Claim[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro2WithSep21Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro2WithSep21Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro2WithSep21Claim[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro2WithOct21Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro2WithOct21Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro2WithOct21Claim[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro2WithNov21Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro2WithNov21Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro2WithNov21Claim[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro2WithDec21Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro2WithDec21Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro2WithDec21Claim[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro2WithJan22Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro2WithJan22Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro2WithJan22Claim[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro2WithFeb22Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro2WithFeb22Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro2WithFeb22Claim[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro2WithMar22Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro2WithMar22Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro2WithMar22Claim[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro2WithApr22Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro2WithApr22Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro2WithApr22Claim[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro2WithMay22Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro2WithMay22Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro2WithMay22Claim[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro2WithJun22Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro2WithJun22Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro2WithJun22Claim[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro2WithJul22Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro2WithJul22Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro2WithJul22Claim[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro2WithAug22Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro2WithAug22Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro2WithAug22Claim[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro2WithSep22Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro2WithSep22Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro2WithSep22Claim[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro2WithOct22Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro2WithOct22Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro2WithOct22Claim[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro2WithNov22Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro2WithNov22Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro2WithNov22Claim[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro2WithDec22Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro2WithDec22Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro2WithDec22Claim[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro2WithJan23Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro2WithJan23Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro2WithJan23Claim[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro2WithFeb23Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro2WithFeb23Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro2WithFeb23Claim[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro2WithMar23Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro2WithMar23Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro2WithMar23Claim[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro2WithApr23Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro2WithApr23Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro2WithApr23Claim[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro2WithMay23Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro2WithMay23Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro2WithMay23Claim[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro2WithJun23Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro2WithJun23Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro2WithJun23Claim[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro2WithJul23Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro2WithJul23Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro2WithJul23Claim[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro2WithAug23Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro2WithAug23Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro2WithAug23Claim[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro2WithSep23Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro2WithSep23Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro2WithSep23Claim[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro2WithOct23Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro2WithOct23Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro2WithOct23Claim[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro2WithNov23Claim[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro2WithNov23Claim[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro2WithNov23Claim[2]]
    
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro3WithJan21Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro3WithJan21Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro3WithJan21Claim[2]] 
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro3WithFeb21Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro3WithFeb21Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro3WithFeb21Claim[2]] 
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro3WithMar21Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro3WithMar21Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro3WithMar21Claim[2]] 
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro3WithApr21Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro3WithApr21Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro3WithApr21Claim[2]] 
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro3WithMay21Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro3WithMay21Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro3WithMay21Claim[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro3WithJun21Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro3WithJun21Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro3WithJun21Claim[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro3WithJul21Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro3WithJul21Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro3WithJul21Claim[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro3WithAug21Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro3WithAug21Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro3WithAug21Claim[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro3WithSep21Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro3WithSep21Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro3WithSep21Claim[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro3WithOct21Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro3WithOct21Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro3WithOct21Claim[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro3WithNov21Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro3WithNov21Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro3WithNov21Claim[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro3WithDec21Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro3WithDec21Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro3WithDec21Claim[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro3WithJan22Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro3WithJan22Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro3WithJan22Claim[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro3WithFeb22Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro3WithFeb22Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro3WithFeb22Claim[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro3WithMar22Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro3WithMar22Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro3WithMar22Claim[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro3WithApr22Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro3WithApr22Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro3WithApr22Claim[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro3WithMay22Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro3WithMay22Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro3WithMay22Claim[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro3WithJun22Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro3WithJun22Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro3WithJun22Claim[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro3WithJul22Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro3WithJul22Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro3WithJul22Claim[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro3WithAug22Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro3WithAug22Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro3WithAug22Claim[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro3WithSep22Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro3WithSep22Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro3WithSep22Claim[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro3WithOct22Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro3WithOct22Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro3WithOct22Claim[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro3WithNov22Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro3WithNov22Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro3WithNov22Claim[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro3WithDec22Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro3WithDec22Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro3WithDec22Claim[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro3WithJan23Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro3WithJan23Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro3WithJan23Claim[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro3WithFeb23Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro3WithFeb23Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro3WithFeb23Claim[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro3WithMar23Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro3WithMar23Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro3WithMar23Claim[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro3WithApr23Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro3WithApr23Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro3WithApr23Claim[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro3WithMay23Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro3WithMay23Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro3WithMay23Claim[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro3WithJun23Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro3WithJun23Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro3WithJun23Claim[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro3WithJul23Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro3WithJul23Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro3WithJul23Claim[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro3WithAug23Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro3WithAug23Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro3WithAug23Claim[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro3WithSep23Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro3WithSep23Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro3WithSep23Claim[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro3WithOct23Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro3WithOct23Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro3WithOct23Claim[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro3WithNov23Claim[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro3WithNov23Claim[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro3WithNov23Claim[2]]
    
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro4WithJan21Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro4WithJan21Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro4WithJan21Claim[2]] 
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro4WithFeb21Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro4WithFeb21Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro4WithFeb21Claim[2]] 
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro4WithMar21Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro4WithMar21Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro4WithMar21Claim[2]] 
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro4WithApr21Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro4WithApr21Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro4WithApr21Claim[2]] 
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro4WithMay21Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro4WithMay21Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro4WithMay21Claim[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro4WithJun21Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro4WithJun21Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro4WithJun21Claim[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro4WithJul21Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro4WithJul21Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro4WithJul21Claim[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro4WithAug21Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro4WithAug21Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro4WithAug21Claim[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro4WithSep21Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro4WithSep21Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro4WithSep21Claim[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro4WithOct21Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro4WithOct21Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro4WithOct21Claim[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro4WithNov21Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro4WithNov21Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro4WithNov21Claim[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro4WithDec21Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro4WithDec21Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro4WithDec21Claim[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro4WithJan22Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro4WithJan22Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro4WithJan22Claim[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro4WithFeb22Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro4WithFeb22Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro4WithFeb22Claim[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro4WithMar22Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro4WithMar22Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro4WithMar22Claim[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro4WithApr22Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro4WithApr22Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro4WithApr22Claim[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro4WithMay22Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro4WithMay22Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro4WithMay22Claim[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro4WithJun22Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro4WithJun22Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro4WithJun22Claim[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro4WithJul22Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro4WithJul22Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro4WithJul22Claim[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro4WithAug22Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro4WithAug22Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro4WithAug22Claim[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro4WithSep22Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro4WithSep22Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro4WithSep22Claim[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro4WithOct22Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro4WithOct22Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro4WithOct22Claim[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro4WithNov22Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro4WithNov22Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro4WithNov22Claim[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro4WithDec22Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro4WithDec22Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro4WithDec22Claim[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro4WithJan23Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro4WithJan23Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro4WithJan23Claim[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro4WithFeb23Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro4WithFeb23Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro4WithFeb23Claim[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro4WithMar23Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro4WithMar23Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro4WithMar23Claim[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro4WithApr23Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro4WithApr23Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro4WithApr23Claim[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro4WithMay23Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro4WithMay23Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro4WithMay23Claim[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro4WithJun23Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro4WithJun23Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro4WithJun23Claim[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro4WithJul23Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro4WithJul23Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro4WithJul23Claim[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro4WithAug23Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro4WithAug23Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro4WithAug23Claim[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro4WithSep23Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro4WithSep23Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro4WithSep23Claim[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro4WithOct23Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro4WithOct23Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro4WithOct23Claim[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro4WithNov23Claim[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro4WithNov23Claim[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro4WithNov23Claim[2]]
    
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro5WithJan21Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro5WithJan21Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro5WithJan21Claim[2]] 
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro5WithFeb21Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro5WithFeb21Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro5WithFeb21Claim[2]] 
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro5WithMar21Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro5WithMar21Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro5WithMar21Claim[2]] 
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro5WithApr21Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro5WithApr21Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro5WithApr21Claim[2]] 
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro5WithMay21Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro5WithMay21Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro5WithMay21Claim[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro5WithJun21Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro5WithJun21Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro5WithJun21Claim[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro5WithJul21Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro5WithJul21Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro5WithJul21Claim[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro5WithAug21Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro5WithAug21Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro5WithAug21Claim[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro5WithSep21Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro5WithSep21Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro5WithSep21Claim[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro5WithOct21Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro5WithOct21Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro5WithOct21Claim[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro5WithNov21Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro5WithNov21Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro5WithNov21Claim[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro5WithDec21Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro5WithDec21Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro5WithDec21Claim[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro5WithJan22Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro5WithJan22Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro5WithJan22Claim[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro5WithFeb22Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro5WithFeb22Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro5WithFeb22Claim[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro5WithMar22Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro5WithMar22Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro5WithMar22Claim[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro5WithApr22Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro5WithApr22Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro5WithApr22Claim[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro5WithMay22Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro5WithMay22Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro5WithMay22Claim[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro5WithJun22Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro5WithJun22Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro5WithJun22Claim[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro5WithJul22Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro5WithJul22Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro5WithJul22Claim[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro5WithAug22Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro5WithAug22Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro5WithAug22Claim[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro5WithSep22Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro5WithSep22Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro5WithSep22Claim[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro5WithOct22Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro5WithOct22Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro5WithOct22Claim[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro5WithNov22Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro5WithNov22Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro5WithNov22Claim[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro5WithDec22Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro5WithDec22Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro5WithDec22Claim[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro5WithJan23Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro5WithJan23Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro5WithJan23Claim[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro5WithFeb23Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro5WithFeb23Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro5WithFeb23Claim[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro5WithMar23Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro5WithMar23Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro5WithMar23Claim[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro5WithApr23Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro5WithApr23Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro5WithApr23Claim[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro5WithMay23Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro5WithMay23Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro5WithMay23Claim[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro5WithJun23Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro5WithJun23Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro5WithJun23Claim[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro5WithJul23Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro5WithJul23Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro5WithJul23Claim[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro5WithAug23Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro5WithAug23Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro5WithAug23Claim[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro5WithSep23Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro5WithSep23Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro5WithSep23Claim[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro5WithOct23Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro5WithOct23Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro5WithOct23Claim[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro5WithNov23Claim[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro5WithNov23Claim[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro5WithNov23Claim[2]]
    
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro6WithJan21Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro6WithJan21Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro6WithJan21Claim[2]] 
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro6WithFeb21Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro6WithFeb21Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro6WithFeb21Claim[2]] 
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro6WithMar21Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro6WithMar21Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro6WithMar21Claim[2]] 
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro6WithApr21Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro6WithApr21Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro6WithApr21Claim[2]] 
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro6WithMay21Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro6WithMay21Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro6WithMay21Claim[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro6WithJun21Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro6WithJun21Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro6WithJun21Claim[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro6WithJul21Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro6WithJul21Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro6WithJul21Claim[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro6WithAug21Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro6WithAug21Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro6WithAug21Claim[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro6WithSep21Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro6WithSep21Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro6WithSep21Claim[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro6WithOct21Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro6WithOct21Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro6WithOct21Claim[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro6WithNov21Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro6WithNov21Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro6WithNov21Claim[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro6WithDec21Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro6WithDec21Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro6WithDec21Claim[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro6WithJan22Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro6WithJan22Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro6WithJan22Claim[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro6WithFeb22Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro6WithFeb22Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro6WithFeb22Claim[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro6WithMar22Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro6WithMar22Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro6WithMar22Claim[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro6WithApr22Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro6WithApr22Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro6WithApr22Claim[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro6WithMay22Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro6WithMay22Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro6WithMay22Claim[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro6WithJun22Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro6WithJun22Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro6WithJun22Claim[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro6WithJul22Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro6WithJul22Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro6WithJul22Claim[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro6WithAug22Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro6WithAug22Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro6WithAug22Claim[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro6WithSep22Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro6WithSep22Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro6WithSep22Claim[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro6WithOct22Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro6WithOct22Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro6WithOct22Claim[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro6WithNov22Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro6WithNov22Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro6WithNov22Claim[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro6WithDec22Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro6WithDec22Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro6WithDec22Claim[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro6WithJan23Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro6WithJan23Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro6WithJan23Claim[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro6WithFeb23Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro6WithFeb23Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro6WithFeb23Claim[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro6WithMar23Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro6WithMar23Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro6WithMar23Claim[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro6WithApr23Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro6WithApr23Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro6WithApr23Claim[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro6WithMay23Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro6WithMay23Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro6WithMay23Claim[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro6WithJun23Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro6WithJun23Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro6WithJun23Claim[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro6WithJul23Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro6WithJul23Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro6WithJul23Claim[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro6WithAug23Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro6WithAug23Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro6WithAug23Claim[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro6WithSep23Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro6WithSep23Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro6WithSep23Claim[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro6WithOct23Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro6WithOct23Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro6WithOct23Claim[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro6WithNov23Claim[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro6WithNov23Claim[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro6WithNov23Claim[2]]
    
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro7WithJan21Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro7WithJan21Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro7WithJan21Claim[2]] 
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro7WithFeb21Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro7WithFeb21Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro7WithFeb21Claim[2]] 
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro7WithMar21Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro7WithMar21Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro7WithMar21Claim[2]] 
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro7WithApr21Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro7WithApr21Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro7WithApr21Claim[2]] 
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro7WithMay21Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro7WithMay21Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro7WithMay21Claim[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro7WithJun21Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro7WithJun21Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro7WithJun21Claim[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro7WithJul21Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro7WithJul21Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro7WithJul21Claim[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro7WithAug21Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro7WithAug21Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro7WithAug21Claim[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro7WithSep21Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro7WithSep21Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro7WithSep21Claim[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro7WithOct21Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro7WithOct21Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro7WithOct21Claim[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro7WithNov21Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro7WithNov21Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro7WithNov21Claim[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro7WithDec21Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro7WithDec21Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro7WithDec21Claim[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro7WithJan22Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro7WithJan22Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro7WithJan22Claim[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro7WithFeb22Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro7WithFeb22Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro7WithFeb22Claim[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro7WithMar22Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro7WithMar22Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro7WithMar22Claim[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro7WithApr22Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro7WithApr22Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro7WithApr22Claim[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro7WithMay22Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro7WithMay22Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro7WithMay22Claim[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro7WithJun22Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro7WithJun22Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro7WithJun22Claim[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro7WithJul22Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro7WithJul22Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro7WithJul22Claim[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro7WithAug22Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro7WithAug22Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro7WithAug22Claim[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro7WithSep22Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro7WithSep22Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro7WithSep22Claim[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro7WithOct22Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro7WithOct22Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro7WithOct22Claim[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro7WithNov22Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro7WithNov22Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro7WithNov22Claim[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro7WithDec22Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro7WithDec22Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro7WithDec22Claim[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro7WithJan23Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro7WithJan23Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro7WithJan23Claim[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro7WithFeb23Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro7WithFeb23Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro7WithFeb23Claim[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro7WithMar23Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro7WithMar23Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro7WithMar23Claim[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro7WithApr23Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro7WithApr23Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro7WithApr23Claim[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro7WithMay23Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro7WithMay23Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro7WithMay23Claim[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro7WithJun23Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro7WithJun23Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro7WithJun23Claim[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro7WithJul23Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro7WithJul23Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro7WithJul23Claim[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro7WithAug23Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro7WithAug23Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro7WithAug23Claim[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro7WithSep23Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro7WithSep23Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro7WithSep23Claim[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro7WithOct23Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro7WithOct23Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro7WithOct23Claim[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro7WithNov23Claim[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro7WithNov23Claim[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro7WithNov23Claim[2]]
                : ''
            },{
                name: 'Per-Visit',
                data: Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [allproWithJan21PerVisit[2]] 
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [allproWithFeb21PerVisit[2]] 
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [allproWithMar21PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [allproWithMar21PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [allproWithMar21PerVisit[2]] 
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [allproWithApr21PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [allproWithApr21PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [allproWithApr21PerVisit[2]] 
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [allproWithMay21PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [allproWithMay21PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [allproWithMay21PerVisit[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [allproWithJun21PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [allproWithJun21PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [allproWithJun21PerVisit[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [allproWithJul21PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [allproWithJul21PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [allproWithJul21PerVisit[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [allproWithAug21PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [allproWithAug21PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [allproWithAug21PerVisit[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [allproWithSep21PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [allproWithSep21PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [allproWithSep21PerVisit[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [allproWithOct21PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [allproWithOct21PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [allproWithOct21PerVisit[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [allproWithNov21PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [allproWithNov21PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [allproWithNov21PerVisit[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [allproWithDec21PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [allproWithDec21PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [allproWithDec21PerVisit[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [allproWithJan22PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [allproWithJan22PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [allproWithJan22PerVisit[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [allproWithFeb22PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [allproWithFeb22PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [allproWithFeb22PerVisit[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [allproWithMar22PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [allproWithMar22PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [allproWithMar22PerVisit[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [allproWithApr22PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [allproWithApr22PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [allproWithApr22PerVisit[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [allproWithMay22PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [allproWithMay22PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [allproWithMay22PerVisit[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [allproWithJun22PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [allproWithJun22PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [allproWithJun22PerVisit[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [allproWithJul22PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [allproWithJul22PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [allproWithJul22PerVisit[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [allproWithAug22PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [allproWithAug22PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [allproWithAug22PerVisit[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [allproWithSep22PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [allproWithSep22PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [allproWithSep22PerVisit[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [allproWithOct22PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [allproWithOct22PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [allproWithOct22PerVisit[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [allproWithNov22PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [allproWithNov22PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [allproWithNov22PerVisit[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [allproWithDec22PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [allproWithDec22PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [allproWithDec22PerVisit[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [allproWithJan23PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [allproWithJan23PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [allproWithJan23PerVisit[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [allproWithFeb23PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [allproWithFeb23PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [allproWithFeb23PerVisit[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [allproWithMar23PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [allproWithMar23PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [allproWithMar23PerVisit[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [allproWithApr23PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [allproWithApr23PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [allproWithApr23PerVisit[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [allproWithMay23PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [allproWithMay23PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [allproWithMay23PerVisit[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [allproWithJun23PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [allproWithJun23PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [allproWithJun23PerVisit[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [allproWithJul23PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [allproWithJul23PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [allproWithJul23PerVisit[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [allproWithAug23PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [allproWithAug23PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [allproWithAug23PerVisit[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [allproWithSep23PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [allproWithSep23PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [allproWithSep23PerVisit[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [allproWithOct23PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [allproWithOct23PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [allproWithOct23PerVisit[2]]
                : Providers === 'All Providers' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [allproWithNov23PerVisit[0]]
                : Providers === 'All Providers' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [allproWithNov23PerVisit[1]]
                : Providers === 'All Providers' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [allproWithNov23PerVisit[2]]
    
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro1WithJan21PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro1WithJan21PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro1WithJan21PerVisit[2]] 
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro1WithFeb21PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro1WithFeb21PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro1WithFeb21PerVisit[2]] 
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro1WithMar21PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro1WithMar21PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro1WithMar21PerVisit[2]] 
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro1WithApr21PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro1WithApr21PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro1WithApr21PerVisit[2]] 
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro1WithMay21PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro1WithMay21PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro1WithMay21PerVisit[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro1WithJun21PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro1WithJun21PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro1WithJun21PerVisit[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro1WithJul21PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro1WithJul21PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro1WithJul21PerVisit[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro1WithAug21PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro1WithAug21PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro1WithAug21PerVisit[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro1WithSep21PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro1WithSep21PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro1WithSep21PerVisit[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro1WithOct21PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro1WithOct21PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro1WithOct21PerVisit[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro1WithNov21PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro1WithNov21PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro1WithNov21PerVisit[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro1WithDec21PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro1WithDec21PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro1WithDec21PerVisit[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro1WithJan22PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro1WithJan22PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro1WithJan22PerVisit[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro1WithFeb22PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro1WithFeb22PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro1WithFeb22PerVisit[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro1WithMar22PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro1WithMar22PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro1WithMar22PerVisit[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro1WithApr22PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro1WithApr22PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro1WithApr22PerVisit[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro1WithMay22PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro1WithMay22PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro1WithMay22PerVisit[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro1WithJun22PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro1WithJun22PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro1WithJun22PerVisit[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro1WithJul22PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro1WithJul22PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro1WithJul22PerVisit[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro1WithAug22PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro1WithAug22PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro1WithAug22PerVisit[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro1WithSep22PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro1WithSep22PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro1WithSep22PerVisit[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro1WithOct22PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro1WithOct22PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro1WithOct22PerVisit[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro1WithNov22PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro1WithNov22PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro1WithNov22PerVisit[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro1WithDec22PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro1WithDec22PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro1WithDec22PerVisit[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro1WithJan23PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro1WithJan23PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro1WithJan23PerVisit[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro1WithFeb23PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro1WithFeb23PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro1WithFeb23PerVisit[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro1WithMar23PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro1WithMar23PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro1WithMar23PerVisit[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro1WithApr23PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro1WithApr23PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro1WithApr23PerVisit[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro1WithMay23PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro1WithMay23PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro1WithMay23PerVisit[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro1WithJun23PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro1WithJun23PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro1WithJun23PerVisit[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro1WithJul23PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro1WithJul23PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro1WithJul23PerVisit[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro1WithAug23PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro1WithAug23PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro1WithAug23PerVisit[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro1WithSep23PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro1WithSep23PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro1WithSep23PerVisit[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro1WithOct23PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro1WithOct23PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro1WithOct23PerVisit[2]]
                : Providers === 'Provider1' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro1WithNov23PerVisit[0]]
                : Providers === 'Provider1' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro1WithNov23PerVisit[1]]
                : Providers === 'Provider1' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro1WithNov23PerVisit[2]]
    
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro2WithJan21PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro2WithJan21PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro2WithJan21PerVisit[2]] 
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro2WithFeb21PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro2WithFeb21PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro2WithFeb21PerVisit[2]] 
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro2WithMar21PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro2WithMar21PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro2WithMar21PerVisit[2]] 
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro2WithApr21PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro2WithApr21PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro2WithApr21PerVisit[2]] 
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro2WithMay21PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro2WithMay21PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro2WithMay21PerVisit[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro2WithJun21PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro2WithJun21PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro2WithJun21PerVisit[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro2WithJul21PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro2WithJul21PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro2WithJul21PerVisit[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro2WithAug21PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro2WithAug21PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro2WithAug21PerVisit[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro2WithSep21PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro2WithSep21PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro2WithSep21PerVisit[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro2WithOct21PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro2WithOct21PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro2WithOct21PerVisit[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro2WithNov21PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro2WithNov21PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro2WithNov21PerVisit[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro2WithDec21PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro2WithDec21PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro2WithDec21PerVisit[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro2WithJan22PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro2WithJan22PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro2WithJan22PerVisit[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro2WithFeb22PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro2WithFeb22PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro2WithFeb22PerVisit[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro2WithMar22PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro2WithMar22PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro2WithMar22PerVisit[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro2WithApr22PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro2WithApr22PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro2WithApr22PerVisit[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro2WithMay22PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro2WithMay22PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro2WithMay22PerVisit[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro2WithJun22PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro2WithJun22PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro2WithJun22PerVisit[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro2WithJul22PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro2WithJul22PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro2WithJul22PerVisit[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro2WithAug22PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro2WithAug22PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro2WithAug22PerVisit[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro2WithSep22PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro2WithSep22PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro2WithSep22PerVisit[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro2WithOct22PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro2WithOct22PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro2WithOct22PerVisit[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro2WithNov22PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro2WithNov22PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro2WithNov22PerVisit[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro2WithDec22PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro2WithDec22PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro2WithDec22PerVisit[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro2WithJan23PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro2WithJan23PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro2WithJan23PerVisit[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro2WithFeb23PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro2WithFeb23PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro2WithFeb23PerVisit[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro2WithMar23PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro2WithMar23PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro2WithMar23PerVisit[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro2WithApr23PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro2WithApr23PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro2WithApr23PerVisit[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro2WithMay23PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro2WithMay23PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro2WithMay23PerVisit[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro2WithJun23PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro2WithJun23PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro2WithJun23PerVisit[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro2WithJul23PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro2WithJul23PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro2WithJul23PerVisit[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro2WithAug23PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro2WithAug23PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro2WithAug23PerVisit[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro2WithSep23PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro2WithSep23PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro2WithSep23PerVisit[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro2WithOct23PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro2WithOct23PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro2WithOct23PerVisit[2]]
                : Providers === 'Provider2' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro2WithNov23PerVisit[0]]
                : Providers === 'Provider2' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro2WithNov23PerVisit[1]]
                : Providers === 'Provider2' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro2WithNov23PerVisit[2]]
    
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro3WithJan21PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro3WithJan21PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro3WithJan21PerVisit[2]] 
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro3WithFeb21PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro3WithFeb21PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro3WithFeb21PerVisit[2]] 
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro3WithMar21PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro3WithMar21PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro3WithMar21PerVisit[2]] 
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro3WithApr21PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro3WithApr21PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro3WithApr21PerVisit[2]] 
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro3WithMay21PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro3WithMay21PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro3WithMay21PerVisit[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro3WithJun21PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro3WithJun21PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro3WithJun21PerVisit[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro3WithJul21PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro3WithJul21PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro3WithJul21PerVisit[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro3WithAug21PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro3WithAug21PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro3WithAug21PerVisit[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro3WithSep21PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro3WithSep21PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro3WithSep21PerVisit[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro3WithOct21PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro3WithOct21PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro3WithOct21PerVisit[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro3WithNov21PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro3WithNov21PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro3WithNov21PerVisit[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro3WithDec21PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro3WithDec21PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro3WithDec21PerVisit[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro3WithJan22PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro3WithJan22PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro3WithJan22PerVisit[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro3WithFeb22PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro3WithFeb22PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro3WithFeb22PerVisit[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro3WithMar22PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro3WithMar22PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro3WithMar22PerVisit[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro3WithApr22PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro3WithApr22PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro3WithApr22PerVisit[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro3WithMay22PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro3WithMay22PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro3WithMay22PerVisit[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro3WithJun22PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro3WithJun22PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro3WithJun22PerVisit[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro3WithJul22PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro3WithJul22PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro3WithJul22PerVisit[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro3WithAug22PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro3WithAug22PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro3WithAug22PerVisit[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro3WithSep22PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro3WithSep22PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro3WithSep22PerVisit[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro3WithOct22PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro3WithOct22PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro3WithOct22PerVisit[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro3WithNov22PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro3WithNov22PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro3WithNov22PerVisit[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro3WithDec22PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro3WithDec22PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro3WithDec22PerVisit[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro3WithJan23PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro3WithJan23PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro3WithJan23PerVisit[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro3WithFeb23PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro3WithFeb23PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro3WithFeb23PerVisit[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro3WithMar23PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro3WithMar23PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro3WithMar23PerVisit[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro3WithApr23PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro3WithApr23PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro3WithApr23PerVisit[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro3WithMay23PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro3WithMay23PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro3WithMay23PerVisit[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro3WithJun23PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro3WithJun23PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro3WithJun23PerVisit[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro3WithJul23PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro3WithJul23PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro3WithJul23PerVisit[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro3WithAug23PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro3WithAug23PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro3WithAug23PerVisit[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro3WithSep23PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro3WithSep23PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro3WithSep23PerVisit[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro3WithOct23PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro3WithOct23PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro3WithOct23PerVisit[2]]
                : Providers === 'Provider3' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro3WithNov23PerVisit[0]]
                : Providers === 'Provider3' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro3WithNov23PerVisit[1]]
                : Providers === 'Provider3' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro3WithNov23PerVisit[2]]
    
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro4WithJan21PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro4WithJan21PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro4WithJan21PerVisit[2]] 
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro4WithFeb21PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro4WithFeb21PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro4WithFeb21PerVisit[2]] 
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro4WithMar21PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro4WithMar21PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro4WithMar21PerVisit[2]] 
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro4WithApr21PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro4WithApr21PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro4WithApr21PerVisit[2]] 
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro4WithMay21PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro4WithMay21PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro4WithMay21PerVisit[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro4WithJun21PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro4WithJun21PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro4WithJun21PerVisit[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro4WithJul21PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro4WithJul21PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro4WithJul21PerVisit[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro4WithAug21PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro4WithAug21PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro4WithAug21PerVisit[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro4WithSep21PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro4WithSep21PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro4WithSep21PerVisit[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro4WithOct21PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro4WithOct21PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro4WithOct21PerVisit[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro4WithNov21PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro4WithNov21PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro4WithNov21PerVisit[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro4WithDec21PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro4WithDec21PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro4WithDec21PerVisit[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro4WithJan22PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro4WithJan22PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro4WithJan22PerVisit[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro4WithFeb22PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro4WithFeb22PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro4WithFeb22PerVisit[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro4WithMar22PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro4WithMar22PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro4WithMar22PerVisit[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro4WithApr22PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro4WithApr22PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro4WithApr22PerVisit[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro4WithMay22PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro4WithMay22PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro4WithMay22PerVisit[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro4WithJun22PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro4WithJun22PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro4WithJun22PerVisit[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro4WithJul22PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro4WithJul22PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro4WithJul22PerVisit[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro4WithAug22PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro4WithAug22PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro4WithAug22PerVisit[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro4WithSep22PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro4WithSep22PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro4WithSep22PerVisit[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro4WithOct22PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro4WithOct22PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro4WithOct22PerVisit[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro4WithNov22PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro4WithNov22PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro4WithNov22PerVisit[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro4WithDec22PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro4WithDec22PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro4WithDec22PerVisit[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro4WithJan23PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro4WithJan23PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro4WithJan23PerVisit[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro4WithFeb23PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro4WithFeb23PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro4WithFeb23PerVisit[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro4WithMar23PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro4WithMar23PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro4WithMar23PerVisit[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro4WithApr23PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro4WithApr23PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro4WithApr23PerVisit[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro4WithMay23PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro4WithMay23PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro4WithMay23PerVisit[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro4WithJun23PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro4WithJun23PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro4WithJun23PerVisit[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro4WithJul23PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro4WithJul23PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro4WithJul23PerVisit[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro4WithAug23PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro4WithAug23PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro4WithAug23PerVisit[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro4WithSep23PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro4WithSep23PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro4WithSep23PerVisit[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro4WithOct23PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro4WithOct23PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro4WithOct23PerVisit[2]]
                : Providers === 'Provider4' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro4WithNov23PerVisit[0]]
                : Providers === 'Provider4' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro4WithNov23PerVisit[1]]
                : Providers === 'Provider4' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro4WithNov23PerVisit[2]]
    
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro5WithJan21PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro5WithJan21PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro5WithJan21PerVisit[2]] 
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro5WithFeb21PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro5WithFeb21PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro5WithFeb21PerVisit[2]] 
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro5WithMar21PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro5WithMar21PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro5WithMar21PerVisit[2]] 
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro5WithApr21PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro5WithApr21PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro5WithApr21PerVisit[2]] 
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro5WithMay21PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro5WithMay21PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro5WithMay21PerVisit[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro5WithJun21PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro5WithJun21PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro5WithJun21PerVisit[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro5WithJul21PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro5WithJul21PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro5WithJul21PerVisit[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro5WithAug21PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro5WithAug21PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro5WithAug21PerVisit[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro5WithSep21PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro5WithSep21PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro5WithSep21PerVisit[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro5WithOct21PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro5WithOct21PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro5WithOct21PerVisit[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro5WithNov21PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro5WithNov21PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro5WithNov21PerVisit[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro5WithDec21PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro5WithDec21PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro5WithDec21PerVisit[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro5WithJan22PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro5WithJan22PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro5WithJan22PerVisit[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro5WithFeb22PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro5WithFeb22PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro5WithFeb22PerVisit[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro5WithMar22PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro5WithMar22PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro5WithMar22PerVisit[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro5WithApr22PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro5WithApr22PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro5WithApr22PerVisit[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro5WithMay22PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro5WithMay22PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro5WithMay22PerVisit[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro5WithJun22PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro5WithJun22PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro5WithJun22PerVisit[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro5WithJul22PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro5WithJul22PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro5WithJul22PerVisit[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro5WithAug22PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro5WithAug22PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro5WithAug22PerVisit[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro5WithSep22PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro5WithSep22PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro5WithSep22PerVisit[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro5WithOct22PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro5WithOct22PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro5WithOct22PerVisit[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro5WithNov22PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro5WithNov22PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro5WithNov22PerVisit[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro5WithDec22PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro5WithDec22PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro5WithDec22PerVisit[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro5WithJan23PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro5WithJan23PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro5WithJan23PerVisit[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro5WithFeb23PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro5WithFeb23PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro5WithFeb23PerVisit[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro5WithMar23PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro5WithMar23PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro5WithMar23PerVisit[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro5WithApr23PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro5WithApr23PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro5WithApr23PerVisit[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro5WithMay23PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro5WithMay23PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro5WithMay23PerVisit[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro5WithJun23PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro5WithJun23PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro5WithJun23PerVisit[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro5WithJul23PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro5WithJul23PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro5WithJul23PerVisit[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro5WithAug23PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro5WithAug23PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro5WithAug23PerVisit[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro5WithSep23PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro5WithSep23PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro5WithSep23PerVisit[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro5WithOct23PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro5WithOct23PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro5WithOct23PerVisit[2]]
                : Providers === 'Provider5' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro5WithNov23PerVisit[0]]
                : Providers === 'Provider5' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro5WithNov23PerVisit[1]]
                : Providers === 'Provider5' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro5WithNov23PerVisit[2]]
    
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro6WithJan21PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro6WithJan21PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro6WithJan21PerVisit[2]] 
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro6WithFeb21PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro6WithFeb21PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro6WithFeb21PerVisit[2]] 
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro6WithMar21PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro6WithMar21PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro6WithMar21PerVisit[2]] 
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro6WithApr21PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro6WithApr21PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro6WithApr21PerVisit[2]] 
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro6WithMay21PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro6WithMay21PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro6WithMay21PerVisit[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro6WithJun21PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro6WithJun21PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro6WithJun21PerVisit[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro6WithJul21PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro6WithJul21PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro6WithJul21PerVisit[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro6WithAug21PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro6WithAug21PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro6WithAug21PerVisit[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro6WithSep21PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro6WithSep21PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro6WithSep21PerVisit[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro6WithOct21PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro6WithOct21PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro6WithOct21PerVisit[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro6WithNov21PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro6WithNov21PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro6WithNov21PerVisit[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro6WithDec21PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro6WithDec21PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro6WithDec21PerVisit[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro6WithJan22PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro6WithJan22PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro6WithJan22PerVisit[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro6WithFeb22PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro6WithFeb22PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro6WithFeb22PerVisit[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro6WithMar22PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro6WithMar22PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro6WithMar22PerVisit[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro6WithApr22PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro6WithApr22PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro6WithApr22PerVisit[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro6WithMay22PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro6WithMay22PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro6WithMay22PerVisit[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro6WithJun22PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro6WithJun22PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro6WithJun22PerVisit[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro6WithJul22PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro6WithJul22PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro6WithJul22PerVisit[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro6WithAug22PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro6WithAug22PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro6WithAug22PerVisit[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro6WithSep22PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro6WithSep22PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro6WithSep22PerVisit[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro6WithOct22PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro6WithOct22PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro6WithOct22PerVisit[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro6WithNov22PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro6WithNov22PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro6WithNov22PerVisit[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro6WithDec22PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro6WithDec22PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro6WithDec22PerVisit[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro6WithJan23PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro6WithJan23PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro6WithJan23PerVisit[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro6WithFeb23PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro6WithFeb23PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro6WithFeb23PerVisit[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro6WithMar23PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro6WithMar23PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro6WithMar23PerVisit[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro6WithApr23PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro6WithApr23PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro6WithApr23PerVisit[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro6WithMay23PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro6WithMay23PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro6WithMay23PerVisit[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro6WithJun23PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro6WithJun23PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro6WithJun23PerVisit[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro6WithJul23PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro6WithJul23PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro6WithJul23PerVisit[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro6WithAug23PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro6WithAug23PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro6WithAug23PerVisit[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro6WithSep23PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro6WithSep23PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro6WithSep23PerVisit[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro6WithOct23PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro6WithOct23PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro6WithOct23PerVisit[2]]
                : Providers === 'Provider6' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro6WithNov23PerVisit[0]]
                : Providers === 'Provider6' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro6WithNov23PerVisit[1]]
                : Providers === 'Provider6' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro6WithNov23PerVisit[2]]
    
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jan-21' && session === 'RX' ? [pro7WithJan21PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jan-21' && session === 'RX' ? [pro7WithJan21PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jan-21' && session === 'RX' ? [pro7WithJan21PerVisit[2]] 
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Feb-21' && session === 'RX' ? [pro7WithFeb21PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Feb-21' && session === 'RX' ? [pro7WithFeb21PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Feb-21' && session === 'RX' ? [pro7WithFeb21PerVisit[2]] 
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Mar-21' && session === 'RX' ? [pro7WithMar21PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Mar-21' && session === 'RX' ? [pro7WithMar21PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Mar-21' && session === 'RX' ? [pro7WithMar21PerVisit[2]] 
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Apr-21' && session === 'RX' ? [pro7WithApr21PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Apr-21' && session === 'RX' ? [pro7WithApr21PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Apr-21' && session === 'RX' ? [pro7WithApr21PerVisit[2]] 
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'May-21' && session === 'RX' ? [pro7WithMay21PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'May-21' && session === 'RX' ? [pro7WithMay21PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'May-21' && session === 'RX' ? [pro7WithMay21PerVisit[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jun-21' && session === 'RX' ? [pro7WithJun21PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jun-21' && session === 'RX' ? [pro7WithJun21PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jun-21' && session === 'RX' ? [pro7WithJun21PerVisit[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jul-21' && session === 'RX' ? [pro7WithJul21PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jul-21' && session === 'RX' ? [pro7WithJul21PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jul-21' && session === 'RX' ? [pro7WithJul21PerVisit[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Aug-21' && session === 'RX' ? [pro7WithAug21PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Aug-21' && session === 'RX' ? [pro7WithAug21PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Aug-21' && session === 'RX' ? [pro7WithAug21PerVisit[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Sep-21' && session === 'RX' ? [pro7WithSep21PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Sep-21' && session === 'RX' ? [pro7WithSep21PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Sep-21' && session === 'RX' ? [pro7WithSep21PerVisit[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Oct-21' && session === 'RX' ? [pro7WithOct21PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Oct-21' && session === 'RX' ? [pro7WithOct21PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Oct-21' && session === 'RX' ? [pro7WithOct21PerVisit[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Nov-21' && session === 'RX' ? [pro7WithNov21PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Nov-21' && session === 'RX' ? [pro7WithNov21PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Nov-21' && session === 'RX' ? [pro7WithNov21PerVisit[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Dec-21' && session === 'RX' ? [pro7WithDec21PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Dec-21' && session === 'RX' ? [pro7WithDec21PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Dec-21' && session === 'RX' ? [pro7WithDec21PerVisit[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jan-22' && session === 'RX' ? [pro7WithJan22PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jan-22' && session === 'RX' ? [pro7WithJan22PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jan-22' && session === 'RX' ? [pro7WithJan22PerVisit[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Feb-22' && session === 'RX' ? [pro7WithFeb22PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Feb-22' && session === 'RX' ? [pro7WithFeb22PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Feb-22' && session === 'RX' ? [pro7WithFeb22PerVisit[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Mar-22' && session === 'RX' ? [pro7WithMar22PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Mar-22' && session === 'RX' ? [pro7WithMar22PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Mar-22' && session === 'RX' ? [pro7WithMar22PerVisit[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Apr-22' && session === 'RX' ? [pro7WithApr22PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Apr-22' && session === 'RX' ? [pro7WithApr22PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Apr-22' && session === 'RX' ? [pro7WithApr22PerVisit[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'May-22' && session === 'RX' ? [pro7WithMay22PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'May-22' && session === 'RX' ? [pro7WithMay22PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'May-22' && session === 'RX' ? [pro7WithMay22PerVisit[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jun-22' && session === 'RX' ? [pro7WithJun22PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jun-22' && session === 'RX' ? [pro7WithJun22PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jun-22' && session === 'RX' ? [pro7WithJun22PerVisit[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jul-22' && session === 'RX' ? [pro7WithJul22PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jul-22' && session === 'RX' ? [pro7WithJul22PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jul-22' && session === 'RX' ? [pro7WithJul22PerVisit[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Aug-22' && session === 'RX' ? [pro7WithAug22PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Aug-22' && session === 'RX' ? [pro7WithAug22PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Aug-22' && session === 'RX' ? [pro7WithAug22PerVisit[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Sep-22' && session === 'RX' ? [pro7WithSep22PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Sep-22' && session === 'RX' ? [pro7WithSep22PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Sep-22' && session === 'RX' ? [pro7WithSep22PerVisit[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Oct-22' && session === 'RX' ? [pro7WithOct22PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Oct-22' && session === 'RX' ? [pro7WithOct22PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Oct-22' && session === 'RX' ? [pro7WithOct22PerVisit[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Nov-22' && session === 'RX' ? [pro7WithNov22PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Nov-22' && session === 'RX' ? [pro7WithNov22PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Nov-22' && session === 'RX' ? [pro7WithNov22PerVisit[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Dec-22' && session === 'RX' ? [pro7WithDec22PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Dec-22' && session === 'RX' ? [pro7WithDec22PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Dec-22' && session === 'RX' ? [pro7WithDec22PerVisit[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jan-23' && session === 'RX' ? [pro7WithJan23PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jan-23' && session === 'RX' ? [pro7WithJan23PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jan-23' && session === 'RX' ? [pro7WithJan23PerVisit[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Feb-23' && session === 'RX' ? [pro7WithFeb23PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Feb-23' && session === 'RX' ? [pro7WithFeb23PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Feb-23' && session === 'RX' ? [pro7WithFeb23PerVisit[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Mar-23' && session === 'RX' ? [pro7WithMar23PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Mar-23' && session === 'RX' ? [pro7WithMar23PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Mar-23' && session === 'RX' ? [pro7WithMar23PerVisit[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Apr-23' && session === 'RX' ? [pro7WithApr23PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Apr-23' && session === 'RX' ? [pro7WithApr23PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Apr-23' && session === 'RX' ? [pro7WithApr23PerVisit[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'May-23' && session === 'RX' ? [pro7WithMay23PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'May-23' && session === 'RX' ? [pro7WithMay23PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'May-23' && session === 'RX' ? [pro7WithMay23PerVisit[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jun-23' && session === 'RX' ? [pro7WithJun23PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jun-23' && session === 'RX' ? [pro7WithJun23PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jun-23' && session === 'RX' ? [pro7WithJun23PerVisit[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Jul-23' && session === 'RX' ? [pro7WithJul23PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Jul-23' && session === 'RX' ? [pro7WithJul23PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Jul-23' && session === 'RX' ? [pro7WithJul23PerVisit[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Aug-23' && session === 'RX' ? [pro7WithAug23PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Aug-23' && session === 'RX' ? [pro7WithAug23PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Aug-23' && session === 'RX' ? [pro7WithAug23PerVisit[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Sep-23' && session === 'RX' ? [pro7WithSep23PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Sep-23' && session === 'RX' ? [pro7WithSep23PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Sep-23' && session === 'RX' ? [pro7WithSep23PerVisit[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Oct-23' && session === 'RX' ? [pro7WithOct23PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Oct-23' && session === 'RX' ? [pro7WithOct23PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Oct-23' && session === 'RX' ? [pro7WithOct23PerVisit[2]]
                : Providers === 'Provider7' && Facility === 'Facility 1' && Month === 'Nov-23' && session === 'RX' ? [pro7WithNov23PerVisit[0]]
                : Providers === 'Provider7' && Facility === 'Facility 2' && Month === 'Nov-23' && session === 'RX' ? [pro7WithNov23PerVisit[1]]
                : Providers === 'Provider7' && Facility === 'Facility 3' && Month === 'Nov-23' && session === 'RX' ? [pro7WithNov23PerVisit[2]]
                : ''
            }
]

    let facilitySeries = [{
        name: 'Charges',
        data: Providers === 'All Providers' && Facility === 'All Facilities' && session === 'RX' ? [...allProviderChargesRX] 
            : Providers === 'All Providers' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(allF1Charge)]
            : Providers === 'All Providers' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(allF2Charge)]
            : Providers === 'All Providers' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(allF3Charge)]
            : Providers === 'Provider1' && Facility === 'All Facilities' && session === 'RX' ? [...provider1Charges] 
            : Providers === 'Provider1' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro1F1Charge)]
            : Providers === 'Provider1' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro1F2Charge)]
            : Providers === 'Provider1' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro1F3Charge)]
            : Providers === 'Provider2' && Facility === 'All Facilities' && session === 'RX' ? [...provider2Charges]
            : Providers === 'Provider2' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro2F1Charge)]
            : Providers === 'Provider2' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro2F2Charge)]
            : Providers === 'Provider2' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro2F3Charge)]
            : Providers === 'Provider3' && Facility === 'All Facilities' && session === 'RX' ? [...provider3Charges]
            : Providers === 'Provider3' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro3F1Charge)]
            : Providers === 'Provider3' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro3F2Charge)]
            : Providers === 'Provider3' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro3F3Charge)]
            : Providers === 'Provider4' && Facility === 'All Facilities' && session === 'RX' ? [...provider4Charges]
            : Providers === 'Provider4' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro4F1Charge)]
            : Providers === 'Provider4' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro4F2Charge)]
            : Providers === 'Provider4' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro4F3Charge)]
            : Providers === 'Provider5' && Facility === 'All Facilities' && session === 'RX' ? [...provider5Charges]
            : Providers === 'Provider5' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro5F1Charge)]
            : Providers === 'Provider5' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro5F2Charge)]
            : Providers === 'Provider5' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro5F3Charge)]
            : Providers === 'Provider6' && Facility === 'All Facilities' && session === 'RX' ? [...provider6Charges]
            : Providers === 'Provider6' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro6F1Charge)]
            : Providers === 'Provider6' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro6F2Charge)]
            : Providers === 'Provider6' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro6F3Charge)]
            : Providers === 'Provider7' && Facility === 'All Facilities' && session === 'RX' ? [...provider7Charges]
            : Providers === 'Provider7' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro7F1Charge)]
            : Providers === 'Provider7' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro7F2Charge)]
            : Providers === 'Provider7' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro7F3Charge)]
            : ""
    },{
        name: 'Payment',
        data: Providers === 'All Providers' && Facility === 'All Facilities' && session === 'RX' ? [...allProviderPaymentRX]
            : Providers === 'All Providers' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(allF1Payment)]
            : Providers === 'All Providers' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(allF2Payment)]
            : Providers === 'All Providers' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(allF3Payment)]
            : Providers === 'Provider1' && Facility === 'All Facilities' && session === 'RX' ? [...provider1Payment]
            : Providers === 'Provider1' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro1F1Payment)]
            : Providers === 'Provider1' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro1F2Payment)]
            : Providers === 'Provider1' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro1F3Payment)]
            : Providers === 'Provider2' && Facility === 'All Facilities' && session === 'RX' ? [...provider2Payment]
            : Providers === 'Provider2' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro2F1Payment)]
            : Providers === 'Provider2' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro2F2Payment)]
            : Providers === 'Provider2' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro2F3Payment)]
            : Providers === 'Provider3' && Facility === 'All Facilities' && session === 'RX' ? [...provider3Payment]
            : Providers === 'Provider3' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro3F1Payment)]
            : Providers === 'Provider3' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro3F2Payment)]
            : Providers === 'Provider3' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro3F3Payment)]
            : Providers === 'Provider4' && Facility === 'All Facilities' && session === 'RX' ? [...provider4Payment]
            : Providers === 'Provider4' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro4F1Payment)]
            : Providers === 'Provider4' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro4F2Payment)]
            : Providers === 'Provider4' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro4F3Payment)]
            : Providers === 'Provider5' && Facility === 'All Facilities' && session === 'RX' ? [...provider5Payment]
            : Providers === 'Provider5' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro5F1Payment)]
            : Providers === 'Provider5' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro5F2Payment)]
            : Providers === 'Provider5' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro5F3Payment)]
            : Providers === 'Provider6' && Facility === 'All Facilities' && session === 'RX' ? [...provider6Payment]
            : Providers === 'Provider6' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro6F1Payment)]
            : Providers === 'Provider6' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro6F2Payment)]
            : Providers === 'Provider6' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro6F3Payment)]
            : Providers === 'Provider7' && Facility === 'All Facilities' && session === 'RX' ? [...provider7Payment]
            : Providers === 'Provider7' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro7F1Payment)]
            : Providers === 'Provider7' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro7F2Payment)]
            : Providers === 'Provider7' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro7F3Payment)]
            : ""
    },{
        name: 'Collectable',
        data: Providers === 'All Providers' && Facility === 'All Facilities' && session === 'RX' ? [...allProviderCollectableRX]
            : Providers === 'All Providers' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(allF1Collectable)]
            : Providers === 'All Providers' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(allF2Collectable)]
            : Providers === 'All Providers' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(allF3Collectable)]
            : Providers === 'Provider1' && Facility === 'All Facilities' && session === 'RX' ? [...provider1Collectable]
            : Providers === 'Provider1' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro1F1Collectable)]
            : Providers === 'Provider1' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro1F2Collectable)]
            : Providers === 'Provider1' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro1F3Collectable)]
            : Providers === 'Provider2' && Facility === 'All Facilities' && session === 'RX' ? [...provider2Collectable]
            : Providers === 'Provider2' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro2F1Collectable)]
            : Providers === 'Provider2' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro2F2Collectable)]
            : Providers === 'Provider2' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro2F3Collectable)]
            : Providers === 'Provider3' && Facility === 'All Facilities' && session === 'RX' ? [...provider3Collectable]
            : Providers === 'Provider3' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro3F1Collectable)]
            : Providers === 'Provider3' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro3F2Collectable)]
            : Providers === 'Provider3' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro3F3Collectable)]
            : Providers === 'Provider4' && Facility === 'All Facilities' && session === 'RX' ? [...provider4Collectable]
            : Providers === 'Provider4' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro4F1Collectable)]
            : Providers === 'Provider4' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro4F2Collectable)]
            : Providers === 'Provider4' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro4F3Collectable)]
            : Providers === 'Provider5' && Facility === 'All Facilities' && session === 'RX' ? [...provider5Collectable]
            : Providers === 'Provider5' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro5F1Collectable)]
            : Providers === 'Provider5' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro5F2Collectable)]
            : Providers === 'Provider5' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro5F3Collectable)]
            : Providers === 'Provider6' && Facility === 'All Facilities' && session === 'RX' ? [...provider6Collectable]
            : Providers === 'Provider6' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro6F1Collectable)]
            : Providers === 'Provider6' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro6F2Collectable)]
            : Providers === 'Provider6' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro6F3Collectable)]
            : Providers === 'Provider7' && Facility === 'All Facilities' && session === 'RX' ? [...provider7Collectable]
            : Providers === 'Provider7' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro7F1Collectable)]
            : Providers === 'Provider7' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro7F2Collectable)]
            : Providers === 'Provider7' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro7F3Collectable)]
            : ""
    },{
        name: 'Non-Collectable',
        data: Providers === 'All Providers' && Facility === 'All Facilities' && session === 'RX' ? [...allProviderNonCollectableRX]
            : Providers === 'All Providers' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(allF1NonCollectable)]
            : Providers === 'All Providers' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(allF2NonCollectable)]
            : Providers === 'All Providers' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(allF3NonCollectable)]
            : Providers === 'Provider1' && Facility === 'All Facilities' && session === 'RX' ? [...provider1NonCollectable]
            : Providers === 'Provider1' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro1F1NonCollectable)]
            : Providers === 'Provider1' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro1F2NonCollectable)]
            : Providers === 'Provider1' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro1F3NonCollectable)]
            : Providers === 'Provider2' && Facility === 'All Facilities' && session === 'RX' ? [...provider2NonCollectable]
            : Providers === 'Provider2' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro2F1NonCollectable)]
            : Providers === 'Provider2' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro2F2NonCollectable)]
            : Providers === 'Provider2' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro2F3NonCollectable)]
            : Providers === 'Provider3' && Facility === 'All Facilities' && session === 'RX' ? [...provider3NonCollectable]
            : Providers === 'Provider3' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro3F1NonCollectable)]
            : Providers === 'Provider3' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro3F2NonCollectable)]
            : Providers === 'Provider3' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro3F3NonCollectable)]
            : Providers === 'Provider4' && Facility === 'All Facilities' && session === 'RX' ? [...provider4NonCollectable]
            : Providers === 'Provider4' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro4F1NonCollectable)]
            : Providers === 'Provider4' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro4F2NonCollectable)]
            : Providers === 'Provider4' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro4F3NonCollectable)]
            : Providers === 'Provider5' && Facility === 'All Facilities' && session === 'RX' ? [...provider5NonCollectable]
            : Providers === 'Provider5' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro5F1NonCollectable)]
            : Providers === 'Provider5' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro5F2NonCollectable)]
            : Providers === 'Provider5' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro5F3NonCollectable)]
            : Providers === 'Provider6' && Facility === 'All Facilities' && session === 'RX' ? [...provider6NonCollectable]
            : Providers === 'Provider6' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro6F1NonCollectable)]
            : Providers === 'Provider6' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro6F2NonCollectable)]
            : Providers === 'Provider6' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro6F3NonCollectable)]
            : Providers === 'Provider7' && Facility === 'All Facilities' && session === 'RX' ? [...provider7NonCollectable]
            : Providers === 'Provider7' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro7F1NonCollectable)]
            : Providers === 'Provider7' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro7F2NonCollectable)]
            : Providers === 'Provider7' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro7F3NonCollectable)]
            : ""
    },{
        name: 'Claim-Count',
        data: Providers === 'All Providers' && Facility === 'All Facilities' && session === 'RX' ? [...allProviderClaimRX]
            : Providers === 'All Providers' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(allF1ClaimCount)]
            : Providers === 'All Providers' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(allF2ClaimCount)]
            : Providers === 'All Providers' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(allF3ClaimCount)]
            : Providers === 'Provider1' && Facility === 'All Facilities' && session === 'RX' ? [...provider1Claim]
            : Providers === 'Provider1' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro1F1ClaimCount)]
            : Providers === 'Provider1' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro1F2ClaimCount)]
            : Providers === 'Provider1' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro1F3ClaimCount)]
            : Providers === 'Provider2' && Facility === 'All Facilities' && session === 'RX' ? [...provider2Claim]
            : Providers === 'Provider2' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro2F1ClaimCount)]
            : Providers === 'Provider2' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro2F2ClaimCount)]
            : Providers === 'Provider2' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro2F3ClaimCount)]
            : Providers === 'Provider3' && Facility === 'All Facilities' && session === 'RX' ? [...provider3Claim]
            : Providers === 'Provider3' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro3F1ClaimCount)]
            : Providers === 'Provider3' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro3F2ClaimCount)]
            : Providers === 'Provider3' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro3F3ClaimCount)]
            : Providers === 'Provider4' && Facility === 'All Facilities' && session === 'RX' ? [...provider4Claim]
            : Providers === 'Provider4' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro4F1ClaimCount)]
            : Providers === 'Provider4' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro4F2ClaimCount)]
            : Providers === 'Provider4' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro4F3ClaimCount)]
            : Providers === 'Provider5' && Facility === 'All Facilities' && session === 'RX' ? [...provider5Claim]
            : Providers === 'Provider5' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro5F1ClaimCount)]
            : Providers === 'Provider5' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro5F2ClaimCount)]
            : Providers === 'Provider5' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro5F3ClaimCount)]
            : Providers === 'Provider6' && Facility === 'All Facilities' && session === 'RX' ? [...provider6Claim]
            : Providers === 'Provider6' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro6F1ClaimCount)]
            : Providers === 'Provider6' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro6F2ClaimCount)]
            : Providers === 'Provider6' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro6F3ClaimCount)]
            : Providers === 'Provider7' && Facility === 'All Facilities' && session === 'RX' ? [...provider7Claim]
            : Providers === 'Provider7' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro7F1ClaimCount)]
            : Providers === 'Provider7' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro7F2ClaimCount)]
            : Providers === 'Provider7' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro7F3ClaimCount)]
            : ""
    },{
        name: 'Per-Visit',
        data: Providers === 'All Providers' && Facility === 'All Facilities' && session === 'RX' ? [...allProviderPervisitRX]
            : Providers === 'All Providers' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(allF1PerVisit)]
            : Providers === 'All Providers' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(allF2PerVisit)]
            : Providers === 'All Providers' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(allF3PerVisit)]
            : Providers === 'Provider1' && Facility === 'All Facilities' && session === 'RX' ? [...provider1PerVisist]
            : Providers === 'Provider1' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro1F1PerVisit)]
            : Providers === 'Provider1' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro1F2PerVisit)]
            : Providers === 'Provider1' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro1F3PerVisit)]
            : Providers === 'Provider2' && Facility === 'All Facilities' && session === 'RX' ? [...provider2PerVisist]
            : Providers === 'Provider2' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro2F1PerVisit)]
            : Providers === 'Provider2' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro2F2PerVisit)]
            : Providers === 'Provider2' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro2F3PerVisit)]
            : Providers === 'Provider3' && Facility === 'All Facilities' && session === 'RX' ? [...provider3PerVisist]
            : Providers === 'Provider3' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro3F1PerVisit)]
            : Providers === 'Provider3' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro3F2PerVisit)]
            : Providers === 'Provider3' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro3F3PerVisit)]
            : Providers === 'Provider4' && Facility === 'All Facilities' && session === 'RX' ? [...provider4PerVisist]
            : Providers === 'Provider4' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro4F1PerVisit)]
            : Providers === 'Provider4' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro4F2PerVisit)]
            : Providers === 'Provider4' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro4F3PerVisit)]
            : Providers === 'Provider5' && Facility === 'All Facilities' && session === 'RX' ? [...provider5PerVisist]
            : Providers === 'Provider5' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro5F1PerVisit)]
            : Providers === 'Provider5' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro5F2PerVisit)]
            : Providers === 'Provider5' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro5F3PerVisit)]
            : Providers === 'Provider6' && Facility === 'All Facilities' && session === 'RX' ? [...provider6PerVisist]
            : Providers === 'Provider6' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro6F1PerVisit)]
            : Providers === 'Provider6' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro6F2PerVisit)]
            : Providers === 'Provider6' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro6F3PerVisit)]
            : Providers === 'Provider7' && Facility === 'All Facilities' && session === 'RX' ? [...provider7PerVisist]
            : Providers === 'Provider7' && Facility === 'Facility 1' && session === 'RX' ? [...Object.values(pro7F1PerVisit)]
            : Providers === 'Provider7' && Facility === 'Facility 2' && session === 'RX' ? [...Object.values(pro7F2PerVisit)]
            : Providers === 'Provider7' && Facility === 'Facility 3' && session === 'RX' ? [...Object.values(pro7F3PerVisit)]
            : ""
    }]


    let series = [{
        name: 'Sum of Charges',
        data: Providers === 'All Providers' && session === 'Aurora' ? [...allCharges,...allChargesAver]
            : Providers === 'All Providers' && session === 'PersonalClinic' ? [...allChargesSAM]
                : Providers === 'All Providers' && session === 'RX' ? [...allProviderChargesRX]
                    : Providers === 'Provider1' && session === 'RX' ? [...provider1Charges]
                        : Providers === 'Provider2' && session === 'RX' ? [...provider2Charges]
                            : Providers === 'Provider3' && session === 'RX' ? [...provider3Charges]
                                : Providers === 'Provider4' && session === 'RX' ? [...provider4Charges]
                                    : Providers === 'Provider5' && session === 'RX' ? [...provider5Charges]
                                        : Providers === 'Provider6' && session === 'RX' ? [...provider6Charges]
                                            : Providers === 'Provider7' && session === 'RX' ? [...provider7Charges]
                                                : Providers === 'Cha, Hyojoon' && session === 'PersonalClinic' ? [...pro1Charges]
                                                    : Providers === 'Desai, Manish' && session === 'PersonalClinic' ? [...pro2Charges]
                                                        : Providers === 'Jain, Sheeja' && session === 'PersonalClinic' ? [...pro3Charges]
                                                            : Providers === 'Jain, Shishir' && session === 'PersonalClinic' ? [...pro4Charges]
                                                                : Providers === 'Kraft, Norma' && session === 'PersonalClinic' ? [...pro5Charges]
                                                                    : Providers === 'Mehta, Mukundini' && session === 'PersonalClinic' ? [...pro6Charges]
                                                                        : ''
    }, {
        name: 'Sum of Payments',
        data: Providers === 'All Providers' && session === 'Aurora' ? [...allPayments,...allPaymentsAver]
            : Providers === 'All Providers' && session === 'PersonalClinic' ? [...allPaymentsSAM]
                : Providers === 'All Providers' && session === 'RX' ? [...allProviderPaymentRX]
                    : Providers === 'Provider1' && session === 'RX' ? [...provider1Payment]
                        : Providers === 'Provider2' && session === 'RX' ? [...provider2Payment]
                            : Providers === 'Provider3' && session === 'RX' ? [...provider3Payment]
                                : Providers === 'Provider4' && session === 'RX' ? [...provider4Payment]
                                    : Providers === 'Provider5' && session === 'RX' ? [...provider5Payment]
                                        : Providers === 'Provider6' && session === 'RX' ? [...provider6Payment]
                                            : Providers === 'Provider7' && session === 'RX' ? [...provider7Payment]
                                                : Providers === 'Cha, Hyojoon' && session === 'PersonalClinic' ? [...pro1Payments]
                                                    : Providers === 'Desai, Manish' && session === 'PersonalClinic' ? [...pro2Payments]
                                                        : Providers === 'Jain, Sheeja' && session === 'PersonalClinic' ? [...pro3Payments]
                                                            : Providers === 'Jain, Shishir' && session === 'PersonalClinic' ? [...pro4Payments]
                                                                : Providers === 'Kraft, Norma' && session === 'PersonalClinic' ? [...pro5Payments]
                                                                    : Providers === 'Mehta, Mukundini' && session === 'PersonalClinic' ? [...pro6Payments]
                                                                        : ''
    }, {
        name: 'Sum of  Collectable Adjustments',
        data: Providers === 'All Providers' && session === 'Aurora' ? [...allCollect,...allCollectAver]
            : Providers === 'All Providers' && session === 'PersonalClinic' ? [...allCollectSAM]
                : Providers === 'All Providers' && session === 'RX' ? [...allProviderCollectableRX]
                    : Providers === 'Provider1' && session === 'RX' ? [...provider1Collectable]
                        : Providers === 'Provider2' && session === 'RX' ? [...provider2Collectable]
                            : Providers === 'Provider3' && session === 'RX' ? [...provider3Collectable]
                                : Providers === 'Provider4' && session === 'RX' ? [...provider4Collectable]
                                    : Providers === 'Provider5' && session === 'RX' ? [...provider5Collectable]
                                        : Providers === 'Provider6' && session === 'RX' ? [...provider6Collectable]
                                            : Providers === 'Provider7' && session === 'RX' ? [...provider7Collectable]
                                                : Providers === 'Cha, Hyojoon' && session === 'PersonalClinic' ? [...pro1Collect]
                                                    : Providers === 'Desai, Manish' && session === 'PersonalClinic' ? [...pro2Collect]
                                                        : Providers === 'Jain, Sheeja' && session === 'PersonalClinic' ? [...pro3Collect]
                                                            : Providers === 'Jain, Shishir' && session === 'PersonalClinic' ? [...pro4Collect]
                                                                : Providers === 'Kraft, Norma' && session === 'PersonalClinic' ? [...pro5Collect]
                                                                    : Providers === 'Mehta, Mukundini' && session === 'PersonalClinic' ? [...pro6Collect]
                                                                        : ''
    }, {
        name: 'Sum of None-Collectable Adjustments',
        data: Providers === 'All Providers' && session === 'Aurora' ? [...allnonCollect,...allnonCollectAver]
            : Providers === 'All Providers' && session === 'PersonalClinic' ? [...allnonCollectSAM]
                : Providers === 'All Providers' && session === 'RX' ? [...allProviderNonCollectableRX]
                    : Providers === 'Provider1' && session === 'RX' ? [...provider1NonCollectable]
                        : Providers === 'Provider2' && session === 'RX' ? [...provider2NonCollectable]
                            : Providers === 'Provider3' && session === 'RX' ? [...provider3NonCollectable]
                                : Providers === 'Provider4' && session === 'RX' ? [...provider4NonCollectable]
                                    : Providers === 'Provider5' && session === 'RX' ? [...provider5NonCollectable]
                                        : Providers === 'Provider6' && session === 'RX' ? [...provider6NonCollectable]
                                            : Providers === 'Provider7' && session === 'RX' ? [...provider7NonCollectable]
                                                : Providers === 'Cha, Hyojoon' && session === 'PersonalClinic' ? [...pro1NonCollect]
                                                    : Providers === 'Desai, Manish' && session === 'PersonalClinic' ? [...pro2NonCollect]
                                                        : Providers === 'Jain, Sheeja' && session === 'PersonalClinic' ? [...pro3NonCollect]
                                                            : Providers === 'Jain, Shishir' && session === 'PersonalClinic' ? [...pro4NonCollect]
                                                                : Providers === 'Kraft, Norma' && session === 'PersonalClinic' ? [...pro5NonCollect]
                                                                    : Providers === 'Mehta, Mukundini' && session === 'PersonalClinic' ? [...pro6NonCollect]
                                                                        : ''
    }, {
        name: 'Sum of Claim Count',
        data: Providers === 'All Providers' && session === 'Aurora' ? [...allClaims,...allClaimsAver]
            : Providers === 'All Providers' && session === 'PersonalClinic' ? [...allClaimsSAM]
                : Providers === 'All Providers' && session === 'RX' ? [...allProviderClaimRX]
                    : Providers === 'Provider1' && session === 'RX' ? [...provider1Claim]
                        : Providers === 'Provider2' && session === 'RX' ? [...provider2Claim]
                            : Providers === 'Provider3' && session === 'RX' ? [...provider3Claim]
                                : Providers === 'Provider4' && session === 'RX' ? [...provider4Claim]
                                    : Providers === 'Provider5' && session === 'RX' ? [...provider5Claim]
                                        : Providers === 'Provider6' && session === 'RX' ? [...provider6Claim]
                                            : Providers === 'Provider7' && session === 'RX' ? [...provider7Claim]
                                                : Providers === 'Cha, Hyojoon' && session === 'PersonalClinic' ? [...pro1Claims]
                                                    : Providers === 'Desai, Manish' && session === 'PersonalClinic' ? [...pro2Claims]
                                                        : Providers === 'Jain, Sheeja' && session === 'PersonalClinic' ? [...pro3Claims]
                                                            : Providers === 'Jain, Shishir' && session === 'PersonalClinic' ? [...pro4Claims]
                                                                : Providers === 'Kraft, Norma' && session === 'PersonalClinic' ? [...pro5Claims]
                                                                    : Providers === 'Mehta, Mukundini' && session === 'PersonalClinic' ? [...pro6Claims]
                                                                        : ''
    }, {
        name: 'Sum of  Per Visit',
        data: Providers === 'All Providers' && session === 'Aurora' ? [...allVisit,...allVisitAver]
            : Providers === 'All Providers' && session === 'PersonalClinic' ? [...allVisitSAM]
                : Providers === 'All Providers' && session === 'RX' ? [...allProviderPervisitRX]
                    : Providers === 'Provider1' && session === 'RX' ? [...provider1PerVisist]
                        : Providers === 'Provider2' && session === 'RX' ? [...provider2PerVisist]
                            : Providers === 'Provider3' && session === 'RX' ? [...provider3PerVisist]
                                : Providers === 'Provider4' && session === 'RX' ? [...provider4PerVisist]
                                    : Providers === 'Provider5' && session === 'RX' ? [...provider5PerVisist]
                                        : Providers === 'Provider6' && session === 'RX' ? [...provider6PerVisist]
                                            : Providers === 'Provider7' && session === 'RX' ? [...provider7PerVisist]
                                                : Providers === 'Cha, Hyojoon' && session === 'PersonalClinic' ? [...pro1Visit]
                                                    : Providers === 'Desai, Manish' && session === 'PersonalClinic' ? [...pro2Visit]
                                                        : Providers === 'Jain, Sheeja' && session === 'PersonalClinic' ? [...pro3Visit]
                                                            : Providers === 'Jain, Shishir' && session === 'PersonalClinic' ? [...pro4Visit]
                                                                : Providers === 'Kraft, Norma' && session === 'PersonalClinic' ? [...pro5Visit]
                                                                    : Providers === 'Mehta, Mukundini' && session === 'PersonalClinic' ? [...pro6Visit]
                                                                        : ''
    }
    ]
    // let quartersSeries = [{

    // }]
    //1st quarter
// console.log("provider : ",Providers );
// console.log("facility : ",Facility );
    var options = {
        chart: {
            type: 'bar',
            height: 450,
            stacked: true,
        },
        plotOptions: {
            bar: {
                horizontal: changeBar,
            },
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        dataLabels:{
            enabled:false
        },
        title: {
            text: Providers === 'All Providers' && session === 'PersonalClinic' ? 'All Providers'
                : Providers === 'Cha, Hyojoon' && session === 'PersonalClinic' ? 'Cha, Hyojoon'
                    : Providers === 'Desai, Manish' && session === 'PersonalClinic' ? 'Desai, Manish '
                        : Providers === 'Jain, Sheeja' && session === 'PersonalClinic' ? 'Jain, Sheeja '
                            : Providers === 'Jain, Shishir' && session === 'PersonalClinic' ? 'Jain, Shishir'
                                : Providers === 'Kraft, Norma' && session === 'PersonalClinic' ? 'Kraft, Norma'
                                    : Providers === 'Mehta, Mukundini' && session === 'PersonalClinic' ? 'Mehta, Mukundini'// from aug 
                                        : Providers === 'All Providers' && session === 'RX' ? 'All Providers' 
                                        // : Providers === 'All Providers' && Facility === 'Facility 1' && session === 'RX' ? 'Facility 1' 
                                        // : Providers === 'All Providers' && Facility === 'Facility 2' && session === 'RX' ? 'Facility-2'
                                        // : Providers === 'All Providers' && Facility === 'Facility 3' && session === 'RX' ? 'Facility-3'  
                                            : Providers === 'Provider1' && session === 'RX' ? 'Provider 1' 
                                            // : Providers === 'Provider1' && Facility === 'Facility 1' && session === 'RX' ? 'Facility-1 in Providers 1'
                                            // : Providers === 'Provider1' && Facility === 'Facility 2' && session === 'RX' ? 'Facility-2 in Providers 1'
                                            // : Providers === 'Provider1' && Facility === 'Facility 3' && session === 'RX' ? 'Facility-3 in Providers 1' 
                                                : Providers === 'Provider2' && session === 'RX' ? 'Provider 2' 
                                                // : Providers === 'Provider2' && Facility === 'Facility 1' && session === 'RX' ? 'Facility-1 in Providers 2'
                                                // : Providers === 'Provider2' && Facility === 'Facility 2' && session === 'RX' ? 'Facility-2 in Providers 2'
                                                // : Providers === 'Provider2' && Facility === 'Facility 3' && session === 'RX' ? 'Facility-3 in Providers 2' 
                                                    : Providers === 'Provider3' && session === 'RX' ? 'Provider 3' 
                                                    // : Providers === 'Provider3' && Facility === 'Facility 1' && session === 'RX' ? 'Facility-1 in Providers 3'
                                                    // : Providers === 'Provider3' && Facility === 'Facility 2' && session === 'RX' ? 'Facility-2 in Providers 3'
                                                    // : Providers === 'Provider3' && Facility === 'Facility 3' && session === 'RX' ? 'Facility-3 in Providers 3' 
                                                        : Providers === 'Provider4' && session === 'RX' ? 'Provider 4' 
                                                        // : Providers === 'Provider4' && Facility === 'Facility 1' && session === 'RX' ? 'Facility-1 in Providers 4'
                                                        // : Providers === 'Provider4' && Facility === 'Facility 2' && session === 'RX' ? 'Facility-2 in Providers 4'
                                                        // : Providers === 'Provider4' && Facility === 'Facility 3' && session === 'RX' ? 'Facility-3 in Providers 4' 
                                                            : Providers === 'Provider5' && session === 'RX' ? 'Provider 5' 
                                                            // : Providers === 'Provider5' && Facility === 'Facility 1' && session === 'RX' ? 'Facility-1 in Providers 5'
                                                            // : Providers === 'Provider5' && Facility === 'Facility 2' && session === 'RX' ? 'Facility-2 in Providers 5'
                                                            // : Providers === 'Provider5' && Facility === 'Facility 3' && session === 'RX' ? 'Facility-3 in Providers 5' 
                                                                : Providers === 'Provider6' && session === 'RX' ? 'Provider6' 
                                                                // : Providers === 'Provider6' && Facility === 'Facility 1' && session === 'RX' ? 'Facility-1 in Providers 6'
                                                                // : Providers === 'Provider6' && Facility === 'Facility 2' && session === 'RX' ? 'Facility-2 in Providers 6'
                                                                // : Providers === 'Provider6' && Facility === 'Facility 3' && session === 'RX' ? 'Facility-3 in Providers 6' 
                                                                    : Providers === 'Provider7' && session === 'RX' ? 'Provider 7' 
                                                                    // : Providers === 'Provider7' && Facility === 'Facility 1' && session === 'RX' ? 'Facility-1 in Providers 7'
                                                                    // : Providers === 'Provider7' && Facility === 'Facility 2' && session === 'RX' ? 'Facility-2 in Providers 7'
                                                                    // : Providers === 'Provider7' && Facility === 'Facility 3' && session === 'RX' ? 'Facility-3 in Providers 7' 
                                                                    : 'Dr Ramesh Bathina'
        },
        xaxis: {
            categories: session === 'Aurora' ? ['Total','Average'] : session === 'PersonalClinic' ? [
                // 'Oct-2020', 'Nov-2020', 'Dec-2020', 'Jan 2021', 'Feb 2021', 'Mar 2021',
                // 'Apr 2021', 'May 2021', 'June 2021', 'Jul 2021',
                // 'Aug 2021',
                // 'Sep 2021', 'Oct 2021',
                // 'Nov 2021', 'Dec 2021',
                'Jan 2022', 'Feb 2022',
                'Mar 2022', 'Apr 2022', 'May 2022', 'Jun 2022', 'Jul-2022', 'Aug-2022'
                , 'Sept-2022', 'Oct-2022', 'Nov-2022', 'Dec-2022', 'Jan-2023', 'Feb-2023', 'Mar-2023', 'Apr-2023', 'May-2023', 'Jun-2023', 'Jul-2023','Aug-2023','Sep-2023','Oct-2023','Nov-2023','Dec-2023','Jan-2024','Feb-2024','Mar-2024','Apr-2024'
                ,'May-2024','Jun-2024','Jul-2024','Aug-2024'] :
                ['Jan 2021', 'Feb 2021', 'Mar 2021',
                    'Apr 2021', 'May 2021', 'June 2021', 'Jul 2021',
                    'Aug 2021',
                    'Sep 2021', 'Oct 2021',
                    'Nov 2021', 'Dec 2021',
                    'Jan 2022', 'Feb 2022',
                    'Mar 2022', 'Apr 2022', 'May 2022', 'Jun 2022', 'Jul-2022', 'Aug-2022'
                    , 'Sept-2022', 'Oct-2022', 'Nov-2022', 'Dec-2022', 'Jan-2023', 'Feb-2023', 'Mar-2023', 'Apr-2023',
                    'May-2023', 'Jun-2023', 'Jul-2023', 'Aug-2023', 'Sep-2023', 'Oct-2023', 'Nov-2023'],
            labels: {
                formatter: function (val) {
                    return val
                }
            },
        },
        yaxis: {
            title: {
                text: undefined
            },
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val
                }
            }
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
        }
    };




    let proValues = sessionStorage.getItem('name') === 'PersonalClinic' ?
        ['Select Provider',
            'All Providers',
            'Cha, Hyojoon',
            'Desai, Manish',
            'Jain, Sheeja',
            'Jain, Shishir',
            'Kraft, Norma',
        'Mehta, Mukundini'] : ['Select Provider',
            'All Providers',
            'Provider1',
            'Provider2',
            'Provider3',
            'Provider4',
            'Provider5',
            'Provider6',
            'Provider7',

        ]

        // New update for the new facility section in november.
        // console.log(`provider : {roiders} , Facility : {aclity} , session : {esion}`);
        // console.log(Providers === 'All Providers' && Facility === 'Facility 1' && session === 'RX')
        var facilityOptions = {
            chart: {
                type: 'bar',
                height: 450,
                stacked: true,
            },
            plotOptions: {
                bar: {
                    horizontal: changeBar,
                },
            },
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            dataLabels:{
                enabled:false
            },
            title: {                // : Facility === 'All Facilities' && session === 'RX' ? 'All Facilities'
                text: Providers === 'All Providers' && Facility === 'Facility 1' && session === 'RX' ? 'Facility-1 in All Providers'
                        : Providers === 'All Providers' && Facility === 'Facility 2' && session === 'RX' ? 'Facility-2 in All Providers'
                            : Providers === 'All Providers' && Facility === 'Facility 3' && session === 'RX' ? 'Facility-3 in All Providers'
                                :  Providers === 'Provider1' && Facility === 'Facility 1' && session === 'RX' ? 'Facility-1 in Providers 1'
                                    :  Providers === 'Provider1' && Facility === 'Facility 2' && session === 'RX' ? 'Facility-2 in Providers 1'
                                    :  Providers === 'Provider1' && Facility === 'Facility 3' && session === 'RX' ? 'Facility-3 in Providers 1'
                                    :  Providers === 'Provider2' && Facility === 'Facility 1' && session === 'RX' ? 'Facility-1 in Providers 2'
                                    :  Providers === 'Provider2' && Facility === 'Facility 2' && session === 'RX' ? 'Facility-2 in Providers 2'
                                    :  Providers === 'Provider2' && Facility === 'Facility 3' && session === 'RX' ? 'Facility-3 in Providers 2'
                                    :  Providers === 'Provider3' && Facility === 'Facility 1' && session === 'RX' ? 'Facility-1 in Providers 3'
                                    :  Providers === 'Provider3' && Facility === 'Facility 2' && session === 'RX' ? 'Facility-2 in Providers 3'
                                    :  Providers === 'Provider3' && Facility === 'Facility 3' && session === 'RX' ? 'Facility-3 in Providers 3'
                                    :  Providers === 'Provider4' && Facility === 'Facility 1' && session === 'RX' ? 'Facility-1 in Providers 4'
                                    :  Providers === 'Provider4' && Facility === 'Facility 2' && session === 'RX' ? 'Facility-2 in Providers 4'
                                    :  Providers === 'Provider4' && Facility === 'Facility 3' && session === 'RX' ? 'Facility-3 in Providers 4'
                                    :  Providers === 'Provider5' && Facility === 'Facility 1' && session === 'RX' ? 'Facility-1 in Providers 5'
                                    :  Providers === 'Provider5' && Facility === 'Facility 2' && session === 'RX' ? 'Facility-2 in Providers 5'
                                    :  Providers === 'Provider5' && Facility === 'Facility 3' && session === 'RX' ? 'Facility-3 in Providers 5'
                                    :  Providers === 'Provider6' && Facility === 'Facility 1' && session === 'RX' ? 'Facility-1 in Providers 6'
                                    :  Providers === 'Provider6' && Facility === 'Facility 2' && session === 'RX' ? 'Facility-2 in Providers 6'
                                    :  Providers === 'Provider6' && Facility === 'Facility 3' && session === 'RX' ? 'Facility-3 in Providers 6'
                                    :  Providers === 'Provider7' && Facility === 'Facility 1' && session === 'RX' ? 'Facility-1 in Providers 7'
                                    :  Providers === 'Provider7' && Facility === 'Facility 2' && session === 'RX' ? 'Facility-2 in Providers 7'
                                    :  Providers === 'Provider7' && Facility === 'Facility 3' && session === 'RX' ? 'Facility-3 in Providers 7'
                                :   ''

            },
            xaxis: {
                categories: session === 'Aurora' || session === 'PersonalClinic' ? '' : [
                    'Jan 2021',
                    'Feb 2021',
                    'Mar 2021',
                    'Apr 2021',
                    'May 2021',
                    'Jun 2021',
                    'Jul 2021',
                    'Aug 2021',
                    'Sep 2021',
                    'Oct 2021',
                    'Nov 2021',
                    'Dec 2021',
                    'Jan 2022',
                    'Feb 2022',
                    'Mar 2022',
                    'Apr 2022',
                    'May 2022',
                    'Jun 2022',
                    'Jul 2022',
                    'Aug 2022',
                    'Sep 2022',
                    'Oct 2022',
                    'Nov 2022',
                    'Dec 2022',
                    'Jan 2023',
                    'Feb 2023',
                    'Mar 2023',
                    'Apr 2023',
                    'May 2023',
                    'Jun 2023',
                    'Jul 2023',
                    'Aug 2023',
                    'Sep 2023',
                    'Oct 2023',
                    'Nov 2023'

                ],
                labels: {
                    formatter: function (val) {
                        return val
                    }
                }
            },
            yaxis: {
                title: {
                    text: undefined
                },
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            },
            fill: {
                opacity: 1
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left',
            }
        };
        
        let facilityValues = sessionStorage.getItem('name') === 'RX' ?
        ['Select Facility',
        'All Facilities',
        'Facility 1',
        'Facility 2',
        'Facility 3'
            // 'All Facilities',
            // 'Facility-1 in All Providers',
            // 'Facility-2 in All Providers',
            // 'Facility-3 in All Providers',
            // 'Facility-1 in Providers 1',
            // 'Facility-2 in Providers 1',
            // 'Facility-3 in Providers 1',
            // 'Facility-1 in Providers 2',
            // 'Facility-2 in Providers 2',
            // 'Facility-3 in Providers 2',
            // 'Facility-1 in Providers 3',
            // 'Facility-2 in Providers 3',
            // 'Facility-3 in Providers 3',
            // 'Facility-1 in Providers 4',
            // 'Facility-2 in Providers 4',
            // 'Facility-3 in Providers 4',
            // 'Facility-1 in Providers 5',
            // 'Facility-2 in Providers 5',
            // 'Facility-3 in Providers 5',
            // 'Facility-1 in Providers 6',
            // 'Facility-2 in Providers 6',
            // 'Facility-3 in Providers 6',
            // 'Facility-1 in Providers 7',
            // 'Facility-2 in Providers 7',
            // 'Facility-3 in Providers 7'
        ] : '' 
        
        
        


    var optionsForMOnth = {
        chart: {
            type: 'bar',
            height: 450,
            stacked: true,
        },
        plotOptions: {
            bar: {
                horizontal: changeBar,
            },
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        title: {
            text: Month === 'Jan-20' ? 'January 2020'
                : Month === 'Feb-20' ? 'February 2020'
                    : Month === 'Mar-20' ? 'March 2020'
                        : Month === 'Apr-20' ? 'April 2020'
                            : Month === 'May-20' ? 'May 2020'
                                : Month === 'Jun-20' ? 'June 2020'
                                    : Month === 'Jul-20' ? 'July 2020'
                                        : Month === 'Aug-20' ? 'August 2020'
                                            : Month === 'Sep-20' ? 'September 2020'
                                                : Month === 'Oct-20' ? 'October 2020'
                                                    : Month === 'Nov-20' ? 'November 2020'
                                                        : Month === 'Dec-20' ? 'December 2020'
                                                            : Month === 'Jan-21' ? 'January 2021'
                                                                : Month === 'Feb-21' ? 'February 2021'
                                                                    : Month === 'Mar-21' ? 'March 2021'
                                                                        : Month === 'Apr-21' ? 'April 2021'
                                                                            : Month === 'May-21' ? 'May 2021'
                                                                                : Month === 'Jun-21' ? 'June 2021'
                                                                                    : Month === 'Jul-21' ? 'July 2021'
                                                                                        : Month === 'Aug-21' ? 'August 2021'
                                                                                            : Month === 'Sep-21' ? 'September 2021'
                                                                                                : Month === 'Oct-21' ? 'October 2021'
                                                                                                    : Month === 'Nov-21' ? 'November 2021'
                                                                                                        : Month === 'Dec-21' ? 'December 2021'
                                                                                                            : Month === 'Jan-22' ? 'January 2022'
                                                                                                                : Month === 'Feb-22' ? 'February 2022'
                                                                                                                    : Month === 'Mar-22' ? 'March 2022'
                                                                                                                        : Month === 'Apr-22' ? 'April 2022'
                                                                                                                            : Month === 'May-22' ? 'May 2022'
                                                                                                                                : Month === 'Jun-22' ? 'June 2022'
                                                                                                                                    : Month === 'Jul-22' ? 'July 2022'
                                                                                                                                        : Month === 'Aug-22' ? 'August 2022'
                                                                                                                                            : Month === 'Sep-22' ? 'September 2022'
                                                                                                                                                : Month === 'Oct-22' ? 'October 2022'
                                                                                                                                                    : Month === 'Nov-22' ? 'November 2022'
                                                                                                                                                        : Month === 'Dec-22' ? 'December 2022'
                                                                                                                                                            : Month === 'Jan-23' ? 'January 2023' :
                                                                                                                                                                Month === 'Feb-23' ? 'February 2023' :
                                                                                                                                                                    Month === 'Mar-23' ? 'March 2023' :
                                                                                                                                                                        Month === 'Apr-23' ? 'April 2023' :
                                                                                                                                                                            Month === 'May-23' ? 'May 2023' :
                                                                                                                                                                                Month === 'Jun-23' ? 'June 2023' :
                                                                                                                                                                                    Month === 'Jul-23' ? 'July 2023' :
                                                                                                                                                                                        Month === 'Aug-23' ? 'August 2023' :
                                                                                                                                                                                            Month === 'Sep-23' ? 'September 2023' :
                                                                                                                                                                                                Month === 'Oct-23' ? 'October 2023' :
                                                                                                                                                                                                    Month === 'Nov-23' ? 'November 2023' :
                                                                                                                                                                                                        Month === 'Dec-23' ? 'December 2023' :
                                                                                                                                                                                                            Month === 'Jan-24' ? 'January 2024' :
                                                                                                                                                                                                                Month === 'Feb-24' ? 'February 2024' :
                                                                                                                                                                                                                    Month === 'Mar-24' ? 'March 2024' :
                                                                                                                                                                                                                        Month === 'Apr-24' ? 'April 2024' :
                                                                                                                                                                                                                            Month === 'May-24' ? 'May 2024' :
                                                                                                                                                                                                                                Month === 'Jun-24' ? 'June 2024' :
                                                                                                                                                                                                                                    Month === 'Jul-24' ? 'July 2024' :
                                                                                                                                                                                                                                        Month === 'Aug-24' ? 'August 2024' :
                                                                                                                                                                                                                                        ''

        },
        xaxis: {
            categories: session === 'Aurora' ? [
                Month
            ] : session === 'PersonalClinic' ? [
                'Cha, Hyojoon',
                'Desai, Manish',
                'Jain, Sheeja',
                'Jain, Shishir',
                'Kraft, Norma',
                'Mehta, Mukundini'
            ] : [
                'Provider1',
                'Provider2',
                'Provider3',
                'Provider4',
                'Provider5',
                'Provider6',
                'Provider7'
            ],
            labels: {
                formatter: function (val) {
                    return val
                }
            },
        },
        yaxis: {
            title: {
                text: undefined
            },
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val
                }
            }
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
        }
    }

    // provider and month

    var optionsForProviderWithMonth = {
        chart: {
            type: 'bar',
            height: 450,
            stacked: true,
        },
        plotOptions: {
            bar: {
                horizontal: changeBar,
            },
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        dataLabels:{
            enabled:false
        },
        title: {
            text: Providers === 'All Providers' && Month === 'Jan-20' ? 'All providers in January 2020'
                : Providers === 'All Providers' && Month === 'Feb-20' ? 'All providers in February 2020'
                    : Providers === 'All Providers' && Month === 'Mar-20' ? 'All providers in March 2020'
                        : Providers === 'All Providers' && Month === 'Apr-20' ? 'All providers in April 2020'
                            : Providers === 'All Providers' && Month === 'May-20' ? 'All providers in May 2020' 
                                : Providers === 'All Providers' && Month === 'Jun-20' ? 'All providers in June 2020'
                                    : Providers === 'All Providers' && Month === 'Jul-20' ? 'All providers in July 2020'
                                        : Providers === 'All Providers' && Month === 'Aug-20' ? 'All providers in August 2020'
                                            : Providers === 'All Providers' && Month === 'Sep-20' ? 'All providers in September 2020'
                                                : Providers === 'All Providers' && Month === 'Oct-20' ? 'All providers in October 2020'
                                                    : Providers === 'All Providers' && Month === 'Nov-20' ? 'All providers in November 2020'
                                                        : Providers === 'All Providers' && Month === 'Dec-20' ? 'All providers in December 2020'
                                                            : Providers === 'All Providers' && Month === 'Jan-21' ? 'All providers in January-2021'
                                                                : Providers === 'All Providers' && Month === 'Feb-21' ? 'All providers in February-2021'
                                                                    : Providers === 'All Providers' && Month === 'Mar-21' ? 'All providers in March-2021'
                                                                        : Providers === 'All Providers' && Month === 'Apr-21' ? 'All providers in April-2021'
                                                                            : Providers === 'All Providers' && Month === 'May-21' ? 'All providers in May-2021'
                                                                                : Providers === 'All Providers' && Month === 'Jun-21' ? 'All providers in June-2021'
                                                                                    : Providers === 'All Providers' && Month === 'Jul-21' ? 'All providers in July-2021'
                                                                                        : Providers === 'All Providers' && Month === 'Aug-21' ? 'All providers in August-2021'
                                                                                            : Providers === 'All Providers' && Month === 'Sep-21' ? 'All providers in September-2021'
                                                                                                : Providers === 'All Providers' && Month === 'Oct-21' ? 'All providers in October-2021'
                                                                                                    : Providers === 'All Providers' && Month === 'Nov-21' ? 'All providers in November-2021'
                                                                                                        : Providers === 'All Providers' && Month === 'Dec-21' ? 'All providers in December-2021'
                                                                                                            : Providers === 'All Providers' && Month === 'Jan-22' ? 'All providers in January-2022'
                                                                                                                : Providers === 'All Providers' && Month === 'Feb-22' ? 'All providers in February-2022'
                                                                                                                    : Providers === 'All Providers' && Month === 'Mar-22' ? 'All providers in March-2022'  
                                                                                                                        : Providers === 'All Providers' && Month === 'Apr-22' ? 'All providers in April-2022'
                                                                                                                            : Providers === 'All Providers' && Month === 'May-22' ? 'All providers in May-2022'
                                                                                                                                : Providers === 'All Providers' && Month === 'Jun-22' ? 'All providers in June-2022'
                                                                                                                                    : Providers === 'All Providers' && Month === 'Jul-22' ? 'All providers in July-2022'
                                                                                                                                        : Providers === 'All Providers' && Month === 'Aug-22' ? 'All providers in August-2022'
                                                                                                                                            : Providers === 'All Providers' && Month === 'Sep-22' ? 'All providers in September-2022'
                                                                                                                                                : Providers === 'All Providers' && Month === 'Oct-22' ? 'All providers in October-2022'
                                                                                                                                                    : Providers === 'All Providers' && Month === 'Nov-22' ? 'All providers in November-2022'
                                                                                                                                                        : Providers === 'All Providers' && Month === 'Dec-22' ? 'All providers in December-2022' :
                                                                                                                                                            Providers === 'All Providers' && Month === 'Jan-23' ? 'All providers in January-2023' :
                                                                                                                                                            Providers === 'All Providers' && Month === 'Feb-23' ? 'All providers in February-2023' :
                                                                                                                                                            Providers === 'All Providers' && Month === 'Mar-23' ? 'All providers in March-2023' :
                                                                                                                                                            Providers === 'All Providers' && Month === 'Apr-23' ? 'All providers in April-2023' :
                                                                                                                                                            Providers === 'All Providers' && Month === 'May-23' ? 'All providers in May-2023' :
                                                                                                                                                            Providers === 'All Providers' && Month === 'Jun-23' ? 'All providers in June-2023' :
                                                                                                                                                            Providers === 'All Providers' && Month === 'Jul-23' ? 'All providers in July-2023' :
                                                                                                                                                            Providers === 'All Providers' && Month === 'Aug-23' ? 'All providers in August-2023' :
                                                                                                                                                            Providers === 'All Providers' && Month === 'Sep-23' ? 'All providers in September-2023' :
                                                                                                                                                            Providers === 'All Providers' && Month === 'Oct-23' ? 'All providers in October-2023' :
                                                                                                                                                            Providers === 'All Providers' && Month === 'Nov-23' ? 'All providers in November-2023' :

            Providers === 'Provider1' && Month === 'Jan-20' ? 'Provider1'
                : Providers === 'Provider1' && Month === 'Feb-20' ? 'Provider1'
                    : Providers === 'Provider1' && Month === 'Mar-20' ? 'Provider1'
                        : Providers === 'Provider1' && Month === 'Apr-20' ? 'Provider1'
                            : Providers === 'Provider1' && Month === 'May-20' ? 'Provider1'
                                : Providers === 'Provider1' && Month === 'Jun-20' ? 'Provider1'
                                    : Providers === 'Provider1' && Month === 'Jul-20' ? 'Provider1'
                                        : Providers === 'Provider1' && Month === 'Aug-20' ? 'Provider1'
                                            : Providers === 'Provider1' && Month === 'Sep-20' ? 'Provider1'
                                                : Providers === 'Provider1' && Month === 'Oct-20' ? 'Provider1'
                                                    : Providers === 'Provider1' && Month === 'Nov-20' ? 'Provider1'
                                                        : Providers === 'Provider1' && Month === 'Dec-20' ? 'Provider1'
                                                            : Providers === 'Provider1' && Month === 'Jan-21' ? 'Provider1 in January-2021'
                                                                : Providers === 'Provider1' && Month === 'Feb-21' ? 'Provider1 in February-2021'
                                                                    : Providers === 'Provider1' && Month === 'Mar-21' ? 'Provider1 in March-2021'
                                                                        : Providers === 'Provider1' && Month === 'Apr-21' ? 'Provider1 in April-2021'
                                                                            : Providers === 'Provider1' && Month === 'May-21' ? 'Provider1 in May-2021'
                                                                                : Providers === 'Provider1' && Month === 'Jun-21' ? 'Provider1 in June-2021'
                                                                                    : Providers === 'Provider1' && Month === 'Jul-21' ? 'Provider1 in July-2021'
                                                                                        : Providers === 'Provider1' && Month === 'Aug-21' ? 'Provider1 in August-2021'
                                                                                            : Providers === 'Provider1' && Month === 'Sep-21' ? 'Provider1 in September-2021'
                                                                                                : Providers === 'Provider1' && Month === 'Oct-21' ? 'Provider1 in October-2021'
                                                                                                    : Providers === 'Provider1' && Month === 'Nov-21' ? 'Provider1 in November-2021'
                                                                                                        : Providers === 'Provider1' && Month === 'Dec-21' ? 'Provider1 in December-2021'
                                                                                                            : Providers === 'Provider1' && Month === 'Jan-22' ? 'Provider1 in January-2022'
                                                                                                                : Providers === 'Provider1' && Month === 'Feb-22' ? 'Provider1 in February-2022'
                                                                                                                    : Providers === 'Provider1' && Month === 'Mar-22' ? 'Provider1 in March-2022'
                                                                                                                        : Providers === 'Provider1' && Month === 'Apr-22' ? 'Provider1 in April-2022'
                                                                                                                            : Providers === 'Provider1' && Month === 'May-22' ? 'Provider1 in May-2022'
                                                                                                                                : Providers === 'Provider1' && Month === 'Jun-22' ? 'Provider1 in June-2022'
                                                                                                                                    : Providers === 'Provider1' && Month === 'Jul-22' ? 'Provider1 in July-2022'
                                                                                                                                        : Providers === 'Provider1' && Month === 'Aug-22' ? 'Provider1 in August-2022'
                                                                                                                                            : Providers === 'Provider1' && Month === 'Sep-22' ? 'Provider1 in September-2022'
                                                                                                                                                : Providers === 'Provider1' && Month === 'Oct-22' ? 'Provider1 in October-2022'
                                                                                                                                                    : Providers === 'Provider1' && Month === 'Nov-22' ? 'Provider1 in November-2022' 
                                                                                                                                                        : Providers === 'Provider1' && Month === 'Dec-22' ? 'Provider1 in December-2022' :
                                                                                                                                                            Providers === 'Provider1' && Month === 'Jan-23' ? 'Provider1 in January-2023' :
                                                                                                                                                            Providers === 'Provider1' && Month === 'Feb-23' ? 'Provider1 in February-2023' :
                                                                                                                                                            Providers === 'Provider1' && Month === 'Mar-23' ? 'Provider1 in March-2023' :
                                                                                                                                                            Providers === 'Provider1' && Month === 'Apr-23' ? 'Provider1 in April-2023' :
                                                                                                                                                            Providers === 'Provider1' && Month === 'May-23' ? 'Provider1 in May-2023' :
                                                                                                                                                            Providers === 'Provider1' && Month === 'Jun-23' ? 'Provider1 in June-2023' :
                                                                                                                                                            Providers === 'Provider1' && Month === 'Jul-23' ? 'Provider1 in July-2023' :
                                                                                                                                                            Providers === 'Provider1' && Month === 'Aug-23' ? 'Provider1 in August-2023' :
                                                                                                                                                            Providers === 'Provider1' && Month === 'Sep-23' ? 'Provider1 in September-2023' :
                                                                                                                                                            Providers === 'Provider1' && Month === 'Oct-23' ? 'Provider1 in October-2023' :
                                                                                                                                                            Providers === 'Provider1' && Month === 'Nov-23' ? 'Provider1 in November-2023' :

            Providers === 'Provider2' && Month === 'Jan-20' ? 'Provider2'
                : Providers === 'Provider2' && Month === 'Feb-20' ? 'Provider2'
                    : Providers === 'Provider2' && Month === 'Mar-20' ? 'Provider2'
                        : Providers === 'Provider2' && Month === 'Apr-20' ? 'Provider2'
                            : Providers === 'Provider2' && Month === 'May-20' ? 'Provider2'
                                : Providers === 'Provider2' && Month === 'Jun-20' ? 'Provider2'
                                    : Providers === 'Provider2' && Month === 'Jul-20' ? 'Provider2'
                                        : Providers === 'Provider2' && Month === 'Aug-20' ? 'Provider2'
                                            : Providers === 'Provider2' && Month === 'Sep-20' ? 'Provider2'
                                                : Providers === 'Provider2' && Month === 'Oct-20' ? 'Provider2'
                                                    : Providers === 'Provider2' && Month === 'Nov-20' ? 'Provider2'
                                                        : Providers === 'Provider2' && Month === 'Dec-20' ? 'Provider2'
                                                            : Providers === 'Provider2' && Month === 'Jan-21' ? 'Provider2 in January-2021'
                                                                : Providers === 'Provider2' && Month === 'Feb-21' ? 'Provider2 in February-2021'
                                                                    : Providers === 'Provider2' && Month === 'Mar-21' ? 'Provider2 in March-2021'
                                                                        : Providers === 'Provider2' && Month === 'Apr-21' ? 'Provider2 in April-2021'
                                                                            : Providers === 'Provider2' && Month === 'May-21' ? 'Provider2 in May-2021'
                                                                                : Providers === 'Provider2' && Month === 'Jun-21' ? 'Provider2 in June-2021'
                                                                                    : Providers === 'Provider2' && Month === 'Jul-21' ? 'Provider2 in July-2021'
                                                                                        : Providers === 'Provider2' && Month === 'Aug-21' ? 'Provider2 in August-2021'
                                                                                            : Providers === 'Provider2' && Month === 'Sep-21' ? 'Provider2 in September-2021'
                                                                                                : Providers === 'Provider2' && Month === 'Oct-21' ? 'Provider2 in October-2021'
                                                                                                    : Providers === 'Provider2' && Month === 'Nov-21' ? 'Provider2 in November-2021'
                                                                                                        : Providers === 'Provider2' && Month === 'Dec-21' ? 'Provider2 in December-2021'
                                                                                                            : Providers === 'Provider2' && Month === 'Jan-22' ? 'Provider2 in January-2022'
                                                                                                                : Providers === 'Provider2' && Month === 'Feb-22' ? 'Provider2 in February-2022'
                                                                                                                    : Providers === 'Provider2' && Month === 'Mar-22' ? 'Provider2 in March-2022'
                                                                                                                        : Providers === 'Provider2' && Month === 'Apr-22' ? 'Provider2 in April-2022'
                                                                                                                            : Providers === 'Provider2' && Month === 'May-22' ? 'Provider2 in May-2022'
                                                                                                                                : Providers === 'Provider2' && Month === 'Jun-22' ? 'Provider2 in June-2022'
                                                                                                                                    : Providers === 'Provider2' && Month === 'Jul-22' ? 'Provider2 in July-2022'
                                                                                                                                        : Providers === 'Provider2' && Month === 'Aug-22' ? 'Provider2 in August-2022'
                                                                                                                                            : Providers === 'Provider2' && Month === 'Sep-22' ? 'Provider2 in September-2022'
                                                                                                                                                : Providers === 'Provider2' && Month === 'Oct-22' ? 'Provider2 in October-2022'
                                                                                                                                                    : Providers === 'Provider2' && Month === 'Nov-22' ? 'Provider2 in November-2022'
                                                                                                                                                        : Providers === 'Provider2' && Month === 'Dec-22' ? 'Provider2 in December-2022' :
                                                                                                                                                            Providers === 'Provider2' && Month === 'Jan-23' ? 'Provider2 in January-2023' :
                                                                                                                                                            Providers === 'Provider2' && Month === 'Feb-23' ? 'Provider2 in February-2023' :
                                                                                                                                                            Providers === 'Provider2' && Month === 'Mar-23' ? 'Provider2 in March-2023' :
                                                                                                                                                            Providers === 'Provider2' && Month === 'Apr-23' ? 'Provider2 in April-2023' :
                                                                                                                                                            Providers === 'Provider2' && Month === 'May-23' ? 'Provider2 in May-2023' :
                                                                                                                                                            Providers === 'Provider2' && Month === 'Jun-23' ? 'Provider2 in June-2023' :
                                                                                                                                                            Providers === 'Provider2' && Month === 'Jul-23' ? 'Provider2 in July-2023' :
                                                                                                                                                            Providers === 'Provider2' && Month === 'Aug-23' ? 'Provider2 in August-2023' :
                                                                                                                                                            Providers === 'Provider2' && Month === 'Sep-23' ? 'Provider2 in September-2023' :
                                                                                                                                                            Providers === 'Provider2' && Month === 'Oct-23' ? 'Provider2 in October-2023' :
                                                                                                                                                            Providers === 'Provider2' && Month === 'Nov-23' ? 'Provider2 in November-2023' :

            Providers === 'Provider3' && Month === 'Jan-20' ? 'Provider3'
                : Providers === 'Provider3' && Month === 'Feb-20' ? 'Provider3'
                    : Providers === 'Provider3' && Month === 'Mar-20' ? 'Provider3'
                        : Providers === 'Provider3' && Month === 'Apr-20' ? 'Provider3'
                            : Providers === 'Provider3' && Month === 'May-20' ? 'Provider3'
                                : Providers === 'Provider3' && Month === 'Jun-20' ? 'Provider3'
                                    : Providers === 'Provider3' && Month === 'Jul-20' ? 'Provider3'
                                        : Providers === 'Provider3' && Month === 'Aug-20' ? 'Provider3'
                                            : Providers === 'Provider3' && Month === 'Sep-20' ? 'Provider3'
                                                : Providers === 'Provider3' && Month === 'Oct-20' ? 'Provider3'
                                                    : Providers === 'Provider3' && Month === 'Nov-20' ? 'Provider3'
                                                        : Providers === 'Provider3' && Month === 'Dec-20' ? 'Provider3'
                                                            : Providers === 'Provider3' && Month === 'Jan-21' ? 'Provider3 in January-2021'
                                                                : Providers === 'Provider3' && Month === 'Feb-21' ? 'Provider3 in February-2021'
                                                                    : Providers === 'Provider3' && Month === 'Mar-21' ? 'Provider3 in March-2021'
                                                                        : Providers === 'Provider3' && Month === 'Apr-21' ? 'Provider3 in April-2021'
                                                                            : Providers === 'Provider3' && Month === 'May-21' ? 'Provider3 in May-2021'
                                                                                : Providers === 'Provider3' && Month === 'Jun-21' ? 'Provider3 in June-2021'
                                                                                    : Providers === 'Provider3' && Month === 'Jul-21' ? 'Provider3 in July-2021'
                                                                                        : Providers === 'Provider3' && Month === 'Aug-21' ? 'Provider3 in August-2021'
                                                                                            : Providers === 'Provider3' && Month === 'Sep-21' ? 'Provider3 in September-2021'
                                                                                                : Providers === 'Provider3' && Month === 'Oct-21' ? 'Provider3 in October-2021'
                                                                                                    : Providers === 'Provider3' && Month === 'Nov-21' ? 'Provider3 in November-2021'
                                                                                                        : Providers === 'Provider3' && Month === 'Dec-21' ? 'Provider3 in December-2021'
                                                                                                            : Providers === 'Provider3' && Month === 'Jan-22' ? 'Provider3 in January-2022'
                                                                                                                : Providers === 'Provider3' && Month === 'Feb-22' ? 'Provider3 in February-2022'
                                                                                                                    : Providers === 'Provider3' && Month === 'Mar-22' ? 'Provider3 in March-2022'
                                                                                                                        : Providers === 'Provider3' && Month === 'Apr-22' ? 'Provider3 in April-2022'
                                                                                                                            : Providers === 'Provider3' && Month === 'May-22' ? 'Provider3 in May-2022'
                                                                                                                                : Providers === 'Provider3' && Month === 'Jun-22' ? 'Provider3 in June-2022'
                                                                                                                                    : Providers === 'Provider3' && Month === 'Jul-22' ? 'Provider3 in July-2022'
                                                                                                                                        : Providers === 'Provider3' && Month === 'Aug-22' ? 'Provider3 in August-2022'
                                                                                                                                            : Providers === 'Provider3' && Month === 'Sep-22' ? 'Provider3 in September-2022'
                                                                                                                                                : Providers === 'Provider3' && Month === 'Oct-22' ? 'Provider3 in October-2022'
                                                                                                                                                    : Providers === 'Provider3' && Month === 'Nov-22' ? 'Provider3 in November-2022'
                                                                                                                                                        : Providers === 'Provider3' && Month === 'Dec-22' ? 'Provider3 in December-2022' :
                                                                                                                                                            Providers === 'Provider3' && Month === 'Jan-23' ? 'Provider3 in January-2023' :
                                                                                                                                                            Providers === 'Provider3' && Month === 'Feb-23' ? 'Provider3 in February-2023' :
                                                                                                                                                            Providers === 'Provider3' && Month === 'Mar-23' ? 'Provider3 in March-2023' :
                                                                                                                                                            Providers === 'Provider3' && Month === 'Apr-23' ? 'Provider3 in April-2023' :
                                                                                                                                                            Providers === 'Provider3' && Month === 'May-23' ? 'Provider3 in May-2023' :
                                                                                                                                                            Providers === 'Provider3' && Month === 'Jun-23' ? 'Provider3 in June-2023' :
                                                                                                                                                            Providers === 'Provider3' && Month === 'Jul-23' ? 'Provider3 in July-2023' :
                                                                                                                                                            Providers === 'Provider3' && Month === 'Aug-23' ? 'Provider3 in August-2023' :
                                                                                                                                                            Providers === 'Provider3' && Month === 'Sep-23' ? 'Provider3 in September-2023' :
                                                                                                                                                            Providers === 'Provider3' && Month === 'Oct-23' ? 'Provider3 in October-2023' :
                                                                                                                                                            Providers === 'Provider3' && Month === 'Nov-23' ? 'Provider3 in November-2023' :     
                                                                                                                                                            
            Providers === 'Provider4' && Month === 'Jan-20' ? 'Provider4'
                : Providers === 'Provider4' && Month === 'Feb-20' ? 'Provider4'
                    : Providers === 'Provider4' && Month === 'Mar-20' ? 'Provider4'
                        : Providers === 'Provider4' && Month === 'Apr-20' ? 'Provider4'
                            : Providers === 'Provider4' && Month === 'May-20' ? 'Provider4'
                                : Providers === 'Provider4' && Month === 'Jun-20' ? 'Provider4'
                                    : Providers === 'Provider4' && Month === 'Jul-20' ? 'Provider4'
                                        : Providers === 'Provider4' && Month === 'Aug-20' ? 'Provider4'
                                            : Providers === 'Provider4' && Month === 'Sep-20' ? 'Provider4'
                                                : Providers === 'Provider4' && Month === 'Oct-20' ? 'Provider4'
                                                    : Providers === 'Provider4' && Month === 'Nov-20' ? 'Provider4'
                                                        : Providers === 'Provider4' && Month === 'Dec-20' ? 'Provider4'
                                                            : Providers === 'Provider4' && Month === 'Jan-21' ? 'Provider4 in January-2021'
                                                                : Providers === 'Provider4' && Month === 'Feb-21' ? 'Provider4 in February-2021'
                                                                    : Providers === 'Provider4' && Month === 'Mar-21' ? 'Provider4 in March-2021'
                                                                        : Providers === 'Provider4' && Month === 'Apr-21' ? 'Provider4 in April-2021'
                                                                            : Providers === 'Provider4' && Month === 'May-21' ? 'Provider4 in May-2021'
                                                                                : Providers === 'Provider4' && Month === 'Jun-21' ? 'Provider4 in June-2021'
                                                                                    : Providers === 'Provider4' && Month === 'Jul-21' ? 'Provider4 in July-2021'
                                                                                        : Providers === 'Provider4' && Month === 'Aug-21' ? 'Provider4 in August-2021'
                                                                                            : Providers === 'Provider4' && Month === 'Sep-21' ? 'Provider4 in September-2021'
                                                                                                : Providers === 'Provider4' && Month === 'Oct-21' ? 'Provider4 in October-2021'
                                                                                                    : Providers === 'Provider4' && Month === 'Nov-21' ? 'Provider4 in November-2021'
                                                                                                        : Providers === 'Provider4' && Month === 'Dec-21' ? 'Provider4 in December-2021'
                                                                                                            : Providers === 'Provider4' && Month === 'Jan-22' ? 'Provider4 in January-2022'
                                                                                                                : Providers === 'Provider4' && Month === 'Feb-22' ? 'Provider4 in February-2022'
                                                                                                                    : Providers === 'Provider4' && Month === 'Mar-22' ? 'Provider4 in March-2022'
                                                                                                                        : Providers === 'Provider4' && Month === 'Apr-22' ? 'Provider4 in April-2022'
                                                                                                                            : Providers === 'Provider4' && Month === 'May-22' ? 'Provider4 in May-2022'
                                                                                                                                : Providers === 'Provider4' && Month === 'Jun-22' ? 'Provider4 in June-2022'
                                                                                                                                    : Providers === 'Provider4' && Month === 'Jul-22' ? 'Provider4 in July-2022'
                                                                                                                                        : Providers === 'Provider4' && Month === 'Aug-22' ? 'Provider4 in August-2022'
                                                                                                                                            : Providers === 'Provider4' && Month === 'Sep-22' ? 'Provider4 in September-2022'
                                                                                                                                                : Providers === 'Provider4' && Month === 'Oct-22' ? 'Provider4 in October-2022'
                                                                                                                                                    : Providers === 'Provider4' && Month === 'Nov-22' ? 'Provider4 in November-2022'
                                                                                                                                                        : Providers === 'Provider4' && Month === 'Dec-22' ? 'Provider4 in December-2022' : 
                                                                                                                                                            Providers === 'Provider4' && Month === 'Jan-23' ? 'Provider4 in January-2023' :
                                                                                                                                                            Providers === 'Provider4' && Month === 'Feb-23' ? 'Provider4 in February-2023' :
                                                                                                                                                            Providers === 'Provider4' && Month === 'Mar-23' ? 'Provider4 in March-2023' :
                                                                                                                                                            Providers === 'Provider4' && Month === 'Apr-23' ? 'Provider4 in April-2023' :
                                                                                                                                                            Providers === 'Provider4' && Month === 'May-23' ? 'Provider4 in May-2023' :
                                                                                                                                                            Providers === 'Provider4' && Month === 'Jun-23' ? 'Provider4 in June-2023' :
                                                                                                                                                            Providers === 'Provider4' && Month === 'Jul-23' ? 'Provider4 in July-2023' :
                                                                                                                                                            Providers === 'Provider4' && Month === 'Aug-23' ? 'Provider4 in August-2023' :
                                                                                                                                                            Providers === 'Provider4' && Month === 'Sep-23' ? 'Provider4 in September-2023' :
                                                                                                                                                            Providers === 'Provider4' && Month === 'Oct-23' ? 'Provider4 in October-2023' :
                                                                                                                                                            Providers === 'Provider4' && Month === 'Nov-23' ? 'Provider4 in November-2023' :
                                                                                                                                                            
            Providers === 'Provider5' && Month === 'Jan-20' ? 'Provider5'
                : Providers === 'Provider5' && Month === 'Feb-20' ? 'Provider5'
                    : Providers === 'Provider5' && Month === 'Mar-20' ? 'Provider5'
                        : Providers === 'Provider5' && Month === 'Apr-20' ? 'Provider5'
                            : Providers === 'Provider5' && Month === 'May-20' ? 'Provider5'
                                : Providers === 'Provider5' && Month === 'Jun-20' ? 'Provider5'
                                    : Providers === 'Provider5' && Month === 'Jul-20' ? 'Provider5'
                                        : Providers === 'Provider5' && Month === 'Aug-20' ? 'Provider5'
                                            : Providers === 'Provider5' && Month === 'Sep-20' ? 'Provider5'
                                                : Providers === 'Provider5' && Month === 'Oct-20' ? 'Provider5'
                                                    : Providers === 'Provider5' && Month === 'Nov-20' ? 'Provider5'
                                                        : Providers === 'Provider5' && Month === 'Dec-20' ? 'Provider5'
                                                            : Providers === 'Provider5' && Month === 'Jan-21' ? 'Provider5 in January-2021'
                                                                : Providers === 'Provider5' && Month === 'Feb-21' ? 'Provider5 in February-2021'
                                                                    : Providers === 'Provider5' && Month === 'Mar-21' ? 'Provider5 in March-2021'
                                                                        : Providers === 'Provider5' && Month === 'Apr-21' ? 'Provider5 in April-2021'
                                                                            : Providers === 'Provider5' && Month === 'May-21' ? 'Provider5 in May-2021'
                                                                                : Providers === 'Provider5' && Month === 'Jun-21' ? 'Provider5 in June-2021'
                                                                                    : Providers === 'Provider5' && Month === 'Jul-21' ? 'Provider5 in July-2021'
                                                                                        : Providers === 'Provider5' && Month === 'Aug-21' ? 'Provider5 in August-2021'
                                                                                            : Providers === 'Provider5' && Month === 'Sep-21' ? 'Provider5 in September-2021'
                                                                                                : Providers === 'Provider5' && Month === 'Oct-21' ? 'Provider5 in October-2021'
                                                                                                    : Providers === 'Provider5' && Month === 'Nov-21' ? 'Provider5 in November-2021'
                                                                                                        : Providers === 'Provider5' && Month === 'Dec-21' ? 'Provider5 in December-2021'
                                                                                                            : Providers === 'Provider5' && Month === 'Jan-22' ? 'Provider5 in January-2022'
                                                                                                                : Providers === 'Provider5' && Month === 'Feb-22' ? 'Provider5 in February-2022'
                                                                                                                    : Providers === 'Provider5' && Month === 'Mar-22' ? 'Provider5 in March-2022'
                                                                                                                        : Providers === 'Provider5' && Month === 'Apr-22' ? 'Provider5 in April-2022'
                                                                                                                            : Providers === 'Provider5' && Month === 'May-22' ? 'Provider5 in May-2022'
                                                                                                                                : Providers === 'Provider5' && Month === 'Jun-22' ? 'Provider5 in June-2022'
                                                                                                                                    : Providers === 'Provider5' && Month === 'Jul-22' ? 'Provider5 in July-2022'
                                                                                                                                        : Providers === 'Provider5' && Month === 'Aug-22' ? 'Provider5 in August-2022'
                                                                                                                                            : Providers === 'Provider5' && Month === 'Sep-22' ? 'Provider5 in September-2022'
                                                                                                                                                : Providers === 'Provider5' && Month === 'Oct-22' ? 'Provider5 in October-2022'
                                                                                                                                                    : Providers === 'Provider5' && Month === 'Nov-22' ? 'Provider5 in November-2022'
                                                                                                                                                        : Providers === 'Provider5' && Month === 'Dec-22' ? 'Provider5 in December-2022' :
                                                                                                                                                            Providers === 'Provider5' && Month === 'Jan-23' ? 'Provider5 in January-2023' :
                                                                                                                                                            Providers === 'Provider5' && Month === 'Feb-23' ? 'Provider5 in February-2023' :
                                                                                                                                                            Providers === 'Provider5' && Month === 'Mar-23' ? 'Provider5 in March-2023' :
                                                                                                                                                            Providers === 'Provider5' && Month === 'Apr-23' ? 'Provider5 in April-2023' :
                                                                                                                                                            Providers === 'Provider5' && Month === 'May-23' ? 'Provider5 in May-2023' :
                                                                                                                                                            Providers === 'Provider5' && Month === 'Jun-23' ? 'Provider5 in June-2023' :
                                                                                                                                                            Providers === 'Provider5' && Month === 'Jul-23' ? 'Provider5 in July-2023' :
                                                                                                                                                            Providers === 'Provider5' && Month === 'Aug-23' ? 'Provider5 in August-2023' :
                                                                                                                                                            Providers === 'Provider5' && Month === 'Sep-23' ? 'Provider5 in September-2023' :
                                                                                                                                                            Providers === 'Provider5' && Month === 'Oct-23' ? 'Provider5 in October-2023' :
                                                                                                                                                            Providers === 'Provider5' && Month === 'Nov-23' ? 'Provider5 in November-2023' :  
                                                                                                                                                            
            Providers === 'Provider6' && Month === 'Jan-20' ? 'Provider6'
                : Providers === 'Provider6' && Month === 'Feb-20' ? 'Provider6'
                    : Providers === 'Provider6' && Month === 'Mar-20' ? 'Provider6'
                        : Providers === 'Provider6' && Month === 'Apr-20' ? 'Provider6'
                            : Providers === 'Provider6' && Month === 'May-20' ? 'Provider6'
                                : Providers === 'Provider6' && Month === 'Jun-20' ? 'Provider6'
                                    : Providers === 'Provider6' && Month === 'Jul-20' ? 'Provider6'
                                        : Providers === 'Provider6' && Month === 'Aug-20' ? 'Provider6'
                                            : Providers === 'Provider6' && Month === 'Sep-20' ? 'Provider6'
                                                : Providers === 'Provider6' && Month === 'Oct-20' ? 'Provider6'
                                                    : Providers === 'Provider6' && Month === 'Nov-20' ? 'Provider6'
                                                        : Providers === 'Provider6' && Month === 'Dec-20' ? 'Provider6'
                                                            : Providers === 'Provider6' && Month === 'Jan-21' ? 'Provider6 in January-2021'
                                                                : Providers === 'Provider6' && Month === 'Feb-21' ? 'Provider6 in February-2021'
                                                                    : Providers === 'Provider6' && Month === 'Mar-21' ? 'Provider6 in March-2021'
                                                                        : Providers === 'Provider6' && Month === 'Apr-21' ? 'Provider6 in April-2021'
                                                                            : Providers === 'Provider6' && Month === 'May-21' ? 'Provider6 in May-2021'
                                                                                : Providers === 'Provider6' && Month === 'Jun-21' ? 'Provider6 in June-2021'
                                                                                    : Providers === 'Provider6' && Month === 'Jul-21' ? 'Provider6 in July-2021'
                                                                                        : Providers === 'Provider6' && Month === 'Aug-21' ? 'Provider6 in August-2021'
                                                                                            : Providers === 'Provider6' && Month === 'Sep-21' ? 'Provider6 in September-2021'
                                                                                                : Providers === 'Provider6' && Month === 'Oct-21' ? 'Provider6 in October-2021'
                                                                                                    : Providers === 'Provider6' && Month === 'Nov-21' ? 'Provider6 in November-2021'
                                                                                                        : Providers === 'Provider6' && Month === 'Dec-21' ? 'Provider6 in December-2021'
                                                                                                            : Providers === 'Provider6' && Month === 'Jan-22' ? 'Provider6 in January-2022'
                                                                                                                : Providers === 'Provider6' && Month === 'Feb-22' ? 'Provider6 in February-2022'
                                                                                                                    : Providers === 'Provider6' && Month === 'Mar-22' ? 'Provider6 in March-2022'
                                                                                                                        : Providers === 'Provider6' && Month === 'Apr-22' ? 'Provider6 in April-2022'
                                                                                                                            : Providers === 'Provider6' && Month === 'May-22' ? 'Provider6 in May-2022'
                                                                                                                                : Providers === 'Provider6' && Month === 'Jun-22' ? 'Provider6 in June-2022'
                                                                                                                                    : Providers === 'Provider6' && Month === 'Jul-22' ? 'Provider6 in July-2022'
                                                                                                                                        : Providers === 'Provider6' && Month === 'Aug-22' ? 'Provider6 in August-2022'
                                                                                                                                            : Providers === 'Provider6' && Month === 'Sep-22' ? 'Provider6 in September-2022'
                                                                                                                                                : Providers === 'Provider6' && Month === 'Oct-22' ? 'Provider6 in October-2022'
                                                                                                                                                    : Providers === 'Provider6' && Month === 'Nov-22' ? 'Provider6 in November-2022'
                                                                                                                                                        : Providers === 'Provider6' && Month === 'Dec-22' ? 'Provider6 in December-2022' :
                                                                                                                                                            Providers === 'Provider6' && Month === 'Jan-23' ? 'Provider6 in January-2023' :
                                                                                                                                                            Providers === 'Provider6' && Month === 'Feb-23' ? 'Provider6 in February-2023' :
                                                                                                                                                            Providers === 'Provider6' && Month === 'Mar-23' ? 'Provider6 in March-2023' :
                                                                                                                                                            Providers === 'Provider6' && Month === 'Apr-23' ? 'Provider6 in April-2023' :
                                                                                                                                                            Providers === 'Provider6' && Month === 'May-23' ? 'Provider6 in May-2023' :
                                                                                                                                                            Providers === 'Provider6' && Month === 'Jun-23' ? 'Provider6 in June-2023' :
                                                                                                                                                            Providers === 'Provider6' && Month === 'Jul-23' ? 'Provider6 in July-2023' :
                                                                                                                                                            Providers === 'Provider6' && Month === 'Aug-23' ? 'Provider6 in August-2023' :
                                                                                                                                                            Providers === 'Provider6' && Month === 'Sep-23' ? 'Provider6 in September-2023' :
                                                                                                                                                            Providers === 'Provider6' && Month === 'Oct-23' ? 'Provider6 in October-2023' :
                                                                                                                                                            Providers === 'Provider6' && Month === 'Nov-23' ? 'Provider6 in November-2023' :  
                                                                                                                                                            
            Providers === 'Provider7' && Month === 'Jan-20' ? 'Provider7'
                : Providers === 'Provider7' && Month === 'Feb-20' ? 'Provider7'
                    : Providers === 'Provider7' && Month === 'Mar-20' ? 'Provider7'
                        : Providers === 'Provider7' && Month === 'Apr-20' ? 'Provider7'
                            : Providers === 'Provider7' && Month === 'May-20' ? 'Provider7'
                                : Providers === 'Provider7' && Month === 'Jun-20' ? 'Provider7'
                                    : Providers === 'Provider7' && Month === 'Jul-20' ? 'Provider7'
                                        : Providers === 'Provider7' && Month === 'Aug-20' ? 'Provider7'
                                            : Providers === 'Provider7' && Month === 'Sep-20' ? 'Provider7'
                                                : Providers === 'Provider7' && Month === 'Oct-20' ? 'Provider7'
                                                    : Providers === 'Provider7' && Month === 'Nov-20' ? 'Provider7'
                                                        : Providers === 'Provider7' && Month === 'Dec-20' ? 'Provider7'
                                                            : Providers === 'Provider7' && Month === 'Jan-21' ? 'Provider7 in January-2021'
                                                                : Providers === 'Provider7' && Month === 'Feb-21' ? 'Provider7 in February-2021'
                                                                    : Providers === 'Provider7' && Month === 'Mar-21' ? 'Provider7 in March-2021'
                                                                        : Providers === 'Provider7' && Month === 'Apr-21' ? 'Provider7 in April-2021'
                                                                            : Providers === 'Provider7' && Month === 'May-21' ? 'Provider7 in May-2021'
                                                                                : Providers === 'Provider7' && Month === 'Jun-21' ? 'Provider7 in June-2021'
                                                                                    : Providers === 'Provider7' && Month === 'Jul-21' ? 'Provider7 in July-2021'
                                                                                        : Providers === 'Provider7' && Month === 'Aug-21' ? 'Provider7 in August-2021'
                                                                                            : Providers === 'Provider7' && Month === 'Sep-21' ? 'Provider7 in September-2021'
                                                                                                : Providers === 'Provider7' && Month === 'Oct-21' ? 'Provider7 in October-2021'
                                                                                                    : Providers === 'Provider7' && Month === 'Nov-21' ? 'Provider7 in November-2021'
                                                                                                        : Providers === 'Provider7' && Month === 'Dec-21' ? 'Provider7 in December-2021'
                                                                                                            : Providers === 'Provider7' && Month === 'Jan-22' ? 'Provider7 in January-2022'
                                                                                                                : Providers === 'Provider7' && Month === 'Feb-22' ? 'Provider7 in February-2022'
                                                                                                                    : Providers === 'Provider7' && Month === 'Mar-22' ? 'Provider7 in March-2022'
                                                                                                                        : Providers === 'Provider7' && Month === 'Apr-22' ? 'Provider7 in April-2022'
                                                                                                                            : Providers === 'Provider7' && Month === 'May-22' ? 'Provider7 in May-2022'
                                                                                                                                : Providers === 'Provider7' && Month === 'Jun-22' ? 'Provider7 in June-2022'
                                                                                                                                    : Providers === 'Provider7' && Month === 'Jul-22' ? 'Provider7 in July-2022'
                                                                                                                                        : Providers === 'Provider7' && Month === 'Aug-22' ? 'Provider7 in August-2022'
                                                                                                                                            : Providers === 'Provider7' && Month === 'Sep-22' ? 'Provider7 in September-2022'
                                                                                                                                                : Providers === 'Provider7' && Month === 'Oct-22' ? 'Provider7 in October-2022'
                                                                                                                                                    : Providers === 'Provider7' && Month === 'Nov-22' ? 'Provider7 in November-2022'
                                                                                                                                                        : Providers === 'Provider7' && Month === 'Dec-22' ? 'Provider7 in December-2022' : 
                                                                                                                                                            Providers === 'Provider7' && Month === 'Jan-23' ? 'Provider7 in January 2023' :
                                                                                                                                                            Providers === 'Provider7' && Month === 'Feb-23' ? 'Provider7 in February 2023' :
                                                                                                                                                            Providers === 'Provider7' && Month === 'Mar-23' ? 'Provider7 in March 2023' :
                                                                                                                                                            Providers === 'Provider7' && Month === 'Apr-23' ? 'Provider7 in April 2023' :
                                                                                                                                                            Providers === 'Provider7' && Month === 'May-23' ? 'Provider7 in May 2023' :
                                                                                                                                                            Providers === 'Provider7' && Month === 'Jun-23' ? 'Provider7 in June 2023' :
                                                                                                                                                            Providers === 'Provider7' && Month === 'Jul-23' ? 'Provider7 in July 2023' :
                                                                                                                                                            Providers === 'Provider7' && Month === 'Aug-23' ? 'Provider7 in August 2023' :
                                                                                                                                                            Providers === 'Provider7' && Month === 'Sep-23' ? 'Provider7 in September 2023' :
                                                                                                                                                            Providers === 'Provider7' && Month === 'Oct-23' ? 'Provider7 in October 2023' :
                                                                                                                                                            Providers === 'Provider7' && Month === 'Nov-23' ? 'Provider7 in November 2023' :                                                                                                                                                 
                                                                                                                                                                                                    ''

        },
        xaxis: {
            categories: session === 'RX' ? [
                Providers && Facility === 'Facility 1' && Month ? 'Facility 1':
                Providers && Facility === 'Facility 2' && Month ? 'Facility 2':
                Providers && Facility === 'Facility 3' && Month ? 'Facility 3':  
                (Providers === 'All Providers' && Facility === 'All Facilities') && Month ? 'All Providers' :
                (Providers === 'Provider1' && Facility === 'All Facilities') && Month ? 'Provider 1' :
                (Providers === 'Provider2' && Facility === 'All Facilities') && Month ? 'Provider 2' :
                (Providers === 'Provider3' && Facility === 'All Facilities') && Month ? 'Provider 3' :
                (Providers === 'Provider4' && Facility === 'All Facilities') && Month ? 'Provider 4' :
                (Providers === 'Provider5' && Facility === 'All Facilities') && Month ? 'Provider 5' :
                (Providers === 'Provider6' && Facility === 'All Facilities') && Month ? 'Provider 6' :
                (Providers === 'Provider7' && Facility === 'All Facilities') && Month ? 'Provider 7' : 'Sum of three facilities'
            ] : ''
            ,
            labels: {
                formatter: function (val) {
                    return val
                }
            },
        },
        yaxis: {
            title: {
                text: undefined
            },
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val
                }
            }
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
        }
    }

    let first = ['Jan-2020', 'Feb-2020', 'Mar-2020']
    let second = ['Apr-2020', 'May02-20', 'Jun-2020']
    let third = ['Jul-2020', 'Aug-2020', 'Sept-2020']
    let fourth = ['Oct-2020', 'Nov-2020', 'Dec-2020']
    let fifth = ['Jan-2021', 'Feb-2021', 'Mar-2021']
    let sixth = ['Apr-2021', 'May-2021', 'Jun-2021']
    let seveth = ['Jul-2021', 'Aug-2021', 'Sept-2021']
    let eight = ['Oct-2021', 'Nov-2021', 'Dec-2021']
    let first2022 = ['Jan-2022', 'Feb-2022', 'Mar-2022']
    let second2022 = ['Apr-2022', 'May-2022', 'Jun-2022']
    let third2022 = ['Jul-2022', 'Aug-2022', 'Sept-2022']
    let fourth2022 = ['Oct-2022', 'Nov-2022', 'Dec-2022']
    let first2023 = ['Jan-2023', 'Feb-2023', 'Mar-2023']
    let second2023 = ['Apr-2023', 'May-2023', 'Jun-2023']
    let third2023 = ['Jul-2023','Aug-2023','Sept-2023']
    let fourth2023 = ['Oct-2023', 'Nov-2023', 'Dec-2023']
    let first2024 = ['Jan-2024', 'Feb-2024', 'Mar-2024']
    let second2024 = ['Apr-2024', 'May-2024', 'Jun-2024']
    let third2024 = ['Jul-2024', 'Aug-2024', 'Sept-2024']
    var quartersOptions = {
        chart: {
            type: 'bar',
            height: 450,
            stacked: true,
        },
        plotOptions: {
            bar: {
                horizontal: changeBar,
            },
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        title: {
            text: session === 'Aurora' ?
                [quarters === '0' ? '2020 first Quarter' :
                    quarters === '1' ? '2020 second Quarter' :
                        quarters === '2' ? '2020 third Quarter' :
                            quarters === '3' ? '2020 fourth Quarter' :
                                quarters === '4' ? '2021 first Quarter' :
                                    quarters === '5' ? '2021 second Quarter' :
                                        quarters === '6' ? '2021 Third Quarter' :
                                            quarters === '7' ? '2021 Fourth Quarter' :
                                                quarters === '8' ? '2022 First Quarter' :
                                                    quarters === '9' ? '2022 Second Quarter' :
                                                        quarters === '10' ? '2022 Third Quarter' :
                                                            quarters === '11' ? '2022 Fourth Quarter' :
                                                                quarters === '12' ? '2023 First Quarter' :
                                                                    quarters === '13' ? '2023 Second Quarter' :
                                                                        quarters === '14' ? '2023 Third Quarter' :
                                                                            quarters === '15' ? '2023 Fourth Quarter' :
                                                                                quarters === '16' ? '2024 First Quarter' :
                                                                                    quarters === '17' ? '2024 Second Quarter' :
                                                                                        quarters === '18' ? '2024 Third Quarter' :
                                                                                            ''] : session === 'PersonalClinic' ? [
                                                                                                quarters === '0' ? '2020 Third Quarter' :
                                                                                                    quarters === '1' ? '2021 first Quarter' :
                                                                                                        quarters === '2' ? '2021 second Quarter' :
                                                                                                            quarters === '3' ? '2021 Third Quarter' :
                                                                                                                quarters === '4' ? '2021 Fourth Quarter' :
                                                                                                                    quarters === '5' ? '2022 First Quarter' :
                                                                                                                        quarters === '6' ? '2022 Second Quarter' :
                                                                                                                            quarters === '7' ? '2022 Third Quarter' :
                                                                                                                                quarters === '8' ? '2022 Fourth Quarter' :
                                                                                                                                    quarters === '9' ? '2023 First Quarter' :
                                                                                                                                        quarters === '10' ? '2023 Second Quarter' :
                                                                                                                                            quarters === '11' ? '2023 Third Quarter' :
                                                                                                                                                quarters === '12' ? '2023 Fourth Quarter' :
                                                                                                                                                    quarters === '13' ? '2024 First Quarter' :
                                                                                                                                                        quarters === '14' ? '2024 Second Quarter' :
                                                                                                                                                            quarters === '15' ? '2024 Third Quarter' :
                                                                                                                                                            ''
                                                                        // ] : '' Nov update
                                                                        ] : session === 'RX' ? [
                                                                            Providers === 'All Providers' && quarters === '0' ? 'First Quarter of 2021 in All Providers' :
                                                                            Providers === 'All Providers' && quarters === '1' ? 'Second Quarter of 2021 in All Providers' :
                                                                            Providers === 'All Providers' && quarters === '2' ? 'Third Quarter of 2021 in All Providers' :
                                                                            Providers === 'All Providers' && quarters === '3' ? 'Fourth Quarter of 2021 in All Providers' :
                                                                            Providers === 'All Providers' && quarters === '4' ? 'First Quarter of 2022 in All Providers' :
                                                                            Providers === 'All Providers' && quarters === '5' ? 'Second Quarter of 2022 in All Providers' :
                                                                            Providers === 'All Providers' && quarters === '6' ? 'Third Quarter of 2022 in All Providers' :
                                                                            Providers === 'All Providers' && quarters === '7' ? 'Fourth Quarter of 2022 in All Providers' :
                                                                            Providers === 'All Providers' && quarters === '8' ? 'First Quarter of 2023 in All Providers' :
                                                                            Providers === 'All Providers' && quarters === '9' ? 'Second Quarter of 2023 in All Providers' :
                                                                            Providers === 'All Providers' && quarters === '10' ? 'Third Quarter of 2023 in All Providers' :
                                                                            Providers === 'All Providers' && quarters === '11' ? 'Fourth Quarter of 2023 in All Providers' :
                                                                            Providers === 'Provider1' && quarters === '0' ? 'First Quarter of 2021 in Provider 1' :
                                                                            Providers === 'Provider1' && quarters === '1' ? 'Second Quarter of 2021 in Provider 1' :
                                                                            Providers === 'Provider1' && quarters === '2' ? 'Third Quarter of 2021 in Provider 1' :
                                                                            Providers === 'Provider1' && quarters === '3' ? 'Fourth Quarter of 2021 in Provider 1' :
                                                                            Providers === 'Provider1' && quarters === '4' ? 'First Quarter of 2022 in Provider 1' :
                                                                            Providers === 'Provider1' && quarters === '5' ? 'Second Quarter of 2022 in Provider 1' :
                                                                            Providers === 'Provider1' && quarters === '6' ? 'Third Quarter of 2022 in Provider 1' :
                                                                            Providers === 'Provider1' && quarters === '7' ? 'Fourth Quarter of 2022 in Provider 1' :
                                                                            Providers === 'Provider1' && quarters === '8' ? 'First Quarter of 2023 in Provider 1' :
                                                                            Providers === 'Provider1' && quarters === '9' ? 'Second Quarter of 2023 in Provider 1' :
                                                                            Providers === 'Provider1' && quarters === '10' ? 'Third Quarter of 2023 in Provider 1' :
                                                                            Providers === 'Provider1' && quarters === '11' ? 'Fourth Quarter of 2023 in Provider 1' :
                                                                            Providers === 'Provider2' && quarters === '0' ? 'First Quarter of 2021 in Provider 2' :
                                                                            Providers === 'Provider2' && quarters === '1' ? 'Second Quarter of 2021 in Provider 2' :
                                                                            Providers === 'Provider2' && quarters === '2' ? 'Third Quarter of 2021 in Provider 2' :
                                                                            Providers === 'Provider2' && quarters === '3' ? 'Fourth Quarter of 2021 in Provider 2' :
                                                                            Providers === 'Provider2' && quarters === '4' ? 'First Quarter of 2022 in Provider 2' :
                                                                            Providers === 'Provider2' && quarters === '5' ? 'Second Quarter of 2022 in Provider 2' :
                                                                            Providers === 'Provider2' && quarters === '6' ? 'Third Quarter of 2022 in Provider 2' :
                                                                            Providers === 'Provider2' && quarters === '7' ? 'Fourth Quarter of 2022 in Provider 2' :
                                                                            Providers === 'Provider2' && quarters === '8' ? 'First Quarter of 2023 in Provider 2' :
                                                                            Providers === 'Provider2' && quarters === '9' ? '2023 Second Quarter of 2023 in Provider 2' :
                                                                            Providers === 'Provider2' && quarters === '10' ? 'Third Quarter of 2023 in Provider 2' :
                                                                            Providers === 'Provider2' && quarters === '11' ? 'Fourth Quarter of 2023 in Provider 2' :
                                                                            Providers === 'Provider3' && quarters === '0' ? 'First Quarter of 2021 in Provider 3' :
                                                                            Providers === 'Provider3' && quarters === '1' ? 'Second Quarter of 2021 in Provider 3' :
                                                                            Providers === 'Provider3' && quarters === '2' ? 'Third Quarter of 2021 in Provider 3' :
                                                                            Providers === 'Provider3' && quarters === '3' ? 'Fourth Quarter of 2021 in Provider 3' :
                                                                            Providers === 'Provider3' && quarters === '4' ? 'First Quarter of 2022 in Provider 3' :
                                                                            Providers === 'Provider3' && quarters === '5' ? 'Second Quarter of 2022 in Provider 3' :
                                                                            Providers === 'Provider3' && quarters === '6' ? 'Third Quarter of 2022 in Provider 3' :
                                                                            Providers === 'Provider3' && quarters === '7' ? 'Fourth Quarter of 2022 in Provider 3' :
                                                                            Providers === 'Provider3' && quarters === '8' ? 'First Quarter of 2023 in Provider 3' :
                                                                            Providers === 'Provider3' && quarters === '9' ? 'Second Quarter of 2023 in Provider 3' :
                                                                            Providers === 'Provider3' && quarters === '10' ? 'Third Quarter of 2023 in Provider 3' :
                                                                            Providers === 'Provider3' && quarters === '11' ? 'Fourth Quarter of 2023 in Provider 3' :
                                                                            Providers === 'Provider4' && quarters === '0' ? 'First Quarter of 2021 in Provider 4' :
                                                                            Providers === 'Provider4' && quarters === '1' ? 'Second Quarter of 2021 in Provider 4' :
                                                                            Providers === 'Provider4' && quarters === '2' ? 'Third Quarter of 2021 in Provider 4' :
                                                                            Providers === 'Provider4' && quarters === '3' ? 'Fourth Quarter of 2021 in Provider 4' :
                                                                            Providers === 'Provider4' && quarters === '4' ? 'First Quarter of 2022 in Provider 4' :
                                                                            Providers === 'Provider4' && quarters === '5' ? 'Second Quarter of 2022 in Provider 4' :
                                                                            Providers === 'Provider4' && quarters === '6' ? 'Third Quarter of 2022 in Provider 4' :
                                                                            Providers === 'Provider4' && quarters === '7' ? 'Fourth Quarter of 2022 in Provider 4' :
                                                                            Providers === 'Provider4' && quarters === '8' ? 'First Quarter of 2023 in Provider 4' :
                                                                            Providers === 'Provider4' && quarters === '9' ? 'Second Quarter of 2023 in Provider 4' :
                                                                            Providers === 'Provider4' && quarters === '10' ? 'Third Quarter of 2023 in Provider 4' :
                                                                            Providers === 'Provider4' && quarters === '11' ? 'Fourth Quarter of 2023 in Provider 4' :
                                                                            Providers === 'Provider5' && quarters === '0' ? 'First Quarter of 2021 in Provider 5' :
                                                                            Providers === 'Provider5' && quarters === '1' ? 'Second Quarter of 2021 in Provider 5' :
                                                                            Providers === 'Provider5' && quarters === '2' ? 'Third Quarter of 2021 in Provider 5' :
                                                                            Providers === 'Provider5' && quarters === '3' ? 'Fourth Quarter of 2021 in Provider 5' :
                                                                            Providers === 'Provider5' && quarters === '4' ? 'First Quarter of 2022 in Provider 5' :
                                                                            Providers === 'Provider5' && quarters === '5' ? 'Second Quarter of 2022 in Provider 5' :
                                                                            Providers === 'Provider5' && quarters === '6' ? 'Third Quarter of 2022 in Provider 5' :
                                                                            Providers === 'Provider5' && quarters === '7' ? 'Fourth Quarter of 2022 in Provider 5' :
                                                                            Providers === 'Provider5' && quarters === '8' ? 'First Quarter of 2023 in Provider 5' :
                                                                            Providers === 'Provider5' && quarters === '9' ? 'Second Quarter of 2023 in Provider 5' :
                                                                            Providers === 'Provider5' && quarters === '10' ? 'Third Quarter of 2023 in Provider 5' :
                                                                            Providers === 'Provider5' && quarters === '11' ? 'Fourth Quarter of 2023 in Provider 5' :
                                                                            Providers === 'Provider6' && quarters === '0' ? 'First Quarter of 2021 in Provider 6' :
                                                                            Providers === 'Provider6' && quarters === '1' ? 'Second Quarter of 2021 in Provider 6' :
                                                                            Providers === 'Provider6' && quarters === '2' ? 'Third Quarter of 2021 in Provider 6' :
                                                                            Providers === 'Provider6' && quarters === '3' ? 'Fourth Quarter of 2021 in Provider 6' :
                                                                            Providers === 'Provider6' && quarters === '4' ? 'First Quarter of 2022 in Provider 6' :
                                                                            Providers === 'Provider6' && quarters === '5' ? 'Second Quarter of 2022 in Provider 6' :
                                                                            Providers === 'Provider6' && quarters === '6' ? 'Third Quarter of 2022 in Provider 6' :
                                                                            Providers === 'Provider6' && quarters === '7' ? 'Fourth Quarter of 2022 in Provider 6' :
                                                                            Providers === 'Provider6' && quarters === '8' ? 'First Quarter of 2023 in Provider 6' :
                                                                            Providers === 'Provider6' && quarters === '9' ? 'Second Quarter of 2023 in Provider 6' :
                                                                            Providers === 'Provider6' && quarters === '10' ? 'Third Quarter of 2023 in Provider 6' :
                                                                            Providers === 'Provider6' && quarters === '11' ? 'Fourth Quarter of 2023 in Provider 6' :
                                                                            Providers === 'Provider7' && quarters === '0' ? 'First Quarter of 2021 in All Providers' :
                                                                            Providers === 'Provider7' && quarters === '1' ? 'Second Quarter of 2021 in Provider 7' :
                                                                            Providers === 'Provider7' && quarters === '2' ? 'Third Quarter of 2021 in Provider 7' :
                                                                            Providers === 'Provider7' && quarters === '3' ? 'Fourth Quarter of 2021 in Provider 7' :
                                                                            Providers === 'Provider7' && quarters === '4' ? 'First Quarter of 2022 in Provider 7' :
                                                                            Providers === 'Provider7' && quarters === '5' ? 'Second Quarter of 2022 in Provider 7' :
                                                                            Providers === 'Provider7' && quarters === '6' ? 'Third Quarter of 2022 in Provider 7' :
                                                                            Providers === 'Provider7' && quarters === '7' ? 'Fourth Quarter of 2022 in Provider 7' :
                                                                            Providers === 'Provider7' && quarters === '8' ? 'First Quarter of 2023 in Provider 7' :
                                                                            Providers === 'Provider7' && quarters === '9' ? 'Second Quarter of 2023 in Provider 7' :
                                                                            Providers === 'Provider7' && quarters === '10' ? 'Third Quarter of 2023 in Provider 7' :
                                                                            Providers === 'Provider7' && quarters === '11' ? 'Fourth Quarter of 2023 in Provider 7' : ''
                                                                        ] : ''
        },
        xaxis: {
            categories: session === 'Aurora' ?
                quarters === '0' ? [...first] :
                    quarters === '1' ? [...second] :
                        quarters === '2' ? [...third] :
                            quarters === '3' ? [...fourth] :
                                quarters === '4' ? [...fifth] :
                                    quarters === '5' ? [...sixth] :
                                        quarters === '6' ? [...seveth] :
                                            quarters === '7' ? [...eight] :
                                                quarters === '8' ? [...first2022] :
                                                    quarters === '9' ? [...second2022] :
                                                        quarters === '10' ? [...third2022] :
                                                            quarters === '11' ? [...fourth2022] :
                                                                quarters === '12' ? [...first2023] :
                                                                    quarters === '13' ? [...second2023] :
                                                                        quarters === '14' ? [...third2023] :
                                                                            quarters === '15' ? [...fourth2023] :
                                                                                quarters === '16' ? [...first2024] :
                                                                                    quarters === '17' ? [...second2024] :
                                                                                        quarters === '18' ? [...third2024] :
                                                                                            ''
                : session === 'PersonalClinic' ?
                    quarters === '0' ? [...fourth] :
                        quarters === '1' ? [...fifth] :
                            quarters === '2' ? [...sixth] :
                                quarters === '3' ? [...seveth] :
                                    quarters === '4' ? [...eight] :
                                        quarters === '5' ? [...first2022] :
                                            quarters === '6' ? [...second2022] :
                                                quarters === '7' ? [...third2022] :
                                                    quarters === '8' ? [...fourth2022] :
                                                        quarters === '9' ? [...first2023] :
                                                            quarters === '10' ? [...second2023] :
                                                                quarters === '11' ? [...third2023] :
                                                                    quarters === '12' ? [...fourth2023] :
                                                                        quarters === '13' ? [...first2024] :
                                                                            quarters === '14' ? [...second2024] :  
                                                                                quarters === '15' ? [...third2024] :                                                                  
                                                                                ''
                    : session === 'RX' ?

                        quarters === '0' ? [...fifth] :
                            quarters === '1' ? [...sixth] :
                                quarters === '2' ? [...seveth] :
                                    quarters === '3' ? [...eight] :
                                        quarters === '4' ? [...first2022] :
                                            quarters === '5' ? [...second2022] :
                                                quarters === '6' ? [...third2022] :
                                                    quarters === '7' ? [...fourth2022] :
                                                        quarters === '8' ? [...first2023] :
                                                            quarters === '9' ? [...second2023] :
                                                                quarters === '10' ? [...third2023] :
                                                                    quarters === '11' ? [...fourth2023] :
                                                                    '' : ''
            ,
            labels: {
                formatter: function (val) {
                    return val
                }
            },
        },
        yaxis: {
            title: {
                text: undefined
            },
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val
                }
            }
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
        }
    };

    let months = session === 'Aurora' ? [
        'Select a month',
        // 'Jan-20',
        // 'Feb-20',
        // 'Mar-20',
        // 'Apr-20',
        // 'May-20',
        // 'Jun-20',
        // 'Jul-20',
        // 'Aug-20',
        // 'Sep-20',
        // 'Oct-20',
        // 'Nov-20',
        // 'Dec-20',
        // 'Jan-21',
        // 'Feb-21',
        // 'Mar-21',
        // 'Apr-21',
        // 'May-21',
        // 'Jun-21',
        // 'Jul-21',
        // 'Aug-21',
        // 'Sep-21',
        // 'Oct-21',
        // 'Nov-21',
        // 'Dec-21',
        'Jan-22',
        'Feb-22',
        'Mar-22',
        'Apr-22',
        'May-22',
        'Jun-22',
        'Jul-22',
        'Aug-22',
        'Sep-22',
        'Oct-22',
        'Nov-22',
        'Dec-22',
        'Jan-23',
        'Feb-23',
        'Mar-23',
        'Apr-23',
        'May-23',
        'Jun-23',
        'Jul-23',
        'Aug-23',
        'Sep-23',
        'Oct-23',
        'Nov-23',
        'Dec-23',
        'Jan-24',
        'Feb-24',
        'Mar-24',
        'Apr-24',
        'May-24',
        'Jun-24',
        'Jul-24',
        'Aug-24'
    ] : session === 'PersonalClinic' ? [
        'Select a month',
        // 'Oct-20',
        // 'Nov-20',
        // 'Dec-20',
        // 'Jan-21',
        // 'Feb-21',
        // 'Mar-21',
        // 'Apr-21',
        // 'May-21',
        // 'Jun-21',
        // 'Jul-21',
        // 'Aug-21',
        // 'Sep-21',
        // 'Oct-21',
        // 'Nov-21',
        // 'Dec-21',
        'Jan-22',
        'Feb-22',
        'Mar-22',
        'Apr-22',
        'May-22',
        'Jun-22',
        'Jul-22',
        'Aug-22',
        'Sep-22',
        'Oct-22',
        'Nov-22',
        'Dec-22',
        'Jan-23',
        'Feb-23',
        'Mar-23',
        'Apr-23',
        'May-23',
        'Jun-23',
        'Jul-23',
        'Aug-23',
        'Sep-23',
        'Oct-23',
        'Nov-23',
        'Dec-23',
        'Jan-24',
        'Feb-24',
        'Mar-24',
        'Apr-24',
        'May-24',
        'Jun-24',
        'Jul-24',
        'Aug-24'
    ] : session === 'RX' ? [
        'Select a month',
        'Jan-21',
        'Feb-21',
        'Mar-21',
        'Apr-21',
        'May-21',
        'Jun-21',
        'Jul-21',
        'Aug-21',
        'Sep-21',
        'Oct-21',
        'Nov-21',
        'Dec-21',
        'Jan-22',
        'Feb-22',
        'Mar-22',
        'Apr-22',
        'May-22',
        'Jun-22',
        'Jul-22',
        'Aug-22',
        'Sep-22',
        'Oct-22',
        'Nov-22',
        'Dec-22',
        'Jan-23',
        'Feb-23',
        'Mar-23',
        'Apr-23',
        'May-23',
        'Jun-23',
        'Jul-23',
        'Aug-23',
        'Sep-23',
        'Oct-23',
        'Nov-23'
    ] : ''
    let quaters = session === 'Aurora' ? [
        '2020-1st Quarter',
        '2020-2nd Quarter',
        '2020-3rd Quarter',
        '2020-4th Quarter',
        '2021-1st Quarter',
        '2021-2nd Quarter',
        '2021-3rd Quarter',
        '2021-4th Quarter',
        '2022-1st Quarter',
        '2022-2nd Quarter',
        '2022-3rd Quarter',
        '2022-4th Quarter',
        '2023-1st Quarter',
        '2023-2nd Quarter',
        '2023-3rd Quarter',
        '2023-4th Quarter',
        '2024-1th Quarter',
        '2024-2nd Quarter',
        '2024-3rd Quarter'
    ] : session === 'PersonalClinic' ? [
        '2020-4th Quarter',
        '2021-1st Quarter',
        '2021-2nd Quarter',
        '2021-3rd Quarter',
        '2021-4th Quarter',
        '2022-1st Quarter',
        '2022-2nd Quarter',
        '2022-3rd Quarter',
        '2022-4th Quarter',
        '2023-1st Quarter',
        '2023-2nd Quarter',
        '2023-3rd Quarter',
        '2023-4th Quarter',
        '2024-1st Quarter',
        '2024-2nd Quarter',
        '2024-3rd Quarter'
    ] : session = 'RX' ? [
        '2021-1st Quarter',
        '2021-2nd Quarter',
        '2021-3rd Quarter',
        '2021-4th Quarter',
        '2022-1st Quarter',
        '2022-2nd Quarter',
        '2022-3rd Quarter',
        '2022-4th Quarter',
        '2023-1st Quarter',
        '2023-2nd Quarter',
        '2023-3rd Quarter',
        '2023-4th Quarter'
    ] : ''

    
    let handleProviders = (e) => {
        setProvider(e.target.value)
        setshowOverAll(true)
        // setFacility('')
        setMonth('')
        setQuarters('')
        setfromToDateChart(false)
        // setProviderFromdate('')
        setFromDate('')
        setToDate('')

    }

    let handleMonth = (e) => {
        // alert(e.target.value)
        setMonth(e.target.value)
        setshowOverAll(false)

        // setQuarters('')
        setProvider('')
        // setFacility('')
        setQuarters('')
        setfromToDateChart(false)
        // setProviderFromdate('')
        setFromDate('')
        setToDate('')

    }
    let handleQuaters = (e) => {
        setQuarters(e.target.value)
        setshowOverAll(false)

        setProvider('')
        // setFacility('')
        setMonth('')
        setfromToDateChart(false)
        // setProviderFromdate('')
        setFromDate('')
        setToDate('')
    } 
    
    let rxhandleProviders = (e) => {
        setProvider(e.target.value)
        setshowOverAll(true)
        // setFacility('')
        // setMonth('')
        // setQuarters('')
        setfromToDateChart(false)
        // setProviderFromdate('')
        setFromDate('')
        setToDate('')

    }
    let handleFacility = (e) => {
        setFacility(e.target.value)
        setshowOverAll(false)
        // setProvider('')
        // setMonth('')
        setQuarters('')
        setfromToDateChart(false)
        // setProviderFromdate('')
        setFromDate('')
        setToDate('')
    }
    // let handleFacility = (e) => {
    //     setFacility(e.target.value)
    //     setshowOverAll(false)
    //     // setProvider('')
    //     // setMonth('')
    //     setQuarters('')
    //     setfromToDateChart(false)
    //     // setProviderFromdate('')
    //     setFromDate('')
    //     setToDate('')
    // }
    let rxhandleMonth = (e) => {
        // alert(e.target.value)
        setMonth(e.target.value)
        setshowOverAll(false)

        // setProvider('')
        // setFacility('')
        setQuarters('')
        setfromToDateChart(false)
        // setProviderFromdate('')
        setFromDate('')
        setToDate('')

    }
    let rxhandleQuaters = (e) => {
        setQuarters(e.target.value)
        setshowOverAll(false)

        // setProvider('')
        setFacility('')
        setMonth('')
        setfromToDateChart(false)
        // setProviderFromdate('')
        setFromDate('')
        setToDate('')
    }


    ///sort provider by from todate
    let fromToDateProvider = [
        {
            name: 'Charges',
            data: []
        },
        {
            name: 'Payment',
            data: []
        },
        {
            name: 'Collectable',
            data: []
        },
        {
            name: 'Non Collectable',
            data: []
        },
        {
            name: 'Claims',
            data: []
        },
        {
            name: 'Average Visits',
            data: []
        },
    ]
    ////fromtodate
    let arrValues = new Array(40).fill(0)
    let [fromToDateMonths, setfromToDateMonths] = useState([])
    let handleGridForProviders = () => {
        //    alert(provider)
        try {
            if (Number(fromdate) >= Number(todate) || Number(todate) <= Number(fromdate)) {
                setshowborder(true)
                toast.error('From option should be less than To option')
                return setTimeout(() => {
                    setshowborder(false)
                }, 1000);

            }
            setisShow(true)
            setfromToDateMonths([])
            setData([])
            setfromToDateChart(true)
            setQuarters('')
            setProvider('')
            setFacility('')
            setMonth('')
            setData([])
            setshowOverAll(false)

            // alert(provider+' '+fromdate+' '+todate)
            // fromToDateProvider = [...Array(6)].map(() => [...Array(Number(todate 0fromdate) + 1 )].fill(0))
            //  console.log('data1' , fromdate,todate,providersArray[provider].length)
            // if (session === 'Aurora') {
            //     provider = 0
            // }
            // console.log(arrValues.length)
            // console.log('from to ' , fromdate,todate)
            for (let i = 0; i < arrValues.length; i++) {
                if (i >= Number(fromdate) && i <= Number(todate)) {
                    // console.log('date month ', fromDate[i], i)
                    setfromToDateMonths(prev => [...prev, fromDate[i]])
                }
            }

            // let len = providerDatas[provider]
            // console.log('length is ' ,  len,provider)
            let providerDatas = session === 'Aurora' ? [...providersArray] :
                session === 'PersonalClinic' ? [...providersArraySAM] : [...RXFromTo]
            // console.log('range data ', providerDatas,fromdate,todate)

            for (let i = 0; i < providerDatas[provider].length; i++) {
                // alert(i)
                let m = 0
                for (let j = Number(fromdate); j <= Number(todate); j++) {
                    // console.log('i,j ', i, j)

                    // console.log('[provider data ', providerDatas[provider][i][j])
                    fromToDateProvider[i].data[m++] = providerDatas[provider][i][j]
                }
            }
            // console.log('fromtoprovider', fromToDateProvider)
            // console.log('fromtoprovide1111r', fromToDateMonths)
            // console.log('fromtoprovider1', data)

            // for(let i = fromdate ; i < todate ; i++){
            //     setMonth(prev => [...prev,fromDate[i]])
            // }
            // setFromDateTemp(fromdate)
            // setToDateTemp(todate)
            // setproviderTemp(provider)
            // setData([...fromToDateProvider])

            setData([...fromToDateProvider])
            setTimeout(() => {
                setisShow(false)
            }, 500);
        } catch (e) {
            console.error('error ihere ', e.toString())
            setTimeout(() => {
                setisShow(false)
            }, 500);
        }
    }
    // console.log('fromtoprovider11', data)

    var fromToDateProviderOptions = {
        chart: {
            type: 'bar',
            height: 450,
            stacked: true,
        },
        plotOptions: {
            bar: {
                horizontal: changeBar,
            },
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        title: {
            text: provider === 0 && session === 'PersonalClinic' ? 'Cha, Hyojoon' :
                provider === 1 && session === 'PersonalClinic' ? 'Desai, Manish  ' :
                    provider === 2 && session === 'PersonalClinic' ? 'Jain, Sheeja ' :
                        provider === 3 && session === 'PersonalClinic' ? 'Jain, Shishir ' :
                            provider === 4 && session === 'PersonalClinic' ? 'Kraft, Norma' :
                                provider === 5 && session === 'PersonalClinic' ? 'Mehta, Mukundini' :
                                    provider === 1 && session === 'RX' ? 'Provider1' :
                                        provider === 2 && session === 'RX' ? 'Provider2' :
                                            provider === 3 && session === 'RX' ? 'Provider3' :
                                                provider === 4 && session === 'RX' ? 'Provider4' :
                                                    provider === 5 && session === 'RX' ? 'Provider5' :
                                                        provider === 6 && session === 'RX' ? 'Provider6' :
                                                            provider === 7 && session === 'RX' ? 'Provider7' :
                                                                ''

        },
        xaxis: {
            categories: [...fromToDateMonths],
            labels: {
                formatter: function (val) {
                    return val
                }
            },
        },
        yaxis: {
            title: {
                text: undefined
            },
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val
                }
            }
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
        }
    };
    let handleOverAll = () => {
        setshowOverAll(true)
        setQuarters('')
        setProvider('All Providers')
        setFacility('')
        setMonth('')
        setfromToDateChart(false)
        // setProviderFromdate('')
        setFromDate('')
        setToDate('')
    }
    console.log(" Providers && Month :", Providers && Month )
    console.log("Quarter :",quarters)
    return (
        < >
            <ToastContainer autoClose={2000} position='top-right' />
            {
                session === 'Aurora' ? <span className='text-center fs-2 fw-bolder'>Ramesh Bathina</span> : <span className='text-center fs-2 fw-bolder' style={{ textShadow: '0px 5px 5px grey' }}> {
                    Providers === 'All Providers' ? 'All Providers'
                        : Providers === 'Cha, Hyojoon' ? 'Cha, Hyojoon'
                            : Providers === 'Desai, Manish' ? 'Desai, Manish'
                                : Providers === 'Jain, Sheeja' ? 'Jain, Sheeja'
                                    : Providers === 'Jain, Shishir' ? 'Jain, Shishir' :
                                        Providers === 'Kraft, Norma' ? 'Kraft, Norma' :
                                            Providers === 'Mehta, Mukundini' ? 'Mehta, Mukundini' :
                                                Providers === 'Provider1' ? 'Provider 1' :
                                                    Providers === 'Provider2' ? 'Provider 2' :
                                                        Providers === 'Provider3' ? 'Provider 3' :
                                                            Providers === 'Provider4' ? 'Provider 4' :
                                                                Providers === 'Provider5' ? 'Provider 5' :
                                                                    Providers === 'Provider6' ? 'Provider 6' :
                                                                        Providers === 'Provider7' ? 'Provider 7'

                                                                    :

                                                                    Month === 'Jan-21' ? 'January 2021'
                                                                        : Month === 'Feb-21' ? 'February 2021'
                                                                            : Month === 'Mar-21' ? 'March 2021'
                                                                                : Month === 'Apr-21' ? 'April 2021'
                                                                                    : Month === 'May-21' ? 'May 2021'
                                                                                        : Month === 'Jun-21' ? 'June 2021'
                                                                                            : Month === 'Jul-21' ? 'July 2021'
                                                                                                : Month === 'Aug-21' ? 'August 2021'
                                                                                                    : Month === 'Sep-21' ? 'September 2021'
                                                                                                        : Month === 'Oct-21' ? 'October 2021'
                                                                                                            : Month === 'Nov-21' ? 'November 2021'
                                                                                                                : Month === 'Dec-21' ? 'December 2021'
                                                                                                                    : Month === 'Jan-22' ? 'January 2022'
                                                                                                                        : Month === 'Feb-22' ? 'February 2022'
                                                                                                                            : Month === 'Mar-22' ? 'March 2022'
                                                                                                                                : Month === 'Apr-22' ? 'April 2022'
                                                                                                                                    : Month === 'May-22' ? 'May 2022'
                                                                                                                                        : Month === 'Jun-22' ? 'June 2022'
                                                                                                                                            : Month === 'Jul-22' ? 'July 2022'
                                                                                                                                                : Month === 'Aug-22' ? 'August 2022'
                                                                                                                                                    : Month === 'Sep-22' ? 'September 2022'
                                                                                                                                                        : Month === 'Oct-22' ? 'October 2022'
                                                                                                                                                            : Month === 'Nov-22' ? 'November 2022'
                                                                                                                                                                : Month === 'Dec-22' ? 'December 2022'
                                                                                                                                                                    : Month === 'Jan-23' ? 'January 2023'
                                                                                                                                                                        : Month === 'Feb-23' ? 'February 2023'
                                                                                                                                                                            : Month === 'Mar-23' ? 'March 2023'
                                                                                                                                                                                : Month === 'Apr-23' ? 'April 2023'
                                                                                                                                                                                    : Month === 'May-23' ? 'May 2023'
                                                                                                                                                                                        : Month === 'Jun-23' ? 'June 2023'
                                                                                                                                                                                            : Month === 'Jul-23' ? 'July 2023'
                                                                                                                                                                                                : Month === 'Aug-23' ? 'August 2023'
                                                                                                                                                                                                    : Month === 'Sep-23' ? 'September 2023'
                                                                                                                                                                                                        : Month === 'Oct-23' ? 'October 2023'
                                                                                                                                                                                                            : Month === 'Nov-23' ? 'November 2023'  
                                                                                                                                                                                                                : Month === 'Dec-23' ? 'December 2023'
                                                                                                                                                                                                                    : Month === 'Jan-24' ? 'January 2024'
                                                                                                                                                                                                                        : Month === 'Feb-24' ? 'February 2024'  
                                                                                                                                                                                                                            : Month === 'Mar-24' ? 'March 2024'
                                                                                                                                                                                                                                : Month === 'Apr-24' ? 'April 2024'   
                                                                                                                                                                                                                                    : Month === 'May-24' ? 'May 2024' 
                                                                                                                                                                                                                                        : Month === 'Jun-24' ? 'June 2024'
                                                                                                                                                                                                                                            : Month === 'Jul-24' ? 'July 2024'
                                                                                                                                                                                                                                                : Month === 'Aug-24' ? 'August 2024'
                                                                                                                                                                                                                                                    : ''
                }</span>
            },
            <ModalLoader isShow={isShow} />
            <Row>
                <Col>
                    <h5 className='text0start m-2'>View by Provider/Month-Yr/Quarter :</h5>
                    <Row className='m-1 border border-1 rounded'>
                        {
                            session === 'Aurora' ? '' : <Col md='4' className='border0end'>
                                <label className='mb-2 fs-4 '>Filter By Prov..</label>
                                <select className='form-control mb-2' value={Providers}
                                    onChange={(e) => session === 'PersonalClinic' ? handleProviders(e) : rxhandleProviders(e)}>
                                    {proValues.map(val => {
                                        return <option value={val}>{val}</option>
                                    })}
                                </select>
                            </Col>
                        }
                        {
                            session === 'Aurora' || session === 'PersonalClinic' ? '' : <Col md='4' className='border0end'>
                                <label className='mb-2 fs-4 '>Filter By Facility</label>
                                <select className='form-control mb-2' value={Facility}
                                    onChange={(e) => handleFacility(e)}>
                                    {facilityValues.map(val => {
                                        return <option value={val}>{val}</option>
                                    })}
                                </select>
                            </Col>
                        }
                        <Col md='4' className=' border-end'>
                            <label className='mb-2 fs-4 '>Filter By month</label>
                            <select className='form-control mb-2' value={Month}
                                onChange={(e) => session === 'Aurora' || session === 'PersonalClinic' ? handleMonth(e) : rxhandleMonth(e)}>
                                {months.map(val => {
                                    return <option value={val}>{val}</option>
                                })}
                            </select>
                        </Col>
                        <Col md='4'>
                            <label className='mb-2 fs-4 '>Filter By Quarter</label>
                            <select className='form-control mb-2' value={quarters} onChange={(e) => session === 'Aurora' || session === 'PersonalClinic' ? handleQuaters(e) : rxhandleQuaters(e)}>
                                <option value=''>Select Quarter</option>
                                {quaters.map((val, i) => {
                                    return <option value={i}
                                        disabled={sessionStorage.getItem('name') === 'RX' ? false : sessionStorage.getItem('name') ===
                                            'Aurora' && i > 7 ? false : sessionStorage.getItem('name') ===
                                                'PersonalClinic' && i > 4 ? false : true}>{val}</option>
                                })}
                            </select>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <h5 className='text0start m-2'>Provider with specific start and end month :</h5>
                    <Row className=' m01 border border-1 rounded'>
                        {
                            session === 'Aurora' ? '' : <Col>
                                <label className='mb-2 fs-4 '>Filter By Prov..</label>
                                <select className='form-control mb-2' value={provider} onChange={(e) => setProviderFromdate(e.target.value)}>
                                    {proValues.map((val, ind) => {
                                        // console.log(`val : {al and ind : {nd`);
                                        return <option disabled={ind === 1 || ind === 0 ? true : false} value={ind - 2}>{val}</option>
                                        
                                    })}
                                </select>
                            </Col>
                        }
                        <Col md='3' className='border-start border-end'>
                            <label className='mb-2 text-start fs-4' >From Date</label>
                            <select
                                value={fromdate} className={`{showborder ? 'border-danger' : ''} form-control mb-2 `}
                                onChange={(e) => setFromDate(e.target.value)}>
                                <option value='select from date'>Select FromDate</option>

                                {[...fromDate].map((val, i) => {
                                    return <option
                                        value={i}
                                    // disabled={sessionStorage.getItem('name') !== 'RX' && i > 11 ?
                                    //     false : true
                                    // }
                                    >
                                        {val}
                                    </option>
                                })}
                            </select>
                        </Col>
                        <Col md='3' className='border-start '>
                            <label className='mb-2 text-start fs-4' >To Date</label>
                            <select value={todate} className={`${showborder ? 'border-danger' : ''} form-control mb-2 `} onChange={(e) => setToDate(e.target.value)}>

                                <option value='select todate'>Select ToDate</option>
                                {[...toDates].map((val, i) => {
                                    return <option value={i}>{val}</option>
                                })}
                            </select>
                        </Col>
                        <Col md='2'>
                            <Button
                                style={{ background: '#377FC3', marginTop: '42px' }}
                                className=' btn form0control'
                                disabled={!fromdate || !todate ? true : false}
                                onClick={() => handleGridForProviders()}>
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className='mt-2'>
                <Col md='5'>
                    <Button className='btn' style={{ backgroundColor: '#3498db' }} onClick={() => handleOverAll()}>Show OverAll Data </Button>

                    <Button className='btn mx-1' style={{ backgroundColor: '#3498db' }} onClick={() => setHorizontal(prev => !prev)}>Vertical/Horizontal View </Button>
                </Col>
            </Row>
            <div className="mt-4 position0relative" style={{ height: '550px' }}>
            {/* {console.log("Providers && Facility && Month:",Providers && Facility && Month)} */}
                <Chart
                    options={ session === 'Aurora' || session === 'PersonalClinic' ? ( Month ? optionsForMOnth : quarters ? quartersOptions :
                        fromToDateChart ? fromToDateProviderOptions : 
                        // showOverAll || Providers || Facility ? options : ''}
                        showOverAll || Providers ? options :'' )
                    : ((Providers && Facility && Month) || (Providers && Month) ? optionsForProviderWithMonth : Providers && quarters ? quartersOptions :
                    fromToDateChart ? fromToDateProviderOptions : 
                    // showOverAll || Providers || Facility ? options : ''}
                    Providers && Facility ? facilityOptions : showOverAll || Providers ? options :'' )}

                        
                    series={ session === 'Aurora' || session === 'PersonalClinic' ?
                    (showOverAll ? series : 
                        // Facility === 'Facility-1 in All Providers' && session === 'RX' ? allF1Charge :
                            // Month === 'Jan-20' && session === 'Aurora' ? jan2020Series :
                            //     Month === 'Feb-20' && session === 'Aurora' ? feb2020Series :
                            //         Month === 'Mar-20' && session === 'Aurora' ? mar2020Series :
                            //             Month === 'Apr-20' && session === 'Aurora' ? apr2020Series :
                            //                 Month === 'May-20' && session === 'Aurora' ? may2020Series :
                            //                     Month === 'Jun-20' && session === 'Aurora' ? june2020Series :
                            //                         Month === 'Jul-20' && session === 'Aurora' ? jul2020Series :
                            //                             Month === 'Aug-20' && session === 'Aurora' ? aug2020Series :
                            //                                 Month === 'Sep-20' && session === 'Aurora' ? sept2020Series :
                            //                                     Month === 'Oct-20' && session === 'Aurora' ? oct2020Series :
                            //                                         Month === 'Nov-20' && session === 'Aurora' ? nov2020Series :
                            //                                             Month === 'Dec-20' && session === 'Aurora' ? dec2020Series :
                            //                                                 Month === 'Jan-21' && session === 'Aurora' ? jan2021Series :
                            //                                                     Month === 'Feb-21' && session === 'Aurora' ? feb2021Series :
                            //                                                         Month === 'Mar-21' && session === 'Aurora' ? mar2021Series :
                            //                                                             Month === 'Apr-21' && session === 'Aurora' ? apr2021Series :
                            //                                                                 Month === 'May-21' && session === 'Aurora' ? may2021Series :
                            //                                                                     Month === 'Jun-21' && session === 'Aurora' ? june2021Series :
                            //                                                                         Month === 'Jul-21' && session === 'Aurora' ? jul2021Series :
                            //                                                                             Month === 'Aug-21' && session === 'Aurora' ? aug2021Series :
                            //                                                                                 Month === 'Sep-21' && session === 'Aurora' ? sept2021Series :
                            //                                                                                     Month === 'Oct-21' && session === 'Aurora' ? oct2021Series :
                            //                                                                                         Month === 'Nov-21' && session === 'Aurora' ? nov2021Series :
                            //                                                                                             Month === 'Dec-21' && session === 'Aurora' ? dec2021Series :
                                                                                                                            Month === 'Jan-22' && session === 'Aurora' ? jan2022Series :
                                                                                                                                Month === 'Feb-22' && session === 'Aurora' ? feb2022Series :
                                                                                                                                    Month === 'Mar-22' && session === 'Aurora' ? mar2022Series :
                                                                                                                                        Month === 'Apr-22' && session === 'Aurora' ? apr2022Series :
                                                                                                                                            Month === 'May-22' && session === 'Aurora' ? may2022Series :
                                                                                                                                                Month === 'Jun-22' && session === 'Aurora' ? jun2022Series :
                                                                                                                                                    Month === 'Jul-22' && session === 'Aurora' ? jul2022Series :
                                                                                                                                                        Month === 'Aug-22' && session === 'Aurora' ? aug2022Series :
                                                                                                                                                            Month === 'Sep-22' && session === 'Aurora' ? sept2022Series :
                                                                                                                                                                Month === 'Oct-22' && session === 'Aurora' ? oct2022Series :
                                                                                                                                                                    Month === 'Nov-22' && session === 'Aurora' ? nov2022Series :
                                                                                                                                                                        Month === 'Dec-22' && session === 'Aurora' ? dec2022Series :
                                                                                                                                                                            Month === 'Jan-23' && session === 'Aurora' ? jan2023Series :
                                                                                                                                                                                Month === 'Feb-23' && session === 'Aurora' ? feb2023Series :
                                                                                                                                                                                    Month === 'Mar-23' && session === 'Aurora' ? mar2023Series :
                                                                                                                                                                                        Month === 'Apr-23' && session === 'Aurora' ? apr2023Series :
                                                                                                                                                                                            Month === 'May-23' && session === 'Aurora' ? may2023Series :
                                                                                                                                                                                                Month === 'Jun-23' && session === 'Aurora' ? jun2023Series :
                                                                                                                                                                                                    Month === 'Jul-23' && session === 'Aurora' ? jul2023Series :
                                                                                                                                                                                                        Month === 'Aug-23' && session === 'Aurora' ? aug2023Series :
                                                                                                                                                                                                            Month === 'Sep-23' && session === 'Aurora' ? sep2023Series :
                                                                                                                                                                                                                Month === 'Oct-23' && session === 'Aurora' ? oct2023Series :
                                                                                                                                                                                                                Month === 'Nov-23' && session === 'Aurora' ? nov2023Series :
                                                                                                                                                                                                                Month === 'Dec-23' && session === 'Aurora' ? dec2023Series :
                                                                                                                                                                                                                Month === 'Jan-24' && session === 'Aurora' ? jan2024Series :
                                                                                                                                                                                                                Month === 'Feb-24' && session === 'Aurora' ? feb2024Series :
                                                                                                                                                                                                                Month === 'Mar-24' && session === 'Aurora' ? mar2024Series :
                                                                                                                                                                                                                Month === 'Apr-24' && session === 'Aurora' ? apr2024Series :
                                                                                                                                                                                                                Month === 'May-24' && session === 'Aurora' ? may2024Series :
                                                                                                                                                                                                                Month === 'Jun-24' && session === 'Aurora' ? jun2024Series :
                                                                                                                                                                                                                Month === 'Jul-24' && session === 'Aurora' ? jul2024Series :
                                                                                                                                                                                                                Month === 'Aug-24' && session === 'Aurora' ? aug2024Series :
                                                                                                                                                                                                                    Month === 'Oct-20' && session === 'PersonalClinic' ? oct2020SeriesPer :
                                                                                                                                                                                                                        Month === 'Nov-20' && session === 'PersonalClinic' ? nov2020SeriesPer :
                                                                                                                                                                                                                            Month === 'Dec-20' && session === 'PersonalClinic' ? dec2020SeriesPer :
                                                                                                                                                                                                                                Month === 'Jan-21' && session === 'PersonalClinic' ? jan2021SeriesPer :
                                                                                                                                                                                                                                    Month === 'Feb-21' && session === 'PersonalClinic' ? feb2021SeriesPer :
                                                                                                                                                                                                                                        Month === 'Mar-21' && session === 'PersonalClinic' ? mar2021SeriesPer :
                                                                                                                                                                                                                                            Month === 'Apr-21' && session === 'PersonalClinic' ? apr2021SeriesPer :
                                                                                                                                                                                                                                                Month === 'May-21' && session === 'PersonalClinic' ? may2021SeriesPer :
                                                                                                                                                                                                                                                    Month === 'Jun-21' && session === 'PersonalClinic' ? june2021SeriesPer :
                                                                                                                                                                                                                                                        Month === 'Jul-21' && session === 'PersonalClinic' ? jul2021SeriesPer :
                                                                                                                                                                                                                                                            Month === 'Aug-21' && session === 'PersonalClinic' ? aug2021SeriesPer :
                                                                                                                                                                                                                                                                Month === 'Sep-21' && session === 'PersonalClinic' ? sept2021SeriesPer :
                                                                                                                                                                                                                                                                    Month === 'Oct-21' && session === 'PersonalClinic' ? oct2021SeriesPer :
                                                                                                                                                                                                                                                                        Month === 'Nov-21' && session === 'PersonalClinic' ? nov2021SeriesPer :
                                                                                                                                                                                                                                                                            Month === 'Dec-21' && session === 'PersonalClinic' ? dec2021SeriesPer :
                                                                                                                                                                                                                                                                                Month === 'Jan-22' && session === 'PersonalClinic' ? jan2022SeriesPer :
                                                                                                                                                                                                                                                                                    Month === 'Feb-22' && session === 'PersonalClinic' ? feb2022SeriesPer :
                                                                                                                                                                                                                                                                                        Month === 'Mar-22' && session === 'PersonalClinic' ? mar2022SeriesPer :
                                                                                                                                                                                                                                                                                            Month === 'Apr-22' && session === 'PersonalClinic' ? apr2022SeriesPer :
                                                                                                                                                                                                                                                                                                Month === 'May-22' && session === 'PersonalClinic' ? may2022SeriesPer :
                                                                                                                                                                                                                                                                                                    Month === 'Jun-22' && session === 'PersonalClinic' ? june2022SeriesPer :
                                                                                                                                                                                                                                                                                                        Month === 'Jul-22' && session === 'PersonalClinic' ? jul2022SeriesPer :
                                                                                                                                                                                                                                                                                                            Month === 'Aug-22' && session === 'PersonalClinic' ? aug2022SeriesPer :
                                                                                                                                                                                                                                                                                                                Month === 'Sep-22' && session === 'PersonalClinic' ? sept2022SeriesPer :
                                                                                                                                                                                                                                                                                                                    Month === 'Oct-22' && session === 'PersonalClinic' ? oct2022SeriesPer :
                                                                                                                                                                                                                                                                                                                        Month === 'Nov-22' && session === 'PersonalClinic' ? nov2022SeriesPer :
                                                                                                                                                                                                                                                                                                                            Month === 'Dec-22' && session === 'PersonalClinic' ? dec2022SeriesPer :
                                                                                                                                                                                                                                                                                                                                Month === 'Jan-23' && session === 'PersonalClinic' ? jan2023SeriesPer :
                                                                                                                                                                                                                                                                                                                                    Month === 'Feb-23' && session === 'PersonalClinic' ? feb2023SeriesPer :
                                                                                                                                                                                                                                                                                                                                        Month === 'Mar-23' && session === 'PersonalClinic' ? mar2023SeriesPer :
                                                                                                                                                                                                                                                                                                                                            Month === 'Apr-23' && session === 'PersonalClinic' ? apr2023SeriesPer :
                                                                                                                                                                                                                                                                                                                                                Month === 'May-23' && session === 'PersonalClinic' ? may2023SeriesPer :
                                                                                                                                                                                                                                                                                                                                                    Month === 'Jun-23' && session === 'PersonalClinic' ? jun2023SeriesPer :
                                                                                                                                                                                                                                                                                                                                                        Month === 'Jul-23' && session === 'PersonalClinic' ? jul2023SeriesPer :
                                                                                                                                                                                                                                                                                                                                                            Month === 'Aug-23' && session === 'PersonalClinic' ? aug2023SeriesPer :
                                                                                                                                                                                                                                                                                                                                                                Month === 'Sep-23' && session === 'PersonalClinic' ? sep2023SeriesPer :
                                                                                                                                                                                                                                                                                                                                                                    Month === 'Oct-23' && session === 'PersonalClinic' ? oct2023SeriesPer :
                                                                                                                                                                                                                                                                                                                                                                    Month === 'Nov-23' && session === 'PersonalClinic' ? nov2023SeriesPer :
                                                                                                                                                                                                                                                                                                                                                                    Month === 'Dec-23' && session === 'PersonalClinic' ? dec2023SeriesPer :
                                                                                                                                                                                                                                                                                                                                                                    Month === 'Jan-24' && session === 'PersonalClinic' ? jan2024SeriesPer :
                                                                                                                                                                                                                                                                                                                                                                    Month === 'Feb-24' && session === 'PersonalClinic' ? feb2024SeriesPer :
                                                                                                                                                                                                                                                                                                                                                                    Month === 'Mar-24' && session === 'PersonalClinic' ? mar2024SeriesPer :
                                                                                                                                                                                                                                                                                                                                                                    Month === 'Apr-24' && session === 'PersonalClinic' ? apr2024SeriesPer :
                                                                                                                                                                                                                                                                                                                                                                    Month === 'May-24' && session === 'PersonalClinic' ? may2024SeriesPer :
                                                                                                                                                                                                                                                                                                                                                                    Month === 'Jun-24' && session === 'PersonalClinic' ? jun2024SeriesPer :
                                                                                                                                                                                                                                                                                                                                                                    Month === 'Jul-24' && session === 'PersonalClinic' ? jul2024SeriesPer :
                                                                                                                                                                                                                                                                                                                                                                    Month === 'Aug-24' && session === 'PersonalClinic' ? aug2024SeriesPer :
                                                                                                                                                                                                                                                                                                                                                                        // Month === 'Jan-21' ? jan2021SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //     Month === 'Feb-21' ? feb2021SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //         Month === 'Mar-21' ? mar2021SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //             Month === 'Apr-21' ? apr2021SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //                 Month === 'May-21' ? may2021SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //                     Month === 'Jun-21' ? june2021SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //                         Month === 'Jul-21' ? jul2021SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //                             Month === 'Aug-21' ? aug2021SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //                                 Month === 'Sep-21' ? sept2021SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //                                     Month === 'Oct-21' ? oct2021SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //                                         Month === 'Nov-21' ? nov2021SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //                                             Month === 'Dec-21' ? dec2021SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //                                                 Month === 'Jan-22' ? jan2022SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //                                                     Month === 'Feb-22' ? feb2022SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //                                                         Month === 'Mar-22' ? mar2022SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //                                                             Month === 'Apr-22' ? apr2022SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //                                                                 Month === 'May-22' ? may2022SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //                                                                     Month === 'Jun-22' ? jun2022SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //                                                                         Month === 'Jul-22' ? jul2022SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //                                                                             Month === 'Aug-22' ? aug2022SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //                                                                                 Month === 'Sep-22' ? sept2022SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //                                                                                     Month === 'Oct-22' ? oct2022SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //                                                                                         Month === 'Nov-22' ? nov2022SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //                                                                                             Month === 'Dec-22' ? dec2022SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //                                                                                                 Month === 'Jan-23' ? jan2023SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //                                                                                                     Month === 'Feb-23' ? feb2023SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //                                                                                                         Month === 'Mar-23' ? mar2023SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //                                                                                                             Month === 'Apr-23' ? apr2023SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //                                                                                                                 Month === 'May-23' ? may2023SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //                                                                                                                     Month === 'Jun-23' ? jun2023SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //                                                                                                                         Month === 'Jul-23' ? jul2023SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //                                                                                                                             Month === 'Aug-23' ? aug2023SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //                                                                                                                                 Month === 'Sep-23' ? sep2023SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //                                                                                                                                     Month === 'Oct-23' ? oct2023SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                        //                                                                                                                                         Month === 'Nov-23' ? nov2023SeriesRX :
                                                                                                                                                                                                                                                                                                                                                                                                                                                quarters === '0' && session === 'PersonalClinic' ? firstChargesSAM :
                                                                                                                                                                                                                                                                                                                                                                                                                                                    quarters === '1' && session === 'PersonalClinic' ? secondChargesSAM :
                                                                                                                                                                                                                                                                                                                                                                                                                                                        quarters === '2' && session === 'PersonalClinic' ? thirdChargesSAM :
                                                                                                                                                                                                                                                                                                                                                                                                                                                            quarters === '3' && session === 'PersonalClinic' ? fourthChargesSAM :
                                                                                                                                                                                                                                                                                                                                                                                                                                                                quarters === '4' && session === 'PersonalClinic' ? fifthChargesSAM :
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    quarters === '5' && session === 'PersonalClinic' ? sixthChargesSAM :
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        quarters === '6' && session === 'PersonalClinic' ? sevethChargesSAM :
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            quarters === '7' && session === 'PersonalClinic' ? eightChargesSAM :
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                quarters === '8' && session === 'PersonalClinic' ? nineChargesSAM :
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    quarters === '9' && session === 'PersonalClinic' ? tenthChargesSAM :
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        quarters === '10' && session === 'PersonalClinic' ? eleventhChargesSAM :
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            quarters === '11' && session === 'PersonalClinic' ? twelvethChargesSAM :
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                quarters === '12' && session === 'PersonalClinic' ? thirteenthChargesSAM :
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    quarters === '13' && session === 'PersonalClinic' ? fourteenthChargesSAM :
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        quarters === '14' && session === 'PersonalClinic' ? fifteenthChargesSAM :
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            quarters === '15' && session === 'PersonalClinic' ? sixteenthChargesSAM :
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                quarters === '8' && session === 'Aurora' ? ninethCharges :
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    quarters === '9' && session === 'Aurora' ? tenthCharges :
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        quarters === '10' && session === 'Aurora' ? eleventhCharges :
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            quarters === '11' && session === 'Aurora' ? twelvethCharges :
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                quarters === '12' && session === 'Aurora' ? thirteenCharges :
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    quarters === '13' && session === 'Aurora' ? fourteenCharges :
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        quarters === '14' && session === 'Aurora' ? fifteenCharges :
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            quarters === '15' && session === 'Aurora' ? sixteenCharges :
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                quarters === '16' && session === 'Aurora' ? seventeenCharges :
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    quarters === '17' && session === 'Aurora' ? eighteenCharges :
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        quarters === '18' && session === 'Aurora' ? nineteenCharges :
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            fromToDateChart ? data :'') 
                    
                    : ( Providers && Facility && Month ? (Facility === 'All Facilities' ? totalFacilityWithMonth : providersMonthFacility):
                        // Providers && Facility && Month ? providersMonthFacility : 
                        Providers && Month ? providersInMonth :
                            // 26-12-2023 begining
                        Month === 'Jan-22' && session === 'Aurora' ? jan2022Series :
                            Month === 'Feb-22' && session === 'Aurora' ? feb2022Series :
                                Month === 'Mar-22' && session === 'Aurora' ? mar2022Series :
                                    Month === 'Apr-22' && session === 'Aurora' ? apr2022Series :
                                        Month === 'May-22' && session === 'Aurora' ? may2022Series :
                                            Month === 'Jun-22' && session === 'Aurora' ? jun2022Series :
                                                Month === 'Jul-22' && session === 'Aurora' ? jul2022Series :
                                                    Month === 'Aug-22' && session === 'Aurora' ? aug2022Series :
                                                        Month === 'Sep-22' && session === 'Aurora' ? sept2022Series :
                                                            Month === 'Oct-22' && session === 'Aurora' ? oct2022Series :
                                                                Month === 'Nov-22' && session === 'Aurora' ? nov2022Series :
                                                                    Month === 'Dec-22' && session === 'Aurora' ? dec2022Series :
                                                                        Month === 'Jan-23' && session === 'Aurora' ? jan2023Series :
                                                                            Month === 'Feb-23' && session === 'Aurora' ? feb2023Series :
                                                                                Month === 'Mar-23' && session === 'Aurora' ? mar2023Series :
                                                                                    Month === 'Apr-23' && session === 'Aurora' ? apr2023Series :
                                                                                        Month === 'May-23' && session === 'Aurora' ? may2023Series :
                                                                                            Month === 'Jun-23' && session === 'Aurora' ? jun2023Series :
                                                                                                Month === 'Jul-23' && session === 'Aurora' ? jul2023Series :
                                                                                                    Month === 'Aug-23' && session === 'Aurora' ? aug2023Series :
                                                                                                        Month === 'Sep-23' && session === 'Aurora' ? sep2023Series :
                                                                                                            Month === 'Oct-23' && session === 'Aurora' ? oct2023Series :
                                                                                                            Month === 'Nov-23' && session === 'Aurora' ? nov2023Series :
                                                                                                                Month === 'Oct-20' && session === 'PersonalClinic' ? oct2020SeriesPer :
                                                                                                                    Month === 'Nov-20' && session === 'PersonalClinic' ? nov2020SeriesPer :
                                                                                                                        Month === 'Dec-20' && session === 'PersonalClinic' ? dec2020SeriesPer :
                                                                                                                            Month === 'Jan-21' && session === 'PersonalClinic' ? jan2021SeriesPer :
                                                                                                                                Month === 'Feb-21' && session === 'PersonalClinic' ? feb2021SeriesPer :
                                                                                                                                    Month === 'Mar-21' && session === 'PersonalClinic' ? mar2021SeriesPer :
                                                                                                                                        Month === 'Apr-21' && session === 'PersonalClinic' ? apr2021SeriesPer :
                                                                                                                                            Month === 'May-21' && session === 'PersonalClinic' ? may2021SeriesPer :
                                                                                                                                                Month === 'Jun-21' && session === 'PersonalClinic' ? june2021SeriesPer :
                                                                                                                                                    Month === 'Jul-21' && session === 'PersonalClinic' ? jul2021SeriesPer :
                                                                                                                                                        Month === 'Aug-21' && session === 'PersonalClinic' ? aug2021SeriesPer :
                                                                                                                                                            Month === 'Sep-21' && session === 'PersonalClinic' ? sept2021SeriesPer :
                                                                                                                                                                Month === 'Oct-21' && session === 'PersonalClinic' ? oct2021SeriesPer :
                                                                                                                                                                    Month === 'Nov-21' && session === 'PersonalClinic' ? nov2021SeriesPer :
                                                                                                                                                                        Month === 'Dec-21' && session === 'PersonalClinic' ? dec2021SeriesPer :
                                                                                                                                                                            Month === 'Jan-22' && session === 'PersonalClinic' ? jan2022SeriesPer :
                                                                                                                                                                                Month === 'Feb-22' && session === 'PersonalClinic' ? feb2022SeriesPer :
                                                                                                                                                                                    Month === 'Mar-22' && session === 'PersonalClinic' ? mar2022SeriesPer :
                                                                                                                                                                                        Month === 'Apr-22' && session === 'PersonalClinic' ? apr2022SeriesPer :
                                                                                                                                                                                            Month === 'May-22' && session === 'PersonalClinic' ? may2022SeriesPer :
                                                                                                                                                                                                Month === 'Jun-22' && session === 'PersonalClinic' ? june2022SeriesPer :
                                                                                                                                                                                                    Month === 'Jul-22' && session === 'PersonalClinic' ? jul2022SeriesPer :
                                                                                                                                                                                                        Month === 'Aug-22' && session === 'PersonalClinic' ? aug2022SeriesPer :
                                                                                                                                                                                                            Month === 'Sep-22' && session === 'PersonalClinic' ? sept2022SeriesPer :
                                                                                                                                                                                                                Month === 'Oct-22' && session === 'PersonalClinic' ? oct2022SeriesPer :
                                                                                                                                                                                                                    Month === 'Nov-22' && session === 'PersonalClinic' ? nov2022SeriesPer :
                                                                                                                                                                                                                        Month === 'Dec-22' && session === 'PersonalClinic' ? dec2022SeriesPer :
                                                                                                                                                                                                                            Month === 'Jan-23' && session === 'PersonalClinic' ? jan2023SeriesPer :
                                                                                                                                                                                                                                Month === 'Feb-23' && session === 'PersonalClinic' ? feb2023SeriesPer :
                                                                                                                                                                                                                                    Month === 'Mar-23' && session === 'PersonalClinic' ? mar2023SeriesPer :
                                                                                                                                                                                                                                        Month === 'Apr-23' && session === 'PersonalClinic' ? apr2023SeriesPer :
                                                                                                                                                                                                                                            Month === 'May-23' && session === 'PersonalClinic' ? may2023SeriesPer :
                                                                                                                                                                                                                                                Month === 'Jun-23' && session === 'PersonalClinic' ? jun2023SeriesPer :
                                                                                                                                                                                                                                                    Month === 'Jul-23' && session === 'PersonalClinic' ? jul2023SeriesPer :
                                                                                                                                                                                                                                                        Month === 'Aug-23' && session === 'PersonalClinic' ? aug2023SeriesPer :
                                                                                                                                                                                                                                                            Month === 'Sep-23' && session === 'PersonalClinic' ? sep2023SeriesPer :
                                                                                                                                                                                                                                                                Month === 'Oct-23' && session === 'PersonalClinic' ? oct2023SeriesPer :
                                                                                                                                                                                                                                                                Month === 'Nov-23' && session === 'PersonalClinic' ? nov2023SeriesPer :
                                                                                                                                                                                                                                                                    // 26-12-2023 begining
                                                                                                                                                                                                                                                                    quarters === '0' && session === 'PersonalClinic' ? firstChargesSAM :
                                                                                                                                                                                                                                                                        quarters === '1' && session === 'PersonalClinic' ? secondChargesSAM :
                                                                                                                                                                                                                                                                            quarters === '2' && session === 'PersonalClinic' ? thirdChargesSAM :
                                                                                                                                                                                                                                                                                quarters === '3' && session === 'PersonalClinic' ? fourthChargesSAM :
                                                                                                                                                                                                                                                                                    quarters === '4' && session === 'PersonalClinic' ? fifthChargesSAM :
                                                                                                                                                                                                                                                                                        quarters === '5' && session === 'PersonalClinic' ? sixthChargesSAM :
                                                                                                                                                                                                                                                                                            quarters === '6' && session === 'PersonalClinic' ? sevethChargesSAM :
                                                                                                                                                                                                                                                                                                quarters === '7' && session === 'PersonalClinic' ? eightChargesSAM :
                                                                                                                                                                                                                                                                                                    quarters === '8' && session === 'PersonalClinic' ? nineChargesSAM :
                                                                                                                                                                                                                                                                                                        quarters === '9' && session === 'PersonalClinic' ? tenthChargesSAM :
                                                                                                                                                                                                                                                                                                            quarters === '10' && session === 'PersonalClinic' ? eleventhChargesSAM :
                                                                                                                                                                                                                                                                                                                quarters === '11' && session === 'PersonalClinic' ? twelvethChargesSAM :
                                                                                                                                                                                                                                                                                                                    quarters === '12' && session === 'PersonalClinic' ? thirteenthChargesSAM :
                                                                                                                                                                                                                                                                                                                        // quarters === '6' && session === 'Aurora' ? seventhCharges :
                                                                                                                                                                                                                                                                                                                        //     quarters === '7' && session === 'Aurora' ? eighthCharges :
                                                                                                                                                                                                                                                                                                                                quarters === '8' && session === 'Aurora' ? ninethCharges :
                                                                                                                                                                                                                                                                                                                                    quarters === '9' && session === 'Aurora' ? tenthCharges :
                                                                                                                                                                                                                                                                                                                                        quarters === '10' && session === 'Aurora' ? eleventhCharges :
                                                                                                                                                                                                                                                                                                                                            quarters === '11' && session === 'Aurora' ? twelvethCharges :
                                                                                                                                                                                                                                                                                                                                                quarters === '12' && session === 'Aurora' ? thirteenCharges :
                                                                                                                                                                                                                                                                                                                                                    quarters === '13' && session === 'Aurora' ? fourteenCharges :
                                                                                                                                                                                                                                                                                                                                                        quarters === '14' && session === 'Aurora' ? fifteenCharges :
                                                                                                                                                                                                                                                                                                                                                            quarters === '15' && session === 'Aurora' ? sixteenCharges :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'All Providers' && quarters === '0' ? firQuarOf21InAll :    // 26-12-2023 end
                                                                                                                                                                                                                                                                                                                                                                Providers === 'All Providers' && quarters === '1' ? secQuarOf21InAll :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'All Providers' && quarters === '2' ? thiQuarOf21InAll :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'All Providers' && quarters === '3' ? fouQuarOf21InAll :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'All Providers' && quarters === '4' ? firQuarOf22InAll :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'All Providers' && quarters === '5' ? secQuarOf22InAll :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'All Providers' && quarters === '6' ? thiQuarOf22InAll :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'All Providers' && quarters === '7' ? fouQuarOf22InAll :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'All Providers' && quarters === '8' ? firQuarOf23InAll :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'All Providers' && quarters === '9' ? secQuarOf23InAll :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'All Providers' && quarters === '10' ? thiQuarOf23InAll :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'All Providers' && quarters === '11' ? fouQuarOf23InAll :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider1' && quarters === '0' ? firQuarOf21InPro1 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider1' && quarters === '1' ? secQuarOf21InPro1 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider1' && quarters === '2' ? thiQuarOf21InPro1 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider1' && quarters === '3' ? fouQuarOf21InPro1 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider1' && quarters === '4' ? firQuarOf22InPro1 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider1' && quarters === '5' ? secQuarOf22InPro1 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider1' && quarters === '6' ? thiQuarOf22InPro1 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider1' && quarters === '7' ? fouQuarOf22InPro1 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider1' && quarters === '8' ? firQuarOf23InPro1 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider1' && quarters === '9' ? secQuarOf23InPro1 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider1' && quarters === '10' ? thiQuarOf23InPro1 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider1' && quarters === '11' ? fouQuarOf23InPro1 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider2' && quarters === '0' ? firQuarOf21InPro2 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider2' && quarters === '1' ? secQuarOf21InPro2 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider2' && quarters === '2' ? thiQuarOf21InPro2 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider2' && quarters === '3' ? fouQuarOf21InPro2 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider2' && quarters === '4' ? firQuarOf22InPro2 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider2' && quarters === '5' ? secQuarOf22InPro2 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider2' && quarters === '6' ? thiQuarOf22InPro2 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider2' && quarters === '7' ? fouQuarOf22InPro2 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider2' && quarters === '8' ? firQuarOf23InPro2 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider2' && quarters === '9' ? secQuarOf23InPro2 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider2' && quarters === '10' ? thiQuarOf23InPro2 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider2' && quarters === '11' ? fouQuarOf23InPro2 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider3' && quarters === '0' ? firQuarOf21InPro3 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider3' && quarters === '1' ? secQuarOf21InPro3 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider3' && quarters === '2' ? thiQuarOf21InPro3 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider3' && quarters === '3' ? fouQuarOf21InPro3 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider3' && quarters === '4' ? firQuarOf22InPro3 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider3' && quarters === '5' ? secQuarOf22InPro3 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider3' && quarters === '6' ? thiQuarOf22InPro3 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider3' && quarters === '7' ? fouQuarOf22InPro3 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider3' && quarters === '8' ? firQuarOf23InPro3 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider3' && quarters === '9' ? secQuarOf23InPro3 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider3' && quarters === '10' ? thiQuarOf23InPro3 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider3' && quarters === '11' ? fouQuarOf23InPro3 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider4' && quarters === '0' ? firQuarOf21InPro4 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider4' && quarters === '1' ? secQuarOf21InPro4 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider4' && quarters === '2' ? thiQuarOf21InPro4 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider4' && quarters === '3' ? fouQuarOf21InPro4 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider4' && quarters === '4' ? firQuarOf22InPro4 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider4' && quarters === '5' ? secQuarOf22InPro4 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider4' && quarters === '6' ? thiQuarOf22InPro4 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider4' && quarters === '7' ? fouQuarOf22InPro4 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider4' && quarters === '8' ? firQuarOf23InPro4 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider4' && quarters === '9' ? secQuarOf23InPro4 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider4' && quarters === '10' ? thiQuarOf23InPro4 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider4' && quarters === '11' ? fouQuarOf23InPro4 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider5' && quarters === '0' ? firQuarOf21InPro5 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider5' && quarters === '1' ? secQuarOf21InPro5 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider5' && quarters === '2' ? thiQuarOf21InPro5 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider5' && quarters === '3' ? fouQuarOf21InPro5 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider5' && quarters === '4' ? firQuarOf22InPro5 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider5' && quarters === '5' ? secQuarOf22InPro5 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider5' && quarters === '6' ? thiQuarOf22InPro5 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider5' && quarters === '7' ? fouQuarOf22InPro5 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider5' && quarters === '8' ? firQuarOf23InPro5 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider5' && quarters === '9' ? secQuarOf23InPro5 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider5' && quarters === '10' ? thiQuarOf23InPro5 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider5' && quarters === '11' ? fouQuarOf23InPro5 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider6' && quarters === '0' ? firQuarOf21InPro6 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider6' && quarters === '1' ? secQuarOf21InPro6 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider6' && quarters === '2' ? thiQuarOf21InPro6 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider6' && quarters === '3' ? fouQuarOf21InPro6 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider6' && quarters === '4' ? firQuarOf22InPro6 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider6' && quarters === '5' ? secQuarOf22InPro6 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider6' && quarters === '6' ? thiQuarOf22InPro6 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider6' && quarters === '7' ? fouQuarOf22InPro6 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider6' && quarters === '8' ? firQuarOf23InPro6 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider6' && quarters === '9' ? secQuarOf23InPro6 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider6' && quarters === '10' ? thiQuarOf23InPro6 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider6' && quarters === '11' ? fouQuarOf23InPro6 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider7' && quarters === '0' ? firQuarOf21InPro7 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider7' && quarters === '1' ? secQuarOf21InPro7 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider7' && quarters === '2' ? thiQuarOf21InPro7 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider7' && quarters === '3' ? fouQuarOf21InPro7 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider7' && quarters === '4' ? firQuarOf22InPro7 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider7' && quarters === '5' ? secQuarOf22InPro7 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider7' && quarters === '6' ? thiQuarOf22InPro7 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider7' && quarters === '7' ? fouQuarOf22InPro7 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider7' && quarters === '8' ? firQuarOf23InPro7 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider7' && quarters === '9' ? secQuarOf23InPro7 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider7' && quarters === '10' ? thiQuarOf23InPro7 :
                                                                                                                                                                                                                                                                                                                                                                Providers === 'Provider7' && quarters === '11' ? fouQuarOf23InPro7 :
                                                                                                                                                                                                                                                                                                                                                                showOverAll ? series :
                                                                                                                                                                                                                                                                                                                                                                fromToDateChart ? data : facilitySeries)
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
                    }
                    type="bar"
                    height="515px" />
            </div>
        </>
    )
}

export default NewProvider