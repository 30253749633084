import {    pro3F1Charge,pro3F1Payment,pro3F1Collectable,pro3F1NonCollectable,pro3F1ClaimCount,pro3F1PerVisit,
    pro3F2Charge,pro3F2Payment,pro3F2Collectable,pro3F2NonCollectable,pro3F2ClaimCount,pro3F2PerVisit,
    pro3F3Charge,pro3F3Payment,pro3F3Collectable,pro3F3NonCollectable,pro3F3ClaimCount,pro3F3PerVisit,} from './FacilityData';


import {provider3PerVisist} from './ProvidersAndFacility';



export const pro3WithJan21Charges = [
    pro3F1Charge.jan21,
    pro3F2Charge.jan21,
    pro3F3Charge.jan21,
    pro3F1Charge.jan21 + pro3F2Charge.jan21 + pro3F3Charge.jan21

]
// console.log("pro3WithJan21Charges :",pro3WithJan21Charges)
export const pro3WithJan21Pay = [
    pro3F1Payment.jan21,
    pro3F2Payment.jan21,
    pro3F3Payment.jan21,
    pro3F1Payment.jan21 + pro3F2Payment.jan21 + pro3F3Payment.jan21
]
export const pro3WithJan21Count = [
    pro3F1Collectable.jan21,
    pro3F2Collectable.jan21,
    pro3F3Collectable.jan21,
    pro3F1Collectable.jan21 + pro3F2Collectable.jan21 + pro3F3Collectable.jan21
]
export const pro3WithJan21NonCount = [
    pro3F1NonCollectable.jan21,
    pro3F2NonCollectable.jan21,
    pro3F3NonCollectable.jan21,
    pro3F1NonCollectable.jan21 + pro3F2NonCollectable.jan21 + pro3F3NonCollectable.jan21
]
export const pro3WithJan21Claim = [
    pro3F1ClaimCount.jan21,
    pro3F2ClaimCount.jan21,
    pro3F3ClaimCount.jan21,
    pro3F1ClaimCount.jan21 + pro3F2ClaimCount.jan21 + pro3F3ClaimCount.jan21
]
export const pro3WithJan21PerVisit = [
    pro3F1PerVisit.jan21,
    pro3F2PerVisit.jan21,
    pro3F3PerVisit.jan21,
    provider3PerVisist[0]
]
// feb 21
export const pro3WithFeb21Charges = [
    pro3F1Charge.feb21,
    pro3F2Charge.feb21,
    pro3F3Charge.feb21,
    pro3F1Charge.feb21 + pro3F2Charge.feb21 + pro3F3Charge.feb21
]
export const pro3WithFeb21Pay = [
    pro3F1Payment.feb21,
    pro3F2Payment.feb21,
    pro3F3Payment.feb21,
    pro3F1Payment.feb21 + pro3F2Payment.feb21 + pro3F3Payment.feb21
]
export const pro3WithFeb21Count = [
    pro3F1Collectable.feb21,
    pro3F2Collectable.feb21,
    pro3F3Collectable.feb21,
    pro3F1Collectable.feb21 + pro3F2Collectable.feb21 + pro3F3Collectable.feb21
]
export const pro3WithFeb21NonCount = [
    pro3F1NonCollectable.feb21,
    pro3F2NonCollectable.feb21,
    pro3F3NonCollectable.feb21,
    pro3F1NonCollectable.feb21 + pro3F2NonCollectable.feb21 + pro3F3NonCollectable.feb21
]
export const pro3WithFeb21Claim = [
    pro3F1ClaimCount.feb21,
    pro3F2ClaimCount.feb21,
    pro3F3ClaimCount.feb21,
    pro3F1ClaimCount.feb21 + pro3F2ClaimCount.feb21 + pro3F3ClaimCount.feb21
]
export const pro3WithFeb21PerVisit = [
    pro3F1PerVisit.feb21,
    pro3F2PerVisit.feb21,
    pro3F3PerVisit.feb21,
    provider3PerVisist[1]
]
// mar 21
export const pro3WithMar21Charges = [
    pro3F1Charge.mar21,
    pro3F2Charge.mar21,
    pro3F3Charge.mar21,
    pro3F1Charge.mar21 + pro3F2Charge.mar21 + pro3F3Charge.mar21
]
export const pro3WithMar21Pay = [
    pro3F1Payment.mar21,
    pro3F2Payment.mar21,
    pro3F3Payment.mar21,
    pro3F1Payment.mar21 + pro3F2Payment.mar21 + pro3F3Payment.mar21
]
export const pro3WithMar21Count = [
    pro3F1Collectable.mar21,
    pro3F2Collectable.mar21,
    pro3F3Collectable.mar21,
    pro3F1Collectable.mar21 + pro3F2Collectable.mar21 + pro3F3Collectable.mar21
]
export const pro3WithMar21NonCount = [
    pro3F1NonCollectable.mar21,
    pro3F2NonCollectable.mar21,
    pro3F3NonCollectable.mar21,
    pro3F1NonCollectable.mar21 + pro3F2NonCollectable.mar21 + pro3F3NonCollectable.mar21
]
export const pro3WithMar21Claim = [
    pro3F1ClaimCount.mar21,
    pro3F2ClaimCount.mar21,
    pro3F3ClaimCount.mar21,
    pro3F1ClaimCount.mar21 + pro3F2ClaimCount.mar21 + pro3F3ClaimCount.mar21
]
export const pro3WithMar21PerVisit = [
    pro3F1PerVisit.mar21,
    pro3F2PerVisit.mar21,
    pro3F3PerVisit.mar21,
    provider3PerVisist[2]
]
// april 21
export const pro3WithApr21Charges = [
    pro3F1Charge.apr21,
    pro3F2Charge.apr21,
    pro3F3Charge.apr21,
    pro3F1Charge.apr21 + pro3F2Charge.apr21 + pro3F3Charge.apr21
]
export const pro3WithApr21Pay = [
    pro3F1Payment.apr21,
    pro3F2Payment.apr21,
    pro3F3Payment.apr21,
    pro3F1Payment.apr21 + pro3F2Payment.apr21 + pro3F3Payment.apr21
]
export const pro3WithApr21Count = [
    pro3F1Collectable.apr21,
    pro3F2Collectable.apr21,
    pro3F3Collectable.apr21,
    pro3F1Collectable.apr21 + pro3F2Collectable.apr21 + pro3F3Collectable.apr21
]
export const pro3WithApr21NonCount = [
    pro3F1NonCollectable.apr21,
    pro3F2NonCollectable.apr21,
    pro3F3NonCollectable.apr21,
    pro3F1NonCollectable.apr21 + pro3F2NonCollectable.apr21 + pro3F3NonCollectable.apr21
]
export const pro3WithApr21Claim = [
    pro3F1ClaimCount.apr21,
    pro3F2ClaimCount.apr21,
    pro3F3ClaimCount.apr21,
    pro3F1ClaimCount.apr21 + pro3F2ClaimCount.apr21 + pro3F3ClaimCount.apr21
]
export const pro3WithApr21PerVisit = [
    pro3F1PerVisit.apr21,
    pro3F2PerVisit.apr21,
    pro3F3PerVisit.apr21,
    provider3PerVisist[3]
]
// may 21
export const pro3WithMay21Charges = [
    pro3F1Charge.may21,
    pro3F2Charge.may21,
    pro3F3Charge.may21,
    pro3F1Charge.may21 + pro3F2Charge.may21 + pro3F3Charge.may21
]
export const pro3WithMay21Pay = [
    pro3F1Payment.may21,
    pro3F2Payment.may21,
    pro3F3Payment.may21,
    pro3F1Payment.may21 + pro3F2Payment.may21 + pro3F3Payment.may21
]
export const pro3WithMay21Count = [
    pro3F1Collectable.may21,
    pro3F2Collectable.may21,
    pro3F3Collectable.may21,
    pro3F1Collectable.may21 + pro3F2Collectable.may21 + pro3F3Collectable.may21
]
export const pro3WithMay21NonCount = [
    pro3F1NonCollectable.may21,
    pro3F2NonCollectable.may21,
    pro3F3NonCollectable.may21,
    pro3F1NonCollectable.may21 + pro3F2NonCollectable.may21 + pro3F3NonCollectable.may21
]
export const pro3WithMay21Claim = [
    pro3F1ClaimCount.may21,
    pro3F2ClaimCount.may21,
    pro3F3ClaimCount.may21,
    pro3F1ClaimCount.may21 + pro3F2ClaimCount.may21 + pro3F3ClaimCount.may21
]
export const pro3WithMay21PerVisit = [
    pro3F1PerVisit.may21,
    pro3F2PerVisit.may21,
    pro3F3PerVisit.may21,
    provider3PerVisist[4]
]
// jun 21
export const pro3WithJun21Charges = [
    pro3F1Charge.jun21,
    pro3F2Charge.jun21,
    pro3F3Charge.jun21,
    pro3F1Charge.jun21 + pro3F2Charge.jun21 + pro3F3Charge.jun21
]
export const pro3WithJun21Pay = [
    pro3F1Payment.jun21,
    pro3F2Payment.jun21,
    pro3F3Payment.jun21,
    pro3F1Payment.jun21 + pro3F2Payment.jun21 + pro3F3Payment.jun21
]
export const pro3WithJun21Count = [
    pro3F1Collectable.jun21,
    pro3F2Collectable.jun21,
    pro3F3Collectable.jun21,
    pro3F1Collectable.jun21 + pro3F2Collectable.jun21 + pro3F3Collectable.jun21
]
export const pro3WithJun21NonCount = [
    pro3F1NonCollectable.jun21,
    pro3F2NonCollectable.jun21,
    pro3F3NonCollectable.jun21,
    pro3F1NonCollectable.jun21 + pro3F2NonCollectable.jun21 + pro3F3NonCollectable.jun21
]
export const pro3WithJun21Claim = [
    pro3F1ClaimCount.jun21,
    pro3F2ClaimCount.jun21,
    pro3F3ClaimCount.jun21,
    pro3F1ClaimCount.jun21 + pro3F2ClaimCount.jun21 + pro3F3ClaimCount.jun21
]
export const pro3WithJun21PerVisit = [
    pro3F1PerVisit.jun21,
    pro3F2PerVisit.jun21,
    pro3F3PerVisit.jun21,
    provider3PerVisist[5]
]
// jul 21
export const pro3WithJul21Charges = [
    pro3F1Charge.jul21,
    pro3F2Charge.jul21,
    pro3F3Charge.jul21,
    pro3F1Charge.jul21 + pro3F2Charge.jul21 + pro3F3Charge.jul21,

]
export const pro3WithJul21Pay = [
    pro3F1Payment.jul21,
    pro3F2Payment.jul21,
    pro3F3Payment.jul21,
    pro3F1Payment.jul21 + pro3F2Payment.jul21 + pro3F3Payment.jul21
]
export const pro3WithJul21Count = [
    pro3F1Collectable.jul21,
    pro3F2Collectable.jul21,
    pro3F3Collectable.jul21,
    pro3F1Collectable.jul21 + pro3F2Collectable.jul21 + pro3F3Collectable.jul21
]
export const pro3WithJul21NonCount = [
    pro3F1NonCollectable.jul21,
    pro3F2NonCollectable.jul21,
    pro3F3NonCollectable.jul21,
    pro3F1NonCollectable.jul21 + pro3F2NonCollectable.jul21 + pro3F3NonCollectable.jul21
]
export const pro3WithJul21Claim = [
    pro3F1ClaimCount.jul21,
    pro3F2ClaimCount.jul21,
    pro3F3ClaimCount.jul21,
    pro3F1ClaimCount.jul21 + pro3F2ClaimCount.jul21 + pro3F3ClaimCount.jul21
]
export const pro3WithJul21PerVisit = [
    pro3F1PerVisit.jul21,
    pro3F2PerVisit.jul21,
    pro3F3PerVisit.jul21,
    provider3PerVisist[6]
]
// aug 21
export const pro3WithAug21Charges = [
    pro3F1Charge.aug21,
    pro3F2Charge.aug21,
    pro3F3Charge.aug21,
    pro3F1Charge.aug21 + pro3F2Charge.aug21 + pro3F3Charge.aug21
]
export const pro3WithAug21Pay = [
    pro3F1Payment.aug21,
    pro3F2Payment.aug21,
    pro3F3Payment.aug21,
    pro3F1Payment.aug21 + pro3F2Payment.aug21 + pro3F3Payment.aug21
]
export const pro3WithAug21Count = [
    pro3F1Collectable.aug21,
    pro3F2Collectable.aug21,
    pro3F3Collectable.aug21,
    pro3F1Collectable.aug21 + pro3F2Collectable.aug21 + pro3F3Collectable.aug21
]
export const pro3WithAug21NonCount = [
    pro3F1NonCollectable.aug21,
    pro3F2NonCollectable.aug21,
    pro3F3NonCollectable.aug21,
    pro3F1NonCollectable.aug21 + pro3F2NonCollectable.aug21 + pro3F3NonCollectable.aug21
]
export const pro3WithAug21Claim = [
    pro3F1ClaimCount.aug21,
    pro3F2ClaimCount.aug21,
    pro3F3ClaimCount.aug21,
    pro3F1ClaimCount.aug21 + pro3F2ClaimCount.aug21 + pro3F3ClaimCount.aug21
]
export const pro3WithAug21PerVisit = [
    pro3F1PerVisit.aug21,
    pro3F2PerVisit.aug21,
    pro3F3PerVisit.aug21,
    provider3PerVisist[7]
]
// sep 21
export const pro3WithSep21Charges = [
    pro3F1Charge.sep21,
    pro3F2Charge.sep21,
    pro3F3Charge.sep21,
    pro3F1Charge.sep21 + pro3F2Charge.sep21 + pro3F3Charge.sep21
]
export const pro3WithSep21Pay = [
    pro3F1Payment.sep21,
    pro3F2Payment.sep21,
    pro3F3Payment.sep21,
    pro3F1Payment.sep21 + pro3F2Payment.sep21 + pro3F3Payment.sep21
]
export const pro3WithSep21Count = [
    pro3F1Collectable.sep21,
    pro3F2Collectable.sep21,
    pro3F3Collectable.sep21,
    pro3F1Collectable.sep21 + pro3F2Collectable.sep21 + pro3F3Collectable.sep21
]
export const pro3WithSep21NonCount = [
    pro3F1NonCollectable.sep21,
    pro3F2NonCollectable.sep21,
    pro3F3NonCollectable.sep21,
    pro3F1NonCollectable.sep21 + pro3F2NonCollectable.sep21 + pro3F3NonCollectable.sep21
]
export const pro3WithSep21Claim = [
    pro3F1ClaimCount.sep21,
    pro3F2ClaimCount.sep21,
    pro3F3ClaimCount.sep21,
    pro3F1ClaimCount.sep21 + pro3F2ClaimCount.sep21 + pro3F3ClaimCount.sep21
]
export const pro3WithSep21PerVisit = [
    pro3F1PerVisit.sep21,
    pro3F2PerVisit.sep21,
    pro3F3PerVisit.sep21,
    provider3PerVisist[8]
]
// oct 21
export const pro3WithOct21Charges = [
    pro3F1Charge.oct21,
    pro3F2Charge.oct21,
    pro3F3Charge.oct21,
    pro3F1Charge.oct21 + pro3F2Charge.oct21 + pro3F3Charge.oct21
]
export const pro3WithOct21Pay = [
    pro3F1Payment.oct21,
    pro3F2Payment.oct21,
    pro3F3Payment.oct21,
    pro3F1Payment.oct21 + pro3F2Payment.oct21 + pro3F3Payment.oct21
]
export const pro3WithOct21Count = [
    pro3F1Collectable.oct21,
    pro3F2Collectable.oct21,
    pro3F3Collectable.oct21,
    pro3F1Collectable.oct21 + pro3F2Collectable.oct21 + pro3F3Collectable.oct21
]
export const pro3WithOct21NonCount = [
    pro3F1NonCollectable.oct21,
    pro3F2NonCollectable.oct21,
    pro3F3NonCollectable.oct21,
    pro3F1NonCollectable.oct21 + pro3F2NonCollectable.oct21 + pro3F3NonCollectable.oct21
]
export const pro3WithOct21Claim = [
    pro3F1ClaimCount.oct21,
    pro3F2ClaimCount.oct21,
    pro3F3ClaimCount.oct21,
    pro3F1ClaimCount.oct21 + pro3F2ClaimCount.oct21 + pro3F3ClaimCount.oct21
]
export const pro3WithOct21PerVisit = [
    pro3F1PerVisit.oct21,
    pro3F2PerVisit.oct21,
    pro3F3PerVisit.oct21,
    provider3PerVisist[9]
]
// nov 21
export const pro3WithNov21Charges = [
    pro3F1Charge.nov21,
    pro3F2Charge.nov21,
    pro3F3Charge.nov21,
    pro3F1Charge.nov21 + pro3F2Charge.nov21 + pro3F3Charge.nov21
]
export const pro3WithNov21Pay = [
    pro3F1Payment.nov21,
    pro3F2Payment.nov21,
    pro3F3Payment.nov21,
    pro3F1Payment.nov21 + pro3F2Payment.nov21 + pro3F3Payment.nov21
]
export const pro3WithNov21Count = [
    pro3F1Collectable.nov21,
    pro3F2Collectable.nov21,
    pro3F3Collectable.nov21,
    pro3F1Collectable.nov21 + pro3F2Collectable.nov21 + pro3F3Collectable.nov21
]
export const pro3WithNov21NonCount = [
    pro3F1NonCollectable.nov21,
    pro3F2NonCollectable.nov21,
    pro3F3NonCollectable.nov21,
    pro3F1NonCollectable.nov21 + pro3F2NonCollectable.nov21 + pro3F3NonCollectable.nov21
]
export const pro3WithNov21Claim = [
    pro3F1ClaimCount.nov21,
    pro3F2ClaimCount.nov21,
    pro3F3ClaimCount.nov21,
    pro3F1ClaimCount.nov21 + pro3F2ClaimCount.nov21 + pro3F3ClaimCount.nov21
]
export const pro3WithNov21PerVisit = [
    pro3F1PerVisit.nov21,
    pro3F2PerVisit.nov21,
    pro3F3PerVisit.nov21,
    provider3PerVisist[10]
]
// dec 21
export const pro3WithDec21Charges = [
    pro3F1Charge.dec21,
    pro3F2Charge.dec21,
    pro3F3Charge.dec21,
    pro3F1Charge.dec21 + pro3F2Charge.dec21 + pro3F3Charge.dec21
]
export const pro3WithDec21Pay = [
    pro3F1Payment.dec21,
    pro3F2Payment.dec21,
    pro3F3Payment.dec21,
    pro3F1Payment.dec21 + pro3F2Payment.dec21 + pro3F3Payment.dec21
]
export const pro3WithDec21Count = [
    pro3F1Collectable.dec21,
    pro3F2Collectable.dec21,
    pro3F3Collectable.dec21,
    pro3F1Collectable.dec21 + pro3F2Collectable.dec21 + pro3F3Collectable.dec21
]
export const pro3WithDec21NonCount = [
    pro3F1NonCollectable.dec21,
    pro3F2NonCollectable.dec21,
    pro3F3NonCollectable.dec21,
    pro3F1NonCollectable.dec21 + pro3F2NonCollectable.dec21 + pro3F3NonCollectable.dec21
] 
export const pro3WithDec21Claim = [
    pro3F1ClaimCount.dec21,
    pro3F2ClaimCount.dec21,
    pro3F3ClaimCount.dec21,
    pro3F1ClaimCount.dec21 + pro3F2ClaimCount.dec21 + pro3F3ClaimCount.dec21
]
export const pro3WithDec21PerVisit = [
    pro3F1PerVisit.dec21,
    pro3F2PerVisit.dec21,
    pro3F3PerVisit.dec21,
    provider3PerVisist[11]
]

// provider-1 
// jan 22
export const pro3WithJan22Charges = [
    pro3F1Charge.jan22,
    pro3F2Charge.jan22,
    pro3F3Charge.jan22,
    pro3F1Charge.jan22 + pro3F2Charge.jan22 + pro3F3Charge.jan22
]
export const pro3WithJan22Pay = [
    pro3F1Payment.jan22,
    pro3F2Payment.jan22,
    pro3F3Payment.jan22,
    pro3F1Payment.jan22 + pro3F2Payment.jan22 + pro3F3Payment.jan22
]
export const pro3WithJan22Count = [
    pro3F1Collectable.jan22,
    pro3F2Collectable.jan22,
    pro3F3Collectable.jan22,
    pro3F1Collectable.jan22 + pro3F2Collectable.jan22 + pro3F3Collectable.jan22
]
export const pro3WithJan22NonCount = [
    pro3F1NonCollectable.jan22,
    pro3F2NonCollectable.jan22,
    pro3F3NonCollectable.jan22,
    pro3F1NonCollectable.jan22 + pro3F2NonCollectable.jan22 + pro3F3NonCollectable.jan22
]
export const pro3WithJan22Claim = [
    pro3F1ClaimCount.jan22,
    pro3F2ClaimCount.jan22,
    pro3F3ClaimCount.jan22,
    pro3F1ClaimCount.jan22 + pro3F2ClaimCount.jan22 + pro3F3ClaimCount.jan22
]
export const pro3WithJan22PerVisit = [
    pro3F1PerVisit.jan22,
    pro3F2PerVisit.jan22,
    pro3F3PerVisit.jan22,
    provider3PerVisist[12]
]
// feb 22
export const pro3WithFeb22Charges = [
    pro3F1Charge.feb22,
    pro3F2Charge.feb22,
    pro3F3Charge.feb22,
    pro3F1Charge.feb22 + pro3F2Charge.feb22 + pro3F3Charge.feb22
]
export const pro3WithFeb22Pay = [
    pro3F1Payment.feb22,
    pro3F2Payment.feb22,
    pro3F3Payment.feb22,
    pro3F1Payment.feb22 + pro3F2Payment.feb22 + pro3F3Payment.feb22
]
export const pro3WithFeb22Count = [
    pro3F1Collectable.feb22,
    pro3F2Collectable.feb22,
    pro3F3Collectable.feb22,
    pro3F1Collectable.feb22 + pro3F2Collectable.feb22 + pro3F3Collectable.feb22
]
export const pro3WithFeb22NonCount = [
    pro3F1NonCollectable.feb22,
    pro3F2NonCollectable.feb22,
    pro3F3NonCollectable.feb22,
    pro3F1NonCollectable.feb22 + pro3F2NonCollectable.feb22 + pro3F3NonCollectable.feb22
]
export const pro3WithFeb22Claim = [
    pro3F1ClaimCount.feb22,
    pro3F2ClaimCount.feb22,
    pro3F3ClaimCount.feb22,
    pro3F1ClaimCount.feb22 + pro3F2ClaimCount.feb22 + pro3F3ClaimCount.feb22
]
export const pro3WithFeb22PerVisit = [
    pro3F1PerVisit.feb22,
    pro3F2PerVisit.feb22,
    pro3F3PerVisit.feb22,
    provider3PerVisist[13]
]
// mar 22
export const pro3WithMar22Charges = [
    pro3F1Charge.mar22,
    pro3F2Charge.mar22,
    pro3F3Charge.mar22,
    pro3F1Charge.mar22 + pro3F2Charge.mar22 + pro3F3Charge.mar22
]
export const pro3WithMar22Pay = [
    pro3F1Payment.mar22,
    pro3F2Payment.mar22,
    pro3F3Payment.mar22,
    pro3F1Payment.mar22 + pro3F2Payment.mar22 + pro3F3Payment.mar22
]
export const pro3WithMar22Count = [
    pro3F1Collectable.mar22,
    pro3F2Collectable.mar22,
    pro3F3Collectable.mar22,
    pro3F1Collectable.mar22 + pro3F2Collectable.mar22 + pro3F3Collectable.mar22
]
export const pro3WithMar22NonCount = [
    pro3F1NonCollectable.mar22,
    pro3F2NonCollectable.mar22,
    pro3F3NonCollectable.mar22,
    pro3F1NonCollectable.mar22 + pro3F2NonCollectable.mar22 + pro3F3NonCollectable.mar22
]
export const pro3WithMar22Claim = [
    pro3F1ClaimCount.mar22,
    pro3F2ClaimCount.mar22,
    pro3F3ClaimCount.mar22,
    pro3F1ClaimCount.mar22 + pro3F2ClaimCount.mar22 + pro3F3ClaimCount.mar22
]
export const pro3WithMar22PerVisit = [
    pro3F1PerVisit.mar22,
    pro3F2PerVisit.mar22,
    pro3F3PerVisit.mar22,
    provider3PerVisist[14]
]
// april 22
export const pro3WithApr22Charges = [
    pro3F1Charge.apr22,
    pro3F2Charge.apr22,
    pro3F3Charge.apr22,
    pro3F1Charge.apr22 + pro3F2Charge.apr22 + pro3F3Charge.apr22
]
export const pro3WithApr22Pay = [
    pro3F1Payment.apr22,
    pro3F2Payment.apr22,
    pro3F3Payment.apr22,
    pro3F1Payment.apr22 + pro3F2Payment.apr22 + pro3F3Payment.apr22
]
export const pro3WithApr22Count = [
    pro3F1Collectable.apr22,
    pro3F2Collectable.apr22,
    pro3F3Collectable.apr22,
    pro3F1Collectable.apr22 + pro3F2Collectable.apr22 + pro3F3Collectable.apr22
]
export const pro3WithApr22NonCount = [
    pro3F1NonCollectable.apr22,
    pro3F2NonCollectable.apr22,
    pro3F3NonCollectable.apr22,
    pro3F1NonCollectable.apr22 + pro3F2NonCollectable.apr22 + pro3F3NonCollectable.apr22
]
export const pro3WithApr22Claim = [
    pro3F1ClaimCount.apr22,
    pro3F2ClaimCount.apr22,
    pro3F3ClaimCount.apr22,
    pro3F1ClaimCount.apr22 + pro3F2ClaimCount.apr22 + pro3F3ClaimCount.apr22
]
export const pro3WithApr22PerVisit = [
    pro3F1PerVisit.apr22,
    pro3F2PerVisit.apr22,
    pro3F3PerVisit.apr22,
    provider3PerVisist[15]
]
// may 22
export const pro3WithMay22Charges = [
    pro3F1Charge.may22,
    pro3F2Charge.may22,
    pro3F3Charge.may22,
    pro3F1Charge.may22 + pro3F2Charge.may22 + pro3F3Charge.may22
]
export const pro3WithMay22Pay = [
    pro3F1Payment.may22,
    pro3F2Payment.may22,
    pro3F3Payment.may22,
    pro3F1Payment.may22 + pro3F2Payment.may22 + pro3F3Payment.may22
]
export const pro3WithMay22Count = [
    pro3F1Collectable.may22,
    pro3F2Collectable.may22,
    pro3F3Collectable.may22,
    pro3F1Collectable.may22 + pro3F2Collectable.may22 + pro3F3Collectable.may22
]
export const pro3WithMay22NonCount = [
    pro3F1NonCollectable.may22,
    pro3F2NonCollectable.may22,
    pro3F3NonCollectable.may22,
    pro3F1NonCollectable.may22 + pro3F2NonCollectable.may22 + pro3F3NonCollectable.may22
]
export const pro3WithMay22Claim = [
    pro3F1ClaimCount.may22,
    pro3F2ClaimCount.may22,
    pro3F3ClaimCount.may22,
    pro3F1ClaimCount.may22 + pro3F2ClaimCount.may22 + pro3F3ClaimCount.may22
]
export const pro3WithMay22PerVisit = [
    pro3F1PerVisit.may22,
    pro3F2PerVisit.may22,
    pro3F3PerVisit.may22,
    provider3PerVisist[16]
]
// jun 22
export const pro3WithJun22Charges = [
    pro3F1Charge.jun22,
    pro3F2Charge.jun22,
    pro3F3Charge.jun22,
    pro3F1Charge.jun22 + pro3F2Charge.jun22 + pro3F3Charge.jun22
]
export const pro3WithJun22Pay = [
    pro3F1Payment.jun22,
    pro3F2Payment.jun22,
    pro3F3Payment.jun22,
    pro3F1Payment.jun22 + pro3F2Payment.jun22 + pro3F3Payment.jun22
]
export const pro3WithJun22Count = [
    pro3F1Collectable.jun22,
    pro3F2Collectable.jun22,
    pro3F3Collectable.jun22,
    pro3F1Collectable.jun22 + pro3F2Collectable.jun22 + pro3F3Collectable.jun22
]
export const pro3WithJun22NonCount = [
    pro3F1NonCollectable.jun22,
    pro3F2NonCollectable.jun22,
    pro3F3NonCollectable.jun22,
    pro3F1NonCollectable.jun22 + pro3F2NonCollectable.jun22 + pro3F3NonCollectable.jun22
]
export const pro3WithJun22Claim = [
    pro3F1ClaimCount.jun22,
    pro3F2ClaimCount.jun22,
    pro3F3ClaimCount.jun22,
    pro3F1ClaimCount.jun22 + pro3F2ClaimCount.jun22 + pro3F3ClaimCount.jun22
]
export const pro3WithJun22PerVisit = [
    pro3F1PerVisit.jun22,
    pro3F2PerVisit.jun22,
    pro3F3PerVisit.jun22,
    provider3PerVisist[17]
]
// jul 22
export const pro3WithJul22Charges = [
    pro3F1Charge.jul22,
    pro3F2Charge.jul22,
    pro3F3Charge.jul22,
    pro3F1Charge.jul22 + pro3F2Charge.jul22 + pro3F3Charge.jul22
]
export const pro3WithJul22Pay = [
    pro3F1Payment.jul22,
    pro3F2Payment.jul22,
    pro3F3Payment.jul22,
    pro3F1Payment.jul22 + pro3F2Payment.jul22 + pro3F3Payment.jul22
]
export const pro3WithJul22Count = [
    pro3F1Collectable.jul22,
    pro3F2Collectable.jul22,
    pro3F3Collectable.jul22,
    pro3F1Collectable.jul22 + pro3F2Collectable.jul22 + pro3F3Collectable.jul22
]
export const pro3WithJul22NonCount = [
    pro3F1NonCollectable.jul22,
    pro3F2NonCollectable.jul22,
    pro3F3NonCollectable.jul22,
    pro3F1NonCollectable.jul22 + pro3F2NonCollectable.jul22 + pro3F3NonCollectable.jul22
]
export const pro3WithJul22Claim = [
    pro3F1ClaimCount.jul22,
    pro3F2ClaimCount.jul22,
    pro3F3ClaimCount.jul22,
    pro3F1ClaimCount.jul22 + pro3F2ClaimCount.jul22 + pro3F3ClaimCount.jul22
]
export const pro3WithJul22PerVisit = [
    pro3F1PerVisit.jul22,
    pro3F2PerVisit.jul22,
    pro3F3PerVisit.jul22,
    provider3PerVisist[18]
]
// aug 22
export const pro3WithAug22Charges = [
    pro3F1Charge.aug22,
    pro3F2Charge.aug22,
    pro3F3Charge.aug22,
    pro3F1Charge.aug22 + pro3F2Charge.aug22 + pro3F3Charge.aug22
]
export const pro3WithAug22Pay = [
    pro3F1Payment.aug22,
    pro3F2Payment.aug22,
    pro3F3Payment.aug22,
    pro3F1Payment.aug22 + pro3F2Payment.aug22 + pro3F3Payment.aug22
]
export const pro3WithAug22Count = [
    pro3F1Collectable.aug22,
    pro3F2Collectable.aug22,
    pro3F3Collectable.aug22,
    pro3F1Collectable.aug22 + pro3F2Collectable.aug22 + pro3F3Collectable.aug22
]
export const pro3WithAug22NonCount = [
    pro3F1NonCollectable.aug22,
    pro3F2NonCollectable.aug22,
    pro3F3NonCollectable.aug22,
    pro3F1NonCollectable.aug22 + pro3F2NonCollectable.aug22 + pro3F3NonCollectable.aug22
]
export const pro3WithAug22Claim = [
    pro3F1ClaimCount.aug22,
    pro3F2ClaimCount.aug22,
    pro3F3ClaimCount.aug22,
    pro3F1ClaimCount.aug22 + pro3F2ClaimCount.aug22 + pro3F3ClaimCount.aug22
]
export const pro3WithAug22PerVisit = [
    pro3F1PerVisit.aug22,
    pro3F2PerVisit.aug22,
    pro3F3PerVisit.aug22,
    provider3PerVisist[19]
]
// sep 22
export const pro3WithSep22Charges = [
    pro3F1Charge.sep22,
    pro3F2Charge.sep22,
    pro3F3Charge.sep22,
    pro3F1Charge.sep22 + pro3F2Charge.sep22 + pro3F3Charge.sep22
]
export const pro3WithSep22Pay = [
    pro3F1Payment.sep22,
    pro3F2Payment.sep22,
    pro3F3Payment.sep22,
    pro3F1Payment.sep22 + pro3F2Payment.sep22 + pro3F3Payment.sep22
]
export const pro3WithSep22Count = [
    pro3F1Collectable.sep22,
    pro3F2Collectable.sep22,
    pro3F3Collectable.sep22,
    pro3F1Collectable.sep22 + pro3F2Collectable.sep22 + pro3F3Collectable.sep22
]
export const pro3WithSep22NonCount = [
    pro3F1NonCollectable.sep22,
    pro3F2NonCollectable.sep22,
    pro3F3NonCollectable.sep22,
    pro3F1NonCollectable.sep22 + pro3F2NonCollectable.sep22 + pro3F3NonCollectable.sep22
]
export const pro3WithSep22Claim = [
    pro3F1ClaimCount.sep22,
    pro3F2ClaimCount.sep22,
    pro3F3ClaimCount.sep22,
    pro3F1ClaimCount.sep22 + pro3F2ClaimCount.sep22 + pro3F3ClaimCount.sep22
]
export const pro3WithSep22PerVisit = [
    pro3F1PerVisit.sep22,
    pro3F2PerVisit.sep22,
    pro3F3PerVisit.sep22,
    provider3PerVisist[20]
]
// oct 22
export const pro3WithOct22Charges = [
    pro3F1Charge.oct22,
    pro3F2Charge.oct22,
    pro3F3Charge.oct22,
    pro3F1Charge.oct22 + pro3F2Charge.oct22 + pro3F3Charge.oct22
]
export const pro3WithOct22Pay = [
    pro3F1Payment.oct22,
    pro3F2Payment.oct22,
    pro3F3Payment.oct22,
    pro3F1Payment.oct22 + pro3F2Payment.oct22 + pro3F3Payment.oct22
]
export const pro3WithOct22Count = [
    pro3F1Collectable.oct22,
    pro3F2Collectable.oct22,
    pro3F3Collectable.oct22,
    pro3F1Collectable.oct22 + pro3F2Collectable.oct22 + pro3F3Collectable.oct22
]
export const pro3WithOct22NonCount = [
    pro3F1NonCollectable.oct22,
    pro3F2NonCollectable.oct22,
    pro3F3NonCollectable.oct22,
    pro3F1NonCollectable.oct22 + pro3F2NonCollectable.oct22 + pro3F3NonCollectable.oct22
]
export const pro3WithOct22Claim = [
    pro3F1ClaimCount.oct22,
    pro3F2ClaimCount.oct22,
    pro3F3ClaimCount.oct22,
    pro3F1ClaimCount.oct22 + pro3F2ClaimCount.oct22 + pro3F3ClaimCount.oct22
]
export const pro3WithOct22PerVisit = [
    pro3F1PerVisit.oct22,
    pro3F2PerVisit.oct22,
    pro3F3PerVisit.oct22,
    provider3PerVisist[21]
]
// nov 22
export const pro3WithNov22Charges = [
    pro3F1Charge.nov22,
    pro3F2Charge.nov22,
    pro3F3Charge.nov22,
    pro3F1Charge.nov22 + pro3F2Charge.nov22 + pro3F3Charge.nov22
]
export const pro3WithNov22Pay = [
    pro3F1Payment.nov22,
    pro3F2Payment.nov22,
    pro3F3Payment.nov22,
    pro3F1Payment.nov22 + pro3F2Payment.nov22 + pro3F3Payment.nov22
]
export const pro3WithNov22Count = [
    pro3F1Collectable.nov22,
    pro3F2Collectable.nov22,
    pro3F3Collectable.nov22,
    pro3F1Collectable.nov22 + pro3F2Collectable.nov22 + pro3F3Collectable.nov22
]
export const pro3WithNov22NonCount = [
    pro3F1NonCollectable.nov22,
    pro3F2NonCollectable.nov22,
    pro3F3NonCollectable.nov22,
    pro3F1NonCollectable.nov22 + pro3F2NonCollectable.nov22 + pro3F3NonCollectable.nov22
]
export const pro3WithNov22Claim = [
    pro3F1ClaimCount.nov22,
    pro3F2ClaimCount.nov22,
    pro3F3ClaimCount.nov22,
    pro3F1ClaimCount.nov22 + pro3F2ClaimCount.nov22 + pro3F3ClaimCount.nov22
]
export const pro3WithNov22PerVisit = [
    pro3F1PerVisit.nov22,
    pro3F2PerVisit.nov22,
    pro3F3PerVisit.nov22,
    provider3PerVisist[22]
]
// dec 22
export const pro3WithDec22Charges = [
    pro3F1Charge.dec22,
    pro3F2Charge.dec22,
    pro3F3Charge.dec22,
    pro3F1Charge.dec22 + pro3F2Charge.dec22 + pro3F3Charge.dec22
]
export const pro3WithDec22Pay = [
    pro3F1Payment.dec22,
    pro3F2Payment.dec22,
    pro3F3Payment.dec22,
    pro3F1Payment.dec22 + pro3F2Payment.dec22 + pro3F3Payment.dec22
]
export const pro3WithDec22Count = [
    pro3F1Collectable.dec22,
    pro3F2Collectable.dec22,
    pro3F3Collectable.dec22,
    pro3F1Collectable.dec22 + pro3F2Collectable.dec22 + pro3F3Collectable.dec22
]
export const pro3WithDec22NonCount = [
    pro3F1NonCollectable.dec22,
    pro3F2NonCollectable.dec22,
    pro3F3NonCollectable.dec22,
    pro3F1NonCollectable.dec22 + pro3F2NonCollectable.dec22 + pro3F3NonCollectable.dec22
]
export const pro3WithDec22Claim = [
    pro3F1ClaimCount.dec22,
    pro3F2ClaimCount.dec22,
    pro3F3ClaimCount.dec22,
    pro3F1ClaimCount.dec22 + pro3F2ClaimCount.dec22 + pro3F3ClaimCount.dec22
]
export const pro3WithDec22PerVisit = [
    pro3F1PerVisit.dec22,
    pro3F2PerVisit.dec22,
    pro3F3PerVisit.dec22,
    provider3PerVisist[23]
]

// provider-1 
// jan 23
export const pro3WithJan23Charges = [
    pro3F1Charge.jan23,
    pro3F2Charge.jan23,
    pro3F3Charge.jan23,
    pro3F1Charge.jan23 + pro3F2Charge.jan23 + pro3F3Charge.jan23
]
export const pro3WithJan23Pay = [
    pro3F1Payment.jan23,
    pro3F2Payment.jan23,
    pro3F3Payment.jan23,
    pro3F1Payment.jan23 + pro3F2Payment.jan23 + pro3F3Payment.jan23
]
export const pro3WithJan23Count = [
    pro3F1Collectable.jan23,
    pro3F2Collectable.jan23,
    pro3F3Collectable.jan23,
    pro3F1Collectable.jan23 + pro3F2Collectable.jan23 + pro3F3Collectable.jan23
]
export const pro3WithJan23NonCount = [
    pro3F1NonCollectable.jan23,
    pro3F2NonCollectable.jan23,
    pro3F3NonCollectable.jan23,
    pro3F1NonCollectable.jan23 + pro3F2NonCollectable.jan23 + pro3F3NonCollectable.jan23
]
export const pro3WithJan23Claim = [
    pro3F1ClaimCount.jan23,
    pro3F2ClaimCount.jan23,
    pro3F3ClaimCount.jan23,
    pro3F1ClaimCount.jan23 + pro3F2ClaimCount.jan23 + pro3F3ClaimCount.jan23
]
export const pro3WithJan23PerVisit = [
    pro3F1PerVisit.jan23,
    pro3F2PerVisit.jan23,
    pro3F3PerVisit.jan23,
    provider3PerVisist[24]
]
// feb 23
export const pro3WithFeb23Charges = [
    pro3F1Charge.feb23,
    pro3F2Charge.feb23,
    pro3F3Charge.feb23,
    pro3F1Charge.feb23 + pro3F2Charge.feb23 + pro3F3Charge.feb23
]
export const pro3WithFeb23Pay = [
    pro3F1Payment.feb23,
    pro3F2Payment.feb23,
    pro3F3Payment.feb23,
    pro3F1Payment.feb23 + pro3F2Payment.feb23 + pro3F3Payment.feb23
]
export const pro3WithFeb23Count = [
    pro3F1Collectable.feb23,
    pro3F2Collectable.feb23,
    pro3F3Collectable.feb23,
    pro3F1Collectable.feb23 + pro3F2Collectable.feb23 + pro3F3Collectable.feb23
]
export const pro3WithFeb23NonCount = [
    pro3F1NonCollectable.feb23,
    pro3F2NonCollectable.feb23,
    pro3F3NonCollectable.feb23,
    pro3F1NonCollectable.feb23 + pro3F2NonCollectable.feb23 + pro3F3NonCollectable.feb23
]
export const pro3WithFeb23Claim = [
    pro3F1ClaimCount.feb23,
    pro3F2ClaimCount.feb23,
    pro3F3ClaimCount.feb23,
    pro3F1ClaimCount.feb23 + pro3F2ClaimCount.feb23 + pro3F3ClaimCount.feb23
]
export const pro3WithFeb23PerVisit = [
    pro3F1PerVisit.feb23,
    pro3F2PerVisit.feb23,
    pro3F3PerVisit.feb23,
    provider3PerVisist[25]
]
// mar 23
export const pro3WithMar23Charges = [
    pro3F1Charge.mar23,
    pro3F2Charge.mar23,
    pro3F3Charge.mar23,
    pro3F1Charge.mar23 + pro3F2Charge.mar23 + pro3F3Charge.mar23
]
export const pro3WithMar23Pay = [
    pro3F1Payment.mar23,
    pro3F2Payment.mar23,
    pro3F3Payment.mar23,
    pro3F1Payment.mar23 + pro3F2Payment.mar23 + pro3F3Payment.mar23
]
export const pro3WithMar23Count = [
    pro3F1Collectable.mar23,
    pro3F2Collectable.mar23,
    pro3F3Collectable.mar23,
    pro3F1Collectable.mar23 + pro3F2Collectable.mar23 + pro3F3Collectable.mar23
]
export const pro3WithMar23NonCount = [
    pro3F1NonCollectable.mar23,
    pro3F2NonCollectable.mar23,
    pro3F3NonCollectable.mar23,
    pro3F1NonCollectable.mar23 + pro3F2NonCollectable.mar23 + pro3F3NonCollectable.mar23
]
export const pro3WithMar23Claim = [
    pro3F1ClaimCount.mar23,
    pro3F2ClaimCount.mar23,
    pro3F3ClaimCount.mar23,
    pro3F1ClaimCount.mar23 + pro3F2ClaimCount.mar23 + pro3F3ClaimCount.mar23
]
export const pro3WithMar23PerVisit = [
    pro3F1PerVisit.mar23,
    pro3F2PerVisit.mar23,
    pro3F3PerVisit.mar23,
    provider3PerVisist[26]
]
// april 23
export const pro3WithApr23Charges = [
    pro3F1Charge.apr23,
    pro3F2Charge.apr23,
    pro3F3Charge.apr23,
    pro3F1Charge.apr23 + pro3F2Charge.apr23 + pro3F3Charge.apr23
]
export const pro3WithApr23Pay = [
    pro3F1Payment.apr23,
    pro3F2Payment.apr23,
    pro3F3Payment.apr23,
    pro3F1Payment.apr23 + pro3F2Payment.apr23 + pro3F3Payment.apr23
]
export const pro3WithApr23Count = [
    pro3F1Collectable.apr23,
    pro3F2Collectable.apr23,
    pro3F3Collectable.apr23,
    pro3F1Collectable.apr23 + pro3F2Collectable.apr23 + pro3F3Collectable.apr23
]
export const pro3WithApr23NonCount = [
    pro3F1NonCollectable.apr23,
    pro3F2NonCollectable.apr23,
    pro3F3NonCollectable.apr23,
    pro3F1NonCollectable.apr23 + pro3F2NonCollectable.apr23 + pro3F3NonCollectable.apr23
]
export const pro3WithApr23Claim = [
    pro3F1ClaimCount.apr23,
    pro3F2ClaimCount.apr23,
    pro3F3ClaimCount.apr23,
    pro3F1ClaimCount.apr23 + pro3F2ClaimCount.apr23 + pro3F3ClaimCount.apr23
]
export const pro3WithApr23PerVisit = [
    pro3F1PerVisit.apr23,
    pro3F2PerVisit.apr23,
    pro3F3PerVisit.apr23,
    provider3PerVisist[27]
]
// may 23
export const pro3WithMay23Charges = [
    pro3F1Charge.may23,
    pro3F2Charge.may23,
    pro3F3Charge.may23,
    pro3F1Charge.may23 + pro3F2Charge.may23 + pro3F3Charge.may23
]
export const pro3WithMay23Pay = [
    pro3F1Payment.may23,
    pro3F2Payment.may23,
    pro3F3Payment.may23,
    pro3F1Payment.may23 + pro3F2Payment.may23 + pro3F3Payment.may23
]
export const pro3WithMay23Count = [
    pro3F1Collectable.may23,
    pro3F2Collectable.may23,
    pro3F3Collectable.may23,
    pro3F1Collectable.may23 + pro3F2Collectable.may23 + pro3F3Collectable.may23
]
export const pro3WithMay23NonCount = [
    pro3F1NonCollectable.may23,
    pro3F2NonCollectable.may23,
    pro3F3NonCollectable.may23,
    pro3F1NonCollectable.may23 + pro3F2NonCollectable.may23 + pro3F3NonCollectable.may23
]
export const pro3WithMay23Claim = [
    pro3F1ClaimCount.may23,
    pro3F2ClaimCount.may23,
    pro3F3ClaimCount.may23,
    pro3F1ClaimCount.may23 + pro3F2ClaimCount.may23 + pro3F3ClaimCount.may23
]
export const pro3WithMay23PerVisit = [
    pro3F1PerVisit.may23,
    pro3F2PerVisit.may23,
    pro3F3PerVisit.may23,
    provider3PerVisist[28]
]
// jun 23
export const pro3WithJun23Charges = [
    pro3F1Charge.jun23,
    pro3F2Charge.jun23,
    pro3F3Charge.jun23,
    pro3F1Charge.jun23 + pro3F2Charge.jun23 + pro3F3Charge.jun23
]
export const pro3WithJun23Pay = [
    pro3F1Payment.jun23,
    pro3F2Payment.jun23,
    pro3F3Payment.jun23,
    pro3F1Payment.jun23 + pro3F2Payment.jun23 + pro3F3Payment.jun23
]
export const pro3WithJun23Count = [
    pro3F1Collectable.jun23,
    pro3F2Collectable.jun23,
    pro3F3Collectable.jun23,
    pro3F1Collectable.jun23 + pro3F2Collectable.jun23 + pro3F3Collectable.jun23
]
export const pro3WithJun23NonCount = [
    pro3F1NonCollectable.jun23,
    pro3F2NonCollectable.jun23,
    pro3F3NonCollectable.jun23,
    pro3F1NonCollectable.jun23 + pro3F2NonCollectable.jun23 + pro3F3NonCollectable.jun23
]
export const pro3WithJun23Claim = [
    pro3F1ClaimCount.jun23,
    pro3F2ClaimCount.jun23,
    pro3F3ClaimCount.jun23,
    pro3F1ClaimCount.jun23 + pro3F2ClaimCount.jun23 + pro3F3ClaimCount.jun23
]
export const pro3WithJun23PerVisit = [
    pro3F1PerVisit.jun23,
    pro3F2PerVisit.jun23,
    pro3F3PerVisit.jun23,
    provider3PerVisist[29]
]
// jul 23
export const pro3WithJul23Charges = [
    pro3F1Charge.jul23,
    pro3F2Charge.jul23,
    pro3F3Charge.jul23,
    pro3F1Charge.jul23 + pro3F2Charge.jul23 + pro3F3Charge.jul23
]
export const pro3WithJul23Pay = [
    pro3F1Payment.jul23,
    pro3F2Payment.jul23,
    pro3F3Payment.jul23,
    pro3F1Payment.jul23 + pro3F2Payment.jul23 + pro3F3Payment.jul23
]
export const pro3WithJul23Count = [
    pro3F1Collectable.jul23,
    pro3F2Collectable.jul23,
    pro3F3Collectable.jul23,
    pro3F1Collectable.jul23 + pro3F2Collectable.jul23 + pro3F3Collectable.jul23
]
export const pro3WithJul23NonCount = [
    pro3F1NonCollectable.jul23,
    pro3F2NonCollectable.jul23,
    pro3F3NonCollectable.jul23,
    pro3F1NonCollectable.jul23 + pro3F2NonCollectable.jul23 + pro3F3NonCollectable.jul23
]
export const pro3WithJul23Claim = [
    pro3F1ClaimCount.jul23,
    pro3F2ClaimCount.jul23,
    pro3F3ClaimCount.jul23,
    pro3F1ClaimCount.jul23 + pro3F2ClaimCount.jul23 + pro3F3ClaimCount.jul23
]
export const pro3WithJul23PerVisit = [
    pro3F1PerVisit.jul23,
    pro3F2PerVisit.jul23,
    pro3F3PerVisit.jul23,
    provider3PerVisist[30]
]
// aug 23
export const pro3WithAug23Charges = [
    pro3F1Charge.aug23,
    pro3F2Charge.aug23,
    pro3F3Charge.aug23,
    pro3F1Charge.aug23 + pro3F2Charge.aug23 + pro3F3Charge.aug23
]
export const pro3WithAug23Pay = [
    pro3F1Payment.aug23,
    pro3F2Payment.aug23,
    pro3F3Payment.aug23,
    pro3F1Payment.aug23 + pro3F2Payment.aug23 + pro3F3Payment.aug23
]
export const pro3WithAug23Count = [
    pro3F1Collectable.aug23,
    pro3F2Collectable.aug23,
    pro3F3Collectable.aug23,
    pro3F1Collectable.aug23 + pro3F2Collectable.aug23 + pro3F3Collectable.aug23
]
export const pro3WithAug23NonCount = [
    pro3F1NonCollectable.aug23,
    pro3F2NonCollectable.aug23,
    pro3F3NonCollectable.aug23,
    pro3F1NonCollectable.aug23 + pro3F2NonCollectable.aug23 + pro3F3NonCollectable.aug23
]
export const pro3WithAug23Claim = [
    pro3F1ClaimCount.aug23,
    pro3F2ClaimCount.aug23,
    pro3F3ClaimCount.aug23,
    pro3F1ClaimCount.aug23 + pro3F2ClaimCount.aug23 + pro3F3ClaimCount.aug23
]
export const pro3WithAug23PerVisit = [
    pro3F1PerVisit.aug23,
    pro3F2PerVisit.aug23,
    pro3F3PerVisit.aug23,
    provider3PerVisist[31]
]
// sep 23
export const pro3WithSep23Charges = [
    pro3F1Charge.sep23,
    pro3F2Charge.sep23,
    pro3F3Charge.sep23,
    pro3F1Charge.sep23 + pro3F2Charge.sep23 + pro3F3Charge.sep23
]
export const pro3WithSep23Pay = [
    pro3F1Payment.sep23,
    pro3F2Payment.sep23,
    pro3F3Payment.sep23,
    pro3F1Payment.sep23 + pro3F2Payment.sep23 + pro3F3Payment.sep23
]
export const pro3WithSep23Count = [
    pro3F1Collectable.sep23,
    pro3F2Collectable.sep23,
    pro3F3Collectable.sep23,
    pro3F1Collectable.sep23 + pro3F2Collectable.sep23 + pro3F3Collectable.sep23
]
export const pro3WithSep23NonCount = [
    pro3F1NonCollectable.sep23,
    pro3F2NonCollectable.sep23,
    pro3F3NonCollectable.sep23,
    pro3F1NonCollectable.sep23 + pro3F2NonCollectable.sep23 + pro3F3NonCollectable.sep23
]
export const pro3WithSep23Claim = [
    pro3F1ClaimCount.sep23,
    pro3F2ClaimCount.sep23,
    pro3F3ClaimCount.sep23,
    pro3F1ClaimCount.sep23 + pro3F2ClaimCount.sep23 + pro3F3ClaimCount.sep23
]
export const pro3WithSep23PerVisit = [
    pro3F1PerVisit.sep23,
    pro3F2PerVisit.sep23,
    pro3F3PerVisit.sep23,
    provider3PerVisist[32]
]
// oct 23
export const pro3WithOct23Charges = [
    pro3F1Charge.oct23,
    pro3F2Charge.oct23,
    pro3F3Charge.oct23,
    pro3F1Charge.oct23 + pro3F2Charge.oct23 + pro3F3Charge.oct23
]
export const pro3WithOct23Pay = [
    pro3F1Payment.oct23,
    pro3F2Payment.oct23,
    pro3F3Payment.oct23,
    pro3F1Payment.oct23 + pro3F2Payment.oct23 + pro3F3Payment.oct23
]
export const pro3WithOct23Count = [
    pro3F1Collectable.oct23,
    pro3F2Collectable.oct23,
    pro3F3Collectable.oct23,
    pro3F1Collectable.oct23 + pro3F2Collectable.oct23 + pro3F3Collectable.oct23
]
export const pro3WithOct23NonCount = [
    pro3F1NonCollectable.oct23,
    pro3F2NonCollectable.oct23,
    pro3F3NonCollectable.oct23,
    pro3F1NonCollectable.oct23 + pro3F2NonCollectable.oct23 + pro3F3NonCollectable.oct23
]
export const pro3WithOct23Claim = [
    pro3F1ClaimCount.oct23,
    pro3F2ClaimCount.oct23,
    pro3F3ClaimCount.oct23,
    pro3F1ClaimCount.oct23 + pro3F2ClaimCount.oct23 + pro3F3ClaimCount.oct23
]
export const pro3WithOct23PerVisit = [
    pro3F1PerVisit.oct23,
    pro3F2PerVisit.oct23,
    pro3F3PerVisit.oct23,
    provider3PerVisist[33]
]
// nov 23
export const pro3WithNov23Charges = [
    pro3F1Charge.nov23,
    pro3F2Charge.nov23,
    pro3F3Charge.nov23,
    pro3F1Charge.nov23 + pro3F2Charge.nov23 + pro3F3Charge.nov23
]
export const pro3WithNov23Pay = [
    pro3F1Payment.nov23,
    pro3F2Payment.nov23,
    pro3F3Payment.nov23,
    pro3F1Payment.nov23 +
    pro3F2Payment.nov23 + 
    pro3F3Payment.nov23
]
export const pro3WithNov23Count = [
    pro3F1Collectable.nov23,
    pro3F2Collectable.nov23,
    pro3F3Collectable.nov23,
    pro3F1Collectable.nov23 +
    pro3F2Collectable.nov23 +
    pro3F3Collectable.nov23
]
export const pro3WithNov23NonCount = [
    pro3F1NonCollectable.nov23,
    pro3F2NonCollectable.nov23,
    pro3F3NonCollectable.nov23,
    pro3F1NonCollectable.nov23+
    pro3F2NonCollectable.nov23+
    pro3F3NonCollectable.nov23
]
export const pro3WithNov23Claim = [
    pro3F1ClaimCount.nov23,
    pro3F2ClaimCount.nov23,
    pro3F3ClaimCount.nov23,
    pro3F1ClaimCount.nov23 + pro3F2ClaimCount.nov23 + pro3F3ClaimCount.nov23
]
export const pro3WithNov23PerVisit = [
    pro3F1PerVisit.nov23,
    pro3F2PerVisit.nov23,
    pro3F3PerVisit.nov23,
    provider3PerVisist[34]
]
// comm
    // export const pro3WithJan21Charges = [
    //     pro3F1Charge.jan21,
    //     pro3F2Charge.jan21,
    //     pro3F3Charge.jan21
    // ]
    // export const pro3WithJan21Pay = [
    //     pro3F1Payment.jan21,
    //     pro3F2Payment.jan21,
    //     pro3F3Payment.jan21
    // ]
    // export const pro3WithJan21Count = [
    //     pro3F1Collectable.jan21,
    //     pro3F2Collectable.jan21,
    //     pro3F3Collectable.jan21
    // ]
    // export const pro3WithJan21NonCount = [
    //     pro3F1NonCollectable.jan21,
    //     pro3F2NonCollectable.jan21,
    //     pro3F3NonCollectable.jan21
    // ]
    // export const pro3WithJan21Claim = [
    //     pro3F1ClaimCount.jan21,
    //     pro3F2ClaimCount.jan21,
    //     pro3F3ClaimCount.jan21
    // ]
    // export const pro3WithJan21PerVisit = [
    //     pro3F1PerVisit.jan21,
    //     pro3F2PerVisit.jan21,
    //     pro3F3PerVisit.jan21
    // ]
    // // feb 21
    // export const pro3WithFeb21Charges = [
    //     pro3F1Charge.feb21,
    //     pro3F2Charge.feb21,
    //     pro3F3Charge.feb21
    // ]
    // export const pro3WithFeb21Pay = [
    //     pro3F1Payment.feb21,
    //     pro3F2Payment.feb21,
    //     pro3F3Payment.feb21
    // ]
    // export const pro3WithFeb21Count = [
    //     pro3F1Collectable.feb21,
    //     pro3F2Collectable.feb21,
    //     pro3F3Collectable.feb21
    // ]
    // export const pro3WithFeb21NonCount = [
    //     pro3F1NonCollectable.feb21,
    //     pro3F2NonCollectable.feb21,
    //     pro3F3NonCollectable.feb21
    // ]
    // export const pro3WithFeb21Claim = [
    //     pro3F1ClaimCount.feb21,
    //     pro3F2ClaimCount.feb21,
    //     pro3F3ClaimCount.feb21
    // ]
    // export const pro3WithFeb21PerVisit = [
    //     pro3F1PerVisit.feb21,
    //     pro3F2PerVisit.feb21,
    //     pro3F3PerVisit.feb21
    // ]
    // // mar 21
    // export const pro3WithMar21Charges = [
    //     pro3F1Charge.mar21,
    //     pro3F2Charge.mar21,
    //     pro3F3Charge.mar21
    // ]
    // export const pro3WithMar21Pay = [
    //     pro3F1Payment.mar21,
    //     pro3F2Payment.mar21,
    //     pro3F3Payment.mar21
    // ]
    // export const pro3WithMar21Count = [
    //     pro3F1Collectable.mar21,
    //     pro3F2Collectable.mar21,
    //     pro3F3Collectable.mar21
    // ]
    // export const pro3WithMar21NonCount = [
    //     pro3F1NonCollectable.mar21,
    //     pro3F2NonCollectable.mar21,
    //     pro3F3NonCollectable.mar21
    // ]
    // export const pro3WithMar21Claim = [
    //     pro3F1ClaimCount.mar21,
    //     pro3F2ClaimCount.mar21,
    //     pro3F3ClaimCount.mar21
    // ]
    // export const pro3WithMar21PerVisit = [
    //     pro3F1PerVisit.mar21,
    //     pro3F2PerVisit.mar21,
    //     pro3F3PerVisit.mar21
    // ]
    // // april 21
    // export const pro3WithApr21Charges = [
    //     pro3F1Charge.apr21,
    //     pro3F2Charge.apr21,
    //     pro3F3Charge.apr21
    // ]
    // export const pro3WithApr21Pay = [
    //     pro3F1Payment.apr21,
    //     pro3F2Payment.apr21,
    //     pro3F3Payment.apr21
    // ]
    // export const pro3WithApr21Count = [
    //     pro3F1Collectable.apr21,
    //     pro3F2Collectable.apr21,
    //     pro3F3Collectable.apr21
    // ]
    // export const pro3WithApr21NonCount = [
    //     pro3F1NonCollectable.apr21,
    //     pro3F2NonCollectable.apr21,
    //     pro3F3NonCollectable.apr21
    // ]
    // export const pro3WithApr21Claim = [
    //     pro3F1ClaimCount.apr21,
    //     pro3F2ClaimCount.apr21,
    //     pro3F3ClaimCount.apr21
    // ]
    // export const pro3WithApr21PerVisit = [
    //     pro3F1PerVisit.apr21,
    //     pro3F2PerVisit.apr21,
    //     pro3F3PerVisit.apr21
    // ]
    // // may 21
    // export const pro3WithMay21Charges = [
    //     pro3F1Charge.may21,
    //     pro3F2Charge.may21,
    //     pro3F3Charge.may21
    // ]
    // export const pro3WithMay21Pay = [
    //     pro3F1Payment.may21,
    //     pro3F2Payment.may21,
    //     pro3F3Payment.may21
    // ]
    // export const pro3WithMay21Count = [
    //     pro3F1Collectable.may21,
    //     pro3F2Collectable.may21,
    //     pro3F3Collectable.may21
    // ]
    // export const pro3WithMay21NonCount = [
    //     pro3F1NonCollectable.may21,
    //     pro3F2NonCollectable.may21,
    //     pro3F3NonCollectable.may21
    // ]
    // export const pro3WithMay21Claim = [
    //     pro3F1ClaimCount.may21,
    //     pro3F2ClaimCount.may21,
    //     pro3F3ClaimCount.may21
    // ]
    // export const pro3WithMay21PerVisit = [
    //     pro3F1PerVisit.may21,
    //     pro3F2PerVisit.may21,
    //     pro3F3PerVisit.may21
    // ]
    // // jun 21
    // export const pro3WithJun21Charges = [
    //     pro3F1Charge.jun21,
    //     pro3F2Charge.jun21,
    //     pro3F3Charge.jun21
    // ]
    // export const pro3WithJun21Pay = [
    //     pro3F1Payment.jun21,
    //     pro3F2Payment.jun21,
    //     pro3F3Payment.jun21
    // ]
    // export const pro3WithJun21Count = [
    //     pro3F1Collectable.jun21,
    //     pro3F2Collectable.jun21,
    //     pro3F3Collectable.jun21
    // ]
    // export const pro3WithJun21NonCount = [
    //     pro3F1NonCollectable.jun21,
    //     pro3F2NonCollectable.jun21,
    //     pro3F3NonCollectable.jun21
    // ]
    // export const pro3WithJun21Claim = [
    //     pro3F1ClaimCount.jun21,
    //     pro3F2ClaimCount.jun21,
    //     pro3F3ClaimCount.jun21
    // ]
    // export const pro3WithJun21PerVisit = [
    //     pro3F1PerVisit.jun21,
    //     pro3F2PerVisit.jun21,
    //     pro3F3PerVisit.jun21
    // ]
    // // jul 21
    // export const pro3WithJul21Charges = [
    //     pro3F1Charge.jul21,
    //     pro3F2Charge.jul21,
    //     pro3F3Charge.jul21
    // ]
    // export const pro3WithJul21Pay = [
    //     pro3F1Payment.jul21,
    //     pro3F2Payment.jul21,
    //     pro3F3Payment.jul21
    // ]
    // export const pro3WithJul21Count = [
    //     pro3F1Collectable.jul21,
    //     pro3F2Collectable.jul21,
    //     pro3F3Collectable.jul21
    // ]
    // export const pro3WithJul21NonCount = [
    //     pro3F1NonCollectable.jul21,
    //     pro3F2NonCollectable.jul21,
    //     pro3F3NonCollectable.jul21
    // ]
    // export const pro3WithJul21Claim = [
    //     pro3F1ClaimCount.jul21,
    //     pro3F2ClaimCount.jul21,
    //     pro3F3ClaimCount.jul21
    // ]
    // export const pro3WithJul21PerVisit = [
    //     pro3F1PerVisit.jul21,
    //     pro3F2PerVisit.jul21,
    //     pro3F3PerVisit.jul21
    // ]
    // // aug 21
    // export const pro3WithAug21Charges = [
    //     pro3F1Charge.aug21,
    //     pro3F2Charge.aug21,
    //     pro3F3Charge.aug21
    // ]
    // export const pro3WithAug21Pay = [
    //     pro3F1Payment.aug21,
    //     pro3F2Payment.aug21,
    //     pro3F3Payment.aug21
    // ]
    // export const pro3WithAug21Count = [
    //     pro3F1Collectable.aug21,
    //     pro3F2Collectable.aug21,
    //     pro3F3Collectable.aug21
    // ]
    // export const pro3WithAug21NonCount = [
    //     pro3F1NonCollectable.aug21,
    //     pro3F2NonCollectable.aug21,
    //     pro3F3NonCollectable.aug21
    // ]
    // export const pro3WithAug21Claim = [
    //     pro3F1ClaimCount.aug21,
    //     pro3F2ClaimCount.aug21,
    //     pro3F3ClaimCount.aug21
    // ]
    // export const pro3WithAug21PerVisit = [
    //     pro3F1PerVisit.aug21,
    //     pro3F2PerVisit.aug21,
    //     pro3F3PerVisit.aug21
    // ]
    // // sep 21
    // export const pro3WithSep21Charges = [
    //     pro3F1Charge.sep21,
    //     pro3F2Charge.sep21,
    //     pro3F3Charge.sep21
    // ]
    // export const pro3WithSep21Pay = [
    //     pro3F1Payment.sep21,
    //     pro3F2Payment.sep21,
    //     pro3F3Payment.sep21
    // ]
    // export const pro3WithSep21Count = [
    //     pro3F1Collectable.sep21,
    //     pro3F2Collectable.sep21,
    //     pro3F3Collectable.sep21
    // ]
    // export const pro3WithSep21NonCount = [
    //     pro3F1NonCollectable.sep21,
    //     pro3F2NonCollectable.sep21,
    //     pro3F3NonCollectable.sep21
    // ]
    // export const pro3WithSep21Claim = [
    //     pro3F1ClaimCount.sep21,
    //     pro3F2ClaimCount.sep21,
    //     pro3F3ClaimCount.sep21
    // ]
    // export const pro3WithSep21PerVisit = [
    //     pro3F1PerVisit.sep21,
    //     pro3F2PerVisit.sep21,
    //     pro3F3PerVisit.sep21
    // ]
    // // oct 21
    // export const pro3WithOct21Charges = [
    //     pro3F1Charge.oct21,
    //     pro3F2Charge.oct21,
    //     pro3F3Charge.oct21
    // ]
    // export const pro3WithOct21Pay = [
    //     pro3F1Payment.oct21,
    //     pro3F2Payment.oct21,
    //     pro3F3Payment.oct21
    // ]
    // export const pro3WithOct21Count = [
    //     pro3F1Collectable.oct21,
    //     pro3F2Collectable.oct21,
    //     pro3F3Collectable.oct21
    // ]
    // export const pro3WithOct21NonCount = [
    //     pro3F1NonCollectable.oct21,
    //     pro3F2NonCollectable.oct21,
    //     pro3F3NonCollectable.oct21
    // ]
    // export const pro3WithOct21Claim = [
    //     pro3F1ClaimCount.oct21,
    //     pro3F2ClaimCount.oct21,
    //     pro3F3ClaimCount.oct21
    // ]
    // export const pro3WithOct21PerVisit = [
    //     pro3F1PerVisit.oct21,
    //     pro3F2PerVisit.oct21,
    //     pro3F3PerVisit.oct21
    // ]
    // // nov 21
    // export const pro3WithNov21Charges = [
    //     pro3F1Charge.nov21,
    //     pro3F2Charge.nov21,
    //     pro3F3Charge.nov21
    // ]
    // export const pro3WithNov21Pay = [
    //     pro3F1Payment.nov21,
    //     pro3F2Payment.nov21,
    //     pro3F3Payment.nov21
    // ]
    // export const pro3WithNov21Count = [
    //     pro3F1Collectable.nov21,
    //     pro3F2Collectable.nov21,
    //     pro3F3Collectable.nov21
    // ]
    // export const pro3WithNov21NonCount = [
    //     pro3F1NonCollectable.nov21,
    //     pro3F2NonCollectable.nov21,
    //     pro3F3NonCollectable.nov21
    // ]
    // export const pro3WithNov21Claim = [
    //     pro3F1ClaimCount.nov21,
    //     pro3F2ClaimCount.nov21,
    //     pro3F3ClaimCount.nov21
    // ]
    // export const pro3WithNov21PerVisit = [
    //     pro3F1PerVisit.nov21,
    //     pro3F2PerVisit.nov21,
    //     pro3F3PerVisit.nov21
    // ]
    // // dec 21
    // export const pro3WithDec21Charges = [
    //     pro3F1Charge.dec21,
    //     pro3F2Charge.dec21,
    //     pro3F3Charge.dec21
    // ]
    // export const pro3WithDec21Pay = [
    //     pro3F1Payment.dec21,
    //     pro3F2Payment.dec21,
    //     pro3F3Payment.dec21
    // ]
    // export const pro3WithDec21Count = [
    //     pro3F1Collectable.dec21,
    //     pro3F2Collectable.dec21,
    //     pro3F3Collectable.dec21
    // ]
    // export const pro3WithDec21NonCount = [
    //     pro3F1NonCollectable.dec21,
    //     pro3F2NonCollectable.dec21,
    //     pro3F3NonCollectable.dec21
    // ]
    // export const pro3WithDec21Claim = [
    //     pro3F1ClaimCount.dec21,
    //     pro3F2ClaimCount.dec21,
    //     pro3F3ClaimCount.dec21
    // ]
    // export const pro3WithDec21PerVisit = [
    //     pro3F1PerVisit.dec21,
    //     pro3F2PerVisit.dec21,
    //     pro3F3PerVisit.dec21
    // ]
    
    // // provider-3 
    // // jan 22
    // export const pro3WithJan22Charges = [
    //     pro3F1Charge.jan22,
    //     pro3F2Charge.jan22,
    //     pro3F3Charge.jan22
    // ]
    // export const pro3WithJan22Pay = [
    //     pro3F1Payment.jan22,
    //     pro3F2Payment.jan22,
    //     pro3F3Payment.jan22
    // ]
    // export const pro3WithJan22Count = [
    //     pro3F1Collectable.jan22,
    //     pro3F2Collectable.jan22,
    //     pro3F3Collectable.jan22
    // ]
    // export const pro3WithJan22NonCount = [
    //     pro3F1NonCollectable.jan22,
    //     pro3F2NonCollectable.jan22,
    //     pro3F3NonCollectable.jan22
    // ]
    // export const pro3WithJan22Claim = [
    //     pro3F1ClaimCount.jan22,
    //     pro3F2ClaimCount.jan22,
    //     pro3F3ClaimCount.jan22
    // ]
    // export const pro3WithJan22PerVisit = [
    //     pro3F1PerVisit.jan22,
    //     pro3F2PerVisit.jan22,
    //     pro3F3PerVisit.jan22
    // ]
    // // feb 22
    // export const pro3WithFeb22Charges = [
    //     pro3F1Charge.feb22,
    //     pro3F2Charge.feb22,
    //     pro3F3Charge.feb22
    // ]
    // export const pro3WithFeb22Pay = [
    //     pro3F1Payment.feb22,
    //     pro3F2Payment.feb22,
    //     pro3F3Payment.feb22
    // ]
    // export const pro3WithFeb22Count = [
    //     pro3F1Collectable.feb22,
    //     pro3F2Collectable.feb22,
    //     pro3F3Collectable.feb22
    // ]
    // export const pro3WithFeb22NonCount = [
    //     pro3F1NonCollectable.feb22,
    //     pro3F2NonCollectable.feb22,
    //     pro3F3NonCollectable.feb22
    // ]
    // export const pro3WithFeb22Claim = [
    //     pro3F1ClaimCount.feb22,
    //     pro3F2ClaimCount.feb22,
    //     pro3F3ClaimCount.feb22
    // ]
    // export const pro3WithFeb22PerVisit = [
    //     pro3F1PerVisit.feb22,
    //     pro3F2PerVisit.feb22,
    //     pro3F3PerVisit.feb22
    // ]
    // // mar 22
    // export const pro3WithMar22Charges = [
    //     pro3F1Charge.mar22,
    //     pro3F2Charge.mar22,
    //     pro3F3Charge.mar22
    // ]
    // export const pro3WithMar22Pay = [
    //     pro3F1Payment.mar22,
    //     pro3F2Payment.mar22,
    //     pro3F3Payment.mar22
    // ]
    // export const pro3WithMar22Count = [
    //     pro3F1Collectable.mar22,
    //     pro3F2Collectable.mar22,
    //     pro3F3Collectable.mar22
    // ]
    // export const pro3WithMar22NonCount = [
    //     pro3F1NonCollectable.mar22,
    //     pro3F2NonCollectable.mar22,
    //     pro3F3NonCollectable.mar22
    // ]
    // export const pro3WithMar22Claim = [
    //     pro3F1ClaimCount.mar22,
    //     pro3F2ClaimCount.mar22,
    //     pro3F3ClaimCount.mar22
    // ]
    // export const pro3WithMar22PerVisit = [
    //     pro3F1PerVisit.mar22,
    //     pro3F2PerVisit.mar22,
    //     pro3F3PerVisit.mar22
    // ]
    // // april 22
    // export const pro3WithApr22Charges = [
    //     pro3F1Charge.apr22,
    //     pro3F2Charge.apr22,
    //     pro3F3Charge.apr22
    // ]
    // export const pro3WithApr22Pay = [
    //     pro3F1Payment.apr22,
    //     pro3F2Payment.apr22,
    //     pro3F3Payment.apr22
    // ]
    // export const pro3WithApr22Count = [
    //     pro3F1Collectable.apr22,
    //     pro3F2Collectable.apr22,
    //     pro3F3Collectable.apr22
    // ]
    // export const pro3WithApr22NonCount = [
    //     pro3F1NonCollectable.apr22,
    //     pro3F2NonCollectable.apr22,
    //     pro3F3NonCollectable.apr22
    // ]
    // export const pro3WithApr22Claim = [
    //     pro3F1ClaimCount.apr22,
    //     pro3F2ClaimCount.apr22,
    //     pro3F3ClaimCount.apr22
    // ]
    // export const pro3WithApr22PerVisit = [
    //     pro3F1PerVisit.apr22,
    //     pro3F2PerVisit.apr22,
    //     pro3F3PerVisit.apr22
    // ]
    // // may 22
    // export const pro3WithMay22Charges = [
    //     pro3F1Charge.may22,
    //     pro3F2Charge.may22,
    //     pro3F3Charge.may22
    // ]
    // export const pro3WithMay22Pay = [
    //     pro3F1Payment.may22,
    //     pro3F2Payment.may22,
    //     pro3F3Payment.may22
    // ]
    // export const pro3WithMay22Count = [
    //     pro3F1Collectable.may22,
    //     pro3F2Collectable.may22,
    //     pro3F3Collectable.may22
    // ]
    // export const pro3WithMay22NonCount = [
    //     pro3F1NonCollectable.may22,
    //     pro3F2NonCollectable.may22,
    //     pro3F3NonCollectable.may22
    // ]
    // export const pro3WithMay22Claim = [
    //     pro3F1ClaimCount.may22,
    //     pro3F2ClaimCount.may22,
    //     pro3F3ClaimCount.may22
    // ]
    // export const pro3WithMay22PerVisit = [
    //     pro3F1PerVisit.may22,
    //     pro3F2PerVisit.may22,
    //     pro3F3PerVisit.may22
    // ]
    // // jun 22
    // export const pro3WithJun22Charges = [
    //     pro3F1Charge.jun22,
    //     pro3F2Charge.jun22,
    //     pro3F3Charge.jun22
    // ]
    // export const pro3WithJun22Pay = [
    //     pro3F1Payment.jun22,
    //     pro3F2Payment.jun22,
    //     pro3F3Payment.jun22
    // ]
    // export const pro3WithJun22Count = [
    //     pro3F1Collectable.jun22,
    //     pro3F2Collectable.jun22,
    //     pro3F3Collectable.jun22
    // ]
    // export const pro3WithJun22NonCount = [
    //     pro3F1NonCollectable.jun22,
    //     pro3F2NonCollectable.jun22,
    //     pro3F3NonCollectable.jun22
    // ]
    // export const pro3WithJun22Claim = [
    //     pro3F1ClaimCount.jun22,
    //     pro3F2ClaimCount.jun22,
    //     pro3F3ClaimCount.jun22
    // ]
    // export const pro3WithJun22PerVisit = [
    //     pro3F1PerVisit.jun22,
    //     pro3F2PerVisit.jun22,
    //     pro3F3PerVisit.jun22
    // ]
    // // jul 22
    // export const pro3WithJul22Charges = [
    //     pro3F1Charge.jul22,
    //     pro3F2Charge.jul22,
    //     pro3F3Charge.jul22
    // ]
    // export const pro3WithJul22Pay = [
    //     pro3F1Payment.jul22,
    //     pro3F2Payment.jul22,
    //     pro3F3Payment.jul22
    // ]
    // export const pro3WithJul22Count = [
    //     pro3F1Collectable.jul22,
    //     pro3F2Collectable.jul22,
    //     pro3F3Collectable.jul22
    // ]
    // export const pro3WithJul22NonCount = [
    //     pro3F1NonCollectable.jul22,
    //     pro3F2NonCollectable.jul22,
    //     pro3F3NonCollectable.jul22
    // ]
    // export const pro3WithJul22Claim = [
    //     pro3F1ClaimCount.jul22,
    //     pro3F2ClaimCount.jul22,
    //     pro3F3ClaimCount.jul22
    // ]
    // export const pro3WithJul22PerVisit = [
    //     pro3F1PerVisit.jul22,
    //     pro3F2PerVisit.jul22,
    //     pro3F3PerVisit.jul22
    // ]
    // // aug 22
    // export const pro3WithAug22Charges = [
    //     pro3F1Charge.aug22,
    //     pro3F2Charge.aug22,
    //     pro3F3Charge.aug22
    // ]
    // export const pro3WithAug22Pay = [
    //     pro3F1Payment.aug22,
    //     pro3F2Payment.aug22,
    //     pro3F3Payment.aug22
    // ]
    // export const pro3WithAug22Count = [
    //     pro3F1Collectable.aug22,
    //     pro3F2Collectable.aug22,
    //     pro3F3Collectable.aug22
    // ]
    // export const pro3WithAug22NonCount = [
    //     pro3F1NonCollectable.aug22,
    //     pro3F2NonCollectable.aug22,
    //     pro3F3NonCollectable.aug22
    // ]
    // export const pro3WithAug22Claim = [
    //     pro3F1ClaimCount.aug22,
    //     pro3F2ClaimCount.aug22,
    //     pro3F3ClaimCount.aug22
    // ]
    // export const pro3WithAug22PerVisit = [
    //     pro3F1PerVisit.aug22,
    //     pro3F2PerVisit.aug22,
    //     pro3F3PerVisit.aug22
    // ]
    // // sep 22
    // export const pro3WithSep22Charges = [
    //     pro3F1Charge.sep22,
    //     pro3F2Charge.sep22,
    //     pro3F3Charge.sep22
    // ]
    // export const pro3WithSep22Pay = [
    //     pro3F1Payment.sep22,
    //     pro3F2Payment.sep22,
    //     pro3F3Payment.sep22
    // ]
    // export const pro3WithSep22Count = [
    //     pro3F1Collectable.sep22,
    //     pro3F2Collectable.sep22,
    //     pro3F3Collectable.sep22
    // ]
    // export const pro3WithSep22NonCount = [
    //     pro3F1NonCollectable.sep22,
    //     pro3F2NonCollectable.sep22,
    //     pro3F3NonCollectable.sep22
    // ]
    // export const pro3WithSep22Claim = [
    //     pro3F1ClaimCount.sep22,
    //     pro3F2ClaimCount.sep22,
    //     pro3F3ClaimCount.sep22
    // ]
    // export const pro3WithSep22PerVisit = [
    //     pro3F1PerVisit.sep22,
    //     pro3F2PerVisit.sep22,
    //     pro3F3PerVisit.sep22
    // ]
    // // oct 22
    // export const pro3WithOct22Charges = [
    //     pro3F1Charge.oct22,
    //     pro3F2Charge.oct22,
    //     pro3F3Charge.oct22
    // ]
    // export const pro3WithOct22Pay = [
    //     pro3F1Payment.oct22,
    //     pro3F2Payment.oct22,
    //     pro3F3Payment.oct22
    // ]
    // export const pro3WithOct22Count = [
    //     pro3F1Collectable.oct22,
    //     pro3F2Collectable.oct22,
    //     pro3F3Collectable.oct22
    // ]
    // export const pro3WithOct22NonCount = [
    //     pro3F1NonCollectable.oct22,
    //     pro3F2NonCollectable.oct22,
    //     pro3F3NonCollectable.oct22
    // ]
    // export const pro3WithOct22Claim = [
    //     pro3F1ClaimCount.oct22,
    //     pro3F2ClaimCount.oct22,
    //     pro3F3ClaimCount.oct22
    // ]
    // export const pro3WithOct22PerVisit = [
    //     pro3F1PerVisit.oct22,
    //     pro3F2PerVisit.oct22,
    //     pro3F3PerVisit.oct22
    // ]
    // // nov 22
    // export const pro3WithNov22Charges = [
    //     pro3F1Charge.nov22,
    //     pro3F2Charge.nov22,
    //     pro3F3Charge.nov22
    // ]
    // export const pro3WithNov22Pay = [
    //     pro3F1Payment.nov22,
    //     pro3F2Payment.nov22,
    //     pro3F3Payment.nov22
    // ]
    // export const pro3WithNov22Count = [
    //     pro3F1Collectable.nov22,
    //     pro3F2Collectable.nov22,
    //     pro3F3Collectable.nov22
    // ]
    // export const pro3WithNov22NonCount = [
    //     pro3F1NonCollectable.nov22,
    //     pro3F2NonCollectable.nov22,
    //     pro3F3NonCollectable.nov22
    // ]
    // export const pro3WithNov22Claim = [
    //     pro3F1ClaimCount.nov22,
    //     pro3F2ClaimCount.nov22,
    //     pro3F3ClaimCount.nov22
    // ]
    // export const pro3WithNov22PerVisit = [
    //     pro3F1PerVisit.nov22,
    //     pro3F2PerVisit.nov22,
    //     pro3F3PerVisit.nov22
    // ]
    // // dec 22
    // export const pro3WithDec22Charges = [
    //     pro3F1Charge.dec22,
    //     pro3F2Charge.dec22,
    //     pro3F3Charge.dec22
    // ]
    // export const pro3WithDec22Pay = [
    //     pro3F1Payment.dec22,
    //     pro3F2Payment.dec22,
    //     pro3F3Payment.dec22
    // ]
    // export const pro3WithDec22Count = [
    //     pro3F1Collectable.dec22,
    //     pro3F2Collectable.dec22,
    //     pro3F3Collectable.dec22
    // ]
    // export const pro3WithDec22NonCount = [
    //     pro3F1NonCollectable.dec22,
    //     pro3F2NonCollectable.dec22,
    //     pro3F3NonCollectable.dec22
    // ]
    // export const pro3WithDec22Claim = [
    //     pro3F1ClaimCount.dec22,
    //     pro3F2ClaimCount.dec22,
    //     pro3F3ClaimCount.dec22
    // ]
    // export const pro3WithDec22PerVisit = [
    //     pro3F1PerVisit.dec22,
    //     pro3F2PerVisit.dec22,
    //     pro3F3PerVisit.dec22
    // ]
    
    // // provider-3
    // // jan 23
    // export const pro3WithJan23Charges = [
    //     pro3F1Charge.jan23,
    //     pro3F2Charge.jan23,
    //     pro3F3Charge.jan23
    // ]
    // export const pro3WithJan23Pay = [
    //     pro3F1Payment.jan23,
    //     pro3F2Payment.jan23,
    //     pro3F3Payment.jan23
    // ]
    // export const pro3WithJan23Count = [
    //     pro3F1Collectable.jan23,
    //     pro3F2Collectable.jan23,
    //     pro3F3Collectable.jan23
    // ]
    // export const pro3WithJan23NonCount = [
    //     pro3F1NonCollectable.jan23,
    //     pro3F2NonCollectable.jan23,
    //     pro3F3NonCollectable.jan23
    // ]
    // export const pro3WithJan23Claim = [
    //     pro3F1ClaimCount.jan23,
    //     pro3F2ClaimCount.jan23,
    //     pro3F3ClaimCount.jan23
    // ]
    // export const pro3WithJan23PerVisit = [
    //     pro3F1PerVisit.jan23,
    //     pro3F2PerVisit.jan23,
    //     pro3F3PerVisit.jan23
    // ]
    // // feb 23
    // export const pro3WithFeb23Charges = [
    //     pro3F1Charge.feb23,
    //     pro3F2Charge.feb23,
    //     pro3F3Charge.feb23
    // ]
    // export const pro3WithFeb23Pay = [
    //     pro3F1Payment.feb23,
    //     pro3F2Payment.feb23,
    //     pro3F3Payment.feb23
    // ]
    // export const pro3WithFeb23Count = [
    //     pro3F1Collectable.feb23,
    //     pro3F2Collectable.feb23,
    //     pro3F3Collectable.feb23
    // ]
    // export const pro3WithFeb23NonCount = [
    //     pro3F1NonCollectable.feb23,
    //     pro3F2NonCollectable.feb23,
    //     pro3F3NonCollectable.feb23
    // ]
    // export const pro3WithFeb23Claim = [
    //     pro3F1ClaimCount.feb23,
    //     pro3F2ClaimCount.feb23,
    //     pro3F3ClaimCount.feb23
    // ]
    // export const pro3WithFeb23PerVisit = [
    //     pro3F1PerVisit.feb23,
    //     pro3F2PerVisit.feb23,
    //     pro3F3PerVisit.feb23
    // ]
    // // mar 23
    // export const pro3WithMar23Charges = [
    //     pro3F1Charge.mar23,
    //     pro3F2Charge.mar23,
    //     pro3F3Charge.mar23
    // ]
    // export const pro3WithMar23Pay = [
    //     pro3F1Payment.mar23,
    //     pro3F2Payment.mar23,
    //     pro3F3Payment.mar23
    // ]
    // export const pro3WithMar23Count = [
    //     pro3F1Collectable.mar23,
    //     pro3F2Collectable.mar23,
    //     pro3F3Collectable.mar23
    // ]
    // export const pro3WithMar23NonCount = [
    //     pro3F1NonCollectable.mar23,
    //     pro3F2NonCollectable.mar23,
    //     pro3F3NonCollectable.mar23
    // ]
    // export const pro3WithMar23Claim = [
    //     pro3F1ClaimCount.mar23,
    //     pro3F2ClaimCount.mar23,
    //     pro3F3ClaimCount.mar23
    // ]
    // export const pro3WithMar23PerVisit = [
    //     pro3F1PerVisit.mar23,
    //     pro3F2PerVisit.mar23,
    //     pro3F3PerVisit.mar23
    // ]
    // // april 23
    // export const pro3WithApr23Charges = [
    //     pro3F1Charge.apr23,
    //     pro3F2Charge.apr23,
    //     pro3F3Charge.apr23
    // ]
    // export const pro3WithApr23Pay = [
    //     pro3F1Payment.apr23,
    //     pro3F2Payment.apr23,
    //     pro3F3Payment.apr23
    // ]
    // export const pro3WithApr23Count = [
    //     pro3F1Collectable.apr23,
    //     pro3F2Collectable.apr23,
    //     pro3F3Collectable.apr23
    // ]
    // export const pro3WithApr23NonCount = [
    //     pro3F1NonCollectable.apr23,
    //     pro3F2NonCollectable.apr23,
    //     pro3F3NonCollectable.apr23
    // ]
    // export const pro3WithApr23Claim = [
    //     pro3F1ClaimCount.apr23,
    //     pro3F2ClaimCount.apr23,
    //     pro3F3ClaimCount.apr23
    // ]
    // export const pro3WithApr23PerVisit = [
    //     pro3F1PerVisit.apr23,
    //     pro3F2PerVisit.apr23,
    //     pro3F3PerVisit.apr23
    // ]
    // // may 23
    // export const pro3WithMay23Charges = [
    //     pro3F1Charge.may23,
    //     pro3F2Charge.may23,
    //     pro3F3Charge.may23
    // ]
    // export const pro3WithMay23Pay = [
    //     pro3F1Payment.may23,
    //     pro3F2Payment.may23,
    //     pro3F3Payment.may23
    // ]
    // export const pro3WithMay23Count = [
    //     pro3F1Collectable.may23,
    //     pro3F2Collectable.may23,
    //     pro3F3Collectable.may23
    // ]
    // export const pro3WithMay23NonCount = [
    //     pro3F1NonCollectable.may23,
    //     pro3F2NonCollectable.may23,
    //     pro3F3NonCollectable.may23
    // ]
    // export const pro3WithMay23Claim = [
    //     pro3F1ClaimCount.may23,
    //     pro3F2ClaimCount.may23,
    //     pro3F3ClaimCount.may23
    // ]
    // export const pro3WithMay23PerVisit = [
    //     pro3F1PerVisit.may23,
    //     pro3F2PerVisit.may23,
    //     pro3F3PerVisit.may23
    // ]
    // // jun 23
    // export const pro3WithJun23Charges = [
    //     pro3F1Charge.jun23,
    //     pro3F2Charge.jun23,
    //     pro3F3Charge.jun23
    // ]
    // export const pro3WithJun23Pay = [
    //     pro3F1Payment.jun23,
    //     pro3F2Payment.jun23,
    //     pro3F3Payment.jun23
    // ]
    // export const pro3WithJun23Count = [
    //     pro3F1Collectable.jun23,
    //     pro3F2Collectable.jun23,
    //     pro3F3Collectable.jun23
    // ]
    // export const pro3WithJun23NonCount = [
    //     pro3F1NonCollectable.jun23,
    //     pro3F2NonCollectable.jun23,
    //     pro3F3NonCollectable.jun23
    // ]
    // export const pro3WithJun23Claim = [
    //     pro3F1ClaimCount.jun23,
    //     pro3F2ClaimCount.jun23,
    //     pro3F3ClaimCount.jun23
    // ]
    // export const pro3WithJun23PerVisit = [
    //     pro3F1PerVisit.jun23,
    //     pro3F2PerVisit.jun23,
    //     pro3F3PerVisit.jun23
    // ]
    // // jul 23
    // export const pro3WithJul23Charges = [
    //     pro3F1Charge.jul23,
    //     pro3F2Charge.jul23,
    //     pro3F3Charge.jul23
    // ]
    // export const pro3WithJul23Pay = [
    //     pro3F1Payment.jul23,
    //     pro3F2Payment.jul23,
    //     pro3F3Payment.jul23
    // ]
    // export const pro3WithJul23Count = [
    //     pro3F1Collectable.jul23,
    //     pro3F2Collectable.jul23,
    //     pro3F3Collectable.jul23
    // ]
    // export const pro3WithJul23NonCount = [
    //     pro3F1NonCollectable.jul23,
    //     pro3F2NonCollectable.jul23,
    //     pro3F3NonCollectable.jul23
    // ]
    // export const pro3WithJul23Claim = [
    //     pro3F1ClaimCount.jul23,
    //     pro3F2ClaimCount.jul23,
    //     pro3F3ClaimCount.jul23
    // ]
    // export const pro3WithJul23PerVisit = [
    //     pro3F1PerVisit.jul23,
    //     pro3F2PerVisit.jul23,
    //     pro3F3PerVisit.jul23
    // ]
    // // aug 23
    // export const pro3WithAug23Charges = [
    //     pro3F1Charge.aug23,
    //     pro3F2Charge.aug23,
    //     pro3F3Charge.aug23
    // ]
    // export const pro3WithAug23Pay = [
    //     pro3F1Payment.aug23,
    //     pro3F2Payment.aug23,
    //     pro3F3Payment.aug23
    // ]
    // export const pro3WithAug23Count = [
    //     pro3F1Collectable.aug23,
    //     pro3F2Collectable.aug23,
    //     pro3F3Collectable.aug23
    // ]
    // export const pro3WithAug23NonCount = [
    //     pro3F1NonCollectable.aug23,
    //     pro3F2NonCollectable.aug23,
    //     pro3F3NonCollectable.aug23
    // ]
    // export const pro3WithAug23Claim = [
    //     pro3F1ClaimCount.aug23,
    //     pro3F2ClaimCount.aug23,
    //     pro3F3ClaimCount.aug23
    // ]
    // export const pro3WithAug23PerVisit = [
    //     pro3F1PerVisit.aug23,
    //     pro3F2PerVisit.aug23,
    //     pro3F3PerVisit.aug23
    // ]
    // // sep 23
    // export const pro3WithSep23Charges = [
    //     pro3F1Charge.sep23,
    //     pro3F2Charge.sep23,
    //     pro3F3Charge.sep23
    // ]
    // export const pro3WithSep23Pay = [
    //     pro3F1Payment.sep23,
    //     pro3F2Payment.sep23,
    //     pro3F3Payment.sep23
    // ]
    // export const pro3WithSep23Count = [
    //     pro3F1Collectable.sep23,
    //     pro3F2Collectable.sep23,
    //     pro3F3Collectable.sep23
    // ]
    // export const pro3WithSep23NonCount = [
    //     pro3F1NonCollectable.sep23,
    //     pro3F2NonCollectable.sep23,
    //     pro3F3NonCollectable.sep23
    // ]
    // export const pro3WithSep23Claim = [
    //     pro3F1ClaimCount.sep23,
    //     pro3F2ClaimCount.sep23,
    //     pro3F3ClaimCount.sep23
    // ]
    // export const pro3WithSep23PerVisit = [
    //     pro3F1PerVisit.sep23,
    //     pro3F2PerVisit.sep23,
    //     pro3F3PerVisit.sep23
    // ]
    // // oct 23
    // export const pro3WithOct23Charges = [
    //     pro3F1Charge.oct23,
    //     pro3F2Charge.oct23,
    //     pro3F3Charge.oct23
    // ]
    // export const pro3WithOct23Pay = [
    //     pro3F1Payment.oct23,
    //     pro3F2Payment.oct23,
    //     pro3F3Payment.oct23
    // ]
    // export const pro3WithOct23Count = [
    //     pro3F1Collectable.oct23,
    //     pro3F2Collectable.oct23,
    //     pro3F3Collectable.oct23
    // ]
    // export const pro3WithOct23NonCount = [
    //     pro3F1NonCollectable.oct23,
    //     pro3F2NonCollectable.oct23,
    //     pro3F3NonCollectable.oct23
    // ]
    // export const pro3WithOct23Claim = [
    //     pro3F1ClaimCount.oct23,
    //     pro3F2ClaimCount.oct23,
    //     pro3F3ClaimCount.oct23
    // ]
    // export const pro3WithOct23PerVisit = [
    //     pro3F1PerVisit.oct23,
    //     pro3F2PerVisit.oct23,
    //     pro3F3PerVisit.oct23
    // ]
    // // nov 23
    // export const pro3WithNov23Charges = [
    //     pro3F1Charge.nov23,
    //     pro3F2Charge.nov23,
    //     pro3F3Charge.nov23
    // ]
    // export const pro3WithNov23Pay = [
    //     pro3F1Payment.nov23,
    //     pro3F2Payment.nov23,
    //     pro3F3Payment.nov23
    // ]
    // export const pro3WithNov23Count = [
    //     pro3F1Collectable.nov23,
    //     pro3F2Collectable.nov23,
    //     pro3F3Collectable.nov23
    // ]
    // export const pro3WithNov23NonCount = [
    //     pro3F1NonCollectable.nov23,
    //     pro3F2NonCollectable.nov23,
    //     pro3F3NonCollectable.nov23
    // ]
    // export const pro3WithNov23Claim = [
    //     pro3F1ClaimCount.nov23,
    //     pro3F2ClaimCount.nov23,
    //     pro3F3ClaimCount.nov23
    // ]
    // export const pro3WithNov23PerVisit = [
    //     pro3F1PerVisit.nov23,
    //     pro3F2PerVisit.nov23,
    //     pro3F3PerVisit.nov23
    // ]









