import React ,{useState} from 'react'
import { Col, Row } from 'reactstrap'
import RejectedClaimBlocks from './RejectedClaimBlocks'
import RejectedClaimsCharts from './RejectedClaimsCharts'

const RejectedClaimsDashBoard = () => {
    const [blockData,setBlockData] = useState({
        header:'',
        rejectedClaim:''
    })
    const handleData = (a,b) =>{
        // console.log('a :',a,'b :',b)
        setBlockData({
            header:a,      
            rejectedClaim:b,
        })
    }
    return (
        <>
            <Row>
                <RejectedClaimBlocks data={blockData} />
            </Row>
            <Row className='mt-3'>

                <RejectedClaimsCharts callBack={handleData} />
            </Row>
        </>
    )
}

export default RejectedClaimsDashBoard