import React from 'react'
import Chart from 'react-apexcharts';

const ERAPie = ({ reUse, data }) => {
    const optionsvisitors = {
        labels: reUse ? ['Par', 'Non-Par', 'Completed', 'In Progress'] : [' ERA eligible  and Enrolled', 'ERA Eligible  and Not enrolled', 'ERA not applicable'],
        chart: {
            fontFamily: "'Poppins', sans-serif",
        },
        dataLabels: {
            enabled: false,
        },
        grid: {
            padding: {
                left: 5,
                right: 5,
            },
            borderColor: 'transparent',
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '82px',
                    labels: {
                        show: true,
                        name: {
                            show: true,
                            fontSize: '18px',
                            color: undefined,
                            offsetY: -10,
                        },
                        value: {
                            show: true,
                            color: '#99abb4',
                        },
                        total: {
                            show: true,
                            label: reUse ? 'Credentailing Status' : 'ERA Status',
                            color: 'black'
                        },
                    },
                },
            },
        },
        stroke: {
            width: 10,
            colors: 'transparent',
        },
        legend: {
            show: true,
        },
        tooltip: {
            fillSeriesColor: false,
        },
        responsive: [
            {
                breakpoint: 380,
                options: {
                    chart: {
                        height: 250,
                    },
                },
            },
        ],
    };

    const seriesvisitors = sessionStorage.getItem('name') === 'Aurora' ? [11, 0, 10] :

        sessionStorage.getItem('name') === 'PersonalClinic' ? [17, 12, 43] :
            sessionStorage.getItem('name') === 'RX' && !reUse ? [12, 8, 40] :
                sessionStorage.getItem('name') === 'RX' && reUse ? [...data] :
                    ''
    return (
        <div className='border border-2' >
            <div className=" position-relative" style={{ height: '350px' }}>
                <Chart options={optionsvisitors} series={seriesvisitors} type="donut" height="355px" />
            </div>
            <div className="d-flex gap-0 mt-0 pt-0 justify-content-center">
                <div className="d-flex align-items-center text-primary fs-6">
                    <i className="bi bi-circle-fill fs-7 me-2"></i>{reUse ? 'PAR' : `ERA eligible  and Enrolled`}
                </div>
                <div className="d-flex align-items-center text-success fs-6">
                    <i className="bi bi-circle-fill fs-7 me-2"></i>{reUse ? 'NON-PAR' : `ERA eligible  and Not Enrolled`}
                </div>
                <div className="d-flex align-items-center text-warning fs-6">
                    <i className="bi bi-circle-fill fs-7 me-2"></i>{reUse ? 'Completed' : `ERA not applicable`}
                </div>
                <div className="d-flex align-items-center text-danger fs-6" hidden={reUse ? false : true}>
                    <i className="bi bi-circle-fill fs-7 me-2"></i>In Progress
                </div>
                {/* <div className="d-flex align-items-center text-danger fs-6">
                    <i className="bi bi-circle-fill fs-7 me-2"></i>Non-Col-Adjustments
                </div>
                <div className="d-flex align-items-center fs-6" style={{ color: 'purple' }}>
                    <i className="bi bi-circle-fill fs-7 me-2"></i>Claim Count
                </div>
                <div className="d-flex align-items-center text-dark fs-6">
                    <i className="bi bi-circle-fill fs-7 me-2"></i>Per Visit
                </div> */}
            </div>
        </div>
    )

}

export default ERAPie