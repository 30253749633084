import React from 'react'
import { Row } from 'reactstrap'
import { RiCopyrightLine } from 'react-icons/ri'

const Footer = () => {
    return (
        <div className='mt-3 p-2  text-center w-100 position-static top-100 bottom-0'>
            <span className='fw-bolder fs-4  '><RiCopyrightLine /></span>
            <span className='mx-1text-muted'>{new Date().getFullYear()}</span>
            <span className='mx-2 text-dark text-muted'>RxRevenue</span>
            <span className=' mx-1 text-dark'>All Rights Reserved</span>
        </div>
    )
}

export default Footer