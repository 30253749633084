export const jan2023Series = [
    {
        name: 'Charges',
        data: [
            121645.56


        ]
    },
    {
        name: 'Payment',
        data: [
            16608.45




        ]
    },
    {
        name: 'Collectable',
        data: [
            4801.58

        ]
    },
    {
        name: 'Non Collectable',
        data: [

            61008.37


        ]
    },
    {
        name: 'Claims',
        data: [
            110


        ]
    },
    {
        name: 'Average Visit',
        data: [
            150.99
        ]
    },
]
export const feb2023Series = [
    {
        name: 'Charges',
        data: [

            51109




        ]
    },
    {
        name: 'Payment',
        data: [
            22276.56



        ]
    },
    {
        name: 'Collectable',
        data: [
            348.14

        ]
    },
    {
        name: 'Non Collectable',
        data: [

            78890.52

        ]
    },
    {
        name: 'Claims',
        data: [
            55



        ]
    },
    {
        name: 'Average Visit',
        data: [
            405.03
        ]
    },
]
export const mar2023Series = [
    {
        name: 'Charges',
        data: [

            135319





        ]
    },
    {
        name: 'Payment',
        data: [
            28900.62



        ]
    },
    {
        name: 'Collectable',
        data: [
            3261.16

        ]
    },
    {
        name: 'Non Collectable',
        data: [

            97653.16

        ]
    },
    {
        name: 'Claims',
        data: [
            117



        ]
    },
    {
        name: 'Average Visit',
        data: [
            247.01
        ]
    },
]
export const apr2023Series = [
    {
        name: 'Charges',
        data: [

            128221



        ]
    },
    {
        name: 'Payment',
        data: [
            25559.7



        ]
    },
    {
        name: 'Collectable',
        data: [
            4484.63

        ]
    },
    {
        name: 'Non Collectable',
        data: [

            58972.81

        ]
    },
    {
        name: 'Claims',
        data: [
            110



        ]
    },
    {
        name: 'Average Visit',
        data: [
            232.362
        ]
    },
]
export const may2023Series = [
    {
        name: 'Charges',
        data: [

            137840.04




        ]
    },
    {
        name: 'Payment',
        data: [
            27936.1



        ]
    },
    {
        name: 'Collectable',
        data: [
            7105.55

        ]
    },
    {
        name: 'Non Collectable',
        data: [

            96544.37

        ]
    },
    {
        name: 'Claims',
        data: [
            109



        ]
    },
    {
        name: 'Average Visit',
        data: [
            256.29
        ]
    },
]

export const jun2023Series = [
    {
        name: 'Charges',
        data: [

            169826.52




        ]
    },
    {
        name: 'Payment',
        data: [
            33230.84



        ]
    },
    {
        name: 'Collectable',
        data: [
            21776.81

        ]
    },
    {
        name: 'Non Collectable',
        data: [

            94347.64

        ]
    },
    {
        name: 'Claims',
        data: [
            134



        ]
    },
    {
        name: 'Average Visit',
        data: [
            247.99
        ]
    },
]

export const jul2023Series = [
    {
        name: 'Charges',
        data: [

            90351




        ]
    },
    {
        name: 'Payment',
        data: [
            25944.87



        ]
    },
    {
        name: 'Collectable',
        data: [
            6407.12

        ]
    },
    {
        name: 'Non Collectable',
        data: [

            68795.65

        ]
    },
    {
        name: 'Claims',
        data: [
            76



        ]
    },
    {
        name: 'Average Visit',
        data: [
            341.38
        ]
    },
]
export const aug2023Series = [
    {
        name: 'Charges',
        data: [

            158702




        ]
    },
    {
        name: 'Payment',
        data: [
            26281.65



        ]
    },
    {
        name: 'Collectable',
        data: [
            14581.63

        ]
    },
    {
        name: 'Non Collectable',
        data: [

            83061.48

        ]
    },
    {
        name: 'Claims',
        data: [
            133
        ]
    },
    {
        name: 'Average Visit',
        data: [
            197.61
        ]
    },
]
export const sep2023Series = [
    {
        name: 'Charges',
        data: [

            123723

        ]
    },
    {
        name: 'Payment',
        data: [
            52398.96

        ]
    },
    {
        name: 'Collectable',
        data: [
            2457.18

        ]
    },
    {
        name: 'Non Collectable',
        data: [
            149185.23
        ]
    },
    {
        name: 'Claims',
        data: [
            103

        ]
    },
    {
        name: 'Average Visit',
        data: [
            508.73
        ]
    },
]
export const oct2023Series = [
    {
        name: 'Charges',
        data: [
            109564
        ]
    },
    {
        name: 'Payment',
        data: [
            33388.75
        ]
    },
    {
        name: 'Collectable',
        data: [
            3989.12
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            87345.09
        ]
    },
    {
        name: 'Claims',
        data: [
            98

        ]
    },
    {
        name: 'Average Visit',
        data: [
            340.70
        ]
    },
]
export const nov2023Series = [
    {
        name: 'Charges',
        data: [
            137811
        ]
    },
    {
        name: 'Payment',
        data: [
            21280.17
        ]
    },
    {
        name: 'Collectable',
        data: [
            6586.54
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            61742.51
        ]
    },
    {
        name: 'Claims',
        data: [
            103

        ]
    },
    {
        name: 'Average Visit',
        data: [
            206.60
        ]
    },
]
export const dec2023Series = [
    {
        name: 'Charges',
        data: [
            95060
        ]
    },
    {
        name: 'Payment',
        data: [
            30310.51
        ]
    },
    {
        name: 'Collectable',
        data: [
            4454.09
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            85095.25
        ]
    },
    {
        name: 'Claims',
        data: [
            82

        ]
    },
    {
        name: 'Average Visit',
        data: [
            369.64
        ]
    },
]