import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'
import IndividualProviders from './IndividualProviders'
import NewProvider from './NewProvider'
import NewProviderPie from './NewProviderPie'
import NewProvidervertical from './NewProvidervertical'
import Provider2 from './Provider2'
import Provider3 from './Provider3'
import Provider4 from './Provider4'
import Provider5 from './Provider5'
import Provider6 from './Provider6'
import Provider7 from './Provider7'
import ProviderDashBoard from './ProviderDashBoard'
import NewProviderHeatMap from './NewProviderHeatMap';
import NewProviderRadar from './NewProviderRadar'
import ProviderGrid from './../Grid/ProviderGrid';
import NestedTable from './NestedTable';

const OverAllProvider = () => {


    return (
        <Row >
       
            <Row className='m-2 border'>

                <NewProvider />

            </Row>
            {/* <Row className='m-2 '>

                <NestedTable />

            </Row> */}

            {/* <Row className='m-2 border'>

                <NewProvidervertical />

            </Row> */}
            {/* <Row className='m-2 border'>

                <NewProviderPie />

            </Row> */}
            {/* <Row className='m-2 border'>

                <NewProviderHeatMap />

            </Row>
            <Row className='m-2 border'>

                <NewProviderRadar />

            </Row> */}

            {/* <Row className='m-2 border'>
                <Col >
                    <Provider2 />
                </Col>
                <Col className='border-start border-end'>
                    <Provider3 />
                </Col>
            </Row>
            <Row className='m-2 border'>
                <Col >
                    <Provider4 />
                </Col>
                <Col className='border-start border-end'>
                    <Provider5 />
                </Col>
            </Row>
            <Row className='m-2 border'>  
                <Col >
                    <Provider6 />
                </Col>
                <Col className='border-start border-end'>
                    <Provider7 />
                </Col>
            </Row> */}



        </Row>
    )
}

export default OverAllProvider