import React, { useState } from 'react'
import { Row, Col, Modal, ModalBody, Button } from 'reactstrap'
import ERAPie from '../ERAStatus/ERAPie'
import bounceModule from '../ERAStatus//ERAModule.module.css'

const CredentialChart = () => {
    const proValues = ['Provider1', 'Provider2', 'Provider3']
    const provider1 = [21, 0, 21, 0]
    const provider2 = [13, 8, 13, 8]
    const provider3 = [14, 7, 14, 7]
    const pro1Modal = [
        [
            'AETNA',
            'BCBS MEDICARE ADVANTAGE',
            'BCBS OF ILLINOIS',
            'CIGNA',
            'HUMANA CLAIMS',
            'MEDICARE',
            'RAILROAD MEDICARE',
            'UMR',
            'UNITED HEALTHCARE (OPTUM)',
            'ALLIED BENEFIT SYSTEMS',
            'ASCENSION SMART HEALTH',
            'BENEFIT ADMINISTRATIVE SYSTEMS,LLC',
            'BLUE CROSS COMMUNITY OPTIONS',
            'BRIGHT HEALTH',
            'CIGNA HEALTHSPRING',
            'GREAT WEST HEALTHCARE',
            'LOYOLA PHSYSICIAN GROUP',
            'MERITAIN HEALTH',
            'OSCAR',
            'TRICARE',
            'WELLCARE'
        ]
        ,
        [
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',

        ]
        ,
        [],
        [],
    ]
    const pro2Modal = [

        [
            'CIGNA',
            'HUMANA CLAIMS',
            'MEDICARE',
            'RAILROAD MEDICARE',
            'UMR',
            'UNITED HEALTHCARE (OPTUM)',
            'BENEFIT ADMINISTRATIVE SYSTEMS,LLC',
            'BRIGHT HEALTH',
            'CIGNA HEALTHSPRING',
            ' GREAT WEST HEALTHCARE',
            'OSCAR',
            'TRICARE',
            'WELLCARE',

        ],
        [
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-']
        ,
        [
            'AETNA',
            'BCBS MEDICARE ADVANTAGE',
            'BCBS OF ILLINOIS',
            'ALLIED BENEFIT SYSTEMS',
            'ASCENSION SMART HEALTH',
            'BLUE CROSS COMMUNITY OPTIONS',
            'LOYOLA PHSYSICIAN GROUP',
            'MERITAIN HEALTH',
        ]
        ,
        [
            '19 - 05 - 2023',
            '30 - 05 - 2023',
            '30 - 05 - 2023',
            '19-05-2023',
            '30-05-2023',
            '30-05-2023',
            '12-06-2023',
            '19-05-2023',
        ]
        ,
    ]
    const pro3Modal = [
        [
            'AETNA',
            'BCBS MEDICARE ADVANTAGE',
            'BCBS OF ILLINOIS',
            'CIGNA',
            'HUMANA CLAIMS',
            'MEDICARE',
            'ALLIED BENEFIT SYSTEMS',
            'ASCENSION SMART HEALTH',
            'BENEFIT ADMINISTRATIVE SYSTEMS, LLC',
            'BLUE CROSS COMMUNITY OPTIONS',
            'BRIGHT HEALTH',
            'CIGNA HEALTHSPRING',
            'GREAT WEST HEALTHCARE',
            'LOYOLA PHSYSICIAN GROUP'
        ]
        ,
        [
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',

        ]
        ,
        [
            'RAILROAD MEDICARE',
            'UMR',
            'UNITED HEALTHCARE(OPTUM)',
            'MERITAIN HEALTH',
            'OSCAR',
            'TRICARE',
            'WELLCARE'
        ]
        ,
        [
            '-',
            '15-06-2023',
            '15-06-2023',
            '29-05-2023',
            '19-05-2023',
            '-',
            '29-05-2023'
        ]

    ]
    const popup = ['Payers Name']
    const [provider, setProvider] = useState('Provider1')
    const [openMod, setopenMod] = useState(false)
    const [modData, setmodData] = useState([])
    const handleModal = () => {
        setopenMod(true)
        if (provider === 'Provider1') {
            setmodData([...pro1Modal])
        }
        if (provider === 'Provider2') {
            setmodData([...pro2Modal])
        }
        if (provider === 'Provider3') {
            setmodData([...pro3Modal])
        }

    }
    const handleClose = () => {
        setopenMod(false)

    }
    // console.log('dataaa ', modData)
    return (
        <div style={{ width: '100%' }}>
            <Row >
                <Modal isOpen={openMod} size='xl'>
                    <ModalBody>
                    <h3 className='text-center'>{provider}</h3>
                            <h6 className='text-center text-muted '>Completed & In Progress Payer Name</h6>
                        <div className='d-flex justify-content-center flex-row'>
                           

                            {
                                modData.length ? modData.map((val, ind) => {
                                    return <div className='d-flex justify-content-start flex-row m-2 border border rounded'>
                                            <div style={{float:'left',alignContent:'left'}}>
                                                <h5 className='m-2'>{ind === 0 ? 'Completed' : ind === 1 ? 'Completed Follow up' : ind === 2 ? 'In Progress' : 'In progress Follow up'}</h5>

                                                {
                                                    val.map((val1, ind1) => {
                                                        return <><span className='text-start fs-7 m-2'>{ind1 + 1}).{val1}</span><br /></>
                                                    })
                                                }
                                            </div>
                                        </div>
                                }) : ''
                            }
                        </div>
                        <Row>
                            <Col md='2'>
                                <Button className='border border-none bg-danger text-white'
                                    onClick={() => handleClose()}>Close</Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
                <Col md='3' className='m-2'>
                    <label className='fs-4 text-dark'>Select Provider</label>
                    <select value={provider} className='form-select' onChange={(e) => setProvider(e.target.value)}>
                        {
                            proValues.map((val, ind) => {
                                return <option key={ind} value={val}>{val}</option>
                            })
                        }
                    </select>
                </Col>
                <Row className='d-flex justify-content-center flex-row'>
                    <Col>
                        <ERAPie reUse={true} data={provider === 'Provider1' ? [...provider1] : provider === 'Provider2' ? [...provider2] : [...provider3]} />
                    </Col>
                    <Col>
                        {
                            popup.map((val, ind) => {
                                return <Row>
                                    <Col
                                        onClick={() => handleModal()}
                                        style={{ backgroundColor: '#6e48aa', cursor: 'pointer' }}
                                        className={`m-1  p-2 border rounded ${bounceModule.bounce}`} >
                                        <h3 className='text-white'>{val}</h3>
                                        <span className='text-center fs-3'>Click here to see more details</span>
                                    </Col>
                                </Row>
                            })
                        }
                    </Col>
                </Row>
            </Row>
        </div>
    )
}

export default CredentialChart