import {    pro4F1Charge,pro4F1Payment,pro4F1Collectable,pro4F1NonCollectable,pro4F1ClaimCount,pro4F1PerVisit,
    pro4F2Charge,pro4F2Payment,pro4F2Collectable,pro4F2NonCollectable,pro4F2ClaimCount,pro4F2PerVisit,
    pro4F3Charge,pro4F3Payment,pro4F3Collectable,pro4F3NonCollectable,pro4F3ClaimCount,pro4F3PerVisit,} from './FacilityData';


import {provider4PerVisist} from './ProvidersAndFacility';



export const pro4WithJan21Charges = [
    pro4F1Charge.jan21,
    pro4F2Charge.jan21,
    pro4F3Charge.jan21,
    pro4F1Charge.jan21 + pro4F2Charge.jan21 + pro4F3Charge.jan21

]
// console.log("pro4WithJan21Charges :",pro4WithJan21Charges)
export const pro4WithJan21Pay = [
    pro4F1Payment.jan21,
    pro4F2Payment.jan21,
    pro4F3Payment.jan21,
    pro4F1Payment.jan21 + pro4F2Payment.jan21 + pro4F3Payment.jan21
]
export const pro4WithJan21Count = [
    pro4F1Collectable.jan21,
    pro4F2Collectable.jan21,
    pro4F3Collectable.jan21,
    pro4F1Collectable.jan21 + pro4F2Collectable.jan21 + pro4F3Collectable.jan21
]
export const pro4WithJan21NonCount = [
    pro4F1NonCollectable.jan21,
    pro4F2NonCollectable.jan21,
    pro4F3NonCollectable.jan21,
    pro4F1NonCollectable.jan21 + pro4F2NonCollectable.jan21 + pro4F3NonCollectable.jan21
]
export const pro4WithJan21Claim = [
    pro4F1ClaimCount.jan21,
    pro4F2ClaimCount.jan21,
    pro4F3ClaimCount.jan21,
    pro4F1ClaimCount.jan21 + pro4F2ClaimCount.jan21 + pro4F3ClaimCount.jan21
]
export const pro4WithJan21PerVisit = [
    pro4F1PerVisit.jan21,
    pro4F2PerVisit.jan21,
    pro4F3PerVisit.jan21,
    provider4PerVisist[0]
]
// feb 21
export const pro4WithFeb21Charges = [
    pro4F1Charge.feb21,
    pro4F2Charge.feb21,
    pro4F3Charge.feb21,
    pro4F1Charge.feb21 + pro4F2Charge.feb21 + pro4F3Charge.feb21
]
export const pro4WithFeb21Pay = [
    pro4F1Payment.feb21,
    pro4F2Payment.feb21,
    pro4F3Payment.feb21,
    pro4F1Payment.feb21 + pro4F2Payment.feb21 + pro4F3Payment.feb21
]
export const pro4WithFeb21Count = [
    pro4F1Collectable.feb21,
    pro4F2Collectable.feb21,
    pro4F3Collectable.feb21,
    pro4F1Collectable.feb21 + pro4F2Collectable.feb21 + pro4F3Collectable.feb21
]
export const pro4WithFeb21NonCount = [
    pro4F1NonCollectable.feb21,
    pro4F2NonCollectable.feb21,
    pro4F3NonCollectable.feb21,
    pro4F1NonCollectable.feb21 + pro4F2NonCollectable.feb21 + pro4F3NonCollectable.feb21
]
export const pro4WithFeb21Claim = [
    pro4F1ClaimCount.feb21,
    pro4F2ClaimCount.feb21,
    pro4F3ClaimCount.feb21,
    pro4F1ClaimCount.feb21 + pro4F2ClaimCount.feb21 + pro4F3ClaimCount.feb21
]
export const pro4WithFeb21PerVisit = [
    pro4F1PerVisit.feb21,
    pro4F2PerVisit.feb21,
    pro4F3PerVisit.feb21,
    provider4PerVisist[1]
]
// mar 21
export const pro4WithMar21Charges = [
    pro4F1Charge.mar21,
    pro4F2Charge.mar21,
    pro4F3Charge.mar21,
    pro4F1Charge.mar21 + pro4F2Charge.mar21 + pro4F3Charge.mar21
]
export const pro4WithMar21Pay = [
    pro4F1Payment.mar21,
    pro4F2Payment.mar21,
    pro4F3Payment.mar21,
    pro4F1Payment.mar21 + pro4F2Payment.mar21 + pro4F3Payment.mar21
]
export const pro4WithMar21Count = [
    pro4F1Collectable.mar21,
    pro4F2Collectable.mar21,
    pro4F3Collectable.mar21,
    pro4F1Collectable.mar21 + pro4F2Collectable.mar21 + pro4F3Collectable.mar21
]
export const pro4WithMar21NonCount = [
    pro4F1NonCollectable.mar21,
    pro4F2NonCollectable.mar21,
    pro4F3NonCollectable.mar21,
    pro4F1NonCollectable.mar21 + pro4F2NonCollectable.mar21 + pro4F3NonCollectable.mar21
]
export const pro4WithMar21Claim = [
    pro4F1ClaimCount.mar21,
    pro4F2ClaimCount.mar21,
    pro4F3ClaimCount.mar21,
    pro4F1ClaimCount.mar21 + pro4F2ClaimCount.mar21 + pro4F3ClaimCount.mar21
]
export const pro4WithMar21PerVisit = [
    pro4F1PerVisit.mar21,
    pro4F2PerVisit.mar21,
    pro4F3PerVisit.mar21,
    provider4PerVisist[2]
]
// april 21
export const pro4WithApr21Charges = [
    pro4F1Charge.apr21,
    pro4F2Charge.apr21,
    pro4F3Charge.apr21,
    pro4F1Charge.apr21 + pro4F2Charge.apr21 + pro4F3Charge.apr21
]
export const pro4WithApr21Pay = [
    pro4F1Payment.apr21,
    pro4F2Payment.apr21,
    pro4F3Payment.apr21,
    pro4F1Payment.apr21 + pro4F2Payment.apr21 + pro4F3Payment.apr21
]
export const pro4WithApr21Count = [
    pro4F1Collectable.apr21,
    pro4F2Collectable.apr21,
    pro4F3Collectable.apr21,
    pro4F1Collectable.apr21 + pro4F2Collectable.apr21 + pro4F3Collectable.apr21
]
export const pro4WithApr21NonCount = [
    pro4F1NonCollectable.apr21,
    pro4F2NonCollectable.apr21,
    pro4F3NonCollectable.apr21,
    pro4F1NonCollectable.apr21 + pro4F2NonCollectable.apr21 + pro4F3NonCollectable.apr21
]
export const pro4WithApr21Claim = [
    pro4F1ClaimCount.apr21,
    pro4F2ClaimCount.apr21,
    pro4F3ClaimCount.apr21,
    pro4F1ClaimCount.apr21 + pro4F2ClaimCount.apr21 + pro4F3ClaimCount.apr21
]
export const pro4WithApr21PerVisit = [
    pro4F1PerVisit.apr21,
    pro4F2PerVisit.apr21,
    pro4F3PerVisit.apr21,
    provider4PerVisist[3]
]
// may 21
export const pro4WithMay21Charges = [
    pro4F1Charge.may21,
    pro4F2Charge.may21,
    pro4F3Charge.may21,
    pro4F1Charge.may21 + pro4F2Charge.may21 + pro4F3Charge.may21
]
export const pro4WithMay21Pay = [
    pro4F1Payment.may21,
    pro4F2Payment.may21,
    pro4F3Payment.may21,
    pro4F1Payment.may21 + pro4F2Payment.may21 + pro4F3Payment.may21
]
export const pro4WithMay21Count = [
    pro4F1Collectable.may21,
    pro4F2Collectable.may21,
    pro4F3Collectable.may21,
    pro4F1Collectable.may21 + pro4F2Collectable.may21 + pro4F3Collectable.may21
]
export const pro4WithMay21NonCount = [
    pro4F1NonCollectable.may21,
    pro4F2NonCollectable.may21,
    pro4F3NonCollectable.may21,
    pro4F1NonCollectable.may21 + pro4F2NonCollectable.may21 + pro4F3NonCollectable.may21
]
export const pro4WithMay21Claim = [
    pro4F1ClaimCount.may21,
    pro4F2ClaimCount.may21,
    pro4F3ClaimCount.may21,
    pro4F1ClaimCount.may21 + pro4F2ClaimCount.may21 + pro4F3ClaimCount.may21
]
export const pro4WithMay21PerVisit = [
    pro4F1PerVisit.may21,
    pro4F2PerVisit.may21,
    pro4F3PerVisit.may21,
    provider4PerVisist[4]
]
// jun 21
export const pro4WithJun21Charges = [
    pro4F1Charge.jun21,
    pro4F2Charge.jun21,
    pro4F3Charge.jun21,
    pro4F1Charge.jun21 + pro4F2Charge.jun21 + pro4F3Charge.jun21
]
export const pro4WithJun21Pay = [
    pro4F1Payment.jun21,
    pro4F2Payment.jun21,
    pro4F3Payment.jun21,
    pro4F1Payment.jun21 + pro4F2Payment.jun21 + pro4F3Payment.jun21
]
export const pro4WithJun21Count = [
    pro4F1Collectable.jun21,
    pro4F2Collectable.jun21,
    pro4F3Collectable.jun21,
    pro4F1Collectable.jun21 + pro4F2Collectable.jun21 + pro4F3Collectable.jun21
]
export const pro4WithJun21NonCount = [
    pro4F1NonCollectable.jun21,
    pro4F2NonCollectable.jun21,
    pro4F3NonCollectable.jun21,
    pro4F1NonCollectable.jun21 + pro4F2NonCollectable.jun21 + pro4F3NonCollectable.jun21
]
export const pro4WithJun21Claim = [
    pro4F1ClaimCount.jun21,
    pro4F2ClaimCount.jun21,
    pro4F3ClaimCount.jun21,
    pro4F1ClaimCount.jun21 + pro4F2ClaimCount.jun21 + pro4F3ClaimCount.jun21
]
export const pro4WithJun21PerVisit = [
    pro4F1PerVisit.jun21,
    pro4F2PerVisit.jun21,
    pro4F3PerVisit.jun21,
    provider4PerVisist[5]
]
// jul 21
export const pro4WithJul21Charges = [
    pro4F1Charge.jul21,
    pro4F2Charge.jul21,
    pro4F3Charge.jul21,
    pro4F1Charge.jul21 + pro4F2Charge.jul21 + pro4F3Charge.jul21,

]
export const pro4WithJul21Pay = [
    pro4F1Payment.jul21,
    pro4F2Payment.jul21,
    pro4F3Payment.jul21,
    pro4F1Payment.jul21 + pro4F2Payment.jul21 + pro4F3Payment.jul21
]
export const pro4WithJul21Count = [
    pro4F1Collectable.jul21,
    pro4F2Collectable.jul21,
    pro4F3Collectable.jul21,
    pro4F1Collectable.jul21 + pro4F2Collectable.jul21 + pro4F3Collectable.jul21
]
export const pro4WithJul21NonCount = [
    pro4F1NonCollectable.jul21,
    pro4F2NonCollectable.jul21,
    pro4F3NonCollectable.jul21,
    pro4F1NonCollectable.jul21 + pro4F2NonCollectable.jul21 + pro4F3NonCollectable.jul21
]
export const pro4WithJul21Claim = [
    pro4F1ClaimCount.jul21,
    pro4F2ClaimCount.jul21,
    pro4F3ClaimCount.jul21,
    pro4F1ClaimCount.jul21 + pro4F2ClaimCount.jul21 + pro4F3ClaimCount.jul21
]
export const pro4WithJul21PerVisit = [
    pro4F1PerVisit.jul21,
    pro4F2PerVisit.jul21,
    pro4F3PerVisit.jul21,
    provider4PerVisist[6]
]
// aug 21
export const pro4WithAug21Charges = [
    pro4F1Charge.aug21,
    pro4F2Charge.aug21,
    pro4F3Charge.aug21,
    pro4F1Charge.aug21 + pro4F2Charge.aug21 + pro4F3Charge.aug21
]
export const pro4WithAug21Pay = [
    pro4F1Payment.aug21,
    pro4F2Payment.aug21,
    pro4F3Payment.aug21,
    pro4F1Payment.aug21 + pro4F2Payment.aug21 + pro4F3Payment.aug21
]
export const pro4WithAug21Count = [
    pro4F1Collectable.aug21,
    pro4F2Collectable.aug21,
    pro4F3Collectable.aug21,
    pro4F1Collectable.aug21 + pro4F2Collectable.aug21 + pro4F3Collectable.aug21
]
export const pro4WithAug21NonCount = [
    pro4F1NonCollectable.aug21,
    pro4F2NonCollectable.aug21,
    pro4F3NonCollectable.aug21,
    pro4F1NonCollectable.aug21 + pro4F2NonCollectable.aug21 + pro4F3NonCollectable.aug21
]
export const pro4WithAug21Claim = [
    pro4F1ClaimCount.aug21,
    pro4F2ClaimCount.aug21,
    pro4F3ClaimCount.aug21,
    pro4F1ClaimCount.aug21 + pro4F2ClaimCount.aug21 + pro4F3ClaimCount.aug21
]
export const pro4WithAug21PerVisit = [
    pro4F1PerVisit.aug21,
    pro4F2PerVisit.aug21,
    pro4F3PerVisit.aug21,
    provider4PerVisist[7]
]
// sep 21
export const pro4WithSep21Charges = [
    pro4F1Charge.sep21,
    pro4F2Charge.sep21,
    pro4F3Charge.sep21,
    pro4F1Charge.sep21 + pro4F2Charge.sep21 + pro4F3Charge.sep21
]
export const pro4WithSep21Pay = [
    pro4F1Payment.sep21,
    pro4F2Payment.sep21,
    pro4F3Payment.sep21,
    pro4F1Payment.sep21 + pro4F2Payment.sep21 + pro4F3Payment.sep21
]
export const pro4WithSep21Count = [
    pro4F1Collectable.sep21,
    pro4F2Collectable.sep21,
    pro4F3Collectable.sep21,
    pro4F1Collectable.sep21 + pro4F2Collectable.sep21 + pro4F3Collectable.sep21
]
export const pro4WithSep21NonCount = [
    pro4F1NonCollectable.sep21,
    pro4F2NonCollectable.sep21,
    pro4F3NonCollectable.sep21,
    pro4F1NonCollectable.sep21 + pro4F2NonCollectable.sep21 + pro4F3NonCollectable.sep21
]
export const pro4WithSep21Claim = [
    pro4F1ClaimCount.sep21,
    pro4F2ClaimCount.sep21,
    pro4F3ClaimCount.sep21,
    pro4F1ClaimCount.sep21 + pro4F2ClaimCount.sep21 + pro4F3ClaimCount.sep21
]
export const pro4WithSep21PerVisit = [
    pro4F1PerVisit.sep21,
    pro4F2PerVisit.sep21,
    pro4F3PerVisit.sep21,
    provider4PerVisist[8]
]
// oct 21
export const pro4WithOct21Charges = [
    pro4F1Charge.oct21,
    pro4F2Charge.oct21,
    pro4F3Charge.oct21,
    pro4F1Charge.oct21 + pro4F2Charge.oct21 + pro4F3Charge.oct21
]
export const pro4WithOct21Pay = [
    pro4F1Payment.oct21,
    pro4F2Payment.oct21,
    pro4F3Payment.oct21,
    pro4F1Payment.oct21 + pro4F2Payment.oct21 + pro4F3Payment.oct21
]
export const pro4WithOct21Count = [
    pro4F1Collectable.oct21,
    pro4F2Collectable.oct21,
    pro4F3Collectable.oct21,
    pro4F1Collectable.oct21 + pro4F2Collectable.oct21 + pro4F3Collectable.oct21
]
export const pro4WithOct21NonCount = [
    pro4F1NonCollectable.oct21,
    pro4F2NonCollectable.oct21,
    pro4F3NonCollectable.oct21,
    pro4F1NonCollectable.oct21 + pro4F2NonCollectable.oct21 + pro4F3NonCollectable.oct21
]
export const pro4WithOct21Claim = [
    pro4F1ClaimCount.oct21,
    pro4F2ClaimCount.oct21,
    pro4F3ClaimCount.oct21,
    pro4F1ClaimCount.oct21 + pro4F2ClaimCount.oct21 + pro4F3ClaimCount.oct21
]
export const pro4WithOct21PerVisit = [
    pro4F1PerVisit.oct21,
    pro4F2PerVisit.oct21,
    pro4F3PerVisit.oct21,
    provider4PerVisist[9]
]
// nov 21
export const pro4WithNov21Charges = [
    pro4F1Charge.nov21,
    pro4F2Charge.nov21,
    pro4F3Charge.nov21,
    pro4F1Charge.nov21 + pro4F2Charge.nov21 + pro4F3Charge.nov21
]
export const pro4WithNov21Pay = [
    pro4F1Payment.nov21,
    pro4F2Payment.nov21,
    pro4F3Payment.nov21,
    pro4F1Payment.nov21 + pro4F2Payment.nov21 + pro4F3Payment.nov21
]
export const pro4WithNov21Count = [
    pro4F1Collectable.nov21,
    pro4F2Collectable.nov21,
    pro4F3Collectable.nov21,
    pro4F1Collectable.nov21 + pro4F2Collectable.nov21 + pro4F3Collectable.nov21
]
export const pro4WithNov21NonCount = [
    pro4F1NonCollectable.nov21,
    pro4F2NonCollectable.nov21,
    pro4F3NonCollectable.nov21,
    pro4F1NonCollectable.nov21 + pro4F2NonCollectable.nov21 + pro4F3NonCollectable.nov21
]
export const pro4WithNov21Claim = [
    pro4F1ClaimCount.nov21,
    pro4F2ClaimCount.nov21,
    pro4F3ClaimCount.nov21,
    pro4F1ClaimCount.nov21 + pro4F2ClaimCount.nov21 + pro4F3ClaimCount.nov21
]
export const pro4WithNov21PerVisit = [
    pro4F1PerVisit.nov21,
    pro4F2PerVisit.nov21,
    pro4F3PerVisit.nov21,
    provider4PerVisist[10]
]
// dec 21
export const pro4WithDec21Charges = [
    pro4F1Charge.dec21,
    pro4F2Charge.dec21,
    pro4F3Charge.dec21,
    pro4F1Charge.dec21 + pro4F2Charge.dec21 + pro4F3Charge.dec21
]
export const pro4WithDec21Pay = [
    pro4F1Payment.dec21,
    pro4F2Payment.dec21,
    pro4F3Payment.dec21,
    pro4F1Payment.dec21 + pro4F2Payment.dec21 + pro4F3Payment.dec21
]
export const pro4WithDec21Count = [
    pro4F1Collectable.dec21,
    pro4F2Collectable.dec21,
    pro4F3Collectable.dec21,
    pro4F1Collectable.dec21 + pro4F2Collectable.dec21 + pro4F3Collectable.dec21
]
export const pro4WithDec21NonCount = [
    pro4F1NonCollectable.dec21,
    pro4F2NonCollectable.dec21,
    pro4F3NonCollectable.dec21,
    pro4F1NonCollectable.dec21 + pro4F2NonCollectable.dec21 + pro4F3NonCollectable.dec21
] 
export const pro4WithDec21Claim = [
    pro4F1ClaimCount.dec21,
    pro4F2ClaimCount.dec21,
    pro4F3ClaimCount.dec21,
    pro4F1ClaimCount.dec21 + pro4F2ClaimCount.dec21 + pro4F3ClaimCount.dec21
]
export const pro4WithDec21PerVisit = [
    pro4F1PerVisit.dec21,
    pro4F2PerVisit.dec21,
    pro4F3PerVisit.dec21,
    provider4PerVisist[11]
]

// provider-1 
// jan 22
export const pro4WithJan22Charges = [
    pro4F1Charge.jan22,
    pro4F2Charge.jan22,
    pro4F3Charge.jan22,
    pro4F1Charge.jan22 + pro4F2Charge.jan22 + pro4F3Charge.jan22
]
export const pro4WithJan22Pay = [
    pro4F1Payment.jan22,
    pro4F2Payment.jan22,
    pro4F3Payment.jan22,
    pro4F1Payment.jan22 + pro4F2Payment.jan22 + pro4F3Payment.jan22
]
export const pro4WithJan22Count = [
    pro4F1Collectable.jan22,
    pro4F2Collectable.jan22,
    pro4F3Collectable.jan22,
    pro4F1Collectable.jan22 + pro4F2Collectable.jan22 + pro4F3Collectable.jan22
]
export const pro4WithJan22NonCount = [
    pro4F1NonCollectable.jan22,
    pro4F2NonCollectable.jan22,
    pro4F3NonCollectable.jan22,
    pro4F1NonCollectable.jan22 + pro4F2NonCollectable.jan22 + pro4F3NonCollectable.jan22
]
export const pro4WithJan22Claim = [
    pro4F1ClaimCount.jan22,
    pro4F2ClaimCount.jan22,
    pro4F3ClaimCount.jan22,
    pro4F1ClaimCount.jan22 + pro4F2ClaimCount.jan22 + pro4F3ClaimCount.jan22
]
export const pro4WithJan22PerVisit = [
    pro4F1PerVisit.jan22,
    pro4F2PerVisit.jan22,
    pro4F3PerVisit.jan22,
    provider4PerVisist[12]
]
// feb 22
export const pro4WithFeb22Charges = [
    pro4F1Charge.feb22,
    pro4F2Charge.feb22,
    pro4F3Charge.feb22,
    pro4F1Charge.feb22 + pro4F2Charge.feb22 + pro4F3Charge.feb22
]
export const pro4WithFeb22Pay = [
    pro4F1Payment.feb22,
    pro4F2Payment.feb22,
    pro4F3Payment.feb22,
    pro4F1Payment.feb22 + pro4F2Payment.feb22 + pro4F3Payment.feb22
]
export const pro4WithFeb22Count = [
    pro4F1Collectable.feb22,
    pro4F2Collectable.feb22,
    pro4F3Collectable.feb22,
    pro4F1Collectable.feb22 + pro4F2Collectable.feb22 + pro4F3Collectable.feb22
]
export const pro4WithFeb22NonCount = [
    pro4F1NonCollectable.feb22,
    pro4F2NonCollectable.feb22,
    pro4F3NonCollectable.feb22,
    pro4F1NonCollectable.feb22 + pro4F2NonCollectable.feb22 + pro4F3NonCollectable.feb22
]
export const pro4WithFeb22Claim = [
    pro4F1ClaimCount.feb22,
    pro4F2ClaimCount.feb22,
    pro4F3ClaimCount.feb22,
    pro4F1ClaimCount.feb22 + pro4F2ClaimCount.feb22 + pro4F3ClaimCount.feb22
]
export const pro4WithFeb22PerVisit = [
    pro4F1PerVisit.feb22,
    pro4F2PerVisit.feb22,
    pro4F3PerVisit.feb22,
    provider4PerVisist[13]
]
// mar 22
export const pro4WithMar22Charges = [
    pro4F1Charge.mar22,
    pro4F2Charge.mar22,
    pro4F3Charge.mar22,
    pro4F1Charge.mar22 + pro4F2Charge.mar22 + pro4F3Charge.mar22
]
export const pro4WithMar22Pay = [
    pro4F1Payment.mar22,
    pro4F2Payment.mar22,
    pro4F3Payment.mar22,
    pro4F1Payment.mar22 + pro4F2Payment.mar22 + pro4F3Payment.mar22
]
export const pro4WithMar22Count = [
    pro4F1Collectable.mar22,
    pro4F2Collectable.mar22,
    pro4F3Collectable.mar22,
    pro4F1Collectable.mar22 + pro4F2Collectable.mar22 + pro4F3Collectable.mar22
]
export const pro4WithMar22NonCount = [
    pro4F1NonCollectable.mar22,
    pro4F2NonCollectable.mar22,
    pro4F3NonCollectable.mar22,
    pro4F1NonCollectable.mar22 + pro4F2NonCollectable.mar22 + pro4F3NonCollectable.mar22
]
export const pro4WithMar22Claim = [
    pro4F1ClaimCount.mar22,
    pro4F2ClaimCount.mar22,
    pro4F3ClaimCount.mar22,
    pro4F1ClaimCount.mar22 + pro4F2ClaimCount.mar22 + pro4F3ClaimCount.mar22
]
export const pro4WithMar22PerVisit = [
    pro4F1PerVisit.mar22,
    pro4F2PerVisit.mar22,
    pro4F3PerVisit.mar22,
    provider4PerVisist[14]
]
// april 22
export const pro4WithApr22Charges = [
    pro4F1Charge.apr22,
    pro4F2Charge.apr22,
    pro4F3Charge.apr22,
    pro4F1Charge.apr22 + pro4F2Charge.apr22 + pro4F3Charge.apr22
]
export const pro4WithApr22Pay = [
    pro4F1Payment.apr22,
    pro4F2Payment.apr22,
    pro4F3Payment.apr22,
    pro4F1Payment.apr22 + pro4F2Payment.apr22 + pro4F3Payment.apr22
]
export const pro4WithApr22Count = [
    pro4F1Collectable.apr22,
    pro4F2Collectable.apr22,
    pro4F3Collectable.apr22,
    pro4F1Collectable.apr22 + pro4F2Collectable.apr22 + pro4F3Collectable.apr22
]
export const pro4WithApr22NonCount = [
    pro4F1NonCollectable.apr22,
    pro4F2NonCollectable.apr22,
    pro4F3NonCollectable.apr22,
    pro4F1NonCollectable.apr22 + pro4F2NonCollectable.apr22 + pro4F3NonCollectable.apr22
]
export const pro4WithApr22Claim = [
    pro4F1ClaimCount.apr22,
    pro4F2ClaimCount.apr22,
    pro4F3ClaimCount.apr22,
    pro4F1ClaimCount.apr22 + pro4F2ClaimCount.apr22 + pro4F3ClaimCount.apr22
]
export const pro4WithApr22PerVisit = [
    pro4F1PerVisit.apr22,
    pro4F2PerVisit.apr22,
    pro4F3PerVisit.apr22,
    provider4PerVisist[15]
]
// may 22
export const pro4WithMay22Charges = [
    pro4F1Charge.may22,
    pro4F2Charge.may22,
    pro4F3Charge.may22,
    pro4F1Charge.may22 + pro4F2Charge.may22 + pro4F3Charge.may22
]
export const pro4WithMay22Pay = [
    pro4F1Payment.may22,
    pro4F2Payment.may22,
    pro4F3Payment.may22,
    pro4F1Payment.may22 + pro4F2Payment.may22 + pro4F3Payment.may22
]
export const pro4WithMay22Count = [
    pro4F1Collectable.may22,
    pro4F2Collectable.may22,
    pro4F3Collectable.may22,
    pro4F1Collectable.may22 + pro4F2Collectable.may22 + pro4F3Collectable.may22
]
export const pro4WithMay22NonCount = [
    pro4F1NonCollectable.may22,
    pro4F2NonCollectable.may22,
    pro4F3NonCollectable.may22,
    pro4F1NonCollectable.may22 + pro4F2NonCollectable.may22 + pro4F3NonCollectable.may22
]
export const pro4WithMay22Claim = [
    pro4F1ClaimCount.may22,
    pro4F2ClaimCount.may22,
    pro4F3ClaimCount.may22,
    pro4F1ClaimCount.may22 + pro4F2ClaimCount.may22 + pro4F3ClaimCount.may22
]
export const pro4WithMay22PerVisit = [
    pro4F1PerVisit.may22,
    pro4F2PerVisit.may22,
    pro4F3PerVisit.may22,
    provider4PerVisist[16]
]
// jun 22
export const pro4WithJun22Charges = [
    pro4F1Charge.jun22,
    pro4F2Charge.jun22,
    pro4F3Charge.jun22,
    pro4F1Charge.jun22 + pro4F2Charge.jun22 + pro4F3Charge.jun22
]
export const pro4WithJun22Pay = [
    pro4F1Payment.jun22,
    pro4F2Payment.jun22,
    pro4F3Payment.jun22,
    pro4F1Payment.jun22 + pro4F2Payment.jun22 + pro4F3Payment.jun22
]
export const pro4WithJun22Count = [
    pro4F1Collectable.jun22,
    pro4F2Collectable.jun22,
    pro4F3Collectable.jun22,
    pro4F1Collectable.jun22 + pro4F2Collectable.jun22 + pro4F3Collectable.jun22
]
export const pro4WithJun22NonCount = [
    pro4F1NonCollectable.jun22,
    pro4F2NonCollectable.jun22,
    pro4F3NonCollectable.jun22,
    pro4F1NonCollectable.jun22 + pro4F2NonCollectable.jun22 + pro4F3NonCollectable.jun22
]
export const pro4WithJun22Claim = [
    pro4F1ClaimCount.jun22,
    pro4F2ClaimCount.jun22,
    pro4F3ClaimCount.jun22,
    pro4F1ClaimCount.jun22 + pro4F2ClaimCount.jun22 + pro4F3ClaimCount.jun22
]
export const pro4WithJun22PerVisit = [
    pro4F1PerVisit.jun22,
    pro4F2PerVisit.jun22,
    pro4F3PerVisit.jun22,
    provider4PerVisist[17]
]
// jul 22
export const pro4WithJul22Charges = [
    pro4F1Charge.jul22,
    pro4F2Charge.jul22,
    pro4F3Charge.jul22,
    pro4F1Charge.jul22 + pro4F2Charge.jul22 + pro4F3Charge.jul22
]
export const pro4WithJul22Pay = [
    pro4F1Payment.jul22,
    pro4F2Payment.jul22,
    pro4F3Payment.jul22,
    pro4F1Payment.jul22 + pro4F2Payment.jul22 + pro4F3Payment.jul22
]
export const pro4WithJul22Count = [
    pro4F1Collectable.jul22,
    pro4F2Collectable.jul22,
    pro4F3Collectable.jul22,
    pro4F1Collectable.jul22 + pro4F2Collectable.jul22 + pro4F3Collectable.jul22
]
export const pro4WithJul22NonCount = [
    pro4F1NonCollectable.jul22,
    pro4F2NonCollectable.jul22,
    pro4F3NonCollectable.jul22,
    pro4F1NonCollectable.jul22 + pro4F2NonCollectable.jul22 + pro4F3NonCollectable.jul22
]
export const pro4WithJul22Claim = [
    pro4F1ClaimCount.jul22,
    pro4F2ClaimCount.jul22,
    pro4F3ClaimCount.jul22,
    pro4F1ClaimCount.jul22 + pro4F2ClaimCount.jul22 + pro4F3ClaimCount.jul22
]
export const pro4WithJul22PerVisit = [
    pro4F1PerVisit.jul22,
    pro4F2PerVisit.jul22,
    pro4F3PerVisit.jul22,
    provider4PerVisist[18]
]
// aug 22
export const pro4WithAug22Charges = [
    pro4F1Charge.aug22,
    pro4F2Charge.aug22,
    pro4F3Charge.aug22,
    pro4F1Charge.aug22 + pro4F2Charge.aug22 + pro4F3Charge.aug22
]
export const pro4WithAug22Pay = [
    pro4F1Payment.aug22,
    pro4F2Payment.aug22,
    pro4F3Payment.aug22,
    pro4F1Payment.aug22 + pro4F2Payment.aug22 + pro4F3Payment.aug22
]
export const pro4WithAug22Count = [
    pro4F1Collectable.aug22,
    pro4F2Collectable.aug22,
    pro4F3Collectable.aug22,
    pro4F1Collectable.aug22 + pro4F2Collectable.aug22 + pro4F3Collectable.aug22
]
export const pro4WithAug22NonCount = [
    pro4F1NonCollectable.aug22,
    pro4F2NonCollectable.aug22,
    pro4F3NonCollectable.aug22,
    pro4F1NonCollectable.aug22 + pro4F2NonCollectable.aug22 + pro4F3NonCollectable.aug22
]
export const pro4WithAug22Claim = [
    pro4F1ClaimCount.aug22,
    pro4F2ClaimCount.aug22,
    pro4F3ClaimCount.aug22,
    pro4F1ClaimCount.aug22 + pro4F2ClaimCount.aug22 + pro4F3ClaimCount.aug22
]
export const pro4WithAug22PerVisit = [
    pro4F1PerVisit.aug22,
    pro4F2PerVisit.aug22,
    pro4F3PerVisit.aug22,
    provider4PerVisist[19]
]
// sep 22
export const pro4WithSep22Charges = [
    pro4F1Charge.sep22,
    pro4F2Charge.sep22,
    pro4F3Charge.sep22,
    pro4F1Charge.sep22 + pro4F2Charge.sep22 + pro4F3Charge.sep22
]
export const pro4WithSep22Pay = [
    pro4F1Payment.sep22,
    pro4F2Payment.sep22,
    pro4F3Payment.sep22,
    pro4F1Payment.sep22 + pro4F2Payment.sep22 + pro4F3Payment.sep22
]
export const pro4WithSep22Count = [
    pro4F1Collectable.sep22,
    pro4F2Collectable.sep22,
    pro4F3Collectable.sep22,
    pro4F1Collectable.sep22 + pro4F2Collectable.sep22 + pro4F3Collectable.sep22
]
export const pro4WithSep22NonCount = [
    pro4F1NonCollectable.sep22,
    pro4F2NonCollectable.sep22,
    pro4F3NonCollectable.sep22,
    pro4F1NonCollectable.sep22 + pro4F2NonCollectable.sep22 + pro4F3NonCollectable.sep22
]
export const pro4WithSep22Claim = [
    pro4F1ClaimCount.sep22,
    pro4F2ClaimCount.sep22,
    pro4F3ClaimCount.sep22,
    pro4F1ClaimCount.sep22 + pro4F2ClaimCount.sep22 + pro4F3ClaimCount.sep22
]
export const pro4WithSep22PerVisit = [
    pro4F1PerVisit.sep22,
    pro4F2PerVisit.sep22,
    pro4F3PerVisit.sep22,
    provider4PerVisist[20]
]
// oct 22
export const pro4WithOct22Charges = [
    pro4F1Charge.oct22,
    pro4F2Charge.oct22,
    pro4F3Charge.oct22,
    pro4F1Charge.oct22 + pro4F2Charge.oct22 + pro4F3Charge.oct22
]
export const pro4WithOct22Pay = [
    pro4F1Payment.oct22,
    pro4F2Payment.oct22,
    pro4F3Payment.oct22,
    pro4F1Payment.oct22 + pro4F2Payment.oct22 + pro4F3Payment.oct22
]
export const pro4WithOct22Count = [
    pro4F1Collectable.oct22,
    pro4F2Collectable.oct22,
    pro4F3Collectable.oct22,
    pro4F1Collectable.oct22 + pro4F2Collectable.oct22 + pro4F3Collectable.oct22
]
export const pro4WithOct22NonCount = [
    pro4F1NonCollectable.oct22,
    pro4F2NonCollectable.oct22,
    pro4F3NonCollectable.oct22,
    pro4F1NonCollectable.oct22 + pro4F2NonCollectable.oct22 + pro4F3NonCollectable.oct22
]
export const pro4WithOct22Claim = [
    pro4F1ClaimCount.oct22,
    pro4F2ClaimCount.oct22,
    pro4F3ClaimCount.oct22,
    pro4F1ClaimCount.oct22 + pro4F2ClaimCount.oct22 + pro4F3ClaimCount.oct22
]
export const pro4WithOct22PerVisit = [
    pro4F1PerVisit.oct22,
    pro4F2PerVisit.oct22,
    pro4F3PerVisit.oct22,
    provider4PerVisist[21]
]
// nov 22
export const pro4WithNov22Charges = [
    pro4F1Charge.nov22,
    pro4F2Charge.nov22,
    pro4F3Charge.nov22,
    pro4F1Charge.nov22 + pro4F2Charge.nov22 + pro4F3Charge.nov22
]
export const pro4WithNov22Pay = [
    pro4F1Payment.nov22,
    pro4F2Payment.nov22,
    pro4F3Payment.nov22,
    pro4F1Payment.nov22 + pro4F2Payment.nov22 + pro4F3Payment.nov22
]
export const pro4WithNov22Count = [
    pro4F1Collectable.nov22,
    pro4F2Collectable.nov22,
    pro4F3Collectable.nov22,
    pro4F1Collectable.nov22 + pro4F2Collectable.nov22 + pro4F3Collectable.nov22
]
export const pro4WithNov22NonCount = [
    pro4F1NonCollectable.nov22,
    pro4F2NonCollectable.nov22,
    pro4F3NonCollectable.nov22,
    pro4F1NonCollectable.nov22 + pro4F2NonCollectable.nov22 + pro4F3NonCollectable.nov22
]
export const pro4WithNov22Claim = [
    pro4F1ClaimCount.nov22,
    pro4F2ClaimCount.nov22,
    pro4F3ClaimCount.nov22,
    pro4F1ClaimCount.nov22 + pro4F2ClaimCount.nov22 + pro4F3ClaimCount.nov22
]
export const pro4WithNov22PerVisit = [
    pro4F1PerVisit.nov22,
    pro4F2PerVisit.nov22,
    pro4F3PerVisit.nov22,
    provider4PerVisist[22]
]
// dec 22
export const pro4WithDec22Charges = [
    pro4F1Charge.dec22,
    pro4F2Charge.dec22,
    pro4F3Charge.dec22,
    pro4F1Charge.dec22 + pro4F2Charge.dec22 + pro4F3Charge.dec22
]
export const pro4WithDec22Pay = [
    pro4F1Payment.dec22,
    pro4F2Payment.dec22,
    pro4F3Payment.dec22,
    pro4F1Payment.dec22 + pro4F2Payment.dec22 + pro4F3Payment.dec22
]
export const pro4WithDec22Count = [
    pro4F1Collectable.dec22,
    pro4F2Collectable.dec22,
    pro4F3Collectable.dec22,
    pro4F1Collectable.dec22 + pro4F2Collectable.dec22 + pro4F3Collectable.dec22
]
export const pro4WithDec22NonCount = [
    pro4F1NonCollectable.dec22,
    pro4F2NonCollectable.dec22,
    pro4F3NonCollectable.dec22,
    pro4F1NonCollectable.dec22 + pro4F2NonCollectable.dec22 + pro4F3NonCollectable.dec22
]
export const pro4WithDec22Claim = [
    pro4F1ClaimCount.dec22,
    pro4F2ClaimCount.dec22,
    pro4F3ClaimCount.dec22,
    pro4F1ClaimCount.dec22 + pro4F2ClaimCount.dec22 + pro4F3ClaimCount.dec22
]
export const pro4WithDec22PerVisit = [
    pro4F1PerVisit.dec22,
    pro4F2PerVisit.dec22,
    pro4F3PerVisit.dec22,
    provider4PerVisist[23]
]

// provider-1 
// jan 23
export const pro4WithJan23Charges = [
    pro4F1Charge.jan23,
    pro4F2Charge.jan23,
    pro4F3Charge.jan23,
    pro4F1Charge.jan23 + pro4F2Charge.jan23 + pro4F3Charge.jan23
]
export const pro4WithJan23Pay = [
    pro4F1Payment.jan23,
    pro4F2Payment.jan23,
    pro4F3Payment.jan23,
    pro4F1Payment.jan23 + pro4F2Payment.jan23 + pro4F3Payment.jan23
]
export const pro4WithJan23Count = [
    pro4F1Collectable.jan23,
    pro4F2Collectable.jan23,
    pro4F3Collectable.jan23,
    pro4F1Collectable.jan23 + pro4F2Collectable.jan23 + pro4F3Collectable.jan23
]
export const pro4WithJan23NonCount = [
    pro4F1NonCollectable.jan23,
    pro4F2NonCollectable.jan23,
    pro4F3NonCollectable.jan23,
    pro4F1NonCollectable.jan23 + pro4F2NonCollectable.jan23 + pro4F3NonCollectable.jan23
]
export const pro4WithJan23Claim = [
    pro4F1ClaimCount.jan23,
    pro4F2ClaimCount.jan23,
    pro4F3ClaimCount.jan23,
    pro4F1ClaimCount.jan23 + pro4F2ClaimCount.jan23 + pro4F3ClaimCount.jan23
]
export const pro4WithJan23PerVisit = [
    pro4F1PerVisit.jan23,
    pro4F2PerVisit.jan23,
    pro4F3PerVisit.jan23,
    provider4PerVisist[24]
]
// feb 23
export const pro4WithFeb23Charges = [
    pro4F1Charge.feb23,
    pro4F2Charge.feb23,
    pro4F3Charge.feb23,
    pro4F1Charge.feb23 + pro4F2Charge.feb23 + pro4F3Charge.feb23
]
export const pro4WithFeb23Pay = [
    pro4F1Payment.feb23,
    pro4F2Payment.feb23,
    pro4F3Payment.feb23,
    pro4F1Payment.feb23 + pro4F2Payment.feb23 + pro4F3Payment.feb23
]
export const pro4WithFeb23Count = [
    pro4F1Collectable.feb23,
    pro4F2Collectable.feb23,
    pro4F3Collectable.feb23,
    pro4F1Collectable.feb23 + pro4F2Collectable.feb23 + pro4F3Collectable.feb23
]
export const pro4WithFeb23NonCount = [
    pro4F1NonCollectable.feb23,
    pro4F2NonCollectable.feb23,
    pro4F3NonCollectable.feb23,
    pro4F1NonCollectable.feb23 + pro4F2NonCollectable.feb23 + pro4F3NonCollectable.feb23
]
export const pro4WithFeb23Claim = [
    pro4F1ClaimCount.feb23,
    pro4F2ClaimCount.feb23,
    pro4F3ClaimCount.feb23,
    pro4F1ClaimCount.feb23 + pro4F2ClaimCount.feb23 + pro4F3ClaimCount.feb23
]
export const pro4WithFeb23PerVisit = [
    pro4F1PerVisit.feb23,
    pro4F2PerVisit.feb23,
    pro4F3PerVisit.feb23,
    provider4PerVisist[25]
]
// mar 23
export const pro4WithMar23Charges = [
    pro4F1Charge.mar23,
    pro4F2Charge.mar23,
    pro4F3Charge.mar23,
    pro4F1Charge.mar23 + pro4F2Charge.mar23 + pro4F3Charge.mar23
]
export const pro4WithMar23Pay = [
    pro4F1Payment.mar23,
    pro4F2Payment.mar23,
    pro4F3Payment.mar23,
    pro4F1Payment.mar23 + pro4F2Payment.mar23 + pro4F3Payment.mar23
]
export const pro4WithMar23Count = [
    pro4F1Collectable.mar23,
    pro4F2Collectable.mar23,
    pro4F3Collectable.mar23,
    pro4F1Collectable.mar23 + pro4F2Collectable.mar23 + pro4F3Collectable.mar23
]
export const pro4WithMar23NonCount = [
    pro4F1NonCollectable.mar23,
    pro4F2NonCollectable.mar23,
    pro4F3NonCollectable.mar23,
    pro4F1NonCollectable.mar23 + pro4F2NonCollectable.mar23 + pro4F3NonCollectable.mar23
]
export const pro4WithMar23Claim = [
    pro4F1ClaimCount.mar23,
    pro4F2ClaimCount.mar23,
    pro4F3ClaimCount.mar23,
    pro4F1ClaimCount.mar23 + pro4F2ClaimCount.mar23 + pro4F3ClaimCount.mar23
]
export const pro4WithMar23PerVisit = [
    pro4F1PerVisit.mar23,
    pro4F2PerVisit.mar23,
    pro4F3PerVisit.mar23,
    provider4PerVisist[26]
]
// april 23
export const pro4WithApr23Charges = [
    pro4F1Charge.apr23,
    pro4F2Charge.apr23,
    pro4F3Charge.apr23,
    pro4F1Charge.apr23 + pro4F2Charge.apr23 + pro4F3Charge.apr23
]
export const pro4WithApr23Pay = [
    pro4F1Payment.apr23,
    pro4F2Payment.apr23,
    pro4F3Payment.apr23,
    pro4F1Payment.apr23 + pro4F2Payment.apr23 + pro4F3Payment.apr23
]
export const pro4WithApr23Count = [
    pro4F1Collectable.apr23,
    pro4F2Collectable.apr23,
    pro4F3Collectable.apr23,
    pro4F1Collectable.apr23 + pro4F2Collectable.apr23 + pro4F3Collectable.apr23
]
export const pro4WithApr23NonCount = [
    pro4F1NonCollectable.apr23,
    pro4F2NonCollectable.apr23,
    pro4F3NonCollectable.apr23,
    pro4F1NonCollectable.apr23 + pro4F2NonCollectable.apr23 + pro4F3NonCollectable.apr23
]
export const pro4WithApr23Claim = [
    pro4F1ClaimCount.apr23,
    pro4F2ClaimCount.apr23,
    pro4F3ClaimCount.apr23,
    pro4F1ClaimCount.apr23 + pro4F2ClaimCount.apr23 + pro4F3ClaimCount.apr23
]
export const pro4WithApr23PerVisit = [
    pro4F1PerVisit.apr23,
    pro4F2PerVisit.apr23,
    pro4F3PerVisit.apr23,
    provider4PerVisist[27]
]
// may 23
export const pro4WithMay23Charges = [
    pro4F1Charge.may23,
    pro4F2Charge.may23,
    pro4F3Charge.may23,
    pro4F1Charge.may23 + pro4F2Charge.may23 + pro4F3Charge.may23
]
export const pro4WithMay23Pay = [
    pro4F1Payment.may23,
    pro4F2Payment.may23,
    pro4F3Payment.may23,
    pro4F1Payment.may23 + pro4F2Payment.may23 + pro4F3Payment.may23
]
export const pro4WithMay23Count = [
    pro4F1Collectable.may23,
    pro4F2Collectable.may23,
    pro4F3Collectable.may23,
    pro4F1Collectable.may23 + pro4F2Collectable.may23 + pro4F3Collectable.may23
]
export const pro4WithMay23NonCount = [
    pro4F1NonCollectable.may23,
    pro4F2NonCollectable.may23,
    pro4F3NonCollectable.may23,
    pro4F1NonCollectable.may23 + pro4F2NonCollectable.may23 + pro4F3NonCollectable.may23
]
export const pro4WithMay23Claim = [
    pro4F1ClaimCount.may23,
    pro4F2ClaimCount.may23,
    pro4F3ClaimCount.may23,
    pro4F1ClaimCount.may23 + pro4F2ClaimCount.may23 + pro4F3ClaimCount.may23
]
export const pro4WithMay23PerVisit = [
    pro4F1PerVisit.may23,
    pro4F2PerVisit.may23,
    pro4F3PerVisit.may23,
    provider4PerVisist[28]
]
// jun 23
export const pro4WithJun23Charges = [
    pro4F1Charge.jun23,
    pro4F2Charge.jun23,
    pro4F3Charge.jun23,
    pro4F1Charge.jun23 + pro4F2Charge.jun23 + pro4F3Charge.jun23
]
export const pro4WithJun23Pay = [
    pro4F1Payment.jun23,
    pro4F2Payment.jun23,
    pro4F3Payment.jun23,
    pro4F1Payment.jun23 + pro4F2Payment.jun23 + pro4F3Payment.jun23
]
export const pro4WithJun23Count = [
    pro4F1Collectable.jun23,
    pro4F2Collectable.jun23,
    pro4F3Collectable.jun23,
    pro4F1Collectable.jun23 + pro4F2Collectable.jun23 + pro4F3Collectable.jun23
]
export const pro4WithJun23NonCount = [
    pro4F1NonCollectable.jun23,
    pro4F2NonCollectable.jun23,
    pro4F3NonCollectable.jun23,
    pro4F1NonCollectable.jun23 + pro4F2NonCollectable.jun23 + pro4F3NonCollectable.jun23
]
export const pro4WithJun23Claim = [
    pro4F1ClaimCount.jun23,
    pro4F2ClaimCount.jun23,
    pro4F3ClaimCount.jun23,
    pro4F1ClaimCount.jun23 + pro4F2ClaimCount.jun23 + pro4F3ClaimCount.jun23
]
export const pro4WithJun23PerVisit = [
    pro4F1PerVisit.jun23,
    pro4F2PerVisit.jun23,
    pro4F3PerVisit.jun23,
    provider4PerVisist[29]
]
// jul 23
export const pro4WithJul23Charges = [
    pro4F1Charge.jul23,
    pro4F2Charge.jul23,
    pro4F3Charge.jul23,
    pro4F1Charge.jul23 + pro4F2Charge.jul23 + pro4F3Charge.jul23
]
export const pro4WithJul23Pay = [
    pro4F1Payment.jul23,
    pro4F2Payment.jul23,
    pro4F3Payment.jul23,
    pro4F1Payment.jul23 + pro4F2Payment.jul23 + pro4F3Payment.jul23
]
export const pro4WithJul23Count = [
    pro4F1Collectable.jul23,
    pro4F2Collectable.jul23,
    pro4F3Collectable.jul23,
    pro4F1Collectable.jul23 + pro4F2Collectable.jul23 + pro4F3Collectable.jul23
]
export const pro4WithJul23NonCount = [
    pro4F1NonCollectable.jul23,
    pro4F2NonCollectable.jul23,
    pro4F3NonCollectable.jul23,
    pro4F1NonCollectable.jul23 + pro4F2NonCollectable.jul23 + pro4F3NonCollectable.jul23
]
export const pro4WithJul23Claim = [
    pro4F1ClaimCount.jul23,
    pro4F2ClaimCount.jul23,
    pro4F3ClaimCount.jul23,
    pro4F1ClaimCount.jul23 + pro4F2ClaimCount.jul23 + pro4F3ClaimCount.jul23
]
export const pro4WithJul23PerVisit = [
    pro4F1PerVisit.jul23,
    pro4F2PerVisit.jul23,
    pro4F3PerVisit.jul23,
    provider4PerVisist[30]
]
// aug 23
export const pro4WithAug23Charges = [
    pro4F1Charge.aug23,
    pro4F2Charge.aug23,
    pro4F3Charge.aug23,
    pro4F1Charge.aug23 + pro4F2Charge.aug23 + pro4F3Charge.aug23
]
export const pro4WithAug23Pay = [
    pro4F1Payment.aug23,
    pro4F2Payment.aug23,
    pro4F3Payment.aug23,
    pro4F1Payment.aug23 + pro4F2Payment.aug23 + pro4F3Payment.aug23
]
export const pro4WithAug23Count = [
    pro4F1Collectable.aug23,
    pro4F2Collectable.aug23,
    pro4F3Collectable.aug23,
    pro4F1Collectable.aug23 + pro4F2Collectable.aug23 + pro4F3Collectable.aug23
]
export const pro4WithAug23NonCount = [
    pro4F1NonCollectable.aug23,
    pro4F2NonCollectable.aug23,
    pro4F3NonCollectable.aug23,
    pro4F1NonCollectable.aug23 + pro4F2NonCollectable.aug23 + pro4F3NonCollectable.aug23
]
export const pro4WithAug23Claim = [
    pro4F1ClaimCount.aug23,
    pro4F2ClaimCount.aug23,
    pro4F3ClaimCount.aug23,
    pro4F1ClaimCount.aug23 + pro4F2ClaimCount.aug23 + pro4F3ClaimCount.aug23
]
export const pro4WithAug23PerVisit = [
    pro4F1PerVisit.aug23,
    pro4F2PerVisit.aug23,
    pro4F3PerVisit.aug23,
    provider4PerVisist[31]
]
// sep 23
export const pro4WithSep23Charges = [
    pro4F1Charge.sep23,
    pro4F2Charge.sep23,
    pro4F3Charge.sep23,
    pro4F1Charge.sep23 + pro4F2Charge.sep23 + pro4F3Charge.sep23
]
export const pro4WithSep23Pay = [
    pro4F1Payment.sep23,
    pro4F2Payment.sep23,
    pro4F3Payment.sep23,
    pro4F1Payment.sep23 + pro4F2Payment.sep23 + pro4F3Payment.sep23
]
export const pro4WithSep23Count = [
    pro4F1Collectable.sep23,
    pro4F2Collectable.sep23,
    pro4F3Collectable.sep23,
    pro4F1Collectable.sep23 + pro4F2Collectable.sep23 + pro4F3Collectable.sep23
]
export const pro4WithSep23NonCount = [
    pro4F1NonCollectable.sep23,
    pro4F2NonCollectable.sep23,
    pro4F3NonCollectable.sep23,
    pro4F1NonCollectable.sep23 + pro4F2NonCollectable.sep23 + pro4F3NonCollectable.sep23
]
export const pro4WithSep23Claim = [
    pro4F1ClaimCount.sep23,
    pro4F2ClaimCount.sep23,
    pro4F3ClaimCount.sep23,
    pro4F1ClaimCount.sep23 + pro4F2ClaimCount.sep23 + pro4F3ClaimCount.sep23
]
export const pro4WithSep23PerVisit = [
    pro4F1PerVisit.sep23,
    pro4F2PerVisit.sep23,
    pro4F3PerVisit.sep23,
    provider4PerVisist[32]
]
// oct 23
export const pro4WithOct23Charges = [
    pro4F1Charge.oct23,
    pro4F2Charge.oct23,
    pro4F3Charge.oct23,
    pro4F1Charge.oct23 + pro4F2Charge.oct23 + pro4F3Charge.oct23
]
export const pro4WithOct23Pay = [
    pro4F1Payment.oct23,
    pro4F2Payment.oct23,
    pro4F3Payment.oct23,
    pro4F1Payment.oct23 + pro4F2Payment.oct23 + pro4F3Payment.oct23
]
export const pro4WithOct23Count = [
    pro4F1Collectable.oct23,
    pro4F2Collectable.oct23,
    pro4F3Collectable.oct23,
    pro4F1Collectable.oct23 + pro4F2Collectable.oct23 + pro4F3Collectable.oct23
]
export const pro4WithOct23NonCount = [
    pro4F1NonCollectable.oct23,
    pro4F2NonCollectable.oct23,
    pro4F3NonCollectable.oct23,
    pro4F1NonCollectable.oct23 + pro4F2NonCollectable.oct23 + pro4F3NonCollectable.oct23
]
export const pro4WithOct23Claim = [
    pro4F1ClaimCount.oct23,
    pro4F2ClaimCount.oct23,
    pro4F3ClaimCount.oct23,
    pro4F1ClaimCount.oct23 + pro4F2ClaimCount.oct23 + pro4F3ClaimCount.oct23
]
export const pro4WithOct23PerVisit = [
    pro4F1PerVisit.oct23,
    pro4F2PerVisit.oct23,
    pro4F3PerVisit.oct23,
    provider4PerVisist[33]
]
// nov 23
export const pro4WithNov23Charges = [
    pro4F1Charge.nov23,
    pro4F2Charge.nov23,
    pro4F3Charge.nov23,
    pro4F1Charge.nov23 + pro4F2Charge.nov23 + pro4F3Charge.nov23
]
export const pro4WithNov23Pay = [
    pro4F1Payment.nov23,
    pro4F2Payment.nov23,
    pro4F3Payment.nov23,
    pro4F1Payment.nov23 +
    pro4F2Payment.nov23 + 
    pro4F3Payment.nov23
]
export const pro4WithNov23Count = [
    pro4F1Collectable.nov23,
    pro4F2Collectable.nov23,
    pro4F3Collectable.nov23,
    pro4F1Collectable.nov23 +
    pro4F2Collectable.nov23 +
    pro4F3Collectable.nov23
]
export const pro4WithNov23NonCount = [
    pro4F1NonCollectable.nov23,
    pro4F2NonCollectable.nov23,
    pro4F3NonCollectable.nov23,
    pro4F1NonCollectable.nov23+
    pro4F2NonCollectable.nov23+
    pro4F3NonCollectable.nov23
]
export const pro4WithNov23Claim = [
    pro4F1ClaimCount.nov23,
    pro4F2ClaimCount.nov23,
    pro4F3ClaimCount.nov23,
    pro4F1ClaimCount.nov23 + pro4F2ClaimCount.nov23 + pro4F3ClaimCount.nov23
]
export const pro4WithNov23PerVisit = [
    pro4F1PerVisit.nov23,
    pro4F2PerVisit.nov23,
    pro4F3PerVisit.nov23,
    provider4PerVisist[34]
]
// comm
    // export const pro4WithJan21Charges = [
    //     pro4F1Charge.jan21,
    //     pro4F2Charge.jan21,
    //     pro4F3Charge.jan21
    // ]
    // export const pro4WithJan21Pay = [
    //     pro4F1Payment.jan21,
    //     pro4F2Payment.jan21,
    //     pro4F3Payment.jan21
    // ]
    // export const pro4WithJan21Count = [
    //     pro4F1Collectable.jan21,
    //     pro4F2Collectable.jan21,
    //     pro4F3Collectable.jan21
    // ]
    // export const pro4WithJan21NonCount = [
    //     pro4F1NonCollectable.jan21,
    //     pro4F2NonCollectable.jan21,
    //     pro4F3NonCollectable.jan21
    // ]
    // export const pro4WithJan21Claim = [
    //     pro4F1ClaimCount.jan21,
    //     pro4F2ClaimCount.jan21,
    //     pro4F3ClaimCount.jan21
    // ]
    // export const pro4WithJan21PerVisit = [
    //     pro4F1PerVisit.jan21,
    //     pro4F2PerVisit.jan21,
    //     pro4F3PerVisit.jan21
    // ]
    // // feb 21
    // export const pro4WithFeb21Charges = [
    //     pro4F1Charge.feb21,
    //     pro4F2Charge.feb21,
    //     pro4F3Charge.feb21
    // ]
    // export const pro4WithFeb21Pay = [
    //     pro4F1Payment.feb21,
    //     pro4F2Payment.feb21,
    //     pro4F3Payment.feb21
    // ]
    // export const pro4WithFeb21Count = [
    //     pro4F1Collectable.feb21,
    //     pro4F2Collectable.feb21,
    //     pro4F3Collectable.feb21
    // ]
    // export const pro4WithFeb21NonCount = [
    //     pro4F1NonCollectable.feb21,
    //     pro4F2NonCollectable.feb21,
    //     pro4F3NonCollectable.feb21
    // ]
    // export const pro4WithFeb21Claim = [
    //     pro4F1ClaimCount.feb21,
    //     pro4F2ClaimCount.feb21,
    //     pro4F3ClaimCount.feb21
    // ]
    // export const pro4WithFeb21PerVisit = [
    //     pro4F1PerVisit.feb21,
    //     pro4F2PerVisit.feb21,
    //     pro4F3PerVisit.feb21
    // ]
    // // mar 21
    // export const pro4WithMar21Charges = [
    //     pro4F1Charge.mar21,
    //     pro4F2Charge.mar21,
    //     pro4F3Charge.mar21
    // ]
    // export const pro4WithMar21Pay = [
    //     pro4F1Payment.mar21,
    //     pro4F2Payment.mar21,
    //     pro4F3Payment.mar21
    // ]
    // export const pro4WithMar21Count = [
    //     pro4F1Collectable.mar21,
    //     pro4F2Collectable.mar21,
    //     pro4F3Collectable.mar21
    // ]
    // export const pro4WithMar21NonCount = [
    //     pro4F1NonCollectable.mar21,
    //     pro4F2NonCollectable.mar21,
    //     pro4F3NonCollectable.mar21
    // ]
    // export const pro4WithMar21Claim = [
    //     pro4F1ClaimCount.mar21,
    //     pro4F2ClaimCount.mar21,
    //     pro4F3ClaimCount.mar21
    // ]
    // export const pro4WithMar21PerVisit = [
    //     pro4F1PerVisit.mar21,
    //     pro4F2PerVisit.mar21,
    //     pro4F3PerVisit.mar21
    // ]
    // // april 21
    // export const pro4WithApr21Charges = [
    //     pro4F1Charge.apr21,
    //     pro4F2Charge.apr21,
    //     pro4F3Charge.apr21
    // ]
    // export const pro4WithApr21Pay = [
    //     pro4F1Payment.apr21,
    //     pro4F2Payment.apr21,
    //     pro4F3Payment.apr21
    // ]
    // export const pro4WithApr21Count = [
    //     pro4F1Collectable.apr21,
    //     pro4F2Collectable.apr21,
    //     pro4F3Collectable.apr21
    // ]
    // export const pro4WithApr21NonCount = [
    //     pro4F1NonCollectable.apr21,
    //     pro4F2NonCollectable.apr21,
    //     pro4F3NonCollectable.apr21
    // ]
    // export const pro4WithApr21Claim = [
    //     pro4F1ClaimCount.apr21,
    //     pro4F2ClaimCount.apr21,
    //     pro4F3ClaimCount.apr21
    // ]
    // export const pro4WithApr21PerVisit = [
    //     pro4F1PerVisit.apr21,
    //     pro4F2PerVisit.apr21,
    //     pro4F3PerVisit.apr21
    // ]
    // // may 21
    // export const pro4WithMay21Charges = [
    //     pro4F1Charge.may21,
    //     pro4F2Charge.may21,
    //     pro4F3Charge.may21
    // ]
    // export const pro4WithMay21Pay = [
    //     pro4F1Payment.may21,
    //     pro4F2Payment.may21,
    //     pro4F3Payment.may21
    // ]
    // export const pro4WithMay21Count = [
    //     pro4F1Collectable.may21,
    //     pro4F2Collectable.may21,
    //     pro4F3Collectable.may21
    // ]
    // export const pro4WithMay21NonCount = [
    //     pro4F1NonCollectable.may21,
    //     pro4F2NonCollectable.may21,
    //     pro4F3NonCollectable.may21
    // ]
    // export const pro4WithMay21Claim = [
    //     pro4F1ClaimCount.may21,
    //     pro4F2ClaimCount.may21,
    //     pro4F3ClaimCount.may21
    // ]
    // export const pro4WithMay21PerVisit = [
    //     pro4F1PerVisit.may21,
    //     pro4F2PerVisit.may21,
    //     pro4F3PerVisit.may21
    // ]
    // // jun 21
    // export const pro4WithJun21Charges = [
    //     pro4F1Charge.jun21,
    //     pro4F2Charge.jun21,
    //     pro4F3Charge.jun21
    // ]
    // export const pro4WithJun21Pay = [
    //     pro4F1Payment.jun21,
    //     pro4F2Payment.jun21,
    //     pro4F3Payment.jun21
    // ]
    // export const pro4WithJun21Count = [
    //     pro4F1Collectable.jun21,
    //     pro4F2Collectable.jun21,
    //     pro4F3Collectable.jun21
    // ]
    // export const pro4WithJun21NonCount = [
    //     pro4F1NonCollectable.jun21,
    //     pro4F2NonCollectable.jun21,
    //     pro4F3NonCollectable.jun21
    // ]
    // export const pro4WithJun21Claim = [
    //     pro4F1ClaimCount.jun21,
    //     pro4F2ClaimCount.jun21,
    //     pro4F3ClaimCount.jun21
    // ]
    // export const pro4WithJun21PerVisit = [
    //     pro4F1PerVisit.jun21,
    //     pro4F2PerVisit.jun21,
    //     pro4F3PerVisit.jun21
    // ]
    // // jul 21
    // export const pro4WithJul21Charges = [
    //     pro4F1Charge.jul21,
    //     pro4F2Charge.jul21,
    //     pro4F3Charge.jul21
    // ]
    // export const pro4WithJul21Pay = [
    //     pro4F1Payment.jul21,
    //     pro4F2Payment.jul21,
    //     pro4F3Payment.jul21
    // ]
    // export const pro4WithJul21Count = [
    //     pro4F1Collectable.jul21,
    //     pro4F2Collectable.jul21,
    //     pro4F3Collectable.jul21
    // ]
    // export const pro4WithJul21NonCount = [
    //     pro4F1NonCollectable.jul21,
    //     pro4F2NonCollectable.jul21,
    //     pro4F3NonCollectable.jul21
    // ]
    // export const pro4WithJul21Claim = [
    //     pro4F1ClaimCount.jul21,
    //     pro4F2ClaimCount.jul21,
    //     pro4F3ClaimCount.jul21
    // ]
    // export const pro4WithJul21PerVisit = [
    //     pro4F1PerVisit.jul21,
    //     pro4F2PerVisit.jul21,
    //     pro4F3PerVisit.jul21
    // ]
    // // aug 21
    // export const pro4WithAug21Charges = [
    //     pro4F1Charge.aug21,
    //     pro4F2Charge.aug21,
    //     pro4F3Charge.aug21
    // ]
    // export const pro4WithAug21Pay = [
    //     pro4F1Payment.aug21,
    //     pro4F2Payment.aug21,
    //     pro4F3Payment.aug21
    // ]
    // export const pro4WithAug21Count = [
    //     pro4F1Collectable.aug21,
    //     pro4F2Collectable.aug21,
    //     pro4F3Collectable.aug21
    // ]
    // export const pro4WithAug21NonCount = [
    //     pro4F1NonCollectable.aug21,
    //     pro4F2NonCollectable.aug21,
    //     pro4F3NonCollectable.aug21
    // ]
    // export const pro4WithAug21Claim = [
    //     pro4F1ClaimCount.aug21,
    //     pro4F2ClaimCount.aug21,
    //     pro4F3ClaimCount.aug21
    // ]
    // export const pro4WithAug21PerVisit = [
    //     pro4F1PerVisit.aug21,
    //     pro4F2PerVisit.aug21,
    //     pro4F3PerVisit.aug21
    // ]
    // // sep 21
    // export const pro4WithSep21Charges = [
    //     pro4F1Charge.sep21,
    //     pro4F2Charge.sep21,
    //     pro4F3Charge.sep21
    // ]
    // export const pro4WithSep21Pay = [
    //     pro4F1Payment.sep21,
    //     pro4F2Payment.sep21,
    //     pro4F3Payment.sep21
    // ]
    // export const pro4WithSep21Count = [
    //     pro4F1Collectable.sep21,
    //     pro4F2Collectable.sep21,
    //     pro4F3Collectable.sep21
    // ]
    // export const pro4WithSep21NonCount = [
    //     pro4F1NonCollectable.sep21,
    //     pro4F2NonCollectable.sep21,
    //     pro4F3NonCollectable.sep21
    // ]
    // export const pro4WithSep21Claim = [
    //     pro4F1ClaimCount.sep21,
    //     pro4F2ClaimCount.sep21,
    //     pro4F3ClaimCount.sep21
    // ]
    // export const pro4WithSep21PerVisit = [
    //     pro4F1PerVisit.sep21,
    //     pro4F2PerVisit.sep21,
    //     pro4F3PerVisit.sep21
    // ]
    // // oct 21
    // export const pro4WithOct21Charges = [
    //     pro4F1Charge.oct21,
    //     pro4F2Charge.oct21,
    //     pro4F3Charge.oct21
    // ]
    // export const pro4WithOct21Pay = [
    //     pro4F1Payment.oct21,
    //     pro4F2Payment.oct21,
    //     pro4F3Payment.oct21
    // ]
    // export const pro4WithOct21Count = [
    //     pro4F1Collectable.oct21,
    //     pro4F2Collectable.oct21,
    //     pro4F3Collectable.oct21
    // ]
    // export const pro4WithOct21NonCount = [
    //     pro4F1NonCollectable.oct21,
    //     pro4F2NonCollectable.oct21,
    //     pro4F3NonCollectable.oct21
    // ]
    // export const pro4WithOct21Claim = [
    //     pro4F1ClaimCount.oct21,
    //     pro4F2ClaimCount.oct21,
    //     pro4F3ClaimCount.oct21
    // ]
    // export const pro4WithOct21PerVisit = [
    //     pro4F1PerVisit.oct21,
    //     pro4F2PerVisit.oct21,
    //     pro4F3PerVisit.oct21
    // ]
    // // nov 21
    // export const pro4WithNov21Charges = [
    //     pro4F1Charge.nov21,
    //     pro4F2Charge.nov21,
    //     pro4F3Charge.nov21
    // ]
    // export const pro4WithNov21Pay = [
    //     pro4F1Payment.nov21,
    //     pro4F2Payment.nov21,
    //     pro4F3Payment.nov21
    // ]
    // export const pro4WithNov21Count = [
    //     pro4F1Collectable.nov21,
    //     pro4F2Collectable.nov21,
    //     pro4F3Collectable.nov21
    // ]
    // export const pro4WithNov21NonCount = [
    //     pro4F1NonCollectable.nov21,
    //     pro4F2NonCollectable.nov21,
    //     pro4F3NonCollectable.nov21
    // ]
    // export const pro4WithNov21Claim = [
    //     pro4F1ClaimCount.nov21,
    //     pro4F2ClaimCount.nov21,
    //     pro4F3ClaimCount.nov21
    // ]
    // export const pro4WithNov21PerVisit = [
    //     pro4F1PerVisit.nov21,
    //     pro4F2PerVisit.nov21,
    //     pro4F3PerVisit.nov21
    // ]
    // // dec 21
    // export const pro4WithDec21Charges = [
    //     pro4F1Charge.dec21,
    //     pro4F2Charge.dec21,
    //     pro4F3Charge.dec21
    // ]
    // export const pro4WithDec21Pay = [
    //     pro4F1Payment.dec21,
    //     pro4F2Payment.dec21,
    //     pro4F3Payment.dec21
    // ]
    // export const pro4WithDec21Count = [
    //     pro4F1Collectable.dec21,
    //     pro4F2Collectable.dec21,
    //     pro4F3Collectable.dec21
    // ]
    // export const pro4WithDec21NonCount = [
    //     pro4F1NonCollectable.dec21,
    //     pro4F2NonCollectable.dec21,
    //     pro4F3NonCollectable.dec21
    // ]
    // export const pro4WithDec21Claim = [
    //     pro4F1ClaimCount.dec21,
    //     pro4F2ClaimCount.dec21,
    //     pro4F3ClaimCount.dec21
    // ]
    // export const pro4WithDec21PerVisit = [
    //     pro4F1PerVisit.dec21,
    //     pro4F2PerVisit.dec21,
    //     pro4F3PerVisit.dec21
    // ]
    
    // // provider-4 
    // // jan 22
    // export const pro4WithJan22Charges = [
    //     pro4F1Charge.jan22,
    //     pro4F2Charge.jan22,
    //     pro4F3Charge.jan22
    // ]
    // export const pro4WithJan22Pay = [
    //     pro4F1Payment.jan22,
    //     pro4F2Payment.jan22,
    //     pro4F3Payment.jan22
    // ]
    // export const pro4WithJan22Count = [
    //     pro4F1Collectable.jan22,
    //     pro4F2Collectable.jan22,
    //     pro4F3Collectable.jan22
    // ]
    // export const pro4WithJan22NonCount = [
    //     pro4F1NonCollectable.jan22,
    //     pro4F2NonCollectable.jan22,
    //     pro4F3NonCollectable.jan22
    // ]
    // export const pro4WithJan22Claim = [
    //     pro4F1ClaimCount.jan22,
    //     pro4F2ClaimCount.jan22,
    //     pro4F3ClaimCount.jan22
    // ]
    // export const pro4WithJan22PerVisit = [
    //     pro4F1PerVisit.jan22,
    //     pro4F2PerVisit.jan22,
    //     pro4F3PerVisit.jan22
    // ]
    // // feb 22
    // export const pro4WithFeb22Charges = [
    //     pro4F1Charge.feb22,
    //     pro4F2Charge.feb22,
    //     pro4F3Charge.feb22
    // ]
    // export const pro4WithFeb22Pay = [
    //     pro4F1Payment.feb22,
    //     pro4F2Payment.feb22,
    //     pro4F3Payment.feb22
    // ]
    // export const pro4WithFeb22Count = [
    //     pro4F1Collectable.feb22,
    //     pro4F2Collectable.feb22,
    //     pro4F3Collectable.feb22
    // ]
    // export const pro4WithFeb22NonCount = [
    //     pro4F1NonCollectable.feb22,
    //     pro4F2NonCollectable.feb22,
    //     pro4F3NonCollectable.feb22
    // ]
    // export const pro4WithFeb22Claim = [
    //     pro4F1ClaimCount.feb22,
    //     pro4F2ClaimCount.feb22,
    //     pro4F3ClaimCount.feb22
    // ]
    // export const pro4WithFeb22PerVisit = [
    //     pro4F1PerVisit.feb22,
    //     pro4F2PerVisit.feb22,
    //     pro4F3PerVisit.feb22
    // ]
    // // mar 22
    // export const pro4WithMar22Charges = [
    //     pro4F1Charge.mar22,
    //     pro4F2Charge.mar22,
    //     pro4F3Charge.mar22
    // ]
    // export const pro4WithMar22Pay = [
    //     pro4F1Payment.mar22,
    //     pro4F2Payment.mar22,
    //     pro4F3Payment.mar22
    // ]
    // export const pro4WithMar22Count = [
    //     pro4F1Collectable.mar22,
    //     pro4F2Collectable.mar22,
    //     pro4F3Collectable.mar22
    // ]
    // export const pro4WithMar22NonCount = [
    //     pro4F1NonCollectable.mar22,
    //     pro4F2NonCollectable.mar22,
    //     pro4F3NonCollectable.mar22
    // ]
    // export const pro4WithMar22Claim = [
    //     pro4F1ClaimCount.mar22,
    //     pro4F2ClaimCount.mar22,
    //     pro4F3ClaimCount.mar22
    // ]
    // export const pro4WithMar22PerVisit = [
    //     pro4F1PerVisit.mar22,
    //     pro4F2PerVisit.mar22,
    //     pro4F3PerVisit.mar22
    // ]
    // // april 22
    // export const pro4WithApr22Charges = [
    //     pro4F1Charge.apr22,
    //     pro4F2Charge.apr22,
    //     pro4F3Charge.apr22
    // ]
    // export const pro4WithApr22Pay = [
    //     pro4F1Payment.apr22,
    //     pro4F2Payment.apr22,
    //     pro4F3Payment.apr22
    // ]
    // export const pro4WithApr22Count = [
    //     pro4F1Collectable.apr22,
    //     pro4F2Collectable.apr22,
    //     pro4F3Collectable.apr22
    // ]
    // export const pro4WithApr22NonCount = [
    //     pro4F1NonCollectable.apr22,
    //     pro4F2NonCollectable.apr22,
    //     pro4F3NonCollectable.apr22
    // ]
    // export const pro4WithApr22Claim = [
    //     pro4F1ClaimCount.apr22,
    //     pro4F2ClaimCount.apr22,
    //     pro4F3ClaimCount.apr22
    // ]
    // export const pro4WithApr22PerVisit = [
    //     pro4F1PerVisit.apr22,
    //     pro4F2PerVisit.apr22,
    //     pro4F3PerVisit.apr22
    // ]
    // // may 22
    // export const pro4WithMay22Charges = [
    //     pro4F1Charge.may22,
    //     pro4F2Charge.may22,
    //     pro4F3Charge.may22
    // ]
    // export const pro4WithMay22Pay = [
    //     pro4F1Payment.may22,
    //     pro4F2Payment.may22,
    //     pro4F3Payment.may22
    // ]
    // export const pro4WithMay22Count = [
    //     pro4F1Collectable.may22,
    //     pro4F2Collectable.may22,
    //     pro4F3Collectable.may22
    // ]
    // export const pro4WithMay22NonCount = [
    //     pro4F1NonCollectable.may22,
    //     pro4F2NonCollectable.may22,
    //     pro4F3NonCollectable.may22
    // ]
    // export const pro4WithMay22Claim = [
    //     pro4F1ClaimCount.may22,
    //     pro4F2ClaimCount.may22,
    //     pro4F3ClaimCount.may22
    // ]
    // export const pro4WithMay22PerVisit = [
    //     pro4F1PerVisit.may22,
    //     pro4F2PerVisit.may22,
    //     pro4F3PerVisit.may22
    // ]
    // // jun 22
    // export const pro4WithJun22Charges = [
    //     pro4F1Charge.jun22,
    //     pro4F2Charge.jun22,
    //     pro4F3Charge.jun22
    // ]
    // export const pro4WithJun22Pay = [
    //     pro4F1Payment.jun22,
    //     pro4F2Payment.jun22,
    //     pro4F3Payment.jun22
    // ]
    // export const pro4WithJun22Count = [
    //     pro4F1Collectable.jun22,
    //     pro4F2Collectable.jun22,
    //     pro4F3Collectable.jun22
    // ]
    // export const pro4WithJun22NonCount = [
    //     pro4F1NonCollectable.jun22,
    //     pro4F2NonCollectable.jun22,
    //     pro4F3NonCollectable.jun22
    // ]
    // export const pro4WithJun22Claim = [
    //     pro4F1ClaimCount.jun22,
    //     pro4F2ClaimCount.jun22,
    //     pro4F3ClaimCount.jun22
    // ]
    // export const pro4WithJun22PerVisit = [
    //     pro4F1PerVisit.jun22,
    //     pro4F2PerVisit.jun22,
    //     pro4F3PerVisit.jun22
    // ]
    // // jul 22
    // export const pro4WithJul22Charges = [
    //     pro4F1Charge.jul22,
    //     pro4F2Charge.jul22,
    //     pro4F3Charge.jul22
    // ]
    // export const pro4WithJul22Pay = [
    //     pro4F1Payment.jul22,
    //     pro4F2Payment.jul22,
    //     pro4F3Payment.jul22
    // ]
    // export const pro4WithJul22Count = [
    //     pro4F1Collectable.jul22,
    //     pro4F2Collectable.jul22,
    //     pro4F3Collectable.jul22
    // ]
    // export const pro4WithJul22NonCount = [
    //     pro4F1NonCollectable.jul22,
    //     pro4F2NonCollectable.jul22,
    //     pro4F3NonCollectable.jul22
    // ]
    // export const pro4WithJul22Claim = [
    //     pro4F1ClaimCount.jul22,
    //     pro4F2ClaimCount.jul22,
    //     pro4F3ClaimCount.jul22
    // ]
    // export const pro4WithJul22PerVisit = [
    //     pro4F1PerVisit.jul22,
    //     pro4F2PerVisit.jul22,
    //     pro4F3PerVisit.jul22
    // ]
    // // aug 22
    // export const pro4WithAug22Charges = [
    //     pro4F1Charge.aug22,
    //     pro4F2Charge.aug22,
    //     pro4F3Charge.aug22
    // ]
    // export const pro4WithAug22Pay = [
    //     pro4F1Payment.aug22,
    //     pro4F2Payment.aug22,
    //     pro4F3Payment.aug22
    // ]
    // export const pro4WithAug22Count = [
    //     pro4F1Collectable.aug22,
    //     pro4F2Collectable.aug22,
    //     pro4F3Collectable.aug22
    // ]
    // export const pro4WithAug22NonCount = [
    //     pro4F1NonCollectable.aug22,
    //     pro4F2NonCollectable.aug22,
    //     pro4F3NonCollectable.aug22
    // ]
    // export const pro4WithAug22Claim = [
    //     pro4F1ClaimCount.aug22,
    //     pro4F2ClaimCount.aug22,
    //     pro4F3ClaimCount.aug22
    // ]
    // export const pro4WithAug22PerVisit = [
    //     pro4F1PerVisit.aug22,
    //     pro4F2PerVisit.aug22,
    //     pro4F3PerVisit.aug22
    // ]
    // // sep 22
    // export const pro4WithSep22Charges = [
    //     pro4F1Charge.sep22,
    //     pro4F2Charge.sep22,
    //     pro4F3Charge.sep22
    // ]
    // export const pro4WithSep22Pay = [
    //     pro4F1Payment.sep22,
    //     pro4F2Payment.sep22,
    //     pro4F3Payment.sep22
    // ]
    // export const pro4WithSep22Count = [
    //     pro4F1Collectable.sep22,
    //     pro4F2Collectable.sep22,
    //     pro4F3Collectable.sep22
    // ]
    // export const pro4WithSep22NonCount = [
    //     pro4F1NonCollectable.sep22,
    //     pro4F2NonCollectable.sep22,
    //     pro4F3NonCollectable.sep22
    // ]
    // export const pro4WithSep22Claim = [
    //     pro4F1ClaimCount.sep22,
    //     pro4F2ClaimCount.sep22,
    //     pro4F3ClaimCount.sep22
    // ]
    // export const pro4WithSep22PerVisit = [
    //     pro4F1PerVisit.sep22,
    //     pro4F2PerVisit.sep22,
    //     pro4F3PerVisit.sep22
    // ]
    // // oct 22
    // export const pro4WithOct22Charges = [
    //     pro4F1Charge.oct22,
    //     pro4F2Charge.oct22,
    //     pro4F3Charge.oct22
    // ]
    // export const pro4WithOct22Pay = [
    //     pro4F1Payment.oct22,
    //     pro4F2Payment.oct22,
    //     pro4F3Payment.oct22
    // ]
    // export const pro4WithOct22Count = [
    //     pro4F1Collectable.oct22,
    //     pro4F2Collectable.oct22,
    //     pro4F3Collectable.oct22
    // ]
    // export const pro4WithOct22NonCount = [
    //     pro4F1NonCollectable.oct22,
    //     pro4F2NonCollectable.oct22,
    //     pro4F3NonCollectable.oct22
    // ]
    // export const pro4WithOct22Claim = [
    //     pro4F1ClaimCount.oct22,
    //     pro4F2ClaimCount.oct22,
    //     pro4F3ClaimCount.oct22
    // ]
    // export const pro4WithOct22PerVisit = [
    //     pro4F1PerVisit.oct22,
    //     pro4F2PerVisit.oct22,
    //     pro4F3PerVisit.oct22
    // ]
    // // nov 22
    // export const pro4WithNov22Charges = [
    //     pro4F1Charge.nov22,
    //     pro4F2Charge.nov22,
    //     pro4F3Charge.nov22
    // ]
    // export const pro4WithNov22Pay = [
    //     pro4F1Payment.nov22,
    //     pro4F2Payment.nov22,
    //     pro4F3Payment.nov22
    // ]
    // export const pro4WithNov22Count = [
    //     pro4F1Collectable.nov22,
    //     pro4F2Collectable.nov22,
    //     pro4F3Collectable.nov22
    // ]
    // export const pro4WithNov22NonCount = [
    //     pro4F1NonCollectable.nov22,
    //     pro4F2NonCollectable.nov22,
    //     pro4F3NonCollectable.nov22
    // ]
    // export const pro4WithNov22Claim = [
    //     pro4F1ClaimCount.nov22,
    //     pro4F2ClaimCount.nov22,
    //     pro4F3ClaimCount.nov22
    // ]
    // export const pro4WithNov22PerVisit = [
    //     pro4F1PerVisit.nov22,
    //     pro4F2PerVisit.nov22,
    //     pro4F3PerVisit.nov22
    // ]
    // // dec 22
    // export const pro4WithDec22Charges = [
    //     pro4F1Charge.dec22,
    //     pro4F2Charge.dec22,
    //     pro4F3Charge.dec22
    // ]
    // export const pro4WithDec22Pay = [
    //     pro4F1Payment.dec22,
    //     pro4F2Payment.dec22,
    //     pro4F3Payment.dec22
    // ]
    // export const pro4WithDec22Count = [
    //     pro4F1Collectable.dec22,
    //     pro4F2Collectable.dec22,
    //     pro4F3Collectable.dec22
    // ]
    // export const pro4WithDec22NonCount = [
    //     pro4F1NonCollectable.dec22,
    //     pro4F2NonCollectable.dec22,
    //     pro4F3NonCollectable.dec22
    // ]
    // export const pro4WithDec22Claim = [
    //     pro4F1ClaimCount.dec22,
    //     pro4F2ClaimCount.dec22,
    //     pro4F3ClaimCount.dec22
    // ]
    // export const pro4WithDec22PerVisit = [
    //     pro4F1PerVisit.dec22,
    //     pro4F2PerVisit.dec22,
    //     pro4F3PerVisit.dec22
    // ]
    
    // // provider-4
    // // jan 23
    // export const pro4WithJan23Charges = [
    //     pro4F1Charge.jan23,
    //     pro4F2Charge.jan23,
    //     pro4F3Charge.jan23
    // ]
    // export const pro4WithJan23Pay = [
    //     pro4F1Payment.jan23,
    //     pro4F2Payment.jan23,
    //     pro4F3Payment.jan23
    // ]
    // export const pro4WithJan23Count = [
    //     pro4F1Collectable.jan23,
    //     pro4F2Collectable.jan23,
    //     pro4F3Collectable.jan23
    // ]
    // export const pro4WithJan23NonCount = [
    //     pro4F1NonCollectable.jan23,
    //     pro4F2NonCollectable.jan23,
    //     pro4F3NonCollectable.jan23
    // ]
    // export const pro4WithJan23Claim = [
    //     pro4F1ClaimCount.jan23,
    //     pro4F2ClaimCount.jan23,
    //     pro4F3ClaimCount.jan23
    // ]
    // export const pro4WithJan23PerVisit = [
    //     pro4F1PerVisit.jan23,
    //     pro4F2PerVisit.jan23,
    //     pro4F3PerVisit.jan23
    // ]
    // // feb 23
    // export const pro4WithFeb23Charges = [
    //     pro4F1Charge.feb23,
    //     pro4F2Charge.feb23,
    //     pro4F3Charge.feb23
    // ]
    // export const pro4WithFeb23Pay = [
    //     pro4F1Payment.feb23,
    //     pro4F2Payment.feb23,
    //     pro4F3Payment.feb23
    // ]
    // export const pro4WithFeb23Count = [
    //     pro4F1Collectable.feb23,
    //     pro4F2Collectable.feb23,
    //     pro4F3Collectable.feb23
    // ]
    // export const pro4WithFeb23NonCount = [
    //     pro4F1NonCollectable.feb23,
    //     pro4F2NonCollectable.feb23,
    //     pro4F3NonCollectable.feb23
    // ]
    // export const pro4WithFeb23Claim = [
    //     pro4F1ClaimCount.feb23,
    //     pro4F2ClaimCount.feb23,
    //     pro4F3ClaimCount.feb23
    // ]
    // export const pro4WithFeb23PerVisit = [
    //     pro4F1PerVisit.feb23,
    //     pro4F2PerVisit.feb23,
    //     pro4F3PerVisit.feb23
    // ]
    // // mar 23
    // export const pro4WithMar23Charges = [
    //     pro4F1Charge.mar23,
    //     pro4F2Charge.mar23,
    //     pro4F3Charge.mar23
    // ]
    // export const pro4WithMar23Pay = [
    //     pro4F1Payment.mar23,
    //     pro4F2Payment.mar23,
    //     pro4F3Payment.mar23
    // ]
    // export const pro4WithMar23Count = [
    //     pro4F1Collectable.mar23,
    //     pro4F2Collectable.mar23,
    //     pro4F3Collectable.mar23
    // ]
    // export const pro4WithMar23NonCount = [
    //     pro4F1NonCollectable.mar23,
    //     pro4F2NonCollectable.mar23,
    //     pro4F3NonCollectable.mar23
    // ]
    // export const pro4WithMar23Claim = [
    //     pro4F1ClaimCount.mar23,
    //     pro4F2ClaimCount.mar23,
    //     pro4F3ClaimCount.mar23
    // ]
    // export const pro4WithMar23PerVisit = [
    //     pro4F1PerVisit.mar23,
    //     pro4F2PerVisit.mar23,
    //     pro4F3PerVisit.mar23
    // ]
    // // april 23
    // export const pro4WithApr23Charges = [
    //     pro4F1Charge.apr23,
    //     pro4F2Charge.apr23,
    //     pro4F3Charge.apr23
    // ]
    // export const pro4WithApr23Pay = [
    //     pro4F1Payment.apr23,
    //     pro4F2Payment.apr23,
    //     pro4F3Payment.apr23
    // ]
    // export const pro4WithApr23Count = [
    //     pro4F1Collectable.apr23,
    //     pro4F2Collectable.apr23,
    //     pro4F3Collectable.apr23
    // ]
    // export const pro4WithApr23NonCount = [
    //     pro4F1NonCollectable.apr23,
    //     pro4F2NonCollectable.apr23,
    //     pro4F3NonCollectable.apr23
    // ]
    // export const pro4WithApr23Claim = [
    //     pro4F1ClaimCount.apr23,
    //     pro4F2ClaimCount.apr23,
    //     pro4F3ClaimCount.apr23
    // ]
    // export const pro4WithApr23PerVisit = [
    //     pro4F1PerVisit.apr23,
    //     pro4F2PerVisit.apr23,
    //     pro4F3PerVisit.apr23
    // ]
    // // may 23
    // export const pro4WithMay23Charges = [
    //     pro4F1Charge.may23,
    //     pro4F2Charge.may23,
    //     pro4F3Charge.may23
    // ]
    // export const pro4WithMay23Pay = [
    //     pro4F1Payment.may23,
    //     pro4F2Payment.may23,
    //     pro4F3Payment.may23
    // ]
    // export const pro4WithMay23Count = [
    //     pro4F1Collectable.may23,
    //     pro4F2Collectable.may23,
    //     pro4F3Collectable.may23
    // ]
    // export const pro4WithMay23NonCount = [
    //     pro4F1NonCollectable.may23,
    //     pro4F2NonCollectable.may23,
    //     pro4F3NonCollectable.may23
    // ]
    // export const pro4WithMay23Claim = [
    //     pro4F1ClaimCount.may23,
    //     pro4F2ClaimCount.may23,
    //     pro4F3ClaimCount.may23
    // ]
    // export const pro4WithMay23PerVisit = [
    //     pro4F1PerVisit.may23,
    //     pro4F2PerVisit.may23,
    //     pro4F3PerVisit.may23
    // ]
    // // jun 23
    // export const pro4WithJun23Charges = [
    //     pro4F1Charge.jun23,
    //     pro4F2Charge.jun23,
    //     pro4F3Charge.jun23
    // ]
    // export const pro4WithJun23Pay = [
    //     pro4F1Payment.jun23,
    //     pro4F2Payment.jun23,
    //     pro4F3Payment.jun23
    // ]
    // export const pro4WithJun23Count = [
    //     pro4F1Collectable.jun23,
    //     pro4F2Collectable.jun23,
    //     pro4F3Collectable.jun23
    // ]
    // export const pro4WithJun23NonCount = [
    //     pro4F1NonCollectable.jun23,
    //     pro4F2NonCollectable.jun23,
    //     pro4F3NonCollectable.jun23
    // ]
    // export const pro4WithJun23Claim = [
    //     pro4F1ClaimCount.jun23,
    //     pro4F2ClaimCount.jun23,
    //     pro4F3ClaimCount.jun23
    // ]
    // export const pro4WithJun23PerVisit = [
    //     pro4F1PerVisit.jun23,
    //     pro4F2PerVisit.jun23,
    //     pro4F3PerVisit.jun23
    // ]
    // // jul 23
    // export const pro4WithJul23Charges = [
    //     pro4F1Charge.jul23,
    //     pro4F2Charge.jul23,
    //     pro4F3Charge.jul23
    // ]
    // export const pro4WithJul23Pay = [
    //     pro4F1Payment.jul23,
    //     pro4F2Payment.jul23,
    //     pro4F3Payment.jul23
    // ]
    // export const pro4WithJul23Count = [
    //     pro4F1Collectable.jul23,
    //     pro4F2Collectable.jul23,
    //     pro4F3Collectable.jul23
    // ]
    // export const pro4WithJul23NonCount = [
    //     pro4F1NonCollectable.jul23,
    //     pro4F2NonCollectable.jul23,
    //     pro4F3NonCollectable.jul23
    // ]
    // export const pro4WithJul23Claim = [
    //     pro4F1ClaimCount.jul23,
    //     pro4F2ClaimCount.jul23,
    //     pro4F3ClaimCount.jul23
    // ]
    // export const pro4WithJul23PerVisit = [
    //     pro4F1PerVisit.jul23,
    //     pro4F2PerVisit.jul23,
    //     pro4F3PerVisit.jul23
    // ]
    // // aug 23
    // export const pro4WithAug23Charges = [
    //     pro4F1Charge.aug23,
    //     pro4F2Charge.aug23,
    //     pro4F3Charge.aug23
    // ]
    // export const pro4WithAug23Pay = [
    //     pro4F1Payment.aug23,
    //     pro4F2Payment.aug23,
    //     pro4F3Payment.aug23
    // ]
    // export const pro4WithAug23Count = [
    //     pro4F1Collectable.aug23,
    //     pro4F2Collectable.aug23,
    //     pro4F3Collectable.aug23
    // ]
    // export const pro4WithAug23NonCount = [
    //     pro4F1NonCollectable.aug23,
    //     pro4F2NonCollectable.aug23,
    //     pro4F3NonCollectable.aug23
    // ]
    // export const pro4WithAug23Claim = [
    //     pro4F1ClaimCount.aug23,
    //     pro4F2ClaimCount.aug23,
    //     pro4F3ClaimCount.aug23
    // ]
    // export const pro4WithAug23PerVisit = [
    //     pro4F1PerVisit.aug23,
    //     pro4F2PerVisit.aug23,
    //     pro4F3PerVisit.aug23
    // ]
    // // sep 23
    // export const pro4WithSep23Charges = [
    //     pro4F1Charge.sep23,
    //     pro4F2Charge.sep23,
    //     pro4F3Charge.sep23
    // ]
    // export const pro4WithSep23Pay = [
    //     pro4F1Payment.sep23,
    //     pro4F2Payment.sep23,
    //     pro4F3Payment.sep23
    // ]
    // export const pro4WithSep23Count = [
    //     pro4F1Collectable.sep23,
    //     pro4F2Collectable.sep23,
    //     pro4F3Collectable.sep23
    // ]
    // export const pro4WithSep23NonCount = [
    //     pro4F1NonCollectable.sep23,
    //     pro4F2NonCollectable.sep23,
    //     pro4F3NonCollectable.sep23
    // ]
    // export const pro4WithSep23Claim = [
    //     pro4F1ClaimCount.sep23,
    //     pro4F2ClaimCount.sep23,
    //     pro4F3ClaimCount.sep23
    // ]
    // export const pro4WithSep23PerVisit = [
    //     pro4F1PerVisit.sep23,
    //     pro4F2PerVisit.sep23,
    //     pro4F3PerVisit.sep23
    // ]
    // // oct 23
    // export const pro4WithOct23Charges = [
    //     pro4F1Charge.oct23,
    //     pro4F2Charge.oct23,
    //     pro4F3Charge.oct23
    // ]
    // export const pro4WithOct23Pay = [
    //     pro4F1Payment.oct23,
    //     pro4F2Payment.oct23,
    //     pro4F3Payment.oct23
    // ]
    // export const pro4WithOct23Count = [
    //     pro4F1Collectable.oct23,
    //     pro4F2Collectable.oct23,
    //     pro4F3Collectable.oct23
    // ]
    // export const pro4WithOct23NonCount = [
    //     pro4F1NonCollectable.oct23,
    //     pro4F2NonCollectable.oct23,
    //     pro4F3NonCollectable.oct23
    // ]
    // export const pro4WithOct23Claim = [
    //     pro4F1ClaimCount.oct23,
    //     pro4F2ClaimCount.oct23,
    //     pro4F3ClaimCount.oct23
    // ]
    // export const pro4WithOct23PerVisit = [
    //     pro4F1PerVisit.oct23,
    //     pro4F2PerVisit.oct23,
    //     pro4F3PerVisit.oct23
    // ]
    // // nov 23
    // export const pro4WithNov23Charges = [
    //     pro4F1Charge.nov23,
    //     pro4F2Charge.nov23,
    //     pro4F3Charge.nov23
    // ]
    // export const pro4WithNov23Pay = [
    //     pro4F1Payment.nov23,
    //     pro4F2Payment.nov23,
    //     pro4F3Payment.nov23
    // ]
    // export const pro4WithNov23Count = [
    //     pro4F1Collectable.nov23,
    //     pro4F2Collectable.nov23,
    //     pro4F3Collectable.nov23
    // ]
    // export const pro4WithNov23NonCount = [
    //     pro4F1NonCollectable.nov23,
    //     pro4F2NonCollectable.nov23,
    //     pro4F3NonCollectable.nov23
    // ]
    // export const pro4WithNov23Claim = [
    //     pro4F1ClaimCount.nov23,
    //     pro4F2ClaimCount.nov23,
    //     pro4F3ClaimCount.nov23
    // ]
    // export const pro4WithNov23PerVisit = [
    //     pro4F1PerVisit.nov23,
    //     pro4F2PerVisit.nov23,
    //     pro4F3PerVisit.nov23
    // ]








