import {allF1Charge , allF1Payment , allF1Collectable , allF1NonCollectable , allF1ClaimCount , allF1PerVisit ,
    allF2Charge , allF2Payment , allF2Collectable , allF2NonCollectable , allF2ClaimCount , allF2PerVisit ,
    allF3Charge , allF3Payment , allF3Collectable , allF3NonCollectable , allF3ClaimCount , allF3PerVisit ,
    pro1F1Charge , pro1F1Payment , pro1F1Collectable , pro1F1NonCollectable , pro1F1ClaimCount , pro1F1PerVisit ,
    pro1F2Charge , pro1F2Payment , pro1F2Collectable , pro1F2NonCollectable , pro1F2ClaimCount , pro1F2PerVisit ,
    pro1F3Charge , pro1F3Payment , pro1F3Collectable , pro1F3NonCollectable , pro1F3ClaimCount , pro1F3PerVisit ,
    pro2F1Charge , pro2F1Payment , pro2F1Collectable , pro2F1NonCollectable , pro2F1ClaimCount , pro2F1PerVisit ,
    pro2F2Charge , pro2F2Payment , pro2F2Collectable , pro2F2NonCollectable , pro2F2ClaimCount , pro2F2PerVisit ,
    pro2F3Charge , pro2F3Payment , pro2F3Collectable , pro2F3NonCollectable , pro2F3ClaimCount , pro2F3PerVisit ,
    pro3F1Charge , pro3F1Payment , pro3F1Collectable , pro3F1NonCollectable , pro3F1ClaimCount , pro3F1PerVisit ,
    pro3F2Charge , pro3F2Payment , pro3F2Collectable , pro3F2NonCollectable , pro3F2ClaimCount , pro3F2PerVisit ,
    pro3F3Charge , pro3F3Payment , pro3F3Collectable , pro3F3NonCollectable , pro3F3ClaimCount , pro3F3PerVisit ,
    pro4F1Charge , pro4F1Payment , pro4F1Collectable , pro4F1NonCollectable , pro4F1ClaimCount , pro4F1PerVisit ,
    pro4F2Charge , pro4F2Payment , pro4F2Collectable , pro4F2NonCollectable , pro4F2ClaimCount , pro4F2PerVisit ,
    pro4F3Charge , pro4F3Payment , pro4F3Collectable , pro4F3NonCollectable , pro4F3ClaimCount , pro4F3PerVisit ,
    pro5F1Charge , pro5F1Payment , pro5F1Collectable , pro5F1NonCollectable , pro5F1ClaimCount , pro5F1PerVisit ,
    pro5F2Charge , pro5F2Payment , pro5F2Collectable , pro5F2NonCollectable , pro5F2ClaimCount , pro5F2PerVisit ,
    pro5F3Charge , pro5F3Payment , pro5F3Collectable , pro5F3NonCollectable , pro5F3ClaimCount , pro5F3PerVisit ,
    pro6F1Charge , pro6F1Payment , pro6F1Collectable , pro6F1NonCollectable , pro6F1ClaimCount , pro6F1PerVisit ,
    pro6F2Charge , pro6F2Payment , pro6F2Collectable , pro6F2NonCollectable , pro6F2ClaimCount , pro6F2PerVisit ,
    pro6F3Charge , pro6F3Payment , pro6F3Collectable , pro6F3NonCollectable , pro6F3ClaimCount , pro6F3PerVisit ,
    pro7F1Charge , pro7F1Payment , pro7F1Collectable , pro7F1NonCollectable , pro7F1ClaimCount , pro7F1PerVisit ,
    pro7F2Charge , pro7F2Payment , pro7F2Collectable , pro7F2NonCollectable , pro7F2ClaimCount , pro7F2PerVisit , 
    pro7F3Charge , pro7F3Payment , pro7F3Collectable , pro7F3NonCollectable , pro7F3ClaimCount , pro7F3PerVisit } from './FacilityData';

import { allProviderPervisitRX, provider1PerVisist, provider2PerVisist, provider3PerVisist, provider4PerVisist, provider5PerVisist, provider6PerVisist, provider7PerVisist } from './ProvidersAndFacility';
    
// export const firstChargesRX = [{
//     name: 'Charges',
//     data: [
//         allProviderChargesRX[0],
//         allProviderChargesRX[1],
//         allProviderChargesRX[2]
//         // 409294.73, 
//         // 336245.51, 
//         // 462655.48
       


//     ]
// },
// {
//     name: 'Payment',
//     data: [
//         allProviderClaimRX
//         // 210765.13,
//         // 243989.80,
//         // 294605.44
    

//     ]
// }, {
//     name: 'Collectable',
//     data: [

//         // 598.50,
//         // 1133.36,
//         // 1479.42       


//     ]
// }, {
//     name: 'NonCollecatble',
//     data: [

//         // 132719.26,
//         // 139849.62,
//         // 158664.07
       


//     ]
// }, {
//     name: 'Claim',
//     data: [

//         // 2974,
//         // 1944,
//         // 2717



//     ]
// }, {
//     name: 'Average Per Visits',
//     data: [

//         // 70.87, 
//         // 125.51, 
//         // 108.43 
       


//     ]
// }
// ]

// //second
// export const secondChargesRX = [
//     {
//         name: 'Charges',
//         data: [
//             allProviderChargesRX[3],
//             allProviderChargesRX[4],
//             allProviderChargesRX[5]
//             // 437064.06,
//             // 379760.89,
//             // 454838.22

//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             // 265935.68,
//             // 226680.19,
//             // 264642.45



//         ]
//     }, {
//         name: 'Collectable',
//         data: [
//             // 378.73,
//             // 198.65,
//             // 1232.4


//         ]
//     }, {
//         name: 'NonCollecatble',
//         data: [
//             // 163671.3,
//             // 129527.1,
//             // 149196.81

//         ]
//     }, {
//         name: 'Claim',
//         data: [
//             // 2529,
//             // 2275,
//             // 2813

//         ]
//     }, {
//         name: 'Average Per Visits',
//         data: [
//             105.15, 
//             99.64, 
//             94.08 
           


//         ]
//     }

// ]

// //thirds
// export const thirdChargesRX = [


//     {
//         name: 'Charges',
//         data: [
//             allProviderChargesRX[6],
//             allProviderChargesRX[7],
//             allProviderChargesRX[8]
//             // 425408.21,
//             // 533633.42,
//             // 466732.54,

//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             265859.52,
//             286586.63,
//             271290.89
           

//         ]
//     }, {
//         name: 'Collectable',
//         data: [
//             732.24,
//             327.28,
//             538.33


//         ]
//     }, {
//         name: 'NonCollecatble',
//         data: [
//             141234.42,
//             157483.85,
//             150836.46


//         ]
//     }, {
//         name: 'Claim',
//         data: [
//             2346,
//             2577,
//             2187


//         ]
//     }, {
//         name: 'Average Per Visits',
//         data: [
//             113.32, 
//             111.21, 
//             124.05
           



//         ]
//     }


// ]

// //fourth
// export const fourthChargesRX = [

//     {
//         name: 'Charges',
//         data: [
//             allProviderChargesRX[9],
//             allProviderChargesRX[10],
//             allProviderChargesRX[11]
//             // 575864.99,
//             // 514278.32,
//             // 493342.37,



//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             324506.34,
//             350271.70,
//             291180.12
           


//         ]
//     }, {
//         name: 'Collectable',
//         data: [
//             879.24,
//             1160.67,
//             4891


//         ]
//     }, {
//         name: 'NonCollecatble',
//         data: [
//             187679,
//             215649.72,
//             164244.91

//         ]
//     }, {
//         name: 'Claim',
//         data: [
//             2855,
//             2530,
//             2481

//         ]
//     }, {
//         name: 'Average Per Visits',
//         data: [
//             113.66, 
//             138.45, 
//             117.36
           



//         ]
//     }

// ]


// //2022 firth
// export const fifthChargesRX = [

//     {
//         name: 'Charges',
//         data: [
//             allProviderChargesRX[12],
//             allProviderChargesRX[13],
//             allProviderChargesRX[14]
//             // 456101.98,
//             // 368106.31,
//             // 503773.54

//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             263487.38,
//             256845.13,
//             315211.42
           

//         ]
//     }, {
//         name: 'Collectable',
//         data: [
//             26667.21,
//             784.04,
//             947.8


//         ]
//     }, {
//         name: 'NonCollecatble',
//         data: [
//             149840.94,
//             143253.3,
//             179263.19

//         ]
//     }, {
//         name: 'Claim',
//         data: [
//             2409,
//             1797,
//             2437


//         ]
//     }, {
//         name: 'Average Per Visits',
//         data: [
//             109.38, 
//             142.93, 
//             129.34
           
//         ]
//     }
// ]

// //2022 second
// export const sixthChargesRX = [
//     {
//         name: 'Charges',
//         data: [
//             allProviderChargesRX[15],
//             allProviderChargesRX[16],
//             allProviderChargesRX[17]
//             // 449736.44,
//             // 458587.23,
//             // 272634.02


//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             300790.68,
//             284924.95,
//             179717.07
           



//         ]
//     }, {
//         name: 'Collectable',
//         data: [
//             590.85,
//             1043.17,
//             1285.44


//         ]
//     }, {
//         name: 'NonCollecatble',
//         data: [
//             155587.08,
//             173833.69,
//             104497.28


//         ]
//     }, {
//         name: 'Claim',
//         data: [
//             2118,
//             2248,
//             1337


//         ]
//     }, {
//         name: 'Average Per Visits',
//         data: [
//             142.02, 
//             126.75, 
//             134.42
           

//         ]
//     }
// ]
// export const seventhChargesRX = [
//     {
//         name: 'Charges',
//         data: [
//             allProviderChargesRX[18],
//             allProviderChargesRX[19],
//             allProviderChargesRX[20]
//             // 447774.86,
//             // 536349.59,
//             // 527758.19



//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             284886.77,
//             355959.43,
//             314055.36
           




//         ]
//     }, {
//         name: 'Collectable',
//         data: [
//             2584.74,
//             48934.58,
//             57303.54



//         ]
//     }, {
//         name: 'NonCollecatble',
//         data: [
//             166646.66,
//             191883.64,
//             161895.72
           



//         ]
//     }, {
//         name: 'Claim',
//         data: [
//             2224,
//             2361,
//             2409



//         ]
//     }, {
//         name: 'Average Per Visits',
//         data: [
//             128.10, 
//             150.77, 
//             130.37 
           


//         ]
//     }
// ]
// export const eighthChargesRX = [
//     {
//         name: 'Charges',
//         data: [
//             allProviderChargesRX[21],
//             allProviderChargesRX[22],
//             allProviderChargesRX[23]
//             // 586453.72, 
//             // 576939.64, 
//             // 484411.80
           




//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             345938.11,
//             352299.58,
//             335016.58




//         ]
//     }, {
//         name: 'Collectable',
//         data: [
//             46346.6,
//             14637.87,
//             673.97




//         ]
//     }, {
//         name: 'NonCollecatble',
//         data: [
//             175178.42,
//             169498.70,
//             154193.76
           



//         ]
//     }, {
//         name: 'Claim',
//         data: [
//             2384,
//             2416,
//             2291




//         ]
//     }, {
//         name: 'Average Per Visits',
//         data: [
//             145.11,
//             145.82,
//             146.23
           


//         ]
//     }
// ]
// //2023 firth
// export const ninethChargesRX = [
//     {
//         name: 'Charges',
//         data: [
//             allProviderChargesRX[24],
//             allProviderChargesRX[25],
//             allProviderChargesRX[26]
//             // 598790.90, 
//             // 436597.62, 
//             // 543125.84
           







//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             331113.61,
//             267846.65,
//             338258.54
           



//         ]
//     }, {
//         name: 'Collectable',
//         data: [
//             263.42,
//             5174.71,
//             836.71
           







//         ]
//     }, {
//         name: 'NonCollecatble',
//         data: [
//             213303.58,
//             152899.89,
//             244436.01
           






//         ]
//     }, {
//         name: 'Claim',
//         data: [
//             2139,
//             1839,
//             1887






//         ]
//     }, {
//         name: 'Average Per Visits',
//         data: [
//             154.80, 
//             145.65, 
//             179.26
           

//         ]
//     }
// ]
// // 2023 second
// export const tenthChargesRX = [
//     {
//         name: 'Charges',
//         data: [
//             allProviderChargesRX[27],
//             allProviderChargesRX[28],
//             allProviderChargesRX[29]
//             // 382809.67, 
//             // 536001.35, 
//             // 550608.64 
           

//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             243658.18,
//             283175.46,
//             308365.80
           





//         ]
//     }, {
//         name: 'Collectable',
//         data: [
//             352.95,
//             1350.38,
//             2249.58
           

//         ]
//     }, {
//         name: 'NonCollecatble',
//         data: [
//             136919.85,
//             184870.21,
//             200353.13
           





//         ]
//     }, {
//         name: 'Claim',
//         data: [
//             1619,
//             2684,
//             2766



//         ]
//     }, {
//         name: 'Average Per Visits',
//         data: [
//             150.50, 
//             105.51, 
//             111.48
           
//         ]
//     }
// ]
// //2023 third 
// export const eleventhChargesRX = [
//     {
//         name: 'Charges',
//         data: [
//             allProviderChargesRX[30],
//             allProviderChargesRX[31],
//             allProviderChargesRX[32]
//             // 483428.06, 
//             // 523824.57, 
//             // 570967.26 
           
           

//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             308956.01,
//             347821.33,
//             282573.58
           

//         ]
//     }, {
//         name: 'Collectable',
//         data: [
//             2465.78,
//             1776.81,
//             2224.68
           

//         ]
//     }, {
//         name: 'NonCollecatble',
//         data: [
//             202220.54,
//             214108.83,
//             188516.38
           
//         ]
//     }, {
//         name: 'Claim',
//         data: [
//             2494,
//             2787,
//             2370

//         ]
//     }, {
//         name: 'Average Per Visits',
//         data: [
//             123.88, 
//             124.80, 
//             119.23
           
//         ]
//     }
// ]
// // 2023 fourth
// export const twelvethChargesRX = [
//     {
//         name: 'Charges',
//         data: [
//             allProviderChargesRX[33],
//             allProviderChargesRX[34],
//             0
           

//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             379691.63,
//             435568.10,
//             0
           

//         ]
//     }, {
//         name: 'Collectable',
//         data: [
//             2086.07,
//             1237.56,
//             0
           

//         ]
//     }, {
//         name: 'NonCollecatble',
//         data: [
//             254240.50,
//             313056.80,
//             0
           
//         ]
//     }, {
//         name: 'Claim',
//         data: [
//             2952,
//             2753,
//             0

//         ]
//     }, {
//         name: 'Average Per Visits',
//         data: [
//             128.62, 
//             158.22, 
//             0
           
//         ]
//     }
// ]


// all 21 firth quarter
export const firQuarOf21InAll = [{
    name: 'Charges',
    data: [
        allF1Charge.jan21 + allF2Charge.jan21 + allF3Charge.jan21,
        allF1Charge.feb21 + allF2Charge.feb21 + allF3Charge.feb21,
        allF1Charge.mar21 + allF2Charge.mar21 + allF3Charge.mar21,

    ]
},{
    name: 'Payment',
    data: [
        allF1Payment.jan21 + allF2Payment.jan21 + allF3Payment.jan21,
        allF1Payment.feb21 + allF2Payment.feb21 + allF3Payment.feb21,
        allF1Payment.mar21 + allF2Payment.mar21 + allF3Payment.mar21,
    ]
},{
    name: 'Collectable',
    data: [
        allF1Collectable.jan21 + allF2Collectable.jan21 + allF3Collectable.jan21,
        allF1Collectable.feb21 + allF2Collectable.feb21 + allF3Collectable.feb21,
        allF1Collectable.mar21 + allF2Collectable.mar21 + allF3Collectable.mar21,
    ]
},{
    name: 'Non Collectable',
    data: [
        allF1NonCollectable.jan21 + allF2NonCollectable.jan21 + allF3NonCollectable.jan21,
        allF1NonCollectable.feb21 + allF2NonCollectable.feb21 + allF3NonCollectable.feb21,
        allF1NonCollectable.mar21 + allF2NonCollectable.mar21 + allF3NonCollectable.mar21,
    ]
},{
    name: 'Claim',
    data: [
        allF1ClaimCount.jan21 + allF2ClaimCount.jan21 + allF3ClaimCount.jan21,
        allF1ClaimCount.feb21 + allF2ClaimCount.feb21 + allF3ClaimCount.feb21,
        allF1ClaimCount.mar21 + allF2ClaimCount.mar21 + allF3ClaimCount.mar21,
    ]
},{
    name: 'Average Per Visits',
    data: [
        allProviderPervisitRX[0],
        allProviderPervisitRX[1],
        allProviderPervisitRX[2]
    ]
}]
// all 21 sec quarter 
export const secQuarOf21InAll = [{
    name: 'Charges',
    data: [
        allF1Charge.apr21 + allF2Charge.apr21 + allF3Charge.apr21,
        allF1Charge.may21 + allF2Charge.may21 + allF3Charge.may21,
        allF1Charge.jun21 + allF2Charge.jun21 + allF3Charge.jun21,

    ]
},{
    name: 'Payment',
    data: [
        allF1Payment.apr21 + allF2Payment.apr21 + allF3Payment.apr21,
        allF1Payment.may21 + allF2Payment.may21 + allF3Payment.may21,
        allF1Payment.jun21 + allF2Payment.jun21 + allF3Payment.jun21,
    ]
},{
    name: 'Collectable',
    data: [
        allF1Collectable.apr21 + allF2Collectable.apr21 + allF3Collectable.apr21,
        allF1Collectable.may21 + allF2Collectable.may21 + allF3Collectable.may21,
        allF1Collectable.jun21 + allF2Collectable.jun21 + allF3Collectable.jun21,
    ]
},{
    name: 'Non Collectable',
    data: [
        allF1NonCollectable.apr21 + allF2NonCollectable.apr21 + allF3NonCollectable.apr21,
        allF1NonCollectable.may21 + allF2NonCollectable.may21 + allF3NonCollectable.may21,
        allF1NonCollectable.jun21 + allF2NonCollectable.jun21 + allF3NonCollectable.jun21,
    ]
},{
    name: 'Claim',
    data: [
        allF1ClaimCount.apr21 + allF2ClaimCount.apr21 + allF3ClaimCount.apr21,
        allF1ClaimCount.may21 + allF2ClaimCount.may21 + allF3ClaimCount.may21,
        allF1ClaimCount.jun21 + allF2ClaimCount.jun21 + allF3ClaimCount.jun21,
    ]
},{
    name: 'Average Per Visits',
    data: [
        allProviderPervisitRX[3],
        allProviderPervisitRX[4],
        allProviderPervisitRX[5]
    ]
}]
// all 21 third quarter 
export const thiQuarOf21InAll = [{
    name: 'Charges',
    data: [
        allF1Charge.jul21 + allF2Charge.jul21 + allF3Charge.jul21,
        allF1Charge.aug21 + allF2Charge.aug21 + allF3Charge.aug21,
        allF1Charge.sep21 + allF2Charge.sep21 + allF3Charge.sep21,

    ]
},{
    name: 'Payment',
    data: [
        allF1Payment.jul21 + allF2Payment.jul21 + allF3Payment.jul21,
        allF1Payment.aug21 + allF2Payment.aug21 + allF3Payment.aug21,
        allF1Payment.sep21 + allF2Payment.sep21 + allF3Payment.sep21,
    ]
},{
    name: 'Collectable',
    data: [
        allF1Collectable.jul21 + allF2Collectable.jul21 + allF3Collectable.jul21,
        allF1Collectable.aug21 + allF2Collectable.aug21 + allF3Collectable.aug21,
        allF1Collectable.sep21 + allF2Collectable.sep21 + allF3Collectable.sep21,
    ]
},{
    name: 'Non Collectable',
    data: [
        allF1NonCollectable.jul21 + allF2NonCollectable.jul21 + allF3NonCollectable.jul21,
        allF1NonCollectable.aug21 + allF2NonCollectable.aug21 + allF3NonCollectable.aug21,
        allF1NonCollectable.sep21 + allF2NonCollectable.sep21 + allF3NonCollectable.sep21,
    ]
},{
    name: 'Claim',
    data: [
        allF1ClaimCount.jul21 + allF2ClaimCount.jul21 + allF3ClaimCount.jul21,
        allF1ClaimCount.aug21 + allF2ClaimCount.aug21 + allF3ClaimCount.aug21,
        allF1ClaimCount.sep21 + allF2ClaimCount.sep21 + allF3ClaimCount.sep21,
    ]
},{
    name: 'Average Per Visits',
    data: [
        allProviderPervisitRX[6],
        allProviderPervisitRX[7],
        allProviderPervisitRX[8]
    ]
}]
// all 21 fourth quarter 
export const fouQuarOf21InAll = [{
    name: 'Charges',
    data: [
        allF1Charge.oct21 + allF2Charge.oct21 + allF3Charge.oct21,
        allF1Charge.nov21 + allF2Charge.nov21 + allF3Charge.nov21,
        allF1Charge.dec21 + allF2Charge.dec21 + allF3Charge.dec21,

    ]
},{
    name: 'Payment',
    data: [
        allF1Payment.oct21 + allF2Payment.oct21 + allF3Payment.oct21,
        allF1Payment.nov21 + allF2Payment.nov21 + allF3Payment.nov21,
        allF1Payment.dec21 + allF2Payment.dec21 + allF3Payment.dec21,
    ]
},{
    name: 'Collectable',
    data: [
        allF1Collectable.oct21 + allF2Collectable.oct21 + allF3Collectable.oct21,
        allF1Collectable.nov21 + allF2Collectable.nov21 + allF3Collectable.nov21,
        allF1Collectable.dec21 + allF2Collectable.dec21 + allF3Collectable.dec21,
    ]
},{
    name: 'Non Collectable',
    data: [
        allF1NonCollectable.oct21 + allF2NonCollectable.oct21 + allF3NonCollectable.oct21,
        allF1NonCollectable.nov21 + allF2NonCollectable.nov21 + allF3NonCollectable.nov21,
        allF1NonCollectable.dec21 + allF2NonCollectable.dec21 + allF3NonCollectable.dec21,
    ]
},{
    name: 'Claim',
    data: [
        allF1ClaimCount.oct21 + allF2ClaimCount.oct21 + allF3ClaimCount.oct21,
        allF1ClaimCount.nov21 + allF2ClaimCount.nov21 + allF3ClaimCount.nov21,
        allF1ClaimCount.dec21 + allF2ClaimCount.dec21 + allF3ClaimCount.dec21,
    ]
},{
    name: 'Average Per Visits',
    data: [
        allProviderPervisitRX[9],
        allProviderPervisitRX[10],
        allProviderPervisitRX[11]
    ]
}]
// all 22 firth quarter 
export const firQuarOf22InAll = [{
    name: 'Charges',
    data: [
        allF1Charge.jan22 + allF2Charge.jan22 + allF3Charge.jan22,
        allF1Charge.feb22 + allF2Charge.feb22 + allF3Charge.feb22,
        allF1Charge.mar22 + allF2Charge.mar22 + allF3Charge.mar22,

    ]
},{
    name: 'Payment',
    data: [
        allF1Payment.jan22 + allF2Payment.jan22 + allF3Payment.jan22,
        allF1Payment.feb22 + allF2Payment.feb22 + allF3Payment.feb22,
        allF1Payment.mar22 + allF2Payment.mar22 + allF3Payment.mar22,
    ]
},{
    name: 'Collectable',
    data: [
        allF1Collectable.jan22 + allF2Collectable.jan22 + allF3Collectable.jan22,
        allF1Collectable.feb22 + allF2Collectable.feb22 + allF3Collectable.feb22,
        allF1Collectable.mar22 + allF2Collectable.mar22 + allF3Collectable.mar22,
    ]
},{
    name: 'Non Collectable',
    data: [
        allF1NonCollectable.jan22 + allF2NonCollectable.jan22 + allF3NonCollectable.jan22,
        allF1NonCollectable.feb22 + allF2NonCollectable.feb22 + allF3NonCollectable.feb22,
        allF1NonCollectable.mar22 + allF2NonCollectable.mar22 + allF3NonCollectable.mar22,
    ]
},{
    name: 'Claim',
    data: [
        allF1ClaimCount.jan22 + allF2ClaimCount.jan22 + allF3ClaimCount.jan22,
        allF1ClaimCount.feb22 + allF2ClaimCount.feb22 + allF3ClaimCount.feb22,
        allF1ClaimCount.mar22 + allF2ClaimCount.mar22 + allF3ClaimCount.mar22,
    ]
},{
    name: 'Average Per Visits',
    data: [
        allProviderPervisitRX[12],
        allProviderPervisitRX[13],
        allProviderPervisitRX[14]
    ]
}]
// all 22 second quarter 
export const secQuarOf22InAll = [{
    name: 'Charges',
    data: [
        allF1Charge.apr22 + allF2Charge.apr22 + allF3Charge.apr22,
        allF1Charge.may22 + allF2Charge.may22 + allF3Charge.may22,
        allF1Charge.jun22 + allF2Charge.jun22 + allF3Charge.jun22,

    ]
},{
    name: 'Payment',
    data: [
        allF1Payment.apr22 + allF2Payment.apr22 + allF3Payment.apr22,
        allF1Payment.may22 + allF2Payment.may22 + allF3Payment.may22,
        allF1Payment.jun22 + allF2Payment.jun22 + allF3Payment.jun22,
    ]
},{
    name: 'Collectable',
    data: [
        allF1Collectable.apr22 + allF2Collectable.apr22 + allF3Collectable.apr22,
        allF1Collectable.may22 + allF2Collectable.may22 + allF3Collectable.may22,
        allF1Collectable.jun22 + allF2Collectable.jun22 + allF3Collectable.jun22,
    ]
},{
    name: 'Non Collectable',
    data: [
        allF1NonCollectable.apr22 + allF2NonCollectable.apr22 + allF3NonCollectable.apr22,
        allF1NonCollectable.may22 + allF2NonCollectable.may22 + allF3NonCollectable.may22,
        allF1NonCollectable.jun22 + allF2NonCollectable.jun22 + allF3NonCollectable.jun22,
    ]
},{
    name: 'Claim',
    data: [
        allF1ClaimCount.apr22 + allF2ClaimCount.apr22 + allF3ClaimCount.apr22,
        allF1ClaimCount.may22 + allF2ClaimCount.may22 + allF3ClaimCount.may22,
        allF1ClaimCount.jun22 + allF2ClaimCount.jun22 + allF3ClaimCount.jun22,
    ]
},{
    name: 'Average Per Visits',
    data: [
        allProviderPervisitRX[15],
        allProviderPervisitRX[16],
        allProviderPervisitRX[17]
    ]
}]
// all 22 third quarter 
export const thiQuarOf22InAll = [{
    name: 'Charges',
    data: [
        allF1Charge.jul22 + allF2Charge.jul22 + allF3Charge.jul22,
        allF1Charge.aug22 + allF2Charge.aug22 + allF3Charge.aug22,
        allF1Charge.sep22 + allF2Charge.sep22 + allF3Charge.sep22,

    ]
},{
    name: 'Payment',
    data: [
        allF1Payment.jul22 + allF2Payment.jul22 + allF3Payment.jul22,
        allF1Payment.aug22 + allF2Payment.aug22 + allF3Payment.aug22,
        allF1Payment.sep22 + allF2Payment.sep22 + allF3Payment.sep22,
    ]
},{
    name: 'Collectable',
    data: [
        allF1Collectable.jul22 + allF2Collectable.jul22 + allF3Collectable.jul22,
        allF1Collectable.aug22 + allF2Collectable.aug22 + allF3Collectable.aug22,
        allF1Collectable.sep22 + allF2Collectable.sep22 + allF3Collectable.sep22,
    ]
},{
    name: 'Non Collectable',
    data: [
        allF1NonCollectable.jul22 + allF2NonCollectable.jul22 + allF3NonCollectable.jul22,
        allF1NonCollectable.aug22 + allF2NonCollectable.aug22 + allF3NonCollectable.aug22,
        allF1NonCollectable.sep22 + allF2NonCollectable.sep22 + allF3NonCollectable.sep22,
    ]
},{
    name: 'Claim',
    data: [
        allF1ClaimCount.jul22 + allF2ClaimCount.jul22 + allF3ClaimCount.jul22,
        allF1ClaimCount.aug22 + allF2ClaimCount.aug22 + allF3ClaimCount.aug22,
        allF1ClaimCount.sep22 + allF2ClaimCount.sep22 + allF3ClaimCount.sep22,
    ]
},{
    name: 'Average Per Visits',
    data: [
        allProviderPervisitRX[18],
        allProviderPervisitRX[19],
        allProviderPervisitRX[20]
    ]
}]
// all 22 fourth quarter 
export const fouQuarOf22InAll = [{
    name: 'Charges',
    data: [
        allF1Charge.oct22 + allF2Charge.oct22 + allF3Charge.oct22,
        allF1Charge.nov22 + allF2Charge.nov22 + allF3Charge.nov22,
        allF1Charge.dec22 + allF2Charge.dec22 + allF3Charge.dec22,

    ]
},{
    name: 'Payment',
    data: [
        allF1Payment.oct22 + allF2Payment.oct22 + allF3Payment.oct22,
        allF1Payment.nov22 + allF2Payment.nov22 + allF3Payment.nov22,
        allF1Payment.dec22 + allF2Payment.dec22 + allF3Payment.dec22,
    ]
},{
    name: 'Collectable',
    data: [
        allF1Collectable.oct22 + allF2Collectable.oct22 + allF3Collectable.oct22,
        allF1Collectable.nov22 + allF2Collectable.nov22 + allF3Collectable.nov22,
        allF1Collectable.dec22 + allF2Collectable.dec22 + allF3Collectable.dec22,
    ]
},{
    name: 'Non Collectable',
    data: [
        allF1NonCollectable.oct22 + allF2NonCollectable.oct22 + allF3NonCollectable.oct22,
        allF1NonCollectable.nov22 + allF2NonCollectable.nov22 + allF3NonCollectable.nov22,
        allF1NonCollectable.dec22 + allF2NonCollectable.dec22 + allF3NonCollectable.dec22,
    ]
},{
    name: 'Claim',
    data: [
        allF1ClaimCount.oct22 + allF2ClaimCount.oct22 + allF3ClaimCount.oct22,
        allF1ClaimCount.nov22 + allF2ClaimCount.nov22 + allF3ClaimCount.nov22,
        allF1ClaimCount.dec22 + allF2ClaimCount.dec22 + allF3ClaimCount.dec22,
    ]
},{
    name: 'Average Per Visits',
    data: [
        allProviderPervisitRX[21],
        allProviderPervisitRX[22],
        allProviderPervisitRX[23]
    ]
}]
// all 23 firth quarter 
export const firQuarOf23InAll = [{
    name: 'Charges',
    data: [
        allF1Charge.jan23 + allF2Charge.jan23 + allF3Charge.jan23,
        allF1Charge.feb23 + allF2Charge.feb23 + allF3Charge.feb23,
        allF1Charge.mar23 + allF2Charge.mar23 + allF3Charge.mar23,

    ]
},{
    name: 'Payment',
    data: [
        allF1Payment.jan23 + allF2Payment.jan23 + allF3Payment.jan23,
        allF1Payment.feb23 + allF2Payment.feb23 + allF3Payment.feb23,
        allF1Payment.mar23 + allF2Payment.mar23 + allF3Payment.mar23,
    ]
},{
    name: 'Collectable',
    data: [
        allF1Collectable.jan23 + allF2Collectable.jan23 + allF3Collectable.jan23,
        allF1Collectable.feb23 + allF2Collectable.feb23 + allF3Collectable.feb23,
        allF1Collectable.mar23 + allF2Collectable.mar23 + allF3Collectable.mar23,
    ]
},{
    name: 'Non Collectable',
    data: [
        allF1NonCollectable.jan23 + allF2NonCollectable.jan23 + allF3NonCollectable.jan23,
        allF1NonCollectable.feb23 + allF2NonCollectable.feb23 + allF3NonCollectable.feb23,
        allF1NonCollectable.mar23 + allF2NonCollectable.mar23 + allF3NonCollectable.mar23,
    ]
},{
    name: 'Claim',
    data: [
        allF1ClaimCount.jan23 + allF2ClaimCount.jan23 + allF3ClaimCount.jan23,
        allF1ClaimCount.feb23 + allF2ClaimCount.feb23 + allF3ClaimCount.feb23,
        allF1ClaimCount.mar23 + allF2ClaimCount.mar23 + allF3ClaimCount.mar23,
    ]
},{
    name: 'Average Per Visits',
    data: [
        allProviderPervisitRX[24],
        allProviderPervisitRX[25],
        allProviderPervisitRX[26]
    ]
}]
// all 23 second quarter 
export const secQuarOf23InAll = [{
    name: 'Charges',
    data: [
        allF1Charge.apr23 + allF2Charge.apr23 + allF3Charge.apr23,
        allF1Charge.may23 + allF2Charge.may23 + allF3Charge.may23,
        allF1Charge.jun23 + allF2Charge.jun23 + allF3Charge.jun23,

    ]
},{
    name: 'Payment',
    data: [
        allF1Payment.apr23 + allF2Payment.apr23 + allF3Payment.apr23,
        allF1Payment.may23 + allF2Payment.may23 + allF3Payment.may23,
        allF1Payment.jun23 + allF2Payment.jun23 + allF3Payment.jun23,
    ]
},{
    name: 'Collectable',
    data: [
        allF1Collectable.apr23 + allF2Collectable.apr23 + allF3Collectable.apr23,
        allF1Collectable.may23 + allF2Collectable.may23 + allF3Collectable.may23,
        allF1Collectable.jun23 + allF2Collectable.jun23 + allF3Collectable.jun23,
    ]
},{
    name: 'Non Collectable',
    data: [
        allF1NonCollectable.apr23 + allF2NonCollectable.apr23 + allF3NonCollectable.apr23,
        allF1NonCollectable.may23 + allF2NonCollectable.may23 + allF3NonCollectable.may23,
        allF1NonCollectable.jun23 + allF2NonCollectable.jun23 + allF3NonCollectable.jun23,
    ]
},{
    name: 'Claim',
    data: [
        allF1ClaimCount.apr23 + allF2ClaimCount.apr23 + allF3ClaimCount.apr23,
        allF1ClaimCount.may23 + allF2ClaimCount.may23 + allF3ClaimCount.may23,
        allF1ClaimCount.jun23 + allF2ClaimCount.jun23 + allF3ClaimCount.jun23,
    ]
},{
    name: 'Average Per Visits',
    data: [
        allProviderPervisitRX[27],
        allProviderPervisitRX[28],
        allProviderPervisitRX[29]
    ]
}]
// all 23 third quarter 
export const thiQuarOf23InAll = [{
    name: 'Charges',
    data: [
        allF1Charge.jul23 + allF2Charge.jul23 + allF3Charge.jul23,
        allF1Charge.aug23 + allF2Charge.aug23 + allF3Charge.aug23,
        allF1Charge.sep23 + allF2Charge.sep23 + allF3Charge.sep23,

    ]
},{
    name: 'Payment',
    data: [
        allF1Payment.jul23 + allF2Payment.jul23 + allF3Payment.jul23,
        allF1Payment.aug23 + allF2Payment.aug23 + allF3Payment.aug23,
        allF1Payment.sep23 + allF2Payment.sep23 + allF3Payment.sep23,
    ]
},{
    name: 'Collectable',
    data: [
        allF1Collectable.jul23 + allF2Collectable.jul23 + allF3Collectable.jul23,
        allF1Collectable.aug23 + allF2Collectable.aug23 + allF3Collectable.aug23,
        allF1Collectable.sep23 + allF2Collectable.sep23 + allF3Collectable.sep23,
    ]
},{
    name: 'Non Collectable',
    data: [
        allF1NonCollectable.jul23 + allF2NonCollectable.jul23 + allF3NonCollectable.jul23,
        allF1NonCollectable.aug23 + allF2NonCollectable.aug23 + allF3NonCollectable.aug23,
        allF1NonCollectable.sep23 + allF2NonCollectable.sep23 + allF3NonCollectable.sep23,
    ]
},{
    name: 'Claim',
    data: [
        allF1ClaimCount.jul23 + allF2ClaimCount.jul23 + allF3ClaimCount.jul23,
        allF1ClaimCount.aug23 + allF2ClaimCount.aug23 + allF3ClaimCount.aug23,
        allF1ClaimCount.sep23 + allF2ClaimCount.sep23 + allF3ClaimCount.sep23,
    ]
},{
    name: 'Average Per Visits',
    data: [
        allProviderPervisitRX[30],
        allProviderPervisitRX[31],
        allProviderPervisitRX[32]
    ]
}]
// all 23 fourth quarter 
export const fouQuarOf23InAll = [{
    name: 'Charges',
    data: [
        allF1Charge.oct23 + allF2Charge.oct23 + allF3Charge.oct23,
        allF1Charge.nov23 + allF2Charge.nov23 + allF3Charge.nov23,
        0,

    ]
},{
    name: 'Payment',
    data: [
        allF1Payment.oct23 + allF2Payment.oct23 + allF3Payment.oct23,
        allF1Payment.nov23 + allF2Payment.nov23 + allF3Payment.nov23,
        0,
    ]
},{
    name: 'Collectable',
    data: [
        allF1Collectable.oct23 + allF2Collectable.oct23 + allF3Collectable.oct23,
        allF1Collectable.nov23 + allF2Collectable.nov23 + allF3Collectable.nov23,
        0,
    ]
},{
    name: 'Non Collectable',
    data: [
        allF1NonCollectable.oct23 + allF2NonCollectable.oct23 + allF3NonCollectable.oct23,
        allF1NonCollectable.nov23 + allF2NonCollectable.nov23 + allF3NonCollectable.nov23,
        0,
    ]
},{
    name: 'Claim',
    data: [
        allF1ClaimCount.oct23 + allF2ClaimCount.oct23 + allF3ClaimCount.oct23,
        allF1ClaimCount.nov23 + allF2ClaimCount.nov23 + allF3ClaimCount.nov23,
        0,
    ]
},{
    name: 'Average Per Visits',
    data: [
        allProviderPervisitRX[33],
        allProviderPervisitRX[34],
        0,
    ]
}]



// pro-1 21 firth quarter
export const firQuarOf21InPro1 = [{
    name: 'Charges',
    data: [
        pro1F1Charge.jan21 + pro1F2Charge.jan21 + pro1F3Charge.jan21,
        pro1F1Charge.feb21 + pro1F2Charge.feb21 + pro1F3Charge.feb21,
        pro1F1Charge.mar21 + pro1F2Charge.mar21 + pro1F3Charge.mar21,

    ]
},{
    name: 'Payment',
    data: [
        pro1F1Payment.jan21 + pro1F2Payment.jan21 + pro1F3Payment.jan21,
        pro1F1Payment.feb21 + pro1F2Payment.feb21 + pro1F3Payment.feb21,
        pro1F1Payment.mar21 + pro1F2Payment.mar21 + pro1F3Payment.mar21,
    ]
},{
    name: 'Collectable',
    data: [
        pro1F1Collectable.jan21 + pro1F2Collectable.jan21 + pro1F3Collectable.jan21,
        pro1F1Collectable.feb21 + pro1F2Collectable.feb21 + pro1F3Collectable.feb21,
        pro1F1Collectable.mar21 + pro1F2Collectable.mar21 + pro1F3Collectable.mar21,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro1F1NonCollectable.jan21 + pro1F2NonCollectable.jan21 + pro1F3NonCollectable.jan21,
        pro1F1NonCollectable.feb21 + pro1F2NonCollectable.feb21 + pro1F3NonCollectable.feb21,
        pro1F1NonCollectable.mar21 + pro1F2NonCollectable.mar21 + pro1F3NonCollectable.mar21,
    ]
},{
    name: 'Claim',
    data: [
        pro1F1ClaimCount.jan21 + pro1F2ClaimCount.jan21 + pro1F3ClaimCount.jan21,
        pro1F1ClaimCount.feb21 + pro1F2ClaimCount.feb21 + pro1F3ClaimCount.feb21,
        pro1F1ClaimCount.mar21 + pro1F2ClaimCount.mar21 + pro1F3ClaimCount.mar21,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider1PerVisist[0],
        provider1PerVisist[1],
        provider1PerVisist[2]
    ]
}]
// pro-1 21 sec quarter 
export const secQuarOf21InPro1 = [{
    name: 'Charges',
    data: [
        pro1F1Charge.apr21 + pro1F2Charge.apr21 + pro1F3Charge.apr21,
        pro1F1Charge.may21 + pro1F2Charge.may21 + pro1F3Charge.may21,
        pro1F1Charge.jun21 + pro1F2Charge.jun21 + pro1F3Charge.jun21,

    ]
},{
    name: 'Payment',
    data: [
        pro1F1Payment.apr21 + pro1F2Payment.apr21 + pro1F3Payment.apr21,
        pro1F1Payment.may21 + pro1F2Payment.may21 + pro1F3Payment.may21,
        pro1F1Payment.jun21 + pro1F2Payment.jun21 + pro1F3Payment.jun21,
    ]
},{
    name: 'Collectable',
    data: [
        pro1F1Collectable.apr21 + pro1F2Collectable.apr21 + pro1F3Collectable.apr21,
        pro1F1Collectable.may21 + pro1F2Collectable.may21 + pro1F3Collectable.may21,
        pro1F1Collectable.jun21 + pro1F2Collectable.jun21 + pro1F3Collectable.jun21,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro1F1NonCollectable.apr21 + pro1F2NonCollectable.apr21 + pro1F3NonCollectable.apr21,
        pro1F1NonCollectable.may21 + pro1F2NonCollectable.may21 + pro1F3NonCollectable.may21,
        pro1F1NonCollectable.jun21 + pro1F2NonCollectable.jun21 + pro1F3NonCollectable.jun21,
    ]
},{
    name: 'Claim',
    data: [
        pro1F1ClaimCount.apr21 + pro1F2ClaimCount.apr21 + pro1F3ClaimCount.apr21,
        pro1F1ClaimCount.may21 + pro1F2ClaimCount.may21 + pro1F3ClaimCount.may21,
        pro1F1ClaimCount.jun21 + pro1F2ClaimCount.jun21 + pro1F3ClaimCount.jun21,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider1PerVisist[3],
        provider1PerVisist[4],
        provider1PerVisist[5]
    ]
}]
// pro-1 21 third quarter 
export const thiQuarOf21InPro1 = [{
    name: 'Charges',
    data: [
        pro1F1Charge.jul21 + pro1F2Charge.jul21 + pro1F3Charge.jul21,
        pro1F1Charge.aug21 + pro1F2Charge.aug21 + pro1F3Charge.aug21,
        pro1F1Charge.sep21 + pro1F2Charge.sep21 + pro1F3Charge.sep21,

    ]
},{
    name: 'Payment',
    data: [
        pro1F1Payment.jul21 + pro1F2Payment.jul21 + pro1F3Payment.jul21,
        pro1F1Payment.aug21 + pro1F2Payment.aug21 + pro1F3Payment.aug21,
        pro1F1Payment.sep21 + pro1F2Payment.sep21 + pro1F3Payment.sep21,
    ]
},{
    name: 'Collectable',
    data: [
        pro1F1Collectable.jul21 + pro1F2Collectable.jul21 + pro1F3Collectable.jul21,
        pro1F1Collectable.aug21 + pro1F2Collectable.aug21 + pro1F3Collectable.aug21,
        pro1F1Collectable.sep21 + pro1F2Collectable.sep21 + pro1F3Collectable.sep21,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro1F1NonCollectable.jul21 + pro1F2NonCollectable.jul21 + pro1F3NonCollectable.jul21,
        pro1F1NonCollectable.aug21 + pro1F2NonCollectable.aug21 + pro1F3NonCollectable.aug21,
        pro1F1NonCollectable.sep21 + pro1F2NonCollectable.sep21 + pro1F3NonCollectable.sep21,
    ]
},{
    name: 'Claim',
    data: [
        pro1F1ClaimCount.jul21 + pro1F2ClaimCount.jul21 + pro1F3ClaimCount.jul21,
        pro1F1ClaimCount.aug21 + pro1F2ClaimCount.aug21 + pro1F3ClaimCount.aug21,
        pro1F1ClaimCount.sep21 + pro1F2ClaimCount.sep21 + pro1F3ClaimCount.sep21,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider1PerVisist[6],
        provider1PerVisist[7],
        provider1PerVisist[8]
    ]
}]
// pro-1 21 fourth quarter 
export const fouQuarOf21InPro1 = [{
    name: 'Charges',
    data: [
        pro1F1Charge.oct21 + pro1F2Charge.oct21 + pro1F3Charge.oct21,
        pro1F1Charge.nov21 + pro1F2Charge.nov21 + pro1F3Charge.nov21,
        pro1F1Charge.dec21 + pro1F2Charge.dec21 + pro1F3Charge.dec21,

    ]
},{
    name: 'Payment',
    data: [
        pro1F1Payment.oct21 + pro1F2Payment.oct21 + pro1F3Payment.oct21,
        pro1F1Payment.nov21 + pro1F2Payment.nov21 + pro1F3Payment.nov21,
        pro1F1Payment.dec21 + pro1F2Payment.dec21 + pro1F3Payment.dec21,
    ]
},{
    name: 'Collectable',
    data: [
        pro1F1Collectable.oct21 + pro1F2Collectable.oct21 + pro1F3Collectable.oct21,
        pro1F1Collectable.nov21 + pro1F2Collectable.nov21 + pro1F3Collectable.nov21,
        pro1F1Collectable.dec21 + pro1F2Collectable.dec21 + pro1F3Collectable.dec21,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro1F1NonCollectable.oct21 + pro1F2NonCollectable.oct21 + pro1F3NonCollectable.oct21,
        pro1F1NonCollectable.nov21 + pro1F2NonCollectable.nov21 + pro1F3NonCollectable.nov21,
        pro1F1NonCollectable.dec21 + pro1F2NonCollectable.dec21 + pro1F3NonCollectable.dec21,
    ]
},{
    name: 'Claim',
    data: [
        pro1F1ClaimCount.oct21 + pro1F2ClaimCount.oct21 + pro1F3ClaimCount.oct21,
        pro1F1ClaimCount.nov21 + pro1F2ClaimCount.nov21 + pro1F3ClaimCount.nov21,
        pro1F1ClaimCount.dec21 + pro1F2ClaimCount.dec21 + pro1F3ClaimCount.dec21,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider1PerVisist[9],
        provider1PerVisist[10],
        provider1PerVisist[11]
    ]
}]
// pro-1 22 firth quarter 
export const firQuarOf22InPro1 = [{
    name: 'Charges',
    data: [
        pro1F1Charge.jan22 + pro1F2Charge.jan22 + pro1F3Charge.jan22,
        pro1F1Charge.feb22 + pro1F2Charge.feb22 + pro1F3Charge.feb22,
        pro1F1Charge.mar22 + pro1F2Charge.mar22 + pro1F3Charge.mar22,

    ]
},{
    name: 'Payment',
    data: [
        pro1F1Payment.jan22 + pro1F2Payment.jan22 + pro1F3Payment.jan22,
        pro1F1Payment.feb22 + pro1F2Payment.feb22 + pro1F3Payment.feb22,
        pro1F1Payment.mar22 + pro1F2Payment.mar22 + pro1F3Payment.mar22,
    ]
},{
    name: 'Collectable',
    data: [
        pro1F1Collectable.jan22 + pro1F2Collectable.jan22 + pro1F3Collectable.jan22,
        pro1F1Collectable.feb22 + pro1F2Collectable.feb22 + pro1F3Collectable.feb22,
        pro1F1Collectable.mar22 + pro1F2Collectable.mar22 + pro1F3Collectable.mar22,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro1F1NonCollectable.jan22 + pro1F2NonCollectable.jan22 + pro1F3NonCollectable.jan22,
        pro1F1NonCollectable.feb22 + pro1F2NonCollectable.feb22 + pro1F3NonCollectable.feb22,
        pro1F1NonCollectable.mar22 + pro1F2NonCollectable.mar22 + pro1F3NonCollectable.mar22,
    ]
},{
    name: 'Claim',
    data: [
        pro1F1ClaimCount.jan22 + pro1F2ClaimCount.jan22 + pro1F3ClaimCount.jan22,
        pro1F1ClaimCount.feb22 + pro1F2ClaimCount.feb22 + pro1F3ClaimCount.feb22,
        pro1F1ClaimCount.mar22 + pro1F2ClaimCount.mar22 + pro1F3ClaimCount.mar22,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider1PerVisist[12],
        provider1PerVisist[13],
        provider1PerVisist[14]
    ]
}]
// pro-1 22 second quarter 
export const secQuarOf22InPro1 = [{
    name: 'Charges',
    data: [
        pro1F1Charge.apr22 + pro1F2Charge.apr22 + pro1F3Charge.apr22,
        pro1F1Charge.may22 + pro1F2Charge.may22 + pro1F3Charge.may22,
        pro1F1Charge.jun22 + pro1F2Charge.jun22 + pro1F3Charge.jun22,

    ]
},{
    name: 'Payment',
    data: [
        pro1F1Payment.apr22 + pro1F2Payment.apr22 + pro1F3Payment.apr22,
        pro1F1Payment.may22 + pro1F2Payment.may22 + pro1F3Payment.may22,
        pro1F1Payment.jun22 + pro1F2Payment.jun22 + pro1F3Payment.jun22,
    ]
},{
    name: 'Collectable',
    data: [
        pro1F1Collectable.apr22 + pro1F2Collectable.apr22 + pro1F3Collectable.apr22,
        pro1F1Collectable.may22 + pro1F2Collectable.may22 + pro1F3Collectable.may22,
        pro1F1Collectable.jun22 + pro1F2Collectable.jun22 + pro1F3Collectable.jun22,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro1F1NonCollectable.apr22 + pro1F2NonCollectable.apr22 + pro1F3NonCollectable.apr22,
        pro1F1NonCollectable.may22 + pro1F2NonCollectable.may22 + pro1F3NonCollectable.may22,
        pro1F1NonCollectable.jun22 + pro1F2NonCollectable.jun22 + pro1F3NonCollectable.jun22,
    ]
},{
    name: 'Claim',
    data: [
        pro1F1ClaimCount.apr22 + pro1F2ClaimCount.apr22 + pro1F3ClaimCount.apr22,
        pro1F1ClaimCount.may22 + pro1F2ClaimCount.may22 + pro1F3ClaimCount.may22,
        pro1F1ClaimCount.jun22 + pro1F2ClaimCount.jun22 + pro1F3ClaimCount.jun22,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider1PerVisist[15],
        provider1PerVisist[16],
        provider1PerVisist[17]
    ]
}]
// pro-1 22 third quarter 
export const thiQuarOf22InPro1 = [{
    name: 'Charges',
    data: [
        pro1F1Charge.jul22 + pro1F2Charge.jul22 + pro1F3Charge.jul22,
        pro1F1Charge.aug22 + pro1F2Charge.aug22 + pro1F3Charge.aug22,
        pro1F1Charge.sep22 + pro1F2Charge.sep22 + pro1F3Charge.sep22,

    ]
},{
    name: 'Payment',
    data: [
        pro1F1Payment.jul22 + pro1F2Payment.jul22 + pro1F3Payment.jul22,
        pro1F1Payment.aug22 + pro1F2Payment.aug22 + pro1F3Payment.aug22,
        pro1F1Payment.sep22 + pro1F2Payment.sep22 + pro1F3Payment.sep22,
    ]
},{
    name: 'Collectable',
    data: [
        pro1F1Collectable.jul22 + pro1F2Collectable.jul22 + pro1F3Collectable.jul22,
        pro1F1Collectable.aug22 + pro1F2Collectable.aug22 + pro1F3Collectable.aug22,
        pro1F1Collectable.sep22 + pro1F2Collectable.sep22 + pro1F3Collectable.sep22,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro1F1NonCollectable.jul22 + pro1F2NonCollectable.jul22 + pro1F3NonCollectable.jul22,
        pro1F1NonCollectable.aug22 + pro1F2NonCollectable.aug22 + pro1F3NonCollectable.aug22,
        pro1F1NonCollectable.sep22 + pro1F2NonCollectable.sep22 + pro1F3NonCollectable.sep22,
    ]
},{
    name: 'Claim',
    data: [
        pro1F1ClaimCount.jul22 + pro1F2ClaimCount.jul22 + pro1F3ClaimCount.jul22,
        pro1F1ClaimCount.aug22 + pro1F2ClaimCount.aug22 + pro1F3ClaimCount.aug22,
        pro1F1ClaimCount.sep22 + pro1F2ClaimCount.sep22 + pro1F3ClaimCount.sep22,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider1PerVisist[18],
        provider1PerVisist[19],
        provider1PerVisist[20]
    ]
}]
// pro-1 22 fourth quarter 
export const fouQuarOf22InPro1 = [{
    name: 'Charges',
    data: [
        pro1F1Charge.oct22 + pro1F2Charge.oct22 + pro1F3Charge.oct22,
        pro1F1Charge.nov22 + pro1F2Charge.nov22 + pro1F3Charge.nov22,
        pro1F1Charge.dec22 + pro1F2Charge.dec22 + pro1F3Charge.dec22,

    ]
},{
    name: 'Payment',
    data: [
        pro1F1Payment.oct22 + pro1F2Payment.oct22 + pro1F3Payment.oct22,
        pro1F1Payment.nov22 + pro1F2Payment.nov22 + pro1F3Payment.nov22,
        pro1F1Payment.dec22 + pro1F2Payment.dec22 + pro1F3Payment.dec22,
    ]
},{
    name: 'Collectable',
    data: [
        pro1F1Collectable.oct22 + pro1F2Collectable.oct22 + pro1F3Collectable.oct22,
        pro1F1Collectable.nov22 + pro1F2Collectable.nov22 + pro1F3Collectable.nov22,
        pro1F1Collectable.dec22 + pro1F2Collectable.dec22 + pro1F3Collectable.dec22,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro1F1NonCollectable.oct22 + pro1F2NonCollectable.oct22 + pro1F3NonCollectable.oct22,
        pro1F1NonCollectable.nov22 + pro1F2NonCollectable.nov22 + pro1F3NonCollectable.nov22,
        pro1F1NonCollectable.dec22 + pro1F2NonCollectable.dec22 + pro1F3NonCollectable.dec22,
    ]
},{
    name: 'Claim',
    data: [
        pro1F1ClaimCount.oct22 + pro1F2ClaimCount.oct22 + pro1F3ClaimCount.oct22,
        pro1F1ClaimCount.nov22 + pro1F2ClaimCount.nov22 + pro1F3ClaimCount.nov22,
        pro1F1ClaimCount.dec22 + pro1F2ClaimCount.dec22 + pro1F3ClaimCount.dec22,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider1PerVisist[21],
        provider1PerVisist[22],
        provider1PerVisist[23]
    ]
}]
// pro-1 23 firth quarter 
export const firQuarOf23InPro1 = [{
    name: 'Charges',
    data: [
        pro1F1Charge.jan23 + pro1F2Charge.jan23 + pro1F3Charge.jan23,
        pro1F1Charge.feb23 + pro1F2Charge.feb23 + pro1F3Charge.feb23,
        pro1F1Charge.mar23 + pro1F2Charge.mar23 + pro1F3Charge.mar23,

    ]
},{
    name: 'Payment',
    data: [
        pro1F1Payment.jan23 + pro1F2Payment.jan23 + pro1F3Payment.jan23,
        pro1F1Payment.feb23 + pro1F2Payment.feb23 + pro1F3Payment.feb23,
        pro1F1Payment.mar23 + pro1F2Payment.mar23 + pro1F3Payment.mar23,
    ]
},{
    name: 'Collectable',
    data: [
        pro1F1Collectable.jan23 + pro1F2Collectable.jan23 + pro1F3Collectable.jan23,
        pro1F1Collectable.feb23 + pro1F2Collectable.feb23 + pro1F3Collectable.feb23,
        pro1F1Collectable.mar23 + pro1F2Collectable.mar23 + pro1F3Collectable.mar23,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro1F1NonCollectable.jan23 + pro1F2NonCollectable.jan23 + pro1F3NonCollectable.jan23,
        pro1F1NonCollectable.feb23 + pro1F2NonCollectable.feb23 + pro1F3NonCollectable.feb23,
        pro1F1NonCollectable.mar23 + pro1F2NonCollectable.mar23 + pro1F3NonCollectable.mar23,
    ]
},{
    name: 'Claim',
    data: [
        pro1F1ClaimCount.jan23 + pro1F2ClaimCount.jan23 + pro1F3ClaimCount.jan23,
        pro1F1ClaimCount.feb23 + pro1F2ClaimCount.feb23 + pro1F3ClaimCount.feb23,
        pro1F1ClaimCount.mar23 + pro1F2ClaimCount.mar23 + pro1F3ClaimCount.mar23,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider1PerVisist[24],
        provider1PerVisist[25],
        provider1PerVisist[26]
    ]
}]
// pro-1 23 second quarter 
export const secQuarOf23InPro1 = [{
    name: 'Charges',
    data: [
        pro1F1Charge.apr23 + pro1F2Charge.apr23 + pro1F3Charge.apr23,
        pro1F1Charge.may23 + pro1F2Charge.may23 + pro1F3Charge.may23,
        pro1F1Charge.jun23 + pro1F2Charge.jun23 + pro1F3Charge.jun23,

    ]
},{
    name: 'Payment',
    data: [
        pro1F1Payment.apr23 + pro1F2Payment.apr23 + pro1F3Payment.apr23,
        pro1F1Payment.may23 + pro1F2Payment.may23 + pro1F3Payment.may23,
        pro1F1Payment.jun23 + pro1F2Payment.jun23 + pro1F3Payment.jun23,
    ]
},{
    name: 'Collectable',
    data: [
        pro1F1Collectable.apr23 + pro1F2Collectable.apr23 + pro1F3Collectable.apr23,
        pro1F1Collectable.may23 + pro1F2Collectable.may23 + pro1F3Collectable.may23,
        pro1F1Collectable.jun23 + pro1F2Collectable.jun23 + pro1F3Collectable.jun23,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro1F1NonCollectable.apr23 + pro1F2NonCollectable.apr23 + pro1F3NonCollectable.apr23,
        pro1F1NonCollectable.may23 + pro1F2NonCollectable.may23 + pro1F3NonCollectable.may23,
        pro1F1NonCollectable.jun23 + pro1F2NonCollectable.jun23 + pro1F3NonCollectable.jun23,
    ]
},{
    name: 'Claim',
    data: [
        pro1F1ClaimCount.apr23 + pro1F2ClaimCount.apr23 + pro1F3ClaimCount.apr23,
        pro1F1ClaimCount.may23 + pro1F2ClaimCount.may23 + pro1F3ClaimCount.may23,
        pro1F1ClaimCount.jun23 + pro1F2ClaimCount.jun23 + pro1F3ClaimCount.jun23,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider1PerVisist[27],
        provider1PerVisist[28],
        provider1PerVisist[29]
    ]
}]
// pro-1 23 third quarter 
export const thiQuarOf23InPro1 = [{
    name: 'Charges',
    data: [
        pro1F1Charge.jul23 + pro1F2Charge.jul23 + pro1F3Charge.jul23,
        pro1F1Charge.aug23 + pro1F2Charge.aug23 + pro1F3Charge.aug23,
        pro1F1Charge.sep23 + pro1F2Charge.sep23 + pro1F3Charge.sep23,

    ]
},{
    name: 'Payment',
    data: [
        pro1F1Payment.jul23 + pro1F2Payment.jul23 + pro1F3Payment.jul23,
        pro1F1Payment.aug23 + pro1F2Payment.aug23 + pro1F3Payment.aug23,
        pro1F1Payment.sep23 + pro1F2Payment.sep23 + pro1F3Payment.sep23,
    ]
},{
    name: 'Collectable',
    data: [
        pro1F1Collectable.jul23 + pro1F2Collectable.jul23 + pro1F3Collectable.jul23,
        pro1F1Collectable.aug23 + pro1F2Collectable.aug23 + pro1F3Collectable.aug23,
        pro1F1Collectable.sep23 + pro1F2Collectable.sep23 + pro1F3Collectable.sep23,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro1F1NonCollectable.jul23 + pro1F2NonCollectable.jul23 + pro1F3NonCollectable.jul23,
        pro1F1NonCollectable.aug23 + pro1F2NonCollectable.aug23 + pro1F3NonCollectable.aug23,
        pro1F1NonCollectable.sep23 + pro1F2NonCollectable.sep23 + pro1F3NonCollectable.sep23,
    ]
},{
    name: 'Claim',
    data: [
        pro1F1ClaimCount.jul23 + pro1F2ClaimCount.jul23 + pro1F3ClaimCount.jul23,
        pro1F1ClaimCount.aug23 + pro1F2ClaimCount.aug23 + pro1F3ClaimCount.aug23,
        pro1F1ClaimCount.sep23 + pro1F2ClaimCount.sep23 + pro1F3ClaimCount.sep23,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider1PerVisist[30],
        provider1PerVisist[31],
        provider1PerVisist[32]
    ]
}]
// pro-1 23 fourth quarter 
export const fouQuarOf23InPro1 = [{
    name: 'Charges',
    data: [
        pro1F1Charge.oct23 + pro1F2Charge.oct23 + pro1F3Charge.oct23,
        pro1F1Charge.nov23 + pro1F2Charge.nov23 + pro1F3Charge.nov23,
        0,

    ]
},{
    name: 'Payment',
    data: [
        pro1F1Payment.oct23 + pro1F2Payment.oct23 + pro1F3Payment.oct23,
        pro1F1Payment.nov23 + pro1F2Payment.nov23 + pro1F3Payment.nov23,
        0,
    ]
},{
    name: 'Collectable',
    data: [
        pro1F1Collectable.oct23 + pro1F2Collectable.oct23 + pro1F3Collectable.oct23,
        pro1F1Collectable.nov23 + pro1F2Collectable.nov23 + pro1F3Collectable.nov23,
        0,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro1F1NonCollectable.oct23 + pro1F2NonCollectable.oct23 + pro1F3NonCollectable.oct23,
        pro1F1NonCollectable.nov23 + pro1F2NonCollectable.nov23 + pro1F3NonCollectable.nov23,
        0,
    ]
},{
    name: 'Claim',
    data: [
        pro1F1ClaimCount.oct23 + pro1F2ClaimCount.oct23 + pro1F3ClaimCount.oct23,
        pro1F1ClaimCount.nov23 + pro1F2ClaimCount.nov23 + pro1F3ClaimCount.nov23,
        0,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider1PerVisist[33],
        provider1PerVisist[34],
        0,
    ]
}]




// pro-2 21 firth quarter
export const firQuarOf21InPro2 = [{
    name: 'Charges',
    data: [
        pro2F1Charge.jan21 + pro2F2Charge.jan21 + pro2F3Charge.jan21,
        pro2F1Charge.feb21 + pro2F2Charge.feb21 + pro2F3Charge.feb21,
        pro2F1Charge.mar21 + pro2F2Charge.mar21 + pro2F3Charge.mar21,

    ]
},{
    name: 'Payment',
    data: [
        pro2F1Payment.jan21 + pro2F2Payment.jan21 + pro2F3Payment.jan21,
        pro2F1Payment.feb21 + pro2F2Payment.feb21 + pro2F3Payment.feb21,
        pro2F1Payment.mar21 + pro2F2Payment.mar21 + pro2F3Payment.mar21,
    ]
},{
    name: 'Collectable',
    data: [
        pro2F1Collectable.jan21 + pro2F2Collectable.jan21 + pro2F3Collectable.jan21,
        pro2F1Collectable.feb21 + pro2F2Collectable.feb21 + pro2F3Collectable.feb21,
        pro2F1Collectable.mar21 + pro2F2Collectable.mar21 + pro2F3Collectable.mar21,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro2F1NonCollectable.jan21 + pro2F2NonCollectable.jan21 + pro2F3NonCollectable.jan21,
        pro2F1NonCollectable.feb21 + pro2F2NonCollectable.feb21 + pro2F3NonCollectable.feb21,
        pro2F1NonCollectable.mar21 + pro2F2NonCollectable.mar21 + pro2F3NonCollectable.mar21,
    ]
},{
    name: 'Claim',
    data: [
        pro2F1ClaimCount.jan21 + pro2F2ClaimCount.jan21 + pro2F3ClaimCount.jan21,
        pro2F1ClaimCount.feb21 + pro2F2ClaimCount.feb21 + pro2F3ClaimCount.feb21,
        pro2F1ClaimCount.mar21 + pro2F2ClaimCount.mar21 + pro2F3ClaimCount.mar21,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider2PerVisist[0],
        provider2PerVisist[1],
        provider2PerVisist[2]
    ]
}]
// pro-2 21 sec quarter 
export const secQuarOf21InPro2 = [{
    name: 'Charges',
    data: [
        pro2F1Charge.apr21 + pro2F2Charge.apr21 + pro2F3Charge.apr21,
        pro2F1Charge.may21 + pro2F2Charge.may21 + pro2F3Charge.may21,
        pro2F1Charge.jun21 + pro2F2Charge.jun21 + pro2F3Charge.jun21,

    ]
},{
    name: 'Payment',
    data: [
        pro2F1Payment.apr21 + pro2F2Payment.apr21 + pro2F3Payment.apr21,
        pro2F1Payment.may21 + pro2F2Payment.may21 + pro2F3Payment.may21,
        pro2F1Payment.jun21 + pro2F2Payment.jun21 + pro2F3Payment.jun21,
    ]
},{
    name: 'Collectable',
    data: [
        pro2F1Collectable.apr21 + pro2F2Collectable.apr21 + pro2F3Collectable.apr21,
        pro2F1Collectable.may21 + pro2F2Collectable.may21 + pro2F3Collectable.may21,
        pro2F1Collectable.jun21 + pro2F2Collectable.jun21 + pro2F3Collectable.jun21,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro2F1NonCollectable.apr21 + pro2F2NonCollectable.apr21 + pro2F3NonCollectable.apr21,
        pro2F1NonCollectable.may21 + pro2F2NonCollectable.may21 + pro2F3NonCollectable.may21,
        pro2F1NonCollectable.jun21 + pro2F2NonCollectable.jun21 + pro2F3NonCollectable.jun21,
    ]
},{
    name: 'Claim',
    data: [
        pro2F1ClaimCount.apr21 + pro2F2ClaimCount.apr21 + pro2F3ClaimCount.apr21,
        pro2F1ClaimCount.may21 + pro2F2ClaimCount.may21 + pro2F3ClaimCount.may21,
        pro2F1ClaimCount.jun21 + pro2F2ClaimCount.jun21 + pro2F3ClaimCount.jun21,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider2PerVisist[3],
        provider2PerVisist[4],
        provider2PerVisist[5]
    ]
}]
// pro-2 21 third quarter 
export const thiQuarOf21InPro2 = [{
    name: 'Charges',
    data: [
        pro2F1Charge.jul21 + pro2F2Charge.jul21 + pro2F3Charge.jul21,
        pro2F1Charge.aug21 + pro2F2Charge.aug21 + pro2F3Charge.aug21,
        pro2F1Charge.sep21 + pro2F2Charge.sep21 + pro2F3Charge.sep21,

    ]
},{
    name: 'Payment',
    data: [
        pro2F1Payment.jul21 + pro2F2Payment.jul21 + pro2F3Payment.jul21,
        pro2F1Payment.aug21 + pro2F2Payment.aug21 + pro2F3Payment.aug21,
        pro2F1Payment.sep21 + pro2F2Payment.sep21 + pro2F3Payment.sep21,
    ]
},{
    name: 'Collectable',
    data: [
        pro2F1Collectable.jul21 + pro2F2Collectable.jul21 + pro2F3Collectable.jul21,
        pro2F1Collectable.aug21 + pro2F2Collectable.aug21 + pro2F3Collectable.aug21,
        pro2F1Collectable.sep21 + pro2F2Collectable.sep21 + pro2F3Collectable.sep21,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro2F1NonCollectable.jul21 + pro2F2NonCollectable.jul21 + pro2F3NonCollectable.jul21,
        pro2F1NonCollectable.aug21 + pro2F2NonCollectable.aug21 + pro2F3NonCollectable.aug21,
        pro2F1NonCollectable.sep21 + pro2F2NonCollectable.sep21 + pro2F3NonCollectable.sep21,
    ]
},{
    name: 'Claim',
    data: [
        pro2F1ClaimCount.jul21 + pro2F2ClaimCount.jul21 + pro2F3ClaimCount.jul21,
        pro2F1ClaimCount.aug21 + pro2F2ClaimCount.aug21 + pro2F3ClaimCount.aug21,
        pro2F1ClaimCount.sep21 + pro2F2ClaimCount.sep21 + pro2F3ClaimCount.sep21,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider2PerVisist[6],
        provider2PerVisist[7],
        provider2PerVisist[8]
    ]
}]
// pro-2 21 fourth quarter 
export const fouQuarOf21InPro2 = [{
    name: 'Charges',
    data: [
        pro2F1Charge.oct21 + pro2F2Charge.oct21 + pro2F3Charge.oct21,
        pro2F1Charge.nov21 + pro2F2Charge.nov21 + pro2F3Charge.nov21,
        pro2F1Charge.dec21 + pro2F2Charge.dec21 + pro2F3Charge.dec21,

    ]
},{
    name: 'Payment',
    data: [
        pro2F1Payment.oct21 + pro2F2Payment.oct21 + pro2F3Payment.oct21,
        pro2F1Payment.nov21 + pro2F2Payment.nov21 + pro2F3Payment.nov21,
        pro2F1Payment.dec21 + pro2F2Payment.dec21 + pro2F3Payment.dec21,
    ]
},{
    name: 'Collectable',
    data: [
        pro2F1Collectable.oct21 + pro2F2Collectable.oct21 + pro2F3Collectable.oct21,
        pro2F1Collectable.nov21 + pro2F2Collectable.nov21 + pro2F3Collectable.nov21,
        pro2F1Collectable.dec21 + pro2F2Collectable.dec21 + pro2F3Collectable.dec21,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro2F1NonCollectable.oct21 + pro2F2NonCollectable.oct21 + pro2F3NonCollectable.oct21,
        pro2F1NonCollectable.nov21 + pro2F2NonCollectable.nov21 + pro2F3NonCollectable.nov21,
        pro2F1NonCollectable.dec21 + pro2F2NonCollectable.dec21 + pro2F3NonCollectable.dec21,
    ]
},{
    name: 'Claim',
    data: [
        pro2F1ClaimCount.oct21 + pro2F2ClaimCount.oct21 + pro2F3ClaimCount.oct21,
        pro2F1ClaimCount.nov21 + pro2F2ClaimCount.nov21 + pro2F3ClaimCount.nov21,
        pro2F1ClaimCount.dec21 + pro2F2ClaimCount.dec21 + pro2F3ClaimCount.dec21,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider2PerVisist[9],
        provider2PerVisist[10],
        provider2PerVisist[11]
    ]
}]
// pro-2 22 firth quarter 
export const firQuarOf22InPro2 = [{
    name: 'Charges',
    data: [
        pro2F1Charge.jan22 + pro2F2Charge.jan22 + pro2F3Charge.jan22,
        pro2F1Charge.feb22 + pro2F2Charge.feb22 + pro2F3Charge.feb22,
        pro2F1Charge.mar22 + pro2F2Charge.mar22 + pro2F3Charge.mar22,

    ]
},{
    name: 'Payment',
    data: [
        pro2F1Payment.jan22 + pro2F2Payment.jan22 + pro2F3Payment.jan22,
        pro2F1Payment.feb22 + pro2F2Payment.feb22 + pro2F3Payment.feb22,
        pro2F1Payment.mar22 + pro2F2Payment.mar22 + pro2F3Payment.mar22,
    ]
},{
    name: 'Collectable',
    data: [
        pro2F1Collectable.jan22 + pro2F2Collectable.jan22 + pro2F3Collectable.jan22,
        pro2F1Collectable.feb22 + pro2F2Collectable.feb22 + pro2F3Collectable.feb22,
        pro2F1Collectable.mar22 + pro2F2Collectable.mar22 + pro2F3Collectable.mar22,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro2F1NonCollectable.jan22 + pro2F2NonCollectable.jan22 + pro2F3NonCollectable.jan22,
        pro2F1NonCollectable.feb22 + pro2F2NonCollectable.feb22 + pro2F3NonCollectable.feb22,
        pro2F1NonCollectable.mar22 + pro2F2NonCollectable.mar22 + pro2F3NonCollectable.mar22,
    ]
},{
    name: 'Claim',
    data: [
        pro2F1ClaimCount.jan22 + pro2F2ClaimCount.jan22 + pro2F3ClaimCount.jan22,
        pro2F1ClaimCount.feb22 + pro2F2ClaimCount.feb22 + pro2F3ClaimCount.feb22,
        pro2F1ClaimCount.mar22 + pro2F2ClaimCount.mar22 + pro2F3ClaimCount.mar22,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider2PerVisist[12],
        provider2PerVisist[13],
        provider2PerVisist[14]
    ]
}]
// pro-2 22 second quarter 
export const secQuarOf22InPro2 = [{
    name: 'Charges',
    data: [
        pro2F1Charge.apr22 + pro2F2Charge.apr22 + pro2F3Charge.apr22,
        pro2F1Charge.may22 + pro2F2Charge.may22 + pro2F3Charge.may22,
        pro2F1Charge.jun22 + pro2F2Charge.jun22 + pro2F3Charge.jun22,

    ]
},{
    name: 'Payment',
    data: [
        pro2F1Payment.apr22 + pro2F2Payment.apr22 + pro2F3Payment.apr22,
        pro2F1Payment.may22 + pro2F2Payment.may22 + pro2F3Payment.may22,
        pro2F1Payment.jun22 + pro2F2Payment.jun22 + pro2F3Payment.jun22,
    ]
},{
    name: 'Collectable',
    data: [
        pro2F1Collectable.apr22 + pro2F2Collectable.apr22 + pro2F3Collectable.apr22,
        pro2F1Collectable.may22 + pro2F2Collectable.may22 + pro2F3Collectable.may22,
        pro2F1Collectable.jun22 + pro2F2Collectable.jun22 + pro2F3Collectable.jun22,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro2F1NonCollectable.apr22 + pro2F2NonCollectable.apr22 + pro2F3NonCollectable.apr22,
        pro2F1NonCollectable.may22 + pro2F2NonCollectable.may22 + pro2F3NonCollectable.may22,
        pro2F1NonCollectable.jun22 + pro2F2NonCollectable.jun22 + pro2F3NonCollectable.jun22,
    ]
},{
    name: 'Claim',
    data: [
        pro2F1ClaimCount.apr22 + pro2F2ClaimCount.apr22 + pro2F3ClaimCount.apr22,
        pro2F1ClaimCount.may22 + pro2F2ClaimCount.may22 + pro2F3ClaimCount.may22,
        pro2F1ClaimCount.jun22 + pro2F2ClaimCount.jun22 + pro2F3ClaimCount.jun22,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider2PerVisist[15],
        provider2PerVisist[16],
        provider2PerVisist[17]
    ]
}]
// pro-2 22 third quarter 
export const thiQuarOf22InPro2 = [{
    name: 'Charges',
    data: [
        pro2F1Charge.jul22 + pro2F2Charge.jul22 + pro2F3Charge.jul22,
        pro2F1Charge.aug22 + pro2F2Charge.aug22 + pro2F3Charge.aug22,
        pro2F1Charge.sep22 + pro2F2Charge.sep22 + pro2F3Charge.sep22,

    ]
},{
    name: 'Payment',
    data: [
        pro2F1Payment.jul22 + pro2F2Payment.jul22 + pro2F3Payment.jul22,
        pro2F1Payment.aug22 + pro2F2Payment.aug22 + pro2F3Payment.aug22,
        pro2F1Payment.sep22 + pro2F2Payment.sep22 + pro2F3Payment.sep22,
    ]
},{
    name: 'Collectable',
    data: [
        pro2F1Collectable.jul22 + pro2F2Collectable.jul22 + pro2F3Collectable.jul22,
        pro2F1Collectable.aug22 + pro2F2Collectable.aug22 + pro2F3Collectable.aug22,
        pro2F1Collectable.sep22 + pro2F2Collectable.sep22 + pro2F3Collectable.sep22,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro2F1NonCollectable.jul22 + pro2F2NonCollectable.jul22 + pro2F3NonCollectable.jul22,
        pro2F1NonCollectable.aug22 + pro2F2NonCollectable.aug22 + pro2F3NonCollectable.aug22,
        pro2F1NonCollectable.sep22 + pro2F2NonCollectable.sep22 + pro2F3NonCollectable.sep22,
    ]
},{
    name: 'Claim',
    data: [
        pro2F1ClaimCount.jul22 + pro2F2ClaimCount.jul22 + pro2F3ClaimCount.jul22,
        pro2F1ClaimCount.aug22 + pro2F2ClaimCount.aug22 + pro2F3ClaimCount.aug22,
        pro2F1ClaimCount.sep22 + pro2F2ClaimCount.sep22 + pro2F3ClaimCount.sep22,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider2PerVisist[18],
        provider2PerVisist[19],
        provider2PerVisist[20]
    ]
}]
// pro-2 22 fourth quarter 
export const fouQuarOf22InPro2 = [{
    name: 'Charges',
    data: [
        pro2F1Charge.oct22 + pro2F2Charge.oct22 + pro2F3Charge.oct22,
        pro2F1Charge.nov22 + pro2F2Charge.nov22 + pro2F3Charge.nov22,
        pro2F1Charge.dec22 + pro2F2Charge.dec22 + pro2F3Charge.dec22,

    ]
},{
    name: 'Payment',
    data: [
        pro2F1Payment.oct22 + pro2F2Payment.oct22 + pro2F3Payment.oct22,
        pro2F1Payment.nov22 + pro2F2Payment.nov22 + pro2F3Payment.nov22,
        pro2F1Payment.dec22 + pro2F2Payment.dec22 + pro2F3Payment.dec22,
    ]
},{
    name: 'Collectable',
    data: [
        pro2F1Collectable.oct22 + pro2F2Collectable.oct22 + pro2F3Collectable.oct22,
        pro2F1Collectable.nov22 + pro2F2Collectable.nov22 + pro2F3Collectable.nov22,
        pro2F1Collectable.dec22 + pro2F2Collectable.dec22 + pro2F3Collectable.dec22,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro2F1NonCollectable.oct22 + pro2F2NonCollectable.oct22 + pro2F3NonCollectable.oct22,
        pro2F1NonCollectable.nov22 + pro2F2NonCollectable.nov22 + pro2F3NonCollectable.nov22,
        pro2F1NonCollectable.dec22 + pro2F2NonCollectable.dec22 + pro2F3NonCollectable.dec22,
    ]
},{
    name: 'Claim',
    data: [
        pro2F1ClaimCount.oct22 + pro2F2ClaimCount.oct22 + pro2F3ClaimCount.oct22,
        pro2F1ClaimCount.nov22 + pro2F2ClaimCount.nov22 + pro2F3ClaimCount.nov22,
        pro2F1ClaimCount.dec22 + pro2F2ClaimCount.dec22 + pro2F3ClaimCount.dec22,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider2PerVisist[21],
        provider2PerVisist[22],
        provider2PerVisist[23]
    ]
}]
// pro-2 23 firth quarter 
export const firQuarOf23InPro2 = [{
    name: 'Charges',
    data: [
        pro2F1Charge.jan23 + pro2F2Charge.jan23 + pro2F3Charge.jan23,
        pro2F1Charge.feb23 + pro2F2Charge.feb23 + pro2F3Charge.feb23,
        pro2F1Charge.mar23 + pro2F2Charge.mar23 + pro2F3Charge.mar23,

    ]
},{
    name: 'Payment',
    data: [
        pro2F1Payment.jan23 + pro2F2Payment.jan23 + pro2F3Payment.jan23,
        pro2F1Payment.feb23 + pro2F2Payment.feb23 + pro2F3Payment.feb23,
        pro2F1Payment.mar23 + pro2F2Payment.mar23 + pro2F3Payment.mar23,
    ]
},{
    name: 'Collectable',
    data: [
        pro2F1Collectable.jan23 + pro2F2Collectable.jan23 + pro2F3Collectable.jan23,
        pro2F1Collectable.feb23 + pro2F2Collectable.feb23 + pro2F3Collectable.feb23,
        pro2F1Collectable.mar23 + pro2F2Collectable.mar23 + pro2F3Collectable.mar23,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro2F1NonCollectable.jan23 + pro2F2NonCollectable.jan23 + pro2F3NonCollectable.jan23,
        pro2F1NonCollectable.feb23 + pro2F2NonCollectable.feb23 + pro2F3NonCollectable.feb23,
        pro2F1NonCollectable.mar23 + pro2F2NonCollectable.mar23 + pro2F3NonCollectable.mar23,
    ]
},{
    name: 'Claim',
    data: [
        pro2F1ClaimCount.jan23 + pro2F2ClaimCount.jan23 + pro2F3ClaimCount.jan23,
        pro2F1ClaimCount.feb23 + pro2F2ClaimCount.feb23 + pro2F3ClaimCount.feb23,
        pro2F1ClaimCount.mar23 + pro2F2ClaimCount.mar23 + pro2F3ClaimCount.mar23,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider2PerVisist[24],
        provider2PerVisist[25],
        provider2PerVisist[26]
    ]
}]
// pro-2 23 second quarter 
export const secQuarOf23InPro2 = [{
    name: 'Charges',
    data: [
        pro2F1Charge.apr23 + pro2F2Charge.apr23 + pro2F3Charge.apr23,
        pro2F1Charge.may23 + pro2F2Charge.may23 + pro2F3Charge.may23,
        pro2F1Charge.jun23 + pro2F2Charge.jun23 + pro2F3Charge.jun23,

    ]
},{
    name: 'Payment',
    data: [
        pro2F1Payment.apr23 + pro2F2Payment.apr23 + pro2F3Payment.apr23,
        pro2F1Payment.may23 + pro2F2Payment.may23 + pro2F3Payment.may23,
        pro2F1Payment.jun23 + pro2F2Payment.jun23 + pro2F3Payment.jun23,
    ]
},{
    name: 'Collectable',
    data: [
        pro2F1Collectable.apr23 + pro2F2Collectable.apr23 + pro2F3Collectable.apr23,
        pro2F1Collectable.may23 + pro2F2Collectable.may23 + pro2F3Collectable.may23,
        pro2F1Collectable.jun23 + pro2F2Collectable.jun23 + pro2F3Collectable.jun23,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro2F1NonCollectable.apr23 + pro2F2NonCollectable.apr23 + pro2F3NonCollectable.apr23,
        pro2F1NonCollectable.may23 + pro2F2NonCollectable.may23 + pro2F3NonCollectable.may23,
        pro2F1NonCollectable.jun23 + pro2F2NonCollectable.jun23 + pro2F3NonCollectable.jun23,
    ]
},{
    name: 'Claim',
    data: [
        pro2F1ClaimCount.apr23 + pro2F2ClaimCount.apr23 + pro2F3ClaimCount.apr23,
        pro2F1ClaimCount.may23 + pro2F2ClaimCount.may23 + pro2F3ClaimCount.may23,
        pro2F1ClaimCount.jun23 + pro2F2ClaimCount.jun23 + pro2F3ClaimCount.jun23,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider2PerVisist[27],
        provider2PerVisist[28],
        provider2PerVisist[29]
    ]
}]
// pro-2 23 third quarter 
export const thiQuarOf23InPro2 = [{
    name: 'Charges',
    data: [
        pro2F1Charge.jul23 + pro2F2Charge.jul23 + pro2F3Charge.jul23,
        pro2F1Charge.aug23 + pro2F2Charge.aug23 + pro2F3Charge.aug23,
        pro2F1Charge.sep23 + pro2F2Charge.sep23 + pro2F3Charge.sep23,

    ]
},{
    name: 'Payment',
    data: [
        pro2F1Payment.jul23 + pro2F2Payment.jul23 + pro2F3Payment.jul23,
        pro2F1Payment.aug23 + pro2F2Payment.aug23 + pro2F3Payment.aug23,
        pro2F1Payment.sep23 + pro2F2Payment.sep23 + pro2F3Payment.sep23,
    ]
},{
    name: 'Collectable',
    data: [
        pro2F1Collectable.jul23 + pro2F2Collectable.jul23 + pro2F3Collectable.jul23,
        pro2F1Collectable.aug23 + pro2F2Collectable.aug23 + pro2F3Collectable.aug23,
        pro2F1Collectable.sep23 + pro2F2Collectable.sep23 + pro2F3Collectable.sep23,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro2F1NonCollectable.jul23 + pro2F2NonCollectable.jul23 + pro2F3NonCollectable.jul23,
        pro2F1NonCollectable.aug23 + pro2F2NonCollectable.aug23 + pro2F3NonCollectable.aug23,
        pro2F1NonCollectable.sep23 + pro2F2NonCollectable.sep23 + pro2F3NonCollectable.sep23,
    ]
},{
    name: 'Claim',
    data: [
        pro2F1ClaimCount.jul23 + pro2F2ClaimCount.jul23 + pro2F3ClaimCount.jul23,
        pro2F1ClaimCount.aug23 + pro2F2ClaimCount.aug23 + pro2F3ClaimCount.aug23,
        pro2F1ClaimCount.sep23 + pro2F2ClaimCount.sep23 + pro2F3ClaimCount.sep23,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider2PerVisist[30],
        provider2PerVisist[31],
        provider2PerVisist[32]
    ]
}]
// pro-2 23 fourth quarter 
export const fouQuarOf23InPro2 = [{
    name: 'Charges',
    data: [
        pro2F1Charge.oct23 + pro2F2Charge.oct23 + pro2F3Charge.oct23,
        pro2F1Charge.nov23 + pro2F2Charge.nov23 + pro2F3Charge.nov23,
        0,

    ]
},{
    name: 'Payment',
    data: [
        pro2F1Payment.oct23 + pro2F2Payment.oct23 + pro2F3Payment.oct23,
        pro2F1Payment.nov23 + pro2F2Payment.nov23 + pro2F3Payment.nov23,
        0,
    ]
},{
    name: 'Collectable',
    data: [
        pro2F1Collectable.oct23 + pro2F2Collectable.oct23 + pro2F3Collectable.oct23,
        pro2F1Collectable.nov23 + pro2F2Collectable.nov23 + pro2F3Collectable.nov23,
        0,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro2F1NonCollectable.oct23 + pro2F2NonCollectable.oct23 + pro2F3NonCollectable.oct23,
        pro2F1NonCollectable.nov23 + pro2F2NonCollectable.nov23 + pro2F3NonCollectable.nov23,
        0,
    ]
},{
    name: 'Claim',
    data: [
        pro2F1ClaimCount.oct23 + pro2F2ClaimCount.oct23 + pro2F3ClaimCount.oct23,
        pro2F1ClaimCount.nov23 + pro2F2ClaimCount.nov23 + pro2F3ClaimCount.nov23,
        0,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider2PerVisist[33],
        provider2PerVisist[34],
        0,
    ]
}]


// pro-3 21 firth quarter
export const firQuarOf21InPro3 = [{
    name: 'Charges',
    data: [
        pro3F1Charge.jan21 + pro3F2Charge.jan21 + pro3F3Charge.jan21,
        pro3F1Charge.feb21 + pro3F2Charge.feb21 + pro3F3Charge.feb21,
        pro3F1Charge.mar21 + pro3F2Charge.mar21 + pro3F3Charge.mar21,

    ]
},{
    name: 'Payment',
    data: [
        pro3F1Payment.jan21 + pro3F2Payment.jan21 + pro3F3Payment.jan21,
        pro3F1Payment.feb21 + pro3F2Payment.feb21 + pro3F3Payment.feb21,
        pro3F1Payment.mar21 + pro3F2Payment.mar21 + pro3F3Payment.mar21,
    ]
},{
    name: 'Collectable',
    data: [
        pro3F1Collectable.jan21 + pro3F2Collectable.jan21 + pro3F3Collectable.jan21,
        pro3F1Collectable.feb21 + pro3F2Collectable.feb21 + pro3F3Collectable.feb21,
        pro3F1Collectable.mar21 + pro3F2Collectable.mar21 + pro3F3Collectable.mar21,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro3F1NonCollectable.jan21 + pro3F2NonCollectable.jan21 + pro3F3NonCollectable.jan21,
        pro3F1NonCollectable.feb21 + pro3F2NonCollectable.feb21 + pro3F3NonCollectable.feb21,
        pro3F1NonCollectable.mar21 + pro3F2NonCollectable.mar21 + pro3F3NonCollectable.mar21,
    ]
},{
    name: 'Claim',
    data: [
        pro3F1ClaimCount.jan21 + pro3F2ClaimCount.jan21 + pro3F3ClaimCount.jan21,
        pro3F1ClaimCount.feb21 + pro3F2ClaimCount.feb21 + pro3F3ClaimCount.feb21,
        pro3F1ClaimCount.mar21 + pro3F2ClaimCount.mar21 + pro3F3ClaimCount.mar21,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider3PerVisist[0],
        provider3PerVisist[1],
        provider3PerVisist[2]
    ]
}]
// pro-3 21 sec quarter 
export const secQuarOf21InPro3 = [{
    name: 'Charges',
    data: [
        pro3F1Charge.apr21 + pro3F2Charge.apr21 + pro3F3Charge.apr21,
        pro3F1Charge.may21 + pro3F2Charge.may21 + pro3F3Charge.may21,
        pro3F1Charge.jun21 + pro3F2Charge.jun21 + pro3F3Charge.jun21,

    ]
},{
    name: 'Payment',
    data: [
        pro3F1Payment.apr21 + pro3F2Payment.apr21 + pro3F3Payment.apr21,
        pro3F1Payment.may21 + pro3F2Payment.may21 + pro3F3Payment.may21,
        pro3F1Payment.jun21 + pro3F2Payment.jun21 + pro3F3Payment.jun21,
    ]
},{
    name: 'Collectable',
    data: [
        pro3F1Collectable.apr21 + pro3F2Collectable.apr21 + pro3F3Collectable.apr21,
        pro3F1Collectable.may21 + pro3F2Collectable.may21 + pro3F3Collectable.may21,
        pro3F1Collectable.jun21 + pro3F2Collectable.jun21 + pro3F3Collectable.jun21,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro3F1NonCollectable.apr21 + pro3F2NonCollectable.apr21 + pro3F3NonCollectable.apr21,
        pro3F1NonCollectable.may21 + pro3F2NonCollectable.may21 + pro3F3NonCollectable.may21,
        pro3F1NonCollectable.jun21 + pro3F2NonCollectable.jun21 + pro3F3NonCollectable.jun21,
    ]
},{
    name: 'Claim',
    data: [
        pro3F1ClaimCount.apr21 + pro3F2ClaimCount.apr21 + pro3F3ClaimCount.apr21,
        pro3F1ClaimCount.may21 + pro3F2ClaimCount.may21 + pro3F3ClaimCount.may21,
        pro3F1ClaimCount.jun21 + pro3F2ClaimCount.jun21 + pro3F3ClaimCount.jun21,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider3PerVisist[3],
        provider3PerVisist[4],
        provider3PerVisist[5]
    ]
}]
// pro-3 21 third quarter 
export const thiQuarOf21InPro3 = [{
    name: 'Charges',
    data: [
        pro3F1Charge.jul21 + pro3F2Charge.jul21 + pro3F3Charge.jul21,
        pro3F1Charge.aug21 + pro3F2Charge.aug21 + pro3F3Charge.aug21,
        pro3F1Charge.sep21 + pro3F2Charge.sep21 + pro3F3Charge.sep21,

    ]
},{
    name: 'Payment',
    data: [
        pro3F1Payment.jul21 + pro3F2Payment.jul21 + pro3F3Payment.jul21,
        pro3F1Payment.aug21 + pro3F2Payment.aug21 + pro3F3Payment.aug21,
        pro3F1Payment.sep21 + pro3F2Payment.sep21 + pro3F3Payment.sep21,
    ]
},{
    name: 'Collectable',
    data: [
        pro3F1Collectable.jul21 + pro3F2Collectable.jul21 + pro3F3Collectable.jul21,
        pro3F1Collectable.aug21 + pro3F2Collectable.aug21 + pro3F3Collectable.aug21,
        pro3F1Collectable.sep21 + pro3F2Collectable.sep21 + pro3F3Collectable.sep21,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro3F1NonCollectable.jul21 + pro3F2NonCollectable.jul21 + pro3F3NonCollectable.jul21,
        pro3F1NonCollectable.aug21 + pro3F2NonCollectable.aug21 + pro3F3NonCollectable.aug21,
        pro3F1NonCollectable.sep21 + pro3F2NonCollectable.sep21 + pro3F3NonCollectable.sep21,
    ]
},{
    name: 'Claim',
    data: [
        pro3F1ClaimCount.jul21 + pro3F2ClaimCount.jul21 + pro3F3ClaimCount.jul21,
        pro3F1ClaimCount.aug21 + pro3F2ClaimCount.aug21 + pro3F3ClaimCount.aug21,
        pro3F1ClaimCount.sep21 + pro3F2ClaimCount.sep21 + pro3F3ClaimCount.sep21,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider3PerVisist[6],
        provider3PerVisist[7],
        provider3PerVisist[8]
    ]
}]
// pro-3 21 fourth quarter 
export const fouQuarOf21InPro3 = [{
    name: 'Charges',
    data: [
        pro3F1Charge.oct21 + pro3F2Charge.oct21 + pro3F3Charge.oct21,
        pro3F1Charge.nov21 + pro3F2Charge.nov21 + pro3F3Charge.nov21,
        pro3F1Charge.dec21 + pro3F2Charge.dec21 + pro3F3Charge.dec21,

    ]
},{
    name: 'Payment',
    data: [
        pro3F1Payment.oct21 + pro3F2Payment.oct21 + pro3F3Payment.oct21,
        pro3F1Payment.nov21 + pro3F2Payment.nov21 + pro3F3Payment.nov21,
        pro3F1Payment.dec21 + pro3F2Payment.dec21 + pro3F3Payment.dec21,
    ]
},{
    name: 'Collectable',
    data: [
        pro3F1Collectable.oct21 + pro3F2Collectable.oct21 + pro3F3Collectable.oct21,
        pro3F1Collectable.nov21 + pro3F2Collectable.nov21 + pro3F3Collectable.nov21,
        pro3F1Collectable.dec21 + pro3F2Collectable.dec21 + pro3F3Collectable.dec21,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro3F1NonCollectable.oct21 + pro3F2NonCollectable.oct21 + pro3F3NonCollectable.oct21,
        pro3F1NonCollectable.nov21 + pro3F2NonCollectable.nov21 + pro3F3NonCollectable.nov21,
        pro3F1NonCollectable.dec21 + pro3F2NonCollectable.dec21 + pro3F3NonCollectable.dec21,
    ]
},{
    name: 'Claim',
    data: [
        pro3F1ClaimCount.oct21 + pro3F2ClaimCount.oct21 + pro3F3ClaimCount.oct21,
        pro3F1ClaimCount.nov21 + pro3F2ClaimCount.nov21 + pro3F3ClaimCount.nov21,
        pro3F1ClaimCount.dec21 + pro3F2ClaimCount.dec21 + pro3F3ClaimCount.dec21,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider3PerVisist[9],
        provider3PerVisist[10],
        provider3PerVisist[11]
    ]
}]
// pro-3 22 firth quarter 
export const firQuarOf22InPro3 = [{
    name: 'Charges',
    data: [
        pro3F1Charge.jan22 + pro3F2Charge.jan22 + pro3F3Charge.jan22,
        pro3F1Charge.feb22 + pro3F2Charge.feb22 + pro3F3Charge.feb22,
        pro3F1Charge.mar22 + pro3F2Charge.mar22 + pro3F3Charge.mar22,

    ]
},{
    name: 'Payment',
    data: [
        pro3F1Payment.jan22 + pro3F2Payment.jan22 + pro3F3Payment.jan22,
        pro3F1Payment.feb22 + pro3F2Payment.feb22 + pro3F3Payment.feb22,
        pro3F1Payment.mar22 + pro3F2Payment.mar22 + pro3F3Payment.mar22,
    ]
},{
    name: 'Collectable',
    data: [
        pro3F1Collectable.jan22 + pro3F2Collectable.jan22 + pro3F3Collectable.jan22,
        pro3F1Collectable.feb22 + pro3F2Collectable.feb22 + pro3F3Collectable.feb22,
        pro3F1Collectable.mar22 + pro3F2Collectable.mar22 + pro3F3Collectable.mar22,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro3F1NonCollectable.jan22 + pro3F2NonCollectable.jan22 + pro3F3NonCollectable.jan22,
        pro3F1NonCollectable.feb22 + pro3F2NonCollectable.feb22 + pro3F3NonCollectable.feb22,
        pro3F1NonCollectable.mar22 + pro3F2NonCollectable.mar22 + pro3F3NonCollectable.mar22,
    ]
},{
    name: 'Claim',
    data: [
        pro3F1ClaimCount.jan22 + pro3F2ClaimCount.jan22 + pro3F3ClaimCount.jan22,
        pro3F1ClaimCount.feb22 + pro3F2ClaimCount.feb22 + pro3F3ClaimCount.feb22,
        pro3F1ClaimCount.mar22 + pro3F2ClaimCount.mar22 + pro3F3ClaimCount.mar22,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider3PerVisist[12],
        provider3PerVisist[13],
        provider3PerVisist[14]
    ]
}]
// pro-3 22 second quarter 
export const secQuarOf22InPro3 = [{
    name: 'Charges',
    data: [
        pro3F1Charge.apr22 + pro3F2Charge.apr22 + pro3F3Charge.apr22,
        pro3F1Charge.may22 + pro3F2Charge.may22 + pro3F3Charge.may22,
        pro3F1Charge.jun22 + pro3F2Charge.jun22 + pro3F3Charge.jun22,

    ]
},{
    name: 'Payment',
    data: [
        pro3F1Payment.apr22 + pro3F2Payment.apr22 + pro3F3Payment.apr22,
        pro3F1Payment.may22 + pro3F2Payment.may22 + pro3F3Payment.may22,
        pro3F1Payment.jun22 + pro3F2Payment.jun22 + pro3F3Payment.jun22,
    ]
},{
    name: 'Collectable',
    data: [
        pro3F1Collectable.apr22 + pro3F2Collectable.apr22 + pro3F3Collectable.apr22,
        pro3F1Collectable.may22 + pro3F2Collectable.may22 + pro3F3Collectable.may22,
        pro3F1Collectable.jun22 + pro3F2Collectable.jun22 + pro3F3Collectable.jun22,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro3F1NonCollectable.apr22 + pro3F2NonCollectable.apr22 + pro3F3NonCollectable.apr22,
        pro3F1NonCollectable.may22 + pro3F2NonCollectable.may22 + pro3F3NonCollectable.may22,
        pro3F1NonCollectable.jun22 + pro3F2NonCollectable.jun22 + pro3F3NonCollectable.jun22,
    ]
},{
    name: 'Claim',
    data: [
        pro3F1ClaimCount.apr22 + pro3F2ClaimCount.apr22 + pro3F3ClaimCount.apr22,
        pro3F1ClaimCount.may22 + pro3F2ClaimCount.may22 + pro3F3ClaimCount.may22,
        pro3F1ClaimCount.jun22 + pro3F2ClaimCount.jun22 + pro3F3ClaimCount.jun22,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider3PerVisist[15],
        provider3PerVisist[16],
        provider3PerVisist[17]
    ]
}]
// pro-3 22 third quarter 
export const thiQuarOf22InPro3 = [{
    name: 'Charges',
    data: [
        pro3F1Charge.jul22 + pro3F2Charge.jul22 + pro3F3Charge.jul22,
        pro3F1Charge.aug22 + pro3F2Charge.aug22 + pro3F3Charge.aug22,
        pro3F1Charge.sep22 + pro3F2Charge.sep22 + pro3F3Charge.sep22,

    ]
},{
    name: 'Payment',
    data: [
        pro3F1Payment.jul22 + pro3F2Payment.jul22 + pro3F3Payment.jul22,
        pro3F1Payment.aug22 + pro3F2Payment.aug22 + pro3F3Payment.aug22,
        pro3F1Payment.sep22 + pro3F2Payment.sep22 + pro3F3Payment.sep22,
    ]
},{
    name: 'Collectable',
    data: [
        pro3F1Collectable.jul22 + pro3F2Collectable.jul22 + pro3F3Collectable.jul22,
        pro3F1Collectable.aug22 + pro3F2Collectable.aug22 + pro3F3Collectable.aug22,
        pro3F1Collectable.sep22 + pro3F2Collectable.sep22 + pro3F3Collectable.sep22,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro3F1NonCollectable.jul22 + pro3F2NonCollectable.jul22 + pro3F3NonCollectable.jul22,
        pro3F1NonCollectable.aug22 + pro3F2NonCollectable.aug22 + pro3F3NonCollectable.aug22,
        pro3F1NonCollectable.sep22 + pro3F2NonCollectable.sep22 + pro3F3NonCollectable.sep22,
    ]
},{
    name: 'Claim',
    data: [
        pro3F1ClaimCount.jul22 + pro3F2ClaimCount.jul22 + pro3F3ClaimCount.jul22,
        pro3F1ClaimCount.aug22 + pro3F2ClaimCount.aug22 + pro3F3ClaimCount.aug22,
        pro3F1ClaimCount.sep22 + pro3F2ClaimCount.sep22 + pro3F3ClaimCount.sep22,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider3PerVisist[18],
        provider3PerVisist[19],
        provider3PerVisist[20]
    ]
}]
// pro-3 22 fourth quarter 
export const fouQuarOf22InPro3 = [{
    name: 'Charges',
    data: [
        pro3F1Charge.oct22 + pro3F2Charge.oct22 + pro3F3Charge.oct22,
        pro3F1Charge.nov22 + pro3F2Charge.nov22 + pro3F3Charge.nov22,
        pro3F1Charge.dec22 + pro3F2Charge.dec22 + pro3F3Charge.dec22,

    ]
},{
    name: 'Payment',
    data: [
        pro3F1Payment.oct22 + pro3F2Payment.oct22 + pro3F3Payment.oct22,
        pro3F1Payment.nov22 + pro3F2Payment.nov22 + pro3F3Payment.nov22,
        pro3F1Payment.dec22 + pro3F2Payment.dec22 + pro3F3Payment.dec22,
    ]
},{
    name: 'Collectable',
    data: [
        pro3F1Collectable.oct22 + pro3F2Collectable.oct22 + pro3F3Collectable.oct22,
        pro3F1Collectable.nov22 + pro3F2Collectable.nov22 + pro3F3Collectable.nov22,
        pro3F1Collectable.dec22 + pro3F2Collectable.dec22 + pro3F3Collectable.dec22,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro3F1NonCollectable.oct22 + pro3F2NonCollectable.oct22 + pro3F3NonCollectable.oct22,
        pro3F1NonCollectable.nov22 + pro3F2NonCollectable.nov22 + pro3F3NonCollectable.nov22,
        pro3F1NonCollectable.dec22 + pro3F2NonCollectable.dec22 + pro3F3NonCollectable.dec22,
    ]
},{
    name: 'Claim',
    data: [
        pro3F1ClaimCount.oct22 + pro3F2ClaimCount.oct22 + pro3F3ClaimCount.oct22,
        pro3F1ClaimCount.nov22 + pro3F2ClaimCount.nov22 + pro3F3ClaimCount.nov22,
        pro3F1ClaimCount.dec22 + pro3F2ClaimCount.dec22 + pro3F3ClaimCount.dec22,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider3PerVisist[21],
        provider3PerVisist[22],
        provider3PerVisist[23]
    ]
}]
// pro-3 23 firth quarter 
export const firQuarOf23InPro3 = [{
    name: 'Charges',
    data: [
        pro3F1Charge.jan23 + pro3F2Charge.jan23 + pro3F3Charge.jan23,
        pro3F1Charge.feb23 + pro3F2Charge.feb23 + pro3F3Charge.feb23,
        pro3F1Charge.mar23 + pro3F2Charge.mar23 + pro3F3Charge.mar23,

    ]
},{
    name: 'Payment',
    data: [
        pro3F1Payment.jan23 + pro3F2Payment.jan23 + pro3F3Payment.jan23,
        pro3F1Payment.feb23 + pro3F2Payment.feb23 + pro3F3Payment.feb23,
        pro3F1Payment.mar23 + pro3F2Payment.mar23 + pro3F3Payment.mar23,
    ]
},{
    name: 'Collectable',
    data: [
        pro3F1Collectable.jan23 + pro3F2Collectable.jan23 + pro3F3Collectable.jan23,
        pro3F1Collectable.feb23 + pro3F2Collectable.feb23 + pro3F3Collectable.feb23,
        pro3F1Collectable.mar23 + pro3F2Collectable.mar23 + pro3F3Collectable.mar23,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro3F1NonCollectable.jan23 + pro3F2NonCollectable.jan23 + pro3F3NonCollectable.jan23,
        pro3F1NonCollectable.feb23 + pro3F2NonCollectable.feb23 + pro3F3NonCollectable.feb23,
        pro3F1NonCollectable.mar23 + pro3F2NonCollectable.mar23 + pro3F3NonCollectable.mar23,
    ]
},{
    name: 'Claim',
    data: [
        pro3F1ClaimCount.jan23 + pro3F2ClaimCount.jan23 + pro3F3ClaimCount.jan23,
        pro3F1ClaimCount.feb23 + pro3F2ClaimCount.feb23 + pro3F3ClaimCount.feb23,
        pro3F1ClaimCount.mar23 + pro3F2ClaimCount.mar23 + pro3F3ClaimCount.mar23,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider3PerVisist[24],
        provider3PerVisist[25],
        provider3PerVisist[26]
    ]
}]
// pro-3 23 second quarter 
export const secQuarOf23InPro3 = [{
    name: 'Charges',
    data: [
        pro3F1Charge.apr23 + pro3F2Charge.apr23 + pro3F3Charge.apr23,
        pro3F1Charge.may23 + pro3F2Charge.may23 + pro3F3Charge.may23,
        pro3F1Charge.jun23 + pro3F2Charge.jun23 + pro3F3Charge.jun23,

    ]
},{
    name: 'Payment',
    data: [
        pro3F1Payment.apr23 + pro3F2Payment.apr23 + pro3F3Payment.apr23,
        pro3F1Payment.may23 + pro3F2Payment.may23 + pro3F3Payment.may23,
        pro3F1Payment.jun23 + pro3F2Payment.jun23 + pro3F3Payment.jun23,
    ]
},{
    name: 'Collectable',
    data: [
        pro3F1Collectable.apr23 + pro3F2Collectable.apr23 + pro3F3Collectable.apr23,
        pro3F1Collectable.may23 + pro3F2Collectable.may23 + pro3F3Collectable.may23,
        pro3F1Collectable.jun23 + pro3F2Collectable.jun23 + pro3F3Collectable.jun23,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro3F1NonCollectable.apr23 + pro3F2NonCollectable.apr23 + pro3F3NonCollectable.apr23,
        pro3F1NonCollectable.may23 + pro3F2NonCollectable.may23 + pro3F3NonCollectable.may23,
        pro3F1NonCollectable.jun23 + pro3F2NonCollectable.jun23 + pro3F3NonCollectable.jun23,
    ]
},{
    name: 'Claim',
    data: [
        pro3F1ClaimCount.apr23 + pro3F2ClaimCount.apr23 + pro3F3ClaimCount.apr23,
        pro3F1ClaimCount.may23 + pro3F2ClaimCount.may23 + pro3F3ClaimCount.may23,
        pro3F1ClaimCount.jun23 + pro3F2ClaimCount.jun23 + pro3F3ClaimCount.jun23,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider3PerVisist[27],
        provider3PerVisist[28],
        provider3PerVisist[29]
    ]
}]
// pro-3 23 third quarter 
export const thiQuarOf23InPro3 = [{
    name: 'Charges',
    data: [
        pro3F1Charge.jul23 + pro3F2Charge.jul23 + pro3F3Charge.jul23,
        pro3F1Charge.aug23 + pro3F2Charge.aug23 + pro3F3Charge.aug23,
        pro3F1Charge.sep23 + pro3F2Charge.sep23 + pro3F3Charge.sep23,

    ]
},{
    name: 'Payment',
    data: [
        pro3F1Payment.jul23 + pro3F2Payment.jul23 + pro3F3Payment.jul23,
        pro3F1Payment.aug23 + pro3F2Payment.aug23 + pro3F3Payment.aug23,
        pro3F1Payment.sep23 + pro3F2Payment.sep23 + pro3F3Payment.sep23,
    ]
},{
    name: 'Collectable',
    data: [
        pro3F1Collectable.jul23 + pro3F2Collectable.jul23 + pro3F3Collectable.jul23,
        pro3F1Collectable.aug23 + pro3F2Collectable.aug23 + pro3F3Collectable.aug23,
        pro3F1Collectable.sep23 + pro3F2Collectable.sep23 + pro3F3Collectable.sep23,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro3F1NonCollectable.jul23 + pro3F2NonCollectable.jul23 + pro3F3NonCollectable.jul23,
        pro3F1NonCollectable.aug23 + pro3F2NonCollectable.aug23 + pro3F3NonCollectable.aug23,
        pro3F1NonCollectable.sep23 + pro3F2NonCollectable.sep23 + pro3F3NonCollectable.sep23,
    ]
},{
    name: 'Claim',
    data: [
        pro3F1ClaimCount.jul23 + pro3F2ClaimCount.jul23 + pro3F3ClaimCount.jul23,
        pro3F1ClaimCount.aug23 + pro3F2ClaimCount.aug23 + pro3F3ClaimCount.aug23,
        pro3F1ClaimCount.sep23 + pro3F2ClaimCount.sep23 + pro3F3ClaimCount.sep23,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider3PerVisist[30],
        provider3PerVisist[31],
        provider3PerVisist[32]
    ]
}]
// pro-3 23 fourth quarter 
export const fouQuarOf23InPro3 = [{
    name: 'Charges',
    data: [
        pro3F1Charge.oct23 + pro3F2Charge.oct23 + pro3F3Charge.oct23,
        pro3F1Charge.nov23 + pro3F2Charge.nov23 + pro3F3Charge.nov23,
        0,

    ]
},{
    name: 'Payment',
    data: [
        pro3F1Payment.oct23 + pro3F2Payment.oct23 + pro3F3Payment.oct23,
        pro3F1Payment.nov23 + pro3F2Payment.nov23 + pro3F3Payment.nov23,
        0,
    ]
},{
    name: 'Collectable',
    data: [
        pro3F1Collectable.oct23 + pro3F2Collectable.oct23 + pro3F3Collectable.oct23,
        pro3F1Collectable.nov23 + pro3F2Collectable.nov23 + pro3F3Collectable.nov23,
        0,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro3F1NonCollectable.oct23 + pro3F2NonCollectable.oct23 + pro3F3NonCollectable.oct23,
        pro3F1NonCollectable.nov23 + pro3F2NonCollectable.nov23 + pro3F3NonCollectable.nov23,
        0,
    ]
},{
    name: 'Claim',
    data: [
        pro3F1ClaimCount.oct23 + pro3F2ClaimCount.oct23 + pro3F3ClaimCount.oct23,
        pro3F1ClaimCount.nov23 + pro3F2ClaimCount.nov23 + pro3F3ClaimCount.nov23,
        0,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider3PerVisist[33],
        provider3PerVisist[34],
        0,
    ]
}]


// pro-4 21 firth quarter
export const firQuarOf21InPro4 = [{
    name: 'Charges',
    data: [
        pro4F1Charge.jan21 + pro4F2Charge.jan21 + pro4F3Charge.jan21,
        pro4F1Charge.feb21 + pro4F2Charge.feb21 + pro4F3Charge.feb21,
        pro4F1Charge.mar21 + pro4F2Charge.mar21 + pro4F3Charge.mar21,

    ]
},{
    name: 'Payment',
    data: [
        pro4F1Payment.jan21 + pro4F2Payment.jan21 + pro4F3Payment.jan21,
        pro4F1Payment.feb21 + pro4F2Payment.feb21 + pro4F3Payment.feb21,
        pro4F1Payment.mar21 + pro4F2Payment.mar21 + pro4F3Payment.mar21,
    ]
},{
    name: 'Collectable',
    data: [
        pro4F1Collectable.jan21 + pro4F2Collectable.jan21 + pro4F3Collectable.jan21,
        pro4F1Collectable.feb21 + pro4F2Collectable.feb21 + pro4F3Collectable.feb21,
        pro4F1Collectable.mar21 + pro4F2Collectable.mar21 + pro4F3Collectable.mar21,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro4F1NonCollectable.jan21 + pro4F2NonCollectable.jan21 + pro4F3NonCollectable.jan21,
        pro4F1NonCollectable.feb21 + pro4F2NonCollectable.feb21 + pro4F3NonCollectable.feb21,
        pro4F1NonCollectable.mar21 + pro4F2NonCollectable.mar21 + pro4F3NonCollectable.mar21,
    ]
},{
    name: 'Claim',
    data: [
        pro4F1ClaimCount.jan21 + pro4F2ClaimCount.jan21 + pro4F3ClaimCount.jan21,
        pro4F1ClaimCount.feb21 + pro4F2ClaimCount.feb21 + pro4F3ClaimCount.feb21,
        pro4F1ClaimCount.mar21 + pro4F2ClaimCount.mar21 + pro4F3ClaimCount.mar21,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider4PerVisist[0],
        provider4PerVisist[1],
        provider4PerVisist[2]
    ]
}]
// pro-4 21 sec quarter 
export const secQuarOf21InPro4 = [{
    name: 'Charges',
    data: [
        pro4F1Charge.apr21 + pro4F2Charge.apr21 + pro4F3Charge.apr21,
        pro4F1Charge.may21 + pro4F2Charge.may21 + pro4F3Charge.may21,
        pro4F1Charge.jun21 + pro4F2Charge.jun21 + pro4F3Charge.jun21,

    ]
},{
    name: 'Payment',
    data: [
        pro4F1Payment.apr21 + pro4F2Payment.apr21 + pro4F3Payment.apr21,
        pro4F1Payment.may21 + pro4F2Payment.may21 + pro4F3Payment.may21,
        pro4F1Payment.jun21 + pro4F2Payment.jun21 + pro4F3Payment.jun21,
    ]
},{
    name: 'Collectable',
    data: [
        pro4F1Collectable.apr21 + pro4F2Collectable.apr21 + pro4F3Collectable.apr21,
        pro4F1Collectable.may21 + pro4F2Collectable.may21 + pro4F3Collectable.may21,
        pro4F1Collectable.jun21 + pro4F2Collectable.jun21 + pro4F3Collectable.jun21,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro4F1NonCollectable.apr21 + pro4F2NonCollectable.apr21 + pro4F3NonCollectable.apr21,
        pro4F1NonCollectable.may21 + pro4F2NonCollectable.may21 + pro4F3NonCollectable.may21,
        pro4F1NonCollectable.jun21 + pro4F2NonCollectable.jun21 + pro4F3NonCollectable.jun21,
    ]
},{
    name: 'Claim',
    data: [
        pro4F1ClaimCount.apr21 + pro4F2ClaimCount.apr21 + pro4F3ClaimCount.apr21,
        pro4F1ClaimCount.may21 + pro4F2ClaimCount.may21 + pro4F3ClaimCount.may21,
        pro4F1ClaimCount.jun21 + pro4F2ClaimCount.jun21 + pro4F3ClaimCount.jun21,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider4PerVisist[3],
        provider4PerVisist[4],
        provider4PerVisist[5]
    ]
}]
// pro-4 21 third quarter 
export const thiQuarOf21InPro4 = [{
    name: 'Charges',
    data: [
        pro4F1Charge.jul21 + pro4F2Charge.jul21 + pro4F3Charge.jul21,
        pro4F1Charge.aug21 + pro4F2Charge.aug21 + pro4F3Charge.aug21,
        pro4F1Charge.sep21 + pro4F2Charge.sep21 + pro4F3Charge.sep21,

    ]
},{
    name: 'Payment',
    data: [
        pro4F1Payment.jul21 + pro4F2Payment.jul21 + pro4F3Payment.jul21,
        pro4F1Payment.aug21 + pro4F2Payment.aug21 + pro4F3Payment.aug21,
        pro4F1Payment.sep21 + pro4F2Payment.sep21 + pro4F3Payment.sep21,
    ]
},{
    name: 'Collectable',
    data: [
        pro4F1Collectable.jul21 + pro4F2Collectable.jul21 + pro4F3Collectable.jul21,
        pro4F1Collectable.aug21 + pro4F2Collectable.aug21 + pro4F3Collectable.aug21,
        pro4F1Collectable.sep21 + pro4F2Collectable.sep21 + pro4F3Collectable.sep21,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro4F1NonCollectable.jul21 + pro4F2NonCollectable.jul21 + pro4F3NonCollectable.jul21,
        pro4F1NonCollectable.aug21 + pro4F2NonCollectable.aug21 + pro4F3NonCollectable.aug21,
        pro4F1NonCollectable.sep21 + pro4F2NonCollectable.sep21 + pro4F3NonCollectable.sep21,
    ]
},{
    name: 'Claim',
    data: [
        pro4F1ClaimCount.jul21 + pro4F2ClaimCount.jul21 + pro4F3ClaimCount.jul21,
        pro4F1ClaimCount.aug21 + pro4F2ClaimCount.aug21 + pro4F3ClaimCount.aug21,
        pro4F1ClaimCount.sep21 + pro4F2ClaimCount.sep21 + pro4F3ClaimCount.sep21,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider4PerVisist[6],
        provider4PerVisist[7],
        provider4PerVisist[8]
    ]
}]
// pro-4 21 fourth quarter 
export const fouQuarOf21InPro4 = [{
    name: 'Charges',
    data: [
        pro4F1Charge.oct21 + pro4F2Charge.oct21 + pro4F3Charge.oct21,
        pro4F1Charge.nov21 + pro4F2Charge.nov21 + pro4F3Charge.nov21,
        pro4F1Charge.dec21 + pro4F2Charge.dec21 + pro4F3Charge.dec21,

    ]
},{
    name: 'Payment',
    data: [
        pro4F1Payment.oct21 + pro4F2Payment.oct21 + pro4F3Payment.oct21,
        pro4F1Payment.nov21 + pro4F2Payment.nov21 + pro4F3Payment.nov21,
        pro4F1Payment.dec21 + pro4F2Payment.dec21 + pro4F3Payment.dec21,
    ]
},{
    name: 'Collectable',
    data: [
        pro4F1Collectable.oct21 + pro4F2Collectable.oct21 + pro4F3Collectable.oct21,
        pro4F1Collectable.nov21 + pro4F2Collectable.nov21 + pro4F3Collectable.nov21,
        pro4F1Collectable.dec21 + pro4F2Collectable.dec21 + pro4F3Collectable.dec21,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro4F1NonCollectable.oct21 + pro4F2NonCollectable.oct21 + pro4F3NonCollectable.oct21,
        pro4F1NonCollectable.nov21 + pro4F2NonCollectable.nov21 + pro4F3NonCollectable.nov21,
        pro4F1NonCollectable.dec21 + pro4F2NonCollectable.dec21 + pro4F3NonCollectable.dec21,
    ]
},{
    name: 'Claim',
    data: [
        pro4F1ClaimCount.oct21 + pro4F2ClaimCount.oct21 + pro4F3ClaimCount.oct21,
        pro4F1ClaimCount.nov21 + pro4F2ClaimCount.nov21 + pro4F3ClaimCount.nov21,
        pro4F1ClaimCount.dec21 + pro4F2ClaimCount.dec21 + pro4F3ClaimCount.dec21,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider4PerVisist[9],
        provider4PerVisist[10],
        provider4PerVisist[11]
    ]
}]
// pro-4 22 firth quarter 
export const firQuarOf22InPro4 = [{
    name: 'Charges',
    data: [
        pro4F1Charge.jan22 + pro4F2Charge.jan22 + pro4F3Charge.jan22,
        pro4F1Charge.feb22 + pro4F2Charge.feb22 + pro4F3Charge.feb22,
        pro4F1Charge.mar22 + pro4F2Charge.mar22 + pro4F3Charge.mar22,

    ]
},{
    name: 'Payment',
    data: [
        pro4F1Payment.jan22 + pro4F2Payment.jan22 + pro4F3Payment.jan22,
        pro4F1Payment.feb22 + pro4F2Payment.feb22 + pro4F3Payment.feb22,
        pro4F1Payment.mar22 + pro4F2Payment.mar22 + pro4F3Payment.mar22,
    ]
},{
    name: 'Collectable',
    data: [
        pro4F1Collectable.jan22 + pro4F2Collectable.jan22 + pro4F3Collectable.jan22,
        pro4F1Collectable.feb22 + pro4F2Collectable.feb22 + pro4F3Collectable.feb22,
        pro4F1Collectable.mar22 + pro4F2Collectable.mar22 + pro4F3Collectable.mar22,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro4F1NonCollectable.jan22 + pro4F2NonCollectable.jan22 + pro4F3NonCollectable.jan22,
        pro4F1NonCollectable.feb22 + pro4F2NonCollectable.feb22 + pro4F3NonCollectable.feb22,
        pro4F1NonCollectable.mar22 + pro4F2NonCollectable.mar22 + pro4F3NonCollectable.mar22,
    ]
},{
    name: 'Claim',
    data: [
        pro4F1ClaimCount.jan22 + pro4F2ClaimCount.jan22 + pro4F3ClaimCount.jan22,
        pro4F1ClaimCount.feb22 + pro4F2ClaimCount.feb22 + pro4F3ClaimCount.feb22,
        pro4F1ClaimCount.mar22 + pro4F2ClaimCount.mar22 + pro4F3ClaimCount.mar22,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider4PerVisist[12],
        provider4PerVisist[13],
        provider4PerVisist[14]
    ]
}]
// pro-4 22 second quarter 
export const secQuarOf22InPro4 = [{
    name: 'Charges',
    data: [
        pro4F1Charge.apr22 + pro4F2Charge.apr22 + pro4F3Charge.apr22,
        pro4F1Charge.may22 + pro4F2Charge.may22 + pro4F3Charge.may22,
        pro4F1Charge.jun22 + pro4F2Charge.jun22 + pro4F3Charge.jun22,

    ]
},{
    name: 'Payment',
    data: [
        pro4F1Payment.apr22 + pro4F2Payment.apr22 + pro4F3Payment.apr22,
        pro4F1Payment.may22 + pro4F2Payment.may22 + pro4F3Payment.may22,
        pro4F1Payment.jun22 + pro4F2Payment.jun22 + pro4F3Payment.jun22,
    ]
},{
    name: 'Collectable',
    data: [
        pro4F1Collectable.apr22 + pro4F2Collectable.apr22 + pro4F3Collectable.apr22,
        pro4F1Collectable.may22 + pro4F2Collectable.may22 + pro4F3Collectable.may22,
        pro4F1Collectable.jun22 + pro4F2Collectable.jun22 + pro4F3Collectable.jun22,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro4F1NonCollectable.apr22 + pro4F2NonCollectable.apr22 + pro4F3NonCollectable.apr22,
        pro4F1NonCollectable.may22 + pro4F2NonCollectable.may22 + pro4F3NonCollectable.may22,
        pro4F1NonCollectable.jun22 + pro4F2NonCollectable.jun22 + pro4F3NonCollectable.jun22,
    ]
},{
    name: 'Claim',
    data: [
        pro4F1ClaimCount.apr22 + pro4F2ClaimCount.apr22 + pro4F3ClaimCount.apr22,
        pro4F1ClaimCount.may22 + pro4F2ClaimCount.may22 + pro4F3ClaimCount.may22,
        pro4F1ClaimCount.jun22 + pro4F2ClaimCount.jun22 + pro4F3ClaimCount.jun22,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider4PerVisist[15],
        provider4PerVisist[16],
        provider4PerVisist[17]
    ]
}]
// pro-4 22 third quarter 
export const thiQuarOf22InPro4 = [{
    name: 'Charges',
    data: [
        pro4F1Charge.jul22 + pro4F2Charge.jul22 + pro4F3Charge.jul22,
        pro4F1Charge.aug22 + pro4F2Charge.aug22 + pro4F3Charge.aug22,
        pro4F1Charge.sep22 + pro4F2Charge.sep22 + pro4F3Charge.sep22,

    ]
},{
    name: 'Payment',
    data: [
        pro4F1Payment.jul22 + pro4F2Payment.jul22 + pro4F3Payment.jul22,
        pro4F1Payment.aug22 + pro4F2Payment.aug22 + pro4F3Payment.aug22,
        pro4F1Payment.sep22 + pro4F2Payment.sep22 + pro4F3Payment.sep22,
    ]
},{
    name: 'Collectable',
    data: [
        pro4F1Collectable.jul22 + pro4F2Collectable.jul22 + pro4F3Collectable.jul22,
        pro4F1Collectable.aug22 + pro4F2Collectable.aug22 + pro4F3Collectable.aug22,
        pro4F1Collectable.sep22 + pro4F2Collectable.sep22 + pro4F3Collectable.sep22,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro4F1NonCollectable.jul22 + pro4F2NonCollectable.jul22 + pro4F3NonCollectable.jul22,
        pro4F1NonCollectable.aug22 + pro4F2NonCollectable.aug22 + pro4F3NonCollectable.aug22,
        pro4F1NonCollectable.sep22 + pro4F2NonCollectable.sep22 + pro4F3NonCollectable.sep22,
    ]
},{
    name: 'Claim',
    data: [
        pro4F1ClaimCount.jul22 + pro4F2ClaimCount.jul22 + pro4F3ClaimCount.jul22,
        pro4F1ClaimCount.aug22 + pro4F2ClaimCount.aug22 + pro4F3ClaimCount.aug22,
        pro4F1ClaimCount.sep22 + pro4F2ClaimCount.sep22 + pro4F3ClaimCount.sep22,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider4PerVisist[18],
        provider4PerVisist[19],
        provider4PerVisist[20]
    ]
}]
// pro-4 22 fourth quarter 
export const fouQuarOf22InPro4 = [{
    name: 'Charges',
    data: [
        pro4F1Charge.oct22 + pro4F2Charge.oct22 + pro4F3Charge.oct22,
        pro4F1Charge.nov22 + pro4F2Charge.nov22 + pro4F3Charge.nov22,
        pro4F1Charge.dec22 + pro4F2Charge.dec22 + pro4F3Charge.dec22,

    ]
},{
    name: 'Payment',
    data: [
        pro4F1Payment.oct22 + pro4F2Payment.oct22 + pro4F3Payment.oct22,
        pro4F1Payment.nov22 + pro4F2Payment.nov22 + pro4F3Payment.nov22,
        pro4F1Payment.dec22 + pro4F2Payment.dec22 + pro4F3Payment.dec22,
    ]
},{
    name: 'Collectable',
    data: [
        pro4F1Collectable.oct22 + pro4F2Collectable.oct22 + pro4F3Collectable.oct22,
        pro4F1Collectable.nov22 + pro4F2Collectable.nov22 + pro4F3Collectable.nov22,
        pro4F1Collectable.dec22 + pro4F2Collectable.dec22 + pro4F3Collectable.dec22,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro4F1NonCollectable.oct22 + pro4F2NonCollectable.oct22 + pro4F3NonCollectable.oct22,
        pro4F1NonCollectable.nov22 + pro4F2NonCollectable.nov22 + pro4F3NonCollectable.nov22,
        pro4F1NonCollectable.dec22 + pro4F2NonCollectable.dec22 + pro4F3NonCollectable.dec22,
    ]
},{
    name: 'Claim',
    data: [
        pro4F1ClaimCount.oct22 + pro4F2ClaimCount.oct22 + pro4F3ClaimCount.oct22,
        pro4F1ClaimCount.nov22 + pro4F2ClaimCount.nov22 + pro4F3ClaimCount.nov22,
        pro4F1ClaimCount.dec22 + pro4F2ClaimCount.dec22 + pro4F3ClaimCount.dec22,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider4PerVisist[21],
        provider4PerVisist[22],
        provider4PerVisist[23]
    ]
}]
// pro-4 23 firth quarter 
export const firQuarOf23InPro4 = [{
    name: 'Charges',
    data: [
        pro4F1Charge.jan23 + pro4F2Charge.jan23 + pro4F3Charge.jan23,
        pro4F1Charge.feb23 + pro4F2Charge.feb23 + pro4F3Charge.feb23,
        pro4F1Charge.mar23 + pro4F2Charge.mar23 + pro4F3Charge.mar23,

    ]
},{
    name: 'Payment',
    data: [
        pro4F1Payment.jan23 + pro4F2Payment.jan23 + pro4F3Payment.jan23,
        pro4F1Payment.feb23 + pro4F2Payment.feb23 + pro4F3Payment.feb23,
        pro4F1Payment.mar23 + pro4F2Payment.mar23 + pro4F3Payment.mar23,
    ]
},{
    name: 'Collectable',
    data: [
        pro4F1Collectable.jan23 + pro4F2Collectable.jan23 + pro4F3Collectable.jan23,
        pro4F1Collectable.feb23 + pro4F2Collectable.feb23 + pro4F3Collectable.feb23,
        pro4F1Collectable.mar23 + pro4F2Collectable.mar23 + pro4F3Collectable.mar23,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro4F1NonCollectable.jan23 + pro4F2NonCollectable.jan23 + pro4F3NonCollectable.jan23,
        pro4F1NonCollectable.feb23 + pro4F2NonCollectable.feb23 + pro4F3NonCollectable.feb23,
        pro4F1NonCollectable.mar23 + pro4F2NonCollectable.mar23 + pro4F3NonCollectable.mar23,
    ]
},{
    name: 'Claim',
    data: [
        pro4F1ClaimCount.jan23 + pro4F2ClaimCount.jan23 + pro4F3ClaimCount.jan23,
        pro4F1ClaimCount.feb23 + pro4F2ClaimCount.feb23 + pro4F3ClaimCount.feb23,
        pro4F1ClaimCount.mar23 + pro4F2ClaimCount.mar23 + pro4F3ClaimCount.mar23,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider4PerVisist[24],
        provider4PerVisist[25],
        provider4PerVisist[26]
    ]
}]
// pro-4 23 second quarter 
export const secQuarOf23InPro4 = [{
    name: 'Charges',
    data: [
        pro4F1Charge.apr23 + pro4F2Charge.apr23 + pro4F3Charge.apr23,
        pro4F1Charge.may23 + pro4F2Charge.may23 + pro4F3Charge.may23,
        pro4F1Charge.jun23 + pro4F2Charge.jun23 + pro4F3Charge.jun23,

    ]
},{
    name: 'Payment',
    data: [
        pro4F1Payment.apr23 + pro4F2Payment.apr23 + pro4F3Payment.apr23,
        pro4F1Payment.may23 + pro4F2Payment.may23 + pro4F3Payment.may23,
        pro4F1Payment.jun23 + pro4F2Payment.jun23 + pro4F3Payment.jun23,
    ]
},{
    name: 'Collectable',
    data: [
        pro4F1Collectable.apr23 + pro4F2Collectable.apr23 + pro4F3Collectable.apr23,
        pro4F1Collectable.may23 + pro4F2Collectable.may23 + pro4F3Collectable.may23,
        pro4F1Collectable.jun23 + pro4F2Collectable.jun23 + pro4F3Collectable.jun23,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro4F1NonCollectable.apr23 + pro4F2NonCollectable.apr23 + pro4F3NonCollectable.apr23,
        pro4F1NonCollectable.may23 + pro4F2NonCollectable.may23 + pro4F3NonCollectable.may23,
        pro4F1NonCollectable.jun23 + pro4F2NonCollectable.jun23 + pro4F3NonCollectable.jun23,
    ]
},{
    name: 'Claim',
    data: [
        pro4F1ClaimCount.apr23 + pro4F2ClaimCount.apr23 + pro4F3ClaimCount.apr23,
        pro4F1ClaimCount.may23 + pro4F2ClaimCount.may23 + pro4F3ClaimCount.may23,
        pro4F1ClaimCount.jun23 + pro4F2ClaimCount.jun23 + pro4F3ClaimCount.jun23,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider4PerVisist[27],
        provider4PerVisist[28],
        provider4PerVisist[29]
    ]
}]
// pro-4 23 third quarter 
export const thiQuarOf23InPro4 = [{
    name: 'Charges',
    data: [
        pro4F1Charge.jul23 + pro4F2Charge.jul23 + pro4F3Charge.jul23,
        pro4F1Charge.aug23 + pro4F2Charge.aug23 + pro4F3Charge.aug23,
        pro4F1Charge.sep23 + pro4F2Charge.sep23 + pro4F3Charge.sep23,

    ]
},{
    name: 'Payment',
    data: [
        pro4F1Payment.jul23 + pro4F2Payment.jul23 + pro4F3Payment.jul23,
        pro4F1Payment.aug23 + pro4F2Payment.aug23 + pro4F3Payment.aug23,
        pro4F1Payment.sep23 + pro4F2Payment.sep23 + pro4F3Payment.sep23,
    ]
},{
    name: 'Collectable',
    data: [
        pro4F1Collectable.jul23 + pro4F2Collectable.jul23 + pro4F3Collectable.jul23,
        pro4F1Collectable.aug23 + pro4F2Collectable.aug23 + pro4F3Collectable.aug23,
        pro4F1Collectable.sep23 + pro4F2Collectable.sep23 + pro4F3Collectable.sep23,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro4F1NonCollectable.jul23 + pro4F2NonCollectable.jul23 + pro4F3NonCollectable.jul23,
        pro4F1NonCollectable.aug23 + pro4F2NonCollectable.aug23 + pro4F3NonCollectable.aug23,
        pro4F1NonCollectable.sep23 + pro4F2NonCollectable.sep23 + pro4F3NonCollectable.sep23,
    ]
},{
    name: 'Claim',
    data: [
        pro4F1ClaimCount.jul23 + pro4F2ClaimCount.jul23 + pro4F3ClaimCount.jul23,
        pro4F1ClaimCount.aug23 + pro4F2ClaimCount.aug23 + pro4F3ClaimCount.aug23,
        pro4F1ClaimCount.sep23 + pro4F2ClaimCount.sep23 + pro4F3ClaimCount.sep23,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider4PerVisist[30],
        provider4PerVisist[31],
        provider4PerVisist[32]
    ]
}]
// pro-4 23 fourth quarter 
export const fouQuarOf23InPro4 = [{
    name: 'Charges',
    data: [
        pro4F1Charge.oct23 + pro4F2Charge.oct23 + pro4F3Charge.oct23,
        pro4F1Charge.nov23 + pro4F2Charge.nov23 + pro4F3Charge.nov23,
        0,

    ]
},{
    name: 'Payment',
    data: [
        pro4F1Payment.oct23 + pro4F2Payment.oct23 + pro4F3Payment.oct23,
        pro4F1Payment.nov23 + pro4F2Payment.nov23 + pro4F3Payment.nov23,
        0,
    ]
},{
    name: 'Collectable',
    data: [
        pro4F1Collectable.oct23 + pro4F2Collectable.oct23 + pro4F3Collectable.oct23,
        pro4F1Collectable.nov23 + pro4F2Collectable.nov23 + pro4F3Collectable.nov23,
        0,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro4F1NonCollectable.oct23 + pro4F2NonCollectable.oct23 + pro4F3NonCollectable.oct23,
        pro4F1NonCollectable.nov23 + pro4F2NonCollectable.nov23 + pro4F3NonCollectable.nov23,
        0,
    ]
},{
    name: 'Claim',
    data: [
        pro4F1ClaimCount.oct23 + pro4F2ClaimCount.oct23 + pro4F3ClaimCount.oct23,
        pro4F1ClaimCount.nov23 + pro4F2ClaimCount.nov23 + pro4F3ClaimCount.nov23,
        0,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider4PerVisist[33],
        provider4PerVisist[34],
        0,
    ]
}]



// pro-5 21 firth quarter
export const firQuarOf21InPro5 = [{
    name: 'Charges',
    data: [
        pro5F1Charge.jan21 + pro5F2Charge.jan21 + pro5F3Charge.jan21,
        pro5F1Charge.feb21 + pro5F2Charge.feb21 + pro5F3Charge.feb21,
        pro5F1Charge.mar21 + pro5F2Charge.mar21 + pro5F3Charge.mar21,

    ]
},{
    name: 'Payment',
    data: [
        pro5F1Payment.jan21 + pro5F2Payment.jan21 + pro5F3Payment.jan21,
        pro5F1Payment.feb21 + pro5F2Payment.feb21 + pro5F3Payment.feb21,
        pro5F1Payment.mar21 + pro5F2Payment.mar21 + pro5F3Payment.mar21,
    ]
},{
    name: 'Collectable',
    data: [
        pro5F1Collectable.jan21 + pro5F2Collectable.jan21 + pro5F3Collectable.jan21,
        pro5F1Collectable.feb21 + pro5F2Collectable.feb21 + pro5F3Collectable.feb21,
        pro5F1Collectable.mar21 + pro5F2Collectable.mar21 + pro5F3Collectable.mar21,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro5F1NonCollectable.jan21 + pro5F2NonCollectable.jan21 + pro5F3NonCollectable.jan21,
        pro5F1NonCollectable.feb21 + pro5F2NonCollectable.feb21 + pro5F3NonCollectable.feb21,
        pro5F1NonCollectable.mar21 + pro5F2NonCollectable.mar21 + pro5F3NonCollectable.mar21,
    ]
},{
    name: 'Claim',
    data: [
        pro5F1ClaimCount.jan21 + pro5F2ClaimCount.jan21 + pro5F3ClaimCount.jan21,
        pro5F1ClaimCount.feb21 + pro5F2ClaimCount.feb21 + pro5F3ClaimCount.feb21,
        pro5F1ClaimCount.mar21 + pro5F2ClaimCount.mar21 + pro5F3ClaimCount.mar21,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider5PerVisist[0],
        provider5PerVisist[1],
        provider5PerVisist[2]
    ]
}]
// pro-5 21 sec quarter 
export const secQuarOf21InPro5 = [{
    name: 'Charges',
    data: [
        pro5F1Charge.apr21 + pro5F2Charge.apr21 + pro5F3Charge.apr21,
        pro5F1Charge.may21 + pro5F2Charge.may21 + pro5F3Charge.may21,
        pro5F1Charge.jun21 + pro5F2Charge.jun21 + pro5F3Charge.jun21,

    ]
},{
    name: 'Payment',
    data: [
        pro5F1Payment.apr21 + pro5F2Payment.apr21 + pro5F3Payment.apr21,
        pro5F1Payment.may21 + pro5F2Payment.may21 + pro5F3Payment.may21,
        pro5F1Payment.jun21 + pro5F2Payment.jun21 + pro5F3Payment.jun21,
    ]
},{
    name: 'Collectable',
    data: [
        pro5F1Collectable.apr21 + pro5F2Collectable.apr21 + pro5F3Collectable.apr21,
        pro5F1Collectable.may21 + pro5F2Collectable.may21 + pro5F3Collectable.may21,
        pro5F1Collectable.jun21 + pro5F2Collectable.jun21 + pro5F3Collectable.jun21,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro5F1NonCollectable.apr21 + pro5F2NonCollectable.apr21 + pro5F3NonCollectable.apr21,
        pro5F1NonCollectable.may21 + pro5F2NonCollectable.may21 + pro5F3NonCollectable.may21,
        pro5F1NonCollectable.jun21 + pro5F2NonCollectable.jun21 + pro5F3NonCollectable.jun21,
    ]
},{
    name: 'Claim',
    data: [
        pro5F1ClaimCount.apr21 + pro5F2ClaimCount.apr21 + pro5F3ClaimCount.apr21,
        pro5F1ClaimCount.may21 + pro5F2ClaimCount.may21 + pro5F3ClaimCount.may21,
        pro5F1ClaimCount.jun21 + pro5F2ClaimCount.jun21 + pro5F3ClaimCount.jun21,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider5PerVisist[3],
        provider5PerVisist[4],
        provider5PerVisist[5]
    ]
}]
// pro-5 21 third quarter 
export const thiQuarOf21InPro5 = [{
    name: 'Charges',
    data: [
        pro5F1Charge.jul21 + pro5F2Charge.jul21 + pro5F3Charge.jul21,
        pro5F1Charge.aug21 + pro5F2Charge.aug21 + pro5F3Charge.aug21,
        pro5F1Charge.sep21 + pro5F2Charge.sep21 + pro5F3Charge.sep21,

    ]
},{
    name: 'Payment',
    data: [
        pro5F1Payment.jul21 + pro5F2Payment.jul21 + pro5F3Payment.jul21,
        pro5F1Payment.aug21 + pro5F2Payment.aug21 + pro5F3Payment.aug21,
        pro5F1Payment.sep21 + pro5F2Payment.sep21 + pro5F3Payment.sep21,
    ]
},{
    name: 'Collectable',
    data: [
        pro5F1Collectable.jul21 + pro5F2Collectable.jul21 + pro5F3Collectable.jul21,
        pro5F1Collectable.aug21 + pro5F2Collectable.aug21 + pro5F3Collectable.aug21,
        pro5F1Collectable.sep21 + pro5F2Collectable.sep21 + pro5F3Collectable.sep21,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro5F1NonCollectable.jul21 + pro5F2NonCollectable.jul21 + pro5F3NonCollectable.jul21,
        pro5F1NonCollectable.aug21 + pro5F2NonCollectable.aug21 + pro5F3NonCollectable.aug21,
        pro5F1NonCollectable.sep21 + pro5F2NonCollectable.sep21 + pro5F3NonCollectable.sep21,
    ]
},{
    name: 'Claim',
    data: [
        pro5F1ClaimCount.jul21 + pro5F2ClaimCount.jul21 + pro5F3ClaimCount.jul21,
        pro5F1ClaimCount.aug21 + pro5F2ClaimCount.aug21 + pro5F3ClaimCount.aug21,
        pro5F1ClaimCount.sep21 + pro5F2ClaimCount.sep21 + pro5F3ClaimCount.sep21,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider5PerVisist[6],
        provider5PerVisist[7],
        provider5PerVisist[8]
    ]
}]
// pro-5 21 fourth quarter 
export const fouQuarOf21InPro5 = [{
    name: 'Charges',
    data: [
        pro5F1Charge.oct21 + pro5F2Charge.oct21 + pro5F3Charge.oct21,
        pro5F1Charge.nov21 + pro5F2Charge.nov21 + pro5F3Charge.nov21,
        pro5F1Charge.dec21 + pro5F2Charge.dec21 + pro5F3Charge.dec21,

    ]
},{
    name: 'Payment',
    data: [
        pro5F1Payment.oct21 + pro5F2Payment.oct21 + pro5F3Payment.oct21,
        pro5F1Payment.nov21 + pro5F2Payment.nov21 + pro5F3Payment.nov21,
        pro5F1Payment.dec21 + pro5F2Payment.dec21 + pro5F3Payment.dec21,
    ]
},{
    name: 'Collectable',
    data: [
        pro5F1Collectable.oct21 + pro5F2Collectable.oct21 + pro5F3Collectable.oct21,
        pro5F1Collectable.nov21 + pro5F2Collectable.nov21 + pro5F3Collectable.nov21,
        pro5F1Collectable.dec21 + pro5F2Collectable.dec21 + pro5F3Collectable.dec21,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro5F1NonCollectable.oct21 + pro5F2NonCollectable.oct21 + pro5F3NonCollectable.oct21,
        pro5F1NonCollectable.nov21 + pro5F2NonCollectable.nov21 + pro5F3NonCollectable.nov21,
        pro5F1NonCollectable.dec21 + pro5F2NonCollectable.dec21 + pro5F3NonCollectable.dec21,
    ]
},{
    name: 'Claim',
    data: [
        pro5F1ClaimCount.oct21 + pro5F2ClaimCount.oct21 + pro5F3ClaimCount.oct21,
        pro5F1ClaimCount.nov21 + pro5F2ClaimCount.nov21 + pro5F3ClaimCount.nov21,
        pro5F1ClaimCount.dec21 + pro5F2ClaimCount.dec21 + pro5F3ClaimCount.dec21,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider5PerVisist[9],
        provider5PerVisist[10],
        provider5PerVisist[11]
    ]
}]
// pro-5 22 firth quarter 
export const firQuarOf22InPro5 = [{
    name: 'Charges',
    data: [
        pro5F1Charge.jan22 + pro5F2Charge.jan22 + pro5F3Charge.jan22,
        pro5F1Charge.feb22 + pro5F2Charge.feb22 + pro5F3Charge.feb22,
        pro5F1Charge.mar22 + pro5F2Charge.mar22 + pro5F3Charge.mar22,

    ]
},{
    name: 'Payment',
    data: [
        pro5F1Payment.jan22 + pro5F2Payment.jan22 + pro5F3Payment.jan22,
        pro5F1Payment.feb22 + pro5F2Payment.feb22 + pro5F3Payment.feb22,
        pro5F1Payment.mar22 + pro5F2Payment.mar22 + pro5F3Payment.mar22,
    ]
},{
    name: 'Collectable',
    data: [
        pro5F1Collectable.jan22 + pro5F2Collectable.jan22 + pro5F3Collectable.jan22,
        pro5F1Collectable.feb22 + pro5F2Collectable.feb22 + pro5F3Collectable.feb22,
        pro5F1Collectable.mar22 + pro5F2Collectable.mar22 + pro5F3Collectable.mar22,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro5F1NonCollectable.jan22 + pro5F2NonCollectable.jan22 + pro5F3NonCollectable.jan22,
        pro5F1NonCollectable.feb22 + pro5F2NonCollectable.feb22 + pro5F3NonCollectable.feb22,
        pro5F1NonCollectable.mar22 + pro5F2NonCollectable.mar22 + pro5F3NonCollectable.mar22,
    ]
},{
    name: 'Claim',
    data: [
        pro5F1ClaimCount.jan22 + pro5F2ClaimCount.jan22 + pro5F3ClaimCount.jan22,
        pro5F1ClaimCount.feb22 + pro5F2ClaimCount.feb22 + pro5F3ClaimCount.feb22,
        pro5F1ClaimCount.mar22 + pro5F2ClaimCount.mar22 + pro5F3ClaimCount.mar22,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider5PerVisist[12],
        provider5PerVisist[13],
        provider5PerVisist[14]
    ]
}]
// pro-5 22 second quarter 
export const secQuarOf22InPro5 = [{
    name: 'Charges',
    data: [
        pro5F1Charge.apr22 + pro5F2Charge.apr22 + pro5F3Charge.apr22,
        pro5F1Charge.may22 + pro5F2Charge.may22 + pro5F3Charge.may22,
        pro5F1Charge.jun22 + pro5F2Charge.jun22 + pro5F3Charge.jun22,

    ]
},{
    name: 'Payment',
    data: [
        pro5F1Payment.apr22 + pro5F2Payment.apr22 + pro5F3Payment.apr22,
        pro5F1Payment.may22 + pro5F2Payment.may22 + pro5F3Payment.may22,
        pro5F1Payment.jun22 + pro5F2Payment.jun22 + pro5F3Payment.jun22,
    ]
},{
    name: 'Collectable',
    data: [
        pro5F1Collectable.apr22 + pro5F2Collectable.apr22 + pro5F3Collectable.apr22,
        pro5F1Collectable.may22 + pro5F2Collectable.may22 + pro5F3Collectable.may22,
        pro5F1Collectable.jun22 + pro5F2Collectable.jun22 + pro5F3Collectable.jun22,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro5F1NonCollectable.apr22 + pro5F2NonCollectable.apr22 + pro5F3NonCollectable.apr22,
        pro5F1NonCollectable.may22 + pro5F2NonCollectable.may22 + pro5F3NonCollectable.may22,
        pro5F1NonCollectable.jun22 + pro5F2NonCollectable.jun22 + pro5F3NonCollectable.jun22,
    ]
},{
    name: 'Claim',
    data: [
        pro5F1ClaimCount.apr22 + pro5F2ClaimCount.apr22 + pro5F3ClaimCount.apr22,
        pro5F1ClaimCount.may22 + pro5F2ClaimCount.may22 + pro5F3ClaimCount.may22,
        pro5F1ClaimCount.jun22 + pro5F2ClaimCount.jun22 + pro5F3ClaimCount.jun22,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider5PerVisist[15],
        provider5PerVisist[16],
        provider5PerVisist[17]
    ]
}]
// pro-5 22 third quarter 
export const thiQuarOf22InPro5 = [{
    name: 'Charges',
    data: [
        pro5F1Charge.jul22 + pro5F2Charge.jul22 + pro5F3Charge.jul22,
        pro5F1Charge.aug22 + pro5F2Charge.aug22 + pro5F3Charge.aug22,
        pro5F1Charge.sep22 + pro5F2Charge.sep22 + pro5F3Charge.sep22,

    ]
},{
    name: 'Payment',
    data: [
        pro5F1Payment.jul22 + pro5F2Payment.jul22 + pro5F3Payment.jul22,
        pro5F1Payment.aug22 + pro5F2Payment.aug22 + pro5F3Payment.aug22,
        pro5F1Payment.sep22 + pro5F2Payment.sep22 + pro5F3Payment.sep22,
    ]
},{
    name: 'Collectable',
    data: [
        pro5F1Collectable.jul22 + pro5F2Collectable.jul22 + pro5F3Collectable.jul22,
        pro5F1Collectable.aug22 + pro5F2Collectable.aug22 + pro5F3Collectable.aug22,
        pro5F1Collectable.sep22 + pro5F2Collectable.sep22 + pro5F3Collectable.sep22,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro5F1NonCollectable.jul22 + pro5F2NonCollectable.jul22 + pro5F3NonCollectable.jul22,
        pro5F1NonCollectable.aug22 + pro5F2NonCollectable.aug22 + pro5F3NonCollectable.aug22,
        pro5F1NonCollectable.sep22 + pro5F2NonCollectable.sep22 + pro5F3NonCollectable.sep22,
    ]
},{
    name: 'Claim',
    data: [
        pro5F1ClaimCount.jul22 + pro5F2ClaimCount.jul22 + pro5F3ClaimCount.jul22,
        pro5F1ClaimCount.aug22 + pro5F2ClaimCount.aug22 + pro5F3ClaimCount.aug22,
        pro5F1ClaimCount.sep22 + pro5F2ClaimCount.sep22 + pro5F3ClaimCount.sep22,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider5PerVisist[18],
        provider5PerVisist[19],
        provider5PerVisist[20]
    ]
}]
// pro-5 22 fourth quarter 
export const fouQuarOf22InPro5 = [{
    name: 'Charges',
    data: [
        pro5F1Charge.oct22 + pro5F2Charge.oct22 + pro5F3Charge.oct22,
        pro5F1Charge.nov22 + pro5F2Charge.nov22 + pro5F3Charge.nov22,
        pro5F1Charge.dec22 + pro5F2Charge.dec22 + pro5F3Charge.dec22,

    ]
},{
    name: 'Payment',
    data: [
        pro5F1Payment.oct22 + pro5F2Payment.oct22 + pro5F3Payment.oct22,
        pro5F1Payment.nov22 + pro5F2Payment.nov22 + pro5F3Payment.nov22,
        pro5F1Payment.dec22 + pro5F2Payment.dec22 + pro5F3Payment.dec22,
    ]
},{
    name: 'Collectable',
    data: [
        pro5F1Collectable.oct22 + pro5F2Collectable.oct22 + pro5F3Collectable.oct22,
        pro5F1Collectable.nov22 + pro5F2Collectable.nov22 + pro5F3Collectable.nov22,
        pro5F1Collectable.dec22 + pro5F2Collectable.dec22 + pro5F3Collectable.dec22,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro5F1NonCollectable.oct22 + pro5F2NonCollectable.oct22 + pro5F3NonCollectable.oct22,
        pro5F1NonCollectable.nov22 + pro5F2NonCollectable.nov22 + pro5F3NonCollectable.nov22,
        pro5F1NonCollectable.dec22 + pro5F2NonCollectable.dec22 + pro5F3NonCollectable.dec22,
    ]
},{
    name: 'Claim',
    data: [
        pro5F1ClaimCount.oct22 + pro5F2ClaimCount.oct22 + pro5F3ClaimCount.oct22,
        pro5F1ClaimCount.nov22 + pro5F2ClaimCount.nov22 + pro5F3ClaimCount.nov22,
        pro5F1ClaimCount.dec22 + pro5F2ClaimCount.dec22 + pro5F3ClaimCount.dec22,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider5PerVisist[21],
        provider5PerVisist[22],
        provider5PerVisist[23]
    ]
}]
// pro-5 23 firth quarter 
export const firQuarOf23InPro5 = [{
    name: 'Charges',
    data: [
        pro5F1Charge.jan23 + pro5F2Charge.jan23 + pro5F3Charge.jan23,
        pro5F1Charge.feb23 + pro5F2Charge.feb23 + pro5F3Charge.feb23,
        pro5F1Charge.mar23 + pro5F2Charge.mar23 + pro5F3Charge.mar23,

    ]
},{
    name: 'Payment',
    data: [
        pro5F1Payment.jan23 + pro5F2Payment.jan23 + pro5F3Payment.jan23,
        pro5F1Payment.feb23 + pro5F2Payment.feb23 + pro5F3Payment.feb23,
        pro5F1Payment.mar23 + pro5F2Payment.mar23 + pro5F3Payment.mar23,
    ]
},{
    name: 'Collectable',
    data: [
        pro5F1Collectable.jan23 + pro5F2Collectable.jan23 + pro5F3Collectable.jan23,
        pro5F1Collectable.feb23 + pro5F2Collectable.feb23 + pro5F3Collectable.feb23,
        pro5F1Collectable.mar23 + pro5F2Collectable.mar23 + pro5F3Collectable.mar23,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro5F1NonCollectable.jan23 + pro5F2NonCollectable.jan23 + pro5F3NonCollectable.jan23,
        pro5F1NonCollectable.feb23 + pro5F2NonCollectable.feb23 + pro5F3NonCollectable.feb23,
        pro5F1NonCollectable.mar23 + pro5F2NonCollectable.mar23 + pro5F3NonCollectable.mar23,
    ]
},{
    name: 'Claim',
    data: [
        pro5F1ClaimCount.jan23 + pro5F2ClaimCount.jan23 + pro5F3ClaimCount.jan23,
        pro5F1ClaimCount.feb23 + pro5F2ClaimCount.feb23 + pro5F3ClaimCount.feb23,
        pro5F1ClaimCount.mar23 + pro5F2ClaimCount.mar23 + pro5F3ClaimCount.mar23,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider5PerVisist[24],
        provider5PerVisist[25],
        provider5PerVisist[26]
    ]
}]
// pro-5 23 second quarter 
export const secQuarOf23InPro5 = [{
    name: 'Charges',
    data: [
        pro5F1Charge.apr23 + pro5F2Charge.apr23 + pro5F3Charge.apr23,
        pro5F1Charge.may23 + pro5F2Charge.may23 + pro5F3Charge.may23,
        pro5F1Charge.jun23 + pro5F2Charge.jun23 + pro5F3Charge.jun23,

    ]
},{
    name: 'Payment',
    data: [
        pro5F1Payment.apr23 + pro5F2Payment.apr23 + pro5F3Payment.apr23,
        pro5F1Payment.may23 + pro5F2Payment.may23 + pro5F3Payment.may23,
        pro5F1Payment.jun23 + pro5F2Payment.jun23 + pro5F3Payment.jun23,
    ]
},{
    name: 'Collectable',
    data: [
        pro5F1Collectable.apr23 + pro5F2Collectable.apr23 + pro5F3Collectable.apr23,
        pro5F1Collectable.may23 + pro5F2Collectable.may23 + pro5F3Collectable.may23,
        pro5F1Collectable.jun23 + pro5F2Collectable.jun23 + pro5F3Collectable.jun23,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro5F1NonCollectable.apr23 + pro5F2NonCollectable.apr23 + pro5F3NonCollectable.apr23,
        pro5F1NonCollectable.may23 + pro5F2NonCollectable.may23 + pro5F3NonCollectable.may23,
        pro5F1NonCollectable.jun23 + pro5F2NonCollectable.jun23 + pro5F3NonCollectable.jun23,
    ]
},{
    name: 'Claim',
    data: [
        pro5F1ClaimCount.apr23 + pro5F2ClaimCount.apr23 + pro5F3ClaimCount.apr23,
        pro5F1ClaimCount.may23 + pro5F2ClaimCount.may23 + pro5F3ClaimCount.may23,
        pro5F1ClaimCount.jun23 + pro5F2ClaimCount.jun23 + pro5F3ClaimCount.jun23,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider5PerVisist[27],
        provider5PerVisist[28],
        provider5PerVisist[29]
    ]
}]
// pro-5 23 third quarter 
export const thiQuarOf23InPro5 = [{
    name: 'Charges',
    data: [
        pro5F1Charge.jul23 + pro5F2Charge.jul23 + pro5F3Charge.jul23,
        pro5F1Charge.aug23 + pro5F2Charge.aug23 + pro5F3Charge.aug23,
        pro5F1Charge.sep23 + pro5F2Charge.sep23 + pro5F3Charge.sep23,

    ]
},{
    name: 'Payment',
    data: [
        pro5F1Payment.jul23 + pro5F2Payment.jul23 + pro5F3Payment.jul23,
        pro5F1Payment.aug23 + pro5F2Payment.aug23 + pro5F3Payment.aug23,
        pro5F1Payment.sep23 + pro5F2Payment.sep23 + pro5F3Payment.sep23,
    ]
},{
    name: 'Collectable',
    data: [
        pro5F1Collectable.jul23 + pro5F2Collectable.jul23 + pro5F3Collectable.jul23,
        pro5F1Collectable.aug23 + pro5F2Collectable.aug23 + pro5F3Collectable.aug23,
        pro5F1Collectable.sep23 + pro5F2Collectable.sep23 + pro5F3Collectable.sep23,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro5F1NonCollectable.jul23 + pro5F2NonCollectable.jul23 + pro5F3NonCollectable.jul23,
        pro5F1NonCollectable.aug23 + pro5F2NonCollectable.aug23 + pro5F3NonCollectable.aug23,
        pro5F1NonCollectable.sep23 + pro5F2NonCollectable.sep23 + pro5F3NonCollectable.sep23,
    ]
},{
    name: 'Claim',
    data: [
        pro5F1ClaimCount.jul23 + pro5F2ClaimCount.jul23 + pro5F3ClaimCount.jul23,
        pro5F1ClaimCount.aug23 + pro5F2ClaimCount.aug23 + pro5F3ClaimCount.aug23,
        pro5F1ClaimCount.sep23 + pro5F2ClaimCount.sep23 + pro5F3ClaimCount.sep23,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider5PerVisist[30],
        provider5PerVisist[31],
        provider5PerVisist[32]
    ]
}]
// pro-5 23 fourth quarter 
export const fouQuarOf23InPro5 = [{
    name: 'Charges',
    data: [
        pro5F1Charge.oct23 + pro5F2Charge.oct23 + pro5F3Charge.oct23,
        pro5F1Charge.nov23 + pro5F2Charge.nov23 + pro5F3Charge.nov23,
        0,

    ]
},{
    name: 'Payment',
    data: [
        pro5F1Payment.oct23 + pro5F2Payment.oct23 + pro5F3Payment.oct23,
        pro5F1Payment.nov23 + pro5F2Payment.nov23 + pro5F3Payment.nov23,
        0,
    ]
},{
    name: 'Collectable',
    data: [
        pro5F1Collectable.oct23 + pro5F2Collectable.oct23 + pro5F3Collectable.oct23,
        pro5F1Collectable.nov23 + pro5F2Collectable.nov23 + pro5F3Collectable.nov23,
        0,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro5F1NonCollectable.oct23 + pro5F2NonCollectable.oct23 + pro5F3NonCollectable.oct23,
        pro5F1NonCollectable.nov23 + pro5F2NonCollectable.nov23 + pro5F3NonCollectable.nov23,
        0,
    ]
},{
    name: 'Claim',
    data: [
        pro5F1ClaimCount.oct23 + pro5F2ClaimCount.oct23 + pro5F3ClaimCount.oct23,
        pro5F1ClaimCount.nov23 + pro5F2ClaimCount.nov23 + pro5F3ClaimCount.nov23,
        0,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider5PerVisist[33],
        provider5PerVisist[34],
        0,
    ]
}]


// pro-6 21 firth quarter
export const firQuarOf21InPro6 = [{
    name: 'Charges',
    data: [
        pro6F1Charge.jan21 + pro6F2Charge.jan21 + pro6F3Charge.jan21,
        pro6F1Charge.feb21 + pro6F2Charge.feb21 + pro6F3Charge.feb21,
        pro6F1Charge.mar21 + pro6F2Charge.mar21 + pro6F3Charge.mar21,

    ]
},{
    name: 'Payment',
    data: [
        pro6F1Payment.jan21 + pro6F2Payment.jan21 + pro6F3Payment.jan21,
        pro6F1Payment.feb21 + pro6F2Payment.feb21 + pro6F3Payment.feb21,
        pro6F1Payment.mar21 + pro6F2Payment.mar21 + pro6F3Payment.mar21,
    ]
},{
    name: 'Collectable',
    data: [
        pro6F1Collectable.jan21 + pro6F2Collectable.jan21 + pro6F3Collectable.jan21,
        pro6F1Collectable.feb21 + pro6F2Collectable.feb21 + pro6F3Collectable.feb21,
        pro6F1Collectable.mar21 + pro6F2Collectable.mar21 + pro6F3Collectable.mar21,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro6F1NonCollectable.jan21 + pro6F2NonCollectable.jan21 + pro6F3NonCollectable.jan21,
        pro6F1NonCollectable.feb21 + pro6F2NonCollectable.feb21 + pro6F3NonCollectable.feb21,
        pro6F1NonCollectable.mar21 + pro6F2NonCollectable.mar21 + pro6F3NonCollectable.mar21,
    ]
},{
    name: 'Claim',
    data: [
        pro6F1ClaimCount.jan21 + pro6F2ClaimCount.jan21 + pro6F3ClaimCount.jan21,
        pro6F1ClaimCount.feb21 + pro6F2ClaimCount.feb21 + pro6F3ClaimCount.feb21,
        pro6F1ClaimCount.mar21 + pro6F2ClaimCount.mar21 + pro6F3ClaimCount.mar21,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider6PerVisist[0],
        provider6PerVisist[1],
        provider6PerVisist[2]
    ]
}]
// pro-6 21 sec quarter 
export const secQuarOf21InPro6 = [{
    name: 'Charges',
    data: [
        pro6F1Charge.apr21 + pro6F2Charge.apr21 + pro6F3Charge.apr21,
        pro6F1Charge.may21 + pro6F2Charge.may21 + pro6F3Charge.may21,
        pro6F1Charge.jun21 + pro6F2Charge.jun21 + pro6F3Charge.jun21,

    ]
},{
    name: 'Payment',
    data: [
        pro6F1Payment.apr21 + pro6F2Payment.apr21 + pro6F3Payment.apr21,
        pro6F1Payment.may21 + pro6F2Payment.may21 + pro6F3Payment.may21,
        pro6F1Payment.jun21 + pro6F2Payment.jun21 + pro6F3Payment.jun21,
    ]
},{
    name: 'Collectable',
    data: [
        pro6F1Collectable.apr21 + pro6F2Collectable.apr21 + pro6F3Collectable.apr21,
        pro6F1Collectable.may21 + pro6F2Collectable.may21 + pro6F3Collectable.may21,
        pro6F1Collectable.jun21 + pro6F2Collectable.jun21 + pro6F3Collectable.jun21,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro6F1NonCollectable.apr21 + pro6F2NonCollectable.apr21 + pro6F3NonCollectable.apr21,
        pro6F1NonCollectable.may21 + pro6F2NonCollectable.may21 + pro6F3NonCollectable.may21,
        pro6F1NonCollectable.jun21 + pro6F2NonCollectable.jun21 + pro6F3NonCollectable.jun21,
    ]
},{
    name: 'Claim',
    data: [
        pro6F1ClaimCount.apr21 + pro6F2ClaimCount.apr21 + pro6F3ClaimCount.apr21,
        pro6F1ClaimCount.may21 + pro6F2ClaimCount.may21 + pro6F3ClaimCount.may21,
        pro6F1ClaimCount.jun21 + pro6F2ClaimCount.jun21 + pro6F3ClaimCount.jun21,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider6PerVisist[3],
        provider6PerVisist[4],
        provider6PerVisist[5]
    ]
}]
// pro-6 21 third quarter 
export const thiQuarOf21InPro6 = [{
    name: 'Charges',
    data: [
        pro6F1Charge.jul21 + pro6F2Charge.jul21 + pro6F3Charge.jul21,
        pro6F1Charge.aug21 + pro6F2Charge.aug21 + pro6F3Charge.aug21,
        pro6F1Charge.sep21 + pro6F2Charge.sep21 + pro6F3Charge.sep21,

    ]
},{
    name: 'Payment',
    data: [
        pro6F1Payment.jul21 + pro6F2Payment.jul21 + pro6F3Payment.jul21,
        pro6F1Payment.aug21 + pro6F2Payment.aug21 + pro6F3Payment.aug21,
        pro6F1Payment.sep21 + pro6F2Payment.sep21 + pro6F3Payment.sep21,
    ]
},{
    name: 'Collectable',
    data: [
        pro6F1Collectable.jul21 + pro6F2Collectable.jul21 + pro6F3Collectable.jul21,
        pro6F1Collectable.aug21 + pro6F2Collectable.aug21 + pro6F3Collectable.aug21,
        pro6F1Collectable.sep21 + pro6F2Collectable.sep21 + pro6F3Collectable.sep21,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro6F1NonCollectable.jul21 + pro6F2NonCollectable.jul21 + pro6F3NonCollectable.jul21,
        pro6F1NonCollectable.aug21 + pro6F2NonCollectable.aug21 + pro6F3NonCollectable.aug21,
        pro6F1NonCollectable.sep21 + pro6F2NonCollectable.sep21 + pro6F3NonCollectable.sep21,
    ]
},{
    name: 'Claim',
    data: [
        pro6F1ClaimCount.jul21 + pro6F2ClaimCount.jul21 + pro6F3ClaimCount.jul21,
        pro6F1ClaimCount.aug21 + pro6F2ClaimCount.aug21 + pro6F3ClaimCount.aug21,
        pro6F1ClaimCount.sep21 + pro6F2ClaimCount.sep21 + pro6F3ClaimCount.sep21,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider6PerVisist[6],
        provider6PerVisist[7],
        provider6PerVisist[8]
    ]
}]
// pro-6 21 fourth quarter 
export const fouQuarOf21InPro6 = [{
    name: 'Charges',
    data: [
        pro6F1Charge.oct21 + pro6F2Charge.oct21 + pro6F3Charge.oct21,
        pro6F1Charge.nov21 + pro6F2Charge.nov21 + pro6F3Charge.nov21,
        pro6F1Charge.dec21 + pro6F2Charge.dec21 + pro6F3Charge.dec21,

    ]
},{
    name: 'Payment',
    data: [
        pro6F1Payment.oct21 + pro6F2Payment.oct21 + pro6F3Payment.oct21,
        pro6F1Payment.nov21 + pro6F2Payment.nov21 + pro6F3Payment.nov21,
        pro6F1Payment.dec21 + pro6F2Payment.dec21 + pro6F3Payment.dec21,
    ]
},{
    name: 'Collectable',
    data: [
        pro6F1Collectable.oct21 + pro6F2Collectable.oct21 + pro6F3Collectable.oct21,
        pro6F1Collectable.nov21 + pro6F2Collectable.nov21 + pro6F3Collectable.nov21,
        pro6F1Collectable.dec21 + pro6F2Collectable.dec21 + pro6F3Collectable.dec21,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro6F1NonCollectable.oct21 + pro6F2NonCollectable.oct21 + pro6F3NonCollectable.oct21,
        pro6F1NonCollectable.nov21 + pro6F2NonCollectable.nov21 + pro6F3NonCollectable.nov21,
        pro6F1NonCollectable.dec21 + pro6F2NonCollectable.dec21 + pro6F3NonCollectable.dec21,
    ]
},{
    name: 'Claim',
    data: [
        pro6F1ClaimCount.oct21 + pro6F2ClaimCount.oct21 + pro6F3ClaimCount.oct21,
        pro6F1ClaimCount.nov21 + pro6F2ClaimCount.nov21 + pro6F3ClaimCount.nov21,
        pro6F1ClaimCount.dec21 + pro6F2ClaimCount.dec21 + pro6F3ClaimCount.dec21,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider6PerVisist[9],
        provider6PerVisist[10],
        provider6PerVisist[11]
    ]
}]
// pro-6 22 firth quarter 
export const firQuarOf22InPro6 = [{
    name: 'Charges',
    data: [
        pro6F1Charge.jan22 + pro6F2Charge.jan22 + pro6F3Charge.jan22,
        pro6F1Charge.feb22 + pro6F2Charge.feb22 + pro6F3Charge.feb22,
        pro6F1Charge.mar22 + pro6F2Charge.mar22 + pro6F3Charge.mar22,

    ]
},{
    name: 'Payment',
    data: [
        pro6F1Payment.jan22 + pro6F2Payment.jan22 + pro6F3Payment.jan22,
        pro6F1Payment.feb22 + pro6F2Payment.feb22 + pro6F3Payment.feb22,
        pro6F1Payment.mar22 + pro6F2Payment.mar22 + pro6F3Payment.mar22,
    ]
},{
    name: 'Collectable',
    data: [
        pro6F1Collectable.jan22 + pro6F2Collectable.jan22 + pro6F3Collectable.jan22,
        pro6F1Collectable.feb22 + pro6F2Collectable.feb22 + pro6F3Collectable.feb22,
        pro6F1Collectable.mar22 + pro6F2Collectable.mar22 + pro6F3Collectable.mar22,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro6F1NonCollectable.jan22 + pro6F2NonCollectable.jan22 + pro6F3NonCollectable.jan22,
        pro6F1NonCollectable.feb22 + pro6F2NonCollectable.feb22 + pro6F3NonCollectable.feb22,
        pro6F1NonCollectable.mar22 + pro6F2NonCollectable.mar22 + pro6F3NonCollectable.mar22,
    ]
},{
    name: 'Claim',
    data: [
        pro6F1ClaimCount.jan22 + pro6F2ClaimCount.jan22 + pro6F3ClaimCount.jan22,
        pro6F1ClaimCount.feb22 + pro6F2ClaimCount.feb22 + pro6F3ClaimCount.feb22,
        pro6F1ClaimCount.mar22 + pro6F2ClaimCount.mar22 + pro6F3ClaimCount.mar22,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider6PerVisist[12],
        provider6PerVisist[13],
        provider6PerVisist[14]
    ]
}]
// pro-6 22 second quarter 
export const secQuarOf22InPro6 = [{
    name: 'Charges',
    data: [
        pro6F1Charge.apr22 + pro6F2Charge.apr22 + pro6F3Charge.apr22,
        pro6F1Charge.may22 + pro6F2Charge.may22 + pro6F3Charge.may22,
        pro6F1Charge.jun22 + pro6F2Charge.jun22 + pro6F3Charge.jun22,

    ]
},{
    name: 'Payment',
    data: [
        pro6F1Payment.apr22 + pro6F2Payment.apr22 + pro6F3Payment.apr22,
        pro6F1Payment.may22 + pro6F2Payment.may22 + pro6F3Payment.may22,
        pro6F1Payment.jun22 + pro6F2Payment.jun22 + pro6F3Payment.jun22,
    ]
},{
    name: 'Collectable',
    data: [
        pro6F1Collectable.apr22 + pro6F2Collectable.apr22 + pro6F3Collectable.apr22,
        pro6F1Collectable.may22 + pro6F2Collectable.may22 + pro6F3Collectable.may22,
        pro6F1Collectable.jun22 + pro6F2Collectable.jun22 + pro6F3Collectable.jun22,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro6F1NonCollectable.apr22 + pro6F2NonCollectable.apr22 + pro6F3NonCollectable.apr22,
        pro6F1NonCollectable.may22 + pro6F2NonCollectable.may22 + pro6F3NonCollectable.may22,
        pro6F1NonCollectable.jun22 + pro6F2NonCollectable.jun22 + pro6F3NonCollectable.jun22,
    ]
},{
    name: 'Claim',
    data: [
        pro6F1ClaimCount.apr22 + pro6F2ClaimCount.apr22 + pro6F3ClaimCount.apr22,
        pro6F1ClaimCount.may22 + pro6F2ClaimCount.may22 + pro6F3ClaimCount.may22,
        pro6F1ClaimCount.jun22 + pro6F2ClaimCount.jun22 + pro6F3ClaimCount.jun22,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider6PerVisist[15],
        provider6PerVisist[16],
        provider6PerVisist[17]
    ]
}]
// pro-6 22 third quarter 
export const thiQuarOf22InPro6 = [{
    name: 'Charges',
    data: [
        pro6F1Charge.jul22 + pro6F2Charge.jul22 + pro6F3Charge.jul22,
        pro6F1Charge.aug22 + pro6F2Charge.aug22 + pro6F3Charge.aug22,
        pro6F1Charge.sep22 + pro6F2Charge.sep22 + pro6F3Charge.sep22,

    ]
},{
    name: 'Payment',
    data: [
        pro6F1Payment.jul22 + pro6F2Payment.jul22 + pro6F3Payment.jul22,
        pro6F1Payment.aug22 + pro6F2Payment.aug22 + pro6F3Payment.aug22,
        pro6F1Payment.sep22 + pro6F2Payment.sep22 + pro6F3Payment.sep22,
    ]
},{
    name: 'Collectable',
    data: [
        pro6F1Collectable.jul22 + pro6F2Collectable.jul22 + pro6F3Collectable.jul22,
        pro6F1Collectable.aug22 + pro6F2Collectable.aug22 + pro6F3Collectable.aug22,
        pro6F1Collectable.sep22 + pro6F2Collectable.sep22 + pro6F3Collectable.sep22,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro6F1NonCollectable.jul22 + pro6F2NonCollectable.jul22 + pro6F3NonCollectable.jul22,
        pro6F1NonCollectable.aug22 + pro6F2NonCollectable.aug22 + pro6F3NonCollectable.aug22,
        pro6F1NonCollectable.sep22 + pro6F2NonCollectable.sep22 + pro6F3NonCollectable.sep22,
    ]
},{
    name: 'Claim',
    data: [
        pro6F1ClaimCount.jul22 + pro6F2ClaimCount.jul22 + pro6F3ClaimCount.jul22,
        pro6F1ClaimCount.aug22 + pro6F2ClaimCount.aug22 + pro6F3ClaimCount.aug22,
        pro6F1ClaimCount.sep22 + pro6F2ClaimCount.sep22 + pro6F3ClaimCount.sep22,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider6PerVisist[18],
        provider6PerVisist[19],
        provider6PerVisist[20]
    ]
}]
// pro-6 22 fourth quarter 
export const fouQuarOf22InPro6 = [{
    name: 'Charges',
    data: [
        pro6F1Charge.oct22 + pro6F2Charge.oct22 + pro6F3Charge.oct22,
        pro6F1Charge.nov22 + pro6F2Charge.nov22 + pro6F3Charge.nov22,
        pro6F1Charge.dec22 + pro6F2Charge.dec22 + pro6F3Charge.dec22,

    ]
},{
    name: 'Payment',
    data: [
        pro6F1Payment.oct22 + pro6F2Payment.oct22 + pro6F3Payment.oct22,
        pro6F1Payment.nov22 + pro6F2Payment.nov22 + pro6F3Payment.nov22,
        pro6F1Payment.dec22 + pro6F2Payment.dec22 + pro6F3Payment.dec22,
    ]
},{
    name: 'Collectable',
    data: [
        pro6F1Collectable.oct22 + pro6F2Collectable.oct22 + pro6F3Collectable.oct22,
        pro6F1Collectable.nov22 + pro6F2Collectable.nov22 + pro6F3Collectable.nov22,
        pro6F1Collectable.dec22 + pro6F2Collectable.dec22 + pro6F3Collectable.dec22,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro6F1NonCollectable.oct22 + pro6F2NonCollectable.oct22 + pro6F3NonCollectable.oct22,
        pro6F1NonCollectable.nov22 + pro6F2NonCollectable.nov22 + pro6F3NonCollectable.nov22,
        pro6F1NonCollectable.dec22 + pro6F2NonCollectable.dec22 + pro6F3NonCollectable.dec22,
    ]
},{
    name: 'Claim',
    data: [
        pro6F1ClaimCount.oct22 + pro6F2ClaimCount.oct22 + pro6F3ClaimCount.oct22,
        pro6F1ClaimCount.nov22 + pro6F2ClaimCount.nov22 + pro6F3ClaimCount.nov22,
        pro6F1ClaimCount.dec22 + pro6F2ClaimCount.dec22 + pro6F3ClaimCount.dec22,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider6PerVisist[21],
        provider6PerVisist[22],
        provider6PerVisist[23]
    ]
}]
// pro-6 23 firth quarter 
export const firQuarOf23InPro6 = [{
    name: 'Charges',
    data: [
        pro6F1Charge.jan23 + pro6F2Charge.jan23 + pro6F3Charge.jan23,
        pro6F1Charge.feb23 + pro6F2Charge.feb23 + pro6F3Charge.feb23,
        pro6F1Charge.mar23 + pro6F2Charge.mar23 + pro6F3Charge.mar23,

    ]
},{
    name: 'Payment',
    data: [
        pro6F1Payment.jan23 + pro6F2Payment.jan23 + pro6F3Payment.jan23,
        pro6F1Payment.feb23 + pro6F2Payment.feb23 + pro6F3Payment.feb23,
        pro6F1Payment.mar23 + pro6F2Payment.mar23 + pro6F3Payment.mar23,
    ]
},{
    name: 'Collectable',
    data: [
        pro6F1Collectable.jan23 + pro6F2Collectable.jan23 + pro6F3Collectable.jan23,
        pro6F1Collectable.feb23 + pro6F2Collectable.feb23 + pro6F3Collectable.feb23,
        pro6F1Collectable.mar23 + pro6F2Collectable.mar23 + pro6F3Collectable.mar23,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro6F1NonCollectable.jan23 + pro6F2NonCollectable.jan23 + pro6F3NonCollectable.jan23,
        pro6F1NonCollectable.feb23 + pro6F2NonCollectable.feb23 + pro6F3NonCollectable.feb23,
        pro6F1NonCollectable.mar23 + pro6F2NonCollectable.mar23 + pro6F3NonCollectable.mar23,
    ]
},{
    name: 'Claim',
    data: [
        pro6F1ClaimCount.jan23 + pro6F2ClaimCount.jan23 + pro6F3ClaimCount.jan23,
        pro6F1ClaimCount.feb23 + pro6F2ClaimCount.feb23 + pro6F3ClaimCount.feb23,
        pro6F1ClaimCount.mar23 + pro6F2ClaimCount.mar23 + pro6F3ClaimCount.mar23,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider6PerVisist[24],
        provider6PerVisist[25],
        provider6PerVisist[26]
    ]
}]
// pro-6 23 second quarter 
export const secQuarOf23InPro6 = [{
    name: 'Charges',
    data: [
        pro6F1Charge.apr23 + pro6F2Charge.apr23 + pro6F3Charge.apr23,
        pro6F1Charge.may23 + pro6F2Charge.may23 + pro6F3Charge.may23,
        pro6F1Charge.jun23 + pro6F2Charge.jun23 + pro6F3Charge.jun23,

    ]
},{
    name: 'Payment',
    data: [
        pro6F1Payment.apr23 + pro6F2Payment.apr23 + pro6F3Payment.apr23,
        pro6F1Payment.may23 + pro6F2Payment.may23 + pro6F3Payment.may23,
        pro6F1Payment.jun23 + pro6F2Payment.jun23 + pro6F3Payment.jun23,
    ]
},{
    name: 'Collectable',
    data: [
        pro6F1Collectable.apr23 + pro6F2Collectable.apr23 + pro6F3Collectable.apr23,
        pro6F1Collectable.may23 + pro6F2Collectable.may23 + pro6F3Collectable.may23,
        pro6F1Collectable.jun23 + pro6F2Collectable.jun23 + pro6F3Collectable.jun23,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro6F1NonCollectable.apr23 + pro6F2NonCollectable.apr23 + pro6F3NonCollectable.apr23,
        pro6F1NonCollectable.may23 + pro6F2NonCollectable.may23 + pro6F3NonCollectable.may23,
        pro6F1NonCollectable.jun23 + pro6F2NonCollectable.jun23 + pro6F3NonCollectable.jun23,
    ]
},{
    name: 'Claim',
    data: [
        pro6F1ClaimCount.apr23 + pro6F2ClaimCount.apr23 + pro6F3ClaimCount.apr23,
        pro6F1ClaimCount.may23 + pro6F2ClaimCount.may23 + pro6F3ClaimCount.may23,
        pro6F1ClaimCount.jun23 + pro6F2ClaimCount.jun23 + pro6F3ClaimCount.jun23,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider6PerVisist[27],
        provider6PerVisist[28],
        provider6PerVisist[29]
    ]
}]
// pro-6 23 third quarter 
export const thiQuarOf23InPro6 = [{
    name: 'Charges',
    data: [
        pro6F1Charge.jul23 + pro6F2Charge.jul23 + pro6F3Charge.jul23,
        pro6F1Charge.aug23 + pro6F2Charge.aug23 + pro6F3Charge.aug23,
        pro6F1Charge.sep23 + pro6F2Charge.sep23 + pro6F3Charge.sep23,

    ]
},{
    name: 'Payment',
    data: [
        pro6F1Payment.jul23 + pro6F2Payment.jul23 + pro6F3Payment.jul23,
        pro6F1Payment.aug23 + pro6F2Payment.aug23 + pro6F3Payment.aug23,
        pro6F1Payment.sep23 + pro6F2Payment.sep23 + pro6F3Payment.sep23,
    ]
},{
    name: 'Collectable',
    data: [
        pro6F1Collectable.jul23 + pro6F2Collectable.jul23 + pro6F3Collectable.jul23,
        pro6F1Collectable.aug23 + pro6F2Collectable.aug23 + pro6F3Collectable.aug23,
        pro6F1Collectable.sep23 + pro6F2Collectable.sep23 + pro6F3Collectable.sep23,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro6F1NonCollectable.jul23 + pro6F2NonCollectable.jul23 + pro6F3NonCollectable.jul23,
        pro6F1NonCollectable.aug23 + pro6F2NonCollectable.aug23 + pro6F3NonCollectable.aug23,
        pro6F1NonCollectable.sep23 + pro6F2NonCollectable.sep23 + pro6F3NonCollectable.sep23,
    ]
},{
    name: 'Claim',
    data: [
        pro6F1ClaimCount.jul23 + pro6F2ClaimCount.jul23 + pro6F3ClaimCount.jul23,
        pro6F1ClaimCount.aug23 + pro6F2ClaimCount.aug23 + pro6F3ClaimCount.aug23,
        pro6F1ClaimCount.sep23 + pro6F2ClaimCount.sep23 + pro6F3ClaimCount.sep23,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider6PerVisist[30],
        provider6PerVisist[31],
        provider6PerVisist[32]
    ]
}]
// pro-6 23 fourth quarter 
export const fouQuarOf23InPro6 = [{
    name: 'Charges',
    data: [
        pro6F1Charge.oct23 + pro6F2Charge.oct23 + pro6F3Charge.oct23,
        pro6F1Charge.nov23 + pro6F2Charge.nov23 + pro6F3Charge.nov23,
        0,

    ]
},{
    name: 'Payment',
    data: [
        pro6F1Payment.oct23 + pro6F2Payment.oct23 + pro6F3Payment.oct23,
        pro6F1Payment.nov23 + pro6F2Payment.nov23 + pro6F3Payment.nov23,
        0,
    ]
},{
    name: 'Collectable',
    data: [
        pro6F1Collectable.oct23 + pro6F2Collectable.oct23 + pro6F3Collectable.oct23,
        pro6F1Collectable.nov23 + pro6F2Collectable.nov23 + pro6F3Collectable.nov23,
        0,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro6F1NonCollectable.oct23 + pro6F2NonCollectable.oct23 + pro6F3NonCollectable.oct23,
        pro6F1NonCollectable.nov23 + pro6F2NonCollectable.nov23 + pro6F3NonCollectable.nov23,
        0,
    ]
},{
    name: 'Claim',
    data: [
        pro6F1ClaimCount.oct23 + pro6F2ClaimCount.oct23 + pro6F3ClaimCount.oct23,
        pro6F1ClaimCount.nov23 + pro6F2ClaimCount.nov23 + pro6F3ClaimCount.nov23,
        0,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider6PerVisist[33],
        provider6PerVisist[34],
        0,
    ]
}]



// pro-7 21 firth quarter
export const firQuarOf21InPro7 = [{
    name: 'Charges',
    data: [
        pro7F1Charge.jan21 + pro7F2Charge.jan21 + pro7F3Charge.jan21,
        pro7F1Charge.feb21 + pro7F2Charge.feb21 + pro7F3Charge.feb21,
        pro7F1Charge.mar21 + pro7F2Charge.mar21 + pro7F3Charge.mar21,

    ]
},{
    name: 'Payment',
    data: [
        pro7F1Payment.jan21 + pro7F2Payment.jan21 + pro7F3Payment.jan21,
        pro7F1Payment.feb21 + pro7F2Payment.feb21 + pro7F3Payment.feb21,
        pro7F1Payment.mar21 + pro7F2Payment.mar21 + pro7F3Payment.mar21,
    ]
},{
    name: 'Collectable',
    data: [
        pro7F1Collectable.jan21 + pro7F2Collectable.jan21 + pro7F3Collectable.jan21,
        pro7F1Collectable.feb21 + pro7F2Collectable.feb21 + pro7F3Collectable.feb21,
        pro7F1Collectable.mar21 + pro7F2Collectable.mar21 + pro7F3Collectable.mar21,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro7F1NonCollectable.jan21 + pro7F2NonCollectable.jan21 + pro7F3NonCollectable.jan21,
        pro7F1NonCollectable.feb21 + pro7F2NonCollectable.feb21 + pro7F3NonCollectable.feb21,
        pro7F1NonCollectable.mar21 + pro7F2NonCollectable.mar21 + pro7F3NonCollectable.mar21,
    ]
},{
    name: 'Claim',
    data: [
        pro7F1ClaimCount.jan21 + pro7F2ClaimCount.jan21 + pro7F3ClaimCount.jan21,
        pro7F1ClaimCount.feb21 + pro7F2ClaimCount.feb21 + pro7F3ClaimCount.feb21,
        pro7F1ClaimCount.mar21 + pro7F2ClaimCount.mar21 + pro7F3ClaimCount.mar21,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider7PerVisist[0],
        provider7PerVisist[1],
        provider7PerVisist[2]
    ]
}]
// pro-7 21 sec quarter 
export const secQuarOf21InPro7 = [{
    name: 'Charges',
    data: [
        pro7F1Charge.apr21 + pro7F2Charge.apr21 + pro7F3Charge.apr21,
        pro7F1Charge.may21 + pro7F2Charge.may21 + pro7F3Charge.may21,
        pro7F1Charge.jun21 + pro7F2Charge.jun21 + pro7F3Charge.jun21,

    ]
},{
    name: 'Payment',
    data: [
        pro7F1Payment.apr21 + pro7F2Payment.apr21 + pro7F3Payment.apr21,
        pro7F1Payment.may21 + pro7F2Payment.may21 + pro7F3Payment.may21,
        pro7F1Payment.jun21 + pro7F2Payment.jun21 + pro7F3Payment.jun21,
    ]
},{
    name: 'Collectable',
    data: [
        pro7F1Collectable.apr21 + pro7F2Collectable.apr21 + pro7F3Collectable.apr21,
        pro7F1Collectable.may21 + pro7F2Collectable.may21 + pro7F3Collectable.may21,
        pro7F1Collectable.jun21 + pro7F2Collectable.jun21 + pro7F3Collectable.jun21,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro7F1NonCollectable.apr21 + pro7F2NonCollectable.apr21 + pro7F3NonCollectable.apr21,
        pro7F1NonCollectable.may21 + pro7F2NonCollectable.may21 + pro7F3NonCollectable.may21,
        pro7F1NonCollectable.jun21 + pro7F2NonCollectable.jun21 + pro7F3NonCollectable.jun21,
    ]
},{
    name: 'Claim',
    data: [
        pro7F1ClaimCount.apr21 + pro7F2ClaimCount.apr21 + pro7F3ClaimCount.apr21,
        pro7F1ClaimCount.may21 + pro7F2ClaimCount.may21 + pro7F3ClaimCount.may21,
        pro7F1ClaimCount.jun21 + pro7F2ClaimCount.jun21 + pro7F3ClaimCount.jun21,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider7PerVisist[3],
        provider7PerVisist[4],
        provider7PerVisist[5]
    ]
}]
// pro-7 21 third quarter 
export const thiQuarOf21InPro7 = [{
    name: 'Charges',
    data: [
        pro7F1Charge.jul21 + pro7F2Charge.jul21 + pro7F3Charge.jul21,
        pro7F1Charge.aug21 + pro7F2Charge.aug21 + pro7F3Charge.aug21,
        pro7F1Charge.sep21 + pro7F2Charge.sep21 + pro7F3Charge.sep21,

    ]
},{
    name: 'Payment',
    data: [
        pro7F1Payment.jul21 + pro7F2Payment.jul21 + pro7F3Payment.jul21,
        pro7F1Payment.aug21 + pro7F2Payment.aug21 + pro7F3Payment.aug21,
        pro7F1Payment.sep21 + pro7F2Payment.sep21 + pro7F3Payment.sep21,
    ]
},{
    name: 'Collectable',
    data: [
        pro7F1Collectable.jul21 + pro7F2Collectable.jul21 + pro7F3Collectable.jul21,
        pro7F1Collectable.aug21 + pro7F2Collectable.aug21 + pro7F3Collectable.aug21,
        pro7F1Collectable.sep21 + pro7F2Collectable.sep21 + pro7F3Collectable.sep21,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro7F1NonCollectable.jul21 + pro7F2NonCollectable.jul21 + pro7F3NonCollectable.jul21,
        pro7F1NonCollectable.aug21 + pro7F2NonCollectable.aug21 + pro7F3NonCollectable.aug21,
        pro7F1NonCollectable.sep21 + pro7F2NonCollectable.sep21 + pro7F3NonCollectable.sep21,
    ]
},{
    name: 'Claim',
    data: [
        pro7F1ClaimCount.jul21 + pro7F2ClaimCount.jul21 + pro7F3ClaimCount.jul21,
        pro7F1ClaimCount.aug21 + pro7F2ClaimCount.aug21 + pro7F3ClaimCount.aug21,
        pro7F1ClaimCount.sep21 + pro7F2ClaimCount.sep21 + pro7F3ClaimCount.sep21,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider7PerVisist[6],
        provider7PerVisist[7],
        provider7PerVisist[8]
    ]
}]
// pro-7 21 fourth quarter 
export const fouQuarOf21InPro7 = [{
    name: 'Charges',
    data: [
        pro7F1Charge.oct21 + pro7F2Charge.oct21 + pro7F3Charge.oct21,
        pro7F1Charge.nov21 + pro7F2Charge.nov21 + pro7F3Charge.nov21,
        pro7F1Charge.dec21 + pro7F2Charge.dec21 + pro7F3Charge.dec21,

    ]
},{
    name: 'Payment',
    data: [
        pro7F1Payment.oct21 + pro7F2Payment.oct21 + pro7F3Payment.oct21,
        pro7F1Payment.nov21 + pro7F2Payment.nov21 + pro7F3Payment.nov21,
        pro7F1Payment.dec21 + pro7F2Payment.dec21 + pro7F3Payment.dec21,
    ]
},{
    name: 'Collectable',
    data: [
        pro7F1Collectable.oct21 + pro7F2Collectable.oct21 + pro7F3Collectable.oct21,
        pro7F1Collectable.nov21 + pro7F2Collectable.nov21 + pro7F3Collectable.nov21,
        pro7F1Collectable.dec21 + pro7F2Collectable.dec21 + pro7F3Collectable.dec21,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro7F1NonCollectable.oct21 + pro7F2NonCollectable.oct21 + pro7F3NonCollectable.oct21,
        pro7F1NonCollectable.nov21 + pro7F2NonCollectable.nov21 + pro7F3NonCollectable.nov21,
        pro7F1NonCollectable.dec21 + pro7F2NonCollectable.dec21 + pro7F3NonCollectable.dec21,
    ]
},{
    name: 'Claim',
    data: [
        pro7F1ClaimCount.oct21 + pro7F2ClaimCount.oct21 + pro7F3ClaimCount.oct21,
        pro7F1ClaimCount.nov21 + pro7F2ClaimCount.nov21 + pro7F3ClaimCount.nov21,
        pro7F1ClaimCount.dec21 + pro7F2ClaimCount.dec21 + pro7F3ClaimCount.dec21,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider7PerVisist[9],
        provider7PerVisist[10],
        provider7PerVisist[11]
    ]
}]
// pro-7 22 firth quarter 
export const firQuarOf22InPro7 = [{
    name: 'Charges',
    data: [
        pro7F1Charge.jan22 + pro7F2Charge.jan22 + pro7F3Charge.jan22,
        pro7F1Charge.feb22 + pro7F2Charge.feb22 + pro7F3Charge.feb22,
        pro7F1Charge.mar22 + pro7F2Charge.mar22 + pro7F3Charge.mar22,

    ]
},{
    name: 'Payment',
    data: [
        pro7F1Payment.jan22 + pro7F2Payment.jan22 + pro7F3Payment.jan22,
        pro7F1Payment.feb22 + pro7F2Payment.feb22 + pro7F3Payment.feb22,
        pro7F1Payment.mar22 + pro7F2Payment.mar22 + pro7F3Payment.mar22,
    ]
},{
    name: 'Collectable',
    data: [
        pro7F1Collectable.jan22 + pro7F2Collectable.jan22 + pro7F3Collectable.jan22,
        pro7F1Collectable.feb22 + pro7F2Collectable.feb22 + pro7F3Collectable.feb22,
        pro7F1Collectable.mar22 + pro7F2Collectable.mar22 + pro7F3Collectable.mar22,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro7F1NonCollectable.jan22 + pro7F2NonCollectable.jan22 + pro7F3NonCollectable.jan22,
        pro7F1NonCollectable.feb22 + pro7F2NonCollectable.feb22 + pro7F3NonCollectable.feb22,
        pro7F1NonCollectable.mar22 + pro7F2NonCollectable.mar22 + pro7F3NonCollectable.mar22,
    ]
},{
    name: 'Claim',
    data: [
        pro7F1ClaimCount.jan22 + pro7F2ClaimCount.jan22 + pro7F3ClaimCount.jan22,
        pro7F1ClaimCount.feb22 + pro7F2ClaimCount.feb22 + pro7F3ClaimCount.feb22,
        pro7F1ClaimCount.mar22 + pro7F2ClaimCount.mar22 + pro7F3ClaimCount.mar22,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider7PerVisist[12],
        provider7PerVisist[13],
        provider7PerVisist[14]
    ]
}]
// pro-7 22 second quarter 
export const secQuarOf22InPro7 = [{
    name: 'Charges',
    data: [
        pro7F1Charge.apr22 + pro7F2Charge.apr22 + pro7F3Charge.apr22,
        pro7F1Charge.may22 + pro7F2Charge.may22 + pro7F3Charge.may22,
        pro7F1Charge.jun22 + pro7F2Charge.jun22 + pro7F3Charge.jun22,

    ]
},{
    name: 'Payment',
    data: [
        pro7F1Payment.apr22 + pro7F2Payment.apr22 + pro7F3Payment.apr22,
        pro7F1Payment.may22 + pro7F2Payment.may22 + pro7F3Payment.may22,
        pro7F1Payment.jun22 + pro7F2Payment.jun22 + pro7F3Payment.jun22,
    ]
},{
    name: 'Collectable',
    data: [
        pro7F1Collectable.apr22 + pro7F2Collectable.apr22 + pro7F3Collectable.apr22,
        pro7F1Collectable.may22 + pro7F2Collectable.may22 + pro7F3Collectable.may22,
        pro7F1Collectable.jun22 + pro7F2Collectable.jun22 + pro7F3Collectable.jun22,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro7F1NonCollectable.apr22 + pro7F2NonCollectable.apr22 + pro7F3NonCollectable.apr22,
        pro7F1NonCollectable.may22 + pro7F2NonCollectable.may22 + pro7F3NonCollectable.may22,
        pro7F1NonCollectable.jun22 + pro7F2NonCollectable.jun22 + pro7F3NonCollectable.jun22,
    ]
},{
    name: 'Claim',
    data: [
        pro7F1ClaimCount.apr22 + pro7F2ClaimCount.apr22 + pro7F3ClaimCount.apr22,
        pro7F1ClaimCount.may22 + pro7F2ClaimCount.may22 + pro7F3ClaimCount.may22,
        pro7F1ClaimCount.jun22 + pro7F2ClaimCount.jun22 + pro7F3ClaimCount.jun22,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider7PerVisist[15],
        provider7PerVisist[16],
        provider7PerVisist[17]
    ]
}]
// pro-7 22 third quarter 
export const thiQuarOf22InPro7 = [{
    name: 'Charges',
    data: [
        pro7F1Charge.jul22 + pro7F2Charge.jul22 + pro7F3Charge.jul22,
        pro7F1Charge.aug22 + pro7F2Charge.aug22 + pro7F3Charge.aug22,
        pro7F1Charge.sep22 + pro7F2Charge.sep22 + pro7F3Charge.sep22,

    ]
},{
    name: 'Payment',
    data: [
        pro7F1Payment.jul22 + pro7F2Payment.jul22 + pro7F3Payment.jul22,
        pro7F1Payment.aug22 + pro7F2Payment.aug22 + pro7F3Payment.aug22,
        pro7F1Payment.sep22 + pro7F2Payment.sep22 + pro7F3Payment.sep22,
    ]
},{
    name: 'Collectable',
    data: [
        pro7F1Collectable.jul22 + pro7F2Collectable.jul22 + pro7F3Collectable.jul22,
        pro7F1Collectable.aug22 + pro7F2Collectable.aug22 + pro7F3Collectable.aug22,
        pro7F1Collectable.sep22 + pro7F2Collectable.sep22 + pro7F3Collectable.sep22,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro7F1NonCollectable.jul22 + pro7F2NonCollectable.jul22 + pro7F3NonCollectable.jul22,
        pro7F1NonCollectable.aug22 + pro7F2NonCollectable.aug22 + pro7F3NonCollectable.aug22,
        pro7F1NonCollectable.sep22 + pro7F2NonCollectable.sep22 + pro7F3NonCollectable.sep22,
    ]
},{
    name: 'Claim',
    data: [
        pro7F1ClaimCount.jul22 + pro7F2ClaimCount.jul22 + pro7F3ClaimCount.jul22,
        pro7F1ClaimCount.aug22 + pro7F2ClaimCount.aug22 + pro7F3ClaimCount.aug22,
        pro7F1ClaimCount.sep22 + pro7F2ClaimCount.sep22 + pro7F3ClaimCount.sep22,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider7PerVisist[18],
        provider7PerVisist[19],
        provider7PerVisist[20]
    ]
}]
// pro-7 22 fourth quarter 
export const fouQuarOf22InPro7 = [{
    name: 'Charges',
    data: [
        pro7F1Charge.oct22 + pro7F2Charge.oct22 + pro7F3Charge.oct22,
        pro7F1Charge.nov22 + pro7F2Charge.nov22 + pro7F3Charge.nov22,
        pro7F1Charge.dec22 + pro7F2Charge.dec22 + pro7F3Charge.dec22,

    ]
},{
    name: 'Payment',
    data: [
        pro7F1Payment.oct22 + pro7F2Payment.oct22 + pro7F3Payment.oct22,
        pro7F1Payment.nov22 + pro7F2Payment.nov22 + pro7F3Payment.nov22,
        pro7F1Payment.dec22 + pro7F2Payment.dec22 + pro7F3Payment.dec22,
    ]
},{
    name: 'Collectable',
    data: [
        pro7F1Collectable.oct22 + pro7F2Collectable.oct22 + pro7F3Collectable.oct22,
        pro7F1Collectable.nov22 + pro7F2Collectable.nov22 + pro7F3Collectable.nov22,
        pro7F1Collectable.dec22 + pro7F2Collectable.dec22 + pro7F3Collectable.dec22,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro7F1NonCollectable.oct22 + pro7F2NonCollectable.oct22 + pro7F3NonCollectable.oct22,
        pro7F1NonCollectable.nov22 + pro7F2NonCollectable.nov22 + pro7F3NonCollectable.nov22,
        pro7F1NonCollectable.dec22 + pro7F2NonCollectable.dec22 + pro7F3NonCollectable.dec22,
    ]
},{
    name: 'Claim',
    data: [
        pro7F1ClaimCount.oct22 + pro7F2ClaimCount.oct22 + pro7F3ClaimCount.oct22,
        pro7F1ClaimCount.nov22 + pro7F2ClaimCount.nov22 + pro7F3ClaimCount.nov22,
        pro7F1ClaimCount.dec22 + pro7F2ClaimCount.dec22 + pro7F3ClaimCount.dec22,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider7PerVisist[21],
        provider7PerVisist[22],
        provider7PerVisist[23]
    ]
}]
// pro-7 23 firth quarter 
export const firQuarOf23InPro7 = [{
    name: 'Charges',
    data: [
        pro7F1Charge.jan23 + pro7F2Charge.jan23 + pro7F3Charge.jan23,
        pro7F1Charge.feb23 + pro7F2Charge.feb23 + pro7F3Charge.feb23,
        pro7F1Charge.mar23 + pro7F2Charge.mar23 + pro7F3Charge.mar23,

    ]
},{
    name: 'Payment',
    data: [
        pro7F1Payment.jan23 + pro7F2Payment.jan23 + pro7F3Payment.jan23,
        pro7F1Payment.feb23 + pro7F2Payment.feb23 + pro7F3Payment.feb23,
        pro7F1Payment.mar23 + pro7F2Payment.mar23 + pro7F3Payment.mar23,
    ]
},{
    name: 'Collectable',
    data: [
        pro7F1Collectable.jan23 + pro7F2Collectable.jan23 + pro7F3Collectable.jan23,
        pro7F1Collectable.feb23 + pro7F2Collectable.feb23 + pro7F3Collectable.feb23,
        pro7F1Collectable.mar23 + pro7F2Collectable.mar23 + pro7F3Collectable.mar23,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro7F1NonCollectable.jan23 + pro7F2NonCollectable.jan23 + pro7F3NonCollectable.jan23,
        pro7F1NonCollectable.feb23 + pro7F2NonCollectable.feb23 + pro7F3NonCollectable.feb23,
        pro7F1NonCollectable.mar23 + pro7F2NonCollectable.mar23 + pro7F3NonCollectable.mar23,
    ]
},{
    name: 'Claim',
    data: [
        pro7F1ClaimCount.jan23 + pro7F2ClaimCount.jan23 + pro7F3ClaimCount.jan23,
        pro7F1ClaimCount.feb23 + pro7F2ClaimCount.feb23 + pro7F3ClaimCount.feb23,
        pro7F1ClaimCount.mar23 + pro7F2ClaimCount.mar23 + pro7F3ClaimCount.mar23,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider7PerVisist[24],
        provider7PerVisist[25],
        provider7PerVisist[26]
    ]
}]
// pro-7 23 second quarter 
export const secQuarOf23InPro7 = [{
    name: 'Charges',
    data: [
        pro7F1Charge.apr23 + pro7F2Charge.apr23 + pro7F3Charge.apr23,
        pro7F1Charge.may23 + pro7F2Charge.may23 + pro7F3Charge.may23,
        pro7F1Charge.jun23 + pro7F2Charge.jun23 + pro7F3Charge.jun23,

    ]
},{
    name: 'Payment',
    data: [
        pro7F1Payment.apr23 + pro7F2Payment.apr23 + pro7F3Payment.apr23,
        pro7F1Payment.may23 + pro7F2Payment.may23 + pro7F3Payment.may23,
        pro7F1Payment.jun23 + pro7F2Payment.jun23 + pro7F3Payment.jun23,
    ]
},{
    name: 'Collectable',
    data: [
        pro7F1Collectable.apr23 + pro7F2Collectable.apr23 + pro7F3Collectable.apr23,
        pro7F1Collectable.may23 + pro7F2Collectable.may23 + pro7F3Collectable.may23,
        pro7F1Collectable.jun23 + pro7F2Collectable.jun23 + pro7F3Collectable.jun23,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro7F1NonCollectable.apr23 + pro7F2NonCollectable.apr23 + pro7F3NonCollectable.apr23,
        pro7F1NonCollectable.may23 + pro7F2NonCollectable.may23 + pro7F3NonCollectable.may23,
        pro7F1NonCollectable.jun23 + pro7F2NonCollectable.jun23 + pro7F3NonCollectable.jun23,
    ]
},{
    name: 'Claim',
    data: [
        pro7F1ClaimCount.apr23 + pro7F2ClaimCount.apr23 + pro7F3ClaimCount.apr23,
        pro7F1ClaimCount.may23 + pro7F2ClaimCount.may23 + pro7F3ClaimCount.may23,
        pro7F1ClaimCount.jun23 + pro7F2ClaimCount.jun23 + pro7F3ClaimCount.jun23,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider7PerVisist[27],
        provider7PerVisist[28],
        provider7PerVisist[29]
    ]
}]
// pro-7 23 third quarter 
export const thiQuarOf23InPro7 = [{
    name: 'Charges',
    data: [
        pro7F1Charge.jul23 + pro7F2Charge.jul23 + pro7F3Charge.jul23,
        pro7F1Charge.aug23 + pro7F2Charge.aug23 + pro7F3Charge.aug23,
        pro7F1Charge.sep23 + pro7F2Charge.sep23 + pro7F3Charge.sep23,

    ]
},{
    name: 'Payment',
    data: [
        pro7F1Payment.jul23 + pro7F2Payment.jul23 + pro7F3Payment.jul23,
        pro7F1Payment.aug23 + pro7F2Payment.aug23 + pro7F3Payment.aug23,
        pro7F1Payment.sep23 + pro7F2Payment.sep23 + pro7F3Payment.sep23,
    ]
},{
    name: 'Collectable',
    data: [
        pro7F1Collectable.jul23 + pro7F2Collectable.jul23 + pro7F3Collectable.jul23,
        pro7F1Collectable.aug23 + pro7F2Collectable.aug23 + pro7F3Collectable.aug23,
        pro7F1Collectable.sep23 + pro7F2Collectable.sep23 + pro7F3Collectable.sep23,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro7F1NonCollectable.jul23 + pro7F2NonCollectable.jul23 + pro7F3NonCollectable.jul23,
        pro7F1NonCollectable.aug23 + pro7F2NonCollectable.aug23 + pro7F3NonCollectable.aug23,
        pro7F1NonCollectable.sep23 + pro7F2NonCollectable.sep23 + pro7F3NonCollectable.sep23,
    ]
},{
    name: 'Claim',
    data: [
        pro7F1ClaimCount.jul23 + pro7F2ClaimCount.jul23 + pro7F3ClaimCount.jul23,
        pro7F1ClaimCount.aug23 + pro7F2ClaimCount.aug23 + pro7F3ClaimCount.aug23,
        pro7F1ClaimCount.sep23 + pro7F2ClaimCount.sep23 + pro7F3ClaimCount.sep23,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider7PerVisist[30],
        provider7PerVisist[31],
        provider7PerVisist[32]
    ]
}]
// pro-7 23 fourth quarter 
export const fouQuarOf23InPro7 = [{
    name: 'Charges',
    data: [
        pro7F1Charge.oct23 + pro7F2Charge.oct23 + pro7F3Charge.oct23,
        pro7F1Charge.nov23 + pro7F2Charge.nov23 + pro7F3Charge.nov23,
        0,

    ]
},{
    name: 'Payment',
    data: [
        pro7F1Payment.oct23 + pro7F2Payment.oct23 + pro7F3Payment.oct23,
        pro7F1Payment.nov23 + pro7F2Payment.nov23 + pro7F3Payment.nov23,
        0,
    ]
},{
    name: 'Collectable',
    data: [
        pro7F1Collectable.oct23 + pro7F2Collectable.oct23 + pro7F3Collectable.oct23,
        pro7F1Collectable.nov23 + pro7F2Collectable.nov23 + pro7F3Collectable.nov23,
        0,
    ]
},{
    name: 'Non Collectable',
    data: [
        pro7F1NonCollectable.oct23 + pro7F2NonCollectable.oct23 + pro7F3NonCollectable.oct23,
        pro7F1NonCollectable.nov23 + pro7F2NonCollectable.nov23 + pro7F3NonCollectable.nov23,
        0,
    ]
},{
    name: 'Claim',
    data: [
        pro7F1ClaimCount.oct23 + pro7F2ClaimCount.oct23 + pro7F3ClaimCount.oct23,
        pro7F1ClaimCount.nov23 + pro7F2ClaimCount.nov23 + pro7F3ClaimCount.nov23,
        0,
    ]
},{
    name: 'Average Per Visits',
    data: [
        provider7PerVisist[33],
        provider7PerVisist[34],
        0,
    ]
}]

