import React from 'react'
import { Modal, ModalBody, Spinner, Row, Col } from 'reactstrap'

const ModalLoader = ({ isShow }) => {
    return (
        <Modal isOpen={isShow} style={{ width: '200px' }} className='position-absolute top-50 start-50 translate-middle'>
            <ModalBody>
                <Row>
                    <Col className='d-flex justify-content-center flex-row'>
                        <Spinner color='primary' />
                    </Col>
                </Row>
                <Row>
                    <Col className='d-flex justify-content-center flex-row'>
                        <small className='fs-5 text-dark'>Please Wait</small>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}

export default ModalLoader