import {    pro2F1Charge,pro2F1Payment,pro2F1Collectable,pro2F1NonCollectable,pro2F1ClaimCount,pro2F1PerVisit,
    pro2F2Charge,pro2F2Payment,pro2F2Collectable,pro2F2NonCollectable,pro2F2ClaimCount,pro2F2PerVisit,
    pro2F3Charge,pro2F3Payment,pro2F3Collectable,pro2F3NonCollectable,pro2F3ClaimCount,pro2F3PerVisit,} from './FacilityData';

import {provider2PerVisist} from './ProvidersAndFacility';




export const pro2WithJan21Charges = [
    pro2F1Charge.jan21,
    pro2F2Charge.jan21,
    pro2F3Charge.jan21,
    pro2F1Charge.jan21 + pro2F2Charge.jan21 + pro2F3Charge.jan21

]
// console.log("pro2WithJan21Charges :",pro2WithJan21Charges)
export const pro2WithJan21Pay = [
    pro2F1Payment.jan21,
    pro2F2Payment.jan21,
    pro2F3Payment.jan21,
    pro2F1Payment.jan21 + pro2F2Payment.jan21 + pro2F3Payment.jan21
]
export const pro2WithJan21Count = [
    pro2F1Collectable.jan21,
    pro2F2Collectable.jan21,
    pro2F3Collectable.jan21,
    pro2F1Collectable.jan21 + pro2F2Collectable.jan21 + pro2F3Collectable.jan21
]
export const pro2WithJan21NonCount = [
    pro2F1NonCollectable.jan21,
    pro2F2NonCollectable.jan21,
    pro2F3NonCollectable.jan21,
    pro2F1NonCollectable.jan21 + pro2F2NonCollectable.jan21 + pro2F3NonCollectable.jan21
]
export const pro2WithJan21Claim = [
    pro2F1ClaimCount.jan21,
    pro2F2ClaimCount.jan21,
    pro2F3ClaimCount.jan21,
    pro2F1ClaimCount.jan21 + pro2F2ClaimCount.jan21 + pro2F3ClaimCount.jan21
]
export const pro2WithJan21PerVisit = [
    pro2F1PerVisit.jan21,
    pro2F2PerVisit.jan21,
    pro2F3PerVisit.jan21,
    provider2PerVisist[0]
]
// feb 21
export const pro2WithFeb21Charges = [
    pro2F1Charge.feb21,
    pro2F2Charge.feb21,
    pro2F3Charge.feb21,
    pro2F1Charge.feb21 + pro2F2Charge.feb21 + pro2F3Charge.feb21
]
export const pro2WithFeb21Pay = [
    pro2F1Payment.feb21,
    pro2F2Payment.feb21,
    pro2F3Payment.feb21,
    pro2F1Payment.feb21 + pro2F2Payment.feb21 + pro2F3Payment.feb21
]
export const pro2WithFeb21Count = [
    pro2F1Collectable.feb21,
    pro2F2Collectable.feb21,
    pro2F3Collectable.feb21,
    pro2F1Collectable.feb21 + pro2F2Collectable.feb21 + pro2F3Collectable.feb21
]
export const pro2WithFeb21NonCount = [
    pro2F1NonCollectable.feb21,
    pro2F2NonCollectable.feb21,
    pro2F3NonCollectable.feb21,
    pro2F1NonCollectable.feb21 + pro2F2NonCollectable.feb21 + pro2F3NonCollectable.feb21
]
export const pro2WithFeb21Claim = [
    pro2F1ClaimCount.feb21,
    pro2F2ClaimCount.feb21,
    pro2F3ClaimCount.feb21,
    pro2F1ClaimCount.feb21 + pro2F2ClaimCount.feb21 + pro2F3ClaimCount.feb21
]
export const pro2WithFeb21PerVisit = [
    pro2F1PerVisit.feb21,
    pro2F2PerVisit.feb21,
    pro2F3PerVisit.feb21,
    provider2PerVisist[1]
]
// mar 21
export const pro2WithMar21Charges = [
    pro2F1Charge.mar21,
    pro2F2Charge.mar21,
    pro2F3Charge.mar21,
    pro2F1Charge.mar21 + pro2F2Charge.mar21 + pro2F3Charge.mar21
]
export const pro2WithMar21Pay = [
    pro2F1Payment.mar21,
    pro2F2Payment.mar21,
    pro2F3Payment.mar21,
    pro2F1Payment.mar21 + pro2F2Payment.mar21 + pro2F3Payment.mar21
]
export const pro2WithMar21Count = [
    pro2F1Collectable.mar21,
    pro2F2Collectable.mar21,
    pro2F3Collectable.mar21,
    pro2F1Collectable.mar21 + pro2F2Collectable.mar21 + pro2F3Collectable.mar21
]
export const pro2WithMar21NonCount = [
    pro2F1NonCollectable.mar21,
    pro2F2NonCollectable.mar21,
    pro2F3NonCollectable.mar21,
    pro2F1NonCollectable.mar21 + pro2F2NonCollectable.mar21 + pro2F3NonCollectable.mar21
]
export const pro2WithMar21Claim = [
    pro2F1ClaimCount.mar21,
    pro2F2ClaimCount.mar21,
    pro2F3ClaimCount.mar21,
    pro2F1ClaimCount.mar21 + pro2F2ClaimCount.mar21 + pro2F3ClaimCount.mar21
]
export const pro2WithMar21PerVisit = [
    pro2F1PerVisit.mar21,
    pro2F2PerVisit.mar21,
    pro2F3PerVisit.mar21,
    provider2PerVisist[2]
]
// april 21
export const pro2WithApr21Charges = [
    pro2F1Charge.apr21,
    pro2F2Charge.apr21,
    pro2F3Charge.apr21,
    pro2F1Charge.apr21 + pro2F2Charge.apr21 + pro2F3Charge.apr21
]
export const pro2WithApr21Pay = [
    pro2F1Payment.apr21,
    pro2F2Payment.apr21,
    pro2F3Payment.apr21,
    pro2F1Payment.apr21 + pro2F2Payment.apr21 + pro2F3Payment.apr21
]
export const pro2WithApr21Count = [
    pro2F1Collectable.apr21,
    pro2F2Collectable.apr21,
    pro2F3Collectable.apr21,
    pro2F1Collectable.apr21 + pro2F2Collectable.apr21 + pro2F3Collectable.apr21
]
export const pro2WithApr21NonCount = [
    pro2F1NonCollectable.apr21,
    pro2F2NonCollectable.apr21,
    pro2F3NonCollectable.apr21,
    pro2F1NonCollectable.apr21 + pro2F2NonCollectable.apr21 + pro2F3NonCollectable.apr21
]
export const pro2WithApr21Claim = [
    pro2F1ClaimCount.apr21,
    pro2F2ClaimCount.apr21,
    pro2F3ClaimCount.apr21,
    pro2F1ClaimCount.apr21 + pro2F2ClaimCount.apr21 + pro2F3ClaimCount.apr21
]
export const pro2WithApr21PerVisit = [
    pro2F1PerVisit.apr21,
    pro2F2PerVisit.apr21,
    pro2F3PerVisit.apr21,
    provider2PerVisist[3]
]
// may 21
export const pro2WithMay21Charges = [
    pro2F1Charge.may21,
    pro2F2Charge.may21,
    pro2F3Charge.may21,
    pro2F1Charge.may21 + pro2F2Charge.may21 + pro2F3Charge.may21
]
export const pro2WithMay21Pay = [
    pro2F1Payment.may21,
    pro2F2Payment.may21,
    pro2F3Payment.may21,
    pro2F1Payment.may21 + pro2F2Payment.may21 + pro2F3Payment.may21
]
export const pro2WithMay21Count = [
    pro2F1Collectable.may21,
    pro2F2Collectable.may21,
    pro2F3Collectable.may21,
    pro2F1Collectable.may21 + pro2F2Collectable.may21 + pro2F3Collectable.may21
]
export const pro2WithMay21NonCount = [
    pro2F1NonCollectable.may21,
    pro2F2NonCollectable.may21,
    pro2F3NonCollectable.may21,
    pro2F1NonCollectable.may21 + pro2F2NonCollectable.may21 + pro2F3NonCollectable.may21
]
export const pro2WithMay21Claim = [
    pro2F1ClaimCount.may21,
    pro2F2ClaimCount.may21,
    pro2F3ClaimCount.may21,
    pro2F1ClaimCount.may21 + pro2F2ClaimCount.may21 + pro2F3ClaimCount.may21
]
export const pro2WithMay21PerVisit = [
    pro2F1PerVisit.may21,
    pro2F2PerVisit.may21,
    pro2F3PerVisit.may21,
    provider2PerVisist[4]
]
// jun 21
export const pro2WithJun21Charges = [
    pro2F1Charge.jun21,
    pro2F2Charge.jun21,
    pro2F3Charge.jun21,
    pro2F1Charge.jun21 + pro2F2Charge.jun21 + pro2F3Charge.jun21
]
export const pro2WithJun21Pay = [
    pro2F1Payment.jun21,
    pro2F2Payment.jun21,
    pro2F3Payment.jun21,
    pro2F1Payment.jun21 + pro2F2Payment.jun21 + pro2F3Payment.jun21
]
export const pro2WithJun21Count = [
    pro2F1Collectable.jun21,
    pro2F2Collectable.jun21,
    pro2F3Collectable.jun21,
    pro2F1Collectable.jun21 + pro2F2Collectable.jun21 + pro2F3Collectable.jun21
]
export const pro2WithJun21NonCount = [
    pro2F1NonCollectable.jun21,
    pro2F2NonCollectable.jun21,
    pro2F3NonCollectable.jun21,
    pro2F1NonCollectable.jun21 + pro2F2NonCollectable.jun21 + pro2F3NonCollectable.jun21
]
export const pro2WithJun21Claim = [
    pro2F1ClaimCount.jun21,
    pro2F2ClaimCount.jun21,
    pro2F3ClaimCount.jun21,
    pro2F1ClaimCount.jun21 + pro2F2ClaimCount.jun21 + pro2F3ClaimCount.jun21
]
export const pro2WithJun21PerVisit = [
    pro2F1PerVisit.jun21,
    pro2F2PerVisit.jun21,
    pro2F3PerVisit.jun21,
    provider2PerVisist[5]
]
// jul 21
export const pro2WithJul21Charges = [
    pro2F1Charge.jul21,
    pro2F2Charge.jul21,
    pro2F3Charge.jul21,
    pro2F1Charge.jul21 + pro2F2Charge.jul21 + pro2F3Charge.jul21,

]
export const pro2WithJul21Pay = [
    pro2F1Payment.jul21,
    pro2F2Payment.jul21,
    pro2F3Payment.jul21,
    pro2F1Payment.jul21 + pro2F2Payment.jul21 + pro2F3Payment.jul21
]
export const pro2WithJul21Count = [
    pro2F1Collectable.jul21,
    pro2F2Collectable.jul21,
    pro2F3Collectable.jul21,
    pro2F1Collectable.jul21 + pro2F2Collectable.jul21 + pro2F3Collectable.jul21
]
export const pro2WithJul21NonCount = [
    pro2F1NonCollectable.jul21,
    pro2F2NonCollectable.jul21,
    pro2F3NonCollectable.jul21,
    pro2F1NonCollectable.jul21 + pro2F2NonCollectable.jul21 + pro2F3NonCollectable.jul21
]
export const pro2WithJul21Claim = [
    pro2F1ClaimCount.jul21,
    pro2F2ClaimCount.jul21,
    pro2F3ClaimCount.jul21,
    pro2F1ClaimCount.jul21 + pro2F2ClaimCount.jul21 + pro2F3ClaimCount.jul21
]
export const pro2WithJul21PerVisit = [
    pro2F1PerVisit.jul21,
    pro2F2PerVisit.jul21,
    pro2F3PerVisit.jul21,
    provider2PerVisist[6]
]
// aug 21
export const pro2WithAug21Charges = [
    pro2F1Charge.aug21,
    pro2F2Charge.aug21,
    pro2F3Charge.aug21,
    pro2F1Charge.aug21 + pro2F2Charge.aug21 + pro2F3Charge.aug21
]
export const pro2WithAug21Pay = [
    pro2F1Payment.aug21,
    pro2F2Payment.aug21,
    pro2F3Payment.aug21,
    pro2F1Payment.aug21 + pro2F2Payment.aug21 + pro2F3Payment.aug21
]
export const pro2WithAug21Count = [
    pro2F1Collectable.aug21,
    pro2F2Collectable.aug21,
    pro2F3Collectable.aug21,
    pro2F1Collectable.aug21 + pro2F2Collectable.aug21 + pro2F3Collectable.aug21
]
export const pro2WithAug21NonCount = [
    pro2F1NonCollectable.aug21,
    pro2F2NonCollectable.aug21,
    pro2F3NonCollectable.aug21,
    pro2F1NonCollectable.aug21 + pro2F2NonCollectable.aug21 + pro2F3NonCollectable.aug21
]
export const pro2WithAug21Claim = [
    pro2F1ClaimCount.aug21,
    pro2F2ClaimCount.aug21,
    pro2F3ClaimCount.aug21,
    pro2F1ClaimCount.aug21 + pro2F2ClaimCount.aug21 + pro2F3ClaimCount.aug21
]
export const pro2WithAug21PerVisit = [
    pro2F1PerVisit.aug21,
    pro2F2PerVisit.aug21,
    pro2F3PerVisit.aug21,
    provider2PerVisist[7]
]
// sep 21
export const pro2WithSep21Charges = [
    pro2F1Charge.sep21,
    pro2F2Charge.sep21,
    pro2F3Charge.sep21,
    pro2F1Charge.sep21 + pro2F2Charge.sep21 + pro2F3Charge.sep21
]
export const pro2WithSep21Pay = [
    pro2F1Payment.sep21,
    pro2F2Payment.sep21,
    pro2F3Payment.sep21,
    pro2F1Payment.sep21 + pro2F2Payment.sep21 + pro2F3Payment.sep21
]
export const pro2WithSep21Count = [
    pro2F1Collectable.sep21,
    pro2F2Collectable.sep21,
    pro2F3Collectable.sep21,
    pro2F1Collectable.sep21 + pro2F2Collectable.sep21 + pro2F3Collectable.sep21
]
export const pro2WithSep21NonCount = [
    pro2F1NonCollectable.sep21,
    pro2F2NonCollectable.sep21,
    pro2F3NonCollectable.sep21,
    pro2F1NonCollectable.sep21 + pro2F2NonCollectable.sep21 + pro2F3NonCollectable.sep21
]
export const pro2WithSep21Claim = [
    pro2F1ClaimCount.sep21,
    pro2F2ClaimCount.sep21,
    pro2F3ClaimCount.sep21,
    pro2F1ClaimCount.sep21 + pro2F2ClaimCount.sep21 + pro2F3ClaimCount.sep21
]
export const pro2WithSep21PerVisit = [
    pro2F1PerVisit.sep21,
    pro2F2PerVisit.sep21,
    pro2F3PerVisit.sep21,
    provider2PerVisist[8]
]
// oct 21
export const pro2WithOct21Charges = [
    pro2F1Charge.oct21,
    pro2F2Charge.oct21,
    pro2F3Charge.oct21,
    pro2F1Charge.oct21 + pro2F2Charge.oct21 + pro2F3Charge.oct21
]
export const pro2WithOct21Pay = [
    pro2F1Payment.oct21,
    pro2F2Payment.oct21,
    pro2F3Payment.oct21,
    pro2F1Payment.oct21 + pro2F2Payment.oct21 + pro2F3Payment.oct21
]
export const pro2WithOct21Count = [
    pro2F1Collectable.oct21,
    pro2F2Collectable.oct21,
    pro2F3Collectable.oct21,
    pro2F1Collectable.oct21 + pro2F2Collectable.oct21 + pro2F3Collectable.oct21
]
export const pro2WithOct21NonCount = [
    pro2F1NonCollectable.oct21,
    pro2F2NonCollectable.oct21,
    pro2F3NonCollectable.oct21,
    pro2F1NonCollectable.oct21 + pro2F2NonCollectable.oct21 + pro2F3NonCollectable.oct21
]
export const pro2WithOct21Claim = [
    pro2F1ClaimCount.oct21,
    pro2F2ClaimCount.oct21,
    pro2F3ClaimCount.oct21,
    pro2F1ClaimCount.oct21 + pro2F2ClaimCount.oct21 + pro2F3ClaimCount.oct21
]
export const pro2WithOct21PerVisit = [
    pro2F1PerVisit.oct21,
    pro2F2PerVisit.oct21,
    pro2F3PerVisit.oct21,
    provider2PerVisist[9]
]
// nov 21
export const pro2WithNov21Charges = [
    pro2F1Charge.nov21,
    pro2F2Charge.nov21,
    pro2F3Charge.nov21,
    pro2F1Charge.nov21 + pro2F2Charge.nov21 + pro2F3Charge.nov21
]
export const pro2WithNov21Pay = [
    pro2F1Payment.nov21,
    pro2F2Payment.nov21,
    pro2F3Payment.nov21,
    pro2F1Payment.nov21 + pro2F2Payment.nov21 + pro2F3Payment.nov21
]
export const pro2WithNov21Count = [
    pro2F1Collectable.nov21,
    pro2F2Collectable.nov21,
    pro2F3Collectable.nov21,
    pro2F1Collectable.nov21 + pro2F2Collectable.nov21 + pro2F3Collectable.nov21
]
export const pro2WithNov21NonCount = [
    pro2F1NonCollectable.nov21,
    pro2F2NonCollectable.nov21,
    pro2F3NonCollectable.nov21,
    pro2F1NonCollectable.nov21 + pro2F2NonCollectable.nov21 + pro2F3NonCollectable.nov21
]
export const pro2WithNov21Claim = [
    pro2F1ClaimCount.nov21,
    pro2F2ClaimCount.nov21,
    pro2F3ClaimCount.nov21,
    pro2F1ClaimCount.nov21 + pro2F2ClaimCount.nov21 + pro2F3ClaimCount.nov21
]
export const pro2WithNov21PerVisit = [
    pro2F1PerVisit.nov21,
    pro2F2PerVisit.nov21,
    pro2F3PerVisit.nov21,
    provider2PerVisist[10]
]
// dec 21
export const pro2WithDec21Charges = [
    pro2F1Charge.dec21,
    pro2F2Charge.dec21,
    pro2F3Charge.dec21,
    pro2F1Charge.dec21 + pro2F2Charge.dec21 + pro2F3Charge.dec21
]
export const pro2WithDec21Pay = [
    pro2F1Payment.dec21,
    pro2F2Payment.dec21,
    pro2F3Payment.dec21,
    pro2F1Payment.dec21 + pro2F2Payment.dec21 + pro2F3Payment.dec21
]
export const pro2WithDec21Count = [
    pro2F1Collectable.dec21,
    pro2F2Collectable.dec21,
    pro2F3Collectable.dec21,
    pro2F1Collectable.dec21 + pro2F2Collectable.dec21 + pro2F3Collectable.dec21
]
export const pro2WithDec21NonCount = [
    pro2F1NonCollectable.dec21,
    pro2F2NonCollectable.dec21,
    pro2F3NonCollectable.dec21,
    pro2F1NonCollectable.dec21 + pro2F2NonCollectable.dec21 + pro2F3NonCollectable.dec21
] 
export const pro2WithDec21Claim = [
    pro2F1ClaimCount.dec21,
    pro2F2ClaimCount.dec21,
    pro2F3ClaimCount.dec21,
    pro2F1ClaimCount.dec21 + pro2F2ClaimCount.dec21 + pro2F3ClaimCount.dec21
]
export const pro2WithDec21PerVisit = [
    pro2F1PerVisit.dec21,
    pro2F2PerVisit.dec21,
    pro2F3PerVisit.dec21,
    provider2PerVisist[11]
]

// provider-1 
// jan 22
export const pro2WithJan22Charges = [
    pro2F1Charge.jan22,
    pro2F2Charge.jan22,
    pro2F3Charge.jan22,
    pro2F1Charge.jan22 + pro2F2Charge.jan22 + pro2F3Charge.jan22
]
export const pro2WithJan22Pay = [
    pro2F1Payment.jan22,
    pro2F2Payment.jan22,
    pro2F3Payment.jan22,
    pro2F1Payment.jan22 + pro2F2Payment.jan22 + pro2F3Payment.jan22
]
export const pro2WithJan22Count = [
    pro2F1Collectable.jan22,
    pro2F2Collectable.jan22,
    pro2F3Collectable.jan22,
    pro2F1Collectable.jan22 + pro2F2Collectable.jan22 + pro2F3Collectable.jan22
]
export const pro2WithJan22NonCount = [
    pro2F1NonCollectable.jan22,
    pro2F2NonCollectable.jan22,
    pro2F3NonCollectable.jan22,
    pro2F1NonCollectable.jan22 + pro2F2NonCollectable.jan22 + pro2F3NonCollectable.jan22
]
export const pro2WithJan22Claim = [
    pro2F1ClaimCount.jan22,
    pro2F2ClaimCount.jan22,
    pro2F3ClaimCount.jan22,
    pro2F1ClaimCount.jan22 + pro2F2ClaimCount.jan22 + pro2F3ClaimCount.jan22
]
export const pro2WithJan22PerVisit = [
    pro2F1PerVisit.jan22,
    pro2F2PerVisit.jan22,
    pro2F3PerVisit.jan22,
    provider2PerVisist[12]
]
// feb 22
export const pro2WithFeb22Charges = [
    pro2F1Charge.feb22,
    pro2F2Charge.feb22,
    pro2F3Charge.feb22,
    pro2F1Charge.feb22 + pro2F2Charge.feb22 + pro2F3Charge.feb22
]
export const pro2WithFeb22Pay = [
    pro2F1Payment.feb22,
    pro2F2Payment.feb22,
    pro2F3Payment.feb22,
    pro2F1Payment.feb22 + pro2F2Payment.feb22 + pro2F3Payment.feb22
]
export const pro2WithFeb22Count = [
    pro2F1Collectable.feb22,
    pro2F2Collectable.feb22,
    pro2F3Collectable.feb22,
    pro2F1Collectable.feb22 + pro2F2Collectable.feb22 + pro2F3Collectable.feb22
]
export const pro2WithFeb22NonCount = [
    pro2F1NonCollectable.feb22,
    pro2F2NonCollectable.feb22,
    pro2F3NonCollectable.feb22,
    pro2F1NonCollectable.feb22 + pro2F2NonCollectable.feb22 + pro2F3NonCollectable.feb22
]
export const pro2WithFeb22Claim = [
    pro2F1ClaimCount.feb22,
    pro2F2ClaimCount.feb22,
    pro2F3ClaimCount.feb22,
    pro2F1ClaimCount.feb22 + pro2F2ClaimCount.feb22 + pro2F3ClaimCount.feb22
]
export const pro2WithFeb22PerVisit = [
    pro2F1PerVisit.feb22,
    pro2F2PerVisit.feb22,
    pro2F3PerVisit.feb22,
    provider2PerVisist[13]
]
// mar 22
export const pro2WithMar22Charges = [
    pro2F1Charge.mar22,
    pro2F2Charge.mar22,
    pro2F3Charge.mar22,
    pro2F1Charge.mar22 + pro2F2Charge.mar22 + pro2F3Charge.mar22
]
export const pro2WithMar22Pay = [
    pro2F1Payment.mar22,
    pro2F2Payment.mar22,
    pro2F3Payment.mar22,
    pro2F1Payment.mar22 + pro2F2Payment.mar22 + pro2F3Payment.mar22
]
export const pro2WithMar22Count = [
    pro2F1Collectable.mar22,
    pro2F2Collectable.mar22,
    pro2F3Collectable.mar22,
    pro2F1Collectable.mar22 + pro2F2Collectable.mar22 + pro2F3Collectable.mar22
]
export const pro2WithMar22NonCount = [
    pro2F1NonCollectable.mar22,
    pro2F2NonCollectable.mar22,
    pro2F3NonCollectable.mar22,
    pro2F1NonCollectable.mar22 + pro2F2NonCollectable.mar22 + pro2F3NonCollectable.mar22
]
export const pro2WithMar22Claim = [
    pro2F1ClaimCount.mar22,
    pro2F2ClaimCount.mar22,
    pro2F3ClaimCount.mar22,
    pro2F1ClaimCount.mar22 + pro2F2ClaimCount.mar22 + pro2F3ClaimCount.mar22
]
export const pro2WithMar22PerVisit = [
    pro2F1PerVisit.mar22,
    pro2F2PerVisit.mar22,
    pro2F3PerVisit.mar22,
    provider2PerVisist[14]
]
// april 22
export const pro2WithApr22Charges = [
    pro2F1Charge.apr22,
    pro2F2Charge.apr22,
    pro2F3Charge.apr22,
    pro2F1Charge.apr22 + pro2F2Charge.apr22 + pro2F3Charge.apr22
]
export const pro2WithApr22Pay = [
    pro2F1Payment.apr22,
    pro2F2Payment.apr22,
    pro2F3Payment.apr22,
    pro2F1Payment.apr22 + pro2F2Payment.apr22 + pro2F3Payment.apr22
]
export const pro2WithApr22Count = [
    pro2F1Collectable.apr22,
    pro2F2Collectable.apr22,
    pro2F3Collectable.apr22,
    pro2F1Collectable.apr22 + pro2F2Collectable.apr22 + pro2F3Collectable.apr22
]
export const pro2WithApr22NonCount = [
    pro2F1NonCollectable.apr22,
    pro2F2NonCollectable.apr22,
    pro2F3NonCollectable.apr22,
    pro2F1NonCollectable.apr22 + pro2F2NonCollectable.apr22 + pro2F3NonCollectable.apr22
]
export const pro2WithApr22Claim = [
    pro2F1ClaimCount.apr22,
    pro2F2ClaimCount.apr22,
    pro2F3ClaimCount.apr22,
    pro2F1ClaimCount.apr22 + pro2F2ClaimCount.apr22 + pro2F3ClaimCount.apr22
]
export const pro2WithApr22PerVisit = [
    pro2F1PerVisit.apr22,
    pro2F2PerVisit.apr22,
    pro2F3PerVisit.apr22,
    provider2PerVisist[15]
]
// may 22
export const pro2WithMay22Charges = [
    pro2F1Charge.may22,
    pro2F2Charge.may22,
    pro2F3Charge.may22,
    pro2F1Charge.may22 + pro2F2Charge.may22 + pro2F3Charge.may22
]
export const pro2WithMay22Pay = [
    pro2F1Payment.may22,
    pro2F2Payment.may22,
    pro2F3Payment.may22,
    pro2F1Payment.may22 + pro2F2Payment.may22 + pro2F3Payment.may22
]
export const pro2WithMay22Count = [
    pro2F1Collectable.may22,
    pro2F2Collectable.may22,
    pro2F3Collectable.may22,
    pro2F1Collectable.may22 + pro2F2Collectable.may22 + pro2F3Collectable.may22
]
export const pro2WithMay22NonCount = [
    pro2F1NonCollectable.may22,
    pro2F2NonCollectable.may22,
    pro2F3NonCollectable.may22,
    pro2F1NonCollectable.may22 + pro2F2NonCollectable.may22 + pro2F3NonCollectable.may22
]
export const pro2WithMay22Claim = [
    pro2F1ClaimCount.may22,
    pro2F2ClaimCount.may22,
    pro2F3ClaimCount.may22,
    pro2F1ClaimCount.may22 + pro2F2ClaimCount.may22 + pro2F3ClaimCount.may22
]
export const pro2WithMay22PerVisit = [
    pro2F1PerVisit.may22,
    pro2F2PerVisit.may22,
    pro2F3PerVisit.may22,
    provider2PerVisist[16]
]
// jun 22
export const pro2WithJun22Charges = [
    pro2F1Charge.jun22,
    pro2F2Charge.jun22,
    pro2F3Charge.jun22,
    pro2F1Charge.jun22 + pro2F2Charge.jun22 + pro2F3Charge.jun22
]
export const pro2WithJun22Pay = [
    pro2F1Payment.jun22,
    pro2F2Payment.jun22,
    pro2F3Payment.jun22,
    pro2F1Payment.jun22 + pro2F2Payment.jun22 + pro2F3Payment.jun22
]
export const pro2WithJun22Count = [
    pro2F1Collectable.jun22,
    pro2F2Collectable.jun22,
    pro2F3Collectable.jun22,
    pro2F1Collectable.jun22 + pro2F2Collectable.jun22 + pro2F3Collectable.jun22
]
export const pro2WithJun22NonCount = [
    pro2F1NonCollectable.jun22,
    pro2F2NonCollectable.jun22,
    pro2F3NonCollectable.jun22,
    pro2F1NonCollectable.jun22 + pro2F2NonCollectable.jun22 + pro2F3NonCollectable.jun22
]
export const pro2WithJun22Claim = [
    pro2F1ClaimCount.jun22,
    pro2F2ClaimCount.jun22,
    pro2F3ClaimCount.jun22,
    pro2F1ClaimCount.jun22 + pro2F2ClaimCount.jun22 + pro2F3ClaimCount.jun22
]
export const pro2WithJun22PerVisit = [
    pro2F1PerVisit.jun22,
    pro2F2PerVisit.jun22,
    pro2F3PerVisit.jun22,
    provider2PerVisist[17]
]
// jul 22
export const pro2WithJul22Charges = [
    pro2F1Charge.jul22,
    pro2F2Charge.jul22,
    pro2F3Charge.jul22,
    pro2F1Charge.jul22 + pro2F2Charge.jul22 + pro2F3Charge.jul22
]
export const pro2WithJul22Pay = [
    pro2F1Payment.jul22,
    pro2F2Payment.jul22,
    pro2F3Payment.jul22,
    pro2F1Payment.jul22 + pro2F2Payment.jul22 + pro2F3Payment.jul22
]
export const pro2WithJul22Count = [
    pro2F1Collectable.jul22,
    pro2F2Collectable.jul22,
    pro2F3Collectable.jul22,
    pro2F1Collectable.jul22 + pro2F2Collectable.jul22 + pro2F3Collectable.jul22
]
export const pro2WithJul22NonCount = [
    pro2F1NonCollectable.jul22,
    pro2F2NonCollectable.jul22,
    pro2F3NonCollectable.jul22,
    pro2F1NonCollectable.jul22 + pro2F2NonCollectable.jul22 + pro2F3NonCollectable.jul22
]
export const pro2WithJul22Claim = [
    pro2F1ClaimCount.jul22,
    pro2F2ClaimCount.jul22,
    pro2F3ClaimCount.jul22,
    pro2F1ClaimCount.jul22 + pro2F2ClaimCount.jul22 + pro2F3ClaimCount.jul22
]
export const pro2WithJul22PerVisit = [
    pro2F1PerVisit.jul22,
    pro2F2PerVisit.jul22,
    pro2F3PerVisit.jul22,
    provider2PerVisist[18]
]
// aug 22
export const pro2WithAug22Charges = [
    pro2F1Charge.aug22,
    pro2F2Charge.aug22,
    pro2F3Charge.aug22,
    pro2F1Charge.aug22 + pro2F2Charge.aug22 + pro2F3Charge.aug22
]
export const pro2WithAug22Pay = [
    pro2F1Payment.aug22,
    pro2F2Payment.aug22,
    pro2F3Payment.aug22,
    pro2F1Payment.aug22 + pro2F2Payment.aug22 + pro2F3Payment.aug22
]
export const pro2WithAug22Count = [
    pro2F1Collectable.aug22,
    pro2F2Collectable.aug22,
    pro2F3Collectable.aug22,
    pro2F1Collectable.aug22 + pro2F2Collectable.aug22 + pro2F3Collectable.aug22
]
export const pro2WithAug22NonCount = [
    pro2F1NonCollectable.aug22,
    pro2F2NonCollectable.aug22,
    pro2F3NonCollectable.aug22,
    pro2F1NonCollectable.aug22 + pro2F2NonCollectable.aug22 + pro2F3NonCollectable.aug22
]
export const pro2WithAug22Claim = [
    pro2F1ClaimCount.aug22,
    pro2F2ClaimCount.aug22,
    pro2F3ClaimCount.aug22,
    pro2F1ClaimCount.aug22 + pro2F2ClaimCount.aug22 + pro2F3ClaimCount.aug22
]
export const pro2WithAug22PerVisit = [
    pro2F1PerVisit.aug22,
    pro2F2PerVisit.aug22,
    pro2F3PerVisit.aug22,
    provider2PerVisist[19]
]
// sep 22
export const pro2WithSep22Charges = [
    pro2F1Charge.sep22,
    pro2F2Charge.sep22,
    pro2F3Charge.sep22,
    pro2F1Charge.sep22 + pro2F2Charge.sep22 + pro2F3Charge.sep22
]
export const pro2WithSep22Pay = [
    pro2F1Payment.sep22,
    pro2F2Payment.sep22,
    pro2F3Payment.sep22,
    pro2F1Payment.sep22 + pro2F2Payment.sep22 + pro2F3Payment.sep22
]
export const pro2WithSep22Count = [
    pro2F1Collectable.sep22,
    pro2F2Collectable.sep22,
    pro2F3Collectable.sep22,
    pro2F1Collectable.sep22 + pro2F2Collectable.sep22 + pro2F3Collectable.sep22
]
export const pro2WithSep22NonCount = [
    pro2F1NonCollectable.sep22,
    pro2F2NonCollectable.sep22,
    pro2F3NonCollectable.sep22,
    pro2F1NonCollectable.sep22 + pro2F2NonCollectable.sep22 + pro2F3NonCollectable.sep22
]
export const pro2WithSep22Claim = [
    pro2F1ClaimCount.sep22,
    pro2F2ClaimCount.sep22,
    pro2F3ClaimCount.sep22,
    pro2F1ClaimCount.sep22 + pro2F2ClaimCount.sep22 + pro2F3ClaimCount.sep22
]
export const pro2WithSep22PerVisit = [
    pro2F1PerVisit.sep22,
    pro2F2PerVisit.sep22,
    pro2F3PerVisit.sep22,
    provider2PerVisist[20]
]
// oct 22
export const pro2WithOct22Charges = [
    pro2F1Charge.oct22,
    pro2F2Charge.oct22,
    pro2F3Charge.oct22,
    pro2F1Charge.oct22 + pro2F2Charge.oct22 + pro2F3Charge.oct22
]
export const pro2WithOct22Pay = [
    pro2F1Payment.oct22,
    pro2F2Payment.oct22,
    pro2F3Payment.oct22,
    pro2F1Payment.oct22 + pro2F2Payment.oct22 + pro2F3Payment.oct22
]
export const pro2WithOct22Count = [
    pro2F1Collectable.oct22,
    pro2F2Collectable.oct22,
    pro2F3Collectable.oct22,
    pro2F1Collectable.oct22 + pro2F2Collectable.oct22 + pro2F3Collectable.oct22
]
export const pro2WithOct22NonCount = [
    pro2F1NonCollectable.oct22,
    pro2F2NonCollectable.oct22,
    pro2F3NonCollectable.oct22,
    pro2F1NonCollectable.oct22 + pro2F2NonCollectable.oct22 + pro2F3NonCollectable.oct22
]
export const pro2WithOct22Claim = [
    pro2F1ClaimCount.oct22,
    pro2F2ClaimCount.oct22,
    pro2F3ClaimCount.oct22,
    pro2F1ClaimCount.oct22 + pro2F2ClaimCount.oct22 + pro2F3ClaimCount.oct22
]
export const pro2WithOct22PerVisit = [
    pro2F1PerVisit.oct22,
    pro2F2PerVisit.oct22,
    pro2F3PerVisit.oct22,
    provider2PerVisist[21]
]
// nov 22
export const pro2WithNov22Charges = [
    pro2F1Charge.nov22,
    pro2F2Charge.nov22,
    pro2F3Charge.nov22,
    pro2F1Charge.nov22 + pro2F2Charge.nov22 + pro2F3Charge.nov22
]
export const pro2WithNov22Pay = [
    pro2F1Payment.nov22,
    pro2F2Payment.nov22,
    pro2F3Payment.nov22,
    pro2F1Payment.nov22 + pro2F2Payment.nov22 + pro2F3Payment.nov22
]
export const pro2WithNov22Count = [
    pro2F1Collectable.nov22,
    pro2F2Collectable.nov22,
    pro2F3Collectable.nov22,
    pro2F1Collectable.nov22 + pro2F2Collectable.nov22 + pro2F3Collectable.nov22
]
export const pro2WithNov22NonCount = [
    pro2F1NonCollectable.nov22,
    pro2F2NonCollectable.nov22,
    pro2F3NonCollectable.nov22,
    pro2F1NonCollectable.nov22 + pro2F2NonCollectable.nov22 + pro2F3NonCollectable.nov22
]
export const pro2WithNov22Claim = [
    pro2F1ClaimCount.nov22,
    pro2F2ClaimCount.nov22,
    pro2F3ClaimCount.nov22,
    pro2F1ClaimCount.nov22 + pro2F2ClaimCount.nov22 + pro2F3ClaimCount.nov22
]
export const pro2WithNov22PerVisit = [
    pro2F1PerVisit.nov22,
    pro2F2PerVisit.nov22,
    pro2F3PerVisit.nov22,
    provider2PerVisist[22]
]
// dec 22
export const pro2WithDec22Charges = [
    pro2F1Charge.dec22,
    pro2F2Charge.dec22,
    pro2F3Charge.dec22,
    pro2F1Charge.dec22 + pro2F2Charge.dec22 + pro2F3Charge.dec22
]
export const pro2WithDec22Pay = [
    pro2F1Payment.dec22,
    pro2F2Payment.dec22,
    pro2F3Payment.dec22,
    pro2F1Payment.dec22 + pro2F2Payment.dec22 + pro2F3Payment.dec22
]
export const pro2WithDec22Count = [
    pro2F1Collectable.dec22,
    pro2F2Collectable.dec22,
    pro2F3Collectable.dec22,
    pro2F1Collectable.dec22 + pro2F2Collectable.dec22 + pro2F3Collectable.dec22
]
export const pro2WithDec22NonCount = [
    pro2F1NonCollectable.dec22,
    pro2F2NonCollectable.dec22,
    pro2F3NonCollectable.dec22,
    pro2F1NonCollectable.dec22 + pro2F2NonCollectable.dec22 + pro2F3NonCollectable.dec22
]
export const pro2WithDec22Claim = [
    pro2F1ClaimCount.dec22,
    pro2F2ClaimCount.dec22,
    pro2F3ClaimCount.dec22,
    pro2F1ClaimCount.dec22 + pro2F2ClaimCount.dec22 + pro2F3ClaimCount.dec22
]
export const pro2WithDec22PerVisit = [
    pro2F1PerVisit.dec22,
    pro2F2PerVisit.dec22,
    pro2F3PerVisit.dec22,
    provider2PerVisist[23]
]

// provider-1 
// jan 23
export const pro2WithJan23Charges = [
    pro2F1Charge.jan23,
    pro2F2Charge.jan23,
    pro2F3Charge.jan23,
    pro2F1Charge.jan23 + pro2F2Charge.jan23 + pro2F3Charge.jan23
]
export const pro2WithJan23Pay = [
    pro2F1Payment.jan23,
    pro2F2Payment.jan23,
    pro2F3Payment.jan23,
    pro2F1Payment.jan23 + pro2F2Payment.jan23 + pro2F3Payment.jan23
]
export const pro2WithJan23Count = [
    pro2F1Collectable.jan23,
    pro2F2Collectable.jan23,
    pro2F3Collectable.jan23,
    pro2F1Collectable.jan23 + pro2F2Collectable.jan23 + pro2F3Collectable.jan23
]
export const pro2WithJan23NonCount = [
    pro2F1NonCollectable.jan23,
    pro2F2NonCollectable.jan23,
    pro2F3NonCollectable.jan23,
    pro2F1NonCollectable.jan23 + pro2F2NonCollectable.jan23 + pro2F3NonCollectable.jan23
]
export const pro2WithJan23Claim = [
    pro2F1ClaimCount.jan23,
    pro2F2ClaimCount.jan23,
    pro2F3ClaimCount.jan23,
    pro2F1ClaimCount.jan23 + pro2F2ClaimCount.jan23 + pro2F3ClaimCount.jan23
]
export const pro2WithJan23PerVisit = [
    pro2F1PerVisit.jan23,
    pro2F2PerVisit.jan23,
    pro2F3PerVisit.jan23,
    provider2PerVisist[24]
]
// feb 23
export const pro2WithFeb23Charges = [
    pro2F1Charge.feb23,
    pro2F2Charge.feb23,
    pro2F3Charge.feb23,
    pro2F1Charge.feb23 + pro2F2Charge.feb23 + pro2F3Charge.feb23
]
export const pro2WithFeb23Pay = [
    pro2F1Payment.feb23,
    pro2F2Payment.feb23,
    pro2F3Payment.feb23,
    pro2F1Payment.feb23 + pro2F2Payment.feb23 + pro2F3Payment.feb23
]
export const pro2WithFeb23Count = [
    pro2F1Collectable.feb23,
    pro2F2Collectable.feb23,
    pro2F3Collectable.feb23,
    pro2F1Collectable.feb23 + pro2F2Collectable.feb23 + pro2F3Collectable.feb23
]
export const pro2WithFeb23NonCount = [
    pro2F1NonCollectable.feb23,
    pro2F2NonCollectable.feb23,
    pro2F3NonCollectable.feb23,
    pro2F1NonCollectable.feb23 + pro2F2NonCollectable.feb23 + pro2F3NonCollectable.feb23
]
export const pro2WithFeb23Claim = [
    pro2F1ClaimCount.feb23,
    pro2F2ClaimCount.feb23,
    pro2F3ClaimCount.feb23,
    pro2F1ClaimCount.feb23 + pro2F2ClaimCount.feb23 + pro2F3ClaimCount.feb23
]
export const pro2WithFeb23PerVisit = [
    pro2F1PerVisit.feb23,
    pro2F2PerVisit.feb23,
    pro2F3PerVisit.feb23,
    provider2PerVisist[25]
]
// mar 23
export const pro2WithMar23Charges = [
    pro2F1Charge.mar23,
    pro2F2Charge.mar23,
    pro2F3Charge.mar23,
    pro2F1Charge.mar23 + pro2F2Charge.mar23 + pro2F3Charge.mar23
]
export const pro2WithMar23Pay = [
    pro2F1Payment.mar23,
    pro2F2Payment.mar23,
    pro2F3Payment.mar23,
    pro2F1Payment.mar23 + pro2F2Payment.mar23 + pro2F3Payment.mar23
]
export const pro2WithMar23Count = [
    pro2F1Collectable.mar23,
    pro2F2Collectable.mar23,
    pro2F3Collectable.mar23,
    pro2F1Collectable.mar23 + pro2F2Collectable.mar23 + pro2F3Collectable.mar23
]
export const pro2WithMar23NonCount = [
    pro2F1NonCollectable.mar23,
    pro2F2NonCollectable.mar23,
    pro2F3NonCollectable.mar23,
    pro2F1NonCollectable.mar23 + pro2F2NonCollectable.mar23 + pro2F3NonCollectable.mar23
]
export const pro2WithMar23Claim = [
    pro2F1ClaimCount.mar23,
    pro2F2ClaimCount.mar23,
    pro2F3ClaimCount.mar23,
    pro2F1ClaimCount.mar23 + pro2F2ClaimCount.mar23 + pro2F3ClaimCount.mar23
]
export const pro2WithMar23PerVisit = [
    pro2F1PerVisit.mar23,
    pro2F2PerVisit.mar23,
    pro2F3PerVisit.mar23,
    provider2PerVisist[26]
]
// april 23
export const pro2WithApr23Charges = [
    pro2F1Charge.apr23,
    pro2F2Charge.apr23,
    pro2F3Charge.apr23,
    pro2F1Charge.apr23 + pro2F2Charge.apr23 + pro2F3Charge.apr23
]
export const pro2WithApr23Pay = [
    pro2F1Payment.apr23,
    pro2F2Payment.apr23,
    pro2F3Payment.apr23,
    pro2F1Payment.apr23 + pro2F2Payment.apr23 + pro2F3Payment.apr23
]
export const pro2WithApr23Count = [
    pro2F1Collectable.apr23,
    pro2F2Collectable.apr23,
    pro2F3Collectable.apr23,
    pro2F1Collectable.apr23 + pro2F2Collectable.apr23 + pro2F3Collectable.apr23
]
export const pro2WithApr23NonCount = [
    pro2F1NonCollectable.apr23,
    pro2F2NonCollectable.apr23,
    pro2F3NonCollectable.apr23,
    pro2F1NonCollectable.apr23 + pro2F2NonCollectable.apr23 + pro2F3NonCollectable.apr23
]
export const pro2WithApr23Claim = [
    pro2F1ClaimCount.apr23,
    pro2F2ClaimCount.apr23,
    pro2F3ClaimCount.apr23,
    pro2F1ClaimCount.apr23 + pro2F2ClaimCount.apr23 + pro2F3ClaimCount.apr23
]
export const pro2WithApr23PerVisit = [
    pro2F1PerVisit.apr23,
    pro2F2PerVisit.apr23,
    pro2F3PerVisit.apr23,
    provider2PerVisist[27]
]
// may 23
export const pro2WithMay23Charges = [
    pro2F1Charge.may23,
    pro2F2Charge.may23,
    pro2F3Charge.may23,
    pro2F1Charge.may23 + pro2F2Charge.may23 + pro2F3Charge.may23
]
export const pro2WithMay23Pay = [
    pro2F1Payment.may23,
    pro2F2Payment.may23,
    pro2F3Payment.may23,
    pro2F1Payment.may23 + pro2F2Payment.may23 + pro2F3Payment.may23
]
export const pro2WithMay23Count = [
    pro2F1Collectable.may23,
    pro2F2Collectable.may23,
    pro2F3Collectable.may23,
    pro2F1Collectable.may23 + pro2F2Collectable.may23 + pro2F3Collectable.may23
]
export const pro2WithMay23NonCount = [
    pro2F1NonCollectable.may23,
    pro2F2NonCollectable.may23,
    pro2F3NonCollectable.may23,
    pro2F1NonCollectable.may23 + pro2F2NonCollectable.may23 + pro2F3NonCollectable.may23
]
export const pro2WithMay23Claim = [
    pro2F1ClaimCount.may23,
    pro2F2ClaimCount.may23,
    pro2F3ClaimCount.may23,
    pro2F1ClaimCount.may23 + pro2F2ClaimCount.may23 + pro2F3ClaimCount.may23
]
export const pro2WithMay23PerVisit = [
    pro2F1PerVisit.may23,
    pro2F2PerVisit.may23,
    pro2F3PerVisit.may23,
    provider2PerVisist[28]
]
// jun 23
export const pro2WithJun23Charges = [
    pro2F1Charge.jun23,
    pro2F2Charge.jun23,
    pro2F3Charge.jun23,
    pro2F1Charge.jun23 + pro2F2Charge.jun23 + pro2F3Charge.jun23
]
export const pro2WithJun23Pay = [
    pro2F1Payment.jun23,
    pro2F2Payment.jun23,
    pro2F3Payment.jun23,
    pro2F1Payment.jun23 + pro2F2Payment.jun23 + pro2F3Payment.jun23
]
export const pro2WithJun23Count = [
    pro2F1Collectable.jun23,
    pro2F2Collectable.jun23,
    pro2F3Collectable.jun23,
    pro2F1Collectable.jun23 + pro2F2Collectable.jun23 + pro2F3Collectable.jun23
]
export const pro2WithJun23NonCount = [
    pro2F1NonCollectable.jun23,
    pro2F2NonCollectable.jun23,
    pro2F3NonCollectable.jun23,
    pro2F1NonCollectable.jun23 + pro2F2NonCollectable.jun23 + pro2F3NonCollectable.jun23
]
export const pro2WithJun23Claim = [
    pro2F1ClaimCount.jun23,
    pro2F2ClaimCount.jun23,
    pro2F3ClaimCount.jun23,
    pro2F1ClaimCount.jun23 + pro2F2ClaimCount.jun23 + pro2F3ClaimCount.jun23
]
export const pro2WithJun23PerVisit = [
    pro2F1PerVisit.jun23,
    pro2F2PerVisit.jun23,
    pro2F3PerVisit.jun23,
    provider2PerVisist[29]
]
// jul 23
export const pro2WithJul23Charges = [
    pro2F1Charge.jul23,
    pro2F2Charge.jul23,
    pro2F3Charge.jul23,
    pro2F1Charge.jul23 + pro2F2Charge.jul23 + pro2F3Charge.jul23
]
export const pro2WithJul23Pay = [
    pro2F1Payment.jul23,
    pro2F2Payment.jul23,
    pro2F3Payment.jul23,
    pro2F1Payment.jul23 + pro2F2Payment.jul23 + pro2F3Payment.jul23
]
export const pro2WithJul23Count = [
    pro2F1Collectable.jul23,
    pro2F2Collectable.jul23,
    pro2F3Collectable.jul23,
    pro2F1Collectable.jul23 + pro2F2Collectable.jul23 + pro2F3Collectable.jul23
]
export const pro2WithJul23NonCount = [
    pro2F1NonCollectable.jul23,
    pro2F2NonCollectable.jul23,
    pro2F3NonCollectable.jul23,
    pro2F1NonCollectable.jul23 + pro2F2NonCollectable.jul23 + pro2F3NonCollectable.jul23
]
export const pro2WithJul23Claim = [
    pro2F1ClaimCount.jul23,
    pro2F2ClaimCount.jul23,
    pro2F3ClaimCount.jul23,
    pro2F1ClaimCount.jul23 + pro2F2ClaimCount.jul23 + pro2F3ClaimCount.jul23
]
export const pro2WithJul23PerVisit = [
    pro2F1PerVisit.jul23,
    pro2F2PerVisit.jul23,
    pro2F3PerVisit.jul23,
    provider2PerVisist[30]
]
// aug 23
export const pro2WithAug23Charges = [
    pro2F1Charge.aug23,
    pro2F2Charge.aug23,
    pro2F3Charge.aug23,
    pro2F1Charge.aug23 + pro2F2Charge.aug23 + pro2F3Charge.aug23
]
export const pro2WithAug23Pay = [
    pro2F1Payment.aug23,
    pro2F2Payment.aug23,
    pro2F3Payment.aug23,
    pro2F1Payment.aug23 + pro2F2Payment.aug23 + pro2F3Payment.aug23
]
export const pro2WithAug23Count = [
    pro2F1Collectable.aug23,
    pro2F2Collectable.aug23,
    pro2F3Collectable.aug23,
    pro2F1Collectable.aug23 + pro2F2Collectable.aug23 + pro2F3Collectable.aug23
]
export const pro2WithAug23NonCount = [
    pro2F1NonCollectable.aug23,
    pro2F2NonCollectable.aug23,
    pro2F3NonCollectable.aug23,
    pro2F1NonCollectable.aug23 + pro2F2NonCollectable.aug23 + pro2F3NonCollectable.aug23
]
export const pro2WithAug23Claim = [
    pro2F1ClaimCount.aug23,
    pro2F2ClaimCount.aug23,
    pro2F3ClaimCount.aug23,
    pro2F1ClaimCount.aug23 + pro2F2ClaimCount.aug23 + pro2F3ClaimCount.aug23
]
export const pro2WithAug23PerVisit = [
    pro2F1PerVisit.aug23,
    pro2F2PerVisit.aug23,
    pro2F3PerVisit.aug23,
    provider2PerVisist[31]
]
// sep 23
export const pro2WithSep23Charges = [
    pro2F1Charge.sep23,
    pro2F2Charge.sep23,
    pro2F3Charge.sep23,
    pro2F1Charge.sep23 + pro2F2Charge.sep23 + pro2F3Charge.sep23
]
export const pro2WithSep23Pay = [
    pro2F1Payment.sep23,
    pro2F2Payment.sep23,
    pro2F3Payment.sep23,
    pro2F1Payment.sep23 + pro2F2Payment.sep23 + pro2F3Payment.sep23
]
export const pro2WithSep23Count = [
    pro2F1Collectable.sep23,
    pro2F2Collectable.sep23,
    pro2F3Collectable.sep23,
    pro2F1Collectable.sep23 + pro2F2Collectable.sep23 + pro2F3Collectable.sep23
]
export const pro2WithSep23NonCount = [
    pro2F1NonCollectable.sep23,
    pro2F2NonCollectable.sep23,
    pro2F3NonCollectable.sep23,
    pro2F1NonCollectable.sep23 + pro2F2NonCollectable.sep23 + pro2F3NonCollectable.sep23
]
export const pro2WithSep23Claim = [
    pro2F1ClaimCount.sep23,
    pro2F2ClaimCount.sep23,
    pro2F3ClaimCount.sep23,
    pro2F1ClaimCount.sep23 + pro2F2ClaimCount.sep23 + pro2F3ClaimCount.sep23
]
export const pro2WithSep23PerVisit = [
    pro2F1PerVisit.sep23,
    pro2F2PerVisit.sep23,
    pro2F3PerVisit.sep23,
    provider2PerVisist[32]
]
// oct 23
export const pro2WithOct23Charges = [
    pro2F1Charge.oct23,
    pro2F2Charge.oct23,
    pro2F3Charge.oct23,
    pro2F1Charge.oct23 + pro2F2Charge.oct23 + pro2F3Charge.oct23
]
export const pro2WithOct23Pay = [
    pro2F1Payment.oct23,
    pro2F2Payment.oct23,
    pro2F3Payment.oct23,
    pro2F1Payment.oct23 + pro2F2Payment.oct23 + pro2F3Payment.oct23
]
export const pro2WithOct23Count = [
    pro2F1Collectable.oct23,
    pro2F2Collectable.oct23,
    pro2F3Collectable.oct23,
    pro2F1Collectable.oct23 + pro2F2Collectable.oct23 + pro2F3Collectable.oct23
]
export const pro2WithOct23NonCount = [
    pro2F1NonCollectable.oct23,
    pro2F2NonCollectable.oct23,
    pro2F3NonCollectable.oct23,
    pro2F1NonCollectable.oct23 + pro2F2NonCollectable.oct23 + pro2F3NonCollectable.oct23
]
export const pro2WithOct23Claim = [
    pro2F1ClaimCount.oct23,
    pro2F2ClaimCount.oct23,
    pro2F3ClaimCount.oct23,
    pro2F1ClaimCount.oct23 + pro2F2ClaimCount.oct23 + pro2F3ClaimCount.oct23
]
export const pro2WithOct23PerVisit = [
    pro2F1PerVisit.oct23,
    pro2F2PerVisit.oct23,
    pro2F3PerVisit.oct23,
    provider2PerVisist[33]
]
// nov 23
export const pro2WithNov23Charges = [
    pro2F1Charge.nov23,
    pro2F2Charge.nov23,
    pro2F3Charge.nov23,
    pro2F1Charge.nov23 + pro2F2Charge.nov23 + pro2F3Charge.nov23
]
export const pro2WithNov23Pay = [
    pro2F1Payment.nov23,
    pro2F2Payment.nov23,
    pro2F3Payment.nov23,
    pro2F1Payment.nov23 +
    pro2F2Payment.nov23 + 
    pro2F3Payment.nov23
]
export const pro2WithNov23Count = [
    pro2F1Collectable.nov23,
    pro2F2Collectable.nov23,
    pro2F3Collectable.nov23,
    pro2F1Collectable.nov23 +
    pro2F2Collectable.nov23 +
    pro2F3Collectable.nov23
]
export const pro2WithNov23NonCount = [
    pro2F1NonCollectable.nov23,
    pro2F2NonCollectable.nov23,
    pro2F3NonCollectable.nov23,
    pro2F1NonCollectable.nov23+
    pro2F2NonCollectable.nov23+
    pro2F3NonCollectable.nov23
]
export const pro2WithNov23Claim = [
    pro2F1ClaimCount.nov23,
    pro2F2ClaimCount.nov23,
    pro2F3ClaimCount.nov23,
    pro2F1ClaimCount.nov23 + pro2F2ClaimCount.nov23 + pro2F3ClaimCount.nov23
]
export const pro2WithNov23PerVisit = [
    pro2F1PerVisit.nov23,
    pro2F2PerVisit.nov23,
    pro2F3PerVisit.nov23,
    provider2PerVisist[34]
]
// comm

    // export const pro2WithJan21Charges = [
    //     pro2F1Charge.jan21,
    //     pro2F2Charge.jan21,
    //     pro2F3Charge.jan21
    // ]
    // export const pro2WithJan21Pay = [
    //     pro2F1Payment.jan21,
    //     pro2F2Payment.jan21,
    //     pro2F3Payment.jan21
    // ]
    // export const pro2WithJan21Count = [
    //     pro2F1Collectable.jan21,
    //     pro2F2Collectable.jan21,
    //     pro2F3Collectable.jan21
    // ]
    // export const pro2WithJan21NonCount = [
    //     pro2F1NonCollectable.jan21,
    //     pro2F2NonCollectable.jan21,
    //     pro2F3NonCollectable.jan21
    // ]
    // export const pro2WithJan21Claim = [
    //     pro2F1ClaimCount.jan21,
    //     pro2F2ClaimCount.jan21,
    //     pro2F3ClaimCount.jan21
    // ]
    // export const pro2WithJan21PerVisit = [
    //     pro2F1PerVisit.jan21,
    //     pro2F2PerVisit.jan21,
    //     pro2F3PerVisit.jan21
    // ]
    // // feb 21
    // export const pro2WithFeb21Charges = [
    //     pro2F1Charge.feb21,
    //     pro2F2Charge.feb21,
    //     pro2F3Charge.feb21
    // ]
    // export const pro2WithFeb21Pay = [
    //     pro2F1Payment.feb21,
    //     pro2F2Payment.feb21,
    //     pro2F3Payment.feb21
    // ]
    // export const pro2WithFeb21Count = [
    //     pro2F1Collectable.feb21,
    //     pro2F2Collectable.feb21,
    //     pro2F3Collectable.feb21
    // ]
    // export const pro2WithFeb21NonCount = [
    //     pro2F1NonCollectable.feb21,
    //     pro2F2NonCollectable.feb21,
    //     pro2F3NonCollectable.feb21
    // ]
    // export const pro2WithFeb21Claim = [
    //     pro2F1ClaimCount.feb21,
    //     pro2F2ClaimCount.feb21,
    //     pro2F3ClaimCount.feb21
    // ]
    // export const pro2WithFeb21PerVisit = [
    //     pro2F1PerVisit.feb21,
    //     pro2F2PerVisit.feb21,
    //     pro2F3PerVisit.feb21
    // ]
    // // mar 21
    // export const pro2WithMar21Charges = [
    //     pro2F1Charge.mar21,
    //     pro2F2Charge.mar21,
    //     pro2F3Charge.mar21
    // ]
    // export const pro2WithMar21Pay = [
    //     pro2F1Payment.mar21,
    //     pro2F2Payment.mar21,
    //     pro2F3Payment.mar21
    // ]
    // export const pro2WithMar21Count = [
    //     pro2F1Collectable.mar21,
    //     pro2F2Collectable.mar21,
    //     pro2F3Collectable.mar21
    // ]
    // export const pro2WithMar21NonCount = [
    //     pro2F1NonCollectable.mar21,
    //     pro2F2NonCollectable.mar21,
    //     pro2F3NonCollectable.mar21
    // ]
    // export const pro2WithMar21Claim = [
    //     pro2F1ClaimCount.mar21,
    //     pro2F2ClaimCount.mar21,
    //     pro2F3ClaimCount.mar21
    // ]
    // export const pro2WithMar21PerVisit = [
    //     pro2F1PerVisit.mar21,
    //     pro2F2PerVisit.mar21,
    //     pro2F3PerVisit.mar21
    // ]
    // // april 21
    // export const pro2WithApr21Charges = [
    //     pro2F1Charge.apr21,
    //     pro2F2Charge.apr21,
    //     pro2F3Charge.apr21
    // ]
    // export const pro2WithApr21Pay = [
    //     pro2F1Payment.apr21,
    //     pro2F2Payment.apr21,
    //     pro2F3Payment.apr21
    // ]
    // export const pro2WithApr21Count = [
    //     pro2F1Collectable.apr21,
    //     pro2F2Collectable.apr21,
    //     pro2F3Collectable.apr21
    // ]
    // export const pro2WithApr21NonCount = [
    //     pro2F1NonCollectable.apr21,
    //     pro2F2NonCollectable.apr21,
    //     pro2F3NonCollectable.apr21
    // ]
    // export const pro2WithApr21Claim = [
    //     pro2F1ClaimCount.apr21,
    //     pro2F2ClaimCount.apr21,
    //     pro2F3ClaimCount.apr21
    // ]
    // export const pro2WithApr21PerVisit = [
    //     pro2F1PerVisit.apr21,
    //     pro2F2PerVisit.apr21,
    //     pro2F3PerVisit.apr21
    // ]
    // // may 21
    // export const pro2WithMay21Charges = [
    //     pro2F1Charge.may21,
    //     pro2F2Charge.may21,
    //     pro2F3Charge.may21
    // ]
    // export const pro2WithMay21Pay = [
    //     pro2F1Payment.may21,
    //     pro2F2Payment.may21,
    //     pro2F3Payment.may21
    // ]
    // export const pro2WithMay21Count = [
    //     pro2F1Collectable.may21,
    //     pro2F2Collectable.may21,
    //     pro2F3Collectable.may21
    // ]
    // export const pro2WithMay21NonCount = [
    //     pro2F1NonCollectable.may21,
    //     pro2F2NonCollectable.may21,
    //     pro2F3NonCollectable.may21
    // ]
    // export const pro2WithMay21Claim = [
    //     pro2F1ClaimCount.may21,
    //     pro2F2ClaimCount.may21,
    //     pro2F3ClaimCount.may21
    // ]
    // export const pro2WithMay21PerVisit = [
    //     pro2F1PerVisit.may21,
    //     pro2F2PerVisit.may21,
    //     pro2F3PerVisit.may21
    // ]
    // // jun 21
    // export const pro2WithJun21Charges = [
    //     pro2F1Charge.jun21,
    //     pro2F2Charge.jun21,
    //     pro2F3Charge.jun21
    // ]
    // export const pro2WithJun21Pay = [
    //     pro2F1Payment.jun21,
    //     pro2F2Payment.jun21,
    //     pro2F3Payment.jun21
    // ]
    // export const pro2WithJun21Count = [
    //     pro2F1Collectable.jun21,
    //     pro2F2Collectable.jun21,
    //     pro2F3Collectable.jun21
    // ]
    // export const pro2WithJun21NonCount = [
    //     pro2F1NonCollectable.jun21,
    //     pro2F2NonCollectable.jun21,
    //     pro2F3NonCollectable.jun21
    // ]
    // export const pro2WithJun21Claim = [
    //     pro2F1ClaimCount.jun21,
    //     pro2F2ClaimCount.jun21,
    //     pro2F3ClaimCount.jun21
    // ]
    // export const pro2WithJun21PerVisit = [
    //     pro2F1PerVisit.jun21,
    //     pro2F2PerVisit.jun21,
    //     pro2F3PerVisit.jun21
    // ]
    // // jul 21
    // export const pro2WithJul21Charges = [
    //     pro2F1Charge.jul21,
    //     pro2F2Charge.jul21,
    //     pro2F3Charge.jul21
    // ]
    // export const pro2WithJul21Pay = [
    //     pro2F1Payment.jul21,
    //     pro2F2Payment.jul21,
    //     pro2F3Payment.jul21
    // ]
    // export const pro2WithJul21Count = [
    //     pro2F1Collectable.jul21,
    //     pro2F2Collectable.jul21,
    //     pro2F3Collectable.jul21
    // ]
    // export const pro2WithJul21NonCount = [
    //     pro2F1NonCollectable.jul21,
    //     pro2F2NonCollectable.jul21,
    //     pro2F3NonCollectable.jul21
    // ]
    // export const pro2WithJul21Claim = [
    //     pro2F1ClaimCount.jul21,
    //     pro2F2ClaimCount.jul21,
    //     pro2F3ClaimCount.jul21
    // ]
    // export const pro2WithJul21PerVisit = [
    //     pro2F1PerVisit.jul21,
    //     pro2F2PerVisit.jul21,
    //     pro2F3PerVisit.jul21
    // ]
    // // aug 21
    // export const pro2WithAug21Charges = [
    //     pro2F1Charge.aug21,
    //     pro2F2Charge.aug21,
    //     pro2F3Charge.aug21
    // ]
    // export const pro2WithAug21Pay = [
    //     pro2F1Payment.aug21,
    //     pro2F2Payment.aug21,
    //     pro2F3Payment.aug21
    // ]
    // export const pro2WithAug21Count = [
    //     pro2F1Collectable.aug21,
    //     pro2F2Collectable.aug21,
    //     pro2F3Collectable.aug21
    // ]
    // export const pro2WithAug21NonCount = [
    //     pro2F1NonCollectable.aug21,
    //     pro2F2NonCollectable.aug21,
    //     pro2F3NonCollectable.aug21
    // ]
    // export const pro2WithAug21Claim = [
    //     pro2F1ClaimCount.aug21,
    //     pro2F2ClaimCount.aug21,
    //     pro2F3ClaimCount.aug21
    // ]
    // export const pro2WithAug21PerVisit = [
    //     pro2F1PerVisit.aug21,
    //     pro2F2PerVisit.aug21,
    //     pro2F3PerVisit.aug21
    // ]
    // // sep 21
    // export const pro2WithSep21Charges = [
    //     pro2F1Charge.sep21,
    //     pro2F2Charge.sep21,
    //     pro2F3Charge.sep21
    // ]
    // export const pro2WithSep21Pay = [
    //     pro2F1Payment.sep21,
    //     pro2F2Payment.sep21,
    //     pro2F3Payment.sep21
    // ]
    // export const pro2WithSep21Count = [
    //     pro2F1Collectable.sep21,
    //     pro2F2Collectable.sep21,
    //     pro2F3Collectable.sep21
    // ]
    // export const pro2WithSep21NonCount = [
    //     pro2F1NonCollectable.sep21,
    //     pro2F2NonCollectable.sep21,
    //     pro2F3NonCollectable.sep21
    // ]
    // export const pro2WithSep21Claim = [
    //     pro2F1ClaimCount.sep21,
    //     pro2F2ClaimCount.sep21,
    //     pro2F3ClaimCount.sep21
    // ]
    // export const pro2WithSep21PerVisit = [
    //     pro2F1PerVisit.sep21,
    //     pro2F2PerVisit.sep21,
    //     pro2F3PerVisit.sep21
    // ]
    // // oct 21
    // export const pro2WithOct21Charges = [
    //     pro2F1Charge.oct21,
    //     pro2F2Charge.oct21,
    //     pro2F3Charge.oct21
    // ]
    // export const pro2WithOct21Pay = [
    //     pro2F1Payment.oct21,
    //     pro2F2Payment.oct21,
    //     pro2F3Payment.oct21
    // ]
    // export const pro2WithOct21Count = [
    //     pro2F1Collectable.oct21,
    //     pro2F2Collectable.oct21,
    //     pro2F3Collectable.oct21
    // ]
    // export const pro2WithOct21NonCount = [
    //     pro2F1NonCollectable.oct21,
    //     pro2F2NonCollectable.oct21,
    //     pro2F3NonCollectable.oct21
    // ]
    // export const pro2WithOct21Claim = [
    //     pro2F1ClaimCount.oct21,
    //     pro2F2ClaimCount.oct21,
    //     pro2F3ClaimCount.oct21
    // ]
    // export const pro2WithOct21PerVisit = [
    //     pro2F1PerVisit.oct21,
    //     pro2F2PerVisit.oct21,
    //     pro2F3PerVisit.oct21
    // ]
    // // nov 21
    // export const pro2WithNov21Charges = [
    //     pro2F1Charge.nov21,
    //     pro2F2Charge.nov21,
    //     pro2F3Charge.nov21
    // ]
    // export const pro2WithNov21Pay = [
    //     pro2F1Payment.nov21,
    //     pro2F2Payment.nov21,
    //     pro2F3Payment.nov21
    // ]
    // export const pro2WithNov21Count = [
    //     pro2F1Collectable.nov21,
    //     pro2F2Collectable.nov21,
    //     pro2F3Collectable.nov21
    // ]
    // export const pro2WithNov21NonCount = [
    //     pro2F1NonCollectable.nov21,
    //     pro2F2NonCollectable.nov21,
    //     pro2F3NonCollectable.nov21
    // ]
    // export const pro2WithNov21Claim = [
    //     pro2F1ClaimCount.nov21,
    //     pro2F2ClaimCount.nov21,
    //     pro2F3ClaimCount.nov21
    // ]
    // export const pro2WithNov21PerVisit = [
    //     pro2F1PerVisit.nov21,
    //     pro2F2PerVisit.nov21,
    //     pro2F3PerVisit.nov21
    // ]
    // // dec 21
    // export const pro2WithDec21Charges = [
    //     pro2F1Charge.dec21,
    //     pro2F2Charge.dec21,
    //     pro2F3Charge.dec21
    // ]
    // export const pro2WithDec21Pay = [
    //     pro2F1Payment.dec21,
    //     pro2F2Payment.dec21,
    //     pro2F3Payment.dec21
    // ]
    // export const pro2WithDec21Count = [
    //     pro2F1Collectable.dec21,
    //     pro2F2Collectable.dec21,
    //     pro2F3Collectable.dec21
    // ]
    // export const pro2WithDec21NonCount = [
    //     pro2F1NonCollectable.dec21,
    //     pro2F2NonCollectable.dec21,
    //     pro2F3NonCollectable.dec21
    // ]
    // export const pro2WithDec21Claim = [
    //     pro2F1ClaimCount.dec21,
    //     pro2F2ClaimCount.dec21,
    //     pro2F3ClaimCount.dec21
    // ]
    // export const pro2WithDec21PerVisit = [
    //     pro2F1PerVisit.dec21,
    //     pro2F2PerVisit.dec21,
    //     pro2F3PerVisit.dec21
    // ]
    
    // // provider-2 
    // // jan 22
    // export const pro2WithJan22Charges = [
    //     pro2F1Charge.jan22,
    //     pro2F2Charge.jan22,
    //     pro2F3Charge.jan22
    // ]
    // export const pro2WithJan22Pay = [
    //     pro2F1Payment.jan22,
    //     pro2F2Payment.jan22,
    //     pro2F3Payment.jan22
    // ]
    // export const pro2WithJan22Count = [
    //     pro2F1Collectable.jan22,
    //     pro2F2Collectable.jan22,
    //     pro2F3Collectable.jan22
    // ]
    // export const pro2WithJan22NonCount = [
    //     pro2F1NonCollectable.jan22,
    //     pro2F2NonCollectable.jan22,
    //     pro2F3NonCollectable.jan22
    // ]
    // export const pro2WithJan22Claim = [
    //     pro2F1ClaimCount.jan22,
    //     pro2F2ClaimCount.jan22,
    //     pro2F3ClaimCount.jan22
    // ]
    // export const pro2WithJan22PerVisit = [
    //     pro2F1PerVisit.jan22,
    //     pro2F2PerVisit.jan22,
    //     pro2F3PerVisit.jan22
    // ]
    // // feb 22
    // export const pro2WithFeb22Charges = [
    //     pro2F1Charge.feb22,
    //     pro2F2Charge.feb22,
    //     pro2F3Charge.feb22
    // ]
    // export const pro2WithFeb22Pay = [
    //     pro2F1Payment.feb22,
    //     pro2F2Payment.feb22,
    //     pro2F3Payment.feb22
    // ]
    // export const pro2WithFeb22Count = [
    //     pro2F1Collectable.feb22,
    //     pro2F2Collectable.feb22,
    //     pro2F3Collectable.feb22
    // ]
    // export const pro2WithFeb22NonCount = [
    //     pro2F1NonCollectable.feb22,
    //     pro2F2NonCollectable.feb22,
    //     pro2F3NonCollectable.feb22
    // ]
    // export const pro2WithFeb22Claim = [
    //     pro2F1ClaimCount.feb22,
    //     pro2F2ClaimCount.feb22,
    //     pro2F3ClaimCount.feb22
    // ]
    // export const pro2WithFeb22PerVisit = [
    //     pro2F1PerVisit.feb22,
    //     pro2F2PerVisit.feb22,
    //     pro2F3PerVisit.feb22
    // ]
    // // mar 22
    // export const pro2WithMar22Charges = [
    //     pro2F1Charge.mar22,
    //     pro2F2Charge.mar22,
    //     pro2F3Charge.mar22
    // ]
    // export const pro2WithMar22Pay = [
    //     pro2F1Payment.mar22,
    //     pro2F2Payment.mar22,
    //     pro2F3Payment.mar22
    // ]
    // export const pro2WithMar22Count = [
    //     pro2F1Collectable.mar22,
    //     pro2F2Collectable.mar22,
    //     pro2F3Collectable.mar22
    // ]
    // export const pro2WithMar22NonCount = [
    //     pro2F1NonCollectable.mar22,
    //     pro2F2NonCollectable.mar22,
    //     pro2F3NonCollectable.mar22
    // ]
    // export const pro2WithMar22Claim = [
    //     pro2F1ClaimCount.mar22,
    //     pro2F2ClaimCount.mar22,
    //     pro2F3ClaimCount.mar22
    // ]
    // export const pro2WithMar22PerVisit = [
    //     pro2F1PerVisit.mar22,
    //     pro2F2PerVisit.mar22,
    //     pro2F3PerVisit.mar22
    // ]
    // // april 22
    // export const pro2WithApr22Charges = [
    //     pro2F1Charge.apr22,
    //     pro2F2Charge.apr22,
    //     pro2F3Charge.apr22
    // ]
    // export const pro2WithApr22Pay = [
    //     pro2F1Payment.apr22,
    //     pro2F2Payment.apr22,
    //     pro2F3Payment.apr22
    // ]
    // export const pro2WithApr22Count = [
    //     pro2F1Collectable.apr22,
    //     pro2F2Collectable.apr22,
    //     pro2F3Collectable.apr22
    // ]
    // export const pro2WithApr22NonCount = [
    //     pro2F1NonCollectable.apr22,
    //     pro2F2NonCollectable.apr22,
    //     pro2F3NonCollectable.apr22
    // ]
    // export const pro2WithApr22Claim = [
    //     pro2F1ClaimCount.apr22,
    //     pro2F2ClaimCount.apr22,
    //     pro2F3ClaimCount.apr22
    // ]
    // export const pro2WithApr22PerVisit = [
    //     pro2F1PerVisit.apr22,
    //     pro2F2PerVisit.apr22,
    //     pro2F3PerVisit.apr22
    // ]
    // // may 22
    // export const pro2WithMay22Charges = [
    //     pro2F1Charge.may22,
    //     pro2F2Charge.may22,
    //     pro2F3Charge.may22
    // ]
    // export const pro2WithMay22Pay = [
    //     pro2F1Payment.may22,
    //     pro2F2Payment.may22,
    //     pro2F3Payment.may22
    // ]
    // export const pro2WithMay22Count = [
    //     pro2F1Collectable.may22,
    //     pro2F2Collectable.may22,
    //     pro2F3Collectable.may22
    // ]
    // export const pro2WithMay22NonCount = [
    //     pro2F1NonCollectable.may22,
    //     pro2F2NonCollectable.may22,
    //     pro2F3NonCollectable.may22
    // ]
    // export const pro2WithMay22Claim = [
    //     pro2F1ClaimCount.may22,
    //     pro2F2ClaimCount.may22,
    //     pro2F3ClaimCount.may22
    // ]
    // export const pro2WithMay22PerVisit = [
    //     pro2F1PerVisit.may22,
    //     pro2F2PerVisit.may22,
    //     pro2F3PerVisit.may22
    // ]
    // // jun 22
    // export const pro2WithJun22Charges = [
    //     pro2F1Charge.jun22,
    //     pro2F2Charge.jun22,
    //     pro2F3Charge.jun22
    // ]
    // export const pro2WithJun22Pay = [
    //     pro2F1Payment.jun22,
    //     pro2F2Payment.jun22,
    //     pro2F3Payment.jun22
    // ]
    // export const pro2WithJun22Count = [
    //     pro2F1Collectable.jun22,
    //     pro2F2Collectable.jun22,
    //     pro2F3Collectable.jun22
    // ]
    // export const pro2WithJun22NonCount = [
    //     pro2F1NonCollectable.jun22,
    //     pro2F2NonCollectable.jun22,
    //     pro2F3NonCollectable.jun22
    // ]
    // export const pro2WithJun22Claim = [
    //     pro2F1ClaimCount.jun22,
    //     pro2F2ClaimCount.jun22,
    //     pro2F3ClaimCount.jun22
    // ]
    // export const pro2WithJun22PerVisit = [
    //     pro2F1PerVisit.jun22,
    //     pro2F2PerVisit.jun22,
    //     pro2F3PerVisit.jun22
    // ]
    // // jul 22
    // export const pro2WithJul22Charges = [
    //     pro2F1Charge.jul22,
    //     pro2F2Charge.jul22,
    //     pro2F3Charge.jul22
    // ]
    // export const pro2WithJul22Pay = [
    //     pro2F1Payment.jul22,
    //     pro2F2Payment.jul22,
    //     pro2F3Payment.jul22
    // ]
    // export const pro2WithJul22Count = [
    //     pro2F1Collectable.jul22,
    //     pro2F2Collectable.jul22,
    //     pro2F3Collectable.jul22
    // ]
    // export const pro2WithJul22NonCount = [
    //     pro2F1NonCollectable.jul22,
    //     pro2F2NonCollectable.jul22,
    //     pro2F3NonCollectable.jul22
    // ]
    // export const pro2WithJul22Claim = [
    //     pro2F1ClaimCount.jul22,
    //     pro2F2ClaimCount.jul22,
    //     pro2F3ClaimCount.jul22
    // ]
    // export const pro2WithJul22PerVisit = [
    //     pro2F1PerVisit.jul22,
    //     pro2F2PerVisit.jul22,
    //     pro2F3PerVisit.jul22
    // ]
    // // aug 22
    // export const pro2WithAug22Charges = [
    //     pro2F1Charge.aug22,
    //     pro2F2Charge.aug22,
    //     pro2F3Charge.aug22
    // ]
    // export const pro2WithAug22Pay = [
    //     pro2F1Payment.aug22,
    //     pro2F2Payment.aug22,
    //     pro2F3Payment.aug22
    // ]
    // export const pro2WithAug22Count = [
    //     pro2F1Collectable.aug22,
    //     pro2F2Collectable.aug22,
    //     pro2F3Collectable.aug22
    // ]
    // export const pro2WithAug22NonCount = [
    //     pro2F1NonCollectable.aug22,
    //     pro2F2NonCollectable.aug22,
    //     pro2F3NonCollectable.aug22
    // ]
    // export const pro2WithAug22Claim = [
    //     pro2F1ClaimCount.aug22,
    //     pro2F2ClaimCount.aug22,
    //     pro2F3ClaimCount.aug22
    // ]
    // export const pro2WithAug22PerVisit = [
    //     pro2F1PerVisit.aug22,
    //     pro2F2PerVisit.aug22,
    //     pro2F3PerVisit.aug22
    // ]
    // // sep 22
    // export const pro2WithSep22Charges = [
    //     pro2F1Charge.sep22,
    //     pro2F2Charge.sep22,
    //     pro2F3Charge.sep22
    // ]
    // export const pro2WithSep22Pay = [
    //     pro2F1Payment.sep22,
    //     pro2F2Payment.sep22,
    //     pro2F3Payment.sep22
    // ]
    // export const pro2WithSep22Count = [
    //     pro2F1Collectable.sep22,
    //     pro2F2Collectable.sep22,
    //     pro2F3Collectable.sep22
    // ]
    // export const pro2WithSep22NonCount = [
    //     pro2F1NonCollectable.sep22,
    //     pro2F2NonCollectable.sep22,
    //     pro2F3NonCollectable.sep22
    // ]
    // export const pro2WithSep22Claim = [
    //     pro2F1ClaimCount.sep22,
    //     pro2F2ClaimCount.sep22,
    //     pro2F3ClaimCount.sep22
    // ]
    // export const pro2WithSep22PerVisit = [
    //     pro2F1PerVisit.sep22,
    //     pro2F2PerVisit.sep22,
    //     pro2F3PerVisit.sep22
    // ]
    // // oct 22
    // export const pro2WithOct22Charges = [
    //     pro2F1Charge.oct22,
    //     pro2F2Charge.oct22,
    //     pro2F3Charge.oct22
    // ]
    // export const pro2WithOct22Pay = [
    //     pro2F1Payment.oct22,
    //     pro2F2Payment.oct22,
    //     pro2F3Payment.oct22
    // ]
    // export const pro2WithOct22Count = [
    //     pro2F1Collectable.oct22,
    //     pro2F2Collectable.oct22,
    //     pro2F3Collectable.oct22
    // ]
    // export const pro2WithOct22NonCount = [
    //     pro2F1NonCollectable.oct22,
    //     pro2F2NonCollectable.oct22,
    //     pro2F3NonCollectable.oct22
    // ]
    // export const pro2WithOct22Claim = [
    //     pro2F1ClaimCount.oct22,
    //     pro2F2ClaimCount.oct22,
    //     pro2F3ClaimCount.oct22
    // ]
    // export const pro2WithOct22PerVisit = [
    //     pro2F1PerVisit.oct22,
    //     pro2F2PerVisit.oct22,
    //     pro2F3PerVisit.oct22
    // ]
    // // nov 22
    // export const pro2WithNov22Charges = [
    //     pro2F1Charge.nov22,
    //     pro2F2Charge.nov22,
    //     pro2F3Charge.nov22
    // ]
    // export const pro2WithNov22Pay = [
    //     pro2F1Payment.nov22,
    //     pro2F2Payment.nov22,
    //     pro2F3Payment.nov22
    // ]
    // export const pro2WithNov22Count = [
    //     pro2F1Collectable.nov22,
    //     pro2F2Collectable.nov22,
    //     pro2F3Collectable.nov22
    // ]
    // export const pro2WithNov22NonCount = [
    //     pro2F1NonCollectable.nov22,
    //     pro2F2NonCollectable.nov22,
    //     pro2F3NonCollectable.nov22
    // ]
    // export const pro2WithNov22Claim = [
    //     pro2F1ClaimCount.nov22,
    //     pro2F2ClaimCount.nov22,
    //     pro2F3ClaimCount.nov22
    // ]
    // export const pro2WithNov22PerVisit = [
    //     pro2F1PerVisit.nov22,
    //     pro2F2PerVisit.nov22,
    //     pro2F3PerVisit.nov22
    // ]
    // // dec 22
    // export const pro2WithDec22Charges = [
    //     pro2F1Charge.dec22,
    //     pro2F2Charge.dec22,
    //     pro2F3Charge.dec22
    // ]
    // export const pro2WithDec22Pay = [
    //     pro2F1Payment.dec22,
    //     pro2F2Payment.dec22,
    //     pro2F3Payment.dec22
    // ]
    // export const pro2WithDec22Count = [
    //     pro2F1Collectable.dec22,
    //     pro2F2Collectable.dec22,
    //     pro2F3Collectable.dec22
    // ]
    // export const pro2WithDec22NonCount = [
    //     pro2F1NonCollectable.dec22,
    //     pro2F2NonCollectable.dec22,
    //     pro2F3NonCollectable.dec22
    // ]
    // export const pro2WithDec22Claim = [
    //     pro2F1ClaimCount.dec22,
    //     pro2F2ClaimCount.dec22,
    //     pro2F3ClaimCount.dec22
    // ]
    // export const pro2WithDec22PerVisit = [
    //     pro2F1PerVisit.dec22,
    //     pro2F2PerVisit.dec22,
    //     pro2F3PerVisit.dec22
    // ]
    
    // // provider-2
    // // jan 23
    // export const pro2WithJan23Charges = [
    //     pro2F1Charge.jan23,
    //     pro2F2Charge.jan23,
    //     pro2F3Charge.jan23
    // ]
    // export const pro2WithJan23Pay = [
    //     pro2F1Payment.jan23,
    //     pro2F2Payment.jan23,
    //     pro2F3Payment.jan23
    // ]
    // export const pro2WithJan23Count = [
    //     pro2F1Collectable.jan23,
    //     pro2F2Collectable.jan23,
    //     pro2F3Collectable.jan23
    // ]
    // export const pro2WithJan23NonCount = [
    //     pro2F1NonCollectable.jan23,
    //     pro2F2NonCollectable.jan23,
    //     pro2F3NonCollectable.jan23
    // ]
    // export const pro2WithJan23Claim = [
    //     pro2F1ClaimCount.jan23,
    //     pro2F2ClaimCount.jan23,
    //     pro2F3ClaimCount.jan23
    // ]
    // export const pro2WithJan23PerVisit = [
    //     pro2F1PerVisit.jan23,
    //     pro2F2PerVisit.jan23,
    //     pro2F3PerVisit.jan23
    // ]
    // // feb 23
    // export const pro2WithFeb23Charges = [
    //     pro2F1Charge.feb23,
    //     pro2F2Charge.feb23,
    //     pro2F3Charge.feb23
    // ]
    // export const pro2WithFeb23Pay = [
    //     pro2F1Payment.feb23,
    //     pro2F2Payment.feb23,
    //     pro2F3Payment.feb23
    // ]
    // export const pro2WithFeb23Count = [
    //     pro2F1Collectable.feb23,
    //     pro2F2Collectable.feb23,
    //     pro2F3Collectable.feb23
    // ]
    // export const pro2WithFeb23NonCount = [
    //     pro2F1NonCollectable.feb23,
    //     pro2F2NonCollectable.feb23,
    //     pro2F3NonCollectable.feb23
    // ]
    // export const pro2WithFeb23Claim = [
    //     pro2F1ClaimCount.feb23,
    //     pro2F2ClaimCount.feb23,
    //     pro2F3ClaimCount.feb23
    // ]
    // export const pro2WithFeb23PerVisit = [
    //     pro2F1PerVisit.feb23,
    //     pro2F2PerVisit.feb23,
    //     pro2F3PerVisit.feb23
    // ]
    // // mar 23
    // export const pro2WithMar23Charges = [
    //     pro2F1Charge.mar23,
    //     pro2F2Charge.mar23,
    //     pro2F3Charge.mar23
    // ]
    // export const pro2WithMar23Pay = [
    //     pro2F1Payment.mar23,
    //     pro2F2Payment.mar23,
    //     pro2F3Payment.mar23
    // ]
    // export const pro2WithMar23Count = [
    //     pro2F1Collectable.mar23,
    //     pro2F2Collectable.mar23,
    //     pro2F3Collectable.mar23
    // ]
    // export const pro2WithMar23NonCount = [
    //     pro2F1NonCollectable.mar23,
    //     pro2F2NonCollectable.mar23,
    //     pro2F3NonCollectable.mar23
    // ]
    // export const pro2WithMar23Claim = [
    //     pro2F1ClaimCount.mar23,
    //     pro2F2ClaimCount.mar23,
    //     pro2F3ClaimCount.mar23
    // ]
    // export const pro2WithMar23PerVisit = [
    //     pro2F1PerVisit.mar23,
    //     pro2F2PerVisit.mar23,
    //     pro2F3PerVisit.mar23
    // ]
    // // april 23
    // export const pro2WithApr23Charges = [
    //     pro2F1Charge.apr23,
    //     pro2F2Charge.apr23,
    //     pro2F3Charge.apr23
    // ]
    // export const pro2WithApr23Pay = [
    //     pro2F1Payment.apr23,
    //     pro2F2Payment.apr23,
    //     pro2F3Payment.apr23
    // ]
    // export const pro2WithApr23Count = [
    //     pro2F1Collectable.apr23,
    //     pro2F2Collectable.apr23,
    //     pro2F3Collectable.apr23
    // ]
    // export const pro2WithApr23NonCount = [
    //     pro2F1NonCollectable.apr23,
    //     pro2F2NonCollectable.apr23,
    //     pro2F3NonCollectable.apr23
    // ]
    // export const pro2WithApr23Claim = [
    //     pro2F1ClaimCount.apr23,
    //     pro2F2ClaimCount.apr23,
    //     pro2F3ClaimCount.apr23
    // ]
    // export const pro2WithApr23PerVisit = [
    //     pro2F1PerVisit.apr23,
    //     pro2F2PerVisit.apr23,
    //     pro2F3PerVisit.apr23
    // ]
    // // may 23
    // export const pro2WithMay23Charges = [
    //     pro2F1Charge.may23,
    //     pro2F2Charge.may23,
    //     pro2F3Charge.may23
    // ]
    // export const pro2WithMay23Pay = [
    //     pro2F1Payment.may23,
    //     pro2F2Payment.may23,
    //     pro2F3Payment.may23
    // ]
    // export const pro2WithMay23Count = [
    //     pro2F1Collectable.may23,
    //     pro2F2Collectable.may23,
    //     pro2F3Collectable.may23
    // ]
    // export const pro2WithMay23NonCount = [
    //     pro2F1NonCollectable.may23,
    //     pro2F2NonCollectable.may23,
    //     pro2F3NonCollectable.may23
    // ]
    // export const pro2WithMay23Claim = [
    //     pro2F1ClaimCount.may23,
    //     pro2F2ClaimCount.may23,
    //     pro2F3ClaimCount.may23
    // ]
    // export const pro2WithMay23PerVisit = [
    //     pro2F1PerVisit.may23,
    //     pro2F2PerVisit.may23,
    //     pro2F3PerVisit.may23
    // ]
    // // jun 23
    // export const pro2WithJun23Charges = [
    //     pro2F1Charge.jun23,
    //     pro2F2Charge.jun23,
    //     pro2F3Charge.jun23
    // ]
    // export const pro2WithJun23Pay = [
    //     pro2F1Payment.jun23,
    //     pro2F2Payment.jun23,
    //     pro2F3Payment.jun23
    // ]
    // export const pro2WithJun23Count = [
    //     pro2F1Collectable.jun23,
    //     pro2F2Collectable.jun23,
    //     pro2F3Collectable.jun23
    // ]
    // export const pro2WithJun23NonCount = [
    //     pro2F1NonCollectable.jun23,
    //     pro2F2NonCollectable.jun23,
    //     pro2F3NonCollectable.jun23
    // ]
    // export const pro2WithJun23Claim = [
    //     pro2F1ClaimCount.jun23,
    //     pro2F2ClaimCount.jun23,
    //     pro2F3ClaimCount.jun23
    // ]
    // export const pro2WithJun23PerVisit = [
    //     pro2F1PerVisit.jun23,
    //     pro2F2PerVisit.jun23,
    //     pro2F3PerVisit.jun23
    // ]
    // // jul 23
    // export const pro2WithJul23Charges = [
    //     pro2F1Charge.jul23,
    //     pro2F2Charge.jul23,
    //     pro2F3Charge.jul23
    // ]
    // export const pro2WithJul23Pay = [
    //     pro2F1Payment.jul23,
    //     pro2F2Payment.jul23,
    //     pro2F3Payment.jul23
    // ]
    // export const pro2WithJul23Count = [
    //     pro2F1Collectable.jul23,
    //     pro2F2Collectable.jul23,
    //     pro2F3Collectable.jul23
    // ]
    // export const pro2WithJul23NonCount = [
    //     pro2F1NonCollectable.jul23,
    //     pro2F2NonCollectable.jul23,
    //     pro2F3NonCollectable.jul23
    // ]
    // export const pro2WithJul23Claim = [
    //     pro2F1ClaimCount.jul23,
    //     pro2F2ClaimCount.jul23,
    //     pro2F3ClaimCount.jul23
    // ]
    // export const pro2WithJul23PerVisit = [
    //     pro2F1PerVisit.jul23,
    //     pro2F2PerVisit.jul23,
    //     pro2F3PerVisit.jul23
    // ]
    // // aug 23
    // export const pro2WithAug23Charges = [
    //     pro2F1Charge.aug23,
    //     pro2F2Charge.aug23,
    //     pro2F3Charge.aug23
    // ]
    // export const pro2WithAug23Pay = [
    //     pro2F1Payment.aug23,
    //     pro2F2Payment.aug23,
    //     pro2F3Payment.aug23
    // ]
    // export const pro2WithAug23Count = [
    //     pro2F1Collectable.aug23,
    //     pro2F2Collectable.aug23,
    //     pro2F3Collectable.aug23
    // ]
    // export const pro2WithAug23NonCount = [
    //     pro2F1NonCollectable.aug23,
    //     pro2F2NonCollectable.aug23,
    //     pro2F3NonCollectable.aug23
    // ]
    // export const pro2WithAug23Claim = [
    //     pro2F1ClaimCount.aug23,
    //     pro2F2ClaimCount.aug23,
    //     pro2F3ClaimCount.aug23
    // ]
    // export const pro2WithAug23PerVisit = [
    //     pro2F1PerVisit.aug23,
    //     pro2F2PerVisit.aug23,
    //     pro2F3PerVisit.aug23
    // ]
    // // sep 23
    // export const pro2WithSep23Charges = [
    //     pro2F1Charge.sep23,
    //     pro2F2Charge.sep23,
    //     pro2F3Charge.sep23
    // ]
    // export const pro2WithSep23Pay = [
    //     pro2F1Payment.sep23,
    //     pro2F2Payment.sep23,
    //     pro2F3Payment.sep23
    // ]
    // export const pro2WithSep23Count = [
    //     pro2F1Collectable.sep23,
    //     pro2F2Collectable.sep23,
    //     pro2F3Collectable.sep23
    // ]
    // export const pro2WithSep23NonCount = [
    //     pro2F1NonCollectable.sep23,
    //     pro2F2NonCollectable.sep23,
    //     pro2F3NonCollectable.sep23
    // ]
    // export const pro2WithSep23Claim = [
    //     pro2F1ClaimCount.sep23,
    //     pro2F2ClaimCount.sep23,
    //     pro2F3ClaimCount.sep23
    // ]
    // export const pro2WithSep23PerVisit = [
    //     pro2F1PerVisit.sep23,
    //     pro2F2PerVisit.sep23,
    //     pro2F3PerVisit.sep23
    // ]
    // // oct 23
    // export const pro2WithOct23Charges = [
    //     pro2F1Charge.oct23,
    //     pro2F2Charge.oct23,
    //     pro2F3Charge.oct23
    // ]
    // export const pro2WithOct23Pay = [
    //     pro2F1Payment.oct23,
    //     pro2F2Payment.oct23,
    //     pro2F3Payment.oct23
    // ]
    // export const pro2WithOct23Count = [
    //     pro2F1Collectable.oct23,
    //     pro2F2Collectable.oct23,
    //     pro2F3Collectable.oct23
    // ]
    // export const pro2WithOct23NonCount = [
    //     pro2F1NonCollectable.oct23,
    //     pro2F2NonCollectable.oct23,
    //     pro2F3NonCollectable.oct23
    // ]
    // export const pro2WithOct23Claim = [
    //     pro2F1ClaimCount.oct23,
    //     pro2F2ClaimCount.oct23,
    //     pro2F3ClaimCount.oct23
    // ]
    // export const pro2WithOct23PerVisit = [
    //     pro2F1PerVisit.oct23,
    //     pro2F2PerVisit.oct23,
    //     pro2F3PerVisit.oct23
    // ]
    // // nov 23
    // export const pro2WithNov23Charges = [
    //     pro2F1Charge.nov23,
    //     pro2F2Charge.nov23,
    //     pro2F3Charge.nov23
    // ]
    // export const pro2WithNov23Pay = [
    //     pro2F1Payment.nov23,
    //     pro2F2Payment.nov23,
    //     pro2F3Payment.nov23
    // ]
    // export const pro2WithNov23Count = [
    //     pro2F1Collectable.nov23,
    //     pro2F2Collectable.nov23,
    //     pro2F3Collectable.nov23
    // ]
    // export const pro2WithNov23NonCount = [
    //     pro2F1NonCollectable.nov23,
    //     pro2F2NonCollectable.nov23,
    //     pro2F3NonCollectable.nov23
    // ]
    // export const pro2WithNov23Claim = [
    //     pro2F1ClaimCount.nov23,
    //     pro2F2ClaimCount.nov23,
    //     pro2F3ClaimCount.nov23
    // ]
    // export const pro2WithNov23PerVisit = [
    //     pro2F1PerVisit.nov23,
    //     pro2F2PerVisit.nov23,
    //     pro2F3PerVisit.nov23
    // ]








