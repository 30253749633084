import './App.css';
import { Suspense, useState } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import '../node_modules/font-awesome/css/font-awesome.min.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/@material-ui/icons/index'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import OverAllProvider from './DashBoards/Provider/OverAllProvider';
import { Row, Nav, NavLink, NavItem, TabPane, TabContent, Col } from 'reactstrap'
import ClaimDashBoard from './DashBoards/ClaimSummary/ClaimDashBoard';
import ERABlocks from './DashBoards/ERAStatus/ERABlocks';
import RejectedClaimsDashBoard from './DashBoards/RejectedClaims/RejectedClaimsDashBoard';
import CPTDashBoards from './DashBoards/CPTLevelPayment/CPTDashBoards';
import LoginPage from './Login/LoginPage';
import TabData from './Tabs/TabData';
import RxRevenue from './Images/RxRevenue.png'
import Footer from './DashBoards/Footer/Footer';
import wave from './Images/wave.png'


function App() {
  const location = useLocation()
  const [activeTab, setActiveTab] = useState('1');
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <>
      {
        location.pathname !== '/' ? <Row className='m-0' style={{position:'fixed',top:'0px',width:'100%',zIndex:'1'}}>
          <small className='text-center fs-3'
            style={{ position: 'absolute', left: '50%', transform: 'translate(-50%)', top: '25px' }}
          >{sessionStorage.getItem('name') === 'PersonalClinic' ? 
          'Personal Physicians Provider Performance Report August 2024' : sessionStorage.getItem('name') === 'RX' ? 'RxRevenue Monthly Performance Report November 2023' :
          'Aurora Pain Clinic Provider Performance Report August 2024'}</small>
          <Row className='mb-5 m-0 d-flex justify-content-center flex-row border-bottom shadow-sm' 
          style={{ background: '#F3F3F3' }}>
            <Col className='d-flex justify-content-end flex-row'>
              <img
                src={RxRevenue}
                style={{ width: 190, height: 100, position: 'relative', right: 0, }}
              />
            </Col>
          </Row>
        </Row> : ''
      }
      <Suspense fallback="Loading">

        <Routes>
          <Route path='/' exact element={<LoginPage />} />
          <Route path={`/dashboard`} element={<TabData />} />
        </Routes>
        <Footer />
      </Suspense>
    </>

  );
}

export default App;
