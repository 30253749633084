import React, { useState, useEffect } from 'react'
import OverAllProvider from '../DashBoards/Provider/OverAllProvider';
import { Row, Nav, NavLink, NavItem, TabPane, TabContent, Col, Button } from 'reactstrap'
import ClaimDashBoard from '../DashBoards/ClaimSummary/ClaimDashBoard';
import ERABlocks from '../DashBoards/ERAStatus/ERABlocks';
import RejectedClaimsDashBoard from '../DashBoards/RejectedClaims/RejectedClaimsDashBoard';
import CPTDashBoards from '../DashBoards/CPTLevelPayment/CPTDashBoards';
import { useLocation, useNavigate } from 'react-router-dom';
import CredentialChart from '../DashBoards/CredentialStatus/CredentialChart';
const TabData = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [query, setQuery] = useState('')

    const [activeTab, setActiveTab] = useState('1');
    useEffect(() => {
        if (!sessionStorage.getItem('name')) {
            return navigate('/', {
                replace: true
            })
        }
        const queryData = new URLSearchParams(location.search)
        setQuery(queryData.get('name'))
    }, [])
    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    const handleLogout = () => {
        sessionStorage.setItem('name', '')
        return navigate('/', {
            replace: true
        })
    }
    return (
        <div style={{marginTop:'130px'}}>
            <Button
                className='mb-2  btn text-white mx-2 '
                color='danger'
                onClick={() => handleLogout()}
            >Logout</Button>

            <Nav tabs  >
                <NavItem >
                    <NavLink style={{ cursor: 'pointer' }}
                        className={activeTab === '1' ? 'active ' : ' text-dark'}
                        onClick={() => {
                            toggle('1');
                        }}
                    >
                        Provider Summary
                    </NavLink>
                </NavItem >
                <NavItem >
                    <NavLink style={{ cursor: 'pointer' }}
                        className={activeTab === '2' ? 'active ' : ' text-dark'}
                        onClick={() => {
                            toggle('2');
                        }}
                    >
                        Claim Summary
                    </NavLink>
                </NavItem >
                <NavItem >
                    <NavLink style={{ cursor: 'pointer' }}
                        className={activeTab === '3' ? 'active ' : 'text-dark'}
                        onClick={() => {
                            toggle('3');
                        }}
                    >
                        ERA Status
                    </NavLink>
                </NavItem >
                <NavItem hidden={sessionStorage.getItem('name') === 'RX' ? false : true}>
                    <NavLink style={{ cursor: 'pointer' }}
                        className={activeTab === '4' ? 'active ' : 'text-dark'}
                        onClick={() => {
                            toggle('4');
                        }}
                    >
                        Credentialialing status
                    </NavLink>
                </NavItem >
                <NavItem >
                    <NavLink style={{ cursor: 'pointer' }}
                        className={activeTab === '5' ? 'active ' : 'text-dark'}
                        onClick={() => {
                            toggle('5');
                        }}
                    >
                        Rejected Claim
                    </NavLink>
                </NavItem >
                <NavItem >
                    <NavLink style={{ cursor: 'pointer' }}
                        className={activeTab === '6' ? 'active ' : 'text-dark'}
                        onClick={() => {
                            toggle('6');
                        }}
                    >
                        CPT Level Payment
                    </NavLink>
                </NavItem >
                {/* <NavItem >
          <NavLink style={{ cursor: 'pointer' }}
            className={activeTab === '6' ? 'active ' : 'text-wdark}
            onClick={() => {
              toggle('6');
            }}
          >
            Insurance Aging
          </NavLink>
        </NavItem > */}
            </Nav>
            <TabContent className="p-4" activeTab={activeTab}>
                <TabPane tabId="1">
                    <OverAllProvider />
                </TabPane>
                <TabPane tabId="2">
                    <ClaimDashBoard />
                </TabPane>
                <TabPane tabId="3">
                    <ERABlocks />
                </TabPane>
                <TabPane tabId="4">
                    <CredentialChart />
                </TabPane>
                <TabPane tabId="5">
                    <RejectedClaimsDashBoard />
                </TabPane>
                <TabPane tabId="6">
                    <CPTDashBoards />
                </TabPane>
                {/* <TabPane tabId="6">
          <InsuranceDashboard />
        </TabPane> */}
            </TabContent>


        </div>
    )
}

export default TabData