import { transamericapayments, transamericaunits, travellerpayments, travellerunits, tricarepayments, tricareunits, trustmarkpayments, trustmarkunits, unifiedpayments, unifiedunits, unitednationalpayments, unitednationalunits, unitedsharedpayments, unitedsharedunits, unitedworldpayments, unitedworldunits, unknownpayments, unknownunits, walmartpayments, walmartunits, webtpapayments, webtpaunits, wellfleetpayments, wellfleetunits, wellnetpayments, wellnetunits, zurichpayments, zurichunits, unitedHeartLandUnit, unitedHeartPayments } from './CPTData11';
import { rslpayments, rslunits, sanapayments, sanaunits, sedgwickpaymenst, sedgwickunits, sentrypayments, sentryunits, smarthealthapayments, smarthealthunits, societypayments, societyunits, starmackpayments, starmackunits, statefarmpayments, statefarmunits, studentresourcepayments, studentresourceunits, transactrixpayments, transactrixunits } from './CPTData10';
import { panamericanpayments, panamericanunits, patientadvpayments, patientadvunits, planstinpayments, planstinunits, pmapayments, pmaunits, prairiepayments, prairieunits, preferedpayments, preferedunits, premierpayments, premierunits, railroadpayments, railroadunits, risingmedicalpayments, risingmedicalunits } from './CPTData9';
import { medisharepayments, medishareunits, meridianunits, meridinapayments, meritainpayments, meritainunits, midwestpayments, midwestunits, nalcpayments, nalcunits, nationalgeneralpayments, nationalgeneralunits, northamericapayments, northamericaunits, oscarpayments, oscarunits, paipayments, paiunits } from './CPTData8';
import { illinoiscasualtyunits, illinoispublicunits, internationalbenefitunits, ipmgunits, keybenefitunits, kpicunits, laborersunits, libertyunits, lucentunits, assachusetunits, illinoiscasualtypayments, illinoispublicpayments, internationalbenefitpayments, ipmgpayments, keybenefitpayments, kpicpayments, laborerspayments, lucentpayments, assachusetpayments, libertypayments } from './CPTData7';
import { groupadminiunits, guideoneunits, hanoverunits, hardfortunits, healthlinkunits, performanceunits, hmaunits, icwunits, idpaunits, groupadminipayments, guideonepayments, hanoverpayments, hardfortpayments, healthlinkpayments, performancepayments, hmapayments, icupayments, idpapayments } from './CPTData6';
import { corvelpayments, corvelunits, crumforesterpayments, crumforesterunits, emcpayments, emcunits, eriepayments, erieunits, esispayments, esisunits, fhnpayments, fhnunits, foreignservicepayments, foreignserviceunits, freedomlifepayments, freedomlifeunits, GALLAGHERpayments, GALLAGHERunits, goldenrulepayments, goldenruleunits } from './CPTData5';
import { brighthealthpayments, brighthealthunits, broadspirepayments, broadspireunits, californiapayments, californiaunits, champvapayments, champvaunits, cignawestpayments, cignawestunits, classicbluepayments, classicblueunits, conciergepayments, conciergeunits, coresourcepayments, coresourceunits } from './CPTData4';
import { benefitadministrativeunits, berkshireunits, bindunits, bluecrossunits, boonunits, boonchapmanunits, benefitadministrativepayments, berkshirepayments, bindpayments, bluecrosspayments, boonpayments, boonchapmanpayments } from './CPTData3';
import { allsaversunits, alliedbenefitunits, alliednationalunits, allstateinsuranceunits, amrisureinsuranceunits, amtrustfinancialunits, ascensionunits, ascensionsmartunits, allsaverspayments, alliedbenefitpayments, alliednationalpayments, allstateinsurancepayments, amrisureinsurancepayments, amtrustfinancialpayments, ascensionpayments, ascensionsmartpayments } from './CPTData2';
import {
    medicareUnits, bcbsillinoisUnits, bcbsmedicareunits,
    aetnaunit, aarpunit, cignahealthunits, cignamedicareadvunits,
    hmoilinoisunits, humanainsuranceunits, selfpayunits, unitedhealthunits, umrunits, absunits, administrativeunits, afsliccareunits, medicarePayments, bcbspayments, bcbsmedicarepayments, aetnapayments, aarppayments, cignahealthpayments, cignamedicareadvpayments, hmoillionoispayments, humanainsurancepayments, selfpaypayments, unitedhealthpayments, umrpayments, abspayments, administrativepayments, afsliccarepayments, totalPayments, totalUnits,
    humanaHmoUnits,humanaSelectUnits,medicarePartBUnits,midwestOperatingEngWelFundUnits,patientUnits,selfPayUnits,silacInsuranceCompanyUnits,ufcwLocal1546HealthAndWelfareFundUnits,unitedHealthCareUnits,unitedHEALTHCareUnits,usaSeniorCareUnits,humanaHmoPayment,humanaSelectPayment,medicarePartBPayment,midwestOperatingEngWelFundPayment,patientPayment,selfPayPayment,silacInsuranceCompanyPayment,ufcwLocal1546HealthAndWelfareFundPayment,
    unitedHealthCarePayment,unitedHEALTHCarePayment,usaSeniorCarePayment,unitedHealthCareNewUnit,unitedHealthCareNewPayment
} from './CPTData';
import {
    personaltotal, personaltotalunit, personalunit1,
    personalunit2,
    personalunit3,
    personalunit4,
    personalunit5,
    personalunit6,
    personalunit7,
    personalunit8,
    personalunit9,
    personalunit10,
    personalunit11,
    personalunit12,
    personalunit13,
    personalunit14,
    personalunit15,
    personalunit16,
    personalunit17,
    personalunit18,
    personalunit19,
    personalunit20,
    personalunit21,
    personalunit22,
    personalunit23,
    personalunit24,
    personalunit25,
    personalunit26,
    personalunit27,
    personalunit28,
    personalunit29,
    personalunit30,
    personalunit31,
    personalunit32,
    personalunit33,
    personalunit34,
    personalunit35,
    personalunit36,
    personalunit37,
    personalunit38,
    personalunit39,
    personalunit40,
    personalunit41,
    personalunit42,
    personalunit43,
    personalunit44,
    personalunit45,
    personalunit46,
    personalunit47,
    personalunit48,
    personalunit49,
    personalunit50,
    personalunit51,
    personalunit52,
    personalunit53,
    personalunit54,
    personalunit55,
    personalunit56,
    personalunit57,
    personalunit58,
    personalunit59,
    personalunit60,
    personalunit61,
    personalunit62,
    personalunit63,
    personalunit64,
    personalunit65,
    personalunit66,
    personalunit67,
    personalunit68,
    personalunit69,
    personalunit70,
    personalunit71,
    personalunit711,
    personalunit72,
    personalunit73,
    personalunit74,
    personalunit75,
    personalunit76,
    personalunit77,
    personalunit78,
    personalunit79,
    personalunit80, personalpayment1,
    personalpayment2,
    personalpayment3,
    personalpayment4,
    personalpayment5,
    personalpayment6,
    personalpayment7,
    personalpayment8,
    personalpayment9,
    personalpayment10,
    personalpayment11,
    personalpayment12,
    personalpayment13,
    personalpayment14,
    personalpayment15,
    personalpayment16,
    personalpayment17,
    personalpayment18,
    personalpayment19,
    personalpayment20,
    personalpayment21,
    personalpayment22,
    personalpayment23,
    personalpayment24,
    personalpayment25,
    personalpayment26,
    personalpayment27,
    personalpayment28,
    personalpayment29,
    personalpayment30,
    personalpayment31,
    personalpayment32,
    personalpayment33,
    personalpayment34,
    personalpayment35,
    personalpayment36,
    personalpayment37,
    personalpayment38,
    personalpayment39,
    personalpayment40,
    personalpayment41,
    personalpayment42,
    personalpayment43,
    personalpayment44,
    personalpayment45,
    personalpayment46,
    personalpayment47,
    personalpayment48,
    personalpayment49,
    personalpayment50,
    personalpayment51,
    personalpayment52,
    personalpayment53,
    personalpayment54,
    personalpayment55,
    personalpayment56,
    personalpayment57,
    personalpayment58,
    personalpayment59,
    personalpayment60,
    personalpayment61,
    personalpayment62,
    personalpayment63,
    personalpayment64,
    personalpayment65,
    personalpayment66,
    personalpayment67,
    personalpayment68,
    personalpayment69,
    personalpayment70,
    personalpayment71,
    personalpayment711,
    personalpayment72,
    personalpayment73,
    personalpayment74,
    personalpayment75,
    personalpayment76,
    personalpayment77,
    personalpayment78,
    personalpayment79,
    personalpayment80
} from './PersonalClinic/Cptdata1';
import {
    personaltotalunitRX,
    personalunit1RX,
    personalunit2RX,
    personalunit3RX,
    personalunit4RX,
    personalunit5RX,
    personalunit6RX,
    personalunit7RX,
    personalunit8RX,
    personalunit9RX,
    personalunit10RX,
    personalunit11RX,
    personalunit12RX,
    personalunit13RX,
    personalunit14RX,
    personalunit15RX,
    personalunit16RX,
    personalunit17RX,
    personalunit18RX,
    personalunit19RX,
    personalunit20RX,
    personalunit21RX,
    personalunit22RX,
    personalunit23RX,
    personalunit24RX,
    personalunit25RX,
    personalunit26RX,
    personalunit27RX,
    personalunit28RX,
    personalunit29RX,
    personalunit30RX,
    personalunit31RX,
    personalunit32RX,
    personalunit33RX,
    personalunit34RX,
    personalunit35RX,
    personalunit36RX,
    personalunit37RX,
    personalunit38RX,
    personalunit39RX,
    personalunit40RX,
    personalunit41RX,
    personalunit42RX,
    personalunit43RX,
    personalunit44RX,
    personalunit45RX,
    personalunit46RX,
    personalunit47RX,
    personalunit48RX,
    personalunit49RX,
    personalunit50RX,
    personalunit51RX,
    personalunit52RX,
    personalunit53RX,
    personalunit54RX,
    personalunit55RX,
    personalunit56RX,
    personalunit57RX,
    personalunit58RX,
    personalunit59RX,
    personalunit60RX,
    personalunit61RX,
    personalunit62RX,
    personalunit63RX,
    personalunit64RX,
    personalunit65RX,
    personalunit66RX,
    personalunit67RX,
    personalunit68RX,
    personalunit69RX,
    personalunit70RX,
    personalunit71RX,
    personalunit711RX,
    personalunit72RX,
    personalunit73RX,
    personalunit74RX,
    personalunit75RX,
    personalunit76RX,
    personalunit77RX,
    personalunit78RX,
    personalunit79RX,
    personalunit80RX,
    personaltotalRX,
    personalpayment1RX,
    personalpayment2RX,
    personalpayment3RX,
    personalpayment4RX,
    personalpayment5RX,
    personalpayment6RX,
    personalpayment7RX,
    personalpayment8RX,
    personalpayment9RX,
    personalpayment10RX,
    personalpayment11RX,
    personalpayment12RX,
    personalpayment13RX,
    personalpayment14RX,
    personalpayment15RX,
    personalpayment16RX,
    personalpayment17RX,
    personalpayment18RX,
    personalpayment19RX,
    personalpayment20RX,
    personalpayment21RX,
    personalpayment22RX,
    personalpayment23RX,
    personalpayment24RX,
    personalpayment25RX,
    personalpayment26RX,
    personalpayment27RX,
    personalpayment28RX,
    personalpayment29RX,
    personalpayment30RX,
    personalpayment31RX,
    personalpayment32RX,
    personalpayment33RX,
    personalpayment34RX,
    personalpayment35RX,
    personalpayment36RX,
    personalpayment37RX,
    personalpayment38RX,
    personalpayment39RX,
    personalpayment40RX,
    personalpayment41RX,
    personalpayment42RX,
    personalpayment43RX,
    personalpayment44RX,
    personalpayment45RX,
    personalpayment46RX,
    personalpayment47RX,
    personalpayment48RX,
    personalpayment49RX,
    personalpayment50RX,
    personalpayment51RX,
    personalpayment52RX,
    personalpayment53RX,
    personalpayment54RX,
    personalpayment55RX,
    personalpayment56RX,
    personalpayment57RX,
    personalpayment58RX,
    personalpayment59RX,
    personalpayment60RX,
    personalpayment61RX,
    personalpayment62RX,
    personalpayment63RX,
    personalpayment64RX,
    personalpayment65RX,
    personalpayment66RX,
    personalpayment67RX,
    personalpayment68RX,
    personalpayment69RX,
    personalpayment70RX,
    personalpayment71RX,
    personalpayment711RX,
    personalpayment72RX,
    personalpayment73RX,
    personalpayment74RX,
    personalpayment75RX,
    personalpayment76RX,
    personalpayment77RX,
    personalpayment78RX,
    personalpayment79RX,
    personalpayment80RX
} from './RxRevenue/RxCPTData'
import {
    personalunit81RX,
    personalunit82RX,
    personalunit83RX,
    personalunit84RX,
    personalunit85RX,
    personalunit86RX,
    personalunit87RX,
    personalunit88RX,
    personalunit89RX,
    personalunit90RX,
    personalunit91RX,
    personalunit92RX,
    personalunit93RX,
    personalunit94RX,
    personalunit95RX,
    personalunit96RX,
    personalunit97RX,
    personalunit98RX,
    personalunit99RX,
    personalunit100RX,
    personalunit101RX,
    personalunit102RX,
    personalunit103RX,
    personalunit104RX,
    personalunit105RX,
    personalunit106RX,
    personalunit107RX,
    personalunit108RX,
    personalpayment81RX,
    personalpayment82RX,
    personalpayment83RX,
    personalpayment84RX,
    personalpayment85RX,
    personalpayment86RX,
    personalpayment87RX,
    personalpayment88RX,
    personalpayment89RX,
    personalpayment90RX,
    personalpayment91RX,
    personalpayment92RX,
    personalpayment93RX,
    personalpayment94RX,
    personalpayment95RX,
    personalpayment96RX,
    personalpayment97RX,
    personalpayment98RX,
    personalpayment99RX,
    personalpayment100RX,
    personalpayment101RX,
    personalpayment102RX,
    personalpayment103RX,
    personalpayment104RX,
    personalpayment105RX,
    personalpayment106RX,
    personalpayment107RX,
    personalpayment108RX
} from './RxRevenue/RxCPTData2'
import { personalpayment81, personalpayment82, personalpayment83, personalpayment84, personalpayment85, personalpayment86, personalpayment87, personalpayment88, personalpayment89, personalpayment90, personalpayment91, personalunit81, personalunit82, personalunit83, personalunit84, personalunit85, personalunit86, personalunit87, personalunit88, personalunit89, personalunit90, personalunit91 } from './PersonalClinic/Cptdata2';
export const arrayValuesOfCPT = (indexCpt, value) => {
    console.log('index  value sis ', indexCpt)
    const val = sessionStorage.getItem('name') === 'Aurora' ? [
        value,
        totalUnits[indexCpt],
        totalPayments[indexCpt],
        medicareUnits[indexCpt],
        medicarePayments[indexCpt],
        bcbsillinoisUnits[indexCpt],
        bcbspayments[indexCpt],
        bcbsmedicareunits[indexCpt],
        bcbsmedicarepayments[indexCpt],
        aetnaunit[indexCpt],
        aetnapayments[indexCpt],
        aarpunit[indexCpt],
        aarppayments[indexCpt],
        cignahealthunits[indexCpt],
        cignahealthpayments[indexCpt],
        cignamedicareadvunits[indexCpt],
        cignamedicareadvpayments[indexCpt],
        hmoilinoisunits[indexCpt],
        hmoillionoispayments[indexCpt],
        humanainsuranceunits[indexCpt],
        humanainsurancepayments[indexCpt],
        selfpayunits[indexCpt],
        selfpaypayments[indexCpt],
        unitedhealthunits[indexCpt],
        unitedhealthpayments[indexCpt],
        umrunits[indexCpt],
        umrpayments[indexCpt],
        absunits[indexCpt],
        abspayments[indexCpt],
        administrativeunits[indexCpt],
        administrativepayments[indexCpt],
        afsliccareunits[indexCpt],
        afsliccarepayments[indexCpt],
        humanaHmoUnits[indexCpt],
        humanaHmoPayment[indexCpt],
        humanaSelectUnits[indexCpt],
        humanaSelectPayment[indexCpt],
        medicarePartBUnits[indexCpt],
        medicarePartBPayment[indexCpt],
        midwestOperatingEngWelFundUnits[indexCpt],
        midwestOperatingEngWelFundPayment[indexCpt],
        patientUnits[indexCpt],
        patientPayment[indexCpt],
        selfPayUnits[indexCpt],
        selfPayPayment[indexCpt],
        silacInsuranceCompanyUnits[indexCpt],
        silacInsuranceCompanyPayment[indexCpt],
        ufcwLocal1546HealthAndWelfareFundUnits[indexCpt],
        ufcwLocal1546HealthAndWelfareFundPayment[indexCpt],
        unitedHealthCareUnits[indexCpt],
        unitedHealthCarePayment[indexCpt],
        unitedHEALTHCareUnits[indexCpt],
        unitedHEALTHCarePayment[indexCpt],
        usaSeniorCareUnits[indexCpt],
        usaSeniorCarePayment[indexCpt],
        unitedHealthCareNewUnit[indexCpt],
        unitedHealthCareNewPayment[indexCpt]
        // allsaversunits[indexCpt],
        // allsaverspayments[indexCpt],
        // alliedbenefitunits[indexCpt],
        // alliedbenefitpayments[indexCpt],
        // alliednationalunits[indexCpt],
        // alliednationalpayments[indexCpt],
        // allstateinsuranceunits[indexCpt],
        // allstateinsurancepayments[indexCpt],
        // amrisureinsuranceunits[indexCpt],
        // amrisureinsurancepayments[indexCpt],
        // amtrustfinancialunits[indexCpt],
        // amtrustfinancialpayments[indexCpt],
        // ascensionunits[indexCpt],
        // ascensionpayments[indexCpt],
        // ascensionsmartunits[indexCpt],
        // ascensionsmartpayments[indexCpt],
        // benefitadministrativeunits[indexCpt],
        // benefitadministrativepayments[indexCpt],
        // berkshireunits[indexCpt],
        // berkshirepayments[indexCpt],
        // bindunits[indexCpt],
        // bindpayments[indexCpt],
        // bluecrossunits[indexCpt],
        // bluecrosspayments[indexCpt],
        // boonunits[indexCpt],
        // boonpayments[indexCpt]
        // boonchapmanunits[indexCpt],
        // boonchapmanpayments[indexCpt],
        // brighthealthunits[indexCpt],
        // brighthealthpayments[indexCpt],
        // broadspireunits[indexCpt],
        // broadspirepayments[indexCpt],
        // californiaunits[indexCpt],
        // californiapayments[indexCpt],
        // champvaunits[indexCpt],
        // champvapayments[indexCpt],
    ] : sessionStorage.getItem('name') === 'RX' ? [
        value,
        personaltotalunitRX[indexCpt],
        personaltotalRX[indexCpt],
        personalunit1RX[indexCpt],
        personalpayment1RX[indexCpt],
        personalunit2RX[indexCpt],
        personalpayment2RX[indexCpt],
        personalunit3RX[indexCpt],
        personalpayment3RX[indexCpt],
        personalunit4RX[indexCpt],
        personalpayment4RX[indexCpt],
        personalunit5RX[indexCpt],
        personalpayment5RX[indexCpt],
        personalunit6RX[indexCpt],
        personalpayment6RX[indexCpt],
        personalunit7RX[indexCpt],
        personalpayment7RX[indexCpt],
        personalunit8RX[indexCpt],
        personalpayment8RX[indexCpt],
        personalunit9RX[indexCpt],
        personalpayment9RX[indexCpt],
        personalunit10RX[indexCpt],
        personalpayment10RX[indexCpt],
        personalunit11RX[indexCpt],
        personalpayment11RX[indexCpt],
        personalunit12RX[indexCpt],
        personalpayment12RX[indexCpt],
        personalunit13RX[indexCpt],
        personalpayment13RX[indexCpt],
        personalunit14RX[indexCpt],
        personalpayment14RX[indexCpt],
        personalunit15RX[indexCpt],
        personalpayment15RX[indexCpt],
        personalunit16RX[indexCpt],
        personalpayment16RX[indexCpt],
        personalunit17RX[indexCpt],
        personalpayment17RX[indexCpt],
        personalunit18RX[indexCpt],
        personalpayment18RX[indexCpt],
        personalunit19RX[indexCpt],
        personalpayment19RX[indexCpt],
        personalunit20RX[indexCpt],
        personalpayment20RX[indexCpt],
        personalunit21RX[indexCpt],
        personalpayment21RX[indexCpt],
        personalunit22RX[indexCpt],
        personalpayment22RX[indexCpt],
        personalunit23RX[indexCpt],
        personalpayment23RX[indexCpt],
        personalunit24RX[indexCpt],
        personalpayment24RX[indexCpt],
        personalunit25RX[indexCpt],
        personalpayment25RX[indexCpt],
        personalunit26RX[indexCpt],
        personalpayment26RX[indexCpt],
        personalunit27RX[indexCpt],
        personalpayment27RX[indexCpt],
        personalunit28RX[indexCpt],
        personalpayment28RX[indexCpt],
        personalunit29RX[indexCpt],
        personalpayment29RX[indexCpt],
        personalunit30RX[indexCpt],
        personalpayment30RX[indexCpt],
        personalunit31RX[indexCpt],
        personalpayment31RX[indexCpt],
        personalunit32RX[indexCpt],
        personalpayment32RX[indexCpt],
        personalunit33RX[indexCpt],
        personalpayment33RX[indexCpt],
        personalunit34RX[indexCpt],
        personalpayment34RX[indexCpt],
        personalunit35RX[indexCpt],
        personalpayment35RX[indexCpt],
        personalunit36RX[indexCpt],
        personalpayment36RX[indexCpt],
        personalunit37RX[indexCpt],
        personalpayment37RX[indexCpt],
        personalunit38RX[indexCpt],
        personalpayment38RX[indexCpt],
        personalunit39RX[indexCpt],
        personalpayment39RX[indexCpt],
        personalunit40RX[indexCpt],
        personalpayment40RX[indexCpt],
        personalunit41RX[indexCpt],
        personalpayment41RX[indexCpt],
        personalunit42RX[indexCpt],
        personalpayment42RX[indexCpt],
        personalunit43RX[indexCpt],
        personalpayment43RX[indexCpt],
        personalunit44RX[indexCpt],
        personalpayment44RX[indexCpt],
        personalunit45RX[indexCpt],
        personalpayment45RX[indexCpt],
        personalunit46RX[indexCpt],
        personalpayment46RX[indexCpt],
        personalunit47RX[indexCpt],
        personalpayment47RX[indexCpt],
        personalunit48RX[indexCpt],
        personalpayment48RX[indexCpt],
        personalunit49RX[indexCpt],
        personalpayment49RX[indexCpt],
        personalunit50RX[indexCpt],
        personalpayment50RX[indexCpt],
        personalunit51RX[indexCpt],
        personalpayment51RX[indexCpt],
        personalunit52RX[indexCpt],
        personalpayment52RX[indexCpt],
        personalunit53RX[indexCpt],
        personalpayment53RX[indexCpt],
        personalunit54RX[indexCpt],
        personalpayment54RX[indexCpt],
        personalunit55RX[indexCpt],
        personalpayment55RX[indexCpt],
        personalunit56RX[indexCpt],
        personalpayment56RX[indexCpt],
        personalunit57RX[indexCpt],
        personalpayment57RX[indexCpt],
        personalunit58RX[indexCpt],
        personalpayment58RX[indexCpt],
        personalunit59RX[indexCpt],
        personalpayment59RX[indexCpt],
        personalunit60RX[indexCpt],
        personalpayment60RX[indexCpt],
        personalunit61RX[indexCpt],
        personalpayment61RX[indexCpt],
        personalunit62RX[indexCpt],
        personalpayment62RX[indexCpt],
        personalunit63RX[indexCpt],
        personalpayment63RX[indexCpt],
        personalunit64RX[indexCpt],
        personalpayment64RX[indexCpt],
        personalunit65RX[indexCpt],
        personalpayment65RX[indexCpt],
        personalunit66RX[indexCpt],
        personalpayment66RX[indexCpt],
        personalunit67RX[indexCpt],
        personalpayment67RX[indexCpt],
        personalunit68RX[indexCpt],
        personalpayment68RX[indexCpt],
        personalunit69RX[indexCpt],
        personalpayment69RX[indexCpt],
        personalunit70RX[indexCpt],
        personalpayment70RX[indexCpt],
        personalunit71RX[indexCpt],
        personalpayment71RX[indexCpt],
        personalunit711RX[indexCpt],
        personalpayment711RX[indexCpt],
        personalunit72RX[indexCpt],
        personalpayment72RX[indexCpt],
        personalunit73RX[indexCpt],
        personalpayment73RX[indexCpt],
        personalunit74RX[indexCpt],
        personalpayment74RX[indexCpt],
        personalunit75RX[indexCpt],
        personalpayment75RX[indexCpt],
        personalunit76RX[indexCpt],
        personalpayment76RX[indexCpt],
        personalunit77RX[indexCpt],
        personalpayment77RX[indexCpt],
        personalunit78RX[indexCpt],
        personalpayment78RX[indexCpt],
        personalunit79RX[indexCpt],
        personalpayment79RX[indexCpt],
        personalunit80RX[indexCpt],
        personalpayment80RX[indexCpt],
        personalunit81RX[indexCpt],
        personalpayment81RX[indexCpt],
        personalunit82RX[indexCpt],
        personalpayment82RX[indexCpt],
        personalunit83RX[indexCpt],
        personalpayment83RX[indexCpt],
        personalunit84RX[indexCpt],
        personalpayment84RX[indexCpt],
        personalunit85RX[indexCpt],
        personalpayment85RX[indexCpt],
        personalunit86RX[indexCpt],
        personalpayment86RX[indexCpt],
        personalunit87RX[indexCpt],
        personalpayment87RX[indexCpt],
        personalunit88RX[indexCpt],
        personalpayment88RX[indexCpt],
        personalunit89RX[indexCpt],
        personalpayment89RX[indexCpt],
        personalunit90RX[indexCpt],
        personalpayment90RX[indexCpt],
        personalunit91RX[indexCpt],
        personalpayment91RX[indexCpt],
        personalunit92RX[indexCpt],
        personalpayment92RX[indexCpt],
        personalunit93RX[indexCpt],
        personalpayment93RX[indexCpt],
        personalunit94RX[indexCpt],
        personalpayment94RX[indexCpt],
        personalunit95RX[indexCpt],
        personalpayment95RX[indexCpt],
        personalunit96RX[indexCpt],
        personalpayment96RX[indexCpt],
        personalunit97RX[indexCpt],
        personalpayment97RX[indexCpt],
        personalunit98RX[indexCpt],
        personalpayment98RX[indexCpt],
        personalunit99RX[indexCpt],
        personalpayment99RX[indexCpt],
        personalunit100RX[indexCpt],
        personalpayment100RX[indexCpt],
        personalunit101RX[indexCpt],
        personalpayment101RX[indexCpt],
        personalunit102RX[indexCpt],
        personalpayment102RX[indexCpt],
        personalunit103RX[indexCpt],
        personalpayment103RX[indexCpt],
        personalunit104RX[indexCpt],
        personalpayment104RX[indexCpt],
        personalunit105RX[indexCpt],
        personalpayment105RX[indexCpt],
        personalunit106RX[indexCpt],
        personalpayment106RX[indexCpt],
        personalunit107RX[indexCpt],
        personalpayment107RX[indexCpt],
        personalunit108RX[indexCpt],
        personalpayment108RX[indexCpt],
    ] : [
        value,
        personaltotalunit[indexCpt],
        personaltotal[indexCpt],
        personalunit1[indexCpt],
        personalpayment1[indexCpt],
        personalunit2[indexCpt],
        personalpayment2[indexCpt],
        personalunit3[indexCpt],
        personalpayment3[indexCpt],
        personalunit4[indexCpt],
        personalpayment4[indexCpt],
        personalunit5[indexCpt],
        personalpayment5[indexCpt],
        personalunit6[indexCpt],
        personalpayment6[indexCpt],
        personalunit7[indexCpt],
        personalpayment7[indexCpt],
        personalunit8[indexCpt],
        personalpayment8[indexCpt],
        personalunit9[indexCpt],
        personalpayment9[indexCpt],
        personalunit10[indexCpt],
        personalpayment10[indexCpt],
        personalunit11[indexCpt],
        personalpayment11[indexCpt],
        personalunit12[indexCpt],
        personalpayment12[indexCpt],
        personalunit13[indexCpt],
        personalpayment13[indexCpt],
        personalunit14[indexCpt],
        personalpayment14[indexCpt],
        personalunit15[indexCpt],
        personalpayment15[indexCpt],
        personalunit16[indexCpt],
        personalpayment16[indexCpt],
        personalunit17[indexCpt],
        personalpayment17[indexCpt],
        personalunit18[indexCpt],
        personalpayment18[indexCpt],
        personalunit19[indexCpt],
        personalpayment19[indexCpt],
        personalunit20[indexCpt],
        personalpayment20[indexCpt],
        personalunit21[indexCpt],
        personalpayment21[indexCpt],
        personalunit22[indexCpt],
        personalpayment22[indexCpt],
        personalunit23[indexCpt],
        personalpayment23[indexCpt],
        personalunit24[indexCpt],
        personalpayment24[indexCpt],
        personalunit25[indexCpt],
        personalpayment25[indexCpt],
        personalunit26[indexCpt],
        personalpayment26[indexCpt],
        personalunit27[indexCpt],
        personalpayment27[indexCpt],
        personalunit28[indexCpt],
        personalpayment28[indexCpt],
        personalunit29[indexCpt],
        personalpayment29[indexCpt],
        personalunit30[indexCpt],
        personalpayment30[indexCpt],
        personalunit31[indexCpt],
        personalpayment31[indexCpt],
        personalunit32[indexCpt],
        personalpayment32[indexCpt],
        personalunit33[indexCpt],
        personalpayment33[indexCpt],
        personalunit34[indexCpt],
        personalpayment34[indexCpt],
        personalunit35[indexCpt],
        personalpayment35[indexCpt],
        personalunit36[indexCpt],
        personalpayment36[indexCpt],
        personalunit37[indexCpt],
        personalpayment37[indexCpt],
        personalunit38[indexCpt],
        personalpayment38[indexCpt],
        personalunit39[indexCpt],
        personalpayment39[indexCpt],
        personalunit40[indexCpt],
        personalpayment40[indexCpt],
        personalunit41[indexCpt],
        personalpayment41[indexCpt],
        personalunit42[indexCpt],
        personalpayment42[indexCpt],
        personalunit43[indexCpt],
        personalpayment43[indexCpt],
        personalunit44[indexCpt],
        personalpayment44[indexCpt],
        personalunit45[indexCpt],
        personalpayment45[indexCpt],
        personalunit46[indexCpt],
        personalpayment46[indexCpt],
        personalunit47[indexCpt],
        personalpayment47[indexCpt],
        personalunit48[indexCpt],
        personalpayment48[indexCpt],
        personalunit49[indexCpt],
        personalpayment49[indexCpt],
        personalunit50[indexCpt],
        personalpayment50[indexCpt],
        personalunit51[indexCpt],
        personalpayment51[indexCpt],
        personalunit52[indexCpt],
        personalpayment52[indexCpt],
        personalunit53[indexCpt],
        personalpayment53[indexCpt],
        personalunit54[indexCpt],
        personalpayment54[indexCpt],
        personalunit55[indexCpt],
        personalpayment55[indexCpt],
        personalunit56[indexCpt],
        personalpayment56[indexCpt],
        personalunit57[indexCpt],
        personalpayment57[indexCpt],
        personalunit58[indexCpt],
        personalpayment58[indexCpt],
        personalunit59[indexCpt],
        personalpayment59[indexCpt],
        personalunit60[indexCpt],
        personalpayment60[indexCpt],
        personalunit61[indexCpt],
        personalpayment61[indexCpt],
        personalunit62[indexCpt],
        personalpayment62[indexCpt],
        personalunit63[indexCpt],
        personalpayment63[indexCpt],
        personalunit64[indexCpt],
        personalpayment64[indexCpt],
        personalunit65[indexCpt],
        personalpayment65[indexCpt],
        personalunit66[indexCpt],
        personalpayment66[indexCpt],
        personalunit67[indexCpt],
        personalpayment67[indexCpt],
        personalunit68[indexCpt],
        personalpayment68[indexCpt],
        personalunit69[indexCpt],
        personalpayment69[indexCpt],
        personalunit70[indexCpt],
        personalpayment70[indexCpt],
        personalunit71[indexCpt],
        personalpayment71[indexCpt],
        personalunit711[indexCpt],
        personalpayment711[indexCpt],
        personalunit72[indexCpt],
        personalpayment72[indexCpt],
        personalunit73[indexCpt],
        personalpayment73[indexCpt]
        // personalunit74[indexCpt],
        // personalpayment74[indexCpt],
        // personalunit75[indexCpt],
        // personalpayment75[indexCpt],
        // personalunit76[indexCpt],
        // personalpayment76[indexCpt],
        // personalunit77[indexCpt],
        // personalpayment77[indexCpt],
        // personalunit78[indexCpt],
        // personalpayment78[indexCpt],
        // personalunit79[indexCpt],
        // personalpayment79[indexCpt]
        // personalunit80[indexCpt],
        // personalpayment80[indexCpt],
        // personalunit81[indexCpt],
        // personalpayment81[indexCpt],
        // personalunit82[indexCpt],
        // personalpayment82[indexCpt],
        // personalunit83[indexCpt],
        // personalpayment83[indexCpt],
        // personalunit84[indexCpt],
        // personalpayment84[indexCpt],
        // personalunit85[indexCpt],
        // personalpayment85[indexCpt],
        // personalunit86[indexCpt],
        // personalpayment86[indexCpt],
        // personalunit87[indexCpt],
        // personalpayment87[indexCpt],
        // personalunit88[indexCpt],
        // personalpayment88[indexCpt],
        // personalunit89[indexCpt],
        // personalpayment89[indexCpt],
        // personalunit90[indexCpt],
        // personalpayment90[indexCpt],
        // personalunit91[indexCpt],
        // personalpayment91[indexCpt]

    ]
    return val
}