export const providersArray = [
    [
        [
            147095.04,
            123579.28,
            152113.47,
            90365,
            135785.24,
            184484.48,
            144727.08,
            111474.78,
            108228,
            108534,
            118235,
            112412,
            121645.56,
            51109,
            135319,
            128221,
            137840.04,
            169826.52,
            90351,
            158702,
            123723,
            109564,
            137811,
            95060,
            123694,
            98453,
            118159,
            82976,
            54337.8,
            122131.4,
            116533,
            56727

        ],
        [
            41727.76,
            41810.2,
            30048.18,
            31459.37,
            25267.96,
            38257.31,
            40085.43,
            41420.44,
            33039.98,
            31236.42,
            27135.48,
            32563.75,
            16608.45,
            22276.56,
            28900.62,
            25559.7,
            27936.1,
            33230.84,
            25944.87,
            26281.65,
            52398.96,
            33388.75,
            21280.17,
            30310.51,
            18390.27,
            21713.2,
            15969.11,
            32949.93,
            26715.99,
            18705.17,
            25849.39,
            14403.40
            
        ],
        [
            15381.06,
            16102.08,
            6211.12,
            1616.8,
            1804.76,
            16042.84,
            6297.12,
            1972.4,
            6022.46,
            872.98,
            906.9,
            25158.91,
            4801.58,
            -348.14,
            3261.16,
            4484.63,
            7105.55,
            21776.81,
            6407.12,
            14581.63,
            2457.18,
            3989.12,
            6586.54,
            4454.09,
            1877.67,
            10184.94,
            3227.12,
            3144.95,
            2317.96,
            2975.26,
            320.94,
            2413.94
            
        ],
        [
            108936.82,
            137512.69,
            77614.33,
            91045.12,
            72383.57,
            112706.87,
            112025.71,
            129932.81,
            86852.02,
            87087.91,
            87204.88,
            102323.13,
            61008.37,
            78890.52,
            97653.16,
            58972.81,
            96544.37,
            94347.64,
            68795.65,
            83061.48,
            149185.23,
            87345.09,
            61742.51,
            85095.25,
            47684.68,
            165428.7,
            33110.5,
            98328.71,
            70507.09,
            75251.46,
            78020.93,
            50271.30
               
        ],
        [
            124,
            98,
            138,
            81,
            110,
            148,
            115,
            106,
            88,
            123,
            109,
            90,
            110,
            55,
            117,
            110,
            109,
            134,
            76,
            133,
            103,
            98,
            103,
            82,
            112,
            101,
            115,
            66,
            78,
            105,
            93,
            61
            
        ],
        [
            336.514193548387,
            426.634693877551,
            217.740434782609,
            388.387283950617,
            229.708727272727,
            258.495337837838,
            348.568956521739,
            390.758867924528,
            375.454318181818,
            253.954634146341,
            248.949357798165,
            361.819444444444,
            150.985909090909,
            405.028363636364,
            247.013846153846,
            232.360909090909,
            256.294495412844,
            247.991343283582,
            341.379868421053,
            197.606390977444,
            508.727766990291,
            340.701530612245,
            206.60359223301,
            369.640365853659,
            164.198839285714,
            214.982178217822,
            138.861826086957,
            499.241363636364,
            342.512692307692,
            178.144476190476,
            277.932795698925,
            236.12
            
        ]
    ]
]
export const providersArraySAM = [
    //provider1,
    [
         [
            0,
            70,
            2052,
            0,
            2528,
            1866,
            1311,
            1575,
            415,
            5114,
            1133,
            555,
            420,
            914.77,
            1313,
            60,
            620,
            1813,
            0,
            269,
            1400,
            3304,
            2814,
            2082,
            1225,
            24396,
            1225,
            1599,
            3199,
            5834,
            1835,
            4510,
            
        ]
        , [
            0,
            0,
            305,
            0,
            475,
            315,
            0,
            115,
            35,
            170,
            190,
            95,
            70,
            145,
            0,
            0,
            45,
            145,
            0,
            0,
            153.87,
            410,
            65,
            15,
            98,
            480,
            0,
            50,
            15,
            65,
            65,
            175,
            
        ]
       ,[
        0,
        210,
        1642,
        0,
        2053,
        1296,
        608,
        0,
        0,
        998,
        1332,
        105,
        70,
        0,
        550,
        5145,
        1261,
        739,
        719,
        1676,
        0,
        1389,
        210,
        843.77,
        190,
        195,
        0,
        35,
        134,
        0,
        4902,
        841,
        
        ]
       , [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        14.29,
        0,
        
        ]
        , [
            2,
            8,
            7,
            6,
            4,
            14,
            4,
            7,
            2,
            9,
            2,
            2,
            8,
            2,
            2,
            8,
            0,
            9,
            9,
            15,
            9,
            18,
            11,
            83,
            10,
            14,
            15,
            18,
            8,
            8,
            
        ]
        ,[
            0,
            38,
            68,
            53,
            0,
            8,
            9,
            24,
            95,
            11,
            35,
            73,
            0,
            0,
            23,
            18,
            0,
            0,
            17,
            27,
            7,
            1,
            9,
            6,
            0,
            4,
            1,
            4,
            8,
            22,
            
        ]
    ],
    //provider2,
    [
         [
            239640,
            145875,
            115500,
            96621,
            118862,
            118595,
            221633,
            144934,
            125124,
            138402,
            229697,
            158689,
            109062,
            171357,
            157915,
            136543,
            192195,
            181819,
            137179,
            133587,
            199977,
            175656,
            114457,
            154163,
            111440,
            121562,
            230544,
            135735,
            126101,
            164263,
            134043,
            140416,
            
        ]
        , [
            45826,
            54400,
            65955,
            35528,
            42979,
            38409,
            55367,
            57841,
            40817,
            44604,
            57823,
            63533,
            41653,
            43640,
            53453,
            45658,
            61371,
            56157,
            53951,
            46989,
            46007,
            64737,
            49036,
            52829,
            45549,
            38504,
            46531,
            65979,
            49888,
            54137,
            53497,
            58956,
            
        ]
        ,[
            4355,
            7465,
            13074,
            6118,
            10264,
            9244,
            10358,
            10871,
            13492,
            8136,
            4020,
            7043,
            3099,
            3842,
            8525,
            6654,
            7065,
            3397,
            7510,
            4779,
            7435,
            8733,
            4338,
            9174,
            7317,
            6022,
            5482,
            3877,
            10327,
            27357,
            6076,
            4204,
            
        ]
        ,[
            89438,
            116737,
            94250,
            63044,
            84987,
            78980,
            109259,
            110700,
            74318,
            76331,
            103645,
            128368,
            84112,
            89742,
            110152,
            77239,
            113227,
            96468,
            99595,
            85242,
            82890,
            119497,
            90438,
            82774,
            78421,
            84447,
            88871,
            124752,
            81274,
            90691,
            86231,
            103628,
            
        ]
       ,[
        714,
        592,
        385,
        335,
        406,
        371,
        675,
        443,
        494,
        461,
        778,
        656,
        383,
        680,
        504,
        479,
        514,
        569,
        445,
        551,
        581,
        537,
        371,
        515,
        468,
        413,
        820,
        492,
        408,
        586,
        477,
        509,
        
        ]
       ,[
        64,
        92,
        171,
        106,
        106,
        104,
        82,
        131,
        83,
        97,
        74,
        97,
        109,
        64,
        106,
        95,
        119,
        99,
        121,
        85,
        79,
        121,
        132,
        103,
        97,
        93,
        57,
        134,
        122,
        92,
        112,
        116,
        
        ]
    ],
    ///provider3,
    [
        [
            104415,
            83841,
            91654,
            116275,
            89559,
            98343,
            99543,
            93435,
            111757,
            81466,
            132711,
            129252,
            114683,
            124785,
            150652,
            120066,
            144756,
            149094,
            128109,
            160202,
            138505,
            136507,
            161449,
            150551,
            135810,
            100283,
            182684,
            136694,
            132491,
            130377,
            101558,
            174254,
            
        ]
       , [
        32058,
        31151,
        31169,
        31852,
        35668,
        36485,
        27063,
        42242,
        35374,
        29874,
        43836,
        48475,
        34535,
        38741,
        45434,
        39199,
        43616,
        39140,
        38254,
        41937,
        46657,
        40490,
        51396,
        52111,
        40502,
        34453,
        34733,
        47530,
        41942,
        34965,
        41298,
        45813,
        
        ]
        , [
            4290,
            3411,
            4862,
            3251,
            1610,
            6298,
            3826,
            1219,
            14738,
            4604,
            3489,
            3858,
            3300,
            2356,
            5703,
            1416,
            4216,
            2955,
            1598,
            3371,
            2383,
            3329,
            1618,
            3441,
            2755,
            3018,
            3514,
            2710,
            1252,
            4169,
            2875,
            9703,
            
        ]
      , [
        57539,
        66240,
        62684,
        58960,
        68711,
        69205,
        44911,
        79560,
        59323,
        54959,
        73468,
        82712,
        59367,
        79934,
        94837,
        78365,
        99783,
        97024,
        84784,
        94890,
        100341,
        81256,
        113450,
        104642,
        93983,
        77781,
        91174,
        120128,
        99902,
        69055,
        86162,
        100883,
        
        ]
        , [
            354,
            306,
            300,
            358,
            283,
            341,
            330,
            298,
            347,
            241,
            399,
            417,
            355,
            1149,
            393,
            330,
            361,
            346,
            336,
            401,
            313,
            346,
            390,
            350,
            354,
            254,
            383,
            321,
            359,
            338,
            260,
            435,
            
        ]
       , [
        91,
        102,
        104,
        89,
        126,
        107,
        82,
        142,
        102,
        124,
        110,
        116,
        97,
        34,
        116,
        119,
        121,
        113,
        114,
        105,
        149,
        117,
        132,
        149,
        114,
        136,
        91,
        148,
        117,
        103,
        159,
        105,
        
        ]
    ],
    ///providers4,
    [

         [
            137862,
            131972,
            131361,
            131019,
            141023,
            121212,
            125803,
            129129,
            146014,
            158577,
            149514,
            171700,
            195143,
            127673,
            197265,
            139822,
            137004,
            151932,
            168234,
            160450,
            161201,
            175414,
            192401,
            182890,
            150922,
            110750,
            192534,
            138206,
            162061,
            174053,
            172816,
            169461,
            
        ]
        ,[
            54438,
            41957,
            43432,
            46661,
            49868,
            44342,
            34355,
            50891,
            47443,
            56920,
            58573,
            61251,
            50777,
            52215,
            47854,
            45389,
            54680,
            47529,
            46681,
            52989,
            54673,
            50974,
            59741,
            61655,
            54807,
            42780,
            46122,
            48947,
            46357,
            48511,
            54093,
            62750,
            
        ]
        , [
            4252,
            5117,
            7469,
            6463,
            5883,
            5603,
            4116,
            1832,
            11072,
            4199,
            5121,
            2733,
            4269,
            4176,
            7498,
            1668,
            4643,
            2142,
            4288,
            5812,
            5443,
            4700,
            7937,
            10465,
            4310,
            6703,
            3940,
            4534,
            2507,
            5499,
            4519,
            3991,
            
        ]
        ,[
            74004,
            94476,
            74805,
            93966,
            84593,
            90254,
            61098,
            86847,
            83444,
            96333,
            94003,
            100460,
            103061,
            105748,
            98026,
            97226,
            113346,
            100565,
            97609,
            98831,
            106264,
            103355,
            117191,
            117183,
            108747,
            97957,
            95045,
            99910,
            106948,
            107967,
            108648,
            133066,
            
        ]
        ,[
            310,
            398,
            331,
            351,
            394,
            334,
            314,
            336,
            341,
            320,
            345,
            437,
            443,
            682,
            431,
            340,
            338,
            357,
            377,
            422,
            429,
            412,
            425,
            442,
            396,
            289,
            446,
            317,
            380,
            430,
            433,
            402,
            
        ]
        , [
            176,
            105,
            131,
            133,
            127,
            133,
            109,
            151,
            139,
            178,
            170,
            140,
            115,
            77,
            111,
            133,
            162,
            133,
            124,
            126,
            127,
            124,
            141,
            139,
            138,
            148,
            103,
            154,
            122,
            113,
            125,
            156,
            
        ]
    ],
    ///provider5,
    [
        [
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
           
        ]
        , [
            -40, 
            150, 
            0, 
            0, 
            25, 
            114, 
            -43, 
            -32, 
            8, 
            -125, 
            -10, 
            15, 
            15, 
            -10, 
            0, 
            0, 
            161, 
            0, 
            0, 
            0, 
            9, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
           
        ]
        ,[
            80, 
            -205, 
            130, 
            45, 
            -25, 
            -25, 
            0, 
            0, 
            -50, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            25, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
           
        ]
       ,[
        78, 
        55, 
        0, 
        0, 
        0, 
        0, 
        58, 
        32, 
        0, 
        140, 
        0, 
        0, 
        0, 
        35, 
        0, 
        0, 
        69, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
        0, 
       
        ]
        ,[
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
           
        ]
        , [
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
            0, 
           
        ]

    ],
    // provider6,
    [
        [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0, 
            0,   
            35075,
            33099,
            41679,
            32519,
            27421,
            9051,
            36196,
            33321,
            21375,
            22178,
            24584,
            20791,
            15998,
            
            
        ]
       ,[
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0, 
            0, 
            1603,
            11541,
            10544,
            8116,
            11670,
            4555,
            6763,
            9016,
            9480,
            7912,
            7225,
            8560,
            6988,
            
            
        ]
       ,[
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,   
            0,   
            708,
            1538,
            1447,
            1277,
            2065,
            744,
            1460,
            7396,
            900,
            137,
            988,
            883,
            -610,
            
        ]
      ,[
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0, 
            0,   
            2390,
            19679,
            22546,
            17424,
            19710,
            6654,
            14446,
            15393,
            18506,
            14647,
            14842,
            19183,
            14684,
            
        ]
       ,[
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            85,
            80,
            133,
            90,
            85,
            32,
            99,
            87,
            56,
            61,
            77,
            56,
            51,
            
       ]
       , [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0, 
            0,   
            19,
            144,
            79,
            90,
            137,
            142,
            68,
            104,
            169,
            130,
            94,
            153,
            137,
            
       ]
    ]

]
    