import React from 'react'
import { Row, Col } from 'reactstrap'
const ClaimBlocks = ({data}) => {
    console.log('blovk data ' ,  data)
    return (
        <Row className='text-white'>
            <h3 className='text-dark'>Over All Claim summary analysis</h3>
            <Col style={{ backgroundColor: '#008FFB' }} className='m-1 p-2 border rounded'>
                <h3>GrandTotal:</h3>
                <span className='text-center'>{data.grandTotal}</span>
            </Col>
            {/* {sessionStorage.getItem('name') === 'PersonalClinic' ?<Col style={{ backgroundColor: '#008FFB' }} className='m-1 p-2 border rounded'>
                <h3>2020:</h3>
                <span className='text-center'>{data.twenty20}</span>    
            </Col>  */}
            {/* {sessionStorage.getItem('name') === 'RX' ?<Col style={{ backgroundColor: '#008FFB' }} className='m-1 p-2 border rounded'>
            <h3>2020:</h3>
            <span className='text-center'>{data.twenty20}</span>    // THIS IS NOVEMBER UPDATE FOR THE RXREVENUE TEST BOARD
            </Col> : ' '} */}

            {/* <Col style={{ backgroundColor: '#008FFB' }} className='m-1 p-2 border rounded'>
                <h3>2020:</h3>    THIS JULY UPDATE FOR AURORA.
                <span className='text-center'>{data.twenty21}</span>    
            </Col>  */}

            {/* {sessionStorage.getItem('name') === 'PersonalClinic' ?<Col style={{ backgroundColor: '#008FFB' }} className='m-1 p-2 border rounded'>
                <h3>2021:</h3>
                <span className='text-center'>{data.twenty21}</span>    
            </Col> 
            : sessionStorage.getItem('name') === 'RX' ?<Col style={{ backgroundColor: '#008FFB' }} className='m-1 p-2 border rounded'>
                <h3>2021:</h3>
                <span className='text-center'>{data.twenty21}</span>    
            </Col> : ' '}  */}

            {/* {sessionStorage.getItem('name') === 'PersonalClinic' ?<Col style={{ backgroundColor: '#008FFB' }} className='m-1 p-2 border rounded'>
                <h3>2021:</h3>
                <span className='text-center'>{data.twenty21}</span>    
            </Col> 
            : ' ' } */}
            
            {/* sessionStorage.getItem('name') === 'RX' ?<Col style={{ backgroundColor: '#008FFB' }} className='m-1 p-2 border rounded'>
                <h3>2021:</h3>
                <span className='text-center'>{data.twenty21}</span>    
            </Col> : ' ' */}

            {/* <Col style={{ backgroundColor: '#008FFB' }} className='m-1 p-2 border rounded'>
                <h3>2021:</h3>    THIS AUGUST UPDATE FOR AURORA.
                <span className='text-center'>{data.twenty21}</span>
            </Col> */}
            {
                sessionStorage.getItem('name') === 'PersonalClinic' || sessionStorage.getItem('name') === 'RX' ? 
                <Col style={{ backgroundColor: '#008FFB' }} className='m-1 p-2 border rounded'>
                    <h3>2022:</h3>
                    <span className='text-center'>{data.twenty22}</span>
                </Col> : ''
            }
            <Col style={{ backgroundColor: '#008FFB' }} className='m-1 p-2 border rounded'>
                <h3>2023:</h3>
                <span className='text-center'>{data.twenty23}</span>
            </Col>
            { sessionStorage.getItem('name') === 'Aurora' || 'PersonalClinic' ? 
            <Col style={{ backgroundColor: '#008FFB' }} className='m-1 p-2 border rounded'>
                <h3>2024:</h3>
                <span className='text-center'>{data.twenty24}</span>
            </Col> : '' }
            <Col style={{ backgroundColor: data.percentageOvera90 >= 1 && data.percentageOvera90 <= 20 ? '#52c234' : 
        data.percentageOvera90 >= 20.01 && data.percentageOvera90 <= 25 ? '#F9D423' : data.percentageOvera90 >= 25 ? '#DC2430' : 'black'
        }} className='m-1 p-2 border rounded'>
                <h3>Over 90 days:</h3>
                <span className='text-center'>{data.over90} ({data.percentageOvera90.toFixed(2)} %)</span>
            </Col>
            <Col style={{ backgroundColor: '#008FFB' }} className='m-1 p-2 border rounded'>
                <h3>Within 90 days:</h3>
                <span className='text-center'>{data.within90} ({ ((data.within90 / data.grandTotal) * 100).toFixed(2)} %)</span>
            </Col>
        </Row>
    )
}

export default ClaimBlocks