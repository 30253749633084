import {pro1F1Charge,pro1F1Payment,pro1F1Collectable,pro1F1NonCollectable,pro1F1ClaimCount,pro1F1PerVisit,
    pro1F2Charge,pro1F2Payment,pro1F2Collectable,pro1F2NonCollectable,pro1F2ClaimCount,pro1F2PerVisit,
    pro1F3Charge,pro1F3Payment,pro1F3Collectable,pro1F3NonCollectable,pro1F3ClaimCount,pro1F3PerVisit,} from './FacilityData';

import {provider1PerVisist } from './ProvidersAndFacility';

export const pro1WithJan21Charges = [
    pro1F1Charge.jan21,
    pro1F2Charge.jan21,
    pro1F3Charge.jan21,
    pro1F1Charge.jan21 + pro1F2Charge.jan21 + pro1F3Charge.jan21

]
// console.log("pro1WithJan21Charges :",pro1WithJan21Charges)
export const pro1WithJan21Pay = [
    pro1F1Payment.jan21,
    pro1F2Payment.jan21,
    pro1F3Payment.jan21,
    pro1F1Payment.jan21 + pro1F2Payment.jan21 + pro1F3Payment.jan21
]
export const pro1WithJan21Count = [
    pro1F1Collectable.jan21,
    pro1F2Collectable.jan21,
    pro1F3Collectable.jan21,
    pro1F1Collectable.jan21 + pro1F2Collectable.jan21 + pro1F3Collectable.jan21
]
export const pro1WithJan21NonCount = [
    pro1F1NonCollectable.jan21,
    pro1F2NonCollectable.jan21,
    pro1F3NonCollectable.jan21,
    pro1F1NonCollectable.jan21 + pro1F2NonCollectable.jan21 + pro1F3NonCollectable.jan21
]
export const pro1WithJan21Claim = [
    pro1F1ClaimCount.jan21,
    pro1F2ClaimCount.jan21,
    pro1F3ClaimCount.jan21,
    pro1F1ClaimCount.jan21 + pro1F2ClaimCount.jan21 + pro1F3ClaimCount.jan21
]
export const pro1WithJan21PerVisit = [
    pro1F1PerVisit.jan21,
    pro1F2PerVisit.jan21,
    pro1F3PerVisit.jan21,
    provider1PerVisist[0]
]
// feb 21
export const pro1WithFeb21Charges = [
    pro1F1Charge.feb21,
    pro1F2Charge.feb21,
    pro1F3Charge.feb21,
    pro1F1Charge.feb21 + pro1F2Charge.feb21 + pro1F3Charge.feb21
]
export const pro1WithFeb21Pay = [
    pro1F1Payment.feb21,
    pro1F2Payment.feb21,
    pro1F3Payment.feb21,
    pro1F1Payment.feb21 + pro1F2Payment.feb21 + pro1F3Payment.feb21
]
export const pro1WithFeb21Count = [
    pro1F1Collectable.feb21,
    pro1F2Collectable.feb21,
    pro1F3Collectable.feb21,
    pro1F1Collectable.feb21 + pro1F2Collectable.feb21 + pro1F3Collectable.feb21
]
export const pro1WithFeb21NonCount = [
    pro1F1NonCollectable.feb21,
    pro1F2NonCollectable.feb21,
    pro1F3NonCollectable.feb21,
    pro1F1NonCollectable.feb21 + pro1F2NonCollectable.feb21 + pro1F3NonCollectable.feb21
]
export const pro1WithFeb21Claim = [
    pro1F1ClaimCount.feb21,
    pro1F2ClaimCount.feb21,
    pro1F3ClaimCount.feb21,
    pro1F1ClaimCount.feb21 + pro1F2ClaimCount.feb21 + pro1F3ClaimCount.feb21
]
export const pro1WithFeb21PerVisit = [
    pro1F1PerVisit.feb21,
    pro1F2PerVisit.feb21,
    pro1F3PerVisit.feb21,
    provider1PerVisist[1]
]
// mar 21
export const pro1WithMar21Charges = [
    pro1F1Charge.mar21,
    pro1F2Charge.mar21,
    pro1F3Charge.mar21,
    pro1F1Charge.mar21 + pro1F2Charge.mar21 + pro1F3Charge.mar21
]
export const pro1WithMar21Pay = [
    pro1F1Payment.mar21,
    pro1F2Payment.mar21,
    pro1F3Payment.mar21,
    pro1F1Payment.mar21 + pro1F2Payment.mar21 + pro1F3Payment.mar21
]
export const pro1WithMar21Count = [
    pro1F1Collectable.mar21,
    pro1F2Collectable.mar21,
    pro1F3Collectable.mar21,
    pro1F1Collectable.mar21 + pro1F2Collectable.mar21 + pro1F3Collectable.mar21
]
export const pro1WithMar21NonCount = [
    pro1F1NonCollectable.mar21,
    pro1F2NonCollectable.mar21,
    pro1F3NonCollectable.mar21,
    pro1F1NonCollectable.mar21 + pro1F2NonCollectable.mar21 + pro1F3NonCollectable.mar21
]
export const pro1WithMar21Claim = [
    pro1F1ClaimCount.mar21,
    pro1F2ClaimCount.mar21,
    pro1F3ClaimCount.mar21,
    pro1F1ClaimCount.mar21 + pro1F2ClaimCount.mar21 + pro1F3ClaimCount.mar21
]
export const pro1WithMar21PerVisit = [
    pro1F1PerVisit.mar21,
    pro1F2PerVisit.mar21,
    pro1F3PerVisit.mar21,
    provider1PerVisist[2]
]
// april 21
export const pro1WithApr21Charges = [
    pro1F1Charge.apr21,
    pro1F2Charge.apr21,
    pro1F3Charge.apr21,
    pro1F1Charge.apr21 + pro1F2Charge.apr21 + pro1F3Charge.apr21
]
export const pro1WithApr21Pay = [
    pro1F1Payment.apr21,
    pro1F2Payment.apr21,
    pro1F3Payment.apr21,
    pro1F1Payment.apr21 + pro1F2Payment.apr21 + pro1F3Payment.apr21
]
export const pro1WithApr21Count = [
    pro1F1Collectable.apr21,
    pro1F2Collectable.apr21,
    pro1F3Collectable.apr21,
    pro1F1Collectable.apr21 + pro1F2Collectable.apr21 + pro1F3Collectable.apr21
]
export const pro1WithApr21NonCount = [
    pro1F1NonCollectable.apr21,
    pro1F2NonCollectable.apr21,
    pro1F3NonCollectable.apr21,
    pro1F1NonCollectable.apr21 + pro1F2NonCollectable.apr21 + pro1F3NonCollectable.apr21
]
export const pro1WithApr21Claim = [
    pro1F1ClaimCount.apr21,
    pro1F2ClaimCount.apr21,
    pro1F3ClaimCount.apr21,
    pro1F1ClaimCount.apr21 + pro1F2ClaimCount.apr21 + pro1F3ClaimCount.apr21
]
export const pro1WithApr21PerVisit = [
    pro1F1PerVisit.apr21,
    pro1F2PerVisit.apr21,
    pro1F3PerVisit.apr21,
    provider1PerVisist[3]
]
// may 21
export const pro1WithMay21Charges = [
    pro1F1Charge.may21,
    pro1F2Charge.may21,
    pro1F3Charge.may21,
    pro1F1Charge.may21 + pro1F2Charge.may21 + pro1F3Charge.may21
]
export const pro1WithMay21Pay = [
    pro1F1Payment.may21,
    pro1F2Payment.may21,
    pro1F3Payment.may21,
    pro1F1Payment.may21 + pro1F2Payment.may21 + pro1F3Payment.may21
]
export const pro1WithMay21Count = [
    pro1F1Collectable.may21,
    pro1F2Collectable.may21,
    pro1F3Collectable.may21,
    pro1F1Collectable.may21 + pro1F2Collectable.may21 + pro1F3Collectable.may21
]
export const pro1WithMay21NonCount = [
    pro1F1NonCollectable.may21,
    pro1F2NonCollectable.may21,
    pro1F3NonCollectable.may21,
    pro1F1NonCollectable.may21 + pro1F2NonCollectable.may21 + pro1F3NonCollectable.may21
]
export const pro1WithMay21Claim = [
    pro1F1ClaimCount.may21,
    pro1F2ClaimCount.may21,
    pro1F3ClaimCount.may21,
    pro1F1ClaimCount.may21 + pro1F2ClaimCount.may21 + pro1F3ClaimCount.may21
]
export const pro1WithMay21PerVisit = [
    pro1F1PerVisit.may21,
    pro1F2PerVisit.may21,
    pro1F3PerVisit.may21,
    provider1PerVisist[4]
]
// jun 21
export const pro1WithJun21Charges = [
    pro1F1Charge.jun21,
    pro1F2Charge.jun21,
    pro1F3Charge.jun21,
    pro1F1Charge.jun21 + pro1F2Charge.jun21 + pro1F3Charge.jun21
]
export const pro1WithJun21Pay = [
    pro1F1Payment.jun21,
    pro1F2Payment.jun21,
    pro1F3Payment.jun21,
    pro1F1Payment.jun21 + pro1F2Payment.jun21 + pro1F3Payment.jun21
]
export const pro1WithJun21Count = [
    pro1F1Collectable.jun21,
    pro1F2Collectable.jun21,
    pro1F3Collectable.jun21,
    pro1F1Collectable.jun21 + pro1F2Collectable.jun21 + pro1F3Collectable.jun21
]
export const pro1WithJun21NonCount = [
    pro1F1NonCollectable.jun21,
    pro1F2NonCollectable.jun21,
    pro1F3NonCollectable.jun21,
    pro1F1NonCollectable.jun21 + pro1F2NonCollectable.jun21 + pro1F3NonCollectable.jun21
]
export const pro1WithJun21Claim = [
    pro1F1ClaimCount.jun21,
    pro1F2ClaimCount.jun21,
    pro1F3ClaimCount.jun21,
    pro1F1ClaimCount.jun21 + pro1F2ClaimCount.jun21 + pro1F3ClaimCount.jun21
]
export const pro1WithJun21PerVisit = [
    pro1F1PerVisit.jun21,
    pro1F2PerVisit.jun21,
    pro1F3PerVisit.jun21,
    provider1PerVisist[5]
]
// jul 21
export const pro1WithJul21Charges = [
    pro1F1Charge.jul21,
    pro1F2Charge.jul21,
    pro1F3Charge.jul21,
    pro1F1Charge.jul21 + pro1F2Charge.jul21 + pro1F3Charge.jul21,

]
export const pro1WithJul21Pay = [
    pro1F1Payment.jul21,
    pro1F2Payment.jul21,
    pro1F3Payment.jul21,
    pro1F1Payment.jul21 + pro1F2Payment.jul21 + pro1F3Payment.jul21
]
export const pro1WithJul21Count = [
    pro1F1Collectable.jul21,
    pro1F2Collectable.jul21,
    pro1F3Collectable.jul21,
    pro1F1Collectable.jul21 + pro1F2Collectable.jul21 + pro1F3Collectable.jul21
]
export const pro1WithJul21NonCount = [
    pro1F1NonCollectable.jul21,
    pro1F2NonCollectable.jul21,
    pro1F3NonCollectable.jul21,
    pro1F1NonCollectable.jul21 + pro1F2NonCollectable.jul21 + pro1F3NonCollectable.jul21
]
export const pro1WithJul21Claim = [
    pro1F1ClaimCount.jul21,
    pro1F2ClaimCount.jul21,
    pro1F3ClaimCount.jul21,
    pro1F1ClaimCount.jul21 + pro1F2ClaimCount.jul21 + pro1F3ClaimCount.jul21
]
export const pro1WithJul21PerVisit = [
    pro1F1PerVisit.jul21,
    pro1F2PerVisit.jul21,
    pro1F3PerVisit.jul21,
    provider1PerVisist[6]
]
// aug 21
export const pro1WithAug21Charges = [
    pro1F1Charge.aug21,
    pro1F2Charge.aug21,
    pro1F3Charge.aug21,
    pro1F1Charge.aug21 + pro1F2Charge.aug21 + pro1F3Charge.aug21
]
export const pro1WithAug21Pay = [
    pro1F1Payment.aug21,
    pro1F2Payment.aug21,
    pro1F3Payment.aug21,
    pro1F1Payment.aug21 + pro1F2Payment.aug21 + pro1F3Payment.aug21
]
export const pro1WithAug21Count = [
    pro1F1Collectable.aug21,
    pro1F2Collectable.aug21,
    pro1F3Collectable.aug21,
    pro1F1Collectable.aug21 + pro1F2Collectable.aug21 + pro1F3Collectable.aug21
]
export const pro1WithAug21NonCount = [
    pro1F1NonCollectable.aug21,
    pro1F2NonCollectable.aug21,
    pro1F3NonCollectable.aug21,
    pro1F1NonCollectable.aug21 + pro1F2NonCollectable.aug21 + pro1F3NonCollectable.aug21
]
export const pro1WithAug21Claim = [
    pro1F1ClaimCount.aug21,
    pro1F2ClaimCount.aug21,
    pro1F3ClaimCount.aug21,
    pro1F1ClaimCount.aug21 + pro1F2ClaimCount.aug21 + pro1F3ClaimCount.aug21
]
export const pro1WithAug21PerVisit = [
    pro1F1PerVisit.aug21,
    pro1F2PerVisit.aug21,
    pro1F3PerVisit.aug21,
    provider1PerVisist[7]
]
// sep 21
export const pro1WithSep21Charges = [
    pro1F1Charge.sep21,
    pro1F2Charge.sep21,
    pro1F3Charge.sep21,
    pro1F1Charge.sep21 + pro1F2Charge.sep21 + pro1F3Charge.sep21
]
export const pro1WithSep21Pay = [
    pro1F1Payment.sep21,
    pro1F2Payment.sep21,
    pro1F3Payment.sep21,
    pro1F1Payment.sep21 + pro1F2Payment.sep21 + pro1F3Payment.sep21
]
export const pro1WithSep21Count = [
    pro1F1Collectable.sep21,
    pro1F2Collectable.sep21,
    pro1F3Collectable.sep21,
    pro1F1Collectable.sep21 + pro1F2Collectable.sep21 + pro1F3Collectable.sep21
]
export const pro1WithSep21NonCount = [
    pro1F1NonCollectable.sep21,
    pro1F2NonCollectable.sep21,
    pro1F3NonCollectable.sep21,
    pro1F1NonCollectable.sep21 + pro1F2NonCollectable.sep21 + pro1F3NonCollectable.sep21
]
export const pro1WithSep21Claim = [
    pro1F1ClaimCount.sep21,
    pro1F2ClaimCount.sep21,
    pro1F3ClaimCount.sep21,
    pro1F1ClaimCount.sep21 + pro1F2ClaimCount.sep21 + pro1F3ClaimCount.sep21
]
export const pro1WithSep21PerVisit = [
    pro1F1PerVisit.sep21,
    pro1F2PerVisit.sep21,
    pro1F3PerVisit.sep21,
    provider1PerVisist[8]
]
// oct 21
export const pro1WithOct21Charges = [
    pro1F1Charge.oct21,
    pro1F2Charge.oct21,
    pro1F3Charge.oct21,
    pro1F1Charge.oct21 + pro1F2Charge.oct21 + pro1F3Charge.oct21
]
export const pro1WithOct21Pay = [
    pro1F1Payment.oct21,
    pro1F2Payment.oct21,
    pro1F3Payment.oct21,
    pro1F1Payment.oct21 + pro1F2Payment.oct21 + pro1F3Payment.oct21
]
export const pro1WithOct21Count = [
    pro1F1Collectable.oct21,
    pro1F2Collectable.oct21,
    pro1F3Collectable.oct21,
    pro1F1Collectable.oct21 + pro1F2Collectable.oct21 + pro1F3Collectable.oct21
]
export const pro1WithOct21NonCount = [
    pro1F1NonCollectable.oct21,
    pro1F2NonCollectable.oct21,
    pro1F3NonCollectable.oct21,
    pro1F1NonCollectable.oct21 + pro1F2NonCollectable.oct21 + pro1F3NonCollectable.oct21
]
export const pro1WithOct21Claim = [
    pro1F1ClaimCount.oct21,
    pro1F2ClaimCount.oct21,
    pro1F3ClaimCount.oct21,
    pro1F1ClaimCount.oct21 + pro1F2ClaimCount.oct21 + pro1F3ClaimCount.oct21
]
export const pro1WithOct21PerVisit = [
    pro1F1PerVisit.oct21,
    pro1F2PerVisit.oct21,
    pro1F3PerVisit.oct21,
    provider1PerVisist[9]
]
// nov 21
export const pro1WithNov21Charges = [
    pro1F1Charge.nov21,
    pro1F2Charge.nov21,
    pro1F3Charge.nov21,
    pro1F1Charge.nov21 + pro1F2Charge.nov21 + pro1F3Charge.nov21
]
export const pro1WithNov21Pay = [
    pro1F1Payment.nov21,
    pro1F2Payment.nov21,
    pro1F3Payment.nov21,
    pro1F1Payment.nov21 + pro1F2Payment.nov21 + pro1F3Payment.nov21
]
export const pro1WithNov21Count = [
    pro1F1Collectable.nov21,
    pro1F2Collectable.nov21,
    pro1F3Collectable.nov21,
    pro1F1Collectable.nov21 + pro1F2Collectable.nov21 + pro1F3Collectable.nov21
]
export const pro1WithNov21NonCount = [
    pro1F1NonCollectable.nov21,
    pro1F2NonCollectable.nov21,
    pro1F3NonCollectable.nov21,
    pro1F1NonCollectable.nov21 + pro1F2NonCollectable.nov21 + pro1F3NonCollectable.nov21
]
export const pro1WithNov21Claim = [
    pro1F1ClaimCount.nov21,
    pro1F2ClaimCount.nov21,
    pro1F3ClaimCount.nov21,
    pro1F1ClaimCount.nov21 + pro1F2ClaimCount.nov21 + pro1F3ClaimCount.nov21
]
export const pro1WithNov21PerVisit = [
    pro1F1PerVisit.nov21,
    pro1F2PerVisit.nov21,
    pro1F3PerVisit.nov21,
    provider1PerVisist[10]
]
// dec 21
export const pro1WithDec21Charges = [
    pro1F1Charge.dec21,
    pro1F2Charge.dec21,
    pro1F3Charge.dec21,
    pro1F1Charge.dec21 + pro1F2Charge.dec21 + pro1F3Charge.dec21
]
export const pro1WithDec21Pay = [
    pro1F1Payment.dec21,
    pro1F2Payment.dec21,
    pro1F3Payment.dec21,
    pro1F1Payment.dec21 + pro1F2Payment.dec21 + pro1F3Payment.dec21
]
export const pro1WithDec21Count = [
    pro1F1Collectable.dec21,
    pro1F2Collectable.dec21,
    pro1F3Collectable.dec21,
    pro1F1Collectable.dec21 + pro1F2Collectable.dec21 + pro1F3Collectable.dec21
]
export const pro1WithDec21NonCount = [
    pro1F1NonCollectable.dec21,
    pro1F2NonCollectable.dec21,
    pro1F3NonCollectable.dec21,
    pro1F1NonCollectable.dec21 + pro1F2NonCollectable.dec21 + pro1F3NonCollectable.dec21
] 
export const pro1WithDec21Claim = [
    pro1F1ClaimCount.dec21,
    pro1F2ClaimCount.dec21,
    pro1F3ClaimCount.dec21,
    pro1F1ClaimCount.dec21 + pro1F2ClaimCount.dec21 + pro1F3ClaimCount.dec21
]
export const pro1WithDec21PerVisit = [
    pro1F1PerVisit.dec21,
    pro1F2PerVisit.dec21,
    pro1F3PerVisit.dec21,
    provider1PerVisist[11]
]

// provider-1 
// jan 22
export const pro1WithJan22Charges = [
    pro1F1Charge.jan22,
    pro1F2Charge.jan22,
    pro1F3Charge.jan22,
    pro1F1Charge.jan22 + pro1F2Charge.jan22 + pro1F3Charge.jan22
]
export const pro1WithJan22Pay = [
    pro1F1Payment.jan22,
    pro1F2Payment.jan22,
    pro1F3Payment.jan22,
    pro1F1Payment.jan22 + pro1F2Payment.jan22 + pro1F3Payment.jan22
]
export const pro1WithJan22Count = [
    pro1F1Collectable.jan22,
    pro1F2Collectable.jan22,
    pro1F3Collectable.jan22,
    pro1F1Collectable.jan22 + pro1F2Collectable.jan22 + pro1F3Collectable.jan22
]
export const pro1WithJan22NonCount = [
    pro1F1NonCollectable.jan22,
    pro1F2NonCollectable.jan22,
    pro1F3NonCollectable.jan22,
    pro1F1NonCollectable.jan22 + pro1F2NonCollectable.jan22 + pro1F3NonCollectable.jan22
]
export const pro1WithJan22Claim = [
    pro1F1ClaimCount.jan22,
    pro1F2ClaimCount.jan22,
    pro1F3ClaimCount.jan22,
    pro1F1ClaimCount.jan22 + pro1F2ClaimCount.jan22 + pro1F3ClaimCount.jan22
]
export const pro1WithJan22PerVisit = [
    pro1F1PerVisit.jan22,
    pro1F2PerVisit.jan22,
    pro1F3PerVisit.jan22,
    provider1PerVisist[12]
]
// feb 22
export const pro1WithFeb22Charges = [
    pro1F1Charge.feb22,
    pro1F2Charge.feb22,
    pro1F3Charge.feb22,
    pro1F1Charge.feb22 + pro1F2Charge.feb22 + pro1F3Charge.feb22
]
export const pro1WithFeb22Pay = [
    pro1F1Payment.feb22,
    pro1F2Payment.feb22,
    pro1F3Payment.feb22,
    pro1F1Payment.feb22 + pro1F2Payment.feb22 + pro1F3Payment.feb22
]
export const pro1WithFeb22Count = [
    pro1F1Collectable.feb22,
    pro1F2Collectable.feb22,
    pro1F3Collectable.feb22,
    pro1F1Collectable.feb22 + pro1F2Collectable.feb22 + pro1F3Collectable.feb22
]
export const pro1WithFeb22NonCount = [
    pro1F1NonCollectable.feb22,
    pro1F2NonCollectable.feb22,
    pro1F3NonCollectable.feb22,
    pro1F1NonCollectable.feb22 + pro1F2NonCollectable.feb22 + pro1F3NonCollectable.feb22
]
export const pro1WithFeb22Claim = [
    pro1F1ClaimCount.feb22,
    pro1F2ClaimCount.feb22,
    pro1F3ClaimCount.feb22,
    pro1F1ClaimCount.feb22 + pro1F2ClaimCount.feb22 + pro1F3ClaimCount.feb22
]
export const pro1WithFeb22PerVisit = [
    pro1F1PerVisit.feb22,
    pro1F2PerVisit.feb22,
    pro1F3PerVisit.feb22,
    provider1PerVisist[13]
]
// mar 22
export const pro1WithMar22Charges = [
    pro1F1Charge.mar22,
    pro1F2Charge.mar22,
    pro1F3Charge.mar22,
    pro1F1Charge.mar22 + pro1F2Charge.mar22 + pro1F3Charge.mar22
]
export const pro1WithMar22Pay = [
    pro1F1Payment.mar22,
    pro1F2Payment.mar22,
    pro1F3Payment.mar22,
    pro1F1Payment.mar22 + pro1F2Payment.mar22 + pro1F3Payment.mar22
]
export const pro1WithMar22Count = [
    pro1F1Collectable.mar22,
    pro1F2Collectable.mar22,
    pro1F3Collectable.mar22,
    pro1F1Collectable.mar22 + pro1F2Collectable.mar22 + pro1F3Collectable.mar22
]
export const pro1WithMar22NonCount = [
    pro1F1NonCollectable.mar22,
    pro1F2NonCollectable.mar22,
    pro1F3NonCollectable.mar22,
    pro1F1NonCollectable.mar22 + pro1F2NonCollectable.mar22 + pro1F3NonCollectable.mar22
]
export const pro1WithMar22Claim = [
    pro1F1ClaimCount.mar22,
    pro1F2ClaimCount.mar22,
    pro1F3ClaimCount.mar22,
    pro1F1ClaimCount.mar22 + pro1F2ClaimCount.mar22 + pro1F3ClaimCount.mar22
]
export const pro1WithMar22PerVisit = [
    pro1F1PerVisit.mar22,
    pro1F2PerVisit.mar22,
    pro1F3PerVisit.mar22,
    provider1PerVisist[14]
]
// april 22
export const pro1WithApr22Charges = [
    pro1F1Charge.apr22,
    pro1F2Charge.apr22,
    pro1F3Charge.apr22,
    pro1F1Charge.apr22 + pro1F2Charge.apr22 + pro1F3Charge.apr22
]
export const pro1WithApr22Pay = [
    pro1F1Payment.apr22,
    pro1F2Payment.apr22,
    pro1F3Payment.apr22,
    pro1F1Payment.apr22 + pro1F2Payment.apr22 + pro1F3Payment.apr22
]
export const pro1WithApr22Count = [
    pro1F1Collectable.apr22,
    pro1F2Collectable.apr22,
    pro1F3Collectable.apr22,
    pro1F1Collectable.apr22 + pro1F2Collectable.apr22 + pro1F3Collectable.apr22
]
export const pro1WithApr22NonCount = [
    pro1F1NonCollectable.apr22,
    pro1F2NonCollectable.apr22,
    pro1F3NonCollectable.apr22,
    pro1F1NonCollectable.apr22 + pro1F2NonCollectable.apr22 + pro1F3NonCollectable.apr22
]
export const pro1WithApr22Claim = [
    pro1F1ClaimCount.apr22,
    pro1F2ClaimCount.apr22,
    pro1F3ClaimCount.apr22,
    pro1F1ClaimCount.apr22 + pro1F2ClaimCount.apr22 + pro1F3ClaimCount.apr22
]
export const pro1WithApr22PerVisit = [
    pro1F1PerVisit.apr22,
    pro1F2PerVisit.apr22,
    pro1F3PerVisit.apr22,
    provider1PerVisist[15]
]
// may 22
export const pro1WithMay22Charges = [
    pro1F1Charge.may22,
    pro1F2Charge.may22,
    pro1F3Charge.may22,
    pro1F1Charge.may22 + pro1F2Charge.may22 + pro1F3Charge.may22
]
export const pro1WithMay22Pay = [
    pro1F1Payment.may22,
    pro1F2Payment.may22,
    pro1F3Payment.may22,
    pro1F1Payment.may22 + pro1F2Payment.may22 + pro1F3Payment.may22
]
export const pro1WithMay22Count = [
    pro1F1Collectable.may22,
    pro1F2Collectable.may22,
    pro1F3Collectable.may22,
    pro1F1Collectable.may22 + pro1F2Collectable.may22 + pro1F3Collectable.may22
]
export const pro1WithMay22NonCount = [
    pro1F1NonCollectable.may22,
    pro1F2NonCollectable.may22,
    pro1F3NonCollectable.may22,
    pro1F1NonCollectable.may22 + pro1F2NonCollectable.may22 + pro1F3NonCollectable.may22
]
export const pro1WithMay22Claim = [
    pro1F1ClaimCount.may22,
    pro1F2ClaimCount.may22,
    pro1F3ClaimCount.may22,
    pro1F1ClaimCount.may22 + pro1F2ClaimCount.may22 + pro1F3ClaimCount.may22
]
export const pro1WithMay22PerVisit = [
    pro1F1PerVisit.may22,
    pro1F2PerVisit.may22,
    pro1F3PerVisit.may22,
    provider1PerVisist[16]
]
// jun 22
export const pro1WithJun22Charges = [
    pro1F1Charge.jun22,
    pro1F2Charge.jun22,
    pro1F3Charge.jun22,
    pro1F1Charge.jun22 + pro1F2Charge.jun22 + pro1F3Charge.jun22
]
export const pro1WithJun22Pay = [
    pro1F1Payment.jun22,
    pro1F2Payment.jun22,
    pro1F3Payment.jun22,
    pro1F1Payment.jun22 + pro1F2Payment.jun22 + pro1F3Payment.jun22
]
export const pro1WithJun22Count = [
    pro1F1Collectable.jun22,
    pro1F2Collectable.jun22,
    pro1F3Collectable.jun22,
    pro1F1Collectable.jun22 + pro1F2Collectable.jun22 + pro1F3Collectable.jun22
]
export const pro1WithJun22NonCount = [
    pro1F1NonCollectable.jun22,
    pro1F2NonCollectable.jun22,
    pro1F3NonCollectable.jun22,
    pro1F1NonCollectable.jun22 + pro1F2NonCollectable.jun22 + pro1F3NonCollectable.jun22
]
export const pro1WithJun22Claim = [
    pro1F1ClaimCount.jun22,
    pro1F2ClaimCount.jun22,
    pro1F3ClaimCount.jun22,
    pro1F1ClaimCount.jun22 + pro1F2ClaimCount.jun22 + pro1F3ClaimCount.jun22
]
export const pro1WithJun22PerVisit = [
    pro1F1PerVisit.jun22,
    pro1F2PerVisit.jun22,
    pro1F3PerVisit.jun22,
    provider1PerVisist[17]
]
// jul 22
export const pro1WithJul22Charges = [
    pro1F1Charge.jul22,
    pro1F2Charge.jul22,
    pro1F3Charge.jul22,
    pro1F1Charge.jul22 + pro1F2Charge.jul22 + pro1F3Charge.jul22
]
export const pro1WithJul22Pay = [
    pro1F1Payment.jul22,
    pro1F2Payment.jul22,
    pro1F3Payment.jul22,
    pro1F1Payment.jul22 + pro1F2Payment.jul22 + pro1F3Payment.jul22
]
export const pro1WithJul22Count = [
    pro1F1Collectable.jul22,
    pro1F2Collectable.jul22,
    pro1F3Collectable.jul22,
    pro1F1Collectable.jul22 + pro1F2Collectable.jul22 + pro1F3Collectable.jul22
]
export const pro1WithJul22NonCount = [
    pro1F1NonCollectable.jul22,
    pro1F2NonCollectable.jul22,
    pro1F3NonCollectable.jul22,
    pro1F1NonCollectable.jul22 + pro1F2NonCollectable.jul22 + pro1F3NonCollectable.jul22
]
export const pro1WithJul22Claim = [
    pro1F1ClaimCount.jul22,
    pro1F2ClaimCount.jul22,
    pro1F3ClaimCount.jul22,
    pro1F1ClaimCount.jul22 + pro1F2ClaimCount.jul22 + pro1F3ClaimCount.jul22
]
export const pro1WithJul22PerVisit = [
    pro1F1PerVisit.jul22,
    pro1F2PerVisit.jul22,
    pro1F3PerVisit.jul22,
    provider1PerVisist[18]
]
// aug 22
export const pro1WithAug22Charges = [
    pro1F1Charge.aug22,
    pro1F2Charge.aug22,
    pro1F3Charge.aug22,
    pro1F1Charge.aug22 + pro1F2Charge.aug22 + pro1F3Charge.aug22
]
export const pro1WithAug22Pay = [
    pro1F1Payment.aug22,
    pro1F2Payment.aug22,
    pro1F3Payment.aug22,
    pro1F1Payment.aug22 + pro1F2Payment.aug22 + pro1F3Payment.aug22
]
export const pro1WithAug22Count = [
    pro1F1Collectable.aug22,
    pro1F2Collectable.aug22,
    pro1F3Collectable.aug22,
    pro1F1Collectable.aug22 + pro1F2Collectable.aug22 + pro1F3Collectable.aug22
]
export const pro1WithAug22NonCount = [
    pro1F1NonCollectable.aug22,
    pro1F2NonCollectable.aug22,
    pro1F3NonCollectable.aug22,
    pro1F1NonCollectable.aug22 + pro1F2NonCollectable.aug22 + pro1F3NonCollectable.aug22
]
export const pro1WithAug22Claim = [
    pro1F1ClaimCount.aug22,
    pro1F2ClaimCount.aug22,
    pro1F3ClaimCount.aug22,
    pro1F1ClaimCount.aug22 + pro1F2ClaimCount.aug22 + pro1F3ClaimCount.aug22
]
export const pro1WithAug22PerVisit = [
    pro1F1PerVisit.aug22,
    pro1F2PerVisit.aug22,
    pro1F3PerVisit.aug22,
    provider1PerVisist[19]
]
// sep 22
export const pro1WithSep22Charges = [
    pro1F1Charge.sep22,
    pro1F2Charge.sep22,
    pro1F3Charge.sep22,
    pro1F1Charge.sep22 + pro1F2Charge.sep22 + pro1F3Charge.sep22
]
export const pro1WithSep22Pay = [
    pro1F1Payment.sep22,
    pro1F2Payment.sep22,
    pro1F3Payment.sep22,
    pro1F1Payment.sep22 + pro1F2Payment.sep22 + pro1F3Payment.sep22
]
export const pro1WithSep22Count = [
    pro1F1Collectable.sep22,
    pro1F2Collectable.sep22,
    pro1F3Collectable.sep22,
    pro1F1Collectable.sep22 + pro1F2Collectable.sep22 + pro1F3Collectable.sep22
]
export const pro1WithSep22NonCount = [
    pro1F1NonCollectable.sep22,
    pro1F2NonCollectable.sep22,
    pro1F3NonCollectable.sep22,
    pro1F1NonCollectable.sep22 + pro1F2NonCollectable.sep22 + pro1F3NonCollectable.sep22
]
export const pro1WithSep22Claim = [
    pro1F1ClaimCount.sep22,
    pro1F2ClaimCount.sep22,
    pro1F3ClaimCount.sep22,
    pro1F1ClaimCount.sep22 + pro1F2ClaimCount.sep22 + pro1F3ClaimCount.sep22
]
export const pro1WithSep22PerVisit = [
    pro1F1PerVisit.sep22,
    pro1F2PerVisit.sep22,
    pro1F3PerVisit.sep22,
    provider1PerVisist[20]
]
// oct 22
export const pro1WithOct22Charges = [
    pro1F1Charge.oct22,
    pro1F2Charge.oct22,
    pro1F3Charge.oct22,
    pro1F1Charge.oct22 + pro1F2Charge.oct22 + pro1F3Charge.oct22
]
export const pro1WithOct22Pay = [
    pro1F1Payment.oct22,
    pro1F2Payment.oct22,
    pro1F3Payment.oct22,
    pro1F1Payment.oct22 + pro1F2Payment.oct22 + pro1F3Payment.oct22
]
export const pro1WithOct22Count = [
    pro1F1Collectable.oct22,
    pro1F2Collectable.oct22,
    pro1F3Collectable.oct22,
    pro1F1Collectable.oct22 + pro1F2Collectable.oct22 + pro1F3Collectable.oct22
]
export const pro1WithOct22NonCount = [
    pro1F1NonCollectable.oct22,
    pro1F2NonCollectable.oct22,
    pro1F3NonCollectable.oct22,
    pro1F1NonCollectable.oct22 + pro1F2NonCollectable.oct22 + pro1F3NonCollectable.oct22
]
export const pro1WithOct22Claim = [
    pro1F1ClaimCount.oct22,
    pro1F2ClaimCount.oct22,
    pro1F3ClaimCount.oct22,
    pro1F1ClaimCount.oct22 + pro1F2ClaimCount.oct22 + pro1F3ClaimCount.oct22
]
export const pro1WithOct22PerVisit = [
    pro1F1PerVisit.oct22,
    pro1F2PerVisit.oct22,
    pro1F3PerVisit.oct22,
    provider1PerVisist[21]
]
// nov 22
export const pro1WithNov22Charges = [
    pro1F1Charge.nov22,
    pro1F2Charge.nov22,
    pro1F3Charge.nov22,
    pro1F1Charge.nov22 + pro1F2Charge.nov22 + pro1F3Charge.nov22
]
export const pro1WithNov22Pay = [
    pro1F1Payment.nov22,
    pro1F2Payment.nov22,
    pro1F3Payment.nov22,
    pro1F1Payment.nov22 + pro1F2Payment.nov22 + pro1F3Payment.nov22
]
export const pro1WithNov22Count = [
    pro1F1Collectable.nov22,
    pro1F2Collectable.nov22,
    pro1F3Collectable.nov22,
    pro1F1Collectable.nov22 + pro1F2Collectable.nov22 + pro1F3Collectable.nov22
]
export const pro1WithNov22NonCount = [
    pro1F1NonCollectable.nov22,
    pro1F2NonCollectable.nov22,
    pro1F3NonCollectable.nov22,
    pro1F1NonCollectable.nov22 + pro1F2NonCollectable.nov22 + pro1F3NonCollectable.nov22
]
export const pro1WithNov22Claim = [
    pro1F1ClaimCount.nov22,
    pro1F2ClaimCount.nov22,
    pro1F3ClaimCount.nov22,
    pro1F1ClaimCount.nov22 + pro1F2ClaimCount.nov22 + pro1F3ClaimCount.nov22
]
export const pro1WithNov22PerVisit = [
    pro1F1PerVisit.nov22,
    pro1F2PerVisit.nov22,
    pro1F3PerVisit.nov22,
    provider1PerVisist[22]
]
// dec 22
export const pro1WithDec22Charges = [
    pro1F1Charge.dec22,
    pro1F2Charge.dec22,
    pro1F3Charge.dec22,
    pro1F1Charge.dec22 + pro1F2Charge.dec22 + pro1F3Charge.dec22
]
export const pro1WithDec22Pay = [
    pro1F1Payment.dec22,
    pro1F2Payment.dec22,
    pro1F3Payment.dec22,
    pro1F1Payment.dec22 + pro1F2Payment.dec22 + pro1F3Payment.dec22
]
export const pro1WithDec22Count = [
    pro1F1Collectable.dec22,
    pro1F2Collectable.dec22,
    pro1F3Collectable.dec22,
    pro1F1Collectable.dec22 + pro1F2Collectable.dec22 + pro1F3Collectable.dec22
]
export const pro1WithDec22NonCount = [
    pro1F1NonCollectable.dec22,
    pro1F2NonCollectable.dec22,
    pro1F3NonCollectable.dec22,
    pro1F1NonCollectable.dec22 + pro1F2NonCollectable.dec22 + pro1F3NonCollectable.dec22
]
export const pro1WithDec22Claim = [
    pro1F1ClaimCount.dec22,
    pro1F2ClaimCount.dec22,
    pro1F3ClaimCount.dec22,
    pro1F1ClaimCount.dec22 + pro1F2ClaimCount.dec22 + pro1F3ClaimCount.dec22
]
export const pro1WithDec22PerVisit = [
    pro1F1PerVisit.dec22,
    pro1F2PerVisit.dec22,
    pro1F3PerVisit.dec22,
    provider1PerVisist[23]
]

// provider-1 
// jan 23
export const pro1WithJan23Charges = [
    pro1F1Charge.jan23,
    pro1F2Charge.jan23,
    pro1F3Charge.jan23,
    pro1F1Charge.jan23 + pro1F2Charge.jan23 + pro1F3Charge.jan23
]
export const pro1WithJan23Pay = [
    pro1F1Payment.jan23,
    pro1F2Payment.jan23,
    pro1F3Payment.jan23,
    pro1F1Payment.jan23 + pro1F2Payment.jan23 + pro1F3Payment.jan23
]
export const pro1WithJan23Count = [
    pro1F1Collectable.jan23,
    pro1F2Collectable.jan23,
    pro1F3Collectable.jan23,
    pro1F1Collectable.jan23 + pro1F2Collectable.jan23 + pro1F3Collectable.jan23
]
export const pro1WithJan23NonCount = [
    pro1F1NonCollectable.jan23,
    pro1F2NonCollectable.jan23,
    pro1F3NonCollectable.jan23,
    pro1F1NonCollectable.jan23 + pro1F2NonCollectable.jan23 + pro1F3NonCollectable.jan23
]
export const pro1WithJan23Claim = [
    pro1F1ClaimCount.jan23,
    pro1F2ClaimCount.jan23,
    pro1F3ClaimCount.jan23,
    pro1F1ClaimCount.jan23 + pro1F2ClaimCount.jan23 + pro1F3ClaimCount.jan23
]
export const pro1WithJan23PerVisit = [
    pro1F1PerVisit.jan23,
    pro1F2PerVisit.jan23,
    pro1F3PerVisit.jan23,
    provider1PerVisist[24]
]
// feb 23
export const pro1WithFeb23Charges = [
    pro1F1Charge.feb23,
    pro1F2Charge.feb23,
    pro1F3Charge.feb23,
    pro1F1Charge.feb23 + pro1F2Charge.feb23 + pro1F3Charge.feb23
]
export const pro1WithFeb23Pay = [
    pro1F1Payment.feb23,
    pro1F2Payment.feb23,
    pro1F3Payment.feb23,
    pro1F1Payment.feb23 + pro1F2Payment.feb23 + pro1F3Payment.feb23
]
export const pro1WithFeb23Count = [
    pro1F1Collectable.feb23,
    pro1F2Collectable.feb23,
    pro1F3Collectable.feb23,
    pro1F1Collectable.feb23 + pro1F2Collectable.feb23 + pro1F3Collectable.feb23
]
export const pro1WithFeb23NonCount = [
    pro1F1NonCollectable.feb23,
    pro1F2NonCollectable.feb23,
    pro1F3NonCollectable.feb23,
    pro1F1NonCollectable.feb23 + pro1F2NonCollectable.feb23 + pro1F3NonCollectable.feb23
]
export const pro1WithFeb23Claim = [
    pro1F1ClaimCount.feb23,
    pro1F2ClaimCount.feb23,
    pro1F3ClaimCount.feb23,
    pro1F1ClaimCount.feb23 + pro1F2ClaimCount.feb23 + pro1F3ClaimCount.feb23
]
export const pro1WithFeb23PerVisit = [
    pro1F1PerVisit.feb23,
    pro1F2PerVisit.feb23,
    pro1F3PerVisit.feb23,
    provider1PerVisist[25]
]
// mar 23
export const pro1WithMar23Charges = [
    pro1F1Charge.mar23,
    pro1F2Charge.mar23,
    pro1F3Charge.mar23,
    pro1F1Charge.mar23 + pro1F2Charge.mar23 + pro1F3Charge.mar23
]
export const pro1WithMar23Pay = [
    pro1F1Payment.mar23,
    pro1F2Payment.mar23,
    pro1F3Payment.mar23,
    pro1F1Payment.mar23 + pro1F2Payment.mar23 + pro1F3Payment.mar23
]
export const pro1WithMar23Count = [
    pro1F1Collectable.mar23,
    pro1F2Collectable.mar23,
    pro1F3Collectable.mar23,
    pro1F1Collectable.mar23 + pro1F2Collectable.mar23 + pro1F3Collectable.mar23
]
export const pro1WithMar23NonCount = [
    pro1F1NonCollectable.mar23,
    pro1F2NonCollectable.mar23,
    pro1F3NonCollectable.mar23,
    pro1F1NonCollectable.mar23 + pro1F2NonCollectable.mar23 + pro1F3NonCollectable.mar23
]
export const pro1WithMar23Claim = [
    pro1F1ClaimCount.mar23,
    pro1F2ClaimCount.mar23,
    pro1F3ClaimCount.mar23,
    pro1F1ClaimCount.mar23 + pro1F2ClaimCount.mar23 + pro1F3ClaimCount.mar23
]
export const pro1WithMar23PerVisit = [
    pro1F1PerVisit.mar23,
    pro1F2PerVisit.mar23,
    pro1F3PerVisit.mar23,
    provider1PerVisist[26]
]
// april 23
export const pro1WithApr23Charges = [
    pro1F1Charge.apr23,
    pro1F2Charge.apr23,
    pro1F3Charge.apr23,
    pro1F1Charge.apr23 + pro1F2Charge.apr23 + pro1F3Charge.apr23
]
export const pro1WithApr23Pay = [
    pro1F1Payment.apr23,
    pro1F2Payment.apr23,
    pro1F3Payment.apr23,
    pro1F1Payment.apr23 + pro1F2Payment.apr23 + pro1F3Payment.apr23
]
export const pro1WithApr23Count = [
    pro1F1Collectable.apr23,
    pro1F2Collectable.apr23,
    pro1F3Collectable.apr23,
    pro1F1Collectable.apr23 + pro1F2Collectable.apr23 + pro1F3Collectable.apr23
]
export const pro1WithApr23NonCount = [
    pro1F1NonCollectable.apr23,
    pro1F2NonCollectable.apr23,
    pro1F3NonCollectable.apr23,
    pro1F1NonCollectable.apr23 + pro1F2NonCollectable.apr23 + pro1F3NonCollectable.apr23
]
export const pro1WithApr23Claim = [
    pro1F1ClaimCount.apr23,
    pro1F2ClaimCount.apr23,
    pro1F3ClaimCount.apr23,
    pro1F1ClaimCount.apr23 + pro1F2ClaimCount.apr23 + pro1F3ClaimCount.apr23
]
export const pro1WithApr23PerVisit = [
    pro1F1PerVisit.apr23,
    pro1F2PerVisit.apr23,
    pro1F3PerVisit.apr23,
    provider1PerVisist[27]
]
// may 23
export const pro1WithMay23Charges = [
    pro1F1Charge.may23,
    pro1F2Charge.may23,
    pro1F3Charge.may23,
    pro1F1Charge.may23 + pro1F2Charge.may23 + pro1F3Charge.may23
]
export const pro1WithMay23Pay = [
    pro1F1Payment.may23,
    pro1F2Payment.may23,
    pro1F3Payment.may23,
    pro1F1Payment.may23 + pro1F2Payment.may23 + pro1F3Payment.may23
]
export const pro1WithMay23Count = [
    pro1F1Collectable.may23,
    pro1F2Collectable.may23,
    pro1F3Collectable.may23,
    pro1F1Collectable.may23 + pro1F2Collectable.may23 + pro1F3Collectable.may23
]
export const pro1WithMay23NonCount = [
    pro1F1NonCollectable.may23,
    pro1F2NonCollectable.may23,
    pro1F3NonCollectable.may23,
    pro1F1NonCollectable.may23 + pro1F2NonCollectable.may23 + pro1F3NonCollectable.may23
]
export const pro1WithMay23Claim = [
    pro1F1ClaimCount.may23,
    pro1F2ClaimCount.may23,
    pro1F3ClaimCount.may23,
    pro1F1ClaimCount.may23 + pro1F2ClaimCount.may23 + pro1F3ClaimCount.may23
]
export const pro1WithMay23PerVisit = [
    pro1F1PerVisit.may23,
    pro1F2PerVisit.may23,
    pro1F3PerVisit.may23,
    provider1PerVisist[28]
]
// jun 23
export const pro1WithJun23Charges = [
    pro1F1Charge.jun23,
    pro1F2Charge.jun23,
    pro1F3Charge.jun23,
    pro1F1Charge.jun23 + pro1F2Charge.jun23 + pro1F3Charge.jun23
]
export const pro1WithJun23Pay = [
    pro1F1Payment.jun23,
    pro1F2Payment.jun23,
    pro1F3Payment.jun23,
    pro1F1Payment.jun23 + pro1F2Payment.jun23 + pro1F3Payment.jun23
]
export const pro1WithJun23Count = [
    pro1F1Collectable.jun23,
    pro1F2Collectable.jun23,
    pro1F3Collectable.jun23,
    pro1F1Collectable.jun23 + pro1F2Collectable.jun23 + pro1F3Collectable.jun23
]
export const pro1WithJun23NonCount = [
    pro1F1NonCollectable.jun23,
    pro1F2NonCollectable.jun23,
    pro1F3NonCollectable.jun23,
    pro1F1NonCollectable.jun23 + pro1F2NonCollectable.jun23 + pro1F3NonCollectable.jun23
]
export const pro1WithJun23Claim = [
    pro1F1ClaimCount.jun23,
    pro1F2ClaimCount.jun23,
    pro1F3ClaimCount.jun23,
    pro1F1ClaimCount.jun23 + pro1F2ClaimCount.jun23 + pro1F3ClaimCount.jun23
]
export const pro1WithJun23PerVisit = [
    pro1F1PerVisit.jun23,
    pro1F2PerVisit.jun23,
    pro1F3PerVisit.jun23,
    provider1PerVisist[29]
]
// jul 23
export const pro1WithJul23Charges = [
    pro1F1Charge.jul23,
    pro1F2Charge.jul23,
    pro1F3Charge.jul23,
    pro1F1Charge.jul23 + pro1F2Charge.jul23 + pro1F3Charge.jul23
]
export const pro1WithJul23Pay = [
    pro1F1Payment.jul23,
    pro1F2Payment.jul23,
    pro1F3Payment.jul23,
    pro1F1Payment.jul23 + pro1F2Payment.jul23 + pro1F3Payment.jul23
]
export const pro1WithJul23Count = [
    pro1F1Collectable.jul23,
    pro1F2Collectable.jul23,
    pro1F3Collectable.jul23,
    pro1F1Collectable.jul23 + pro1F2Collectable.jul23 + pro1F3Collectable.jul23
]
export const pro1WithJul23NonCount = [
    pro1F1NonCollectable.jul23,
    pro1F2NonCollectable.jul23,
    pro1F3NonCollectable.jul23,
    pro1F1NonCollectable.jul23 + pro1F2NonCollectable.jul23 + pro1F3NonCollectable.jul23
]
export const pro1WithJul23Claim = [
    pro1F1ClaimCount.jul23,
    pro1F2ClaimCount.jul23,
    pro1F3ClaimCount.jul23,
    pro1F1ClaimCount.jul23 + pro1F2ClaimCount.jul23 + pro1F3ClaimCount.jul23
]
export const pro1WithJul23PerVisit = [
    pro1F1PerVisit.jul23,
    pro1F2PerVisit.jul23,
    pro1F3PerVisit.jul23,
    provider1PerVisist[30]
]
// aug 23
export const pro1WithAug23Charges = [
    pro1F1Charge.aug23,
    pro1F2Charge.aug23,
    pro1F3Charge.aug23,
    pro1F1Charge.aug23 + pro1F2Charge.aug23 + pro1F3Charge.aug23
]
export const pro1WithAug23Pay = [
    pro1F1Payment.aug23,
    pro1F2Payment.aug23,
    pro1F3Payment.aug23,
    pro1F1Payment.aug23 + pro1F2Payment.aug23 + pro1F3Payment.aug23
]
export const pro1WithAug23Count = [
    pro1F1Collectable.aug23,
    pro1F2Collectable.aug23,
    pro1F3Collectable.aug23,
    pro1F1Collectable.aug23 + pro1F2Collectable.aug23 + pro1F3Collectable.aug23
]
export const pro1WithAug23NonCount = [
    pro1F1NonCollectable.aug23,
    pro1F2NonCollectable.aug23,
    pro1F3NonCollectable.aug23,
    pro1F1NonCollectable.aug23 + pro1F2NonCollectable.aug23 + pro1F3NonCollectable.aug23
]
export const pro1WithAug23Claim = [
    pro1F1ClaimCount.aug23,
    pro1F2ClaimCount.aug23,
    pro1F3ClaimCount.aug23,
    pro1F1ClaimCount.aug23 + pro1F2ClaimCount.aug23 + pro1F3ClaimCount.aug23
]
export const pro1WithAug23PerVisit = [
    pro1F1PerVisit.aug23,
    pro1F2PerVisit.aug23,
    pro1F3PerVisit.aug23,
    provider1PerVisist[31]
]
// sep 23
export const pro1WithSep23Charges = [
    pro1F1Charge.sep23,
    pro1F2Charge.sep23,
    pro1F3Charge.sep23,
    pro1F1Charge.sep23 + pro1F2Charge.sep23 + pro1F3Charge.sep23
]
export const pro1WithSep23Pay = [
    pro1F1Payment.sep23,
    pro1F2Payment.sep23,
    pro1F3Payment.sep23,
    pro1F1Payment.sep23 + pro1F2Payment.sep23 + pro1F3Payment.sep23
]
export const pro1WithSep23Count = [
    pro1F1Collectable.sep23,
    pro1F2Collectable.sep23,
    pro1F3Collectable.sep23,
    pro1F1Collectable.sep23 + pro1F2Collectable.sep23 + pro1F3Collectable.sep23
]
export const pro1WithSep23NonCount = [
    pro1F1NonCollectable.sep23,
    pro1F2NonCollectable.sep23,
    pro1F3NonCollectable.sep23,
    pro1F1NonCollectable.sep23 + pro1F2NonCollectable.sep23 + pro1F3NonCollectable.sep23
]
export const pro1WithSep23Claim = [
    pro1F1ClaimCount.sep23,
    pro1F2ClaimCount.sep23,
    pro1F3ClaimCount.sep23,
    pro1F1ClaimCount.sep23 + pro1F2ClaimCount.sep23 + pro1F3ClaimCount.sep23
]
export const pro1WithSep23PerVisit = [
    pro1F1PerVisit.sep23,
    pro1F2PerVisit.sep23,
    pro1F3PerVisit.sep23,
    provider1PerVisist[32]
]
// oct 23
export const pro1WithOct23Charges = [
    pro1F1Charge.oct23,
    pro1F2Charge.oct23,
    pro1F3Charge.oct23,
    pro1F1Charge.oct23 + pro1F2Charge.oct23 + pro1F3Charge.oct23
]
export const pro1WithOct23Pay = [
    pro1F1Payment.oct23,
    pro1F2Payment.oct23,
    pro1F3Payment.oct23,
    pro1F1Payment.oct23 + pro1F2Payment.oct23 + pro1F3Payment.oct23
]
export const pro1WithOct23Count = [
    pro1F1Collectable.oct23,
    pro1F2Collectable.oct23,
    pro1F3Collectable.oct23,
    pro1F1Collectable.oct23 + pro1F2Collectable.oct23 + pro1F3Collectable.oct23
]
export const pro1WithOct23NonCount = [
    pro1F1NonCollectable.oct23,
    pro1F2NonCollectable.oct23,
    pro1F3NonCollectable.oct23,
    pro1F1NonCollectable.oct23 + pro1F2NonCollectable.oct23 + pro1F3NonCollectable.oct23
]
export const pro1WithOct23Claim = [
    pro1F1ClaimCount.oct23,
    pro1F2ClaimCount.oct23,
    pro1F3ClaimCount.oct23,
    pro1F1ClaimCount.oct23 + pro1F2ClaimCount.oct23 + pro1F3ClaimCount.oct23
]
export const pro1WithOct23PerVisit = [
    pro1F1PerVisit.oct23,
    pro1F2PerVisit.oct23,
    pro1F3PerVisit.oct23,
    provider1PerVisist[33]
]
// nov 23
export const pro1WithNov23Charges = [
    pro1F1Charge.nov23,
    pro1F2Charge.nov23,
    pro1F3Charge.nov23,
    pro1F1Charge.nov23 + pro1F2Charge.nov23 + pro1F3Charge.nov23
]
export const pro1WithNov23Pay = [
    pro1F1Payment.nov23,
    pro1F2Payment.nov23,
    pro1F3Payment.nov23,
    pro1F1Payment.nov23 +
    pro1F2Payment.nov23 + 
    pro1F3Payment.nov23
]
export const pro1WithNov23Count = [
    pro1F1Collectable.nov23,
    pro1F2Collectable.nov23,
    pro1F3Collectable.nov23,
    pro1F1Collectable.nov23 +
    pro1F2Collectable.nov23 +
    pro1F3Collectable.nov23
]
export const pro1WithNov23NonCount = [
    pro1F1NonCollectable.nov23,
    pro1F2NonCollectable.nov23,
    pro1F3NonCollectable.nov23,
    pro1F1NonCollectable.nov23+
    pro1F2NonCollectable.nov23+
    pro1F3NonCollectable.nov23
]
export const pro1WithNov23Claim = [
    pro1F1ClaimCount.nov23,
    pro1F2ClaimCount.nov23,
    pro1F3ClaimCount.nov23,
    pro1F1ClaimCount.nov23 + pro1F2ClaimCount.nov23 + pro1F3ClaimCount.nov23
]
export const pro1WithNov23PerVisit = [
    pro1F1PerVisit.nov23,
    pro1F2PerVisit.nov23,
    pro1F3PerVisit.nov23,
    provider1PerVisist[34]
]
// console.log("pro1WithNov23PerVisit :",pro1WithNov23PerVisit)





