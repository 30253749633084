
export const allF1Charge = {
    jan21 : 136431.58,
    feb21 : 112081.84,
    mar21 : 154218.49,
    apr21 : 145688.02,
    may21 : 126586.96,
    jun21 : 151612.74,
    jul21 : 141802.74,
    aug21 : 177877.81,
    sep21 : 155577.51,
    oct21 : 191955,
    nov21 : 171426.11,
    dec21 : 164447.46,
    jan22 : 152033.99,
    feb22 : 122702.10,
    mar22 : 167924.51,
    apr22 : 149912.15,
    may22 : 152862.41,
    jun22 : 90878.01,
    jul22 : 149258.29,
    aug22 : 178783.20,
    sep22 : 175919.40,
    oct22 : 195484.57,
    nov22 : 192313.21,
    dec22 : 161470.60,
    jan23 : 199596.97,   
    feb23 : 145532.54,   
    mar23 : 181041.95,   
    apr23 : 127603.22,   
    may23 : 178667.12,   
    jun23 : 183536.21,   
    jul23 : 161142.69,   
    aug23 : 174608.19,   
    sep23 : 190322.42,   
    oct23 : 244425.98,
    nov23 : 237880.54 
     
  
}
export const allF1Payment = {
    jan21 : 70255.04,   
    feb21 : 81329.93,   
    mar21 : 98201.81,   
    apr21 : 88645.23,   
    may21 : 75560.06,   
    jun21 : 88214.15,   
    jul21 : 88619.84,   
    aug21 : 95528.88,   
    sep21 : 90430.30,   
    oct21 : 108168.78,   
    nov21 : 116757.23,   
    dec21 : 97060.04,  
    jan22 : 87829.13,  
    feb22 : 85615.04,  
    mar22 : 105070.47,   
    apr22 : 100263.56,   
    may22 : 94974.98,
    jun22 : 59905.69,  
    jul22 : 94962.26, 
    aug22 : 118653.14,  
    sep22 : 104685.12,  
    oct22 : 115312.70,  
    nov22 : 117433.19,  
    dec22 : 111672.19,  
    jan23 : 110371.20,  
    feb23 : 89282.22, 
    mar23 : 112752.85,  
    apr23 : 81238.53,
    may23 : 94391.82,
    jun23 : 102788.60, 
    jul23 : 102985.34, 
    aug23 : 115940.44, 
    sep23 : 94191.19,
    oct23 : 126563.88, 
    nov23 : 145189.37  



}
export const allF1Collectable = {
    jan21 : 199.50,   
    feb21 : 377.79,   
    mar21 : 493.14,   
    apr21 : 126.24,   
    may21 : 66.22,   
    jun21 : 410.80,   
    jul21 : 244.08,   
    aug21 : 109.09,   
    sep21 : 179.44,   
    oct21 : 293.08,   
    nov21 : 386.89,   
    dec21 : 1630.33,   
    jan22 : 8889.07,  
    feb22 : 261.35,   
    mar22 : 315.93,   
    apr22 : 196.95,   
    may22 : 347.72,   
    jun22 : 428.48, 
    jul22 : 861.58,   
    aug22 : 16311.53,   
    sep22 : 19101.18,   
    oct22 : 15448.87,   
    nov22 : 4879.29,   
    dec22 : 224.66,   
    jan23 : 87.81,   
    feb23 : 1724.90,   
    mar23 : 278.90,   
    apr23 : 117.65,   
    may23 : 450.13,   
    jun23 : 749.86,   
    jul23 : 821.93,   
    aug23 : 592.27,   
    sep23 : 741.56,   
    oct23 : 695.36,   
    nov23 : 412.52   
   


}
export const allF1NonCollectable = {
    jan21 : 44239.75,   
    feb21 : 46616.54,   
    mar21 : 52888.02,   
    apr21 : 54557.10,   
    may21 : 43175.70,   
    jun21 : 49732.27,   
    jul21 : 47078.14, 
    aug21 : 52494.62,   
    sep21 : 50278.82,   
    oct21 : 62559.67,   
    nov21 : 71883.24,   
    dec21 : 54748.30,   
    jan22 : 49946.98,   
    feb22 : 47751.10,   
    mar22 : 59754.40,   
    apr22 : 51862.36,   
    may22 : 57944.56,   
    jun22 : 34832.43,   
    jul22 : 55548.89,   
    aug22 : 63961.21,   
    sep22 : 53965.24,   
    oct22 : 58392.81,   
    nov22 : 56499.57,   
    dec22 : 51397.92,   
    jan23 : 71101.19,   
    feb23 : 51029.59,   
    mar23 : 81478.67,   
    apr23 : 45639.95,   
    may23 : 61623.40, 
    jun23 : 66784.38,   
    jul23 : 67406.85,   
    aug23 : 71369.61,   
    sep23 : 62838.79,   
    oct23 : 84746.83,   
    nov23 : 104352.27   
   


}
export const allF1ClaimCount = {
    jan21 : 991,
    feb21 : 648,
    mar21 : 906,
    apr21 : 843,
    may21 : 758,
    jun21 : 938,
    jul21 : 782,
    aug21 : 859,
    sep21 : 729,
    oct21 : 952,
    nov21 : 843,
    dec21 : 827,
    jan22 : 803,
    feb22 : 599,
    mar22 : 812,
    apr22 : 706,
    may22 : 749,
    jun22 : 446,
    jul22 : 741,
    aug22 : 787,
    sep22 : 803,
    oct22 : 795,
    nov22 : 805,
    dec22 : 764,
    jan23 : 713,
    feb23 : 613,
    mar23 : 629,
    apr23 : 540,
    may23 : 895,
    jun23 : 922,
    jul23 : 831,
    aug23 : 929,
    sep23 : 790,
    oct23 : 984,
    nov23 : 918
    


}
export const allF1PerVisit = {
    jan21 : 70.87,   
    feb21 : 125.51,   
    mar21 : 108.43,  
    apr21 : 105.15,   
    may21 : 99.64,   
    jun21 : 94.08,   
    jul21 : 94.08,   
    aug21 : 111.21,   
    sep21 : 124.05,   
    oct21 : 113.66,   
    nov21 : 138.45,   
    dec21 : 117.36,   
    jan22 : 109.38,   
    feb22 : 142.93,   
    mar22 : 129.34,   
    apr22 : 142.02,   
    may22 : 126.75,   
    jun22 : 134.42,     
    jul22 : 134.42, 
    aug22 : 150.77,   
    sep22 : 130.37,   
    oct22 : 145.11,   
    nov22 : 145.82,   
    dec22 : 146.23,   
    jan23 : 154.80,   
    feb23 : 145.65,   
    mar23 : 179.26,   
    apr23 : 150.50,   
    may23 : 105.51,   
    jun23 : 111.48,   
    jul23 : 123.88,   
    aug23 : 124.80,   
    sep23 : 119.23,   
    oct23 : 128.62,   
    nov23 : 158.22   
   


}
// F2
export const allF2Charge = {
    jan21 : 136431.58,
    feb21 : 112081.84,
    mar21 : 154218.49,
    apr21 : 145688.02,
    may21 : 126586.96,
    jun21 : 151612.74,
    jul21 : 141802.74,
    aug21 : 177877.81,
    sep21 : 155577.51,
    oct21 : 191955,
    nov21 : 171426.11,
    dec21 : 164447.46,
    jan22 : 152033.99,
    feb22 : 122702.10,
    mar22 : 167924.51,
    apr22 : 149912.15,
    may22 : 152862.41,
    jun22 : 90878.01,
    jul22 : 149258.29,
    aug22 : 178783.20,
    sep22 : 175919.40,
    oct22 : 195484.57,
    nov22 : 192313.21,
    dec22 : 161470.60,
    jan23 : 199596.97,   
    feb23 : 145532.54,   
    mar23 : 181041.95,   
    apr23 : 127603.22,   
    may23 : 178667.12,   
    jun23 : 183536.21,   
    jul23 : 161142.69,   
    aug23 : 174608.19,   
    sep23 : 190322.42,   
    oct23 : 244425.98,
    nov23 : 237880.54 
}
export const allF2Payment = {
    jan21 : 70255.04,   
    feb21 : 81329.93,   
    mar21 : 98201.81,   
    apr21 : 88645.23,   
    may21 : 75560.06,   
    jun21 : 88214.15,   
    jul21 : 88619.84,   
    aug21 : 95528.88,   
    sep21 : 90430.30,   
    oct21 : 108168.78,   
    nov21 : 116757.23,   
    dec21 : 97060.04,  
    jan22 : 87829.13,  
    feb22 : 85615.04,  
    mar22 : 105070.47,   
    apr22 : 100263.56,   
    may22 : 94974.98,
    jun22 : 59905.69,  
    jul22 : 94962.26, 
    aug22 : 118653.14,  
    sep22 : 104685.12,  
    oct22 : 115312.70,  
    nov22 : 117433.19,  
    dec22 : 111672.19,  
    jan23 : 110371.20,  
    feb23 : 89282.22, 
    mar23 : 112752.85,  
    apr23 : 81238.53,
    may23 : 94391.82,
    jun23 : 102788.60, 
    jul23 : 102985.34, 
    aug23 : 115940.44, 
    sep23 : 94191.19,
    oct23 : 126563.88, 
    nov23 : 145189.37    
}
export const allF2Collectable = {
    jan21 : 199.50,   
    feb21 : 377.79,   
    mar21 : 493.14,   
    apr21 : 126.24,   
    may21 : 66.22,   
    jun21 : 410.80,   
    jul21 : 244.08,   
    aug21 : 109.09,   
    sep21 : 179.44,   
    oct21 : 293.08,   
    nov21 : 386.89,   
    dec21 : 1630.33,   
    jan22 : 8889.07,  
    feb22 : 261.35,   
    mar22 : 315.93,   
    apr22 : 196.95,   
    may22 : 347.72,   
    jun22 : 428.48, 
    jul22 : 861.58,   
    aug22 : 16311.53,   
    sep22 : 19101.18,   
    oct22 : 15448.87,   
    nov22 : 4879.29,   
    dec22 : 224.66,   
    jan23 : 87.81,   
    feb23 : 1724.90,   
    mar23 : 278.90,   
    apr23 : 117.65,   
    may23 : 450.13,   
    jun23 : 749.86,   
    jul23 : 821.93,   
    aug23 : 592.27,   
    sep23 : 741.56,   
    oct23 : 695.36,   
    nov23 : 412.52   
}
export const allF2NonCollectable = {
    jan21 : 44239.75,   
    feb21 : 46616.54,   
    mar21 : 52888.02,   
    apr21 : 54557.10,   
    may21 : 43175.70,   
    jun21 : 49732.27,   
    jul21 : 47078.14, 
    aug21 : 52494.62,   
    sep21 : 50278.82,   
    oct21 : 62559.67,   
    nov21 : 71883.24,   
    dec21 : 54748.30,   
    jan22 : 49946.98,   
    feb22 : 47751.10,   
    mar22 : 59754.40,   
    apr22 : 51862.36,   
    may22 : 57944.56,   
    jun22 : 34832.43,   
    jul22 : 55548.89,   
    aug22 : 63961.21,   
    sep22 : 53965.24,   
    oct22 : 58392.81,   
    nov22 : 56499.57,   
    dec22 : 51397.92,   
    jan23 : 71101.19,   
    feb23 : 51029.59,   
    mar23 : 81478.67,   
    apr23 : 45639.95,   
    may23 : 61623.40, 
    jun23 : 66784.38,   
    jul23 : 67406.85,   
    aug23 : 71369.61,   
    sep23 : 62838.79,   
    oct23 : 84746.83,   
    nov23 : 104352.27   
}
export const allF2ClaimCount = {
    jan21 : 991,
    feb21 : 648,
    mar21 : 906,
    apr21 : 843,
    may21 : 758,
    jun21 : 938,
    jul21 : 782,
    aug21 : 859,
    sep21 : 729,
    oct21 : 952,
    nov21 : 843,
    dec21 : 827,
    jan22 : 803,
    feb22 : 599,
    mar22 : 812,
    apr22 : 706,
    may22 : 749,
    jun22 : 446,
    jul22 : 741,
    aug22 : 787,
    sep22 : 803,
    oct22 : 795,
    nov22 : 805,
    dec22 : 764,
    jan23 : 713,
    feb23 : 613,
    mar23 : 629,
    apr23 : 540,
    may23 : 895,
    jun23 : 922,
    jul23 : 831,
    aug23 : 929,
    sep23 : 790,
    oct23 : 984,
    nov23 : 918
}
export const allF2PerVisit = {
    jan21 : 70.87,   
    feb21 : 125.51,   
    mar21 : 108.43,  
    apr21 : 105.15,   
    may21 : 99.64,   
    jun21 : 94.08,   
    jul21 : 94.08,   
    aug21 : 111.21,   
    sep21 : 124.05,   
    oct21 : 113.66,   
    nov21 : 138.45,   
    dec21 : 117.36,   
    jan22 : 109.38,   
    feb22 : 142.93,   
    mar22 : 129.34,   
    apr22 : 142.02,   
    may22 : 126.75,   
    jun22 : 134.42,     
    jul22 : 134.42, 
    aug22 : 150.77,   
    sep22 : 130.37,   
    oct22 : 145.11,   
    nov22 : 145.82,   
    dec22 : 146.23,   
    jan23 : 154.80,   
    feb23 : 145.65,   
    mar23 : 179.26,   
    apr23 : 150.50,   
    may23 : 105.51,   
    jun23 : 111.48,   
    jul23 : 123.88,   
    aug23 : 124.80,   
    sep23 : 119.23,   
    oct23 : 128.62,   
    nov23 : 158.22   
}
// F3 
export const allF3Charge = {
    jan21 : 136431.58,
    feb21 : 112081.84,
    mar21 : 154218.49,
    apr21 : 145688.02,
    may21 : 126586.96,
    jun21 : 151612.74,
    jul21 : 141802.74,
    aug21 : 177877.81,
    sep21 : 155577.51,
    oct21 : 191955,
    nov21 : 171426.11,
    dec21 : 164447.46,
    jan22 : 152033.99,
    feb22 : 122702.10,
    mar22 : 167924.51,
    apr22 : 149912.15,
    may22 : 152862.41,
    jun22 : 90878.01,
    jul22 : 149258.29,
    aug22 : 178783.20,
    sep22 : 175919.40,
    oct22 : 195484.57,
    nov22 : 192313.21,
    dec22 : 161470.60,
    jan23 : 199596.97,   
    feb23 : 145532.54,   
    mar23 : 181041.95,   
    apr23 : 127603.22,   
    may23 : 178667.12,   
    jun23 : 183536.21,   
    jul23 : 161142.69,   
    aug23 : 174608.19,   
    sep23 : 190322.42,   
    oct23 : 244425.98,
    nov23 : 237880.54 
}
export const allF3Payment = {
    jan21 : 70255.04,   
    feb21 : 81329.93,   
    mar21 : 98201.81,   
    apr21 : 88645.23,   
    may21 : 75560.06,   
    jun21 : 88214.15,   
    jul21 : 88619.84,   
    aug21 : 95528.88,   
    sep21 : 90430.30,   
    oct21 : 108168.78,   
    nov21 : 116757.23,   
    dec21 : 97060.04,  
    jan22 : 87829.13,  
    feb22 : 85615.04,  
    mar22 : 105070.47,   
    apr22 : 100263.56,   
    may22 : 94974.98,
    jun22 : 59905.69,  
    jul22 : 94962.26, 
    aug22 : 118653.14,  
    sep22 : 104685.12,  
    oct22 : 115312.70,  
    nov22 : 117433.19,  
    dec22 : 111672.19,  
    jan23 : 110371.20,  
    feb23 : 89282.22, 
    mar23 : 112752.85,  
    apr23 : 81238.53,
    may23 : 94391.82,
    jun23 : 102788.60, 
    jul23 : 102985.34, 
    aug23 : 115940.44, 
    sep23 : 94191.19,
    oct23 : 126563.88, 
    nov23 : 145189.37    
}
export const allF3Collectable = {
    jan21 : 199.50,   
    feb21 : 377.79,   
    mar21 : 493.14,   
    apr21 : 126.24,   
    may21 : 66.22,   
    jun21 : 410.80,   
    jul21 : 244.08,   
    aug21 : 109.09,   
    sep21 : 179.44,   
    oct21 : 293.08,   
    nov21 : 386.89,   
    dec21 : 1630.33,   
    jan22 : 8889.07,  
    feb22 : 261.35,   
    mar22 : 315.93,   
    apr22 : 196.95,   
    may22 : 347.72,   
    jun22 : 428.48, 
    jul22 : 861.58,   
    aug22 : 16311.53,   
    sep22 : 19101.18,   
    oct22 : 15448.87,   
    nov22 : 4879.29,   
    dec22 : 224.66,   
    jan23 : 87.81,   
    feb23 : 1724.90,   
    mar23 : 278.90,   
    apr23 : 117.65,   
    may23 : 450.13,   
    jun23 : 749.86,   
    jul23 : 821.93,   
    aug23 : 592.27,   
    sep23 : 741.56,   
    oct23 : 695.36,   
    nov23 : 412.52   
}
export const allF3NonCollectable = {
    jan21 : 44239.75,   
    feb21 : 46616.54,   
    mar21 : 52888.02,   
    apr21 : 54557.10,   
    may21 : 43175.70,   
    jun21 : 49732.27,   
    jul21 : 47078.14, 
    aug21 : 52494.62,   
    sep21 : 50278.82,   
    oct21 : 62559.67,   
    nov21 : 71883.24,   
    dec21 : 54748.30,   
    jan22 : 49946.98,   
    feb22 : 47751.10,   
    mar22 : 59754.40,   
    apr22 : 51862.36,   
    may22 : 57944.56,   
    jun22 : 34832.43,   
    jul22 : 55548.89,   
    aug22 : 63961.21,   
    sep22 : 53965.24,   
    oct22 : 58392.81,   
    nov22 : 56499.57,   
    dec22 : 51397.92,   
    jan23 : 71101.19,   
    feb23 : 51029.59,   
    mar23 : 81478.67,   
    apr23 : 45639.95,   
    may23 : 61623.40, 
    jun23 : 66784.38,   
    jul23 : 67406.85,   
    aug23 : 71369.61,   
    sep23 : 62838.79,   
    oct23 : 84746.83,   
    nov23 : 104352.27  
}
export const allF3ClaimCount = {
    jan21 : 991,
    feb21 : 648,
    mar21 : 906,
    apr21 : 843,
    may21 : 758,
    jun21 : 938,
    jul21 : 782,
    aug21 : 859,
    sep21 : 729,
    oct21 : 952,
    nov21 : 843,
    dec21 : 827,
    jan22 : 803,
    feb22 : 599,
    mar22 : 812,
    apr22 : 706,
    may22 : 749,
    jun22 : 446,
    jul22 : 741,
    aug22 : 787,
    sep22 : 803,
    oct22 : 795,
    nov22 : 805,
    dec22 : 764,
    jan23 : 713,
    feb23 : 613,
    mar23 : 629,
    apr23 : 540,
    may23 : 895,
    jun23 : 922,
    jul23 : 831,
    aug23 : 929,
    sep23 : 790,
    oct23 : 984,
    nov23 : 918
}
export const allF3PerVisit = {
    jan21 : 70.87,   
    feb21 : 125.51,   
    mar21 : 108.43,  
    apr21 : 105.15,   
    may21 : 99.64,   
    jun21 : 94.08,   
    jul21 : 94.08,   
    aug21 : 111.21,   
    sep21 : 124.05,   
    oct21 : 113.66,   
    nov21 : 138.45,   
    dec21 : 117.36,   
    jan22 : 109.38,   
    feb22 : 142.93,   
    mar22 : 129.34,   
    apr22 : 142.02,   
    may22 : 126.75,   
    jun22 : 134.42,     
    jul22 : 134.42, 
    aug22 : 150.77,   
    sep22 : 130.37,   
    oct22 : 145.11,   
    nov22 : 145.82,   
    dec22 : 146.23,   
    jan23 : 154.80,   
    feb23 : 145.65,   
    mar23 : 179.26,   
    apr23 : 150.50,   
    may23 : 105.51,   
    jun23 : 111.48,   
    jul23 : 123.88,   
    aug23 : 124.80,   
    sep23 : 119.23,   
    oct23 : 128.62,   
    nov23 : 158.22   
}
// pro1 F1
export const pro1F1Charge = {
    jan21 : 0,   
    feb21 : 0,   
    mar21 : 0,   
    apr21 : 0,   
    may21 : 0,   
    jun21 : 0,   
    jul21 : 0,   
    aug21 : 0,   
    sep21 : 0,   
    oct21 : 0,   
    nov21 : 0,   
    dec21 : 0,   
    jan22 : 0,   
    feb22 : 0,   
    mar22 : 0,   
    apr22 : 0,   
    may22 : 0,   
    jun22 : 0,   
    jul22 : 0,   
    aug22 : 0,   
    sep22 : 0,   
    oct22 : 0,   
    nov22 : 0,   
    dec22 : 0,   
    jan23 : 0,   
    feb23 : 0,   
    mar23 : 0,   
    apr23 : 0,   
    may23 : 0,   
    jun23 : 0,   
    jul23 : 0,   
    aug23 : 0,   
    sep23 : 0,   
    oct23 : 0,   
    nov23 : 0                                                                                                                         
   
}
export const pro1F1Payment = {
    jan21 : 0,     
    feb21 : 31.48, 
    mar21 : 0,     
    apr21 : 9.57, 
    may21 : 15.03, 
    jun21 : 4.34, 
    jul21 : 0,
    aug21 : 3.33,
    sep21 : 0,
    oct21 : 0,  
    nov21 : 3.33, 
    dec21 : 0, 
    jan22 : 2.73,
    feb22 : 0,
    mar22 : 0,
    apr22 : 0, 
    may22 : 19.50, 
    jun22 : 0,     
    jul22 : 1.67, 
    aug22 : 0,      
    sep22 : 0,
    oct22 : 0,
    nov22 : 0,     
    dec22 : 51.93,
    jan23 : 0,  
    feb23 : 1.67,  
    mar23 : 0,     
    apr23 : 0,     
    may23 : 24.44,  
    jun23 : 3.88,  
    jul23 : 0,     
    aug23 : 0,      
    sep23 : 0,     
    oct23 : 0,      
    nov23 : 0   
   
}
export const pro1F1Collectable = {
    jan21 : 0,   
    feb21 : 0,      
    mar21 : 1.62,  
    apr21 : 0,     
    may21 : 15.03,
    jun21 : 0,
    jul21 : 0,
    aug21 : 0,
    sep21 : 0, 
    oct21 : 0,      
    nov21 : 0,      
    dec21 : 0,      
    jan22 : 0, 
    feb22 : 0,
    mar22 : 0,
    apr22 : 0,     
    may22 : 0,      
    jun22 : 10,  
    jul22 : 0,     
    aug22 : 0,     
    sep22 : 63.33, 
    oct22 : 0,
    nov22 : 0, 
    dec22 : 0,     
    jan23 : 0,      
    feb23 : 0,      
    mar23 : 0,      
    apr23 : 0,
    may23 : 0,      
    jun23 : 5,  
    jul23 : 0,     
    aug23 : 0,     
    sep23 : 0,     
    oct23 : 0,     
    nov23 : 0     
 
}
export const pro1F1NonCollectable = {
    jan21 : 16,  
    feb21 : 31.48,  
    mar21 : 0,     
    apr21 : 92.62, 
    may21 : 0,     
    jun21 : 4.34,  
    jul21 : 0, 
    aug21 : 0,
    sep21 : 0,
    oct21 : 0,     
    nov21 : 1.67, 
    dec21 : 0, 
    jan22 : 2.73,
    feb22 : 0,
    mar22 : 0,
    apr22 : 0,  
    may22 : 19.50, 
    jun22 : 0,     
    jul22 : 1.67, 
    aug22 : 20.54,  
    sep22 : 0,
    oct22 : 0,
    nov22 : 0,     
    dec22 : 51.93,
    jan23 : 0, 
    feb23 : 1.67, 
    mar23 : 0,      
    apr23 : 0,     
    may23 : 24.44,  
    jun23 : 1.12, 
    jul23 : 0,      
    aug23 : 0,      
    sep23 : 0,     
    oct23 : 0,     
    nov23 : 0    
    
}
export const pro1F1ClaimCount = {
    jan21 : 0,
    feb21 : 0,
    mar21 : 0,
    apr21 : 0,
    may21 : 0,
    jun21 : 0,
    jul21 : 0,
    aug21 : 0,
    sep21 : 0,
    oct21 : 0,
    nov21 : 0,
    dec21 : 0,
    jan22 : 0,
    feb22 : 0,
    mar22 : 0,
    apr22 : 0,
    may22 : 0,
    jun22 : 0,
    jul22 : 0,
    aug22 : 0,
    sep22 : 0,
    oct22 : 0,
    nov22 : 0,
    dec22 : 0,
    jan23 : 0,
    feb23 : 0,
    mar23 : 0,
    apr23 : 0,
    may23 : 0,
    jun23 : 0,
    jul23 : 0,
    aug23 : 0,
    sep23 : 0,
    oct23 : 0,
    nov23 : 0
      
}
export const pro1F1PerVisit = {
    jan21 : 0,   
    feb21 : 0,   
    mar21 : 0,   
    apr21 : 0,   
    may21 : 0,   
    jun21 : 0,   
    jul21 : 0,   
    aug21 : 0,   
    sep21 : 0,   
    oct21 : 0,   
    nov21 : 0,   
    dec21 : 0,   
    jan22 : 0,   
    feb22 : 0,   
    mar22 : 0,   
    apr22 : 0,   
    may22 : 0,   
    jun22 : 0,   
    jul22 : 0,   
    aug22 : 0,   
    sep22 : 0,   
    oct22 : 0,   
    nov22 : 0,   
    dec22 : 0,   
    jan23 : 0,   
    feb23 : 0,   
    mar23 : 0,   
    apr23 : 0,   
    may23 : 0,   
    jun23 : 0,   
    jul23 : 0,   
    aug23 : 0,   
    sep23 : 0,   
    oct23 : 0,   
    nov23 : 0                                                                                                                           
       
}
// pro1 F2 
export const pro1F2Charge = {
    jan21 : 0,   
    feb21 : 0,   
    mar21 : 0,   
    apr21 : 0,   
    may21 : 0,   
    jun21 : 0,   
    jul21 : 0,   
    aug21 : 0,   
    sep21 : 0,   
    oct21 : 0,   
    nov21 : 0,   
    dec21 : 0,   
    jan22 : 0,   
    feb22 : 0,   
    mar22 : 0,   
    apr22 : 0,   
    may22 : 0,   
    jun22 : 0,   
    jul22 : 0,   
    aug22 : 0,   
    sep22 : 0,   
    oct22 : 0,   
    nov22 : 0,   
    dec22 : 0,   
    jan23 : 0,   
    feb23 : 0,   
    mar23 : 0,   
    apr23 : 0,   
    may23 : 0,   
    jun23 : 0,   
    jul23 : 0,   
    aug23 : 0,   
    sep23 : 0,   
    oct23 : 0,   
    nov23 : 0  
}
export const pro1F2Payment = {
    jan21 : 0,     
    feb21 : 31.48, 
    mar21 : 0,     
    apr21 : 9.57, 
    may21 : 15.03, 
    jun21 : 4.34, 
    jul21 : 0,
    aug21 : 3.33,
    sep21 : 0,
    oct21 : 0,  
    nov21 : 3.33, 
    dec21 : 0, 
    jan22 : 2.73,
    feb22 : 0,
    mar22 : 0,
    apr22 : 0, 
    may22 : 19.50, 
    jun22 : 0,     
    jul22 : 1.67, 
    aug22 : 0,      
    sep22 : 0,
    oct22 : 0,
    nov22 : 0,     
    dec22 : 51.93,
    jan23 : 0,  
    feb23 : 1.67,  
    mar23 : 0,     
    apr23 : 0,     
    may23 : 24.44,  
    jun23 : 3.88,  
    jul23 : 0,     
    aug23 : 0,      
    sep23 : 0,     
    oct23 : 0,      
    nov23 : 0    
}
export const pro1F2Collectable = {
    jan21 : 0,   
    feb21 : 0,      
    mar21 : 1.62,  
    apr21 : 0,     
    may21 : 15.03,
    jun21 : 0,
    jul21 : 0,
    aug21 : 0,
    sep21 : 0, 
    oct21 : 0,      
    nov21 : 0,      
    dec21 : 0,      
    jan22 : 0, 
    feb22 : 0,
    mar22 : 0,
    apr22 : 0,     
    may22 : 0,      
    jun22 : 10,  
    jul22 : 0,     
    aug22 : 0,     
    sep22 : 63.33, 
    oct22 : 0,
    nov22 : 0, 
    dec22 : 0,     
    jan23 : 0,      
    feb23 : 0,      
    mar23 : 0,      
    apr23 : 0,
    may23 : 0,      
    jun23 : 5,  
    jul23 : 0,     
    aug23 : 0,     
    sep23 : 0,     
    oct23 : 0,     
    nov23 : 0     
}
export const pro1F2NonCollectable = {
    jan21 : 16,  
    feb21 : 31.48,  
    mar21 : 0,     
    apr21 : 92.62, 
    may21 : 0,     
    jun21 : 4.34,  
    jul21 : 0, 
    aug21 : 0,
    sep21 : 0,
    oct21 : 0,     
    nov21 : 1.67, 
    dec21 : 0, 
    jan22 : 2.73,
    feb22 : 0,
    mar22 : 0,
    apr22 : 0,  
    may22 : 19.50, 
    jun22 : 0,     
    jul22 : 1.67, 
    aug22 : 20.54,  
    sep22 : 0,
    oct22 : 0,
    nov22 : 0,     
    dec22 : 51.93,
    jan23 : 0, 
    feb23 : 1.67, 
    mar23 : 0,      
    apr23 : 0,     
    may23 : 24.44,  
    jun23 : 1.12, 
    jul23 : 0,      
    aug23 : 0,      
    sep23 : 0,     
    oct23 : 0,     
    nov23 : 0        
}
export const pro1F2ClaimCount = {
    jan21 : 0,
    feb21 : 0,
    mar21 : 0,
    apr21 : 0,
    may21 : 0,
    jun21 : 0,
    jul21 : 0,
    aug21 : 0,
    sep21 : 0,
    oct21 : 0,
    nov21 : 0,
    dec21 : 0,
    jan22 : 0,
    feb22 : 0,
    mar22 : 0,
    apr22 : 0,
    may22 : 0,
    jun22 : 0,
    jul22 : 0,
    aug22 : 0,
    sep22 : 0,
    oct22 : 0,
    nov22 : 0,
    dec22 : 0,
    jan23 : 0,
    feb23 : 0,
    mar23 : 0,
    apr23 : 0,
    may23 : 0,
    jun23 : 0,
    jul23 : 0,
    aug23 : 0,
    sep23 : 0,
    oct23 : 0,
    nov23 : 0    
}
export const pro1F2PerVisit = {
    jan21 : 0,   
    feb21 : 0,   
    mar21 : 0,   
    apr21 : 0,   
    may21 : 0,   
    jun21 : 0,   
    jul21 : 0,   
    aug21 : 0,   
    sep21 : 0,   
    oct21 : 0,   
    nov21 : 0,   
    dec21 : 0,   
    jan22 : 0,   
    feb22 : 0,   
    mar22 : 0,   
    apr22 : 0,   
    may22 : 0,   
    jun22 : 0,   
    jul22 : 0,   
    aug22 : 0,   
    sep22 : 0,   
    oct22 : 0,   
    nov22 : 0,   
    dec22 : 0,   
    jan23 : 0,   
    feb23 : 0,   
    mar23 : 0,   
    apr23 : 0,   
    may23 : 0,   
    jun23 : 0,   
    jul23 : 0,   
    aug23 : 0,   
    sep23 : 0,   
    oct23 : 0,   
    nov23 : 0     
}
// pro1 F3
export const pro1F3Charge = {
    jan21 : 0,   
    feb21 : 0,   
    mar21 : 0,   
    apr21 : 0,   
    may21 : 0,   
    jun21 : 0,   
    jul21 : 0,   
    aug21 : 0,   
    sep21 : 0,   
    oct21 : 0,   
    nov21 : 0,   
    dec21 : 0,   
    jan22 : 0,   
    feb22 : 0,   
    mar22 : 0,   
    apr22 : 0,   
    may22 : 0,   
    jun22 : 0,   
    jul22 : 0,   
    aug22 : 0,   
    sep22 : 0,   
    oct22 : 0,   
    nov22 : 0,   
    dec22 : 0,   
    jan23 : 0,   
    feb23 : 0,   
    mar23 : 0,   
    apr23 : 0,   
    may23 : 0,   
    jun23 : 0,   
    jul23 : 0,   
    aug23 : 0,   
    sep23 : 0,   
    oct23 : 0,   
    nov23 : 0  
}
export const pro1F3Payment = {
    jan21 : 0,     
    feb21 : 31.48, 
    mar21 : 0,     
    apr21 : 9.57, 
    may21 : 15.03, 
    jun21 : 4.34, 
    jul21 : 0,
    aug21 : 3.33,
    sep21 : 0,
    oct21 : 0,  
    nov21 : 3.33, 
    dec21 : 0, 
    jan22 : 2.73,
    feb22 : 0,
    mar22 : 0,
    apr22 : 0, 
    may22 : 19.50, 
    jun22 : 0,     
    jul22 : 1.67, 
    aug22 : 0,      
    sep22 : 0,
    oct22 : 0,
    nov22 : 0,     
    dec22 : 51.93,
    jan23 : 0,  
    feb23 : 1.67,  
    mar23 : 0,     
    apr23 : 0,     
    may23 : 24.44,  
    jun23 : 3.88,  
    jul23 : 0,     
    aug23 : 0,      
    sep23 : 0,     
    oct23 : 0,      
    nov23 : 0 
}
export const pro1F3Collectable = {
    jan21 : 0,   
    feb21 : 0,      
    mar21 : 1.62,  
    apr21 : 0,     
    may21 : 15.03,
    jun21 : 0,
    jul21 : 0,
    aug21 : 0,
    sep21 : 0, 
    oct21 : 0,      
    nov21 : 0,      
    dec21 : 0,      
    jan22 : 0, 
    feb22 : 0,
    mar22 : 0,
    apr22 : 0,     
    may22 : 0,      
    jun22 : 10,  
    jul22 : 0,     
    aug22 : 0,     
    sep22 : 63.33, 
    oct22 : 0,
    nov22 : 0, 
    dec22 : 0,     
    jan23 : 0,      
    feb23 : 0,      
    mar23 : 0,      
    apr23 : 0,
    may23 : 0,      
    jun23 : 5,  
    jul23 : 0,     
    aug23 : 0,     
    sep23 : 0,     
    oct23 : 0,     
    nov23 : 0     
}
export const pro1F3NonCollectable = {
    jan21 : 16,  
    feb21 : 31.48,  
    mar21 : 0,     
    apr21 : 92.62, 
    may21 : 0,     
    jun21 : 4.34,  
    jul21 : 0, 
    aug21 : 0,
    sep21 : 0,
    oct21 : 0,     
    nov21 : 1.67, 
    dec21 : 0, 
    jan22 : 2.73,
    feb22 : 0,
    mar22 : 0,
    apr22 : 0,  
    may22 : 19.50, 
    jun22 : 0,     
    jul22 : 1.67, 
    aug22 : 20.54,  
    sep22 : 0,
    oct22 : 0,
    nov22 : 0,     
    dec22 : 51.93,
    jan23 : 0, 
    feb23 : 1.67, 
    mar23 : 0,      
    apr23 : 0,     
    may23 : 24.44,  
    jun23 : 1.12, 
    jul23 : 0,      
    aug23 : 0,      
    sep23 : 0,     
    oct23 : 0,     
    nov23 : 0      
}
export const pro1F3ClaimCount = {
    jan21 : 0,
    feb21 : 0,
    mar21 : 0,
    apr21 : 0,
    may21 : 0,
    jun21 : 0,
    jul21 : 0,
    aug21 : 0,
    sep21 : 0,
    oct21 : 0,
    nov21 : 0,
    dec21 : 0,
    jan22 : 0,
    feb22 : 0,
    mar22 : 0,
    apr22 : 0,
    may22 : 0,
    jun22 : 0,
    jul22 : 0,
    aug22 : 0,
    sep22 : 0,
    oct22 : 0,
    nov22 : 0,
    dec22 : 0,
    jan23 : 0,
    feb23 : 0,
    mar23 : 0,
    apr23 : 0,
    may23 : 0,
    jun23 : 0,
    jul23 : 0,
    aug23 : 0,
    sep23 : 0,
    oct23 : 0,
    nov23 : 0    
}
export const pro1F3PerVisit = {
    jan21 : 0,   
    feb21 : 0,   
    mar21 : 0,   
    apr21 : 0,   
    may21 : 0,   
    jun21 : 0,   
    jul21 : 0,   
    aug21 : 0,   
    sep21 : 0,   
    oct21 : 0,   
    nov21 : 0,   
    dec21 : 0,   
    jan22 : 0,   
    feb22 : 0,   
    mar22 : 0,   
    apr22 : 0,   
    may22 : 0,   
    jun22 : 0,   
    jul22 : 0,   
    aug22 : 0,   
    sep22 : 0,   
    oct22 : 0,   
    nov22 : 0,   
    dec22 : 0,   
    jan23 : 0,   
    feb23 : 0,   
    mar23 : 0,   
    apr23 : 0,   
    may23 : 0,   
    jun23 : 0,   
    jul23 : 0,   
    aug23 : 0,   
    sep23 : 0,   
    oct23 : 0,   
    nov23 : 0     
}
// pro2 F1
export const pro2F1Charge = {
    jan21 : 20281.27,  
    feb21 : 20151.48,  
    mar21 : 19007.33, 
    apr21 : 23953.42,  
    may21 : 16103.79,  
    jun21 : 26278.66, 
    jul21 : 22156.69, 
    aug21 : 24461.17, 
    sep21 : 16778.17,  
    oct21 : 36833.21, 
    nov21 : 34311.33,  
    dec21 : 24303.00, 
    jan22 : 26208.06,  
    feb22 : 19547.66,  
    mar22 : 29282.33,  
    apr22 : 18894.38,  
    may22 : 23961.00, 
    jun22 : 16828.80, 
    jul22 : 25401.16, 
    aug22 : 15403.08,  
    sep22 : 29584.32, 
    oct22 : 27985.37, 
    nov22 : 37526.99,  
    dec22 : 20912.67, 
    jan23 : 34828.00, 
    feb23 : 33541.74, 
    mar23 : 33168.35, 
    apr23 : 13903.00, 
    may23 : 30474.27, 
    jun23 : 27346.62,  
    jul23 : 25367.00, 
    aug23 : 28022.84, 
    sep23 : 19079.03,  
    oct23 : 42602.33,  
    nov23 : 39032.36 
   
}
export const pro2F1Payment = {
    jan21 : 11476.09, 
    feb21 : 12247.67,  
    mar21 : 12870.67, 
    apr21 : 14134.14, 
    may21 : 10978.97, 
    jun21 : 14660.78, 
    jul21 : 13015.59, 
    aug21 : 15982.75,  
    sep21 : 11122.39, 
    oct21 : 15766.93,  
    nov21 : 21837.79,  
    dec21 : 15809.06,  
    jan22 : 15871.87,  
    feb22 : 13550.05, 
    mar22 : 17729.12,  
    apr22 : 15343.50,  
    may22 : 11847.85,  
    jun22 : 10409.79,  
    jul22 : 15397.74, 
    aug22 : 13880.32, 
    sep22 : 14225.77,  
    oct22 : 18095.09,  
    nov22 : 18302.93, 
    dec22 : 15585.20,  
    jan23 : 18909.27,  
    feb23 : 17463.23, 
    mar23 : 18722.11, 
    apr23 : 13475.55,  
    may23 : 13122.52, 
    jun23 : 14898.61,  
    jul23 : 15840.93, 
    aug23 : 18038.11, 
    sep23 : 11751.92, 
    oct23 : 18881.10, 
    nov23 : 23421.70 
    
}
export const pro2F1Collectable = {
    jan21 : 62.45, 
    feb21 : 80.90,  
    mar21 : 32.36, 
    apr21 : 36.80, 
    may21 : 51.42, 
    jun21 : 82.53,  
    jul21 : 23.74, 
    aug21 : 0,    
    sep21 : 39.72,  
    oct21 : 49.92,  
    nov21 : 34.97, 
    dec21 : 65.72, 
    jan22 : 2568.61, 
    feb22 : 60.94, 
    mar22 : 43.33,
    apr22 : 78.83, 
    may22 : 74.56, 
    jun22 : 81.01, 
    jul22 : 71.82, 
    aug22 : 3716.74, 
    sep22 : 4360.32, 
    oct22 : 2458.86,  
    nov22 : 1171.96, 
    dec22 : 0.43, 
    jan23 : 33.15, 
    feb23 : 67.04,
    mar23 : 178.70, 
    apr23 : 93.38, 
    may23 : 59.90, 
    jun23 : 180.78, 
    jul23 : 85.64, 
    aug23 : 107.67, 
    sep23 : 42.21, 
    oct23 : 306.10, 
    nov23 : 149.28 
       
}
export const pro2F1NonCollectable = {
    jan21 : 7685.27, 
    feb21 : 7354.11,  
    mar21 : 7278.27,  
    apr21 : 8522.51, 
    may21 : 6092.24, 
    jun21 : 8261.06, 
    jul21 : 6736.73, 
    aug21 : 8348.06, 
    sep21 : 6444.82, 
    oct21 : 9578.01, 
    nov21 : 14140.05, 
    dec21 : 8765.11, 
    jan22 : 8888.29, 
    feb22 : 7819.41, 
    mar22 : 10325.87, 
    apr22 : 8253.88, 
    may22 : 6872.59, 
    jun22 : 6877.88, 
    jul22 : 10135.90, 
    aug22 : 7948.13, 
    sep22 : 8011.29, 
    oct22 : 9683.87, 
    nov22 : 9167.31,  
    dec22 : 7283.70, 
    jan23 : 13296.53, 
    feb23 : 13799.51,
    mar23 : 14431.75, 
    apr23 : 10201.65, 
    may23 : 9404.40, 
    jun23 : 10533.53, 
    jul23 : 10876.18, 
    aug23 : 11656.76, 
    sep23 : 8191.09, 
    oct23 : 12428.58, 
    nov23 : 16275.77 
       
}
export const pro2F1ClaimCount = {
    jan21 : 143,
    feb21 : 111,
    mar21 : 110,
    apr21 : 140,
    may21 : 90,
    jun21 : 157,
    jul21 : 132,
    aug21 : 139,
    sep21 : 86,
    oct21 : 157,
    nov21 : 150,
    dec21 : 111,
    jan22 : 131,
    feb22 : 94,
    mar22 : 138,
    apr22 : 91,
    may22 : 113,
    jun22 : 79,
    jul22 : 127,
    aug22 : 137,
    sep22 : 132,
    oct22 : 151,
    nov22 : 145,
    dec22 : 126,
    jan23 : 130,
    feb23 : 134,
    mar23 : 139,
    apr23 : 109,
    may23 : 154,
    jun23 : 145,
    jul23 : 146,
    aug23 : 165,
    sep23 : 96,
    oct23 : 158,
    nov23 : 164
        
}
export const pro2F1PerVisit = {
    jan21 : 80.25,  
    feb21 : 110.34, 
    mar21 : 116.65, 
    apr21 : 100.96, 
    may21 : 121.54, 
    jun21 : 93.38, 
    jul21 : 98.85, 
    aug21 : 115.26, 
    sep21 : 129.83, 
    oct21 : 100.64, 
    nov21 : 145.59,
    dec21 : 142.85, 
    jan22 : 121.16, 
    feb22 : 144.15, 
    mar22 : 128.16,  
    apr22 : 168.61, 
    may22 : 104.85, 
    jun22 : 131.77, 
    jul22 : 121.56, 
    aug22 : 101.07, 
    sep22 : 107.50, 
    oct22 : 120.10, 
    nov22 : 126.23, 
    dec22 : 123.69, 
    jan23 : 145.83, 
    feb23 : 130.32, 
    mar23 : 134.69, 
    apr23 : 123.25, 
    may23 : 85.21,  
    jun23 : 102.51, 
    jul23 : 108.50, 
    aug23 : 109.10, 
    sep23 : 121.99, 
    oct23 : 119.25, 
    nov23 : 142.53 
       
}
// pro2 F2
export const pro2F2Charge = {
    jan21 : 20281.27,  
    feb21 : 20151.48,  
    mar21 : 19007.33, 
    apr21 : 23953.42,  
    may21 : 16103.79,  
    jun21 : 26278.66, 
    jul21 : 22156.69, 
    aug21 : 24461.17, 
    sep21 : 16778.17,  
    oct21 : 36833.21, 
    nov21 : 34311.33,  
    dec21 : 24303.00, 
    jan22 : 26208.06,  
    feb22 : 19547.66,  
    mar22 : 29282.33,  
    apr22 : 18894.38,  
    may22 : 23961.00, 
    jun22 : 16828.80, 
    jul22 : 25401.16, 
    aug22 : 15403.08,  
    sep22 : 29584.32, 
    oct22 : 27985.37, 
    nov22 : 37526.99,  
    dec22 : 20912.67, 
    jan23 : 34828.00, 
    feb23 : 33541.74, 
    mar23 : 33168.35, 
    apr23 : 13903.00, 
    may23 : 30474.27, 
    jun23 : 27346.62,  
    jul23 : 25367.00, 
    aug23 : 28022.84, 
    sep23 : 19079.03,  
    oct23 : 42602.33,  
    nov23 : 39032.36 
}
export const pro2F2Payment = {
    jan21 : 11476.09, 
    feb21 : 12247.67,  
    mar21 : 12870.67, 
    apr21 : 14134.14, 
    may21 : 10978.97, 
    jun21 : 14660.78, 
    jul21 : 13015.59, 
    aug21 : 15982.75,  
    sep21 : 11122.39, 
    oct21 : 15766.93,  
    nov21 : 21837.79,  
    dec21 : 15809.06,  
    jan22 : 15871.87,  
    feb22 : 13550.05, 
    mar22 : 17729.12,  
    apr22 : 15343.50,  
    may22 : 11847.85,  
    jun22 : 10409.79,  
    jul22 : 15397.74, 
    aug22 : 13880.32, 
    sep22 : 14225.77,  
    oct22 : 18095.09,  
    nov22 : 18302.93, 
    dec22 : 15585.20,  
    jan23 : 18909.27,  
    feb23 : 17463.23, 
    mar23 : 18722.11, 
    apr23 : 13475.55,  
    may23 : 13122.52, 
    jun23 : 14898.61,  
    jul23 : 15840.93, 
    aug23 : 18038.11, 
    sep23 : 11751.92, 
    oct23 : 18881.10, 
    nov23 : 23421.70     
}
export const pro2F2Collectable = {
    jan21 : 62.45, 
    feb21 : 80.90,  
    mar21 : 32.36, 
    apr21 : 36.80, 
    may21 : 51.42, 
    jun21 : 82.53,  
    jul21 : 23.74, 
    aug21 : 0,    
    sep21 : 39.72,  
    oct21 : 49.92,  
    nov21 : 34.97, 
    dec21 : 65.72, 
    jan22 : 2568.61, 
    feb22 : 60.94, 
    mar22 : 43.33,
    apr22 : 78.83, 
    may22 : 74.56, 
    jun22 : 81.01, 
    jul22 : 71.82, 
    aug22 : 3716.74, 
    sep22 : 4360.32, 
    oct22 : 2458.86,  
    nov22 : 1171.96, 
    dec22 : 0.43, 
    jan23 : 33.15, 
    feb23 : 67.04,
    mar23 : 178.70, 
    apr23 : 93.38, 
    may23 : 59.90, 
    jun23 : 180.78, 
    jul23 : 85.64, 
    aug23 : 107.67, 
    sep23 : 42.21, 
    oct23 : 306.10, 
    nov23 : 149.28     
}
export const pro2F2NonCollectable = {
    jan21 : 7685.27, 
    feb21 : 7354.11,  
    mar21 : 7278.27,  
    apr21 : 8522.51, 
    may21 : 6092.24, 
    jun21 : 8261.06, 
    jul21 : 6736.73, 
    aug21 : 8348.06, 
    sep21 : 6444.82, 
    oct21 : 9578.01, 
    nov21 : 14140.05, 
    dec21 : 8765.11, 
    jan22 : 8888.29, 
    feb22 : 7819.41, 
    mar22 : 10325.87, 
    apr22 : 8253.88, 
    may22 : 6872.59, 
    jun22 : 6877.88, 
    jul22 : 10135.90, 
    aug22 : 7948.13, 
    sep22 : 8011.29, 
    oct22 : 9683.87, 
    nov22 : 9167.31,  
    dec22 : 7283.70, 
    jan23 : 13296.53, 
    feb23 : 13799.51,
    mar23 : 14431.75, 
    apr23 : 10201.65, 
    may23 : 9404.40, 
    jun23 : 10533.53, 
    jul23 : 10876.18, 
    aug23 : 11656.76, 
    sep23 : 8191.09, 
    oct23 : 12428.58, 
    nov23 : 16275.77     
}
export const pro2F2ClaimCount = {
    jan21 : 143,
    feb21 : 111,
    mar21 : 110,
    apr21 : 140,
    may21 : 90,
    jun21 : 157,
    jul21 : 132,
    aug21 : 139,
    sep21 : 86,
    oct21 : 157,
    nov21 : 150,
    dec21 : 111,
    jan22 : 131,
    feb22 : 94,
    mar22 : 138,
    apr22 : 91,
    may22 : 113,
    jun22 : 79,
    jul22 : 127,
    aug22 : 137,
    sep22 : 132,
    oct22 : 151,
    nov22 : 145,
    dec22 : 126,
    jan23 : 130,
    feb23 : 134,
    mar23 : 139,
    apr23 : 109,
    may23 : 154,
    jun23 : 145,
    jul23 : 146,
    aug23 : 165,
    sep23 : 96,
    oct23 : 158,
    nov23 : 164    
}
export const pro2F2PerVisit = {
    jan21 : 80.25,  
    feb21 : 110.34, 
    mar21 : 116.65, 
    apr21 : 100.96, 
    may21 : 121.54, 
    jun21 : 93.38, 
    jul21 : 98.85, 
    aug21 : 115.26, 
    sep21 : 129.83, 
    oct21 : 100.64, 
    nov21 : 145.59,
    dec21 : 142.85, 
    jan22 : 121.16, 
    feb22 : 144.15, 
    mar22 : 128.16,  
    apr22 : 168.61, 
    may22 : 104.85, 
    jun22 : 131.77, 
    jul22 : 121.56, 
    aug22 : 101.07, 
    sep22 : 107.50, 
    oct22 : 120.10, 
    nov22 : 126.23, 
    dec22 : 123.69, 
    jan23 : 145.83, 
    feb23 : 130.32, 
    mar23 : 134.69, 
    apr23 : 123.25, 
    may23 : 85.21,  
    jun23 : 102.51, 
    jul23 : 108.50, 
    aug23 : 109.10, 
    sep23 : 121.99, 
    oct23 : 119.25, 
    nov23 : 142.53     
}
// pro2 F3
export const pro2F3Charge = {
    jan21 : 20281.27,  
    feb21 : 20151.48,  
    mar21 : 19007.33, 
    apr21 : 23953.42,  
    may21 : 16103.79,  
    jun21 : 26278.66, 
    jul21 : 22156.69, 
    aug21 : 24461.17, 
    sep21 : 16778.17,  
    oct21 : 36833.21, 
    nov21 : 34311.33,  
    dec21 : 24303.00, 
    jan22 : 26208.06,  
    feb22 : 19547.66,  
    mar22 : 29282.33,  
    apr22 : 18894.38,  
    may22 : 23961.00, 
    jun22 : 16828.80, 
    jul22 : 25401.16, 
    aug22 : 15403.08,  
    sep22 : 29584.32, 
    oct22 : 27985.37, 
    nov22 : 37526.99,  
    dec22 : 20912.67, 
    jan23 : 34828.00, 
    feb23 : 33541.74, 
    mar23 : 33168.35, 
    apr23 : 13903.00, 
    may23 : 30474.27, 
    jun23 : 27346.62,  
    jul23 : 25367.00, 
    aug23 : 28022.84, 
    sep23 : 19079.03,  
    oct23 : 42602.33,  
    nov23 : 39032.36 
}
export const pro2F3Payment = {
    jan21 : 11476.09, 
    feb21 : 12247.67,  
    mar21 : 12870.67, 
    apr21 : 14134.14, 
    may21 : 10978.97, 
    jun21 : 14660.78, 
    jul21 : 13015.59, 
    aug21 : 15982.75,  
    sep21 : 11122.39, 
    oct21 : 15766.93,  
    nov21 : 21837.79,  
    dec21 : 15809.06,  
    jan22 : 15871.87,  
    feb22 : 13550.05, 
    mar22 : 17729.12,  
    apr22 : 15343.50,  
    may22 : 11847.85,  
    jun22 : 10409.79,  
    jul22 : 15397.74, 
    aug22 : 13880.32, 
    sep22 : 14225.77,  
    oct22 : 18095.09,  
    nov22 : 18302.93, 
    dec22 : 15585.20,  
    jan23 : 18909.27,  
    feb23 : 17463.23, 
    mar23 : 18722.11, 
    apr23 : 13475.55,  
    may23 : 13122.52, 
    jun23 : 14898.61,  
    jul23 : 15840.93, 
    aug23 : 18038.11, 
    sep23 : 11751.92, 
    oct23 : 18881.10, 
    nov23 : 23421.70     
}
export const pro2F3Collectable = {
    jan21 : 62.45, 
    feb21 : 80.90,  
    mar21 : 32.36, 
    apr21 : 36.80, 
    may21 : 51.42, 
    jun21 : 82.53,  
    jul21 : 23.74, 
    aug21 : 0,    
    sep21 : 39.72,  
    oct21 : 49.92,  
    nov21 : 34.97, 
    dec21 : 65.72, 
    jan22 : 2568.61, 
    feb22 : 60.94, 
    mar22 : 43.33,
    apr22 : 78.83, 
    may22 : 74.56, 
    jun22 : 81.01, 
    jul22 : 71.82, 
    aug22 : 3716.74, 
    sep22 : 4360.32, 
    oct22 : 2458.86,  
    nov22 : 1171.96, 
    dec22 : 0.43, 
    jan23 : 33.15, 
    feb23 : 67.04,
    mar23 : 178.70, 
    apr23 : 93.38, 
    may23 : 59.90, 
    jun23 : 180.78, 
    jul23 : 85.64, 
    aug23 : 107.67, 
    sep23 : 42.21, 
    oct23 : 306.10, 
    nov23 : 149.28     
}
export const pro2F3NonCollectable = {
    jan21 : 7685.27, 
    feb21 : 7354.11,  
    mar21 : 7278.27,  
    apr21 : 8522.51, 
    may21 : 6092.24, 
    jun21 : 8261.06, 
    jul21 : 6736.73, 
    aug21 : 8348.06, 
    sep21 : 6444.82, 
    oct21 : 9578.01, 
    nov21 : 14140.05, 
    dec21 : 8765.11, 
    jan22 : 8888.29, 
    feb22 : 7819.41, 
    mar22 : 10325.87, 
    apr22 : 8253.88, 
    may22 : 6872.59, 
    jun22 : 6877.88, 
    jul22 : 10135.90, 
    aug22 : 7948.13, 
    sep22 : 8011.29, 
    oct22 : 9683.87, 
    nov22 : 9167.31,  
    dec22 : 7283.70, 
    jan23 : 13296.53, 
    feb23 : 13799.51,
    mar23 : 14431.75, 
    apr23 : 10201.65, 
    may23 : 9404.40, 
    jun23 : 10533.53, 
    jul23 : 10876.18, 
    aug23 : 11656.76, 
    sep23 : 8191.09, 
    oct23 : 12428.58, 
    nov23 : 16275.77     
}
export const pro2F3ClaimCount = {
    jan21 : 143,
    feb21 : 111,
    mar21 : 110,
    apr21 : 140,
    may21 : 90,
    jun21 : 157,
    jul21 : 132,
    aug21 : 139,
    sep21 : 86,
    oct21 : 157,
    nov21 : 150,
    dec21 : 111,
    jan22 : 131,
    feb22 : 94,
    mar22 : 138,
    apr22 : 91,
    may22 : 113,
    jun22 : 79,
    jul22 : 127,
    aug22 : 137,
    sep22 : 132,
    oct22 : 151,
    nov22 : 145,
    dec22 : 126,
    jan23 : 130,
    feb23 : 134,
    mar23 : 139,
    apr23 : 109,
    may23 : 154,
    jun23 : 145,
    jul23 : 146,
    aug23 : 165,
    sep23 : 96,
    oct23 : 158,
    nov23 : 164      
}
export const pro2F3PerVisit = {
    jan21 : 80.25,  
    feb21 : 110.34, 
    mar21 : 116.65, 
    apr21 : 100.96, 
    may21 : 121.54, 
    jun21 : 93.38, 
    jul21 : 98.85, 
    aug21 : 115.26, 
    sep21 : 129.83, 
    oct21 : 100.64, 
    nov21 : 145.59,
    dec21 : 142.85, 
    jan22 : 121.16, 
    feb22 : 144.15, 
    mar22 : 128.16,  
    apr22 : 168.61, 
    may22 : 104.85, 
    jun22 : 131.77, 
    jul22 : 121.56, 
    aug22 : 101.07, 
    sep22 : 107.50, 
    oct22 : 120.10, 
    nov22 : 126.23, 
    dec22 : 123.69, 
    jan23 : 145.83, 
    feb23 : 130.32, 
    mar23 : 134.69, 
    apr23 : 123.25, 
    may23 : 85.21,  
    jun23 : 102.51, 
    jul23 : 108.50, 
    aug23 : 109.10, 
    sep23 : 121.99, 
    oct23 : 119.25, 
    nov23 : 142.53    
}
// pro3 F1
export const pro3F1Charge = {
    jan21 : 28504.15, 
    feb21 : 17935.04, 
    mar21 : 30846.78, 
    apr21 : 25335.12, 
    may21 : 20102.60, 
    jun21 : 28659.27, 
    jul21 : 26020.83,  
    aug21 : 31189.99,  
    sep21 : 24227.59, 
    oct21 : 30514.38, 
    nov21 : 32351.68, 
    dec21 : 33463.55, 
    jan22 : 28479.91, 
    feb22 : 24022.63, 
    mar22 : 27676.63,  
    apr22 : 27930.62,  
    may22 : 27078.05, 
    jun22 : 13033.69, 
    jul22 : 29478.11, 
    aug22 : 34070.27, 
    sep22 : 31629.71, 
    oct22 : 38785.63,  
    nov22 : 39738.62, 
    dec22 : 32246.34, 
    jan23 : 44097.83, 
    feb23 : 33770.79, 
    mar23 : 44845.77, 
    apr23 : 37857.59, 
    may23 : 31472.99, 
    jun23 : 34744.53, 
    jul23 : 22897.65,  
    aug23 : 33010.61,  
    sep23 : 41183.49, 
    oct23 : 40227.98,  
    nov23 : 50843.35 
   
}
export const pro3F1Payment = {
    jan21 : 13408.34, 
    feb21 : 16555.39, 
    mar21 : 17535.27, 
    apr21 : 16851.20, 
    may21 : 12956.47, 
    jun21 : 13604.85,  
    jul21 : 16511.15, 
    aug21 : 18463.18, 
    sep21 : 13745.75, 
    oct21 : 16353.12, 
    nov21 : 19311.36, 
    dec21 : 19420.32, 
    jan22 : 14845.97, 
    feb22 : 18671.00, 
    mar22 : 18017.52, 
    apr22 : 16691.44, 
    may22 : 17539.15, 
    jun22 : 10518.37, 
    jul22 : 16037.79, 
    aug22 : 23910.83, 
    sep22 : 16528.86, 
    oct22 : 22958.90,  
    nov22 : 24044.02, 
    dec22 : 23127.22, 
    jan23 : 22920.06, 
    feb23 : 19985.26, 
    mar23 : 24332.90, 
    apr23 : 21812.03, 
    may23 : 18316.14, 
    jun23 : 20634.74, 
    jul23 : 15553.18,  
    aug23 : 20592.95, 
    sep23 : 17791.90, 
    oct23 : 20628.08, 
    nov23 : 29553.99 
       
}
export const pro3F1Collectable = {
    jan21 : 0.29, 
    feb21 : 40.59, 
    mar21 : 154.88, 
    apr21 : 11.67, 
    may21 : 47.41, 
    jun21 : 25.81,  
    jul21 : 57.31, 
    aug21 : 74.67, 
    sep21 : 38.06, 
    oct21 : 36.87, 
    nov21 : 67.33,   
    dec21 : 95.11, 
    jan22 : 1442.53, 
    feb22 : 122.91, 
    mar22 : 35.99, 
    apr22 : 53.33,  
    may22 : 42.85, 
    jun22 : 179.36, 
    jul22 : 107.35 ,
    aug22 : 3314.11, 
    sep22 : 2569.47,  
    oct22 : 2468.02,
    nov22 : 975.19, 
    dec22 : 57.62, 
    jan23 : 48.32, 
    feb23 : 50.00, 
    mar23 : 30.08,  
    apr23 : 72.22, 
    may23 : 65.20, 
    jun23 : 184.33, 
    jul23 : 478.96, 
    aug23 : 162.55, 
    sep23 : 167.50, 
    oct23 : 141.70,  
    nov23 : 81.03 
       
}
export const pro3F1NonCollectable = {
    jan21 : 8697.09,  
    feb21 : 9259.63,  
    mar21 : 9513.58, 
    apr21 : 10642.74, 
    may21 : 7969.70, 
    jun21 : 8080.94,  
    jul21 : 9090.37,  
    aug21 : 10228.71, 
    sep21 : 7543.98, 
    oct21 : 10399.00, 
    nov21 : 12122.78, 
    dec21 : 12449.20, 
    jan22 : 8895.14, 
    feb22 : 10067.39, 
    mar22 : 10315.47, 
    apr22 : 8497.35, 
    may22 : 12199.71, 
    jun22 : 5847.13,  
    jul22 : 9380.70,  
    aug22 : 12575.10, 
    sep22 : 8549.81, 
    oct22 : 11590.86, 
    nov22 : 12403.19,  
    dec22 : 10787.13,  
    jan23 : 14650.54,  
    feb23 : 15902.69,  
    mar23 : 18411.41, 
    apr23 : 16329.40,  
    may23 : 13173.83, 
    jun23 : 14128.73,  
    jul23 : 10863.24,  
    aug23 : 13930.50, 
    sep23 : 11660.14,  
    oct23 : 14932.49, 
    nov23 : 21924.02 
       
}
export const pro3F1ClaimCount = {
    jan21 : 204,
    feb21 : 113,
    mar21 : 185,
    apr21 : 163,
    may21 : 127,
    jun21 : 178,
    jul21 : 147,
    aug21 : 157,
    sep21 : 118,
    oct21 : 158,
    nov21 : 150,
    dec21 : 171,
    jan22 : 144,
    feb22 : 113,
    mar22 : 135,
    apr22 : 127,
    may22 : 130,
    jun22 : 67,
    jul22 : 124,
    aug22 : 129,
    sep22 : 132,
    oct22 : 137,
    nov22 : 140,
    dec22 : 144,
    jan23 : 146,
    feb23 : 139,
    mar23 : 156,
    apr23 : 133,
    may23 : 174,
    jun23 : 170,
    jul23 : 143,
    aug23 : 184,
    sep23 : 154,
    oct23 : 166,
    nov23 : 199
        
}
export const pro3F1PerVisit = {
    jan21 : 65.62, 
    feb21 : 146.51, 
    mar21 : 94.79, 
    apr21 : 103.17, 
    may21 : 101.75, 
    jun21 : 76.29, 
    jul21 : 112.07, 
    aug21 : 117.60, 
    sep21 : 116.16,  
    oct21 : 103.50, 
    nov21 : 129.03, 
    dec21 : 113.79, 
    jan22 : 103.34, 
    feb22 : 164.74, 
    mar22 : 133.79,  
    apr22 : 131.08, 
    may22 : 134.57, 
    jun22 : 156.21, 
    jul22 : 129.34, 
    aug22 : 185.84, 
    sep22 : 124.90, 
    oct22 : 167.18, 
    nov22 : 171.74, 
    dec22 : 160.61, 
    jan23 : 156.63, 
    feb23 : 143.78, 
    mar23 : 156.31, 
    apr23 : 164.00, 
    may23 : 105.47,  
    jun23 : 121.14, 
    jul23 : 108.51, 
    aug23 : 111.72, 
    sep23 : 115.28, 
    oct23 : 124.02, 
    nov23 : 148.26 
      
}
// pro3 F2
export const pro3F2Charge = {
    jan21 : 28504.15, 
    feb21 : 17935.04, 
    mar21 : 30846.78, 
    apr21 : 25335.12, 
    may21 : 20102.60, 
    jun21 : 28659.27, 
    jul21 : 26020.83,  
    aug21 : 31189.99,  
    sep21 : 24227.59, 
    oct21 : 30514.38, 
    nov21 : 32351.68, 
    dec21 : 33463.55, 
    jan22 : 28479.91, 
    feb22 : 24022.63, 
    mar22 : 27676.63,  
    apr22 : 27930.62,  
    may22 : 27078.05, 
    jun22 : 13033.69, 
    jul22 : 29478.11, 
    aug22 : 34070.27, 
    sep22 : 31629.71, 
    oct22 : 38785.63,  
    nov22 : 39738.62, 
    dec22 : 32246.34, 
    jan23 : 44097.83, 
    feb23 : 33770.79, 
    mar23 : 44845.77, 
    apr23 : 37857.59, 
    may23 : 31472.99, 
    jun23 : 34744.53, 
    jul23 : 22897.65,  
    aug23 : 33010.61,  
    sep23 : 41183.49, 
    oct23 : 40227.98,  
    nov23 : 50843.35 
}
export const pro3F2Payment = {
    jan21 : 13408.34, 
    feb21 : 16555.39, 
    mar21 : 17535.27, 
    apr21 : 16851.20, 
    may21 : 12956.47, 
    jun21 : 13604.85,  
    jul21 : 16511.15, 
    aug21 : 18463.18, 
    sep21 : 13745.75, 
    oct21 : 16353.12, 
    nov21 : 19311.36, 
    dec21 : 19420.32, 
    jan22 : 14845.97, 
    feb22 : 18671.00, 
    mar22 : 18017.52, 
    apr22 : 16691.44, 
    may22 : 17539.15, 
    jun22 : 10518.37, 
    jul22 : 16037.79, 
    aug22 : 23910.83, 
    sep22 : 16528.86, 
    oct22 : 22958.90,  
    nov22 : 24044.02, 
    dec22 : 23127.22, 
    jan23 : 22920.06, 
    feb23 : 19985.26, 
    mar23 : 24332.90, 
    apr23 : 21812.03, 
    may23 : 18316.14, 
    jun23 : 20634.74, 
    jul23 : 15553.18,  
    aug23 : 20592.95, 
    sep23 : 17791.90, 
    oct23 : 20628.08, 
    nov23 : 29553.99     
}
export const pro3F2Collectable = {
    jan21 : 0.29, 
    feb21 : 40.59, 
    mar21 : 154.88, 
    apr21 : 11.67, 
    may21 : 47.41, 
    jun21 : 25.81,  
    jul21 : 57.31, 
    aug21 : 74.67, 
    sep21 : 38.06, 
    oct21 : 36.87, 
    nov21 : 67.33,   
    dec21 : 95.11, 
    jan22 : 1442.53, 
    feb22 : 122.91, 
    mar22 : 35.99, 
    apr22 : 53.33,  
    may22 : 42.85, 
    jun22 : 179.36, 
    jul22 : 107.35 ,
    aug22 : 3314.11, 
    sep22 : 2569.47,  
    oct22 : 2468.02,
    nov22 : 975.19, 
    dec22 : 57.62, 
    jan23 : 48.32, 
    feb23 : 50.00, 
    mar23 : 30.08,  
    apr23 : 72.22, 
    may23 : 65.20, 
    jun23 : 184.33, 
    jul23 : 478.96, 
    aug23 : 162.55, 
    sep23 : 167.50, 
    oct23 : 141.70,  
    nov23 : 81.03    
}
export const pro3F2NonCollectable = {
    jan21 : 8697.09,  
    feb21 : 9259.63,  
    mar21 : 9513.58, 
    apr21 : 10642.74, 
    may21 : 7969.70, 
    jun21 : 8080.94,  
    jul21 : 9090.37,  
    aug21 : 10228.71, 
    sep21 : 7543.98, 
    oct21 : 10399.00, 
    nov21 : 12122.78, 
    dec21 : 12449.20, 
    jan22 : 8895.14, 
    feb22 : 10067.39, 
    mar22 : 10315.47, 
    apr22 : 8497.35, 
    may22 : 12199.71, 
    jun22 : 5847.13,  
    jul22 : 9380.70,  
    aug22 : 12575.10, 
    sep22 : 8549.81, 
    oct22 : 11590.86, 
    nov22 : 12403.19,  
    dec22 : 10787.13,  
    jan23 : 14650.54,  
    feb23 : 15902.69,  
    mar23 : 18411.41, 
    apr23 : 16329.40,  
    may23 : 13173.83, 
    jun23 : 14128.73,  
    jul23 : 10863.24,  
    aug23 : 13930.50, 
    sep23 : 11660.14,  
    oct23 : 14932.49, 
    nov23 : 21924.02     
}
export const pro3F2ClaimCount = {
    jan21 : 204,
    feb21 : 113,
    mar21 : 185,
    apr21 : 163,
    may21 : 127,
    jun21 : 178,
    jul21 : 147,
    aug21 : 157,
    sep21 : 118,
    oct21 : 158,
    nov21 : 150,
    dec21 : 171,
    jan22 : 144,
    feb22 : 113,
    mar22 : 135,
    apr22 : 127,
    may22 : 130,
    jun22 : 67,
    jul22 : 124,
    aug22 : 129,
    sep22 : 132,
    oct22 : 137,
    nov22 : 140,
    dec22 : 144,
    jan23 : 146,
    feb23 : 139,
    mar23 : 156,
    apr23 : 133,
    may23 : 174,
    jun23 : 170,
    jul23 : 143,
    aug23 : 184,
    sep23 : 154,
    oct23 : 166,
    nov23 : 199   
}
export const pro3F2PerVisit = {
    jan21 : 65.62, 
    feb21 : 146.51, 
    mar21 : 94.79, 
    apr21 : 103.17, 
    may21 : 101.75, 
    jun21 : 76.29, 
    jul21 : 112.07, 
    aug21 : 117.60, 
    sep21 : 116.16,  
    oct21 : 103.50, 
    nov21 : 129.03, 
    dec21 : 113.79, 
    jan22 : 103.34, 
    feb22 : 164.74, 
    mar22 : 133.79,  
    apr22 : 131.08, 
    may22 : 134.57, 
    jun22 : 156.21, 
    jul22 : 129.34, 
    aug22 : 185.84, 
    sep22 : 124.90, 
    oct22 : 167.18, 
    nov22 : 171.74, 
    dec22 : 160.61, 
    jan23 : 156.63, 
    feb23 : 143.78, 
    mar23 : 156.31, 
    apr23 : 164.00, 
    may23 : 105.47,  
    jun23 : 121.14, 
    jul23 : 108.51, 
    aug23 : 111.72, 
    sep23 : 115.28, 
    oct23 : 124.02, 
    nov23 : 148.26     
}
// pro3 F3
export const pro3F3Charge = {
    jan21 : 28504.15, 
    feb21 : 17935.04, 
    mar21 : 30846.78, 
    apr21 : 25335.12, 
    may21 : 20102.60, 
    jun21 : 28659.27, 
    jul21 : 26020.83,  
    aug21 : 31189.99,  
    sep21 : 24227.59, 
    oct21 : 30514.38, 
    nov21 : 32351.68, 
    dec21 : 33463.55, 
    jan22 : 28479.91, 
    feb22 : 24022.63, 
    mar22 : 27676.63,  
    apr22 : 27930.62,  
    may22 : 27078.05, 
    jun22 : 13033.69, 
    jul22 : 29478.11, 
    aug22 : 34070.27, 
    sep22 : 31629.71, 
    oct22 : 38785.63,  
    nov22 : 39738.62, 
    dec22 : 32246.34, 
    jan23 : 44097.83, 
    feb23 : 33770.79, 
    mar23 : 44845.77, 
    apr23 : 37857.59, 
    may23 : 31472.99, 
    jun23 : 34744.53, 
    jul23 : 22897.65,  
    aug23 : 33010.61,  
    sep23 : 41183.49, 
    oct23 : 40227.98,  
    nov23 : 50843.35 
}
export const pro3F3Payment = {
    jan21 : 13408.34, 
    feb21 : 16555.39, 
    mar21 : 17535.27, 
    apr21 : 16851.20, 
    may21 : 12956.47, 
    jun21 : 13604.85,  
    jul21 : 16511.15, 
    aug21 : 18463.18, 
    sep21 : 13745.75, 
    oct21 : 16353.12, 
    nov21 : 19311.36, 
    dec21 : 19420.32, 
    jan22 : 14845.97, 
    feb22 : 18671.00, 
    mar22 : 18017.52, 
    apr22 : 16691.44, 
    may22 : 17539.15, 
    jun22 : 10518.37, 
    jul22 : 16037.79, 
    aug22 : 23910.83, 
    sep22 : 16528.86, 
    oct22 : 22958.90,  
    nov22 : 24044.02, 
    dec22 : 23127.22, 
    jan23 : 22920.06, 
    feb23 : 19985.26, 
    mar23 : 24332.90, 
    apr23 : 21812.03, 
    may23 : 18316.14, 
    jun23 : 20634.74, 
    jul23 : 15553.18,  
    aug23 : 20592.95, 
    sep23 : 17791.90, 
    oct23 : 20628.08, 
    nov23 : 29553.99       
}
export const pro3F3Collectable = {
    jan21 : 0.29, 
    feb21 : 40.59, 
    mar21 : 154.88, 
    apr21 : 11.67, 
    may21 : 47.41, 
    jun21 : 25.81,  
    jul21 : 57.31, 
    aug21 : 74.67, 
    sep21 : 38.06, 
    oct21 : 36.87, 
    nov21 : 67.33,   
    dec21 : 95.11, 
    jan22 : 1442.53, 
    feb22 : 122.91, 
    mar22 : 35.99, 
    apr22 : 53.33,  
    may22 : 42.85, 
    jun22 : 179.36, 
    jul22 : 107.35 ,
    aug22 : 3314.11, 
    sep22 : 2569.47,  
    oct22 : 2468.02,
    nov22 : 975.19, 
    dec22 : 57.62, 
    jan23 : 48.32, 
    feb23 : 50.00, 
    mar23 : 30.08,  
    apr23 : 72.22, 
    may23 : 65.20, 
    jun23 : 184.33, 
    jul23 : 478.96, 
    aug23 : 162.55, 
    sep23 : 167.50, 
    oct23 : 141.70,  
    nov23 : 81.03        
}
export const pro3F3NonCollectable = {
    jan21 : 8697.09,  
    feb21 : 9259.63,  
    mar21 : 9513.58, 
    apr21 : 10642.74, 
    may21 : 7969.70, 
    jun21 : 8080.94,  
    jul21 : 9090.37,  
    aug21 : 10228.71, 
    sep21 : 7543.98, 
    oct21 : 10399.00, 
    nov21 : 12122.78, 
    dec21 : 12449.20, 
    jan22 : 8895.14, 
    feb22 : 10067.39, 
    mar22 : 10315.47, 
    apr22 : 8497.35, 
    may22 : 12199.71, 
    jun22 : 5847.13,  
    jul22 : 9380.70,  
    aug22 : 12575.10, 
    sep22 : 8549.81, 
    oct22 : 11590.86, 
    nov22 : 12403.19,  
    dec22 : 10787.13,  
    jan23 : 14650.54,  
    feb23 : 15902.69,  
    mar23 : 18411.41, 
    apr23 : 16329.40,  
    may23 : 13173.83, 
    jun23 : 14128.73,  
    jul23 : 10863.24,  
    aug23 : 13930.50, 
    sep23 : 11660.14,  
    oct23 : 14932.49, 
    nov23 : 21924.02       
}
export const pro3F3ClaimCount = {
    jan21 : 204,
    feb21 : 113,
    mar21 : 185,
    apr21 : 163,
    may21 : 127,
    jun21 : 178,
    jul21 : 147,
    aug21 : 157,
    sep21 : 118,
    oct21 : 158,
    nov21 : 150,
    dec21 : 171,
    jan22 : 144,
    feb22 : 113,
    mar22 : 135,
    apr22 : 127,
    may22 : 130,
    jun22 : 67,
    jul22 : 124,
    aug22 : 129,
    sep22 : 132,
    oct22 : 137,
    nov22 : 140,
    dec22 : 144,
    jan23 : 146,
    feb23 : 139,
    mar23 : 156,
    apr23 : 133,
    may23 : 174,
    jun23 : 170,
    jul23 : 143,
    aug23 : 184,
    sep23 : 154,
    oct23 : 166,
    nov23 : 199       
}
export const pro3F3PerVisit = {
    jan21 : 65.62, 
    feb21 : 146.51, 
    mar21 : 94.79, 
    apr21 : 103.17, 
    may21 : 101.75, 
    jun21 : 76.29, 
    jul21 : 112.07, 
    aug21 : 117.60, 
    sep21 : 116.16,  
    oct21 : 103.50, 
    nov21 : 129.03, 
    dec21 : 113.79, 
    jan22 : 103.34, 
    feb22 : 164.74, 
    mar22 : 133.79,  
    apr22 : 131.08, 
    may22 : 134.57, 
    jun22 : 156.21, 
    jul22 : 129.34, 
    aug22 : 185.84, 
    sep22 : 124.90, 
    oct22 : 167.18, 
    nov22 : 171.74, 
    dec22 : 160.61, 
    jan23 : 156.63, 
    feb23 : 143.78, 
    mar23 : 156.31, 
    apr23 : 164.00, 
    may23 : 105.47,  
    jun23 : 121.14, 
    jul23 : 108.51, 
    aug23 : 111.72, 
    sep23 : 115.28, 
    oct23 : 124.02, 
    nov23 : 148.26      
}
// pro4 F1
export const pro4F1Charge = {
    jan21 : 19143.69,  
    feb21 : 19582.85, 
    mar21 : 25216.74,  
    apr21 : 23456.09,  
    may21 : 22376.42,  
    jun21 : 20601.33, 
    jul21 : 25856.57,  
    aug21 : 22655.16,  
    sep21 : 29797.83,  
    oct21 : 32202.00, 
    nov21 : 30919.07,  
    dec21 : 23201.42, 
    jan22 : 23139.82,  
    feb22 : 23812.50,  
    mar22 : 26712.49,  
    apr22 : 25488.79,  
    may22 : 31883.16,  
    jun22 : 12234.90, 
    jul22 : 21771.00,  
    aug22 : 29083.65, 
    sep22 : 34069.66, 
    oct22 : 39738.00,  
    nov22 : 33045.50, 
    dec22 : 25999.67, 
    jan23 : 33042.89, 
    feb23 : 31689.54,  
    mar23 : 30104.48, 
    apr23 : 31495.49,  
    may23 : 31018.73,  
    jun23 : 24403.51, 
    jul23 : 29259.65, 
    aug23 : 25181.50, 
    sep23 : 36101.91, 
    oct23 : 43490.49, 
    nov23 : 42212.39  
   
}
export const pro4F1Payment = {
    jan21 : 9483.16, 
    feb21 : 12625.26, 
    mar21 : 16091.19, 
    apr21 : 14181.37,  
    may21 : 13424.87, 
    jun21 : 13286.97, 
    jul21 : 12463.84,  
    aug21 : 13559.92,  
    sep21 : 16237.45,  
    oct21 : 19985.86,  
    nov21 : 20739.99, 
    dec21 : 15737.25, 
    jan22 : 12310.29, 
    feb22 : 11534.47, 
    mar22 : 20318.15,  
    apr22 : 15005.18, 
    may22 : 16447.50, 
    jun22 : 11552.62,  
    jul22 : 15002.04, 
    aug22 : 18181.96,  
    sep22 : 19335.48,  
    oct22 : 20940.92, 
    nov22 : 24780.46, 
    dec22 : 20245.09,  
    jan23 : 17448.04, 
    feb23 : 17119.40, 
    mar23 : 18659.39,  
    apr23 : 16845.87, 
    may23 : 18751.92, 
    jun23 : 15954.23, 
    jul23 : 16442.26, 
    aug23 : 17508.75,  
    sep23 : 16504.95, 
    oct23 : 22077.96,  
    nov23 : 25054.40 
       
}
export const pro4F1Collectable = {
    jan21 : 0.34, 
    feb21 : 32.84,  
    mar21 : 130.58, 
    apr21 : 35.95, 
    may21 : 19.44, 
    jun21 : 9.23, 
    jul21 : 98.43,  
    aug21 : 0.68, 
    sep21 : 58.33, 
    oct21 : 70.99, 
    nov21 : 79.93, 
    dec21 : 37.18,
    jan22 : 939.22, 
    feb22 : 21.55, 
    mar22 : 86.68, 
    apr22 : 20.00, 
    may22 : 6.67, 
    jun22 : 40.00, 
    jul22 : 100.79, 
    aug22 : 1791.72, 
    sep22 : 3640.79, 
    oct22 : 3605.32, 
    nov22 : 1051.78, 
    dec22 : 102.18, 
    jan23 : 3.33,  
    feb23 : 0,   
    mar23 : 16.67, 
    apr23 : 96.00, 
    may23 : 85.88, 
    jun23 : 45.96, 
    jul23 : 98.82, 
    aug23 : 50.91, 
    sep23 : 362.03, 
    oct23 : 30.50, 
    nov23 : 52.94 
       
}
export const pro4F1NonCollectable = {
    jan21 : 6497.13,  
    feb21 : 7428.90, 
    mar21 : 8911.55, 
    apr21 : 8440.02, 
    may21 : 7962.06,  
    jun21 : 8244.13, 
    jul21 : 7010.76,  
    aug21 : 7408.79, 
    sep21 : 9603.04, 
    oct21 : 11364.88, 
    nov21 : 12803.44, 
    dec21 : 9205.40, 
    jan22 : 7341.80,  
    feb22 : 7300.69, 
    mar22 : 12158.36, 
    apr22 : 8082.88, 
    may22 : 10193.93, 
    jun22 : 6088.33, 
    jul22 : 8019.87, 
    aug22 : 9944.89, 
    sep22 : 10318.19, 
    oct22 : 10844.32, 
    nov22 : 11417.83, 
    dec22 : 8769.57, 
    jan23 : 11568.14, 
    feb23 : 12374.24, 
    mar23 : 13685.08, 
    apr23 : 11052.91, 
    may23 : 11940.81, 
    jun23 : 10811.11, 
    jul23 : 10678.24, 
    aug23 : 10546.93, 
    sep23 : 10686.21, 
    oct23 : 14694.06,  
    nov23 : 18822.54 
      
}
export const pro4F1ClaimCount = {
    jan21 : 148,
    feb21 : 118,
    mar21 : 160,
    apr21 : 137,
    may21 : 141,
    jun21 : 134,
    jul21 : 141,
    aug21 : 123,
    sep21 : 145,
    oct21 : 162,
    nov21 : 151,
    dec21 : 116,
    jan22 : 125,
    feb22 : 117,
    mar22 : 136,
    apr22 : 122,
    may22 : 151,
    jun22 : 71,
    jul22 : 125,
    aug22 : 141,
    sep22 : 151,
    oct22 : 136,
    nov22 : 164,
    dec22 : 146,
    jan23 : 129,
    feb23 : 123,
    mar23 : 121,
    apr23 : 119,
    may23 : 163,
    jun23 : 139,
    jul23 : 149,
    aug23 : 153,
    sep23 : 156,
    oct23 : 177,
    nov23 : 165
        
}
export const pro4F1PerVisit = {
    jan21 : 64.22, 
    feb21 : 106.69, 
    mar21 : 100.78, 
    apr21 : 103.26, 
    may21 : 95.44, 
    jun21 : 99.16, 
    jul21 : 88.61, 
    aug21 : 109.95, 
    sep21 : 111.73, 
    oct21 : 123.62, 
    nov21 : 137.65, 
    dec21 : 136.06, 
    jan22 : 98.22, 
    feb22 : 98.31, 
    mar22 : 149.03, 
    apr22 : 123.33,  
    may22 : 108.92, 
    jun22 : 163.48, 
    jul22 : 119.70, 
    aug22 : 128.95, 
    sep22 : 128.05, 
    oct22 : 154.36, 
    nov22 : 151.10, 
    dec22 : 138.67, 
    jan23 : 135.26, 
    feb23 : 139.56, 
    mar23 : 154.64, 
    apr23 : 141.17, 
    may23 : 115.28, 
    jun23 : 115.05, 
    jul23 : 110.10, 
    aug23 : 114.69, 
    sep23 : 105.58, 
    oct23 : 124.50, 
    nov23 : 152.15 
       
}
// pro4 F2
export const pro4F2Charge = {
    jan21 : 19143.69,  
    feb21 : 19582.85, 
    mar21 : 25216.74,  
    apr21 : 23456.09,  
    may21 : 22376.42,  
    jun21 : 20601.33, 
    jul21 : 25856.57,  
    aug21 : 22655.16,  
    sep21 : 29797.83,  
    oct21 : 32202.00, 
    nov21 : 30919.07,  
    dec21 : 23201.42, 
    jan22 : 23139.82,  
    feb22 : 23812.50,  
    mar22 : 26712.49,  
    apr22 : 25488.79,  
    may22 : 31883.16,  
    jun22 : 12234.90, 
    jul22 : 21771.00,  
    aug22 : 29083.65, 
    sep22 : 34069.66, 
    oct22 : 39738.00,  
    nov22 : 33045.50, 
    dec22 : 25999.67, 
    jan23 : 33042.89, 
    feb23 : 31689.54,  
    mar23 : 30104.48, 
    apr23 : 31495.49,  
    may23 : 31018.73,  
    jun23 : 24403.51, 
    jul23 : 29259.65, 
    aug23 : 25181.50, 
    sep23 : 36101.91, 
    oct23 : 43490.49, 
    nov23 : 42212.39 
}
export const pro4F2Payment = {
    jan21 : 9483.16, 
    feb21 : 12625.26, 
    mar21 : 16091.19, 
    apr21 : 14181.37,  
    may21 : 13424.87, 
    jun21 : 13286.97, 
    jul21 : 12463.84,  
    aug21 : 13559.92,  
    sep21 : 16237.45,  
    oct21 : 19985.86,  
    nov21 : 20739.99, 
    dec21 : 15737.25, 
    jan22 : 12310.29, 
    feb22 : 11534.47, 
    mar22 : 20318.15,  
    apr22 : 15005.18, 
    may22 : 16447.50, 
    jun22 : 11552.62,  
    jul22 : 15002.04, 
    aug22 : 18181.96,  
    sep22 : 19335.48,  
    oct22 : 20940.92, 
    nov22 : 24780.46, 
    dec22 : 20245.09,  
    jan23 : 17448.04, 
    feb23 : 17119.40, 
    mar23 : 18659.39,  
    apr23 : 16845.87, 
    may23 : 18751.92, 
    jun23 : 15954.23, 
    jul23 : 16442.26, 
    aug23 : 17508.75,  
    sep23 : 16504.95, 
    oct23 : 22077.96,  
    nov23 : 25054.40     
}
export const pro4F2Collectable = {
    jan21 : 0.34, 
    feb21 : 32.84,  
    mar21 : 130.58, 
    apr21 : 35.95, 
    may21 : 19.44, 
    jun21 : 9.23, 
    jul21 : 98.43,  
    aug21 : 0.68, 
    sep21 : 58.33, 
    oct21 : 70.99, 
    nov21 : 79.93, 
    dec21 : 37.18,
    jan22 : 939.22, 
    feb22 : 21.55, 
    mar22 : 86.68, 
    apr22 : 20.00, 
    may22 : 6.67, 
    jun22 : 40.00, 
    jul22 : 100.79, 
    aug22 : 1791.72, 
    sep22 : 3640.79, 
    oct22 : 3605.32, 
    nov22 : 1051.78, 
    dec22 : 102.18, 
    jan23 : 3.33,  
    feb23 : 0,   
    mar23 : 16.67, 
    apr23 : 96.00, 
    may23 : 85.88, 
    jun23 : 45.96, 
    jul23 : 98.82, 
    aug23 : 50.91, 
    sep23 : 362.03, 
    oct23 : 30.50, 
    nov23 : 52.94    
}
export const pro4F2NonCollectable = {
    jan21 : 6497.13,  
    feb21 : 7428.90, 
    mar21 : 8911.55, 
    apr21 : 8440.02, 
    may21 : 7962.06,  
    jun21 : 8244.13, 
    jul21 : 7010.76,  
    aug21 : 7408.79, 
    sep21 : 9603.04, 
    oct21 : 11364.88, 
    nov21 : 12803.44, 
    dec21 : 9205.40, 
    jan22 : 7341.80,  
    feb22 : 7300.69, 
    mar22 : 12158.36, 
    apr22 : 8082.88, 
    may22 : 10193.93, 
    jun22 : 6088.33, 
    jul22 : 8019.87, 
    aug22 : 9944.89, 
    sep22 : 10318.19, 
    oct22 : 10844.32, 
    nov22 : 11417.83, 
    dec22 : 8769.57, 
    jan23 : 11568.14, 
    feb23 : 12374.24, 
    mar23 : 13685.08, 
    apr23 : 11052.91, 
    may23 : 11940.81, 
    jun23 : 10811.11, 
    jul23 : 10678.24, 
    aug23 : 10546.93, 
    sep23 : 10686.21, 
    oct23 : 14694.06,  
    nov23 : 18822.54     
}
export const pro4F2ClaimCount = {
    jan21 : 148,
    feb21 : 118,
    mar21 : 160,
    apr21 : 137,
    may21 : 141,
    jun21 : 134,
    jul21 : 141,
    aug21 : 123,
    sep21 : 145,
    oct21 : 162,
    nov21 : 151,
    dec21 : 116,
    jan22 : 125,
    feb22 : 117,
    mar22 : 136,
    apr22 : 122,
    may22 : 151,
    jun22 : 71,
    jul22 : 125,
    aug22 : 141,
    sep22 : 151,
    oct22 : 136,
    nov22 : 164,
    dec22 : 146,
    jan23 : 129,
    feb23 : 123,
    mar23 : 121,
    apr23 : 119,
    may23 : 163,
    jun23 : 139,
    jul23 : 149,
    aug23 : 153,
    sep23 : 156,
    oct23 : 177,
    nov23 : 165    
}
export const pro4F2PerVisit = {
    jan21 : 64.22, 
    feb21 : 106.69, 
    mar21 : 100.78, 
    apr21 : 103.26, 
    may21 : 95.44, 
    jun21 : 99.16, 
    jul21 : 88.61, 
    aug21 : 109.95, 
    sep21 : 111.73, 
    oct21 : 123.62, 
    nov21 : 137.65, 
    dec21 : 136.06, 
    jan22 : 98.22, 
    feb22 : 98.31, 
    mar22 : 149.03, 
    apr22 : 123.33,  
    may22 : 108.92, 
    jun22 : 163.48, 
    jul22 : 119.70, 
    aug22 : 128.95, 
    sep22 : 128.05, 
    oct22 : 154.36, 
    nov22 : 151.10, 
    dec22 : 138.67, 
    jan23 : 135.26, 
    feb23 : 139.56, 
    mar23 : 154.64, 
    apr23 : 141.17, 
    may23 : 115.28, 
    jun23 : 115.05, 
    jul23 : 110.10, 
    aug23 : 114.69, 
    sep23 : 105.58, 
    oct23 : 124.50, 
    nov23 : 152.15     
}
// pro4 F3
export const pro4F3Charge = {
    jan21 : 19143.69,  
    feb21 : 19582.85, 
    mar21 : 25216.74,  
    apr21 : 23456.09,  
    may21 : 22376.42,  
    jun21 : 20601.33, 
    jul21 : 25856.57,  
    aug21 : 22655.16,  
    sep21 : 29797.83,  
    oct21 : 32202.00, 
    nov21 : 30919.07,  
    dec21 : 23201.42, 
    jan22 : 23139.82,  
    feb22 : 23812.50,  
    mar22 : 26712.49,  
    apr22 : 25488.79,  
    may22 : 31883.16,  
    jun22 : 12234.90, 
    jul22 : 21771.00,  
    aug22 : 29083.65, 
    sep22 : 34069.66, 
    oct22 : 39738.00,  
    nov22 : 33045.50, 
    dec22 : 25999.67, 
    jan23 : 33042.89, 
    feb23 : 31689.54,  
    mar23 : 30104.48, 
    apr23 : 31495.49,  
    may23 : 31018.73,  
    jun23 : 24403.51, 
    jul23 : 29259.65, 
    aug23 : 25181.50, 
    sep23 : 36101.91, 
    oct23 : 43490.49, 
    nov23 : 42212.39 
}
export const pro4F3Payment = {
    jan21 : 9483.16, 
    feb21 : 12625.26, 
    mar21 : 16091.19, 
    apr21 : 14181.37,  
    may21 : 13424.87, 
    jun21 : 13286.97, 
    jul21 : 12463.84,  
    aug21 : 13559.92,  
    sep21 : 16237.45,  
    oct21 : 19985.86,  
    nov21 : 20739.99, 
    dec21 : 15737.25, 
    jan22 : 12310.29, 
    feb22 : 11534.47, 
    mar22 : 20318.15,  
    apr22 : 15005.18, 
    may22 : 16447.50, 
    jun22 : 11552.62,  
    jul22 : 15002.04, 
    aug22 : 18181.96,  
    sep22 : 19335.48,  
    oct22 : 20940.92, 
    nov22 : 24780.46, 
    dec22 : 20245.09,  
    jan23 : 17448.04, 
    feb23 : 17119.40, 
    mar23 : 18659.39,  
    apr23 : 16845.87, 
    may23 : 18751.92, 
    jun23 : 15954.23, 
    jul23 : 16442.26, 
    aug23 : 17508.75,  
    sep23 : 16504.95, 
    oct23 : 22077.96,  
    nov23 : 25054.40       
}
export const pro4F3Collectable = {
    jan21 : 0.34, 
    feb21 : 32.84,  
    mar21 : 130.58, 
    apr21 : 35.95, 
    may21 : 19.44, 
    jun21 : 9.23, 
    jul21 : 98.43,  
    aug21 : 0.68, 
    sep21 : 58.33, 
    oct21 : 70.99, 
    nov21 : 79.93, 
    dec21 : 37.18,
    jan22 : 939.22, 
    feb22 : 21.55, 
    mar22 : 86.68, 
    apr22 : 20.00, 
    may22 : 6.67, 
    jun22 : 40.00, 
    jul22 : 100.79, 
    aug22 : 1791.72, 
    sep22 : 3640.79, 
    oct22 : 3605.32, 
    nov22 : 1051.78, 
    dec22 : 102.18, 
    jan23 : 3.33,  
    feb23 : 0,   
    mar23 : 16.67, 
    apr23 : 96.00, 
    may23 : 85.88, 
    jun23 : 45.96, 
    jul23 : 98.82, 
    aug23 : 50.91, 
    sep23 : 362.03, 
    oct23 : 30.50, 
    nov23 : 52.94       
}
export const pro4F3NonCollectable = {
    jan21 : 6497.13,  
    feb21 : 7428.90, 
    mar21 : 8911.55, 
    apr21 : 8440.02, 
    may21 : 7962.06,  
    jun21 : 8244.13, 
    jul21 : 7010.76,  
    aug21 : 7408.79, 
    sep21 : 9603.04, 
    oct21 : 11364.88, 
    nov21 : 12803.44, 
    dec21 : 9205.40, 
    jan22 : 7341.80,  
    feb22 : 7300.69, 
    mar22 : 12158.36, 
    apr22 : 8082.88, 
    may22 : 10193.93, 
    jun22 : 6088.33, 
    jul22 : 8019.87, 
    aug22 : 9944.89, 
    sep22 : 10318.19, 
    oct22 : 10844.32, 
    nov22 : 11417.83, 
    dec22 : 8769.57, 
    jan23 : 11568.14, 
    feb23 : 12374.24, 
    mar23 : 13685.08, 
    apr23 : 11052.91, 
    may23 : 11940.81, 
    jun23 : 10811.11, 
    jul23 : 10678.24, 
    aug23 : 10546.93, 
    sep23 : 10686.21, 
    oct23 : 14694.06,  
    nov23 : 18822.54        
}
export const pro4F3ClaimCount = {
    jan21 : 148,
    feb21 : 118,
    mar21 : 160,
    apr21 : 137,
    may21 : 141,
    jun21 : 134,
    jul21 : 141,
    aug21 : 123,
    sep21 : 145,
    oct21 : 162,
    nov21 : 151,
    dec21 : 116,
    jan22 : 125,
    feb22 : 117,
    mar22 : 136,
    apr22 : 122,
    may22 : 151,
    jun22 : 71,
    jul22 : 125,
    aug22 : 141,
    sep22 : 151,
    oct22 : 136,
    nov22 : 164,
    dec22 : 146,
    jan23 : 129,
    feb23 : 123,
    mar23 : 121,
    apr23 : 119,
    may23 : 163,
    jun23 : 139,
    jul23 : 149,
    aug23 : 153,
    sep23 : 156,
    oct23 : 177,
    nov23 : 165     
}
export const pro4F3PerVisit = {
    jan21 : 64.22, 
    feb21 : 106.69, 
    mar21 : 100.78, 
    apr21 : 103.26, 
    may21 : 95.44, 
    jun21 : 99.16, 
    jul21 : 88.61, 
    aug21 : 109.95, 
    sep21 : 111.73, 
    oct21 : 123.62, 
    nov21 : 137.65, 
    dec21 : 136.06, 
    jan22 : 98.22, 
    feb22 : 98.31, 
    mar22 : 149.03, 
    apr22 : 123.33,  
    may22 : 108.92, 
    jun22 : 163.48, 
    jul22 : 119.70, 
    aug22 : 128.95, 
    sep22 : 128.05, 
    oct22 : 154.36, 
    nov22 : 151.10, 
    dec22 : 138.67, 
    jan23 : 135.26, 
    feb23 : 139.56, 
    mar23 : 154.64, 
    apr23 : 141.17, 
    may23 : 115.28, 
    jun23 : 115.05, 
    jul23 : 110.10, 
    aug23 : 114.69, 
    sep23 : 105.58, 
    oct23 : 124.50, 
    nov23 : 152.15       
}
// pro5 F1
export const pro5F1Charge = {
    jan21 : 18640.16,  
    feb21 : 12013.56, 
    mar21 : 20431.90, 
    apr21 : 22310.38, 
    may21 : 17600.15, 
    jun21 : 23582.48, 
    jul21 : 23733.65, 
    aug21 : 33536.88, 
    sep21 : 23835.65, 
    oct21 : 24913.63, 
    nov21 : 22043.31,  
    dec21 : 20790.66,  
    jan22 : 23707.87,  
    feb22 : 21220.13,  
    mar22 : 22330.16, 
    apr22 : 21249.16,  
    may22 : 22196.00,  
    jun22 : 15141.37, 
    jul22 : 24289.99, 
    aug22 : 32150.55, 
    sep22 : 23703.33, 
    oct22 : 25748.71, 
    nov22 : 24737.11,  
    dec22 : 26417.46,  
    jan23 : 30653.36,  
    feb23 : 3016.15, 
    mar23 : 830.83, 
    apr23 : 969.35,  
    may23 : 24254.33, 
    jun23 : 27793.23,  
    jul23 : 23089.67,  
    aug23 : 21549.90, 
    sep23 : 21260.02, 
    oct23 : 30371.16, 
    nov23 : 21939.19  
   
}
export const pro5F1Payment = {
    jan21 : 9272.49, 
    feb21 : 10403.17,  
    mar21 : 10356.72,  
    apr21 : 12170.86, 
    may21 : 10848.45, 
    jun21 : 13103.80, 
    jul21 : 12116.10, 
    aug21 : 17716.64,  
    sep21 : 14358.18, 
    oct21 : 15814.05, 
    nov21 : 14519.71,  
    dec21 : 13030.22, 
    jan22 : 11833.87, 
    feb22 : 13236.45, 
    mar22 : 14181.23, 
    apr22 : 14721.30,  
    may22 : 15254.43, 
    jun22 : 8388.32, 
    jul22 : 16544.47, 
    aug22 : 19559.04, 
    sep22 : 15520.91,  
    oct22 : 18472.25,  
    nov22 : 14051.42,  
    dec22 : 16593.42,  
    jan23 : 17307.82,  
    feb23 : 9595.66, 
    mar23 : 2724.51,  
    apr23 : 2374.97,  
    may23 : 6088.69, 
    jun23 : 15057.20, 
    jul23 : 13870.92, 
    aug23 : 14485.90, 
    sep23 : 11592.28, 
    oct23 : 17744.00, 
    nov23 : 14051.39 
       
}
export const pro5F1Collectable = {
    jan21 : 3.52, 
    feb21 : 3.51,  
    mar21 : 75.90, 
    apr21 : 15.98,
    may21 : 93.99,  
    jun21 : 123.90,  
    jul21 : 56.75, 
    aug21 : 0.75, 
    sep21 : 0,    
    oct21 : 6.12,  
    nov21 : 31.56, 
    dec21 : 1.01, 
    jan22 : 1939.69,  
    feb22 : 8.33, 
    mar22 : 36.87,  
    apr22 : 20.00,  
    may22 : 69.88, 
    jun22 : 37.80, 
    jul22 : 43.01, 
    aug22 : 2959.26, 
    sep22 : 3683.19,  
    oct22 : 3012.73, 
    nov22 : 600.33, 
    dec22 : 3.35, 
    jan23 : 17.56, 
    feb23 : 16.67, 
    mar23 : 0,    
    apr23 : 0,     
    may23 : 58.69, 
    jun23 : 78.14, 
    jul23 : 86.59, 
    aug23 : 68.45, 
    sep23 : 26.43, 
    oct23 : 50.51, 
    nov23 : 30.69 
       
}
export const pro5F1NonCollectable = {
    jan21 : 6042.68, 
    feb21 : 5875.19, 
    mar21 : 6364.55, 
    apr21 : 9148.24, 
    may21 : 5852.78, 
    jun21 : 7213.03, 
    jul21 : 8909.31, 
    aug21 : 9758.01, 
    sep21 : 7637.07, 
    oct21 : 10055.80,  
    nov21 : 9410.54, 
    dec21 : 5317.40, 
    jan22 : 7036.15,  
    feb22 : 7856.08, 
    mar22 : 8365.14, 
    apr22 : 8111.48, 
    may22 : 8873.95, 
    jun22 : 5022.69, 
    jul22 : 10340.68, 
    aug22 : 11298.89, 
    sep22 : 8477.90, 
    oct22 : 9366.15, 
    nov22 : 6699.44, 
    dec22 : 7921.60, 
    jan23 : 10786.41, 
    feb23 : 7041.47, 
    mar23 : 1745.98, 
    apr23 : 790.62, 
    may23 : 4517.42,  
    jun23 : 10072.38, 
    jul23 : 9356.70, 
    aug23 : 9487.01, 
    sep23 : 6419.76, 
    oct23 : 11251.07, 
    nov23 : 10233.53 

      
}
export const pro5F1ClaimCount = {
    jan21 : 155,
    feb21 : 76,
    mar21 : 134,
    apr21 : 132,
    may21 : 116,
    jun21 : 156,
    jul21 : 133,
    aug21 : 162,
    sep21 : 118,
    oct21 : 130,
    nov21 : 120,
    dec21 : 119,
    jan22 : 131,
    feb22 : 113,
    mar22 : 120,
    apr22 : 107,
    may22 : 116,
    jun22 : 76,
    jul22 : 120,
    aug22 : 123,
    sep22 : 132,
    oct22 : 123,
    nov22 : 114,
    dec22 : 125,
    jan23 : 93,
    feb23 : 31,
    mar23 : 0,
    apr23 : 0,
    may23 : 138,
    jun23 : 147,
    jul23 : 124,
    aug23 : 129,
    sep23 : 115,
    oct23 : 127,
    nov23 : 95
        
}
export const pro5F1PerVisit = {
    jan21 : 59.95, 
    feb21 : 137.49,  
    mar21 : 77.48, 
    apr21 : 91.97, 
    may21 : 93.25, 
    jun21 : 84.18, 
    jul21 : 90.87,
    aug21 : 109.14, 
    sep21 : 121.68, 
    oct21 : 121.34, 
    nov21 : 120.66, 
    dec21 : 109.81, 
    jan22 : 90.33, 
    feb22 : 117.14,  
    mar22 : 118.18, 
    apr22 : 137.58, 
    may22 : 131.88,  
    jun22 : 110.37, 
    jul22 : 137.87, 
    aug22 : 159.02, 
    sep22 : 117.29, 
    oct22 : 150.59, 
    nov22 : 123.26, 
    dec22 : 132.75, 
    jan23 : 186.78, 
    feb23 : 312.90, 
    mar23 : 0,     
    apr23 : 0,     
    may23 : 44.12, 
    jun23 : 102.66, 
    jul23 : 112.16, 
    aug23 : 112.29, 
    sep23 : 100.51, 
    oct23 : 140.08, 
    nov23 : 147.91 
      
}
// pro5 F2
export const pro5F2Charge = {
    jan21 : 18640.16,  
    feb21 : 12013.56, 
    mar21 : 20431.90, 
    apr21 : 22310.38, 
    may21 : 17600.15, 
    jun21 : 23582.48, 
    jul21 : 23733.65, 
    aug21 : 33536.88, 
    sep21 : 23835.65, 
    oct21 : 24913.63, 
    nov21 : 22043.31,  
    dec21 : 20790.66,  
    jan22 : 23707.87,  
    feb22 : 21220.13,  
    mar22 : 22330.16, 
    apr22 : 21249.16,  
    may22 : 22196.00,  
    jun22 : 15141.37, 
    jul22 : 24289.99, 
    aug22 : 32150.55, 
    sep22 : 23703.33, 
    oct22 : 25748.71, 
    nov22 : 24737.11,  
    dec22 : 26417.46,  
    jan23 : 30653.36,  
    feb23 : 3016.15, 
    mar23 : 830.83, 
    apr23 : 969.35,  
    may23 : 24254.33, 
    jun23 : 27793.23,  
    jul23 : 23089.67,  
    aug23 : 21549.90, 
    sep23 : 21260.02, 
    oct23 : 30371.16, 
    nov23 : 21939.19  
}
export const pro5F2Payment = {
    jan21 : 9272.49, 
    feb21 : 10403.17,  
    mar21 : 10356.72,  
    apr21 : 12170.86, 
    may21 : 10848.45, 
    jun21 : 13103.80, 
    jul21 : 12116.10, 
    aug21 : 17716.64,  
    sep21 : 14358.18, 
    oct21 : 15814.05, 
    nov21 : 14519.71,  
    dec21 : 13030.22, 
    jan22 : 11833.87, 
    feb22 : 13236.45, 
    mar22 : 14181.23, 
    apr22 : 14721.30,  
    may22 : 15254.43, 
    jun22 : 8388.32, 
    jul22 : 16544.47, 
    aug22 : 19559.04, 
    sep22 : 15520.91,  
    oct22 : 18472.25,  
    nov22 : 14051.42,  
    dec22 : 16593.42,  
    jan23 : 17307.82,  
    feb23 : 9595.66, 
    mar23 : 2724.51,  
    apr23 : 2374.97,  
    may23 : 6088.69, 
    jun23 : 15057.20, 
    jul23 : 13870.92, 
    aug23 : 14485.90, 
    sep23 : 11592.28, 
    oct23 : 17744.00, 
    nov23 : 14051.39     
}
export const pro5F2Collectable = {
    jan21 : 3.52, 
    feb21 : 3.51,  
    mar21 : 75.90, 
    apr21 : 15.98,
    may21 : 93.99,  
    jun21 : 123.90,  
    jul21 : 56.75, 
    aug21 : 0.75, 
    sep21 : 0,    
    oct21 : 6.12,  
    nov21 : 31.56, 
    dec21 : 1.01, 
    jan22 : 1939.69,  
    feb22 : 8.33, 
    mar22 : 36.87,  
    apr22 : 20.00,  
    may22 : 69.88, 
    jun22 : 37.80, 
    jul22 : 43.01, 
    aug22 : 2959.26, 
    sep22 : 3683.19,  
    oct22 : 3012.73, 
    nov22 : 600.33, 
    dec22 : 3.35, 
    jan23 : 17.56, 
    feb23 : 16.67, 
    mar23 : 0,    
    apr23 : 0,     
    may23 : 58.69, 
    jun23 : 78.14, 
    jul23 : 86.59, 
    aug23 : 68.45, 
    sep23 : 26.43, 
    oct23 : 50.51, 
    nov23 : 30.69     
}
export const pro5F2NonCollectable = {
    jan21 : 6042.68, 
    feb21 : 5875.19, 
    mar21 : 6364.55, 
    apr21 : 9148.24, 
    may21 : 5852.78, 
    jun21 : 7213.03, 
    jul21 : 8909.31, 
    aug21 : 9758.01, 
    sep21 : 7637.07, 
    oct21 : 10055.80,  
    nov21 : 9410.54, 
    dec21 : 5317.40, 
    jan22 : 7036.15,  
    feb22 : 7856.08, 
    mar22 : 8365.14, 
    apr22 : 8111.48, 
    may22 : 8873.95, 
    jun22 : 5022.69, 
    jul22 : 10340.68, 
    aug22 : 11298.89, 
    sep22 : 8477.90, 
    oct22 : 9366.15, 
    nov22 : 6699.44, 
    dec22 : 7921.60, 
    jan23 : 10786.41, 
    feb23 : 7041.47, 
    mar23 : 1745.98, 
    apr23 : 790.62, 
    may23 : 4517.42,  
    jun23 : 10072.38, 
    jul23 : 9356.70, 
    aug23 : 9487.01, 
    sep23 : 6419.76, 
    oct23 : 11251.07, 
    nov23 : 10233.53  
}
export const pro5F2ClaimCount = {
    jan21 : 155,
    feb21 : 76,
    mar21 : 134,
    apr21 : 132,
    may21 : 116,
    jun21 : 156,
    jul21 : 133,
    aug21 : 162,
    sep21 : 118,
    oct21 : 130,
    nov21 : 120,
    dec21 : 119,
    jan22 : 131,
    feb22 : 113,
    mar22 : 120,
    apr22 : 107,
    may22 : 116,
    jun22 : 76,
    jul22 : 120,
    aug22 : 123,
    sep22 : 132,
    oct22 : 123,
    nov22 : 114,
    dec22 : 125,
    jan23 : 93,
    feb23 : 31,
    mar23 : 0,
    apr23 : 0,
    may23 : 138,
    jun23 : 147,
    jul23 : 124,
    aug23 : 129,
    sep23 : 115,
    oct23 : 127,
    nov23 : 95    
}
export const pro5F2PerVisit = {
    jan21 : 59.95, 
    feb21 : 137.49,  
    mar21 : 77.48, 
    apr21 : 91.97, 
    may21 : 93.25, 
    jun21 : 84.18, 
    jul21 : 90.87,
    aug21 : 109.14, 
    sep21 : 121.68, 
    oct21 : 121.34, 
    nov21 : 120.66, 
    dec21 : 109.81, 
    jan22 : 90.33, 
    feb22 : 117.14,  
    mar22 : 118.18, 
    apr22 : 137.58, 
    may22 : 131.88,  
    jun22 : 110.37, 
    jul22 : 137.87, 
    aug22 : 159.02, 
    sep22 : 117.29, 
    oct22 : 150.59, 
    nov22 : 123.26, 
    dec22 : 132.75, 
    jan23 : 186.78, 
    feb23 : 312.90, 
    mar23 : 0,     
    apr23 : 0,     
    may23 : 44.12, 
    jun23 : 102.66, 
    jul23 : 112.16, 
    aug23 : 112.29, 
    sep23 : 100.51, 
    oct23 : 140.08, 
    nov23 : 147.91    
}
// pro5 F3
export const pro5F3Charge = {
    jan21 : 18640.16,  
    feb21 : 12013.56, 
    mar21 : 20431.90, 
    apr21 : 22310.38, 
    may21 : 17600.15, 
    jun21 : 23582.48, 
    jul21 : 23733.65, 
    aug21 : 33536.88, 
    sep21 : 23835.65, 
    oct21 : 24913.63, 
    nov21 : 22043.31,  
    dec21 : 20790.66,  
    jan22 : 23707.87,  
    feb22 : 21220.13,  
    mar22 : 22330.16, 
    apr22 : 21249.16,  
    may22 : 22196.00,  
    jun22 : 15141.37, 
    jul22 : 24289.99, 
    aug22 : 32150.55, 
    sep22 : 23703.33, 
    oct22 : 25748.71, 
    nov22 : 24737.11,  
    dec22 : 26417.46,  
    jan23 : 30653.36,  
    feb23 : 3016.15, 
    mar23 : 830.83, 
    apr23 : 969.35,  
    may23 : 24254.33, 
    jun23 : 27793.23,  
    jul23 : 23089.67,  
    aug23 : 21549.90, 
    sep23 : 21260.02, 
    oct23 : 30371.16, 
    nov23 : 21939.19  
}
export const pro5F3Payment = {
    jan21 : 9272.49, 
    feb21 : 10403.17,  
    mar21 : 10356.72,  
    apr21 : 12170.86, 
    may21 : 10848.45, 
    jun21 : 13103.80, 
    jul21 : 12116.10, 
    aug21 : 17716.64,  
    sep21 : 14358.18, 
    oct21 : 15814.05, 
    nov21 : 14519.71,  
    dec21 : 13030.22, 
    jan22 : 11833.87, 
    feb22 : 13236.45, 
    mar22 : 14181.23, 
    apr22 : 14721.30,  
    may22 : 15254.43, 
    jun22 : 8388.32, 
    jul22 : 16544.47, 
    aug22 : 19559.04, 
    sep22 : 15520.91,  
    oct22 : 18472.25,  
    nov22 : 14051.42,  
    dec22 : 16593.42,  
    jan23 : 17307.82,  
    feb23 : 9595.66, 
    mar23 : 2724.51,  
    apr23 : 2374.97,  
    may23 : 6088.69, 
    jun23 : 15057.20, 
    jul23 : 13870.92, 
    aug23 : 14485.90, 
    sep23 : 11592.28, 
    oct23 : 17744.00, 
    nov23 : 14051.39    
}
export const pro5F3Collectable = {
    jan21 : 3.52, 
    feb21 : 3.51,  
    mar21 : 75.90, 
    apr21 : 15.98,
    may21 : 93.99,  
    jun21 : 123.90,  
    jul21 : 56.75, 
    aug21 : 0.75, 
    sep21 : 0,    
    oct21 : 6.12,  
    nov21 : 31.56, 
    dec21 : 1.01, 
    jan22 : 1939.69,  
    feb22 : 8.33, 
    mar22 : 36.87,  
    apr22 : 20.00,  
    may22 : 69.88, 
    jun22 : 37.80, 
    jul22 : 43.01, 
    aug22 : 2959.26, 
    sep22 : 3683.19,  
    oct22 : 3012.73, 
    nov22 : 600.33, 
    dec22 : 3.35, 
    jan23 : 17.56, 
    feb23 : 16.67, 
    mar23 : 0,    
    apr23 : 0,     
    may23 : 58.69, 
    jun23 : 78.14, 
    jul23 : 86.59, 
    aug23 : 68.45, 
    sep23 : 26.43, 
    oct23 : 50.51, 
    nov23 : 30.69     
}
export const pro5F3NonCollectable = {
    jan21 : 6042.68, 
    feb21 : 5875.19, 
    mar21 : 6364.55, 
    apr21 : 9148.24, 
    may21 : 5852.78, 
    jun21 : 7213.03, 
    jul21 : 8909.31, 
    aug21 : 9758.01, 
    sep21 : 7637.07, 
    oct21 : 10055.80,  
    nov21 : 9410.54, 
    dec21 : 5317.40, 
    jan22 : 7036.15,  
    feb22 : 7856.08, 
    mar22 : 8365.14, 
    apr22 : 8111.48, 
    may22 : 8873.95, 
    jun22 : 5022.69, 
    jul22 : 10340.68, 
    aug22 : 11298.89, 
    sep22 : 8477.90, 
    oct22 : 9366.15, 
    nov22 : 6699.44, 
    dec22 : 7921.60, 
    jan23 : 10786.41, 
    feb23 : 7041.47, 
    mar23 : 1745.98, 
    apr23 : 790.62, 
    may23 : 4517.42,  
    jun23 : 10072.38, 
    jul23 : 9356.70, 
    aug23 : 9487.01, 
    sep23 : 6419.76, 
    oct23 : 11251.07, 
    nov23 : 10233.53     
}
export const pro5F3ClaimCount = {
    jan21 : 155,
    feb21 : 76,
    mar21 : 134,
    apr21 : 132,
    may21 : 116,
    jun21 : 156,
    jul21 : 133,
    aug21 : 162,
    sep21 : 118,
    oct21 : 130,
    nov21 : 120,
    dec21 : 119,
    jan22 : 131,
    feb22 : 113,
    mar22 : 120,
    apr22 : 107,
    may22 : 116,
    jun22 : 76,
    jul22 : 120,
    aug22 : 123,
    sep22 : 132,
    oct22 : 123,
    nov22 : 114,
    dec22 : 125,
    jan23 : 93,
    feb23 : 31,
    mar23 : 0,
    apr23 : 0,
    may23 : 138,
    jun23 : 147,
    jul23 : 124,
    aug23 : 129,
    sep23 : 115,
    oct23 : 127,
    nov23 : 95    
}
export const pro5F3PerVisit = {
    jan21 : 59.95, 
    feb21 : 137.49,  
    mar21 : 77.48, 
    apr21 : 91.97, 
    may21 : 93.25, 
    jun21 : 84.18, 
    jul21 : 90.87,
    aug21 : 109.14, 
    sep21 : 121.68, 
    oct21 : 121.34, 
    nov21 : 120.66, 
    dec21 : 109.81, 
    jan22 : 90.33, 
    feb22 : 117.14,  
    mar22 : 118.18, 
    apr22 : 137.58, 
    may22 : 131.88,  
    jun22 : 110.37, 
    jul22 : 137.87, 
    aug22 : 159.02, 
    sep22 : 117.29, 
    oct22 : 150.59, 
    nov22 : 123.26, 
    dec22 : 132.75, 
    jan23 : 186.78, 
    feb23 : 312.90, 
    mar23 : 0,     
    apr23 : 0,     
    may23 : 44.12, 
    jun23 : 102.66, 
    jul23 : 112.16, 
    aug23 : 112.29, 
    sep23 : 100.51, 
    oct23 : 140.08, 
    nov23 : 147.91     
}
// pro6 F1
export const pro6F1Charge = {
    jan21 : 26882.97,  
    feb21 : 21333.90,  
    mar21 : 28432.76, 
    apr21 : 22826.01, 
    may21 : 24328.67,  
    jun21 : 29828.33,  
    jul21 : 19385.33, 
    aug21 : 31724.27, 
    sep21 : 31721.94, 
    oct21 : 37570.79, 
    nov21 : 30393.05, 
    dec21 : 35587.67, 
    jan22 : 28363.67, 
    feb22 : 19506.52, 
    mar22 : 31830.41, 
    apr22 : 29715.86,  
    may22 : 27761.50, 
    jun22 : 17962.70,  
    jul22 : 24390.36,  
    aug22 : 38267.98, 
    sep22 : 31419.71, 
    oct22 : 34625.98, 
    nov22 : 30297.00, 
    dec22 : 30462.13, 
    jan23 : 29301.55, 
    feb23 : 34516.31, 
    mar23 : 39461.33, 
    apr23 : 33193.67, 
    may23 : 30930.90, 
    jun23 : 34368.52, 
    jul23 : 25782.00, 
    aug23 : 30638.80,  
    sep23 : 38407.63, 
    oct23 : 46191.01, 
    nov23 : 39435.33  
   
}
export const pro6F1Payment = {
    jan21 : 15387.52, 
    feb21 : 15770.33, 
    mar21 : 18549.45, 
    apr21 : 14076.62, 
    may21 : 11334.09, 
    jun21 : 15742.43, 
    jul21 : 18449.67, 
    aug21 : 12918.83, 
    sep21 : 16304.27, 
    oct21 : 20490.96, 
    nov21 : 21593.94, 
    dec21 : 19276.80, 
    jan22 : 16622.84, 
    feb22 : 14889.64, 
    mar22 : 17278.62, 
    apr22 : 20599.42, 
    may22 : 17655.75, 
    jun22 : 8204.65, 
    jul22 : 15167.44, 
    aug22 : 21546.88, 
    sep22 : 22149.58, 
    oct22 : 19258.96, 
    nov22 : 19716.76, 
    dec22 : 17802.94, 
    jan23 : 19022.17, 
    feb23 : 17857.60, 
    mar23 : 20200.53, 
    apr23 : 18814.61, 
    may23 : 19717.15, 
    jun23 : 16512.53, 
    jul23 : 17947.41, 
    aug23 : 19506.86, 
    sep23 : 16686.92, 
    oct23 : 25544.24, 
    nov23 : 26262.00 
      
}
export const pro6F1Collectable = {
    jan21 : 123.96, 
    feb21 : 219.59, 
    mar21 : 68.73, 
    apr21 : 10.85, 
    may21 : 40.07,  
    jun21 : 49.66, 
    jul21 : 2.47, 
    aug21 : 16.33, 
    sep21 : 43.33, 
    oct21 : 103.35, 
    nov21 : 104.65, 
    dec21 : 1428.92, 
    jan22 : 1391.58, 
    feb22 : 22.62, 
    mar22 : 59.50, 
    apr22 : 24.79, 
    may22 : 123.77, 
    jun22 : 22.66, 
    jul22 : 291.44, 
    aug22 : 2505.40, 
    sep22 : 1784.82, 
    oct22 : 1892.56, 
    nov22 : 750.54, 
    dec22 : 61.07, 
    jan23 : 6.44, 
    feb23 : 106.39, 
    mar23 : 36.79, 
    apr23 : 76.65, 
    may23 : 144.45, 
    jun23 : 195.41,  
    jul23 : 44.89, 
    aug23 : 134.76, 
    sep23 : 86.98, 
    oct23 : 68.89, 
    nov23 : 67.50 
       
}
export const pro6F1NonCollectable = {
    jan21 : 9587.81, 
    feb21 : 9160.81, 
    mar21 : 11046.32, 
    apr21 : 8946.86, 
    may21 : 8121.06, 
    jun21 : 9649.26, 
    jul21 : 7836.72, 
    aug21 : 9198.67,  
    sep21 : 9685.79,  
    oct21 : 11747.99, 
    nov21 : 13732.41, 
    dec21 : 12112.76, 
    jan22 : 10206.91, 
    feb22 : 8473.74, 
    mar22 : 11201.24, 
    apr22 : 10435.73, 
    may22 : 12072.56,
    jun22 : 5824.26, 
    jul22 : 9802.09, 
    aug22 : 12363.94, 
    sep22 : 11773.05, 
    oct22 : 10583.81, 
    nov22 : 9326.77, 
    dec22 : 9617.16, 
    jan23 : 13455.36,  
    feb23 : 13795.44,  
    mar23 : 16154.77, 
    apr23 : 13108.99, 
    may23 : 14078.36, 
    jun23 : 12344.02, 
    jul23 : 14844.64, 
    aug23 : 13440.61, 
    sep23 : 12786.52, 
    oct23 : 16795.59, 
    nov23 : 18676.80 
       
}
export const pro6F1ClaimCount = {
    jan21 : 226,
    feb21 : 134,
    mar21 : 198,
    apr21 : 162,
    may21 : 172,
    jun21 : 204,
    jul21 : 129,
    aug21 : 174,
    sep21 : 161,
    oct21 : 214,
    nov21 : 176,
    dec21 : 193,
    jan22 : 165,
    feb22 : 109,
    mar22 : 181,
    apr22 : 164,
    may22 : 167,
    jun22 : 102,
    jul22 : 170,
    aug22 : 167,
    sep22 : 162,
    oct22 : 154,
    nov22 : 159,
    dec22 : 152,
    jan23 : 146,
    feb23 : 149,
    mar23 : 156,
    apr23 : 144,
    may23 : 148,
    jun23 : 209,
    jul23 : 158,
    aug23 : 181,
    sep23 : 178,
    oct23 : 208,
    nov23 : 153
        
}
export const pro6F1PerVisit = {
    jan21 : 68.19, 
    feb21 : 117.98, 
    mar21 : 93.84, 
    apr21 : 86.89, 
    may21 : 65.77, 
    jun21 : 77.17, 
    jul21 : 143.39, 
    aug21 : 74.10, 
    sep21 : 101.48, 
    oct21 : 95.60, 
    nov21 : 122.69, 
    dec21 : 99.71, 
    jan22 : 100.95, 
    feb22 : 136.19, 
    mar22 : 95.64, 
    apr22 : 125.61, 
    may22 : 105.72, 
    jun22 : 80.44, 
    jul22 : 89.40, 
    aug22 : 128.77, 
    sep22 : 136.45, 
    oct22 : 125.06, 
    nov22 : 123.75,
    dec22 : 117.12, 
    jan23 : 129.99, 
    feb23 : 119.58, 
    mar23 : 129.21, 
    apr23 : 130.66, 
    may23 : 132.92, 
    jun23 : 79.13, 
    jul23 : 113.35, 
    aug23 : 107.77, 
    sep23 : 93.75, 
    oct23 : 122.61, 
    nov23 : 171.27 
      
}
// pro6 F2
export const pro6F2Charge = {
    jan21 : 26882.97,  
    feb21 : 21333.90,  
    mar21 : 28432.76, 
    apr21 : 22826.01, 
    may21 : 24328.67,  
    jun21 : 29828.33,  
    jul21 : 19385.33, 
    aug21 : 31724.27, 
    sep21 : 31721.94, 
    oct21 : 37570.79, 
    nov21 : 30393.05, 
    dec21 : 35587.67, 
    jan22 : 28363.67, 
    feb22 : 19506.52, 
    mar22 : 31830.41, 
    apr22 : 29715.86,  
    may22 : 27761.50, 
    jun22 : 17962.70,  
    jul22 : 24390.36,  
    aug22 : 38267.98, 
    sep22 : 31419.71, 
    oct22 : 34625.98, 
    nov22 : 30297.00, 
    dec22 : 30462.13, 
    jan23 : 29301.55, 
    feb23 : 34516.31, 
    mar23 : 39461.33, 
    apr23 : 33193.67, 
    may23 : 30930.90, 
    jun23 : 34368.52, 
    jul23 : 25782.00, 
    aug23 : 30638.80,  
    sep23 : 38407.63, 
    oct23 : 46191.01, 
    nov23 : 39435.33  
}
export const pro6F2Payment = {
    jan21 : 15387.52, 
    feb21 : 15770.33, 
    mar21 : 18549.45, 
    apr21 : 14076.62, 
    may21 : 11334.09, 
    jun21 : 15742.43, 
    jul21 : 18449.67, 
    aug21 : 12918.83, 
    sep21 : 16304.27, 
    oct21 : 20490.96, 
    nov21 : 21593.94, 
    dec21 : 19276.80, 
    jan22 : 16622.84, 
    feb22 : 14889.64, 
    mar22 : 17278.62, 
    apr22 : 20599.42, 
    may22 : 17655.75, 
    jun22 : 8204.65, 
    jul22 : 15167.44, 
    aug22 : 21546.88, 
    sep22 : 22149.58, 
    oct22 : 19258.96, 
    nov22 : 19716.76, 
    dec22 : 17802.94, 
    jan23 : 19022.17, 
    feb23 : 17857.60, 
    mar23 : 20200.53, 
    apr23 : 18814.61, 
    may23 : 19717.15, 
    jun23 : 16512.53, 
    jul23 : 17947.41, 
    aug23 : 19506.86, 
    sep23 : 16686.92, 
    oct23 : 25544.24, 
    nov23 : 26262.00     
}
export const pro6F2Collectable = {
    jan21 : 123.96, 
    feb21 : 219.59, 
    mar21 : 68.73, 
    apr21 : 10.85, 
    may21 : 40.07,  
    jun21 : 49.66, 
    jul21 : 2.47, 
    aug21 : 16.33, 
    sep21 : 43.33, 
    oct21 : 103.35, 
    nov21 : 104.65, 
    dec21 : 1428.92, 
    jan22 : 1391.58, 
    feb22 : 22.62, 
    mar22 : 59.50, 
    apr22 : 24.79, 
    may22 : 123.77, 
    jun22 : 22.66, 
    jul22 : 291.44, 
    aug22 : 2505.40, 
    sep22 : 1784.82, 
    oct22 : 1892.56, 
    nov22 : 750.54, 
    dec22 : 61.07, 
    jan23 : 6.44, 
    feb23 : 106.39, 
    mar23 : 36.79, 
    apr23 : 76.65, 
    may23 : 144.45, 
    jun23 : 195.41,  
    jul23 : 44.89, 
    aug23 : 134.76, 
    sep23 : 86.98, 
    oct23 : 68.89, 
    nov23 : 67.50     
}
export const pro6F2NonCollectable = {
    jan21 : 9587.81, 
    feb21 : 9160.81, 
    mar21 : 11046.32, 
    apr21 : 8946.86, 
    may21 : 8121.06, 
    jun21 : 9649.26, 
    jul21 : 7836.72, 
    aug21 : 9198.67,  
    sep21 : 9685.79,  
    oct21 : 11747.99, 
    nov21 : 13732.41, 
    dec21 : 12112.76, 
    jan22 : 10206.91, 
    feb22 : 8473.74, 
    mar22 : 11201.24, 
    apr22 : 10435.73, 
    may22 : 12072.56,
    jun22 : 5824.26, 
    jul22 : 9802.09, 
    aug22 : 12363.94, 
    sep22 : 11773.05, 
    oct22 : 10583.81, 
    nov22 : 9326.77, 
    dec22 : 9617.16, 
    jan23 : 13455.36,  
    feb23 : 13795.44,  
    mar23 : 16154.77, 
    apr23 : 13108.99, 
    may23 : 14078.36, 
    jun23 : 12344.02, 
    jul23 : 14844.64, 
    aug23 : 13440.61, 
    sep23 : 12786.52, 
    oct23 : 16795.59, 
    nov23 : 18676.80  
}
export const pro6F2ClaimCount = {
    jan21 : 226,
    feb21 : 134,
    mar21 : 198,
    apr21 : 162,
    may21 : 172,
    jun21 : 204,
    jul21 : 129,
    aug21 : 174,
    sep21 : 161,
    oct21 : 214,
    nov21 : 176,
    dec21 : 193,
    jan22 : 165,
    feb22 : 109,
    mar22 : 181,
    apr22 : 164,
    may22 : 167,
    jun22 : 102,
    jul22 : 170,
    aug22 : 167,
    sep22 : 162,
    oct22 : 154,
    nov22 : 159,
    dec22 : 152,
    jan23 : 146,
    feb23 : 149,
    mar23 : 156,
    apr23 : 144,
    may23 : 148,
    jun23 : 209,
    jul23 : 158,
    aug23 : 181,
    sep23 : 178,
    oct23 : 208,
    nov23 : 153    
}
export const pro6F2PerVisit = {
    jan21 : 68.19, 
    feb21 : 117.98, 
    mar21 : 93.84, 
    apr21 : 86.89, 
    may21 : 65.77, 
    jun21 : 77.17, 
    jul21 : 143.39, 
    aug21 : 74.10, 
    sep21 : 101.48, 
    oct21 : 95.60, 
    nov21 : 122.69, 
    dec21 : 99.71, 
    jan22 : 100.95, 
    feb22 : 136.19, 
    mar22 : 95.64, 
    apr22 : 125.61, 
    may22 : 105.72, 
    jun22 : 80.44, 
    jul22 : 89.40, 
    aug22 : 128.77, 
    sep22 : 136.45, 
    oct22 : 125.06, 
    nov22 : 123.75,
    dec22 : 117.12, 
    jan23 : 129.99, 
    feb23 : 119.58, 
    mar23 : 129.21, 
    apr23 : 130.66, 
    may23 : 132.92, 
    jun23 : 79.13, 
    jul23 : 113.35, 
    aug23 : 107.77, 
    sep23 : 93.75, 
    oct23 : 122.61, 
    nov23 : 171.27    
}
// pro6 F3
export const pro6F3Charge = {
    jan21 : 26882.97,  
    feb21 : 21333.90,  
    mar21 : 28432.76, 
    apr21 : 22826.01, 
    may21 : 24328.67,  
    jun21 : 29828.33,  
    jul21 : 19385.33, 
    aug21 : 31724.27, 
    sep21 : 31721.94, 
    oct21 : 37570.79, 
    nov21 : 30393.05, 
    dec21 : 35587.67, 
    jan22 : 28363.67, 
    feb22 : 19506.52, 
    mar22 : 31830.41, 
    apr22 : 29715.86,  
    may22 : 27761.50, 
    jun22 : 17962.70,  
    jul22 : 24390.36,  
    aug22 : 38267.98, 
    sep22 : 31419.71, 
    oct22 : 34625.98, 
    nov22 : 30297.00, 
    dec22 : 30462.13, 
    jan23 : 29301.55, 
    feb23 : 34516.31, 
    mar23 : 39461.33, 
    apr23 : 33193.67, 
    may23 : 30930.90, 
    jun23 : 34368.52, 
    jul23 : 25782.00, 
    aug23 : 30638.80,  
    sep23 : 38407.63, 
    oct23 : 46191.01, 
    nov23 : 39435.33  
}
export const pro6F3Payment = {
    jan21 : 15387.52, 
    feb21 : 15770.33, 
    mar21 : 18549.45, 
    apr21 : 14076.62, 
    may21 : 11334.09, 
    jun21 : 15742.43, 
    jul21 : 18449.67, 
    aug21 : 12918.83, 
    sep21 : 16304.27, 
    oct21 : 20490.96, 
    nov21 : 21593.94, 
    dec21 : 19276.80, 
    jan22 : 16622.84, 
    feb22 : 14889.64, 
    mar22 : 17278.62, 
    apr22 : 20599.42, 
    may22 : 17655.75, 
    jun22 : 8204.65, 
    jul22 : 15167.44, 
    aug22 : 21546.88, 
    sep22 : 22149.58, 
    oct22 : 19258.96, 
    nov22 : 19716.76, 
    dec22 : 17802.94, 
    jan23 : 19022.17, 
    feb23 : 17857.60, 
    mar23 : 20200.53, 
    apr23 : 18814.61, 
    may23 : 19717.15, 
    jun23 : 16512.53, 
    jul23 : 17947.41, 
    aug23 : 19506.86, 
    sep23 : 16686.92, 
    oct23 : 25544.24, 
    nov23 : 26262.00    
}
export const pro6F3Collectable = {
    jan21 : 123.96, 
    feb21 : 219.59, 
    mar21 : 68.73, 
    apr21 : 10.85, 
    may21 : 40.07,  
    jun21 : 49.66, 
    jul21 : 2.47, 
    aug21 : 16.33, 
    sep21 : 43.33, 
    oct21 : 103.35, 
    nov21 : 104.65, 
    dec21 : 1428.92, 
    jan22 : 1391.58, 
    feb22 : 22.62, 
    mar22 : 59.50, 
    apr22 : 24.79, 
    may22 : 123.77, 
    jun22 : 22.66, 
    jul22 : 291.44, 
    aug22 : 2505.40, 
    sep22 : 1784.82, 
    oct22 : 1892.56, 
    nov22 : 750.54, 
    dec22 : 61.07, 
    jan23 : 6.44, 
    feb23 : 106.39, 
    mar23 : 36.79, 
    apr23 : 76.65, 
    may23 : 144.45, 
    jun23 : 195.41,  
    jul23 : 44.89, 
    aug23 : 134.76, 
    sep23 : 86.98, 
    oct23 : 68.89, 
    nov23 : 67.50     
}
export const pro6F3NonCollectable = {
    jan21 : 9587.81, 
    feb21 : 9160.81, 
    mar21 : 11046.32, 
    apr21 : 8946.86, 
    may21 : 8121.06, 
    jun21 : 9649.26, 
    jul21 : 7836.72, 
    aug21 : 9198.67,  
    sep21 : 9685.79,  
    oct21 : 11747.99, 
    nov21 : 13732.41, 
    dec21 : 12112.76, 
    jan22 : 10206.91, 
    feb22 : 8473.74, 
    mar22 : 11201.24, 
    apr22 : 10435.73, 
    may22 : 12072.56,
    jun22 : 5824.26, 
    jul22 : 9802.09, 
    aug22 : 12363.94, 
    sep22 : 11773.05, 
    oct22 : 10583.81, 
    nov22 : 9326.77, 
    dec22 : 9617.16, 
    jan23 : 13455.36,  
    feb23 : 13795.44,  
    mar23 : 16154.77, 
    apr23 : 13108.99, 
    may23 : 14078.36, 
    jun23 : 12344.02, 
    jul23 : 14844.64, 
    aug23 : 13440.61, 
    sep23 : 12786.52, 
    oct23 : 16795.59, 
    nov23 : 18676.80    
}
export const pro6F3ClaimCount = {
    jan21 : 226,
    feb21 : 134,
    mar21 : 198,
    apr21 : 162,
    may21 : 172,
    jun21 : 204,
    jul21 : 129,
    aug21 : 174,
    sep21 : 161,
    oct21 : 214,
    nov21 : 176,
    dec21 : 193,
    jan22 : 165,
    feb22 : 109,
    mar22 : 181,
    apr22 : 164,
    may22 : 167,
    jun22 : 102,
    jul22 : 170,
    aug22 : 167,
    sep22 : 162,
    oct22 : 154,
    nov22 : 159,
    dec22 : 152,
    jan23 : 146,
    feb23 : 149,
    mar23 : 156,
    apr23 : 144,
    may23 : 148,
    jun23 : 209,
    jul23 : 158,
    aug23 : 181,
    sep23 : 178,
    oct23 : 208,
    nov23 : 153    
}
export const pro6F3PerVisit = {
    jan21 : 68.19, 
    feb21 : 117.98, 
    mar21 : 93.84, 
    apr21 : 86.89, 
    may21 : 65.77, 
    jun21 : 77.17, 
    jul21 : 143.39, 
    aug21 : 74.10, 
    sep21 : 101.48, 
    oct21 : 95.60, 
    nov21 : 122.69, 
    dec21 : 99.71, 
    jan22 : 100.95, 
    feb22 : 136.19, 
    mar22 : 95.64, 
    apr22 : 125.61, 
    may22 : 105.72, 
    jun22 : 80.44, 
    jul22 : 89.40, 
    aug22 : 128.77, 
    sep22 : 136.45, 
    oct22 : 125.06, 
    nov22 : 123.75,
    dec22 : 117.12, 
    jan23 : 129.99, 
    feb23 : 119.58, 
    mar23 : 129.21, 
    apr23 : 130.66, 
    may23 : 132.92, 
    jun23 : 79.13, 
    jul23 : 113.35, 
    aug23 : 107.77, 
    sep23 : 93.75, 
    oct23 : 122.61, 
    nov23 : 171.27   
}
// pro7 F1
export const pro7F1Charge = {
    jan21 : 22979.33, 
    feb21 : 21058.33, 
    mar21 : 30283.00, 
    apr21 : 27807.00, 
    may21 : 26075.33, 
    jun21 : 22662.67, 
    jul21 : 24649.67, 
    aug21 : 34310.33, 
    sep21 : 29216.33, 
    oct21 : 29921.00, 
    nov21 : 21407.67, 
    dec21 : 27101.17, 
    jan22 : 22134.67, 
    feb22 : 14592.67, 
    mar22 : 30092.50, 
    apr22 : 26633.33, 
    may22 : 19982.70, 
    jun22 : 15676.55, 
    jul22 : 23927.67, 
    aug22 : 29807.67, 
    sep22 : 25482.67, 
    oct22 : 28597.55, 
    nov22 : 26968.00, 
    dec22 : 25349.00, 
    jan23 : 11084.33, 
    feb23 : 14982.00, 
    mar23 : 18766.02, 
    apr23 : 13275.79, 
    may23 : 11781.55, 
    jun23 : 19902.92, 
    jul23 : 11141.35, 
    aug23 : 11610.34, 
    sep23 : 16561.00,  
    oct23 : 16945.67, 
    nov23 : 17527.92 
   
}
export const pro7F1Payment = {
    jan21 : 11227.44, 
    feb21 : 13696.64, 
    mar21 : 22798.52, 
    apr21 : 17240.62, 
    may21 : 16002.18, 
    jun21 : 17810.99, 
    jul21 : 16063.50, 
    aug21 : 16890.89, 
    sep21 : 18648.92, 
    oct21 : 19737.86, 
    nov21 : 18762.77, 
    dec21 : 13786.39, 
    jan22 : 16308.23, 
    feb22 : 13721.78, 
    mar22 : 17550.83, 
    apr22 : 17916.06, 
    may22 : 16217.47, 
    jun22 : 10831.94, 
    jul22 : 16824.44, 
    aug22 : 21531.47, 
    sep22 : 16934.52, 
    oct22 : 15586.58, 
    nov22 : 16537.60, 
    dec22 : 18256.39, 
    jan23 : 14880.57, 
    feb23 : 7740.74,
    mar23 : 12601.33, 
    apr23 : 8896.03, 
    may23 : 9376.46, 
    jun23 : 8434.21, 
    jul23 : 11951.11, 
    aug23 : 9516.58, 
    sep23 : 8445.07, 
    oct23 : 9557.30, 
    nov23 : 12162.66 
       
}
export const pro7F1Collectable = {
    jan21 : 8.94, 
    feb21 : 0.37, 
    mar21 : 29.07, 
    apr21 : 15.00, 
    may21 : 16.89, 
    jun21 : 119.67, 
    jul21 : 5.38, 
    aug21 : 16.67, 
    sep21 : 0,   
    oct21 : 25.83, 
    nov21 : 68.46, 
    dec21 : 2.39, 
    jan22 : 607.44, 
    feb22 : 25.00, 
    mar22 : 53.57, 
    apr22 : 0,   
    may22 : 30.00, 
    jun22 : 57.64, 
    jul22 : 247.16, 
    aug22 : 2024.29, 
    sep22 : 2999.26, 
    oct22 : 2011.39, 
    nov22 : 329.50, 
    dec22 : 0,     
    jan23 : 0,     
    feb23 : 0,      
    mar23 : 0,     
    apr23 : 427.33, 
    may23 : 1.36, 
    jun23 : 19.52, 
    jul23 : 24.14, 
    aug23 : 20.31, 
    sep23 : 48.26, 
    oct23 : 86.63, 
    nov23 : 28.69 
      
}
export const pro7F1NonCollectable = {
    jan21 : 5713.77, 
    feb21 : 7569.37, 
    mar21 : 9773.76, 
    apr21 : 8722.44, 
    may21 : 7177.87, 
    jun21 : 8288.18, 
    jul21 : 7494.26, 
    aug21 : 7552.38,  
    sep21 : 9364.13, 
    oct21 : 9413.99, 
    nov21 : 9675.68, 
    dec21 : 6898.42, 
    jan22 : 7581.42, 
    feb22 : 6233.78, 
    mar22 : 7388.31, 
    apr22 : 8481.04, 
    may22 : 7751.32, 
    jun22 : 5172.14, 
    jul22 : 7871.31, 
    aug22 : 9893.44, 
    sep22 : 6954.25, 
    oct22 : 6326.21, 
    nov22 : 7513.82, 
    dec22 : 7190.46, 
    jan23 : 7640.30, 
    feb23 : 3791.85, 
    mar23 : 6753.54, 
    apr23 : 4323.19, 
    may23 : 4464.29, 
    jun23 : 4264.71, 
    jul23 : 5538.71, 
    aug23 : 4703.69, 
    sep23 : 4773.84, 
    oct23 : 6474.44, 
    nov23 : 7895.13  
       
}
export const pro7F1ClaimCount = {
    jan21 : 116,
    feb21 : 96,
    mar21 : 119,
    apr21 : 108,
    may21 : 111,
    jun21 : 109,
    jul21 : 100,
    aug21 : 103,
    sep21 : 101,
    oct21 : 131,
    nov21 : 97,
    dec21 : 118,
    jan22 : 107,
    feb22 : 52,
    mar22 : 102,
    apr22 : 95,
    may22 : 72,
    jun22 : 51,
    jul22 : 76,
    aug22 : 90,
    sep22 : 93,
    oct22 : 94,
    nov22 : 83,
    dec22 : 71,
    jan23 : 69,
    feb23 : 37,
    mar23 : 57,
    apr23 : 34,
    may23 : 42,
    jun23 : 60,
    jul23 : 39,
    aug23 : 52,
    sep23 : 45,
    oct23 : 70,
    nov23 : 59
       
}
export const pro7F1PerVisit = {
    jan21 : 96.79, 
    feb21 : 142.18, 
    mar21 : 191.05, 
    apr21 : 159.64, 
    may21 : 143.73, 
    jun21 : 163.90, 
    jul21 : 160.10, 
    aug21 : 163.46, 
    sep21 : 184.64, 
    oct21 : 151.06, 
    nov21 : 194.10, 
    dec21 : 116.83, 
    jan22 : 151.94, 
    feb22 : 263.88,  
    mar22 : 171.51, 
    apr22 : 188.59, 
    may22 : 224.20, 
    jun22 : 213.79,  
    jul22 : 222.35, 
    aug22 : 240.13, 
    sep22 : 182.75, 
    oct22 : 165.23, 
    nov22 : 199.25, 
    dec22 : 258.35, 
    jan23 : 215.66, 
    feb23 : 207.34, 
    mar23 : 219.79, 
    apr23 : 261.65, 
    may23 : 221.49, 
    jun23 : 141.36, 
    jul23 : 303.84, 
    aug23 : 181.85, 
    sep23 : 187.67, 
    oct23 : 136.53, 
    nov23 : 204.99 
     
}
// pro7 F2
export const pro7F2Charge = {
    jan21 : 22979.33, 
    feb21 : 21058.33, 
    mar21 : 30283.00, 
    apr21 : 27807.00, 
    may21 : 26075.33, 
    jun21 : 22662.67, 
    jul21 : 24649.67, 
    aug21 : 34310.33, 
    sep21 : 29216.33, 
    oct21 : 29921.00, 
    nov21 : 21407.67, 
    dec21 : 27101.17, 
    jan22 : 22134.67, 
    feb22 : 14592.67, 
    mar22 : 30092.50, 
    apr22 : 26633.33, 
    may22 : 19982.70, 
    jun22 : 15676.55, 
    jul22 : 23927.67, 
    aug22 : 29807.67, 
    sep22 : 25482.67, 
    oct22 : 28597.55, 
    nov22 : 26968.00, 
    dec22 : 25349.00, 
    jan23 : 11084.33, 
    feb23 : 14982.00, 
    mar23 : 18766.02, 
    apr23 : 13275.79, 
    may23 : 11781.55, 
    jun23 : 19902.92, 
    jul23 : 11141.35, 
    aug23 : 11610.34, 
    sep23 : 16561.00,  
    oct23 : 16945.67, 
    nov23 : 17527.92 
}
export const pro7F2Payment = {
    jan21 : 11227.44, 
    feb21 : 13696.64, 
    mar21 : 22798.52, 
    apr21 : 17240.62, 
    may21 : 16002.18, 
    jun21 : 17810.99, 
    jul21 : 16063.50, 
    aug21 : 16890.89, 
    sep21 : 18648.92, 
    oct21 : 19737.86, 
    nov21 : 18762.77, 
    dec21 : 13786.39, 
    jan22 : 16308.23, 
    feb22 : 13721.78, 
    mar22 : 17550.83, 
    apr22 : 17916.06, 
    may22 : 16217.47, 
    jun22 : 10831.94, 
    jul22 : 16824.44, 
    aug22 : 21531.47, 
    sep22 : 16934.52, 
    oct22 : 15586.58, 
    nov22 : 16537.60, 
    dec22 : 18256.39, 
    jan23 : 14880.57, 
    feb23 : 7740.74,
    mar23 : 12601.33, 
    apr23 : 8896.03, 
    may23 : 9376.46, 
    jun23 : 8434.21, 
    jul23 : 11951.11, 
    aug23 : 9516.58, 
    sep23 : 8445.07, 
    oct23 : 9557.30, 
    nov23 : 12162.66     
}
export const pro7F2Collectable = {
    jan21 : 8.94, 
    feb21 : 0.37, 
    mar21 : 29.07, 
    apr21 : 15.00, 
    may21 : 16.89, 
    jun21 : 119.67, 
    jul21 : 5.38, 
    aug21 : 16.67, 
    sep21 : 0,   
    oct21 : 25.83, 
    nov21 : 68.46, 
    dec21 : 2.39, 
    jan22 : 607.44, 
    feb22 : 25.00, 
    mar22 : 53.57, 
    apr22 : 0,   
    may22 : 30.00, 
    jun22 : 57.64, 
    jul22 : 247.16, 
    aug22 : 2024.29, 
    sep22 : 2999.26, 
    oct22 : 2011.39, 
    nov22 : 329.50, 
    dec22 : 0,     
    jan23 : 0,     
    feb23 : 0,      
    mar23 : 0,     
    apr23 : 427.33, 
    may23 : 1.36, 
    jun23 : 19.52, 
    jul23 : 24.14, 
    aug23 : 20.31, 
    sep23 : 48.26, 
    oct23 : 86.63, 
    nov23 : 28.69    
}
export const pro7F2NonCollectable = {
    jan21 : 5713.77, 
    feb21 : 7569.37, 
    mar21 : 9773.76, 
    apr21 : 8722.44, 
    may21 : 7177.87, 
    jun21 : 8288.18, 
    jul21 : 7494.26, 
    aug21 : 7552.38,  
    sep21 : 9364.13, 
    oct21 : 9413.99, 
    nov21 : 9675.68, 
    dec21 : 6898.42, 
    jan22 : 7581.42, 
    feb22 : 6233.78, 
    mar22 : 7388.31, 
    apr22 : 8481.04, 
    may22 : 7751.32, 
    jun22 : 5172.14, 
    jul22 : 7871.31, 
    aug22 : 9893.44, 
    sep22 : 6954.25, 
    oct22 : 6326.21, 
    nov22 : 7513.82, 
    dec22 : 7190.46, 
    jan23 : 7640.30, 
    feb23 : 3791.85, 
    mar23 : 6753.54, 
    apr23 : 4323.19, 
    may23 : 4464.29, 
    jun23 : 4264.71, 
    jul23 : 5538.71, 
    aug23 : 4703.69, 
    sep23 : 4773.84, 
    oct23 : 6474.44, 
    nov23 : 7895.13     
}
export const pro7F2ClaimCount = {
    jan21 : 116,
    feb21 : 96,
    mar21 : 119,
    apr21 : 108,
    may21 : 111,
    jun21 : 109,
    jul21 : 100,
    aug21 : 103,
    sep21 : 101,
    oct21 : 131,
    nov21 : 97,
    dec21 : 118,
    jan22 : 107,
    feb22 : 52,
    mar22 : 102,
    apr22 : 95,
    may22 : 72,
    jun22 : 51,
    jul22 : 76,
    aug22 : 90,
    sep22 : 93,
    oct22 : 94,
    nov22 : 83,
    dec22 : 71,
    jan23 : 69,
    feb23 : 37,
    mar23 : 57,
    apr23 : 34,
    may23 : 42,
    jun23 : 60,
    jul23 : 39,
    aug23 : 52,
    sep23 : 45,
    oct23 : 70,
    nov23 : 59   
}
export const pro7F2PerVisit = {
    jan21 : 96.79, 
    feb21 : 142.18, 
    mar21 : 191.05, 
    apr21 : 159.64, 
    may21 : 143.73, 
    jun21 : 163.90, 
    jul21 : 160.10, 
    aug21 : 163.46, 
    sep21 : 184.64, 
    oct21 : 151.06, 
    nov21 : 194.10, 
    dec21 : 116.83, 
    jan22 : 151.94, 
    feb22 : 263.88,  
    mar22 : 171.51, 
    apr22 : 188.59, 
    may22 : 224.20, 
    jun22 : 213.79,  
    jul22 : 222.35, 
    aug22 : 240.13, 
    sep22 : 182.75, 
    oct22 : 165.23, 
    nov22 : 199.25, 
    dec22 : 258.35, 
    jan23 : 215.66, 
    feb23 : 207.34, 
    mar23 : 219.79, 
    apr23 : 261.65, 
    may23 : 221.49, 
    jun23 : 141.36, 
    jul23 : 303.84, 
    aug23 : 181.85, 
    sep23 : 187.67, 
    oct23 : 136.53, 
    nov23 : 204.99    
}
// pro7 F3
export const pro7F3Charge = {
    jan21 : 22979.33, 
    feb21 : 21058.33, 
    mar21 : 30283.00, 
    apr21 : 27807.00, 
    may21 : 26075.33, 
    jun21 : 22662.67, 
    jul21 : 24649.67, 
    aug21 : 34310.33, 
    sep21 : 29216.33, 
    oct21 : 29921.00, 
    nov21 : 21407.67, 
    dec21 : 27101.17, 
    jan22 : 22134.67, 
    feb22 : 14592.67, 
    mar22 : 30092.50, 
    apr22 : 26633.33, 
    may22 : 19982.70, 
    jun22 : 15676.55, 
    jul22 : 23927.67, 
    aug22 : 29807.67, 
    sep22 : 25482.67, 
    oct22 : 28597.55, 
    nov22 : 26968.00, 
    dec22 : 25349.00, 
    jan23 : 11084.33, 
    feb23 : 14982.00, 
    mar23 : 18766.02, 
    apr23 : 13275.79, 
    may23 : 11781.55, 
    jun23 : 19902.92, 
    jul23 : 11141.35, 
    aug23 : 11610.34, 
    sep23 : 16561.00,  
    oct23 : 16945.67, 
    nov23 : 17527.92 
}
export const pro7F3Payment = {
    jan21 : 11227.44, 
    feb21 : 13696.64, 
    mar21 : 22798.52, 
    apr21 : 17240.62, 
    may21 : 16002.18, 
    jun21 : 17810.99, 
    jul21 : 16063.50, 
    aug21 : 16890.89, 
    sep21 : 18648.92, 
    oct21 : 19737.86, 
    nov21 : 18762.77, 
    dec21 : 13786.39, 
    jan22 : 16308.23, 
    feb22 : 13721.78, 
    mar22 : 17550.83, 
    apr22 : 17916.06, 
    may22 : 16217.47, 
    jun22 : 10831.94, 
    jul22 : 16824.44, 
    aug22 : 21531.47, 
    sep22 : 16934.52, 
    oct22 : 15586.58, 
    nov22 : 16537.60, 
    dec22 : 18256.39, 
    jan23 : 14880.57, 
    feb23 : 7740.74,
    mar23 : 12601.33, 
    apr23 : 8896.03, 
    may23 : 9376.46, 
    jun23 : 8434.21, 
    jul23 : 11951.11, 
    aug23 : 9516.58, 
    sep23 : 8445.07, 
    oct23 : 9557.30, 
    nov23 : 12162.66     
}
export const pro7F3Collectable = {
    jan21 : 8.94, 
    feb21 : 0.37, 
    mar21 : 29.07, 
    apr21 : 15.00, 
    may21 : 16.89, 
    jun21 : 119.67, 
    jul21 : 5.38, 
    aug21 : 16.67, 
    sep21 : 0,   
    oct21 : 25.83, 
    nov21 : 68.46, 
    dec21 : 2.39, 
    jan22 : 607.44, 
    feb22 : 25.00, 
    mar22 : 53.57, 
    apr22 : 0,   
    may22 : 30.00, 
    jun22 : 57.64, 
    jul22 : 247.16, 
    aug22 : 2024.29, 
    sep22 : 2999.26, 
    oct22 : 2011.39, 
    nov22 : 329.50, 
    dec22 : 0,     
    jan23 : 0,     
    feb23 : 0,      
    mar23 : 0,     
    apr23 : 427.33, 
    may23 : 1.36, 
    jun23 : 19.52, 
    jul23 : 24.14, 
    aug23 : 20.31, 
    sep23 : 48.26, 
    oct23 : 86.63, 
    nov23 : 28.69    
}
export const pro7F3NonCollectable = {
    jan21 : 5713.77, 
    feb21 : 7569.37, 
    mar21 : 9773.76, 
    apr21 : 8722.44, 
    may21 : 7177.87, 
    jun21 : 8288.18, 
    jul21 : 7494.26, 
    aug21 : 7552.38,  
    sep21 : 9364.13, 
    oct21 : 9413.99, 
    nov21 : 9675.68, 
    dec21 : 6898.42, 
    jan22 : 7581.42, 
    feb22 : 6233.78, 
    mar22 : 7388.31, 
    apr22 : 8481.04, 
    may22 : 7751.32, 
    jun22 : 5172.14, 
    jul22 : 7871.31, 
    aug22 : 9893.44, 
    sep22 : 6954.25, 
    oct22 : 6326.21, 
    nov22 : 7513.82, 
    dec22 : 7190.46, 
    jan23 : 7640.30, 
    feb23 : 3791.85, 
    mar23 : 6753.54, 
    apr23 : 4323.19, 
    may23 : 4464.29, 
    jun23 : 4264.71, 
    jul23 : 5538.71, 
    aug23 : 4703.69, 
    sep23 : 4773.84, 
    oct23 : 6474.44, 
    nov23 : 7895.13     
}
export const pro7F3ClaimCount = {
    jan21 : 116,
    feb21 : 96,
    mar21 : 119,
    apr21 : 108,
    may21 : 111,
    jun21 : 109,
    jul21 : 100,
    aug21 : 103,
    sep21 : 101,
    oct21 : 131,
    nov21 : 97,
    dec21 : 118,
    jan22 : 107,
    feb22 : 52,
    mar22 : 102,
    apr22 : 95,
    may22 : 72,
    jun22 : 51,
    jul22 : 76,
    aug22 : 90,
    sep22 : 93,
    oct22 : 94,
    nov22 : 83,
    dec22 : 71,
    jan23 : 69,
    feb23 : 37,
    mar23 : 57,
    apr23 : 34,
    may23 : 42,
    jun23 : 60,
    jul23 : 39,
    aug23 : 52,
    sep23 : 45,
    oct23 : 70,
    nov23 : 59    
}
export const pro7F3PerVisit = {
    jan21 : 96.79, 
    feb21 : 142.18, 
    mar21 : 191.05, 
    apr21 : 159.64, 
    may21 : 143.73, 
    jun21 : 163.90, 
    jul21 : 160.10, 
    aug21 : 163.46, 
    sep21 : 184.64, 
    oct21 : 151.06, 
    nov21 : 194.10, 
    dec21 : 116.83, 
    jan22 : 151.94, 
    feb22 : 263.88,  
    mar22 : 171.51, 
    apr22 : 188.59, 
    may22 : 224.20, 
    jun22 : 213.79,  
    jul22 : 222.35, 
    aug22 : 240.13, 
    sep22 : 182.75, 
    oct22 : 165.23, 
    nov22 : 199.25, 
    dec22 : 258.35, 
    jan23 : 215.66, 
    feb23 : 207.34, 
    mar23 : 219.79, 
    apr23 : 261.65, 
    may23 : 221.49, 
    jun23 : 141.36, 
    jul23 : 303.84, 
    aug23 : 181.85, 
    sep23 : 187.67, 
    oct23 : 136.53, 
    nov23 : 204.99    
}








// export const allF1Charge = [
//     136431.58,
//     112081.84,
//     154218.49,
//     145688.02,
//     126586.96,
//     151612.74,
//     141802.74,
//     177877.81,
//     155577.51,
//     191955,
//     171426.11,
//     164447.46,
//     152033.99,
//     122702.10,
//     167924.51,
//     149912.15,
//     152862.41,
//     90878.01,
//     149258.29,
//     178783.20,
//     175919.40,
//     195484.57,
//     192313.21,
//     161470.60,
//     199596.97,   
//     145532.54,   
//     181041.95,   
//     127603.22,   
//     178667.12,   
//     183536.21,   
//     161142.69,   
//     174608.19,   
//     190322.42,   
//     244425.98,
//     237880.54 
     
  
// ]
// export const allF1Payment = [
//     70255.04,   
//     81329.93,   
//     98201.81,   
//     88645.23,   
//     75560.06,   
//     88214.15,   
//     88619.84,   
//     95528.88,   
//     90430.30,   
//     108168.78,   
//     116757.23,   
//     97060.04,  
//     87829.13,  
//     85615.04,  
//     105070.47,   
//     100263.56,   
//     94974.98,
//     59905.69,  
//     94962.26, 
//     118653.14,  
//     104685.12,  
//     115312.70,  
//     117433.19,  
//     111672.19,  
//     110371.20,  
//     89282.22, 
//     112752.85,  
//     81238.53,
//     94391.82,
//     102788.60, 
//     102985.34, 
//     115940.44, 
//     94191.19,
//     126563.88, 
//     145189.37  



// ]
// export const allF1Collectable = [
//     199.50,   
//     377.79,   
//     493.14,   
//     126.24,   
//     66.22,   
//     410.80,   
//     244.08,   
//     109.09,   
//     179.44,   
//     293.08,   
//     386.89,   
//     1630.33,   
//     8889.07,  
//     261.35,   
//     315.93,   
//     196.95,   
//     347.72,   
//     428.48, 
//     861.58,   
//     16311.53,   
//     19101.18,   
//     15448.87,   
//     4879.29,   
//     224.66,   
//     87.81,   
//     1724.90,   
//     278.90,   
//     117.65,   
//     450.13,   
//     749.86,   
//     821.93,   
//     592.27,   
//     741.56,   
//     695.36,   
//     412.52   
   


// ]
// export const allF1NonCollectable = [
//     44239.75,   
//     46616.54,   
//     52888.02,   
//     54557.10,   
//     43175.70,   
//     49732.27,   
//     47078.14, 
//     52494.62,   
//     50278.82,   
//     62559.67,   
//     71883.24,   
//     54748.30,   
//     49946.98,   
//     47751.10,   
//     59754.40,   
//     51862.36,   
//     57944.56,   
//     34832.43,   
//     55548.89,   
//     63961.21,   
//     53965.24,   
//     58392.81,   
//     56499.57,   
//     51397.92,   
//     71101.19,   
//     51029.59,   
//     81478.67,   
//     45639.95,   
//     61623.40, 
//     66784.38,   
//     67406.85,   
//     71369.61,   
//     62838.79,   
//     84746.83,   
//     104352.27   
   


// ]
// export const allF1ClaimCount = [
//     991,
//     648,
//     906,
//     843,
//     758,
//     938,
//     782,
//     859,
//     729,
//     952,
//     843,
//     827,
//     803,
//     599,
//     812,
//     706,
//     749,
//     446,
//     741,
//     787,
//     803,
//     795,
//     805,
//     764,
//     713,
//     613,
//     629,
//     540,
//     895,
//     922,
//     831,
//     929,
//     790,
//     984,
//     918
    


// ]
// export const allF1PerVisit = [
//     70.87,   
//     125.51,   
//     108.43,  
//     105.15,   
//     99.64,   
//     94.08,   
//     94.08,   
//     111.21,   
//     124.05,   
//     113.66,   
//     138.45,   
//     117.36,   
//     109.38,   
//     142.93,   
//     129.34,   
//     142.02,   
//     126.75,   
//     134.42,     
//     134.42, 
//     150.77,   
//     130.37,   
//     145.11,   
//     145.82,   
//     146.23,   
//     154.80,   
//     145.65,   
//     179.26,   
//     150.50,   
//     105.51,   
//     111.48,   
//     123.88,   
//     124.80,   
//     119.23,   
//     128.62,   
//     158.22   
   


// ]
// // F2
// export const allF2Charge = [
//     136431.58,
//     112081.84,
//     154218.49,
//     145688.02,
//     126586.96,
//     151612.74,
//     141802.74,
//     177877.81,
//     155577.51,
//     191955,
//     171426.11,
//     164447.46,
//     152033.99,
//     122702.10,
//     167924.51,
//     149912.15,
//     152862.41,
//     90878.01,
//     149258.29,
//     178783.20,
//     175919.40,
//     195484.57,
//     192313.21,
//     161470.60,
//     199596.97,   
//     145532.54,   
//     181041.95,   
//     127603.22,   
//     178667.12,   
//     183536.21,   
//     161142.69,   
//     174608.19,   
//     190322.42,   
//     244425.98,
//     237880.54 
// ]
// export const allF2Payment = [
//     70255.04,   
//     81329.93,   
//     98201.81,   
//     88645.23,   
//     75560.06,   
//     88214.15,   
//     88619.84,   
//     95528.88,   
//     90430.30,   
//     108168.78,   
//     116757.23,   
//     97060.04,  
//     87829.13,  
//     85615.04,  
//     105070.47,   
//     100263.56,   
//     94974.98,
//     59905.69,  
//     94962.26, 
//     118653.14,  
//     104685.12,  
//     115312.70,  
//     117433.19,  
//     111672.19,  
//     110371.20,  
//     89282.22, 
//     112752.85,  
//     81238.53,
//     94391.82,
//     102788.60, 
//     102985.34, 
//     115940.44, 
//     94191.19,
//     126563.88, 
//     145189.37    
// ]
// export const allF2Collectable = [
//     199.50,   
//     377.79,   
//     493.14,   
//     126.24,   
//     66.22,   
//     410.80,   
//     244.08,   
//     109.09,   
//     179.44,   
//     293.08,   
//     386.89,   
//     1630.33,   
//     8889.07,  
//     261.35,   
//     315.93,   
//     196.95,   
//     347.72,   
//     428.48, 
//     861.58,   
//     16311.53,   
//     19101.18,   
//     15448.87,   
//     4879.29,   
//     224.66,   
//     87.81,   
//     1724.90,   
//     278.90,   
//     117.65,   
//     450.13,   
//     749.86,   
//     821.93,   
//     592.27,   
//     741.56,   
//     695.36,   
//     412.52   
// ]
// export const allF2NonCollectable = [
//     44239.75,   
//     46616.54,   
//     52888.02,   
//     54557.10,   
//     43175.70,   
//     49732.27,   
//     47078.14, 
//     52494.62,   
//     50278.82,   
//     62559.67,   
//     71883.24,   
//     54748.30,   
//     49946.98,   
//     47751.10,   
//     59754.40,   
//     51862.36,   
//     57944.56,   
//     34832.43,   
//     55548.89,   
//     63961.21,   
//     53965.24,   
//     58392.81,   
//     56499.57,   
//     51397.92,   
//     71101.19,   
//     51029.59,   
//     81478.67,   
//     45639.95,   
//     61623.40, 
//     66784.38,   
//     67406.85,   
//     71369.61,   
//     62838.79,   
//     84746.83,   
//     104352.27   
// ]
// export const allF2ClaimCount = [
//     991,
//     648,
//     906,
//     843,
//     758,
//     938,
//     782,
//     859,
//     729,
//     952,
//     843,
//     827,
//     803,
//     599,
//     812,
//     706,
//     749,
//     446,
//     741,
//     787,
//     803,
//     795,
//     805,
//     764,
//     713,
//     613,
//     629,
//     540,
//     895,
//     922,
//     831,
//     929,
//     790,
//     984,
//     918
// ]
// export const allF2PerVisit = [
//     70.87,   
//     125.51,   
//     108.43,  
//     105.15,   
//     99.64,   
//     94.08,   
//     94.08,   
//     111.21,   
//     124.05,   
//     113.66,   
//     138.45,   
//     117.36,   
//     109.38,   
//     142.93,   
//     129.34,   
//     142.02,   
//     126.75,   
//     134.42,     
//     134.42, 
//     150.77,   
//     130.37,   
//     145.11,   
//     145.82,   
//     146.23,   
//     154.80,   
//     145.65,   
//     179.26,   
//     150.50,   
//     105.51,   
//     111.48,   
//     123.88,   
//     124.80,   
//     119.23,   
//     128.62,   
//     158.22   
// ]
// // F3 
// export const allF3Charge = [
//     136431.58,
//     112081.84,
//     154218.49,
//     145688.02,
//     126586.96,
//     151612.74,
//     141802.74,
//     177877.81,
//     155577.51,
//     191955,
//     171426.11,
//     164447.46,
//     152033.99,
//     122702.10,
//     167924.51,
//     149912.15,
//     152862.41,
//     90878.01,
//     149258.29,
//     178783.20,
//     175919.40,
//     195484.57,
//     192313.21,
//     161470.60,
//     199596.97,   
//     145532.54,   
//     181041.95,   
//     127603.22,   
//     178667.12,   
//     183536.21,   
//     161142.69,   
//     174608.19,   
//     190322.42,   
//     244425.98,
//     237880.54 
// ]
// export const allF3Payment = [
//     70255.04,   
//     81329.93,   
//     98201.81,   
//     88645.23,   
//     75560.06,   
//     88214.15,   
//     88619.84,   
//     95528.88,   
//     90430.30,   
//     108168.78,   
//     116757.23,   
//     97060.04,  
//     87829.13,  
//     85615.04,  
//     105070.47,   
//     100263.56,   
//     94974.98,
//     59905.69,  
//     94962.26, 
//     118653.14,  
//     104685.12,  
//     115312.70,  
//     117433.19,  
//     111672.19,  
//     110371.20,  
//     89282.22, 
//     112752.85,  
//     81238.53,
//     94391.82,
//     102788.60, 
//     102985.34, 
//     115940.44, 
//     94191.19,
//     126563.88, 
//     145189.37    
// ]
// export const allF3Collectable = [
//     199.50,   
//     377.79,   
//     493.14,   
//     126.24,   
//     66.22,   
//     410.80,   
//     244.08,   
//     109.09,   
//     179.44,   
//     293.08,   
//     386.89,   
//     1630.33,   
//     8889.07,  
//     261.35,   
//     315.93,   
//     196.95,   
//     347.72,   
//     428.48, 
//     861.58,   
//     16311.53,   
//     19101.18,   
//     15448.87,   
//     4879.29,   
//     224.66,   
//     87.81,   
//     1724.90,   
//     278.90,   
//     117.65,   
//     450.13,   
//     749.86,   
//     821.93,   
//     592.27,   
//     741.56,   
//     695.36,   
//     412.52   
// ]
// export const allF3NonCollectable = [
//     44239.75,   
//     46616.54,   
//     52888.02,   
//     54557.10,   
//     43175.70,   
//     49732.27,   
//     47078.14, 
//     52494.62,   
//     50278.82,   
//     62559.67,   
//     71883.24,   
//     54748.30,   
//     49946.98,   
//     47751.10,   
//     59754.40,   
//     51862.36,   
//     57944.56,   
//     34832.43,   
//     55548.89,   
//     63961.21,   
//     53965.24,   
//     58392.81,   
//     56499.57,   
//     51397.92,   
//     71101.19,   
//     51029.59,   
//     81478.67,   
//     45639.95,   
//     61623.40, 
//     66784.38,   
//     67406.85,   
//     71369.61,   
//     62838.79,   
//     84746.83,   
//     104352.27  
// ]
// export const allF3ClaimCount = [
//     991,
//     648,
//     906,
//     843,
//     758,
//     938,
//     782,
//     859,
//     729,
//     952,
//     843,
//     827,
//     803,
//     599,
//     812,
//     706,
//     749,
//     446,
//     741,
//     787,
//     803,
//     795,
//     805,
//     764,
//     713,
//     613,
//     629,
//     540,
//     895,
//     922,
//     831,
//     929,
//     790,
//     984,
//     918
// ]
// export const allF3PerVisit = [
//     70.87,   
//     125.51,   
//     108.43,  
//     105.15,   
//     99.64,   
//     94.08,   
//     94.08,   
//     111.21,   
//     124.05,   
//     113.66,   
//     138.45,   
//     117.36,   
//     109.38,   
//     142.93,   
//     129.34,   
//     142.02,   
//     126.75,   
//     134.42,     
//     134.42, 
//     150.77,   
//     130.37,   
//     145.11,   
//     145.82,   
//     146.23,   
//     154.80,   
//     145.65,   
//     179.26,   
//     150.50,   
//     105.51,   
//     111.48,   
//     123.88,   
//     124.80,   
//     119.23,   
//     128.62,   
//     158.22   
// ]
// // pro1 F1
// export const pro1F1Charge = [
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0                                                                                                                         
   
// ]
// export const pro1F1Payment = [
//     0,     
//     31.48, 
//     0,     
//     9.57, 
//     15.03, 
//     4.34, 
//     0,
//     3.33,
//     0,
//     0,  
//     3.33, 
//     0, 
//     2.73,
//     0,
//     0,
//     0, 
//     19.50, 
//     0,     
//     1.67, 
//     0,      
//     0,
//     0,
//     0,     
//     51.93,
//     0,  
//     1.67,  
//     0,     
//     0,     
//     24.44,  
//     3.88,  
//     0,     
//     0,      
//     0,     
//     0,      
//     0   
   
// ]
// export const pro1F1Collectable = [
//     0,   
//     0,      
//     1.62,  
//     0,     
//     15.03,
//     0,
//     0,
//     0,
//     0, 
//     0,      
//     0,      
//     0,      
//     0, 
//     0,
//     0,
//     0,     
//     0,      
//     10,  
//     0,     
//     0,     
//     63.33, 
//     0,
//     0, 
//     0,     
//     0,      
//     0,      
//     0,      
//     0,
//     0,      
//     5,  
//     0,     
//     0,     
//     0,     
//     0,     
//     0     
 
// ]
// export const pro1F1NonCollectable = [
//     16,  
//     31.48,  
//     0,     
//     92.62, 
//     0,     
//     4.34,  
//     0, 
//     0,
//     0,
//     0,     
//     1.67, 
//     0, 
//     2.73,
//     0,
//     0,
//     0,  
//     19.50, 
//     0,     
//     1.67, 
//     20.54,  
//     0,
//     0,
//     0,     
//     51.93,
//     0, 
//     1.67, 
//     0,      
//     0,     
//     24.44,  
//     1.12, 
//     0,      
//     0,      
//     0,     
//     0,     
//     0    
    
// ]
// export const pro1F1ClaimCount = [
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0
      
// ]
// export const pro1F1PerVisit = [
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0                                                                                                                           
       
// ]
// // pro1 F2 
// export const pro1F2Charge = [
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0  
// ]
// export const pro1F2Payment = [
//     0,     
//     31.48, 
//     0,     
//     9.57, 
//     15.03, 
//     4.34, 
//     0,
//     3.33,
//     0,
//     0,  
//     3.33, 
//     0, 
//     2.73,
//     0,
//     0,
//     0, 
//     19.50, 
//     0,     
//     1.67, 
//     0,      
//     0,
//     0,
//     0,     
//     51.93,
//     0,  
//     1.67,  
//     0,     
//     0,     
//     24.44,  
//     3.88,  
//     0,     
//     0,      
//     0,     
//     0,      
//     0    
// ]
// export const pro1F2Collectable = [
//     0,   
//     0,      
//     1.62,  
//     0,     
//     15.03,
//     0,
//     0,
//     0,
//     0, 
//     0,      
//     0,      
//     0,      
//     0, 
//     0,
//     0,
//     0,     
//     0,      
//     10,  
//     0,     
//     0,     
//     63.33, 
//     0,
//     0, 
//     0,     
//     0,      
//     0,      
//     0,      
//     0,
//     0,      
//     5,  
//     0,     
//     0,     
//     0,     
//     0,     
//     0     
// ]
// export const pro1F2NonCollectable = [
//     16,  
//     31.48,  
//     0,     
//     92.62, 
//     0,     
//     4.34,  
//     0, 
//     0,
//     0,
//     0,     
//     1.67, 
//     0, 
//     2.73,
//     0,
//     0,
//     0,  
//     19.50, 
//     0,     
//     1.67, 
//     20.54,  
//     0,
//     0,
//     0,     
//     51.93,
//     0, 
//     1.67, 
//     0,      
//     0,     
//     24.44,  
//     1.12, 
//     0,      
//     0,      
//     0,     
//     0,     
//     0        
// ]
// export const pro1F2ClaimCount = [
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0    
// ]
// export const pro1F2PerVisit = [
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0     
// ]
// // pro1 F3
// export const pro1F3Charge = [
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0  
// ]
// export const pro1F3Payment = [
//     0,     
//     31.48, 
//     0,     
//     9.57, 
//     15.03, 
//     4.34, 
//     0,
//     3.33,
//     0,
//     0,  
//     3.33, 
//     0, 
//     2.73,
//     0,
//     0,
//     0, 
//     19.50, 
//     0,     
//     1.67, 
//     0,      
//     0,
//     0,
//     0,     
//     51.93,
//     0,  
//     1.67,  
//     0,     
//     0,     
//     24.44,  
//     3.88,  
//     0,     
//     0,      
//     0,     
//     0,      
//     0 
// ]
// export const pro1F3Collectable = [
//     0,   
//     0,      
//     1.62,  
//     0,     
//     15.03,
//     0,
//     0,
//     0,
//     0, 
//     0,      
//     0,      
//     0,      
//     0, 
//     0,
//     0,
//     0,     
//     0,      
//     10,  
//     0,     
//     0,     
//     63.33, 
//     0,
//     0, 
//     0,     
//     0,      
//     0,      
//     0,      
//     0,
//     0,      
//     5,  
//     0,     
//     0,     
//     0,     
//     0,     
//     0     
// ]
// export const pro1F3NonCollectable = [
//     16,  
//     31.48,  
//     0,     
//     92.62, 
//     0,     
//     4.34,  
//     0, 
//     0,
//     0,
//     0,     
//     1.67, 
//     0, 
//     2.73,
//     0,
//     0,
//     0,  
//     19.50, 
//     0,     
//     1.67, 
//     20.54,  
//     0,
//     0,
//     0,     
//     51.93,
//     0, 
//     1.67, 
//     0,      
//     0,     
//     24.44,  
//     1.12, 
//     0,      
//     0,      
//     0,     
//     0,     
//     0      
// ]
// export const pro1F3ClaimCount = [
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0,
//     0    
// ]
// export const pro1F3PerVisit = [
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0,   
//     0     
// ]
// // pro2 F1
// export const pro2F1Charge = [
//     20281.27,  
//     20151.48,  
//     19007.33, 
//     23953.42,  
//     16103.79,  
//     26278.66, 
//     22156.69, 
//     24461.17, 
//     16778.17,  
//     36833.21, 
//     34311.33,  
//     24303.00, 
//     26208.06,  
//     19547.66,  
//     29282.33,  
//     18894.38,  
//     23961.00, 
//     16828.80, 
//     25401.16, 
//     15403.08,  
//     29584.32, 
//     27985.37, 
//     37526.99,  
//     20912.67, 
//     34828.00, 
//     33541.74, 
//     33168.35, 
//     13903.00, 
//     30474.27, 
//     27346.62,  
//     25367.00, 
//     28022.84, 
//     19079.03,  
//     42602.33,  
//     39032.36 
   
// ]
// export const pro2F1Payment = [
//     11476.09, 
//     12247.67,  
//     12870.67, 
//     14134.14, 
//     10978.97, 
//     14660.78, 
//     13015.59, 
//     15982.75,  
//     11122.39, 
//     15766.93,  
//     21837.79,  
//     15809.06,  
//     15871.87,  
//     13550.05, 
//     17729.12,  
//     15343.50,  
//     11847.85,  
//     10409.79,  
//     15397.74, 
//     13880.32, 
//     14225.77,  
//     18095.09,  
//     18302.93, 
//     15585.20,  
//     18909.27,  
//     17463.23, 
//     18722.11, 
//     13475.55,  
//     13122.52, 
//     14898.61,  
//     15840.93, 
//     18038.11, 
//     11751.92, 
//     18881.10, 
//     23421.70 
    
// ]
// export const pro2F1Collectable = [
//     62.45, 
//     80.90,  
//     32.36, 
//     36.80, 
//     51.42, 
//     82.53,  
//     23.74, 
//     0,    
//     39.72,  
//     49.92,  
//     34.97, 
//     65.72, 
//     2568.61, 
//     60.94, 
//     43.33,
//     78.83, 
//     74.56, 
//     81.01, 
//     71.82, 
//     3716.74, 
//     4360.32, 
//     2458.86,  
//     1171.96, 
//     0.43, 
//     33.15, 
//     67.04,
//     178.70, 
//     93.38, 
//     59.90, 
//     180.78, 
//     85.64, 
//     107.67, 
//     42.21, 
//     306.10, 
//     149.28 
       
// ]
// export const pro2F1NonCollectable = [
//     7685.27, 
//     7354.11,  
//     7278.27,  
//     8522.51, 
//     6092.24, 
//     8261.06, 
//     6736.73, 
//     8348.06, 
//     6444.82, 
//     9578.01, 
//     14140.05, 
//     8765.11, 
//     8888.29, 
//     7819.41, 
//     10325.87, 
//     8253.88, 
//     6872.59, 
//     6877.88, 
//     10135.90, 
//     7948.13, 
//     8011.29, 
//     9683.87, 
//     9167.31,  
//     7283.70, 
//     13296.53, 
//     13799.51,
//     14431.75, 
//     10201.65, 
//     9404.40, 
//     10533.53, 
//     10876.18, 
//     11656.76, 
//     8191.09, 
//     12428.58, 
//     16275.77 
       
// ]
// export const pro2F1ClaimCount = [
//     143,
//     111,
//     110,
//     140,
//     90,
//     157,
//     132,
//     139,
//     86,
//     157,
//     150,
//     111,
//     131,
//     94,
//     138,
//     91,
//     113,
//     79,
//     127,
//     137,
//     132,
//     151,
//     145,
//     126,
//     130,
//     134,
//     139,
//     109,
//     154,
//     145,
//     146,
//     165,
//     96,
//     158,
//     164
        
// ]
// export const pro2F1PerVisit = [
//     80.25,  
//     110.34, 
//     116.65, 
//     100.96, 
//     121.54, 
//     93.38, 
//     98.85, 
//     115.26, 
//     129.83, 
//     100.64, 
//     145.59,
//     142.85, 
//     121.16, 
//     144.15, 
//     128.16,  
//     168.61, 
//     104.85, 
//     131.77, 
//     121.56, 
//     101.07, 
//     107.50, 
//     120.10, 
//     126.23, 
//     123.69, 
//     145.83, 
//     130.32, 
//     134.69, 
//     123.25, 
//     85.21,  
//     102.51, 
//     108.50, 
//     109.10, 
//     121.99, 
//     119.25, 
//     142.53 
       
// ]
// // pro2 F2
// export const pro2F2Charge = [
//     20281.27,  
//     20151.48,  
//     19007.33, 
//     23953.42,  
//     16103.79,  
//     26278.66, 
//     22156.69, 
//     24461.17, 
//     16778.17,  
//     36833.21, 
//     34311.33,  
//     24303.00, 
//     26208.06,  
//     19547.66,  
//     29282.33,  
//     18894.38,  
//     23961.00, 
//     16828.80, 
//     25401.16, 
//     15403.08,  
//     29584.32, 
//     27985.37, 
//     37526.99,  
//     20912.67, 
//     34828.00, 
//     33541.74, 
//     33168.35, 
//     13903.00, 
//     30474.27, 
//     27346.62,  
//     25367.00, 
//     28022.84, 
//     19079.03,  
//     42602.33,  
//     39032.36 
// ]
// export const pro2F2Payment = [
//     11476.09, 
//     12247.67,  
//     12870.67, 
//     14134.14, 
//     10978.97, 
//     14660.78, 
//     13015.59, 
//     15982.75,  
//     11122.39, 
//     15766.93,  
//     21837.79,  
//     15809.06,  
//     15871.87,  
//     13550.05, 
//     17729.12,  
//     15343.50,  
//     11847.85,  
//     10409.79,  
//     15397.74, 
//     13880.32, 
//     14225.77,  
//     18095.09,  
//     18302.93, 
//     15585.20,  
//     18909.27,  
//     17463.23, 
//     18722.11, 
//     13475.55,  
//     13122.52, 
//     14898.61,  
//     15840.93, 
//     18038.11, 
//     11751.92, 
//     18881.10, 
//     23421.70     
// ]
// export const pro2F2Collectable = [
//     62.45, 
//     80.90,  
//     32.36, 
//     36.80, 
//     51.42, 
//     82.53,  
//     23.74, 
//     0,    
//     39.72,  
//     49.92,  
//     34.97, 
//     65.72, 
//     2568.61, 
//     60.94, 
//     43.33,
//     78.83, 
//     74.56, 
//     81.01, 
//     71.82, 
//     3716.74, 
//     4360.32, 
//     2458.86,  
//     1171.96, 
//     0.43, 
//     33.15, 
//     67.04,
//     178.70, 
//     93.38, 
//     59.90, 
//     180.78, 
//     85.64, 
//     107.67, 
//     42.21, 
//     306.10, 
//     149.28     
// ]
// export const pro2F2NonCollectable = [
//     7685.27, 
//     7354.11,  
//     7278.27,  
//     8522.51, 
//     6092.24, 
//     8261.06, 
//     6736.73, 
//     8348.06, 
//     6444.82, 
//     9578.01, 
//     14140.05, 
//     8765.11, 
//     8888.29, 
//     7819.41, 
//     10325.87, 
//     8253.88, 
//     6872.59, 
//     6877.88, 
//     10135.90, 
//     7948.13, 
//     8011.29, 
//     9683.87, 
//     9167.31,  
//     7283.70, 
//     13296.53, 
//     13799.51,
//     14431.75, 
//     10201.65, 
//     9404.40, 
//     10533.53, 
//     10876.18, 
//     11656.76, 
//     8191.09, 
//     12428.58, 
//     16275.77     
// ]
// export const pro2F2ClaimCount = [
//     143,
//     111,
//     110,
//     140,
//     90,
//     157,
//     132,
//     139,
//     86,
//     157,
//     150,
//     111,
//     131,
//     94,
//     138,
//     91,
//     113,
//     79,
//     127,
//     137,
//     132,
//     151,
//     145,
//     126,
//     130,
//     134,
//     139,
//     109,
//     154,
//     145,
//     146,
//     165,
//     96,
//     158,
//     164    
// ]
// export const pro2F2PerVisit = [
//     80.25,  
//     110.34, 
//     116.65, 
//     100.96, 
//     121.54, 
//     93.38, 
//     98.85, 
//     115.26, 
//     129.83, 
//     100.64, 
//     145.59,
//     142.85, 
//     121.16, 
//     144.15, 
//     128.16,  
//     168.61, 
//     104.85, 
//     131.77, 
//     121.56, 
//     101.07, 
//     107.50, 
//     120.10, 
//     126.23, 
//     123.69, 
//     145.83, 
//     130.32, 
//     134.69, 
//     123.25, 
//     85.21,  
//     102.51, 
//     108.50, 
//     109.10, 
//     121.99, 
//     119.25, 
//     142.53     
// ]
// // pro2 F3
// export const pro2F3Charge = [
//     20281.27,  
//     20151.48,  
//     19007.33, 
//     23953.42,  
//     16103.79,  
//     26278.66, 
//     22156.69, 
//     24461.17, 
//     16778.17,  
//     36833.21, 
//     34311.33,  
//     24303.00, 
//     26208.06,  
//     19547.66,  
//     29282.33,  
//     18894.38,  
//     23961.00, 
//     16828.80, 
//     25401.16, 
//     15403.08,  
//     29584.32, 
//     27985.37, 
//     37526.99,  
//     20912.67, 
//     34828.00, 
//     33541.74, 
//     33168.35, 
//     13903.00, 
//     30474.27, 
//     27346.62,  
//     25367.00, 
//     28022.84, 
//     19079.03,  
//     42602.33,  
//     39032.36 
// ]
// export const pro2F3Payment = [
//     11476.09, 
//     12247.67,  
//     12870.67, 
//     14134.14, 
//     10978.97, 
//     14660.78, 
//     13015.59, 
//     15982.75,  
//     11122.39, 
//     15766.93,  
//     21837.79,  
//     15809.06,  
//     15871.87,  
//     13550.05, 
//     17729.12,  
//     15343.50,  
//     11847.85,  
//     10409.79,  
//     15397.74, 
//     13880.32, 
//     14225.77,  
//     18095.09,  
//     18302.93, 
//     15585.20,  
//     18909.27,  
//     17463.23, 
//     18722.11, 
//     13475.55,  
//     13122.52, 
//     14898.61,  
//     15840.93, 
//     18038.11, 
//     11751.92, 
//     18881.10, 
//     23421.70     
// ]
// export const pro2F3Collectable = [
//     62.45, 
//     80.90,  
//     32.36, 
//     36.80, 
//     51.42, 
//     82.53,  
//     23.74, 
//     0,    
//     39.72,  
//     49.92,  
//     34.97, 
//     65.72, 
//     2568.61, 
//     60.94, 
//     43.33,
//     78.83, 
//     74.56, 
//     81.01, 
//     71.82, 
//     3716.74, 
//     4360.32, 
//     2458.86,  
//     1171.96, 
//     0.43, 
//     33.15, 
//     67.04,
//     178.70, 
//     93.38, 
//     59.90, 
//     180.78, 
//     85.64, 
//     107.67, 
//     42.21, 
//     306.10, 
//     149.28     
// ]
// export const pro2F3NonCollectable = [
//     7685.27, 
//     7354.11,  
//     7278.27,  
//     8522.51, 
//     6092.24, 
//     8261.06, 
//     6736.73, 
//     8348.06, 
//     6444.82, 
//     9578.01, 
//     14140.05, 
//     8765.11, 
//     8888.29, 
//     7819.41, 
//     10325.87, 
//     8253.88, 
//     6872.59, 
//     6877.88, 
//     10135.90, 
//     7948.13, 
//     8011.29, 
//     9683.87, 
//     9167.31,  
//     7283.70, 
//     13296.53, 
//     13799.51,
//     14431.75, 
//     10201.65, 
//     9404.40, 
//     10533.53, 
//     10876.18, 
//     11656.76, 
//     8191.09, 
//     12428.58, 
//     16275.77     
// ]
// export const pro2F3ClaimCount = [
//     143,
//     111,
//     110,
//     140,
//     90,
//     157,
//     132,
//     139,
//     86,
//     157,
//     150,
//     111,
//     131,
//     94,
//     138,
//     91,
//     113,
//     79,
//     127,
//     137,
//     132,
//     151,
//     145,
//     126,
//     130,
//     134,
//     139,
//     109,
//     154,
//     145,
//     146,
//     165,
//     96,
//     158,
//     164      
// ]
// export const pro2F3PerVisit = [
//     80.25,  
//     110.34, 
//     116.65, 
//     100.96, 
//     121.54, 
//     93.38, 
//     98.85, 
//     115.26, 
//     129.83, 
//     100.64, 
//     145.59,
//     142.85, 
//     121.16, 
//     144.15, 
//     128.16,  
//     168.61, 
//     104.85, 
//     131.77, 
//     121.56, 
//     101.07, 
//     107.50, 
//     120.10, 
//     126.23, 
//     123.69, 
//     145.83, 
//     130.32, 
//     134.69, 
//     123.25, 
//     85.21,  
//     102.51, 
//     108.50, 
//     109.10, 
//     121.99, 
//     119.25, 
//     142.53    
// ]
// // pro3 F1
// export const pro3F1Charge = [
//     28504.15, 
//     17935.04, 
//     30846.78, 
//     25335.12, 
//     20102.60, 
//     28659.27, 
//     26020.83,  
//     31189.99,  
//     24227.59, 
//     30514.38, 
//     32351.68, 
//     33463.55, 
//     28479.91, 
//     24022.63, 
//     27676.63,  
//     27930.62,  
//     27078.05, 
//     13033.69, 
//     29478.11, 
//     34070.27, 
//     31629.71, 
//     38785.63,  
//     39738.62, 
//     32246.34, 
//     44097.83, 
//     33770.79, 
//     44845.77, 
//     37857.59, 
//     31472.99, 
//     34744.53, 
//     22897.65,  
//     33010.61,  
//     41183.49, 
//     40227.98,  
//     50843.35 
   
// ]
// export const pro3F1Payment = [
//     13408.34, 
//     16555.39, 
//     17535.27, 
//     16851.20, 
//     12956.47, 
//     13604.85,  
//     16511.15, 
//     18463.18, 
//     13745.75, 
//     16353.12, 
//     19311.36, 
//     19420.32, 
//     14845.97, 
//     18671.00, 
//     18017.52, 
//     16691.44, 
//     17539.15, 
//     10518.37, 
//     16037.79, 
//     23910.83, 
//     16528.86, 
//     22958.90,  
//     24044.02, 
//     23127.22, 
//     22920.06, 
//     19985.26, 
//     24332.90, 
//     21812.03, 
//     18316.14, 
//     20634.74, 
//     15553.18,  
//     20592.95, 
//     17791.90, 
//     20628.08, 
//     29553.99 
       
// ]
// export const pro3F1Collectable = [
//     0.29, 
//     40.59, 
//     154.88, 
//     11.67, 
//     47.41, 
//     25.81,  
//     57.31, 
//     74.67, 
//     38.06, 
//     36.87, 
//     67.33,   
//     95.11, 
//     1442.53, 
//     122.91, 
//     35.99, 
//     53.33,  
//     42.85, 
//     179.36, 
//     107.35 ,
//     3314.11, 
//     2569.47,  
//     2468.02,
//     975.19, 
//     57.62, 
//     48.32, 
//     50.00, 
//     30.08,  
//     72.22, 
//     65.20, 
//     184.33, 
//     478.96, 
//     162.55, 
//     167.50, 
//     141.70,  
//     81.03 
       
// ]
// export const pro3F1NonCollectable = [
//     8697.09,  
//     9259.63,  
//     9513.58, 
//     10642.74, 
//     7969.70, 
//     8080.94,  
//     9090.37,  
//     10228.71, 
//     7543.98, 
//     10399.00, 
//     12122.78, 
//     12449.20, 
//     8895.14, 
//     10067.39, 
//     10315.47, 
//     8497.35, 
//     12199.71, 
//     5847.13,  
//     9380.70,  
//     12575.10, 
//     8549.81, 
//     11590.86, 
//     12403.19,  
//     10787.13,  
//     14650.54,  
//     15902.69,  
//     18411.41, 
//     16329.40,  
//     13173.83, 
//     14128.73,  
//     10863.24,  
//     13930.50, 
//     11660.14,  
//     14932.49, 
//     21924.02 
       
// ]
// export const pro3F1ClaimCount = [
//     204,
//     113,
//     185,
//     163,
//     127,
//     178,
//     147,
//     157,
//     118,
//     158,
//     150,
//     171,
//     144,
//     113,
//     135,
//     127,
//     130,
//     67,
//     124,
//     129,
//     132,
//     137,
//     140,
//     144,
//     146,
//     139,
//     156,
//     133,
//     174,
//     170,
//     143,
//     184,
//     154,
//     166,
//     199
        
// ]
// export const pro3F1PerVisit = [
//     65.62, 
//     146.51, 
//     94.79, 
//     103.17, 
//     101.75, 
//     76.29, 
//     112.07, 
//     117.60, 
//     116.16,  
//     103.50, 
//     129.03, 
//     113.79, 
//     103.34, 
//     164.74, 
//     133.79,  
//     131.08, 
//     134.57, 
//     156.21, 
//     129.34, 
//     185.84, 
//     124.90, 
//     167.18, 
//     171.74, 
//     160.61, 
//     156.63, 
//     143.78, 
//     156.31, 
//     164.00, 
//     105.47,  
//     121.14, 
//     108.51, 
//     111.72, 
//     115.28, 
//     124.02, 
//     148.26 
      
// ]
// // pro3 F2
// export const pro3F2Charge = [
//     28504.15, 
//     17935.04, 
//     30846.78, 
//     25335.12, 
//     20102.60, 
//     28659.27, 
//     26020.83,  
//     31189.99,  
//     24227.59, 
//     30514.38, 
//     32351.68, 
//     33463.55, 
//     28479.91, 
//     24022.63, 
//     27676.63,  
//     27930.62,  
//     27078.05, 
//     13033.69, 
//     29478.11, 
//     34070.27, 
//     31629.71, 
//     38785.63,  
//     39738.62, 
//     32246.34, 
//     44097.83, 
//     33770.79, 
//     44845.77, 
//     37857.59, 
//     31472.99, 
//     34744.53, 
//     22897.65,  
//     33010.61,  
//     41183.49, 
//     40227.98,  
//     50843.35 
// ]
// export const pro3F2Payment = [
//     13408.34, 
//     16555.39, 
//     17535.27, 
//     16851.20, 
//     12956.47, 
//     13604.85,  
//     16511.15, 
//     18463.18, 
//     13745.75, 
//     16353.12, 
//     19311.36, 
//     19420.32, 
//     14845.97, 
//     18671.00, 
//     18017.52, 
//     16691.44, 
//     17539.15, 
//     10518.37, 
//     16037.79, 
//     23910.83, 
//     16528.86, 
//     22958.90,  
//     24044.02, 
//     23127.22, 
//     22920.06, 
//     19985.26, 
//     24332.90, 
//     21812.03, 
//     18316.14, 
//     20634.74, 
//     15553.18,  
//     20592.95, 
//     17791.90, 
//     20628.08, 
//     29553.99     
// ]
// export const pro3F2Collectable = [
//     0.29, 
//     40.59, 
//     154.88, 
//     11.67, 
//     47.41, 
//     25.81,  
//     57.31, 
//     74.67, 
//     38.06, 
//     36.87, 
//     67.33,   
//     95.11, 
//     1442.53, 
//     122.91, 
//     35.99, 
//     53.33,  
//     42.85, 
//     179.36, 
//     107.35 ,
//     3314.11, 
//     2569.47,  
//     2468.02,
//     975.19, 
//     57.62, 
//     48.32, 
//     50.00, 
//     30.08,  
//     72.22, 
//     65.20, 
//     184.33, 
//     478.96, 
//     162.55, 
//     167.50, 
//     141.70,  
//     81.03    
// ]
// export const pro3F2NonCollectable = [
//     8697.09,  
//     9259.63,  
//     9513.58, 
//     10642.74, 
//     7969.70, 
//     8080.94,  
//     9090.37,  
//     10228.71, 
//     7543.98, 
//     10399.00, 
//     12122.78, 
//     12449.20, 
//     8895.14, 
//     10067.39, 
//     10315.47, 
//     8497.35, 
//     12199.71, 
//     5847.13,  
//     9380.70,  
//     12575.10, 
//     8549.81, 
//     11590.86, 
//     12403.19,  
//     10787.13,  
//     14650.54,  
//     15902.69,  
//     18411.41, 
//     16329.40,  
//     13173.83, 
//     14128.73,  
//     10863.24,  
//     13930.50, 
//     11660.14,  
//     14932.49, 
//     21924.02     
// ]
// export const pro3F2ClaimCount = [
//     204,
//     113,
//     185,
//     163,
//     127,
//     178,
//     147,
//     157,
//     118,
//     158,
//     150,
//     171,
//     144,
//     113,
//     135,
//     127,
//     130,
//     67,
//     124,
//     129,
//     132,
//     137,
//     140,
//     144,
//     146,
//     139,
//     156,
//     133,
//     174,
//     170,
//     143,
//     184,
//     154,
//     166,
//     199   
// ]
// export const pro3F2PerVisit = [
//     65.62, 
//     146.51, 
//     94.79, 
//     103.17, 
//     101.75, 
//     76.29, 
//     112.07, 
//     117.60, 
//     116.16,  
//     103.50, 
//     129.03, 
//     113.79, 
//     103.34, 
//     164.74, 
//     133.79,  
//     131.08, 
//     134.57, 
//     156.21, 
//     129.34, 
//     185.84, 
//     124.90, 
//     167.18, 
//     171.74, 
//     160.61, 
//     156.63, 
//     143.78, 
//     156.31, 
//     164.00, 
//     105.47,  
//     121.14, 
//     108.51, 
//     111.72, 
//     115.28, 
//     124.02, 
//     148.26     
// ]
// // pro3 F3
// export const pro3F3Charge = [
//     28504.15, 
//     17935.04, 
//     30846.78, 
//     25335.12, 
//     20102.60, 
//     28659.27, 
//     26020.83,  
//     31189.99,  
//     24227.59, 
//     30514.38, 
//     32351.68, 
//     33463.55, 
//     28479.91, 
//     24022.63, 
//     27676.63,  
//     27930.62,  
//     27078.05, 
//     13033.69, 
//     29478.11, 
//     34070.27, 
//     31629.71, 
//     38785.63,  
//     39738.62, 
//     32246.34, 
//     44097.83, 
//     33770.79, 
//     44845.77, 
//     37857.59, 
//     31472.99, 
//     34744.53, 
//     22897.65,  
//     33010.61,  
//     41183.49, 
//     40227.98,  
//     50843.35 
// ]
// export const pro3F3Payment = [
//     13408.34, 
//     16555.39, 
//     17535.27, 
//     16851.20, 
//     12956.47, 
//     13604.85,  
//     16511.15, 
//     18463.18, 
//     13745.75, 
//     16353.12, 
//     19311.36, 
//     19420.32, 
//     14845.97, 
//     18671.00, 
//     18017.52, 
//     16691.44, 
//     17539.15, 
//     10518.37, 
//     16037.79, 
//     23910.83, 
//     16528.86, 
//     22958.90,  
//     24044.02, 
//     23127.22, 
//     22920.06, 
//     19985.26, 
//     24332.90, 
//     21812.03, 
//     18316.14, 
//     20634.74, 
//     15553.18,  
//     20592.95, 
//     17791.90, 
//     20628.08, 
//     29553.99       
// ]
// export const pro3F3Collectable = [
//     0.29, 
//     40.59, 
//     154.88, 
//     11.67, 
//     47.41, 
//     25.81,  
//     57.31, 
//     74.67, 
//     38.06, 
//     36.87, 
//     67.33,   
//     95.11, 
//     1442.53, 
//     122.91, 
//     35.99, 
//     53.33,  
//     42.85, 
//     179.36, 
//     107.35 ,
//     3314.11, 
//     2569.47,  
//     2468.02,
//     975.19, 
//     57.62, 
//     48.32, 
//     50.00, 
//     30.08,  
//     72.22, 
//     65.20, 
//     184.33, 
//     478.96, 
//     162.55, 
//     167.50, 
//     141.70,  
//     81.03        
// ]
// export const pro3F3NonCollectable = [
//     8697.09,  
//     9259.63,  
//     9513.58, 
//     10642.74, 
//     7969.70, 
//     8080.94,  
//     9090.37,  
//     10228.71, 
//     7543.98, 
//     10399.00, 
//     12122.78, 
//     12449.20, 
//     8895.14, 
//     10067.39, 
//     10315.47, 
//     8497.35, 
//     12199.71, 
//     5847.13,  
//     9380.70,  
//     12575.10, 
//     8549.81, 
//     11590.86, 
//     12403.19,  
//     10787.13,  
//     14650.54,  
//     15902.69,  
//     18411.41, 
//     16329.40,  
//     13173.83, 
//     14128.73,  
//     10863.24,  
//     13930.50, 
//     11660.14,  
//     14932.49, 
//     21924.02       
// ]
// export const pro3F3ClaimCount = [
//     204,
//     113,
//     185,
//     163,
//     127,
//     178,
//     147,
//     157,
//     118,
//     158,
//     150,
//     171,
//     144,
//     113,
//     135,
//     127,
//     130,
//     67,
//     124,
//     129,
//     132,
//     137,
//     140,
//     144,
//     146,
//     139,
//     156,
//     133,
//     174,
//     170,
//     143,
//     184,
//     154,
//     166,
//     199       
// ]
// export const pro3F3PerVisit = [
//     65.62, 
//     146.51, 
//     94.79, 
//     103.17, 
//     101.75, 
//     76.29, 
//     112.07, 
//     117.60, 
//     116.16,  
//     103.50, 
//     129.03, 
//     113.79, 
//     103.34, 
//     164.74, 
//     133.79,  
//     131.08, 
//     134.57, 
//     156.21, 
//     129.34, 
//     185.84, 
//     124.90, 
//     167.18, 
//     171.74, 
//     160.61, 
//     156.63, 
//     143.78, 
//     156.31, 
//     164.00, 
//     105.47,  
//     121.14, 
//     108.51, 
//     111.72, 
//     115.28, 
//     124.02, 
//     148.26      
// ]
// // pro4 F1
// export const pro4F1Charge = [
//     19143.69,  
//     19582.85, 
//     25216.74,  
//     23456.09,  
//     22376.42,  
//     20601.33, 
//     25856.57,  
//     22655.16,  
//     29797.83,  
//     32202.00, 
//     30919.07,  
//     23201.42, 
//     23139.82,  
//     23812.50,  
//     26712.49,  
//     25488.79,  
//     31883.16,  
//     12234.90, 
//     21771.00,  
//     29083.65, 
//     34069.66, 
//     39738.00,  
//     33045.50, 
//     25999.67, 
//     33042.89, 
//     31689.54,  
//     30104.48, 
//     31495.49,  
//     31018.73,  
//     24403.51, 
//     29259.65, 
//     25181.50, 
//     36101.91, 
//     43490.49, 
//     42212.39  
   
// ]
// export const pro4F1Payment = [
//     9483.16, 
//     12625.26, 
//     16091.19, 
//     14181.37,  
//     13424.87, 
//     13286.97, 
//     12463.84,  
//     13559.92,  
//     16237.45,  
//     19985.86,  
//     20739.99, 
//     15737.25, 
//     12310.29, 
//     11534.47, 
//     20318.15,  
//     15005.18, 
//     16447.50, 
//     11552.62,  
//     15002.04, 
//     18181.96,  
//     19335.48,  
//     20940.92, 
//     24780.46, 
//     20245.09,  
//     17448.04, 
//     17119.40, 
//     18659.39,  
//     16845.87, 
//     18751.92, 
//     15954.23, 
//     16442.26, 
//     17508.75,  
//     16504.95, 
//     22077.96,  
//     25054.40 
       
// ]
// export const pro4F1Collectable = [
//     0.34, 
//     32.84,  
//     130.58, 
//     35.95, 
//     19.44, 
//     9.23, 
//     98.43,  
//     0.68, 
//     58.33, 
//     70.99, 
//     79.93, 
//     37.18,
//     939.22, 
//     21.55, 
//     86.68, 
//     20.00, 
//     6.67, 
//     40.00, 
//     100.79, 
//     1791.72, 
//     3640.79, 
//     3605.32, 
//     1051.78, 
//     102.18, 
//     3.33,  
//     0,   
//     16.67, 
//     96.00, 
//     85.88, 
//     45.96, 
//     98.82, 
//     50.91, 
//     362.03, 
//     30.50, 
//     52.94 
       
// ]
// export const pro4F1NonCollectable = [
//     6497.13,  
//     7428.90, 
//     8911.55, 
//     8440.02, 
//     7962.06,  
//     8244.13, 
//     7010.76,  
//     7408.79, 
//     9603.04, 
//     11364.88, 
//     12803.44, 
//     9205.40, 
//     7341.80,  
//     7300.69, 
//     12158.36, 
//     8082.88, 
//     10193.93, 
//     6088.33, 
//     8019.87, 
//     9944.89, 
//     10318.19, 
//     10844.32, 
//     11417.83, 
//     8769.57, 
//     11568.14, 
//     12374.24, 
//     13685.08, 
//     11052.91, 
//     11940.81, 
//     10811.11, 
//     10678.24, 
//     10546.93, 
//     10686.21, 
//     14694.06,  
//     18822.54 
      
// ]
// export const pro4F1ClaimCount = [
//     148,
//     118,
//     160,
//     137,
//     141,
//     134,
//     141,
//     123,
//     145,
//     162,
//     151,
//     116,
//     125,
//     117,
//     136,
//     122,
//     151,
//     71,
//     125,
//     141,
//     151,
//     136,
//     164,
//     146,
//     129,
//     123,
//     121,
//     119,
//     163,
//     139,
//     149,
//     153,
//     156,
//     177,
//     165
        
// ]
// export const pro4F1PerVisit = [
//     64.22, 
//     106.69, 
//     100.78, 
//     103.26, 
//     95.44, 
//     99.16, 
//     88.61, 
//     109.95, 
//     111.73, 
//     123.62, 
//     137.65, 
//     136.06, 
//     98.22, 
//     98.31, 
//     149.03, 
//     123.33,  
//     108.92, 
//     163.48, 
//     119.70, 
//     128.95, 
//     128.05, 
//     154.36, 
//     151.10, 
//     138.67, 
//     135.26, 
//     139.56, 
//     154.64, 
//     141.17, 
//     115.28, 
//     115.05, 
//     110.10, 
//     114.69, 
//     105.58, 
//     124.50, 
//     152.15 
       
// ]
// // pro4 F2
// export const pro4F2Charge = [
//     19143.69,  
//     19582.85, 
//     25216.74,  
//     23456.09,  
//     22376.42,  
//     20601.33, 
//     25856.57,  
//     22655.16,  
//     29797.83,  
//     32202.00, 
//     30919.07,  
//     23201.42, 
//     23139.82,  
//     23812.50,  
//     26712.49,  
//     25488.79,  
//     31883.16,  
//     12234.90, 
//     21771.00,  
//     29083.65, 
//     34069.66, 
//     39738.00,  
//     33045.50, 
//     25999.67, 
//     33042.89, 
//     31689.54,  
//     30104.48, 
//     31495.49,  
//     31018.73,  
//     24403.51, 
//     29259.65, 
//     25181.50, 
//     36101.91, 
//     43490.49, 
//     42212.39 
// ]
// export const pro4F2Payment = [
//     9483.16, 
//     12625.26, 
//     16091.19, 
//     14181.37,  
//     13424.87, 
//     13286.97, 
//     12463.84,  
//     13559.92,  
//     16237.45,  
//     19985.86,  
//     20739.99, 
//     15737.25, 
//     12310.29, 
//     11534.47, 
//     20318.15,  
//     15005.18, 
//     16447.50, 
//     11552.62,  
//     15002.04, 
//     18181.96,  
//     19335.48,  
//     20940.92, 
//     24780.46, 
//     20245.09,  
//     17448.04, 
//     17119.40, 
//     18659.39,  
//     16845.87, 
//     18751.92, 
//     15954.23, 
//     16442.26, 
//     17508.75,  
//     16504.95, 
//     22077.96,  
//     25054.40     
// ]
// export const pro4F2Collectable = [
//     0.34, 
//     32.84,  
//     130.58, 
//     35.95, 
//     19.44, 
//     9.23, 
//     98.43,  
//     0.68, 
//     58.33, 
//     70.99, 
//     79.93, 
//     37.18,
//     939.22, 
//     21.55, 
//     86.68, 
//     20.00, 
//     6.67, 
//     40.00, 
//     100.79, 
//     1791.72, 
//     3640.79, 
//     3605.32, 
//     1051.78, 
//     102.18, 
//     3.33,  
//     0,   
//     16.67, 
//     96.00, 
//     85.88, 
//     45.96, 
//     98.82, 
//     50.91, 
//     362.03, 
//     30.50, 
//     52.94    
// ]
// export const pro4F2NonCollectable = [
//     6497.13,  
//     7428.90, 
//     8911.55, 
//     8440.02, 
//     7962.06,  
//     8244.13, 
//     7010.76,  
//     7408.79, 
//     9603.04, 
//     11364.88, 
//     12803.44, 
//     9205.40, 
//     7341.80,  
//     7300.69, 
//     12158.36, 
//     8082.88, 
//     10193.93, 
//     6088.33, 
//     8019.87, 
//     9944.89, 
//     10318.19, 
//     10844.32, 
//     11417.83, 
//     8769.57, 
//     11568.14, 
//     12374.24, 
//     13685.08, 
//     11052.91, 
//     11940.81, 
//     10811.11, 
//     10678.24, 
//     10546.93, 
//     10686.21, 
//     14694.06,  
//     18822.54     
// ]
// export const pro4F2ClaimCount = [
//     148,
//     118,
//     160,
//     137,
//     141,
//     134,
//     141,
//     123,
//     145,
//     162,
//     151,
//     116,
//     125,
//     117,
//     136,
//     122,
//     151,
//     71,
//     125,
//     141,
//     151,
//     136,
//     164,
//     146,
//     129,
//     123,
//     121,
//     119,
//     163,
//     139,
//     149,
//     153,
//     156,
//     177,
//     165    
// ]
// export const pro4F2PerVisit = [
//     64.22, 
//     106.69, 
//     100.78, 
//     103.26, 
//     95.44, 
//     99.16, 
//     88.61, 
//     109.95, 
//     111.73, 
//     123.62, 
//     137.65, 
//     136.06, 
//     98.22, 
//     98.31, 
//     149.03, 
//     123.33,  
//     108.92, 
//     163.48, 
//     119.70, 
//     128.95, 
//     128.05, 
//     154.36, 
//     151.10, 
//     138.67, 
//     135.26, 
//     139.56, 
//     154.64, 
//     141.17, 
//     115.28, 
//     115.05, 
//     110.10, 
//     114.69, 
//     105.58, 
//     124.50, 
//     152.15     
// ]
// // pro4 F3
// export const pro4F3Charge = [
//     19143.69,  
//     19582.85, 
//     25216.74,  
//     23456.09,  
//     22376.42,  
//     20601.33, 
//     25856.57,  
//     22655.16,  
//     29797.83,  
//     32202.00, 
//     30919.07,  
//     23201.42, 
//     23139.82,  
//     23812.50,  
//     26712.49,  
//     25488.79,  
//     31883.16,  
//     12234.90, 
//     21771.00,  
//     29083.65, 
//     34069.66, 
//     39738.00,  
//     33045.50, 
//     25999.67, 
//     33042.89, 
//     31689.54,  
//     30104.48, 
//     31495.49,  
//     31018.73,  
//     24403.51, 
//     29259.65, 
//     25181.50, 
//     36101.91, 
//     43490.49, 
//     42212.39 
// ]
// export const pro4F3Payment = [
//     9483.16, 
//     12625.26, 
//     16091.19, 
//     14181.37,  
//     13424.87, 
//     13286.97, 
//     12463.84,  
//     13559.92,  
//     16237.45,  
//     19985.86,  
//     20739.99, 
//     15737.25, 
//     12310.29, 
//     11534.47, 
//     20318.15,  
//     15005.18, 
//     16447.50, 
//     11552.62,  
//     15002.04, 
//     18181.96,  
//     19335.48,  
//     20940.92, 
//     24780.46, 
//     20245.09,  
//     17448.04, 
//     17119.40, 
//     18659.39,  
//     16845.87, 
//     18751.92, 
//     15954.23, 
//     16442.26, 
//     17508.75,  
//     16504.95, 
//     22077.96,  
//     25054.40       
// ]
// export const pro4F3Collectable = [
//     0.34, 
//     32.84,  
//     130.58, 
//     35.95, 
//     19.44, 
//     9.23, 
//     98.43,  
//     0.68, 
//     58.33, 
//     70.99, 
//     79.93, 
//     37.18,
//     939.22, 
//     21.55, 
//     86.68, 
//     20.00, 
//     6.67, 
//     40.00, 
//     100.79, 
//     1791.72, 
//     3640.79, 
//     3605.32, 
//     1051.78, 
//     102.18, 
//     3.33,  
//     0,   
//     16.67, 
//     96.00, 
//     85.88, 
//     45.96, 
//     98.82, 
//     50.91, 
//     362.03, 
//     30.50, 
//     52.94       
// ]
// export const pro4F3NonCollectable = [
//     6497.13,  
//     7428.90, 
//     8911.55, 
//     8440.02, 
//     7962.06,  
//     8244.13, 
//     7010.76,  
//     7408.79, 
//     9603.04, 
//     11364.88, 
//     12803.44, 
//     9205.40, 
//     7341.80,  
//     7300.69, 
//     12158.36, 
//     8082.88, 
//     10193.93, 
//     6088.33, 
//     8019.87, 
//     9944.89, 
//     10318.19, 
//     10844.32, 
//     11417.83, 
//     8769.57, 
//     11568.14, 
//     12374.24, 
//     13685.08, 
//     11052.91, 
//     11940.81, 
//     10811.11, 
//     10678.24, 
//     10546.93, 
//     10686.21, 
//     14694.06,  
//     18822.54        
// ]
// export const pro4F3ClaimCount = [
//     148,
//     118,
//     160,
//     137,
//     141,
//     134,
//     141,
//     123,
//     145,
//     162,
//     151,
//     116,
//     125,
//     117,
//     136,
//     122,
//     151,
//     71,
//     125,
//     141,
//     151,
//     136,
//     164,
//     146,
//     129,
//     123,
//     121,
//     119,
//     163,
//     139,
//     149,
//     153,
//     156,
//     177,
//     165     
// ]
// export const pro4F3PerVisit = [
//     64.22, 
//     106.69, 
//     100.78, 
//     103.26, 
//     95.44, 
//     99.16, 
//     88.61, 
//     109.95, 
//     111.73, 
//     123.62, 
//     137.65, 
//     136.06, 
//     98.22, 
//     98.31, 
//     149.03, 
//     123.33,  
//     108.92, 
//     163.48, 
//     119.70, 
//     128.95, 
//     128.05, 
//     154.36, 
//     151.10, 
//     138.67, 
//     135.26, 
//     139.56, 
//     154.64, 
//     141.17, 
//     115.28, 
//     115.05, 
//     110.10, 
//     114.69, 
//     105.58, 
//     124.50, 
//     152.15       
// ]
// // pro5 F1
// export const pro5F1Charge = [
//     18640.16,  
//     12013.56, 
//     20431.90, 
//     22310.38, 
//     17600.15, 
//     23582.48, 
//     23733.65, 
//     33536.88, 
//     23835.65, 
//     24913.63, 
//     22043.31,  
//     20790.66,  
//     23707.87,  
//     21220.13,  
//     22330.16, 
//     21249.16,  
//     22196.00,  
//     15141.37, 
//     24289.99, 
//     32150.55, 
//     23703.33, 
//     25748.71, 
//     24737.11,  
//     26417.46,  
//     30653.36,  
//     3016.15, 
//     830.83, 
//     969.35,  
//     24254.33, 
//     27793.23,  
//     23089.67,  
//     21549.90, 
//     21260.02, 
//     30371.16, 
//     21939.19  
   
// ]
// export const pro5F1Payment = [
//     9272.49, 
//     10403.17,  
//     10356.72,  
//     12170.86, 
//     10848.45, 
//     13103.80, 
//     12116.10, 
//     17716.64,  
//     14358.18, 
//     15814.05, 
//     14519.71,  
//     13030.22, 
//     11833.87, 
//     13236.45, 
//     14181.23, 
//     14721.30,  
//     15254.43, 
//     8388.32, 
//     16544.47, 
//     19559.04, 
//     15520.91,  
//     18472.25,  
//     14051.42,  
//     16593.42,  
//     17307.82,  
//     9595.66, 
//     2724.51,  
//     2374.97,  
//     6088.69, 
//     15057.20, 
//     13870.92, 
//     14485.90, 
//     11592.28, 
//     17744.00, 
//     14051.39 
       
// ]
// export const pro5F1Collectable = [
//     3.52, 
//     3.51,  
//     75.90, 
//     15.98,
//     93.99,  
//     123.90,  
//     56.75, 
//     0.75, 
//     0,    
//     6.12,  
//     31.56, 
//     1.01, 
//     1939.69,  
//     8.33, 
//     36.87,  
//     20.00,  
//     69.88, 
//     37.80, 
//     43.01, 
//     2959.26, 
//     3683.19,  
//     3012.73, 
//     600.33, 
//     3.35, 
//     17.56, 
//     16.67, 
//     0,    
//     0,     
//     58.69, 
//     78.14, 
//     86.59, 
//     68.45, 
//     26.43, 
//     50.51, 
//     30.69 
       
// ]
// export const pro5F1NonCollectable = [
//     6042.68, 
//     5875.19, 
//     6364.55, 
//     9148.24, 
//     5852.78, 
//     7213.03, 
//     8909.31, 
//     9758.01, 
//     7637.07, 
//     10055.80,  
//     9410.54, 
//     5317.40, 
//     7036.15,  
//     7856.08, 
//     8365.14, 
//     8111.48, 
//     8873.95, 
//     5022.69, 
//     10340.68, 
//     11298.89, 
//     8477.90, 
//     9366.15, 
//     6699.44, 
//     7921.60, 
//     10786.41, 
//     7041.47, 
//     1745.98, 
//     790.62, 
//     4517.42,  
//     10072.38, 
//     9356.70, 
//     9487.01, 
//     6419.76, 
//     11251.07, 
//     10233.53 

      
// ]
// export const pro5F1ClaimCount = [
//     155,
//     76,
//     134,
//     132,
//     116,
//     156,
//     133,
//     162,
//     118,
//     130,
//     120,
//     119,
//     131,
//     113,
//     120,
//     107,
//     116,
//     76,
//     120,
//     123,
//     132,
//     123,
//     114,
//     125,
//     93,
//     31,
//     0,
//     0,
//     138,
//     147,
//     124,
//     129,
//     115,
//     127,
//     95
        
// ]
// export const pro5F1PerVisit = [
//     59.95, 
//     137.49,  
//     77.48, 
//     91.97, 
//     93.25, 
//     84.18, 
//     90.87,
//     109.14, 
//     121.68, 
//     121.34, 
//     120.66, 
//     109.81, 
//     90.33, 
//     117.14,  
//     118.18, 
//     137.58, 
//     131.88,  
//     110.37, 
//     137.87, 
//     159.02, 
//     117.29, 
//     150.59, 
//     123.26, 
//     132.75, 
//     186.78, 
//     312.90, 
//     0,     
//     0,     
//     44.12, 
//     102.66, 
//     112.16, 
//     112.29, 
//     100.51, 
//     140.08, 
//     147.91 
      
// ]
// // pro5 F2
// export const pro5F2Charge = [
//     18640.16,  
//     12013.56, 
//     20431.90, 
//     22310.38, 
//     17600.15, 
//     23582.48, 
//     23733.65, 
//     33536.88, 
//     23835.65, 
//     24913.63, 
//     22043.31,  
//     20790.66,  
//     23707.87,  
//     21220.13,  
//     22330.16, 
//     21249.16,  
//     22196.00,  
//     15141.37, 
//     24289.99, 
//     32150.55, 
//     23703.33, 
//     25748.71, 
//     24737.11,  
//     26417.46,  
//     30653.36,  
//     3016.15, 
//     830.83, 
//     969.35,  
//     24254.33, 
//     27793.23,  
//     23089.67,  
//     21549.90, 
//     21260.02, 
//     30371.16, 
//     21939.19  
// ]
// export const pro5F2Payment = [
//     9272.49, 
//     10403.17,  
//     10356.72,  
//     12170.86, 
//     10848.45, 
//     13103.80, 
//     12116.10, 
//     17716.64,  
//     14358.18, 
//     15814.05, 
//     14519.71,  
//     13030.22, 
//     11833.87, 
//     13236.45, 
//     14181.23, 
//     14721.30,  
//     15254.43, 
//     8388.32, 
//     16544.47, 
//     19559.04, 
//     15520.91,  
//     18472.25,  
//     14051.42,  
//     16593.42,  
//     17307.82,  
//     9595.66, 
//     2724.51,  
//     2374.97,  
//     6088.69, 
//     15057.20, 
//     13870.92, 
//     14485.90, 
//     11592.28, 
//     17744.00, 
//     14051.39     
// ]
// export const pro5F2Collectable = [
//     3.52, 
//     3.51,  
//     75.90, 
//     15.98,
//     93.99,  
//     123.90,  
//     56.75, 
//     0.75, 
//     0,    
//     6.12,  
//     31.56, 
//     1.01, 
//     1939.69,  
//     8.33, 
//     36.87,  
//     20.00,  
//     69.88, 
//     37.80, 
//     43.01, 
//     2959.26, 
//     3683.19,  
//     3012.73, 
//     600.33, 
//     3.35, 
//     17.56, 
//     16.67, 
//     0,    
//     0,     
//     58.69, 
//     78.14, 
//     86.59, 
//     68.45, 
//     26.43, 
//     50.51, 
//     30.69     
// ]
// export const pro5F2NonCollectable = [
//     6042.68, 
//     5875.19, 
//     6364.55, 
//     9148.24, 
//     5852.78, 
//     7213.03, 
//     8909.31, 
//     9758.01, 
//     7637.07, 
//     10055.80,  
//     9410.54, 
//     5317.40, 
//     7036.15,  
//     7856.08, 
//     8365.14, 
//     8111.48, 
//     8873.95, 
//     5022.69, 
//     10340.68, 
//     11298.89, 
//     8477.90, 
//     9366.15, 
//     6699.44, 
//     7921.60, 
//     10786.41, 
//     7041.47, 
//     1745.98, 
//     790.62, 
//     4517.42,  
//     10072.38, 
//     9356.70, 
//     9487.01, 
//     6419.76, 
//     11251.07, 
//     10233.53  
// ]
// export const pro5F2ClaimCount = [
//     155,
//     76,
//     134,
//     132,
//     116,
//     156,
//     133,
//     162,
//     118,
//     130,
//     120,
//     119,
//     131,
//     113,
//     120,
//     107,
//     116,
//     76,
//     120,
//     123,
//     132,
//     123,
//     114,
//     125,
//     93,
//     31,
//     0,
//     0,
//     138,
//     147,
//     124,
//     129,
//     115,
//     127,
//     95    
// ]
// export const pro5F2PerVisit = [
//     59.95, 
//     137.49,  
//     77.48, 
//     91.97, 
//     93.25, 
//     84.18, 
//     90.87,
//     109.14, 
//     121.68, 
//     121.34, 
//     120.66, 
//     109.81, 
//     90.33, 
//     117.14,  
//     118.18, 
//     137.58, 
//     131.88,  
//     110.37, 
//     137.87, 
//     159.02, 
//     117.29, 
//     150.59, 
//     123.26, 
//     132.75, 
//     186.78, 
//     312.90, 
//     0,     
//     0,     
//     44.12, 
//     102.66, 
//     112.16, 
//     112.29, 
//     100.51, 
//     140.08, 
//     147.91    
// ]
// // pro5 F3
// export const pro5F3Charge = [
//     18640.16,  
//     12013.56, 
//     20431.90, 
//     22310.38, 
//     17600.15, 
//     23582.48, 
//     23733.65, 
//     33536.88, 
//     23835.65, 
//     24913.63, 
//     22043.31,  
//     20790.66,  
//     23707.87,  
//     21220.13,  
//     22330.16, 
//     21249.16,  
//     22196.00,  
//     15141.37, 
//     24289.99, 
//     32150.55, 
//     23703.33, 
//     25748.71, 
//     24737.11,  
//     26417.46,  
//     30653.36,  
//     3016.15, 
//     830.83, 
//     969.35,  
//     24254.33, 
//     27793.23,  
//     23089.67,  
//     21549.90, 
//     21260.02, 
//     30371.16, 
//     21939.19  
// ]
// export const pro5F3Payment = [
//     9272.49, 
//     10403.17,  
//     10356.72,  
//     12170.86, 
//     10848.45, 
//     13103.80, 
//     12116.10, 
//     17716.64,  
//     14358.18, 
//     15814.05, 
//     14519.71,  
//     13030.22, 
//     11833.87, 
//     13236.45, 
//     14181.23, 
//     14721.30,  
//     15254.43, 
//     8388.32, 
//     16544.47, 
//     19559.04, 
//     15520.91,  
//     18472.25,  
//     14051.42,  
//     16593.42,  
//     17307.82,  
//     9595.66, 
//     2724.51,  
//     2374.97,  
//     6088.69, 
//     15057.20, 
//     13870.92, 
//     14485.90, 
//     11592.28, 
//     17744.00, 
//     14051.39    
// ]
// export const pro5F3Collectable = [
//     3.52, 
//     3.51,  
//     75.90, 
//     15.98,
//     93.99,  
//     123.90,  
//     56.75, 
//     0.75, 
//     0,    
//     6.12,  
//     31.56, 
//     1.01, 
//     1939.69,  
//     8.33, 
//     36.87,  
//     20.00,  
//     69.88, 
//     37.80, 
//     43.01, 
//     2959.26, 
//     3683.19,  
//     3012.73, 
//     600.33, 
//     3.35, 
//     17.56, 
//     16.67, 
//     0,    
//     0,     
//     58.69, 
//     78.14, 
//     86.59, 
//     68.45, 
//     26.43, 
//     50.51, 
//     30.69     
// ]
// export const pro5F3NonCollectable = [
//     6042.68, 
//     5875.19, 
//     6364.55, 
//     9148.24, 
//     5852.78, 
//     7213.03, 
//     8909.31, 
//     9758.01, 
//     7637.07, 
//     10055.80,  
//     9410.54, 
//     5317.40, 
//     7036.15,  
//     7856.08, 
//     8365.14, 
//     8111.48, 
//     8873.95, 
//     5022.69, 
//     10340.68, 
//     11298.89, 
//     8477.90, 
//     9366.15, 
//     6699.44, 
//     7921.60, 
//     10786.41, 
//     7041.47, 
//     1745.98, 
//     790.62, 
//     4517.42,  
//     10072.38, 
//     9356.70, 
//     9487.01, 
//     6419.76, 
//     11251.07, 
//     10233.53     
// ]
// export const pro5F3ClaimCount = [
//     155,
//     76,
//     134,
//     132,
//     116,
//     156,
//     133,
//     162,
//     118,
//     130,
//     120,
//     119,
//     131,
//     113,
//     120,
//     107,
//     116,
//     76,
//     120,
//     123,
//     132,
//     123,
//     114,
//     125,
//     93,
//     31,
//     0,
//     0,
//     138,
//     147,
//     124,
//     129,
//     115,
//     127,
//     95    
// ]
// export const pro5F3PerVisit = [
//     59.95, 
//     137.49,  
//     77.48, 
//     91.97, 
//     93.25, 
//     84.18, 
//     90.87,
//     109.14, 
//     121.68, 
//     121.34, 
//     120.66, 
//     109.81, 
//     90.33, 
//     117.14,  
//     118.18, 
//     137.58, 
//     131.88,  
//     110.37, 
//     137.87, 
//     159.02, 
//     117.29, 
//     150.59, 
//     123.26, 
//     132.75, 
//     186.78, 
//     312.90, 
//     0,     
//     0,     
//     44.12, 
//     102.66, 
//     112.16, 
//     112.29, 
//     100.51, 
//     140.08, 
//     147.91     
// ]
// // pro6 F1
// export const pro6F1Charge = [
//     26882.97,  
//     21333.90,  
//     28432.76, 
//     22826.01, 
//     24328.67,  
//     29828.33,  
//     19385.33, 
//     31724.27, 
//     31721.94, 
//     37570.79, 
//     30393.05, 
//     35587.67, 
//     28363.67, 
//     19506.52, 
//     31830.41, 
//     29715.86,  
//     27761.50, 
//     17962.70,  
//     24390.36,  
//     38267.98, 
//     31419.71, 
//     34625.98, 
//     30297.00, 
//     30462.13, 
//     29301.55, 
//     34516.31, 
//     39461.33, 
//     33193.67, 
//     30930.90, 
//     34368.52, 
//     25782.00, 
//     30638.80,  
//     38407.63, 
//     46191.01, 
//     39435.33  
   
// ]
// export const pro6F1Payment = [
//     15387.52, 
//     15770.33, 
//     18549.45, 
//     14076.62, 
//     11334.09, 
//     15742.43, 
//     18449.67, 
//     12918.83, 
//     16304.27, 
//     20490.96, 
//     21593.94, 
//     19276.80, 
//     16622.84, 
//     14889.64, 
//     17278.62, 
//     20599.42, 
//     17655.75, 
//     8204.65, 
//     15167.44, 
//     21546.88, 
//     22149.58, 
//     19258.96, 
//     19716.76, 
//     17802.94, 
//     19022.17, 
//     17857.60, 
//     20200.53, 
//     18814.61, 
//     19717.15, 
//     16512.53, 
//     17947.41, 
//     19506.86, 
//     16686.92, 
//     25544.24, 
//     26262.00 
      
// ]
// export const pro6F1Collectable = [
//     123.96, 
//     219.59, 
//     68.73, 
//     10.85, 
//     40.07,  
//     49.66, 
//     2.47, 
//     16.33, 
//     43.33, 
//     103.35, 
//     104.65, 
//     1428.92, 
//     1391.58, 
//     22.62, 
//     59.50, 
//     24.79, 
//     123.77, 
//     22.66, 
//     291.44, 
//     2505.40, 
//     1784.82, 
//     1892.56, 
//     750.54, 
//     61.07, 
//     6.44, 
//     106.39, 
//     36.79, 
//     76.65, 
//     144.45, 
//     195.41,  
//     44.89, 
//     134.76, 
//     86.98, 
//     68.89, 
//     67.50 
       
// ]
// export const pro6F1NonCollectable = [
//     9587.81, 
//     9160.81, 
//     11046.32, 
//     8946.86, 
//     8121.06, 
//     9649.26, 
//     7836.72, 
//     9198.67,  
//     9685.79,  
//     11747.99, 
//     13732.41, 
//     12112.76, 
//     10206.91, 
//     8473.74, 
//     11201.24, 
//     10435.73, 
//     12072.56,
//     5824.26, 
//     9802.09, 
//     12363.94, 
//     11773.05, 
//     10583.81, 
//     9326.77, 
//     9617.16, 
//     13455.36,  
//     13795.44,  
//     16154.77, 
//     13108.99, 
//     14078.36, 
//     12344.02, 
//     14844.64, 
//     13440.61, 
//     12786.52, 
//     16795.59, 
//     18676.80 
       
// ]
// export const pro6F1ClaimCount = [
//     226,
//     134,
//     198,
//     162,
//     172,
//     204,
//     129,
//     174,
//     161,
//     214,
//     176,
//     193,
//     165,
//     109,
//     181,
//     164,
//     167,
//     102,
//     170,
//     167,
//     162,
//     154,
//     159,
//     152,
//     146,
//     149,
//     156,
//     144,
//     148,
//     209,
//     158,
//     181,
//     178,
//     208,
//     153
        
// ]
// export const pro6F1PerVisit = [
//     68.19, 
//     117.98, 
//     93.84, 
//     86.89, 
//     65.77, 
//     77.17, 
//     143.39, 
//     74.10, 
//     101.48, 
//     95.60, 
//     122.69, 
//     99.71, 
//     100.95, 
//     136.19, 
//     95.64, 
//     125.61, 
//     105.72, 
//     80.44, 
//     89.40, 
//     128.77, 
//     136.45, 
//     125.06, 
//     123.75,
//     117.12, 
//     129.99, 
//     119.58, 
//     129.21, 
//     130.66, 
//     132.92, 
//     79.13, 
//     113.35, 
//     107.77, 
//     93.75, 
//     122.61, 
//     171.27 
      
// ]
// // pro6 F2
// export const pro6F2Charge = [
//     26882.97,  
//     21333.90,  
//     28432.76, 
//     22826.01, 
//     24328.67,  
//     29828.33,  
//     19385.33, 
//     31724.27, 
//     31721.94, 
//     37570.79, 
//     30393.05, 
//     35587.67, 
//     28363.67, 
//     19506.52, 
//     31830.41, 
//     29715.86,  
//     27761.50, 
//     17962.70,  
//     24390.36,  
//     38267.98, 
//     31419.71, 
//     34625.98, 
//     30297.00, 
//     30462.13, 
//     29301.55, 
//     34516.31, 
//     39461.33, 
//     33193.67, 
//     30930.90, 
//     34368.52, 
//     25782.00, 
//     30638.80,  
//     38407.63, 
//     46191.01, 
//     39435.33  
// ]
// export const pro6F2Payment = [
//     15387.52, 
//     15770.33, 
//     18549.45, 
//     14076.62, 
//     11334.09, 
//     15742.43, 
//     18449.67, 
//     12918.83, 
//     16304.27, 
//     20490.96, 
//     21593.94, 
//     19276.80, 
//     16622.84, 
//     14889.64, 
//     17278.62, 
//     20599.42, 
//     17655.75, 
//     8204.65, 
//     15167.44, 
//     21546.88, 
//     22149.58, 
//     19258.96, 
//     19716.76, 
//     17802.94, 
//     19022.17, 
//     17857.60, 
//     20200.53, 
//     18814.61, 
//     19717.15, 
//     16512.53, 
//     17947.41, 
//     19506.86, 
//     16686.92, 
//     25544.24, 
//     26262.00     
// ]
// export const pro6F2Collectable = [
//     123.96, 
//     219.59, 
//     68.73, 
//     10.85, 
//     40.07,  
//     49.66, 
//     2.47, 
//     16.33, 
//     43.33, 
//     103.35, 
//     104.65, 
//     1428.92, 
//     1391.58, 
//     22.62, 
//     59.50, 
//     24.79, 
//     123.77, 
//     22.66, 
//     291.44, 
//     2505.40, 
//     1784.82, 
//     1892.56, 
//     750.54, 
//     61.07, 
//     6.44, 
//     106.39, 
//     36.79, 
//     76.65, 
//     144.45, 
//     195.41,  
//     44.89, 
//     134.76, 
//     86.98, 
//     68.89, 
//     67.50     
// ]
// export const pro6F2NonCollectable = [
//     9587.81, 
//     9160.81, 
//     11046.32, 
//     8946.86, 
//     8121.06, 
//     9649.26, 
//     7836.72, 
//     9198.67,  
//     9685.79,  
//     11747.99, 
//     13732.41, 
//     12112.76, 
//     10206.91, 
//     8473.74, 
//     11201.24, 
//     10435.73, 
//     12072.56,
//     5824.26, 
//     9802.09, 
//     12363.94, 
//     11773.05, 
//     10583.81, 
//     9326.77, 
//     9617.16, 
//     13455.36,  
//     13795.44,  
//     16154.77, 
//     13108.99, 
//     14078.36, 
//     12344.02, 
//     14844.64, 
//     13440.61, 
//     12786.52, 
//     16795.59, 
//     18676.80  
// ]
// export const pro6F2ClaimCount = [
//     226,
//     134,
//     198,
//     162,
//     172,
//     204,
//     129,
//     174,
//     161,
//     214,
//     176,
//     193,
//     165,
//     109,
//     181,
//     164,
//     167,
//     102,
//     170,
//     167,
//     162,
//     154,
//     159,
//     152,
//     146,
//     149,
//     156,
//     144,
//     148,
//     209,
//     158,
//     181,
//     178,
//     208,
//     153    
// ]
// export const pro6F2PerVisit = [
//     68.19, 
//     117.98, 
//     93.84, 
//     86.89, 
//     65.77, 
//     77.17, 
//     143.39, 
//     74.10, 
//     101.48, 
//     95.60, 
//     122.69, 
//     99.71, 
//     100.95, 
//     136.19, 
//     95.64, 
//     125.61, 
//     105.72, 
//     80.44, 
//     89.40, 
//     128.77, 
//     136.45, 
//     125.06, 
//     123.75,
//     117.12, 
//     129.99, 
//     119.58, 
//     129.21, 
//     130.66, 
//     132.92, 
//     79.13, 
//     113.35, 
//     107.77, 
//     93.75, 
//     122.61, 
//     171.27    
// ]
// // pro6 F3
// export const pro6F3Charge = [
//     26882.97,  
//     21333.90,  
//     28432.76, 
//     22826.01, 
//     24328.67,  
//     29828.33,  
//     19385.33, 
//     31724.27, 
//     31721.94, 
//     37570.79, 
//     30393.05, 
//     35587.67, 
//     28363.67, 
//     19506.52, 
//     31830.41, 
//     29715.86,  
//     27761.50, 
//     17962.70,  
//     24390.36,  
//     38267.98, 
//     31419.71, 
//     34625.98, 
//     30297.00, 
//     30462.13, 
//     29301.55, 
//     34516.31, 
//     39461.33, 
//     33193.67, 
//     30930.90, 
//     34368.52, 
//     25782.00, 
//     30638.80,  
//     38407.63, 
//     46191.01, 
//     39435.33  
// ]
// export const pro6F3Payment = [
//     15387.52, 
//     15770.33, 
//     18549.45, 
//     14076.62, 
//     11334.09, 
//     15742.43, 
//     18449.67, 
//     12918.83, 
//     16304.27, 
//     20490.96, 
//     21593.94, 
//     19276.80, 
//     16622.84, 
//     14889.64, 
//     17278.62, 
//     20599.42, 
//     17655.75, 
//     8204.65, 
//     15167.44, 
//     21546.88, 
//     22149.58, 
//     19258.96, 
//     19716.76, 
//     17802.94, 
//     19022.17, 
//     17857.60, 
//     20200.53, 
//     18814.61, 
//     19717.15, 
//     16512.53, 
//     17947.41, 
//     19506.86, 
//     16686.92, 
//     25544.24, 
//     26262.00    
// ]
// export const pro6F3Collectable = [
//     123.96, 
//     219.59, 
//     68.73, 
//     10.85, 
//     40.07,  
//     49.66, 
//     2.47, 
//     16.33, 
//     43.33, 
//     103.35, 
//     104.65, 
//     1428.92, 
//     1391.58, 
//     22.62, 
//     59.50, 
//     24.79, 
//     123.77, 
//     22.66, 
//     291.44, 
//     2505.40, 
//     1784.82, 
//     1892.56, 
//     750.54, 
//     61.07, 
//     6.44, 
//     106.39, 
//     36.79, 
//     76.65, 
//     144.45, 
//     195.41,  
//     44.89, 
//     134.76, 
//     86.98, 
//     68.89, 
//     67.50     
// ]
// export const pro6F3NonCollectable = [
//     9587.81, 
//     9160.81, 
//     11046.32, 
//     8946.86, 
//     8121.06, 
//     9649.26, 
//     7836.72, 
//     9198.67,  
//     9685.79,  
//     11747.99, 
//     13732.41, 
//     12112.76, 
//     10206.91, 
//     8473.74, 
//     11201.24, 
//     10435.73, 
//     12072.56,
//     5824.26, 
//     9802.09, 
//     12363.94, 
//     11773.05, 
//     10583.81, 
//     9326.77, 
//     9617.16, 
//     13455.36,  
//     13795.44,  
//     16154.77, 
//     13108.99, 
//     14078.36, 
//     12344.02, 
//     14844.64, 
//     13440.61, 
//     12786.52, 
//     16795.59, 
//     18676.80    
// ]
// export const pro6F3ClaimCount = [
//     226,
//     134,
//     198,
//     162,
//     172,
//     204,
//     129,
//     174,
//     161,
//     214,
//     176,
//     193,
//     165,
//     109,
//     181,
//     164,
//     167,
//     102,
//     170,
//     167,
//     162,
//     154,
//     159,
//     152,
//     146,
//     149,
//     156,
//     144,
//     148,
//     209,
//     158,
//     181,
//     178,
//     208,
//     153    
// ]
// export const pro6F3PerVisit = [
//     68.19, 
//     117.98, 
//     93.84, 
//     86.89, 
//     65.77, 
//     77.17, 
//     143.39, 
//     74.10, 
//     101.48, 
//     95.60, 
//     122.69, 
//     99.71, 
//     100.95, 
//     136.19, 
//     95.64, 
//     125.61, 
//     105.72, 
//     80.44, 
//     89.40, 
//     128.77, 
//     136.45, 
//     125.06, 
//     123.75,
//     117.12, 
//     129.99, 
//     119.58, 
//     129.21, 
//     130.66, 
//     132.92, 
//     79.13, 
//     113.35, 
//     107.77, 
//     93.75, 
//     122.61, 
//     171.27   
// ]
// // pro7 F1
// export const pro7F1Charge = [
//     22979.33, 
//     21058.33, 
//     30283.00, 
//     27807.00, 
//     26075.33, 
//     22662.67, 
//     24649.67, 
//     34310.33, 
//     29216.33, 
//     29921.00, 
//     21407.67, 
//     27101.17, 
//     22134.67, 
//     14592.67, 
//     30092.50, 
//     26633.33, 
//     19982.70, 
//     15676.55, 
//     23927.67, 
//     29807.67, 
//     25482.67, 
//     28597.55, 
//     26968.00, 
//     25349.00, 
//     11084.33, 
//     14982.00, 
//     18766.02, 
//     13275.79, 
//     11781.55, 
//     19902.92, 
//     11141.35, 
//     11610.34, 
//     16561.00,  
//     16945.67, 
//     17527.92 
   
// ]
// export const pro7F1Payment = [
//     11227.44, 
//     13696.64, 
//     22798.52, 
//     17240.62, 
//     16002.18, 
//     17810.99, 
//     16063.50, 
//     16890.89, 
//     18648.92, 
//     19737.86, 
//     18762.77, 
//     13786.39, 
//     16308.23, 
//     13721.78, 
//     17550.83, 
//     17916.06, 
//     16217.47, 
//     10831.94, 
//     16824.44, 
//     21531.47, 
//     16934.52, 
//     15586.58, 
//     16537.60, 
//     18256.39, 
//     14880.57, 
//     7740.74,
//     12601.33, 
//     8896.03, 
//     9376.46, 
//     8434.21, 
//     11951.11, 
//     9516.58, 
//     8445.07, 
//     9557.30, 
//     12162.66 
       
// ]
// export const pro7F1Collectable = [
//     8.94, 
//     0.37, 
//     29.07, 
//     15.00, 
//     16.89, 
//     119.67, 
//     5.38, 
//     16.67, 
//     0,   
//     25.83, 
//     68.46, 
//     2.39, 
//     607.44, 
//     25.00, 
//     53.57, 
//     0,   
//     30.00, 
//     57.64, 
//     247.16, 
//     2024.29, 
//     2999.26, 
//     2011.39, 
//     329.50, 
//     0,     
//     0,     
//     0,      
//     0,     
//     427.33, 
//     1.36, 
//     19.52, 
//     24.14, 
//     20.31, 
//     48.26, 
//     86.63, 
//     28.69 
      
// ]
// export const pro7F1NonCollectable = [
//     5713.77, 
//     7569.37, 
//     9773.76, 
//     8722.44, 
//     7177.87, 
//     8288.18, 
//     7494.26, 
//     7552.38,  
//     9364.13, 
//     9413.99, 
//     9675.68, 
//     6898.42, 
//     7581.42, 
//     6233.78, 
//     7388.31, 
//     8481.04, 
//     7751.32, 
//     5172.14, 
//     7871.31, 
//     9893.44, 
//     6954.25, 
//     6326.21, 
//     7513.82, 
//     7190.46, 
//     7640.30, 
//     3791.85, 
//     6753.54, 
//     4323.19, 
//     4464.29, 
//     4264.71, 
//     5538.71, 
//     4703.69, 
//     4773.84, 
//     6474.44, 
//     7895.13  
       
// ]
// export const pro7F1ClaimCount = [
//     116,
//     96,
//     119,
//     108,
//     111,
//     109,
//     100,
//     103,
//     101,
//     131,
//     97,
//     118,
//     107,
//     52,
//     102,
//     95,
//     72,
//     51,
//     76,
//     90,
//     93,
//     94,
//     83,
//     71,
//     69,
//     37,
//     57,
//     34,
//     42,
//     60,
//     39,
//     52,
//     45,
//     70,
//     59
       
// ]
// export const pro7F1PerVisit = [
//     96.79, 
//     142.18, 
//     191.05, 
//     159.64, 
//     143.73, 
//     163.90, 
//     160.10, 
//     163.46, 
//     184.64, 
//     151.06, 
//     194.10, 
//     116.83, 
//     151.94, 
//     263.88,  
//     171.51, 
//     188.59, 
//     224.20, 
//     213.79,  
//     222.35, 
//     240.13, 
//     182.75, 
//     165.23, 
//     199.25, 
//     258.35, 
//     215.66, 
//     207.34, 
//     219.79, 
//     261.65, 
//     221.49, 
//     141.36, 
//     303.84, 
//     181.85, 
//     187.67, 
//     136.53, 
//     204.99 
     
// ]
// // pro7 F2
// export const pro7F2Charge = [
//     22979.33, 
//     21058.33, 
//     30283.00, 
//     27807.00, 
//     26075.33, 
//     22662.67, 
//     24649.67, 
//     34310.33, 
//     29216.33, 
//     29921.00, 
//     21407.67, 
//     27101.17, 
//     22134.67, 
//     14592.67, 
//     30092.50, 
//     26633.33, 
//     19982.70, 
//     15676.55, 
//     23927.67, 
//     29807.67, 
//     25482.67, 
//     28597.55, 
//     26968.00, 
//     25349.00, 
//     11084.33, 
//     14982.00, 
//     18766.02, 
//     13275.79, 
//     11781.55, 
//     19902.92, 
//     11141.35, 
//     11610.34, 
//     16561.00,  
//     16945.67, 
//     17527.92 
// ]
// export const pro7F2Payment = [
//     11227.44, 
//     13696.64, 
//     22798.52, 
//     17240.62, 
//     16002.18, 
//     17810.99, 
//     16063.50, 
//     16890.89, 
//     18648.92, 
//     19737.86, 
//     18762.77, 
//     13786.39, 
//     16308.23, 
//     13721.78, 
//     17550.83, 
//     17916.06, 
//     16217.47, 
//     10831.94, 
//     16824.44, 
//     21531.47, 
//     16934.52, 
//     15586.58, 
//     16537.60, 
//     18256.39, 
//     14880.57, 
//     7740.74,
//     12601.33, 
//     8896.03, 
//     9376.46, 
//     8434.21, 
//     11951.11, 
//     9516.58, 
//     8445.07, 
//     9557.30, 
//     12162.66     
// ]
// export const pro7F2Collectable = [
//     8.94, 
//     0.37, 
//     29.07, 
//     15.00, 
//     16.89, 
//     119.67, 
//     5.38, 
//     16.67, 
//     0,   
//     25.83, 
//     68.46, 
//     2.39, 
//     607.44, 
//     25.00, 
//     53.57, 
//     0,   
//     30.00, 
//     57.64, 
//     247.16, 
//     2024.29, 
//     2999.26, 
//     2011.39, 
//     329.50, 
//     0,     
//     0,     
//     0,      
//     0,     
//     427.33, 
//     1.36, 
//     19.52, 
//     24.14, 
//     20.31, 
//     48.26, 
//     86.63, 
//     28.69    
// ]
// export const pro7F2NonCollectable = [
//     5713.77, 
//     7569.37, 
//     9773.76, 
//     8722.44, 
//     7177.87, 
//     8288.18, 
//     7494.26, 
//     7552.38,  
//     9364.13, 
//     9413.99, 
//     9675.68, 
//     6898.42, 
//     7581.42, 
//     6233.78, 
//     7388.31, 
//     8481.04, 
//     7751.32, 
//     5172.14, 
//     7871.31, 
//     9893.44, 
//     6954.25, 
//     6326.21, 
//     7513.82, 
//     7190.46, 
//     7640.30, 
//     3791.85, 
//     6753.54, 
//     4323.19, 
//     4464.29, 
//     4264.71, 
//     5538.71, 
//     4703.69, 
//     4773.84, 
//     6474.44, 
//     7895.13     
// ]
// export const pro7F2ClaimCount = [
//     116,
//     96,
//     119,
//     108,
//     111,
//     109,
//     100,
//     103,
//     101,
//     131,
//     97,
//     118,
//     107,
//     52,
//     102,
//     95,
//     72,
//     51,
//     76,
//     90,
//     93,
//     94,
//     83,
//     71,
//     69,
//     37,
//     57,
//     34,
//     42,
//     60,
//     39,
//     52,
//     45,
//     70,
//     59   
// ]
// export const pro7F2PerVisit = [
//     96.79, 
//     142.18, 
//     191.05, 
//     159.64, 
//     143.73, 
//     163.90, 
//     160.10, 
//     163.46, 
//     184.64, 
//     151.06, 
//     194.10, 
//     116.83, 
//     151.94, 
//     263.88,  
//     171.51, 
//     188.59, 
//     224.20, 
//     213.79,  
//     222.35, 
//     240.13, 
//     182.75, 
//     165.23, 
//     199.25, 
//     258.35, 
//     215.66, 
//     207.34, 
//     219.79, 
//     261.65, 
//     221.49, 
//     141.36, 
//     303.84, 
//     181.85, 
//     187.67, 
//     136.53, 
//     204.99    
// ]
// // pro7 F3
// export const pro7F3Charge = [
//     22979.33, 
//     21058.33, 
//     30283.00, 
//     27807.00, 
//     26075.33, 
//     22662.67, 
//     24649.67, 
//     34310.33, 
//     29216.33, 
//     29921.00, 
//     21407.67, 
//     27101.17, 
//     22134.67, 
//     14592.67, 
//     30092.50, 
//     26633.33, 
//     19982.70, 
//     15676.55, 
//     23927.67, 
//     29807.67, 
//     25482.67, 
//     28597.55, 
//     26968.00, 
//     25349.00, 
//     11084.33, 
//     14982.00, 
//     18766.02, 
//     13275.79, 
//     11781.55, 
//     19902.92, 
//     11141.35, 
//     11610.34, 
//     16561.00,  
//     16945.67, 
//     17527.92 
// ]
// export const pro7F3Payment = [
//     11227.44, 
//     13696.64, 
//     22798.52, 
//     17240.62, 
//     16002.18, 
//     17810.99, 
//     16063.50, 
//     16890.89, 
//     18648.92, 
//     19737.86, 
//     18762.77, 
//     13786.39, 
//     16308.23, 
//     13721.78, 
//     17550.83, 
//     17916.06, 
//     16217.47, 
//     10831.94, 
//     16824.44, 
//     21531.47, 
//     16934.52, 
//     15586.58, 
//     16537.60, 
//     18256.39, 
//     14880.57, 
//     7740.74,
//     12601.33, 
//     8896.03, 
//     9376.46, 
//     8434.21, 
//     11951.11, 
//     9516.58, 
//     8445.07, 
//     9557.30, 
//     12162.66     
// ]
// export const pro7F3Collectable = [
//     8.94, 
//     0.37, 
//     29.07, 
//     15.00, 
//     16.89, 
//     119.67, 
//     5.38, 
//     16.67, 
//     0,   
//     25.83, 
//     68.46, 
//     2.39, 
//     607.44, 
//     25.00, 
//     53.57, 
//     0,   
//     30.00, 
//     57.64, 
//     247.16, 
//     2024.29, 
//     2999.26, 
//     2011.39, 
//     329.50, 
//     0,     
//     0,     
//     0,      
//     0,     
//     427.33, 
//     1.36, 
//     19.52, 
//     24.14, 
//     20.31, 
//     48.26, 
//     86.63, 
//     28.69    
// ]
// export const pro7F3NonCollectable = [
//     5713.77, 
//     7569.37, 
//     9773.76, 
//     8722.44, 
//     7177.87, 
//     8288.18, 
//     7494.26, 
//     7552.38,  
//     9364.13, 
//     9413.99, 
//     9675.68, 
//     6898.42, 
//     7581.42, 
//     6233.78, 
//     7388.31, 
//     8481.04, 
//     7751.32, 
//     5172.14, 
//     7871.31, 
//     9893.44, 
//     6954.25, 
//     6326.21, 
//     7513.82, 
//     7190.46, 
//     7640.30, 
//     3791.85, 
//     6753.54, 
//     4323.19, 
//     4464.29, 
//     4264.71, 
//     5538.71, 
//     4703.69, 
//     4773.84, 
//     6474.44, 
//     7895.13     
// ]
// export const pro7F3ClaimCount = [
//     116,
//     96,
//     119,
//     108,
//     111,
//     109,
//     100,
//     103,
//     101,
//     131,
//     97,
//     118,
//     107,
//     52,
//     102,
//     95,
//     72,
//     51,
//     76,
//     90,
//     93,
//     94,
//     83,
//     71,
//     69,
//     37,
//     57,
//     34,
//     42,
//     60,
//     39,
//     52,
//     45,
//     70,
//     59    
// ]
// export const pro7F3PerVisit = [
//     96.79, 
//     142.18, 
//     191.05, 
//     159.64, 
//     143.73, 
//     163.90, 
//     160.10, 
//     163.46, 
//     184.64, 
//     151.06, 
//     194.10, 
//     116.83, 
//     151.94, 
//     263.88,  
//     171.51, 
//     188.59, 
//     224.20, 
//     213.79,  
//     222.35, 
//     240.13, 
//     182.75, 
//     165.23, 
//     199.25, 
//     258.35, 
//     215.66, 
//     207.34, 
//     219.79, 
//     261.65, 
//     221.49, 
//     141.36, 
//     303.84, 
//     181.85, 
//     187.67, 
//     136.53, 
//     204.99    
// ]
