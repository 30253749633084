export const jan2022Series = [
    {
        name: 'Charges',
        data: [
            147095.04	


        ]
    },
    {
        name: 'Payment',
        data: [
            41727.76	

        ]
    },
    {
        name: 'Collectable',
        data: [
            15381.06	
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            108936.82		

        ]
    },
    {
        name: 'Claims',
        data: [
            124	

        ]
    },
    {
        name: 'Average Visit',
        data: [
            336.5141935  
        ]
    },
]
export const feb2022Series = [
    {
        name: 'Charges',
        data: [
            123579.28	
	 
        ]
    },
    {
        name: 'Payment',
        data: [
            41810.2	


        ]
    },
    {
        name: 'Collectable',
        data: [
            	16102.08	 	  	


        ]
    },
    {
        name: 'Non Collectable',
        data: [
          137512.69
        ]
    },
    {
        name: 'Claims',
        data: [
           98	
        ]
    },
    {
        name: 'Average Visit',
        data: [
            	426.6346939 	
        ]
    },
]
export const mar2022Series = [
    {
        name: 'Charges',
        data: [
            152113.47	


         
        ]
    },
    {
        name: 'Payment',
        data: [
            30048.18	 


        ]
    },
    {
        name: 'Collectable',
        data: [
            6211.12


        ]
    },
    {
        name: 'Non Collectable',
        data: [

            77614.33	


        ]
    },
    {
        name: 'Claims',
        data: [
            138	


        ]
    },
    {
        name: 'Average Visit',
        data: [
            217.7404348	 

        ]
    },
]
export const apr2022Series = [
    {
        name: 'Charges',
        data: [
            90365	



        ]
    },
    {
        name: 'Payment',
        data: [
            31459.37	

        ]
    },
    {
        name: 'Collectable',
        data: [
            1616.8	

        ]
    },
    {
        name: 'Non Collectable',
        data: [

            91045.12	


        ]
    },
    {
        name: 'Claims',
        data: [
            81	


        ]
    },
    {
        name: 'Average Visit',
        data: [
            388.387284		
        ]
    },
]
export const may2022Series = [
    {
        name: 'Charges',
        data: [
            135785.24	
 

        ]
    },
    {
        name: 'Payment',
        data: [
            25267.96	

        ]
    },
    {
        name: 'Collectable',
        data: [
            1804.76	

        ]
    },
    {
        name: 'Non Collectable',
        data: [
            72383.57	

        ]
    },
    {
        name: 'Claims',
        data: [
            110		


        ]
    },
    {
        name: 'Average Visit',
        data: [
            229.7087273 	 

        ]
    },
]
export const jun2022Series = [
    {
        name: 'Charges',
        data: [
            184484.48	
	 
           

        ]
    },
    {
        name: 'Payment',
        data: [
            38257.31	
        ]
    },
    {
        name: 'Collectable',
        data: [
            16042.84		
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            112706.87	



        ]
    },
    {
        name: 'Claims',
        data: [
            148	
        ]
    },
    {
        name: 'Average Visit',
        data: [
            258.4862838	 

        ]
    },
]
export const jul2022Series = [
    {
        name: 'Charges',
        data: [
            144727.08	


        ]
    },
    {
        name: 'Payment',
        data: [
            40085.43	

        ]
    },
    {
        name: 'Collectable',
        data: [
            6297.12	
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            112025.71		  	
        ]
    },
    {
        name: 'Claims',
        data: [
            115	



        ]
    },
    {
        name: 'Average Visit',
        data: [
            348.5689565	


        ]
    },
]
export const aug2022Series = [
    {
        name: 'Charges',
        data: [
            111474.78


        ]
    },
    {
        name: 'Payment',
        data: [
            41420.44	

        ]
    },
    {
        name: 'Collectable',
        data: [
            1972.4	


        ]
    },
    {
        name: 'Non Collectable',
        data: [
            129932.81	
        ]
    },
    {
        name: 'Claims',
        data: [
            106	
 


        ]
    },
    {
        name: 'Average Visit',
        data: [
            390.7588679 

        ]
    },
]
export const sept2022Series = [
    {
        name: 'Charges',
        data: [
            108228	
	
        ]
    },
    {
        name: 'Payment',
        data: [
            33039.98	


        ]
    },
    {
        name: 'Collectable',
        data: [
            6022.46	
        ]
    },
    {
        name: 'Non Collectable',
        data: [

            86852.02	

        ]
    },
    {
        name: 'Claims',
        data: [
            88	

        ]
    },
    {
        name: 'Average Visit',
        data: [
            375.4543182 
        ]
    },
]
export const oct2022Series = [
    {
        name: 'Charges',
        data: [

            108534


        ]
    },
    {
        name: 'Payment',
        data: [
            31236.42



        ]
    },
    {
        name: 'Collectable',
        data: [
            872.98

        ]
    },
    {
        name: 'Non Collectable',
        data: [

            87087.91

        ]
    },
    {
        name: 'Claims',
        data: [
            123



        ]
    },
    {
        name: 'Average Visit',
        data: [
            253.9546341


        ]
    },
]
export const nov2022Series = [
    {
        name: 'Charges',
        data: [

            118235
        ]
    },
    {
        name: 'Payment',
        data: [
            27135.48



        ]
    },
    {
        name: 'Collectable',
        data: [
            906.9

        ]
    },
    {
        name: 'Non Collectable',
        data: [

            87204.88


        ]
    },
    {
        name: 'Claims',
        data: [
            109


        ]
    },
    {
        name: 'Average Visit',
        data: [
            248.95
        ]
    },
]
export const dec2022Series = [
    {
        name: 'Charges',
        data: [

            112412
        ]
    },
    {
        name: 'Payment',
        data: [
            32563.75
        ]
    },
    {
        name: 'Collectable',
        data: [
            25158.91

        ]
    },
    {
        name: 'Non Collectable',
        data: [

            102323.13

        ]
    },
    {
        name: 'Claims',
        data: [
            90


        ]
    },
    {
        name: 'Average Visit',
        data: [
            361.82
        ]
    },
]