import React from 'react'
import { Row, Col, Progress } from 'reactstrap'
const CPTLevelBlocks = ({data}) => {
    // console.log('cpt level;   ' , data)
    return (
        <Row className='text-dark'>
            <h3 className='text-dark'>CPT Level Payments</h3>
            <Col style={{ backgroundColor: 'white' }} className='m-1 p-2 border rounded '>
                <h3>Total Units:</h3>
                <span className='text-center'>{data.totalunits}</span>
            </Col>
            {/* <Col style={{ backgroundColor: 'white' }} className='m-1 p-2 border rounded'>
                <h3>CPT %:</h3>
                <span className='text-center'>100 %</span>
                <Progress className="mt-2" color='danger' value={100} />

            </Col> */}
            {/* <Col style={{ backgroundColor: 'white' }} className='m-1 p-2 border rounded'>
                <h3>Total Charges:</h3>
                <span className='text-center'>$ 7968406.76</span>
            </Col> */}
            <Col style={{ backgroundColor: 'white' }} className='m-1 p-2 border rounded'>
                <h3>Total Payments:</h3>
                <span className='text-center'>$ {data.totalPayments}</span>
            </Col>
        </Row>
    )
}

export default CPTLevelBlocks