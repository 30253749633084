/* 
export const firstCharges = [{
    name: 'Charges',
    data: [
        200017.51 ,
        97865.04 ,
        212288.26 
    ]
},
{
    name: 'Payment',
    data: [
       36223.50 ,
   32849.61 ,
     25511.81 ,
    ]
}, {
    name: 'Collectable',
    data: [
        3669.03 ,
        3510.18 ,
        2323.24,

    ]
}, {
    name: 'Non-Collecatble',
    data: [
        160838.99 ,
       153128.20 ,
        117149.80 ,
    ]
}, {
    name: 'Claim',
    data: [
       180,
        84,
       164,
    ]
}, {
    name: 'Average Per Visits',
    data: [
        
        201.24 ,
        
        391.07 ,
    
        155.56 
    ]
}
]

//second
export const secondCharges = [
    {
        name: 'Charges',
        data: [
            74905.76 ,
           150239.00 ,
            141682.28 

        ]
    },
    {
        name: 'Payment',
        data: [
           23655.10 ,
        26823.41 , 20737.60 ,

        ]
    }, {
        name: 'Collectable',
        data: [
            2211.93 ,
            4642.07 ,
            11837.60 
        ]
    }, {
        name: 'Non-Collecatble',
        data: [
          105475.25 ,
            116532.26 ,
         76705.60 ,

        ]
    }, {
        name: 'Claim',
        data: [
          64,
          111,
         119,

        ]
    }, {
        name: 'Average Per Visits',
        data: [
            
            369.61 ,
            
            241.65 ,
        
            174.27 


        ]
    }

]

//thirds
export const thirdCharges = [


    {
        name: 'Charges',
        data: [
            65545.58 ,
            329915.10 ,
            169139.05 

        ]
    },
    {
        name: 'Payment',
        data: [
           17179.09 ,
          33180.83 ,
        48289.17 ,

        ]
    }, {
        name: 'Collectable',
        data: [
            446.02 ,
            779.37 ,
            2071.57 

        ]
    }, {
        name: 'Non-Collecatble',
        data: [
           53666.18 ,
          118906.74 ,
            199762.49 ,

        ]
    }, {
        name: 'Claim',
        data: [
           73,
            237,
           157,

        ]
    }, {
        name: 'Average Per Visits',
        data: [
            
            235.33 ,
            
            140.00 ,
        
            307.57 


        ]
    }


]

//fourth
export const fourthCharges = [

    {
        name: 'Charges',
        data: [
           233808.82 ,
           178929.16 ,
            189988.54 

        ]
    },
    {
        name: 'Payment',
        data: [
           36819.70 ,
            31229.31 ,
           52961.14 ,

        ]
    }, {
        name: 'Collectable',
        data: [
            5914.66 ,
            1225.77 ,
            1435.49 

        ]
    }, {
        name: 'Non-Collecatble',
        data: [
           114484.97 ,
          111264.87 ,
           184955.86 ,

        ]
    }, {
        name: 'Claim',
        data: [
         173,
          140,
           137,

        ]
    }, {
        name: 'Average Per Visits',
        data: [
            
            212.83 ,
            
            223.07 ,
        
            386.58 


        ]
    }

]


//fifth
export const fifthCharges = [

    {
        name: 'Charges',
        data: [
           147969.85 ,
          171945.61 ,
            169254.00 
        ]
    },
    {
        name: 'Payment',
        data: [
           64947.23 ,
            25514.88 ,
         32384.67 ,

        ]
    }, {
        name: 'Collectable',
        data: [
            7169.47 ,
4646.81 ,
3069.15 

        ]
    }, {
        name: 'Non-Collecatble',
        data: [
           276851.47 ,  98414.90 ,
           110362.61 ,

        ]
    }, {
        name: 'Claim',
        data: [
          115,
          135,
           134,

        ]
    }, {
        name: 'Average Per Visits',
        data: [
            
            564.76 ,
            
            189.00 ,
        
            241.68 
        ]
    }
]

//sixth
export const sixthCharges = [
    {
        name: 'Charges',
        data: [
           180682.39 ,
            167814.37 ,
          202408.34 

        ]
    },
    {
        name: 'Payment',
        data: [
            57204.33 ,
            37329.14 ,
            40959.48 ,


        ]
    }, {
        name: 'Collectable',
        data: [
            2609.59 ,
            3778.90 ,
            2709.73

        ]
    }, {
        name: 'Non-Collecatble',
        data: [
           178390.91 ,
           128253.94 ,
            120755.62 ,

        ]
    }, {
        name: 'Claim',
        data: [
           151,
            139,
           153,

        ]
    }, {
        name: 'Average Per Visits',
        data: [
            
            378.84 ,
            
            268.55 ,
        
            267.71 

        ]
    }
]
export const seventhCharges = [
    {
        name: 'Charges',
        data: [
            170959.00 ,
            179601.71 ,
            171755.90 


        ]
    },
    {
        name: 'Payment',
        data: [
             53551.52 ,
           49434.44 ,
           41145.10 ,



        ]
    }, {
        name: 'Collectable',
        data: [
            
11145.33 ,
7259.45 ,
5528.69 


        ]
    }, {
        name: 'Non-Collecatble',
        data: [
           180224.09 ,
           148888.31 ,
           141883.35 ,


        ]
    }, {
        name: 'Claim',
        data: [
           130,
            143,
            130,


        ]
    }, {
        name: 'Average Per Visits',
        data: [
            
            411.93 ,
            
            345.70 ,
        
            316.50 

        ]
    }
]
export const eighthCharges = [
    {
        name: 'Charges',
        data: [
            194529.00 ,
            222809.33 ,
           260349.10 



        ]
    },
    {
        name: 'Payment',
        data: [
             46985.84 ,
            42560.71 ,
       50734.17 ,



        ]
    }, {
        name: 'Collectable',
        data: [
            6538.99 ,
            5957.15 ,
            17786.22 



        ]
    }, {
        name: 'Non-Collecatble',
        data: [
           158421.85 ,
           130257.96 ,
           152976.76 ,



        ]
    }, {
        name: 'Claim',
        data: [
           157,
            148,
           177,



        ]
    }, {
        name: 'Average Per Visits',
        data: [
            
            299.27, 
            
            287.57 ,
        
            286.63 

        ]
    }
]
*/ 
export const ninethCharges = [//2022 - jan,feb,mar
    {
        name: 'Charges',
        data: [
            147095.04,
            123579.28,
            152113.47

        ]
    },
    {
        name: 'Payment',
        data: [
            41727.76,
            41810.2,
            30048.18
        
        ]
    }, {
        name: 'Collectable',
        data: [
            15381.06,
            16102.08,
            6211.12
            
        ]
    }, {
        name: 'Non-Collecatble',
        data: [
            108936.82,
            137512.69,
            77614.33
    
        ]
    }, {
        name: 'Claim',
        data: [
            124,
            98,
            138

        ]
    }, {
        name: 'Average Per Visits',
        data: [
            
            336.5141935,
            426.6346939,
            217.7404348
           
        ]
    }
]
export const tenthCharges = [//2022 - apr,may,jun
    {
        name: 'Charges',
        data: [
            90365,
            135785.24,
            184484.48
           
           
        ]
    },
    {
        name: 'Payment',
        data: [
            31459.37,
            25267.96,
            38257.31

        ]
    }, {
        name: 'Collectable',
        data: [
            1616.8,
            1804.76,
            16042.84
            
        ]
    }, {
        name: 'Non-Collecatble',
        data: [
            91045.12,
            72383.57,
            112706.87
           
        ]
    }, {
        name: 'Claim',
        data: [
            81,
            110,
            148
        
        ]
    }, {
        name: 'Average Per Visits',
        data: [
            388.39,
            229.71,
            258.50
        ]
    }
]
export const eleventhCharges = [//2022 - jul,aug,sep
    {
        name: 'Charges',
        data: [
            144727.08,
            111474.78,
            108228
           
           
        ]
    },
    {
        name: 'Payment',
        data: [
            40085.43,
            41420.44,
            33039.98

        ]
    }, {
        name: 'Collectable',
        data: [
            6297.12,
            1972.4,
            6022.46
                    
        ]
    }, {
        name: 'Non-Collecatble',
        data: [
            112025.71,
            129932.81,
            86852.02
            
        ]
    }, {
        name: 'Claim',
        data: [
            115,
            106,
            88
           
            
        ]
    }, {
        name: 'Average Per Visits',
        data: [
            348.57,
            390.76,
            375.4543182
           
        ]
    }
]
export const twelvethCharges = [//2022 - oct,nov,dec
    {
        name: 'Charges',
        data: [
            108534,
            118235,
            112412
           
           
        ]
    },
    {
        name: 'Payment',
        data: [
            31236.42,
            27135.48,
            32563.75
            
        ]
    }, {
        name: 'Collectable',
        data: [
            872.98,
            906.9,
            25158.91
           
        ]
    }, {
        name: 'Non-Collecatble',
        data: [
            87087.91,
            87204.88,
            102323.13
            
        ]
    }, {
        name: 'Claim',
        data: [
            123,
            109,
            90
           
        ]
    }, {
        name: 'Average Per Visits',
        data: [
            253.9546341,
            248.95,
            361.82
        ]
    }
]
export const thirteenCharges = [//2023 - jan,feb,mar
    {
        name: 'Charges',
        data: [
            121645.56,
            51109,
            135319

           
        ]
    },
    {
        name: 'Payment',
        data: [
            16608.45,
            22276.56,
            28900.62
           
        ]
    }, {
        name: 'Collectable',
        data: [
            4801.58,
            348.14,
            3261.16
             
        ]
    }, {
        name: 'Non-Collecatble',
        data: [
            61008.37,
            78890.52,
            97653.16
               
        ]
    }, {
        name: 'Claim',
        data: [
            110,
            55,
            117
               
        ]
    }, {
        name: 'Average Per Visits',
        data: [
            150.99,
            405.03,
            247.01
        ]
    }
]
export const fourteenCharges = [//2023 - apr,may,jun
    {
        name: 'Charges',
        data: [
            128221.00,
            137840.04,
            169826.52

        ]
    },
    {
        name: 'Payment',
        data: [
            25559.70,
            27936.10,
            33230.84
        ]
    }, {
        name: 'Collectable',
        data: [
            4484.63,
            7105.55,
            21776.18
        ]
    }, {
        name: 'Non-Collecatble',
        data: [
            58972.81,
            96544.37,
            94347.64
        ]
    }, {
        name: 'Claim',
        data: [
            110,
            109,
            134
        ]
    }, {
        name: 'Average Per Visits',
        data: [
            
            232.362,
            256.62,
            247.99

        ]
    }
]
export const fifteenCharges = [//2023 - jul,aug,sep
    {
        name: 'Charges',
        data: [
            90351,
            158702,
            123723,

        ]
    },
    {
        name: 'Payment',
        data: [
            25944.87,
            26281.65,
            52398.96
        ]
    }, {
        name: 'Collectable',
        data: [
            6407.12,
            14581.63,
            2457.18
        ]
    }, {
        name: 'Non-Collecatble',
        data: [
            68795.65,
            83061.48,
            149185.23

        ]
    }, {
        name: 'Claim',
        data: [
            76,
            133,
            103
        ]
    }, {
        name: 'Average Per Visits',
        data: [
            
            341.38,
            197.61,
            508.73

        ]
    }
]
export const sixteenCharges = [//2023 - oct,nov,dec
    {
        name: 'Charges',
        data: [
            109564,
            137811,
            95060,

        ]
    },
    {
        name: 'Payment',
        data: [
            33388.75,
            21280.17,
            30310.51

        ]
    }, {
        name: 'Collectable',
        data: [
            3989.12,
            6586.54,
            4454.09
        ]
    }, {
        name: 'Non-Collecatble',
        data: [
            87345.09,
            61742.51,
            85095.25
            
        ]
    }, {
        name: 'Claim',
        data: [
            98,
            103,
            82
        ]
    }, {
        name: 'Average Per Visits',
        data: [
            
            340.74,
            206.60,
            369.64

        ]
    }
]
export const seventeenCharges = [//2024 - jan,feb.mar
    {
        name: 'Charges',
        data: [
            123694,
            98453,
            118159,

        ]
    },
    {
        name: 'Payment',
        data: [
            18390.27,
            21713.20,
            15969.11

        ]
    }, {
        name: 'Collectable',
        data: [
            1877.67,
            10184.94,
            3227.12

        ]
    }, {
        name: 'Non-Collecatble',
        data: [
            47684.68,
            165428.7,
            33110.5

        ]
    }, {
        name: 'Claim',
        data: [
            112,
            101,
            115
        ]
    }, {
        name: 'Average Per Visits',
        data: [
            
            164.20,
            214.98,
            138.86

        ]
    }
]

export const eighteenCharges = [//2024 - apr,may,june
    {
        name: 'Charges',
        data: [
            82976,
            54337.8,
            122131.4

        ]
    },
    {
        name: 'Payment',
        data: [
            32949.93,
            26715.99,
            18705.17

        ]
    }, {
        name: 'Collectable',
        data: [
            3144.95,
            2317.96,
            2975.26

        ]
    }, {
        name: 'Non-Collecatble',
        data: [
            98328.71,
            70507.09,
            75251.46,

        ]
    }, {
        name: 'Claim',
        data: [
            66,
            78,
            105
        ]
    }, {
        name: 'Average Per Visits',
        data: [
            499.24,
            342.51,
            171.14

        ]
    }
]

export const nineteenCharges = [//2024 - july,august,september
    {
        name: 'Charges',
        data: [
            116533,
            56727,
            0

        ]
    },
    {
        name: 'Payment',
        data: [
            25849.39,
            14403.40,
            0

        ]
    }, {
        name: 'Collectable',
        data: [
            320.94,
            2413.94,
            0

        ]
    }, {
        name: 'Non-Collecatble',
        data: [
            78020.93,
            50271.30,
            0

        ]
    }, {
        name: 'Claim',
        data: [
            93,
            61,
            0
        ]
    }, {
        name: 'Average Per Visits',
        data: [
            277.94,
            236.12,
            0

        ]
    }
]










































export const firstChargesSAM = [
    {
        name: 'Charges',
        data: [
           570016.43,
           660934.9,
          480660.58,
        ]
    },
    {
        name: 'Payment',
        data: [
          138420.6,
           155773.4,
           152042.68,
        ]
    }, {
        name: 'Collectable',
        data: [
           16189.78,
           38258.28,
          21373.37,
        ]
    }, {
        name: 'Non-Collecatble',
        data: [
            381446.17,
            435443.46,
            435789.74,
            
        ]
    }, {
        name: 'Claim',
        data: [
            1163,
          1620,
         1007,
            

        ]
    }, {
        name: 'Average Per Visits',
        data: [
           378.3572395,
            296.2272423,
            453.6647587,
            
        ]
    }

]

export const secondChargesSAM = [{
    name: 'Charges',
    data: [
       569720.27,
319879.43,
478003.36,

    ]
},
{
    name: 'Payment',
    data: [
       119612,
       107041.19,
      115459.4,
        
    ]
}, {
    name: 'Collectable',
    data: [
        24073.95,
20581.1,
28990.84,

    ]
}, {
    name: 'Non-Collecatble',
    data: [
      312776.68,
        331406.73,
        318857.75,
        
    ]
}, {
    name: 'Claim',
    data: [
       1315,
       725,
        1089,

    ]
}, {
    name: 'Average Per Visits',
    data: [
       274.3045426,
      541.8218271,
       328.5357285,
    ]
}
]
export const thirdChargesSAM = [
    {
        name: 'Charges',
        data: [
            470002.38,
            554324.87,
            324152.5,
        ]
    },
    {
        name: 'Payment',
        data: [
           107683.98,
           120402.05,
           116653.51,
        ]
    }, {
        name: 'Collectable',
        data: [
           15958.63,
            15433.18,
          17658.53,
            
        ]
    }, {
        name: 'Non-Collecatble',
        data: [
           288856.91,
            338281.14,
          290929.8,
            
        ]
    }, {
        name: 'Claim',
        data: [
           1110,
           1341,
            936,
        ]
    }, {
        name: 'Average Per Visits',
        data: [
          409.5714575,
            270.1474233,
            160,]
    }
]
export const fourthChargesSAM = [{
    name: 'Charges',
    data: [
        303554.3,
       296443.19,
        387952.86,
    ]
},
{
    name: 'Payment',
    data: [
      123948.6,
     114857.18,
      120857.5,
    ]
}, {
    name: 'Collectable',
    data: [

       51428.91,
47573.32,
30531.73,

    ]
}, {
    name: 'Non-Collecatble',
    data: [
       228053.7,
237729.72,
224981.76,

    ]
}, {
    name: 'Claim',
    data: [
      956,
      820,
      1095,
        
    ]
}, {
    name: 'Average Per Visits',
    data: [
        475.2223614,
       525.0219935,
        447.2020894,
        ]
}]

//fourth,
//fifth,
export const fifthChargesSAM = [
    {
        name: 'Charges',
        data: [
           632227.9,
           356940.16,
            364773.5,
        ]
    },
    {
        name: 'Payment',
        data: [
           150644.9,
           146942.33,
          156139.18,]
    }, {
        name: 'Collectable',
        data: [
            10952.59,
           17295.66,
          13237.9,
            
        ]
    }, {
        name: 'Non-Collecatble',
        data: [
            308395.22,
            290215.39,
          279164.31,
            
        ]
    }, {
        name: 'Claim',
        data: [
           1585,
           927,
          1087,
        ]
    }, {
        name: 'Average Per Visits',
        data: [
           291.0511202,
           479.4973283,
            428.6589755,]
    }
]
//2022 starts 
export const sixthChargesSAM = [//2022-jan,feb,mar
    {
        name: 'Charges',
        data: [
            481027.46,
            361328.7,
            339807.05

          
            
        ]
    },
    {
        name: 'Payment',
        data: [
            132281.25,
            127658.26,
            140846.73
           
            
           
            
            
        ]
    }, {
        name: 'Collectable',
        data: [
            12976.26,
            15927.64,
            27177.06


            
            

        ]
    }, {
        name: 'Non-Collecatble',
        data: [
            220749.63,
            277241.96,
            231527.47
        
            
            
            
            
        ]
    }, {
        name: 'Claim',
        data: [
            1378,
            1298,
            1024
           
            
            
            
        ]
    }, {
        name: 'Average Per Visits',
        data: [
            96.00,
            98.35,
            137.55
            ]
    }
]
//sixth,

export const sevethChargesSAM = [//2022-apr,may,jun
    {
        name: 'Charges',
        data: [
            343423.9,
            350187.12,
            340015.59

            
            
            ]
    },
    {
        name: 'Payment',
        data: [
            114010.36,
            128554.98,
            119664.64
            
           
            
        ]
    }, {
        name: 'Collectable',
        data: [
            16010.36,
            20250.95,
            22415.47
           
            

        ]
    }, {
        name: 'Non-Collecatble',
        data: [
            215654.6,
            238270.43,
            238439.69
            
          
            
        ]
    }, {
        name: 'Claim',
        data: [
            1043,
            1089,
            1052
           
            
            ]
    }, {
        name: 'Average Per Visits',
        data: [
            109.31,
            118.05,
            113.75
           
           
            
        ]
    }
]
export const eightChargesSAM = [//2022-jul,aug,sep
    {
        name: 'Charges',
        data: [
            448290.63,
            369072.98,
            383310.2


            
            

        ]
    },
    {
        name: 'Payment',
        data: [
            116741.31,
            151057.68,
            123677.05
           
           
            


        ]
    }, {
        name: 'Collectable',
        data: [
            18907.77,
            13921.79,
            39252.34
           
           
            
            
        ]
    }, {
        name: 'Non-Collecatble',
        data: [
            215326.95,
            277140.36,
            217085.76
            
            
            
        ]
    }, {
        name: 'Claim',
        data: [
            1323,
            1091,
            1186
           
           
            
]

    }, {
        name: 'Average Per Visits',
        data: [
            88.24,
            138.46,
            104.28
        ]
    }
]
export const nineChargesSAM = [//2022-oct,nov,dec
    {
        name: 'Charges',
        data: [
            383559.4,
            513055.18,
            460196.14
           
            


        ]
    },
    {
        name: 'Payment',
        data: [
            131443.24,
            160411.36,
            173369.27
            
           
            
        ]
    }, {
        name: 'Collectable',
        data: [
            17936.93,
            13962.15,
            13739.24
            
            
            
            
        ]
    }, {
        name: 'Non-Collecatble',
        data: [
            227762.59,
            271115.52,
            311539.68
           
            
            
            
        ]
    }, {
        name: 'Claim',
        data: [
            1029,
            1524,
            1519
           
            
]

    }, {
        name: 'Average Per Visits',
        data: [
            127.74,
            105.26,
            114.13
           


            
        ]
    }
]
export const tenthChargesSAM = [//2023 - jan,feb,mar
    {
        name: 'Charges',
        data: [
            419307.83, 
            424729.61, 
            507144.53 
           
          

        ]
    },
    {
        name: 'Payment',
        data: [
            127059.66, 
            134731.89, 
            146741.15 

            
        ]
    }, {
        name: 'Collectable',
        data: [
            10738.54, 
            10373.08, 
            22275.55            

            
        ]
    }, {
        name: 'Non-Collecatble',
        data: [
            246540.05, 
            275458.61, 
            303015.07 
           
            
        ]
    }, {
        name: 'Claim',
        data: [
            1183,
            2513,
            1336
        
            
]

    }, {
        name: 'Average Per Visits',
        data: [
            107.40,
            53.61,
            109.84
            
        ]
    }
]
export const eleventhChargesSAM = [//2023 - apr,may,jun
    {
        name: 'Charges',
        data: [
            396490.89, 
            474574.80, 
            484657.35           

        ]
    },
    {
        name: 'Payment',
        data: [
            130246.14, 
            160108.61, 
            142970.45           
            
        ]
    }, {
        name: 'Collectable',
        data: [
            15223.74, 
            17184.43, 
            9233.63            
            
            
        ]
    }, {
        name: 'Non-Collecatble',
        data: [
            252830.64, 
            326893.41, 
            294057.17           
            
            
        ]
    }, {
        name: 'Claim',
        data: [
            1151,
            1215,
            1280

]

    }, {
        name: 'Average Per Visits',
        data: [
            113.16, 
            131.78, 
            111.70            
        ]
    }
]
export const twelvethChargesSAM = [// 2023 - july,aug,sep
    {
        name: 'Charges',
        data: [
            433521.88, 
            489582.85, 
            534181.68           
        ]
    },
    {
        name: 'Payment',
        data: [
            138886.25, 
            143517.76, 
            159041.02            
            
        ]
    }, {
        name: 'Collectable',
        data: [
            14114.94, 
            16346.81, 
            16799.57          
            
        ]
    }, {
        name: 'Non-Collecatble',
        data: [
            281989.05, 
            281353.32, 
            309174.02           
            
        ]
    }, {
        name: 'Claim',
        data: [
            1158,
            1468,
            1412

            
]

    }, {
        name: 'Average Per Visits',
        data: [
            119.94, 
            98.29, 
            112.64 
           
        ]
    }
]
export const thirteenthChargesSAM = [// 2023 - oct,nov,dec
    {
        name: 'Charges',
        data: [
            532560.22, 
            503639.82, 
            517108.16
           
        ]
    },
    {
        name: 'Payment',
        data: [
            167155.18, 
            168352.96, 
            178280.39
           
        ]
    }, {
        name: 'Collectable',
        data: [
            19598.70, 
            15381.24, 
            25988.00 
           
        ]
    }, {
        name: 'Non-Collecatble',
        data: [
            326653.60, 
            338502.86, 
            324309.25 
           
        ]
    }, {
        name: 'Claim',
        data: [
            1443,
            1285,
            1410

        ]

    }, {
        name: 'Average Per Visits',
        data: [
            115.84, 
            131.01, 
            126.44
           
        ]
    }
]
export const fourteenthChargesSAM = [// 2024 - Jan , Feb ,Mar
    {
        name: 'Charges',
        data: [
            408448.25, 
            393186.50,
            640308.33
           
        ]
    },
    {
        name: 'Payment',
        data: [
            145511.88, 
            122979.68,
            136401.86

           
        ]
    }, {
        name: 'Collectable',
        data: [
            15316.32, 
            17398.36,
            20331.50

           
        ]
    }, {
        name: 'Non-Collecatble',
        data: [
            287804.41, 
            274631.32,
            290482.91

        ]
    }, {
        name: 'Claim',
        data: [
            1261,
            1138.00,
            1746.00

        ]

    }, {
        name: 'Average Per Visits',
        data: [
            115.39, 
            108.07,
            78.12

        ]
    }
]

export const fifteenthChargesSAM = [// 2024 - Apr , May , Jun
    {
        name: 'Charges',
        data: [
            433608.50,
            446029.93,
            499110.98

        ]
    },
    {
        name: 'Payment',
        data: [
            171985.37,
            146113.96,
            144902.29
        ]
    }, {
        name: 'Collectable',
        data: [
            12055.78,
            14356.71,
            38012.28
        ]
    }, {
        name: 'Non-Collecatble',
        data: [
            363295.53,
            302770.81,
            282554.81
        ]
    }, {
        name: 'Claim',
        data: [
            1200,
            1223,
            1449
        ]

    }, {
        name: 'Average Per Visits',
        data: [
            143.32,
            119.47,
            100
        ]
    }
]
export const sixteenthChargesSAM = [// 2024 - Jul, Aug, Sep
    {
        name: 'Charges',
        data: [
            431042.78,
            504639.18,
            0

        ]
    },
    {
        name: 'Payment',
        data: [
            157512.41,
            174682.05,
            0
        ]
    }, {
        name: 'Collectable',
        data: [
            19254.69,
            18129.42,
            0
        ]
    }, {
        name: 'Non-Collecatble',
        data: [
            300238.39,
            352260.46,
            0
        ]
    }, {
        name: 'Claim',
        data: [
            1234,
            1405,
            0
        ]

    }, {
        name: 'Average Per Visits',
        data: [
            127.64,
            124.33,
            0
        ]
    }
]
// export const tenChargesSAM = [
//     {
//         name: 'Charges',
//         data: [
//             382666.4,
// 514918.21,
// 0,


//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             131510.2,
//             159746.36,
//         0,
//         ]
//     }, {
//         name: 'Collectable',
//         data: [
//             228051.39,
//             269754.63,
//             0,
            
//         ]
//     }, {
//         name: 'Non-Collecatble',
//         data: [
//             18006.84,
//             14141.87,
//             0,
            
//         ]
//     }, {
//         name: 'Claim',
//         data: [
//             1029,
//             1524,
//             0,
// ]

//     }, {
//         name: 'Average Per Visits',
//         data: [
//             420.3787658,
//             391.913143,
//             0,
//         ]
//     }
// ]
//second


//thirds
