export const option = ['Provider1', 'Provider2', 'Provider3', 'Provider4', 'Provider5', 'Provider6', 'Provider7']
const from = [
    'Jan-2020', 'Feb-2020', 'Mar-2020',
    'Apr-2020', 'May-2020', 'Jun-2020', 'July-2020', 'Aug-2020', 'Sep-2020', 'Oct-2020', 'Nov-2020', 'Dec-2020',
    'Jan-2021', 'Feb-2021', 'Mar-2021',
    'Apr-2021', 'May-2021', 'Jun-2021', 'July-2021', 'Aug-2021', 'Sep-2021', 'Oct-2021', 'Nov-2021', 'Dec-2021',
    'Jan-2022', 'Feb-2022', 'Mar-2022', 'Apr-2022', 'May-2022', 'Jun-2022', 'Sept-2022', 'Oct-2022', 'Nov-2022']
const to = [
    'Oct-2020', 'Nov-2020', 'Dec-2020',
    'Jan-2021', 'Feb-2021', 'Mar-2021',
    'Apr-2021', 'May-2021', 'Jun-2021', 'July-2021', 'Aug-2021', 'Sep-2021', 'Oct-2021', 'Nov-2021', 'Dec-2021',
    'Jan-2022', 'Feb-2022', 'Mar-2022', 'Apr-2022', 'May-2022', 'Jun-2022', 'Jul-2022', 'Aug-2022', 'Sept-2022', 'Oct-2022', 'Nov-2022'
]

export const fromDate = sessionStorage.getItem('name') === 'Aurora' ? from : sessionStorage.getItem('name') === 'PersonalClinic' ?
   to : ''
export const toDates = sessionStorage.getItem('name') === 'Aurora' ? from : sessionStorage.getItem('name') === 'PersonalClinic' ?
   to : ''

