

export const jan2020Series = [
    {
        name: 'Charges',
        data: [
            200017.51


        ]
    },
    {
        name: 'Payment',
        data: [
            36223.50


        ]
    },
    {
        name: 'Collectable',
        data: [
            3669.03


        ]
    },
    {
        name: 'Non Collectable',
        data: [
            160838.99

        ]
    },
    {
        name: 'Claims',
        data: [
            180


        ]
    },
    {
        name: 'Average Visit',
        data: [
            201.24
        ]
    },
]
export const feb2020Series = [
    {
        name: 'Charges',
        data: [
            97865.04


        ]
    },
    {
        name: 'Payment',
        data: [
            32849.61

        ]
    },
    {
        name: 'Collectable',
        data: [

            3510.18

        ]
    },
    {
        name: 'Non Collectable',
        data: [
            153128.20

        ]
    },
    {
        name: 'Claims',
        data: [
            84


        ]
    },
    {
        name: 'Average Visit',
        data: [
            391.07

        ]
    },
]
export const mar2020Series = [
    {
        name: 'Charges',
        data: [
            212288.26

        ]
    },
    {
        name: 'Payment',
        data: [
            25511.81

        ]
    },
    {
        name: 'Collectable',
        data: [
            2323.24



        ]
    },
    {
        name: 'Non Collectable',
        data: [
            117149.80

        ]
    },
    {
        name: 'Claims',
        data: [
            164



        ]
    },
    {
        name: 'Average Visit',
        data: [
            155.56

        ]
    },
]
export const apr2020Series = [
    {
        name: 'Charges',
        data: [
            74905.76
        ]
    },
    {
        name: 'Payment',
        data: [
            23655.10
        ]
    },
    {
        name: 'Collectable',
        data: [
            2211.93
        ]
    },
    {
        name: 'Non Collectable',
        data: [

            105475.25



        ]
    },
    {
        name: 'Claims',
        data: [
            64



        ]
    },
    {
        name: 'Average Visit',
        data: [
            369.61


        ]
    },
]
export const may2020Series = [
    {
        name: 'Charges',
        data: [
            150239.00
        ]
    },
    {
        name: 'Payment',
        data: [
            26823.41
        ]
    },
    {
        name: 'Collectable',
        data: [
            4642.07



        ]
    },
    {
        name: 'Non Collectable',
        data: [
            116532.26



        ]
    },
    {
        name: 'Claims',
        data: [
            111
        ]
    },
    {
        name: 'Average Visit',
        data: [
            241.65
        ]
    },
]
export const june2020Series = [
    {
        name: 'Charges',
        data: [
            141682.28


        ]
    },
    {
        name: 'Payment',
        data: [
            20737.60

        ]
    },
    {
        name: 'Collectable',
        data: [
            11837.60


        ]
    },
    {
        name: 'Non Collectable',
        data: [
            76705.60

        ]
    },
    {
        name: 'Claims',
        data: [
            119



        ]
    },
    {
        name: 'Average Visit',
        data: [
            174.27

        ]
    },
]
export const jul2020Series = [
    {
        name: 'Charges',
        data: [

            65545.58



        ]
    },
    {
        name: 'Payment',
        data: [
            17179.09



        ]
    },
    {
        name: 'Collectable',
        data: [
            446.02



        ]
    },
    {
        name: 'Non Collectable',
        data: [

            53666.18


        ]
    },
    {
        name: 'Claims',
        data: [
            73


        ]
    },
    {
        name: 'Average Visit',
        data: [
            235.33

        ]
    },
]
export const aug2020Series = [
    {
        name: 'Charges',
        data: [
            329915.10



        ]
    },
    {
        name: 'Payment',
        data: [
            33180.83



        ]
    },
    {
        name: 'Collectable',
        data: [
            779.37



        ]
    },
    {
        name: 'Non Collectable',
        data: [
            118906.74

        ]
    },
    {
        name: 'Claims',
        data: [
            237



        ]
    },
    {
        name: 'Average Visit',
        data: [
            140.00
        ]
    },
]
export const sept2020Series = [
    {
        name: 'Charges',
        data: [
            169139.05



        ]
    },
    {
        name: 'Payment',
        data: [
            48289.17



        ]
    },
    {
        name: 'Collectable',
        data: [
            2071.57
        ]
    },
    {
        name: 'Non Collectable',
        data: [

            199762.49



        ]
    },
    {
        name: 'Claims',
        data: [
            57



        ]
    },
    {
        name: 'Average Visit',
        data: [
            307.57
        ]
    },
]
export const oct2020Series = [
    {
        name: 'Charges',
        data: [
            233808.82



        ]
    },
    {
        name: 'Payment',
        data: [
            36819.70

        ]
    },
    {
        name: 'Collectable',
        data: [
            5914.66

        ]
    },
    {
        name: 'Non Collectable',
        data: [
            114484.97

        ]
    },
    {
        name: 'Claims',
        data: [
            173



        ]
    },
    {
        name: 'Average Visit',
        data: [
            212.83

        ]
    },
]
export const nov2020Series = [
    {
        name: 'Charges',
        data: [
            178929.16



        ]
    },
    {
        name: 'Payment',
        data: [
            31229.31

        ]
    },
    {
        name: 'Collectable',
        data: [
            1225.77



        ]
    },
    {
        name: 'Non Collectable',
        data: [
            111264.87


        ]
    },
    {
        name: 'Claims',
        data: [
            140


        ]
    },
    {
        name: 'Average Visit',
        data: [
            223.07

        ]
    },
]
export const dec2020Series = [
    {
        name: 'Charges',
        data: [
            189988.54

        ]
    },
    {
        name: 'Payment',
        data: [
            52961.14



        ]
    },
    {
        name: 'Collectable',
        data: [
            1435.49



        ]
    },
    {
        name: 'Non Collectable',
        data: [
            184955.86



        ]
    },
    {
        name: 'Claims',
        data: [
            137

        ]
    },
    {
        name: 'Average Visit',
        data: [
            386.58

        ]
    },
]
////
export const jan2021Series = [
    {
        name: 'Charges',
        data: [
            147969.85
        ]
    },
    {
        name: 'Payment',
        data: [
            64947.23


        ]
    },
    {
        name: 'Collectable',
        data: [
            7169.47

        ]
    },
    {
        name: 'Non Collectable',
        data: [
            276851.47


        ]
    },
    {
        name: 'Claims',
        data: [
            115


        ]
    },
    {
        name: 'Average Visit',
        data: [
            564.76

        ]
    },
]
export const feb2021Series = [
    {
        name: 'Charges',
        data: [
            171945.61



        ]
    },
    {
        name: 'Payment',
        data: [
            25514.88


        ]
    },
    {
        name: 'Collectable',
        data: [
            4646.81



        ]
    },
    {
        name: 'Non Collectable',
        data: [
            98414.90

        ]
    },
    {
        name: 'Claims',
        data: [
            35

        ]
    },
    {
        name: 'Average Visit',
        data: [
            189.00

        ]
    },
]
export const mar2021Series = [
    {
        name: 'Charges',
        data: [

            32384.67


        ]
    },
    {
        name: 'Payment',
        data: [
            32384.67



        ]
    },
    {
        name: 'Collectable',
        data: [
            3069.15



        ]
    },
    {
        name: 'Non Collectable',
        data: [

            110362.61



        ]
    },
    {
        name: 'Claims',
        data: [
            134



        ]
    },
    {
        name: 'Average Visit',
        data: [
            241.68


        ]
    },
]
export const apr2021Series = [
    {
        name: 'Charges',
        data: [

            180682.39





        ]
    },
    {
        name: 'Payment',
        data: [
            57204.33


        ]
    },
    {
        name: 'Collectable',
        data: [
            2609.59


        ]
    },
    {
        name: 'Non Collectable',
        data: [

            178390.91



        ]
    },
    {
        name: 'Claims',
        data: [
            151



        ]
    },
    {
        name: 'Average Visit',
        data: [
            378.84


        ]
    },
]
export const may2021Series = [
    {
        name: 'Charges',
        data: [

            167814.37

        ]
    },
    {
        name: 'Payment',
        data: [
            37329.14
        ]
    },
    {
        name: 'Collectable',
        data: [
            3778.90


        ]
    },
    {
        name: 'Non Collectable',
        data: [
            128253.94


        ]
    },
    {
        name: 'Claims',
        data: [
            139



        ]
    },
    {
        name: 'Average Visit',
        data: [
            268.55


        ]
    },
]
export const june2021Series = [
    {
        name: 'Charges',
        data: [

            202408.34
        ]
    },
    {
        name: 'Payment',
        data: [
            40959.48



        ]
    },
    {
        name: 'Collectable',
        data: [
            2709.73

        ]
    },
    {
        name: 'Non Collectable',
        data: [
            120755.62



        ]
    },
    {
        name: 'Claims',
        data: [
            153

        ]
    },
    {
        name: 'Average Visit',
        data: [
            267.71


        ]
    },
]
export const jul2021Series = [
    {
        name: 'Charges',
        data: [

            170959.00





        ]
    },
    {
        name: 'Payment',
        data: [
            180224.09

        ]
    },
    {
        name: 'Collectable',
        data: [
            11145.33

        ]
    },
    {
        name: 'Non Collectable',
        data: [
            180224.09

        ]
    },
    {
        name: 'Claims',
        data: [
            130


        ]
    },
    {
        name: 'Average Visit',
        data: [
            411.93


        ]
    },
]
export const aug2021Series = [
    {
        name: 'Charges',
        data: [

            179601.71



        ]
    },
    {
        name: 'Payment',
        data: [
            148888.31


        ]
    },
    {
        name: 'Collectable',
        data: [
            7259.45


        ]
    },
    {
        name: 'Non Collectable',
        data: [
            148888.31


        ]
    },
    {
        name: 'Claims',
        data: [
            143


        ]
    },
    {
        name: 'Average Visit',
        data: [
            345.70

        ]
    },
]
export const sept2021Series = [
    {
        name: 'Charges',
        data: [

            141883.35





        ]
    },
    {
        name: 'Payment',
        data: [
            41145.10


        ]
    },
    {
        name: 'Collectable',
        data: [
            5528.69

        ]
    },
    {
        name: 'Non Collectable',
        data: [

            141883.35


        ]
    },
    {
        name: 'Claims',
        data: [
            130

        ]
    },
    {
        name: 'Average Visit',
        data: [
            316.50


        ]
    },
]
export const oct2021Series = [
    {
        name: 'Charges',
        data: [

            194529.00





        ]
    },
    {
        name: 'Payment',
        data: [
            158421.85
        ]
    },
    {
        name: 'Collectable',
        data: [
            6538.99

        ]
    },
    {
        name: 'Non Collectable',
        data: [
            158421.85


        ]
    },
    {
        name: 'Claims',
        data: [
            157

        ]
    },
    {
        name: 'Average Visit',
        data: [
            299.27

        ]
    },
]
export const nov2021Series = [
    {
        name: 'Charges',
        data: [

            130257.96



        ]
    },
    {
        name: 'Payment',
        data: [
            42560.71


        ]
    },
    {
        name: 'Collectable',
        data: [
            5957.15


        ]
    },
    {
        name: 'Non Collectable',
        data: [
            130257.96

        ]
    },
    {
        name: 'Claims',
        data: [
            148


        ]
    },
    {
        name: 'Average Visit',
        data: [
            287.57
        ]
    },
]
export const dec2021Series = [
    {
        name: 'Charges',
        data: [

            152976.76


        ]
    },
    {
        name: 'Payment',
        data: [
            50734.17



        ]
    },
    {
        name: 'Collectable',
        data: [
            17786.22

        ]
    },
    {
        name: 'Non Collectable',
        data: [
            152976.76



        ]
    },
    {
        name: 'Claims',
        data: [
            177
        ]
    },
    {
        name: 'Average Visit',
        data: [
            286.63

        ]
    },
]

///2022

// export const jan2022Series = [
//     {
//         name: 'Charges',
//         data: [
//             147095.04

//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             41727.76

//         ]
//     },
//     {
//         name: 'Collectable',
//         data: [
//             15381.06
//         ]
//     },
//     {
//         name: 'Non Collectable',
//         data: [
//             108936.82

//         ]
//     },
//     {
//         name: 'Claims',
//         data: [
//             124

//         ]
//     },
//     {
//         name: 'Average Visit',
//         data: [
//             336.5141935
//         ]
//     },
// ]
// export const feb2022Series = [
//     {
//         name: 'Charges',
//         data: [
//             123579.28

//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             41810.2


//         ]
//     },
//     {
//         name: 'Collectable',
//         data: [
//             16117.08


//         ]
//     },
//     {
//         name: 'Non Collectable',
//         data: [
//             137512.69
//         ]
//     },
//     {
//         name: 'Claims',
//         data: [
//             98
//         ]
//     },
//     {
//         name: 'Average Visit',
//         data: [
//             426.6346939
//         ]
//     },
// ]
// export const mar2022Series = [
//     {
//         name: 'Charges',
//         data: [
//             152113.47

//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             30048.18

//         ]
//     },
//     {
//         name: 'Collectable',
//         data: [
//             6211.12


//         ]
//     },
//     {
//         name: 'Non Collectable',
//         data: [

//             77614.33
//         ]
//     },
//     {
//         name: 'Claims',
//         data: [
//             138


//         ]
//     },
//     {
//         name: 'Average Visit',
//         data: [
//             217.7404348

//         ]
//     },
// ]
// export const apr2022Series = [
//     {
//         name: 'Charges',
//         data: [
//             90365
//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             31459.37

//         ]
//     },
//     {
//         name: 'Collectable',
//         data: [
//             1616.80

//         ]
//     },
//     {
//         name: 'Non Collectable',
//         data: [

//             91045.12


//         ]
//     },
//     {
//         name: 'Claims',
//         data: [
//             81


//         ]
//     },
//     {
//         name: 'Average Visit',
//         data: [
//             388.39
//         ]
//     },
// ]
// export const may2022Series = [
//     {
//         name: 'Charges',
//         data: [
//             135785.24


//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             25267.96

//         ]
//     },
//     {
//         name: 'Collectable',
//         data: [
//             1804.76

//         ]
//     },
//     {
//         name: 'Non Collectable',
//         data: [
//             72383.57

//         ]
//     },
//     {
//         name: 'Claims',
//         data: [
//             110


//         ]
//     },
//     {
//         name: 'Average Visit',
//         data: [
//             229.71

//         ]
//     },
// ]
// export const jun2022Series = [
//     {
//         name: 'Charges',
//         data: [
//             184484.48
//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             38257.31
//         ]
//     },
//     {
//         name: 'Collectable',
//         data: [
//             16042.84
//         ]
//     },
//     {
//         name: 'Non Collectable',
//         data: [
//             112706.87



//         ]
//     },
//     {
//         name: 'Claims',
//         data: [
//             148
//         ]
//     },
//     {
//         name: 'Average Visit',
//         data: [
//             258.50

//         ]
//     },
// ]
// export const jul2022Series = [
//     {
//         name: 'Charges',
//         data: [
//             144727.08
//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             40085.43

//         ]
//     },
//     {
//         name: 'Collectable',
//         data: [
//             6297.12
//         ]
//     },
//     {
//         name: 'Non Collectable',
//         data: [
//             112025.71
//         ]
//     },
//     {
//         name: 'Claims',
//         data: [
//             115



//         ]
//     },
//     {
//         name: 'Average Visit',
//         data: [
//             348.57


//         ]
//     },
// ]
// export const aug2022Series = [
//     {
//         name: 'Charges',
//         data: [
//             111474.78
//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             41420.44

//         ]
//     },
//     {
//         name: 'Collectable',
//         data: [
//             1972.4


//         ]
//     },
//     {
//         name: 'Non Collectable',
//         data: [
//             129932.81
//         ]
//     },
//     {
//         name: 'Claims',
//         data: [
//             106



//         ]
//     },
//     {
//         name: 'Average Visit',
//         data: [
//             390.76

//         ]
//     },
// ]
// export const sept2022Series = [
//     {
//         name: 'Charges',
//         data: [
//             108228


//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             33039.98


//         ]
//     },
//     {
//         name: 'Collectable',
//         data: [
//             6022.46
//         ]
//     },
//     {
//         name: 'Non Collectable',
//         data: [

//             86852.02

//         ]
//     },
//     {
//         name: 'Claims',
//         data: [
//             88

//         ]
//     },
//     {
//         name: 'Average Visit',
//         data: [
//             375.4543182
//         ]
//     },
// ]

// export const oct2022Series = [
//     {
//         name: 'Charges',
//         data: [

//             108534


//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             31236.42



//         ]
//     },
//     {
//         name: 'Collectable',
//         data: [
//             872.98

//         ]
//     },
//     {
//         name: 'Non Collectable',
//         data: [

//             87087.91

//         ]
//     },
//     {
//         name: 'Claims',
//         data: [
//             123



//         ]
//     },
//     {
//         name: 'Average Visit',
//         data: [
//             253.9546341


//         ]
//     },
// ]
// export const nov2022Series = [
//     {
//         name: 'Charges',
//         data: [

//             118235
//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             27135.48



//         ]
//     },
//     {
//         name: 'Collectable',
//         data: [
//             906.9

//         ]
//     },
//     {
//         name: 'Non Collectable',
//         data: [

//             87204.88


//         ]
//     },
//     {
//         name: 'Claims',
//         data: [
//             109


//         ]
//     },
//     {
//         name: 'Average Visit',
//         data: [
//             248.95
//         ]
//     },
// ]
// export const dec2022Series = [
//     {
//         name: 'Charges',
//         data: [

//             112412
//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             32563.75
//         ]
//     },
//     {
//         name: 'Collectable',
//         data: [
//             25158.91

//         ]
//     },
//     {
//         name: 'Non Collectable',
//         data: [

//             102323.13

//         ]
//     },
//     {
//         name: 'Claims',
//         data: [
//             90


//         ]
//     },
//     {
//         name: 'Average Visit',
//         data: [
//             361.82
//         ]
//     },
// ]

///2023

// export const jan2023Series = [
//     {
//         name: 'Charges',
//         data: [
//             121645.56


//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             16608.45




//         ]
//     },
//     {
//         name: 'Collectable',
//         data: [
//             4801.58

//         ]
//     },
//     {
//         name: 'Non Collectable',
//         data: [

//             61008.37


//         ]
//     },
//     {
//         name: 'Claims',
//         data: [
//             110


//         ]
//     },
//     {
//         name: 'Average Visit',
//         data: [
//             150.99
//         ]
//     },
// ]
// export const feb2023Series = [
//     {
//         name: 'Charges',
//         data: [

//             51109




//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             22276.56



//         ]
//     },
//     {
//         name: 'Collectable',
//         data: [
//             -348.14

//         ]
//     },
//     {
//         name: 'Non Collectable',
//         data: [

//             78890.52

//         ]
//     },
//     {
//         name: 'Claims',
//         data: [
//             55



//         ]
//     },
//     {
//         name: 'Average Visit',
//         data: [
//             405.03
//         ]
//     },
// ]
// export const mar2023Series = [
//     {
//         name: 'Charges',
//         data: [

//             135319





//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             28900.62



//         ]
//     },
//     {
//         name: 'Collectable',
//         data: [
//             3261.16

//         ]
//     },
//     {
//         name: 'Non Collectable',
//         data: [

//             97653.16

//         ]
//     },
//     {
//         name: 'Claims',
//         data: [
//             117



//         ]
//     },
//     {
//         name: 'Average Visit',
//         data: [
//             247.01
//         ]
//     },
// ]
// export const apr2023Series = [
//     {
//         name: 'Charges',
//         data: [

//             128411



//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             25559.7



//         ]
//     },
//     {
//         name: 'Collectable',
//         data: [
//             4484.63

//         ]
//     },
//     {
//         name: 'Non Collectable',
//         data: [

//             58972.81

//         ]
//     },
//     {
//         name: 'Claims',
//         data: [
//             110



//         ]
//     },
//     {
//         name: 'Average Visit',
//         data: [
//             232.362
//         ]
//     },
// ]
// export const may2023Series = [
//     {
//         name: 'Charges',
//         data: [

//             137840.04




//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             27936.1



//         ]
//     },
//     {
//         name: 'Collectable',
//         data: [
//             7105.55

//         ]
//     },
//     {
//         name: 'Non Collectable',
//         data: [

//             96544.37

//         ]
//     },
//     {
//         name: 'Claims',
//         data: [
//             109



//         ]
//     },
//     {
//         name: 'Average Visit',
//         data: [
//             256.29
//         ]
//     },
// ]

// export const jun2023Series = [
//     {
//         name: 'Charges',
//         data: [

//             169756.52




//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             33230.84



//         ]
//     },
//     {
//         name: 'Collectable',
//         data: [
//             21776.81

//         ]
//     },
//     {
//         name: 'Non Collectable',
//         data: [

//             94347.64

//         ]
//     },
//     {
//         name: 'Claims',
//         data: [
//             134



//         ]
//     },
//     {
//         name: 'Average Visit',
//         data: [
//             247.99
//         ]
//     },
// ]

// export const jul2023Series = [
//     {
//         name: 'Charges',
//         data: [

//             90351




//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             25944.87



//         ]
//     },
//     {
//         name: 'Collectable',
//         data: [
//             6393.08

//         ]
//     },
//     {
//         name: 'Non Collectable',
//         data: [

//             68782.4

//         ]
//     },
//     {
//         name: 'Claims',
//         data: [
//             76



//         ]
//     },
//     {
//         name: 'Average Visit',
//         data: [
//             341.38
//         ]
//     },
// ]
// export const aug2023Series = [
//     {
//         name: 'Charges',
//         data: [

//             158556




//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             26281.65



//         ]
//     },
//     {
//         name: 'Collectable',
//         data: [
//             14581.92

//         ]
//     },
//     {
//         name: 'Non Collectable',
//         data: [

//             83083.48

//         ]
//     },
//     {
//         name: 'Claims',
//         data: [
//             133
//         ]
//     },
//     {
//         name: 'Average Visit',
//         data: [
//             197.61
//         ]
//     },
// ]
// export const sep2023Series = [
//     {
//         name: 'Charges',
//         data: [

//             123723

//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             52423.67

//         ]
//     },
//     {
//         name: 'Collectable',
//         data: [
//             2457.18

//         ]
//     },
//     {
//         name: 'Non Collectable',
//         data: [
//             149435.23
//         ]
//     },
//     {
//         name: 'Claims',
//         data: [
//             103

//         ]
//     },
//     {
//         name: 'Average Visit',
//         data: [
//             508.97
//         ]
//     },
// ]
// export const oct2023Series = [
//     {
//         name: 'Charges',
//         data: [
//             109267
//         ]
//     },
//     {
//         name: 'Payment',
//         data: [
//             33257.13
//         ]
//     },
//     {
//         name: 'Collectable',
//         data: [
//             3989.12
//         ]
//     },
//     {
//         name: 'Non Collectable',
//         data: [
//             87345.09
//         ]
//     },
//     {
//         name: 'Claims',
//         data: [
//             98

//         ]
//     },
//     {
//         name: 'Average Visit',
//         data: [
//             339.36
//         ]
//     },
// ]

///////personal clinic data

export const oct2020SeriesPer = [

    {
        name: 'Charges',
        data: [
            899,
            230220.74,
            150111.7,
            188784.99,
            0

        ]
    },
    {
        name: 'Payment',
        data: [
            0,
            48322.13,
            42467.71,
            47399.19,
            231.57,



        ]
    },
    {
        name: 'Collectable',
        data: [

            0,
            141596.76,
            105970.11,
            133879.3,
            0,



        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            141596.76,
            105970.11,
            133879.3,
            0,



        ]
    },
    {
        name: 'Claims',
        data: [
            1,
            539,
            291,
            332,
            0,





        ]
    },
    {
        name: 'Average Visit',
        data: [
            0,
            89.65144712,
            145.9371478,
            142.7686446,
            0,



        ]
    },
]
export const nov2020SeriesPer = [
    {
        name: 'Charges',
        data: [
            0,
            226569.28,
            212911.65,
            221453.97,
            0,





        ]
    },
    {
        name: 'Payment',
        data: [
            0,
            46145.72,
            51108.33,
            58287.85,
            231.5,

        ]
    },
    {
        name: 'Collectable',
        data: [
            0,
            13884.64,
            5029.87,
            8812.63,
            10531.14,







        ]
    },
    {
        name: 'Non Collectable',
        data: [


            0,
            144906.3,
            134886.56,
            155650.6,
            0,


        ]
    },
    {
        name: 'Claims',
        data: [
            0,
            648,
            457,
            515,
            0,





        ]
    },
    {
        name: 'Average Visit',
        data: [
            0,
            71.21253086,
            111.8344201,
            113.1802913,
            0,



        ]
    },
]
export const dec2020SeriesPer = [

    {
        name: 'Charges',
        data: [
            0,
            154510.3,
            150993.9,
            175156.38,
            0,



        ]
    },
    {
        name: 'Payment',
        data: [
            0,
            52745.87,
            43399.56,
            55733.24,
            164.01,

        ]
    },
    {
        name: 'Collectable',
        data: [
            0,
            8090.8,
            8456.8,
            4414.52,
            411.25,

        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            154103.68,
            126629.05,
            155057.01,
            0,

        ]
    },
    {
        name: 'Claims',
        data: [
            0,
            374,
            311,
            322,
            0,


        ]
    },
    {
        name: 'Average Visit',
        data: [
            0,
            141.031738,
            139.5484244,
            173.0845963,
            0,


        ]
    },
]
////
export const jan2021SeriesPer = [
    {
        name: 'Charges',
        data: [
            0,
            215050.05,
            145050.09,
            209620.13,
            0,





        ]
    },
    {
        name: 'Payment',
        data: [
            0,
            49631.45,
            30275.31,
            39520.62,
            184.62,




        ]
    },
    {
        name: 'Collectable',
        data: [
            0,
            6960.69,
            9098.8,
            7979.46,
            35,


        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            121715.12,
            77063.72,
            113997.84,
            0,

        ]
    },
    {
        name: 'Claims',
        data: [
            0,
            564,
            320,
            431,
            0,




        ]
    },
    {
        name: 'Average Visit',
        data: [
            0,
            87.99902482,
            94.61034375,
            91.69517401,
            0,



        ]
    },
]
export const feb2021SeriesPer = [
    {
        name: 'Charges',
        data: [
            435,
            118708.05,
            103920.03,
            96816.35,
            0,

        ]
    },
    {
        name: 'Payment',
        data: [
            80,
            35050.98,
            29458.43,
            42057.78,
            394,

        ]
    },
    {
        name: 'Collectable',
        data: [
            355,
            8887.72,
            6573.61,
            4363.37,
            401.4,

        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            115171.81,
            95435.05,
            120799.87,
            0,


        ]
    },
    {
        name: 'Claims',
        data: [
            1,
            306,
            222,
            196,
            0,

        ]
    },
    {
        name: 'Average Visit',
        data: [
            80,
            114.5456863,
            132.6956306,
            214.5805102,
            0,

        ]
    }
]
export const mar2021SeriesPer = [
    {
        name: 'Charges',
        data: [
            0,
            189170.77,
            115779.88,
            173052.71,
            0
        ]
    },
    {
        name: 'Payment',
        data: [
            0,
            39295.97,
            34691.74,
            40848.11,
            623.58,
        ]
    },
    {
        name: 'Collectable',
        data: [
            0,
            12719.07,
            6817.21,
            8707.58,
            746.98,

        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            124770.43,
            88984.53,
            104823.23,
            279.56,


        ]
    },
    {
        name: 'Claims',
        data: [
            0,
            467,
            271,
            351,
            0,

        ]
    },
    {
        name: 'Average Visit',
        data: [
            0,
            84.14554604,
            128.0138007,
            116.3763818,
            0,

        ]
    },
]
export const apr2021SeriesPer = [
    {
        name: 'Charges',
        data: [
            350,
            181770.35,
            126540.02,
            161342.01,
            0,

        ]
    },
    {
        name: 'Payment',
        data: [
            110,
            38209.65,
            31390.94,
            37928.39,
            45,

        ]
    },
    {
        name: 'Collectable',
        data: [
            270,
            6056.14,
            5264.85,
            4367.64,
            0,

        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            103881.57,
            83529.56,
            101445.78,
            0,

        ]
    },
    {
        name: 'Claims',
        data: [
            1,
            480,
            295,
            334,
            0,

        ]
    },
    {
        name: 'Average Visit',
        data: [
            110,
            79.6034375,
            106.4099661,
            113.5580539,
            0,

        ]
    },
]
export const may2021SeriesPer = [
    {
        name: 'Charges',
        data: [
            624,
            174285.35,
            174723.33,
            204692.19,
            0,

        ]
    },
    {
        name: 'Payment',
        data: [
            0,
            43687.56,
            36671.52,
            39853.47,
            189.5,

        ]
    },
    {
        name: 'Collectable',
        data: [
            1283,
            5005.52,
            6165.93,
            2802.3,
            176.43,

        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            122044.18,
            93093.63,
            123143.33,
            0,

        ]
    },
    {
        name: 'Claims',
        data: [
            2,
            501,
            391,
            447,
            0,


        ]
    },
    {
        name: 'Average Visit',
        data: [
            0,
            87.20071856,
            93.78905371,
            89.15765101,
            0,

        ]
    }
]
export const june2021SeriesPer = [
    {
        name: 'Charges',
        data: [
            210,
            114932.34,
            98647.06,
            110363.1,
            0,

        ]
    },
    {
        name: 'Payment',
        data: [
            160,
            39469.75,
            32566.58,
            44457.18,
            0,

        ]
    },
    {
        name: 'Collectable',
        data: [
            130,
            6430.3,
            6637.01,
            4440.63,
            20.59,

        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            96154,
            83359.59,
            111416.21,
            0,


        ]
    },
    {
        name: 'Claims',
        data: [
            1,
            398,
            236,
            301,
            0,

        ]
    },
    {
        name: 'Average Visit',
        data: [
            160,
            0,
            0,
            0,
            0,

        ]
    },
]
export const jul2021SeriesPer = [
    {
        name: 'Charges',
        data: [
            1330,
            130764.17,
            68101.1,
            103359.03,
            0,

        ]
    },
    {
        name: 'Payment',
        data: [
            240,
            49218.91,
            32626.93,
            41674.48,
            188.28,

        ]
    },
    {
        name: 'Collectable',
        data: [
            800,
            15362.16,
            7205.15,
            16469.25,
            11592.35,

        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            89047.03,
            61066.83,
            77939.84,
            0,

        ]
    },
    {
        name: 'Claims',
        data: [
            6,
            487,
            170,
            293,
            0,

        ]
    },
    {
        name: 'Average Visit',
        data: [
            40,
            101.0655236,
            191.9231176,
            142.2337201,
            0,

        ]
    },
]
export const aug2021SeriesPer = [
    {
        name: 'Charges',
        data: [
            0,
            47791,
            126697.15,
            121955.04,
            0,

        ]
    },
    {
        name: 'Payment',
        data: [
            80,
            38019.06,
            32896.24,
            43172.76,
            689.12,

        ]
    },
    {
        name: 'Collectable',
        data: [
            130,
            22182.48,
            2674.57,
            21492.58,
            1093.69,

        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            69706.17,
            78628.45,
            89307.6,
            87.5,

        ]
    },
    {
        name: 'Claims',
        data: [
            1,
            185,
            312,
            322,
            0,

        ]
    },
    {
        name: 'Average Visit',
        data: [
            80,
            205.5084324,
            105.4366667,
            134.0768944,
            0,

        ]
    },
]
export const sept2021SeriesPer = [
    {
        name: 'Charges',
        data: [
            210,
            152814.21,
            95614.96,
            139313.69,
            0,

        ]
    },
    {
        name: 'Payment',
        data: [
            90,
            35213.47,
            42059.61,
            43481.42,
            13,

        ]
    },
    {
        name: 'Collectable',
        data: [
            0,
            9271.75,
            12976.35,
            7000.55,
            1283.08,

        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            61481.62,
            75681.57,
            87818.57,
            0,

        ]
    },
    {
        name: 'Claims',
        data: [
            1,
            476,
            260,
            358,
            0,

        ]
    },
    {
        name: 'Average Visit',
        data: [
            90,
            73.97787815,
            161.7677308,
            121.4564804,
            0,

        ]
    },
]
export const oct2021SeriesPer = [
    {
        name: 'Charges',
        data: [
            0,
            249689.6,
            165976.83,
            216561.47,
            0,

        ]
    },
    {
        name: 'Payment',
        data: [
            0,
            62625.35,
            43597.99,
            44330.49,
            91.07,

        ]
    },
    {
        name: 'Collectable',
        data: [
            210,
            5687.75,
            1264.26,
            3831.03,
            40.45,

        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            121005.29,
            86718.41,
            100632.14,
            39.38,

        ]
    },
    {
        name: 'Claims',
        data: [
            0,
            715,
            398,
            472,
            0,

        ]
    },
    {
        name: 'Average Visit',
        data: [
            0,
            87.5879021,
            109.5426884,
            93.92052966,
            0,

        ]
    },
]
export const nov2021SeriesPer = [
    {
        name: 'Charges',
        data: [
            0,
            92528.32,
            133879.6,
            130532.24,
            0,

        ]
    },
    {
        name: 'Payment',
        data: [
            0,
            57656.52,
            48542.5,
            40509.6,
            233.71,

        ]
    },
    {
        name: 'Collectable',
        data: [
            0,
            6289.79,
            3378.84,
            7627.03,
            0,

        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            103975.2,
            92516.98,
            93723.21,
            0,

        ]
    },
    {
        name: 'Claims',
        data: [
            0,
            286,
            350,
            291,
            0,

        ]
    },
    {
        name: 'Average Visit',
        data: [
            0,
            201.5962238,
            138.6928571,
            139.2082474,
            0

        ]
    },
]
export const dec2021SeriesPer = [
    {
        name: 'Charges',
        data: [
            0,
            101032.08,
            108237.38,
            155504.04,
            0,

        ]
    },
    {
        name: 'Payment',
        data: [
            0,
            49413.62,
            40631.45,
            66025.99,
            68.12,


        ]
    },
    {
        name: 'Collectable',
        data: [
            0,
            5407.16,
            2707.57,
            5123.17,
            0,

        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            85319.3,
            78447.03,
            115397.98,
            0,


        ]
    },
    {
        name: 'Claims',
        data: [
            0,
            384,
            290,
            413,
            0,

        ]
    },
    {
        name: 'Average Visit',
        data: [
            0,
            128.6813021,
            140.1084483,
            159.8692252,
            0,

        ]
    },
]
///2022,

export const jan2022SeriesPer = [
    {
        name: 'Charges',
        data: [
            0,
            239640.24,
            104415,
            137862.22,
            0,
            0
        ]
    },
    {
        name: 'Payment',
        data: [
            0,
            45825.51, 
            32057.61, 
            54438.4, 
            40.27,
            0
        ]
    },
    {
        name: 'Collectable',
        data: [
            0,
            4354.95,
            4289.61,
            4251.7,
            80,
            0
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            89438.36,
            57538.84,
            74003.69,
            78.39,
            0
        ]
    },
    {
        name: 'Claims',
        data: [
            0,
            714,
            354,
            310,
            0,
            0
        ]
    },
    {
        name: 'Average Visit',
        data: [
            0,
            64.18138655,
            90.55822034,
            175.6077419,
            0,
            0
        ]
    }
]
export const feb2022SeriesPer = [
    {
        name: 'Charges',
        data: [
            70,
            145875.37,
            83841.01,
            131972.32,
            0,
            0
        ]
    },
    {
        name: 'Payment',
        data: [
            0,
            54400.43,
            31151.23,
            41956.73,
            149.87,
            0
        ]
    },
    {
        name: 'Collectable',
        data: [
            210,
            7465.48,
            3410.67,
            5116.64,
            205.15,
            0
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            116736.61,
            66240.02,
            94476.04,
            55.28,
            0
        ]
    },
    {
        name: 'Claims',
        data: [
            2,
            592,
            306,
            398,
            0,
            0
        ]
    },
    {
        name: 'Average Visit',
        data: [
            0,
            91.89261824,
            101.8014052,
            105.4189196,
            0,
            0
        ]
    }
]
export const mar2022SeriesPer = [
    {
        name: 'Charges',
        data: [
            2052,
            115499.95,
            91654.01,
            131361.09,
            0,
            0
        ]
    },
    {
        name: 'Payment',
        data: [
            305,
            65955.13,
            31169.33,
            43432.27,
            0,
            0

        ]
    },
    {
        name: ' Collectable',
        data: [
            1642,
            13074.35,
            4861.54,
            7469.46,
            130.27,
            0

        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            94249.74,
            62684.13,
            74805.49,
            0,
            0
        ]
    },
    {
        name: 'Claims',
        data: [
            8,
            385,
            300,
            331,
            0,
            0

        ]
    },
    {
        name: 'Average Visit',
        data: [
            38.13,
            171.312026,
            103.8977667,
            131.2153172,
            0,
            0


        ]
    }
]
export const apr2022SeriesPer = [
    {
        name: 'Charges',
        data: [
            0,
            96620.91,
            116275.2,
            131018.79,
            0,
            0
        ]
    },
    {
        name: 'Payment',
        data: [
            0,
            35527.73,
            31851.57,
            46661.06,
            0,
            0
        ]
    },
    {
        name: 'Collectable',
        data: [
            0,
            6117.93,
            3250.86,
            6462.57,
            45,
            0

        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            63043.81,
            58959.78,
            93966.01,
            0,
            0
        ]
    },
    {
        name: 'Claims',
        data: [
            0,
            335,
            358,
            351,
            0,
            0
        ]
    },
    {
        name: 'Average Visit',
        data: [
            0,
            106.05,
            88.97086592,
            132.9374929,
            0,
            0
        ]
    }
]
export const may2022SeriesPer = [
    {
        name: 'Charges',
        data: [
            2528,
            118862.01,
            89558.96,
            141023.15,
            0,
            0
        ]
    },
    {
        name: 'Payment',
        data: [
            475,
            42978.85,
            35667.87,
            49868.26,
            25,
            0
        ]
    },
    {
        name: 'Collectable',
        data: [
            2053,
            10263.56,
            1609.74,
            5882.92,
            25,
            0

        ]
    },
    {
        name: 'Non Collectable',
        data: [

            0,
            84986.71,
            68711.19,
            84592.53,
            0,
            0
        ]
    },
    {
        name: 'Claims',
        data: [
            7,
            406,
            283,
            394,
            0,
            0
        ]
    },
    {
        name: 'Average Visit',
        data: [
            67.85714286,
            105.8592365,
            126.0348763,
            126.5691878,
            0,
            0
        ]
    }
]
export const june2022SeriesPer = [
    {
        name: 'Charges',
        data: [
            1866,
            118594.7,
            98343.37,
            121211.52,
            0,
            0
        ]
    },
    {
        name: 'Payment',
        data: [
            315,
            38409.23,
            36485.18,
            44341.63,
            113.6,
            0
        ]
    },
    {
        name: 'Collectable',
        data: [
            1296,
            9243.95,
            6297.62,
            5602.9,
            25,
            0
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            78980.25,
            69205.17,
            90254.27,
            0,
            0
        ]
    },
    {
        name: 'Claims',
        data: [
            6,
            371,
            341,
            334,
            0,
            0
        ]
    },
    {
        name: 'Average Visit',
        data: [
            52.5,
            103.5289218,
            106.9946628,
            132.7593713,
            0,
            0

        ]
    }
]
export const jul2022SeriesPer = [
    {
        name: 'Charges',
        data: [
            1311,
            221632.93,
            99543.49,
            125803.21,
            0,
            0
        ]
    },
    {
        name: 'Payment',
        data: [
            0,
            55366.57,
            27063.4,
            34354.5,
            43.16,
            0
        ]
    },
    {
        name: 'Collectable',
        data: [
            608,
            10357.5,
            3825.82,
            4116.45,
            0,
            0
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            109259.44,
            44910.93,
            61098.42,
            58.16,
            0
        ]
    },
    {
        name: 'Claims',
        data: [
            4,
            675,
            330,
            314,
            0,
            0
        ]
    },
    {
        name: 'Average Visit',
        data: [
            0,
            82.02454815,
            82.01030303,
            109.4092357,
            0,
            0
        ]
    }
]
export const aug2022SeriesPer = [
    {
        name: 'Charges',
        data: [
            1575,
            144934.07,
            93434.8,
            129129.11,
            0,
            0
        ]
    },
    {
        name: 'Payment',
        data: [
            115,
            57841.12,
            42242.25,
            50891.46,
            32.15,
            0
        ]
    },
    {
        name: 'Collectable',
        data: [
            0,
            10870.57,
            1219.44,
            1831.78,
            0,
            0
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            110700.32,
            79560.49,
            86847.4,
            32.15,
            0
        ]
    },
    {
        name: 'Claims',
        data: [
            14,
            443,
            298,
            336,
            0,
            0
        ]
    },
    {
        name: 'Average Visit',
        data: [
            8.214285714,
            130.5668623,
            141.7525168,
            151.4626786,
            0,
            0

        ]
    }
]
export const sept2022SeriesPer = [
    {
        name: 'Charges',
        data: [
            415,
            125124.15,
            111757.36,
            146013.69,
            0,
            0
        ]
    },
    {
        name: 'Payment',
        data: [
            35,
            40816.56,
            35373.81,
            47443.46,
            8.22,
            0
        ]
    },
    {
        name: 'Collectable',
        data: [
            0,
            13491.9,
            14738.19,
            11072.25,
            50,
            0
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            74318.44,
            59323.21,
            83444.11,
            0,
            0
        ]
    },
    {
        name: 'Claims',
        data: [
            4,
            494,
            347,
            341,
            0,
            0
        ]
    },
    {
        name: 'Average Visit',
        data: [
            8.75,
            82.62461538,
            101.9418156,
            139.1303812,
            0,
            0
        ]
    }
]
export const oct2022SeriesPer = [
    {
        name: 'Charges',
        data: [
            5114,
            138402.08,
            81466.38,
            158576.94,
            0,
            0
        ]
    },
    {
        name: 'Payment',
        data: [
            170,
            44603.81,
            29874.49,
            56920.03,
            125.09,
            0
        ]
    },
    {
        name: 'Collectable',
        data: [
            998,
            8136.22,
            4604.03,
            4198.68,
            0,
            0
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            76331.07,
            54958.65,
            96332.78,
            140.09,
            0
        ]
    },
    {
        name: 'Claims',
        data: [
            7,
            461,
            241,
            320,
            0,
            0
        ]
    },
    {
        name: 'Average Visit',
        data: [
            24.28571429,
            96.75,
            123.9605394,
            177.88,
            0,
            0
        ]
    }
]
export const nov2022SeriesPer = [
    {
        name: 'Charges',
        data: [
            1133,
            229697.46,
            132710.72,
            149514,
            0,
            0
        ]
    },
    {
        name: 'Payment',
        data: [
            190,
            57823.35,
            43835.88,
            58572.54,
            10.41,
            0
        ]
    },
    {
        name: 'Collectable',
        data: [
            1332,
            4019.72,
            3489.46,
            5120.97,
            0,
            0
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            103644.65,
            73467.73,
            94003.14,
            0,
            0
        ]
    },
    {
        name: 'Claims',
        data: [
            2,
            778,
            399,
            345,
            0,
            0
        ]
    },
    {
        name: 'Average Visit',
        data: [
            95,
            74.32307198,
            109.8643609,
            169.7754783,
            0,
            0    
        ]
    }
]
export const dec2022SeriesPer = [
    {
        name: 'Charges',
        data: [
            555,
            158689.37,
            129251.58,
            171700.19,
            0,
            0
        ]
    },
    {
        name: 'Payment',
        data: [
            95,
            63533.04,
            48475.33,
            61250.9,
            15,
            0
        ]
    },
    {
        name: 'Collectable',
        data: [
            105,
            7043.47,
            3858,
            2732.77,
            0,
            0
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            128367.69,
            82711.61,
            100460.38,
            0,
            0
        ]
    },
    {
        name: 'Claims',
        data: [
            9,
            656,
            417,
            437,
            0,
            0
        ]
    },
    {
        name: 'Average Visit',
        data: [
            10.55555556,
            96.84914634,
            116.2477938,
            140.1622426,
            0,
            0
        ]
    }
]
///2023,
export const jan2023SeriesPer = [
    {
        name: 'Charges',
        data: [
            420,
            109061.96,
            114683,
            195142.87,
            0,
            0
        ]
    },
    {
        name: 'Payment',
        data: [
            70,
            41653.26,
            34535.40,
            50777.38,
            15,
            0
        ]
    },
    {
        name: 'Collectable',
        data: [
            70,
            3099.34,
            3300.32,
            4268.88,
            0,
            0
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            84112.18,
            59367.26,
            103060.61,
            0,
            0
        ]
    },
    {
        name: 'Claims',
        data: [
            2,
            383,
            355,
            443,
            0,
            0
        ]
    },
    {
        name: 'Average Visit',
        data: [
            35,
            108.76,
            97.28,
            114.62,
            0,
            0
        ]
    }
]
export const feb2023SeriesPer = [
    {
        name: 'Charges',
        data: [
            914.77,
            171356.91,
            124785.07,
            127672.86,
            0,
            0
        ]
    },
    {
        name: 'Payment',
        data: [
            145,
            43640.19,
            38741.09,
            52215.28,
            9.67,
            0
        ]
    },
    {
        name: 'Collectable',
        data: [
            0,
            3841.82,
            2355.69,
            4175.57,
            0,
            0
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            89741.84,
            79933.65,
            105748.45,
            34.67,
            0
        ]
    },
    {
        name: 'Claims',
        data: [
            2,
            680,
            1149,
            682,
            0,
            0
        ]
    },
    {
        name: 'Average Visit',
        data: [
            72.50,
            64.18,
            33.72,
            76.56,
            0,
            0
        ]
    }
]
export const mar2023SeriesPer = [
    {
        name: 'Charges',
        data: [
            1313,
            157914.53,
            150652,
            197265,
            0,
            0
        ]
    },
    {
        name: 'Payment',
        data: [
            0,
            53453.43,
            45434.05,
            47853.67,
            0,
            0
        ]
    },
    {
        name: 'Collectable',
        data: [
            550,
            8525.19,
            5702.75,
            7497.61,
            0,
            0
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            110151.77,
            94836.99,
            98026.31,
            0,
            0
        ]
    },
    {
        name: 'Claims',
        data: [
            8,
            504,
            393,
            431,
            0,
            0
        ]
    },
    {
        name: 'Average Visit',
        data: [
            0,
            106.06,
            115.61,
            111.03,
            0,
            0
        ]
    }
]
export const apr2023SeriesPer = [
    {
        name: 'Charges',
        data: [
            60,
            136543.24,
            120066.11,
            139821.54,
            0,
            0
        ]
    },
    {
        name: 'Payment',
        data: [
            0,
            45658,
            39199.13,
            45389.01,
            0,
            0
        ]
    },
    {
        name: 'Collectable',
        data: [
            5145,
            6653.66,
            1415.99,
            1667.8,
            0,
            0
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            77239.15,
            78365.35,
            97226.14,
            0,
            0
        ]
    },
    {
        name: 'Claims',
        data: [
            2,
            479,
            330,
            340,
            0,
            0
        ]
    },
    {
        name: 'Average Visit',
        data: [
            0,
            95.32,
            118.79,
            133.50,
            0,
            0
        ]
    }
]

export const may2023SeriesPer = [
    {
        name: 'Charges',
        data: [
            620,
            192194.74,
            144756.03,
            137004.03,
            0,
            0
        ]
    },
    {
        name: 'Payment',
        data: [
            45,
            61371.26,
            43616.09,
            54679.7,
            161,
            0
        ]
    },
    {
        name: 'Collectable',
        data: [
            1261,
            7064.52,
            4215.84,
            4642.55,
            25.20,
            0
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            113227.21,
            99783.35,
            113346.11,
            69,
            0
        ]
    },
    {
        name: 'Claims',
        data: [
            2,
            514,
            361,
            338,
            0,
            0
        ]
    },
    {
        name: 'Average Visit',
        data: [
            22.50,
            119.40,
            120.82,
            161.77,
            0,
            0
        ]
    }
]
export const jun2023SeriesPer = [
    {
        name: 'Charges',
        data: [
            1813,
            181819.17,
            149093.55,
            151931.63,
            0,
            0
        ]
    },
    {
        name: 'Payment',
        data: [
            145,
            56157.32,
            39139.53,
            47528.6,
            0,
            0
        ]
    },
    {
        name: 'Collectable',
        data: [
            739,
            3397.42,
            2954.88,
            2142.33,
            0,
            0
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            96467.55,
            97024.41,
            100565.21,
            0,
            0
        ]
    },
    {
        name: 'Claims',
        data: [
            8,
            569,
            346,
            357,
            0,
            0
        ]
    },
    {
        name: 'Average Visit',
        data: [
            18.13,
            98.69,
            113.12,
            133.13,
            0,
            0
        ]
    }
]
export const jul2023SeriesPer = [
    {
        name: 'Charges',
        data: [
            0,
            137178.64,
            128109.45,
            168233.79,
            0,
            0
        ]
    },
    {
        name: 'Payment',
        data: [
            0,
            53951.09,
            38254.34,
            46680.82,
            0,
            0
        ]
    },
    {
        name: 'Collectable',
        data: [
            719,
            7509.54,
            1598.36,
            4288.04,
            0,
            0
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            99595.44,
            84784.36,
            97609.25,
            0,
            0
        ]
    },
    {
        name: 'Claims',
        data: [
            0,
            445,
            336,
            377,
            0,
            0
        ]
    },
    {
        name: 'Average Visit',
        data: [
            0,
            121.24,
            113.85,
            123.82,
            0,
            0
        ]
    }
]
export const aug2023SeriesPer = [
    {
        name: 'Charges',
        data: [
            269,
            133587.29,
            160201.86,
            160449.71,
            0,
            35074.99
        ]
    },
    {
        name: 'Payment',
        data: [
            0,
            46988.98,
            41936.99,
            52989.17,
            0,
            1602.62
        ]
    },
    {
        name: 'Collectable',
        data: [
            1676,
            4779.23,
            3371.42,
            5812.16,
            0,
            708
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            85242.14,
            94889.63,
            98831.39,
            0,
            2390.16
        ]
    },
    {
        name: 'Claims',
        data: [
            9,
            551,
            401,
            422,
            0,
            85
        ]
    },
    {
        name: 'Average Visit',
        data: [
            0,
            85.28,
            104.58,
            125.57,
            0,
            18.85
        ]
    }
]
export const sep2023SeriesPer = [
    {
        name: 'Charges',
        data: [
            1400,
            199977.13,
            138504.99,
            161200.61,
            0,
            33098.95
        ]
    },
    {
        name: 'Payment',
        data: [
            153.87,
            46006.67,
            46657.2,
            54673.18,
            8.62,
            11541.48
        ]
    },
    {
        name: 'Collectable',
        data: [
            0,
            7435.37,
            2383.13,
            5443.07,
            0,
            1538
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            82890.26,   
            100340.96,
            106264.25,
            0,
            19678.55
        ]
    },
    {
        name: 'Claims',
        data: [
            9,
            581,
            313,
            429,
            0,
            80
        ]
    },
    {
        name: 'Average Visit',
        data: [
            17.10,
            79.19,
            149.06,
            127.44,
            0,
            144.27
        ]
    }
]
export const oct2023SeriesPer = [
    {
        name: 'Charges',
        data: [
            3304,
            175655.81,
            136507.13,
            175414.41,
            0,
            41678.87
        ]
    },
    {
        name: 'Payment',
        data: [
            410,
            64737.39,
            40489.63,
            50973.84,
            0,
            10544.32
        ]
    },
    {
        name: 'Collectable',
        data: [
            1389,
            8733.23,
            3329.32,
            4700.06,
            0,
            1447.09
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            119496.74,
            81256.47,
            103354.68,
            0,
            22545.71
        ]
    },
    {
        name: 'Claims',
        data: [
            15,
            537,
            346,
            412,
            0,
            133
        ]
    },
    {
        name: 'Average Visit',
        data: [
            27.33,
            120.55,
            117,
            123.72,
            0,
            79.28
        ]
    }
]

export const nov2023SeriesPer = [
    {
        name: 'Charges',
        data: [
            2814,
            114457.01,
            161449.15,
            192400.61,
            0,
            32519.05
        ]
    },
    {
        name: 'Payment',
        data: [
            65,
            49035.5,
            51395.53,
            59741.33,
            0,
            8115.6
        ]
    },
    {
        name: 'Collectable',
        data: [
            210,
            4338.12,
            1618.38,
            7937.44,
            0,
            1277.3
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            90438.2,
            113450.26,
            117190.88,
            0,
            17423.52
        ]
    },
    {
        name: 'Claims',
        data: [
            9,
            371,
            390,
            425,
            0,
            90
        ]
    },
    {
        name: 'Average Visit',
        data: [
            7.22,
            132.17,
            131.78,
            140.57,
            0,
            90.17
        ]
    }
]
export const dec2023SeriesPer = [
    {
        name: 'Charges',
        data: [
            2082,
            154163.25,
            150551.31,
            182890.31,
            0,
            27421.29
        ]
    },
    {
        name: 'Payment',
        data: [
            15,
            52828.66,
            52111.25,
            61655.44,
            0,
            11670.04
        ]
    },
    {
        name: 'Collectable',
        data: [
            843.77,
            9173.74,
            3440.76,
            10464.74,
            0,
            2064.99
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            82774.03,
            104642.07,
            117183.2,
            0,
            19709.95
        ]
    },
    {
        name: 'Claims',
        data: [
            18,
            515,
            350,
            442,
            0,
            85
        ]
    },
    {
        name: 'Average Visit',
        data: [
            0.83,
            102.58,
            148.89,
            139.49,
            0,
            137.29
        ]
    }
]
export const jan2024SeriesPer = [
    {
        name: 'Charges',
        data: [
            1225,
            111439.6,
            135810.46,
            150921.81,
            0,
            9051.38
        ]
    },
    {
        name: 'Payment',
        data: [
            98,
            45549.45,
            40501.77,
            54807.19,
            0,
            4555.47
        ]
    },
    {
        name: 'Collectable',
        data: [
            190,
            7317.43,
            2754.59,
            4310.3,
            0,
            744
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            78420.63,
            93983.15,
            108746.69,
            0,
            6653.94
        ]
    },
    {
        name: 'Claims',
        data: [
            11,
            468,
            354,
            396,
            0,
            32
        ]
    },
    {
        name: 'Average Visit',
        data: [
            8.91,
            97.33,
            114.41,
            138.40,
            0,
            142.36
        ]
    }
]

export const feb2024SeriesPer = [
    {
        name: 'Charges',
        data: [
            24396,
            121562.01,
            100282.83,
            110749.66,
            0,
            36196
        ]
    },
    {
        name: 'Payment',
        data: [
            480,
            38503.57,
            34453.31,
            42780.18,
            0,
            6762.62
        ]
    },
    {
        name: 'Collectable',
        data: [
            195,
            6022.08,
            3017.92,
            6703.23,
            0,
            1460.13
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            84446.55,
            77781.28,
            97957.48,
            0,
            14446.01
        ]
    },
    {
        name: 'Claims',
        data: [
            83,
            413,
            254,
            289,
            0,
            99
        ]
    },
    {
        name: 'Average Visit',
        data: [
            5.78,
            93.23,
            135.64,
            148.03,
            0,
            68.31
        ]
    }
]

export const mar2024SeriesPer = [
    {
        name: 'Charges',
        data: [
            1225,
            230544.15,
            182684.11,
            192533.78,
            0,
            33321.29
        ]
    },
    {
        name: 'Payment',
        data: [
            0,
            46531.06,
            34733.3,
            46121.77,
            0,
            9015.73
        ]
    },
    {
        name: 'Collectable',
        data: [
            0,
            5482.27,
            3513.87,
            3939.75,
            0,
            7395.61
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            88870.67,
            91174.02,
            95044.81,
            0,
            15393.41
        ]
    },
    {
        name: 'Claims',
        data: [
            10,
            820,
            383,
            446,
            0,
            87
        ]
    },
    {
        name: 'Average Visit',
        data: [
            0,
            56.75,
            90.69,
            103.41,
            0,
            103.63
        ]
    }
]

export const apr2024SeriesPer = [
    {
        name: 'Charges',
        data: [
            1599,
            135735.2,
            136693.74,
            138205.87,
            0,
            21374.69
        ]
    },
    {
        name: 'Payment',
        data: [
            50,
            65978.75,
            47529.73,
            48947.03,
            0,
            9479.86
        ]
    },
    {
        name: 'Collectable',
        data: [
            35,
            3877.22,
            2710.45,
            4533.5,
            0,
            899.61
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            124751.96,
            120128.02,
            99909.65,
            0,
            18505.9
        ]
    },
    {
        name: 'Claims',
        data: [
            14,
            492,
            321,
            317,
            0,
            56
        ]
    },
    {
        name: 'Average Visit',
        data: [
            3.57,
            134.10,
            148.07,
            154.41,
            0,
            169.28
        ]
    }
]

export const may2024SeriesPer = [
    {
        name: 'Charges',
        data: [
            3199,
            126100.58,
            132491.42,
            162060.78,
            0,
            22178.15
        ]
    },
    {
        name: 'Payment',
        data: [
            15,
            49887.86,
            41941.87,
            46356.86,
            0,
            7912.37
        ]
    },
    {
        name: 'Collectable',
        data: [
            134,
            10326.81,
            1251.69,
            2507.12,
            0,
            137.09
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            81274.07,
            99902.02,
            106948.01,
            0,
            14646.71
        ]
    },
    {
        name: 'Claims',
        data: [
            15,
            408,
            359,
            380,
            0,
            61
        ]
    },
    {
        name: 'Average Visit',
        data: [
            1,
            122.27,
            116.83,
            121.99,
            0,
            129.71
        ]
    }
]

export const jun2024SeriesPer = [
    {
        name: 'Charges',
        data: [
            5834,
            164262.53,
            130377.45,
            174053.4,
            0,
            24583.6
        ]
    },
    {
        name: 'Payment',
        data: [
            65,
            54136.79,
            34964.83,
            48510.67,
            0,
            7225
        ]
    },
    {
        name: 'Collectable',
        data: [
            0,
            27357.03,
            4168.66,
            5498.59,
            0,
            988
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            90690.72,
            69055.07,
            107966.69,
            0,
            14842.33
        ]
    },
    {
        name: 'Claims',
        data: [
            18,
            586,
            338,
            430,
            0,
            77
        ]
    },
    {
        name: 'Average Visit',
        data: [
            3.61,
            92.38,
            103.45,
            112.82,
            0,
            93.83
        ]
    }
]

export const jul2024SeriesPer = [
    {
        name: 'Charges',
        data: [
            1835,
            134043.42,
            101557.54,
            172816.19,
            0,
            20790.63

        ]
    },
    {
        name: 'Payment',
        data: [
            65,
            53496.91,
            41298.06,
            54092.62,
            0,
            8559.82

        ]
    },
    {
        name: 'Collectable',
        data: [
            4902,
            6075.78,
            2875.29,
            4518.73,
            0,
            882.89

        ]
    },
    {
        name: 'Non Collectable',
        data: [
            14.29,
            86230.57,
            86162.16,
            108648.49,
            0,
            19182.88
        ]
    },
    {
        name: 'Claims',
        data: [
            8,
            477,
            260,
            433,
            0,
            56
        ]
    },
    {
        name: 'Average Visit',
        data: [
            8.13,
            112.15,
            158.84,
            124.93,
            0,
            152.85
        ]
    }
]

export const aug2024SeriesPer = [
    {
        name: 'Charges',
        data: [
            4510,
            140416.48,
            174253.63,
            169461.22,
            0,
            15997.85

        ]
    },
    {
        name: 'Payment',
        data: [
            175,
            58956.39,
            45813.3,
            62749.59,
            0,
            6987.77

        ]
    },
    {
        name: 'Collectable',
        data: [
            841,
            4204.37,
            9702.91,
            3990.64,
            0,
            609.5

        ]
    },
    {
        name: 'Non Collectable',
        data: [
            0,
            103627.94,
            100882.89,
            133065.63,
            0,
            14684
        ]
    },
    {
        name: 'Claims',
        data: [
            8,
            509,
            435,
            402,
            0,
            51
        ]
    },
    {
        name: 'Average Visit',
        data: [
            21.88,
            115.83,
            105.32,
            156.09,
            0,
            137.02
        ]
    }
]