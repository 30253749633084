import React, { useState } from 'react'
import { Row } from 'reactstrap'
import { totalPayments, totalUnits } from './CPTData'
import CPTLevelBlocks from './CPTLevelBlocks'
import CPTLevelCharts from './CPTLevelCharts'
import { personaltotal, personaltotalunit } from './PersonalClinic/Cptdata1';
import { personaltotalunitRX, personaltotalRX } from './RxRevenue/RxCPTData'

const CPTDashBoards = () => {
    const [analysis, setAnalysis] = useState({
        totalunits: sessionStorage.getItem('name') === 'Aurora' ? totalUnits[0] :
            sessionStorage.getItem('name') === 'PersonalClinic' ? personaltotalunit[0] : personaltotalunitRX[0],
        totalPayments: sessionStorage.getItem('name') === 'Aurora' ? totalPayments[0] :
            sessionStorage.getItem('name') === 'PersonalClinic' ? personaltotal[0] : personaltotalRX[0]
        ,
    })
    const updateDate = (data) => {
        console.log('data sin  ', data)
        setAnalysis({
            totalunits: data[0],
            totalPayments: data[1],
        })
    }
    return (
        <>
            <Row>
                <CPTLevelBlocks data={analysis} />
            </Row>
            <Row>
                <CPTLevelCharts callbackupdate={updateDate} />
            </Row>
        </>
    )
}

export default CPTDashBoards