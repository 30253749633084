import React, { useState } from 'react'
import { Row, Col } from 'reactstrap'
const RejectedClaimBlocks = ({ data }) => {
    // console.log(data)
    const [rejectedData,] = useState(sessionStorage.getItem('name')
        === 'Aurora' ? '4.00' : sessionStorage.getItem('name') === 'RX' ? '1.53' : '3.00')

    return (
        <>
            <Row className='text-white'>
                <h4 className='text-dark'>Over All Rejected Claims Analysis:</h4>
                <Col style={{ backgroundColor: '#6e48aa' }} className='m-1 p-2 border rounded'>
                    <h3>Total Claims:</h3>
                    <span className='text-center fs-3'>
                        {sessionStorage.getItem('name')
                            === 'Aurora' ? '75' : sessionStorage.getItem('name') === 'RX' ? '2752' : '1566'}</span>
                </Col>
                <Col style={{ backgroundColor: '#6e48aa' }} className='m-1 p-2 border rounded'>
                    <h3>Rejected Claims:</h3>
                    <span className='text-center fs-3'>
                        {sessionStorage.getItem('name') === 'Aurora' ? '3' :
                            sessionStorage.getItem('name') === 'RX' ? '42' : '47'} </span>
                </Col>
                <Col style={{ backgroundColor: '#6e48aa' }} className='m-1 p-2 border rounded'>
                    <h3>Rejected Amount</h3>
                    <span className='text-center fs-3'>
                        {sessionStorage.getItem('name') === 'Aurora'
                            ? '$ 4,654.00' : sessionStorage.getItem('name') === 'RX' ? ' $7,059.05' : '$21,906.00'} </span>
                </Col>
                <Col style={{ backgroundColor: rejectedData <= 5 ? '#52c234' : '#dc2430' }} className='m-1 p-2 border rounded'>
                    <h3>Rejected %</h3>
                    <span className='text-center fs-3'>{rejectedData} %</span>
                </Col>
            </Row>
            {data.header !== 'close' ? <Row className='text-white' >
                <h4 className='text-dark'>{data.header} Analysis</h4>
                <Col style={{ backgroundColor: '#6e48aa' }} className='m-1 p-2 border rounded'>
                    <h3>Total Rejected Claims:</h3>
                    <span className='text-center fs-3'>{sessionStorage.getItem('name') === 'Aurora' ? '3' :
                        sessionStorage.getItem('name') === 'RX' ? '42' : '47'}</span>
                </Col>
                <Col style={{ backgroundColor: '#6e48aa' }} className='m-1 p-2 border rounded'>
                    <h3>Rejected Claims:</h3>
                    <span className='text-center fs-3'>{data.rejectedClaim}</span>
                </Col>
                <Col style={{ backgroundColor: data.rejectedClaim <= 5 ? '#52c234' : '#dc2430' }} className='m-1 p-2 border rounded'>
                    <h3>Rejected %</h3>
                    <span className='text-center fs-3'>{(sessionStorage.getItem('name') === 'Aurora' ?
                        (data.rejectedClaim / 3) * 100 :
                        sessionStorage.getItem('name') === 'RX' ? (data.rejectedClaim / 42) * 100 :
                            (data.rejectedClaim / 47) * 100).toFixed(2)}%</span>
                </Col>
            </Row> : ''}

        </>
    )
}

export default RejectedClaimBlocks