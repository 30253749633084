import React from 'react'
import { Modal, ModalBody, Spinner, Row, Col, Button } from 'reactstrap'
import eraModule from './ERAModule.module.css'

const ModalShowInsuranceName = ({ isShow, data, eraStatusName ,callBackCloseModal}) => {
    return (
        <Modal isOpen={isShow} size='lg' className={`${eraModule.popUp} position-absolute start-50 translate-middle-x overflow-auto`}>
            <ModalBody>
                <h3 className='text-center' style={{color:'#6E48AA'}}>{eraStatusName}</h3>
                <Row>
                    {
                        data.map((val, i) => {
                            return <small className={`${eraModule.trans} fs-5 text-start text-dark`}>{i + 1}. {val}</small>
                        })
                    }
                </Row>
                <Row >
                    <Col className='d-flex justify-content-center flex-wrap'>
                        <Button className='mt-2 bg-white btn text-danger border border-danger'  onClick={() => callBackCloseModal()}>Close</Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}

export default ModalShowInsuranceName