export const rejectedPayer = [
  'All',
  'IL Medicare Part B (J6)',
  'UMR Wausau',
  'Bright HealthCare',

]
export const rejectedPayerRepetition = [
  'IL Medicare Part B (J6)',
  'UMR Wausau',
  'Bright HealthCare',
]

export const rejectedPayerSAM = [
  'All',
  'HUMANA CLAIMS',
  'UNITED HEALTH CARE',
  'AETNA BETTER HEALTH INC D',
  'MHPBLUE ADVANTAGE COMMERC',
  'UNITED HEALTHCARE/ MEDICA',
  'MEDICARE   PART B',
  'UNITED HEALTHCARE',
  'BLUE CROSS BLUE SHIELD PP',
  'ILLINOIS MEDICAID',
  'BLUE CROSS COMMUNITY MMAI',
  'CIGNA',
  'AETNA',
  'MERIDIAN HEALTH PLAN',
  'CIGNA PPO',
  
  
  
]
export const rejectedPayerRepetitionSAM = [
  'HUMANA CLAIMS',
  'UNITED HEALTH CARE',
  'AETNA BETTER HEALTH INC D',
  'MHPBLUE ADVANTAGE COMMERC',
  'HUMANA CLAIMS',
  'HUMANA CLAIMS',
  'HUMANA CLAIMS',
  'UNITED HEALTH CARE',
  'UNITED HEALTHCARE/ MEDICA',
  'UNITED HEALTHCARE/ MEDICA',
  'MEDICARE   PART B',
  'HUMANA CLAIMS',
  'HUMANA CLAIMS',
  'UNITED HEALTHCARE',
  'HUMANA CLAIMS',
  'BLUE CROSS BLUE SHIELD PP',
  'ILLINOIS MEDICAID',
  'HUMANA CLAIMS',
  'BLUE CROSS COMMUNITY MMAI',
  'AETNA BETTER HEALTH INC D',
  'CIGNA',
  'AETNA',
  'AETNA',
  'HUMANA CLAIMS',
  'HUMANA CLAIMS',
  'MERIDIAN HEALTH PLAN',
  'MHPBLUE ADVANTAGE COMMERC',
  'BLUE CROSS COMMUNITY MMAI',
  'BLUE CROSS BLUE SHIELD PP',
  'BLUE CROSS BLUE SHIELD PP',
  'MHPBLUE ADVANTAGE COMMERC',
  'CIGNA',
  'CIGNA',
  'UNITED HEALTHCARE',
  'HUMANA CLAIMS',
  'HUMANA CLAIMS',
  'HUMANA CLAIMS',
  'HUMANA CLAIMS',
  'MHPBLUE ADVANTAGE COMMERC',
  'MERIDIAN HEALTH PLAN',
  'CIGNA PPO',
  'MHPBLUE ADVANTAGE COMMERC',
  'MHPBLUE ADVANTAGE COMMERC',
  'UNITED HEALTHCARE',
  'BLUE CROSS COMMUNITY MMAI',
  'AETNA',
  'HUMANA CLAIMS',
  
]
export const rejectedPayerRX = [
  'All',
  'MEDICARE',
  'HUMANA GOLD PLUS ADVENTIST AMITA',
  'UNITED HEALTH/30555/SLC,UT',
  'CIGNA',
  'Medicare Secondary',
  'TRICARE EAST REGION CLAIMS',
  'BCBS',
  'HUMANA GOLD LOYOLA PHYSICIANS PARTNERS',
  'UNITED HEALTHCARE',
  'BCBS Illinois',
  'BLUE ADVANTAGE HMO - LPP',
  'BCBS/MEDICARE ADVANTAGE HMO/LPP',
  'CIGNA/182223/CHATTANOOGA,TN',
  'HMO ILLINOIS LPP',
  'ALL SAVERS',
  'AARP',
  'AETNA',
  'AARP MEDICARE ADV - UHC PPO',
  'CIGNA TRUE CHOICE MEDICARE PPO'
]
export const rejectedPayerRepetitionRX = [
  'MEDICARE',
  'HUMANA GOLD PLUS ADVENTIST AMITA',
  'UNITED HEALTH/30555/SLC,UT',
  'UNITED HEALTH/30555/SLC,UT',
  'CIGNA',
  'Medicare Secondary',
  'TRICARE EAST REGION CLAIMS',
  'BCBS',
  'HUMANA GOLD LOYOLA PHYSICIANS PARTNERS',
  'MEDICARE',
  'UNITED HEALTHCARE',
  'MEDICARE',
  'BCBS Illinois',
  'BCBS Illinois',
  'BLUE ADVANTAGE HMO - LPP',
  'BCBS/MEDICARE ADVANTAGE HMO/LPP',
  'CIGNA/182223/CHATTANOOGA,TN',
  'MEDICARE',
  'Medicare Secondary',
  'HMO ILLINOIS LPP',
  'UNITED HEALTH/30555/SLC,UT',
  'ALL SAVERS',
  'MEDICARE',
  'HUMANA GOLD PLUS ADVENTIST AMITA',
  'MEDICARE',
  'BLUE ADVANTAGE HMO - LPP',
  'MEDICARE',
  'BCBS',
  'AARP',
  'AARP',
  'AETNA',
  'BCBS',
  'TRICARE EAST REGION CLAIMS',
  'BCBS/MEDICARE ADVANTAGE HMO/LPP',
  'BCBS',
  'BCBS/MEDICARE ADVANTAGE HMO/LPP',
  'MEDICARE',
  'AARP MEDICARE ADV - UHC PPO',
  'MEDICARE',
  'CIGNA TRUE CHOICE MEDICARE PPO',
  'CIGNA TRUE CHOICE MEDICARE PPO',
  'CIGNA/182223/CHATTANOOGA,TN',
]