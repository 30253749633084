export const payers = [
    'All',
    'AARP UHC CLAIMS UNIT',
    'AETNA',
    'Aetna Medicare Advantage',
    'ASCENSION SMAR THEALTH',
    'BCBS PPO',
    'BLUE CROSS MEDICARE ADVANTAGE',
    'CHAMPVA',
    'CIGNA',
    'CIGNA GREAT WEST',
    'DEVOTED HEALTH INC',
    'FOX VALLEY MEDICINE LTD',
    'FOX VALLEY MEDICINE LTD BCBS HMO',
    'HUMANA HMO',
    'HUMANA SELECT',
    'MEDICARE PART B',
    'MIDWEST OPERATING ENG WEL FUND',
    'UFCW LOCAL 1546 HEALTH & WELFARE FUND',
    'UNITED HEALTH CARE',
    'UNITED HEALTHCARE',
    'UNITED HEARTLAND',
    
    
]
export const payersSAM = [
    'All',
    'AARP MEDICARE COMPLETE PLUS HMOPOS',
    'AARP/UHC CLAIMS',
    'Advocate- Christ',
    'ADVOCATE CHRIST PHYSICIAN PARTNERS',
    'ADVOCATE PHYSICIAN PARTNERS',
    'AETNA',
    'AETNA BETTER HEALTH INC DUAL PLAN( IL )',
    'Aetna Better Health of IL - Medicaid',
    'Aetna Senior Supplement Ins',
    'ALL SAVERS ( UHC )',
    'ALLIED BENEFIT SYSTEM',
    'ALLSTATE',
    'AMERICAN FAMILY INSURANCE',
    'AMERICAN HEARTLAND',
    'AMITA HEALTH CARE NETWORK HCN',
    'Automated Benefit Services, Inc.',
    'BCBS',
    'BLUE CHOICE SELECT',
    'Blue Cross Blue Shield HMO Illinois',
    'BLUE CROSS BLUE SHIELD PPO',
    'BLUE CROSS COMMUNITY MMAI/FAMILY HEALTH',
    'BLUE CROSS MEDICARE ADVANTAGE',
    'BLUE CROSS MEDICARE ADVANTAGE PPO',
    'CBCS',
    'CENTRAL STATES HEALTH & WELFARE FUND',
    'CHAMPVA CENTER',
    'CIGNA',
    'CIGNA HEALTH & LIFE INURANCE',
    'CIGNA HEALTHSPRING HMO',
    'CIGNA HMO',
    'CIGNA MEDICARE',
    'Cigna PPO',
    'Clarification',
    'CLEAR SPRING HEALTH',
    'CNA INS',
    'CORESOURCE',
    'COUNTY CARE HEALTH PLAN',
    'Devoted Health',
    'FEDERAL LIFE INSURANACE CO',
    'GEHA GOVERNMENT EMPLOYEES HOSP ASSOC I',
    'GPM LIFE INSURANCE',
    'Gravie Administrative Services -Aetna',
    'GREAT WEST CASUALTY CO',
    'HEALTHLINK',
    'HUMANA  ADVENTIST',
    'HUMANA CLAIMS',
    'IHP EDWARD/MED ADV C/O ARCADIA',
    'ILLINOIS DEPT OF PUBLIC AID',
    'IPMG',
    'MacNeal Health Providers CHS( HMO )',
    'MEDICARE   PART B',
    'MERIDIAN HEALTH PLAN',
    'MERITAIN HEALTH',
    'MHPBLUE ADVANTAGE COMMERCIAL',
    'MOLINA HEALTHCARE OF ILL MMCP',
    'MUTUAL OF OMAHA CO',
    'NORTHSHORE PHYSICIAN ASSOCIATES',
    'OSCAR',
    'Priority health',
    'Provider  Partners Health Plan',
    'RAILROAD MEDICARE PALMETTO/TRAVELERS',
    'Seamless Health',
    'Sedgewick',
    'State Farm',
    'STATE FARM CLAIMS',
    'TRICARE CLAIMS',
    'UMR',
    'UNITED HEALTH CARE',
    'UNITED HEALTHCARE',
    'United Healthcare Medicare',
    'UNITED HEALTHCARE MEDICARE COMPLETE',
    'United HealthCare Shared Services',
    'UNITED HEALTHCARE/ MEDICARE SOLUTION',
    'UNITED WORLD LIFE INSURANCE COMPANY',
    'US DEPARTMENT OF LABOR',
    'VHA OFFICE OF COMMUNITY CARE',
    'WELLCARE BY ALLWELL',
    'WELLCARE HMO/ /CLAIMS',
    'WELLNET HEALTHCARE',
    'WPS TRICARE FOR LIFE',
    'ZING HEALTH',
    
    
]
export const payersRX = [
    'All',
    'AARP',
    'AARP MEDICARE ADV - UHC PPO',
    'AARP MEDICARE ADVANTAGE PLAN 1 HMO',
    'ADMINISTRATIVE CONCEPTS, INC.',
    'AETNA',
    'AETNA LIFE INSURANCE',
    'AETNA LIFE INSURANCE COMPANY',
    'AETNA SENIOR SUPPLEMENTAL INS',
    'AETNA/LEXINGTON',
    'ALL SAVERS',
    'ALLIED BENEFIT SYSTEMS',
    'AMTRUST FINANCIAL',
    'BCBS',
    'BCBS Illinois',
    'BCBS MEDICARE ADVANTAGE PPO',
    'BCBS OF MICHIGAN MEDICARE ADVANTAGE',
    'BCBS/ MEDICARE ADVANTAGE HMO - EHP',
    'BCBS/MEDICARE ADVANTAGE HMO/LPP',
    'BLUE ADVANTAGE HMO - LPP',
    'BLUE ADVANTAGE HMO EHP',
    'BLUE CHOICE',
    'BLUE CROSS COMMUNITY OPTIONS',
    'BLUE PRECISION HMO - LPP',
    'BLUE PRECISION HMO EHP',
    'BNSF RAILWAY COMPANY',
    'CCMSI/DANVILLE,IL',
    'CHAMPVA',
    'Chubb Insurance',
    'CIGNA',
    'CIGNA CONNECT HMO',
    'CIGNA HEALTHSPRING MEDICARE HMO',
    'CIGNA TRUE CHOICE MEDICARE PPO',
    'CIGNA/182223/CHATTANOOGA,TN',
    'CIGNA/188004CHATTANOOGA',
    'CIGNA/5200/SCRANTON',
    'CIGNA-GWH',
    'DEVOTED HEALTH',
    'EQUITABLE LIFE &  CASUALTY INS',
    'GALLAGHER BASSETT',
    'GRAVIE ADMINISTRATIVE SERVICES',
    'HARTFORD LIFE INSURANCE CO',
    'HEALTHLINK',
    'HMO ILLINOIS EHP',
    'HMO ILLINOIS LPP',
    'HUMANA COMM DIABETES HMO ADVENTIST',
    'HUMANA GOLD PLUS -  EHP',
    'HUMANA GOLD PLUS - ADVENTIST',
    'HUMANA GOLD PLUS - LPP',
    'HUMANA GOLD PLUS INTEGRATED',
    'HUMANA HMO ADVENTIST AMITA',
    'HUMANA MEDICARE HMO ADVENTIST AMITA',
    'HUMANA MEDICARE PPO',
    'HUMANA PPO',
    'IDPA SECONDARY',
    'ILLINOIS DEPT OF PUBLIC AID',
    'INDIANA COUNCIL OF ROOFERS',
    'INDIVIDUAL ASSURANCE CO',
    'INSURANCE BENEFIT SYSTEM ADM',
    'LIBERTY MUTUAL',
    'MANHATTAN LIFE ASSURANCE',
    'Massachusetts Mutual Life Insurance Co',
    'MEDICARE',
    'Medicare Secondary',
    'MERITAIN HEALTH',
    'MIDWEST OPERATING ENGINEERS',
    'MUTUAL OF OMAHA',
    'NALC/ASHBURN,VA',
    'OSCAR',
    'PAN AMERICAN LIFE INS CO',
    'PLANSTIN ADMININSTRATION',
    'PROGRESSIVE',
    'RAILROAD MEDICARE',
    'SEDGWICK',
    'SELF PAY',
    'SUREST',
    'TRANSACTRX',
    'UMR',
    'United Health Care',
    'UNITED HEALTH/30551/SLC,UT',
    'UNITED HEALTH/30555/SLC,UT',
    'UNITED HEALTH/30985/SLC,UT',
    'United Healthcare',
    'UNITED HEALTHCARE  MEDICARE  ADVANTAGE PPO',
    'UNITED HEALTHCARE AARP MEDICAREADVANTAGE HMO',
    'UNITED HEALTHCARE NAVIGATE',
    'UNITED HEALTHCARE NAVIGATE HMO',
    'United Healthcare Oxford',
    'UNITED HEALTHCARE/MEDICARE  ADVANTAG PPO',
    'UNITED WORLD LIFE INS CO',
    'UNITEDHEALTH SHARED SERVICES',
    'VAULT/AMPS ADMIN SERVICES'


]
export const payersCPT = [
    'CPT Codes',
    ' ',
    'Total',
    ' ',
    'AARP UHC CLAIMS UNIT',
    ' ',
    'AETNA',
    ' ',
    'AETNA ',
    ' ',
    'ALLIED BENEFIT SYSTEMS INC',
    ' ',
    'BCBS',
    ' ',
    'BCBS PPO',
    ' ',
    'BLUE CROSS MEDICARE ADVANTAGE',
    ' ',
    'CIGNA',
    ' ',
    'CIGNA ',
    ' ',
    'CIGNA GREAT WEST',
    ' ',
    'COLONIAL PEN LIFE INSURANCE',
    ' ',
    'COUNTRY FINANCIAL',
    ' ',
    'DEVOTED HEALTH INC',
    ' ',
    'FOX VALLEY MEDICINE LTD',
    ' ',
    'FOX VALLEY MEDICINE LTD BCBS HMO',
    ' ',
    'GENEX - NEXT LEVEL ADMINISTRATORS',
    ' ',
    'HEALTH ALLIANCE',
    ' ',
    'HUMANA CLAIMS',
    ' ',
    'HUMANA HMO',
    ' ',
    'HUMANA SELECT',
    ' ',
    'MEDICARE PART B',
    ' ',
    'Patient',
    ' ',
    'Self Pay',
    ' ',
    'SENTINEL SECURITY LIFE',
    ' ',
    'TRICARE FOR LIFE',
    ' ',
    'UFCW LOCAL 1546 HEALTH & WELFARE FUND',
    ' ',
    'UNITED AMERICAN INSURANCE COMPANY',
    ' ',
    'UNITED HEALTH CARE',
    ' ',
    'UNITED HEALTHCARE',
    ' ',
    'UNITED HEARTLAND',
    ' ',
    'USA senior Care'

    
]
export const payersCPTPersonal = [
    'CPT Code',
    'TOTAL',
    ' ',
    'AARP MEDICARE COMPLETE PLUS HMOPOS',
    '',
    'AARP/UHC CLAIMS',
    '',
    'ACCIDENT FUND',
    '',
    'Advocate- Christ',
    '',
    'ADVOCATE PHYSICIAN PARTNERS',
    '',
    'AETNA',
    '',
    'AETNA BETTER HEALTH INC DUAL PLAN( IL )',
    '',
    'Aetna Better Health of IL - Medicaid',
    '',
    'AETNA CHOICE POS II',
    '',
    'AETNA SELECT OPEN ACCESS',
    '',
    'ALL SAVERS ( UHC )',
    '',
    'ALLIED BENEFIT SYSTEM',
    '',
    'ALLSTATE',
    '',
    'AMERICAN CONTINENTAL INS CO',
    '',
    'AMITA HEALTH CARE NETWORK HCN',
    '',
    'ANTHEM BCBS ( OHIO )',
    '',
    'Ascension Complete Illinois',
    '',
    'ASR HEALTH BENEFITS',
    '',
    'Automated Benefit Services, Inc.',
    '',
    'BCBS',
    '',
    'BLUE CROSS  BLUE SHIELD OF MICHIGAN',
    '',
    'BLUE CROSS BLUE SHIELD PPO',
    '',
    'BLUE CROSS COMMUNITY MMAI/FAMILY HEALTH',
    '',
    'BLUE CROSS MEDICARE ADVANTAGE',
    '',
    'BLUE CROSS MEDICARE ADVANTAGE PPO',
    '',
    'CHAMPVA CENTER',
    '',
    'CIGNA',
    '',
    'CIGNA HEALTH & LIFE INURANCE',
    '',
    'CIGNA HEALTHSPRING HMO',
    '',
    'CIGNA HMO',
    '',
    'CIGNA MEDICARE',
    '',
    'Cigna PPO',
    '',
    'CLEAR SPRING HEALTH',
    '',
    'Companion Life Insurance',
    '',
    'CORESOURCE',
    '',
    'CORVEL ADVENT HEALTH',
    '',
    'corvel corp',
    '',
    'Country Financial',
    '',
    'COUNTY CARE',
    '',
    'COUNTY CARE HEALTH PLAN',
    '',
    'Devoted Health',
    '',
    'FEDERAL LIFE INSURANACE CO',
    '',
    'GEHA GOVERNMENT EMPLOYEES HOSP ASSOC I',
    '',
    'GHI',
    '',
    'GOLDEN RULE INSURANCE',
    '',
    'Gravie Administrative Services -Aetna',
    '',
    'HARTFORD Transamerica Life Insurance Co',
    '',
    'Healthcomp',
    '',
    'HEALTHLINK',
    '',
    'HUMANA  ADVENTIST',
    '',
    'HUMANA CLAIMS',
    '',
    'HUMANA GOLD',
    '',
    'IHP EDWARD/MED ADV C/O ARCADIA',
    '',
    'ILLINOIS DEPT OF PUBLIC AID',
    '',
    'IPMG',
    '',
    'Longevity Health Plan',
    '',
    'MacNeal Health Providers CHS( HMO )',
    '',
    'MANHATTAN LIFE INSURANCE COMPANY ',
    '',
    'MEDICARE   PART B',
    '',
    'MERCHANTS CREDIT GUIDE ',
    '',
    'MERIDIAN HEALTH PLAN',
    '',
    'MERITAIN',
    '',
    'MERITAIN HEALTH',
    '',
    'MHPBLUE ADVANTAGE COMMERCIAL',
    '',
    'MIDWEST OPERATING ENGINEERS',
    '',
    'MOLINA HEALTHCARE OF ILL MMCP',
    '',
    'MUTUAL OF OMAHA CO',
    '',
    'NATIONWIDE',
    '',
    'OSCAR',
    '',
    'Patient',
    '',
    'Priority health',
    '',
    'Provider  Partners Health Plan',
    '',
    'RAILROAD MEDICARE PALMETTO/TRAVELERS',
    '',
    'Revecore',
    '',
    'SELECTIVE INSURANCE CO OF AMERICA',
    '',
    'Self Pay',
    '',
    'State Farm',
    '',
    'STATE FARM CLAIMS',
    '',
    'TRICARE CLAIMS',
    '',
    'UMR',
    '',
    'UNION HEALTH SERVICES',
    '',
    'UNITED HEALTH CARE',
    '',
    'United Health Care Choice Plus',
    '',
    'UNITED HEALTHCARE',
    '',
    'United Healthcare Medicare',
    '',
    'UNITED HEALTHCARE MEDICARE COMPLETE',
    '',
    'United HealthCare Shared Services',
    '',
    'UNITED HEALTHCARE/ MEDICARE SOLUTION',
    '',
    'UNITED OF OMAHA',
    '',
    'WAUSAU WAUSAU WI BX 8013',
    '',
    'WELLCARE HMO/ /CLAIMS',
    '',
    'WPS TRICARE FOR LIFE'

]
export const payersCPTRX = [
    'CPT Code',
    'Total',
    ' ',
    'MEDICARE',
    ' ',
    'BCBS Illinois',
    ' ',
    'BCBS MEDICARE ADVANTAGE',
    ' ',
    'AETNA',
    ' ',
    'AARP',
    ' ',
    'CIGNA HEALTHCARE',
    ' ',
    'CIGNA MEDICARE ADVANTAGE',
    ' ',
    'HMO ILLINOIS LPP',
    ' ',
    'HUMANA INSURANCE',
    ' ',
    'SELF PAY',
    ' ',
    'UNITED HEALTH CARE',
    ' ',
    'UMR',
    ' ',
    'ABS',
    ' ',
    'ADMINISTRATIVE CONCEPTS INC',
    ' ',
    'AFSLIC CARE CHOICE',
    ' ',
    'ALL SAVERS',
    ' ',
    'ALLIED BENEFIT SYSTEMS',
    ' ',
    'ALLIED NATIONAL',
    ' ',
    'ALLSTATE  INSURANCE',
    ' ',
    'AMERISURE INSURANCE COMPANY',
    ' ',
    'AMTRUST FINANCIAL',
    ' ',
    'Ascension Complete',
    ' ',
    'ASCENSION SMART HEALTH',
    ' ',
    'BENEFIT ADMINISTRAATIVE SYSTEMS,LLC',
    ' ',
    'BERKSHIRE, HATHOWAY',
    ' ',
    'BIND BENEFITS, INC',
    ' ',
    'BLUE CROSS COMMUNITY MMAI',
    ' ',
    'BOON GROUP',
    ' ',
    'BOON-CHAPMAN BENEFIT ADMIN INC',
    ' ',
    'BRIGHT HEALTH',
    ' ',
    'BROADSPIRE',
    ' ',
    'CALIFORNIA INS COMPANY',
    ' ',
    'CHAMPVA',
    ' ',
    'CIGNA GREAT WEST',
    ' ',
    'CLASSIC BLUE HMO - MHP',
    ' ',
    'CONCIERGE ADMINISTRATIVE SERVICES',
    ' ',
    'CORESOURCE',
    ' ',
    'CORVEL',
    ' ',
    'CRUM FORESTER INS',
    ' ',
    'EMC INSURANCE',
    ' ',
    'Erie Insurance Group',
    ' ',
    'ESIS CENTRAL WC CLAIMS',
    ' ',
    'FHN',
    ' ',
    'FOREIGN SERVICE BENEFIT PLAN',
    ' ',
    'FREEDOM LIFE INS CO OF AMERICA',
    ' ',
    'GALLAGHER BASSETT',
    ' ',
    'GOLDEN RULE INSURANCE CO',
    ' ',
    'GROUP ADMINISTRATORS LTD',
    ' ',
    'GUIDEONE',
    ' ',
    'HANOVER INSURANCE CO',
    ' ',
    'HARTFORD/LEXINGTON',
    ' ',
    'HEALTHLINK',
    ' ',
    'High Performance Health Plan',
    ' ',
    'HMA, LLC',
    ' ',
    'ICW GROUP',
    ' ',
    'IDPA SECONDARY',
    ' ',
    'ILLINOIS CASUALTY COMPANY',
    ' ',
    'ILLINOIS DEPT OF PUBLIC AID',
    ' ',
    'International Benefits Admin',
    ' ',
    'IPMG EMPLOYEE BENEFITS SERVICES',
    ' ',
    'KEY BENEFIT ADMINISTRATORS',
    ' ',
    'KPIC',
    ' ',
    'LABORERS HWF/WESTCHESTER,IL',
    ' ',
    'LIBERTY MUTUAL',
    ' ',
    'LUCENT HEALTH',
    ' ',
    'Massachusetts Mutual Life Insurance Co',
    ' ',
    'MEDI SHARE',
    ' ',
    'MERIDIAN HEALTH',
    ' ',
    'MERITAIN HEALTH',
    ' ',
    'MIDWEST OPERATING ENGINEERS',
    ' ',
    'NALC/ASHBURN,VA',
    ' ',
    'NATIONAL GENERAL',
    ' ',
    'North America Administrators, Lp',
    ' ',
    'OSCAR',
    ' ',
    'PAI',
    ' ',
    'PAN AMERICAN LIFE INS CO',
    ' ',
    'PATIENT ADVOCATES',
    ' ',
    'PLANSTIN ADMININSTRATION',
    ' ',
    'PMA COMPANIES',
    ' ',
    'PRAIRIE STATES ENTERPRISES,INC',
    ' ',
    'PREFERRED ONE/MINNEAPOLIS,MN',
    ' ',
    'PREMIER ACCESS, INC',
    ' ',
    'RAILROAD MEDICARE',
    ' ',
    'RISING MEDICAL SOLUTIONS',
    ' ',
    'RSL SPECIALTY PRODUCTS ADMINISTRATION',
    ' ',
    'SANA BENEFITS',
    ' ',
    'SEDGWICK',
    ' ',
    'SENTRY CLAIMS CENTER',
    ' ',
    'SMART HEALTH',
    ' ',
    'Societys Insurance',
    ' ',
    'STARMARK/CLINTON,IA',
    ' ',
    'STATE FARM',
    ' ',
    'STUDENT RESOURCES',
    ' ',
    'TRANSACTRX',
    ' ',
    'TRANSAMERICA LIFE INSURANCE',
    ' ',
    'TRAVELERS INSURANCE',
    ' ',
    'TRICARE',
    ' ',
    'TRUSTMARK HEALTH BENEFITS',
    ' ',
    'Unified Life Insurance Co',
    ' ',
    'UNITED HEARTLAND',
    ' ',
    'UNITED NATIONAL INSURANCE GROUP',
    ' ',
    'UNITED SHARED SERVICES',
    ' ',
    'UNITED WORLD LIFE INS CO',
    ' ',
    'Unknown',
    ' ',
    'Walmart Claim Services',
    ' ',
    'WEBTPA',
    ' ',
    'WELLFLEET',
    ' ',
    'WELLNET',
    ' ',
    'ZURICH INSURANCE'
]