export const totalUnits = [
  554,
  1,
  2,
  0,
  1,
  0,
  0,
  18,
  1,
  3,
  1,
  3,
  2,
  0,
  0,
  0,
  0,
  2,
  22,
  0,
  0,
  0,
  0,
  4,
  0,
  29,
  26,
  57,
  276,
  0,
  26,
  0,
  0,
  0,
  2,
  1,
  72,
  5,
  
]
export const totalPayments = [ 
  14476,
  49,
  189,
  0,
  399,
  651,
  188,
  4604,
  545,
  950,
  20,
  696,
  126,
  719,
  393,
  15,
  128,
  0,
  -595,
  0,
  130,
  0,
  23,
  947,
  93,
  2840,
  1230,
  -10,
  50,
  5,
  0,
  0,
  2,
  15,
  40,
  20,
  13,
  0,
  
]
export const AETNAUnits = [// AETNA-(Units)
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  3,
  0,
  3,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  
]
export const AETNAPayments = [// AETNA-(Payment)
  249,
  0,
  0,
  0,
  0,
  0,
  0,
  224,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  23,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  
]
export const medicareUnits = [//  Aetna Medicare Advantage-(Units)
  22,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  1,
  2,
  12,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  3,
  0,
  
]
export const medicarePayments = [// Aetna Medicare Advantage-(Payment)
  815,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  421,
  263,
  0,
  128,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  3,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  
]
export const bcbsillinoisUnits = [// AMERICAN REPUBLIC CORP INSURANCE CO-(Units)
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  
]
export const bcbspayments = [// AMERICAN REPUBLIC CORP INSURANCE CO-(Payment)
  27,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  27,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  
]
export const bcbsmedicareunits = [// ASCENSION SMAR THEALTH-(Units)
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  
]
export const bcbsmedicarepayments = [//  ASCENSION SMAR THEALTH-(Payment)
  913,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  910,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  2,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  
]
export const aetnaunit = [// BCBS-(Units)	
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  
]
export const aetnapayments = [// BCBS-(Payments)
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  
]
export const aarpunit = [// BCBS PPO-(Units)
  100,
  1,
  1,
  0,
  0,
  0,
  0,
  1,
  0,
  1,
  1,
  1,
  0,
  0,
  0,
  0,
  0,
  1,
  4,
  0,
  0,
  0,
  0,
  1,
  0,
  6,
  5,
  12,
  48,
  0,
  5,
  0,
  0,
  0,
  0,
  0,
  9,
  3,
  
]
export const aarppayments = [// BCBS PPO- (Payments)
  2741,
  6,
  56,
  0,
  0,
  0,
  0,
  737,
  169,
  0,
  0,
  229,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  12,
  0,
  0,
  112,
  19,
  310,
  1063,
  0,
  22,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  5,
  0,
  
]
export const cignahealthunits = [// BLUE CROSS MEDICARE ADVANTAGE-(Units)
  24,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  2,
  1,
  3,
  12,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  3,
  0,
  
]
export const cignahealthpayments = [// BLUE CROSS MEDICARE ADVANTAGE-(Payment)
  791,
  0,
  0,
  0,
  0,
  0,
  0,
  388,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  402,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  
]
export const cignamedicareadvunits = [// CHAMPVA- (Units)
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  
]
export const cignamedicareadvpayments = [// CHAMPVA -(Payment) 
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  
]
export const hmoilinoisunits = [// CIGNA -(Units)
  20,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  1,
  12,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  3,
  0,
  
]
export const hmoillionoispayments = [// CIGNA-(Payment)
  203,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  203,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  
]
export const humanainsuranceunits = [// CIGNA GREAT WEST- (Units)
  62,
  0,
  0,
  0,
  0,
  0,
  0,
  2,
  1,
  0,
  0,
  2,
  2,
  0,
  0,
  0,
  0,
  0,
  2,
  0,
  0,
  0,
  0,
  0,
  0,
  2,
  4,
  6,
  24,
  0,
  4,
  0,
  0,
  0,
  0,
  0,
  12,
  1,
  
]
export const humanainsurancepayments = [// CIGNA GREAT WEST-(Payment)
  1058,
  0,
  0,
  0,
  359,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  242,
  0,
  454,
  0,
  0,
  0,
  2,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  
]
export const selfpayunits = [// DEVOTED HEALTH INC - (Units)
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  
]
export const selfpaypayments = [// FOX VALLEY MEDICINE LTD-(Payment)
  74,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  74,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  
]// change from next
export const silacInsuranceCompanyUnits = [// UNITED HEALTH CARE - (Units)
  23,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  1,
  0,
  1,
  1,
  2,
  12,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  3,
  0,
  
]
export const silacInsuranceCompanyPayment = [// UNITED HEALTH CARE-(Payment)
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  
]
export const unitedhealthunits = [// FOX VALLEY MEDICINE LTD BCBS HMO-(Units)
  77,
  0,
  0,
  0,
  1,
  0,
  0,
  2,
  0,
  2,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  4,
  0,
  0,
  0,
  0,
  0,
  0,
  4,
  4,
  8,
  36,
  0,
  3,
  0,
  0,
  0,
  0,
  0,
  12,
  1,
  
]
export const unitedhealthpayments = [// FOX VALLEY MEDICINE LTD BCBS HMO-(Payment)
  65,
  0,
  0,
  0,
  0,
  0,
  0,
  657,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  -595,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  3,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  
]
export const umrunits = [// HUMANA HMO-(Units)
  4,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  2,
  0,
  2,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  
]
export const umrpayments = [// HUMANA HMO-(Payment) 
  957,
  0,
  0,
  0,
  0,
  651,
  188,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  118,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  
]
export const absunits = [// HUMANA SELECT -(Units)
  41,
  0,
  0,
  0,
  0,
  0,
  0,
  2,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  2,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  2,
  2,
  24,
  0,
  2,
  0,
  0,
  0,
  0,
  0,
  6,
  0,
  
]
export const abspayments = [ // HUMANA SELECT-(Payment)
  1440,
  0,
  0,
  0,
  0,
  0,
  0,
  1161,
  171,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  112,
  0,
  -10,
  4,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  
]
export const administrativeunits = [//  MEDICARE PART B -(Units)
  128,
  0,
  1,
  0,
  0,
  0,
  0,
  5,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  4,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  5,
  12,
  72,
  0,
  7,
  0,
  0,
  0,
  0,
  0,
  15,
  0,
  
]
export const administrativepayments = [// MEDICARE PART B-(Payment)
  1402,
  44,
  0,
  0,
  0,
  0,
  0,
  402,
  182,
  0,
  0,
  0,
  0,
  158,
  55,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  138,
  0,
  420,
  0,
  0,
  3,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  
]
export const afsliccareunits = [//Meritain Health -(Units)
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  
]
export const afsliccarepayments = [// Meritain Health-(Payment)
  264,
  0,
  0,
  0,
  0,
  0,
  0,
  262,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  
]
export const humanaHmoUnits = [// MIDWEST OPERATING ENG WEL FUND-(Units)
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  
]
export const humanaHmoPayment = [// MIDWEST OPERATING ENG WEL FUND-(Payment)
  208,
  0,
  14,
  0,
  0,
  0,
  0,
  128,
  23,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  27,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  15,
  0,
  0,
  0,
  0,
  
]
export const humanaSelectUnits = [// Patient-(Units)
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  
]
export const humanaSelectPayment = [// Patient-(Payment)
  2592,
  0,
  118,
  0,
  40,
  0,
  0,
  843,
  0,
  41,
  20,
  50,
  0,
  140,
  75,
  15,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  317,
  0,
  709,
  168,
  0,
  11,
  1,
  0,
  0,
  0,
  0,
  20,
  20,
  3,
  0,
  
]
export const medicarePartBUnits = [// Self Pay  -(Units)
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  
]
export const medicarePartBPayment = [// Self Pay -(Payment)
  20,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  20,
  0,
  0,
  0,
  
]
export const midwestOperatingEngWelFundUnits = [// UNITED HEALTH CARE-(Units)
  5,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  2,
  0,
  2,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  
]
export const midwestOperatingEngWelFundPayment = [// UNITED HEALTH CARE -(Payment)
  656,
  0,
  0,
  0,
  0,
  0,
  0,
  -199,
  0,
  0,
  0,
  417,
  126,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  138,
  0,
  174,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  
]
 
export const patientUnits = [// UNITED HEALTHCARE-(Units)
  20,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  1,
  12,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  3,
  0,
  
]
export const patientPayment = [// UNITED HEALTHCARE-(Payment)
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  
]
export const selfPayUnits = [// UNITED HEARTLAND-(Units)
  20,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  1,
  12,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  3,
  0,
  
]
export const selfPayPayment = [//UNITED HEARTLAND -(Payment)
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  
]

// august data is completed here.

export const ufcwLocal1546HealthAndWelfareFundUnits = [// UNITED HEALTHCARE-(Units)
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  
]
export const ufcwLocal1546HealthAndWelfareFundPayment = [// UNITED HEALTHCARE -(Payment)
  336,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  335,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  
]
export const unitedHealthCareUnits = [//UNITED HEARTLAND-(Units)
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  
]
export const unitedHealthCarePayment = [// UNITED HEARTLAND-(Payment)
  569,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  543,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  12,
  0,
  0,
  0,
  0,
  0,
  14,
  0,
  
]
export const unitedHEALTHCareUnits = [// USA senior Care-(Units)
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  
]
export const unitedHEALTHCarePayment = [// USA senior Care - (Payment)
  27,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  27,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  
]
export const usaSeniorCareUnits = [// UNITED HEALTH CARE -(Units)
  29,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  2,
  0,
  2,
  0,
  1,
  5,
  12,
  0,
  1,
  0,
  0,
  0,
  0,
  3,
  1,
  
]
export const usaSeniorCarePayment = [// UNITED HEALTH CARE-(Payment)
  353,
  44,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  170,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  138,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  
]
export const unitedHealthCareNewUnit = [// UNITED HEALTHCARE-(Unit)
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  
]  
export const unitedHealthCareNewPayment = [// UNITED HEALTHCARE-(Payment)
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  
]

export const unitHealthCareUnit = [// UNITED HEARTLAND - (Unit)
  20,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  1,
  12,
  0,
  1,
  0,
  0,
  0,
  0,
  3,
  0,
  
]
export const unitHealthCarePayment = [// UNITED HEARTLAND- Payment
  699,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  543,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  130,
  0,
  0,
  0,
  14,
  0,
  0,
  0,
  0,
  0,
  0,
  12,
  0,
  
]

export const UnitHealthCareUnits = [// USA senior Care-Unit
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  
]
export const UnitHealthCarePayments = [// USA senior Care- Payment
  27,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  27,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  
]
// end of the july data
// may month data.
export const USAseniorCareUnits = [// USA senior Care-units
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,


  
]
export const USAseniorCarePayment = [// USA senior Care- Payment
180, 
0,
0,
0,
0,
0,
132, 
0,   
40,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,   
0,
9, 
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0

  
]
// Until this is jan-2024 data
export const unitedHeartlandUnit = [ // UNITED HEARTLAND - (Unit)
2,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
1,
0,
0,
0,
0,
0,
0,
1,
0,
0,
0,
0,
0,
0,
0,
0,
0

]
export const unitedHeartlandPayment = [ // UNITED HEARTLAND - (Payment)
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0

]

