export const cptCodes = [
    'Select Code',
    'All',
    '20552',
    '20610',
    '20611',
    '62321',
    '63650',
    '63685',
    '64483',
    '64484',
    '64490',
    '64491',
    '64493',
    '64494',
    '64633',
    '64634',
    '64635',
    '64640',
    '76942',
    '77003',
    '95971',
    '95972',
    '95973',
    '99203',
    '99204',
    '99213',
    '99214',
    'A4550',
    'G8427',
    'J1100',
    'J1885',
    'J2001',
    'J2250',
    'J3010',
    'J7321',
    'MDREC',
    'MEDRE',
    'Q9967',
    'S0020',
    
    
]
export const cptCodesPersonal = [
    'Select Code',
    'All',
    '10060',
    '11200',
    '11201',
    '11420',
    '20610',
    '20611',
    '36410',
    '36415',
    '57452',
    '57454',
    '57455',
    '57511',
    '58100',
    '58300',
    '58301',
    '69210',
    '80048',
    '80050',
    '80053',
    '80061',
    '80076',
    '80307',
    '81000',
    '81001',
    '81002',
    '81003',
    '81025',
    '82043',
    '82044',
    '82274',
    '82306',
    '82465',
    '82570',
    '82607',
    '82728',
    '82746',
    '82947',
    '82962',
    '83036',
    '83037',
    '83516',
    '83540',
    '83550',
    '83718',
    '83735',
    '84146',
    '84153',
    '84154',
    '84436',
    '84439',
    '84443',
    '84466',
    '84478',
    '84480',
    '84481',
    '84550',
    '84702',
    '85025',
    '85027',
    '85610',
    '85611',
    '85652',
    '85730',
    '85732',
    '86038',
    '86039',
    '86225',
    '86235',
    '86431',
    '86580',
    '86592',
    '86735',
    '86762',
    '86765',
    '87070',
    '87071',
    '87086',
    '87088',
    '87205',
    '87389',
    '87491',
    '87591',
    '88142',
    '90460',
    '90471',
    '90472',
    '90656',
    '90658',
    '90674',
    '90686',
    '90688',
    '90715',
    '90732',
    '93000',
    '94640',
    '96127',
    '96372',
    '96373',
    '97001',
    '97002',
    '97032',
    '97035',
    '97110',
    '97112',
    '97116',
    '97140',
    '97161',
    '97164',
    '99202',
    '99203',
    '99204',
    '99205',
    '99211',
    '99212',
    '99213',
    '99214',
    '99215',
    '99223',
    '99233',
    '99236',
    '99239',
    '99243',
    '99244',
    '99245',
    '99255',
    '99306',
    '99307',
    '99309',
    '99310',
    '99384',
    '99385',
    '99386',
    '99387',
    '99394',
    '99395',
    '99396',
    '99397',
    '99401',
    '99441',
    '99442',
    '99495',
    '99496',
    '99497',
    '1022F',
    '1036F',
    '1123F',
    '1157F',
    '1158F',
    '1220F',
    '3014F',
    '3015F',
    '3016F',
    '3017F',
    '3288F',
    '3351F',
    '4011F',
    '4013F',
    '4040F',
    'CAP00',
    'D9987',
    'G0008',
    'G0009',
    'G0101',
    'G0103',
    'G0179',
    'G0180',
    'G0283',
    'G0328',
    'G0402',
    'G0438',
    'G0439',
    'G0442',
    'G0444',
    'G0447',
    'G8417',
    'G8476',
    'G8482',
    'J1010',
    'J1020',
    'J1050',
    'J1070',
    'j1071',
    'J3420',
    'Q0091',
    
]
export const cptCodesRX = [
    'Select code',
    'All',
    '99072',
    '99213',
    '99214',
    'J1050',
    '96127',
    '80061L',
    '90460',
    '90471',
    '36415L',
    '0241U',
    '99396',
    '36416',
    '90461',
    '99490',
    '80053L',
    'G0439',
    '80050L',
    'g0444',
    '99395',
    '85025L',
    '90682',
    '90686',
    '83036',
    '87651',
    '83036l',
    '93000',
    '90715',
    '81003',
    'NO SHOW',
    'G0008',
    '92587',
    '99212',
    '90662',
    '85610',
    '82306L',
    '96372',
    '87635',
    '99394',
    '99392',
    '96110',
    '84153L',
    '99393',
    '90670',
    '82570L',
    '82043L',
    '99215',
    '77080',
    '84443L',
    '90750',
    '85018',
    '82728L',
    '90472',
    '99391',
    '87086L',
    '99203',
    '90651',
    'J3420',
    'S9981',
    '99385',
    '83540L',
    '82607L',
    '89002',
    '81001L',
    '69209',
    'G0009',
    '87088L',
    '99173',
    'NOCHG',
    '85652L',
    '99204',
    '90698',
    '90732',
    '90621',
    '90734',
    '83655',
    '99484',
    '90633',
    '84466L',
    '99439',
    '99397',
    '84439L',
    '81025',
    '82947',
    '90680',
    '99386',
    '99080',
    'J0500',
    '99211',
    '36415',
    '87426',
    '99441',
    'J0561',
    'G0180',
    '99442',
    '17110',
    '90707',
    '99495',
    '99496',
    '90716',
    '90710',
    '90696',
    '90744',
    '80048L',
    '90746',
    '80053',
    '97035',
    'G0402',
    '99383',
    '99406',
    '87880',
    '80061',
    '99202',
    'G2012',
    '99381',
    '99421',
    '85025',
    'G0438',
    '90697',
    '90619',
    '69210',
    '84443',
    '99384',
    '90632',
    '10060',
    'G0179',
    '82247',
    '89000',
    '99382',
    '11200',
    '20550',
    '89003',
    '99422',
    '99499',
    '89001',
    '86580',
    'J7613',
    '93270',
    '99205',
    '94664',
    '99423',
    '99443',
    '11102',
    '58300',
    'S8110',
    '20610',
    '82306',
    '11981',
    '58301',
    '82746',
    '20605',
    '87491',
    '93272',
    '99417',
    '11201',
    '82728',
    '84153',
    '87086',
    'j0696',
    '83540',
    '86703',
    '94640',
    'J1020',
    '11406',
    '11982',
    '17000',
    '82043',
    '86592',
    '92567',
    '11303',
    '69090',
    '81001',
    '82607',
    '85018QW',
    '90700',
    '90713',
    '80076',
    '87804',
    'G0103L',
    '17250',
    '83001',
    '83002',
    '86803',
    '87340',
    '90648',
    '94060',
    'J7307',
    '10061',
    '82374',
    '83516',
    '84403',
    '84450',
    '85379',
    '85730',
    '86480',
    '90714',
    'J3250',
    'J3301',
    '11401',
    '11402',
    '20600',
    '82270',
    '82570',
    '82627',
    '83735',
    '84100',
    '85045',
    '86140',
    '86769',
    '87070L',
    '99387',
    '11302',
    '11306',
    '11404',
    '11422',
    '11426',
    '11441',
    '11983',
    '12001',
    '17111',
    '20520',
    '20553',
    '46083',
    '82248',
    '82274',
    '82310',
    '82533',
    '82550',
    '82977',
    '84439',
    '86038',
    '86510',
    '87186L',
    '87806',
    '87880QW',
    '88175',
    '90677',
    '96160',
    '99358',
    'A7003',
    'J7298',
    'j7300',
    '10120',
    '10140',
    '11300',
    '11301',
    '11400',
    '11602',
    '11740',
    '30901',
    '53000',
    '56420',
    '69000',
    '69200',
    '73610',
    '80048',
    '80050',
    '80320',
    '82365',
    '82397',
    '82465',
    '82540l',
    '82565',
    '82785',
    '82947QW',
    '83013',
    '83021',
    '83718',
    '83874',
    '84146',
    '84430',
    '84466',
    '84481',
    '84520',
    '84550',
    '84703',
    '85651',
    '85652',
    '86003',
    '86039',
    '86651',
    '86677',
    '86695',
    '86696',
    '86706',
    '86735',
    '86762',
    '86765',
    '86787',
    '86900',
    '87040',
    '87070',
    '87255',
    '87389',
    '87510',
    '87661',
    '88141',
    '88305',
    '90658',
    '94010',
    '94760',
    '96161',
    '97036',
    '99090',
    '99172',
    '99354',
    'G0101',
    'G0108',
    'g0145',
    'J1030',
    'j2550',
    'j3240',
    'J3400',
    'j7301',
    'NSF',
    'J3535',
    '82330',
    '84075',
    '87591'
]