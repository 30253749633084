import {    pro7F1Charge,pro7F1Payment,pro7F1Collectable,pro7F1NonCollectable,pro7F1ClaimCount,pro7F1PerVisit,
    pro7F2Charge,pro7F2Payment,pro7F2Collectable,pro7F2NonCollectable,pro7F2ClaimCount,pro7F2PerVisit,
    pro7F3Charge,pro7F3Payment,pro7F3Collectable,pro7F3NonCollectable,pro7F3ClaimCount,pro7F3PerVisit,} from './FacilityData';

import {provider7PerVisist} from './ProvidersAndFacility';




export const pro7WithJan21Charges = [
    pro7F1Charge.jan21,
    pro7F2Charge.jan21,
    pro7F3Charge.jan21,
    pro7F1Charge.jan21 + pro7F2Charge.jan21 + pro7F3Charge.jan21

]
// console.log("pro7WithJan21Charges :",pro7WithJan21Charges)
export const pro7WithJan21Pay = [
    pro7F1Payment.jan21,
    pro7F2Payment.jan21,
    pro7F3Payment.jan21,
    pro7F1Payment.jan21 + pro7F2Payment.jan21 + pro7F3Payment.jan21
]
export const pro7WithJan21Count = [
    pro7F1Collectable.jan21,
    pro7F2Collectable.jan21,
    pro7F3Collectable.jan21,
    pro7F1Collectable.jan21 + pro7F2Collectable.jan21 + pro7F3Collectable.jan21
]
export const pro7WithJan21NonCount = [
    pro7F1NonCollectable.jan21,
    pro7F2NonCollectable.jan21,
    pro7F3NonCollectable.jan21,
    pro7F1NonCollectable.jan21 + pro7F2NonCollectable.jan21 + pro7F3NonCollectable.jan21
]
export const pro7WithJan21Claim = [
    pro7F1ClaimCount.jan21,
    pro7F2ClaimCount.jan21,
    pro7F3ClaimCount.jan21,
    pro7F1ClaimCount.jan21 + pro7F2ClaimCount.jan21 + pro7F3ClaimCount.jan21
]
export const pro7WithJan21PerVisit = [
    pro7F1PerVisit.jan21,
    pro7F2PerVisit.jan21,
    pro7F3PerVisit.jan21,
    provider7PerVisist[0]
]
// feb 21
export const pro7WithFeb21Charges = [
    pro7F1Charge.feb21,
    pro7F2Charge.feb21,
    pro7F3Charge.feb21,
    pro7F1Charge.feb21 + pro7F2Charge.feb21 + pro7F3Charge.feb21
]
export const pro7WithFeb21Pay = [
    pro7F1Payment.feb21,
    pro7F2Payment.feb21,
    pro7F3Payment.feb21,
    pro7F1Payment.feb21 + pro7F2Payment.feb21 + pro7F3Payment.feb21
]
export const pro7WithFeb21Count = [
    pro7F1Collectable.feb21,
    pro7F2Collectable.feb21,
    pro7F3Collectable.feb21,
    pro7F1Collectable.feb21 + pro7F2Collectable.feb21 + pro7F3Collectable.feb21
]
export const pro7WithFeb21NonCount = [
    pro7F1NonCollectable.feb21,
    pro7F2NonCollectable.feb21,
    pro7F3NonCollectable.feb21,
    pro7F1NonCollectable.feb21 + pro7F2NonCollectable.feb21 + pro7F3NonCollectable.feb21
]
export const pro7WithFeb21Claim = [
    pro7F1ClaimCount.feb21,
    pro7F2ClaimCount.feb21,
    pro7F3ClaimCount.feb21,
    pro7F1ClaimCount.feb21 + pro7F2ClaimCount.feb21 + pro7F3ClaimCount.feb21
]
export const pro7WithFeb21PerVisit = [
    pro7F1PerVisit.feb21,
    pro7F2PerVisit.feb21,
    pro7F3PerVisit.feb21,
    provider7PerVisist[1]
]
// mar 21
export const pro7WithMar21Charges = [
    pro7F1Charge.mar21,
    pro7F2Charge.mar21,
    pro7F3Charge.mar21,
    pro7F1Charge.mar21 + pro7F2Charge.mar21 + pro7F3Charge.mar21
]
export const pro7WithMar21Pay = [
    pro7F1Payment.mar21,
    pro7F2Payment.mar21,
    pro7F3Payment.mar21,
    pro7F1Payment.mar21 + pro7F2Payment.mar21 + pro7F3Payment.mar21
]
export const pro7WithMar21Count = [
    pro7F1Collectable.mar21,
    pro7F2Collectable.mar21,
    pro7F3Collectable.mar21,
    pro7F1Collectable.mar21 + pro7F2Collectable.mar21 + pro7F3Collectable.mar21
]
export const pro7WithMar21NonCount = [
    pro7F1NonCollectable.mar21,
    pro7F2NonCollectable.mar21,
    pro7F3NonCollectable.mar21,
    pro7F1NonCollectable.mar21 + pro7F2NonCollectable.mar21 + pro7F3NonCollectable.mar21
]
export const pro7WithMar21Claim = [
    pro7F1ClaimCount.mar21,
    pro7F2ClaimCount.mar21,
    pro7F3ClaimCount.mar21,
    pro7F1ClaimCount.mar21 + pro7F2ClaimCount.mar21 + pro7F3ClaimCount.mar21
]
export const pro7WithMar21PerVisit = [
    pro7F1PerVisit.mar21,
    pro7F2PerVisit.mar21,
    pro7F3PerVisit.mar21,
    provider7PerVisist[2]
]
// april 21
export const pro7WithApr21Charges = [
    pro7F1Charge.apr21,
    pro7F2Charge.apr21,
    pro7F3Charge.apr21,
    pro7F1Charge.apr21 + pro7F2Charge.apr21 + pro7F3Charge.apr21
]
export const pro7WithApr21Pay = [
    pro7F1Payment.apr21,
    pro7F2Payment.apr21,
    pro7F3Payment.apr21,
    pro7F1Payment.apr21 + pro7F2Payment.apr21 + pro7F3Payment.apr21
]
export const pro7WithApr21Count = [
    pro7F1Collectable.apr21,
    pro7F2Collectable.apr21,
    pro7F3Collectable.apr21,
    pro7F1Collectable.apr21 + pro7F2Collectable.apr21 + pro7F3Collectable.apr21
]
export const pro7WithApr21NonCount = [
    pro7F1NonCollectable.apr21,
    pro7F2NonCollectable.apr21,
    pro7F3NonCollectable.apr21,
    pro7F1NonCollectable.apr21 + pro7F2NonCollectable.apr21 + pro7F3NonCollectable.apr21
]
export const pro7WithApr21Claim = [
    pro7F1ClaimCount.apr21,
    pro7F2ClaimCount.apr21,
    pro7F3ClaimCount.apr21,
    pro7F1ClaimCount.apr21 + pro7F2ClaimCount.apr21 + pro7F3ClaimCount.apr21
]
export const pro7WithApr21PerVisit = [
    pro7F1PerVisit.apr21,
    pro7F2PerVisit.apr21,
    pro7F3PerVisit.apr21,
    provider7PerVisist[3]
]
// may 21
export const pro7WithMay21Charges = [
    pro7F1Charge.may21,
    pro7F2Charge.may21,
    pro7F3Charge.may21,
    pro7F1Charge.may21 + pro7F2Charge.may21 + pro7F3Charge.may21
]
export const pro7WithMay21Pay = [
    pro7F1Payment.may21,
    pro7F2Payment.may21,
    pro7F3Payment.may21,
    pro7F1Payment.may21 + pro7F2Payment.may21 + pro7F3Payment.may21
]
export const pro7WithMay21Count = [
    pro7F1Collectable.may21,
    pro7F2Collectable.may21,
    pro7F3Collectable.may21,
    pro7F1Collectable.may21 + pro7F2Collectable.may21 + pro7F3Collectable.may21
]
export const pro7WithMay21NonCount = [
    pro7F1NonCollectable.may21,
    pro7F2NonCollectable.may21,
    pro7F3NonCollectable.may21,
    pro7F1NonCollectable.may21 + pro7F2NonCollectable.may21 + pro7F3NonCollectable.may21
]
export const pro7WithMay21Claim = [
    pro7F1ClaimCount.may21,
    pro7F2ClaimCount.may21,
    pro7F3ClaimCount.may21,
    pro7F1ClaimCount.may21 + pro7F2ClaimCount.may21 + pro7F3ClaimCount.may21
]
export const pro7WithMay21PerVisit = [
    pro7F1PerVisit.may21,
    pro7F2PerVisit.may21,
    pro7F3PerVisit.may21,
    provider7PerVisist[4]
]
// jun 21
export const pro7WithJun21Charges = [
    pro7F1Charge.jun21,
    pro7F2Charge.jun21,
    pro7F3Charge.jun21,
    pro7F1Charge.jun21 + pro7F2Charge.jun21 + pro7F3Charge.jun21
]
export const pro7WithJun21Pay = [
    pro7F1Payment.jun21,
    pro7F2Payment.jun21,
    pro7F3Payment.jun21,
    pro7F1Payment.jun21 + pro7F2Payment.jun21 + pro7F3Payment.jun21
]
export const pro7WithJun21Count = [
    pro7F1Collectable.jun21,
    pro7F2Collectable.jun21,
    pro7F3Collectable.jun21,
    pro7F1Collectable.jun21 + pro7F2Collectable.jun21 + pro7F3Collectable.jun21
]
export const pro7WithJun21NonCount = [
    pro7F1NonCollectable.jun21,
    pro7F2NonCollectable.jun21,
    pro7F3NonCollectable.jun21,
    pro7F1NonCollectable.jun21 + pro7F2NonCollectable.jun21 + pro7F3NonCollectable.jun21
]
export const pro7WithJun21Claim = [
    pro7F1ClaimCount.jun21,
    pro7F2ClaimCount.jun21,
    pro7F3ClaimCount.jun21,
    pro7F1ClaimCount.jun21 + pro7F2ClaimCount.jun21 + pro7F3ClaimCount.jun21
]
export const pro7WithJun21PerVisit = [
    pro7F1PerVisit.jun21,
    pro7F2PerVisit.jun21,
    pro7F3PerVisit.jun21,
    provider7PerVisist[5]
]
// jul 21
export const pro7WithJul21Charges = [
    pro7F1Charge.jul21,
    pro7F2Charge.jul21,
    pro7F3Charge.jul21,
    pro7F1Charge.jul21 + pro7F2Charge.jul21 + pro7F3Charge.jul21,

]
export const pro7WithJul21Pay = [
    pro7F1Payment.jul21,
    pro7F2Payment.jul21,
    pro7F3Payment.jul21,
    pro7F1Payment.jul21 + pro7F2Payment.jul21 + pro7F3Payment.jul21
]
export const pro7WithJul21Count = [
    pro7F1Collectable.jul21,
    pro7F2Collectable.jul21,
    pro7F3Collectable.jul21,
    pro7F1Collectable.jul21 + pro7F2Collectable.jul21 + pro7F3Collectable.jul21
]
export const pro7WithJul21NonCount = [
    pro7F1NonCollectable.jul21,
    pro7F2NonCollectable.jul21,
    pro7F3NonCollectable.jul21,
    pro7F1NonCollectable.jul21 + pro7F2NonCollectable.jul21 + pro7F3NonCollectable.jul21
]
export const pro7WithJul21Claim = [
    pro7F1ClaimCount.jul21,
    pro7F2ClaimCount.jul21,
    pro7F3ClaimCount.jul21,
    pro7F1ClaimCount.jul21 + pro7F2ClaimCount.jul21 + pro7F3ClaimCount.jul21
]
export const pro7WithJul21PerVisit = [
    pro7F1PerVisit.jul21,
    pro7F2PerVisit.jul21,
    pro7F3PerVisit.jul21,
    provider7PerVisist[6]
]
// aug 21
export const pro7WithAug21Charges = [
    pro7F1Charge.aug21,
    pro7F2Charge.aug21,
    pro7F3Charge.aug21,
    pro7F1Charge.aug21 + pro7F2Charge.aug21 + pro7F3Charge.aug21
]
export const pro7WithAug21Pay = [
    pro7F1Payment.aug21,
    pro7F2Payment.aug21,
    pro7F3Payment.aug21,
    pro7F1Payment.aug21 + pro7F2Payment.aug21 + pro7F3Payment.aug21
]
export const pro7WithAug21Count = [
    pro7F1Collectable.aug21,
    pro7F2Collectable.aug21,
    pro7F3Collectable.aug21,
    pro7F1Collectable.aug21 + pro7F2Collectable.aug21 + pro7F3Collectable.aug21
]
export const pro7WithAug21NonCount = [
    pro7F1NonCollectable.aug21,
    pro7F2NonCollectable.aug21,
    pro7F3NonCollectable.aug21,
    pro7F1NonCollectable.aug21 + pro7F2NonCollectable.aug21 + pro7F3NonCollectable.aug21
]
export const pro7WithAug21Claim = [
    pro7F1ClaimCount.aug21,
    pro7F2ClaimCount.aug21,
    pro7F3ClaimCount.aug21,
    pro7F1ClaimCount.aug21 + pro7F2ClaimCount.aug21 + pro7F3ClaimCount.aug21
]
export const pro7WithAug21PerVisit = [
    pro7F1PerVisit.aug21,
    pro7F2PerVisit.aug21,
    pro7F3PerVisit.aug21,
    provider7PerVisist[7]
]
// sep 21
export const pro7WithSep21Charges = [
    pro7F1Charge.sep21,
    pro7F2Charge.sep21,
    pro7F3Charge.sep21,
    pro7F1Charge.sep21 + pro7F2Charge.sep21 + pro7F3Charge.sep21
]
export const pro7WithSep21Pay = [
    pro7F1Payment.sep21,
    pro7F2Payment.sep21,
    pro7F3Payment.sep21,
    pro7F1Payment.sep21 + pro7F2Payment.sep21 + pro7F3Payment.sep21
]
export const pro7WithSep21Count = [
    pro7F1Collectable.sep21,
    pro7F2Collectable.sep21,
    pro7F3Collectable.sep21,
    pro7F1Collectable.sep21 + pro7F2Collectable.sep21 + pro7F3Collectable.sep21
]
export const pro7WithSep21NonCount = [
    pro7F1NonCollectable.sep21,
    pro7F2NonCollectable.sep21,
    pro7F3NonCollectable.sep21,
    pro7F1NonCollectable.sep21 + pro7F2NonCollectable.sep21 + pro7F3NonCollectable.sep21
]
export const pro7WithSep21Claim = [
    pro7F1ClaimCount.sep21,
    pro7F2ClaimCount.sep21,
    pro7F3ClaimCount.sep21,
    pro7F1ClaimCount.sep21 + pro7F2ClaimCount.sep21 + pro7F3ClaimCount.sep21
]
export const pro7WithSep21PerVisit = [
    pro7F1PerVisit.sep21,
    pro7F2PerVisit.sep21,
    pro7F3PerVisit.sep21,
    provider7PerVisist[8]
]
// oct 21
export const pro7WithOct21Charges = [
    pro7F1Charge.oct21,
    pro7F2Charge.oct21,
    pro7F3Charge.oct21,
    pro7F1Charge.oct21 + pro7F2Charge.oct21 + pro7F3Charge.oct21
]
export const pro7WithOct21Pay = [
    pro7F1Payment.oct21,
    pro7F2Payment.oct21,
    pro7F3Payment.oct21,
    pro7F1Payment.oct21 + pro7F2Payment.oct21 + pro7F3Payment.oct21
]
export const pro7WithOct21Count = [
    pro7F1Collectable.oct21,
    pro7F2Collectable.oct21,
    pro7F3Collectable.oct21,
    pro7F1Collectable.oct21 + pro7F2Collectable.oct21 + pro7F3Collectable.oct21
]
export const pro7WithOct21NonCount = [
    pro7F1NonCollectable.oct21,
    pro7F2NonCollectable.oct21,
    pro7F3NonCollectable.oct21,
    pro7F1NonCollectable.oct21 + pro7F2NonCollectable.oct21 + pro7F3NonCollectable.oct21
]
export const pro7WithOct21Claim = [
    pro7F1ClaimCount.oct21,
    pro7F2ClaimCount.oct21,
    pro7F3ClaimCount.oct21,
    pro7F1ClaimCount.oct21 + pro7F2ClaimCount.oct21 + pro7F3ClaimCount.oct21
]
export const pro7WithOct21PerVisit = [
    pro7F1PerVisit.oct21,
    pro7F2PerVisit.oct21,
    pro7F3PerVisit.oct21,
    provider7PerVisist[9]
]
// nov 21
export const pro7WithNov21Charges = [
    pro7F1Charge.nov21,
    pro7F2Charge.nov21,
    pro7F3Charge.nov21,
    pro7F1Charge.nov21 + pro7F2Charge.nov21 + pro7F3Charge.nov21
]
export const pro7WithNov21Pay = [
    pro7F1Payment.nov21,
    pro7F2Payment.nov21,
    pro7F3Payment.nov21,
    pro7F1Payment.nov21 + pro7F2Payment.nov21 + pro7F3Payment.nov21
]
export const pro7WithNov21Count = [
    pro7F1Collectable.nov21,
    pro7F2Collectable.nov21,
    pro7F3Collectable.nov21,
    pro7F1Collectable.nov21 + pro7F2Collectable.nov21 + pro7F3Collectable.nov21
]
export const pro7WithNov21NonCount = [
    pro7F1NonCollectable.nov21,
    pro7F2NonCollectable.nov21,
    pro7F3NonCollectable.nov21,
    pro7F1NonCollectable.nov21 + pro7F2NonCollectable.nov21 + pro7F3NonCollectable.nov21
]
export const pro7WithNov21Claim = [
    pro7F1ClaimCount.nov21,
    pro7F2ClaimCount.nov21,
    pro7F3ClaimCount.nov21,
    pro7F1ClaimCount.nov21 + pro7F2ClaimCount.nov21 + pro7F3ClaimCount.nov21
]
export const pro7WithNov21PerVisit = [
    pro7F1PerVisit.nov21,
    pro7F2PerVisit.nov21,
    pro7F3PerVisit.nov21,
    provider7PerVisist[10]
]
// dec 21
export const pro7WithDec21Charges = [
    pro7F1Charge.dec21,
    pro7F2Charge.dec21,
    pro7F3Charge.dec21,
    pro7F1Charge.dec21 + pro7F2Charge.dec21 + pro7F3Charge.dec21
]
export const pro7WithDec21Pay = [
    pro7F1Payment.dec21,
    pro7F2Payment.dec21,
    pro7F3Payment.dec21,
    pro7F1Payment.dec21 + pro7F2Payment.dec21 + pro7F3Payment.dec21
]
export const pro7WithDec21Count = [
    pro7F1Collectable.dec21,
    pro7F2Collectable.dec21,
    pro7F3Collectable.dec21,
    pro7F1Collectable.dec21 + pro7F2Collectable.dec21 + pro7F3Collectable.dec21
]
export const pro7WithDec21NonCount = [
    pro7F1NonCollectable.dec21,
    pro7F2NonCollectable.dec21,
    pro7F3NonCollectable.dec21,
    pro7F1NonCollectable.dec21 + pro7F2NonCollectable.dec21 + pro7F3NonCollectable.dec21
] 
export const pro7WithDec21Claim = [
    pro7F1ClaimCount.dec21,
    pro7F2ClaimCount.dec21,
    pro7F3ClaimCount.dec21,
    pro7F1ClaimCount.dec21 + pro7F2ClaimCount.dec21 + pro7F3ClaimCount.dec21
]
export const pro7WithDec21PerVisit = [
    pro7F1PerVisit.dec21,
    pro7F2PerVisit.dec21,
    pro7F3PerVisit.dec21,
    provider7PerVisist[11]
]

// provider-1 
// jan 22
export const pro7WithJan22Charges = [
    pro7F1Charge.jan22,
    pro7F2Charge.jan22,
    pro7F3Charge.jan22,
    pro7F1Charge.jan22 + pro7F2Charge.jan22 + pro7F3Charge.jan22
]
export const pro7WithJan22Pay = [
    pro7F1Payment.jan22,
    pro7F2Payment.jan22,
    pro7F3Payment.jan22,
    pro7F1Payment.jan22 + pro7F2Payment.jan22 + pro7F3Payment.jan22
]
export const pro7WithJan22Count = [
    pro7F1Collectable.jan22,
    pro7F2Collectable.jan22,
    pro7F3Collectable.jan22,
    pro7F1Collectable.jan22 + pro7F2Collectable.jan22 + pro7F3Collectable.jan22
]
export const pro7WithJan22NonCount = [
    pro7F1NonCollectable.jan22,
    pro7F2NonCollectable.jan22,
    pro7F3NonCollectable.jan22,
    pro7F1NonCollectable.jan22 + pro7F2NonCollectable.jan22 + pro7F3NonCollectable.jan22
]
export const pro7WithJan22Claim = [
    pro7F1ClaimCount.jan22,
    pro7F2ClaimCount.jan22,
    pro7F3ClaimCount.jan22,
    pro7F1ClaimCount.jan22 + pro7F2ClaimCount.jan22 + pro7F3ClaimCount.jan22
]
export const pro7WithJan22PerVisit = [
    pro7F1PerVisit.jan22,
    pro7F2PerVisit.jan22,
    pro7F3PerVisit.jan22,
    provider7PerVisist[12]
]
// feb 22
export const pro7WithFeb22Charges = [
    pro7F1Charge.feb22,
    pro7F2Charge.feb22,
    pro7F3Charge.feb22,
    pro7F1Charge.feb22 + pro7F2Charge.feb22 + pro7F3Charge.feb22
]
export const pro7WithFeb22Pay = [
    pro7F1Payment.feb22,
    pro7F2Payment.feb22,
    pro7F3Payment.feb22,
    pro7F1Payment.feb22 + pro7F2Payment.feb22 + pro7F3Payment.feb22
]
export const pro7WithFeb22Count = [
    pro7F1Collectable.feb22,
    pro7F2Collectable.feb22,
    pro7F3Collectable.feb22,
    pro7F1Collectable.feb22 + pro7F2Collectable.feb22 + pro7F3Collectable.feb22
]
export const pro7WithFeb22NonCount = [
    pro7F1NonCollectable.feb22,
    pro7F2NonCollectable.feb22,
    pro7F3NonCollectable.feb22,
    pro7F1NonCollectable.feb22 + pro7F2NonCollectable.feb22 + pro7F3NonCollectable.feb22
]
export const pro7WithFeb22Claim = [
    pro7F1ClaimCount.feb22,
    pro7F2ClaimCount.feb22,
    pro7F3ClaimCount.feb22,
    pro7F1ClaimCount.feb22 + pro7F2ClaimCount.feb22 + pro7F3ClaimCount.feb22
]
export const pro7WithFeb22PerVisit = [
    pro7F1PerVisit.feb22,
    pro7F2PerVisit.feb22,
    pro7F3PerVisit.feb22,
    provider7PerVisist[13]
]
// mar 22
export const pro7WithMar22Charges = [
    pro7F1Charge.mar22,
    pro7F2Charge.mar22,
    pro7F3Charge.mar22,
    pro7F1Charge.mar22 + pro7F2Charge.mar22 + pro7F3Charge.mar22
]
export const pro7WithMar22Pay = [
    pro7F1Payment.mar22,
    pro7F2Payment.mar22,
    pro7F3Payment.mar22,
    pro7F1Payment.mar22 + pro7F2Payment.mar22 + pro7F3Payment.mar22
]
export const pro7WithMar22Count = [
    pro7F1Collectable.mar22,
    pro7F2Collectable.mar22,
    pro7F3Collectable.mar22,
    pro7F1Collectable.mar22 + pro7F2Collectable.mar22 + pro7F3Collectable.mar22
]
export const pro7WithMar22NonCount = [
    pro7F1NonCollectable.mar22,
    pro7F2NonCollectable.mar22,
    pro7F3NonCollectable.mar22,
    pro7F1NonCollectable.mar22 + pro7F2NonCollectable.mar22 + pro7F3NonCollectable.mar22
]
export const pro7WithMar22Claim = [
    pro7F1ClaimCount.mar22,
    pro7F2ClaimCount.mar22,
    pro7F3ClaimCount.mar22,
    pro7F1ClaimCount.mar22 + pro7F2ClaimCount.mar22 + pro7F3ClaimCount.mar22
]
export const pro7WithMar22PerVisit = [
    pro7F1PerVisit.mar22,
    pro7F2PerVisit.mar22,
    pro7F3PerVisit.mar22,
    provider7PerVisist[14]
]
// april 22
export const pro7WithApr22Charges = [
    pro7F1Charge.apr22,
    pro7F2Charge.apr22,
    pro7F3Charge.apr22,
    pro7F1Charge.apr22 + pro7F2Charge.apr22 + pro7F3Charge.apr22
]
export const pro7WithApr22Pay = [
    pro7F1Payment.apr22,
    pro7F2Payment.apr22,
    pro7F3Payment.apr22,
    pro7F1Payment.apr22 + pro7F2Payment.apr22 + pro7F3Payment.apr22
]
export const pro7WithApr22Count = [
    pro7F1Collectable.apr22,
    pro7F2Collectable.apr22,
    pro7F3Collectable.apr22,
    pro7F1Collectable.apr22 + pro7F2Collectable.apr22 + pro7F3Collectable.apr22
]
export const pro7WithApr22NonCount = [
    pro7F1NonCollectable.apr22,
    pro7F2NonCollectable.apr22,
    pro7F3NonCollectable.apr22,
    pro7F1NonCollectable.apr22 + pro7F2NonCollectable.apr22 + pro7F3NonCollectable.apr22
]
export const pro7WithApr22Claim = [
    pro7F1ClaimCount.apr22,
    pro7F2ClaimCount.apr22,
    pro7F3ClaimCount.apr22,
    pro7F1ClaimCount.apr22 + pro7F2ClaimCount.apr22 + pro7F3ClaimCount.apr22
]
export const pro7WithApr22PerVisit = [
    pro7F1PerVisit.apr22,
    pro7F2PerVisit.apr22,
    pro7F3PerVisit.apr22,
    provider7PerVisist[15]
]
// may 22
export const pro7WithMay22Charges = [
    pro7F1Charge.may22,
    pro7F2Charge.may22,
    pro7F3Charge.may22,
    pro7F1Charge.may22 + pro7F2Charge.may22 + pro7F3Charge.may22
]
export const pro7WithMay22Pay = [
    pro7F1Payment.may22,
    pro7F2Payment.may22,
    pro7F3Payment.may22,
    pro7F1Payment.may22 + pro7F2Payment.may22 + pro7F3Payment.may22
]
export const pro7WithMay22Count = [
    pro7F1Collectable.may22,
    pro7F2Collectable.may22,
    pro7F3Collectable.may22,
    pro7F1Collectable.may22 + pro7F2Collectable.may22 + pro7F3Collectable.may22
]
export const pro7WithMay22NonCount = [
    pro7F1NonCollectable.may22,
    pro7F2NonCollectable.may22,
    pro7F3NonCollectable.may22,
    pro7F1NonCollectable.may22 + pro7F2NonCollectable.may22 + pro7F3NonCollectable.may22
]
export const pro7WithMay22Claim = [
    pro7F1ClaimCount.may22,
    pro7F2ClaimCount.may22,
    pro7F3ClaimCount.may22,
    pro7F1ClaimCount.may22 + pro7F2ClaimCount.may22 + pro7F3ClaimCount.may22
]
export const pro7WithMay22PerVisit = [
    pro7F1PerVisit.may22,
    pro7F2PerVisit.may22,
    pro7F3PerVisit.may22,
    provider7PerVisist[16]
]
// jun 22
export const pro7WithJun22Charges = [
    pro7F1Charge.jun22,
    pro7F2Charge.jun22,
    pro7F3Charge.jun22,
    pro7F1Charge.jun22 + pro7F2Charge.jun22 + pro7F3Charge.jun22
]
export const pro7WithJun22Pay = [
    pro7F1Payment.jun22,
    pro7F2Payment.jun22,
    pro7F3Payment.jun22,
    pro7F1Payment.jun22 + pro7F2Payment.jun22 + pro7F3Payment.jun22
]
export const pro7WithJun22Count = [
    pro7F1Collectable.jun22,
    pro7F2Collectable.jun22,
    pro7F3Collectable.jun22,
    pro7F1Collectable.jun22 + pro7F2Collectable.jun22 + pro7F3Collectable.jun22
]
export const pro7WithJun22NonCount = [
    pro7F1NonCollectable.jun22,
    pro7F2NonCollectable.jun22,
    pro7F3NonCollectable.jun22,
    pro7F1NonCollectable.jun22 + pro7F2NonCollectable.jun22 + pro7F3NonCollectable.jun22
]
export const pro7WithJun22Claim = [
    pro7F1ClaimCount.jun22,
    pro7F2ClaimCount.jun22,
    pro7F3ClaimCount.jun22,
    pro7F1ClaimCount.jun22 + pro7F2ClaimCount.jun22 + pro7F3ClaimCount.jun22
]
export const pro7WithJun22PerVisit = [
    pro7F1PerVisit.jun22,
    pro7F2PerVisit.jun22,
    pro7F3PerVisit.jun22,
    provider7PerVisist[17]
]
// jul 22
export const pro7WithJul22Charges = [
    pro7F1Charge.jul22,
    pro7F2Charge.jul22,
    pro7F3Charge.jul22,
    pro7F1Charge.jul22 + pro7F2Charge.jul22 + pro7F3Charge.jul22
]
export const pro7WithJul22Pay = [
    pro7F1Payment.jul22,
    pro7F2Payment.jul22,
    pro7F3Payment.jul22,
    pro7F1Payment.jul22 + pro7F2Payment.jul22 + pro7F3Payment.jul22
]
export const pro7WithJul22Count = [
    pro7F1Collectable.jul22,
    pro7F2Collectable.jul22,
    pro7F3Collectable.jul22,
    pro7F1Collectable.jul22 + pro7F2Collectable.jul22 + pro7F3Collectable.jul22
]
export const pro7WithJul22NonCount = [
    pro7F1NonCollectable.jul22,
    pro7F2NonCollectable.jul22,
    pro7F3NonCollectable.jul22,
    pro7F1NonCollectable.jul22 + pro7F2NonCollectable.jul22 + pro7F3NonCollectable.jul22
]
export const pro7WithJul22Claim = [
    pro7F1ClaimCount.jul22,
    pro7F2ClaimCount.jul22,
    pro7F3ClaimCount.jul22,
    pro7F1ClaimCount.jul22 + pro7F2ClaimCount.jul22 + pro7F3ClaimCount.jul22
]
export const pro7WithJul22PerVisit = [
    pro7F1PerVisit.jul22,
    pro7F2PerVisit.jul22,
    pro7F3PerVisit.jul22,
    provider7PerVisist[18]
]
// aug 22
export const pro7WithAug22Charges = [
    pro7F1Charge.aug22,
    pro7F2Charge.aug22,
    pro7F3Charge.aug22,
    pro7F1Charge.aug22 + pro7F2Charge.aug22 + pro7F3Charge.aug22
]
export const pro7WithAug22Pay = [
    pro7F1Payment.aug22,
    pro7F2Payment.aug22,
    pro7F3Payment.aug22,
    pro7F1Payment.aug22 + pro7F2Payment.aug22 + pro7F3Payment.aug22
]
export const pro7WithAug22Count = [
    pro7F1Collectable.aug22,
    pro7F2Collectable.aug22,
    pro7F3Collectable.aug22,
    pro7F1Collectable.aug22 + pro7F2Collectable.aug22 + pro7F3Collectable.aug22
]
export const pro7WithAug22NonCount = [
    pro7F1NonCollectable.aug22,
    pro7F2NonCollectable.aug22,
    pro7F3NonCollectable.aug22,
    pro7F1NonCollectable.aug22 + pro7F2NonCollectable.aug22 + pro7F3NonCollectable.aug22
]
export const pro7WithAug22Claim = [
    pro7F1ClaimCount.aug22,
    pro7F2ClaimCount.aug22,
    pro7F3ClaimCount.aug22,
    pro7F1ClaimCount.aug22 + pro7F2ClaimCount.aug22 + pro7F3ClaimCount.aug22
]
export const pro7WithAug22PerVisit = [
    pro7F1PerVisit.aug22,
    pro7F2PerVisit.aug22,
    pro7F3PerVisit.aug22,
    provider7PerVisist[19]
]
// sep 22
export const pro7WithSep22Charges = [
    pro7F1Charge.sep22,
    pro7F2Charge.sep22,
    pro7F3Charge.sep22,
    pro7F1Charge.sep22 + pro7F2Charge.sep22 + pro7F3Charge.sep22
]
export const pro7WithSep22Pay = [
    pro7F1Payment.sep22,
    pro7F2Payment.sep22,
    pro7F3Payment.sep22,
    pro7F1Payment.sep22 + pro7F2Payment.sep22 + pro7F3Payment.sep22
]
export const pro7WithSep22Count = [
    pro7F1Collectable.sep22,
    pro7F2Collectable.sep22,
    pro7F3Collectable.sep22,
    pro7F1Collectable.sep22 + pro7F2Collectable.sep22 + pro7F3Collectable.sep22
]
export const pro7WithSep22NonCount = [
    pro7F1NonCollectable.sep22,
    pro7F2NonCollectable.sep22,
    pro7F3NonCollectable.sep22,
    pro7F1NonCollectable.sep22 + pro7F2NonCollectable.sep22 + pro7F3NonCollectable.sep22
]
export const pro7WithSep22Claim = [
    pro7F1ClaimCount.sep22,
    pro7F2ClaimCount.sep22,
    pro7F3ClaimCount.sep22,
    pro7F1ClaimCount.sep22 + pro7F2ClaimCount.sep22 + pro7F3ClaimCount.sep22
]
export const pro7WithSep22PerVisit = [
    pro7F1PerVisit.sep22,
    pro7F2PerVisit.sep22,
    pro7F3PerVisit.sep22,
    provider7PerVisist[20]
]
// oct 22
export const pro7WithOct22Charges = [
    pro7F1Charge.oct22,
    pro7F2Charge.oct22,
    pro7F3Charge.oct22,
    pro7F1Charge.oct22 + pro7F2Charge.oct22 + pro7F3Charge.oct22
]
export const pro7WithOct22Pay = [
    pro7F1Payment.oct22,
    pro7F2Payment.oct22,
    pro7F3Payment.oct22,
    pro7F1Payment.oct22 + pro7F2Payment.oct22 + pro7F3Payment.oct22
]
export const pro7WithOct22Count = [
    pro7F1Collectable.oct22,
    pro7F2Collectable.oct22,
    pro7F3Collectable.oct22,
    pro7F1Collectable.oct22 + pro7F2Collectable.oct22 + pro7F3Collectable.oct22
]
export const pro7WithOct22NonCount = [
    pro7F1NonCollectable.oct22,
    pro7F2NonCollectable.oct22,
    pro7F3NonCollectable.oct22,
    pro7F1NonCollectable.oct22 + pro7F2NonCollectable.oct22 + pro7F3NonCollectable.oct22
]
export const pro7WithOct22Claim = [
    pro7F1ClaimCount.oct22,
    pro7F2ClaimCount.oct22,
    pro7F3ClaimCount.oct22,
    pro7F1ClaimCount.oct22 + pro7F2ClaimCount.oct22 + pro7F3ClaimCount.oct22
]
export const pro7WithOct22PerVisit = [
    pro7F1PerVisit.oct22,
    pro7F2PerVisit.oct22,
    pro7F3PerVisit.oct22,
    provider7PerVisist[21]
]
// nov 22
export const pro7WithNov22Charges = [
    pro7F1Charge.nov22,
    pro7F2Charge.nov22,
    pro7F3Charge.nov22,
    pro7F1Charge.nov22 + pro7F2Charge.nov22 + pro7F3Charge.nov22
]
export const pro7WithNov22Pay = [
    pro7F1Payment.nov22,
    pro7F2Payment.nov22,
    pro7F3Payment.nov22,
    pro7F1Payment.nov22 + pro7F2Payment.nov22 + pro7F3Payment.nov22
]
export const pro7WithNov22Count = [
    pro7F1Collectable.nov22,
    pro7F2Collectable.nov22,
    pro7F3Collectable.nov22,
    pro7F1Collectable.nov22 + pro7F2Collectable.nov22 + pro7F3Collectable.nov22
]
export const pro7WithNov22NonCount = [
    pro7F1NonCollectable.nov22,
    pro7F2NonCollectable.nov22,
    pro7F3NonCollectable.nov22,
    pro7F1NonCollectable.nov22 + pro7F2NonCollectable.nov22 + pro7F3NonCollectable.nov22
]
export const pro7WithNov22Claim = [
    pro7F1ClaimCount.nov22,
    pro7F2ClaimCount.nov22,
    pro7F3ClaimCount.nov22,
    pro7F1ClaimCount.nov22 + pro7F2ClaimCount.nov22 + pro7F3ClaimCount.nov22
]
export const pro7WithNov22PerVisit = [
    pro7F1PerVisit.nov22,
    pro7F2PerVisit.nov22,
    pro7F3PerVisit.nov22,
    provider7PerVisist[22]
]
// dec 22
export const pro7WithDec22Charges = [
    pro7F1Charge.dec22,
    pro7F2Charge.dec22,
    pro7F3Charge.dec22,
    pro7F1Charge.dec22 + pro7F2Charge.dec22 + pro7F3Charge.dec22
]
export const pro7WithDec22Pay = [
    pro7F1Payment.dec22,
    pro7F2Payment.dec22,
    pro7F3Payment.dec22,
    pro7F1Payment.dec22 + pro7F2Payment.dec22 + pro7F3Payment.dec22
]
export const pro7WithDec22Count = [
    pro7F1Collectable.dec22,
    pro7F2Collectable.dec22,
    pro7F3Collectable.dec22,
    pro7F1Collectable.dec22 + pro7F2Collectable.dec22 + pro7F3Collectable.dec22
]
export const pro7WithDec22NonCount = [
    pro7F1NonCollectable.dec22,
    pro7F2NonCollectable.dec22,
    pro7F3NonCollectable.dec22,
    pro7F1NonCollectable.dec22 + pro7F2NonCollectable.dec22 + pro7F3NonCollectable.dec22
]
export const pro7WithDec22Claim = [
    pro7F1ClaimCount.dec22,
    pro7F2ClaimCount.dec22,
    pro7F3ClaimCount.dec22,
    pro7F1ClaimCount.dec22 + pro7F2ClaimCount.dec22 + pro7F3ClaimCount.dec22
]
export const pro7WithDec22PerVisit = [
    pro7F1PerVisit.dec22,
    pro7F2PerVisit.dec22,
    pro7F3PerVisit.dec22,
    provider7PerVisist[23]
]

// provider-1 
// jan 23
export const pro7WithJan23Charges = [
    pro7F1Charge.jan23,
    pro7F2Charge.jan23,
    pro7F3Charge.jan23,
    pro7F1Charge.jan23 + pro7F2Charge.jan23 + pro7F3Charge.jan23
]
export const pro7WithJan23Pay = [
    pro7F1Payment.jan23,
    pro7F2Payment.jan23,
    pro7F3Payment.jan23,
    pro7F1Payment.jan23 + pro7F2Payment.jan23 + pro7F3Payment.jan23
]
export const pro7WithJan23Count = [
    pro7F1Collectable.jan23,
    pro7F2Collectable.jan23,
    pro7F3Collectable.jan23,
    pro7F1Collectable.jan23 + pro7F2Collectable.jan23 + pro7F3Collectable.jan23
]
export const pro7WithJan23NonCount = [
    pro7F1NonCollectable.jan23,
    pro7F2NonCollectable.jan23,
    pro7F3NonCollectable.jan23,
    pro7F1NonCollectable.jan23 + pro7F2NonCollectable.jan23 + pro7F3NonCollectable.jan23
]
export const pro7WithJan23Claim = [
    pro7F1ClaimCount.jan23,
    pro7F2ClaimCount.jan23,
    pro7F3ClaimCount.jan23,
    pro7F1ClaimCount.jan23 + pro7F2ClaimCount.jan23 + pro7F3ClaimCount.jan23
]
export const pro7WithJan23PerVisit = [
    pro7F1PerVisit.jan23,
    pro7F2PerVisit.jan23,
    pro7F3PerVisit.jan23,
    provider7PerVisist[24]
]
// feb 23
export const pro7WithFeb23Charges = [
    pro7F1Charge.feb23,
    pro7F2Charge.feb23,
    pro7F3Charge.feb23,
    pro7F1Charge.feb23 + pro7F2Charge.feb23 + pro7F3Charge.feb23
]
export const pro7WithFeb23Pay = [
    pro7F1Payment.feb23,
    pro7F2Payment.feb23,
    pro7F3Payment.feb23,
    pro7F1Payment.feb23 + pro7F2Payment.feb23 + pro7F3Payment.feb23
]
export const pro7WithFeb23Count = [
    pro7F1Collectable.feb23,
    pro7F2Collectable.feb23,
    pro7F3Collectable.feb23,
    pro7F1Collectable.feb23 + pro7F2Collectable.feb23 + pro7F3Collectable.feb23
]
export const pro7WithFeb23NonCount = [
    pro7F1NonCollectable.feb23,
    pro7F2NonCollectable.feb23,
    pro7F3NonCollectable.feb23,
    pro7F1NonCollectable.feb23 + pro7F2NonCollectable.feb23 + pro7F3NonCollectable.feb23
]
export const pro7WithFeb23Claim = [
    pro7F1ClaimCount.feb23,
    pro7F2ClaimCount.feb23,
    pro7F3ClaimCount.feb23,
    pro7F1ClaimCount.feb23 + pro7F2ClaimCount.feb23 + pro7F3ClaimCount.feb23
]
export const pro7WithFeb23PerVisit = [
    pro7F1PerVisit.feb23,
    pro7F2PerVisit.feb23,
    pro7F3PerVisit.feb23,
    provider7PerVisist[25]
]
// mar 23
export const pro7WithMar23Charges = [
    pro7F1Charge.mar23,
    pro7F2Charge.mar23,
    pro7F3Charge.mar23,
    pro7F1Charge.mar23 + pro7F2Charge.mar23 + pro7F3Charge.mar23
]
export const pro7WithMar23Pay = [
    pro7F1Payment.mar23,
    pro7F2Payment.mar23,
    pro7F3Payment.mar23,
    pro7F1Payment.mar23 + pro7F2Payment.mar23 + pro7F3Payment.mar23
]
export const pro7WithMar23Count = [
    pro7F1Collectable.mar23,
    pro7F2Collectable.mar23,
    pro7F3Collectable.mar23,
    pro7F1Collectable.mar23 + pro7F2Collectable.mar23 + pro7F3Collectable.mar23
]
export const pro7WithMar23NonCount = [
    pro7F1NonCollectable.mar23,
    pro7F2NonCollectable.mar23,
    pro7F3NonCollectable.mar23,
    pro7F1NonCollectable.mar23 + pro7F2NonCollectable.mar23 + pro7F3NonCollectable.mar23
]
export const pro7WithMar23Claim = [
    pro7F1ClaimCount.mar23,
    pro7F2ClaimCount.mar23,
    pro7F3ClaimCount.mar23,
    pro7F1ClaimCount.mar23 + pro7F2ClaimCount.mar23 + pro7F3ClaimCount.mar23
]
export const pro7WithMar23PerVisit = [
    pro7F1PerVisit.mar23,
    pro7F2PerVisit.mar23,
    pro7F3PerVisit.mar23,
    provider7PerVisist[26]
]
// april 23
export const pro7WithApr23Charges = [
    pro7F1Charge.apr23,
    pro7F2Charge.apr23,
    pro7F3Charge.apr23,
    pro7F1Charge.apr23 + pro7F2Charge.apr23 + pro7F3Charge.apr23
]
export const pro7WithApr23Pay = [
    pro7F1Payment.apr23,
    pro7F2Payment.apr23,
    pro7F3Payment.apr23,
    pro7F1Payment.apr23 + pro7F2Payment.apr23 + pro7F3Payment.apr23
]
export const pro7WithApr23Count = [
    pro7F1Collectable.apr23,
    pro7F2Collectable.apr23,
    pro7F3Collectable.apr23,
    pro7F1Collectable.apr23 + pro7F2Collectable.apr23 + pro7F3Collectable.apr23
]
export const pro7WithApr23NonCount = [
    pro7F1NonCollectable.apr23,
    pro7F2NonCollectable.apr23,
    pro7F3NonCollectable.apr23,
    pro7F1NonCollectable.apr23 + pro7F2NonCollectable.apr23 + pro7F3NonCollectable.apr23
]
export const pro7WithApr23Claim = [
    pro7F1ClaimCount.apr23,
    pro7F2ClaimCount.apr23,
    pro7F3ClaimCount.apr23,
    pro7F1ClaimCount.apr23 + pro7F2ClaimCount.apr23 + pro7F3ClaimCount.apr23
]
export const pro7WithApr23PerVisit = [
    pro7F1PerVisit.apr23,
    pro7F2PerVisit.apr23,
    pro7F3PerVisit.apr23,
    provider7PerVisist[27]
]
// may 23
export const pro7WithMay23Charges = [
    pro7F1Charge.may23,
    pro7F2Charge.may23,
    pro7F3Charge.may23,
    pro7F1Charge.may23 + pro7F2Charge.may23 + pro7F3Charge.may23
]
export const pro7WithMay23Pay = [
    pro7F1Payment.may23,
    pro7F2Payment.may23,
    pro7F3Payment.may23,
    pro7F1Payment.may23 + pro7F2Payment.may23 + pro7F3Payment.may23
]
export const pro7WithMay23Count = [
    pro7F1Collectable.may23,
    pro7F2Collectable.may23,
    pro7F3Collectable.may23,
    pro7F1Collectable.may23 + pro7F2Collectable.may23 + pro7F3Collectable.may23
]
export const pro7WithMay23NonCount = [
    pro7F1NonCollectable.may23,
    pro7F2NonCollectable.may23,
    pro7F3NonCollectable.may23,
    pro7F1NonCollectable.may23 + pro7F2NonCollectable.may23 + pro7F3NonCollectable.may23
]
export const pro7WithMay23Claim = [
    pro7F1ClaimCount.may23,
    pro7F2ClaimCount.may23,
    pro7F3ClaimCount.may23,
    pro7F1ClaimCount.may23 + pro7F2ClaimCount.may23 + pro7F3ClaimCount.may23
]
export const pro7WithMay23PerVisit = [
    pro7F1PerVisit.may23,
    pro7F2PerVisit.may23,
    pro7F3PerVisit.may23,
    provider7PerVisist[28]
]
// jun 23
export const pro7WithJun23Charges = [
    pro7F1Charge.jun23,
    pro7F2Charge.jun23,
    pro7F3Charge.jun23,
    pro7F1Charge.jun23 + pro7F2Charge.jun23 + pro7F3Charge.jun23
]
export const pro7WithJun23Pay = [
    pro7F1Payment.jun23,
    pro7F2Payment.jun23,
    pro7F3Payment.jun23,
    pro7F1Payment.jun23 + pro7F2Payment.jun23 + pro7F3Payment.jun23
]
export const pro7WithJun23Count = [
    pro7F1Collectable.jun23,
    pro7F2Collectable.jun23,
    pro7F3Collectable.jun23,
    pro7F1Collectable.jun23 + pro7F2Collectable.jun23 + pro7F3Collectable.jun23
]
export const pro7WithJun23NonCount = [
    pro7F1NonCollectable.jun23,
    pro7F2NonCollectable.jun23,
    pro7F3NonCollectable.jun23,
    pro7F1NonCollectable.jun23 + pro7F2NonCollectable.jun23 + pro7F3NonCollectable.jun23
]
export const pro7WithJun23Claim = [
    pro7F1ClaimCount.jun23,
    pro7F2ClaimCount.jun23,
    pro7F3ClaimCount.jun23,
    pro7F1ClaimCount.jun23 + pro7F2ClaimCount.jun23 + pro7F3ClaimCount.jun23
]
export const pro7WithJun23PerVisit = [
    pro7F1PerVisit.jun23,
    pro7F2PerVisit.jun23,
    pro7F3PerVisit.jun23,
    provider7PerVisist[29]
]
// jul 23
export const pro7WithJul23Charges = [
    pro7F1Charge.jul23,
    pro7F2Charge.jul23,
    pro7F3Charge.jul23,
    pro7F1Charge.jul23 + pro7F2Charge.jul23 + pro7F3Charge.jul23
]
export const pro7WithJul23Pay = [
    pro7F1Payment.jul23,
    pro7F2Payment.jul23,
    pro7F3Payment.jul23,
    pro7F1Payment.jul23 + pro7F2Payment.jul23 + pro7F3Payment.jul23
]
export const pro7WithJul23Count = [
    pro7F1Collectable.jul23,
    pro7F2Collectable.jul23,
    pro7F3Collectable.jul23,
    pro7F1Collectable.jul23 + pro7F2Collectable.jul23 + pro7F3Collectable.jul23
]
export const pro7WithJul23NonCount = [
    pro7F1NonCollectable.jul23,
    pro7F2NonCollectable.jul23,
    pro7F3NonCollectable.jul23,
    pro7F1NonCollectable.jul23 + pro7F2NonCollectable.jul23 + pro7F3NonCollectable.jul23
]
export const pro7WithJul23Claim = [
    pro7F1ClaimCount.jul23,
    pro7F2ClaimCount.jul23,
    pro7F3ClaimCount.jul23,
    pro7F1ClaimCount.jul23 + pro7F2ClaimCount.jul23 + pro7F3ClaimCount.jul23
]
export const pro7WithJul23PerVisit = [
    pro7F1PerVisit.jul23,
    pro7F2PerVisit.jul23,
    pro7F3PerVisit.jul23,
    provider7PerVisist[30]
]
// aug 23
export const pro7WithAug23Charges = [
    pro7F1Charge.aug23,
    pro7F2Charge.aug23,
    pro7F3Charge.aug23,
    pro7F1Charge.aug23 + pro7F2Charge.aug23 + pro7F3Charge.aug23
]
export const pro7WithAug23Pay = [
    pro7F1Payment.aug23,
    pro7F2Payment.aug23,
    pro7F3Payment.aug23,
    pro7F1Payment.aug23 + pro7F2Payment.aug23 + pro7F3Payment.aug23
]
export const pro7WithAug23Count = [
    pro7F1Collectable.aug23,
    pro7F2Collectable.aug23,
    pro7F3Collectable.aug23,
    pro7F1Collectable.aug23 + pro7F2Collectable.aug23 + pro7F3Collectable.aug23
]
export const pro7WithAug23NonCount = [
    pro7F1NonCollectable.aug23,
    pro7F2NonCollectable.aug23,
    pro7F3NonCollectable.aug23,
    pro7F1NonCollectable.aug23 + pro7F2NonCollectable.aug23 + pro7F3NonCollectable.aug23
]
export const pro7WithAug23Claim = [
    pro7F1ClaimCount.aug23,
    pro7F2ClaimCount.aug23,
    pro7F3ClaimCount.aug23,
    pro7F1ClaimCount.aug23 + pro7F2ClaimCount.aug23 + pro7F3ClaimCount.aug23
]
export const pro7WithAug23PerVisit = [
    pro7F1PerVisit.aug23,
    pro7F2PerVisit.aug23,
    pro7F3PerVisit.aug23,
    provider7PerVisist[31]
]
// sep 23
export const pro7WithSep23Charges = [
    pro7F1Charge.sep23,
    pro7F2Charge.sep23,
    pro7F3Charge.sep23,
    pro7F1Charge.sep23 + pro7F2Charge.sep23 + pro7F3Charge.sep23
]
export const pro7WithSep23Pay = [
    pro7F1Payment.sep23,
    pro7F2Payment.sep23,
    pro7F3Payment.sep23,
    pro7F1Payment.sep23 + pro7F2Payment.sep23 + pro7F3Payment.sep23
]
export const pro7WithSep23Count = [
    pro7F1Collectable.sep23,
    pro7F2Collectable.sep23,
    pro7F3Collectable.sep23,
    pro7F1Collectable.sep23 + pro7F2Collectable.sep23 + pro7F3Collectable.sep23
]
export const pro7WithSep23NonCount = [
    pro7F1NonCollectable.sep23,
    pro7F2NonCollectable.sep23,
    pro7F3NonCollectable.sep23,
    pro7F1NonCollectable.sep23 + pro7F2NonCollectable.sep23 + pro7F3NonCollectable.sep23
]
export const pro7WithSep23Claim = [
    pro7F1ClaimCount.sep23,
    pro7F2ClaimCount.sep23,
    pro7F3ClaimCount.sep23,
    pro7F1ClaimCount.sep23 + pro7F2ClaimCount.sep23 + pro7F3ClaimCount.sep23
]
export const pro7WithSep23PerVisit = [
    pro7F1PerVisit.sep23,
    pro7F2PerVisit.sep23,
    pro7F3PerVisit.sep23,
    provider7PerVisist[32]
]
// oct 23
export const pro7WithOct23Charges = [
    pro7F1Charge.oct23,
    pro7F2Charge.oct23,
    pro7F3Charge.oct23,
    pro7F1Charge.oct23 + pro7F2Charge.oct23 + pro7F3Charge.oct23
]
export const pro7WithOct23Pay = [
    pro7F1Payment.oct23,
    pro7F2Payment.oct23,
    pro7F3Payment.oct23,
    pro7F1Payment.oct23 + pro7F2Payment.oct23 + pro7F3Payment.oct23
]
export const pro7WithOct23Count = [
    pro7F1Collectable.oct23,
    pro7F2Collectable.oct23,
    pro7F3Collectable.oct23,
    pro7F1Collectable.oct23 + pro7F2Collectable.oct23 + pro7F3Collectable.oct23
]
export const pro7WithOct23NonCount = [
    pro7F1NonCollectable.oct23,
    pro7F2NonCollectable.oct23,
    pro7F3NonCollectable.oct23,
    pro7F1NonCollectable.oct23 + pro7F2NonCollectable.oct23 + pro7F3NonCollectable.oct23
]
export const pro7WithOct23Claim = [
    pro7F1ClaimCount.oct23,
    pro7F2ClaimCount.oct23,
    pro7F3ClaimCount.oct23,
    pro7F1ClaimCount.oct23 + pro7F2ClaimCount.oct23 + pro7F3ClaimCount.oct23
]
export const pro7WithOct23PerVisit = [
    pro7F1PerVisit.oct23,
    pro7F2PerVisit.oct23,
    pro7F3PerVisit.oct23,
    provider7PerVisist[33]
]
// nov 23
export const pro7WithNov23Charges = [
    pro7F1Charge.nov23,
    pro7F2Charge.nov23,
    pro7F3Charge.nov23,
    pro7F1Charge.nov23 + pro7F2Charge.nov23 + pro7F3Charge.nov23
]
export const pro7WithNov23Pay = [
    pro7F1Payment.nov23,
    pro7F2Payment.nov23,
    pro7F3Payment.nov23,
    pro7F1Payment.nov23 +
    pro7F2Payment.nov23 + 
    pro7F3Payment.nov23
]
export const pro7WithNov23Count = [
    pro7F1Collectable.nov23,
    pro7F2Collectable.nov23,
    pro7F3Collectable.nov23,
    pro7F1Collectable.nov23 +
    pro7F2Collectable.nov23 +
    pro7F3Collectable.nov23
]
export const pro7WithNov23NonCount = [
    pro7F1NonCollectable.nov23,
    pro7F2NonCollectable.nov23,
    pro7F3NonCollectable.nov23,
    pro7F1NonCollectable.nov23+
    pro7F2NonCollectable.nov23+
    pro7F3NonCollectable.nov23
]
export const pro7WithNov23Claim = [
    pro7F1ClaimCount.nov23,
    pro7F2ClaimCount.nov23,
    pro7F3ClaimCount.nov23,
    pro7F1ClaimCount.nov23 + pro7F2ClaimCount.nov23 + pro7F3ClaimCount.nov23
]
export const pro7WithNov23PerVisit = [
    pro7F1PerVisit.nov23,
    pro7F2PerVisit.nov23,
    pro7F3PerVisit.nov23,
    provider7PerVisist[34]
]
// comm 

// export const pro7WithJan21Charges = [
//     pro7F1Charge.jan21,
//     pro7F2Charge.jan21,
//     pro7F3Charge.jan21
// ]
// export const pro7WithJan21Pay = [
//     pro7F1Payment.jan21,
//     pro7F2Payment.jan21,
//     pro7F3Payment.jan21
// ]
// export const pro7WithJan21Count = [
//     pro7F1Collectable.jan21,
//     pro7F2Collectable.jan21,
//     pro7F3Collectable.jan21
// ]
// export const pro7WithJan21NonCount = [
//     pro7F1NonCollectable.jan21,
//     pro7F2NonCollectable.jan21,
//     pro7F3NonCollectable.jan21
// ]
// export const pro7WithJan21Claim = [
//     pro7F1ClaimCount.jan21,
//     pro7F2ClaimCount.jan21,
//     pro7F3ClaimCount.jan21
// ]
// export const pro7WithJan21PerVisit = [
//     pro7F1PerVisit.jan21,
//     pro7F2PerVisit.jan21,
//     pro7F3PerVisit.jan21
// ]
// // feb 21
// export const pro7WithFeb21Charges = [
//     pro7F1Charge.feb21,
//     pro7F2Charge.feb21,
//     pro7F3Charge.feb21
// ]
// export const pro7WithFeb21Pay = [
//     pro7F1Payment.feb21,
//     pro7F2Payment.feb21,
//     pro7F3Payment.feb21
// ]
// export const pro7WithFeb21Count = [
//     pro7F1Collectable.feb21,
//     pro7F2Collectable.feb21,
//     pro7F3Collectable.feb21
// ]
// export const pro7WithFeb21NonCount = [
//     pro7F1NonCollectable.feb21,
//     pro7F2NonCollectable.feb21,
//     pro7F3NonCollectable.feb21
// ]
// export const pro7WithFeb21Claim = [
//     pro7F1ClaimCount.feb21,
//     pro7F2ClaimCount.feb21,
//     pro7F3ClaimCount.feb21
// ]
// export const pro7WithFeb21PerVisit = [
//     pro7F1PerVisit.feb21,
//     pro7F2PerVisit.feb21,
//     pro7F3PerVisit.feb21
// ]
// // mar 21
// export const pro7WithMar21Charges = [
//     pro7F1Charge.mar21,
//     pro7F2Charge.mar21,
//     pro7F3Charge.mar21
// ]
// export const pro7WithMar21Pay = [
//     pro7F1Payment.mar21,
//     pro7F2Payment.mar21,
//     pro7F3Payment.mar21
// ]
// export const pro7WithMar21Count = [
//     pro7F1Collectable.mar21,
//     pro7F2Collectable.mar21,
//     pro7F3Collectable.mar21
// ]
// export const pro7WithMar21NonCount = [
//     pro7F1NonCollectable.mar21,
//     pro7F2NonCollectable.mar21,
//     pro7F3NonCollectable.mar21
// ]
// export const pro7WithMar21Claim = [
//     pro7F1ClaimCount.mar21,
//     pro7F2ClaimCount.mar21,
//     pro7F3ClaimCount.mar21
// ]
// export const pro7WithMar21PerVisit = [
//     pro7F1PerVisit.mar21,
//     pro7F2PerVisit.mar21,
//     pro7F3PerVisit.mar21
// ]
// // april 21
// export const pro7WithApr21Charges = [
//     pro7F1Charge.apr21,
//     pro7F2Charge.apr21,
//     pro7F3Charge.apr21
// ]
// export const pro7WithApr21Pay = [
//     pro7F1Payment.apr21,
//     pro7F2Payment.apr21,
//     pro7F3Payment.apr21
// ]
// export const pro7WithApr21Count = [
//     pro7F1Collectable.apr21,
//     pro7F2Collectable.apr21,
//     pro7F3Collectable.apr21
// ]
// export const pro7WithApr21NonCount = [
//     pro7F1NonCollectable.apr21,
//     pro7F2NonCollectable.apr21,
//     pro7F3NonCollectable.apr21
// ]
// export const pro7WithApr21Claim = [
//     pro7F1ClaimCount.apr21,
//     pro7F2ClaimCount.apr21,
//     pro7F3ClaimCount.apr21
// ]
// export const pro7WithApr21PerVisit = [
//     pro7F1PerVisit.apr21,
//     pro7F2PerVisit.apr21,
//     pro7F3PerVisit.apr21
// ]
// // may 21
// export const pro7WithMay21Charges = [
//     pro7F1Charge.may21,
//     pro7F2Charge.may21,
//     pro7F3Charge.may21
// ]
// export const pro7WithMay21Pay = [
//     pro7F1Payment.may21,
//     pro7F2Payment.may21,
//     pro7F3Payment.may21
// ]
// export const pro7WithMay21Count = [
//     pro7F1Collectable.may21,
//     pro7F2Collectable.may21,
//     pro7F3Collectable.may21
// ]
// export const pro7WithMay21NonCount = [
//     pro7F1NonCollectable.may21,
//     pro7F2NonCollectable.may21,
//     pro7F3NonCollectable.may21
// ]
// export const pro7WithMay21Claim = [
//     pro7F1ClaimCount.may21,
//     pro7F2ClaimCount.may21,
//     pro7F3ClaimCount.may21
// ]
// export const pro7WithMay21PerVisit = [
//     pro7F1PerVisit.may21,
//     pro7F2PerVisit.may21,
//     pro7F3PerVisit.may21
// ]
// // jun 21
// export const pro7WithJun21Charges = [
//     pro7F1Charge.jun21,
//     pro7F2Charge.jun21,
//     pro7F3Charge.jun21
// ]
// export const pro7WithJun21Pay = [
//     pro7F1Payment.jun21,
//     pro7F2Payment.jun21,
//     pro7F3Payment.jun21
// ]
// export const pro7WithJun21Count = [
//     pro7F1Collectable.jun21,
//     pro7F2Collectable.jun21,
//     pro7F3Collectable.jun21
// ]
// export const pro7WithJun21NonCount = [
//     pro7F1NonCollectable.jun21,
//     pro7F2NonCollectable.jun21,
//     pro7F3NonCollectable.jun21
// ]
// export const pro7WithJun21Claim = [
//     pro7F1ClaimCount.jun21,
//     pro7F2ClaimCount.jun21,
//     pro7F3ClaimCount.jun21
// ]
// export const pro7WithJun21PerVisit = [
//     pro7F1PerVisit.jun21,
//     pro7F2PerVisit.jun21,
//     pro7F3PerVisit.jun21
// ]
// // jul 21
// export const pro7WithJul21Charges = [
//     pro7F1Charge.jul21,
//     pro7F2Charge.jul21,
//     pro7F3Charge.jul21
// ]
// export const pro7WithJul21Pay = [
//     pro7F1Payment.jul21,
//     pro7F2Payment.jul21,
//     pro7F3Payment.jul21
// ]
// export const pro7WithJul21Count = [
//     pro7F1Collectable.jul21,
//     pro7F2Collectable.jul21,
//     pro7F3Collectable.jul21
// ]
// export const pro7WithJul21NonCount = [
//     pro7F1NonCollectable.jul21,
//     pro7F2NonCollectable.jul21,
//     pro7F3NonCollectable.jul21
// ]
// export const pro7WithJul21Claim = [
//     pro7F1ClaimCount.jul21,
//     pro7F2ClaimCount.jul21,
//     pro7F3ClaimCount.jul21
// ]
// export const pro7WithJul21PerVisit = [
//     pro7F1PerVisit.jul21,
//     pro7F2PerVisit.jul21,
//     pro7F3PerVisit.jul21
// ]
// // aug 21
// export const pro7WithAug21Charges = [
//     pro7F1Charge.aug21,
//     pro7F2Charge.aug21,
//     pro7F3Charge.aug21
// ]
// export const pro7WithAug21Pay = [
//     pro7F1Payment.aug21,
//     pro7F2Payment.aug21,
//     pro7F3Payment.aug21
// ]
// export const pro7WithAug21Count = [
//     pro7F1Collectable.aug21,
//     pro7F2Collectable.aug21,
//     pro7F3Collectable.aug21
// ]
// export const pro7WithAug21NonCount = [
//     pro7F1NonCollectable.aug21,
//     pro7F2NonCollectable.aug21,
//     pro7F3NonCollectable.aug21
// ]
// export const pro7WithAug21Claim = [
//     pro7F1ClaimCount.aug21,
//     pro7F2ClaimCount.aug21,
//     pro7F3ClaimCount.aug21
// ]
// export const pro7WithAug21PerVisit = [
//     pro7F1PerVisit.aug21,
//     pro7F2PerVisit.aug21,
//     pro7F3PerVisit.aug21
// ]
// // sep 21
// export const pro7WithSep21Charges = [
//     pro7F1Charge.sep21,
//     pro7F2Charge.sep21,
//     pro7F3Charge.sep21
// ]
// export const pro7WithSep21Pay = [
//     pro7F1Payment.sep21,
//     pro7F2Payment.sep21,
//     pro7F3Payment.sep21
// ]
// export const pro7WithSep21Count = [
//     pro7F1Collectable.sep21,
//     pro7F2Collectable.sep21,
//     pro7F3Collectable.sep21
// ]
// export const pro7WithSep21NonCount = [
//     pro7F1NonCollectable.sep21,
//     pro7F2NonCollectable.sep21,
//     pro7F3NonCollectable.sep21
// ]
// export const pro7WithSep21Claim = [
//     pro7F1ClaimCount.sep21,
//     pro7F2ClaimCount.sep21,
//     pro7F3ClaimCount.sep21
// ]
// export const pro7WithSep21PerVisit = [
//     pro7F1PerVisit.sep21,
//     pro7F2PerVisit.sep21,
//     pro7F3PerVisit.sep21
// ]
// // oct 21
// export const pro7WithOct21Charges = [
//     pro7F1Charge.oct21,
//     pro7F2Charge.oct21,
//     pro7F3Charge.oct21
// ]
// export const pro7WithOct21Pay = [
//     pro7F1Payment.oct21,
//     pro7F2Payment.oct21,
//     pro7F3Payment.oct21
// ]
// export const pro7WithOct21Count = [
//     pro7F1Collectable.oct21,
//     pro7F2Collectable.oct21,
//     pro7F3Collectable.oct21
// ]
// export const pro7WithOct21NonCount = [
//     pro7F1NonCollectable.oct21,
//     pro7F2NonCollectable.oct21,
//     pro7F3NonCollectable.oct21
// ]
// export const pro7WithOct21Claim = [
//     pro7F1ClaimCount.oct21,
//     pro7F2ClaimCount.oct21,
//     pro7F3ClaimCount.oct21
// ]
// export const pro7WithOct21PerVisit = [
//     pro7F1PerVisit.oct21,
//     pro7F2PerVisit.oct21,
//     pro7F3PerVisit.oct21
// ]
// // nov 21
// export const pro7WithNov21Charges = [
//     pro7F1Charge.nov21,
//     pro7F2Charge.nov21,
//     pro7F3Charge.nov21
// ]
// export const pro7WithNov21Pay = [
//     pro7F1Payment.nov21,
//     pro7F2Payment.nov21,
//     pro7F3Payment.nov21
// ]
// export const pro7WithNov21Count = [
//     pro7F1Collectable.nov21,
//     pro7F2Collectable.nov21,
//     pro7F3Collectable.nov21
// ]
// export const pro7WithNov21NonCount = [
//     pro7F1NonCollectable.nov21,
//     pro7F2NonCollectable.nov21,
//     pro7F3NonCollectable.nov21
// ]
// export const pro7WithNov21Claim = [
//     pro7F1ClaimCount.nov21,
//     pro7F2ClaimCount.nov21,
//     pro7F3ClaimCount.nov21
// ]
// export const pro7WithNov21PerVisit = [
//     pro7F1PerVisit.nov21,
//     pro7F2PerVisit.nov21,
//     pro7F3PerVisit.nov21
// ]
// // dec 21
// export const pro7WithDec21Charges = [
//     pro7F1Charge.dec21,
//     pro7F2Charge.dec21,
//     pro7F3Charge.dec21
// ]
// export const pro7WithDec21Pay = [
//     pro7F1Payment.dec21,
//     pro7F2Payment.dec21,
//     pro7F3Payment.dec21
// ]
// export const pro7WithDec21Count = [
//     pro7F1Collectable.dec21,
//     pro7F2Collectable.dec21,
//     pro7F3Collectable.dec21
// ]
// export const pro7WithDec21NonCount = [
//     pro7F1NonCollectable.dec21,
//     pro7F2NonCollectable.dec21,
//     pro7F3NonCollectable.dec21
// ]
// export const pro7WithDec21Claim = [
//     pro7F1ClaimCount.dec21,
//     pro7F2ClaimCount.dec21,
//     pro7F3ClaimCount.dec21
// ]
// export const pro7WithDec21PerVisit = [
//     pro7F1PerVisit.dec21,
//     pro7F2PerVisit.dec21,
//     pro7F3PerVisit.dec21
// ]

// // provider-6
// // jan 22
// export const pro7WithJan22Charges = [
//     pro7F1Charge.jan22,
//     pro7F2Charge.jan22,
//     pro7F3Charge.jan22
// ]
// export const pro7WithJan22Pay = [
//     pro7F1Payment.jan22,
//     pro7F2Payment.jan22,
//     pro7F3Payment.jan22
// ]
// export const pro7WithJan22Count = [
//     pro7F1Collectable.jan22,
//     pro7F2Collectable.jan22,
//     pro7F3Collectable.jan22
// ]
// export const pro7WithJan22NonCount = [
//     pro7F1NonCollectable.jan22,
//     pro7F2NonCollectable.jan22,
//     pro7F3NonCollectable.jan22
// ]
// export const pro7WithJan22Claim = [
//     pro7F1ClaimCount.jan22,
//     pro7F2ClaimCount.jan22,
//     pro7F3ClaimCount.jan22
// ]
// export const pro7WithJan22PerVisit = [
//     pro7F1PerVisit.jan22,
//     pro7F2PerVisit.jan22,
//     pro7F3PerVisit.jan22
// ]
// // feb 22
// export const pro7WithFeb22Charges = [
//     pro7F1Charge.feb22,
//     pro7F2Charge.feb22,
//     pro7F3Charge.feb22
// ]
// export const pro7WithFeb22Pay = [
//     pro7F1Payment.feb22,
//     pro7F2Payment.feb22,
//     pro7F3Payment.feb22
// ]
// export const pro7WithFeb22Count = [
//     pro7F1Collectable.feb22,
//     pro7F2Collectable.feb22,
//     pro7F3Collectable.feb22
// ]
// export const pro7WithFeb22NonCount = [
//     pro7F1NonCollectable.feb22,
//     pro7F2NonCollectable.feb22,
//     pro7F3NonCollectable.feb22
// ]
// export const pro7WithFeb22Claim = [
//     pro7F1ClaimCount.feb22,
//     pro7F2ClaimCount.feb22,
//     pro7F3ClaimCount.feb22
// ]
// export const pro7WithFeb22PerVisit = [
//     pro7F1PerVisit.feb22,
//     pro7F2PerVisit.feb22,
//     pro7F3PerVisit.feb22
// ]
// // mar 22
// export const pro7WithMar22Charges = [
//     pro7F1Charge.mar22,
//     pro7F2Charge.mar22,
//     pro7F3Charge.mar22
// ]
// export const pro7WithMar22Pay = [
//     pro7F1Payment.mar22,
//     pro7F2Payment.mar22,
//     pro7F3Payment.mar22
// ]
// export const pro7WithMar22Count = [
//     pro7F1Collectable.mar22,
//     pro7F2Collectable.mar22,
//     pro7F3Collectable.mar22
// ]
// export const pro7WithMar22NonCount = [
//     pro7F1NonCollectable.mar22,
//     pro7F2NonCollectable.mar22,
//     pro7F3NonCollectable.mar22
// ]
// export const pro7WithMar22Claim = [
//     pro7F1ClaimCount.mar22,
//     pro7F2ClaimCount.mar22,
//     pro7F3ClaimCount.mar22
// ]
// export const pro7WithMar22PerVisit = [
//     pro7F1PerVisit.mar22,
//     pro7F2PerVisit.mar22,
//     pro7F3PerVisit.mar22
// ]
// // april 22
// export const pro7WithApr22Charges = [
//     pro7F1Charge.apr22,
//     pro7F2Charge.apr22,
//     pro7F3Charge.apr22
// ]
// export const pro7WithApr22Pay = [
//     pro7F1Payment.apr22,
//     pro7F2Payment.apr22,
//     pro7F3Payment.apr22
// ]
// export const pro7WithApr22Count = [
//     pro7F1Collectable.apr22,
//     pro7F2Collectable.apr22,
//     pro7F3Collectable.apr22
// ]
// export const pro7WithApr22NonCount = [
//     pro7F1NonCollectable.apr22,
//     pro7F2NonCollectable.apr22,
//     pro7F3NonCollectable.apr22
// ]
// export const pro7WithApr22Claim = [
//     pro7F1ClaimCount.apr22,
//     pro7F2ClaimCount.apr22,
//     pro7F3ClaimCount.apr22
// ]
// export const pro7WithApr22PerVisit = [
//     pro7F1PerVisit.apr22,
//     pro7F2PerVisit.apr22,
//     pro7F3PerVisit.apr22
// ]
// // may 22
// export const pro7WithMay22Charges = [
//     pro7F1Charge.may22,
//     pro7F2Charge.may22,
//     pro7F3Charge.may22
// ]
// export const pro7WithMay22Pay = [
//     pro7F1Payment.may22,
//     pro7F2Payment.may22,
//     pro7F3Payment.may22
// ]
// export const pro7WithMay22Count = [
//     pro7F1Collectable.may22,
//     pro7F2Collectable.may22,
//     pro7F3Collectable.may22
// ]
// export const pro7WithMay22NonCount = [
//     pro7F1NonCollectable.may22,
//     pro7F2NonCollectable.may22,
//     pro7F3NonCollectable.may22
// ]
// export const pro7WithMay22Claim = [
//     pro7F1ClaimCount.may22,
//     pro7F2ClaimCount.may22,
//     pro7F3ClaimCount.may22
// ]
// export const pro7WithMay22PerVisit = [
//     pro7F1PerVisit.may22,
//     pro7F2PerVisit.may22,
//     pro7F3PerVisit.may22
// ]
// // jun 22
// export const pro7WithJun22Charges = [
//     pro7F1Charge.jun22,
//     pro7F2Charge.jun22,
//     pro7F3Charge.jun22
// ]
// export const pro7WithJun22Pay = [
//     pro7F1Payment.jun22,
//     pro7F2Payment.jun22,
//     pro7F3Payment.jun22
// ]
// export const pro7WithJun22Count = [
//     pro7F1Collectable.jun22,
//     pro7F2Collectable.jun22,
//     pro7F3Collectable.jun22
// ]
// export const pro7WithJun22NonCount = [
//     pro7F1NonCollectable.jun22,
//     pro7F2NonCollectable.jun22,
//     pro7F3NonCollectable.jun22
// ]
// export const pro7WithJun22Claim = [
//     pro7F1ClaimCount.jun22,
//     pro7F2ClaimCount.jun22,
//     pro7F3ClaimCount.jun22
// ]
// export const pro7WithJun22PerVisit = [
//     pro7F1PerVisit.jun22,
//     pro7F2PerVisit.jun22,
//     pro7F3PerVisit.jun22
// ]
// // jul 22
// export const pro7WithJul22Charges = [
//     pro7F1Charge.jul22,
//     pro7F2Charge.jul22,
//     pro7F3Charge.jul22
// ]
// export const pro7WithJul22Pay = [
//     pro7F1Payment.jul22,
//     pro7F2Payment.jul22,
//     pro7F3Payment.jul22
// ]
// export const pro7WithJul22Count = [
//     pro7F1Collectable.jul22,
//     pro7F2Collectable.jul22,
//     pro7F3Collectable.jul22
// ]
// export const pro7WithJul22NonCount = [
//     pro7F1NonCollectable.jul22,
//     pro7F2NonCollectable.jul22,
//     pro7F3NonCollectable.jul22
// ]
// export const pro7WithJul22Claim = [
//     pro7F1ClaimCount.jul22,
//     pro7F2ClaimCount.jul22,
//     pro7F3ClaimCount.jul22
// ]
// export const pro7WithJul22PerVisit = [
//     pro7F1PerVisit.jul22,
//     pro7F2PerVisit.jul22,
//     pro7F3PerVisit.jul22
// ]
// // aug 22
// export const pro7WithAug22Charges = [
//     pro7F1Charge.aug22,
//     pro7F2Charge.aug22,
//     pro7F3Charge.aug22
// ]
// export const pro7WithAug22Pay = [
//     pro7F1Payment.aug22,
//     pro7F2Payment.aug22,
//     pro7F3Payment.aug22
// ]
// export const pro7WithAug22Count = [
//     pro7F1Collectable.aug22,
//     pro7F2Collectable.aug22,
//     pro7F3Collectable.aug22
// ]
// export const pro7WithAug22NonCount = [
//     pro7F1NonCollectable.aug22,
//     pro7F2NonCollectable.aug22,
//     pro7F3NonCollectable.aug22
// ]
// export const pro7WithAug22Claim = [
//     pro7F1ClaimCount.aug22,
//     pro7F2ClaimCount.aug22,
//     pro7F3ClaimCount.aug22
// ]
// export const pro7WithAug22PerVisit = [
//     pro7F1PerVisit.aug22,
//     pro7F2PerVisit.aug22,
//     pro7F3PerVisit.aug22
// ]
// // sep 22
// export const pro7WithSep22Charges = [
//     pro7F1Charge.sep22,
//     pro7F2Charge.sep22,
//     pro7F3Charge.sep22
// ]
// export const pro7WithSep22Pay = [
//     pro7F1Payment.sep22,
//     pro7F2Payment.sep22,
//     pro7F3Payment.sep22
// ]
// export const pro7WithSep22Count = [
//     pro7F1Collectable.sep22,
//     pro7F2Collectable.sep22,
//     pro7F3Collectable.sep22
// ]
// export const pro7WithSep22NonCount = [
//     pro7F1NonCollectable.sep22,
//     pro7F2NonCollectable.sep22,
//     pro7F3NonCollectable.sep22
// ]
// export const pro7WithSep22Claim = [
//     pro7F1ClaimCount.sep22,
//     pro7F2ClaimCount.sep22,
//     pro7F3ClaimCount.sep22
// ]
// export const pro7WithSep22PerVisit = [
//     pro7F1PerVisit.sep22,
//     pro7F2PerVisit.sep22,
//     pro7F3PerVisit.sep22
// ]
// // oct 22
// export const pro7WithOct22Charges = [
//     pro7F1Charge.oct22,
//     pro7F2Charge.oct22,
//     pro7F3Charge.oct22
// ]
// export const pro7WithOct22Pay = [
//     pro7F1Payment.oct22,
//     pro7F2Payment.oct22,
//     pro7F3Payment.oct22
// ]
// export const pro7WithOct22Count = [
//     pro7F1Collectable.oct22,
//     pro7F2Collectable.oct22,
//     pro7F3Collectable.oct22
// ]
// export const pro7WithOct22NonCount = [
//     pro7F1NonCollectable.oct22,
//     pro7F2NonCollectable.oct22,
//     pro7F3NonCollectable.oct22
// ]
// export const pro7WithOct22Claim = [
//     pro7F1ClaimCount.oct22,
//     pro7F2ClaimCount.oct22,
//     pro7F3ClaimCount.oct22
// ]
// export const pro7WithOct22PerVisit = [
//     pro7F1PerVisit.oct22,
//     pro7F2PerVisit.oct22,
//     pro7F3PerVisit.oct22
// ]
// // nov 22
// export const pro7WithNov22Charges = [
//     pro7F1Charge.nov22,
//     pro7F2Charge.nov22,
//     pro7F3Charge.nov22
// ]
// export const pro7WithNov22Pay = [
//     pro7F1Payment.nov22,
//     pro7F2Payment.nov22,
//     pro7F3Payment.nov22
// ]
// export const pro7WithNov22Count = [
//     pro7F1Collectable.nov22,
//     pro7F2Collectable.nov22,
//     pro7F3Collectable.nov22
// ]
// export const pro7WithNov22NonCount = [
//     pro7F1NonCollectable.nov22,
//     pro7F2NonCollectable.nov22,
//     pro7F3NonCollectable.nov22
// ]
// export const pro7WithNov22Claim = [
//     pro7F1ClaimCount.nov22,
//     pro7F2ClaimCount.nov22,
//     pro7F3ClaimCount.nov22
// ]
// export const pro7WithNov22PerVisit = [
//     pro7F1PerVisit.nov22,
//     pro7F2PerVisit.nov22,
//     pro7F3PerVisit.nov22
// ]
// // dec 22
// export const pro7WithDec22Charges = [
//     pro7F1Charge.dec22,
//     pro7F2Charge.dec22,
//     pro7F3Charge.dec22
// ]
// export const pro7WithDec22Pay = [
//     pro7F1Payment.dec22,
//     pro7F2Payment.dec22,
//     pro7F3Payment.dec22
// ]
// export const pro7WithDec22Count = [
//     pro7F1Collectable.dec22,
//     pro7F2Collectable.dec22,
//     pro7F3Collectable.dec22
// ]
// export const pro7WithDec22NonCount = [
//     pro7F1NonCollectable.dec22,
//     pro7F2NonCollectable.dec22,
//     pro7F3NonCollectable.dec22
// ]
// export const pro7WithDec22Claim = [
//     pro7F1ClaimCount.dec22,
//     pro7F2ClaimCount.dec22,
//     pro7F3ClaimCount.dec22
// ]
// export const pro7WithDec22PerVisit = [
//     pro7F1PerVisit.dec22,
//     pro7F2PerVisit.dec22,
//     pro7F3PerVisit.dec22
// ]

// // provider-6
// // jan 23
// export const pro7WithJan23Charges = [
//     pro7F1Charge.jan23,
//     pro7F2Charge.jan23,
//     pro7F3Charge.jan23
// ]
// export const pro7WithJan23Pay = [
//     pro7F1Payment.jan23,
//     pro7F2Payment.jan23,
//     pro7F3Payment.jan23
// ]
// export const pro7WithJan23Count = [
//     pro7F1Collectable.jan23,
//     pro7F2Collectable.jan23,
//     pro7F3Collectable.jan23
// ]
// export const pro7WithJan23NonCount = [
//     pro7F1NonCollectable.jan23,
//     pro7F2NonCollectable.jan23,
//     pro7F3NonCollectable.jan23
// ]
// export const pro7WithJan23Claim = [
//     pro7F1ClaimCount.jan23,
//     pro7F2ClaimCount.jan23,
//     pro7F3ClaimCount.jan23
// ]
// export const pro7WithJan23PerVisit = [
//     pro7F1PerVisit.jan23,
//     pro7F2PerVisit.jan23,
//     pro7F3PerVisit.jan23
// ]
// // feb 23
// export const pro7WithFeb23Charges = [
//     pro7F1Charge.feb23,
//     pro7F2Charge.feb23,
//     pro7F3Charge.feb23
// ]
// export const pro7WithFeb23Pay = [
//     pro7F1Payment.feb23,
//     pro7F2Payment.feb23,
//     pro7F3Payment.feb23
// ]
// export const pro7WithFeb23Count = [
//     pro7F1Collectable.feb23,
//     pro7F2Collectable.feb23,
//     pro7F3Collectable.feb23
// ]
// export const pro7WithFeb23NonCount = [
//     pro7F1NonCollectable.feb23,
//     pro7F2NonCollectable.feb23,
//     pro7F3NonCollectable.feb23
// ]
// export const pro7WithFeb23Claim = [
//     pro7F1ClaimCount.feb23,
//     pro7F2ClaimCount.feb23,
//     pro7F3ClaimCount.feb23
// ]
// export const pro7WithFeb23PerVisit = [
//     pro7F1PerVisit.feb23,
//     pro7F2PerVisit.feb23,
//     pro7F3PerVisit.feb23
// ]
// // mar 23
// export const pro7WithMar23Charges = [
//     pro7F1Charge.mar23,
//     pro7F2Charge.mar23,
//     pro7F3Charge.mar23
// ]
// export const pro7WithMar23Pay = [
//     pro7F1Payment.mar23,
//     pro7F2Payment.mar23,
//     pro7F3Payment.mar23
// ]
// export const pro7WithMar23Count = [
//     pro7F1Collectable.mar23,
//     pro7F2Collectable.mar23,
//     pro7F3Collectable.mar23
// ]
// export const pro7WithMar23NonCount = [
//     pro7F1NonCollectable.mar23,
//     pro7F2NonCollectable.mar23,
//     pro7F3NonCollectable.mar23
// ]
// export const pro7WithMar23Claim = [
//     pro7F1ClaimCount.mar23,
//     pro7F2ClaimCount.mar23,
//     pro7F3ClaimCount.mar23
// ]
// export const pro7WithMar23PerVisit = [
//     pro7F1PerVisit.mar23,
//     pro7F2PerVisit.mar23,
//     pro7F3PerVisit.mar23
// ]
// // april 23
// export const pro7WithApr23Charges = [
//     pro7F1Charge.apr23,
//     pro7F2Charge.apr23,
//     pro7F3Charge.apr23
// ]
// export const pro7WithApr23Pay = [
//     pro7F1Payment.apr23,
//     pro7F2Payment.apr23,
//     pro7F3Payment.apr23
// ]
// export const pro7WithApr23Count = [
//     pro7F1Collectable.apr23,
//     pro7F2Collectable.apr23,
//     pro7F3Collectable.apr23
// ]
// export const pro7WithApr23NonCount = [
//     pro7F1NonCollectable.apr23,
//     pro7F2NonCollectable.apr23,
//     pro7F3NonCollectable.apr23
// ]
// export const pro7WithApr23Claim = [
//     pro7F1ClaimCount.apr23,
//     pro7F2ClaimCount.apr23,
//     pro7F3ClaimCount.apr23
// ]
// export const pro7WithApr23PerVisit = [
//     pro7F1PerVisit.apr23,
//     pro7F2PerVisit.apr23,
//     pro7F3PerVisit.apr23
// ]
// // may 23
// export const pro7WithMay23Charges = [
//     pro7F1Charge.may23,
//     pro7F2Charge.may23,
//     pro7F3Charge.may23
// ]
// export const pro7WithMay23Pay = [
//     pro7F1Payment.may23,
//     pro7F2Payment.may23,
//     pro7F3Payment.may23
// ]
// export const pro7WithMay23Count = [
//     pro7F1Collectable.may23,
//     pro7F2Collectable.may23,
//     pro7F3Collectable.may23
// ]
// export const pro7WithMay23NonCount = [
//     pro7F1NonCollectable.may23,
//     pro7F2NonCollectable.may23,
//     pro7F3NonCollectable.may23
// ]
// export const pro7WithMay23Claim = [
//     pro7F1ClaimCount.may23,
//     pro7F2ClaimCount.may23,
//     pro7F3ClaimCount.may23
// ]
// export const pro7WithMay23PerVisit = [
//     pro7F1PerVisit.may23,
//     pro7F2PerVisit.may23,
//     pro7F3PerVisit.may23
// ]
// // jun 23
// export const pro7WithJun23Charges = [
//     pro7F1Charge.jun23,
//     pro7F2Charge.jun23,
//     pro7F3Charge.jun23
// ]
// export const pro7WithJun23Pay = [
//     pro7F1Payment.jun23,
//     pro7F2Payment.jun23,
//     pro7F3Payment.jun23
// ]
// export const pro7WithJun23Count = [
//     pro7F1Collectable.jun23,
//     pro7F2Collectable.jun23,
//     pro7F3Collectable.jun23
// ]
// export const pro7WithJun23NonCount = [
//     pro7F1NonCollectable.jun23,
//     pro7F2NonCollectable.jun23,
//     pro7F3NonCollectable.jun23
// ]
// export const pro7WithJun23Claim = [
//     pro7F1ClaimCount.jun23,
//     pro7F2ClaimCount.jun23,
//     pro7F3ClaimCount.jun23
// ]
// export const pro7WithJun23PerVisit = [
//     pro7F1PerVisit.jun23,
//     pro7F2PerVisit.jun23,
//     pro7F3PerVisit.jun23
// ]
// // jul 23
// export const pro7WithJul23Charges = [
//     pro7F1Charge.jul23,
//     pro7F2Charge.jul23,
//     pro7F3Charge.jul23
// ]
// export const pro7WithJul23Pay = [
//     pro7F1Payment.jul23,
//     pro7F2Payment.jul23,
//     pro7F3Payment.jul23
// ]
// export const pro7WithJul23Count = [
//     pro7F1Collectable.jul23,
//     pro7F2Collectable.jul23,
//     pro7F3Collectable.jul23
// ]
// export const pro7WithJul23NonCount = [
//     pro7F1NonCollectable.jul23,
//     pro7F2NonCollectable.jul23,
//     pro7F3NonCollectable.jul23
// ]
// export const pro7WithJul23Claim = [
//     pro7F1ClaimCount.jul23,
//     pro7F2ClaimCount.jul23,
//     pro7F3ClaimCount.jul23
// ]
// export const pro7WithJul23PerVisit = [
//     pro7F1PerVisit.jul23,
//     pro7F2PerVisit.jul23,
//     pro7F3PerVisit.jul23
// ]
// // aug 23
// export const pro7WithAug23Charges = [
//     pro7F1Charge.aug23,
//     pro7F2Charge.aug23,
//     pro7F3Charge.aug23
// ]
// export const pro7WithAug23Pay = [
//     pro7F1Payment.aug23,
//     pro7F2Payment.aug23,
//     pro7F3Payment.aug23
// ]
// export const pro7WithAug23Count = [
//     pro7F1Collectable.aug23,
//     pro7F2Collectable.aug23,
//     pro7F3Collectable.aug23
// ]
// export const pro7WithAug23NonCount = [
//     pro7F1NonCollectable.aug23,
//     pro7F2NonCollectable.aug23,
//     pro7F3NonCollectable.aug23
// ]
// export const pro7WithAug23Claim = [
//     pro7F1ClaimCount.aug23,
//     pro7F2ClaimCount.aug23,
//     pro7F3ClaimCount.aug23
// ]
// export const pro7WithAug23PerVisit = [
//     pro7F1PerVisit.aug23,
//     pro7F2PerVisit.aug23,
//     pro7F3PerVisit.aug23
// ]
// // sep 23
// export const pro7WithSep23Charges = [
//     pro7F1Charge.sep23,
//     pro7F2Charge.sep23,
//     pro7F3Charge.sep23
// ]
// export const pro7WithSep23Pay = [
//     pro7F1Payment.sep23,
//     pro7F2Payment.sep23,
//     pro7F3Payment.sep23
// ]
// export const pro7WithSep23Count = [
//     pro7F1Collectable.sep23,
//     pro7F2Collectable.sep23,
//     pro7F3Collectable.sep23
// ]
// export const pro7WithSep23NonCount = [
//     pro7F1NonCollectable.sep23,
//     pro7F2NonCollectable.sep23,
//     pro7F3NonCollectable.sep23
// ]
// export const pro7WithSep23Claim = [
//     pro7F1ClaimCount.sep23,
//     pro7F2ClaimCount.sep23,
//     pro7F3ClaimCount.sep23
// ]
// export const pro7WithSep23PerVisit = [
//     pro7F1PerVisit.sep23,
//     pro7F2PerVisit.sep23,
//     pro7F3PerVisit.sep23
// ]
// // console.log("pro7WithSep23PerVisit :",pro7WithSep23PerVisit)
// // oct 23
// export const pro7WithOct23Charges = [
//     pro7F1Charge.oct23,
//     pro7F2Charge.oct23,
//     pro7F3Charge.oct23
// ]
// export const pro7WithOct23Pay = [
//     pro7F1Payment.oct23,
//     pro7F2Payment.oct23,
//     pro7F3Payment.oct23
// ]
// export const pro7WithOct23Count = [
//     pro7F1Collectable.oct23,
//     pro7F2Collectable.oct23,
//     pro7F3Collectable.oct23
// ]
// export const pro7WithOct23NonCount = [
//     pro7F1NonCollectable.oct23,
//     pro7F2NonCollectable.oct23,
//     pro7F3NonCollectable.oct23
// ]
// export const pro7WithOct23Claim = [
//     pro7F1ClaimCount.oct23,
//     pro7F2ClaimCount.oct23,
//     pro7F3ClaimCount.oct23
// ]
// export const pro7WithOct23PerVisit = [
//     pro7F1PerVisit.oct23,
//     pro7F2PerVisit.oct23,
//     pro7F3PerVisit.oct23
// ]
// // nov 23
// export const pro7WithNov23Charges = [
//     pro7F1Charge.nov23,
//     pro7F2Charge.nov23,
//     pro7F3Charge.nov23
// ]
// export const pro7WithNov23Pay = [
//     pro7F1Payment.nov23,
//     pro7F2Payment.nov23,
//     pro7F3Payment.nov23
// ]
// export const pro7WithNov23Count = [
//     pro7F1Collectable.nov23,
//     pro7F2Collectable.nov23,
//     pro7F3Collectable.nov23
// ]
// export const pro7WithNov23NonCount = [
//     pro7F1NonCollectable.nov23,
//     pro7F2NonCollectable.nov23,
//     pro7F3NonCollectable.nov23
// ]
// export const pro7WithNov23Claim = [
//     pro7F1ClaimCount.nov23,
//     pro7F2ClaimCount.nov23,
//     pro7F3ClaimCount.nov23
// ]
// export const pro7WithNov23PerVisit = [
//     pro7F1PerVisit.nov23,
//     pro7F2PerVisit.nov23,
//     pro7F3PerVisit.nov23
// ]
// console.log("pro7WithNov23PerVisit :",pro7WithNov23PerVisit)