export const jan2024Series = [
    {
        name: 'Charges',
        data: [
            123694
        ]
    },
    {
        name: 'Payment',
        data: [
            18390.27
        ]
    },
    {
        name: 'Collectable',
        data: [
            1877.67
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            47684.68
        ]
    },
    {
        name: 'Claims',
        data: [
            112

        ]
    },
    {
        name: 'Average Visit',
        data: [
            164
        ]
    },
]

export const feb2024Series = [
    {
        name: 'Charges',
        data: [
            98453
        ]
    },
    {
        name: 'Payment',
        data: [
            21713
        ]
    },
    {
        name: 'Collectable',
        data: [
            10184.94
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            165428.7
        ]
    },
    {
        name: 'Claims',
        data: [
            101

        ]
    },
    {
        name: 'Average Visit',
        data: [
            214.98
        ]
    },
]

export const mar2024Series = [
    {
        name: 'Charges',
        data: [
            118159
        ]
    },
    {
        name: 'Payment',
        data: [
            15969.11
        ]
    },
    {
        name: 'Collectable',
        data: [
            3227.12
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            33110.5
        ]
    },
    {
        name: 'Claims',
        data: [
            115

        ]
    },
    {
        name: 'Average Visit',
        data: [
            138.86
        ]
    },
]

export const apr2024Series = [
    {
        name: 'Charges',
        data: [
            82976
        ]
    },
    {
        name: 'Payment',
        data: [
            32949.93
        ]
    },
    {
        name: 'Collectable',
        data: [
            3144.95
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            98056.71
        ]
    },
    {
        name: 'Claims',
        data: [
            66

        ]
    },
    {
        name: 'Average Visit',
        data: [
            499.24
        ]
    },
]

export const may2024Series = [
    {
        name: 'Charges',
        data: [
            54337.8
        ]
    },
    {
        name: 'Payment',
        data: [
            26715.99
        ]
    },
    {
        name: 'Collectable',
        data: [
            2317.96
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            70507.09
        ]
    },
    {
        name: 'Claims',
        data: [
            78

        ]
    },
    {
        name: 'Average Visit',
        data: [
            342.51
        ]
    },
]

export const jun2024Series = [
    {
        name: 'Charges',
        data: [
            122131.4
        ]
    },
    {
        name: 'Payment',
        data: [
            18725.17
        ]
    },
    {
        name: 'Collectable',
        data: [
            2975.26
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            75251.46
        ]
    },
    {
        name: 'Claims',
        data: [
            105

        ]
    },
    {
        name: 'Average Visit',
        data: [
            178.14
        ]
    },
]

export const jul2024Series = [
    {
        name: 'Charges',
        data: [
            116533
        ]
    },
    {
        name: 'Payment',
        data: [
            25849
        ]
    },
    {
        name: 'Collectable',
        data: [
            320.94
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            78020.93
        ]
    },
    {
        name: 'Claims',
        data: [
            93

        ]
    },
    {
        name: 'Average Visit',
        data: [
            277.93
        ]
    },
]

export const aug2024Series = [
    {
        name: 'Charges',
        data: [
            56727
        ]
    },
    {
        name: 'Payment',
        data: [
            14403
        ]
    },
    {
        name: 'Collectable',
        data: [
            2414
        ]
    },
    {
        name: 'Non Collectable',
        data: [
            50271
        ]
    },
    {
        name: 'Claims',
        data: [
            61

        ]
    },
    {
        name: 'Average Visit',
        data: [
            236
        ]
    },
]