import React, { useState } from 'react'
import DashCards from '../../DashBoardsCards/DashCards';
import Chart from 'react-apexcharts';
import { Col, Row } from 'reactstrap';
import { payers, payersRX, payersSAM } from './payers';
import {
    grandTotal, twentyone, twentytwenty, twentytwo, twentythree, twentyfour , within90, over90, jan2020, jan2021, jan2022, apr2020, may2020, jun2020, july2020, sept2020, oct2020
    , dec2020, feb2021, feb2022, mar2021, mar2022, apr2021, apr2022, may2021, may2022, jun2021, jun2022, july2021, aug2021, sept2021, oct2021, nov2021, dec2021,

    grandTotalSAM, twentyoneSAM, twentytwentySAM, twentytwoSAM, twentythreeSAM, twentyfourSAM , within90SAM, over90SAM, jan2020SAM, jan2021SAM, jan2022SAM, apr2020SAM, may2020SAM, jun2020SAM, july2020SAM, sept2020SAM, oct2020SAM
    , dec2020SAM, feb2021SAM, feb2022SAM, mar2021SAM, mar2022SAM, apr2021SAM,
    apr2022SAM, may2021SAM, may2022SAM, jun2021SAM, jun2022SAM,
    july2021SAM, aug2021SAM, sept2021SAM, oct2021SAM, nov2021SAM,
    dec2021SAM, aug2022, jul2022, sept2022, jul2022SAM, aug2022SAM,
    sept2022SAM, nov2020SAM, nov2022, oct2022SAM, nov2022SAM, dec2022, oct2022, dec2022SAM,
    jan2023, feb2023, jan2023SAM, feb2023SAM, mar2023, apr2023, mar2023SAM, apr2023SAM, may2023, may2023SAM,jun2023,jun2023SAM,jul2023,jul2023SAM,aug2023,aug2023SAM,sep2023,sep2023SAM,oct2023,oct2023SAM,nov2023,nov2023SAM,dec2023,dec2023SAM,
    jan2024, jan2024SAM , feb2024 , mar2024 , feb2024SAM , mar2024SAM , apr2024 , apr2024SAM , may2024 , may2024SAM, jun2024, jun2024SAM, jul2024, jul2024SAM, aug2024, aug2024SAM
} from './ClaimSumaryData';
import ModalLoader from '../../Loader/ModalLoader';
import { apr2022RX, apr2023RX, aug2022RX, dec2022RX, feb2023RX, grandTotalRX, jan2023RX, jul2022RX, jun2022RX, mar2022RX, mar2023RX, may2022RX, nov2022RX, oct2022RX, over90RX, sept2022RX, twentyoneRX, twentythreeRX, twentytwentyRX, twentytwoRX, within90RX ,may2023RX , jun2023RX,
    jul2023RX, aug2023RX, sep2023RX, oct2023RX, nov2023RX} from './RX/RXClaimData';

const Provider3 = ({ updateData }) => {
    const [payer, setPayer] = useState('')
    const [indexNo, setIndexNo] = useState(0)
    const series = [
        {
            name: 'Claims',
            data: sessionStorage.getItem('name') === 'Aurora' ? [
                // twentytwenty[indexNo],
                // twentyone[indexNo],
                // twentytwo[indexNo],
                twentythree[indexNo],
                twentyfour[indexNo],
                over90[indexNo],
                within90[indexNo],
                // jun2020[indexNo],
                // july2020[indexNo],
                // feb2021[indexNo],
                // mar2021[indexNo],
                // apr2021[indexNo],
                // may2021[indexNo],
                // jun2021[indexNo],
                // july2021[indexNo],
                // aug2021[indexNo],
                // sept2021[indexNo],
                // oct2021[indexNo],
                // nov2021[indexNo],
                // dec2021[indexNo],
                // jan2022[indexNo],
                // apr2022[indexNo],
                // may2022[indexNo],
                // jun2022[indexNo],
                // jul2022[indexNo],
                // aug2022[indexNo],
                // sept2022[indexNo],
                // oct2022[indexNo],
                // nov2022[indexNo],
                // dec2022[indexNo],
                // jan2023[indexNo],
                // feb2023[indexNo],
                // mar2023[indexNo],
                apr2023[indexNo],
                // may2023[indexNo],
                // jun2023[indexNo],
                // jul2023[indexNo],
                // aug2023[indexNo],
                // sep2023[indexNo],
                oct2023[indexNo],
                nov2023[indexNo],
                // dec2023[indexNo],
                jan2024[indexNo],
                feb2024[indexNo],
                mar2024[indexNo],
                apr2024[indexNo],
                may2024[indexNo],
                jun2024[indexNo],
                jul2024[indexNo],
                aug2024[indexNo],
                grandTotal[indexNo]
            ] : sessionStorage.getItem('name') === 'PersonalClinic' ?
                [
                    // twentytwentySAM[indexNo],
                    // twentyoneSAM[indexNo],
                    twentytwoSAM[indexNo],
                    twentythreeSAM[indexNo],
                    twentyfourSAM[indexNo],
                    over90SAM[indexNo],
                    within90SAM[indexNo],
                    // oct2020SAM[indexNo],
                    // nov2020SAM[indexNo],
                    // dec2020SAM[indexNo],
                    // jan2021SAM[indexNo],
                    // feb2021SAM[indexNo],
                    // mar2021SAM[indexNo],
                    // apr2021SAM[indexNo],
                    // may2021SAM[indexNo],
                    // jun2021SAM[indexNo],
                    // july2021SAM[indexNo],
                    // aug2021SAM[indexNo],
                    // sept2021SAM[indexNo],
                    // oct2021SAM[indexNo],
                    // nov2021SAM[indexNo],
                    // dec2021SAM[indexNo],
                    // jan2022SAM[indexNo],
                    // feb2022SAM[indexNo],
                    // mar2022SAM[indexNo],
                    // apr2022SAM[indexNo],
                    may2022SAM[indexNo],
                    // jun2022SAM[indexNo],
                    // jul2022SAM[indexNo],
                    // aug2022SAM[indexNo],
                    // sept2022SAM[indexNo],
                    // oct2022SAM[indexNo],
                    // nov2022SAM[indexNo],
                    // dec2022SAM[indexNo],
                    // jan2023SAM[indexNo],
                    feb2023SAM[indexNo],
                    mar2023SAM[indexNo],
                    apr2023SAM[indexNo],
                    may2023SAM[indexNo],
                    jun2023SAM[indexNo],
                    jul2023SAM[indexNo],
                    aug2023SAM[indexNo],
                    sep2023SAM[indexNo],
                    oct2023SAM[indexNo],
                    nov2023SAM[indexNo],
                    dec2023SAM[indexNo],
                    jan2024SAM[indexNo],
                    feb2024SAM[indexNo],
                    mar2024SAM[indexNo],
                    apr2024SAM[indexNo],
                    may2024SAM[indexNo],
                    jun2024SAM[indexNo],
                    jul2024SAM[indexNo],
                    aug2024SAM[indexNo],
                    grandTotalSAM[indexNo]
                ] :
                sessionStorage.getItem('name') === 'RX' ?
                    [
                        twentytwoRX[indexNo],
                        twentythreeRX[indexNo],
                        over90RX[indexNo],
                        within90RX[indexNo],
                        // mar2022RX[indexNo],
                        // apr2022RX[indexNo],
                        // may2022RX[indexNo],
                        // jun2022RX[indexNo],
                        jul2022RX[indexNo],
                        // aug2022RX[indexNo],
                        // sept2022RX[indexNo],
                        oct2022RX[indexNo],
                        // nov2022RX[indexNo],
                        // dec2022RX[indexNo],
                        jan2023RX[indexNo],
                        feb2023RX[indexNo],
                        mar2023RX[indexNo],
                        apr2023RX[indexNo],
                        may2023RX[indexNo],
                        jun2023RX[indexNo],
                        jul2023RX[indexNo],
                        aug2023RX[indexNo],
                        sep2023RX[indexNo],
                        oct2023RX[indexNo],
                        nov2023RX[indexNo],
                        grandTotalRX[indexNo]
                    ]
                    : ''
        }
    ]
    const yAxis = sessionStorage.getItem('name') === 'Aurora' ? [
        // '2021',
        // '2022',
        '2023',
        '2024',
        'Over 90 Days',
        'With in 90days',
        // 'Jun - 2020',
        // 'Jul - 2020',
        // 'Feb - 2021',
        // 'Mar - 2021',
        // 'Apr - 2021',
        // 'May - 2021',
        // 'Jun - 2021',
        // 'Jul - 2021',
        // 'Aug - 2021',
        // 'Sep - 2021',
        // 'Oct - 2021',
        // 'Nov - 2021',
        // 'Dec - 2021',
        // 'Jan - 2022',
        // 'Feb - 2022',
        // 'Mar - 2022',
        // 'Apr - 2022	',
        // 'May - 2022',
        // 'Jun - 2022',
        // 'Jul - 2022',
        // 'Aug - 2022',
        // 'Sept - 2022',
        // 'Oct - 2022',
        // 'Nov - 2022',
        // 'Dec - 2022',
        // 'Jan - 2023',
        // 'Feb - 2023',
        // 'Mar - 2023',
        'Apr - 2023',
        // 'May - 2023',
        // 'Jun - 2023',
        // 'Jul - 2023',
        // 'Aug - 2023',
        // 'Sep - 2023',
        'Oct - 2023',
        'Nov - 2023',
        // 'Dec - 2023',
        'Jan - 2024',
        'Feb - 2024',
        'Mar - 2024',
        'Apr - 2024',
        'May - 2024',
        'Jun - 2024',
        'Jul - 2024',
        'Aug - 2024',
        'Grand Total',
    ] : sessionStorage.getItem('name') === 'RX' ? [
        '2022',
        '2023',
        'Over 90 Days',
        'With in 90days',
        // 'Mar - 2022',
        // 'Apr - 2022	',
        // 'May - 2022',
        // 'Jun - 2022',
        'Jul - 2022',
        // 'Aug - 2022',
        // 'Sept - 2022',
        'Oct - 2022',
        // 'Nov - 2022',
        // 'Dec - 2022',
        'Jan - 2023',
        'Feb - 2023',
        'Mar - 2023',
        'Apr - 2023',
        'May - 2023',
        'Jun - 2023',
        'Jul - 2023',
        'Aug - 2023',
        'Sep - 2023',
        'Oct - 2023',
        'Nov - 2023',
        'Grand Total',
    ] : [
        // '2020',
        // '2021',
        '2022',
        '2023',
        '2024',
        'Over 90 Days',
        'With in 90days',
        // 'Oct - 2020',
        // 'Nov - 2020',
        // 'Dec - 2020',
        // 'Jan - 2021',
        // 'Feb - 2021',
        // 'Mar - 2021',
        // 'Apr - 2021',
        // 'May - 2021',
        // 'Jun - 2021',
        // 'Jul-2021',
        // 'Aug - 2021',
        // 'Sep - 2021',
        // 'Oct - 2021',
        // 'Nov - 2021',
        // 'Dec - 2021',
        // 'Jan - 2022',
        // 'Feb - 2022',
        // 'Mar - 2022',
        // 'Apr - 2022	',
        'May - 2022',
        // 'Jun - 2022',
        // 'Jul - 2022',
        // 'Aug - 2022',
        // 'Sep - 2022',
        // 'Oct - 2022',
        // 'Nov - 2022',
        // 'Dec - 2022',
        // 'Jan - 2023',
        'Feb - 2023',
        'Mar - 2023',
        'Apr - 2023',
        'May - 2023',
        'Jun - 2023',
        'Jul - 2023',
        'Aug - 2023',
        'Sep - 2023',
        'Oct - 2023',
        'Nov - 2023',
        'Dec - 2023',
        'Jan - 2024',
        'Feb - 2024',
        'Mar - 2024',
        'Apr - 2024',
        'May - 2024',
        'Jun - 2024',
        'Jul - 2024',
        'Aug - 2024',
        'Grand Total',
    ]
    var options = {
        chart: {
            type: 'bar',
            height: 450,
            stacked: true,
        },
        plotOptions: {
            bar: {
                horizontal: false,
            },
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },

        xaxis: {
            categories: yAxis,
            labels: {
                formatter: function (val) {
                    return val
                }
            },

        },
        yaxis: {
            title: {
                text: undefined
            },
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val
                }
            }
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
        }
    };
    const [isShow, setisShow] = useState(false)

    const handleData = (e) => {
        setisShow(true)
        setPayer(e.target.value)
        const dat = sessionStorage.getItem('name') === 'Aurora' ? payers.indexOf(e.target.value) : 
        sessionStorage.getItem('name') === 'PersonalClinic' ? payersSAM.indexOf(e.target.value) : payersRX.indexOf(e.target.value)
        setIndexNo(dat)
        let a = sessionStorage.getItem('name') === 'Aurora' ? grandTotal[payers.indexOf(e.target.value)] : sessionStorage.getItem('name') === 'PersonalClinic' ? grandTotalSAM[payersSAM.indexOf(e.target.value)] : ''
        let b = sessionStorage.getItem('name') === 'Aurora' ? twentytwenty[payers.indexOf(e.target.value)] : sessionStorage.getItem('name') === 'PersonalClinic' ? twentytwentySAM[payers.indexOf(e.target.value)] : ''
        let c = sessionStorage.getItem('name') === 'Aurora' ? twentyone[payers.indexOf(e.target.value)] : sessionStorage.getItem('name') === 'PersonalClinic' ? twentyoneSAM[payers.indexOf(e.target.value)] : ''
        let d = sessionStorage.getItem('name') === 'Aurora' ? twentytwo[payers.indexOf(e.target.value)] : sessionStorage.getItem('name') === 'PersonalClinic' ? twentytwoSAM[payers.indexOf(e.target.value)] : ''
        let m = sessionStorage.getItem('name') === 'Aurora' ? over90[payers.indexOf(e.target.value)] : sessionStorage.getItem('name') === 'PersonalClinic' ? over90SAM[payers.indexOf(e.target.value)] : ''
        let f = sessionStorage.getItem('name') === 'Aurora' ? within90[payers.indexOf(e.target.value)] : sessionStorage.getItem('name') === 'PersonalClinic' ? within90SAM[payers.indexOf(e.target.value)] : ''
        updateData(
            sessionStorage.getItem('name') === 'Aurora' ? grandTotal[payers.indexOf(e.target.value)] :
                sessionStorage.getItem('name') === 'RX' ? grandTotalRX[payersRX.indexOf(e.target.value)] : sessionStorage.getItem('name') === 'PersonalClinic' ? grandTotalSAM[payersSAM.indexOf(e.target.value)] : '',
            // sessionStorage.getItem('name') === 'Aurora' ? twentytwenty[payers.indexOf(e.target.value)] :
            //     sessionStorage.getItem('name') === 'RX' ? twentytwentyRX[payersRX.indexOf(e.target.value)] : sessionStorage.getItem('name') === 'PersonalClinic' ? twentytwentySAM[payersSAM.indexOf(e.target.value)] : '',
            // sessionStorage.getItem('name') === 'Aurora' ? twentyone[payers.indexOf(e.target.value)] :
            //     sessionStorage.getItem('name') === 'RX' ? twentyoneRX[payersRX.indexOf(e.target.value)] : sessionStorage.getItem('name') === 'PersonalClinic' ? twentyoneSAM[payersSAM.indexOf(e.target.value)] : '',
            sessionStorage.getItem('name') === 'Aurora' ? twentytwo[payers.indexOf(e.target.value)] :
                sessionStorage.getItem('name') === 'RX' ? twentytwoRX[payersRX.indexOf(e.target.value)] : sessionStorage.getItem('name') === 'PersonalClinic' ? twentytwoSAM[payersSAM.indexOf(e.target.value)] : '',
            sessionStorage.getItem('name') === 'Aurora' ? twentythree[payers.indexOf(e.target.value)] :
                sessionStorage.getItem('name') === 'RX' ? twentythreeRX[payersRX.indexOf(e.target.value)] : sessionStorage.getItem('name') === 'PersonalClinic' ? twentythreeSAM[payersSAM.indexOf(e.target.value)] : '',
            sessionStorage.getItem('name') === 'Aurora' ? twentyfour[payers.indexOf(e.target.value)] :
                sessionStorage.getItem('name') === 'PersonalClinic' ? twentyfourSAM[payersSAM.indexOf(e.target.value)] : '',
                // sessionStorage.getItem('name') === 'RX' ? twentythreeRX[payersRX.indexOf(e.target.value)] : '',
            sessionStorage.getItem('name') === 'Aurora' ? over90[payers.indexOf(e.target.value)] :
                sessionStorage.getItem('name') === 'RX' ? over90RX[payersRX.indexOf(e.target.value)] : sessionStorage.getItem('name') === 'PersonalClinic' ? over90SAM[payersSAM.indexOf(e.target.value)] : '',
            sessionStorage.getItem('name') === 'Aurora' ? within90[payers.indexOf(e.target.value)] :
                sessionStorage.getItem('name') === 'RX' ? within90RX[payersRX.indexOf(e.target.value)] : sessionStorage.getItem('name') === 'PersonalClinic' ? within90SAM[payersSAM.indexOf(e.target.value)] : '')
        // console.log(payers.indexOf(e.target.value))
        setTimeout(() => {
            setisShow(false)
        }, 1200);
    }
    return (
        <div >
            {/* <h3 className='text-start'> Claim Summary</h3> */}
            <Row>
                <ModalLoader isShow={isShow} />
                <Col md='4'>
                    <small className='text-start fs-4 text-dark fw-bolder'>Filter by payers</small>
                    {
                        <select className='form-control' value={payer} onChange={(e) => handleData(e)}>
                            {
                                sessionStorage.getItem('name') === 'Aurora' ? payers.map((val, i) => {
                                    return <option key={i} value={val}>{val}</option>
                                }) : sessionStorage.getItem('name') === 'RX' ?
                                    payersRX.map((val, i) => {
                                        return <option key={i} value={val}>{val}</option>
                                    })
                                    : payersSAM.map((val, i) => {
                                        return <option key={i} value={val}>{val}</option>
                                    })
                            }
                        </select>

                    }
                </Col>
            </Row>
            <div className="mt-4 position-relative" style={{ height: '450px' }}>
                <Chart options={options} series={series} type="bar" height="415px" />
            </div>
        </div>
    )
}

export default Provider3