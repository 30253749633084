import {allF1Charge,allF1Payment,allF1Collectable,allF1NonCollectable,allF1ClaimCount,allF1PerVisit,
    allF2Charge,allF2Payment,allF2Collectable,allF2NonCollectable,allF2ClaimCount,allF2PerVisit,
    allF3Charge,allF3Payment,allF3Collectable,allF3NonCollectable,allF3ClaimCount,allF3PerVisit,
    // pro1F1Charge,pro1F1Payment,pro1F1Collectable,pro1F1NonCollectable,pro1F1ClaimCount,pro1F1PerVisit,
    // pro1F2Charge,pro1F2Payment,pro1F2Collectable,pro1F2NonCollectable,pro1F2ClaimCount,pro1F2PerVisit,
    // pro1F3Charge,pro1F3Payment,pro1F3Collectable,pro1F3NonCollectable,pro1F3ClaimCount,pro1F3PerVisit,
    // pro2F1Charge,pro2F1Payment,pro2F1Collectable,pro2F1NonCollectable,pro2F1ClaimCount,pro2F1PerVisit,
    // pro2F2Charge,pro2F2Payment,pro2F2Collectable,pro2F2NonCollectable,pro2F2ClaimCount,pro2F2PerVisit,
    // pro2F3Charge,pro2F3Payment,pro2F3Collectable,pro2F3NonCollectable,pro2F3ClaimCount,pro2F3PerVisit,
    // pro3F1Charge,pro3F1Payment,pro3F1Collectable,pro3F1NonCollectable,pro3F1ClaimCount,pro3F1PerVisit,
    // pro3F2Charge,pro3F2Payment,pro3F2Collectable,pro3F2NonCollectable,pro3F2ClaimCount,pro3F2PerVisit,
    // pro3F3Charge,pro3F3Payment,pro3F3Collectable,pro3F3NonCollectable,pro3F3ClaimCount,pro3F3PerVisit,
    // pro4F1Charge,pro4F1Payment,pro4F1Collectable,pro4F1NonCollectable,pro4F1ClaimCount,pro4F1PerVisit,
    // pro4F2Charge,pro4F2Payment,pro4F2Collectable,pro4F2NonCollectable,pro4F2ClaimCount,pro4F2PerVisit,
    // pro4F3Charge,pro4F3Payment,pro4F3Collectable,pro4F3NonCollectable,pro4F3ClaimCount,pro4F3PerVisit,
    // pro5F1Charge,pro5F1Payment,pro5F1Collectable,pro5F1NonCollectable,pro5F1ClaimCount,pro5F1PerVisit,
    // pro5F2Charge,pro5F2Payment,pro5F2Collectable,pro5F2NonCollectable,pro5F2ClaimCount,pro5F2PerVisit,
    // pro5F3Charge,pro5F3Payment,pro5F3Collectable,pro5F3NonCollectable,pro5F3ClaimCount,pro5F3PerVisit,
    // pro6F1Charge,pro6F1Payment,pro6F1Collectable,pro6F1NonCollectable,pro6F1ClaimCount,pro6F1PerVisit,
    // pro6F2Charge,pro6F2Payment,pro6F2Collectable,pro6F2NonCollectable,pro6F2ClaimCount,pro6F2PerVisit,
    // pro6F3Charge,pro6F3Payment,pro6F3Collectable,pro6F3NonCollectable,pro6F3ClaimCount,pro6F3PerVisit,
    // pro7F1Charge,pro7F1Payment,pro7F1Collectable,pro7F1NonCollectable,pro7F1ClaimCount,pro7F1PerVisit,
    // pro7F2Charge,pro7F2Payment,pro7F2Collectable,pro7F2NonCollectable,pro7F2ClaimCount,pro7F2PerVisit,
    // pro7F3Charge,pro7F3Payment,pro7F3Collectable,pro7F3NonCollectable,pro7F3ClaimCount,pro7F3PerVisit,
} from './FacilityData';

import {allProviderPervisitRX } from './ProvidersAndFacility';

// import { allProviderChargesRX, allProviderClaimRX, allProviderCollectableRX, allProviderNonCollectableRX, allProviderPaymentRX,
//     provider1Charges, provider1Claim, provider1Collectable, provider1NonCollectable, provider1Payment, 
//     provider2Charges, provider2Claim, provider2Collectable, provider2NonCollectable, provider2Payment, 
//     provider3Charges, provider3Claim, provider3Collectable, provider3NonCollectable, provider3Payment, 
//     provider4Charges, provider4Claim, provider4Collectable, provider4NonCollectable, provider4Payment, 
//     provider5Charges, provider5Claim, provider5Collectable, provider5NonCollectable, provider5Payment, 
//     provider6Charges, provider6Claim, provider6Collectable, provider6NonCollectable, provider6Payment, 
//     provider7Charges, provider7Claim, provider7Collectable, provider7NonCollectable, provider7Payment } from './ProvidersAndFacility';
// import {allProviderPervisitRX , provider1PerVisist , provider2PerVisist , provider3PerVisist , provider4PerVisist , provider5PerVisist , provider6PerVisist , provider7PerVisist} from './ProvidersData';
// Jan 21
export const allproWithJan21Charges = [
    allF1Charge.jan21,
    allF2Charge.jan21,
    allF3Charge.jan21,
    allF1Charge.jan21+allF2Charge.jan21+allF3Charge.jan21,
]
export const allproWithJan21Pay = [
    allF1Payment.jan21,
    allF2Payment.jan21,
    allF3Payment.jan21,
    allF1Payment.jan21 + allF2Payment.jan21 + allF3Payment.jan21,
]
export const allproWithJan21Count = [
    allF1Collectable.jan21,
    allF2Collectable.jan21,
    allF3Collectable.jan21,
    allF1Collectable.jan21 + allF2Collectable.jan21 + allF3Collectable.jan21,
]
export const allproWithJan21NonCount = [
    allF1NonCollectable.jan21,
    allF2NonCollectable.jan21,
    allF3NonCollectable.jan21,
    allF1NonCollectable.jan21 + allF2NonCollectable.jan21 + allF3NonCollectable.jan21,
]
export const allproWithJan21Claim = [
    allF1ClaimCount.jan21,
    allF2ClaimCount.jan21,
    allF3ClaimCount.jan21,
    allF1ClaimCount.jan21 + allF2ClaimCount.jan21 + allF3ClaimCount.jan21,
]
export const allproWithJan21PerVisit = [
    allF1PerVisit.jan21,
    allF2PerVisit.jan21,
    allF3PerVisit.jan21,
    allProviderPervisitRX[0]
]
// feb 21
export const allproWithFeb21Charges = [
    allF1Charge.feb21,
    allF2Charge.feb21,
    allF3Charge.feb21,
    allF1Charge.feb21 + allF2Charge.feb21 + allF3Charge.feb21,
]
export const allproWithFeb21Pay = [
    allF1Payment.feb21,
    allF2Payment.feb21,
    allF3Payment.feb21,
    allF1Payment.feb21 + allF2Payment.feb21 + allF3Payment.feb21
]
export const allproWithFeb21Count = [
    allF1Collectable.feb21,
    allF2Collectable.feb21,
    allF3Collectable.feb21,
    allF1Collectable.feb21 + allF2Collectable.feb21 + allF3Collectable.feb21
]
export const allproWithFeb21NonCount = [
    allF1NonCollectable.feb21,
    allF2NonCollectable.feb21,
    allF3NonCollectable.feb21,
    allF1NonCollectable.feb21 + allF2NonCollectable.feb21 + allF3NonCollectable.feb21
]
export const allproWithFeb21Claim = [
    allF1ClaimCount.feb21,
    allF2ClaimCount.feb21,
    allF3ClaimCount.feb21,
    allF1ClaimCount.feb21 + allF2ClaimCount.feb21 + allF3ClaimCount.feb21
]
export const allproWithFeb21PerVisit = [
    allF1PerVisit.feb21,
    allF2PerVisit.feb21,
    allF3PerVisit.feb21,
    allProviderPervisitRX[1]
    
]
// mar 21
export const allproWithMar21Charges = [
    allF1Charge.mar21,
    allF2Charge.mar21,
    allF3Charge.mar21,
    allF1Charge.mar21 + allF2Charge.mar21 + allF3Charge.mar21
]
export const allproWithMar21Pay = [
    allF1Payment.mar21,
    allF2Payment.mar21,
    allF3Payment.mar21,
    allF1Payment.mar21 + allF2Payment.mar21 + allF3Payment.mar21
]
export const allproWithMar21Count = [
    allF1Collectable.mar21,
    allF2Collectable.mar21,
    allF3Collectable.mar21,
    allF1Collectable.mar21 + allF2Collectable.mar21 + allF3Collectable.mar21
]
export const allproWithMar21NonCount = [
    allF1NonCollectable.mar21,
    allF2NonCollectable.mar21,
    allF3NonCollectable.mar21,
    allF1NonCollectable.mar21 + allF2NonCollectable.mar21 + allF3NonCollectable.mar21
]
export const allproWithMar21Claim = [
    allF1ClaimCount.mar21,
    allF2ClaimCount.mar21,
    allF3ClaimCount.mar21,
    allF1ClaimCount.mar21 + allF2ClaimCount.mar21 + allF3ClaimCount.mar21
]
export const allproWithMar21PerVisit = [
    allF1PerVisit.mar21,
    allF2PerVisit.mar21,
    allF3PerVisit.mar21,
    allProviderPervisitRX[2]
]
// april 21
export const allproWithApr21Charges = [
    allF1Charge.apr21,
    allF2Charge.apr21,
    allF3Charge.apr21,
    allF1Charge.apr21 + allF2Charge.apr21 + allF3Charge.apr21
]
export const allproWithApr21Pay = [
    allF1Payment.apr21,
    allF2Payment.apr21,
    allF3Payment.apr21,
    allF1Payment.apr21 + allF2Payment.apr21 + allF3Payment.apr21
]
export const allproWithApr21Count = [
    allF1Collectable.apr21,
    allF2Collectable.apr21,
    allF3Collectable.apr21,
    allF1Collectable.apr21 + allF2Collectable.apr21 + allF3Collectable.apr21
]
export const allproWithApr21NonCount = [
    allF1NonCollectable.apr21,
    allF2NonCollectable.apr21,
    allF3NonCollectable.apr21,
    allF1NonCollectable.apr21 + allF2NonCollectable.apr21 + allF3NonCollectable.apr21
]
export const allproWithApr21Claim = [
    allF1ClaimCount.apr21,
    allF2ClaimCount.apr21,
    allF3ClaimCount.apr21,
    allF1ClaimCount.apr21 + allF2ClaimCount.apr21 + allF3ClaimCount.apr21
]
export const allproWithApr21PerVisit = [
    allF1PerVisit.apr21,
    allF2PerVisit.apr21,
    allF3PerVisit.apr21,
    allProviderPervisitRX[3]
]
// may 21
export const allproWithMay21Charges = [
    allF1Charge.may21,
    allF2Charge.may21,
    allF3Charge.may21,
    allF1Charge.may21 + allF2Charge.may21 + allF3Charge.may21
]
export const allproWithMay21Pay = [
    allF1Payment.may21,
    allF2Payment.may21,
    allF3Payment.may21,
    allF1Payment.may21 + allF2Payment.may21 + allF3Payment.may21
]
export const allproWithMay21Count = [
    allF1Collectable.may21,
    allF2Collectable.may21,
    allF3Collectable.may21,
    allF1Collectable.may21 + allF2Collectable.may21 + allF3Collectable.may21
]
export const allproWithMay21NonCount = [
    allF1NonCollectable.may21,
    allF2NonCollectable.may21,
    allF3NonCollectable.may21,
    allF1NonCollectable.may21 + allF2NonCollectable.may21 + allF3NonCollectable.may21
]
export const allproWithMay21Claim = [
    allF1ClaimCount.may21,
    allF2ClaimCount.may21,
    allF3ClaimCount.may21,
    allF1ClaimCount.may21 + allF2ClaimCount.may21 + allF3ClaimCount.may21
]
export const allproWithMay21PerVisit = [
    allF1PerVisit.may21,
    allF2PerVisit.may21,
    allF3PerVisit.may21,
    allProviderPervisitRX[4]
]
// jun 21
export const allproWithJun21Charges = [
    allF1Charge.jun21,
    allF2Charge.jun21,
    allF3Charge.jun21,
    allF1Charge.jun21 + allF2Charge.jun21 + allF3Charge.jun21
    
]
export const allproWithJun21Pay = [
    allF1Payment.jun21,
    allF2Payment.jun21,
    allF3Payment.jun21,
    allF1Payment.jun21 + allF2Payment.jun21 + allF3Payment.jun21
]
export const allproWithJun21Count = [
    allF1Collectable.jun21,
    allF2Collectable.jun21,
    allF3Collectable.jun21,
    allF1Collectable.jun21 + allF2Collectable.jun21 + allF3Collectable.jun21
]
export const allproWithJun21NonCount = [
    allF1NonCollectable.jun21,
    allF2NonCollectable.jun21,
    allF3NonCollectable.jun21,
    allF1NonCollectable.jun21 + allF2NonCollectable.jun21 + allF3NonCollectable.jun21
]
export const allproWithJun21Claim = [
    allF1ClaimCount.jun21,
    allF2ClaimCount.jun21,
    allF3ClaimCount.jun21,
    allF1ClaimCount.jun21 + allF2ClaimCount.jun21 + allF3ClaimCount.jun21
]
export const allproWithJun21PerVisit = [
    allF1PerVisit.jun21,
    allF2PerVisit.jun21,
    allF3PerVisit.jun21,
    allProviderPervisitRX[5]
]
// jul 21
export const allproWithJul21Charges = [
    allF1Charge.jul21,
    allF2Charge.jul21,
    allF3Charge.jul21,
    allF1Charge.jul21 + allF2Charge.jul21 + allF3Charge.jul21
]
export const allproWithJul21Pay = [
    allF1Payment.jul21,
    allF2Payment.jul21,
    allF3Payment.jul21,
    allF1Payment.jul21 + allF2Payment.jul21 + allF3Payment.jul21
]
export const allproWithJul21Count = [
    allF1Collectable.jul21,
    allF2Collectable.jul21,
    allF3Collectable.jul21,
    allF1Collectable.jul21 + allF2Collectable.jul21 + allF3Collectable.jul21
]
export const allproWithJul21NonCount = [
    allF1NonCollectable.jul21,
    allF2NonCollectable.jul21,
    allF3NonCollectable.jul21,
    allF1NonCollectable.jul21 + allF2NonCollectable.jul21 + allF3NonCollectable.jul21
]
export const allproWithJul21Claim = [
    allF1ClaimCount.jul21,
    allF2ClaimCount.jul21,
    allF3ClaimCount.jul21,
    allF1ClaimCount.jul21 + allF2ClaimCount.jul21 + allF3ClaimCount.jul21
]
export const allproWithJul21PerVisit = [
    allF1PerVisit.jul21,
    allF2PerVisit.jul21,
    allF3PerVisit.jul21,
    allProviderPervisitRX[6]
]
// aug 21
export const allproWithAug21Charges = [
    allF1Charge.aug21,
    allF2Charge.aug21,
    allF3Charge.aug21,
    allF1Charge.aug21 + allF2Charge.aug21 + allF3Charge.aug21
]
export const allproWithAug21Pay = [
    allF1Payment.aug21,
    allF2Payment.aug21,
    allF3Payment.aug21,
    allF1Payment.aug21 + allF2Payment.aug21 + allF3Payment.aug21
]
export const allproWithAug21Count = [
    allF1Collectable.aug21,
    allF2Collectable.aug21,
    allF3Collectable.aug21,
    allF1Collectable.aug21 + allF2Collectable.aug21 + allF3Collectable.aug21
]
export const allproWithAug21NonCount = [
    allF1NonCollectable.aug21,
    allF2NonCollectable.aug21,
    allF3NonCollectable.aug21,
    allF1NonCollectable.aug21 + allF2NonCollectable.aug21 + allF3NonCollectable.aug21
]
export const allproWithAug21Claim = [
    allF1ClaimCount.aug21,
    allF2ClaimCount.aug21,
    allF3ClaimCount.aug21,
    allF1ClaimCount.aug21 + allF2ClaimCount.aug21 + allF3ClaimCount.aug21
]
export const allproWithAug21PerVisit = [
    allF1PerVisit.aug21,
    allF2PerVisit.aug21,
    allF3PerVisit.aug21,
    allProviderPervisitRX[7]
]
// sep 21
export const allproWithSep21Charges = [
    allF1Charge.sep21,
    allF2Charge.sep21,
    allF3Charge.sep21,
    allF1Charge.sep21 + allF2Charge.sep21 + allF3Charge.sep21
]
export const allproWithSep21Pay = [
    allF1Payment.sep21,
    allF2Payment.sep21,
    allF3Payment.sep21,
    allF1Payment.sep21 + allF2Payment.sep21 + allF3Payment.sep21
]
export const allproWithSep21Count = [
    allF1Collectable.sep21,
    allF2Collectable.sep21,
    allF3Collectable.sep21,
    allF1Collectable.sep21 + allF2Collectable.sep21 + allF3Collectable.sep21
]
export const allproWithSep21NonCount = [
    allF1NonCollectable.sep21,
    allF2NonCollectable.sep21,
    allF3NonCollectable.sep21,
    allF1NonCollectable.sep21 + allF2NonCollectable.sep21 + allF3NonCollectable.sep21
]
export const allproWithSep21Claim = [
    allF1ClaimCount.sep21,
    allF2ClaimCount.sep21,
    allF3ClaimCount.sep21,
    allF1ClaimCount.sep21 + allF2ClaimCount.sep21 + allF3ClaimCount.sep21
]
export const allproWithSep21PerVisit = [
    allF1PerVisit.sep21,
    allF2PerVisit.sep21,
    allF3PerVisit.sep21,
    allProviderPervisitRX[8]
]
// oct 21
export const allproWithOct21Charges = [
    allF1Charge.oct21,
    allF2Charge.oct21,
    allF3Charge.oct21,
    allF1Charge.oct21 + allF2Charge.oct21 + allF3Charge.oct21
]
export const allproWithOct21Pay = [
    allF1Payment.oct21,
    allF2Payment.oct21,
    allF3Payment.oct21,
    allF1Payment.oct21 + allF2Payment.oct21 + allF3Payment.oct21
]
export const allproWithOct21Count = [
    allF1Collectable.oct21,
    allF2Collectable.oct21,
    allF3Collectable.oct21,
    allF1Collectable.oct21 + allF2Collectable.oct21 + allF3Collectable.oct21
]
export const allproWithOct21NonCount = [
    allF1NonCollectable.oct21,
    allF2NonCollectable.oct21,
    allF3NonCollectable.oct21,
    allF1NonCollectable.oct21 + allF2NonCollectable.oct21 + allF3NonCollectable.oct21
]
export const allproWithOct21Claim = [
    allF1ClaimCount.oct21,
    allF2ClaimCount.oct21,
    allF3ClaimCount.oct21,
    allF1ClaimCount.oct21 + allF2ClaimCount.oct21 + allF3ClaimCount.oct21
]
export const allproWithOct21PerVisit = [
    allF1PerVisit.oct21,
    allF2PerVisit.oct21,
    allF3PerVisit.oct21,
    allProviderPervisitRX[9]
]
// nov 21
export const allproWithNov21Charges = [
    allF1Charge.nov21,
    allF2Charge.nov21,
    allF3Charge.nov21,
    allF1Charge.nov21 + allF2Charge.nov21 + allF3Charge.nov21
]
export const allproWithNov21Pay = [
    allF1Payment.nov21,
    allF2Payment.nov21,
    allF3Payment.nov21,
    allF1Payment.nov21 + allF2Payment.nov21 + allF3Payment.nov21
]
export const allproWithNov21Count = [
    allF1Collectable.nov21,
    allF2Collectable.nov21,
    allF3Collectable.nov21,
    allF1Collectable.nov21 + allF2Collectable.nov21 + allF3Collectable.nov21
]
export const allproWithNov21NonCount = [
    allF1NonCollectable.nov21,
    allF2NonCollectable.nov21,
    allF3NonCollectable.nov21,
    allF1NonCollectable.nov21 + allF2NonCollectable.nov21 + allF3NonCollectable.nov21
]
export const allproWithNov21Claim = [
    allF1ClaimCount.nov21,
    allF2ClaimCount.nov21,
    allF3ClaimCount.nov21,
    allF1ClaimCount.nov21 + allF2ClaimCount.nov21 + allF3ClaimCount.nov21
]
export const allproWithNov21PerVisit = [
    allF1PerVisit.nov21,
    allF2PerVisit.nov21,
    allF3PerVisit.nov21,
    allProviderPervisitRX[10]
]
// dec 21
export const allproWithDec21Charges = [
    allF1Charge.dec21,
    allF2Charge.dec21,
    allF3Charge.dec21,
    allF1Charge.dec21 + allF2Charge.dec21 + allF3Charge.dec21
]
export const allproWithDec21Pay = [
    allF1Payment.dec21,
    allF2Payment.dec21,
    allF3Payment.dec21,
    allF1Payment.dec21 + allF2Payment.dec21 + allF3Payment.dec21
]
export const allproWithDec21Count = [
    allF1Collectable.dec21,
    allF2Collectable.dec21,
    allF3Collectable.dec21,
    allF1Collectable.dec21 + allF2Collectable.dec21 + allF3Collectable.dec21
]
export const allproWithDec21NonCount = [
    allF1NonCollectable.dec21,
    allF2NonCollectable.dec21,
    allF3NonCollectable.dec21,
    allF1NonCollectable.dec21 + allF2NonCollectable.dec21 + allF3NonCollectable.dec21
]
export const allproWithDec21Claim = [
    allF1ClaimCount.dec21,
    allF2ClaimCount.dec21,
    allF3ClaimCount.dec21,
    allF1ClaimCount.dec21 + allF2ClaimCount.dec21 + allF3ClaimCount.dec21
]
export const allproWithDec21PerVisit = [
    allF1PerVisit.dec21,
    allF2PerVisit.dec21,
    allF3PerVisit.dec21,
    allProviderPervisitRX[11]
]
// jan 22
export const allproWithJan22Charges = [
    allF1Charge.jan22,
    allF2Charge.jan22,
    allF3Charge.jan22,
    allF1Charge.jan22 + allF2Charge.jan22 + allF3Charge.jan22
]
export const allproWithJan22Pay = [
    allF1Payment.jan22,
    allF2Payment.jan22,
    allF3Payment.jan22,
    allF1Payment.jan22 + allF2Payment.jan22 + allF3Payment.jan22
]
export const allproWithJan22Count = [
    allF1Collectable.jan22,
    allF2Collectable.jan22,
    allF3Collectable.jan22,
    allF1Collectable.jan22 + allF2Collectable.jan22 + allF3Collectable.jan22
]
export const allproWithJan22NonCount = [
    allF1NonCollectable.jan22,
    allF2NonCollectable.jan22,
    allF3NonCollectable.jan22,
    allF1NonCollectable.jan22 + allF2NonCollectable.jan22 + allF3NonCollectable.jan22
]
export const allproWithJan22Claim = [
    allF1ClaimCount.jan22,
    allF2ClaimCount.jan22,
    allF3ClaimCount.jan22,
    allF1ClaimCount.jan22 + allF2ClaimCount.jan22 + allF3ClaimCount.jan22
]
export const allproWithJan22PerVisit = [
    allF1PerVisit.jan22,
    allF2PerVisit.jan22,
    allF3PerVisit.jan22,
    allProviderPervisitRX[12]
]
// feb 22
export const allproWithFeb22Charges = [
    allF1Charge.feb22,
    allF2Charge.feb22,
    allF3Charge.feb22,
    allF1Charge.feb22 + allF2Charge.feb22 + allF3Charge.feb22
]
export const allproWithFeb22Pay = [
    allF1Payment.feb22,
    allF2Payment.feb22,
    allF3Payment.feb22,
    allF1Payment.feb22 + allF2Payment.feb22 + allF3Payment.feb22
]
export const allproWithFeb22Count = [
    allF1Collectable.feb22,
    allF2Collectable.feb22,
    allF3Collectable.feb22,
    allF1Collectable.feb22 + allF2Collectable.feb22 + allF3Collectable.feb22
]
export const allproWithFeb22NonCount = [
    allF1NonCollectable.feb22,
    allF2NonCollectable.feb22,
    allF3NonCollectable.feb22,
    allF1NonCollectable.feb22 + allF2NonCollectable.feb22 + allF3NonCollectable.feb22
]
export const allproWithFeb22Claim = [
    allF1ClaimCount.feb22,
    allF2ClaimCount.feb22,
    allF3ClaimCount.feb22,
    allProviderPervisitRX[13]
]
export const allproWithFeb22PerVisit = [
    allF1PerVisit.feb22,
    allF2PerVisit.feb22,
    allF3PerVisit.feb22,
    allF1PerVisit.feb22 + allF2PerVisit.feb22 + allF3PerVisit.feb22
]
// mar 22
export const allproWithMar22Charges = [
    allF1Charge.mar22,
    allF2Charge.mar22,
    allF3Charge.mar22,
    allF1Charge.mar22 + allF2Charge.mar22 + allF3Charge.mar22
]
export const allproWithMar22Pay = [
    allF1Payment.mar22,
    allF2Payment.mar22,
    allF3Payment.mar22,
    allF1Payment.mar22 + allF2Payment.mar22 + allF3Payment.mar22
]
export const allproWithMar22Count = [
    allF1Collectable.mar22,
    allF2Collectable.mar22,
    allF3Collectable.mar22,
    allF1Collectable.mar22 + allF2Collectable.mar22 + allF3Collectable.mar22
]
export const allproWithMar22NonCount = [
    allF1NonCollectable.mar22,
    allF2NonCollectable.mar22,
    allF3NonCollectable.mar22,
    allF1NonCollectable.mar22 + allF2NonCollectable.mar22 + allF3NonCollectable.mar22
]
export const allproWithMar22Claim = [
    allF1ClaimCount.mar22,
    allF2ClaimCount.mar22,
    allF3ClaimCount.mar22,
    allF1ClaimCount.mar22 + allF2ClaimCount.mar22 + allF3ClaimCount.mar22
]
export const allproWithMar22PerVisit = [
    allF1PerVisit.mar22,
    allF2PerVisit.mar22,
    allF3PerVisit.mar22,
    allProviderPervisitRX[14]
]
// april 22
export const allproWithApr22Charges = [
    allF1Charge.apr22,
    allF2Charge.apr22,
    allF3Charge.apr22,
    allF1Charge.apr22 + allF2Charge.apr22 + allF3Charge.apr22
]
export const allproWithApr22Pay = [
    allF1Payment.apr22,
    allF2Payment.apr22,
    allF3Payment.apr22,
    allF1Payment.apr22 + allF2Payment.apr22 + allF3Payment.apr22
]
export const allproWithApr22Count = [
    allF1Collectable.apr22,
    allF2Collectable.apr22,
    allF3Collectable.apr22,
    allF1Collectable.apr22 + allF2Collectable.apr22 + allF3Collectable.apr22
]
export const allproWithApr22NonCount = [
    allF1NonCollectable.apr22,
    allF2NonCollectable.apr22,
    allF3NonCollectable.apr22,
    allF1NonCollectable.apr22 + allF2NonCollectable.apr22 + allF3NonCollectable.apr22
]
export const allproWithApr22Claim = [
    allF1ClaimCount.apr22,
    allF2ClaimCount.apr22,
    allF3ClaimCount.apr22,
    allF1ClaimCount.apr22 + allF2ClaimCount.apr22 + allF3ClaimCount.apr22

]
export const allproWithApr22PerVisit = [
    allF1PerVisit.apr22,
    allF2PerVisit.apr22,
    allF3PerVisit.apr22,
    allProviderPervisitRX[15]
]
// may 22
export const allproWithMay22Charges = [
    allF1Charge.may22,
    allF2Charge.may22,
    allF3Charge.may22,
    allF1Charge.may22 + allF2Charge.may22 + allF3Charge.may22
]
export const allproWithMay22Pay = [
    allF1Payment.may22,
    allF2Payment.may22,
    allF3Payment.may22,
    allF1Payment.may22 + allF2Payment.may22 + allF3Payment.may22
]
export const allproWithMay22Count = [
    allF1Collectable.may22,
    allF2Collectable.may22,
    allF3Collectable.may22,
    allF1Collectable.may22 + allF2Collectable.may22 + allF3Collectable.may22
]
export const allproWithMay22NonCount = [
    allF1NonCollectable.may22,
    allF2NonCollectable.may22,
    allF3NonCollectable.may22,
    allF1NonCollectable.may22 + allF2NonCollectable.may22 + allF3NonCollectable.may22
]
export const allproWithMay22Claim = [
    allF1ClaimCount.may22,
    allF2ClaimCount.may22,
    allF3ClaimCount.may22,
    allF1ClaimCount.may22 + allF2ClaimCount.may22 + allF3ClaimCount.may22
]
export const allproWithMay22PerVisit = [
    allF1PerVisit.may22,
    allF2PerVisit.may22,
    allF3PerVisit.may22,
    allProviderPervisitRX[16]
]
// jun 22
export const allproWithJun22Charges = [
    allF1Charge.jun22,
    allF2Charge.jun22,
    allF3Charge.jun22,
    allF1Charge.jun22 + allF2Charge.jun22 + allF3Charge.jun22
]
export const allproWithJun22Pay = [
    allF1Payment.jun22,
    allF2Payment.jun22,
    allF3Payment.jun22,
    allF1Payment.jun22 + allF2Payment.jun22 + allF3Payment.jun22
]
export const allproWithJun22Count = [
    allF1Collectable.jun22,
    allF2Collectable.jun22,
    allF3Collectable.jun22,
    allF1Collectable.jun22 + allF2Collectable.jun22 + allF3Collectable.jun22
]
export const allproWithJun22NonCount = [
    allF1NonCollectable.jun22,
    allF2NonCollectable.jun22,
    allF3NonCollectable.jun22,
    allF1NonCollectable.jun22 + allF2NonCollectable.jun22 + allF3NonCollectable.jun22
]
export const allproWithJun22Claim = [
    allF1ClaimCount.jun22,
    allF2ClaimCount.jun22,
    allF3ClaimCount.jun22,
    allF1ClaimCount.jun22 + allF2ClaimCount.jun22 + allF3ClaimCount.jun22
]
export const allproWithJun22PerVisit = [
    allF1PerVisit.jun22,
    allF2PerVisit.jun22,
    allF3PerVisit.jun22,
    allProviderPervisitRX[17]
]
// jul 22
export const allproWithJul22Charges = [
    allF1Charge.jul22,
    allF2Charge.jul22,
    allF3Charge.jul22,
    allF1Charge.jul22 + allF2Charge.jul22 + allF3Charge.jul22
]
export const allproWithJul22Pay = [
    allF1Payment.jul22,
    allF2Payment.jul22,
    allF3Payment.jul22,
    allF1Payment.jul22 + allF2Payment.jul22 + allF3Payment.jul22
]
export const allproWithJul22Count = [
    allF1Collectable.jul22,
    allF2Collectable.jul22,
    allF3Collectable.jul22,
    allF1Collectable.jul22 + allF2Collectable.jul22 + allF3Collectable.jul22
]
export const allproWithJul22NonCount = [
    allF1NonCollectable.jul22,
    allF2NonCollectable.jul22,
    allF3NonCollectable.jul22,
    allF1NonCollectable.jul22 + allF2NonCollectable.jul22 + allF3NonCollectable.jul22
]
export const allproWithJul22Claim = [
    allF1ClaimCount.jul22,
    allF2ClaimCount.jul22,
    allF3ClaimCount.jul22,
    allF1ClaimCount.jul22 + allF2ClaimCount.jul22 + allF3ClaimCount.jul22
]
export const allproWithJul22PerVisit = [
    allF1PerVisit.jul22,
    allF2PerVisit.jul22,
    allF3PerVisit.jul22,
    allProviderPervisitRX[18]
]
// aug 22
export const allproWithAug22Charges = [
    allF1Charge.aug22,
    allF2Charge.aug22,
    allF3Charge.aug22,
    allF1Charge.aug22 + allF2Charge.aug22 + allF3Charge.aug22
]
export const allproWithAug22Pay = [
    allF1Payment.aug22,
    allF2Payment.aug22,
    allF3Payment.aug22,
    allF1Payment.aug22 + allF2Payment.aug22 + allF3Payment.aug22
]
export const allproWithAug22Count = [
    allF1Collectable.aug22,
    allF2Collectable.aug22,
    allF3Collectable.aug22,
    allF1Collectable.aug22 + allF2Collectable.aug22 + allF3Collectable.aug22
]
export const allproWithAug22NonCount = [
    allF1NonCollectable.aug22,
    allF2NonCollectable.aug22,
    allF3NonCollectable.aug22,
    allF1NonCollectable.aug22 + allF2NonCollectable.aug22 + allF3NonCollectable.aug22
]
export const allproWithAug22Claim = [
    allF1ClaimCount.aug22,
    allF2ClaimCount.aug22,
    allF3ClaimCount.aug22,
    allF1ClaimCount.aug22 + allF2ClaimCount.aug22 + allF3ClaimCount.aug22
]
export const allproWithAug22PerVisit = [
    allF1PerVisit.aug22,
    allF2PerVisit.aug22,
    allF3PerVisit.aug22,
    allProviderPervisitRX[19]
]
// sep 22
export const allproWithSep22Charges = [
    allF1Charge.sep22,
    allF2Charge.sep22,
    allF3Charge.sep22,
    allF1Charge.sep22 + allF2Charge.sep22 + allF3Charge.sep22
]
export const allproWithSep22Pay = [
    allF1Payment.sep22,
    allF2Payment.sep22,
    allF3Payment.sep22,
    allF1Payment.sep22 + allF2Payment.sep22 + allF3Payment.sep22
]
export const allproWithSep22Count = [
    allF1Collectable.sep22,
    allF2Collectable.sep22,
    allF3Collectable.sep22,
    allF1Collectable.sep22 + allF2Collectable.sep22 + allF3Collectable.sep22
]
export const allproWithSep22NonCount = [
    allF1NonCollectable.sep22,
    allF2NonCollectable.sep22,
    allF3NonCollectable.sep22,
    allF1NonCollectable.sep22 + allF2NonCollectable.sep22 + allF3NonCollectable.sep22
]
export const allproWithSep22Claim = [
    allF1ClaimCount.sep22,
    allF2ClaimCount.sep22,
    allF3ClaimCount.sep22,
    allF1ClaimCount.sep22 + allF2ClaimCount.sep22 + allF3ClaimCount.sep22
]
export const allproWithSep22PerVisit = [
    allF1PerVisit.sep22,
    allF2PerVisit.sep22,
    allF3PerVisit.sep22,
    allProviderPervisitRX[20]
]
// oct 22
export const allproWithOct22Charges = [
    allF1Charge.oct22,
    allF2Charge.oct22,
    allF3Charge.oct22,
    allF1Charge.oct22 + allF2Charge.oct22 + allF3Charge.oct22
]
export const allproWithOct22Pay = [
    allF1Payment.oct22,
    allF2Payment.oct22,
    allF3Payment.oct22,
    allF1Payment.oct22 + allF2Payment.oct22 + allF3Payment.oct22
]
export const allproWithOct22Count = [
    allF1Collectable.oct22,
    allF2Collectable.oct22,
    allF3Collectable.oct22,
    allF1Collectable.oct22 + allF2Collectable.oct22 + allF3Collectable.oct22
]
export const allproWithOct22NonCount = [
    allF1NonCollectable.oct22,
    allF2NonCollectable.oct22,
    allF3NonCollectable.oct22,
    allF1NonCollectable.oct22 + allF2NonCollectable.oct22 + allF3NonCollectable.oct22 
]
export const allproWithOct22Claim = [
    allF1ClaimCount.oct22,
    allF2ClaimCount.oct22,
    allF3ClaimCount.oct22,
    allF1ClaimCount.oct22 + allF2ClaimCount.oct22 + allF3ClaimCount.oct22
]
export const allproWithOct22PerVisit = [
    allF1PerVisit.oct22,
    allF2PerVisit.oct22,
    allF3PerVisit.oct22,
    allProviderPervisitRX[21]
]
// nov 22
export const allproWithNov22Charges = [
    allF1Charge.nov22,
    allF2Charge.nov22,
    allF3Charge.nov22,
    allF1Charge.nov22 + allF2Charge.nov22 + allF3Charge.nov22
]
export const allproWithNov22Pay = [
    allF1Payment.nov22,
    allF2Payment.nov22,
    allF3Payment.nov22,
    allF1Payment.nov22 + allF2Payment.nov22 + allF3Payment.nov22
]
export const allproWithNov22Count = [
    allF1Collectable.nov22,
    allF2Collectable.nov22,
    allF3Collectable.nov22,
    allF1Collectable.nov22 + allF2Collectable.nov22 + allF3Collectable.nov22
]
export const allproWithNov22NonCount = [
    allF1NonCollectable.nov22,
    allF2NonCollectable.nov22,
    allF3NonCollectable.nov22,
    allF1NonCollectable.nov22 + allF2NonCollectable.nov22 + allF3NonCollectable.nov22
]
export const allproWithNov22Claim = [
    allF1ClaimCount.nov22,
    allF2ClaimCount.nov22,
    allF3ClaimCount.nov22,
    allF1ClaimCount.nov22 + allF2ClaimCount.nov22 + allF3ClaimCount.nov22
]
export const allproWithNov22PerVisit = [
    allF1PerVisit.nov22,
    allF2PerVisit.nov22,
    allF3PerVisit.nov22,
    allProviderPervisitRX[22]
]
// dec 22
export const allproWithDec22Charges = [
    allF1Charge.dec22,
    allF2Charge.dec22,
    allF3Charge.dec22,
    allF1Charge.dec22 + allF2Charge.dec22 + allF3Charge.dec22
]
export const allproWithDec22Pay = [
    allF1Payment.dec22,
    allF2Payment.dec22,
    allF3Payment.dec22,
    allF1Payment.dec22 + allF2Payment.dec22 + allF3Payment.dec22
]
export const allproWithDec22Count = [
    allF1Collectable.dec22,
    allF2Collectable.dec22,
    allF3Collectable.dec22,
    allF1Collectable.dec22 + allF2Collectable.dec22 + allF3Collectable.dec22
]
export const allproWithDec22NonCount = [
    allF1NonCollectable.dec22,
    allF2NonCollectable.dec22,
    allF3NonCollectable.dec22,
    allF1NonCollectable.dec22 + allF2NonCollectable.dec22 + allF3NonCollectable.dec22
]
export const allproWithDec22Claim = [
    allF1ClaimCount.dec22,
    allF2ClaimCount.dec22,
    allF3ClaimCount.dec22,
    allF1ClaimCount.dec22 + allF2ClaimCount.dec22 + allF3ClaimCount.dec22
]
export const allproWithDec22PerVisit = [
    allF1PerVisit.dec22,
    allF2PerVisit.dec22,
    allF3PerVisit.dec22,
    allProviderPervisitRX[23]
]
// jan 23
export const allproWithJan23Charges = [
    allF1Charge.jan23,
    allF2Charge.jan23,
    allF3Charge.jan23,
    allF1Charge.jan23 + allF2Charge.jan23 + allF3Charge.jan23
]
export const allproWithJan23Pay = [
    allF1Payment.jan23,
    allF2Payment.jan23,
    allF3Payment.jan23,
    allF1Payment.jan23 + allF2Payment.jan23 + allF3Payment.jan23
]
export const allproWithJan23Count = [
    allF1Collectable.jan23,
    allF2Collectable.jan23,
    allF3Collectable.jan23,
    allF1Collectable.jan23 + allF2Collectable.jan23 + allF3Collectable.jan23
]
export const allproWithJan23NonCount = [
    allF1NonCollectable.jan23,
    allF2NonCollectable.jan23,
    allF3NonCollectable.jan23,
    allF1NonCollectable.jan23 + allF2NonCollectable.jan23 + allF3NonCollectable.jan23
]
export const allproWithJan23Claim = [
    allF1ClaimCount.jan23,
    allF2ClaimCount.jan23,
    allF3ClaimCount.jan23,
    allF1ClaimCount.jan23 + allF2ClaimCount.jan23 + allF3ClaimCount.jan23
]
export const allproWithJan23PerVisit = [
    allF1PerVisit.jan23,
    allF2PerVisit.jan23,
    allF3PerVisit.jan23,
    allProviderPervisitRX[24]
]
// feb 23
export const allproWithFeb23Charges = [
    allF1Charge.feb23,
    allF2Charge.feb23,
    allF3Charge.feb23,
    allF1Charge.feb23 + allF2Charge.feb23 + allF3Charge.feb23
]
export const allproWithFeb23Pay = [
    allF1Payment.feb23,
    allF2Payment.feb23,
    allF3Payment.feb23,
    allF1Payment.feb23 + allF2Payment.feb23 + allF3Payment.feb23
]
export const allproWithFeb23Count = [
    allF1Collectable.feb23,
    allF2Collectable.feb23,
    allF3Collectable.feb23,
    allF1Collectable.feb23 + allF2Collectable.feb23 + allF3Collectable.feb23
]
export const allproWithFeb23NonCount = [
    allF1NonCollectable.feb23,
    allF2NonCollectable.feb23,
    allF3NonCollectable.feb23,
    allF1NonCollectable.feb23 + allF2NonCollectable.feb23 + allF3NonCollectable.feb23
]
export const allproWithFeb23Claim = [
    allF1ClaimCount.feb23,
    allF2ClaimCount.feb23,
    allF3ClaimCount.feb23,
    allF1ClaimCount.feb23 + allF2ClaimCount.feb23 + allF3ClaimCount.feb23
]
export const allproWithFeb23PerVisit = [
    allF1PerVisit.feb23,
    allF2PerVisit.feb23,
    allF3PerVisit.feb23,
    allProviderPervisitRX[25]
]
// mar 23
export const allproWithMar23Charges = [
    allF1Charge.mar23,
    allF2Charge.mar23,
    allF3Charge.mar23,
    allF1Charge.mar23 + allF2Charge.mar23 + allF3Charge.mar23
]
export const allproWithMar23Pay = [
    allF1Payment.mar23,
    allF2Payment.mar23,
    allF3Payment.mar23,
    allF1Payment.mar23 + allF2Payment.mar23 + allF3Payment.mar23
]
export const allproWithMar23Count = [
    allF1Collectable.mar23,
    allF2Collectable.mar23,
    allF3Collectable.mar23,
    allF1Collectable.mar23 + allF2Collectable.mar23 + allF3Collectable.mar23
]
export const allproWithMar23NonCount = [
    allF1NonCollectable.mar23,
    allF2NonCollectable.mar23,
    allF3NonCollectable.mar23,
    allF1NonCollectable.mar23 + allF2NonCollectable.mar23 + allF3NonCollectable.mar23
]
export const allproWithMar23Claim = [
    allF1ClaimCount.mar23,
    allF2ClaimCount.mar23,
    allF3ClaimCount.mar23,
    allF1ClaimCount.mar23 + allF2ClaimCount.mar23 + allF3ClaimCount.mar23
]
export const allproWithMar23PerVisit = [
    allF1PerVisit.mar23,
    allF2PerVisit.mar23,
    allF3PerVisit.mar23,
    allProviderPervisitRX[26]
]
// april 23
export const allproWithApr23Charges = [
    allF1Charge.apr23,
    allF2Charge.apr23,
    allF3Charge.apr23,
    allF1Charge.apr23 + allF2Charge.apr23 + allF3Charge.apr23
]
export const allproWithApr23Pay = [
    allF1Payment.apr23,
    allF2Payment.apr23,
    allF3Payment.apr23,
    allF1Payment.apr23 + allF2Payment.apr23 + allF3Payment.apr23
]
export const allproWithApr23Count = [
    allF1Collectable.apr23,
    allF2Collectable.apr23,
    allF3Collectable.apr23,
    allF1Collectable.apr23 + allF2Collectable.apr23 + allF3Collectable.apr23
]
export const allproWithApr23NonCount = [
    allF1NonCollectable.apr23,
    allF2NonCollectable.apr23,
    allF3NonCollectable.apr23,
    allF1NonCollectable.apr23 + allF2NonCollectable.apr23 + allF3NonCollectable.apr23
]
export const allproWithApr23Claim = [
    allF1ClaimCount.apr23,
    allF2ClaimCount.apr23,
    allF3ClaimCount.apr23,
    allF1ClaimCount.apr23 + allF2ClaimCount.apr23 + allF3ClaimCount.apr23
]
export const allproWithApr23PerVisit = [
    allF1PerVisit.apr23,
    allF2PerVisit.apr23,
    allF3PerVisit.apr23,
    allProviderPervisitRX[27]
]
// may 23
export const allproWithMay23Charges = [
    allF1Charge.may23,
    allF2Charge.may23,
    allF3Charge.may23,
    allF1Charge.may23 + allF2Charge.may23 + allF3Charge.may23
]
export const allproWithMay23Pay = [
    allF1Payment.may23,
    allF2Payment.may23,
    allF3Payment.may23,
    allF1Payment.may23 + allF2Payment.may23 + allF3Payment.may23,
    
]
export const allproWithMay23Count = [
    allF1Collectable.may23,
    allF2Collectable.may23,
    allF3Collectable.may23,
    allF1Collectable.may23 + allF2Collectable.may23 + allF3Collectable.may23
]
export const allproWithMay23NonCount = [
    allF1NonCollectable.may23,
    allF2NonCollectable.may23,
    allF3NonCollectable.may23,
    allF1NonCollectable.may23 + allF2NonCollectable.may23 + allF3NonCollectable.may23
]
export const allproWithMay23Claim = [
    allF1ClaimCount.may23,
    allF2ClaimCount.may23,
    allF3ClaimCount.may23,
    allF1ClaimCount.may23 + allF2ClaimCount.may23 + allF3ClaimCount.may23
]
export const allproWithMay23PerVisit = [
    allF1PerVisit.may23,
    allF2PerVisit.may23,
    allF3PerVisit.may23,
    allProviderPervisitRX[28]
]
// jun 23
export const allproWithJun23Charges = [
    allF1Charge.jun23,
    allF2Charge.jun23,
    allF3Charge.jun23,
    allF1Charge.jun23 + allF2Charge.jun23 + allF3Charge.jun23
]
export const allproWithJun23Pay = [
    allF1Payment.jun23,
    allF2Payment.jun23,
    allF3Payment.jun23,
    allF1Payment.jun23 + allF2Payment.jun23 + allF3Payment.jun23
]
export const allproWithJun23Count = [
    allF1Collectable.jun23,
    allF2Collectable.jun23,
    allF3Collectable.jun23,
    allF1Collectable.jun23 + allF2Collectable.jun23 + allF3Collectable.jun23
]
export const allproWithJun23NonCount = [
    allF1NonCollectable.jun23,
    allF2NonCollectable.jun23,
    allF3NonCollectable.jun23,
    allF1NonCollectable.jun23 + allF2NonCollectable.jun23 + allF3NonCollectable.jun23
]
export const allproWithJun23Claim = [
    allF1ClaimCount.jun23,
    allF2ClaimCount.jun23,
    allF3ClaimCount.jun23,
    allF1ClaimCount.jun23 + allF2ClaimCount.jun23 + allF3ClaimCount.jun23
]
export const allproWithJun23PerVisit = [
    allF1PerVisit.jun23,
    allF2PerVisit.jun23,
    allF3PerVisit.jun23,
    allProviderPervisitRX[29]
]
// jul 23
export const allproWithJul23Charges = [
    allF1Charge.jul23,
    allF2Charge.jul23,
    allF3Charge.jul23,
    allF1Charge.jul23 + allF2Charge.jul23 + allF3Charge.jul23
]
export const allproWithJul23Pay = [
    allF1Payment.jul23,
    allF2Payment.jul23,
    allF3Payment.jul23,
    allF1Payment.jul23 + allF2Payment.jul23 + allF3Payment.jul23
]
export const allproWithJul23Count = [
    allF1Collectable.jul23,
    allF2Collectable.jul23,
    allF3Collectable.jul23,
    allF1Collectable.jul23 + allF2Collectable.jul23 + allF3Collectable.jul23
]
export const allproWithJul23NonCount = [
    allF1NonCollectable.jul23,
    allF2NonCollectable.jul23,
    allF3NonCollectable.jul23,
    allF1NonCollectable.jul23 + allF2NonCollectable.jul23 + allF3NonCollectable.jul23
]
export const allproWithJul23Claim = [
    allF1ClaimCount.jul23,
    allF2ClaimCount.jul23,
    allF3ClaimCount.jul23,
    allF1ClaimCount.jul23 + allF2ClaimCount.jul23 + allF3ClaimCount.jul23
]
export const allproWithJul23PerVisit = [
    allF1PerVisit.jul23,
    allF2PerVisit.jul23,
    allF3PerVisit.jul23,
    allProviderPervisitRX[30]
]
// aug 23
export const allproWithAug23Charges = [
    allF1Charge.aug23,
    allF2Charge.aug23,
    allF3Charge.aug23,
    allF1Charge.aug23 + allF2Charge.aug23 + allF3Charge.aug23
]
export const allproWithAug23Pay = [
    allF1Payment.aug23,
    allF2Payment.aug23,
    allF3Payment.aug23,
    allF1Payment.aug23 + allF2Payment.aug23 + allF3Payment.aug23
]
export const allproWithAug23Count = [
    allF1Collectable.aug23,
    allF2Collectable.aug23,
    allF3Collectable.aug23,
    allF1Collectable.aug23 + allF2Collectable.aug23 + allF3Collectable.aug23
]
export const allproWithAug23NonCount = [
    allF1NonCollectable.aug23,
    allF2NonCollectable.aug23,
    allF3NonCollectable.aug23,
    allF1NonCollectable.aug23 + allF2NonCollectable.aug23 + allF3NonCollectable.aug23
]
export const allproWithAug23Claim = [
    allF1ClaimCount.aug23,
    allF2ClaimCount.aug23,
    allF3ClaimCount.aug23,
    allF1ClaimCount.aug23 + allF2ClaimCount.aug23 + allF3ClaimCount.aug23
]
export const allproWithAug23PerVisit = [
    allF1PerVisit.aug23,
    allF2PerVisit.aug23,
    allF3PerVisit.aug23,
    allProviderPervisitRX[31]
]
// sep 23
export const allproWithSep23Charges = [
    allF1Charge.sep23,
    allF2Charge.sep23,
    allF3Charge.sep23,
    allF1Charge.sep23 + allF2Charge.sep23 + allF3Charge.sep23
]
export const allproWithSep23Pay = [
    allF1Payment.sep23,
    allF2Payment.sep23,
    allF3Payment.sep23,
    allF1Payment.sep23 + allF2Payment.sep23 + allF3Payment.sep23
]
export const allproWithSep23Count = [
    allF1Collectable.sep23,
    allF2Collectable.sep23,
    allF3Collectable.sep23,
    allF1Collectable.sep23 + allF2Collectable.sep23 + allF3Collectable.sep23
]
export const allproWithSep23NonCount = [
    allF1NonCollectable.sep23,
    allF2NonCollectable.sep23,
    allF3NonCollectable.sep23,
    allF1NonCollectable.sep23 + allF2NonCollectable.sep23 + allF3NonCollectable.sep23
]
export const allproWithSep23Claim = [
    allF1ClaimCount.sep23,
    allF2ClaimCount.sep23,
    allF3ClaimCount.sep23,
    allF1ClaimCount.sep23 + allF2ClaimCount.sep23 + allF3ClaimCount.sep23
]
export const allproWithSep23PerVisit = [
    allF1PerVisit.sep23,
    allF2PerVisit.sep23,
    allF3PerVisit.sep23,
    allProviderPervisitRX[32]
]
// oct 23
export const allproWithOct23Charges = [
    allF1Charge.oct23,
    allF2Charge.oct23,
    allF3Charge.oct23,
    allF1Charge.oct23 + allF2Charge.oct23 + allF3Charge.oct23
]
export const allproWithOct23Pay = [
    allF1Payment.oct23,
    allF2Payment.oct23,
    allF3Payment.oct23,
    allF1Payment.oct23 + allF2Payment.oct23 + allF3Payment.oct23
]
export const allproWithOct23Count = [
    allF1Collectable.oct23,
    allF2Collectable.oct23,
    allF3Collectable.oct23,
    allF1Collectable.oct23 + allF2Collectable.oct23 + allF3Collectable.oct23
]
export const allproWithOct23NonCount = [
    allF1NonCollectable.oct23,
    allF2NonCollectable.oct23,
    allF3NonCollectable.oct23,
    allF1NonCollectable.oct23 + allF2NonCollectable.oct23 + allF3NonCollectable.oct23
]
export const allproWithOct23Claim = [
    allF1ClaimCount.oct23,
    allF2ClaimCount.oct23,
    allF3ClaimCount.oct23,
    allF1ClaimCount.oct23 + allF2ClaimCount.oct23 + allF3ClaimCount.oct23
]
export const allproWithOct23PerVisit = [
    allF1PerVisit.oct23,
    allF2PerVisit.oct23,
    allF3PerVisit.oct23,
    allProviderPervisitRX[33]
]
// nov 23
export const allproWithNov23Charges = [
    allF1Charge.nov23,
    allF2Charge.nov23,
    allF3Charge.nov23,
    allF1Charge.nov23 + allF2Charge.nov23 + allF3Charge.nov23
]
export const allproWithNov23Pay = [
    allF1Payment.nov23,
    allF2Payment.nov23,
    allF3Payment.nov23,
    allF1Payment.nov23 + allF2Payment.nov23 + allF3Payment.nov23
]
export const allproWithNov23Count = [
    allF1Collectable.nov23,
    allF2Collectable.nov23,
    allF3Collectable.nov23,
    allF1Collectable.nov23 + allF2Collectable.nov23 + allF3Collectable.nov23
]
export const allproWithNov23NonCount = [
    allF1NonCollectable.nov23,
    allF2NonCollectable.nov23,
    allF3NonCollectable.nov23,
    allF1NonCollectable.nov23 + allF2NonCollectable.nov23 + allF3NonCollectable.nov23
]
export const allproWithNov23Claim = [
    allF1ClaimCount.nov23,
    allF2ClaimCount.nov23,
    allF3ClaimCount.nov23,
    allF1ClaimCount.nov23 + allF2ClaimCount.nov23 + allF3ClaimCount.nov23
]
export const allproWithNov23PerVisit = [
    allF1PerVisit.nov23,
    allF2PerVisit.nov23,
    allF3PerVisit.nov23,
    allProviderPervisitRX[34]
]

// provider-1 
// jan 21
// export const pro1WithJan21Charges = [
//     pro1F1Charge.jan21,
//     pro1F2Charge.jan21,
//     pro1F3Charge.jan21
// ]
// export const pro1WithJan21Pay = [
//     pro1F1Payment.jan21,
//     pro1F2Payment.jan21,
//     pro1F3Payment.jan21
// ]
// export const pro1WithJan21Count = [
//     pro1F1Collectable.jan21,
//     pro1F2Collectable.jan21,
//     pro1F3Collectable.jan21
// ]
// export const pro1WithJan21NonCount = [
//     pro1F1NonCollectable.jan21,
//     pro1F2NonCollectable.jan21,
//     pro1F3NonCollectable.jan21
// ]
// export const pro1WithJan21Claim = [
//     pro1F1ClaimCount.jan21,
//     pro1F2ClaimCount.jan21,
//     pro1F3ClaimCount.jan21
// ]
// export const pro1WithJan21PerVisit = [
//     pro1F1PerVisit.jan21,
//     pro1F2PerVisit.jan21,
//     pro1F3PerVisit.jan21
// ]
// // feb 21
// export const pro1WithFeb21Charges = [
//     pro1F1Charge.feb21,
//     pro1F2Charge.feb21,
//     pro1F3Charge.feb21
// ]
// export const pro1WithFeb21Pay = [
//     pro1F1Payment.feb21,
//     pro1F2Payment.feb21,
//     pro1F3Payment.feb21
// ]
// export const pro1WithFeb21Count = [
//     pro1F1Collectable.feb21,
//     pro1F2Collectable.feb21,
//     pro1F3Collectable.feb21
// ]
// export const pro1WithFeb21NonCount = [
//     pro1F1NonCollectable.feb21,
//     pro1F2NonCollectable.feb21,
//     pro1F3NonCollectable.feb21
// ]
// export const pro1WithFeb21Claim = [
//     pro1F1ClaimCount.feb21,
//     pro1F2ClaimCount.feb21,
//     pro1F3ClaimCount.feb21
// ]
// export const pro1WithFeb21PerVisit = [
//     pro1F1PerVisit.feb21,
//     pro1F2PerVisit.feb21,
//     pro1F3PerVisit.feb21
// ]
// // mar 21
// export const pro1WithMar21Charges = [
//     pro1F1Charge.mar21,
//     pro1F2Charge.mar21,
//     pro1F3Charge.mar21
// ]
// export const pro1WithMar21Pay = [
//     pro1F1Payment.mar21,
//     pro1F2Payment.mar21,
//     pro1F3Payment.mar21
// ]
// export const pro1WithMar21Count = [
//     pro1F1Collectable.mar21,
//     pro1F2Collectable.mar21,
//     pro1F3Collectable.mar21
// ]
// export const pro1WithMar21NonCount = [
//     pro1F1NonCollectable.mar21,
//     pro1F2NonCollectable.mar21,
//     pro1F3NonCollectable.mar21
// ]
// export const pro1WithMar21Claim = [
//     pro1F1ClaimCount.mar21,
//     pro1F2ClaimCount.mar21,
//     pro1F3ClaimCount.mar21
// ]
// export const pro1WithMar21PerVisit = [
//     pro1F1PerVisit.mar21,
//     pro1F2PerVisit.mar21,
//     pro1F3PerVisit.mar21
// ]
// // april 21
// export const pro1WithApr21Charges = [
//     pro1F1Charge.apr21,
//     pro1F2Charge.apr21,
//     pro1F3Charge.apr21
// ]
// export const pro1WithApr21Pay = [
//     pro1F1Payment.apr21,
//     pro1F2Payment.apr21,
//     pro1F3Payment.apr21
// ]
// export const pro1WithApr21Count = [
//     pro1F1Collectable.apr21,
//     pro1F2Collectable.apr21,
//     pro1F3Collectable.apr21
// ]
// export const pro1WithApr21NonCount = [
//     pro1F1NonCollectable.apr21,
//     pro1F2NonCollectable.apr21,
//     pro1F3NonCollectable.apr21
// ]
// export const pro1WithApr21Claim = [
//     pro1F1ClaimCount.apr21,
//     pro1F2ClaimCount.apr21,
//     pro1F3ClaimCount.apr21
// ]
// export const pro1WithApr21PerVisit = [
//     pro1F1PerVisit.apr21,
//     pro1F2PerVisit.apr21,
//     pro1F3PerVisit.apr21
// ]
// // may 21
// export const pro1WithMay21Charges = [
//     pro1F1Charge.may21,
//     pro1F2Charge.may21,
//     pro1F3Charge.may21
// ]
// export const pro1WithMay21Pay = [
//     pro1F1Payment.may21,
//     pro1F2Payment.may21,
//     pro1F3Payment.may21
// ]
// export const pro1WithMay21Count = [
//     pro1F1Collectable.may21,
//     pro1F2Collectable.may21,
//     pro1F3Collectable.may21
// ]
// export const pro1WithMay21NonCount = [
//     pro1F1NonCollectable.may21,
//     pro1F2NonCollectable.may21,
//     pro1F3NonCollectable.may21
// ]
// export const pro1WithMay21Claim = [
//     pro1F1ClaimCount.may21,
//     pro1F2ClaimCount.may21,
//     pro1F3ClaimCount.may21
// ]
// export const pro1WithMay21PerVisit = [
//     pro1F1PerVisit.may21,
//     pro1F2PerVisit.may21,
//     pro1F3PerVisit.may21
// ]
// // jun 21
// export const pro1WithJun21Charges = [
//     pro1F1Charge.jun21,
//     pro1F2Charge.jun21,
//     pro1F3Charge.jun21
// ]
// export const pro1WithJun21Pay = [
//     pro1F1Payment.jun21,
//     pro1F2Payment.jun21,
//     pro1F3Payment.jun21
// ]
// export const pro1WithJun21Count = [
//     pro1F1Collectable.jun21,
//     pro1F2Collectable.jun21,
//     pro1F3Collectable.jun21
// ]
// export const pro1WithJun21NonCount = [
//     pro1F1NonCollectable.jun21,
//     pro1F2NonCollectable.jun21,
//     pro1F3NonCollectable.jun21
// ]
// export const pro1WithJun21Claim = [
//     pro1F1ClaimCount.jun21,
//     pro1F2ClaimCount.jun21,
//     pro1F3ClaimCount.jun21
// ]
// export const pro1WithJun21PerVisit = [
//     pro1F1PerVisit.jun21,
//     pro1F2PerVisit.jun21,
//     pro1F3PerVisit.jun21
// ]
// // jul 21
// export const pro1WithJul21Charges = [
//     pro1F1Charge.jul21,
//     pro1F2Charge.jul21,
//     pro1F3Charge.jul21
// ]
// export const pro1WithJul21Pay = [
//     pro1F1Payment.jul21,
//     pro1F2Payment.jul21,
//     pro1F3Payment.jul21
// ]
// export const pro1WithJul21Count = [
//     pro1F1Collectable.jul21,
//     pro1F2Collectable.jul21,
//     pro1F3Collectable.jul21
// ]
// export const pro1WithJul21NonCount = [
//     pro1F1NonCollectable.jul21,
//     pro1F2NonCollectable.jul21,
//     pro1F3NonCollectable.jul21
// ]
// export const pro1WithJul21Claim = [
//     pro1F1ClaimCount.jul21,
//     pro1F2ClaimCount.jul21,
//     pro1F3ClaimCount.jul21
// ]
// export const pro1WithJul21PerVisit = [
//     pro1F1PerVisit.jul21,
//     pro1F2PerVisit.jul21,
//     pro1F3PerVisit.jul21
// ]
// // aug 21
// export const pro1WithAug21Charges = [
//     pro1F1Charge.aug21,
//     pro1F2Charge.aug21,
//     pro1F3Charge.aug21
// ]
// export const pro1WithAug21Pay = [
//     pro1F1Payment.aug21,
//     pro1F2Payment.aug21,
//     pro1F3Payment.aug21
// ]
// export const pro1WithAug21Count = [
//     pro1F1Collectable.aug21,
//     pro1F2Collectable.aug21,
//     pro1F3Collectable.aug21
// ]
// export const pro1WithAug21NonCount = [
//     pro1F1NonCollectable.aug21,
//     pro1F2NonCollectable.aug21,
//     pro1F3NonCollectable.aug21
// ]
// export const pro1WithAug21Claim = [
//     pro1F1ClaimCount.aug21,
//     pro1F2ClaimCount.aug21,
//     pro1F3ClaimCount.aug21
// ]
// export const pro1WithAug21PerVisit = [
//     pro1F1PerVisit.aug21,
//     pro1F2PerVisit.aug21,
//     pro1F3PerVisit.aug21
// ]
// // sep 21
// export const pro1WithSep21Charges = [
//     pro1F1Charge.sep21,
//     pro1F2Charge.sep21,
//     pro1F3Charge.sep21
// ]
// export const pro1WithSep21Pay = [
//     pro1F1Payment.sep21,
//     pro1F2Payment.sep21,
//     pro1F3Payment.sep21
// ]
// export const pro1WithSep21Count = [
//     pro1F1Collectable.sep21,
//     pro1F2Collectable.sep21,
//     pro1F3Collectable.sep21
// ]
// export const pro1WithSep21NonCount = [
//     pro1F1NonCollectable.sep21,
//     pro1F2NonCollectable.sep21,
//     pro1F3NonCollectable.sep21
// ]
// export const pro1WithSep21Claim = [
//     pro1F1ClaimCount.sep21,
//     pro1F2ClaimCount.sep21,
//     pro1F3ClaimCount.sep21
// ]
// export const pro1WithSep21PerVisit = [
//     pro1F1PerVisit.sep21,
//     pro1F2PerVisit.sep21,
//     pro1F3PerVisit.sep21
// ]
// // oct 21
// export const pro1WithOct21Charges = [
//     pro1F1Charge.oct21,
//     pro1F2Charge.oct21,
//     pro1F3Charge.oct21
// ]
// export const pro1WithOct21Pay = [
//     pro1F1Payment.oct21,
//     pro1F2Payment.oct21,
//     pro1F3Payment.oct21
// ]
// export const pro1WithOct21Count = [
//     pro1F1Collectable.oct21,
//     pro1F2Collectable.oct21,
//     pro1F3Collectable.oct21
// ]
// export const pro1WithOct21NonCount = [
//     pro1F1NonCollectable.oct21,
//     pro1F2NonCollectable.oct21,
//     pro1F3NonCollectable.oct21
// ]
// export const pro1WithOct21Claim = [
//     pro1F1ClaimCount.oct21,
//     pro1F2ClaimCount.oct21,
//     pro1F3ClaimCount.oct21
// ]
// export const pro1WithOct21PerVisit = [
//     pro1F1PerVisit.oct21,
//     pro1F2PerVisit.oct21,
//     pro1F3PerVisit.oct21
// ]
// // nov 21
// export const pro1WithNov21Charges = [
//     pro1F1Charge.nov21,
//     pro1F2Charge.nov21,
//     pro1F3Charge.nov21
// ]
// export const pro1WithNov21Pay = [
//     pro1F1Payment.nov21,
//     pro1F2Payment.nov21,
//     pro1F3Payment.nov21
// ]
// export const pro1WithNov21Count = [
//     pro1F1Collectable.nov21,
//     pro1F2Collectable.nov21,
//     pro1F3Collectable.nov21
// ]
// export const pro1WithNov21NonCount = [
//     pro1F1NonCollectable.nov21,
//     pro1F2NonCollectable.nov21,
//     pro1F3NonCollectable.nov21
// ]
// export const pro1WithNov21Claim = [
//     pro1F1ClaimCount.nov21,
//     pro1F2ClaimCount.nov21,
//     pro1F3ClaimCount.nov21
// ]
// export const pro1WithNov21PerVisit = [
//     pro1F1PerVisit.nov21,
//     pro1F2PerVisit.nov21,
//     pro1F3PerVisit.nov21
// ]
// // dec 21
// export const pro1WithDec21Charges = [
//     pro1F1Charge.dec21,
//     pro1F2Charge.dec21,
//     pro1F3Charge.dec21
// ]
// export const pro1WithDec21Pay = [
//     pro1F1Payment.dec21,
//     pro1F2Payment.dec21,
//     pro1F3Payment.dec21
// ]
// export const pro1WithDec21Count = [
//     pro1F1Collectable.dec21,
//     pro1F2Collectable.dec21,
//     pro1F3Collectable.dec21
// ]
// export const pro1WithDec21NonCount = [
//     pro1F1NonCollectable.dec21,
//     pro1F2NonCollectable.dec21,
//     pro1F3NonCollectable.dec21
// ]
// export const pro1WithDec21Claim = [
//     pro1F1ClaimCount.dec21,
//     pro1F2ClaimCount.dec21,
//     pro1F3ClaimCount.dec21
// ]
// export const pro1WithDec21PerVisit = [
//     pro1F1PerVisit.dec21,
//     pro1F2PerVisit.dec21,
//     pro1F3PerVisit.dec21
// ]

// // provider-1 
// // jan 22
// export const pro1WithJan22Charges = [
//     pro1F1Charge.jan22,
//     pro1F2Charge.jan22,
//     pro1F3Charge.jan22
// ]
// export const pro1WithJan22Pay = [
//     pro1F1Payment.jan22,
//     pro1F2Payment.jan22,
//     pro1F3Payment.jan22
// ]
// export const pro1WithJan22Count = [
//     pro1F1Collectable.jan22,
//     pro1F2Collectable.jan22,
//     pro1F3Collectable.jan22
// ]
// export const pro1WithJan22NonCount = [
//     pro1F1NonCollectable.jan22,
//     pro1F2NonCollectable.jan22,
//     pro1F3NonCollectable.jan22
// ]
// export const pro1WithJan22Claim = [
//     pro1F1ClaimCount.jan22,
//     pro1F2ClaimCount.jan22,
//     pro1F3ClaimCount.jan22
// ]
// export const pro1WithJan22PerVisit = [
//     pro1F1PerVisit.jan22,
//     pro1F2PerVisit.jan22,
//     pro1F3PerVisit.jan22
// ]
// // feb 22
// export const pro1WithFeb22Charges = [
//     pro1F1Charge.feb22,
//     pro1F2Charge.feb22,
//     pro1F3Charge.feb22
// ]
// export const pro1WithFeb22Pay = [
//     pro1F1Payment.feb22,
//     pro1F2Payment.feb22,
//     pro1F3Payment.feb22
// ]
// export const pro1WithFeb22Count = [
//     pro1F1Collectable.feb22,
//     pro1F2Collectable.feb22,
//     pro1F3Collectable.feb22
// ]
// export const pro1WithFeb22NonCount = [
//     pro1F1NonCollectable.feb22,
//     pro1F2NonCollectable.feb22,
//     pro1F3NonCollectable.feb22
// ]
// export const pro1WithFeb22Claim = [
//     pro1F1ClaimCount.feb22,
//     pro1F2ClaimCount.feb22,
//     pro1F3ClaimCount.feb22
// ]
// export const pro1WithFeb22PerVisit = [
//     pro1F1PerVisit.feb22,
//     pro1F2PerVisit.feb22,
//     pro1F3PerVisit.feb22
// ]
// // mar 22
// export const pro1WithMar22Charges = [
//     pro1F1Charge.mar22,
//     pro1F2Charge.mar22,
//     pro1F3Charge.mar22
// ]
// export const pro1WithMar22Pay = [
//     pro1F1Payment.mar22,
//     pro1F2Payment.mar22,
//     pro1F3Payment.mar22
// ]
// export const pro1WithMar22Count = [
//     pro1F1Collectable.mar22,
//     pro1F2Collectable.mar22,
//     pro1F3Collectable.mar22
// ]
// export const pro1WithMar22NonCount = [
//     pro1F1NonCollectable.mar22,
//     pro1F2NonCollectable.mar22,
//     pro1F3NonCollectable.mar22
// ]
// export const pro1WithMar22Claim = [
//     pro1F1ClaimCount.mar22,
//     pro1F2ClaimCount.mar22,
//     pro1F3ClaimCount.mar22
// ]
// export const pro1WithMar22PerVisit = [
//     pro1F1PerVisit.mar22,
//     pro1F2PerVisit.mar22,
//     pro1F3PerVisit.mar22
// ]
// // april 22
// export const pro1WithApr22Charges = [
//     pro1F1Charge.apr22,
//     pro1F2Charge.apr22,
//     pro1F3Charge.apr22
// ]
// export const pro1WithApr22Pay = [
//     pro1F1Payment.apr22,
//     pro1F2Payment.apr22,
//     pro1F3Payment.apr22
// ]
// export const pro1WithApr22Count = [
//     pro1F1Collectable.apr22,
//     pro1F2Collectable.apr22,
//     pro1F3Collectable.apr22
// ]
// export const pro1WithApr22NonCount = [
//     pro1F1NonCollectable.apr22,
//     pro1F2NonCollectable.apr22,
//     pro1F3NonCollectable.apr22
// ]
// export const pro1WithApr22Claim = [
//     pro1F1ClaimCount.apr22,
//     pro1F2ClaimCount.apr22,
//     pro1F3ClaimCount.apr22
// ]
// export const pro1WithApr22PerVisit = [
//     pro1F1PerVisit.apr22,
//     pro1F2PerVisit.apr22,
//     pro1F3PerVisit.apr22
// ]
// // may 22
// export const pro1WithMay22Charges = [
//     pro1F1Charge.may22,
//     pro1F2Charge.may22,
//     pro1F3Charge.may22
// ]
// export const pro1WithMay22Pay = [
//     pro1F1Payment.may22,
//     pro1F2Payment.may22,
//     pro1F3Payment.may22
// ]
// export const pro1WithMay22Count = [
//     pro1F1Collectable.may22,
//     pro1F2Collectable.may22,
//     pro1F3Collectable.may22
// ]
// export const pro1WithMay22NonCount = [
//     pro1F1NonCollectable.may22,
//     pro1F2NonCollectable.may22,
//     pro1F3NonCollectable.may22
// ]
// export const pro1WithMay22Claim = [
//     pro1F1ClaimCount.may22,
//     pro1F2ClaimCount.may22,
//     pro1F3ClaimCount.may22
// ]
// export const pro1WithMay22PerVisit = [
//     pro1F1PerVisit.may22,
//     pro1F2PerVisit.may22,
//     pro1F3PerVisit.may22
// ]
// // jun 22
// export const pro1WithJun22Charges = [
//     pro1F1Charge.jun22,
//     pro1F2Charge.jun22,
//     pro1F3Charge.jun22
// ]
// export const pro1WithJun22Pay = [
//     pro1F1Payment.jun22,
//     pro1F2Payment.jun22,
//     pro1F3Payment.jun22
// ]
// export const pro1WithJun22Count = [
//     pro1F1Collectable.jun22,
//     pro1F2Collectable.jun22,
//     pro1F3Collectable.jun22
// ]
// export const pro1WithJun22NonCount = [
//     pro1F1NonCollectable.jun22,
//     pro1F2NonCollectable.jun22,
//     pro1F3NonCollectable.jun22
// ]
// export const pro1WithJun22Claim = [
//     pro1F1ClaimCount.jun22,
//     pro1F2ClaimCount.jun22,
//     pro1F3ClaimCount.jun22
// ]
// export const pro1WithJun22PerVisit = [
//     pro1F1PerVisit.jun22,
//     pro1F2PerVisit.jun22,
//     pro1F3PerVisit.jun22
// ]
// // jul 22
// export const pro1WithJul22Charges = [
//     pro1F1Charge.jul22,
//     pro1F2Charge.jul22,
//     pro1F3Charge.jul22
// ]
// export const pro1WithJul22Pay = [
//     pro1F1Payment.jul22,
//     pro1F2Payment.jul22,
//     pro1F3Payment.jul22
// ]
// export const pro1WithJul22Count = [
//     pro1F1Collectable.jul22,
//     pro1F2Collectable.jul22,
//     pro1F3Collectable.jul22
// ]
// export const pro1WithJul22NonCount = [
//     pro1F1NonCollectable.jul22,
//     pro1F2NonCollectable.jul22,
//     pro1F3NonCollectable.jul22
// ]
// export const pro1WithJul22Claim = [
//     pro1F1ClaimCount.jul22,
//     pro1F2ClaimCount.jul22,
//     pro1F3ClaimCount.jul22
// ]
// export const pro1WithJul22PerVisit = [
//     pro1F1PerVisit.jul22,
//     pro1F2PerVisit.jul22,
//     pro1F3PerVisit.jul22
// ]
// // aug 22
// export const pro1WithAug22Charges = [
//     pro1F1Charge.aug22,
//     pro1F2Charge.aug22,
//     pro1F3Charge.aug22
// ]
// export const pro1WithAug22Pay = [
//     pro1F1Payment.aug22,
//     pro1F2Payment.aug22,
//     pro1F3Payment.aug22
// ]
// export const pro1WithAug22Count = [
//     pro1F1Collectable.aug22,
//     pro1F2Collectable.aug22,
//     pro1F3Collectable.aug22
// ]
// export const pro1WithAug22NonCount = [
//     pro1F1NonCollectable.aug22,
//     pro1F2NonCollectable.aug22,
//     pro1F3NonCollectable.aug22
// ]
// export const pro1WithAug22Claim = [
//     pro1F1ClaimCount.aug22,
//     pro1F2ClaimCount.aug22,
//     pro1F3ClaimCount.aug22
// ]
// export const pro1WithAug22PerVisit = [
//     pro1F1PerVisit.aug22,
//     pro1F2PerVisit.aug22,
//     pro1F3PerVisit.aug22
// ]
// // sep 22
// export const pro1WithSep22Charges = [
//     pro1F1Charge.sep22,
//     pro1F2Charge.sep22,
//     pro1F3Charge.sep22
// ]
// export const pro1WithSep22Pay = [
//     pro1F1Payment.sep22,
//     pro1F2Payment.sep22,
//     pro1F3Payment.sep22
// ]
// export const pro1WithSep22Count = [
//     pro1F1Collectable.sep22,
//     pro1F2Collectable.sep22,
//     pro1F3Collectable.sep22
// ]
// export const pro1WithSep22NonCount = [
//     pro1F1NonCollectable.sep22,
//     pro1F2NonCollectable.sep22,
//     pro1F3NonCollectable.sep22
// ]
// export const pro1WithSep22Claim = [
//     pro1F1ClaimCount.sep22,
//     pro1F2ClaimCount.sep22,
//     pro1F3ClaimCount.sep22
// ]
// export const pro1WithSep22PerVisit = [
//     pro1F1PerVisit.sep22,
//     pro1F2PerVisit.sep22,
//     pro1F3PerVisit.sep22
// ]
// // oct 22
// export const pro1WithOct22Charges = [
//     pro1F1Charge.oct22,
//     pro1F2Charge.oct22,
//     pro1F3Charge.oct22
// ]
// export const pro1WithOct22Pay = [
//     pro1F1Payment.oct22,
//     pro1F2Payment.oct22,
//     pro1F3Payment.oct22
// ]
// export const pro1WithOct22Count = [
//     pro1F1Collectable.oct22,
//     pro1F2Collectable.oct22,
//     pro1F3Collectable.oct22
// ]
// export const pro1WithOct22NonCount = [
//     pro1F1NonCollectable.oct22,
//     pro1F2NonCollectable.oct22,
//     pro1F3NonCollectable.oct22
// ]
// export const pro1WithOct22Claim = [
//     pro1F1ClaimCount.oct22,
//     pro1F2ClaimCount.oct22,
//     pro1F3ClaimCount.oct22
// ]
// export const pro1WithOct22PerVisit = [
//     pro1F1PerVisit.oct22,
//     pro1F2PerVisit.oct22,
//     pro1F3PerVisit.oct22
// ]
// // nov 22
// export const pro1WithNov22Charges = [
//     pro1F1Charge.nov22,
//     pro1F2Charge.nov22,
//     pro1F3Charge.nov22
// ]
// export const pro1WithNov22Pay = [
//     pro1F1Payment.nov22,
//     pro1F2Payment.nov22,
//     pro1F3Payment.nov22
// ]
// export const pro1WithNov22Count = [
//     pro1F1Collectable.nov22,
//     pro1F2Collectable.nov22,
//     pro1F3Collectable.nov22
// ]
// export const pro1WithNov22NonCount = [
//     pro1F1NonCollectable.nov22,
//     pro1F2NonCollectable.nov22,
//     pro1F3NonCollectable.nov22
// ]
// export const pro1WithNov22Claim = [
//     pro1F1ClaimCount.nov22,
//     pro1F2ClaimCount.nov22,
//     pro1F3ClaimCount.nov22
// ]
// export const pro1WithNov22PerVisit = [
//     pro1F1PerVisit.nov22,
//     pro1F2PerVisit.nov22,
//     pro1F3PerVisit.nov22
// ]
// // dec 22
// export const pro1WithDec22Charges = [
//     pro1F1Charge.dec22,
//     pro1F2Charge.dec22,
//     pro1F3Charge.dec22
// ]
// export const pro1WithDec22Pay = [
//     pro1F1Payment.dec22,
//     pro1F2Payment.dec22,
//     pro1F3Payment.dec22
// ]
// export const pro1WithDec22Count = [
//     pro1F1Collectable.dec22,
//     pro1F2Collectable.dec22,
//     pro1F3Collectable.dec22
// ]
// export const pro1WithDec22NonCount = [
//     pro1F1NonCollectable.dec22,
//     pro1F2NonCollectable.dec22,
//     pro1F3NonCollectable.dec22
// ]
// export const pro1WithDec22Claim = [
//     pro1F1ClaimCount.dec22,
//     pro1F2ClaimCount.dec22,
//     pro1F3ClaimCount.dec22
// ]
// export const pro1WithDec22PerVisit = [
//     pro1F1PerVisit.dec22,
//     pro1F2PerVisit.dec22,
//     pro1F3PerVisit.dec22
// ]

// // provider-1 
// // jan 23
// export const pro1WithJan23Charges = [
//     pro1F1Charge.jan23,
//     pro1F2Charge.jan23,
//     pro1F3Charge.jan23
// ]
// export const pro1WithJan23Pay = [
//     pro1F1Payment.jan23,
//     pro1F2Payment.jan23,
//     pro1F3Payment.jan23
// ]
// export const pro1WithJan23Count = [
//     pro1F1Collectable.jan23,
//     pro1F2Collectable.jan23,
//     pro1F3Collectable.jan23
// ]
// export const pro1WithJan23NonCount = [
//     pro1F1NonCollectable.jan23,
//     pro1F2NonCollectable.jan23,
//     pro1F3NonCollectable.jan23
// ]
// export const pro1WithJan23Claim = [
//     pro1F1ClaimCount.jan23,
//     pro1F2ClaimCount.jan23,
//     pro1F3ClaimCount.jan23
// ]
// export const pro1WithJan23PerVisit = [
//     pro1F1PerVisit.jan23,
//     pro1F2PerVisit.jan23,
//     pro1F3PerVisit.jan23
// ]
// // feb 23
// export const pro1WithFeb23Charges = [
//     pro1F1Charge.feb23,
//     pro1F2Charge.feb23,
//     pro1F3Charge.feb23
// ]
// export const pro1WithFeb23Pay = [
//     pro1F1Payment.feb23,
//     pro1F2Payment.feb23,
//     pro1F3Payment.feb23
// ]
// export const pro1WithFeb23Count = [
//     pro1F1Collectable.feb23,
//     pro1F2Collectable.feb23,
//     pro1F3Collectable.feb23
// ]
// export const pro1WithFeb23NonCount = [
//     pro1F1NonCollectable.feb23,
//     pro1F2NonCollectable.feb23,
//     pro1F3NonCollectable.feb23
// ]
// export const pro1WithFeb23Claim = [
//     pro1F1ClaimCount.feb23,
//     pro1F2ClaimCount.feb23,
//     pro1F3ClaimCount.feb23
// ]
// export const pro1WithFeb23PerVisit = [
//     pro1F1PerVisit.feb23,
//     pro1F2PerVisit.feb23,
//     pro1F3PerVisit.feb23
// ]
// // mar 23
// export const pro1WithMar23Charges = [
//     pro1F1Charge.mar23,
//     pro1F2Charge.mar23,
//     pro1F3Charge.mar23
// ]
// export const pro1WithMar23Pay = [
//     pro1F1Payment.mar23,
//     pro1F2Payment.mar23,
//     pro1F3Payment.mar23
// ]
// export const pro1WithMar23Count = [
//     pro1F1Collectable.mar23,
//     pro1F2Collectable.mar23,
//     pro1F3Collectable.mar23
// ]
// export const pro1WithMar23NonCount = [
//     pro1F1NonCollectable.mar23,
//     pro1F2NonCollectable.mar23,
//     pro1F3NonCollectable.mar23
// ]
// export const pro1WithMar23Claim = [
//     pro1F1ClaimCount.mar23,
//     pro1F2ClaimCount.mar23,
//     pro1F3ClaimCount.mar23
// ]
// export const pro1WithMar23PerVisit = [
//     pro1F1PerVisit.mar23,
//     pro1F2PerVisit.mar23,
//     pro1F3PerVisit.mar23
// ]
// // april 23
// export const pro1WithApr23Charges = [
//     pro1F1Charge.apr23,
//     pro1F2Charge.apr23,
//     pro1F3Charge.apr23
// ]
// export const pro1WithApr23Pay = [
//     pro1F1Payment.apr23,
//     pro1F2Payment.apr23,
//     pro1F3Payment.apr23
// ]
// export const pro1WithApr23Count = [
//     pro1F1Collectable.apr23,
//     pro1F2Collectable.apr23,
//     pro1F3Collectable.apr23
// ]
// export const pro1WithApr23NonCount = [
//     pro1F1NonCollectable.apr23,
//     pro1F2NonCollectable.apr23,
//     pro1F3NonCollectable.apr23
// ]
// export const pro1WithApr23Claim = [
//     pro1F1ClaimCount.apr23,
//     pro1F2ClaimCount.apr23,
//     pro1F3ClaimCount.apr23
// ]
// export const pro1WithApr23PerVisit = [
//     pro1F1PerVisit.apr23,
//     pro1F2PerVisit.apr23,
//     pro1F3PerVisit.apr23
// ]
// // may 23
// export const pro1WithMay23Charges = [
//     pro1F1Charge.may23,
//     pro1F2Charge.may23,
//     pro1F3Charge.may23
// ]
// export const pro1WithMay23Pay = [
//     pro1F1Payment.may23,
//     pro1F2Payment.may23,
//     pro1F3Payment.may23
// ]
// export const pro1WithMay23Count = [
//     pro1F1Collectable.may23,
//     pro1F2Collectable.may23,
//     pro1F3Collectable.may23
// ]
// export const pro1WithMay23NonCount = [
//     pro1F1NonCollectable.may23,
//     pro1F2NonCollectable.may23,
//     pro1F3NonCollectable.may23
// ]
// export const pro1WithMay23Claim = [
//     pro1F1ClaimCount.may23,
//     pro1F2ClaimCount.may23,
//     pro1F3ClaimCount.may23
// ]
// export const pro1WithMay23PerVisit = [
//     pro1F1PerVisit.may23,
//     pro1F2PerVisit.may23,
//     pro1F3PerVisit.may23
// ]
// // jun 23
// export const pro1WithJun23Charges = [
//     pro1F1Charge.jun23,
//     pro1F2Charge.jun23,
//     pro1F3Charge.jun23
// ]
// export const pro1WithJun23Pay = [
//     pro1F1Payment.jun23,
//     pro1F2Payment.jun23,
//     pro1F3Payment.jun23
// ]
// export const pro1WithJun23Count = [
//     pro1F1Collectable.jun23,
//     pro1F2Collectable.jun23,
//     pro1F3Collectable.jun23
// ]
// export const pro1WithJun23NonCount = [
//     pro1F1NonCollectable.jun23,
//     pro1F2NonCollectable.jun23,
//     pro1F3NonCollectable.jun23
// ]
// export const pro1WithJun23Claim = [
//     pro1F1ClaimCount.jun23,
//     pro1F2ClaimCount.jun23,
//     pro1F3ClaimCount.jun23
// ]
// export const pro1WithJun23PerVisit = [
//     pro1F1PerVisit.jun23,
//     pro1F2PerVisit.jun23,
//     pro1F3PerVisit.jun23
// ]
// // jul 23
// export const pro1WithJul23Charges = [
//     pro1F1Charge.jul23,
//     pro1F2Charge.jul23,
//     pro1F3Charge.jul23
// ]
// export const pro1WithJul23Pay = [
//     pro1F1Payment.jul23,
//     pro1F2Payment.jul23,
//     pro1F3Payment.jul23
// ]
// export const pro1WithJul23Count = [
//     pro1F1Collectable.jul23,
//     pro1F2Collectable.jul23,
//     pro1F3Collectable.jul23
// ]
// export const pro1WithJul23NonCount = [
//     pro1F1NonCollectable.jul23,
//     pro1F2NonCollectable.jul23,
//     pro1F3NonCollectable.jul23
// ]
// export const pro1WithJul23Claim = [
//     pro1F1ClaimCount.jul23,
//     pro1F2ClaimCount.jul23,
//     pro1F3ClaimCount.jul23
// ]
// export const pro1WithJul23PerVisit = [
//     pro1F1PerVisit.jul23,
//     pro1F2PerVisit.jul23,
//     pro1F3PerVisit.jul23
// ]
// // aug 23
// export const pro1WithAug23Charges = [
//     pro1F1Charge.aug23,
//     pro1F2Charge.aug23,
//     pro1F3Charge.aug23
// ]
// export const pro1WithAug23Pay = [
//     pro1F1Payment.aug23,
//     pro1F2Payment.aug23,
//     pro1F3Payment.aug23
// ]
// export const pro1WithAug23Count = [
//     pro1F1Collectable.aug23,
//     pro1F2Collectable.aug23,
//     pro1F3Collectable.aug23
// ]
// export const pro1WithAug23NonCount = [
//     pro1F1NonCollectable.aug23,
//     pro1F2NonCollectable.aug23,
//     pro1F3NonCollectable.aug23
// ]
// export const pro1WithAug23Claim = [
//     pro1F1ClaimCount.aug23,
//     pro1F2ClaimCount.aug23,
//     pro1F3ClaimCount.aug23
// ]
// export const pro1WithAug23PerVisit = [
//     pro1F1PerVisit.aug23,
//     pro1F2PerVisit.aug23,
//     pro1F3PerVisit.aug23
// ]
// // sep 23
// export const pro1WithSep23Charges = [
//     pro1F1Charge.sep23,
//     pro1F2Charge.sep23,
//     pro1F3Charge.sep23
// ]
// export const pro1WithSep23Pay = [
//     pro1F1Payment.sep23,
//     pro1F2Payment.sep23,
//     pro1F3Payment.sep23
// ]
// export const pro1WithSep23Count = [
//     pro1F1Collectable.sep23,
//     pro1F2Collectable.sep23,
//     pro1F3Collectable.sep23
// ]
// export const pro1WithSep23NonCount = [
//     pro1F1NonCollectable.sep23,
//     pro1F2NonCollectable.sep23,
//     pro1F3NonCollectable.sep23
// ]
// export const pro1WithSep23Claim = [
//     pro1F1ClaimCount.sep23,
//     pro1F2ClaimCount.sep23,
//     pro1F3ClaimCount.sep23
// ]
// export const pro1WithSep23PerVisit = [
//     pro1F1PerVisit.sep23,
//     pro1F2PerVisit.sep23,
//     pro1F3PerVisit.sep23
// ]
// // oct 23
// export const pro1WithOct23Charges = [
//     pro1F1Charge.oct23,
//     pro1F2Charge.oct23,
//     pro1F3Charge.oct23
// ]
// export const pro1WithOct23Pay = [
//     pro1F1Payment.oct23,
//     pro1F2Payment.oct23,
//     pro1F3Payment.oct23
// ]
// export const pro1WithOct23Count = [
//     pro1F1Collectable.oct23,
//     pro1F2Collectable.oct23,
//     pro1F3Collectable.oct23
// ]
// export const pro1WithOct23NonCount = [
//     pro1F1NonCollectable.oct23,
//     pro1F2NonCollectable.oct23,
//     pro1F3NonCollectable.oct23
// ]
// export const pro1WithOct23Claim = [
//     pro1F1ClaimCount.oct23,
//     pro1F2ClaimCount.oct23,
//     pro1F3ClaimCount.oct23
// ]
// export const pro1WithOct23PerVisit = [
//     pro1F1PerVisit.oct23,
//     pro1F2PerVisit.oct23,
//     pro1F3PerVisit.oct23
// ]
// // nov 23
// export const pro1WithNov23Charges = [
//     pro1F1Charge.nov23,
//     pro1F2Charge.nov23,
//     pro1F3Charge.nov23
// ]
// export const pro1WithNov23Pay = [
//     pro1F1Payment.nov23,
//     pro1F2Payment.nov23,
//     pro1F3Payment.nov23
// ]
// export const pro1WithNov23Count = [
//     pro1F1Collectable.nov23,
//     pro1F2Collectable.nov23,
//     pro1F3Collectable.nov23
// ]
// export const pro1WithNov23NonCount = [
//     pro1F1NonCollectable.nov23,
//     pro1F2NonCollectable.nov23,
//     pro1F3NonCollectable.nov23
// ]
// export const pro1WithNov23Claim = [
//     pro1F1ClaimCount.nov23,
//     pro1F2ClaimCount.nov23,
//     pro1F3ClaimCount.nov23
// ]
// export const pro1WithNov23PerVisit = [
//     pro1F1PerVisit.nov23,
//     pro1F2PerVisit.nov23,
//     pro1F3PerVisit.nov23
// ]


// provider-2
// jan 21
// export const pro2WithJan21Charges = [
//     pro2F1Charge.jan21,
//     pro2F2Charge.jan21,
//     pro2F3Charge.jan21
// ]
// export const pro2WithJan21Pay = [
//     pro2F1Payment.jan21,
//     pro2F2Payment.jan21,
//     pro2F3Payment.jan21
// ]
// export const pro2WithJan21Count = [
//     pro2F1Collectable.jan21,
//     pro2F2Collectable.jan21,
//     pro2F3Collectable.jan21
// ]
// export const pro2WithJan21NonCount = [
//     pro2F1NonCollectable.jan21,
//     pro2F2NonCollectable.jan21,
//     pro2F3NonCollectable.jan21
// ]
// export const pro2WithJan21Claim = [
//     pro2F1ClaimCount.jan21,
//     pro2F2ClaimCount.jan21,
//     pro2F3ClaimCount.jan21
// ]
// export const pro2WithJan21PerVisit = [
//     pro2F1PerVisit.jan21,
//     pro2F2PerVisit.jan21,
//     pro2F3PerVisit.jan21
// ]
// // feb 21
// export const pro2WithFeb21Charges = [
//     pro2F1Charge.feb21,
//     pro2F2Charge.feb21,
//     pro2F3Charge.feb21
// ]
// export const pro2WithFeb21Pay = [
//     pro2F1Payment.feb21,
//     pro2F2Payment.feb21,
//     pro2F3Payment.feb21
// ]
// export const pro2WithFeb21Count = [
//     pro2F1Collectable.feb21,
//     pro2F2Collectable.feb21,
//     pro2F3Collectable.feb21
// ]
// export const pro2WithFeb21NonCount = [
//     pro2F1NonCollectable.feb21,
//     pro2F2NonCollectable.feb21,
//     pro2F3NonCollectable.feb21
// ]
// export const pro2WithFeb21Claim = [
//     pro2F1ClaimCount.feb21,
//     pro2F2ClaimCount.feb21,
//     pro2F3ClaimCount.feb21
// ]
// export const pro2WithFeb21PerVisit = [
//     pro2F1PerVisit.feb21,
//     pro2F2PerVisit.feb21,
//     pro2F3PerVisit.feb21
// ]
// // mar 21
// export const pro2WithMar21Charges = [
//     pro2F1Charge.mar21,
//     pro2F2Charge.mar21,
//     pro2F3Charge.mar21
// ]
// export const pro2WithMar21Pay = [
//     pro2F1Payment.mar21,
//     pro2F2Payment.mar21,
//     pro2F3Payment.mar21
// ]
// export const pro2WithMar21Count = [
//     pro2F1Collectable.mar21,
//     pro2F2Collectable.mar21,
//     pro2F3Collectable.mar21
// ]
// export const pro2WithMar21NonCount = [
//     pro2F1NonCollectable.mar21,
//     pro2F2NonCollectable.mar21,
//     pro2F3NonCollectable.mar21
// ]
// export const pro2WithMar21Claim = [
//     pro2F1ClaimCount.mar21,
//     pro2F2ClaimCount.mar21,
//     pro2F3ClaimCount.mar21
// ]
// export const pro2WithMar21PerVisit = [
//     pro2F1PerVisit.mar21,
//     pro2F2PerVisit.mar21,
//     pro2F3PerVisit.mar21
// ]
// // april 21
// export const pro2WithApr21Charges = [
//     pro2F1Charge.apr21,
//     pro2F2Charge.apr21,
//     pro2F3Charge.apr21
// ]
// export const pro2WithApr21Pay = [
//     pro2F1Payment.apr21,
//     pro2F2Payment.apr21,
//     pro2F3Payment.apr21
// ]
// export const pro2WithApr21Count = [
//     pro2F1Collectable.apr21,
//     pro2F2Collectable.apr21,
//     pro2F3Collectable.apr21
// ]
// export const pro2WithApr21NonCount = [
//     pro2F1NonCollectable.apr21,
//     pro2F2NonCollectable.apr21,
//     pro2F3NonCollectable.apr21
// ]
// export const pro2WithApr21Claim = [
//     pro2F1ClaimCount.apr21,
//     pro2F2ClaimCount.apr21,
//     pro2F3ClaimCount.apr21
// ]
// export const pro2WithApr21PerVisit = [
//     pro2F1PerVisit.apr21,
//     pro2F2PerVisit.apr21,
//     pro2F3PerVisit.apr21
// ]
// // may 21
// export const pro2WithMay21Charges = [
//     pro2F1Charge.may21,
//     pro2F2Charge.may21,
//     pro2F3Charge.may21
// ]
// export const pro2WithMay21Pay = [
//     pro2F1Payment.may21,
//     pro2F2Payment.may21,
//     pro2F3Payment.may21
// ]
// export const pro2WithMay21Count = [
//     pro2F1Collectable.may21,
//     pro2F2Collectable.may21,
//     pro2F3Collectable.may21
// ]
// export const pro2WithMay21NonCount = [
//     pro2F1NonCollectable.may21,
//     pro2F2NonCollectable.may21,
//     pro2F3NonCollectable.may21
// ]
// export const pro2WithMay21Claim = [
//     pro2F1ClaimCount.may21,
//     pro2F2ClaimCount.may21,
//     pro2F3ClaimCount.may21
// ]
// export const pro2WithMay21PerVisit = [
//     pro2F1PerVisit.may21,
//     pro2F2PerVisit.may21,
//     pro2F3PerVisit.may21
// ]
// // jun 21
// export const pro2WithJun21Charges = [
//     pro2F1Charge.jun21,
//     pro2F2Charge.jun21,
//     pro2F3Charge.jun21
// ]
// export const pro2WithJun21Pay = [
//     pro2F1Payment.jun21,
//     pro2F2Payment.jun21,
//     pro2F3Payment.jun21
// ]
// export const pro2WithJun21Count = [
//     pro2F1Collectable.jun21,
//     pro2F2Collectable.jun21,
//     pro2F3Collectable.jun21
// ]
// export const pro2WithJun21NonCount = [
//     pro2F1NonCollectable.jun21,
//     pro2F2NonCollectable.jun21,
//     pro2F3NonCollectable.jun21
// ]
// export const pro2WithJun21Claim = [
//     pro2F1ClaimCount.jun21,
//     pro2F2ClaimCount.jun21,
//     pro2F3ClaimCount.jun21
// ]
// export const pro2WithJun21PerVisit = [
//     pro2F1PerVisit.jun21,
//     pro2F2PerVisit.jun21,
//     pro2F3PerVisit.jun21
// ]
// // jul 21
// export const pro2WithJul21Charges = [
//     pro2F1Charge.jul21,
//     pro2F2Charge.jul21,
//     pro2F3Charge.jul21
// ]
// export const pro2WithJul21Pay = [
//     pro2F1Payment.jul21,
//     pro2F2Payment.jul21,
//     pro2F3Payment.jul21
// ]
// export const pro2WithJul21Count = [
//     pro2F1Collectable.jul21,
//     pro2F2Collectable.jul21,
//     pro2F3Collectable.jul21
// ]
// export const pro2WithJul21NonCount = [
//     pro2F1NonCollectable.jul21,
//     pro2F2NonCollectable.jul21,
//     pro2F3NonCollectable.jul21
// ]
// export const pro2WithJul21Claim = [
//     pro2F1ClaimCount.jul21,
//     pro2F2ClaimCount.jul21,
//     pro2F3ClaimCount.jul21
// ]
// export const pro2WithJul21PerVisit = [
//     pro2F1PerVisit.jul21,
//     pro2F2PerVisit.jul21,
//     pro2F3PerVisit.jul21
// ]
// // aug 21
// export const pro2WithAug21Charges = [
//     pro2F1Charge.aug21,
//     pro2F2Charge.aug21,
//     pro2F3Charge.aug21
// ]
// export const pro2WithAug21Pay = [
//     pro2F1Payment.aug21,
//     pro2F2Payment.aug21,
//     pro2F3Payment.aug21
// ]
// export const pro2WithAug21Count = [
//     pro2F1Collectable.aug21,
//     pro2F2Collectable.aug21,
//     pro2F3Collectable.aug21
// ]
// export const pro2WithAug21NonCount = [
//     pro2F1NonCollectable.aug21,
//     pro2F2NonCollectable.aug21,
//     pro2F3NonCollectable.aug21
// ]
// export const pro2WithAug21Claim = [
//     pro2F1ClaimCount.aug21,
//     pro2F2ClaimCount.aug21,
//     pro2F3ClaimCount.aug21
// ]
// export const pro2WithAug21PerVisit = [
//     pro2F1PerVisit.aug21,
//     pro2F2PerVisit.aug21,
//     pro2F3PerVisit.aug21
// ]
// // sep 21
// export const pro2WithSep21Charges = [
//     pro2F1Charge.sep21,
//     pro2F2Charge.sep21,
//     pro2F3Charge.sep21
// ]
// export const pro2WithSep21Pay = [
//     pro2F1Payment.sep21,
//     pro2F2Payment.sep21,
//     pro2F3Payment.sep21
// ]
// export const pro2WithSep21Count = [
//     pro2F1Collectable.sep21,
//     pro2F2Collectable.sep21,
//     pro2F3Collectable.sep21
// ]
// export const pro2WithSep21NonCount = [
//     pro2F1NonCollectable.sep21,
//     pro2F2NonCollectable.sep21,
//     pro2F3NonCollectable.sep21
// ]
// export const pro2WithSep21Claim = [
//     pro2F1ClaimCount.sep21,
//     pro2F2ClaimCount.sep21,
//     pro2F3ClaimCount.sep21
// ]
// export const pro2WithSep21PerVisit = [
//     pro2F1PerVisit.sep21,
//     pro2F2PerVisit.sep21,
//     pro2F3PerVisit.sep21
// ]
// // oct 21
// export const pro2WithOct21Charges = [
//     pro2F1Charge.oct21,
//     pro2F2Charge.oct21,
//     pro2F3Charge.oct21
// ]
// export const pro2WithOct21Pay = [
//     pro2F1Payment.oct21,
//     pro2F2Payment.oct21,
//     pro2F3Payment.oct21
// ]
// export const pro2WithOct21Count = [
//     pro2F1Collectable.oct21,
//     pro2F2Collectable.oct21,
//     pro2F3Collectable.oct21
// ]
// export const pro2WithOct21NonCount = [
//     pro2F1NonCollectable.oct21,
//     pro2F2NonCollectable.oct21,
//     pro2F3NonCollectable.oct21
// ]
// export const pro2WithOct21Claim = [
//     pro2F1ClaimCount.oct21,
//     pro2F2ClaimCount.oct21,
//     pro2F3ClaimCount.oct21
// ]
// export const pro2WithOct21PerVisit = [
//     pro2F1PerVisit.oct21,
//     pro2F2PerVisit.oct21,
//     pro2F3PerVisit.oct21
// ]
// // nov 21
// export const pro2WithNov21Charges = [
//     pro2F1Charge.nov21,
//     pro2F2Charge.nov21,
//     pro2F3Charge.nov21
// ]
// export const pro2WithNov21Pay = [
//     pro2F1Payment.nov21,
//     pro2F2Payment.nov21,
//     pro2F3Payment.nov21
// ]
// export const pro2WithNov21Count = [
//     pro2F1Collectable.nov21,
//     pro2F2Collectable.nov21,
//     pro2F3Collectable.nov21
// ]
// export const pro2WithNov21NonCount = [
//     pro2F1NonCollectable.nov21,
//     pro2F2NonCollectable.nov21,
//     pro2F3NonCollectable.nov21
// ]
// export const pro2WithNov21Claim = [
//     pro2F1ClaimCount.nov21,
//     pro2F2ClaimCount.nov21,
//     pro2F3ClaimCount.nov21
// ]
// export const pro2WithNov21PerVisit = [
//     pro2F1PerVisit.nov21,
//     pro2F2PerVisit.nov21,
//     pro2F3PerVisit.nov21
// ]
// // dec 21
// export const pro2WithDec21Charges = [
//     pro2F1Charge.dec21,
//     pro2F2Charge.dec21,
//     pro2F3Charge.dec21
// ]
// export const pro2WithDec21Pay = [
//     pro2F1Payment.dec21,
//     pro2F2Payment.dec21,
//     pro2F3Payment.dec21
// ]
// export const pro2WithDec21Count = [
//     pro2F1Collectable.dec21,
//     pro2F2Collectable.dec21,
//     pro2F3Collectable.dec21
// ]
// export const pro2WithDec21NonCount = [
//     pro2F1NonCollectable.dec21,
//     pro2F2NonCollectable.dec21,
//     pro2F3NonCollectable.dec21
// ]
// export const pro2WithDec21Claim = [
//     pro2F1ClaimCount.dec21,
//     pro2F2ClaimCount.dec21,
//     pro2F3ClaimCount.dec21
// ]
// export const pro2WithDec21PerVisit = [
//     pro2F1PerVisit.dec21,
//     pro2F2PerVisit.dec21,
//     pro2F3PerVisit.dec21
// ]

// // provider-2 
// // jan 22
// export const pro2WithJan22Charges = [
//     pro2F1Charge.jan22,
//     pro2F2Charge.jan22,
//     pro2F3Charge.jan22
// ]
// export const pro2WithJan22Pay = [
//     pro2F1Payment.jan22,
//     pro2F2Payment.jan22,
//     pro2F3Payment.jan22
// ]
// export const pro2WithJan22Count = [
//     pro2F1Collectable.jan22,
//     pro2F2Collectable.jan22,
//     pro2F3Collectable.jan22
// ]
// export const pro2WithJan22NonCount = [
//     pro2F1NonCollectable.jan22,
//     pro2F2NonCollectable.jan22,
//     pro2F3NonCollectable.jan22
// ]
// export const pro2WithJan22Claim = [
//     pro2F1ClaimCount.jan22,
//     pro2F2ClaimCount.jan22,
//     pro2F3ClaimCount.jan22
// ]
// export const pro2WithJan22PerVisit = [
//     pro2F1PerVisit.jan22,
//     pro2F2PerVisit.jan22,
//     pro2F3PerVisit.jan22
// ]
// // feb 22
// export const pro2WithFeb22Charges = [
//     pro2F1Charge.feb22,
//     pro2F2Charge.feb22,
//     pro2F3Charge.feb22
// ]
// export const pro2WithFeb22Pay = [
//     pro2F1Payment.feb22,
//     pro2F2Payment.feb22,
//     pro2F3Payment.feb22
// ]
// export const pro2WithFeb22Count = [
//     pro2F1Collectable.feb22,
//     pro2F2Collectable.feb22,
//     pro2F3Collectable.feb22
// ]
// export const pro2WithFeb22NonCount = [
//     pro2F1NonCollectable.feb22,
//     pro2F2NonCollectable.feb22,
//     pro2F3NonCollectable.feb22
// ]
// export const pro2WithFeb22Claim = [
//     pro2F1ClaimCount.feb22,
//     pro2F2ClaimCount.feb22,
//     pro2F3ClaimCount.feb22
// ]
// export const pro2WithFeb22PerVisit = [
//     pro2F1PerVisit.feb22,
//     pro2F2PerVisit.feb22,
//     pro2F3PerVisit.feb22
// ]
// // mar 22
// export const pro2WithMar22Charges = [
//     pro2F1Charge.mar22,
//     pro2F2Charge.mar22,
//     pro2F3Charge.mar22
// ]
// export const pro2WithMar22Pay = [
//     pro2F1Payment.mar22,
//     pro2F2Payment.mar22,
//     pro2F3Payment.mar22
// ]
// export const pro2WithMar22Count = [
//     pro2F1Collectable.mar22,
//     pro2F2Collectable.mar22,
//     pro2F3Collectable.mar22
// ]
// export const pro2WithMar22NonCount = [
//     pro2F1NonCollectable.mar22,
//     pro2F2NonCollectable.mar22,
//     pro2F3NonCollectable.mar22
// ]
// export const pro2WithMar22Claim = [
//     pro2F1ClaimCount.mar22,
//     pro2F2ClaimCount.mar22,
//     pro2F3ClaimCount.mar22
// ]
// export const pro2WithMar22PerVisit = [
//     pro2F1PerVisit.mar22,
//     pro2F2PerVisit.mar22,
//     pro2F3PerVisit.mar22
// ]
// // april 22
// export const pro2WithApr22Charges = [
//     pro2F1Charge.apr22,
//     pro2F2Charge.apr22,
//     pro2F3Charge.apr22
// ]
// export const pro2WithApr22Pay = [
//     pro2F1Payment.apr22,
//     pro2F2Payment.apr22,
//     pro2F3Payment.apr22
// ]
// export const pro2WithApr22Count = [
//     pro2F1Collectable.apr22,
//     pro2F2Collectable.apr22,
//     pro2F3Collectable.apr22
// ]
// export const pro2WithApr22NonCount = [
//     pro2F1NonCollectable.apr22,
//     pro2F2NonCollectable.apr22,
//     pro2F3NonCollectable.apr22
// ]
// export const pro2WithApr22Claim = [
//     pro2F1ClaimCount.apr22,
//     pro2F2ClaimCount.apr22,
//     pro2F3ClaimCount.apr22
// ]
// export const pro2WithApr22PerVisit = [
//     pro2F1PerVisit.apr22,
//     pro2F2PerVisit.apr22,
//     pro2F3PerVisit.apr22
// ]
// // may 22
// export const pro2WithMay22Charges = [
//     pro2F1Charge.may22,
//     pro2F2Charge.may22,
//     pro2F3Charge.may22
// ]
// export const pro2WithMay22Pay = [
//     pro2F1Payment.may22,
//     pro2F2Payment.may22,
//     pro2F3Payment.may22
// ]
// export const pro2WithMay22Count = [
//     pro2F1Collectable.may22,
//     pro2F2Collectable.may22,
//     pro2F3Collectable.may22
// ]
// export const pro2WithMay22NonCount = [
//     pro2F1NonCollectable.may22,
//     pro2F2NonCollectable.may22,
//     pro2F3NonCollectable.may22
// ]
// export const pro2WithMay22Claim = [
//     pro2F1ClaimCount.may22,
//     pro2F2ClaimCount.may22,
//     pro2F3ClaimCount.may22
// ]
// export const pro2WithMay22PerVisit = [
//     pro2F1PerVisit.may22,
//     pro2F2PerVisit.may22,
//     pro2F3PerVisit.may22
// ]
// // jun 22
// export const pro2WithJun22Charges = [
//     pro2F1Charge.jun22,
//     pro2F2Charge.jun22,
//     pro2F3Charge.jun22
// ]
// export const pro2WithJun22Pay = [
//     pro2F1Payment.jun22,
//     pro2F2Payment.jun22,
//     pro2F3Payment.jun22
// ]
// export const pro2WithJun22Count = [
//     pro2F1Collectable.jun22,
//     pro2F2Collectable.jun22,
//     pro2F3Collectable.jun22
// ]
// export const pro2WithJun22NonCount = [
//     pro2F1NonCollectable.jun22,
//     pro2F2NonCollectable.jun22,
//     pro2F3NonCollectable.jun22
// ]
// export const pro2WithJun22Claim = [
//     pro2F1ClaimCount.jun22,
//     pro2F2ClaimCount.jun22,
//     pro2F3ClaimCount.jun22
// ]
// export const pro2WithJun22PerVisit = [
//     pro2F1PerVisit.jun22,
//     pro2F2PerVisit.jun22,
//     pro2F3PerVisit.jun22
// ]
// // jul 22
// export const pro2WithJul22Charges = [
//     pro2F1Charge.jul22,
//     pro2F2Charge.jul22,
//     pro2F3Charge.jul22
// ]
// export const pro2WithJul22Pay = [
//     pro2F1Payment.jul22,
//     pro2F2Payment.jul22,
//     pro2F3Payment.jul22
// ]
// export const pro2WithJul22Count = [
//     pro2F1Collectable.jul22,
//     pro2F2Collectable.jul22,
//     pro2F3Collectable.jul22
// ]
// export const pro2WithJul22NonCount = [
//     pro2F1NonCollectable.jul22,
//     pro2F2NonCollectable.jul22,
//     pro2F3NonCollectable.jul22
// ]
// export const pro2WithJul22Claim = [
//     pro2F1ClaimCount.jul22,
//     pro2F2ClaimCount.jul22,
//     pro2F3ClaimCount.jul22
// ]
// export const pro2WithJul22PerVisit = [
//     pro2F1PerVisit.jul22,
//     pro2F2PerVisit.jul22,
//     pro2F3PerVisit.jul22
// ]
// // aug 22
// export const pro2WithAug22Charges = [
//     pro2F1Charge.aug22,
//     pro2F2Charge.aug22,
//     pro2F3Charge.aug22
// ]
// export const pro2WithAug22Pay = [
//     pro2F1Payment.aug22,
//     pro2F2Payment.aug22,
//     pro2F3Payment.aug22
// ]
// export const pro2WithAug22Count = [
//     pro2F1Collectable.aug22,
//     pro2F2Collectable.aug22,
//     pro2F3Collectable.aug22
// ]
// export const pro2WithAug22NonCount = [
//     pro2F1NonCollectable.aug22,
//     pro2F2NonCollectable.aug22,
//     pro2F3NonCollectable.aug22
// ]
// export const pro2WithAug22Claim = [
//     pro2F1ClaimCount.aug22,
//     pro2F2ClaimCount.aug22,
//     pro2F3ClaimCount.aug22
// ]
// export const pro2WithAug22PerVisit = [
//     pro2F1PerVisit.aug22,
//     pro2F2PerVisit.aug22,
//     pro2F3PerVisit.aug22
// ]
// // sep 22
// export const pro2WithSep22Charges = [
//     pro2F1Charge.sep22,
//     pro2F2Charge.sep22,
//     pro2F3Charge.sep22
// ]
// export const pro2WithSep22Pay = [
//     pro2F1Payment.sep22,
//     pro2F2Payment.sep22,
//     pro2F3Payment.sep22
// ]
// export const pro2WithSep22Count = [
//     pro2F1Collectable.sep22,
//     pro2F2Collectable.sep22,
//     pro2F3Collectable.sep22
// ]
// export const pro2WithSep22NonCount = [
//     pro2F1NonCollectable.sep22,
//     pro2F2NonCollectable.sep22,
//     pro2F3NonCollectable.sep22
// ]
// export const pro2WithSep22Claim = [
//     pro2F1ClaimCount.sep22,
//     pro2F2ClaimCount.sep22,
//     pro2F3ClaimCount.sep22
// ]
// export const pro2WithSep22PerVisit = [
//     pro2F1PerVisit.sep22,
//     pro2F2PerVisit.sep22,
//     pro2F3PerVisit.sep22
// ]
// // oct 22
// export const pro2WithOct22Charges = [
//     pro2F1Charge.oct22,
//     pro2F2Charge.oct22,
//     pro2F3Charge.oct22
// ]
// export const pro2WithOct22Pay = [
//     pro2F1Payment.oct22,
//     pro2F2Payment.oct22,
//     pro2F3Payment.oct22
// ]
// export const pro2WithOct22Count = [
//     pro2F1Collectable.oct22,
//     pro2F2Collectable.oct22,
//     pro2F3Collectable.oct22
// ]
// export const pro2WithOct22NonCount = [
//     pro2F1NonCollectable.oct22,
//     pro2F2NonCollectable.oct22,
//     pro2F3NonCollectable.oct22
// ]
// export const pro2WithOct22Claim = [
//     pro2F1ClaimCount.oct22,
//     pro2F2ClaimCount.oct22,
//     pro2F3ClaimCount.oct22
// ]
// export const pro2WithOct22PerVisit = [
//     pro2F1PerVisit.oct22,
//     pro2F2PerVisit.oct22,
//     pro2F3PerVisit.oct22
// ]
// // nov 22
// export const pro2WithNov22Charges = [
//     pro2F1Charge.nov22,
//     pro2F2Charge.nov22,
//     pro2F3Charge.nov22
// ]
// export const pro2WithNov22Pay = [
//     pro2F1Payment.nov22,
//     pro2F2Payment.nov22,
//     pro2F3Payment.nov22
// ]
// export const pro2WithNov22Count = [
//     pro2F1Collectable.nov22,
//     pro2F2Collectable.nov22,
//     pro2F3Collectable.nov22
// ]
// export const pro2WithNov22NonCount = [
//     pro2F1NonCollectable.nov22,
//     pro2F2NonCollectable.nov22,
//     pro2F3NonCollectable.nov22
// ]
// export const pro2WithNov22Claim = [
//     pro2F1ClaimCount.nov22,
//     pro2F2ClaimCount.nov22,
//     pro2F3ClaimCount.nov22
// ]
// export const pro2WithNov22PerVisit = [
//     pro2F1PerVisit.nov22,
//     pro2F2PerVisit.nov22,
//     pro2F3PerVisit.nov22
// ]
// // dec 22
// export const pro2WithDec22Charges = [
//     pro2F1Charge.dec22,
//     pro2F2Charge.dec22,
//     pro2F3Charge.dec22
// ]
// export const pro2WithDec22Pay = [
//     pro2F1Payment.dec22,
//     pro2F2Payment.dec22,
//     pro2F3Payment.dec22
// ]
// export const pro2WithDec22Count = [
//     pro2F1Collectable.dec22,
//     pro2F2Collectable.dec22,
//     pro2F3Collectable.dec22
// ]
// export const pro2WithDec22NonCount = [
//     pro2F1NonCollectable.dec22,
//     pro2F2NonCollectable.dec22,
//     pro2F3NonCollectable.dec22
// ]
// export const pro2WithDec22Claim = [
//     pro2F1ClaimCount.dec22,
//     pro2F2ClaimCount.dec22,
//     pro2F3ClaimCount.dec22
// ]
// export const pro2WithDec22PerVisit = [
//     pro2F1PerVisit.dec22,
//     pro2F2PerVisit.dec22,
//     pro2F3PerVisit.dec22
// ]

// // provider-2
// // jan 23
// export const pro2WithJan23Charges = [
//     pro2F1Charge.jan23,
//     pro2F2Charge.jan23,
//     pro2F3Charge.jan23
// ]
// export const pro2WithJan23Pay = [
//     pro2F1Payment.jan23,
//     pro2F2Payment.jan23,
//     pro2F3Payment.jan23
// ]
// export const pro2WithJan23Count = [
//     pro2F1Collectable.jan23,
//     pro2F2Collectable.jan23,
//     pro2F3Collectable.jan23
// ]
// export const pro2WithJan23NonCount = [
//     pro2F1NonCollectable.jan23,
//     pro2F2NonCollectable.jan23,
//     pro2F3NonCollectable.jan23
// ]
// export const pro2WithJan23Claim = [
//     pro2F1ClaimCount.jan23,
//     pro2F2ClaimCount.jan23,
//     pro2F3ClaimCount.jan23
// ]
// export const pro2WithJan23PerVisit = [
//     pro2F1PerVisit.jan23,
//     pro2F2PerVisit.jan23,
//     pro2F3PerVisit.jan23
// ]
// // feb 23
// export const pro2WithFeb23Charges = [
//     pro2F1Charge.feb23,
//     pro2F2Charge.feb23,
//     pro2F3Charge.feb23
// ]
// export const pro2WithFeb23Pay = [
//     pro2F1Payment.feb23,
//     pro2F2Payment.feb23,
//     pro2F3Payment.feb23
// ]
// export const pro2WithFeb23Count = [
//     pro2F1Collectable.feb23,
//     pro2F2Collectable.feb23,
//     pro2F3Collectable.feb23
// ]
// export const pro2WithFeb23NonCount = [
//     pro2F1NonCollectable.feb23,
//     pro2F2NonCollectable.feb23,
//     pro2F3NonCollectable.feb23
// ]
// export const pro2WithFeb23Claim = [
//     pro2F1ClaimCount.feb23,
//     pro2F2ClaimCount.feb23,
//     pro2F3ClaimCount.feb23
// ]
// export const pro2WithFeb23PerVisit = [
//     pro2F1PerVisit.feb23,
//     pro2F2PerVisit.feb23,
//     pro2F3PerVisit.feb23
// ]
// // mar 23
// export const pro2WithMar23Charges = [
//     pro2F1Charge.mar23,
//     pro2F2Charge.mar23,
//     pro2F3Charge.mar23
// ]
// export const pro2WithMar23Pay = [
//     pro2F1Payment.mar23,
//     pro2F2Payment.mar23,
//     pro2F3Payment.mar23
// ]
// export const pro2WithMar23Count = [
//     pro2F1Collectable.mar23,
//     pro2F2Collectable.mar23,
//     pro2F3Collectable.mar23
// ]
// export const pro2WithMar23NonCount = [
//     pro2F1NonCollectable.mar23,
//     pro2F2NonCollectable.mar23,
//     pro2F3NonCollectable.mar23
// ]
// export const pro2WithMar23Claim = [
//     pro2F1ClaimCount.mar23,
//     pro2F2ClaimCount.mar23,
//     pro2F3ClaimCount.mar23
// ]
// export const pro2WithMar23PerVisit = [
//     pro2F1PerVisit.mar23,
//     pro2F2PerVisit.mar23,
//     pro2F3PerVisit.mar23
// ]
// // april 23
// export const pro2WithApr23Charges = [
//     pro2F1Charge.apr23,
//     pro2F2Charge.apr23,
//     pro2F3Charge.apr23
// ]
// export const pro2WithApr23Pay = [
//     pro2F1Payment.apr23,
//     pro2F2Payment.apr23,
//     pro2F3Payment.apr23
// ]
// export const pro2WithApr23Count = [
//     pro2F1Collectable.apr23,
//     pro2F2Collectable.apr23,
//     pro2F3Collectable.apr23
// ]
// export const pro2WithApr23NonCount = [
//     pro2F1NonCollectable.apr23,
//     pro2F2NonCollectable.apr23,
//     pro2F3NonCollectable.apr23
// ]
// export const pro2WithApr23Claim = [
//     pro2F1ClaimCount.apr23,
//     pro2F2ClaimCount.apr23,
//     pro2F3ClaimCount.apr23
// ]
// export const pro2WithApr23PerVisit = [
//     pro2F1PerVisit.apr23,
//     pro2F2PerVisit.apr23,
//     pro2F3PerVisit.apr23
// ]
// // may 23
// export const pro2WithMay23Charges = [
//     pro2F1Charge.may23,
//     pro2F2Charge.may23,
//     pro2F3Charge.may23
// ]
// export const pro2WithMay23Pay = [
//     pro2F1Payment.may23,
//     pro2F2Payment.may23,
//     pro2F3Payment.may23
// ]
// export const pro2WithMay23Count = [
//     pro2F1Collectable.may23,
//     pro2F2Collectable.may23,
//     pro2F3Collectable.may23
// ]
// export const pro2WithMay23NonCount = [
//     pro2F1NonCollectable.may23,
//     pro2F2NonCollectable.may23,
//     pro2F3NonCollectable.may23
// ]
// export const pro2WithMay23Claim = [
//     pro2F1ClaimCount.may23,
//     pro2F2ClaimCount.may23,
//     pro2F3ClaimCount.may23
// ]
// export const pro2WithMay23PerVisit = [
//     pro2F1PerVisit.may23,
//     pro2F2PerVisit.may23,
//     pro2F3PerVisit.may23
// ]
// // jun 23
// export const pro2WithJun23Charges = [
//     pro2F1Charge.jun23,
//     pro2F2Charge.jun23,
//     pro2F3Charge.jun23
// ]
// export const pro2WithJun23Pay = [
//     pro2F1Payment.jun23,
//     pro2F2Payment.jun23,
//     pro2F3Payment.jun23
// ]
// export const pro2WithJun23Count = [
//     pro2F1Collectable.jun23,
//     pro2F2Collectable.jun23,
//     pro2F3Collectable.jun23
// ]
// export const pro2WithJun23NonCount = [
//     pro2F1NonCollectable.jun23,
//     pro2F2NonCollectable.jun23,
//     pro2F3NonCollectable.jun23
// ]
// export const pro2WithJun23Claim = [
//     pro2F1ClaimCount.jun23,
//     pro2F2ClaimCount.jun23,
//     pro2F3ClaimCount.jun23
// ]
// export const pro2WithJun23PerVisit = [
//     pro2F1PerVisit.jun23,
//     pro2F2PerVisit.jun23,
//     pro2F3PerVisit.jun23
// ]
// // jul 23
// export const pro2WithJul23Charges = [
//     pro2F1Charge.jul23,
//     pro2F2Charge.jul23,
//     pro2F3Charge.jul23
// ]
// export const pro2WithJul23Pay = [
//     pro2F1Payment.jul23,
//     pro2F2Payment.jul23,
//     pro2F3Payment.jul23
// ]
// export const pro2WithJul23Count = [
//     pro2F1Collectable.jul23,
//     pro2F2Collectable.jul23,
//     pro2F3Collectable.jul23
// ]
// export const pro2WithJul23NonCount = [
//     pro2F1NonCollectable.jul23,
//     pro2F2NonCollectable.jul23,
//     pro2F3NonCollectable.jul23
// ]
// export const pro2WithJul23Claim = [
//     pro2F1ClaimCount.jul23,
//     pro2F2ClaimCount.jul23,
//     pro2F3ClaimCount.jul23
// ]
// export const pro2WithJul23PerVisit = [
//     pro2F1PerVisit.jul23,
//     pro2F2PerVisit.jul23,
//     pro2F3PerVisit.jul23
// ]
// // aug 23
// export const pro2WithAug23Charges = [
//     pro2F1Charge.aug23,
//     pro2F2Charge.aug23,
//     pro2F3Charge.aug23
// ]
// export const pro2WithAug23Pay = [
//     pro2F1Payment.aug23,
//     pro2F2Payment.aug23,
//     pro2F3Payment.aug23
// ]
// export const pro2WithAug23Count = [
//     pro2F1Collectable.aug23,
//     pro2F2Collectable.aug23,
//     pro2F3Collectable.aug23
// ]
// export const pro2WithAug23NonCount = [
//     pro2F1NonCollectable.aug23,
//     pro2F2NonCollectable.aug23,
//     pro2F3NonCollectable.aug23
// ]
// export const pro2WithAug23Claim = [
//     pro2F1ClaimCount.aug23,
//     pro2F2ClaimCount.aug23,
//     pro2F3ClaimCount.aug23
// ]
// export const pro2WithAug23PerVisit = [
//     pro2F1PerVisit.aug23,
//     pro2F2PerVisit.aug23,
//     pro2F3PerVisit.aug23
// ]
// // sep 23
// export const pro2WithSep23Charges = [
//     pro2F1Charge.sep23,
//     pro2F2Charge.sep23,
//     pro2F3Charge.sep23
// ]
// export const pro2WithSep23Pay = [
//     pro2F1Payment.sep23,
//     pro2F2Payment.sep23,
//     pro2F3Payment.sep23
// ]
// export const pro2WithSep23Count = [
//     pro2F1Collectable.sep23,
//     pro2F2Collectable.sep23,
//     pro2F3Collectable.sep23
// ]
// export const pro2WithSep23NonCount = [
//     pro2F1NonCollectable.sep23,
//     pro2F2NonCollectable.sep23,
//     pro2F3NonCollectable.sep23
// ]
// export const pro2WithSep23Claim = [
//     pro2F1ClaimCount.sep23,
//     pro2F2ClaimCount.sep23,
//     pro2F3ClaimCount.sep23
// ]
// export const pro2WithSep23PerVisit = [
//     pro2F1PerVisit.sep23,
//     pro2F2PerVisit.sep23,
//     pro2F3PerVisit.sep23
// ]
// // oct 23
// export const pro2WithOct23Charges = [
//     pro2F1Charge.oct23,
//     pro2F2Charge.oct23,
//     pro2F3Charge.oct23
// ]
// export const pro2WithOct23Pay = [
//     pro2F1Payment.oct23,
//     pro2F2Payment.oct23,
//     pro2F3Payment.oct23
// ]
// export const pro2WithOct23Count = [
//     pro2F1Collectable.oct23,
//     pro2F2Collectable.oct23,
//     pro2F3Collectable.oct23
// ]
// export const pro2WithOct23NonCount = [
//     pro2F1NonCollectable.oct23,
//     pro2F2NonCollectable.oct23,
//     pro2F3NonCollectable.oct23
// ]
// export const pro2WithOct23Claim = [
//     pro2F1ClaimCount.oct23,
//     pro2F2ClaimCount.oct23,
//     pro2F3ClaimCount.oct23
// ]
// export const pro2WithOct23PerVisit = [
//     pro2F1PerVisit.oct23,
//     pro2F2PerVisit.oct23,
//     pro2F3PerVisit.oct23
// ]
// // nov 23
// export const pro2WithNov23Charges = [
//     pro2F1Charge.nov23,
//     pro2F2Charge.nov23,
//     pro2F3Charge.nov23
// ]
// export const pro2WithNov23Pay = [
//     pro2F1Payment.nov23,
//     pro2F2Payment.nov23,
//     pro2F3Payment.nov23
// ]
// export const pro2WithNov23Count = [
//     pro2F1Collectable.nov23,
//     pro2F2Collectable.nov23,
//     pro2F3Collectable.nov23
// ]
// export const pro2WithNov23NonCount = [
//     pro2F1NonCollectable.nov23,
//     pro2F2NonCollectable.nov23,
//     pro2F3NonCollectable.nov23
// ]
// export const pro2WithNov23Claim = [
//     pro2F1ClaimCount.nov23,
//     pro2F2ClaimCount.nov23,
//     pro2F3ClaimCount.nov23
// ]
// export const pro2WithNov23PerVisit = [
//     pro2F1PerVisit.nov23,
//     pro2F2PerVisit.nov23,
//     pro2F3PerVisit.nov23
// ]


// provider-3
// jan 21
// export const pro3WithJan21Charges = [
//     pro3F1Charge.jan21,
//     pro3F2Charge.jan21,
//     pro3F3Charge.jan21
// ]
// export const pro3WithJan21Pay = [
//     pro3F1Payment.jan21,
//     pro3F2Payment.jan21,
//     pro3F3Payment.jan21
// ]
// export const pro3WithJan21Count = [
//     pro3F1Collectable.jan21,
//     pro3F2Collectable.jan21,
//     pro3F3Collectable.jan21
// ]
// export const pro3WithJan21NonCount = [
//     pro3F1NonCollectable.jan21,
//     pro3F2NonCollectable.jan21,
//     pro3F3NonCollectable.jan21
// ]
// export const pro3WithJan21Claim = [
//     pro3F1ClaimCount.jan21,
//     pro3F2ClaimCount.jan21,
//     pro3F3ClaimCount.jan21
// ]
// export const pro3WithJan21PerVisit = [
//     pro3F1PerVisit.jan21,
//     pro3F2PerVisit.jan21,
//     pro3F3PerVisit.jan21
// ]
// // feb 21
// export const pro3WithFeb21Charges = [
//     pro3F1Charge.feb21,
//     pro3F2Charge.feb21,
//     pro3F3Charge.feb21
// ]
// export const pro3WithFeb21Pay = [
//     pro3F1Payment.feb21,
//     pro3F2Payment.feb21,
//     pro3F3Payment.feb21
// ]
// export const pro3WithFeb21Count = [
//     pro3F1Collectable.feb21,
//     pro3F2Collectable.feb21,
//     pro3F3Collectable.feb21
// ]
// export const pro3WithFeb21NonCount = [
//     pro3F1NonCollectable.feb21,
//     pro3F2NonCollectable.feb21,
//     pro3F3NonCollectable.feb21
// ]
// export const pro3WithFeb21Claim = [
//     pro3F1ClaimCount.feb21,
//     pro3F2ClaimCount.feb21,
//     pro3F3ClaimCount.feb21
// ]
// export const pro3WithFeb21PerVisit = [
//     pro3F1PerVisit.feb21,
//     pro3F2PerVisit.feb21,
//     pro3F3PerVisit.feb21
// ]
// // mar 21
// export const pro3WithMar21Charges = [
//     pro3F1Charge.mar21,
//     pro3F2Charge.mar21,
//     pro3F3Charge.mar21
// ]
// export const pro3WithMar21Pay = [
//     pro3F1Payment.mar21,
//     pro3F2Payment.mar21,
//     pro3F3Payment.mar21
// ]
// export const pro3WithMar21Count = [
//     pro3F1Collectable.mar21,
//     pro3F2Collectable.mar21,
//     pro3F3Collectable.mar21
// ]
// export const pro3WithMar21NonCount = [
//     pro3F1NonCollectable.mar21,
//     pro3F2NonCollectable.mar21,
//     pro3F3NonCollectable.mar21
// ]
// export const pro3WithMar21Claim = [
//     pro3F1ClaimCount.mar21,
//     pro3F2ClaimCount.mar21,
//     pro3F3ClaimCount.mar21
// ]
// export const pro3WithMar21PerVisit = [
//     pro3F1PerVisit.mar21,
//     pro3F2PerVisit.mar21,
//     pro3F3PerVisit.mar21
// ]
// // april 21
// export const pro3WithApr21Charges = [
//     pro3F1Charge.apr21,
//     pro3F2Charge.apr21,
//     pro3F3Charge.apr21
// ]
// export const pro3WithApr21Pay = [
//     pro3F1Payment.apr21,
//     pro3F2Payment.apr21,
//     pro3F3Payment.apr21
// ]
// export const pro3WithApr21Count = [
//     pro3F1Collectable.apr21,
//     pro3F2Collectable.apr21,
//     pro3F3Collectable.apr21
// ]
// export const pro3WithApr21NonCount = [
//     pro3F1NonCollectable.apr21,
//     pro3F2NonCollectable.apr21,
//     pro3F3NonCollectable.apr21
// ]
// export const pro3WithApr21Claim = [
//     pro3F1ClaimCount.apr21,
//     pro3F2ClaimCount.apr21,
//     pro3F3ClaimCount.apr21
// ]
// export const pro3WithApr21PerVisit = [
//     pro3F1PerVisit.apr21,
//     pro3F2PerVisit.apr21,
//     pro3F3PerVisit.apr21
// ]
// // may 21
// export const pro3WithMay21Charges = [
//     pro3F1Charge.may21,
//     pro3F2Charge.may21,
//     pro3F3Charge.may21
// ]
// export const pro3WithMay21Pay = [
//     pro3F1Payment.may21,
//     pro3F2Payment.may21,
//     pro3F3Payment.may21
// ]
// export const pro3WithMay21Count = [
//     pro3F1Collectable.may21,
//     pro3F2Collectable.may21,
//     pro3F3Collectable.may21
// ]
// export const pro3WithMay21NonCount = [
//     pro3F1NonCollectable.may21,
//     pro3F2NonCollectable.may21,
//     pro3F3NonCollectable.may21
// ]
// export const pro3WithMay21Claim = [
//     pro3F1ClaimCount.may21,
//     pro3F2ClaimCount.may21,
//     pro3F3ClaimCount.may21
// ]
// export const pro3WithMay21PerVisit = [
//     pro3F1PerVisit.may21,
//     pro3F2PerVisit.may21,
//     pro3F3PerVisit.may21
// ]
// // jun 21
// export const pro3WithJun21Charges = [
//     pro3F1Charge.jun21,
//     pro3F2Charge.jun21,
//     pro3F3Charge.jun21
// ]
// export const pro3WithJun21Pay = [
//     pro3F1Payment.jun21,
//     pro3F2Payment.jun21,
//     pro3F3Payment.jun21
// ]
// export const pro3WithJun21Count = [
//     pro3F1Collectable.jun21,
//     pro3F2Collectable.jun21,
//     pro3F3Collectable.jun21
// ]
// export const pro3WithJun21NonCount = [
//     pro3F1NonCollectable.jun21,
//     pro3F2NonCollectable.jun21,
//     pro3F3NonCollectable.jun21
// ]
// export const pro3WithJun21Claim = [
//     pro3F1ClaimCount.jun21,
//     pro3F2ClaimCount.jun21,
//     pro3F3ClaimCount.jun21
// ]
// export const pro3WithJun21PerVisit = [
//     pro3F1PerVisit.jun21,
//     pro3F2PerVisit.jun21,
//     pro3F3PerVisit.jun21
// ]
// // jul 21
// export const pro3WithJul21Charges = [
//     pro3F1Charge.jul21,
//     pro3F2Charge.jul21,
//     pro3F3Charge.jul21
// ]
// export const pro3WithJul21Pay = [
//     pro3F1Payment.jul21,
//     pro3F2Payment.jul21,
//     pro3F3Payment.jul21
// ]
// export const pro3WithJul21Count = [
//     pro3F1Collectable.jul21,
//     pro3F2Collectable.jul21,
//     pro3F3Collectable.jul21
// ]
// export const pro3WithJul21NonCount = [
//     pro3F1NonCollectable.jul21,
//     pro3F2NonCollectable.jul21,
//     pro3F3NonCollectable.jul21
// ]
// export const pro3WithJul21Claim = [
//     pro3F1ClaimCount.jul21,
//     pro3F2ClaimCount.jul21,
//     pro3F3ClaimCount.jul21
// ]
// export const pro3WithJul21PerVisit = [
//     pro3F1PerVisit.jul21,
//     pro3F2PerVisit.jul21,
//     pro3F3PerVisit.jul21
// ]
// // aug 21
// export const pro3WithAug21Charges = [
//     pro3F1Charge.aug21,
//     pro3F2Charge.aug21,
//     pro3F3Charge.aug21
// ]
// export const pro3WithAug21Pay = [
//     pro3F1Payment.aug21,
//     pro3F2Payment.aug21,
//     pro3F3Payment.aug21
// ]
// export const pro3WithAug21Count = [
//     pro3F1Collectable.aug21,
//     pro3F2Collectable.aug21,
//     pro3F3Collectable.aug21
// ]
// export const pro3WithAug21NonCount = [
//     pro3F1NonCollectable.aug21,
//     pro3F2NonCollectable.aug21,
//     pro3F3NonCollectable.aug21
// ]
// export const pro3WithAug21Claim = [
//     pro3F1ClaimCount.aug21,
//     pro3F2ClaimCount.aug21,
//     pro3F3ClaimCount.aug21
// ]
// export const pro3WithAug21PerVisit = [
//     pro3F1PerVisit.aug21,
//     pro3F2PerVisit.aug21,
//     pro3F3PerVisit.aug21
// ]
// // sep 21
// export const pro3WithSep21Charges = [
//     pro3F1Charge.sep21,
//     pro3F2Charge.sep21,
//     pro3F3Charge.sep21
// ]
// export const pro3WithSep21Pay = [
//     pro3F1Payment.sep21,
//     pro3F2Payment.sep21,
//     pro3F3Payment.sep21
// ]
// export const pro3WithSep21Count = [
//     pro3F1Collectable.sep21,
//     pro3F2Collectable.sep21,
//     pro3F3Collectable.sep21
// ]
// export const pro3WithSep21NonCount = [
//     pro3F1NonCollectable.sep21,
//     pro3F2NonCollectable.sep21,
//     pro3F3NonCollectable.sep21
// ]
// export const pro3WithSep21Claim = [
//     pro3F1ClaimCount.sep21,
//     pro3F2ClaimCount.sep21,
//     pro3F3ClaimCount.sep21
// ]
// export const pro3WithSep21PerVisit = [
//     pro3F1PerVisit.sep21,
//     pro3F2PerVisit.sep21,
//     pro3F3PerVisit.sep21
// ]
// // oct 21
// export const pro3WithOct21Charges = [
//     pro3F1Charge.oct21,
//     pro3F2Charge.oct21,
//     pro3F3Charge.oct21
// ]
// export const pro3WithOct21Pay = [
//     pro3F1Payment.oct21,
//     pro3F2Payment.oct21,
//     pro3F3Payment.oct21
// ]
// export const pro3WithOct21Count = [
//     pro3F1Collectable.oct21,
//     pro3F2Collectable.oct21,
//     pro3F3Collectable.oct21
// ]
// export const pro3WithOct21NonCount = [
//     pro3F1NonCollectable.oct21,
//     pro3F2NonCollectable.oct21,
//     pro3F3NonCollectable.oct21
// ]
// export const pro3WithOct21Claim = [
//     pro3F1ClaimCount.oct21,
//     pro3F2ClaimCount.oct21,
//     pro3F3ClaimCount.oct21
// ]
// export const pro3WithOct21PerVisit = [
//     pro3F1PerVisit.oct21,
//     pro3F2PerVisit.oct21,
//     pro3F3PerVisit.oct21
// ]
// // nov 21
// export const pro3WithNov21Charges = [
//     pro3F1Charge.nov21,
//     pro3F2Charge.nov21,
//     pro3F3Charge.nov21
// ]
// export const pro3WithNov21Pay = [
//     pro3F1Payment.nov21,
//     pro3F2Payment.nov21,
//     pro3F3Payment.nov21
// ]
// export const pro3WithNov21Count = [
//     pro3F1Collectable.nov21,
//     pro3F2Collectable.nov21,
//     pro3F3Collectable.nov21
// ]
// export const pro3WithNov21NonCount = [
//     pro3F1NonCollectable.nov21,
//     pro3F2NonCollectable.nov21,
//     pro3F3NonCollectable.nov21
// ]
// export const pro3WithNov21Claim = [
//     pro3F1ClaimCount.nov21,
//     pro3F2ClaimCount.nov21,
//     pro3F3ClaimCount.nov21
// ]
// export const pro3WithNov21PerVisit = [
//     pro3F1PerVisit.nov21,
//     pro3F2PerVisit.nov21,
//     pro3F3PerVisit.nov21
// ]
// // dec 21
// export const pro3WithDec21Charges = [
//     pro3F1Charge.dec21,
//     pro3F2Charge.dec21,
//     pro3F3Charge.dec21
// ]
// export const pro3WithDec21Pay = [
//     pro3F1Payment.dec21,
//     pro3F2Payment.dec21,
//     pro3F3Payment.dec21
// ]
// export const pro3WithDec21Count = [
//     pro3F1Collectable.dec21,
//     pro3F2Collectable.dec21,
//     pro3F3Collectable.dec21
// ]
// export const pro3WithDec21NonCount = [
//     pro3F1NonCollectable.dec21,
//     pro3F2NonCollectable.dec21,
//     pro3F3NonCollectable.dec21
// ]
// export const pro3WithDec21Claim = [
//     pro3F1ClaimCount.dec21,
//     pro3F2ClaimCount.dec21,
//     pro3F3ClaimCount.dec21
// ]
// export const pro3WithDec21PerVisit = [
//     pro3F1PerVisit.dec21,
//     pro3F2PerVisit.dec21,
//     pro3F3PerVisit.dec21
// ]

// // provider-3 
// // jan 22
// export const pro3WithJan22Charges = [
//     pro3F1Charge.jan22,
//     pro3F2Charge.jan22,
//     pro3F3Charge.jan22
// ]
// export const pro3WithJan22Pay = [
//     pro3F1Payment.jan22,
//     pro3F2Payment.jan22,
//     pro3F3Payment.jan22
// ]
// export const pro3WithJan22Count = [
//     pro3F1Collectable.jan22,
//     pro3F2Collectable.jan22,
//     pro3F3Collectable.jan22
// ]
// export const pro3WithJan22NonCount = [
//     pro3F1NonCollectable.jan22,
//     pro3F2NonCollectable.jan22,
//     pro3F3NonCollectable.jan22
// ]
// export const pro3WithJan22Claim = [
//     pro3F1ClaimCount.jan22,
//     pro3F2ClaimCount.jan22,
//     pro3F3ClaimCount.jan22
// ]
// export const pro3WithJan22PerVisit = [
//     pro3F1PerVisit.jan22,
//     pro3F2PerVisit.jan22,
//     pro3F3PerVisit.jan22
// ]
// // feb 22
// export const pro3WithFeb22Charges = [
//     pro3F1Charge.feb22,
//     pro3F2Charge.feb22,
//     pro3F3Charge.feb22
// ]
// export const pro3WithFeb22Pay = [
//     pro3F1Payment.feb22,
//     pro3F2Payment.feb22,
//     pro3F3Payment.feb22
// ]
// export const pro3WithFeb22Count = [
//     pro3F1Collectable.feb22,
//     pro3F2Collectable.feb22,
//     pro3F3Collectable.feb22
// ]
// export const pro3WithFeb22NonCount = [
//     pro3F1NonCollectable.feb22,
//     pro3F2NonCollectable.feb22,
//     pro3F3NonCollectable.feb22
// ]
// export const pro3WithFeb22Claim = [
//     pro3F1ClaimCount.feb22,
//     pro3F2ClaimCount.feb22,
//     pro3F3ClaimCount.feb22
// ]
// export const pro3WithFeb22PerVisit = [
//     pro3F1PerVisit.feb22,
//     pro3F2PerVisit.feb22,
//     pro3F3PerVisit.feb22
// ]
// // mar 22
// export const pro3WithMar22Charges = [
//     pro3F1Charge.mar22,
//     pro3F2Charge.mar22,
//     pro3F3Charge.mar22
// ]
// export const pro3WithMar22Pay = [
//     pro3F1Payment.mar22,
//     pro3F2Payment.mar22,
//     pro3F3Payment.mar22
// ]
// export const pro3WithMar22Count = [
//     pro3F1Collectable.mar22,
//     pro3F2Collectable.mar22,
//     pro3F3Collectable.mar22
// ]
// export const pro3WithMar22NonCount = [
//     pro3F1NonCollectable.mar22,
//     pro3F2NonCollectable.mar22,
//     pro3F3NonCollectable.mar22
// ]
// export const pro3WithMar22Claim = [
//     pro3F1ClaimCount.mar22,
//     pro3F2ClaimCount.mar22,
//     pro3F3ClaimCount.mar22
// ]
// export const pro3WithMar22PerVisit = [
//     pro3F1PerVisit.mar22,
//     pro3F2PerVisit.mar22,
//     pro3F3PerVisit.mar22
// ]
// // april 22
// export const pro3WithApr22Charges = [
//     pro3F1Charge.apr22,
//     pro3F2Charge.apr22,
//     pro3F3Charge.apr22
// ]
// export const pro3WithApr22Pay = [
//     pro3F1Payment.apr22,
//     pro3F2Payment.apr22,
//     pro3F3Payment.apr22
// ]
// export const pro3WithApr22Count = [
//     pro3F1Collectable.apr22,
//     pro3F2Collectable.apr22,
//     pro3F3Collectable.apr22
// ]
// export const pro3WithApr22NonCount = [
//     pro3F1NonCollectable.apr22,
//     pro3F2NonCollectable.apr22,
//     pro3F3NonCollectable.apr22
// ]
// export const pro3WithApr22Claim = [
//     pro3F1ClaimCount.apr22,
//     pro3F2ClaimCount.apr22,
//     pro3F3ClaimCount.apr22
// ]
// export const pro3WithApr22PerVisit = [
//     pro3F1PerVisit.apr22,
//     pro3F2PerVisit.apr22,
//     pro3F3PerVisit.apr22
// ]
// // may 22
// export const pro3WithMay22Charges = [
//     pro3F1Charge.may22,
//     pro3F2Charge.may22,
//     pro3F3Charge.may22
// ]
// export const pro3WithMay22Pay = [
//     pro3F1Payment.may22,
//     pro3F2Payment.may22,
//     pro3F3Payment.may22
// ]
// export const pro3WithMay22Count = [
//     pro3F1Collectable.may22,
//     pro3F2Collectable.may22,
//     pro3F3Collectable.may22
// ]
// export const pro3WithMay22NonCount = [
//     pro3F1NonCollectable.may22,
//     pro3F2NonCollectable.may22,
//     pro3F3NonCollectable.may22
// ]
// export const pro3WithMay22Claim = [
//     pro3F1ClaimCount.may22,
//     pro3F2ClaimCount.may22,
//     pro3F3ClaimCount.may22
// ]
// export const pro3WithMay22PerVisit = [
//     pro3F1PerVisit.may22,
//     pro3F2PerVisit.may22,
//     pro3F3PerVisit.may22
// ]
// // jun 22
// export const pro3WithJun22Charges = [
//     pro3F1Charge.jun22,
//     pro3F2Charge.jun22,
//     pro3F3Charge.jun22
// ]
// export const pro3WithJun22Pay = [
//     pro3F1Payment.jun22,
//     pro3F2Payment.jun22,
//     pro3F3Payment.jun22
// ]
// export const pro3WithJun22Count = [
//     pro3F1Collectable.jun22,
//     pro3F2Collectable.jun22,
//     pro3F3Collectable.jun22
// ]
// export const pro3WithJun22NonCount = [
//     pro3F1NonCollectable.jun22,
//     pro3F2NonCollectable.jun22,
//     pro3F3NonCollectable.jun22
// ]
// export const pro3WithJun22Claim = [
//     pro3F1ClaimCount.jun22,
//     pro3F2ClaimCount.jun22,
//     pro3F3ClaimCount.jun22
// ]
// export const pro3WithJun22PerVisit = [
//     pro3F1PerVisit.jun22,
//     pro3F2PerVisit.jun22,
//     pro3F3PerVisit.jun22
// ]
// // jul 22
// export const pro3WithJul22Charges = [
//     pro3F1Charge.jul22,
//     pro3F2Charge.jul22,
//     pro3F3Charge.jul22
// ]
// export const pro3WithJul22Pay = [
//     pro3F1Payment.jul22,
//     pro3F2Payment.jul22,
//     pro3F3Payment.jul22
// ]
// export const pro3WithJul22Count = [
//     pro3F1Collectable.jul22,
//     pro3F2Collectable.jul22,
//     pro3F3Collectable.jul22
// ]
// export const pro3WithJul22NonCount = [
//     pro3F1NonCollectable.jul22,
//     pro3F2NonCollectable.jul22,
//     pro3F3NonCollectable.jul22
// ]
// export const pro3WithJul22Claim = [
//     pro3F1ClaimCount.jul22,
//     pro3F2ClaimCount.jul22,
//     pro3F3ClaimCount.jul22
// ]
// export const pro3WithJul22PerVisit = [
//     pro3F1PerVisit.jul22,
//     pro3F2PerVisit.jul22,
//     pro3F3PerVisit.jul22
// ]
// // aug 22
// export const pro3WithAug22Charges = [
//     pro3F1Charge.aug22,
//     pro3F2Charge.aug22,
//     pro3F3Charge.aug22
// ]
// export const pro3WithAug22Pay = [
//     pro3F1Payment.aug22,
//     pro3F2Payment.aug22,
//     pro3F3Payment.aug22
// ]
// export const pro3WithAug22Count = [
//     pro3F1Collectable.aug22,
//     pro3F2Collectable.aug22,
//     pro3F3Collectable.aug22
// ]
// export const pro3WithAug22NonCount = [
//     pro3F1NonCollectable.aug22,
//     pro3F2NonCollectable.aug22,
//     pro3F3NonCollectable.aug22
// ]
// export const pro3WithAug22Claim = [
//     pro3F1ClaimCount.aug22,
//     pro3F2ClaimCount.aug22,
//     pro3F3ClaimCount.aug22
// ]
// export const pro3WithAug22PerVisit = [
//     pro3F1PerVisit.aug22,
//     pro3F2PerVisit.aug22,
//     pro3F3PerVisit.aug22
// ]
// // sep 22
// export const pro3WithSep22Charges = [
//     pro3F1Charge.sep22,
//     pro3F2Charge.sep22,
//     pro3F3Charge.sep22
// ]
// export const pro3WithSep22Pay = [
//     pro3F1Payment.sep22,
//     pro3F2Payment.sep22,
//     pro3F3Payment.sep22
// ]
// export const pro3WithSep22Count = [
//     pro3F1Collectable.sep22,
//     pro3F2Collectable.sep22,
//     pro3F3Collectable.sep22
// ]
// export const pro3WithSep22NonCount = [
//     pro3F1NonCollectable.sep22,
//     pro3F2NonCollectable.sep22,
//     pro3F3NonCollectable.sep22
// ]
// export const pro3WithSep22Claim = [
//     pro3F1ClaimCount.sep22,
//     pro3F2ClaimCount.sep22,
//     pro3F3ClaimCount.sep22
// ]
// export const pro3WithSep22PerVisit = [
//     pro3F1PerVisit.sep22,
//     pro3F2PerVisit.sep22,
//     pro3F3PerVisit.sep22
// ]
// // oct 22
// export const pro3WithOct22Charges = [
//     pro3F1Charge.oct22,
//     pro3F2Charge.oct22,
//     pro3F3Charge.oct22
// ]
// export const pro3WithOct22Pay = [
//     pro3F1Payment.oct22,
//     pro3F2Payment.oct22,
//     pro3F3Payment.oct22
// ]
// export const pro3WithOct22Count = [
//     pro3F1Collectable.oct22,
//     pro3F2Collectable.oct22,
//     pro3F3Collectable.oct22
// ]
// export const pro3WithOct22NonCount = [
//     pro3F1NonCollectable.oct22,
//     pro3F2NonCollectable.oct22,
//     pro3F3NonCollectable.oct22
// ]
// export const pro3WithOct22Claim = [
//     pro3F1ClaimCount.oct22,
//     pro3F2ClaimCount.oct22,
//     pro3F3ClaimCount.oct22
// ]
// export const pro3WithOct22PerVisit = [
//     pro3F1PerVisit.oct22,
//     pro3F2PerVisit.oct22,
//     pro3F3PerVisit.oct22
// ]
// // nov 22
// export const pro3WithNov22Charges = [
//     pro3F1Charge.nov22,
//     pro3F2Charge.nov22,
//     pro3F3Charge.nov22
// ]
// export const pro3WithNov22Pay = [
//     pro3F1Payment.nov22,
//     pro3F2Payment.nov22,
//     pro3F3Payment.nov22
// ]
// export const pro3WithNov22Count = [
//     pro3F1Collectable.nov22,
//     pro3F2Collectable.nov22,
//     pro3F3Collectable.nov22
// ]
// export const pro3WithNov22NonCount = [
//     pro3F1NonCollectable.nov22,
//     pro3F2NonCollectable.nov22,
//     pro3F3NonCollectable.nov22
// ]
// export const pro3WithNov22Claim = [
//     pro3F1ClaimCount.nov22,
//     pro3F2ClaimCount.nov22,
//     pro3F3ClaimCount.nov22
// ]
// export const pro3WithNov22PerVisit = [
//     pro3F1PerVisit.nov22,
//     pro3F2PerVisit.nov22,
//     pro3F3PerVisit.nov22
// ]
// // dec 22
// export const pro3WithDec22Charges = [
//     pro3F1Charge.dec22,
//     pro3F2Charge.dec22,
//     pro3F3Charge.dec22
// ]
// export const pro3WithDec22Pay = [
//     pro3F1Payment.dec22,
//     pro3F2Payment.dec22,
//     pro3F3Payment.dec22
// ]
// export const pro3WithDec22Count = [
//     pro3F1Collectable.dec22,
//     pro3F2Collectable.dec22,
//     pro3F3Collectable.dec22
// ]
// export const pro3WithDec22NonCount = [
//     pro3F1NonCollectable.dec22,
//     pro3F2NonCollectable.dec22,
//     pro3F3NonCollectable.dec22
// ]
// export const pro3WithDec22Claim = [
//     pro3F1ClaimCount.dec22,
//     pro3F2ClaimCount.dec22,
//     pro3F3ClaimCount.dec22
// ]
// export const pro3WithDec22PerVisit = [
//     pro3F1PerVisit.dec22,
//     pro3F2PerVisit.dec22,
//     pro3F3PerVisit.dec22
// ]

// // provider-3
// // jan 23
// export const pro3WithJan23Charges = [
//     pro3F1Charge.jan23,
//     pro3F2Charge.jan23,
//     pro3F3Charge.jan23
// ]
// export const pro3WithJan23Pay = [
//     pro3F1Payment.jan23,
//     pro3F2Payment.jan23,
//     pro3F3Payment.jan23
// ]
// export const pro3WithJan23Count = [
//     pro3F1Collectable.jan23,
//     pro3F2Collectable.jan23,
//     pro3F3Collectable.jan23
// ]
// export const pro3WithJan23NonCount = [
//     pro3F1NonCollectable.jan23,
//     pro3F2NonCollectable.jan23,
//     pro3F3NonCollectable.jan23
// ]
// export const pro3WithJan23Claim = [
//     pro3F1ClaimCount.jan23,
//     pro3F2ClaimCount.jan23,
//     pro3F3ClaimCount.jan23
// ]
// export const pro3WithJan23PerVisit = [
//     pro3F1PerVisit.jan23,
//     pro3F2PerVisit.jan23,
//     pro3F3PerVisit.jan23
// ]
// // feb 23
// export const pro3WithFeb23Charges = [
//     pro3F1Charge.feb23,
//     pro3F2Charge.feb23,
//     pro3F3Charge.feb23
// ]
// export const pro3WithFeb23Pay = [
//     pro3F1Payment.feb23,
//     pro3F2Payment.feb23,
//     pro3F3Payment.feb23
// ]
// export const pro3WithFeb23Count = [
//     pro3F1Collectable.feb23,
//     pro3F2Collectable.feb23,
//     pro3F3Collectable.feb23
// ]
// export const pro3WithFeb23NonCount = [
//     pro3F1NonCollectable.feb23,
//     pro3F2NonCollectable.feb23,
//     pro3F3NonCollectable.feb23
// ]
// export const pro3WithFeb23Claim = [
//     pro3F1ClaimCount.feb23,
//     pro3F2ClaimCount.feb23,
//     pro3F3ClaimCount.feb23
// ]
// export const pro3WithFeb23PerVisit = [
//     pro3F1PerVisit.feb23,
//     pro3F2PerVisit.feb23,
//     pro3F3PerVisit.feb23
// ]
// // mar 23
// export const pro3WithMar23Charges = [
//     pro3F1Charge.mar23,
//     pro3F2Charge.mar23,
//     pro3F3Charge.mar23
// ]
// export const pro3WithMar23Pay = [
//     pro3F1Payment.mar23,
//     pro3F2Payment.mar23,
//     pro3F3Payment.mar23
// ]
// export const pro3WithMar23Count = [
//     pro3F1Collectable.mar23,
//     pro3F2Collectable.mar23,
//     pro3F3Collectable.mar23
// ]
// export const pro3WithMar23NonCount = [
//     pro3F1NonCollectable.mar23,
//     pro3F2NonCollectable.mar23,
//     pro3F3NonCollectable.mar23
// ]
// export const pro3WithMar23Claim = [
//     pro3F1ClaimCount.mar23,
//     pro3F2ClaimCount.mar23,
//     pro3F3ClaimCount.mar23
// ]
// export const pro3WithMar23PerVisit = [
//     pro3F1PerVisit.mar23,
//     pro3F2PerVisit.mar23,
//     pro3F3PerVisit.mar23
// ]
// // april 23
// export const pro3WithApr23Charges = [
//     pro3F1Charge.apr23,
//     pro3F2Charge.apr23,
//     pro3F3Charge.apr23
// ]
// export const pro3WithApr23Pay = [
//     pro3F1Payment.apr23,
//     pro3F2Payment.apr23,
//     pro3F3Payment.apr23
// ]
// export const pro3WithApr23Count = [
//     pro3F1Collectable.apr23,
//     pro3F2Collectable.apr23,
//     pro3F3Collectable.apr23
// ]
// export const pro3WithApr23NonCount = [
//     pro3F1NonCollectable.apr23,
//     pro3F2NonCollectable.apr23,
//     pro3F3NonCollectable.apr23
// ]
// export const pro3WithApr23Claim = [
//     pro3F1ClaimCount.apr23,
//     pro3F2ClaimCount.apr23,
//     pro3F3ClaimCount.apr23
// ]
// export const pro3WithApr23PerVisit = [
//     pro3F1PerVisit.apr23,
//     pro3F2PerVisit.apr23,
//     pro3F3PerVisit.apr23
// ]
// // may 23
// export const pro3WithMay23Charges = [
//     pro3F1Charge.may23,
//     pro3F2Charge.may23,
//     pro3F3Charge.may23
// ]
// export const pro3WithMay23Pay = [
//     pro3F1Payment.may23,
//     pro3F2Payment.may23,
//     pro3F3Payment.may23
// ]
// export const pro3WithMay23Count = [
//     pro3F1Collectable.may23,
//     pro3F2Collectable.may23,
//     pro3F3Collectable.may23
// ]
// export const pro3WithMay23NonCount = [
//     pro3F1NonCollectable.may23,
//     pro3F2NonCollectable.may23,
//     pro3F3NonCollectable.may23
// ]
// export const pro3WithMay23Claim = [
//     pro3F1ClaimCount.may23,
//     pro3F2ClaimCount.may23,
//     pro3F3ClaimCount.may23
// ]
// export const pro3WithMay23PerVisit = [
//     pro3F1PerVisit.may23,
//     pro3F2PerVisit.may23,
//     pro3F3PerVisit.may23
// ]
// // jun 23
// export const pro3WithJun23Charges = [
//     pro3F1Charge.jun23,
//     pro3F2Charge.jun23,
//     pro3F3Charge.jun23
// ]
// export const pro3WithJun23Pay = [
//     pro3F1Payment.jun23,
//     pro3F2Payment.jun23,
//     pro3F3Payment.jun23
// ]
// export const pro3WithJun23Count = [
//     pro3F1Collectable.jun23,
//     pro3F2Collectable.jun23,
//     pro3F3Collectable.jun23
// ]
// export const pro3WithJun23NonCount = [
//     pro3F1NonCollectable.jun23,
//     pro3F2NonCollectable.jun23,
//     pro3F3NonCollectable.jun23
// ]
// export const pro3WithJun23Claim = [
//     pro3F1ClaimCount.jun23,
//     pro3F2ClaimCount.jun23,
//     pro3F3ClaimCount.jun23
// ]
// export const pro3WithJun23PerVisit = [
//     pro3F1PerVisit.jun23,
//     pro3F2PerVisit.jun23,
//     pro3F3PerVisit.jun23
// ]
// // jul 23
// export const pro3WithJul23Charges = [
//     pro3F1Charge.jul23,
//     pro3F2Charge.jul23,
//     pro3F3Charge.jul23
// ]
// export const pro3WithJul23Pay = [
//     pro3F1Payment.jul23,
//     pro3F2Payment.jul23,
//     pro3F3Payment.jul23
// ]
// export const pro3WithJul23Count = [
//     pro3F1Collectable.jul23,
//     pro3F2Collectable.jul23,
//     pro3F3Collectable.jul23
// ]
// export const pro3WithJul23NonCount = [
//     pro3F1NonCollectable.jul23,
//     pro3F2NonCollectable.jul23,
//     pro3F3NonCollectable.jul23
// ]
// export const pro3WithJul23Claim = [
//     pro3F1ClaimCount.jul23,
//     pro3F2ClaimCount.jul23,
//     pro3F3ClaimCount.jul23
// ]
// export const pro3WithJul23PerVisit = [
//     pro3F1PerVisit.jul23,
//     pro3F2PerVisit.jul23,
//     pro3F3PerVisit.jul23
// ]
// // aug 23
// export const pro3WithAug23Charges = [
//     pro3F1Charge.aug23,
//     pro3F2Charge.aug23,
//     pro3F3Charge.aug23
// ]
// export const pro3WithAug23Pay = [
//     pro3F1Payment.aug23,
//     pro3F2Payment.aug23,
//     pro3F3Payment.aug23
// ]
// export const pro3WithAug23Count = [
//     pro3F1Collectable.aug23,
//     pro3F2Collectable.aug23,
//     pro3F3Collectable.aug23
// ]
// export const pro3WithAug23NonCount = [
//     pro3F1NonCollectable.aug23,
//     pro3F2NonCollectable.aug23,
//     pro3F3NonCollectable.aug23
// ]
// export const pro3WithAug23Claim = [
//     pro3F1ClaimCount.aug23,
//     pro3F2ClaimCount.aug23,
//     pro3F3ClaimCount.aug23
// ]
// export const pro3WithAug23PerVisit = [
//     pro3F1PerVisit.aug23,
//     pro3F2PerVisit.aug23,
//     pro3F3PerVisit.aug23
// ]
// // sep 23
// export const pro3WithSep23Charges = [
//     pro3F1Charge.sep23,
//     pro3F2Charge.sep23,
//     pro3F3Charge.sep23
// ]
// export const pro3WithSep23Pay = [
//     pro3F1Payment.sep23,
//     pro3F2Payment.sep23,
//     pro3F3Payment.sep23
// ]
// export const pro3WithSep23Count = [
//     pro3F1Collectable.sep23,
//     pro3F2Collectable.sep23,
//     pro3F3Collectable.sep23
// ]
// export const pro3WithSep23NonCount = [
//     pro3F1NonCollectable.sep23,
//     pro3F2NonCollectable.sep23,
//     pro3F3NonCollectable.sep23
// ]
// export const pro3WithSep23Claim = [
//     pro3F1ClaimCount.sep23,
//     pro3F2ClaimCount.sep23,
//     pro3F3ClaimCount.sep23
// ]
// export const pro3WithSep23PerVisit = [
//     pro3F1PerVisit.sep23,
//     pro3F2PerVisit.sep23,
//     pro3F3PerVisit.sep23
// ]
// // oct 23
// export const pro3WithOct23Charges = [
//     pro3F1Charge.oct23,
//     pro3F2Charge.oct23,
//     pro3F3Charge.oct23
// ]
// export const pro3WithOct23Pay = [
//     pro3F1Payment.oct23,
//     pro3F2Payment.oct23,
//     pro3F3Payment.oct23
// ]
// export const pro3WithOct23Count = [
//     pro3F1Collectable.oct23,
//     pro3F2Collectable.oct23,
//     pro3F3Collectable.oct23
// ]
// export const pro3WithOct23NonCount = [
//     pro3F1NonCollectable.oct23,
//     pro3F2NonCollectable.oct23,
//     pro3F3NonCollectable.oct23
// ]
// export const pro3WithOct23Claim = [
//     pro3F1ClaimCount.oct23,
//     pro3F2ClaimCount.oct23,
//     pro3F3ClaimCount.oct23
// ]
// export const pro3WithOct23PerVisit = [
//     pro3F1PerVisit.oct23,
//     pro3F2PerVisit.oct23,
//     pro3F3PerVisit.oct23
// ]
// // nov 23
// export const pro3WithNov23Charges = [
//     pro3F1Charge.nov23,
//     pro3F2Charge.nov23,
//     pro3F3Charge.nov23
// ]
// export const pro3WithNov23Pay = [
//     pro3F1Payment.nov23,
//     pro3F2Payment.nov23,
//     pro3F3Payment.nov23
// ]
// export const pro3WithNov23Count = [
//     pro3F1Collectable.nov23,
//     pro3F2Collectable.nov23,
//     pro3F3Collectable.nov23
// ]
// export const pro3WithNov23NonCount = [
//     pro3F1NonCollectable.nov23,
//     pro3F2NonCollectable.nov23,
//     pro3F3NonCollectable.nov23
// ]
// export const pro3WithNov23Claim = [
//     pro3F1ClaimCount.nov23,
//     pro3F2ClaimCount.nov23,
//     pro3F3ClaimCount.nov23
// ]
// export const pro3WithNov23PerVisit = [
//     pro3F1PerVisit.nov23,
//     pro3F2PerVisit.nov23,
//     pro3F3PerVisit.nov23
// ]


// provider-4
// jan 21
// export const pro4WithJan21Charges = [
//     pro4F1Charge.jan21,
//     pro4F2Charge.jan21,
//     pro4F3Charge.jan21
// ]
// export const pro4WithJan21Pay = [
//     pro4F1Payment.jan21,
//     pro4F2Payment.jan21,
//     pro4F3Payment.jan21
// ]
// export const pro4WithJan21Count = [
//     pro4F1Collectable.jan21,
//     pro4F2Collectable.jan21,
//     pro4F3Collectable.jan21
// ]
// export const pro4WithJan21NonCount = [
//     pro4F1NonCollectable.jan21,
//     pro4F2NonCollectable.jan21,
//     pro4F3NonCollectable.jan21
// ]
// export const pro4WithJan21Claim = [
//     pro4F1ClaimCount.jan21,
//     pro4F2ClaimCount.jan21,
//     pro4F3ClaimCount.jan21
// ]
// export const pro4WithJan21PerVisit = [
//     pro4F1PerVisit.jan21,
//     pro4F2PerVisit.jan21,
//     pro4F3PerVisit.jan21
// ]
// // feb 21
// export const pro4WithFeb21Charges = [
//     pro4F1Charge.feb21,
//     pro4F2Charge.feb21,
//     pro4F3Charge.feb21
// ]
// export const pro4WithFeb21Pay = [
//     pro4F1Payment.feb21,
//     pro4F2Payment.feb21,
//     pro4F3Payment.feb21
// ]
// export const pro4WithFeb21Count = [
//     pro4F1Collectable.feb21,
//     pro4F2Collectable.feb21,
//     pro4F3Collectable.feb21
// ]
// export const pro4WithFeb21NonCount = [
//     pro4F1NonCollectable.feb21,
//     pro4F2NonCollectable.feb21,
//     pro4F3NonCollectable.feb21
// ]
// export const pro4WithFeb21Claim = [
//     pro4F1ClaimCount.feb21,
//     pro4F2ClaimCount.feb21,
//     pro4F3ClaimCount.feb21
// ]
// export const pro4WithFeb21PerVisit = [
//     pro4F1PerVisit.feb21,
//     pro4F2PerVisit.feb21,
//     pro4F3PerVisit.feb21
// ]
// // mar 21
// export const pro4WithMar21Charges = [
//     pro4F1Charge.mar21,
//     pro4F2Charge.mar21,
//     pro4F3Charge.mar21
// ]
// export const pro4WithMar21Pay = [
//     pro4F1Payment.mar21,
//     pro4F2Payment.mar21,
//     pro4F3Payment.mar21
// ]
// export const pro4WithMar21Count = [
//     pro4F1Collectable.mar21,
//     pro4F2Collectable.mar21,
//     pro4F3Collectable.mar21
// ]
// export const pro4WithMar21NonCount = [
//     pro4F1NonCollectable.mar21,
//     pro4F2NonCollectable.mar21,
//     pro4F3NonCollectable.mar21
// ]
// export const pro4WithMar21Claim = [
//     pro4F1ClaimCount.mar21,
//     pro4F2ClaimCount.mar21,
//     pro4F3ClaimCount.mar21
// ]
// export const pro4WithMar21PerVisit = [
//     pro4F1PerVisit.mar21,
//     pro4F2PerVisit.mar21,
//     pro4F3PerVisit.mar21
// ]
// // april 21
// export const pro4WithApr21Charges = [
//     pro4F1Charge.apr21,
//     pro4F2Charge.apr21,
//     pro4F3Charge.apr21
// ]
// export const pro4WithApr21Pay = [
//     pro4F1Payment.apr21,
//     pro4F2Payment.apr21,
//     pro4F3Payment.apr21
// ]
// export const pro4WithApr21Count = [
//     pro4F1Collectable.apr21,
//     pro4F2Collectable.apr21,
//     pro4F3Collectable.apr21
// ]
// export const pro4WithApr21NonCount = [
//     pro4F1NonCollectable.apr21,
//     pro4F2NonCollectable.apr21,
//     pro4F3NonCollectable.apr21
// ]
// export const pro4WithApr21Claim = [
//     pro4F1ClaimCount.apr21,
//     pro4F2ClaimCount.apr21,
//     pro4F3ClaimCount.apr21
// ]
// export const pro4WithApr21PerVisit = [
//     pro4F1PerVisit.apr21,
//     pro4F2PerVisit.apr21,
//     pro4F3PerVisit.apr21
// ]
// // may 21
// export const pro4WithMay21Charges = [
//     pro4F1Charge.may21,
//     pro4F2Charge.may21,
//     pro4F3Charge.may21
// ]
// export const pro4WithMay21Pay = [
//     pro4F1Payment.may21,
//     pro4F2Payment.may21,
//     pro4F3Payment.may21
// ]
// export const pro4WithMay21Count = [
//     pro4F1Collectable.may21,
//     pro4F2Collectable.may21,
//     pro4F3Collectable.may21
// ]
// export const pro4WithMay21NonCount = [
//     pro4F1NonCollectable.may21,
//     pro4F2NonCollectable.may21,
//     pro4F3NonCollectable.may21
// ]
// export const pro4WithMay21Claim = [
//     pro4F1ClaimCount.may21,
//     pro4F2ClaimCount.may21,
//     pro4F3ClaimCount.may21
// ]
// export const pro4WithMay21PerVisit = [
//     pro4F1PerVisit.may21,
//     pro4F2PerVisit.may21,
//     pro4F3PerVisit.may21
// ]
// // jun 21
// export const pro4WithJun21Charges = [
//     pro4F1Charge.jun21,
//     pro4F2Charge.jun21,
//     pro4F3Charge.jun21
// ]
// export const pro4WithJun21Pay = [
//     pro4F1Payment.jun21,
//     pro4F2Payment.jun21,
//     pro4F3Payment.jun21
// ]
// export const pro4WithJun21Count = [
//     pro4F1Collectable.jun21,
//     pro4F2Collectable.jun21,
//     pro4F3Collectable.jun21
// ]
// export const pro4WithJun21NonCount = [
//     pro4F1NonCollectable.jun21,
//     pro4F2NonCollectable.jun21,
//     pro4F3NonCollectable.jun21
// ]
// export const pro4WithJun21Claim = [
//     pro4F1ClaimCount.jun21,
//     pro4F2ClaimCount.jun21,
//     pro4F3ClaimCount.jun21
// ]
// export const pro4WithJun21PerVisit = [
//     pro4F1PerVisit.jun21,
//     pro4F2PerVisit.jun21,
//     pro4F3PerVisit.jun21
// ]
// // jul 21
// export const pro4WithJul21Charges = [
//     pro4F1Charge.jul21,
//     pro4F2Charge.jul21,
//     pro4F3Charge.jul21
// ]
// export const pro4WithJul21Pay = [
//     pro4F1Payment.jul21,
//     pro4F2Payment.jul21,
//     pro4F3Payment.jul21
// ]
// export const pro4WithJul21Count = [
//     pro4F1Collectable.jul21,
//     pro4F2Collectable.jul21,
//     pro4F3Collectable.jul21
// ]
// export const pro4WithJul21NonCount = [
//     pro4F1NonCollectable.jul21,
//     pro4F2NonCollectable.jul21,
//     pro4F3NonCollectable.jul21
// ]
// export const pro4WithJul21Claim = [
//     pro4F1ClaimCount.jul21,
//     pro4F2ClaimCount.jul21,
//     pro4F3ClaimCount.jul21
// ]
// export const pro4WithJul21PerVisit = [
//     pro4F1PerVisit.jul21,
//     pro4F2PerVisit.jul21,
//     pro4F3PerVisit.jul21
// ]
// // aug 21
// export const pro4WithAug21Charges = [
//     pro4F1Charge.aug21,
//     pro4F2Charge.aug21,
//     pro4F3Charge.aug21
// ]
// export const pro4WithAug21Pay = [
//     pro4F1Payment.aug21,
//     pro4F2Payment.aug21,
//     pro4F3Payment.aug21
// ]
// export const pro4WithAug21Count = [
//     pro4F1Collectable.aug21,
//     pro4F2Collectable.aug21,
//     pro4F3Collectable.aug21
// ]
// export const pro4WithAug21NonCount = [
//     pro4F1NonCollectable.aug21,
//     pro4F2NonCollectable.aug21,
//     pro4F3NonCollectable.aug21
// ]
// export const pro4WithAug21Claim = [
//     pro4F1ClaimCount.aug21,
//     pro4F2ClaimCount.aug21,
//     pro4F3ClaimCount.aug21
// ]
// export const pro4WithAug21PerVisit = [
//     pro4F1PerVisit.aug21,
//     pro4F2PerVisit.aug21,
//     pro4F3PerVisit.aug21
// ]
// // sep 21
// export const pro4WithSep21Charges = [
//     pro4F1Charge.sep21,
//     pro4F2Charge.sep21,
//     pro4F3Charge.sep21
// ]
// export const pro4WithSep21Pay = [
//     pro4F1Payment.sep21,
//     pro4F2Payment.sep21,
//     pro4F3Payment.sep21
// ]
// export const pro4WithSep21Count = [
//     pro4F1Collectable.sep21,
//     pro4F2Collectable.sep21,
//     pro4F3Collectable.sep21
// ]
// export const pro4WithSep21NonCount = [
//     pro4F1NonCollectable.sep21,
//     pro4F2NonCollectable.sep21,
//     pro4F3NonCollectable.sep21
// ]
// export const pro4WithSep21Claim = [
//     pro4F1ClaimCount.sep21,
//     pro4F2ClaimCount.sep21,
//     pro4F3ClaimCount.sep21
// ]
// export const pro4WithSep21PerVisit = [
//     pro4F1PerVisit.sep21,
//     pro4F2PerVisit.sep21,
//     pro4F3PerVisit.sep21
// ]
// // oct 21
// export const pro4WithOct21Charges = [
//     pro4F1Charge.oct21,
//     pro4F2Charge.oct21,
//     pro4F3Charge.oct21
// ]
// export const pro4WithOct21Pay = [
//     pro4F1Payment.oct21,
//     pro4F2Payment.oct21,
//     pro4F3Payment.oct21
// ]
// export const pro4WithOct21Count = [
//     pro4F1Collectable.oct21,
//     pro4F2Collectable.oct21,
//     pro4F3Collectable.oct21
// ]
// export const pro4WithOct21NonCount = [
//     pro4F1NonCollectable.oct21,
//     pro4F2NonCollectable.oct21,
//     pro4F3NonCollectable.oct21
// ]
// export const pro4WithOct21Claim = [
//     pro4F1ClaimCount.oct21,
//     pro4F2ClaimCount.oct21,
//     pro4F3ClaimCount.oct21
// ]
// export const pro4WithOct21PerVisit = [
//     pro4F1PerVisit.oct21,
//     pro4F2PerVisit.oct21,
//     pro4F3PerVisit.oct21
// ]
// // nov 21
// export const pro4WithNov21Charges = [
//     pro4F1Charge.nov21,
//     pro4F2Charge.nov21,
//     pro4F3Charge.nov21
// ]
// export const pro4WithNov21Pay = [
//     pro4F1Payment.nov21,
//     pro4F2Payment.nov21,
//     pro4F3Payment.nov21
// ]
// export const pro4WithNov21Count = [
//     pro4F1Collectable.nov21,
//     pro4F2Collectable.nov21,
//     pro4F3Collectable.nov21
// ]
// export const pro4WithNov21NonCount = [
//     pro4F1NonCollectable.nov21,
//     pro4F2NonCollectable.nov21,
//     pro4F3NonCollectable.nov21
// ]
// export const pro4WithNov21Claim = [
//     pro4F1ClaimCount.nov21,
//     pro4F2ClaimCount.nov21,
//     pro4F3ClaimCount.nov21
// ]
// export const pro4WithNov21PerVisit = [
//     pro4F1PerVisit.nov21,
//     pro4F2PerVisit.nov21,
//     pro4F3PerVisit.nov21
// ]
// // dec 21
// export const pro4WithDec21Charges = [
//     pro4F1Charge.dec21,
//     pro4F2Charge.dec21,
//     pro4F3Charge.dec21
// ]
// export const pro4WithDec21Pay = [
//     pro4F1Payment.dec21,
//     pro4F2Payment.dec21,
//     pro4F3Payment.dec21
// ]
// export const pro4WithDec21Count = [
//     pro4F1Collectable.dec21,
//     pro4F2Collectable.dec21,
//     pro4F3Collectable.dec21
// ]
// export const pro4WithDec21NonCount = [
//     pro4F1NonCollectable.dec21,
//     pro4F2NonCollectable.dec21,
//     pro4F3NonCollectable.dec21
// ]
// export const pro4WithDec21Claim = [
//     pro4F1ClaimCount.dec21,
//     pro4F2ClaimCount.dec21,
//     pro4F3ClaimCount.dec21
// ]
// export const pro4WithDec21PerVisit = [
//     pro4F1PerVisit.dec21,
//     pro4F2PerVisit.dec21,
//     pro4F3PerVisit.dec21
// ]

// // provider-4 
// // jan 22
// export const pro4WithJan22Charges = [
//     pro4F1Charge.jan22,
//     pro4F2Charge.jan22,
//     pro4F3Charge.jan22
// ]
// export const pro4WithJan22Pay = [
//     pro4F1Payment.jan22,
//     pro4F2Payment.jan22,
//     pro4F3Payment.jan22
// ]
// export const pro4WithJan22Count = [
//     pro4F1Collectable.jan22,
//     pro4F2Collectable.jan22,
//     pro4F3Collectable.jan22
// ]
// export const pro4WithJan22NonCount = [
//     pro4F1NonCollectable.jan22,
//     pro4F2NonCollectable.jan22,
//     pro4F3NonCollectable.jan22
// ]
// export const pro4WithJan22Claim = [
//     pro4F1ClaimCount.jan22,
//     pro4F2ClaimCount.jan22,
//     pro4F3ClaimCount.jan22
// ]
// export const pro4WithJan22PerVisit = [
//     pro4F1PerVisit.jan22,
//     pro4F2PerVisit.jan22,
//     pro4F3PerVisit.jan22
// ]
// // feb 22
// export const pro4WithFeb22Charges = [
//     pro4F1Charge.feb22,
//     pro4F2Charge.feb22,
//     pro4F3Charge.feb22
// ]
// export const pro4WithFeb22Pay = [
//     pro4F1Payment.feb22,
//     pro4F2Payment.feb22,
//     pro4F3Payment.feb22
// ]
// export const pro4WithFeb22Count = [
//     pro4F1Collectable.feb22,
//     pro4F2Collectable.feb22,
//     pro4F3Collectable.feb22
// ]
// export const pro4WithFeb22NonCount = [
//     pro4F1NonCollectable.feb22,
//     pro4F2NonCollectable.feb22,
//     pro4F3NonCollectable.feb22
// ]
// export const pro4WithFeb22Claim = [
//     pro4F1ClaimCount.feb22,
//     pro4F2ClaimCount.feb22,
//     pro4F3ClaimCount.feb22
// ]
// export const pro4WithFeb22PerVisit = [
//     pro4F1PerVisit.feb22,
//     pro4F2PerVisit.feb22,
//     pro4F3PerVisit.feb22
// ]
// // mar 22
// export const pro4WithMar22Charges = [
//     pro4F1Charge.mar22,
//     pro4F2Charge.mar22,
//     pro4F3Charge.mar22
// ]
// export const pro4WithMar22Pay = [
//     pro4F1Payment.mar22,
//     pro4F2Payment.mar22,
//     pro4F3Payment.mar22
// ]
// export const pro4WithMar22Count = [
//     pro4F1Collectable.mar22,
//     pro4F2Collectable.mar22,
//     pro4F3Collectable.mar22
// ]
// export const pro4WithMar22NonCount = [
//     pro4F1NonCollectable.mar22,
//     pro4F2NonCollectable.mar22,
//     pro4F3NonCollectable.mar22
// ]
// export const pro4WithMar22Claim = [
//     pro4F1ClaimCount.mar22,
//     pro4F2ClaimCount.mar22,
//     pro4F3ClaimCount.mar22
// ]
// export const pro4WithMar22PerVisit = [
//     pro4F1PerVisit.mar22,
//     pro4F2PerVisit.mar22,
//     pro4F3PerVisit.mar22
// ]
// // april 22
// export const pro4WithApr22Charges = [
//     pro4F1Charge.apr22,
//     pro4F2Charge.apr22,
//     pro4F3Charge.apr22
// ]
// export const pro4WithApr22Pay = [
//     pro4F1Payment.apr22,
//     pro4F2Payment.apr22,
//     pro4F3Payment.apr22
// ]
// export const pro4WithApr22Count = [
//     pro4F1Collectable.apr22,
//     pro4F2Collectable.apr22,
//     pro4F3Collectable.apr22
// ]
// export const pro4WithApr22NonCount = [
//     pro4F1NonCollectable.apr22,
//     pro4F2NonCollectable.apr22,
//     pro4F3NonCollectable.apr22
// ]
// export const pro4WithApr22Claim = [
//     pro4F1ClaimCount.apr22,
//     pro4F2ClaimCount.apr22,
//     pro4F3ClaimCount.apr22
// ]
// export const pro4WithApr22PerVisit = [
//     pro4F1PerVisit.apr22,
//     pro4F2PerVisit.apr22,
//     pro4F3PerVisit.apr22
// ]
// // may 22
// export const pro4WithMay22Charges = [
//     pro4F1Charge.may22,
//     pro4F2Charge.may22,
//     pro4F3Charge.may22
// ]
// export const pro4WithMay22Pay = [
//     pro4F1Payment.may22,
//     pro4F2Payment.may22,
//     pro4F3Payment.may22
// ]
// export const pro4WithMay22Count = [
//     pro4F1Collectable.may22,
//     pro4F2Collectable.may22,
//     pro4F3Collectable.may22
// ]
// export const pro4WithMay22NonCount = [
//     pro4F1NonCollectable.may22,
//     pro4F2NonCollectable.may22,
//     pro4F3NonCollectable.may22
// ]
// export const pro4WithMay22Claim = [
//     pro4F1ClaimCount.may22,
//     pro4F2ClaimCount.may22,
//     pro4F3ClaimCount.may22
// ]
// export const pro4WithMay22PerVisit = [
//     pro4F1PerVisit.may22,
//     pro4F2PerVisit.may22,
//     pro4F3PerVisit.may22
// ]
// // jun 22
// export const pro4WithJun22Charges = [
//     pro4F1Charge.jun22,
//     pro4F2Charge.jun22,
//     pro4F3Charge.jun22
// ]
// export const pro4WithJun22Pay = [
//     pro4F1Payment.jun22,
//     pro4F2Payment.jun22,
//     pro4F3Payment.jun22
// ]
// export const pro4WithJun22Count = [
//     pro4F1Collectable.jun22,
//     pro4F2Collectable.jun22,
//     pro4F3Collectable.jun22
// ]
// export const pro4WithJun22NonCount = [
//     pro4F1NonCollectable.jun22,
//     pro4F2NonCollectable.jun22,
//     pro4F3NonCollectable.jun22
// ]
// export const pro4WithJun22Claim = [
//     pro4F1ClaimCount.jun22,
//     pro4F2ClaimCount.jun22,
//     pro4F3ClaimCount.jun22
// ]
// export const pro4WithJun22PerVisit = [
//     pro4F1PerVisit.jun22,
//     pro4F2PerVisit.jun22,
//     pro4F3PerVisit.jun22
// ]
// // jul 22
// export const pro4WithJul22Charges = [
//     pro4F1Charge.jul22,
//     pro4F2Charge.jul22,
//     pro4F3Charge.jul22
// ]
// export const pro4WithJul22Pay = [
//     pro4F1Payment.jul22,
//     pro4F2Payment.jul22,
//     pro4F3Payment.jul22
// ]
// export const pro4WithJul22Count = [
//     pro4F1Collectable.jul22,
//     pro4F2Collectable.jul22,
//     pro4F3Collectable.jul22
// ]
// export const pro4WithJul22NonCount = [
//     pro4F1NonCollectable.jul22,
//     pro4F2NonCollectable.jul22,
//     pro4F3NonCollectable.jul22
// ]
// export const pro4WithJul22Claim = [
//     pro4F1ClaimCount.jul22,
//     pro4F2ClaimCount.jul22,
//     pro4F3ClaimCount.jul22
// ]
// export const pro4WithJul22PerVisit = [
//     pro4F1PerVisit.jul22,
//     pro4F2PerVisit.jul22,
//     pro4F3PerVisit.jul22
// ]
// // aug 22
// export const pro4WithAug22Charges = [
//     pro4F1Charge.aug22,
//     pro4F2Charge.aug22,
//     pro4F3Charge.aug22
// ]
// export const pro4WithAug22Pay = [
//     pro4F1Payment.aug22,
//     pro4F2Payment.aug22,
//     pro4F3Payment.aug22
// ]
// export const pro4WithAug22Count = [
//     pro4F1Collectable.aug22,
//     pro4F2Collectable.aug22,
//     pro4F3Collectable.aug22
// ]
// export const pro4WithAug22NonCount = [
//     pro4F1NonCollectable.aug22,
//     pro4F2NonCollectable.aug22,
//     pro4F3NonCollectable.aug22
// ]
// export const pro4WithAug22Claim = [
//     pro4F1ClaimCount.aug22,
//     pro4F2ClaimCount.aug22,
//     pro4F3ClaimCount.aug22
// ]
// export const pro4WithAug22PerVisit = [
//     pro4F1PerVisit.aug22,
//     pro4F2PerVisit.aug22,
//     pro4F3PerVisit.aug22
// ]
// // sep 22
// export const pro4WithSep22Charges = [
//     pro4F1Charge.sep22,
//     pro4F2Charge.sep22,
//     pro4F3Charge.sep22
// ]
// export const pro4WithSep22Pay = [
//     pro4F1Payment.sep22,
//     pro4F2Payment.sep22,
//     pro4F3Payment.sep22
// ]
// export const pro4WithSep22Count = [
//     pro4F1Collectable.sep22,
//     pro4F2Collectable.sep22,
//     pro4F3Collectable.sep22
// ]
// export const pro4WithSep22NonCount = [
//     pro4F1NonCollectable.sep22,
//     pro4F2NonCollectable.sep22,
//     pro4F3NonCollectable.sep22
// ]
// export const pro4WithSep22Claim = [
//     pro4F1ClaimCount.sep22,
//     pro4F2ClaimCount.sep22,
//     pro4F3ClaimCount.sep22
// ]
// export const pro4WithSep22PerVisit = [
//     pro4F1PerVisit.sep22,
//     pro4F2PerVisit.sep22,
//     pro4F3PerVisit.sep22
// ]
// // oct 22
// export const pro4WithOct22Charges = [
//     pro4F1Charge.oct22,
//     pro4F2Charge.oct22,
//     pro4F3Charge.oct22
// ]
// export const pro4WithOct22Pay = [
//     pro4F1Payment.oct22,
//     pro4F2Payment.oct22,
//     pro4F3Payment.oct22
// ]
// export const pro4WithOct22Count = [
//     pro4F1Collectable.oct22,
//     pro4F2Collectable.oct22,
//     pro4F3Collectable.oct22
// ]
// export const pro4WithOct22NonCount = [
//     pro4F1NonCollectable.oct22,
//     pro4F2NonCollectable.oct22,
//     pro4F3NonCollectable.oct22
// ]
// export const pro4WithOct22Claim = [
//     pro4F1ClaimCount.oct22,
//     pro4F2ClaimCount.oct22,
//     pro4F3ClaimCount.oct22
// ]
// export const pro4WithOct22PerVisit = [
//     pro4F1PerVisit.oct22,
//     pro4F2PerVisit.oct22,
//     pro4F3PerVisit.oct22
// ]
// // nov 22
// export const pro4WithNov22Charges = [
//     pro4F1Charge.nov22,
//     pro4F2Charge.nov22,
//     pro4F3Charge.nov22
// ]
// export const pro4WithNov22Pay = [
//     pro4F1Payment.nov22,
//     pro4F2Payment.nov22,
//     pro4F3Payment.nov22
// ]
// export const pro4WithNov22Count = [
//     pro4F1Collectable.nov22,
//     pro4F2Collectable.nov22,
//     pro4F3Collectable.nov22
// ]
// export const pro4WithNov22NonCount = [
//     pro4F1NonCollectable.nov22,
//     pro4F2NonCollectable.nov22,
//     pro4F3NonCollectable.nov22
// ]
// export const pro4WithNov22Claim = [
//     pro4F1ClaimCount.nov22,
//     pro4F2ClaimCount.nov22,
//     pro4F3ClaimCount.nov22
// ]
// export const pro4WithNov22PerVisit = [
//     pro4F1PerVisit.nov22,
//     pro4F2PerVisit.nov22,
//     pro4F3PerVisit.nov22
// ]
// // dec 22
// export const pro4WithDec22Charges = [
//     pro4F1Charge.dec22,
//     pro4F2Charge.dec22,
//     pro4F3Charge.dec22
// ]
// export const pro4WithDec22Pay = [
//     pro4F1Payment.dec22,
//     pro4F2Payment.dec22,
//     pro4F3Payment.dec22
// ]
// export const pro4WithDec22Count = [
//     pro4F1Collectable.dec22,
//     pro4F2Collectable.dec22,
//     pro4F3Collectable.dec22
// ]
// export const pro4WithDec22NonCount = [
//     pro4F1NonCollectable.dec22,
//     pro4F2NonCollectable.dec22,
//     pro4F3NonCollectable.dec22
// ]
// export const pro4WithDec22Claim = [
//     pro4F1ClaimCount.dec22,
//     pro4F2ClaimCount.dec22,
//     pro4F3ClaimCount.dec22
// ]
// export const pro4WithDec22PerVisit = [
//     pro4F1PerVisit.dec22,
//     pro4F2PerVisit.dec22,
//     pro4F3PerVisit.dec22
// ]

// // provider-4
// // jan 23
// export const pro4WithJan23Charges = [
//     pro4F1Charge.jan23,
//     pro4F2Charge.jan23,
//     pro4F3Charge.jan23
// ]
// export const pro4WithJan23Pay = [
//     pro4F1Payment.jan23,
//     pro4F2Payment.jan23,
//     pro4F3Payment.jan23
// ]
// export const pro4WithJan23Count = [
//     pro4F1Collectable.jan23,
//     pro4F2Collectable.jan23,
//     pro4F3Collectable.jan23
// ]
// export const pro4WithJan23NonCount = [
//     pro4F1NonCollectable.jan23,
//     pro4F2NonCollectable.jan23,
//     pro4F3NonCollectable.jan23
// ]
// export const pro4WithJan23Claim = [
//     pro4F1ClaimCount.jan23,
//     pro4F2ClaimCount.jan23,
//     pro4F3ClaimCount.jan23
// ]
// export const pro4WithJan23PerVisit = [
//     pro4F1PerVisit.jan23,
//     pro4F2PerVisit.jan23,
//     pro4F3PerVisit.jan23
// ]
// // feb 23
// export const pro4WithFeb23Charges = [
//     pro4F1Charge.feb23,
//     pro4F2Charge.feb23,
//     pro4F3Charge.feb23
// ]
// export const pro4WithFeb23Pay = [
//     pro4F1Payment.feb23,
//     pro4F2Payment.feb23,
//     pro4F3Payment.feb23
// ]
// export const pro4WithFeb23Count = [
//     pro4F1Collectable.feb23,
//     pro4F2Collectable.feb23,
//     pro4F3Collectable.feb23
// ]
// export const pro4WithFeb23NonCount = [
//     pro4F1NonCollectable.feb23,
//     pro4F2NonCollectable.feb23,
//     pro4F3NonCollectable.feb23
// ]
// export const pro4WithFeb23Claim = [
//     pro4F1ClaimCount.feb23,
//     pro4F2ClaimCount.feb23,
//     pro4F3ClaimCount.feb23
// ]
// export const pro4WithFeb23PerVisit = [
//     pro4F1PerVisit.feb23,
//     pro4F2PerVisit.feb23,
//     pro4F3PerVisit.feb23
// ]
// // mar 23
// export const pro4WithMar23Charges = [
//     pro4F1Charge.mar23,
//     pro4F2Charge.mar23,
//     pro4F3Charge.mar23
// ]
// export const pro4WithMar23Pay = [
//     pro4F1Payment.mar23,
//     pro4F2Payment.mar23,
//     pro4F3Payment.mar23
// ]
// export const pro4WithMar23Count = [
//     pro4F1Collectable.mar23,
//     pro4F2Collectable.mar23,
//     pro4F3Collectable.mar23
// ]
// export const pro4WithMar23NonCount = [
//     pro4F1NonCollectable.mar23,
//     pro4F2NonCollectable.mar23,
//     pro4F3NonCollectable.mar23
// ]
// export const pro4WithMar23Claim = [
//     pro4F1ClaimCount.mar23,
//     pro4F2ClaimCount.mar23,
//     pro4F3ClaimCount.mar23
// ]
// export const pro4WithMar23PerVisit = [
//     pro4F1PerVisit.mar23,
//     pro4F2PerVisit.mar23,
//     pro4F3PerVisit.mar23
// ]
// // april 23
// export const pro4WithApr23Charges = [
//     pro4F1Charge.apr23,
//     pro4F2Charge.apr23,
//     pro4F3Charge.apr23
// ]
// export const pro4WithApr23Pay = [
//     pro4F1Payment.apr23,
//     pro4F2Payment.apr23,
//     pro4F3Payment.apr23
// ]
// export const pro4WithApr23Count = [
//     pro4F1Collectable.apr23,
//     pro4F2Collectable.apr23,
//     pro4F3Collectable.apr23
// ]
// export const pro4WithApr23NonCount = [
//     pro4F1NonCollectable.apr23,
//     pro4F2NonCollectable.apr23,
//     pro4F3NonCollectable.apr23
// ]
// export const pro4WithApr23Claim = [
//     pro4F1ClaimCount.apr23,
//     pro4F2ClaimCount.apr23,
//     pro4F3ClaimCount.apr23
// ]
// export const pro4WithApr23PerVisit = [
//     pro4F1PerVisit.apr23,
//     pro4F2PerVisit.apr23,
//     pro4F3PerVisit.apr23
// ]
// // may 23
// export const pro4WithMay23Charges = [
//     pro4F1Charge.may23,
//     pro4F2Charge.may23,
//     pro4F3Charge.may23
// ]
// export const pro4WithMay23Pay = [
//     pro4F1Payment.may23,
//     pro4F2Payment.may23,
//     pro4F3Payment.may23
// ]
// export const pro4WithMay23Count = [
//     pro4F1Collectable.may23,
//     pro4F2Collectable.may23,
//     pro4F3Collectable.may23
// ]
// export const pro4WithMay23NonCount = [
//     pro4F1NonCollectable.may23,
//     pro4F2NonCollectable.may23,
//     pro4F3NonCollectable.may23
// ]
// export const pro4WithMay23Claim = [
//     pro4F1ClaimCount.may23,
//     pro4F2ClaimCount.may23,
//     pro4F3ClaimCount.may23
// ]
// export const pro4WithMay23PerVisit = [
//     pro4F1PerVisit.may23,
//     pro4F2PerVisit.may23,
//     pro4F3PerVisit.may23
// ]
// // jun 23
// export const pro4WithJun23Charges = [
//     pro4F1Charge.jun23,
//     pro4F2Charge.jun23,
//     pro4F3Charge.jun23
// ]
// export const pro4WithJun23Pay = [
//     pro4F1Payment.jun23,
//     pro4F2Payment.jun23,
//     pro4F3Payment.jun23
// ]
// export const pro4WithJun23Count = [
//     pro4F1Collectable.jun23,
//     pro4F2Collectable.jun23,
//     pro4F3Collectable.jun23
// ]
// export const pro4WithJun23NonCount = [
//     pro4F1NonCollectable.jun23,
//     pro4F2NonCollectable.jun23,
//     pro4F3NonCollectable.jun23
// ]
// export const pro4WithJun23Claim = [
//     pro4F1ClaimCount.jun23,
//     pro4F2ClaimCount.jun23,
//     pro4F3ClaimCount.jun23
// ]
// export const pro4WithJun23PerVisit = [
//     pro4F1PerVisit.jun23,
//     pro4F2PerVisit.jun23,
//     pro4F3PerVisit.jun23
// ]
// // jul 23
// export const pro4WithJul23Charges = [
//     pro4F1Charge.jul23,
//     pro4F2Charge.jul23,
//     pro4F3Charge.jul23
// ]
// export const pro4WithJul23Pay = [
//     pro4F1Payment.jul23,
//     pro4F2Payment.jul23,
//     pro4F3Payment.jul23
// ]
// export const pro4WithJul23Count = [
//     pro4F1Collectable.jul23,
//     pro4F2Collectable.jul23,
//     pro4F3Collectable.jul23
// ]
// export const pro4WithJul23NonCount = [
//     pro4F1NonCollectable.jul23,
//     pro4F2NonCollectable.jul23,
//     pro4F3NonCollectable.jul23
// ]
// export const pro4WithJul23Claim = [
//     pro4F1ClaimCount.jul23,
//     pro4F2ClaimCount.jul23,
//     pro4F3ClaimCount.jul23
// ]
// export const pro4WithJul23PerVisit = [
//     pro4F1PerVisit.jul23,
//     pro4F2PerVisit.jul23,
//     pro4F3PerVisit.jul23
// ]
// // aug 23
// export const pro4WithAug23Charges = [
//     pro4F1Charge.aug23,
//     pro4F2Charge.aug23,
//     pro4F3Charge.aug23
// ]
// export const pro4WithAug23Pay = [
//     pro4F1Payment.aug23,
//     pro4F2Payment.aug23,
//     pro4F3Payment.aug23
// ]
// export const pro4WithAug23Count = [
//     pro4F1Collectable.aug23,
//     pro4F2Collectable.aug23,
//     pro4F3Collectable.aug23
// ]
// export const pro4WithAug23NonCount = [
//     pro4F1NonCollectable.aug23,
//     pro4F2NonCollectable.aug23,
//     pro4F3NonCollectable.aug23
// ]
// export const pro4WithAug23Claim = [
//     pro4F1ClaimCount.aug23,
//     pro4F2ClaimCount.aug23,
//     pro4F3ClaimCount.aug23
// ]
// export const pro4WithAug23PerVisit = [
//     pro4F1PerVisit.aug23,
//     pro4F2PerVisit.aug23,
//     pro4F3PerVisit.aug23
// ]
// // sep 23
// export const pro4WithSep23Charges = [
//     pro4F1Charge.sep23,
//     pro4F2Charge.sep23,
//     pro4F3Charge.sep23
// ]
// export const pro4WithSep23Pay = [
//     pro4F1Payment.sep23,
//     pro4F2Payment.sep23,
//     pro4F3Payment.sep23
// ]
// export const pro4WithSep23Count = [
//     pro4F1Collectable.sep23,
//     pro4F2Collectable.sep23,
//     pro4F3Collectable.sep23
// ]
// export const pro4WithSep23NonCount = [
//     pro4F1NonCollectable.sep23,
//     pro4F2NonCollectable.sep23,
//     pro4F3NonCollectable.sep23
// ]
// export const pro4WithSep23Claim = [
//     pro4F1ClaimCount.sep23,
//     pro4F2ClaimCount.sep23,
//     pro4F3ClaimCount.sep23
// ]
// export const pro4WithSep23PerVisit = [
//     pro4F1PerVisit.sep23,
//     pro4F2PerVisit.sep23,
//     pro4F3PerVisit.sep23
// ]
// // oct 23
// export const pro4WithOct23Charges = [
//     pro4F1Charge.oct23,
//     pro4F2Charge.oct23,
//     pro4F3Charge.oct23
// ]
// export const pro4WithOct23Pay = [
//     pro4F1Payment.oct23,
//     pro4F2Payment.oct23,
//     pro4F3Payment.oct23
// ]
// export const pro4WithOct23Count = [
//     pro4F1Collectable.oct23,
//     pro4F2Collectable.oct23,
//     pro4F3Collectable.oct23
// ]
// export const pro4WithOct23NonCount = [
//     pro4F1NonCollectable.oct23,
//     pro4F2NonCollectable.oct23,
//     pro4F3NonCollectable.oct23
// ]
// export const pro4WithOct23Claim = [
//     pro4F1ClaimCount.oct23,
//     pro4F2ClaimCount.oct23,
//     pro4F3ClaimCount.oct23
// ]
// export const pro4WithOct23PerVisit = [
//     pro4F1PerVisit.oct23,
//     pro4F2PerVisit.oct23,
//     pro4F3PerVisit.oct23
// ]
// // nov 23
// export const pro4WithNov23Charges = [
//     pro4F1Charge.nov23,
//     pro4F2Charge.nov23,
//     pro4F3Charge.nov23
// ]
// export const pro4WithNov23Pay = [
//     pro4F1Payment.nov23,
//     pro4F2Payment.nov23,
//     pro4F3Payment.nov23
// ]
// export const pro4WithNov23Count = [
//     pro4F1Collectable.nov23,
//     pro4F2Collectable.nov23,
//     pro4F3Collectable.nov23
// ]
// export const pro4WithNov23NonCount = [
//     pro4F1NonCollectable.nov23,
//     pro4F2NonCollectable.nov23,
//     pro4F3NonCollectable.nov23
// ]
// export const pro4WithNov23Claim = [
//     pro4F1ClaimCount.nov23,
//     pro4F2ClaimCount.nov23,
//     pro4F3ClaimCount.nov23
// ]
// export const pro4WithNov23PerVisit = [
//     pro4F1PerVisit.nov23,
//     pro4F2PerVisit.nov23,
//     pro4F3PerVisit.nov23
// ]


// provider-5
// jan 21
// export const pro5WithJan21Charges = [
//     pro5F1Charge.jan21,
//     pro5F2Charge.jan21,
//     pro5F3Charge.jan21
// ]
// export const pro5WithJan21Pay = [
//     pro5F1Payment.jan21,
//     pro5F2Payment.jan21,
//     pro5F3Payment.jan21
// ]
// export const pro5WithJan21Count = [
//     pro5F1Collectable.jan21,
//     pro5F2Collectable.jan21,
//     pro5F3Collectable.jan21
// ]
// export const pro5WithJan21NonCount = [
//     pro5F1NonCollectable.jan21,
//     pro5F2NonCollectable.jan21,
//     pro5F3NonCollectable.jan21
// ]
// export const pro5WithJan21Claim = [
//     pro5F1ClaimCount.jan21,
//     pro5F2ClaimCount.jan21,
//     pro5F3ClaimCount.jan21
// ]
// export const pro5WithJan21PerVisit = [
//     pro5F1PerVisit.jan21,
//     pro5F2PerVisit.jan21,
//     pro5F3PerVisit.jan21
// ]
// // feb 21
// export const pro5WithFeb21Charges = [
//     pro5F1Charge.feb21,
//     pro5F2Charge.feb21,
//     pro5F3Charge.feb21
// ]
// export const pro5WithFeb21Pay = [
//     pro5F1Payment.feb21,
//     pro5F2Payment.feb21,
//     pro5F3Payment.feb21
// ]
// export const pro5WithFeb21Count = [
//     pro5F1Collectable.feb21,
//     pro5F2Collectable.feb21,
//     pro5F3Collectable.feb21
// ]
// export const pro5WithFeb21NonCount = [
//     pro5F1NonCollectable.feb21,
//     pro5F2NonCollectable.feb21,
//     pro5F3NonCollectable.feb21
// ]
// export const pro5WithFeb21Claim = [
//     pro5F1ClaimCount.feb21,
//     pro5F2ClaimCount.feb21,
//     pro5F3ClaimCount.feb21
// ]
// export const pro5WithFeb21PerVisit = [
//     pro5F1PerVisit.feb21,
//     pro5F2PerVisit.feb21,
//     pro5F3PerVisit.feb21
// ]
// // mar 21
// export const pro5WithMar21Charges = [
//     pro5F1Charge.mar21,
//     pro5F2Charge.mar21,
//     pro5F3Charge.mar21
// ]
// export const pro5WithMar21Pay = [
//     pro5F1Payment.mar21,
//     pro5F2Payment.mar21,
//     pro5F3Payment.mar21
// ]
// export const pro5WithMar21Count = [
//     pro5F1Collectable.mar21,
//     pro5F2Collectable.mar21,
//     pro5F3Collectable.mar21
// ]
// export const pro5WithMar21NonCount = [
//     pro5F1NonCollectable.mar21,
//     pro5F2NonCollectable.mar21,
//     pro5F3NonCollectable.mar21
// ]
// export const pro5WithMar21Claim = [
//     pro5F1ClaimCount.mar21,
//     pro5F2ClaimCount.mar21,
//     pro5F3ClaimCount.mar21
// ]
// export const pro5WithMar21PerVisit = [
//     pro5F1PerVisit.mar21,
//     pro5F2PerVisit.mar21,
//     pro5F3PerVisit.mar21
// ]
// // april 21
// export const pro5WithApr21Charges = [
//     pro5F1Charge.apr21,
//     pro5F2Charge.apr21,
//     pro5F3Charge.apr21
// ]
// export const pro5WithApr21Pay = [
//     pro5F1Payment.apr21,
//     pro5F2Payment.apr21,
//     pro5F3Payment.apr21
// ]
// export const pro5WithApr21Count = [
//     pro5F1Collectable.apr21,
//     pro5F2Collectable.apr21,
//     pro5F3Collectable.apr21
// ]
// export const pro5WithApr21NonCount = [
//     pro5F1NonCollectable.apr21,
//     pro5F2NonCollectable.apr21,
//     pro5F3NonCollectable.apr21
// ]
// export const pro5WithApr21Claim = [
//     pro5F1ClaimCount.apr21,
//     pro5F2ClaimCount.apr21,
//     pro5F3ClaimCount.apr21
// ]
// export const pro5WithApr21PerVisit = [
//     pro5F1PerVisit.apr21,
//     pro5F2PerVisit.apr21,
//     pro5F3PerVisit.apr21
// ]
// // may 21
// export const pro5WithMay21Charges = [
//     pro5F1Charge.may21,
//     pro5F2Charge.may21,
//     pro5F3Charge.may21
// ]
// export const pro5WithMay21Pay = [
//     pro5F1Payment.may21,
//     pro5F2Payment.may21,
//     pro5F3Payment.may21
// ]
// export const pro5WithMay21Count = [
//     pro5F1Collectable.may21,
//     pro5F2Collectable.may21,
//     pro5F3Collectable.may21
// ]
// export const pro5WithMay21NonCount = [
//     pro5F1NonCollectable.may21,
//     pro5F2NonCollectable.may21,
//     pro5F3NonCollectable.may21
// ]
// export const pro5WithMay21Claim = [
//     pro5F1ClaimCount.may21,
//     pro5F2ClaimCount.may21,
//     pro5F3ClaimCount.may21
// ]
// export const pro5WithMay21PerVisit = [
//     pro5F1PerVisit.may21,
//     pro5F2PerVisit.may21,
//     pro5F3PerVisit.may21
// ]
// // jun 21
// export const pro5WithJun21Charges = [
//     pro5F1Charge.jun21,
//     pro5F2Charge.jun21,
//     pro5F3Charge.jun21
// ]
// export const pro5WithJun21Pay = [
//     pro5F1Payment.jun21,
//     pro5F2Payment.jun21,
//     pro5F3Payment.jun21
// ]
// export const pro5WithJun21Count = [
//     pro5F1Collectable.jun21,
//     pro5F2Collectable.jun21,
//     pro5F3Collectable.jun21
// ]
// export const pro5WithJun21NonCount = [
//     pro5F1NonCollectable.jun21,
//     pro5F2NonCollectable.jun21,
//     pro5F3NonCollectable.jun21
// ]
// export const pro5WithJun21Claim = [
//     pro5F1ClaimCount.jun21,
//     pro5F2ClaimCount.jun21,
//     pro5F3ClaimCount.jun21
// ]
// export const pro5WithJun21PerVisit = [
//     pro5F1PerVisit.jun21,
//     pro5F2PerVisit.jun21,
//     pro5F3PerVisit.jun21
// ]
// // jul 21
// export const pro5WithJul21Charges = [
//     pro5F1Charge.jul21,
//     pro5F2Charge.jul21,
//     pro5F3Charge.jul21
// ]
// export const pro5WithJul21Pay = [
//     pro5F1Payment.jul21,
//     pro5F2Payment.jul21,
//     pro5F3Payment.jul21
// ]
// export const pro5WithJul21Count = [
//     pro5F1Collectable.jul21,
//     pro5F2Collectable.jul21,
//     pro5F3Collectable.jul21
// ]
// export const pro5WithJul21NonCount = [
//     pro5F1NonCollectable.jul21,
//     pro5F2NonCollectable.jul21,
//     pro5F3NonCollectable.jul21
// ]
// export const pro5WithJul21Claim = [
//     pro5F1ClaimCount.jul21,
//     pro5F2ClaimCount.jul21,
//     pro5F3ClaimCount.jul21
// ]
// export const pro5WithJul21PerVisit = [
//     pro5F1PerVisit.jul21,
//     pro5F2PerVisit.jul21,
//     pro5F3PerVisit.jul21
// ]
// // aug 21
// export const pro5WithAug21Charges = [
//     pro5F1Charge.aug21,
//     pro5F2Charge.aug21,
//     pro5F3Charge.aug21
// ]
// export const pro5WithAug21Pay = [
//     pro5F1Payment.aug21,
//     pro5F2Payment.aug21,
//     pro5F3Payment.aug21
// ]
// export const pro5WithAug21Count = [
//     pro5F1Collectable.aug21,
//     pro5F2Collectable.aug21,
//     pro5F3Collectable.aug21
// ]
// export const pro5WithAug21NonCount = [
//     pro5F1NonCollectable.aug21,
//     pro5F2NonCollectable.aug21,
//     pro5F3NonCollectable.aug21
// ]
// export const pro5WithAug21Claim = [
//     pro5F1ClaimCount.aug21,
//     pro5F2ClaimCount.aug21,
//     pro5F3ClaimCount.aug21
// ]
// export const pro5WithAug21PerVisit = [
//     pro5F1PerVisit.aug21,
//     pro5F2PerVisit.aug21,
//     pro5F3PerVisit.aug21
// ]
// // sep 21
// export const pro5WithSep21Charges = [
//     pro5F1Charge.sep21,
//     pro5F2Charge.sep21,
//     pro5F3Charge.sep21
// ]
// export const pro5WithSep21Pay = [
//     pro5F1Payment.sep21,
//     pro5F2Payment.sep21,
//     pro5F3Payment.sep21
// ]
// export const pro5WithSep21Count = [
//     pro5F1Collectable.sep21,
//     pro5F2Collectable.sep21,
//     pro5F3Collectable.sep21
// ]
// export const pro5WithSep21NonCount = [
//     pro5F1NonCollectable.sep21,
//     pro5F2NonCollectable.sep21,
//     pro5F3NonCollectable.sep21
// ]
// export const pro5WithSep21Claim = [
//     pro5F1ClaimCount.sep21,
//     pro5F2ClaimCount.sep21,
//     pro5F3ClaimCount.sep21
// ]
// export const pro5WithSep21PerVisit = [
//     pro5F1PerVisit.sep21,
//     pro5F2PerVisit.sep21,
//     pro5F3PerVisit.sep21
// ]
// // oct 21
// export const pro5WithOct21Charges = [
//     pro5F1Charge.oct21,
//     pro5F2Charge.oct21,
//     pro5F3Charge.oct21
// ]
// export const pro5WithOct21Pay = [
//     pro5F1Payment.oct21,
//     pro5F2Payment.oct21,
//     pro5F3Payment.oct21
// ]
// export const pro5WithOct21Count = [
//     pro5F1Collectable.oct21,
//     pro5F2Collectable.oct21,
//     pro5F3Collectable.oct21
// ]
// export const pro5WithOct21NonCount = [
//     pro5F1NonCollectable.oct21,
//     pro5F2NonCollectable.oct21,
//     pro5F3NonCollectable.oct21
// ]
// export const pro5WithOct21Claim = [
//     pro5F1ClaimCount.oct21,
//     pro5F2ClaimCount.oct21,
//     pro5F3ClaimCount.oct21
// ]
// export const pro5WithOct21PerVisit = [
//     pro5F1PerVisit.oct21,
//     pro5F2PerVisit.oct21,
//     pro5F3PerVisit.oct21
// ]
// // nov 21
// export const pro5WithNov21Charges = [
//     pro5F1Charge.nov21,
//     pro5F2Charge.nov21,
//     pro5F3Charge.nov21
// ]
// export const pro5WithNov21Pay = [
//     pro5F1Payment.nov21,
//     pro5F2Payment.nov21,
//     pro5F3Payment.nov21
// ]
// export const pro5WithNov21Count = [
//     pro5F1Collectable.nov21,
//     pro5F2Collectable.nov21,
//     pro5F3Collectable.nov21
// ]
// export const pro5WithNov21NonCount = [
//     pro5F1NonCollectable.nov21,
//     pro5F2NonCollectable.nov21,
//     pro5F3NonCollectable.nov21
// ]
// export const pro5WithNov21Claim = [
//     pro5F1ClaimCount.nov21,
//     pro5F2ClaimCount.nov21,
//     pro5F3ClaimCount.nov21
// ]
// export const pro5WithNov21PerVisit = [
//     pro5F1PerVisit.nov21,
//     pro5F2PerVisit.nov21,
//     pro5F3PerVisit.nov21
// ]
// // dec 21
// export const pro5WithDec21Charges = [
//     pro5F1Charge.dec21,
//     pro5F2Charge.dec21,
//     pro5F3Charge.dec21
// ]
// export const pro5WithDec21Pay = [
//     pro5F1Payment.dec21,
//     pro5F2Payment.dec21,
//     pro5F3Payment.dec21
// ]
// export const pro5WithDec21Count = [
//     pro5F1Collectable.dec21,
//     pro5F2Collectable.dec21,
//     pro5F3Collectable.dec21
// ]
// export const pro5WithDec21NonCount = [
//     pro5F1NonCollectable.dec21,
//     pro5F2NonCollectable.dec21,
//     pro5F3NonCollectable.dec21
// ]
// export const pro5WithDec21Claim = [
//     pro5F1ClaimCount.dec21,
//     pro5F2ClaimCount.dec21,
//     pro5F3ClaimCount.dec21
// ]
// export const pro5WithDec21PerVisit = [
//     pro5F1PerVisit.dec21,
//     pro5F2PerVisit.dec21,
//     pro5F3PerVisit.dec21
// ]

// // provider-5
// // jan 22
// export const pro5WithJan22Charges = [
//     pro5F1Charge.jan22,
//     pro5F2Charge.jan22,
//     pro5F3Charge.jan22
// ]
// export const pro5WithJan22Pay = [
//     pro5F1Payment.jan22,
//     pro5F2Payment.jan22,
//     pro5F3Payment.jan22
// ]
// export const pro5WithJan22Count = [
//     pro5F1Collectable.jan22,
//     pro5F2Collectable.jan22,
//     pro5F3Collectable.jan22
// ]
// export const pro5WithJan22NonCount = [
//     pro5F1NonCollectable.jan22,
//     pro5F2NonCollectable.jan22,
//     pro5F3NonCollectable.jan22
// ]
// export const pro5WithJan22Claim = [
//     pro5F1ClaimCount.jan22,
//     pro5F2ClaimCount.jan22,
//     pro5F3ClaimCount.jan22
// ]
// export const pro5WithJan22PerVisit = [
//     pro5F1PerVisit.jan22,
//     pro5F2PerVisit.jan22,
//     pro5F3PerVisit.jan22
// ]
// // feb 22
// export const pro5WithFeb22Charges = [
//     pro5F1Charge.feb22,
//     pro5F2Charge.feb22,
//     pro5F3Charge.feb22
// ]
// export const pro5WithFeb22Pay = [
//     pro5F1Payment.feb22,
//     pro5F2Payment.feb22,
//     pro5F3Payment.feb22
// ]
// export const pro5WithFeb22Count = [
//     pro5F1Collectable.feb22,
//     pro5F2Collectable.feb22,
//     pro5F3Collectable.feb22
// ]
// export const pro5WithFeb22NonCount = [
//     pro5F1NonCollectable.feb22,
//     pro5F2NonCollectable.feb22,
//     pro5F3NonCollectable.feb22
// ]
// export const pro5WithFeb22Claim = [
//     pro5F1ClaimCount.feb22,
//     pro5F2ClaimCount.feb22,
//     pro5F3ClaimCount.feb22
// ]
// export const pro5WithFeb22PerVisit = [
//     pro5F1PerVisit.feb22,
//     pro5F2PerVisit.feb22,
//     pro5F3PerVisit.feb22
// ]
// // mar 22
// export const pro5WithMar22Charges = [
//     pro5F1Charge.mar22,
//     pro5F2Charge.mar22,
//     pro5F3Charge.mar22
// ]
// export const pro5WithMar22Pay = [
//     pro5F1Payment.mar22,
//     pro5F2Payment.mar22,
//     pro5F3Payment.mar22
// ]
// export const pro5WithMar22Count = [
//     pro5F1Collectable.mar22,
//     pro5F2Collectable.mar22,
//     pro5F3Collectable.mar22
// ]
// export const pro5WithMar22NonCount = [
//     pro5F1NonCollectable.mar22,
//     pro5F2NonCollectable.mar22,
//     pro5F3NonCollectable.mar22
// ]
// export const pro5WithMar22Claim = [
//     pro5F1ClaimCount.mar22,
//     pro5F2ClaimCount.mar22,
//     pro5F3ClaimCount.mar22
// ]
// export const pro5WithMar22PerVisit = [
//     pro5F1PerVisit.mar22,
//     pro5F2PerVisit.mar22,
//     pro5F3PerVisit.mar22
// ]
// // april 22
// export const pro5WithApr22Charges = [
//     pro5F1Charge.apr22,
//     pro5F2Charge.apr22,
//     pro5F3Charge.apr22
// ]
// export const pro5WithApr22Pay = [
//     pro5F1Payment.apr22,
//     pro5F2Payment.apr22,
//     pro5F3Payment.apr22
// ]
// export const pro5WithApr22Count = [
//     pro5F1Collectable.apr22,
//     pro5F2Collectable.apr22,
//     pro5F3Collectable.apr22
// ]
// export const pro5WithApr22NonCount = [
//     pro5F1NonCollectable.apr22,
//     pro5F2NonCollectable.apr22,
//     pro5F3NonCollectable.apr22
// ]
// export const pro5WithApr22Claim = [
//     pro5F1ClaimCount.apr22,
//     pro5F2ClaimCount.apr22,
//     pro5F3ClaimCount.apr22
// ]
// export const pro5WithApr22PerVisit = [
//     pro5F1PerVisit.apr22,
//     pro5F2PerVisit.apr22,
//     pro5F3PerVisit.apr22
// ]
// // may 22
// export const pro5WithMay22Charges = [
//     pro5F1Charge.may22,
//     pro5F2Charge.may22,
//     pro5F3Charge.may22
// ]
// export const pro5WithMay22Pay = [
//     pro5F1Payment.may22,
//     pro5F2Payment.may22,
//     pro5F3Payment.may22
// ]
// export const pro5WithMay22Count = [
//     pro5F1Collectable.may22,
//     pro5F2Collectable.may22,
//     pro5F3Collectable.may22
// ]
// export const pro5WithMay22NonCount = [
//     pro5F1NonCollectable.may22,
//     pro5F2NonCollectable.may22,
//     pro5F3NonCollectable.may22
// ]
// export const pro5WithMay22Claim = [
//     pro5F1ClaimCount.may22,
//     pro5F2ClaimCount.may22,
//     pro5F3ClaimCount.may22
// ]
// export const pro5WithMay22PerVisit = [
//     pro5F1PerVisit.may22,
//     pro5F2PerVisit.may22,
//     pro5F3PerVisit.may22
// ]
// // jun 22
// export const pro5WithJun22Charges = [
//     pro5F1Charge.jun22,
//     pro5F2Charge.jun22,
//     pro5F3Charge.jun22
// ]
// export const pro5WithJun22Pay = [
//     pro5F1Payment.jun22,
//     pro5F2Payment.jun22,
//     pro5F3Payment.jun22
// ]
// export const pro5WithJun22Count = [
//     pro5F1Collectable.jun22,
//     pro5F2Collectable.jun22,
//     pro5F3Collectable.jun22
// ]
// export const pro5WithJun22NonCount = [
//     pro5F1NonCollectable.jun22,
//     pro5F2NonCollectable.jun22,
//     pro5F3NonCollectable.jun22
// ]
// export const pro5WithJun22Claim = [
//     pro5F1ClaimCount.jun22,
//     pro5F2ClaimCount.jun22,
//     pro5F3ClaimCount.jun22
// ]
// export const pro5WithJun22PerVisit = [
//     pro5F1PerVisit.jun22,
//     pro5F2PerVisit.jun22,
//     pro5F3PerVisit.jun22
// ]
// // jul 22
// export const pro5WithJul22Charges = [
//     pro5F1Charge.jul22,
//     pro5F2Charge.jul22,
//     pro5F3Charge.jul22
// ]
// export const pro5WithJul22Pay = [
//     pro5F1Payment.jul22,
//     pro5F2Payment.jul22,
//     pro5F3Payment.jul22
// ]
// export const pro5WithJul22Count = [
//     pro5F1Collectable.jul22,
//     pro5F2Collectable.jul22,
//     pro5F3Collectable.jul22
// ]
// export const pro5WithJul22NonCount = [
//     pro5F1NonCollectable.jul22,
//     pro5F2NonCollectable.jul22,
//     pro5F3NonCollectable.jul22
// ]
// export const pro5WithJul22Claim = [
//     pro5F1ClaimCount.jul22,
//     pro5F2ClaimCount.jul22,
//     pro5F3ClaimCount.jul22
// ]
// export const pro5WithJul22PerVisit = [
//     pro5F1PerVisit.jul22,
//     pro5F2PerVisit.jul22,
//     pro5F3PerVisit.jul22
// ]
// // aug 22
// export const pro5WithAug22Charges = [
//     pro5F1Charge.aug22,
//     pro5F2Charge.aug22,
//     pro5F3Charge.aug22
// ]
// export const pro5WithAug22Pay = [
//     pro5F1Payment.aug22,
//     pro5F2Payment.aug22,
//     pro5F3Payment.aug22
// ]
// export const pro5WithAug22Count = [
//     pro5F1Collectable.aug22,
//     pro5F2Collectable.aug22,
//     pro5F3Collectable.aug22
// ]
// export const pro5WithAug22NonCount = [
//     pro5F1NonCollectable.aug22,
//     pro5F2NonCollectable.aug22,
//     pro5F3NonCollectable.aug22
// ]
// export const pro5WithAug22Claim = [
//     pro5F1ClaimCount.aug22,
//     pro5F2ClaimCount.aug22,
//     pro5F3ClaimCount.aug22
// ]
// export const pro5WithAug22PerVisit = [
//     pro5F1PerVisit.aug22,
//     pro5F2PerVisit.aug22,
//     pro5F3PerVisit.aug22
// ]
// // sep 22
// export const pro5WithSep22Charges = [
//     pro5F1Charge.sep22,
//     pro5F2Charge.sep22,
//     pro5F3Charge.sep22
// ]
// export const pro5WithSep22Pay = [
//     pro5F1Payment.sep22,
//     pro5F2Payment.sep22,
//     pro5F3Payment.sep22
// ]
// export const pro5WithSep22Count = [
//     pro5F1Collectable.sep22,
//     pro5F2Collectable.sep22,
//     pro5F3Collectable.sep22
// ]
// export const pro5WithSep22NonCount = [
//     pro5F1NonCollectable.sep22,
//     pro5F2NonCollectable.sep22,
//     pro5F3NonCollectable.sep22
// ]
// export const pro5WithSep22Claim = [
//     pro5F1ClaimCount.sep22,
//     pro5F2ClaimCount.sep22,
//     pro5F3ClaimCount.sep22
// ]
// export const pro5WithSep22PerVisit = [
//     pro5F1PerVisit.sep22,
//     pro5F2PerVisit.sep22,
//     pro5F3PerVisit.sep22
// ]
// // oct 22
// export const pro5WithOct22Charges = [
//     pro5F1Charge.oct22,
//     pro5F2Charge.oct22,
//     pro5F3Charge.oct22
// ]
// export const pro5WithOct22Pay = [
//     pro5F1Payment.oct22,
//     pro5F2Payment.oct22,
//     pro5F3Payment.oct22
// ]
// export const pro5WithOct22Count = [
//     pro5F1Collectable.oct22,
//     pro5F2Collectable.oct22,
//     pro5F3Collectable.oct22
// ]
// export const pro5WithOct22NonCount = [
//     pro5F1NonCollectable.oct22,
//     pro5F2NonCollectable.oct22,
//     pro5F3NonCollectable.oct22
// ]
// export const pro5WithOct22Claim = [
//     pro5F1ClaimCount.oct22,
//     pro5F2ClaimCount.oct22,
//     pro5F3ClaimCount.oct22
// ]
// export const pro5WithOct22PerVisit = [
//     pro5F1PerVisit.oct22,
//     pro5F2PerVisit.oct22,
//     pro5F3PerVisit.oct22
// ]
// // nov 22
// export const pro5WithNov22Charges = [
//     pro5F1Charge.nov22,
//     pro5F2Charge.nov22,
//     pro5F3Charge.nov22
// ]
// export const pro5WithNov22Pay = [
//     pro5F1Payment.nov22,
//     pro5F2Payment.nov22,
//     pro5F3Payment.nov22
// ]
// export const pro5WithNov22Count = [
//     pro5F1Collectable.nov22,
//     pro5F2Collectable.nov22,
//     pro5F3Collectable.nov22
// ]
// export const pro5WithNov22NonCount = [
//     pro5F1NonCollectable.nov22,
//     pro5F2NonCollectable.nov22,
//     pro5F3NonCollectable.nov22
// ]
// export const pro5WithNov22Claim = [
//     pro5F1ClaimCount.nov22,
//     pro5F2ClaimCount.nov22,
//     pro5F3ClaimCount.nov22
// ]
// export const pro5WithNov22PerVisit = [
//     pro5F1PerVisit.nov22,
//     pro5F2PerVisit.nov22,
//     pro5F3PerVisit.nov22
// ]
// // dec 22
// export const pro5WithDec22Charges = [
//     pro5F1Charge.dec22,
//     pro5F2Charge.dec22,
//     pro5F3Charge.dec22
// ]
// export const pro5WithDec22Pay = [
//     pro5F1Payment.dec22,
//     pro5F2Payment.dec22,
//     pro5F3Payment.dec22
// ]
// export const pro5WithDec22Count = [
//     pro5F1Collectable.dec22,
//     pro5F2Collectable.dec22,
//     pro5F3Collectable.dec22
// ]
// export const pro5WithDec22NonCount = [
//     pro5F1NonCollectable.dec22,
//     pro5F2NonCollectable.dec22,
//     pro5F3NonCollectable.dec22
// ]
// export const pro5WithDec22Claim = [
//     pro5F1ClaimCount.dec22,
//     pro5F2ClaimCount.dec22,
//     pro5F3ClaimCount.dec22
// ]
// export const pro5WithDec22PerVisit = [
//     pro5F1PerVisit.dec22,
//     pro5F2PerVisit.dec22,
//     pro5F3PerVisit.dec22
// ]

// // provider-5
// // jan 23
// export const pro5WithJan23Charges = [
//     pro5F1Charge.jan23,
//     pro5F2Charge.jan23,
//     pro5F3Charge.jan23
// ]
// export const pro5WithJan23Pay = [
//     pro5F1Payment.jan23,
//     pro5F2Payment.jan23,
//     pro5F3Payment.jan23
// ]
// export const pro5WithJan23Count = [
//     pro5F1Collectable.jan23,
//     pro5F2Collectable.jan23,
//     pro5F3Collectable.jan23
// ]
// export const pro5WithJan23NonCount = [
//     pro5F1NonCollectable.jan23,
//     pro5F2NonCollectable.jan23,
//     pro5F3NonCollectable.jan23
// ]
// export const pro5WithJan23Claim = [
//     pro5F1ClaimCount.jan23,
//     pro5F2ClaimCount.jan23,
//     pro5F3ClaimCount.jan23
// ]
// export const pro5WithJan23PerVisit = [
//     pro5F1PerVisit.jan23,
//     pro5F2PerVisit.jan23,
//     pro5F3PerVisit.jan23
// ]
// // feb 23
// export const pro5WithFeb23Charges = [
//     pro5F1Charge.feb23,
//     pro5F2Charge.feb23,
//     pro5F3Charge.feb23
// ]
// export const pro5WithFeb23Pay = [
//     pro5F1Payment.feb23,
//     pro5F2Payment.feb23,
//     pro5F3Payment.feb23
// ]
// export const pro5WithFeb23Count = [
//     pro5F1Collectable.feb23,
//     pro5F2Collectable.feb23,
//     pro5F3Collectable.feb23
// ]
// export const pro5WithFeb23NonCount = [
//     pro5F1NonCollectable.feb23,
//     pro5F2NonCollectable.feb23,
//     pro5F3NonCollectable.feb23
// ]
// export const pro5WithFeb23Claim = [
//     pro5F1ClaimCount.feb23,
//     pro5F2ClaimCount.feb23,
//     pro5F3ClaimCount.feb23
// ]
// export const pro5WithFeb23PerVisit = [
//     pro5F1PerVisit.feb23,
//     pro5F2PerVisit.feb23,
//     pro5F3PerVisit.feb23
// ]
// // mar 23
// export const pro5WithMar23Charges = [
//     pro5F1Charge.mar23,
//     pro5F2Charge.mar23,
//     pro5F3Charge.mar23
// ]
// export const pro5WithMar23Pay = [
//     pro5F1Payment.mar23,
//     pro5F2Payment.mar23,
//     pro5F3Payment.mar23
// ]
// export const pro5WithMar23Count = [
//     pro5F1Collectable.mar23,
//     pro5F2Collectable.mar23,
//     pro5F3Collectable.mar23
// ]
// export const pro5WithMar23NonCount = [
//     pro5F1NonCollectable.mar23,
//     pro5F2NonCollectable.mar23,
//     pro5F3NonCollectable.mar23
// ]
// export const pro5WithMar23Claim = [
//     pro5F1ClaimCount.mar23,
//     pro5F2ClaimCount.mar23,
//     pro5F3ClaimCount.mar23
// ]
// export const pro5WithMar23PerVisit = [
//     pro5F1PerVisit.mar23,
//     pro5F2PerVisit.mar23,
//     pro5F3PerVisit.mar23
// ]
// // april 23
// export const pro5WithApr23Charges = [
//     pro5F1Charge.apr23,
//     pro5F2Charge.apr23,
//     pro5F3Charge.apr23
// ]
// export const pro5WithApr23Pay = [
//     pro5F1Payment.apr23,
//     pro5F2Payment.apr23,
//     pro5F3Payment.apr23
// ]
// export const pro5WithApr23Count = [
//     pro5F1Collectable.apr23,
//     pro5F2Collectable.apr23,
//     pro5F3Collectable.apr23
// ]
// export const pro5WithApr23NonCount = [
//     pro5F1NonCollectable.apr23,
//     pro5F2NonCollectable.apr23,
//     pro5F3NonCollectable.apr23
// ]
// export const pro5WithApr23Claim = [
//     pro5F1ClaimCount.apr23,
//     pro5F2ClaimCount.apr23,
//     pro5F3ClaimCount.apr23
// ]
// export const pro5WithApr23PerVisit = [
//     pro5F1PerVisit.apr23,
//     pro5F2PerVisit.apr23,
//     pro5F3PerVisit.apr23
// ]
// // may 23
// export const pro5WithMay23Charges = [
//     pro5F1Charge.may23,
//     pro5F2Charge.may23,
//     pro5F3Charge.may23
// ]
// export const pro5WithMay23Pay = [
//     pro5F1Payment.may23,
//     pro5F2Payment.may23,
//     pro5F3Payment.may23
// ]
// export const pro5WithMay23Count = [
//     pro5F1Collectable.may23,
//     pro5F2Collectable.may23,
//     pro5F3Collectable.may23
// ]
// export const pro5WithMay23NonCount = [
//     pro5F1NonCollectable.may23,
//     pro5F2NonCollectable.may23,
//     pro5F3NonCollectable.may23
// ]
// export const pro5WithMay23Claim = [
//     pro5F1ClaimCount.may23,
//     pro5F2ClaimCount.may23,
//     pro5F3ClaimCount.may23
// ]
// export const pro5WithMay23PerVisit = [
//     pro5F1PerVisit.may23,
//     pro5F2PerVisit.may23,
//     pro5F3PerVisit.may23
// ]
// // jun 23
// export const pro5WithJun23Charges = [
//     pro5F1Charge.jun23,
//     pro5F2Charge.jun23,
//     pro5F3Charge.jun23
// ]
// export const pro5WithJun23Pay = [
//     pro5F1Payment.jun23,
//     pro5F2Payment.jun23,
//     pro5F3Payment.jun23
// ]
// export const pro5WithJun23Count = [
//     pro5F1Collectable.jun23,
//     pro5F2Collectable.jun23,
//     pro5F3Collectable.jun23
// ]
// export const pro5WithJun23NonCount = [
//     pro5F1NonCollectable.jun23,
//     pro5F2NonCollectable.jun23,
//     pro5F3NonCollectable.jun23
// ]
// export const pro5WithJun23Claim = [
//     pro5F1ClaimCount.jun23,
//     pro5F2ClaimCount.jun23,
//     pro5F3ClaimCount.jun23
// ]
// export const pro5WithJun23PerVisit = [
//     pro5F1PerVisit.jun23,
//     pro5F2PerVisit.jun23,
//     pro5F3PerVisit.jun23
// ]
// // jul 23
// export const pro5WithJul23Charges = [
//     pro5F1Charge.jul23,
//     pro5F2Charge.jul23,
//     pro5F3Charge.jul23
// ]
// export const pro5WithJul23Pay = [
//     pro5F1Payment.jul23,
//     pro5F2Payment.jul23,
//     pro5F3Payment.jul23
// ]
// export const pro5WithJul23Count = [
//     pro5F1Collectable.jul23,
//     pro5F2Collectable.jul23,
//     pro5F3Collectable.jul23
// ]
// export const pro5WithJul23NonCount = [
//     pro5F1NonCollectable.jul23,
//     pro5F2NonCollectable.jul23,
//     pro5F3NonCollectable.jul23
// ]
// export const pro5WithJul23Claim = [
//     pro5F1ClaimCount.jul23,
//     pro5F2ClaimCount.jul23,
//     pro5F3ClaimCount.jul23
// ]
// export const pro5WithJul23PerVisit = [
//     pro5F1PerVisit.jul23,
//     pro5F2PerVisit.jul23,
//     pro5F3PerVisit.jul23
// ]
// // aug 23
// export const pro5WithAug23Charges = [
//     pro5F1Charge.aug23,
//     pro5F2Charge.aug23,
//     pro5F3Charge.aug23
// ]
// export const pro5WithAug23Pay = [
//     pro5F1Payment.aug23,
//     pro5F2Payment.aug23,
//     pro5F3Payment.aug23
// ]
// export const pro5WithAug23Count = [
//     pro5F1Collectable.aug23,
//     pro5F2Collectable.aug23,
//     pro5F3Collectable.aug23
// ]
// export const pro5WithAug23NonCount = [
//     pro5F1NonCollectable.aug23,
//     pro5F2NonCollectable.aug23,
//     pro5F3NonCollectable.aug23
// ]
// export const pro5WithAug23Claim = [
//     pro5F1ClaimCount.aug23,
//     pro5F2ClaimCount.aug23,
//     pro5F3ClaimCount.aug23
// ]
// export const pro5WithAug23PerVisit = [
//     pro5F1PerVisit.aug23,
//     pro5F2PerVisit.aug23,
//     pro5F3PerVisit.aug23
// ]
// // sep 23
// export const pro5WithSep23Charges = [
//     pro5F1Charge.sep23,
//     pro5F2Charge.sep23,
//     pro5F3Charge.sep23
// ]
// export const pro5WithSep23Pay = [
//     pro5F1Payment.sep23,
//     pro5F2Payment.sep23,
//     pro5F3Payment.sep23
// ]
// export const pro5WithSep23Count = [
//     pro5F1Collectable.sep23,
//     pro5F2Collectable.sep23,
//     pro5F3Collectable.sep23
// ]
// export const pro5WithSep23NonCount = [
//     pro5F1NonCollectable.sep23,
//     pro5F2NonCollectable.sep23,
//     pro5F3NonCollectable.sep23
// ]
// export const pro5WithSep23Claim = [
//     pro5F1ClaimCount.sep23,
//     pro5F2ClaimCount.sep23,
//     pro5F3ClaimCount.sep23
// ]
// export const pro5WithSep23PerVisit = [
//     pro5F1PerVisit.sep23,
//     pro5F2PerVisit.sep23,
//     pro5F3PerVisit.sep23
// ]
// // oct 23
// export const pro5WithOct23Charges = [
//     pro5F1Charge.oct23,
//     pro5F2Charge.oct23,
//     pro5F3Charge.oct23
// ]
// export const pro5WithOct23Pay = [
//     pro5F1Payment.oct23,
//     pro5F2Payment.oct23,
//     pro5F3Payment.oct23
// ]
// export const pro5WithOct23Count = [
//     pro5F1Collectable.oct23,
//     pro5F2Collectable.oct23,
//     pro5F3Collectable.oct23
// ]
// export const pro5WithOct23NonCount = [
//     pro5F1NonCollectable.oct23,
//     pro5F2NonCollectable.oct23,
//     pro5F3NonCollectable.oct23
// ]
// export const pro5WithOct23Claim = [
//     pro5F1ClaimCount.oct23,
//     pro5F2ClaimCount.oct23,
//     pro5F3ClaimCount.oct23
// ]
// export const pro5WithOct23PerVisit = [
//     pro5F1PerVisit.oct23,
//     pro5F2PerVisit.oct23,
//     pro5F3PerVisit.oct23
// ]
// // nov 23
// export const pro5WithNov23Charges = [
//     pro5F1Charge.nov23,
//     pro5F2Charge.nov23,
//     pro5F3Charge.nov23
// ]
// export const pro5WithNov23Pay = [
//     pro5F1Payment.nov23,
//     pro5F2Payment.nov23,
//     pro5F3Payment.nov23
// ]
// export const pro5WithNov23Count = [
//     pro5F1Collectable.nov23,
//     pro5F2Collectable.nov23,
//     pro5F3Collectable.nov23
// ]
// export const pro5WithNov23NonCount = [
//     pro5F1NonCollectable.nov23,
//     pro5F2NonCollectable.nov23,
//     pro5F3NonCollectable.nov23
// ]
// export const pro5WithNov23Claim = [
//     pro5F1ClaimCount.nov23,
//     pro5F2ClaimCount.nov23,
//     pro5F3ClaimCount.nov23
// ]
// export const pro5WithNov23PerVisit = [
//     pro5F1PerVisit.nov23,
//     pro5F2PerVisit.nov23,
//     pro5F3PerVisit.nov23
// ]


// provider-6
// jan 21
// export const pro6WithJan21Charges = [
//     pro6F1Charge.jan21,
//     pro6F2Charge.jan21,
//     pro6F3Charge.jan21
// ]
// export const pro6WithJan21Pay = [
//     pro6F1Payment.jan21,
//     pro6F2Payment.jan21,
//     pro6F3Payment.jan21
// ]
// export const pro6WithJan21Count = [
//     pro6F1Collectable.jan21,
//     pro6F2Collectable.jan21,
//     pro6F3Collectable.jan21
// ]
// export const pro6WithJan21NonCount = [
//     pro6F1NonCollectable.jan21,
//     pro6F2NonCollectable.jan21,
//     pro6F3NonCollectable.jan21
// ]
// export const pro6WithJan21Claim = [
//     pro6F1ClaimCount.jan21,
//     pro6F2ClaimCount.jan21,
//     pro6F3ClaimCount.jan21
// ]
// export const pro6WithJan21PerVisit = [
//     pro6F1PerVisit.jan21,
//     pro6F2PerVisit.jan21,
//     pro6F3PerVisit.jan21
// ]
// // feb 21
// export const pro6WithFeb21Charges = [
//     pro6F1Charge.feb21,
//     pro6F2Charge.feb21,
//     pro6F3Charge.feb21
// ]
// export const pro6WithFeb21Pay = [
//     pro6F1Payment.feb21,
//     pro6F2Payment.feb21,
//     pro6F3Payment.feb21
// ]
// export const pro6WithFeb21Count = [
//     pro6F1Collectable.feb21,
//     pro6F2Collectable.feb21,
//     pro6F3Collectable.feb21
// ]
// export const pro6WithFeb21NonCount = [
//     pro6F1NonCollectable.feb21,
//     pro6F2NonCollectable.feb21,
//     pro6F3NonCollectable.feb21
// ]
// export const pro6WithFeb21Claim = [
//     pro6F1ClaimCount.feb21,
//     pro6F2ClaimCount.feb21,
//     pro6F3ClaimCount.feb21
// ]
// export const pro6WithFeb21PerVisit = [
//     pro6F1PerVisit.feb21,
//     pro6F2PerVisit.feb21,
//     pro6F3PerVisit.feb21
// ]
// // mar 21
// export const pro6WithMar21Charges = [
//     pro6F1Charge.mar21,
//     pro6F2Charge.mar21,
//     pro6F3Charge.mar21
// ]
// export const pro6WithMar21Pay = [
//     pro6F1Payment.mar21,
//     pro6F2Payment.mar21,
//     pro6F3Payment.mar21
// ]
// export const pro6WithMar21Count = [
//     pro6F1Collectable.mar21,
//     pro6F2Collectable.mar21,
//     pro6F3Collectable.mar21
// ]
// export const pro6WithMar21NonCount = [
//     pro6F1NonCollectable.mar21,
//     pro6F2NonCollectable.mar21,
//     pro6F3NonCollectable.mar21
// ]
// export const pro6WithMar21Claim = [
//     pro6F1ClaimCount.mar21,
//     pro6F2ClaimCount.mar21,
//     pro6F3ClaimCount.mar21
// ]
// export const pro6WithMar21PerVisit = [
//     pro6F1PerVisit.mar21,
//     pro6F2PerVisit.mar21,
//     pro6F3PerVisit.mar21
// ]
// // april 21
// export const pro6WithApr21Charges = [
//     pro6F1Charge.apr21,
//     pro6F2Charge.apr21,
//     pro6F3Charge.apr21
// ]
// export const pro6WithApr21Pay = [
//     pro6F1Payment.apr21,
//     pro6F2Payment.apr21,
//     pro6F3Payment.apr21
// ]
// export const pro6WithApr21Count = [
//     pro6F1Collectable.apr21,
//     pro6F2Collectable.apr21,
//     pro6F3Collectable.apr21
// ]
// export const pro6WithApr21NonCount = [
//     pro6F1NonCollectable.apr21,
//     pro6F2NonCollectable.apr21,
//     pro6F3NonCollectable.apr21
// ]
// export const pro6WithApr21Claim = [
//     pro6F1ClaimCount.apr21,
//     pro6F2ClaimCount.apr21,
//     pro6F3ClaimCount.apr21
// ]
// export const pro6WithApr21PerVisit = [
//     pro6F1PerVisit.apr21,
//     pro6F2PerVisit.apr21,
//     pro6F3PerVisit.apr21
// ]
// // may 21
// export const pro6WithMay21Charges = [
//     pro6F1Charge.may21,
//     pro6F2Charge.may21,
//     pro6F3Charge.may21
// ]
// export const pro6WithMay21Pay = [
//     pro6F1Payment.may21,
//     pro6F2Payment.may21,
//     pro6F3Payment.may21
// ]
// export const pro6WithMay21Count = [
//     pro6F1Collectable.may21,
//     pro6F2Collectable.may21,
//     pro6F3Collectable.may21
// ]
// export const pro6WithMay21NonCount = [
//     pro6F1NonCollectable.may21,
//     pro6F2NonCollectable.may21,
//     pro6F3NonCollectable.may21
// ]
// export const pro6WithMay21Claim = [
//     pro6F1ClaimCount.may21,
//     pro6F2ClaimCount.may21,
//     pro6F3ClaimCount.may21
// ]
// export const pro6WithMay21PerVisit = [
//     pro6F1PerVisit.may21,
//     pro6F2PerVisit.may21,
//     pro6F3PerVisit.may21
// ]
// // jun 21
// export const pro6WithJun21Charges = [
//     pro6F1Charge.jun21,
//     pro6F2Charge.jun21,
//     pro6F3Charge.jun21
// ]
// export const pro6WithJun21Pay = [
//     pro6F1Payment.jun21,
//     pro6F2Payment.jun21,
//     pro6F3Payment.jun21
// ]
// export const pro6WithJun21Count = [
//     pro6F1Collectable.jun21,
//     pro6F2Collectable.jun21,
//     pro6F3Collectable.jun21
// ]
// export const pro6WithJun21NonCount = [
//     pro6F1NonCollectable.jun21,
//     pro6F2NonCollectable.jun21,
//     pro6F3NonCollectable.jun21
// ]
// export const pro6WithJun21Claim = [
//     pro6F1ClaimCount.jun21,
//     pro6F2ClaimCount.jun21,
//     pro6F3ClaimCount.jun21
// ]
// export const pro6WithJun21PerVisit = [
//     pro6F1PerVisit.jun21,
//     pro6F2PerVisit.jun21,
//     pro6F3PerVisit.jun21
// ]
// // jul 21
// export const pro6WithJul21Charges = [
//     pro6F1Charge.jul21,
//     pro6F2Charge.jul21,
//     pro6F3Charge.jul21
// ]
// export const pro6WithJul21Pay = [
//     pro6F1Payment.jul21,
//     pro6F2Payment.jul21,
//     pro6F3Payment.jul21
// ]
// export const pro6WithJul21Count = [
//     pro6F1Collectable.jul21,
//     pro6F2Collectable.jul21,
//     pro6F3Collectable.jul21
// ]
// export const pro6WithJul21NonCount = [
//     pro6F1NonCollectable.jul21,
//     pro6F2NonCollectable.jul21,
//     pro6F3NonCollectable.jul21
// ]
// export const pro6WithJul21Claim = [
//     pro6F1ClaimCount.jul21,
//     pro6F2ClaimCount.jul21,
//     pro6F3ClaimCount.jul21
// ]
// export const pro6WithJul21PerVisit = [
//     pro6F1PerVisit.jul21,
//     pro6F2PerVisit.jul21,
//     pro6F3PerVisit.jul21
// ]
// // aug 21
// export const pro6WithAug21Charges = [
//     pro6F1Charge.aug21,
//     pro6F2Charge.aug21,
//     pro6F3Charge.aug21
// ]
// export const pro6WithAug21Pay = [
//     pro6F1Payment.aug21,
//     pro6F2Payment.aug21,
//     pro6F3Payment.aug21
// ]
// export const pro6WithAug21Count = [
//     pro6F1Collectable.aug21,
//     pro6F2Collectable.aug21,
//     pro6F3Collectable.aug21
// ]
// export const pro6WithAug21NonCount = [
//     pro6F1NonCollectable.aug21,
//     pro6F2NonCollectable.aug21,
//     pro6F3NonCollectable.aug21
// ]
// export const pro6WithAug21Claim = [
//     pro6F1ClaimCount.aug21,
//     pro6F2ClaimCount.aug21,
//     pro6F3ClaimCount.aug21
// ]
// export const pro6WithAug21PerVisit = [
//     pro6F1PerVisit.aug21,
//     pro6F2PerVisit.aug21,
//     pro6F3PerVisit.aug21
// ]
// // sep 21
// export const pro6WithSep21Charges = [
//     pro6F1Charge.sep21,
//     pro6F2Charge.sep21,
//     pro6F3Charge.sep21
// ]
// export const pro6WithSep21Pay = [
//     pro6F1Payment.sep21,
//     pro6F2Payment.sep21,
//     pro6F3Payment.sep21
// ]
// export const pro6WithSep21Count = [
//     pro6F1Collectable.sep21,
//     pro6F2Collectable.sep21,
//     pro6F3Collectable.sep21
// ]
// export const pro6WithSep21NonCount = [
//     pro6F1NonCollectable.sep21,
//     pro6F2NonCollectable.sep21,
//     pro6F3NonCollectable.sep21
// ]
// export const pro6WithSep21Claim = [
//     pro6F1ClaimCount.sep21,
//     pro6F2ClaimCount.sep21,
//     pro6F3ClaimCount.sep21
// ]
// export const pro6WithSep21PerVisit = [
//     pro6F1PerVisit.sep21,
//     pro6F2PerVisit.sep21,
//     pro6F3PerVisit.sep21
// ]
// // oct 21
// export const pro6WithOct21Charges = [
//     pro6F1Charge.oct21,
//     pro6F2Charge.oct21,
//     pro6F3Charge.oct21
// ]
// export const pro6WithOct21Pay = [
//     pro6F1Payment.oct21,
//     pro6F2Payment.oct21,
//     pro6F3Payment.oct21
// ]
// export const pro6WithOct21Count = [
//     pro6F1Collectable.oct21,
//     pro6F2Collectable.oct21,
//     pro6F3Collectable.oct21
// ]
// export const pro6WithOct21NonCount = [
//     pro6F1NonCollectable.oct21,
//     pro6F2NonCollectable.oct21,
//     pro6F3NonCollectable.oct21
// ]
// export const pro6WithOct21Claim = [
//     pro6F1ClaimCount.oct21,
//     pro6F2ClaimCount.oct21,
//     pro6F3ClaimCount.oct21
// ]
// export const pro6WithOct21PerVisit = [
//     pro6F1PerVisit.oct21,
//     pro6F2PerVisit.oct21,
//     pro6F3PerVisit.oct21
// ]
// // nov 21
// export const pro6WithNov21Charges = [
//     pro6F1Charge.nov21,
//     pro6F2Charge.nov21,
//     pro6F3Charge.nov21
// ]
// export const pro6WithNov21Pay = [
//     pro6F1Payment.nov21,
//     pro6F2Payment.nov21,
//     pro6F3Payment.nov21
// ]
// export const pro6WithNov21Count = [
//     pro6F1Collectable.nov21,
//     pro6F2Collectable.nov21,
//     pro6F3Collectable.nov21
// ]
// export const pro6WithNov21NonCount = [
//     pro6F1NonCollectable.nov21,
//     pro6F2NonCollectable.nov21,
//     pro6F3NonCollectable.nov21
// ]
// export const pro6WithNov21Claim = [
//     pro6F1ClaimCount.nov21,
//     pro6F2ClaimCount.nov21,
//     pro6F3ClaimCount.nov21
// ]
// export const pro6WithNov21PerVisit = [
//     pro6F1PerVisit.nov21,
//     pro6F2PerVisit.nov21,
//     pro6F3PerVisit.nov21
// ]
// // dec 21
// export const pro6WithDec21Charges = [
//     pro6F1Charge.dec21,
//     pro6F2Charge.dec21,
//     pro6F3Charge.dec21
// ]
// export const pro6WithDec21Pay = [
//     pro6F1Payment.dec21,
//     pro6F2Payment.dec21,
//     pro6F3Payment.dec21
// ]
// export const pro6WithDec21Count = [
//     pro6F1Collectable.dec21,
//     pro6F2Collectable.dec21,
//     pro6F3Collectable.dec21
// ]
// export const pro6WithDec21NonCount = [
//     pro6F1NonCollectable.dec21,
//     pro6F2NonCollectable.dec21,
//     pro6F3NonCollectable.dec21
// ]
// export const pro6WithDec21Claim = [
//     pro6F1ClaimCount.dec21,
//     pro6F2ClaimCount.dec21,
//     pro6F3ClaimCount.dec21
// ]
// export const pro6WithDec21PerVisit = [
//     pro6F1PerVisit.dec21,
//     pro6F2PerVisit.dec21,
//     pro6F3PerVisit.dec21
// ]

// // provider-6
// // jan 22
// export const pro6WithJan22Charges = [
//     pro6F1Charge.jan22,
//     pro6F2Charge.jan22,
//     pro6F3Charge.jan22
// ]
// export const pro6WithJan22Pay = [
//     pro6F1Payment.jan22,
//     pro6F2Payment.jan22,
//     pro6F3Payment.jan22
// ]
// export const pro6WithJan22Count = [
//     pro6F1Collectable.jan22,
//     pro6F2Collectable.jan22,
//     pro6F3Collectable.jan22
// ]
// export const pro6WithJan22NonCount = [
//     pro6F1NonCollectable.jan22,
//     pro6F2NonCollectable.jan22,
//     pro6F3NonCollectable.jan22
// ]
// export const pro6WithJan22Claim = [
//     pro6F1ClaimCount.jan22,
//     pro6F2ClaimCount.jan22,
//     pro6F3ClaimCount.jan22
// ]
// export const pro6WithJan22PerVisit = [
//     pro6F1PerVisit.jan22,
//     pro6F2PerVisit.jan22,
//     pro6F3PerVisit.jan22
// ]
// // feb 22
// export const pro6WithFeb22Charges = [
//     pro6F1Charge.feb22,
//     pro6F2Charge.feb22,
//     pro6F3Charge.feb22
// ]
// export const pro6WithFeb22Pay = [
//     pro6F1Payment.feb22,
//     pro6F2Payment.feb22,
//     pro6F3Payment.feb22
// ]
// export const pro6WithFeb22Count = [
//     pro6F1Collectable.feb22,
//     pro6F2Collectable.feb22,
//     pro6F3Collectable.feb22
// ]
// export const pro6WithFeb22NonCount = [
//     pro6F1NonCollectable.feb22,
//     pro6F2NonCollectable.feb22,
//     pro6F3NonCollectable.feb22
// ]
// export const pro6WithFeb22Claim = [
//     pro6F1ClaimCount.feb22,
//     pro6F2ClaimCount.feb22,
//     pro6F3ClaimCount.feb22
// ]
// export const pro6WithFeb22PerVisit = [
//     pro6F1PerVisit.feb22,
//     pro6F2PerVisit.feb22,
//     pro6F3PerVisit.feb22
// ]
// // mar 22
// export const pro6WithMar22Charges = [
//     pro6F1Charge.mar22,
//     pro6F2Charge.mar22,
//     pro6F3Charge.mar22
// ]
// export const pro6WithMar22Pay = [
//     pro6F1Payment.mar22,
//     pro6F2Payment.mar22,
//     pro6F3Payment.mar22
// ]
// export const pro6WithMar22Count = [
//     pro6F1Collectable.mar22,
//     pro6F2Collectable.mar22,
//     pro6F3Collectable.mar22
// ]
// export const pro6WithMar22NonCount = [
//     pro6F1NonCollectable.mar22,
//     pro6F2NonCollectable.mar22,
//     pro6F3NonCollectable.mar22
// ]
// export const pro6WithMar22Claim = [
//     pro6F1ClaimCount.mar22,
//     pro6F2ClaimCount.mar22,
//     pro6F3ClaimCount.mar22
// ]
// export const pro6WithMar22PerVisit = [
//     pro6F1PerVisit.mar22,
//     pro6F2PerVisit.mar22,
//     pro6F3PerVisit.mar22
// ]
// // april 22
// export const pro6WithApr22Charges = [
//     pro6F1Charge.apr22,
//     pro6F2Charge.apr22,
//     pro6F3Charge.apr22
// ]
// export const pro6WithApr22Pay = [
//     pro6F1Payment.apr22,
//     pro6F2Payment.apr22,
//     pro6F3Payment.apr22
// ]
// export const pro6WithApr22Count = [
//     pro6F1Collectable.apr22,
//     pro6F2Collectable.apr22,
//     pro6F3Collectable.apr22
// ]
// export const pro6WithApr22NonCount = [
//     pro6F1NonCollectable.apr22,
//     pro6F2NonCollectable.apr22,
//     pro6F3NonCollectable.apr22
// ]
// export const pro6WithApr22Claim = [
//     pro6F1ClaimCount.apr22,
//     pro6F2ClaimCount.apr22,
//     pro6F3ClaimCount.apr22
// ]
// export const pro6WithApr22PerVisit = [
//     pro6F1PerVisit.apr22,
//     pro6F2PerVisit.apr22,
//     pro6F3PerVisit.apr22
// ]
// // may 22
// export const pro6WithMay22Charges = [
//     pro6F1Charge.may22,
//     pro6F2Charge.may22,
//     pro6F3Charge.may22
// ]
// export const pro6WithMay22Pay = [
//     pro6F1Payment.may22,
//     pro6F2Payment.may22,
//     pro6F3Payment.may22
// ]
// export const pro6WithMay22Count = [
//     pro6F1Collectable.may22,
//     pro6F2Collectable.may22,
//     pro6F3Collectable.may22
// ]
// export const pro6WithMay22NonCount = [
//     pro6F1NonCollectable.may22,
//     pro6F2NonCollectable.may22,
//     pro6F3NonCollectable.may22
// ]
// export const pro6WithMay22Claim = [
//     pro6F1ClaimCount.may22,
//     pro6F2ClaimCount.may22,
//     pro6F3ClaimCount.may22
// ]
// export const pro6WithMay22PerVisit = [
//     pro6F1PerVisit.may22,
//     pro6F2PerVisit.may22,
//     pro6F3PerVisit.may22
// ]
// // jun 22
// export const pro6WithJun22Charges = [
//     pro6F1Charge.jun22,
//     pro6F2Charge.jun22,
//     pro6F3Charge.jun22
// ]
// export const pro6WithJun22Pay = [
//     pro6F1Payment.jun22,
//     pro6F2Payment.jun22,
//     pro6F3Payment.jun22
// ]
// export const pro6WithJun22Count = [
//     pro6F1Collectable.jun22,
//     pro6F2Collectable.jun22,
//     pro6F3Collectable.jun22
// ]
// export const pro6WithJun22NonCount = [
//     pro6F1NonCollectable.jun22,
//     pro6F2NonCollectable.jun22,
//     pro6F3NonCollectable.jun22
// ]
// export const pro6WithJun22Claim = [
//     pro6F1ClaimCount.jun22,
//     pro6F2ClaimCount.jun22,
//     pro6F3ClaimCount.jun22
// ]
// export const pro6WithJun22PerVisit = [
//     pro6F1PerVisit.jun22,
//     pro6F2PerVisit.jun22,
//     pro6F3PerVisit.jun22
// ]
// // jul 22
// export const pro6WithJul22Charges = [
//     pro6F1Charge.jul22,
//     pro6F2Charge.jul22,
//     pro6F3Charge.jul22
// ]
// export const pro6WithJul22Pay = [
//     pro6F1Payment.jul22,
//     pro6F2Payment.jul22,
//     pro6F3Payment.jul22
// ]
// export const pro6WithJul22Count = [
//     pro6F1Collectable.jul22,
//     pro6F2Collectable.jul22,
//     pro6F3Collectable.jul22
// ]
// export const pro6WithJul22NonCount = [
//     pro6F1NonCollectable.jul22,
//     pro6F2NonCollectable.jul22,
//     pro6F3NonCollectable.jul22
// ]
// export const pro6WithJul22Claim = [
//     pro6F1ClaimCount.jul22,
//     pro6F2ClaimCount.jul22,
//     pro6F3ClaimCount.jul22
// ]
// export const pro6WithJul22PerVisit = [
//     pro6F1PerVisit.jul22,
//     pro6F2PerVisit.jul22,
//     pro6F3PerVisit.jul22
// ]
// // aug 22
// export const pro6WithAug22Charges = [
//     pro6F1Charge.aug22,
//     pro6F2Charge.aug22,
//     pro6F3Charge.aug22
// ]
// export const pro6WithAug22Pay = [
//     pro6F1Payment.aug22,
//     pro6F2Payment.aug22,
//     pro6F3Payment.aug22
// ]
// export const pro6WithAug22Count = [
//     pro6F1Collectable.aug22,
//     pro6F2Collectable.aug22,
//     pro6F3Collectable.aug22
// ]
// export const pro6WithAug22NonCount = [
//     pro6F1NonCollectable.aug22,
//     pro6F2NonCollectable.aug22,
//     pro6F3NonCollectable.aug22
// ]
// export const pro6WithAug22Claim = [
//     pro6F1ClaimCount.aug22,
//     pro6F2ClaimCount.aug22,
//     pro6F3ClaimCount.aug22
// ]
// export const pro6WithAug22PerVisit = [
//     pro6F1PerVisit.aug22,
//     pro6F2PerVisit.aug22,
//     pro6F3PerVisit.aug22
// ]
// // sep 22
// export const pro6WithSep22Charges = [
//     pro6F1Charge.sep22,
//     pro6F2Charge.sep22,
//     pro6F3Charge.sep22
// ]
// export const pro6WithSep22Pay = [
//     pro6F1Payment.sep22,
//     pro6F2Payment.sep22,
//     pro6F3Payment.sep22
// ]
// export const pro6WithSep22Count = [
//     pro6F1Collectable.sep22,
//     pro6F2Collectable.sep22,
//     pro6F3Collectable.sep22
// ]
// export const pro6WithSep22NonCount = [
//     pro6F1NonCollectable.sep22,
//     pro6F2NonCollectable.sep22,
//     pro6F3NonCollectable.sep22
// ]
// export const pro6WithSep22Claim = [
//     pro6F1ClaimCount.sep22,
//     pro6F2ClaimCount.sep22,
//     pro6F3ClaimCount.sep22
// ]
// export const pro6WithSep22PerVisit = [
//     pro6F1PerVisit.sep22,
//     pro6F2PerVisit.sep22,
//     pro6F3PerVisit.sep22
// ]
// // oct 22
// export const pro6WithOct22Charges = [
//     pro6F1Charge.oct22,
//     pro6F2Charge.oct22,
//     pro6F3Charge.oct22
// ]
// export const pro6WithOct22Pay = [
//     pro6F1Payment.oct22,
//     pro6F2Payment.oct22,
//     pro6F3Payment.oct22
// ]
// export const pro6WithOct22Count = [
//     pro6F1Collectable.oct22,
//     pro6F2Collectable.oct22,
//     pro6F3Collectable.oct22
// ]
// export const pro6WithOct22NonCount = [
//     pro6F1NonCollectable.oct22,
//     pro6F2NonCollectable.oct22,
//     pro6F3NonCollectable.oct22
// ]
// export const pro6WithOct22Claim = [
//     pro6F1ClaimCount.oct22,
//     pro6F2ClaimCount.oct22,
//     pro6F3ClaimCount.oct22
// ]
// export const pro6WithOct22PerVisit = [
//     pro6F1PerVisit.oct22,
//     pro6F2PerVisit.oct22,
//     pro6F3PerVisit.oct22
// ]
// // nov 22
// export const pro6WithNov22Charges = [
//     pro6F1Charge.nov22,
//     pro6F2Charge.nov22,
//     pro6F3Charge.nov22
// ]
// export const pro6WithNov22Pay = [
//     pro6F1Payment.nov22,
//     pro6F2Payment.nov22,
//     pro6F3Payment.nov22
// ]
// export const pro6WithNov22Count = [
//     pro6F1Collectable.nov22,
//     pro6F2Collectable.nov22,
//     pro6F3Collectable.nov22
// ]
// export const pro6WithNov22NonCount = [
//     pro6F1NonCollectable.nov22,
//     pro6F2NonCollectable.nov22,
//     pro6F3NonCollectable.nov22
// ]
// export const pro6WithNov22Claim = [
//     pro6F1ClaimCount.nov22,
//     pro6F2ClaimCount.nov22,
//     pro6F3ClaimCount.nov22
// ]
// export const pro6WithNov22PerVisit = [
//     pro6F1PerVisit.nov22,
//     pro6F2PerVisit.nov22,
//     pro6F3PerVisit.nov22
// ]
// // dec 22
// export const pro6WithDec22Charges = [
//     pro6F1Charge.dec22,
//     pro6F2Charge.dec22,
//     pro6F3Charge.dec22
// ]
// export const pro6WithDec22Pay = [
//     pro6F1Payment.dec22,
//     pro6F2Payment.dec22,
//     pro6F3Payment.dec22
// ]
// export const pro6WithDec22Count = [
//     pro6F1Collectable.dec22,
//     pro6F2Collectable.dec22,
//     pro6F3Collectable.dec22
// ]
// export const pro6WithDec22NonCount = [
//     pro6F1NonCollectable.dec22,
//     pro6F2NonCollectable.dec22,
//     pro6F3NonCollectable.dec22
// ]
// export const pro6WithDec22Claim = [
//     pro6F1ClaimCount.dec22,
//     pro6F2ClaimCount.dec22,
//     pro6F3ClaimCount.dec22
// ]
// export const pro6WithDec22PerVisit = [
//     pro6F1PerVisit.dec22,
//     pro6F2PerVisit.dec22,
//     pro6F3PerVisit.dec22
// ]

// // provider-6
// // jan 23
// export const pro6WithJan23Charges = [
//     pro6F1Charge.jan23,
//     pro6F2Charge.jan23,
//     pro6F3Charge.jan23
// ]
// export const pro6WithJan23Pay = [
//     pro6F1Payment.jan23,
//     pro6F2Payment.jan23,
//     pro6F3Payment.jan23
// ]
// export const pro6WithJan23Count = [
//     pro6F1Collectable.jan23,
//     pro6F2Collectable.jan23,
//     pro6F3Collectable.jan23
// ]
// export const pro6WithJan23NonCount = [
//     pro6F1NonCollectable.jan23,
//     pro6F2NonCollectable.jan23,
//     pro6F3NonCollectable.jan23
// ]
// export const pro6WithJan23Claim = [
//     pro6F1ClaimCount.jan23,
//     pro6F2ClaimCount.jan23,
//     pro6F3ClaimCount.jan23
// ]
// export const pro6WithJan23PerVisit = [
//     pro6F1PerVisit.jan23,
//     pro6F2PerVisit.jan23,
//     pro6F3PerVisit.jan23
// ]
// // feb 23
// export const pro6WithFeb23Charges = [
//     pro6F1Charge.feb23,
//     pro6F2Charge.feb23,
//     pro6F3Charge.feb23
// ]
// export const pro6WithFeb23Pay = [
//     pro6F1Payment.feb23,
//     pro6F2Payment.feb23,
//     pro6F3Payment.feb23
// ]
// export const pro6WithFeb23Count = [
//     pro6F1Collectable.feb23,
//     pro6F2Collectable.feb23,
//     pro6F3Collectable.feb23
// ]
// export const pro6WithFeb23NonCount = [
//     pro6F1NonCollectable.feb23,
//     pro6F2NonCollectable.feb23,
//     pro6F3NonCollectable.feb23
// ]
// export const pro6WithFeb23Claim = [
//     pro6F1ClaimCount.feb23,
//     pro6F2ClaimCount.feb23,
//     pro6F3ClaimCount.feb23
// ]
// export const pro6WithFeb23PerVisit = [
//     pro6F1PerVisit.feb23,
//     pro6F2PerVisit.feb23,
//     pro6F3PerVisit.feb23
// ]
// // mar 23
// export const pro6WithMar23Charges = [
//     pro6F1Charge.mar23,
//     pro6F2Charge.mar23,
//     pro6F3Charge.mar23
// ]
// export const pro6WithMar23Pay = [
//     pro6F1Payment.mar23,
//     pro6F2Payment.mar23,
//     pro6F3Payment.mar23
// ]
// export const pro6WithMar23Count = [
//     pro6F1Collectable.mar23,
//     pro6F2Collectable.mar23,
//     pro6F3Collectable.mar23
// ]
// export const pro6WithMar23NonCount = [
//     pro6F1NonCollectable.mar23,
//     pro6F2NonCollectable.mar23,
//     pro6F3NonCollectable.mar23
// ]
// export const pro6WithMar23Claim = [
//     pro6F1ClaimCount.mar23,
//     pro6F2ClaimCount.mar23,
//     pro6F3ClaimCount.mar23
// ]
// export const pro6WithMar23PerVisit = [
//     pro6F1PerVisit.mar23,
//     pro6F2PerVisit.mar23,
//     pro6F3PerVisit.mar23
// ]
// // april 23
// export const pro6WithApr23Charges = [
//     pro6F1Charge.apr23,
//     pro6F2Charge.apr23,
//     pro6F3Charge.apr23
// ]
// export const pro6WithApr23Pay = [
//     pro6F1Payment.apr23,
//     pro6F2Payment.apr23,
//     pro6F3Payment.apr23
// ]
// export const pro6WithApr23Count = [
//     pro6F1Collectable.apr23,
//     pro6F2Collectable.apr23,
//     pro6F3Collectable.apr23
// ]
// export const pro6WithApr23NonCount = [
//     pro6F1NonCollectable.apr23,
//     pro6F2NonCollectable.apr23,
//     pro6F3NonCollectable.apr23
// ]
// export const pro6WithApr23Claim = [
//     pro6F1ClaimCount.apr23,
//     pro6F2ClaimCount.apr23,
//     pro6F3ClaimCount.apr23
// ]
// export const pro6WithApr23PerVisit = [
//     pro6F1PerVisit.apr23,
//     pro6F2PerVisit.apr23,
//     pro6F3PerVisit.apr23
// ]
// // may 23
// export const pro6WithMay23Charges = [
//     pro6F1Charge.may23,
//     pro6F2Charge.may23,
//     pro6F3Charge.may23
// ]
// export const pro6WithMay23Pay = [
//     pro6F1Payment.may23,
//     pro6F2Payment.may23,
//     pro6F3Payment.may23
// ]
// export const pro6WithMay23Count = [
//     pro6F1Collectable.may23,
//     pro6F2Collectable.may23,
//     pro6F3Collectable.may23
// ]
// export const pro6WithMay23NonCount = [
//     pro6F1NonCollectable.may23,
//     pro6F2NonCollectable.may23,
//     pro6F3NonCollectable.may23
// ]
// export const pro6WithMay23Claim = [
//     pro6F1ClaimCount.may23,
//     pro6F2ClaimCount.may23,
//     pro6F3ClaimCount.may23
// ]
// export const pro6WithMay23PerVisit = [
//     pro6F1PerVisit.may23,
//     pro6F2PerVisit.may23,
//     pro6F3PerVisit.may23
// ]
// // jun 23
// export const pro6WithJun23Charges = [
//     pro6F1Charge.jun23,
//     pro6F2Charge.jun23,
//     pro6F3Charge.jun23
// ]
// export const pro6WithJun23Pay = [
//     pro6F1Payment.jun23,
//     pro6F2Payment.jun23,
//     pro6F3Payment.jun23
// ]
// export const pro6WithJun23Count = [
//     pro6F1Collectable.jun23,
//     pro6F2Collectable.jun23,
//     pro6F3Collectable.jun23
// ]
// export const pro6WithJun23NonCount = [
//     pro6F1NonCollectable.jun23,
//     pro6F2NonCollectable.jun23,
//     pro6F3NonCollectable.jun23
// ]
// export const pro6WithJun23Claim = [
//     pro6F1ClaimCount.jun23,
//     pro6F2ClaimCount.jun23,
//     pro6F3ClaimCount.jun23
// ]
// export const pro6WithJun23PerVisit = [
//     pro6F1PerVisit.jun23,
//     pro6F2PerVisit.jun23,
//     pro6F3PerVisit.jun23
// ]
// // jul 23
// export const pro6WithJul23Charges = [
//     pro6F1Charge.jul23,
//     pro6F2Charge.jul23,
//     pro6F3Charge.jul23
// ]
// export const pro6WithJul23Pay = [
//     pro6F1Payment.jul23,
//     pro6F2Payment.jul23,
//     pro6F3Payment.jul23
// ]
// export const pro6WithJul23Count = [
//     pro6F1Collectable.jul23,
//     pro6F2Collectable.jul23,
//     pro6F3Collectable.jul23
// ]
// export const pro6WithJul23NonCount = [
//     pro6F1NonCollectable.jul23,
//     pro6F2NonCollectable.jul23,
//     pro6F3NonCollectable.jul23
// ]
// export const pro6WithJul23Claim = [
//     pro6F1ClaimCount.jul23,
//     pro6F2ClaimCount.jul23,
//     pro6F3ClaimCount.jul23
// ]
// export const pro6WithJul23PerVisit = [
//     pro6F1PerVisit.jul23,
//     pro6F2PerVisit.jul23,
//     pro6F3PerVisit.jul23
// ]
// // aug 23
// export const pro6WithAug23Charges = [
//     pro6F1Charge.aug23,
//     pro6F2Charge.aug23,
//     pro6F3Charge.aug23
// ]
// export const pro6WithAug23Pay = [
//     pro6F1Payment.aug23,
//     pro6F2Payment.aug23,
//     pro6F3Payment.aug23
// ]
// export const pro6WithAug23Count = [
//     pro6F1Collectable.aug23,
//     pro6F2Collectable.aug23,
//     pro6F3Collectable.aug23
// ]
// export const pro6WithAug23NonCount = [
//     pro6F1NonCollectable.aug23,
//     pro6F2NonCollectable.aug23,
//     pro6F3NonCollectable.aug23
// ]
// export const pro6WithAug23Claim = [
//     pro6F1ClaimCount.aug23,
//     pro6F2ClaimCount.aug23,
//     pro6F3ClaimCount.aug23
// ]
// export const pro6WithAug23PerVisit = [
//     pro6F1PerVisit.aug23,
//     pro6F2PerVisit.aug23,
//     pro6F3PerVisit.aug23
// ]
// // sep 23
// export const pro6WithSep23Charges = [
//     pro6F1Charge.sep23,
//     pro6F2Charge.sep23,
//     pro6F3Charge.sep23
// ]
// export const pro6WithSep23Pay = [
//     pro6F1Payment.sep23,
//     pro6F2Payment.sep23,
//     pro6F3Payment.sep23
// ]
// export const pro6WithSep23Count = [
//     pro6F1Collectable.sep23,
//     pro6F2Collectable.sep23,
//     pro6F3Collectable.sep23
// ]
// export const pro6WithSep23NonCount = [
//     pro6F1NonCollectable.sep23,
//     pro6F2NonCollectable.sep23,
//     pro6F3NonCollectable.sep23
// ]
// export const pro6WithSep23Claim = [
//     pro6F1ClaimCount.sep23,
//     pro6F2ClaimCount.sep23,
//     pro6F3ClaimCount.sep23
// ]
// export const pro6WithSep23PerVisit = [
//     pro6F1PerVisit.sep23,
//     pro6F2PerVisit.sep23,
//     pro6F3PerVisit.sep23
// ]
// // oct 23
// export const pro6WithOct23Charges = [
//     pro6F1Charge.oct23,
//     pro6F2Charge.oct23,
//     pro6F3Charge.oct23
// ]
// export const pro6WithOct23Pay = [
//     pro6F1Payment.oct23,
//     pro6F2Payment.oct23,
//     pro6F3Payment.oct23
// ]
// export const pro6WithOct23Count = [
//     pro6F1Collectable.oct23,
//     pro6F2Collectable.oct23,
//     pro6F3Collectable.oct23
// ]
// export const pro6WithOct23NonCount = [
//     pro6F1NonCollectable.oct23,
//     pro6F2NonCollectable.oct23,
//     pro6F3NonCollectable.oct23
// ]
// export const pro6WithOct23Claim = [
//     pro6F1ClaimCount.oct23,
//     pro6F2ClaimCount.oct23,
//     pro6F3ClaimCount.oct23
// ]
// export const pro6WithOct23PerVisit = [
//     pro6F1PerVisit.oct23,
//     pro6F2PerVisit.oct23,
//     pro6F3PerVisit.oct23
// ]
// // nov 23
// export const pro6WithNov23Charges = [
//     pro6F1Charge.nov23,
//     pro6F2Charge.nov23,
//     pro6F3Charge.nov23
// ]
// export const pro6WithNov23Pay = [
//     pro6F1Payment.nov23,
//     pro6F2Payment.nov23,
//     pro6F3Payment.nov23
// ]
// export const pro6WithNov23Count = [
//     pro6F1Collectable.nov23,
//     pro6F2Collectable.nov23,
//     pro6F3Collectable.nov23
// ]
// export const pro6WithNov23NonCount = [
//     pro6F1NonCollectable.nov23,
//     pro6F2NonCollectable.nov23,
//     pro6F3NonCollectable.nov23
// ]
// export const pro6WithNov23Claim = [
//     pro6F1ClaimCount.nov23,
//     pro6F2ClaimCount.nov23,
//     pro6F3ClaimCount.nov23
// ]
// export const pro6WithNov23PerVisit = [
//     pro6F1PerVisit.nov23,
//     pro6F2PerVisit.nov23,
//     pro6F3PerVisit.nov23
// ]


// provider-7
// jan 21
// export const pro7WithJan21Charges = [
//     pro7F1Charge.jan21,
//     pro7F2Charge.jan21,
//     pro7F3Charge.jan21
// ]
// export const pro7WithJan21Pay = [
//     pro7F1Payment.jan21,
//     pro7F2Payment.jan21,
//     pro7F3Payment.jan21
// ]
// export const pro7WithJan21Count = [
//     pro7F1Collectable.jan21,
//     pro7F2Collectable.jan21,
//     pro7F3Collectable.jan21
// ]
// export const pro7WithJan21NonCount = [
//     pro7F1NonCollectable.jan21,
//     pro7F2NonCollectable.jan21,
//     pro7F3NonCollectable.jan21
// ]
// export const pro7WithJan21Claim = [
//     pro7F1ClaimCount.jan21,
//     pro7F2ClaimCount.jan21,
//     pro7F3ClaimCount.jan21
// ]
// export const pro7WithJan21PerVisit = [
//     pro7F1PerVisit.jan21,
//     pro7F2PerVisit.jan21,
//     pro7F3PerVisit.jan21
// ]
// // feb 21
// export const pro7WithFeb21Charges = [
//     pro7F1Charge.feb21,
//     pro7F2Charge.feb21,
//     pro7F3Charge.feb21
// ]
// export const pro7WithFeb21Pay = [
//     pro7F1Payment.feb21,
//     pro7F2Payment.feb21,
//     pro7F3Payment.feb21
// ]
// export const pro7WithFeb21Count = [
//     pro7F1Collectable.feb21,
//     pro7F2Collectable.feb21,
//     pro7F3Collectable.feb21
// ]
// export const pro7WithFeb21NonCount = [
//     pro7F1NonCollectable.feb21,
//     pro7F2NonCollectable.feb21,
//     pro7F3NonCollectable.feb21
// ]
// export const pro7WithFeb21Claim = [
//     pro7F1ClaimCount.feb21,
//     pro7F2ClaimCount.feb21,
//     pro7F3ClaimCount.feb21
// ]
// export const pro7WithFeb21PerVisit = [
//     pro7F1PerVisit.feb21,
//     pro7F2PerVisit.feb21,
//     pro7F3PerVisit.feb21
// ]
// // mar 21
// export const pro7WithMar21Charges = [
//     pro7F1Charge.mar21,
//     pro7F2Charge.mar21,
//     pro7F3Charge.mar21
// ]
// export const pro7WithMar21Pay = [
//     pro7F1Payment.mar21,
//     pro7F2Payment.mar21,
//     pro7F3Payment.mar21
// ]
// export const pro7WithMar21Count = [
//     pro7F1Collectable.mar21,
//     pro7F2Collectable.mar21,
//     pro7F3Collectable.mar21
// ]
// export const pro7WithMar21NonCount = [
//     pro7F1NonCollectable.mar21,
//     pro7F2NonCollectable.mar21,
//     pro7F3NonCollectable.mar21
// ]
// export const pro7WithMar21Claim = [
//     pro7F1ClaimCount.mar21,
//     pro7F2ClaimCount.mar21,
//     pro7F3ClaimCount.mar21
// ]
// export const pro7WithMar21PerVisit = [
//     pro7F1PerVisit.mar21,
//     pro7F2PerVisit.mar21,
//     pro7F3PerVisit.mar21
// ]
// // april 21
// export const pro7WithApr21Charges = [
//     pro7F1Charge.apr21,
//     pro7F2Charge.apr21,
//     pro7F3Charge.apr21
// ]
// export const pro7WithApr21Pay = [
//     pro7F1Payment.apr21,
//     pro7F2Payment.apr21,
//     pro7F3Payment.apr21
// ]
// export const pro7WithApr21Count = [
//     pro7F1Collectable.apr21,
//     pro7F2Collectable.apr21,
//     pro7F3Collectable.apr21
// ]
// export const pro7WithApr21NonCount = [
//     pro7F1NonCollectable.apr21,
//     pro7F2NonCollectable.apr21,
//     pro7F3NonCollectable.apr21
// ]
// export const pro7WithApr21Claim = [
//     pro7F1ClaimCount.apr21,
//     pro7F2ClaimCount.apr21,
//     pro7F3ClaimCount.apr21
// ]
// export const pro7WithApr21PerVisit = [
//     pro7F1PerVisit.apr21,
//     pro7F2PerVisit.apr21,
//     pro7F3PerVisit.apr21
// ]
// // may 21
// export const pro7WithMay21Charges = [
//     pro7F1Charge.may21,
//     pro7F2Charge.may21,
//     pro7F3Charge.may21
// ]
// export const pro7WithMay21Pay = [
//     pro7F1Payment.may21,
//     pro7F2Payment.may21,
//     pro7F3Payment.may21
// ]
// export const pro7WithMay21Count = [
//     pro7F1Collectable.may21,
//     pro7F2Collectable.may21,
//     pro7F3Collectable.may21
// ]
// export const pro7WithMay21NonCount = [
//     pro7F1NonCollectable.may21,
//     pro7F2NonCollectable.may21,
//     pro7F3NonCollectable.may21
// ]
// export const pro7WithMay21Claim = [
//     pro7F1ClaimCount.may21,
//     pro7F2ClaimCount.may21,
//     pro7F3ClaimCount.may21
// ]
// export const pro7WithMay21PerVisit = [
//     pro7F1PerVisit.may21,
//     pro7F2PerVisit.may21,
//     pro7F3PerVisit.may21
// ]
// // jun 21
// export const pro7WithJun21Charges = [
//     pro7F1Charge.jun21,
//     pro7F2Charge.jun21,
//     pro7F3Charge.jun21
// ]
// export const pro7WithJun21Pay = [
//     pro7F1Payment.jun21,
//     pro7F2Payment.jun21,
//     pro7F3Payment.jun21
// ]
// export const pro7WithJun21Count = [
//     pro7F1Collectable.jun21,
//     pro7F2Collectable.jun21,
//     pro7F3Collectable.jun21
// ]
// export const pro7WithJun21NonCount = [
//     pro7F1NonCollectable.jun21,
//     pro7F2NonCollectable.jun21,
//     pro7F3NonCollectable.jun21
// ]
// export const pro7WithJun21Claim = [
//     pro7F1ClaimCount.jun21,
//     pro7F2ClaimCount.jun21,
//     pro7F3ClaimCount.jun21
// ]
// export const pro7WithJun21PerVisit = [
//     pro7F1PerVisit.jun21,
//     pro7F2PerVisit.jun21,
//     pro7F3PerVisit.jun21
// ]
// // jul 21
// export const pro7WithJul21Charges = [
//     pro7F1Charge.jul21,
//     pro7F2Charge.jul21,
//     pro7F3Charge.jul21
// ]
// export const pro7WithJul21Pay = [
//     pro7F1Payment.jul21,
//     pro7F2Payment.jul21,
//     pro7F3Payment.jul21
// ]
// export const pro7WithJul21Count = [
//     pro7F1Collectable.jul21,
//     pro7F2Collectable.jul21,
//     pro7F3Collectable.jul21
// ]
// export const pro7WithJul21NonCount = [
//     pro7F1NonCollectable.jul21,
//     pro7F2NonCollectable.jul21,
//     pro7F3NonCollectable.jul21
// ]
// export const pro7WithJul21Claim = [
//     pro7F1ClaimCount.jul21,
//     pro7F2ClaimCount.jul21,
//     pro7F3ClaimCount.jul21
// ]
// export const pro7WithJul21PerVisit = [
//     pro7F1PerVisit.jul21,
//     pro7F2PerVisit.jul21,
//     pro7F3PerVisit.jul21
// ]
// // aug 21
// export const pro7WithAug21Charges = [
//     pro7F1Charge.aug21,
//     pro7F2Charge.aug21,
//     pro7F3Charge.aug21
// ]
// export const pro7WithAug21Pay = [
//     pro7F1Payment.aug21,
//     pro7F2Payment.aug21,
//     pro7F3Payment.aug21
// ]
// export const pro7WithAug21Count = [
//     pro7F1Collectable.aug21,
//     pro7F2Collectable.aug21,
//     pro7F3Collectable.aug21
// ]
// export const pro7WithAug21NonCount = [
//     pro7F1NonCollectable.aug21,
//     pro7F2NonCollectable.aug21,
//     pro7F3NonCollectable.aug21
// ]
// export const pro7WithAug21Claim = [
//     pro7F1ClaimCount.aug21,
//     pro7F2ClaimCount.aug21,
//     pro7F3ClaimCount.aug21
// ]
// export const pro7WithAug21PerVisit = [
//     pro7F1PerVisit.aug21,
//     pro7F2PerVisit.aug21,
//     pro7F3PerVisit.aug21
// ]
// // sep 21
// export const pro7WithSep21Charges = [
//     pro7F1Charge.sep21,
//     pro7F2Charge.sep21,
//     pro7F3Charge.sep21
// ]
// export const pro7WithSep21Pay = [
//     pro7F1Payment.sep21,
//     pro7F2Payment.sep21,
//     pro7F3Payment.sep21
// ]
// export const pro7WithSep21Count = [
//     pro7F1Collectable.sep21,
//     pro7F2Collectable.sep21,
//     pro7F3Collectable.sep21
// ]
// export const pro7WithSep21NonCount = [
//     pro7F1NonCollectable.sep21,
//     pro7F2NonCollectable.sep21,
//     pro7F3NonCollectable.sep21
// ]
// export const pro7WithSep21Claim = [
//     pro7F1ClaimCount.sep21,
//     pro7F2ClaimCount.sep21,
//     pro7F3ClaimCount.sep21
// ]
// export const pro7WithSep21PerVisit = [
//     pro7F1PerVisit.sep21,
//     pro7F2PerVisit.sep21,
//     pro7F3PerVisit.sep21
// ]
// // oct 21
// export const pro7WithOct21Charges = [
//     pro7F1Charge.oct21,
//     pro7F2Charge.oct21,
//     pro7F3Charge.oct21
// ]
// export const pro7WithOct21Pay = [
//     pro7F1Payment.oct21,
//     pro7F2Payment.oct21,
//     pro7F3Payment.oct21
// ]
// export const pro7WithOct21Count = [
//     pro7F1Collectable.oct21,
//     pro7F2Collectable.oct21,
//     pro7F3Collectable.oct21
// ]
// export const pro7WithOct21NonCount = [
//     pro7F1NonCollectable.oct21,
//     pro7F2NonCollectable.oct21,
//     pro7F3NonCollectable.oct21
// ]
// export const pro7WithOct21Claim = [
//     pro7F1ClaimCount.oct21,
//     pro7F2ClaimCount.oct21,
//     pro7F3ClaimCount.oct21
// ]
// export const pro7WithOct21PerVisit = [
//     pro7F1PerVisit.oct21,
//     pro7F2PerVisit.oct21,
//     pro7F3PerVisit.oct21
// ]
// // nov 21
// export const pro7WithNov21Charges = [
//     pro7F1Charge.nov21,
//     pro7F2Charge.nov21,
//     pro7F3Charge.nov21
// ]
// export const pro7WithNov21Pay = [
//     pro7F1Payment.nov21,
//     pro7F2Payment.nov21,
//     pro7F3Payment.nov21
// ]
// export const pro7WithNov21Count = [
//     pro7F1Collectable.nov21,
//     pro7F2Collectable.nov21,
//     pro7F3Collectable.nov21
// ]
// export const pro7WithNov21NonCount = [
//     pro7F1NonCollectable.nov21,
//     pro7F2NonCollectable.nov21,
//     pro7F3NonCollectable.nov21
// ]
// export const pro7WithNov21Claim = [
//     pro7F1ClaimCount.nov21,
//     pro7F2ClaimCount.nov21,
//     pro7F3ClaimCount.nov21
// ]
// export const pro7WithNov21PerVisit = [
//     pro7F1PerVisit.nov21,
//     pro7F2PerVisit.nov21,
//     pro7F3PerVisit.nov21
// ]
// // dec 21
// export const pro7WithDec21Charges = [
//     pro7F1Charge.dec21,
//     pro7F2Charge.dec21,
//     pro7F3Charge.dec21
// ]
// export const pro7WithDec21Pay = [
//     pro7F1Payment.dec21,
//     pro7F2Payment.dec21,
//     pro7F3Payment.dec21
// ]
// export const pro7WithDec21Count = [
//     pro7F1Collectable.dec21,
//     pro7F2Collectable.dec21,
//     pro7F3Collectable.dec21
// ]
// export const pro7WithDec21NonCount = [
//     pro7F1NonCollectable.dec21,
//     pro7F2NonCollectable.dec21,
//     pro7F3NonCollectable.dec21
// ]
// export const pro7WithDec21Claim = [
//     pro7F1ClaimCount.dec21,
//     pro7F2ClaimCount.dec21,
//     pro7F3ClaimCount.dec21
// ]
// export const pro7WithDec21PerVisit = [
//     pro7F1PerVisit.dec21,
//     pro7F2PerVisit.dec21,
//     pro7F3PerVisit.dec21
// ]

// // provider-6
// // jan 22
// export const pro7WithJan22Charges = [
//     pro7F1Charge.jan22,
//     pro7F2Charge.jan22,
//     pro7F3Charge.jan22
// ]
// export const pro7WithJan22Pay = [
//     pro7F1Payment.jan22,
//     pro7F2Payment.jan22,
//     pro7F3Payment.jan22
// ]
// export const pro7WithJan22Count = [
//     pro7F1Collectable.jan22,
//     pro7F2Collectable.jan22,
//     pro7F3Collectable.jan22
// ]
// export const pro7WithJan22NonCount = [
//     pro7F1NonCollectable.jan22,
//     pro7F2NonCollectable.jan22,
//     pro7F3NonCollectable.jan22
// ]
// export const pro7WithJan22Claim = [
//     pro7F1ClaimCount.jan22,
//     pro7F2ClaimCount.jan22,
//     pro7F3ClaimCount.jan22
// ]
// export const pro7WithJan22PerVisit = [
//     pro7F1PerVisit.jan22,
//     pro7F2PerVisit.jan22,
//     pro7F3PerVisit.jan22
// ]
// // feb 22
// export const pro7WithFeb22Charges = [
//     pro7F1Charge.feb22,
//     pro7F2Charge.feb22,
//     pro7F3Charge.feb22
// ]
// export const pro7WithFeb22Pay = [
//     pro7F1Payment.feb22,
//     pro7F2Payment.feb22,
//     pro7F3Payment.feb22
// ]
// export const pro7WithFeb22Count = [
//     pro7F1Collectable.feb22,
//     pro7F2Collectable.feb22,
//     pro7F3Collectable.feb22
// ]
// export const pro7WithFeb22NonCount = [
//     pro7F1NonCollectable.feb22,
//     pro7F2NonCollectable.feb22,
//     pro7F3NonCollectable.feb22
// ]
// export const pro7WithFeb22Claim = [
//     pro7F1ClaimCount.feb22,
//     pro7F2ClaimCount.feb22,
//     pro7F3ClaimCount.feb22
// ]
// export const pro7WithFeb22PerVisit = [
//     pro7F1PerVisit.feb22,
//     pro7F2PerVisit.feb22,
//     pro7F3PerVisit.feb22
// ]
// // mar 22
// export const pro7WithMar22Charges = [
//     pro7F1Charge.mar22,
//     pro7F2Charge.mar22,
//     pro7F3Charge.mar22
// ]
// export const pro7WithMar22Pay = [
//     pro7F1Payment.mar22,
//     pro7F2Payment.mar22,
//     pro7F3Payment.mar22
// ]
// export const pro7WithMar22Count = [
//     pro7F1Collectable.mar22,
//     pro7F2Collectable.mar22,
//     pro7F3Collectable.mar22
// ]
// export const pro7WithMar22NonCount = [
//     pro7F1NonCollectable.mar22,
//     pro7F2NonCollectable.mar22,
//     pro7F3NonCollectable.mar22
// ]
// export const pro7WithMar22Claim = [
//     pro7F1ClaimCount.mar22,
//     pro7F2ClaimCount.mar22,
//     pro7F3ClaimCount.mar22
// ]
// export const pro7WithMar22PerVisit = [
//     pro7F1PerVisit.mar22,
//     pro7F2PerVisit.mar22,
//     pro7F3PerVisit.mar22
// ]
// // april 22
// export const pro7WithApr22Charges = [
//     pro7F1Charge.apr22,
//     pro7F2Charge.apr22,
//     pro7F3Charge.apr22
// ]
// export const pro7WithApr22Pay = [
//     pro7F1Payment.apr22,
//     pro7F2Payment.apr22,
//     pro7F3Payment.apr22
// ]
// export const pro7WithApr22Count = [
//     pro7F1Collectable.apr22,
//     pro7F2Collectable.apr22,
//     pro7F3Collectable.apr22
// ]
// export const pro7WithApr22NonCount = [
//     pro7F1NonCollectable.apr22,
//     pro7F2NonCollectable.apr22,
//     pro7F3NonCollectable.apr22
// ]
// export const pro7WithApr22Claim = [
//     pro7F1ClaimCount.apr22,
//     pro7F2ClaimCount.apr22,
//     pro7F3ClaimCount.apr22
// ]
// export const pro7WithApr22PerVisit = [
//     pro7F1PerVisit.apr22,
//     pro7F2PerVisit.apr22,
//     pro7F3PerVisit.apr22
// ]
// // may 22
// export const pro7WithMay22Charges = [
//     pro7F1Charge.may22,
//     pro7F2Charge.may22,
//     pro7F3Charge.may22
// ]
// export const pro7WithMay22Pay = [
//     pro7F1Payment.may22,
//     pro7F2Payment.may22,
//     pro7F3Payment.may22
// ]
// export const pro7WithMay22Count = [
//     pro7F1Collectable.may22,
//     pro7F2Collectable.may22,
//     pro7F3Collectable.may22
// ]
// export const pro7WithMay22NonCount = [
//     pro7F1NonCollectable.may22,
//     pro7F2NonCollectable.may22,
//     pro7F3NonCollectable.may22
// ]
// export const pro7WithMay22Claim = [
//     pro7F1ClaimCount.may22,
//     pro7F2ClaimCount.may22,
//     pro7F3ClaimCount.may22
// ]
// export const pro7WithMay22PerVisit = [
//     pro7F1PerVisit.may22,
//     pro7F2PerVisit.may22,
//     pro7F3PerVisit.may22
// ]
// // jun 22
// export const pro7WithJun22Charges = [
//     pro7F1Charge.jun22,
//     pro7F2Charge.jun22,
//     pro7F3Charge.jun22
// ]
// export const pro7WithJun22Pay = [
//     pro7F1Payment.jun22,
//     pro7F2Payment.jun22,
//     pro7F3Payment.jun22
// ]
// export const pro7WithJun22Count = [
//     pro7F1Collectable.jun22,
//     pro7F2Collectable.jun22,
//     pro7F3Collectable.jun22
// ]
// export const pro7WithJun22NonCount = [
//     pro7F1NonCollectable.jun22,
//     pro7F2NonCollectable.jun22,
//     pro7F3NonCollectable.jun22
// ]
// export const pro7WithJun22Claim = [
//     pro7F1ClaimCount.jun22,
//     pro7F2ClaimCount.jun22,
//     pro7F3ClaimCount.jun22
// ]
// export const pro7WithJun22PerVisit = [
//     pro7F1PerVisit.jun22,
//     pro7F2PerVisit.jun22,
//     pro7F3PerVisit.jun22
// ]
// // jul 22
// export const pro7WithJul22Charges = [
//     pro7F1Charge.jul22,
//     pro7F2Charge.jul22,
//     pro7F3Charge.jul22
// ]
// export const pro7WithJul22Pay = [
//     pro7F1Payment.jul22,
//     pro7F2Payment.jul22,
//     pro7F3Payment.jul22
// ]
// export const pro7WithJul22Count = [
//     pro7F1Collectable.jul22,
//     pro7F2Collectable.jul22,
//     pro7F3Collectable.jul22
// ]
// export const pro7WithJul22NonCount = [
//     pro7F1NonCollectable.jul22,
//     pro7F2NonCollectable.jul22,
//     pro7F3NonCollectable.jul22
// ]
// export const pro7WithJul22Claim = [
//     pro7F1ClaimCount.jul22,
//     pro7F2ClaimCount.jul22,
//     pro7F3ClaimCount.jul22
// ]
// export const pro7WithJul22PerVisit = [
//     pro7F1PerVisit.jul22,
//     pro7F2PerVisit.jul22,
//     pro7F3PerVisit.jul22
// ]
// // aug 22
// export const pro7WithAug22Charges = [
//     pro7F1Charge.aug22,
//     pro7F2Charge.aug22,
//     pro7F3Charge.aug22
// ]
// export const pro7WithAug22Pay = [
//     pro7F1Payment.aug22,
//     pro7F2Payment.aug22,
//     pro7F3Payment.aug22
// ]
// export const pro7WithAug22Count = [
//     pro7F1Collectable.aug22,
//     pro7F2Collectable.aug22,
//     pro7F3Collectable.aug22
// ]
// export const pro7WithAug22NonCount = [
//     pro7F1NonCollectable.aug22,
//     pro7F2NonCollectable.aug22,
//     pro7F3NonCollectable.aug22
// ]
// export const pro7WithAug22Claim = [
//     pro7F1ClaimCount.aug22,
//     pro7F2ClaimCount.aug22,
//     pro7F3ClaimCount.aug22
// ]
// export const pro7WithAug22PerVisit = [
//     pro7F1PerVisit.aug22,
//     pro7F2PerVisit.aug22,
//     pro7F3PerVisit.aug22
// ]
// // sep 22
// export const pro7WithSep22Charges = [
//     pro7F1Charge.sep22,
//     pro7F2Charge.sep22,
//     pro7F3Charge.sep22
// ]
// export const pro7WithSep22Pay = [
//     pro7F1Payment.sep22,
//     pro7F2Payment.sep22,
//     pro7F3Payment.sep22
// ]
// export const pro7WithSep22Count = [
//     pro7F1Collectable.sep22,
//     pro7F2Collectable.sep22,
//     pro7F3Collectable.sep22
// ]
// export const pro7WithSep22NonCount = [
//     pro7F1NonCollectable.sep22,
//     pro7F2NonCollectable.sep22,
//     pro7F3NonCollectable.sep22
// ]
// export const pro7WithSep22Claim = [
//     pro7F1ClaimCount.sep22,
//     pro7F2ClaimCount.sep22,
//     pro7F3ClaimCount.sep22
// ]
// export const pro7WithSep22PerVisit = [
//     pro7F1PerVisit.sep22,
//     pro7F2PerVisit.sep22,
//     pro7F3PerVisit.sep22
// ]
// // oct 22
// export const pro7WithOct22Charges = [
//     pro7F1Charge.oct22,
//     pro7F2Charge.oct22,
//     pro7F3Charge.oct22
// ]
// export const pro7WithOct22Pay = [
//     pro7F1Payment.oct22,
//     pro7F2Payment.oct22,
//     pro7F3Payment.oct22
// ]
// export const pro7WithOct22Count = [
//     pro7F1Collectable.oct22,
//     pro7F2Collectable.oct22,
//     pro7F3Collectable.oct22
// ]
// export const pro7WithOct22NonCount = [
//     pro7F1NonCollectable.oct22,
//     pro7F2NonCollectable.oct22,
//     pro7F3NonCollectable.oct22
// ]
// export const pro7WithOct22Claim = [
//     pro7F1ClaimCount.oct22,
//     pro7F2ClaimCount.oct22,
//     pro7F3ClaimCount.oct22
// ]
// export const pro7WithOct22PerVisit = [
//     pro7F1PerVisit.oct22,
//     pro7F2PerVisit.oct22,
//     pro7F3PerVisit.oct22
// ]
// // nov 22
// export const pro7WithNov22Charges = [
//     pro7F1Charge.nov22,
//     pro7F2Charge.nov22,
//     pro7F3Charge.nov22
// ]
// export const pro7WithNov22Pay = [
//     pro7F1Payment.nov22,
//     pro7F2Payment.nov22,
//     pro7F3Payment.nov22
// ]
// export const pro7WithNov22Count = [
//     pro7F1Collectable.nov22,
//     pro7F2Collectable.nov22,
//     pro7F3Collectable.nov22
// ]
// export const pro7WithNov22NonCount = [
//     pro7F1NonCollectable.nov22,
//     pro7F2NonCollectable.nov22,
//     pro7F3NonCollectable.nov22
// ]
// export const pro7WithNov22Claim = [
//     pro7F1ClaimCount.nov22,
//     pro7F2ClaimCount.nov22,
//     pro7F3ClaimCount.nov22
// ]
// export const pro7WithNov22PerVisit = [
//     pro7F1PerVisit.nov22,
//     pro7F2PerVisit.nov22,
//     pro7F3PerVisit.nov22
// ]
// // dec 22
// export const pro7WithDec22Charges = [
//     pro7F1Charge.dec22,
//     pro7F2Charge.dec22,
//     pro7F3Charge.dec22
// ]
// export const pro7WithDec22Pay = [
//     pro7F1Payment.dec22,
//     pro7F2Payment.dec22,
//     pro7F3Payment.dec22
// ]
// export const pro7WithDec22Count = [
//     pro7F1Collectable.dec22,
//     pro7F2Collectable.dec22,
//     pro7F3Collectable.dec22
// ]
// export const pro7WithDec22NonCount = [
//     pro7F1NonCollectable.dec22,
//     pro7F2NonCollectable.dec22,
//     pro7F3NonCollectable.dec22
// ]
// export const pro7WithDec22Claim = [
//     pro7F1ClaimCount.dec22,
//     pro7F2ClaimCount.dec22,
//     pro7F3ClaimCount.dec22
// ]
// export const pro7WithDec22PerVisit = [
//     pro7F1PerVisit.dec22,
//     pro7F2PerVisit.dec22,
//     pro7F3PerVisit.dec22
// ]

// // provider-6
// // jan 23
// export const pro7WithJan23Charges = [
//     pro7F1Charge.jan23,
//     pro7F2Charge.jan23,
//     pro7F3Charge.jan23
// ]
// export const pro7WithJan23Pay = [
//     pro7F1Payment.jan23,
//     pro7F2Payment.jan23,
//     pro7F3Payment.jan23
// ]
// export const pro7WithJan23Count = [
//     pro7F1Collectable.jan23,
//     pro7F2Collectable.jan23,
//     pro7F3Collectable.jan23
// ]
// export const pro7WithJan23NonCount = [
//     pro7F1NonCollectable.jan23,
//     pro7F2NonCollectable.jan23,
//     pro7F3NonCollectable.jan23
// ]
// export const pro7WithJan23Claim = [
//     pro7F1ClaimCount.jan23,
//     pro7F2ClaimCount.jan23,
//     pro7F3ClaimCount.jan23
// ]
// export const pro7WithJan23PerVisit = [
//     pro7F1PerVisit.jan23,
//     pro7F2PerVisit.jan23,
//     pro7F3PerVisit.jan23
// ]
// // feb 23
// export const pro7WithFeb23Charges = [
//     pro7F1Charge.feb23,
//     pro7F2Charge.feb23,
//     pro7F3Charge.feb23
// ]
// export const pro7WithFeb23Pay = [
//     pro7F1Payment.feb23,
//     pro7F2Payment.feb23,
//     pro7F3Payment.feb23
// ]
// export const pro7WithFeb23Count = [
//     pro7F1Collectable.feb23,
//     pro7F2Collectable.feb23,
//     pro7F3Collectable.feb23
// ]
// export const pro7WithFeb23NonCount = [
//     pro7F1NonCollectable.feb23,
//     pro7F2NonCollectable.feb23,
//     pro7F3NonCollectable.feb23
// ]
// export const pro7WithFeb23Claim = [
//     pro7F1ClaimCount.feb23,
//     pro7F2ClaimCount.feb23,
//     pro7F3ClaimCount.feb23
// ]
// export const pro7WithFeb23PerVisit = [
//     pro7F1PerVisit.feb23,
//     pro7F2PerVisit.feb23,
//     pro7F3PerVisit.feb23
// ]
// // mar 23
// export const pro7WithMar23Charges = [
//     pro7F1Charge.mar23,
//     pro7F2Charge.mar23,
//     pro7F3Charge.mar23
// ]
// export const pro7WithMar23Pay = [
//     pro7F1Payment.mar23,
//     pro7F2Payment.mar23,
//     pro7F3Payment.mar23
// ]
// export const pro7WithMar23Count = [
//     pro7F1Collectable.mar23,
//     pro7F2Collectable.mar23,
//     pro7F3Collectable.mar23
// ]
// export const pro7WithMar23NonCount = [
//     pro7F1NonCollectable.mar23,
//     pro7F2NonCollectable.mar23,
//     pro7F3NonCollectable.mar23
// ]
// export const pro7WithMar23Claim = [
//     pro7F1ClaimCount.mar23,
//     pro7F2ClaimCount.mar23,
//     pro7F3ClaimCount.mar23
// ]
// export const pro7WithMar23PerVisit = [
//     pro7F1PerVisit.mar23,
//     pro7F2PerVisit.mar23,
//     pro7F3PerVisit.mar23
// ]
// // april 23
// export const pro7WithApr23Charges = [
//     pro7F1Charge.apr23,
//     pro7F2Charge.apr23,
//     pro7F3Charge.apr23
// ]
// export const pro7WithApr23Pay = [
//     pro7F1Payment.apr23,
//     pro7F2Payment.apr23,
//     pro7F3Payment.apr23
// ]
// export const pro7WithApr23Count = [
//     pro7F1Collectable.apr23,
//     pro7F2Collectable.apr23,
//     pro7F3Collectable.apr23
// ]
// export const pro7WithApr23NonCount = [
//     pro7F1NonCollectable.apr23,
//     pro7F2NonCollectable.apr23,
//     pro7F3NonCollectable.apr23
// ]
// export const pro7WithApr23Claim = [
//     pro7F1ClaimCount.apr23,
//     pro7F2ClaimCount.apr23,
//     pro7F3ClaimCount.apr23
// ]
// export const pro7WithApr23PerVisit = [
//     pro7F1PerVisit.apr23,
//     pro7F2PerVisit.apr23,
//     pro7F3PerVisit.apr23
// ]
// // may 23
// export const pro7WithMay23Charges = [
//     pro7F1Charge.may23,
//     pro7F2Charge.may23,
//     pro7F3Charge.may23
// ]
// export const pro7WithMay23Pay = [
//     pro7F1Payment.may23,
//     pro7F2Payment.may23,
//     pro7F3Payment.may23
// ]
// export const pro7WithMay23Count = [
//     pro7F1Collectable.may23,
//     pro7F2Collectable.may23,
//     pro7F3Collectable.may23
// ]
// export const pro7WithMay23NonCount = [
//     pro7F1NonCollectable.may23,
//     pro7F2NonCollectable.may23,
//     pro7F3NonCollectable.may23
// ]
// export const pro7WithMay23Claim = [
//     pro7F1ClaimCount.may23,
//     pro7F2ClaimCount.may23,
//     pro7F3ClaimCount.may23
// ]
// export const pro7WithMay23PerVisit = [
//     pro7F1PerVisit.may23,
//     pro7F2PerVisit.may23,
//     pro7F3PerVisit.may23
// ]
// // jun 23
// export const pro7WithJun23Charges = [
//     pro7F1Charge.jun23,
//     pro7F2Charge.jun23,
//     pro7F3Charge.jun23
// ]
// export const pro7WithJun23Pay = [
//     pro7F1Payment.jun23,
//     pro7F2Payment.jun23,
//     pro7F3Payment.jun23
// ]
// export const pro7WithJun23Count = [
//     pro7F1Collectable.jun23,
//     pro7F2Collectable.jun23,
//     pro7F3Collectable.jun23
// ]
// export const pro7WithJun23NonCount = [
//     pro7F1NonCollectable.jun23,
//     pro7F2NonCollectable.jun23,
//     pro7F3NonCollectable.jun23
// ]
// export const pro7WithJun23Claim = [
//     pro7F1ClaimCount.jun23,
//     pro7F2ClaimCount.jun23,
//     pro7F3ClaimCount.jun23
// ]
// export const pro7WithJun23PerVisit = [
//     pro7F1PerVisit.jun23,
//     pro7F2PerVisit.jun23,
//     pro7F3PerVisit.jun23
// ]
// // jul 23
// export const pro7WithJul23Charges = [
//     pro7F1Charge.jul23,
//     pro7F2Charge.jul23,
//     pro7F3Charge.jul23
// ]
// export const pro7WithJul23Pay = [
//     pro7F1Payment.jul23,
//     pro7F2Payment.jul23,
//     pro7F3Payment.jul23
// ]
// export const pro7WithJul23Count = [
//     pro7F1Collectable.jul23,
//     pro7F2Collectable.jul23,
//     pro7F3Collectable.jul23
// ]
// export const pro7WithJul23NonCount = [
//     pro7F1NonCollectable.jul23,
//     pro7F2NonCollectable.jul23,
//     pro7F3NonCollectable.jul23
// ]
// export const pro7WithJul23Claim = [
//     pro7F1ClaimCount.jul23,
//     pro7F2ClaimCount.jul23,
//     pro7F3ClaimCount.jul23
// ]
// export const pro7WithJul23PerVisit = [
//     pro7F1PerVisit.jul23,
//     pro7F2PerVisit.jul23,
//     pro7F3PerVisit.jul23
// ]
// // aug 23
// export const pro7WithAug23Charges = [
//     pro7F1Charge.aug23,
//     pro7F2Charge.aug23,
//     pro7F3Charge.aug23
// ]
// export const pro7WithAug23Pay = [
//     pro7F1Payment.aug23,
//     pro7F2Payment.aug23,
//     pro7F3Payment.aug23
// ]
// export const pro7WithAug23Count = [
//     pro7F1Collectable.aug23,
//     pro7F2Collectable.aug23,
//     pro7F3Collectable.aug23
// ]
// export const pro7WithAug23NonCount = [
//     pro7F1NonCollectable.aug23,
//     pro7F2NonCollectable.aug23,
//     pro7F3NonCollectable.aug23
// ]
// export const pro7WithAug23Claim = [
//     pro7F1ClaimCount.aug23,
//     pro7F2ClaimCount.aug23,
//     pro7F3ClaimCount.aug23
// ]
// export const pro7WithAug23PerVisit = [
//     pro7F1PerVisit.aug23,
//     pro7F2PerVisit.aug23,
//     pro7F3PerVisit.aug23
// ]
// // sep 23
// export const pro7WithSep23Charges = [
//     pro7F1Charge.sep23,
//     pro7F2Charge.sep23,
//     pro7F3Charge.sep23
// ]
// export const pro7WithSep23Pay = [
//     pro7F1Payment.sep23,
//     pro7F2Payment.sep23,
//     pro7F3Payment.sep23
// ]
// export const pro7WithSep23Count = [
//     pro7F1Collectable.sep23,
//     pro7F2Collectable.sep23,
//     pro7F3Collectable.sep23
// ]
// export const pro7WithSep23NonCount = [
//     pro7F1NonCollectable.sep23,
//     pro7F2NonCollectable.sep23,
//     pro7F3NonCollectable.sep23
// ]
// export const pro7WithSep23Claim = [
//     pro7F1ClaimCount.sep23,
//     pro7F2ClaimCount.sep23,
//     pro7F3ClaimCount.sep23
// ]
// export const pro7WithSep23PerVisit = [
//     pro7F1PerVisit.sep23,
//     pro7F2PerVisit.sep23,
//     pro7F3PerVisit.sep23
// ]
// // oct 23
// export const pro7WithOct23Charges = [
//     pro7F1Charge.oct23,
//     pro7F2Charge.oct23,
//     pro7F3Charge.oct23
// ]
// export const pro7WithOct23Pay = [
//     pro7F1Payment.oct23,
//     pro7F2Payment.oct23,
//     pro7F3Payment.oct23
// ]
// export const pro7WithOct23Count = [
//     pro7F1Collectable.oct23,
//     pro7F2Collectable.oct23,
//     pro7F3Collectable.oct23
// ]
// export const pro7WithOct23NonCount = [
//     pro7F1NonCollectable.oct23,
//     pro7F2NonCollectable.oct23,
//     pro7F3NonCollectable.oct23
// ]
// export const pro7WithOct23Claim = [
//     pro7F1ClaimCount.oct23,
//     pro7F2ClaimCount.oct23,
//     pro7F3ClaimCount.oct23
// ]
// export const pro7WithOct23PerVisit = [
//     pro7F1PerVisit.oct23,
//     pro7F2PerVisit.oct23,
//     pro7F3PerVisit.oct23
// ]
// // nov 23
// export const pro7WithNov23Charges = [
//     pro7F1Charge.nov23,
//     pro7F2Charge.nov23,
//     pro7F3Charge.nov23
// ]
// export const pro7WithNov23Pay = [
//     pro7F1Payment.nov23,
//     pro7F2Payment.nov23,
//     pro7F3Payment.nov23
// ]
// export const pro7WithNov23Count = [
//     pro7F1Collectable.nov23,
//     pro7F2Collectable.nov23,
//     pro7F3Collectable.nov23
// ]
// export const pro7WithNov23NonCount = [
//     pro7F1NonCollectable.nov23,
//     pro7F2NonCollectable.nov23,
//     pro7F3NonCollectable.nov23
// ]
// export const pro7WithNov23Claim = [
//     pro7F1ClaimCount.nov23,
//     pro7F2ClaimCount.nov23,
//     pro7F3ClaimCount.nov23
// ]
// export const pro7WithNov23PerVisit = [
//     pro7F1PerVisit.nov23,
//     pro7F2PerVisit.nov23,
//     pro7F3PerVisit.nov23
// ]

